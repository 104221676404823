<template>
  <section>
    <form data-vv-scope="payment-request-form">
      <div>
        <div class="columns is-multiline">
          <div class="column is-10 no-bottom-padding">
            <div class="field">
              <button-list-selector :enabled="!readOnly" scope="payment-request-form" validateAs="payee type" label="Payee Type" :required="true" v-model="selectedEntityType" :availableValues="availablePayeeTypes"></button-list-selector>
            </div>
          </div>
          <div class="column is-2 no-bottom-padding" v-if="showBrokerFee">
            <switch-selector :isDisabled="readOnly" type="is-info" label="Broker Fee" v-model="isBrokerFee" :required="false" extraStyles="margin-top:10px;"></switch-selector>
          </div>
          <div class="column is-12 no-bottom-padding" v-if="isWholesalerPayeeType">
            <div class="field">
              <supplier-selector :isDisabled="readOnly || isSupplierLocked" scope="payment-request-form" v-model="selectedSupplier" label="Wholesaler" validateAs="wholesaler" :required="true"></supplier-selector>
            </div>
          </div>
          <div class="column is-12 no-bottom-padding" v-else-if="isCustomerPayeeType">
            <div class="field">
              <customer-selector :isDisabled="readOnly || isCustomerLocked" scope="payment-request-form" :selectedDealer="currentDealer" v-model="selectedCustomer" label="Customer" validateAs="customer" :required="true"></customer-selector>
            </div>
          </div>
          <div class="column is-12 no-bottom-padding" v-else-if="isBankPayeeType">
            <div class="field">
              <bank-selector :isDisabled="readOnly" scope="payment-request-form" :selectedDealer="currentDealer" v-model="selectedBank" label="Bank" validateAs="bank" :required="true"></bank-selector>
            </div>
          </div>
          <div class="column is-12 no-bottom-padding" v-else-if="isAccountingRecon">
            <div class="field">
              <label class="label">Payee</label>
              Post to A/R
            </div>
          </div>
          <div class="column is-12 no-bottom-padding" v-else-if="isDealEquity">
            <div class="field">
              <label class="label">Payee</label>
              Post to deal equity
            </div>
          </div>
          <div class="column no-bottom-padding" :class="showGoodUntilDate ? 'is-narrow' : 'is-6'">
            <div class="field">
              <button-list-selector :enabled="isPaymentTypeEnabled" scope="payment-request-form" validateAs="payment type" label="Payment Type" :required="true" v-model="selectedPaymentType" :availableValues="allPaymentTypes"></button-list-selector>
            </div>
            <div v-if="!isPaymentTypeDetailsValid" class="notification is-danger is-flex">
              <span>
                <i class="fas fa-exclamation-triangle is-danger fa-lg"></i>
              </span>
              <span class="ml-10">
                <p>{{getPaymentTypeInvalidText}}</p>
                <p><button class="button is-white mt-10 is-small" type="button" @click="canLaunchPayeeEdit=true">Fix</button></p>
              </span>
            </div>
          </div>
          <div class="column is-4 no-bottom-padding" v-if="showGoodUntilDate">
            <div class="field">
              <label class="label">Good Until Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
              <div class="control has-icons-left">
                <el-date-picker
                  @change="onFormChange()"
                  v-model="inventory.goodUntilDate"
                  type="date"
                  data-vv-as="good until date"
                  v-validate="{'required': showGoodUntilDate, 'valid_payoff_expiry': true}"
                  data-vv-validate-on="input|close"
                  name="goodUntilDate"
                  data-vv-scope="payment-request-form"
                  placeholder="Click to select..."
                  popper-class="datepicker-container"
                  :clearable="false"
                  :disabled="readOnly">
                </el-date-picker>
                <span v-show="errors.has('payment-request-form.goodUntilDate')" class="help is-danger"><i v-show="errors.has('payment-request-form.goodUntilDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('payment-request-form.goodUntilDate') }}</span>
              </div>
            </div>
          </div>
          <div class="column no-bottom-padding" :class="showGoodUntilDate ? 'is-4' : 'is-6'">
            <label class="label">Amount to Pay<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="control has-icons-left">
              <money :disabled="readOnly" data-vv-scope="payment-request-form" name="amount" data-vv-as="amount" v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has('payment-request-form.amount') }" type="text" placeholder="0000.00" v-model="currentPaymentRequest.amount" v-bind="$globalMoneyFormat"></money>
              <span v-show="errors.has('payment-request-form.amount')" class="help is-danger">
                <i v-show="errors.has('payment-request-form.amount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('payment-request-form.amount') }}
              </span>
              <span class="icon is-small is-left">
                <i class="fas fa-dollar-sign"></i>
              </span>
              <pop-fill-control
                title="Click to auto fill the amount to pay with the current unallocated amount"
                :fillableValue="fillableAmount"
                :onFillAction="onFillPaymentAmount"
                :applyCurrencyFormat="true"
                v-model="currentPaymentRequest.amount"
                :doComparison="true">
              </pop-fill-control>
            </div>
          </div>
          <div class="column is-12" v-if="selectedPaymentType && selectedPaymentType.name === 'Check'">
            <button-list-selector :enabled="!readOnly" scope="payment-request-form" ref="checkDeliveryTypeSelector" validateAs="delivery type" label="How are we sending it?" :required="true" v-model="selectedCheckDeliveryType" :availableValues="definedTypes.checkDeliveryTypes"></button-list-selector>
          </div>
          <div class="column is-6" v-if="selectedPaymentType && selectedPaymentType.name === 'Check' && applicablePayToAddress !== ''">
            <label class="label">Send Check To Address<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="title is-size-6 has-text-success is-uppercase" v-html="applicablePayToAddress">
            </div>
          </div>
          <div class="column is-6" v-if="selectedPaymentType && (selectedPaymentType.name === 'Check' || selectedPaymentType.name === 'Wire') && applicablePayToBank !== ''">
            <label class="label">Wire Payment To Bank Details<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="title is-size-6 has-text-success is-uppercase" v-html="applicablePayToBank">
            </div>
          </div>
          <div class="column is-12 no-bottom-padding">
            <label class="label">Notes</label>
            <div class="control">
              <textarea :disabled="readOnly" data-vv-scope="payment-request-form" data-vv-as="note" v-validate="{'required': noteRequired}"  name="note" :class="{'textarea': true,'is-danger': errors.has('payment-request-form.note') }" placeholder="" v-model="selectedNote" rows="14"></textarea>
              <span v-show="errors.has('payment-request-form.note')" class="help is-danger">
                <i v-show="errors.has('payment-request-form.note')" class="fas fa-exclamation-triangle"></i> {{ errors.first('payment-request-form.note') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <portal to="modal-card-foot">
        <div class="field is-grouped">
          <button class="button is-primary buttonStyle" @click="validateBeforeSubmit">Save</button>
          <button class="button is-danger buttonStyle" @click="onCancel()">Cancel</button>
          <button class="button is-link buttonStyle" @click="onDelete">Delete</button>
        </div>
      </portal>
    </form>

    <b-modal :active.sync="isDeleteConfirmationActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Delete Action</p>
        </header>
        <section class="modal-card-body">
          Are you sure you wish to delete this request?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="onConfirmDelete()">Delete</button>
          <button class="button" @click="isDeleteConfirmationActive = false">Cancel</button>
        </footer>
      </div>
    </b-modal>

    <b-modal :active.sync="canLaunchPayeeEdit" scroll="keep" :has-modal-card="true" id="edit-customer-modal">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body">
          <edit-customer v-if="launchEditCustomer" :customerId="selectedCustomer.id" mode="modal" actionPortal="modal-footer" headerPortal="modal-header"
            v-on:on-save="onCustomerSaved" v-on:on-cancel="onPayeeCancelled" :defaultTab="getEntityDefaultTab"></edit-customer>
          <edit-supplier v-if="launchEditSupplier" :supplierId="selectedSupplier.id" mode="modal" actionPortal="modal-footer" headerPortal="modal-header"
            v-on:on-save="onSupplierSaved" v-on:on-cancel="onPayeeCancelled" :defaultTab="getEntityDefaultTab"></edit-supplier>
          <edit-bank v-if="launchEditBank" :bankId="selectedBank.id" :isInModal="true" actionPortal="modal-footer" headerPortal="modal-header"
            v-on:on-save="onBankSaved" v-on:on-cancel="onPayeeCancelled" :defaultTab="getEntityDefaultTab"></edit-bank>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>

    <b-loading :is-full-page="true" :active.sync="showLoader" :canCancel="false"></b-loading>

  </section>
</template>

<script>
import moment from 'moment'
import CustomerSelector from '@/components/generic/CustomerSelector'
import SupplierSelector from '@/components/generic/SupplierSelector'
import BankSelector from '@/components/generic/BankSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import customerService from '@/services/customerService'
import supplierService from '@/services/supplierService'
import bankService from '@/services/bankService'
import inventoryService from '@/services/inventoryService'
import SwitchSelector from '@/components/generic/SwitchSelector'
import AddressCapture from '@/components/generic/AddressCapture'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import EditCustomer from '@/components/configuration/customers/EditCustomer'
import EditSupplier from '@/components/configuration/suppliers/EditSupplier'
import EditBank from '@/components/configuration/banks/EditBank'
import PopFillControl from '@/components/generic/PopFillControl'

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapSubscriberGetters = createNamespacedHelpers('subscriber').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'PaymentRequestCapture',
  mixins: [validateBeforeSubmitMixin],
  components: {
    'customer-selector': CustomerSelector,
    'bank-selector': BankSelector,
    'button-list-selector': ButtonListSelector,
    'supplier-selector': SupplierSelector,
    'switch-selector': SwitchSelector,
    'address-capture': AddressCapture,
    'pop-fill-control': PopFillControl,
    'edit-customer': EditCustomer,
    'edit-bank': EditBank,
    'edit-supplier': EditSupplier
  },
  props: {
    forBatchPaymentRequest: {
      type: Object,
      default: function () {
        return {
          id: 0,
          paymentRequests: []
        }
      }
    },
    currentPaymentRequestId: {
      default: function () {
        return '0'
      }
    },
    unallocatedAmount: {
      type: Number,
      default: 0
    },
    inventory: {
      type: Object,
      default: null
    },
    readOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      currentPaymentRequest: {
        new: true,
        id: this.$uuid.v4(),
        batchPaymentRequestId: this.forBatchPaymentRequest.id,
        payeeType: null,
        paymentType: 'Check',
        status: 'Pending',
        amount: null,
        note: '',
        autoGenerated: false,
        hasPayoffAddress: true,
        isPayoffDateExpired: false,
        isVisuallyVerified: true
      },
      selectedEntityType: null,
      selectedSupplier: null,
      selectedCustomer: null,
      selectedBank: null,
      selectedNote: '',
      isBrokerFee: false,
      selectedPaymentType: null,
      isDeleteConfirmationActive: false,
      defaultBrokerNote: 'This is a bird-dog / broker fee payment for this vehicle.',
      cpoInfo: '',
      originalGoodUntilDate: moment().format('YYYY/MM/DD'),
      originalPayoffAmount: 0,
      canLaunchPayeeEdit: false,
      isSaving: false,
      isLoading: false,
      selectedCheckDeliveryType: null,
      isSupplierLocked: false,
      isCustomerLocked: false
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapSubscriberGetters(['getCurrentSubscriber']),
    ...mapDealerState(['currentDealer']),
    fillableAmount: function () {
      if (this.unallocatedAmount < 0) {
        if (this.currentPaymentRequest.amount) {
          return this.currentPaymentRequest.amount > Math.abs(this.unallocatedAmount) ? (this.currentPaymentRequest.amount + this.unallocatedAmount) : 0.00
        }
        return 0.00
      } else {
        return this.unallocatedAmount
      }
    },
    applicablePayToAddress: function () {
      if (this.selectedEntity) {
        let address = this.isBankPayeeType ? this.selectedEntity.fullPayoffAddress : (this.selectedEntity.fullAddress ? this.selectedEntity.fullAddress : '')
        let checkInfo = (address) ? address.replace(/,/g, '<br/>') : ''
        return checkInfo
      }
      return ''
    },
    applicablePayToBank: function () {
      if (this.selectedEntity && this.selectedEntity.bankName) {
        let wireDetails = (this.selectedEntity.bankName) ? (this.selectedEntity.bankName || '') +
          '<br/>Account Holder: ' + (this.selectedEntity.accountHolder || '') +
          '<br/>Account No: ' + (this.selectedEntity.accountNo || '') +
          '<br/>Account Type: ' + (this.selectedEntity.accountType || '') +
          '<br/>Branch No: ' + (this.selectedEntity.branchNo || '') +
          '<br/>Branch Name: ' + (this.selectedEntity.branchName || '') +
          '<br/>Swift No/ABA: ' + (this.selectedEntity.swiftNo || '')
          : ''
        return wireDetails
      }
      return ''
    },
    showBrokerFee: function () {
      return this.isWholesalerPayeeType || this.isCustomerPayeeType
    },
    noteRequired: function () {
      return this.isAccountingRecon || this.isRetainDeposit
    },
    allPaymentTypes: function () {
      if (this.isBankPayeeType) {
        return this.definedTypes.paymentTypes.filter((x) => x.id === 0 || x.id === 4)
      } else if (this.isAccountingPayeeType) {
        return this.definedTypes.paymentTypes.filter((x) => x.id === 3)
      }
      return this.definedTypes.paymentTypes
    },
    allPayeeTypes: function () {
      return this.definedTypes.payeeTypes
    },
    availablePayeeTypes: function () {
      if (this.inventory.type !== 'TradeIn') return this.allPayeeTypes.filter((x) => x.id !== 6)
      return this.allPayeeTypes
    },
    selectedEntity: function () {
      if (this.selectedSupplier) {
        return this.selectedSupplier
      }
      if (this.selectedCustomer) {
        return this.selectedCustomer
      }
      if (this.selectedBank) {
        return this.selectedBank
      }
    },
    mode: function () {
      return this.currentPaymentRequestId !== '0' ? 'edit' : 'new'
    },
    isBankContactPersonSet: function () {
      if (!this.selectedEntity) return true
      return this.selectedEntity.firstName !== '' && this.selectedEntity.lastName !== ''
    },
    showGoodUntilDate: function () {
      if (!this.isBankPayeeType) return false
      if (this.inventory && this.inventory.goodUntilDate) {
        let currentDate = moment().format('YYYY/MM/DD')
        let goodUntilDate = moment(this.inventory.goodUntilDate).subtract(2, 'days').format('YYYY/MM/DD')
        return !moment(goodUntilDate).isAfter(currentDate)
      } else {
        return false
      }
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    isPaymentTypeDetailsValid: function () {
      let isNotValid = false
      let isValid = true

      // always return true when payment type not selected
      if (!this.selectedPaymentType) {
        return isValid
      }

      // always return true when payee not wholesale, Customer, Bank
      if (!this.isWholesalerPayeeType && !this.isCustomerPayeeType && !this.isBankPayeeType) {
        return isValid
      }

      // if payment type is wire, check if bank details is set for payee
      if (this.isWirePaymentType && this.selectedEntity) {
        if (this.selectedEntity.bankName && this.selectedEntity.accountHolder && this.selectedEntity.accountNo && this.selectedEntity.swiftNo) {
          return isValid
        } else {
          return isNotValid
        }
      }

      // if payment type is check, check address details is set for payee
      if (this.isCheckPaymentType && this.selectedEntity) {
        let applicableAddressToCheck = this.isBankPayeeType ? this.selectedEntity.fullPayoffAddress : this.selectedEntity.fullAddress
        if (applicableAddressToCheck && applicableAddressToCheck !== '') {
          return isValid
        } else {
          return isNotValid
        }
      }

      if (this.isBankPayeeType && this.selectedEntity) {
        if (this.isBankContactPersonSet) {
          return isValid
        } else {
          return isNotValid
        }
      }

      return isValid
    },
    launchEditCustomer: function () {
      return !this.isPaymentTypeDetailsValid && this.canLaunchPayeeEdit && this.isCustomerPayeeType
    },
    launchEditSupplier: function () {
      return !this.isPaymentTypeDetailsValid && this.canLaunchPayeeEdit && this.isWholesalerPayeeType
    },
    launchEditBank: function () {
      return !this.isPaymentTypeDetailsValid && this.canLaunchPayeeEdit && this.isBankPayeeType
    },
    isWirePaymentType: function () {
      return this.selectedPaymentType && this.selectedPaymentType.name === 'Wire'
    },
    isCheckPaymentType: function () {
      return this.selectedPaymentType && this.selectedPaymentType.name === 'Check'
    },
    isAutoFloorplan: function () {
      return this.selectedPaymentType && this.selectedPaymentType.altName === 'AutoFloorplan'
    },
    getPaymentTypeInvalidText: function () {
      if (this.isPaymentTypeDetailsValid) {
        return ''
      }

      if (this.isWirePaymentType) {
        return 'An issue was found with missing banking details for this payee. Please complete the payee banking details.'
      }

      if (this.isCheckPaymentType) {
        if (this.isBankPayeeType) return 'An issue was found with missing PayOff address details for this bank. Please add or complete the PayOff bank address details.'
        return 'An issue was found with missing address details for this payee. Please add or complete the payee address details.'
      }

      if (this.isBankPayeeType && !this.isBankContactPersonSet) {
        return 'An issue was found with missing contact person details on the selected bank. Please add or complete the contact details for this bank.'
      }
    },
    isWholesalerPayeeType: function () {
      return this.selectedEntityType && (this.selectedEntityType.name === 'Wholesaler' || this.selectedEntityType.altName === 'Wholesaler')
    },
    isCustomerPayeeType: function () {
      return this.selectedEntityType && (this.selectedEntityType.name === 'Customer' || this.selectedEntityType.altName === 'Customer')
    },
    isBankPayeeType: function () {
      return this.selectedEntityType && (this.selectedEntityType.name === 'PayOff' || this.selectedEntityType.altName === 'PayOff')
    },
    isAccountingRecon: function () {
      return this.selectedEntityType && this.selectedEntityType.altName === 'ApplyToAr'
    },
    isDealEquity: function () {
      return this.selectedEntityType && this.selectedEntityType.altName === 'DealEquity'
    },
    isRetainDeposit: function () {
      return this.selectedEntityType && this.selectedEntityType.altName === 'RetainAsDeposit'
    },
    isEntityTrader: function () {
      return this.isWholesalerPayeeType || this.isCustomerPayeeType
    },
    getEntityDefaultTab: function () {
      if (this.isWirePaymentType) {
        return 'Bank'
      }

      if (this.isCheckPaymentType) {
        return 'Address'
      }

      return ''
    },
    showLoader: function () {
      return this.isLoading || this.isSaving
    },
    isPaymentTypeEnabled: function () {
      return !this.readOnly
    },
    isAccountingPayeeType: function () {
      const accountingTypes = ['ApplyToAr', 'RetainAsDeposit', 'DealEquity']
      return this.selectedEntityType && (accountingTypes.includes(this.selectedEntityType.name) || accountingTypes.includes(this.selectedEntityType.altName))
    }
  },
  methods: {
    onFillPaymentAmount: function (fillableValue) {
      this.currentPaymentRequest.amount = fillableValue
    },
    validateBeforeSubmit () {
      let that = this
      this.$validator.validateAll('payment-request-form').then((result) => {
        if (result) {
          that.onSave()
        } else {
          that.$goToValidationError(that.$validator.errors.items[0].id)
        }
      })
    },
    clearErrors: function () {
      this.errors.clear()
    },
    onConfirmDelete: function () {
      this.isDeleteConfirmationActive = false
      this.$emit('delete-payment-request', this.currentPaymentRequest)
    },
    onDelete: function () {
      this.isDeleteConfirmationActive = true
    },
    onSave: function () {
      this.currentPaymentRequest.paymentType = this.selectedPaymentType.altName
      this.currentPaymentRequest.payeeType = this.selectedEntityType.altName
      this.currentPaymentRequest.note = this.selectedNote
      this.currentPaymentRequest.verified = true
      this.currentPaymentRequest.verifiedWire = true
      this.currentPaymentRequest.isBrokerFee = this.isBrokerFee
      this.currentPaymentRequest.paymentTypeInvalidText = this.getPaymentTypeInvalidText
      this.currentPaymentRequest.checkDeliveryType = this.selectedCheckDeliveryType ? this.selectedCheckDeliveryType.altName : ''

      if (this.isWholesalerPayeeType) {
        this.currentPaymentRequest.buyerId = this.selectedSupplier.id
        this.currentPaymentRequest.buyerName = this.selectedSupplier.name
        this.currentPaymentRequest.verified = this.selectedSupplier.verified
        // remove setting verifiedWire to false for now
        // this.currentPaymentRequest.verifiedWire = false
      } else if (this.isCustomerPayeeType) {
        this.currentPaymentRequest.buyerId = this.selectedCustomer.id
        this.currentPaymentRequest.buyerName = this.selectedCustomer.fullName
        this.currentPaymentRequest.verified = this.selectedCustomer.verified
      } else if (this.isBankPayeeType) {
        this.currentPaymentRequest.buyerId = this.selectedEntity.id
        this.currentPaymentRequest.buyerName = this.selectedEntity.name

        if (this.originalGoodUntilDate !== this.inventory.goodUntilDate || this.originalPayoffAmount !== this.currentPaymentRequest.amount) {
          this.inventory.payoffAmount = this.currentPaymentRequest.amount
          this.isSaving = true
          inventoryService.updatePayOffDetails(this.inventory).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              this.currentPaymentRequest.isPayoffDateExpired = false
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      } else if (this.isAccountingRecon || this.isDealEquity || this.isRetainDeposit) {
        this.currentPaymentRequest.buyerId = this.inventory.purchasedFromId
        this.currentPaymentRequest.buyerName = this.inventory.purchasedFromName
      }

      if (this.currentPaymentRequest.new === true) {
        this.currentPaymentRequest.new = false
        this.currentPaymentRequest.isVisuallyVerified = true
        this.$emit('save-new-payment-request', this.currentPaymentRequest)
      } else {
        this.$emit('save-edit-payment-request', this.currentPaymentRequest)
      }
    },
    onCancel: function () {
      this.$emit('cancel-payment-request', {})
    },
    clearCpoInfo: function () {
      if (this.selectedNote !== null && this.selectedNote.indexOf(this.cpoInfo) >= 0) {
        this.selectedNote = this.selectedNote.replace(this.cpoInfo, '')
        this.cpoInfo = ''
      }
    },
    addCpoDetails: function () {
      this.cpoInfo = '\nThis inventory item is CPO.'
      this.selectedNote += this.cpoInfo
    },
    createGenericAddress () {
      return {
        id: this.$uuid.v4(),
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: '',
        addressType: 'LeasePayoff'
      }
    },
    fetchCustomer: function (id) {
      this.isLoading = true
      customerService.single(id).then(response => {
        this.isLoading = false
        this.selectedCustomer = {
          ...response.customer
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    fetchSupplier: function (id) {
      this.isLoading = true
      supplierService.single(id).then(response => {
        this.isLoading = false
        this.selectedSupplier = {
          ...response.supplier
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    fetchBankInfo: function (id) {
      this.isLoading = true
      bankService.single(id).then(response => {
        this.isLoading = false
        this.selectedBank = {
          ...response.bank
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    setDefaultNoteContent: function () {
      if (this.isBrokerFee === true) {
        if (this.selectedNote !== null && this.selectedNote.indexOf(this.defaultBrokerNote) < 0) {
          let linebreak = (this.selectedNote.length > 0) ? '\n' : ''
          this.selectedNote = this.defaultBrokerNote + linebreak + this.selectedNote
        }
      }
      if (this.isBrokerFee === false) {
        if (this.selectedNote !== null && this.selectedNote.indexOf(this.defaultBrokerNote) >= 0) {
          this.selectedNote = this.selectedNote.replace(this.defaultBrokerNote + '\n', '')
          this.selectedNote = this.selectedNote.replace(this.defaultBrokerNote, '')
        }
      }

      if (this.isAccountingRecon) {
        this.selectedNote = `Dealer purchased a ${this.inventory.name}, stock no ${this.inventory.stockNo}, from Supplier/Customer ${this.inventory.purchasedFromName} on ${this.$options.filters.formatDateOnlyShort(this.inventory.createdAt)}. ${this.$formatCurrency(this.currentPaymentRequest.amount)} was not paid to client and held to apply to customers accounts receivable balance.`
      } else if (this.isRetainDeposit) {
        this.selectedNote = `Dealer purchased a ${this.inventory.name}, stock no ${this.inventory.stockNo}, from Supplier/Customer ${this.inventory.purchasedFromName} on ${this.$options.filters.formatDateOnlyShort(this.inventory.createdAt)}. ${this.$formatCurrency(this.currentPaymentRequest.amount)} was retained as a deposit for a future sales.`
      } else if (this.isDealEquity) {
        this.selectedNote = `Dealer purchased a ${this.inventory.name}, stock no ${this.inventory.stockNo} as a trade, from Supplier/Customer ${this.inventory.purchasedFromName} on ${this.$options.filters.formatDateOnlyShort(this.inventory.createdAt)}. ${this.$formatCurrency(this.currentPaymentRequest.amount)} is being used as deal equity on a current deal`
        if (this.inventory.tradeSourceName) {
          this.selectedNote += ` with stock number ${this.inventory.tradeSourceName}.`
        } else {
          this.selectedNote += '.'
        }
      } else if (this.isEntityTrader || this.isBankPayeeType) {
        if (this.isAutoFloorplan) {
          this.selectedNote = `Dealer purchased a ${this.inventory.name}, stock no ${this.inventory.stockNo}, from Supplier/Customer ${this.inventory.purchasedFromName} on ${this.$options.filters.formatDateOnlyShort(this.inventory.createdAt)}. ${this.$formatCurrency(this.currentPaymentRequest.amount)} was not paid to client and payment was made automatically by debiting our Bank Floorplan - Make Accounting Entry only.`
        }
      }
    },
    onCustomerSaved: function (editCustomer) {
      if (editCustomer) {
        this.selectedCustomer = {
          ...editCustomer
        }
      }
      this.canLaunchPayeeEdit = false
    },
    onSupplierSaved: function (editSupplier) {
      if (editSupplier) {
        this.selectedSupplier = {
          ...editSupplier
        }
      }
      this.canLaunchPayeeEdit = false
    },
    onBankSaved: function (editBank) {
      if (editBank) {
        this.selectedBank = {
          ...editBank
        }
        let applicableAddressToCheck = this.isBankPayeeType ? this.selectedEntity.fullPayoffAddress : this.selectedEntity.fullAddress
        this.currentPaymentRequest.hasPayoffAddress = applicableAddressToCheck && applicableAddressToCheck !== ''
      }
      this.canLaunchPayeeEdit = false
    },
    onPayeeCancelled: function () {
      this.canLaunchPayeeEdit = false
    }
  },
  mounted: function () {
    if (this.currentPaymentRequestId !== '0') {
      var that = this
      let found = this.forBatchPaymentRequest.paymentRequests.find(function (x) {
        return x.id === that.currentPaymentRequestId
      })

      if (found) {
        this.currentPaymentRequest = Object.assign({}, found)
        this.currentPaymentRequest.isVisuallyVerified = true
        this.selectedPaymentType = this.allPaymentTypes.find(x => x.altName === this.currentPaymentRequest.paymentType)
        this.selectedEntityType = this.allPayeeTypes.find(x => x.name === this.currentPaymentRequest.payeeType || x.altName === this.currentPaymentRequest.payeeType)
        this.selectedNote = this.currentPaymentRequest.note
        this.selectedCheckDeliveryType = this.currentPaymentRequest.checkDeliveryType ? this.definedTypes.checkDeliveryTypes.find(x => x.altName === this.currentPaymentRequest.checkDeliveryType) : null
        if (this.inventory && this.inventory.cpoStatus && this.inventory.cpoStatus === 'Cpo') {
          this.addCpoDetails()
        }
        this.isBrokerFee = false
        if (this.isWholesalerPayeeType) {
          this.isBrokerFee = this.currentPaymentRequest.isBrokerFee
          this.fetchSupplier(this.currentPaymentRequest.buyerId)
        } else if (this.isCustomerPayeeType) {
          this.isBrokerFee = this.currentPaymentRequest.isBrokerFee
          this.fetchCustomer(this.currentPaymentRequest.buyerId)
        } else if (this.isBankPayeeType) {
          this.originalGoodUntilDate = this.inventory.goodUntilDate
          this.fetchBankInfo(this.currentPaymentRequest.buyerId)
        }
      }
    } else if (this.inventory && this.inventory.cpoStatus && this.inventory.cpoStatus === 'Cpo') {
      this.addCpoDetails()
    }
  },
  watch: {
    'currentPaymentRequest.amount': function (newValue, oldValue) {
      this.setDefaultNoteContent()
    },
    selectedEntityType: function (newValue, oldValue) {
      if (newValue) {
        if (newValue.altName !== 'Customer' && newValue.altName !== 'Wholesaler' && this.isBrokerFee === true) {
          this.isBrokerFee = false
        }
        if (newValue.altName === 'ApplyToAr' || newValue.altName === 'RetainAsDeposit' || newValue.altName === 'DealEquity') {
          let accountingPaymentType = this.allPaymentTypes.find(x => x.id === 3)
          this.selectedPaymentType = accountingPaymentType
        } else {
          if (oldValue) {
            this.selectedPaymentType = null
          }
        }
        if (oldValue) {
          this.selectedNote = ''
          this.selectedSupplier = null
          this.selectedCustomer = null
          this.selectedBank = null
        }
        this.setDefaultNoteContent()
      }
    },
    selectedPaymentType: function (newValue, oldValue) {
      if (newValue) {
        if (oldValue) {
          this.selectedNote = ''
        }
        this.setDefaultNoteContent()
      }
    },
    isBrokerFee: function (newValue, oldValue) {
      if (newValue === true) {
        this.currentPaymentRequest.amount = this.inventory.commission
      }
      if (newValue === false) {
        if (this.currentPaymentRequest.amount === this.inventory.commission) {
          this.currentPaymentRequest.amount = ''
        }
      }
      this.setDefaultNoteContent()
    },
    selectedSupplier: function (newValue, oldValue) {
      if (newValue) {
        this.selectedCustomer = null
        this.selectedBank = null
        this.selectedPaymentType = this.currentPaymentRequest && this.currentPaymentRequest.paymentType !== '' ? this.allPaymentTypes.find(x => x.altName === this.currentPaymentRequest.paymentType) : this.allPaymentTypes.find(x => x.altName === newValue.defaultPaymentMethod)
      }
    },
    selectedCustomer: function (newValue, oldValue) {
      if (newValue) {
        this.selectedSupplier = null
        this.selectedBank = null
        this.selectedPaymentType = this.currentPaymentRequest && this.currentPaymentRequest.paymentType !== '' ? this.allPaymentTypes.find(x => x.altName === this.currentPaymentRequest.paymentType) : this.allPaymentTypes.find(x => x.altName === newValue.defaultPaymentMethod)
      }
    },
    selectedBank: function (newValue, oldValue) {
      if (newValue) {
        this.selectedSupplier = null
        this.selectedCustomer = null
        this.selectedPaymentType = this.currentPaymentRequest && this.currentPaymentRequest.paymentType !== '' ? this.allPaymentTypes.find(x => x.altName === this.currentPaymentRequest.paymentType) : this.allPaymentTypes.find(x => x.altName === 'Check')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .buttonStyle {
    font-family: "Roboto Regular", sans-serif;
    font-size: 0.75rem;
    padding: 0 1.5em;
    height: 3em;
  }
</style>

import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import baseService from './baseService'
import supplierData from './data/supplier'

const cachedAxios = setupCache(axios.create(), {
  ttl: 5000,
  methods: ['get'],
  cachePredicate: {
    statusCheck: (status) => status >= 200 && status < 400
  },
  staleIfError: false
})

const supplierService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(supplierData)
      } else {
        axios
          .post('/supplier/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (supplierId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(supplierData.all.find((x) => x.id === supplierId))
      } else {
        cachedAxios
          .get('/supplier/get?id=' + supplierId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (supplierModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var supplier = {
          id: 999,
          name: supplierModel.name,
          email: supplierModel.email,
          office: supplierModel.office,
          fax: supplierModel.fax,
          address1: supplierModel.address1,
          address2: supplierModel.address2,
          address3: supplierModel.address3,
          address4: supplierModel.address4,
          city: supplierModel.city,
          country: supplierModel.country,
          postalCode: supplierModel.postalCode,
          state: supplierModel.state,
          gpsAddress: supplierModel.gpsAddress,
          status: 'Active'
        }
        supplierData.all.push(supplier)
        resolve(supplier)
      } else {
        axios
          .post('/supplier/save', supplierModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (supplierModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var supplier = supplierData.all.find((x) => x.id === supplierModel.id)
        if (supplier) {
          supplier.name = supplierModel.name
          supplier.email = supplierModel.email
          supplier.office = supplierModel.office
          supplier.fax = supplierModel.fax
          supplier.address1 = supplierModel.address1
          supplier.address2 = supplierModel.address2
          supplier.address3 = supplierModel.address3
          supplier.address4 = supplierModel.address4
          supplier.city = supplierModel.city
          supplier.country = supplierModel.country
          supplier.postalCode = supplierModel.postalCode
          supplier.state = supplierModel.state
          supplier.gpsAddress = supplierModel.gpsAddress
          supplier.status = supplierModel.status
        }
        resolve(supplier)
      } else {
        axios
          .post('/supplier/update', supplierModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (supplierModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        supplierModels.forEach(element => {
          supplierData.all.remove((x) => x.id === element.id)
        })
        resolve(supplierData.all)
      } else {
        var ids = []
        supplierModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/supplier/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  merge (mergeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(supplierData.all)
      } else {
        axios
          .post('/supplier/merge', mergeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllForNotification (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(supplierData.all)
      } else {
        axios
          .post('/supplier/findAllForNotification?', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateIdentityVerificationApproval (supplierModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(supplierData.all)
      } else {
        axios
          .post('/supplier/updateIdentityVerificationApproval', supplierModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createIdentityVerificationRequest (supplierIdentityVerificationRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/supplier/createIdentityVerificationRequest', supplierIdentityVerificationRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  verifySupplier (supplierModel) {
    return new Promise((resolve, reject) => {
      axios
        .post('/supplier/verifySupplier', supplierModel, baseService.defaultConfig)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
    })
  },
  updateCertificationFiles (supplierModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(supplierData.all)
      } else {
        axios
          .post('/supplier/UpdateCertificationFiles', supplierModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getCertificationInfo (supplier) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get('/supplier/getCertificationInfo?id=' + supplier.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getReviewSuppliers (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(supplierData)
      } else {
        axios
          .post('/supplier/reviewList', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateSupplierNumber (supplierNumberModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/supplier/updatesuppliernumber', supplierNumberModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default supplierService

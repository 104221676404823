<template>
  <div class="ml-10">
    <span class="icon" @click="openValidatePinModal()" v-tooltip="{ content: label }">
      <i class="fal fa-lg clickable" :class="controlIconClasses"></i>
    </span>
    <portal to="global-modal-portal" v-if="isValidatePinActive" :disabled="!usePortal">
      <b-modal :active.sync="isValidatePinActive" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="min-width: 350px;">
          <header class="modal-card-head">
            <p class="modal-card-title">Validate Your Pin</p>
          </header>
          <section class="modal-card-body has-background-grey-light" style="padding:12px">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <form class="view-container box">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Pleas enter 6 digit pin<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control">
                      <input name="pin" data-vv-as="Pin" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('pin') }" type="password" placeholder="Please enter pin here..." v-model="pin">
                      <span v-show="errors.has('pin')" class="help is-danger">
                        <i v-show="errors.has('pin')" class="fas fa-exclamation-triangle"></i> {{ errors.first('pin') }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isLoading }"  @click="validatePin()">Validate</button>
            <button class="button is-danger" @click="closeValidatePinModal()">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>

import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import userService from '@/services/userService'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'UserPinControl',
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    value: {
      type: Boolean,
      default: false
    },
    useIcon: {
      type: String,
      default: 'fa-unlock'
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Click to verify pin'
    }
  },
  data () {
    return {
      isValidatePinActive: false,
      pin: '',
      serverErrors: [],
      serverErrorMessage: '',
      isLoading: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    controlIconClasses: function () {
      let result = {}
      result[this.useIcon] = true
      return result
    }
  },
  methods: {
    openValidatePinModal () {
      this.resetValues()
      this.isValidatePinActive = true
    },
    closeValidatePinModal () {
      this.resetValues()
      this.isValidatePinActive = false
    },
    validatePin: function () {
      let that = this
      this.isLoading = true
      this.serverErrorMessage = ''

      if (this.pin === '') {
        this.serverErrorMessage = 'Pin is required to unlock decrypted ssn.'
        return
      }

      if (this.pin !== '') {
        userService.verifyPin(this.pin).then(response => {
          that.isLoading = false
          if (response.result === true) {
            that.pin = ''
            that.$emit('on-pin-verified')
            that.closeValidatePinModal()
          } else {
            that.serverErrorMessage = 'You have entered an invalid pin.'
          }
        }).catch((error) => {
          that.isLoading = false
          that.serverErrorMessage = error.message + '. Please contact support for assistance.'
        })
      } else {
        this.serverErrorMessage = 'You have entered an invalid pin.'
      }

      this.isLoading = false
    },
    resetValues: function () {
      this.pin = ''
      this.serverErrorMessage = ''
    }
  },
  watch: {

  }
}

</script>

  <!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .modal-card-body {
    overflow:auto;
  }

  .modal-card {
    max-width: inherit !important;
  }
</style>

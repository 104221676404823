<template>
  <section>
    <div class="is-flex" :class="mainActionBtnContainerClass" v-if="showActionButton && crudMode === 'create' && !isDepositConverting">
      <div v-if="isWholesaleReceivableListMode && !showButtonType">
        <span class="list-icon clickable" v-tooltip="buildTitle()" @click.stop.prevent="wholeSalePrepareModal()" :disabled="disableSendWireDetailBtn" :class="getPaymentStatusClass()">
          <i :data-type="getDataTypeByPaymentStatus(getPaymentStatus)" class="fal fa-money-bill-wave fa-lg" :id="instanceId"></i>
        </span>
      </div>

      <payment-control-menu-dropdown v-if="!isWholesaleReceivableListMode && showButtonType !== 'squareterminal'"
        :dropdownClass="getDropdownClass"
        :paymentStatusClass="getPaymentStatusClass()"
        :title="buildTitle()"
        :disableSendWireDetailBtn="disableSendWireDetailBtn"
        :paymentStatusForDataType="getPaymentStatus"
        :dataType="getDataTypeByPaymentStatus(getPaymentStatus)"
        :dropDownInvoiceType="dropDownInvoiceType"
        :dropDownWorkOrderType="dropDownWorkOrderType"
        :currentRoService="currentRoService"
        :invoiceTerminalText="invoiceTerminalText"
        :workOrderTerminalText="workOrderTerminalText"
        :isPartsDeposit="isPartsDeposit"
        :showRoInvoiceTerminalMenuItem="showRoInvoiceTerminalMenuItem"
        :showRoWorkOrderTerminalMenuItem="showRoWorkOrderTerminalMenuItem"
        :showPartsTerminalMenuItem="showPartsTerminalMenuItem"
        :dropDownPartsInvoiceType="dropDownPartsInvoiceType"
        :currentParts="currentParts"
        :partsTerminalText="partsTerminalText"
        v-on:on-dropdown-item-clicked="onDropdownItemClick">
      </payment-control-menu-dropdown>

      <div class="is-flex" v-if="showButtonType === 'squareterminal' && printRequest">
        <payment-control-menu-dropdown v-if="(printRequest.paymentRequired && !printRequest.isPaid) || (printRequest.signingRequired && !printRequest.isSigned)"
          :dropdownClass="getDropdownClass"
          :paymentStatusClass="getPaymentStatusClass()"
          :title="buildTitle()"
          :disableSendWireDetailBtn="disableSendWireDetailBtn"
          :paymentStatusForDataType="getPaymentStatus"
          :dataType="getDataTypeByPaymentStatus(getPaymentStatus)"
          :dropDownInvoiceType="dropDownInvoiceType"
          :dropDownWorkOrderType="dropDownWorkOrderType"
          :currentRoService="currentRoService"
          :invoiceTerminalText="invoiceTerminalText"
          :workOrderTerminalText="workOrderTerminalText"
          :isPartsDeposit="isPartsDeposit"
          :showRoInvoiceTerminalMenuItem="showRoInvoiceTerminalMenuItem"
          :showRoWorkOrderTerminalMenuItem="showRoWorkOrderTerminalMenuItem"
          :showPartsTerminalMenuItem="showPartsTerminalMenuItem"
          :dropDownPartsInvoiceType="dropDownPartsInvoiceType"
          :currentParts="currentParts"
          :partsTerminalText="partsTerminalText"
          v-on:on-dropdown-item-clicked="onDropdownItemClick">
        </payment-control-menu-dropdown>

        <button v-if="printRequest.paymentRequired && !printRequest.isPaid"
          class="button is-pulled-right"
          @click.stop.prevent="onDropdownItemClick({type: 'squareterminal', printRequestId: selectedPrintRequestId})"
          :class="getPaymentStatusClass()">Sign & Pay - Terminal
        </button>

        <button v-else-if="printRequest.signingRequired && !printRequest.isSigned"
          class="button is-warning is-pulled-right"
          @click.stop.prevent="onDropdownItemClick({type: 'squareterminalSign', printRequestId: selectedPrintRequestId})"
          :class="getPaymentStatusClass()">Sign Only - Terminal
        </button>
      </div>
    </div>

    <portal to="global-modal-portal" v-if="paymentControlActive" :order="1" :disabled="usePortal" :key="instanceId">
      <b-modal :key="instanceId" :active.sync="paymentControlActive" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card">
          <header class="modal-card-head is-flex">
          <div class="is-flex" style="width: 100%">
            <div class="modal-card-title">{{headerTitle}}</div>
            <div v-if="isSquarePayment" class="is-pulled-right">
              <i :class="getSquareConnectionIconClass" :title="getSquareConnectionIconTitle"></i>
              <span class="tag" :class="getSquareConnectionClass">{{getSquareConnectionTitle}}</span>
            </div>
          </div>
        </header>
          <section class="modal-card-body view-container " style="padding: 0 0px">
            <div class="box" v-show="serverErrors.length > 0 || serverErrorMessage !== ''">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            </div>
            <b-tabs size="is-small" :animated="false" type="is-toggle" v-model="tabIndex">
              <b-tab-item label="Historical Payment Requests" icon-pack="fas" icon="fas fa-percent fa-sm">
                <section style="padding: 12px;">
                  <div>
                    <div class="columns">
                      <div class="column">
                        <div class="notification is-info is-flex">
                          <div>
                            <i class="fas fa-info-circle is-info fa-2x"></i>
                          </div>
                          <div class="ml-10">
                            Previously requested payments are listed below. Please review the historical payments, to prevent the creation of duplicate request, before proceeding to the next step.
                          </div>
                        </div>
                      </div>
                      <div class="column" v-if="hasAmount">
                        <div class="level-item has-text-centered has-background-success has-text-white box" style="height: 100%;">
                          <div>
                            <p class="heading">Requested Amount</p>
                            <p class="title has-text-white">
                              {{amount | currency}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <br/>
                    <div v-if="isRoServiceDeposit">
                      <b-switch type="is-success" v-model="showOnlyCurrentRo">Show only payments for current R.O. service.</b-switch>
                    </div>
                    <br/>
                    <table class="table is-bordered is-hoverable is-fullwidth">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Status</th>
                          <th>Type</th>
                          <th>Amount</th>
                          <th>Date</th>
                          <th>Stock No</th>
                          <th>RO Number</th>
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tr v-for="(payment, index) in matchingPaymentRequestList" :key="payment.id">
                        <td>{{index + 1}}</td>
                        <td>
                          {{getMatchingPaymentStatus(payment.paymentStatus)}}
                        </td>
                        <td>{{getMatchingPaymentType(payment.paymentType) }}</td>
                        <td>{{payment.amount | currency}}</td>
                        <td>{{payment.createdAt | formatDateOnly}}</td>
                        <td>{{payment.stockNo || '-' }}</td>
                        <td>{{payment.roNumber || '-' }}</td>
                        <td>
                          <span class="list-icon pointer clickable" title="Click to resend this deposit request" v-if="canResendDepositRequest(payment)" @click.stop.prevent="resendPaymentTrackingWireRequest(payment)">
                            <i class="fal fa-envelope fa-lg" ></i>
                          </span>
                          <span class="list-icon pointer clickable" title="Click to cancel this deposit request" v-if="canCancelPaymentRequest(payment)" @click.stop.prevent="onLaunchStatusChangeModal(payment.id, 'Cancelled', payment.amount, payment.paidAmount)">
                            <i class="fal fa-ban fa-lg"></i>
                          </span>
                        </td>
                      </tr>
                      <tr v-if="matchingPaymentRequestList.length === 0">
                        <td colspan="8">None specified</td>
                      </tr>
                    </table>
                  </div>
                </section>
              </b-tab-item>
              <b-tab-item label="Payment Request Information" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                <form data-vv-scope="recipient-form" class="view-container" style="margin: 1em;">
                  <div v-if="!isSquareTerminalPayment" class="columns is-multiline">
                    <div class="column is-12" v-if="showCustomerNameSelector">
                      <div class="field">
                        <button-list-selector
                          :useValidator="thisValidator"
                          validateAs="name"
                          :required="true"
                          label="Name"
                          v-model="selectedName"
                          :availableValues="customerNames"
                          :allowDeselection="false"
                          scope="recipient-form">
                        </button-list-selector>
                      </div>
                    </div>
                    <div class="column is-12" v-if="showPaymentStatusOptions">
                      <button-list-selector
                        v-model="selectedPaymentType"
                        label="Select payment status"
                        :useValidator="thisValidator"
                        :required="true"
                        :availableValues="checkAvailablePaymentStatus"
                        :itemLabel="'descriptiveName'"
                        :allowDeselection="false"
                        scope="recipient-form">
                      </button-list-selector>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label class="label">Recipients<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <multiselect
                          :multiple="false"
                          v-validate="{'required': true}"
                          data-vv-scope="recipient-form"
                          data-vv-as="recipients"
                          data-vv-validate-on="input|close"
                          :class="{'is-danger': errors.has('recipient-form.recipients') }"
                          @tag="onRecipientAdded"
                          name="recipients"
                          label="fullNameEmail"
                          trackBy="id"
                          v-model="selectedRecipients"
                          :options="recipients"
                          :allowEmpty="false"
                          :searchable="true"
                          placeholder="please click on name below and complete either email and/or mobile"
                          :loading="isLoading"
                          :internal-search="true"
                          :options-limit="300"
                          :disabled="true"
                          :hideSelected="false">
                        </multiselect>
                        <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                      </div>
                      <div class="field is-pulled-right" v-if="!isRoServiceDeposit && !isPartsDeposit">
                        <a class="is-link" @click="onEditPurchaser(getPurchaserId)" style="margin-left:10px;">
                          {{getPurchaserContactName}}
                        </a>
                        <a v-if="getHasCoApplicant && !getIsSpouseCoApplicant" class="is-link" @click="(getPurchaserContactEmail !== '' || getPurchaserContactMobile !== '')(getCoCustomerId)" style="margin-left:10px;">
                          {{getCoCustomerName}}
                        </a>
                      </div>
                    </div>

                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Amount <b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control has-icons-left">
                          <money name="amount" data-vv-as="amount" v-validate="{'required': true, 'decimal':2, 'min_value': 2, 'max_value': squarePaymentLimit}" :class="{'input': true, 'is-danger': errors.has('recipient-form.amount') }" type="text" placeholder="0000.00" v-model="amount" v-bind="$globalMoneyFormat"></money>
                          <span v-show="errors.has('recipient-form.amount')" class="help is-danger">
                            <i v-show="errors.has('recipient-form.amount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.amount') }}
                          </span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6" v-if="isCashPayment || isCheckPayment || isOtherPayment">
                      <div class="field">
                        <label class="label">Where did the money come from<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control">
                          <input name="payer" type="text" style="width:100%" v-validate="'required'" validateAs="Payer Name" :class="{'input': true, 'is-danger': errors.has('payer') }" placeholder="Payee Name" v-model="payerName" :required="true">
                          <span v-show="errors.has('payer')" class="help is-danger">
                            <i v-show="errors.has('payer')" class="fas fa-exclamation-triangle"></i> {{ errors.first('payer') }}
                          </span>
                          <pop-fill-control title="Click to autofill where money came from" :fillableValue="getExpectedWhereDidMoneyComeFrom" :onFillAction="onPopFillPayerName" v-model="payerName" :doComparison="true"></pop-fill-control>
                        </div>
                      </div>
                    </div>

                    <div class="column is-6" v-if="showMismatchObjects && (isCashPayment || isCheckPayment || isOtherPayment)">
                      <switch-selector type="is-info" label="By overriding the name match you accept responsibility for the capture of this deposit." v-model="overrideName"></switch-selector>
                    </div>
                    <div class="column is-6" v-if="showMismatchObjects && (isCashPayment || isCheckPayment || isOtherPayment)">
                      <user-selector scope="recipient-form" :useValidator="thisValidator" v-model="selectedOverridingManager" label="Which manager gave you the override" data-vv-as="overriding manager" :selectedDealer="this.currentDealer" :required="true"></user-selector>
                    </div>

                    <div class="column is-6" v-if="isCashPayment || isCheckPayment || isOtherPayment">
                      <user-selector scope="recipient-form" :useValidator="thisValidator" v-model="selectedDepositAcceptedBy" label="Who accepted the deposit?" data-vv-as="who accepted the deposit" :selectedDealer="this.currentDealer" :required="true"></user-selector>
                      <pop-fill-control title="Click to autofill who accepted the deposit" :fillableValue="getExpectedWhoAcceptedDeposit" :onFillAction="onPopFillUserName" v-model="getExpectedWhoAcceptedDeposit" :doComparison="false"></pop-fill-control>
                    </div>

                    <div class="column is-12" v-if="isCashPayment || isCheckPayment || isOtherPayment">
                      <file-selector
                      v-model="files"
                      label="Deposit Receipt"
                      :required="false"
                      :multiple="false"
                      :isBoxed="false"
                      :usePortalViewer="false"
                      @uploadStarted="uploadStarted"
                      @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>

                    <div class="column is-6" v-if="isSquarePayment">
                      <div class="field">
                        <square-location-selector
                          v-on:has-square-location-error="onSquareLocationError"
                          v-model="selectedSquareLocation"
                          :useValidator="this.$validator"
                          scope="recipient-form"
                          :isDisabled=true
                          :required=true
                          :isRoInvoice="isRoServiceDeposit">
                        </square-location-selector>
                      </div>
                    </div>

                    <div class="column is-6" v-if="isSquarePayment">
                      <div class="field">
                        <label class="label">Invoice Title<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control">
                          <input name="invoiceTitle" data-vv-as="invoice title" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('recipient-form.invoiceTitle') }" type="text" v-model="invoiceTitle">
                          <span v-show="errors.has('recipient-form.invoiceTitle')" class="help is-danger">
                            <i v-show="errors.has('recipient-form.invoiceTitle')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.invoiceTitle') }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="column is-6" v-if="isSquarePayment">
                      <div class="field">
                        <label class="label">Item<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <multiselect
                          :multiple="false"
                          v-validate="{'required': true}"
                          data-vv-scope="recipient-form"
                          data-vv-as="item"
                          data-vv-validate-on="input|close"
                          :class="{'is-danger': errors.has('recipient-form.selectedInvoiceItem') }"
                          name="selectedInvoiceItem"
                          label="name"
                          trackBy="id"
                          v-model="selectedInvoiceItem"
                          :options="squareItems"
                          :allowEmpty="false"
                          :searchable="true"
                          placeholder="select invoice item"
                          :loading="isLoading"
                          :internal-search="true"
                          :options-limit="300"
                          :hideSelected="true">
                        </multiselect>
                        <span v-show="errors.has('recipient-form.selectedInvoiceItem')" class="help is-danger"><i v-show="errors.has('recipient-form.selectedInvoiceItem')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.selectedInvoiceItem') }}</span>
                      </div>
                    </div>

                    <div class="column is-12" v-if="canShowTaxMultiSelect">
                      <div class="field">
                        <label class="label">Taxes</label>
                        <multiselect
                          :multiple="true"
                          data-vv-scope="recipient-form"
                          name="selectedInvoiceTaxes"
                          label="name"
                          trackBy="id"
                          v-model="selectedInvoiceTaxes"
                          :options="squareTaxes"
                          :allowEmpty="true"
                          :searchable="true"
                          placeholder="select tax item"
                          :loading="isLoading"
                          :internal-search="true"
                          :options-limit="300">
                        </multiselect>
                        <span v-show="errors.has('recipient-form.selectedInvoiceTaxes')" class="help is-danger"><i v-show="errors.has('recipient-form.selectedInvoiceTaxes')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.selectedInvoiceTaxes') }}</span>
                      </div>
                    </div>
                    <div class="column is-12" v-if="isSquarePayment">
                      <div class="field">
                        <switch-selector type="is-info" label="Allow Square to send invoice overdue email 3 days after requested deposit" v-model="setReminder"></switch-selector>
                      </div>
                    </div>
                    <div class="column is-12">
                      <note-capture
                        :required="noteRequired"
                        v-model="notes"
                        :startWith="buildDefaultNote()"
                        label="Deposit Description"
                        validateAs="deposit description"
                        :scope="'recipient-form'"
                        :useValidator="thisValidator">
                      </note-capture>
                     <!-- <span v-show="showNoteRequired || noteRequired" class="help is-danger"><i v-show="showNoteRequired || noteRequired" class="fas fa-exclamation-triangle"></i> The payment description field is required. </span>-->
                    </div>
                    <div class="column is-12" v-if="isWirePayment">
                      <div class="field" v-if="isWholesaleReceivableListMode">
                        <label class="label">Do not send notification and save wire and attachment files only?</label>
                        <div class="control">
                            <switch-selector type="is-info" v-model="wireSaveFileOnly"></switch-selector>
                        </div>
                      </div>
                      <div class="field">
                        <label class="label">Any Supporting Documentation?</label>
                        <div class="control">
                            <switch-selector type="is-info" v-model="hasWireAttachments"></switch-selector>
                        </div>
                          <label class="label" v-if="isWholesale"><br>Please note: The signed buyers order will be automatically attached to the outbound communication.</label>
                      </div>
                      <div class="columns deal-attachments" v-if="hasWireAttachments">
                        <file-selector
                        v-model="wirePdfFiles"
                        label="Select Files"
                        :required="false"
                        :multiple="true"
                        :isBoxed="false"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                        </file-selector>
                      </div>
                    </div>
                  </div>

                  <square-terminal-checkout-control v-if="isSquareTerminalPayment"
                    v-model="squareTerminalCheckOut"
                    :useValidator="this.$validator"
                    scope="recipient-form">
                  </square-terminal-checkout-control>

                </form>
              </b-tab-item>
            </b-tabs>
          </section>
          <footer class="modal-card-foot">
            <div class="columns is-multiline" style="width: 100%;">
              <div class="column is-flex" :class="getFooter1stColumnSize" style="gap: 5px; flex-wrap: wrap;">
                <button v-if="tabIndex === 0" class="button is-warning" :class="{'is-loading': isSaving}" @click="next()" type="button">Next</button>
                <button v-if="tabIndex === 1" class="button is-warning" :class="{'is-loading': isSaving}" @click="previous()" type="button">Previous</button>
                <button v-if="tabIndex === 1" :disabled="disableSave" class="button is-primary" :class="{'is-loading': isSaving}" @click="onConfirmQueueNotification()" type="button">{{saveBtnTitle}}</button>
              </div>
              <div v-if="isRoServiceDeposit" class="column" :class="getFooter2ndColumnSize">
                <div class="is-flex is-align-content-center is-size-7 has-text-info ml-10" style="gap: 5px; flex-wrap: wrap;">
                  <span class="tag is-dark" v-if="roNum !== ''">{{ roNum }}</span>
                  <span class="tag is-dark" v-if="roCustomer !== ''">{{ roCustomer }}</span>
                  <span class="tag is-dark" v-if="roVin !== ''">{{ roVin }}</span>
                  <span class="tag is-dark" v-if="roVehicleInfo !== ''">{{ roVehicleInfo }}</span>
                </div>
              </div>
              <div v-if="isPartsDeposit" class="column" :class="getFooter2ndColumnSize">
                <div class="is-flex is-align-content-center is-size-7 has-text-info ml-10" style="gap: 5px; flex-wrap: wrap;">
                  <span class="tag is-dark" v-if="partsInvoiceNo !== ''">{{ partsInvoiceNo }}</span>
                  <span class="tag is-dark" v-if="getPurchaserContactName !== ''">{{ getPurchaserContactName }}</span>
                </div>
              </div>
              <div class="column">
                <button class="button is-danger is-pulled-right" @click="onClose(false)" type="button">Cancel</button>
              </div>
            </div>
          </footer>
        </div>
      </b-modal>
    </portal>

    <deal-existing-component
      :usePortal="true"
      :key="dealExistingComponent"
      :ref="dealExistingComponent"
      forType="deposit"
      v-model="selectedBuyerDeals"
      v-on:on-selected-deal="onSelectDeal"
      v-on:on-create-new="openModal"
      v-on:on-cancel="onClose">
    </deal-existing-component>

    <portal to="global-modal-portal" v-if="editCustomerActive" :disabled="usePortal">
      <b-modal :active.sync="editCustomerActive" scroll="keep" :has-modal-card="true" id="fulfill-for-deposit-capture-modal">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
          </header>
          <section class="modal-card-body">
            <edit-customer v-if="isRetail && !isPartsDeposit && !isRoServiceDeposit" :customerId="getPurchaserId" mode="modal" actionPortal="modal-footer" headerPortal="modal-header" v-on:on-save="onCustomerSaved" v-on:on-cancel="onPurchaserCancelled" :fulfillForDepositCaptureMode="fulfillForDepositCaptureMode"></edit-customer>
            <edit-parts-customer v-if="isPartsDeposit" :customerId="getPurchaserId" mode="modal" actionPortal="modal-footer" headerPortal="modal-header" v-on:on-save="onPartsCustomerSaved" v-on:on-cancel="onPurchaserCancelled"></edit-parts-customer>
            <edit-ro-service-customer v-if="isRoServiceDeposit" :customerId="getPurchaserId" mode="modal" actionPortal="modal-footer" headerPortal="modal-header" v-on:on-save="onRoServiceCustomerSaved" v-on:on-cancel="onPurchaserCancelled"></edit-ro-service-customer>
            <edit-supplier v-if="isWholesale" :supplierId="getPurchaserId" mode="modal" actionPortal="modal-footer" headerPortal="modal-header" v-on:on-save="onSupplierSaved" v-on:on-cancel="onPurchaserCancelled" :fulfillForDepositCaptureMode="fulfillForDepositCaptureMode"></edit-supplier>
          </section>
          <footer class="modal-card-foot">
            <portal-target name="modal-footer" class="actions" slim/>
        </footer>
        </div>
      </b-modal>
    </portal>

    <portal to="global-modal-portal" v-if="confirmStatusChangeActive" :order="3" :disabled="usePortal">
      <b-modal :active.sync="confirmStatusChangeActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">Change Deposit Status 1</p>
          </header>
          <section class="modal-status-body">
            <div class="block has-background-white p-30">
              <div class="notification is-flex is-warning">
                <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
                <span class="ml-10">
                  Do you want to change the status of this deposit request to {{changeStatusTo}}?
                </span>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmStatusChange(true)" type="button">Yes</button>
            <button class="button is-danger" @click="onConfirmStatusChange(false)" type="button">No</button>
          </footer>
        </div>
      </b-modal>
    </portal>

    <portal to="global-modal-portal" v-if="confirmBatchCancelActive" :order="3" :disabled="usePortal">
      <b-modal :active.sync="confirmBatchCancelActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">Cancel All Existing Payment Requests</p>
          </header>
          <section class="modal-status-body">
            <div class="notification is-flex is-warning">
              <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
              <span class="ml-10">
                Are you sure you wish to cancel all existing payment requests?
              </span>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmCancelAllExistingPaymentRequests(true)" type="button">Yes</button>
            <button class="button is-danger" @click="onConfirmCancelAllExistingPaymentRequests(false)" type="button">No</button>
          </footer>
        </div>
      </b-modal>
    </portal>

    <portal to="global-modal-portal" v-if="isDuplicatePaymentRequestActive" :order="2" :disabled="usePortal" :key="'duplicate-payment-' + instanceId">
      <b-modal :key="'duplicate-payment-' + instanceId" :active.sync="isDuplicatePaymentRequestActive" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card" style="width: auto; background-color:#fff;">
          <header class="modal-card-head">
              <p class="modal-card-title">Duplicate Payment Request Detected</p>
              <button class="button is-danger is-small is-pulled-right" @click="onClose(false)" type="button">Cancel</button>
          </header>
          <section class="modal-card-body duplicate-payment-content">
            <div class="notification is-flex is-warning">
              <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
              <span class="ml-10">
                There are existing payment requests that match your current request. Please review the list below and select the appropriate action for each item. You can cancel each existing payment or send
                a reminder to the customer for payment.
              </span>
            </div>
            <table class="table is-bordered is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Status</th>
                  <th>Type</th>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Stock No</th>
                  <th>RO Number</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tr v-for="(payment, index) in matchingPaymentRequestList" :key="payment.id">
                <td>{{index + 1}}</td>
                <td>
                  {{getMatchingPaymentStatus(payment.paymentStatus)}}
                </td>
                <td>{{getMatchingPaymentType(payment.paymentType)}}</td>
                <td>{{payment.amount | currency}}</td>
                <td>{{payment.createdAt | formatDateOnly}}</td>
                <td>{{payment.stockNo || '-' }}</td>
                <td>{{payment.roNumber || '-' }}</td>
                <td>
                  <span class="list-icon pointer clickable" title="Click to resend this deposit request" v-if="canResendDepositRequest(payment)" @click.stop.prevent="resendPaymentTrackingWireRequest(payment)">
                    <i class="fal fa-envelope fa-lg" ></i>
                  </span>
                  <span class="list-icon pointer clickable" title="Click to cancel this deposit request" v-if="canCancelPaymentRequest(payment)" @click.stop.prevent="onLaunchStatusChangeModal(payment.id, 'Cancelled', payment.amount, payment.paidAmount)">
                    <i class="fal fa-ban fa-lg"></i>
                  </span>
                </td>
              </tr>
              <tr v-if="matchingPaymentRequestList.length === 0">
                <td colspan="8">None specified</td>
              </tr>
            </table>
            <b-loading :is-full-page="true" :active.sync="showLoader" :canCancel="false"></b-loading>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onCancelExistingAndConfirmDuplicatePaymentRequest()" type="button">Cancel existing requests, send a new one for {{amount | currency}}</button>
            <button class="button is-primary" @click="onConfirmDuplicatePaymentRequest()" type="button">Send additional request for {{amount | currency}}</button>
            <button class="button is-primary" @click="onSendReminderForExistingPaymentRequests()" type="button">Send reminder for unpaid balances above</button>
          </footer>
        </div>
      </b-modal>
    </portal>

    <mark-deposit-paid
      ref="markDepositPaid"
      v-if="isDepositConverting"
      :key="instanceId"
      :paymentTracking="currentPaymentTracking"
      :isListMode="false"
      :isMarkPaidFromNotYetPaidMode="true"
      :usePortal="false"
      :showActionButton="false">
    </mark-deposit-paid>

    <b-loading :is-full-page="true" :active.sync="showLoader" :canCancel="false"></b-loading>
  </section>
</template>
<script>

import wireDetailAuditService from '@/services/wireDetailAuditService'
import switchSelector from '@/components/generic/SwitchSelector'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import utilitiesMixin from '@/mixins/generic/utilities'
import pdfSelector from '@/components/generic/FileSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import editCustomer from '@/components/configuration/customers/EditCustomer'
import editSupplier from '@/components/configuration/suppliers/EditSupplier'
import squareService from '@/services/squareService'
import paymentTrackingService from '@/services/paymentTrackingService'
import squareLocationSelector from '@/components/generic/SquareLocationSelector'
import dealService from '@/services/dealService'
import PopFillControl from '@/components/generic/PopFillControl'
import UserSelector from '@/components/generic/UserSelector'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import roServiceService from '@/services/roServiceService'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import eventBus from '@/eventBus'
import NoteCapture from '@/components/generic/NoteCapture'
import DealExistingComponent from '@/components/generic/sales/DealExistingComponent.vue'
import partsService from '@/services/partsService'
import PartsCustomerControl from '@/components/generic/PartsCustomerControl'
import EditRoServiceCustomer from '@/components/roservice/EditRoServiceCustomer'
import customerService from '@/services/customerService'
import supplierService from '@/services/supplierService'
import MarkDepositPaidControl from '@/components/accounting/MarkDepositPaidControl'
import squareTerminalCheckoutControl from '@/components/generic/SquareTerminalCheckoutControl'
import _ from 'lodash'
import paymentControlMenuDropdown from '@/components/generic/PaymentControlMenuDropdown'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'PaymentControl',
  mixins: [utilitiesMixin, validateBeforeSubmitMixin, dealInventoryStyleClasses],
  components: {
    'file-selector': pdfSelector,
    'switch-selector': switchSelector,
    'error-display-component': ErrorDisplayComponent,
    'edit-customer': editCustomer,
    'edit-supplier': editSupplier,
    'square-location-selector': squareLocationSelector,
    'pop-fill-control': PopFillControl,
    'user-selector': UserSelector,
    'button-list-selector': ButtonListSelector,
    'note-capture': NoteCapture,
    'deal-existing-component': DealExistingComponent,
    'edit-parts-customer': PartsCustomerControl,
    'edit-ro-service-customer': EditRoServiceCustomer,
    'mark-deposit-paid': MarkDepositPaidControl,
    'square-terminal-checkout-control': squareTerminalCheckoutControl,
    'payment-control-menu-dropdown': paymentControlMenuDropdown
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    crudMode: {
      type: String,
      default: 'create'
    },
    viewMode: {
      type: String,
      default: 'quickview'
    },
    currentDeal: {
      type: Object,
      default: null
    },
    purchaser: {
      type: Object,
      default: null
    },
    isWholesaleProp: {
      type: Boolean,
      default: false
    },
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    currentRoService: {
      type: Object,
      default: null
    },
    roServiceNote: {
      type: String,
      default: ''
    },
    defaultNote: {
      type: String,
      default: ''
    },
    currentDealId: {
      type: Number,
      default: null
    },
    isWholesaleReceivableListMode: {
      type: Boolean,
      default: false
    },
    listItemDealInfo: {
      type: Object,
      default: null
    },
    wholesaleReceivableDealHasPayments: {
      type: Boolean,
      default: false
    },
    currentParts: {
      type: Object,
      default: null
    },
    usePortal: {
      type: Boolean,
      default: false
    },
    convertTo: {
      type: String,
      default: ''
    },
    isDepositConverting: {
      type: Boolean,
      default: false
    },
    showActionButton: {
      type: Boolean,
      default: true
    },
    noteRequired: {
      type: Boolean,
      default: true
    },
    slideDropdownUp: {
      type: Boolean,
      default: false
    },
    showButtonType: {
      type: String,
      default: undefined
    },
    printRequestIdProp: {
      type: Number,
      default: 0
    },
    printRequest: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      currentPaymentTracking: this.value,
      deal: this.currentDeal,
      selectedRecipients: [],
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      recipients: [],
      isLoading: false,
      amount: 0,
      hasWireAttachments: false,
      wirePdfFiles: [],
      paymentControlActive: false,
      editCustomerActive: false,
      paymentType: '',
      invoiceTitle: '',
      selectedSquareLocation: null,
      squareConnectionStatus: {
        isLoading: false,
        serverErrorMessage: ''
      },
      squareInvoiceDownload: null,
      invoiceVersionNo: '',
      sqInvoiceId: '',
      sqInvoicePaymentRequestId: '',
      squareOrderDownload: null,
      orderLineItemUid: '',
      squareCatalogDownload: [],
      selectedInvoiceItem: null,
      selectedInvoiceTaxes: [],
      customer: this.purchaser ? this.purchaser : this.currentDeal && this.currentDeal.customer ? this.currentDeal.customer : null,
      supplier: this.purchaser,
      setReminder: true,
      payerName: '',
      isCustomerMismatch: true,
      overrideName: false,
      selectedOverridingManager: null,
      selectedDepositAcceptedBy: null,
      files: [],
      selectedName: null,
      notes: [],
      selectedBuyerDeals: [],
      matchingPaymentRequestList: [],
      currentWireDetailAuditSendModel: null,
      currentInvoiceModel: null,
      roServiceDepositModelToSave: null,
      confirmStatusChangeActive: false,
      confirmBatchCancelActive: false,
      changeStatusId: null,
      changeStatusTo: null,
      tabIndex: 0,
      historicalPaymentsReviewed: false,
      isUploadingFile: false,
      fileUploadCount: 0,
      showLoader: false,
      wireSaveFileOnly: false,
      isDuplicatePaymentRequestActive: false,
      selectedPaymentType: null,
      squareTerminalCheckOut: null,
      showOnlyCurrentRo: false,
      initialMatchingPaymentRequestList: [],
      signOnly: false,
      dropDownSelectedPrintRequestId: 0
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    dealId: function () {
      return (this.deal) ? this.deal.id : 0
    },
    humanizedHasWireAttachments: function () {
      return this.hasWireAttachments ? 'Yes' : 'No'
    },
    disableSendWireDetailBtn: function () {
      return this.currentDealer.wireInfo === ''
    },
    isWholesale: function () {
      return this.deal ? this.deal.saleType === 'Wholesale' : this.isWholesaleProp
    },
    isRetail: function () {
      return !this.isWholesale
    },
    headerTitle: function () {
      if (this.isWirePayment) {
        return 'Wire Deposit Request'
      }

      if (this.isSquarePayment) {
        return 'Square Invoice Deposit Request'
      }

      if (this.isCashPayment) {
        return 'Cash Deposit Request'
      }

      if (this.isCheckPayment) {
        return 'Check Deposit Request'
      }

      if (this.isOtherPayment) {
        return 'Other Deposit Request'
      }

      if (this.isSquareTerminalPayment) {
        return 'Square Terminal Checkout Deposit Request'
      }

      return ''
    },
    isWirePayment: function () {
      return this.paymentType === 'wire'
    },
    isSquarePayment: function () {
      return this.paymentType === 'square'
    },
    isCashPayment: function () {
      return this.paymentType === 'cash'
    },
    isCheckPayment: function () {
      return this.paymentType === 'check'
    },
    isOtherPayment: function () {
      return this.paymentType === 'other'
    },
    isSquareTerminalPayment: function () {
      return this.paymentType === 'squareterminal'
    },
    soldItems: function () {
      return (this.deal && this.deal.items) ? this.deal.items.filter((x) => x.dealItemType === 'Sold') : []
    },
    firstSoldItemInventory: function () {
      return this.soldItems.length > 0 ? this.soldItems[0].forInventory : null
    },
    getSquareConnectionIconClass: function () {
      return this.hasSquareLoadingError ? 'fal fa-exclamation-square has-text-danger fa-lg"' : 'fal fa-check-square has-text-success fa-lg"'
    },
    getSquareConnectionIconTitle: function () {
      return this.hasSquareLoadingError ? 'Square error: ' + this.getSquareErrorMessage : 'Square connected'
    },
    getSquareConnectionTitle: function () {
      return this.hasSquareLoadingError ? 'Square connection error' : 'Square connected'
    },
    getSquareConnectionClass: function () {
      return this.hasSquareLoadingError ? 'is-danger' : 'is-success'
    },
    isSquareLoading: function () {
      return this.squareConnectionStatus.isLoading
    },
    hasSquareLoadingError: function () {
      return this.squareConnectionStatus.serverErrorMessage !== ''
    },
    getSquareErrorMessage: function () {
      return this.squareConnectionStatus.serverErrorMessage
    },
    getPurchaserContactName: function () {
      let fName = ''
      let lName = ''

      if (this.deal) {
        return this.deal.purchaserContactName ? this.deal.purchaserContactName : ''
      } else if (!this.isRoServiceDeposit && !this.isPartsDeposit) {
        return this.buyer && this.isWholesale ? this.supplier.primaryContact : this.customer.displayName
      } else if (this.isRoServiceDeposit && !this.isDepositConverting) {
        fName = (this.currentRoService && this.currentRoService.roServiceCustomer) ? this.currentRoService.roServiceCustomer.firstName : ''
        lName = (this.currentRoService && this.currentRoService.roServiceCustomer) ? this.currentRoService.roServiceCustomer.lastName : ''
      } else if (this.isRoServiceDeposit && this.isDepositConverting) {
        fName = (this.customer) ? this.customer.firstName : ''
        lName = (this.customer) ? this.customer.lastName : ''
      } else if (this.isPartsDeposit && !this.isDepositConverting) {
        fName = (this.currentParts && this.currentParts.partsCustomer) ? this.currentParts.partsCustomer.firstName : ''
        lName = (this.currentParts && this.currentParts.partsCustomer) ? this.currentParts.partsCustomer.lastName : ''
      } else if (this.isPartsDeposit && this.isDepositConverting) {
        fName = (this.customer) ? this.customer.firstName : ''
        lName = (this.customer) ? this.customer.lastName : ''
      }

      return fName === '' && lName === '' ? '' : this.toCamelCase(fName) + ' ' + this.toCamelCase(lName)
    },
    getHasCoApplicant: function () {
      return this.deal && this.deal.hasCoApplicant ? this.deal.hasCoApplicant : false
    },
    getIsSpouseCoApplicant: function () {
      return this.deal && this.deal.isSpouseCoApplicant ? this.deal.isSpouseCoApplicant : false
    },
    getPurchaserContactEmail: function () {
      if (this.deal && !this.isDepositConverting) {
        return this.deal.purchaserContactEmail ? this.deal.purchaserContactEmail : ''
      } else if (!this.isRoServiceDeposit && !this.isPartsDeposit) {
        return this.buyer && this.isWholesale ? this.supplier.primaryContactEmail : this.customer.email
      } else if (this.isRoServiceDeposit && !this.isDepositConverting) {
        return this.currentRoService.roServiceCustomer.contactEmailAddress
      } else if (this.isRoServiceDeposit && this.isDepositConverting) {
        return this.customer.contactEmailAddress
      } else if (this.isPartsDeposit && !this.isDepositConverting) {
        return this.currentParts.partsCustomer.contactEmailAddress
      } else if (this.isPartsDeposit && this.isDepositConverting) {
        return this.customer.contactEmailAddress
      }
    },
    getPurchaserContactMobile: function () {
      if (this.deal) {
        return this.deal.purchaserContactMobile ? this.deal.purchaserContactMobile : ''
      } else if (!this.isRoServiceDeposit && !this.isPartsDeposit) {
        return this.buyer && this.isWholesale ? this.supplier.primaryContactMobile : this.customer.mobile
      } else if (this.isRoServiceDeposit && !this.isDepositConverting) {
        return this.currentRoService.roServiceCustomer.cellularPhoneNumber
      } else if (this.isRoServiceDeposit && this.isDepositConverting) {
        return this.customer.cellularPhoneNumber
      } else if (this.isPartsDeposit && !this.isDepositConverting) {
        return this.currentParts.partsCustomer.contactPhoneNumber
      } else if (this.isPartsDeposit && this.isDepositConverting) {
        return this.customer.contactPhoneNumber
      }
    },
    getPurchaserId: function () {
      if (this.isRoServiceDeposit && !this.isDepositConverting) return this.currentRoService.roServiceCustomer.id
      if (this.isRoServiceDeposit && this.isDepositConverting) return this.customer.id
      if (this.isPartsDeposit && !this.isDepositConverting) return this.currentParts.partsCustomer.id
      if (this.isPartsDeposit && this.isDepositConverting) return this.customer.id
      return this.deal ? this.deal.purchaserId : this.buyer.id
    },
    getCoCustomerId: function () {
      return this.deal && this.deal.coCustomerId ? this.deal.coCustomerId : false
    },
    getCoCustomerName: function () {
      return this.deal && this.deal.coCustomerName ? this.deal.coCustomerName : false
    },
    squareTaxes: function () {
      if (this.squareCatalogDownload && this.selectedInvoiceItem) {
        let filteredTaxes = this.squareCatalogDownload.filter(function (x) {
          if (this.selectedInvoiceItem.taxIds) {
            let index = this.selectedInvoiceItem.taxIds.findIndex(y => y === x.id)
            return x.type === 'TAX' && index !== -1
          }
        }, this)

        return filteredTaxes.map(function (y) {
          return { id: y.id, name: y.tax_data.name + ' ' + y.tax_data.percentage + '%' }
        })
      }

      return []
    },
    squareItems: function () {
      if (this.squareCatalogDownload && this.selectedSquareLocation) {
        let filteredItems = this.squareCatalogDownload.filter(function (x) {
          let presentAtCurrentLocation = true

          if (x.present_at_all_locations === false && x.present_at_location_ids && x.present_at_location_ids.length > 0) {
            let index = x.present_at_location_ids.findIndex(y => y === this.selectedSquareLocation.id)
            presentAtCurrentLocation = index !== -1
          }

          if (x.present_at_all_locations === true && x.absent_at_location_ids && x.absent_at_location_ids.length > 0) {
            let index = x.absent_at_location_ids.findIndex(y => y === this.selectedSquareLocation.id)
            presentAtCurrentLocation = index === -1
          }

          return x.type === 'ITEM' && presentAtCurrentLocation === true
        }, this)

        return filteredItems.map(function (y) {
          return { id: y.id, name: y.item_data.name, taxIds: y.item_data.tax_ids, variationItemId: y.item_data.variations[0].id }
        })
      }

      return []
    },
    canShowTaxMultiSelect: function () {
      return this.selectedInvoiceItem !== null && this.isSquarePayment
    },
    squarePaymentLimit: function () {
      if (!this.isSquarePayment) {
        return 99999999.99
      }

      if (!this.currentDealer.squarePaymentLimit || (this.currentDealer.squarePaymentLimit && this.currentDealer.squarePaymentLimit === '')) {
        return 50000.00
      }

      return this.currentDealer.squarePaymentLimit
    },
    getModalId: function () {
      if (this.isSquarePayment) {
        return 'payment-control-modal-square'
      }
      if (this.isWirePayment) {
        return 'payment-control-modal-wire'
      }
      return 'payment-control-modal'
    },
    fulfillForDepositCaptureMode: function () {
      if (this.crudMode === 'create' && this.isSquarePayment && this.getPurchaserContactEmail === '') return true
      if (this.crudMode === 'create' && !this.isSquarePayment && this.getPurchaserContactMobile === '' && this.getPurchaserContactEmail === '') return true
      return false
    },
    showMismatchObjects: function () {
      return this.isCustomerMismatch && this.payerName && this.payerName.length > 0
    },
    disableSave: function () {
      if (this.isUploadingFile) return true

      if (this.isCashPayment || this.isCheckPayment || this.isOtherPayment) {
        if (this.isCustomerMismatch) return !this.overrideName
      }

      return false
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    getExpectedWhereDidMoneyComeFrom: function () {
      if (this.deal) {
        return this.deal.purchaserName
      }

      if (this.isDepositConverting) {
        return this.value.customerName
      }

      if (this.isRoServiceDeposit || this.isPartsDeposit) {
        return this.getPurchaserContactName
      }

      if (this.buyer) {
        return this.buyer && this.isWholesale ? this.supplier.name : this.customer.displayName
      }

      return ''
    },
    getExpectedWhoAcceptedDeposit: function () {
      if (this.currentUser) {
        return this.currentUser.fullName
      }

      return ''
    },
    getPaymentTotalReceived: function () {
      return this.getTotalWireReceived + this.getTotalSquareReceived + this.getTotalCashReceived + this.getTotalCheckReceived + this.getTotalOtherReceived
    },
    getTotalWireReceived: function () {
      let result = 0
      if (this.deal && this.deal.paymentTrackings && this.deal.paymentTrackings.length > 0) {
        this.deal.paymentTrackings.forEach((x) => {
          if (x.status === 'Active' && (x.paymentStatus === 'Paid' || x.paymentStatus === 'PendingInAccounting') && x.type === 'Wire' && x.paymentEvents.length > 0) {
            x.paymentEvents.forEach((y) => {
              if (y.status === 'Active') {
                result += y.amount
              }
            })
          }
        })
      }
      return result
    },
    getTotalSquareReceived: function () {
      let result = 0
      if (this.deal && this.deal.paymentTrackings && this.deal.paymentTrackings.length > 0) {
        this.deal.paymentTrackings.forEach((x) => {
          if (x.status === 'Active' &&
            (x.paymentStatus === 'Paid' || x.paymentStatus === 'PendingInAccounting') &&
            (x.type === 'Square' || x.type === 'SquareTerminal') &&
            x.paymentEvents.length > 0) {
            x.paymentEvents.forEach((y) => {
              if (y.status === 'Active') {
                result += y.amount
              }
            })
          }
        })
      }
      return result
    },
    getTotalCashReceived: function () {
      let result = 0
      if (this.deal && this.deal.paymentTrackings && this.deal.paymentTrackings.length > 0) {
        this.deal.paymentTrackings.forEach((x) => {
          if (x.status === 'Active' && (x.paymentStatus === 'Paid' || x.paymentStatus === 'PendingInAccounting') && x.type === 'Cash' && x.paymentEvents.length > 0) {
            x.paymentEvents.forEach((y) => {
              if (y.status === 'Active') {
                result += y.amount
              }
            })
          }
        })
      }
      return result
    },
    getTotalCheckReceived: function () {
      let result = 0
      if (this.deal && this.deal.paymentTrackings && this.deal.paymentTrackings.length > 0) {
        this.deal.paymentTrackings.forEach((x) => {
          if (x.status === 'Active' && (x.paymentStatus === 'Paid' || x.paymentStatus === 'PendingInAccounting') && x.type === 'Check' && x.paymentEvents.length > 0) {
            x.paymentEvents.forEach((y) => {
              if (y.status === 'Active') {
                result += y.amount
              }
            })
          }
        })
      }
      return result
    },
    getTotalOtherReceived: function () {
      let result = 0
      if (this.deal && this.deal.paymentTrackings && this.deal.paymentTrackings.length > 0) {
        this.deal.paymentTrackings.forEach((x) => {
          if (x.status === 'Active' && (x.paymentStatus === 'Paid' || x.paymentStatus === 'PendingInAccounting') && x.type === 'Other' && x.paymentEvents.length > 0) {
            x.paymentEvents.forEach((y) => {
              if (y.status === 'Active') {
                result += y.amount
              }
            })
          }
        })
      }
      return result
    },
    getPaymentStatus: function () {
      let status = ''
      if (this.isRoServiceDeposit && this.currentRoService && this.currentRoService.id) {
        if (this.currentRoService.invoicePaymentRequired && this.currentRoService.workOrderPaymentRequired) {
          status = this.currentRoService.invoiceIsPaid && this.currentRoService.workOrderIsPaid ? 'Paid' : 'Payment Pending'
          return status
        }

        if (this.currentRoService.workOrderPaymentRequired) {
          status = this.currentRoService.workOrderIsPaid ? 'Paid' : 'Payment Pending'
          return status
        }

        if (this.currentRoService.invoicePaymentRequired) {
          status = this.currentRoService.invoiceIsPaid ? 'Paid' : 'Payment Pending'
          return status
        }

        return status
      }

      status = ''
      if (this.isPartsDeposit && this.currentParts && this.currentParts.id) {
        if (this.currentParts.invoicePaymentRequired) {
          status = this.currentParts.invoiceIsPaid ? 'Paid' : 'Payment Pending'
        }

        return status
      }

      status = ''
      if (this.isWholesaleReceivableListMode) {
        if (!this.wholesaleReceivableDealHasPayments) {
          status = ''
        } else if (this.wholesaleReceivableDealHasPayments) {
          if (this.listItemDealInfo.paymentTrackingStatus === 'NotYetPaid') {
            status = 'Payment Pending'
          } else if (this.listItemDealInfo.paymentTrackingStatus === 'PendingInAccounting') {
            status = 'Pending In Accounting'
          } else if (this.listItemDealInfo.paymentTrackingStatus === 'Paid') {
            status = 'Paid'
          } else if (this.listItemDealInfo.paymentTrackingStatus === 'Cancelled') {
            if (status === '') {
              status = 'Cancelled'
            }
          } else if (this.listItemDealInfo.paymentTrackingStatus === 'Refund') {
            if (status === '') {
              status = 'Refund'
            }
          }
        }
        return status
      }

      status = ''
      if (this.deal && this.deal.paymentTrackings && this.deal.paymentTrackings.length > 0) {
        // need to match the priority payment tracking status sequence from the sql function for lists when more than one active deposit
        let deposits = this.deal.paymentTrackings
        let hasNotYetPaid = deposits.findIndex(x => x.paymentStatus === 'NotYetPaid') !== -1
        if (hasNotYetPaid) {
          status = 'Payment Pending'
          return status
        }

        let hasPendingInAccounting = deposits.findIndex(x => x.paymentStatus === 'PendingInAccounting') !== -1
        if (hasPendingInAccounting) {
          status = 'Pending In Accounting'
          return status
        }

        let hasPaid = deposits.findIndex(x => x.paymentStatus === 'Paid') !== -1
        if (hasPaid) {
          status = 'Paid'
          return status
        }

        let hasCancelled = deposits.findIndex(x => x.paymentStatus === 'Cancelled') !== -1
        if (hasCancelled) {
          status = 'Cancelled'
          return status
        }

        let hasRefund = deposits.findIndex(x => x.paymentStatus === 'Refund') !== -1
        if (hasRefund) {
          status = 'Refund'
          return status
        }
      }

      return status
    },
    isRoServiceDeposit: function () {
      if (this.value && this.value.category === 'RoService') return true
      return this.currentRoService != null
    },
    isPartsDeposit: function () {
      if (this.value && this.value.category === 'Parts') return true
      return this.currentParts != null
    },
    getBuyerType: function () {
      if (this.isRoServiceDeposit) return 'RoServiceCustomer'
      if (this.isPartsDeposit) return 'PartsCustomer'
      return this.isRetail ? 'Customer' : 'Supplier'
    },
    printRequestId: function () {
      if (this.isRoServiceDeposit) {
        return this.currentRoService.invoicePrintRequestId > 0 ? this.currentRoService.invoicePrintRequestId : this.currentRoService.workOrderPrintRequestId
      }

      if (this.isPartsDeposit) {
        return this.currentParts.invoicePrintRequestId > 0 ? this.currentParts.invoicePrintRequestId : 0
      }

      return 0
    },
    hasSquareCreateAccess: function () {
      return this.hasFeatureAccess('deposit.create.square')
    },
    hasWireCreateAccess: function () {
      return this.hasFeatureAccess('deposit.create.wire')
    },
    hasCheckCashOtherCreateAccess: function () {
      return this.hasFeatureAccess('deposit.create.check')
    },
    isCompanySetForCustomer: function () {
      if (!this.customer || !this.customer.companyName) return false
      if (this.customer.displayName === this.customer.companyName) return false
      return this.customer.companyName !== '' && this.customer.companyName !== null
    },
    getCustomerCompanyName: function () {
      return this.isCompanySetForCustomer ? this.customer.companyName : ''
    },
    customerNames: function () {
      if (this.isCompanySetForCustomer) {
        return [{id: 0, name: this.customer.displayName}, {id: 1, name: this.getCustomerCompanyName}]
      }
      return []
    },
    showCustomerNameSelector: function () {
      return this.isSquarePayment && this.isCompanySetForCustomer && !this.isWholesale
    },
    buyer: function () {
      if (this.purchaser) return this.purchaser
      return this.deal && this.deal.customer ? this.deal.customer : null
    },
    showTransactionNote: function () {
      if ((this.isRoServiceDeposit || this.isPartsDeposit) && this.isOtherPayment) {
        return false
      } else if (!this.isSquarePayment) {
        return true
      }
      return false
    },
    hasDepositModifyAccess: function () {
      return this.hasFeatureAccess('deposit.modify')
    },
    dealExistingComponent: function () {
      return 'dec-' + this.instanceId
    },
    checkAvailablePaymentStatus: function () {
      let allStatuses = this.definedTypes.paymentTrackingStatuses
      let filteredTypes = allStatuses.options.filter(x => x.id === allStatuses.notYetPaid || x.id === allStatuses.pendingInAccounting)
      return filteredTypes
    },
    showPaymentStatusOptions: function () {
      return this.isCashPayment || this.isCheckPayment || this.isOtherPayment
    },
    getDropdownClass: function () {
      if (this.slideDropdownUp) {
        return 'send-wire-control is-top-right'
      }
      return 'send-wire-control'
    },
    getSignatureBody: function () {
      let body = ''

      if (this.isRoServiceDeposit) {
        let roNum = this.isDepositConverting ? this.value.roNumber : this.currentRoService.roNumber
        body = 'By signing, I ' + this.getPurchaserContactName +
          ' acknowledge I have received, read and agree to the terms of Repair Order #' + roNum +
          ' including all terms and conditions attached thereto (the “RO”), I understand my signature constitutes my signature on the RO,' +
          ' I consent to be legally bound by the RO and be responsible for all charges on the RO.'
      }

      if (this.isPartsDeposit) {
        body = 'By signing, I ' + this.getPurchaserContactName +
          ' acknowledge I have received, read and agree to the terms of Parts Invoice #' + this.partsInvoiceNo +
          ' including all terms and conditions attached thereto (the “Parts Invoice”), I understand my signature constitutes my signature on the Parts Invoice,' +
          ' I consent to be legally bound by the Parts Invoice and be responsible for all charges on the Parts Invoice.'
      }

      return body
    },
    saveBtnTitle: function () {
      if (this.isSquareTerminalPayment && this.squareTerminalCheckOut) {
        let terminalText = this.squareTerminalCheckOut.signOnly ? 'Send To Terminal For Signing' : 'Send To Terminal For Signing & Payment'
        return terminalText
      }

      return 'Save'
    },
    showRoInvoiceTerminalMenuItem: function () {
      if (this.currentRoService && this.hasSquareCreateAccess) {
        return this.isInvoiceNotPaidButRequired || this.isInvoiceNotSignedButRequired
      }

      return false
    },
    showRoWorkOrderTerminalMenuItem: function () {
      if (this.currentRoService && this.hasSquareCreateAccess) {
        return this.isWorkOrderNotPaidButRequired || this.isWorkOrderNotSignedButRequired
      }

      return false
    },
    isInvoiceNotPaidButRequired: function () {
      return this.currentRoService &&
        this.currentRoService.invoicePrintRequestId > 0 &&
        this.currentRoService.invoicePaymentRequired &&
        !this.currentRoService.invoiceIsPaid
    },
    isInvoiceNotSignedButRequired: function () {
      return this.currentRoService &&
        this.currentRoService.invoicePrintRequestId > 0 &&
        this.currentRoService.invoiceSignatureRequired &&
        !this.currentRoService.invoiceIsSigned
    },
    isWorkOrderNotPaidButRequired: function () {
      return this.currentRoService &&
        this.currentRoService.workOrderPrintRequestId > 0 &&
        this.currentRoService.workOrderPaymentRequired &&
        !this.currentRoService.workOrderIsPaid
    },
    isWorkOrderNotSignedButRequired: function () {
      return this.currentRoService &&
        this.currentRoService.workOrderPrintRequestId > 0 &&
        this.currentRoService.workOrderSignatureRequired &&
        !this.currentRoService.workOrderIsSigned
    },
    invoiceTerminalText: function () {
      if (this.isInvoiceNotPaidButRequired && this.isInvoiceNotSignedButRequired) return 'sign & pay - terminal (invoice)'
      return this.isInvoiceNotPaidButRequired ? 'sign & pay - terminal (invoice)' : 'sign only - terminal (invoice)'
    },
    workOrderTerminalText: function () {
      if (this.isWorkOrderNotPaidButRequired && this.isWorkOrderNotSignedButRequired) return 'sign & pay - terminal (work order)'
      return this.isWorkOrderNotPaidButRequired ? 'sign & pay - terminal (work order)' : 'sign only - terminal (work order)'
    },
    dropDownInvoiceType: function () {
      if (this.isInvoiceNotPaidButRequired && this.isInvoiceNotSignedButRequired) return 'squareterminal'
      return this.isInvoiceNotPaidButRequired ? 'squareterminal' : 'squareterminalSign'
    },
    dropDownWorkOrderType: function () {
      if (this.isWorkOrderNotPaidButRequired && this.isWorkOrderNotSignedButRequired) return 'squareterminal'
      return this.isWorkOrderNotPaidButRequired ? 'squareterminal' : 'squareterminalSign'
    },
    dropDownPartsInvoiceType: function () {
      if (this.isPartsInvoiceNotPaidButRequired && this.isPartsInvoiceNotSignedButRequired) return 'squareterminal'
      return this.isPartsInvoiceNotPaidButRequired ? 'squareterminal' : 'squareterminalSign'
    },
    isPartsInvoiceNotPaidButRequired: function () {
      return this.currentParts &&
        this.currentParts.invoicePrintRequestId > 0 &&
        this.currentParts.invoicePaymentRequired &&
        !this.currentParts.invoiceIsPaid
    },
    isPartsInvoiceNotSignedButRequired: function () {
      return this.currentParts &&
        this.currentParts.invoicePrintRequestId > 0 &&
        this.currentParts.invoiceSignatureRequired &&
        !this.currentParts.invoiceIsSigned
    },
    hasAmount: function () {
      return this.amount && this.amount > 0
    },
    selectedPrintRequestId: function () {
      if (this.printRequestIdProp && this.printRequestIdProp > 0) return this.printRequestIdProp

      return this.dropDownSelectedPrintRequestId
    },
    roNum: function () {
      if (this.isRoServiceDeposit) {
        let roNum = this.isDepositConverting ? 'RO' + this.value.roNumber : 'RO' + this.currentRoService.roNumber
        return roNum
      }

      return ''
    },
    roCustomer: function () {
      if (this.isRoServiceDeposit) {
        let customer = ''
        if (this.currentRoService && this.currentRoService.roServiceCustomer) {
          customer = this.currentRoService.roServiceCustomer.firstName + ' ' + this.currentRoService.roServiceCustomer.lastName
        }

        return customer
      }

      return ''
    },
    roVehicleInfo: function () {
      if (this.isRoServiceDeposit) {
        let vehicleInfo = ''
        if (this.currentRoService && this.currentRoService.roServiceVehicle) {
          vehicleInfo = this.currentRoService.roServiceVehicle.name
        }

        return vehicleInfo
      }

      return ''
    },
    roVin: function () {
      if (this.isRoServiceDeposit) {
        let vin = ''
        if (this.currentRoService && this.currentRoService.roServiceVehicle) {
          vin = this.currentRoService.roServiceVehicle.vin
        }

        return vin
      }

      return ''
    },
    isRoCustomerUsedDepartment: function () {
      return this.roCustomer.trim() === 'Used Car Department'
    },
    isRoCustomerNewDepartment: function () {
      return this.roCustomer.trim() === 'New Car Department'
    },
    mainActionBtnContainerClass: function () {
      return this.showButtonType && this.showButtonType === 'squareterminal' ? 'is-pulled-right' : 'is-pulled-left'
    },
    isNoteRequired: function () {
      if (this.selectedPaymentType && this.selectedPaymentType.id === 4) return false
      return this.noteRequired
    },
    getFooter1stColumnSize: function () {
      return this.isSquareTerminalPayment ? 'is-4' : 'is-3'
    },
    getFooter2ndColumnSize: function () {
      return this.isSquareTerminalPayment ? 'is-6' : 'is-7'
    },
    paymentControlInitiatedFromBuyer: function () {
      return this.purchaser !== null && !this.isRoServiceDeposit && !this.isPartsDeposit && !this.deal
    },
    partsId: function () {
      let partsId = null
      if (this.isPartsDeposit) {
        partsId = this.isDepositConverting ? this.value.partsId : this.currentParts.id
      }
      return partsId
    },
    prefixInvoiceNo: function () {
      let derivedNum = (this.firstSoldItemInventory) ? this.firstSoldItemInventory.stockNo : ''

      if (this.isRoServiceDeposit) {
        derivedNum += derivedNum ? ' ' + this.roNum : this.roNum
      }

      if (this.isPartsDeposit) {
        derivedNum += derivedNum ? ' ' + this.partsInvoiceNo : this.partsInvoiceNo
      }

      return derivedNum
    },
    partsInvoiceNo: function () {
      if (this.isPartsDeposit) {
        let partsInvoiceNo = this.isDepositConverting ? this.value.partsInvoiceNo : this.currentParts.invoiceNumber
        return partsInvoiceNo
      }

      return ''
    },
    partsCustomer: function () {
      if (this.isRoServiceDeposit) {
        let customer = ''
        if (this.currentRoService && this.currentRoService.roServiceCustomer) {
          customer = this.currentRoService.roServiceCustomer.firstName + ' ' + this.currentRoService.roServiceCustomer.lastName
        }

        return customer
      }

      return ''
    },
    showPartsTerminalMenuItem: function () {
      return this.isPartsDeposit && this.currentParts && this.currentParts.hasInvoicePrintRequest
    },
    partsTerminalText: function () {
      if (this.isPartsInvoiceNotPaidButRequired && this.isPartsInvoiceNotSignedButRequired) return 'sign & pay - terminal'
      return this.isPartsInvoiceNotPaidButRequired ? 'sign & pay - terminal' : 'sign only - terminal'
    }
  },
  methods: {
    buildDefaultNote: function () {
      let paymentType = this.paymentType ? this.$toTitleCase(this.paymentType) : ''
      let requestText = this.selectedPaymentType && this.selectedPaymentType.altName === 'PendingInAccounting' ? 'received' : 'request sent'
      let text = paymentType + ' ' + requestText
      return this.defaultNote ? this.defaultNote.replace('%PAYMENTTYPE%', text) : ''
    },
    onCancelExistingAndConfirmDuplicatePaymentRequest: function () {
      this.cancelAllExistingRequests()
    },
    onConfirmDuplicatePaymentRequest: function () {
      this.onConfirmQueueNotification()
    },
    onSendReminderForExistingPaymentRequests: function () {
      this.isDuplicatePaymentRequestActive = false
      this.sendReminderForAllExistingRequests()
      this.onClose(false)
    },
    sendReminderForAllExistingRequests: function () {
      this.matchingPaymentRequestList.forEach(paymentRequest => {
        if (paymentRequest.paymentStatus === this.definedTypes.paymentTrackingStatuses.notYetPaid) {
          this.resendPaymentTrackingWireRequest(paymentRequest)
        }
      })
    },
    cancelAllExistingRequests: function () {
      this.confirmBatchCancelActive = true
    },
    clearAllRecipients: function () {
      this.selectedRecipients = []
      this.recipients = []

      this.serverErrors = []
      this.serverErrorMessage = ''
      this.squareConnectionStatus = {
        isLoading: false,
        serverErrorMessage: ''
      }
    },
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data.fullName,
        mobile: data.mobile,
        id: data.id,
        email: data.email,
        fullNameEmail: data.fullNameEmail,
        firstName: data.firstName,
        lastName: data.lastName
      }
      this.payerName = this.getExpectedWhereDidMoneyComeFrom
      this.selectedRecipients = []
      this.selectedRecipients.push(recipient)
    },
    onClose: function (saved) {
      if (saved) {
        this.$emit('on-save')
        eventBus.$emit('quickview-refresh')
        eventBus.$emit('reload-payment-tracking-list')
      } else {
        this.$emit('on-cancel')
      }
      this.tabIndex = 0
      this.notes = []
      this.isDuplicatePaymentRequestActive = false
      this.paymentControlActive = false
      this.isSaving = false
    },
    initPurchaserData: function () {
      this.clearAllRecipients()
      if (this.isWholesale) {
        this.defaultWholesaleCustomer()
      } else if (!this.isRoServiceDeposit && !this.isPartsDeposit) {
        this.defaultRetailCustomer()
      } else if (this.isRoServiceDeposit) {
        this.defaultRoCustomer()
      } else {
        this.defaultPartsCustomer()
      }
    },
    onConfirmQueueNotification: function () {
      if (this.isWirePayment) {
        this.saveWirePayment()
      } else if (this.isSquarePayment) {
        this.saveSquarePayment()
      } else if (this.isCashPayment || this.isCheckPayment || this.isOtherPayment) {
        this.saveCashCheckPayment()
      } else if (this.isSquareTerminalPayment) {
        this.saveSquareTerminalPayment()
      }
    },
    checkForDuplicateExistingPayments: function () {
      if (this.matchingPaymentRequestList.length > 0 && !this.isDuplicatePaymentRequestActive) {
        let unpaidItems = this.matchingPaymentRequestList.filter((x) => {
          return x.paymentStatus === this.definedTypes.paymentTrackingStatuses.notYetPaid
        })
        this.isDuplicatePaymentRequestActive = unpaidItems && unpaidItems.length > 0
        return this.isDuplicatePaymentRequestActive
      }
      this.isDuplicatePaymentRequestActive = false
      return false
    },
    onLoadHistoricalPayments: function () {
      let paymentTracking = {
        dealId: this.deal ? this.deal.id : null,
        dealerId: this.currentDealer.id,
        buyersId: this.getPurchaserId,
        buyersType: this.getBuyerType,
        paymentStatus: 'PendingInAccounting',
        type: 'Other',
        amount: 0,
        note: '',
        dealCode: this.deal ? this.deal.code : null,
        paymentEvents: [],
        notes: [],
        dealSaleType: this.isWholesale ? 'Wholesale' : 'Retail',
        subscriberId: this.currentDealer.subscriberId
      }

      this.isLoading = true
      this.showLoader = true
      paymentTrackingService.checkMatchingPaymentRequests(paymentTracking).then(response => {
        this.isLoading = false
        this.showLoader = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.matchingPaymentRequestList = []
          if (response.results.length > 0) {
            response.results.forEach((item) => {
              this.matchingPaymentRequestList.push(item)
            })

            this.initialMatchingPaymentRequestList = _.cloneDeep(this.matchingPaymentRequestList)
            if (!this.isRoCustomerUsedDepartment && !this.isRoCustomerNewDepartment && this.isRoServiceDeposit) {
              let currentRoNumber = this.isDepositConverting ? this.value.roNumber : this.currentRoService.roNumber
              this.matchingPaymentRequestList = this.initialMatchingPaymentRequestList.filter((x) => x.roNumber === currentRoNumber)
            }
          }
        }
      }).catch((error) => {
        this.matchingPaymentRequestList = []
        this.loading = false
        this.showLoader = false
        this.serverErrorMessage = error.message
      })
    },
    saveWirePayment: function () {
      let that = this
      that.$validator.validateAll('recipient-form').then((result) => {
        if (result && that.selectedRecipients.length > 0) {
          if (!that.isDepositConverting) {
            if (that.checkForDuplicateExistingPayments()) return
          }

          // need to set tracking number, first check on user then dealer. We use this with InteractiveTel sms gateway
          let trackingNumber = (that.currentUser.activeTrackingNumber && that.currentUser.activeTrackingNumber !== '') ? that.currentUser.activeTrackingNumber : that.currentDealer.defaultCallTrackingNumber
          var note = that.notes.length > 0 ? that.notes[that.notes.length - 1] : null

          let roNum = ''
          if (that.isRoServiceDeposit) {
            roNum = that.isDepositConverting ? that.value.roNumber : that.currentRoService.roNumber
          }

          var wireDetailAuditSendModel = {
            dealerId: that.currentDealer.id,
            dealId: that.deal ? that.deal.id : null,
            callTrackingNumber: trackingNumber,
            recipients: that.selectedRecipients,
            notificationType: 'SmsEmail',
            amount: that.amount,
            attachments: [],
            saleType: that.isWholesale ? 'Wholesale' : 'Retail',
            payerType: that.getBuyerType,
            roNumber: roNum,
            paymentTrackingNote: note,
            partsId: that.partsId,
            convertFromId: that.isDepositConverting ? that.value.id : 0
          }

          if (that.wirePdfFiles.length > 0 && that.hasWireAttachments) {
            var pdfFiles = that.wirePdfFiles.map((x) => {
              return {
                uploadName: x.name,
                uploadSize: x.size,
                type: x.type,
                classType: 'Wire',
                uniqueId: x.uniqueId
              }
            })
            wireDetailAuditSendModel.attachments.push(...pdfFiles)
          }

          if (that.isWholesaleReceivableListMode && that.wireSaveFileOnly) {
            wireDetailAuditSendModel.saveWireOnly = true
          }

          that.isSaving = true
          wireDetailAuditService.queueWireNotification(wireDetailAuditSendModel).then(response => {
            that.isSaving = false
            if (response.errors) {
              that.failedToast('Oops! Something went wrong')
              that.serverErrors = response.errors
            } else {
              that.successToast('Success! Notification queued')
              that.onClose(true)
            }
          }).catch((error) => {
            that.failedToast('Oops! Something went wrong')
            that.isSaving = false
            that.serverErrorMessage = error.message
          })
        } else {
          that.$goToFirstValidationError(that.$validator.errors.items)
        }
      })
    },
    saveSquarePayment: function () {
      let that = this
      that.$validator.validateAll('recipient-form').then((result) => {
        if (result && that.selectedRecipients.length > 0) {
          if (!that.selectedRecipients[0].email) {
            that.serverErrorMessage = 'The recipient email is required. Click on the recipient name to open edit screen'
            return
          }

          if (!that.isDepositConverting) {
            if (that.checkForDuplicateExistingPayments()) return
          }

          let category = that.isRoServiceDeposit ? 'RoService' : 'Standard'
          category = that.isPartsDeposit ? 'Parts' : category

          let roNum = ''
          if (that.isRoServiceDeposit) {
            roNum = that.isDepositConverting ? that.value.roNumber : that.currentRoService.roNumber
          }

          let paymentTracking = {
            id: that.currentPaymentTracking && that.crudMode === 'update' ? that.currentPaymentTracking.id : 0,
            dealId: that.deal ? that.deal.id : null,
            dealerId: that.currentDealer.id,
            buyersId: that.getPurchaserId,
            buyersType: that.getBuyerType,
            paymentStatus: 'NotYetPaid',
            type: 'Square',
            amount: that.amount,
            dealCode: that.deal ? that.deal.code : null,
            dealSaleType: that.isWholesale ? 'Wholesale' : 'Retail',
            subscriberId: that.currentDealer.subscriberId,
            notes: [],
            convertFromId: that.isDepositConverting ? that.value.id : 0,
            category: category,
            roNumber: roNum,
            partsId: that.partsId
          }

          var note = that.notes.length > 0 ? that.notes[that.notes.length - 1] : null
          if (note !== null) {
            paymentTracking.notes.push(note)
          }

          let taxIds = []
          that.selectedInvoiceTaxes.forEach(tax => {
            taxIds.push(tax.id)
          })

          let order = {
            amount: that.amount,
            SqCatalogId: that.selectedInvoiceItem.variationItemId,
            sqTaxIds: taxIds,
            sqId: that.squareOrderDownload && that.crudMode === 'update' ? that.squareOrderDownload.id : '',
            sqVersionNo: that.orderVersionNo,
            sqOrderLineUid: that.squareOrderDownload && that.crudMode === 'update' ? that.orderLineItemUid : '',
            quantity: 1,
            dealerLocationId: (that.selectedSquareLocation) ? that.selectedSquareLocation.id : '',
            purchaserFirstName: that.selectedRecipients[0].firstName,
            purchaserLastName: that.selectedRecipients[0].lastName,
            purchaserEmail: that.selectedRecipients[0].email,
            purchaserMobile: that.selectedRecipients[0].mobile,
            purchaserCompanyName: (that.showCustomerNameSelector) ? that.selectedName.name : (that.isWholesale) ? that.selectedRecipients[0].name : '',
            purchaserId: that.selectedRecipients[0].id
          }

          let invoiceModel = {
            title: that.invoiceTitle,
            description: that.currentDealer.displaySquareInvoiceText,
            orderModel: order,
            paymentTrackingModel: paymentTracking,
            dealerId: that.currentDealer.id,
            subscriberId: that.currentDealer.subscriberId,
            prefixInvoiceNo: that.prefixInvoiceNo,
            sqVersionNo: that.invoiceVersionNo,
            sqId: that.sqInvoiceId,
            sqPaymentRequestId: that.sqInvoicePaymentRequestId,
            sqSetReminder: that.setReminder
          }

          that.isSaving = true
          let serviceMethod = that.crudMode === 'create' ? 'createInvoice' : 'updateInvoice'
          squareService[serviceMethod](invoiceModel).then(response => {
            that.isSaving = false
            if (response.errors && response.errors.length > 0) {
              that.failedToast('Oops! Something went wrong')
              that.serverErrors = response.errors
            } else {
              that.successToast('Success! Square Invoice Created')
              that.onClose(true)
            }
          }).catch((error) => {
            that.failedToast('Oops! Something went wrong')
            that.isSaving = false
            that.serverErrorMessage = error.message
          })
        } else {
          that.$goToFirstValidationError(that.$validator.errors.items)
        }
      })
    },
    saveSquareTerminalPayment: function () {
      this.$validator.validateAll('recipient-form').then((result) => {
        if (result) {
          let category = 'Standard'
          if (this.isRoServiceDeposit) {
            category = 'RoService'
          } else if (this.isPartsDeposit) {
            category = 'Parts'
          }

          let paymentTracking = {
            id: 0,
            dealId: this.deal ? this.deal.id : null,
            dealerId: this.currentDealer.id,
            buyersId: this.getPurchaserId,
            buyersType: this.getBuyerType,
            paymentStatus: 'NotYetPaid',
            type: 'SquareTerminal',
            amount: this.squareTerminalCheckOut.amount,
            dealCode: this.deal ? this.deal.code : null,
            dealSaleType: this.isWholesale ? 'Wholesale' : 'Retail',
            subscriberId: this.currentDealer.subscriberId,
            notes: [],
            convertFromId: this.isDepositConverting ? this.value.id : 0,
            category: category,
            roNumber: this.isRoServiceDeposit ? this.currentRoService.roNumber : '',
            partsId: this.partsId,
            sqItemId: this.squareTerminalCheckOut.itemId,
            sqTaxIds: this.squareTerminalCheckOut.taxIds.length > 0 ? this.squareTerminalCheckOut.taxIds.toString() : '',
            sqLocationId: this.squareTerminalCheckOut.locationId
          }

          this.isSaving = true
          this.squareTerminalCheckOut.printRequestId = this.selectedPrintRequestId
          this.squareTerminalCheckOut.paymentTrackingModel = paymentTracking
          this.squareTerminalCheckOut.sqSignatureOptions = {
            title: 'Terms & Conditions',
            body: this.squareTerminalCheckOut.signatureBody
          }

          // overide signOnly if amount is 0, no need to request payment
          if (this.squareTerminalCheckOut.amount === 0 && !this.squareTerminalCheckOut.signOnly) {
            this.squareTerminalCheckOut.signOnly = true
          }

          squareService.signatureTerminalAction(this.squareTerminalCheckOut).then(response => {
            this.isSaving = false
            if (response.errors && response.errors.length > 0) {
              this.failedToast('Oops! Something went wrong')
              this.serverErrors = response.errors
            } else {
              this.squareTerminalCheckOut = response
              this.$emit('show-terminal-progress', this.squareTerminalCheckOut)
              this.$nextTick(() => {
                this.onClose(true)
              })
            }
          }).catch((error) => {
            this.failedToast('Oops! Something went wrong')
            this.isSaving = false
            this.serverErrorMessage = error.message
          })
        } else {
          this.$goToFirstValidationError(this.$validator.errors.items)
        }
      })
    },
    saveCashCheckPayment: function () {
      let that = this

      that.$validator.validateAll('recipient-form').then((result) => {
        if (result && this.selectedRecipients.length > 0) {
          if (!this.isDepositConverting) {
            if (this.checkForDuplicateExistingPayments()) return
          }

          let paymentEvents = [{
            buyersId: this.getPurchaserId,
            buyersType: this.getBuyerType,
            amount: this.amount,
            payerName: this.payerName,
            attachments: [],
            isManualSquarePayment: false,
            overridingManagerId: this.selectedOverridingManager ? this.selectedOverridingManager.id : null,
            depositAcceptedById: this.selectedDepositAcceptedBy ? this.selectedDepositAcceptedBy.id : null
          }]

          let type = 'Check'
          if (this.isCashPayment) {
            type = 'Cash'
          } else if (this.isOtherPayment) {
            type = 'Other'
          }

          this.files = this.files.filter((x) => x.status === 'Active')
          if (this.files.length > 0) {
            var pdfFiles = this.files.map((x) => {
              return {
                uploadName: x.name,
                uploadSize: x.size,
                type: x.type,
                classType: type,
                uniqueId: x.uniqueId
              }
            })
            paymentEvents[0].attachments.push(...pdfFiles)
          }

          let paymentTracking = {
            dealId: this.deal ? this.deal.id : null,
            dealerId: this.currentDealer.id,
            buyersId: this.getPurchaserId,
            buyersType: this.getBuyerType,
            paymentStatus: this.selectedPaymentType ? this.selectedPaymentType.altName : 'PendingInAccounting',
            type: type,
            amount: this.amount,
            dealCode: this.deal ? this.deal.code : null,
            paymentEvents: paymentEvents,
            notes: [],
            dealSaleType: this.isWholesale ? 'Wholesale' : 'Retail',
            subscriberId: this.currentDealer.subscriberId,
            convertFromId: this.isDepositConverting ? this.value.id : 0
          }

          var note = this.notes.length > 0 ? this.notes[this.notes.length - 1] : null
          if (note !== null) {
            paymentTracking.notes.push(note)
          }

          let serviceToCall = null
          if (this.isRoServiceDeposit) {
            paymentTracking.category = 'RoService'
            paymentTracking.roNumber = this.isDepositConverting ? this.value.roNumber : this.currentRoService.roNumber
            let roServiceDepositModel = {
              roServiceId: this.isDepositConverting ? this.value.roId : this.currentRoService.id,
              printRequestId: this.isDepositConverting ? this.value.printRequestId : (this.currentRoService.invoicePrintRequestId > 0 ? this.currentRoService.invoicePrintRequestId : this.currentRoService.workOrderPrintRequestId),
              amount: this.amount,
              paymentTrackingModel: paymentTracking
            }
            serviceToCall = roServiceService['createPaidDeposit'](roServiceDepositModel)
          } else if (this.isPartsDeposit) {
            paymentTracking.category = 'Parts'
            paymentTracking.partsId = this.partsId
            let partsDepositModel = {
              partsId: paymentTracking.partsId,
              printRequestId: this.isDepositConverting ? this.value.printRequestId : (this.currentParts.invoicePrintRequestId > 0 ? this.currentParts.invoicePrintRequestId : 0),
              amount: this.amount,
              paymentTrackingModel: paymentTracking
            }
            serviceToCall = partsService['createPaidDeposit'](partsDepositModel)
          } else {
            serviceToCall = paymentTrackingService['create'](paymentTracking)
          }

          this.isSaving = true
          serviceToCall.then(response => {
            this.isSaving = false
            if (response.errors) {
              this.failedToast('Oops! Something went wrong')
              this.serverErrors = response.errors
            } else {
              this.successToast('Success! ' + this.paymentType + ' deposit saved')

              // give user the option to mark as paid if converted to 'PendingInAccounting'
              if (this.isDepositConverting && this.selectedPaymentType && this.selectedPaymentType.altName === 'PendingInAccounting') {
                this.currentPaymentTracking = response
                this.currentPaymentTracking.customerName = this.currentPaymentTracking.customerName !== '' ? this.currentPaymentTracking.customerName : this.payerName
                this.$refs.markDepositPaid.onLaunchConfirmPaidDeposit()
                return
              }

              this.onClose(true)
            }
          }).catch((error) => {
            this.failedToast('Oops! Something went wrong')
            this.isSaving = false
            this.serverErrorMessage = error.message
          })
        } else {
          this.$goToFirstValidationError(this.$validator.errors.items)
        }
      })
    },
    defaultRetailCustomer: function () {
      // make the customer or supplier that is attached to this deal selected by default
      if (this.deal && (this.getPurchaserContactEmail !== '' || this.getPurchaserContactMobile !== '')) {
        let proposedName = this.deal.purchaserContactName !== '' ? this.deal.purchaserContactName : this.deal.purchaserName
        var data = {
          fullName: proposedName,
          mobile: this.getPurchaserContactMobile,
          id: this.getPurchaserId,
          email: this.getPurchaserContactEmail,
          fullNameEmail: proposedName + ' - ' + this.getPurchaserContactEmail + '  ' + this.getPurchaserContactMobile,
          firstName: this.deal.purchaserContactFirstName,
          lastName: this.deal.purchaserContactLastName,
          name: '',
          companyName: this.getCustomerCompanyName
        }
        this.onRecipientAdded(data)
      }

      // not coming from deal but straight from customer quickview
      if (this.customer && (this.customer.email !== '' || this.customer.mobile !== '')) {
        var dataQv = {
          fullName: this.customer.displayName,
          mobile: this.customer.mobile,
          id: this.customer.id,
          email: this.customer.email,
          fullNameEmail: this.customer.displayName + ' - ' + this.customer.email + '  ' + this.customer.mobile,
          firstName: this.customer.firstName,
          lastName: this.customer.lastName,
          name: '',
          companyName: this.getCustomerCompanyName
        }
        this.onRecipientAdded(dataQv)
      }

      if (this.isCompanySetForCustomer) this.selectedName = this.customerNames[0]
    },
    defaultWholesaleCustomer: function () {
      // make the customer or supplier that is attached to this deal selected by default
      if (this.deal && (this.getPurchaserContactEmail !== '' || this.getPurchaserContactMobile !== '')) {
        let proposedName = this.deal.purchaserContactName !== '' ? this.deal.purchaserContactName : this.deal.purchaserName
        var data = {
          fullName: proposedName,
          mobile: this.getPurchaserContactMobile,
          id: this.getPurchaserId,
          email: this.getPurchaserContactEmail,
          fullNameEmail: proposedName + ' - ' + this.getPurchaserContactEmail + '  ' + this.getPurchaserContactMobile,
          firstName: this.deal.purchaserContactFirstName,
          lastName: this.deal.purchaserContactLastName,
          name: this.deal.purchaserName,
          companyName: this.deal.purchaserName
        }
        this.onRecipientAdded(data)
      }

      // not coming from deal but straight from supplier quickview
      if (this.supplier && (this.supplier.primaryContactEmail !== '' || this.supplier.primaryContactMobile !== '')) {
        var dataQv = {
          fullName: this.supplier.primaryContact,
          mobile: this.supplier.primaryContactMobile,
          id: this.supplier.id,
          email: this.supplier.primaryContactEmail,
          fullNameEmail: this.supplier.primaryContact + ' - ' + this.supplier.primaryContactEmail + '  ' + this.supplier.primaryContactMobile,
          firstName: this.supplier.primaryContactFirstName,
          lastName: this.supplier.primaryContactLastName,
          name: this.supplier.name,
          companyName: this.supplier.name
        }
        this.onRecipientAdded(dataQv)
      }
    },
    defaultRoCustomer: function () {
      var data = {
        fullName: this.getPurchaserContactName,
        mobile: this.getPurchaserContactMobile,
        id: this.getPurchaserId,
        email: this.getPurchaserContactEmail,
        fullNameEmail: this.getPurchaserContactName + ' - ' + this.getPurchaserContactEmail + '  ' + this.getPurchaserContactMobile,
        firstName: (this.isDepositConverting) ? this.customer.firstName : this.currentRoService.roServiceCustomer.firstName,
        lastName: (this.isDepositConverting) ? this.customer.firstName : this.currentRoService.roServiceCustomer.lastName,
        name: '',
        companyName: ''
      }
      this.onRecipientAdded(data)
    },
    defaultPartsCustomer: function () {
      var data = {
        fullName: this.getPurchaserContactName,
        mobile: this.getPurchaserContactMobile,
        id: this.getPurchaserId,
        email: this.getPurchaserContactEmail,
        fullNameEmail: this.getPurchaserContactName + ' - ' + this.getPurchaserContactEmail + '  ' + this.getPurchaserContactMobile,
        firstName: (this.isDepositConverting) ? this.customer.firstName : this.currentParts.partsCustomer.firstName,
        lastName: (this.isDepositConverting) ? this.customer.firstName : this.currentParts.partsCustomer.lastName,
        name: '',
        companyName: ''
      }
      this.onRecipientAdded(data)
    },
    getPaymentStatusClass: function () {
      let status = this.getPaymentStatus

      if (status === '') {
        return {'has-background-white': true}
      }

      let classToAttach = {}
      switch (status) {
        case 'Paid':
          classToAttach['has-background-primay-green'] = true
          break
        case 'Cancelled':
          // do not show colours for cancelled  or refund
          // classToAttach['has-background-danger'] = true
          break
        case 'Refund':
          // do not show colours for cancelled  or refund
          // classToAttach['has-background-danger'] = true
          break
        case 'Not Yet Paid':
          classToAttach['has-background-warning'] = true
          break
        case 'Payment Pending':
          classToAttach['has-background-warning'] = true
          break
        case 'Pending In Accounting':
          classToAttach['has-background-primay-green'] = true
          break
        default:
          classToAttach['has-background-white'] = true
          break
      }
      return classToAttach
    },
    onCustomerSaved: function (editCustomer) {
      if (editCustomer.id === this.getPurchaserId) {
        if (this.deal) {
          this.deal.purchaserContactEmail = editCustomer.email ? editCustomer.email : ''
          this.deal.purchaserContactMobile = editCustomer.mobile ? editCustomer.mobile : ''
          this.deal.purchaserContactName = editCustomer.firstName + ' ' + editCustomer.lastName
        } else {
          this.customer = editCustomer
          this.customer.displayName = this.customer.firstName + ' ' + this.customer.lastName
        }

        var data = {
          fullName: this.getPurchaserContactName,
          mobile: this.getPurchaserContactMobile,
          id: this.getPurchaserId,
          email: this.getPurchaserContactEmail,
          fullNameEmail: this.getPurchaserContactName + ' - ' + this.getPurchaserContactEmail + '  ' + this.getPurchaserContactMobile,
          name: ''
        }
        this.selectedRecipients = []
        this.onRecipientAdded(data)
      }
      this.editCustomerActive = false
      this.paymentControlActive = true
      this.onLoadHistoricalPayments()
    },
    onPartsCustomerSaved: function (editCustomer) {
      if (editCustomer.id === this.getPurchaserId) {
        var data = {
          fullName: this.getPurchaserContactName,
          mobile: editCustomer.cellularPhoneNumber,
          id: editCustomer.id,
          email: editCustomer.contactEmailAddress,
          fullNameEmail: this.getPurchaserContactName + ' - ' + editCustomer.contactEmailAddress + '  ' + editCustomer.cellularPhoneNumber,
          name: ''
        }

        this.selectedRecipients = []
        this.onRecipientAdded(data)
      }
      this.editCustomerActive = false
      this.paymentControlActive = true
      this.onLoadHistoricalPayments()
    },
    onRoServiceCustomerSaved: function (editCustomer) {
      if (editCustomer.id === this.getPurchaserId) {
        var data = {
          fullName: this.getPurchaserContactName,
          mobile: editCustomer.cellularPhoneNumber,
          id: editCustomer.id,
          email: editCustomer.contactEmailAddress,
          fullNameEmail: this.getPurchaserContactName + ' - ' + editCustomer.contactEmailAddress + '  ' + editCustomer.cellularPhoneNumber,
          name: ''
        }
        this.selectedRecipients = []
        this.onRecipientAdded(data)
      }
      this.editCustomerActive = false
      this.paymentControlActive = true
      this.onLoadHistoricalPayments()
    },
    onSupplierSaved: function (editSupplier) {
      if (editSupplier.id === this.getPurchaserId) {
        let primaryContact = editSupplier.contacts.find((x) => x.status === 'Active' && x.isPrimary)
        this.supplier = editSupplier
        if (this.deal) {
          this.deal.purchaserContactName = primaryContact.firstName + ' ' + primaryContact.lastName
          this.deal.purchaserContactEmail = primaryContact.email ? primaryContact.email : ''
          this.deal.purchaserContactMobile = primaryContact.mobile ? primaryContact.mobile : ''
        } else {
          this.supplier.primaryContact = primaryContact.firstName + ' ' + primaryContact.lastName
          this.supplier.primaryContactEmail = primaryContact.email ? primaryContact.email : ''
          this.supplier.primaryContactMobile = primaryContact.mobile ? primaryContact.mobile : ''
        }

        var data = {
          fullName: this.getPurchaserContactName,
          mobile: this.getPurchaserContactMobile,
          id: this.getPurchaserId,
          email: this.getPurchaserContactEmail,
          fullNameEmail: this.getPurchaserContactName + ' - ' + this.getPurchaserContactEmail + '  ' + this.getPurchaserContactMobile,
          name: this.supplier.name
        }

        this.selectedRecipients = []
        this.onRecipientAdded(data)
      }
      this.editCustomerActive = false
      this.paymentControlActive = true
      this.onLoadHistoricalPayments()
    },
    onPurchaserCancelled: function () {
      this.editCustomerActive = false
      this.paymentControlActive = false
    },
    onEditPurchaser: function () {
      this.editCustomerActive = true
      this.paymentControlActive = false
    },
    async onDropdownItemClick (dropDownObject) {
      if (dropDownObject.printRequestId && dropDownObject.printRequestId > 0) this.dropDownSelectedPrintRequestId = dropDownObject.printRequestId

      if (dropDownObject.type === 'squareterminalSign') {
        this.paymentType = 'squareterminal'
        this.signOnly = true
        await this.openModal()

        // auto move to the next tab as we just requesting a signature
        this.next()

        return
      }

      this.signOnly = false
      this.paymentType = dropDownObject.type
      await this.openModal()
    },
    async openModal () {
      await this.setDefaults()

      if (this.crudMode === 'create' && this.isSquarePayment === true && this.getPurchaserContactEmail === '') {
        this.onEditPurchaser()
        return
      }

      if (this.crudMode === 'create' && !this.isSquarePayment && this.getPurchaserContactMobile === '' && this.getPurchaserContactEmail === '') {
        this.onEditPurchaser()
        return
      }

      if (!this.isRoCustomerNewDepartment && !this.isRoCustomerUsedDepartment) {
        this.showOnlyCurrentRo = true
      }

      this.paymentControlActive = true
      this.onLoadHistoricalPayments()
    },
    async setDefaults () {
      this.selectedInvoiceItem = null
      this.selectedSquareLocation = null
      this.selectedInvoiceTaxes = []
      this.setDefaultsCreate()
      await this.setDefaultsUpdate()
      this.initPurchaserData()
    },
    setDefaultsCreate: function () {
      // deal should be loaded via prop, no need to go to server
      if (this.crudMode === 'create' && this.deal) {
        if (this.isDepositConverting) {
          this.amount = this.value.amount
        } else {
          this.amount = this.deal.totalSalesValue || ''
        }
        this.invoiceTitle = (this.firstSoldItemInventory) ? this.firstSoldItemInventory.name : this.deal.purchaserName

        if (this.isSquareTerminalPayment) {
          this.squareTerminalCheckOut = {
            amount: this.amount,
            note: this.invoiceTitle + ' ' + this.prefixInvoiceNo,
            collectSignature: true,
            signatureBody: this.getSignatureBody,
            signOnly: this.signOnly,
            itemId: '',
            taxIds: []
          }
        }

        return
      }

      if (this.isRoServiceDeposit) {
        if (this.isDepositConverting) {
          this.amount = this.value.amount
        } else {
          this.amount = this.currentRoService.invoiceAmount ? this.currentRoService.invoiceAmount : this.currentRoService.workOrderAmount ? this.currentRoService.workOrderAmount : 0
        }

        if (this.isSquareTerminalPayment) {
          let roNum = 'RO'
          if (this.isRoServiceDeposit) {
            roNum += this.isDepositConverting ? this.value.roNumber : this.currentRoService.roNumber
          }

          this.squareTerminalCheckOut = {
            amount: this.amount,
            note: roNum,
            collectSignature: true,
            signatureBody: this.getSignatureBody,
            signOnly: this.signOnly,
            itemId: '',
            taxIds: []
          }
        }

        return
      }

      if (this.isPartsDeposit) {
        if (this.isDepositConverting) {
          this.amount = this.value.amount
        } else {
          this.amount = this.currentParts.invoiceAmount ? this.currentParts.invoiceAmount : 0
        }

        if (this.isSquareTerminalPayment) {
          this.squareTerminalCheckOut = {
            amount: this.amount,
            note: 'Inv# ' + this.currentParts.invoiceNumber,
            collectSignature: true,
            signatureBody: this.getSignatureBody,
            signOnly: this.signOnly,
            itemId: '',
            taxIds: []
          }
        }

        return
      }

      this.amount = this.value ? this.value.amount : 0

      if (this.isSquareTerminalPayment) {
        this.squareTerminalCheckOut = {
          amount: this.amount,
          note: '',
          collectSignature: true,
          signatureBody: this.getSignatureBody,
          signOnly: this.signOnly,
          itemId: '',
          taxIds: []
        }
      }
    },
    async setDefaultsUpdate () {
      if (this.crudMode === 'update') {
        this.amount = this.currentPaymentTracking.amount || ''

        if (this.currentPaymentTracking && this.currentPaymentTracking.dealId && this.paymentType === 'square') {
          await this.loadDeal(this.currentPaymentTracking.dealId)
        }
      }
    },
    async loadDeal (id) {
      if (id) {
        this.serverErrorMessage = ''
        this.isLoading = true
        await dealService.single(id).then(response => {
          this.isLoading = false
          this.deal = response.deal
        }).catch((error) => {
          this.isLoading = false
          this.serverErrorMessage = error.message
        })
      }
    },
    async loadSquareData () {
      await this.loadSquareCatalogs()
      if (this.crudMode === 'update' && this.currentPaymentTracking && this.currentPaymentTracking.sqInvoiceId !== '') {
        await this.loadSquareInvoice(this.currentPaymentTracking.sqInvoiceId)
        await this.loadSquareOrder(this.squareInvoiceDownload.order_id)
      }
    },
    async loadSquareInvoice (sqInvoiceId) {
      this.squareConnectionStatus.isLoading = true
      await squareService.getInvoice(sqInvoiceId).then(response => {
        this.squareConnectionStatus.isLoading = false
        if (response.errors) {
          this.squareConnectionStatus.serverErrorMessage = response.errors[0].errorMessage
        } else {
          this.squareInvoiceDownload = response
          this.setSquareInvoiceValues()
        }
      }).catch((error) => {
        this.squareConnectionStatus.isLoading = false
        this.squareConnectionStatus.serverErrorMessage = error.message
      })
    },
    async loadSquareOrder (sqOrderId) {
      this.squareConnectionStatus.isLoading = true
      await squareService.getOrder(sqOrderId).then(response => {
        this.squareConnectionStatus.isLoading = false
        if (response.errors) {
          this.squareConnectionStatus.serverErrorMessage = response.errors[0].errorMessage
        } else {
          this.squareOrderDownload = response
          this.setSquareOrderValues()
        }
      }).catch((error) => {
        this.squareConnectionStatus.isLoading = false
        this.squareConnectionStatus.serverErrorMessage = error.message
      })
    },
    async loadSquareCatalogs () {
      this.squareConnectionStatus.isLoading = true
      await squareService.getCatalogs().then(response => {
        this.squareConnectionStatus.isLoading = false
        if (response.errors) {
          this.squareConnectionStatus.serverErrorMessage = response.errors[0].errorMessage
        } else {
          this.squareCatalogDownload = response
          this.setSquareDefaults()
        }
      }).catch((error) => {
        this.squareConnectionStatus.isLoading = false
        this.squareConnectionStatus.serverErrorMessage = error.message
      })
    },
    setSquareInvoiceValues () {
      this.invoiceTitle = (this.squareInvoiceDownload) ? this.squareInvoiceDownload.title : ''
      this.invoiceVersionNo = this.squareInvoiceDownload.version
      this.sqInvoiceId = this.squareInvoiceDownload.id
      this.sqInvoicePaymentRequestId = this.squareInvoiceDownload.payment_requests[0].uid
    },
    setSquareOrderValues () {
      if (this.squareOrderDownload) {
        this.selectedInvoiceItem = this.squareItems.find(x => x.variationItemId === this.squareOrderDownload.line_items[0].catalog_object_id)
        this.orderLineItemUid = this.squareOrderDownload.line_items[0].uid
        this.orderVersionNo = this.squareOrderDownload.version
      }
    },
    setSquareDefaults: function () {
      let partialPaymentItem = this.squareItems.find(x => x.name.toLowerCase().includes('partial payment'))
      if (partialPaymentItem) {
        this.selectedInvoiceItem = partialPaymentItem
      }
    },
    onSquareLocationError: function (error) {
      if (error) {
        this.squareConnectionStatus.serverErrorMessage = error.message
        this.serverErrorMessage = error.message
      }
    },
    onPopFillPayerName: function () {
      this.payerName = this.getExpectedWhereDidMoneyComeFrom
    },
    onPopFillUserName: function () {
      this.selectedDepositAcceptedBy = this.currentUser
    },
    checkCustomerMismatch: function (name) {
      if (name && name.length > 0) {
        return this.getExpectedWhereDidMoneyComeFrom.toLowerCase().trim() !== name.toLowerCase().trim()
      }
      return true
    },
    buildTitle: function () {
      if (this.isRoServiceDeposit && this.roServiceNote && this.roServiceNote.length > 0) {
        return this.roServiceNote
      }

      if (this.isWholesaleReceivableListMode) {
        let row = this.listItemDealInfo
        return this.buildPaymentStatusTitle(row)
      }

      let row = {
        paymentTrackingStatus: this.getPaymentStatus,
        wirePaymentReceived: this.getTotalWireReceived,
        squarePaymentReceived: this.getTotalSquareReceived,
        cashPaymentReceived: this.getTotalCashReceived,
        checkPaymentReceived: this.getTotalCheckReceived,
        otherPaymentReceived: this.getTotalOtherReceived,
        paymentTotalReceived: this.getPaymentTotalReceived,
        paymentControlInitiatedFromBuyer: this.paymentControlInitiatedFromBuyer
      }
      return this.buildPaymentStatusTitle(row)
    },
    inProgressDealsByBuyer: function (buyerId) {
      this.isLoading = true
      this.serverErrorMessage = ''
      this.serverErrors = []
      var type = !this.isWholesaleProp ? 'Individual' : 'Wholesaler'

      dealService.getInProgressDealsByPurchaser(buyerId, this.currentDealer.id, type).then(response => {
        this.isLoading = false
        if (response.errors) {
          this.serverErrors = response.errors
          return
        }
        this.selectedBuyerDeals = response.results
        if (this.selectedBuyerDeals && this.selectedBuyerDeals.length > 0) {
          let dealExistingComponent = this.$refs[this.dealExistingComponent]
          if (dealExistingComponent) {
            dealExistingComponent.onOpenModal()
          }
        } else {
          this.paymentControlActive = true
          this.onLoadHistoricalPayments()
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    onSelectDeal: function (deal) {
      this.deal = deal
      this.openModal()
    },
    getMatchingPaymentStatus: function (statusValue) {
      let result = this.definedTypes.paymentTrackingStatuses.options.find((x) => x.id === statusValue)
      if (result && result.name) {
        return result.name
      }
      return ''
    },
    resendPaymentTrackingWireRequest: function (selectedPaymentRequest) {
      this.resendWirePaymentRequestActive = false
      this.loading = true
      let that = this
      paymentTrackingService.resendPaymentTrackingWireRequest(selectedPaymentRequest.id).then(response => {
        if (response.errors) {
          that.failedToast('Oops! Something went wrong')
          that.serverErrors = response.errors
        } else {
          that.successToast('Success! Payment request queued for resending.')
          that.onClose(false)
        }
        that.loading = false
      }).catch((error) => {
        that.serverErrorMessage = error.message
        that.loading = false
      })
      this.loading = true
    },
    canResendDepositRequest: function (payment) {
      return payment.paymentStatus !== this.definedTypes.paymentTrackingStatuses.paid && payment.paymentStatus !== this.definedTypes.paymentTrackingStatuses.pendingInAccounting
    },
    canCancelPaymentRequest: function (payment) {
      if (!this.hasDepositModifyAccess) return false
      if (payment.paymentType === this.definedTypes.paymentTrackingTypes.square || payment.paymentType === this.definedTypes.paymentTrackingTypes.squareTerminal) {
        return payment.paymentStatus === this.definedTypes.paymentTrackingStatuses.notYetPaid
      }
      return payment.paymentStatus !== this.definedTypes.paymentTrackingStatuses.cancelled
    },
    onLaunchStatusChangeModal: function (id, data, amount, amountPaid) {
      this.changeStatusId = id
      if (data === '') {
        if (amount === amountPaid) {
          data = 'Paid'
        } else {
          data = 'NotYetPaid'
        }
      }
      this.changeStatusTo = data
      this.paymentControlActive = false
      this.confirmStatusChangeActive = true
    },
    onConfirmStatusChange: function (save) {
      if (save) {
        this.isSaving = true
        this.serverErrorMessage = ''
        this.serverErrors = []

        paymentTrackingService.updateStatus(this.changeStatusId, this.changeStatusTo).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.isLoading = true
            this.onLoadHistoricalPayments()
            this.paymentControlActive = true
            this.confirmStatusChangeActive = false
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
        })
      } else {
        this.changeStatusId = null
        this.changeStatusTo = null
        this.confirmStatusChangeActive = false
        this.paymentControlActive = true
      }
    },
    onConfirmCancelAllExistingPaymentRequests: function (save) {
      if (save) {
        this.matchingPaymentRequestList.forEach(paymentRequest => {
          if (this.canCancelPaymentRequest(paymentRequest)) {
            this.isSaving = true
            this.serverErrorMessage = ''
            this.serverErrors = []
            paymentTrackingService.updateStatus(paymentRequest.id, 'Cancelled').then(response => {
              this.isSaving = false
              if (response.errors) {
                this.serverErrors = response.errors
              }
            }).catch((error) => {
              this.isSaving = false
              this.serverErrorMessage = error.message
            })
          }
        })
        this.confirmBatchCancelActive = false
        this.onConfirmQueueNotification()
      } else {
        this.confirmBatchCancelActive = false
      }
    },
    getMatchingPaymentType: function (typeValue) {
      let result = this.definedTypes.paymentTrackingTypes.options.find((x) => x.id === typeValue)
      if (result && result.name) {
        return result.name
      }
      return ''
    },
    next: function () {
      this.tabIndex = 1
    },
    previous: function () {
      this.tabIndex = 0
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    sortDealPaymentTrackings: function () {
      if (this.deal && this.deal.paymentTrackings) {
        this.deal.paymentTrackings.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      }
    },
    async wholeSalePrepareModal () {
      this.showLoader = true
      await this.loadDeal(this.currentDealId)
      this.currentDeal = this.deal
      this.showLoader = false
      this.sortDealPaymentTrackings()
      this.onDropdownItemClick({type: 'wire', printRequestId: this.selectedPrintRequestId})
    },
    async convertPrepareModal () {
      this.showLoader = true
      await this.loadDeal(this.currentDealId)

      if (this.value.buyersType === 'Customer') {
        this.showLoader = true
        await this.loadCustomer()
      }

      if (this.value.buyersType === 'Supplier') {
        this.showLoader = true
        await this.loadSupplier()
      }

      if (this.value.buyersType === 'RoServiceCustomer') {
        this.showLoader = true
        await this.loadRoCustomer()
      }

      if (this.value.buyersType === 'PartsCustomer') {
        this.showLoader = true
        await this.loadPartsCustomer()
      }

      this.showLoader = false
      this.onDropdownItemClick({type: this.convertTo.toLowerCase(), printRequestId: this.selectedPrintRequestId})
    },
    async loadCustomer () {
      this.isLoading = true
      await customerService.single(this.purchaser.id).then(response => {
        this.customer = response.customer
        this.customer.displayName = this.customer.firstName + ' ' + this.customer.lastName
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    async loadRoCustomer () {
      this.isLoading = true
      await roServiceService.fetchCustomerById(this.purchaser.id).then(response => {
        this.customer = response.customer
        this.customer.displayName = this.customer.firstName + ' ' + this.customer.lastName
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    async loadPartsCustomer () {
      this.isLoading = true
      await partsService.fetchCustomerById(this.purchaser.id).then(response => {
        this.customer = response.customer
        this.customer.displayName = this.customer.firstName + ' ' + this.customer.lastName
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    async loadSupplier () {
      this.isLoading = true
      await supplierService.single(this.purchaser.id).then(response => {
        this.supplier = response.supplier
        this.isLoading = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
      })
    }
  },
  watch: {
    selectedInvoiceItem: function (newValue, oldValue) {
      this.selectedInvoiceTaxes = []

      // if in update mode and loading for first time, get tax values from saved order else get tax values from item defaults
      if (this.crudMode === 'update' && !oldValue && newValue && this.squareOrderDownload && this.squareOrderDownload.taxes.length > 0) {
        this.squareOrderDownload.taxes.forEach((tax) => {
          if (tax) {
            this.selectedInvoiceTaxes.push({id: tax.catalog_object_id, name: tax.name + ' ' + tax.percentage + '%'})
          }
        })
      } else if (newValue && newValue.taxIds && newValue.taxIds.length > 0) {
        newValue.taxIds.forEach((taxId) => {
          let tax = this.squareTaxes.find(x => x.id === taxId)
          if (tax) {
            this.selectedInvoiceTaxes.push({id: tax.id, name: tax.name})
          }
        })
      }
    },
    selectedSquareLocation: function (newValue, oldValue) {
      if (newValue && this.paymentType === 'square') {
        this.loadSquareData()
      }
    },
    payerName: function (newValue, oldValue) {
      this.isCustomerMismatch = this.checkCustomerMismatch(newValue)
    },
    selectedName: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.selectedRecipients[0].fullName = newValue.name
        this.selectedRecipients[0].fullNameEmail = newValue.name + ' - ' + this.customer.email + '  ' + this.customer.mobile
      }
    },
    currentDeal: function (newValue, oldValue) {
      if (newValue && newValue.id) {
        this.deal = newValue
        this.sortDealPaymentTrackings()
      }
    },
    convertTo: function (newValue, oldValue) {
      if (newValue && newValue !== '' && this.isDepositConverting) {
        this.convertPrepareModal()
      }
    },
    showOnlyCurrentRo: function (newValue, oldValue) {
      this.matchingPaymentRequestList = []
      if (newValue && this.isRoServiceDeposit) {
        let currentRoNumber = this.isDepositConverting ? this.value.roNumber : this.currentRoService.roNumber
        this.matchingPaymentRequestList = this.initialMatchingPaymentRequestList.filter((x) => x.roNumber === currentRoNumber)
      } else {
        this.matchingPaymentRequestList = _.cloneDeep(this.initialMatchingPaymentRequestList)
      }
    }
  },
  mounted: function () {
    this.sortDealPaymentTrackings()
    eventBus.$on('on-open-send-wire-control', this.openModal)
    eventBus.$on('close-payment-control', this.onClose)
  },
  beforeDestroy () {
    eventBus.$off('close-payment-control', this.onClose)
  }
}

</script>

<style scoped>
  .modal-warning {
    height: calc(20vh - 10px) !important;
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-card-body {
    position:relative;
    max-height: 70vh !important;
    height: 70vh !important;
    max-width: 100% !important;
    min-width: 100% !important;
    overflow-y: auto !important;
  }

  .dropdown-btn {
    border: 1px dashed #e0e0e3;
    border-radius: 50%;
    background: white;
    width: 2.25em;
    color: #4b6271;
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(80vh - 10px);
  }

  .notification {
    line-height: 1.5 !important;
  }

</style>

<template>
  <section style="margin: 0px; padding: 0px;">
    <div v-if="!isWireRequest  && !hideActionIcon">
      <div class="quick-action is-pulled-left" v-if="!isVerified && !isListMode">
      <button type="button" class="button" @click="onOpen()"  title="Click to verify customer">
        <b-icon pack="fal" icon="fal fa-badge-check has-text-danger" :custom-size="iconCustomSize"></b-icon>
      </button>
      </div>
      <div v-if="!isVerified && isListMode">
        <span @click.stop.prevent="onOpen()" style="cursor: pointer;"><b-icon pack="fal" icon="fal fa-badge-check" class="has-text-danger" :custom-size="iconCustomSize" title="Click to verify customer"></b-icon></span>
      </div>

      <div class="quick-action" v-if="isVerified && !isListMode">
        <button type="button" class="button" title="Customer verified">
          <span @click.stop.prevent="onOpen()" style="cursor: pointer;">
            <b-icon pack="fal" icon="fal fa-badge-check has-text-success" :custom-size="iconCustomSize"></b-icon>
          </span>
        </button>
      </div>
      <div v-if="isVerified && isListMode">
        <span @click.stop.prevent="onOpen()" style="cursor: pointer;"><b-icon pack="fal" icon="fal fa-badge-check" class="has-text-success" :custom-size="iconCustomSize" title="Click to verify customer"></b-icon></span>
      </div>
    </div>

    <div v-if="isWireRequest && !hideActionIcon ">
      <div v-if="!isVerified || !hasCompletedRequestedWireQuestions">
        <span @click.stop.prevent="onOpen()" style="cursor: pointer;"><b-icon pack="fal" icon="fal fa-badge-check" class="has-text-danger" :custom-size="iconCustomSize" title="Click to verify customer"></b-icon></span>
      </div>
      <div v-if="isVerified && hasCompletedRequestedWireQuestions">
        <span @click.stop.prevent="onOpen()" style="cursor: pointer;"><b-icon pack="fal" icon="fal fa-badge-check" class="has-text-success" :custom-size="iconCustomSize" title="Click to verify customer"></b-icon></span>
      </div>
    </div>

    <portal to="global-modal-portal" v-if="verifyCustomerControlActive" :disabled="!usePortal">
      <b-modal :active="verifyCustomerControlActive" scroll="keep" has-modal-card full-screen  :canCancel="['escape', 'x']" id="verify-modal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{title}}</p>
          </header>
          <section class="modal-card-body" style="padding: 10px 10px;">
            <div class="box has-background-grey-lighter">
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q1" v-show="permittedQuestions.includes('q1')">Did you call the customer?</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q2" v-show="permittedQuestions.includes('q2')">Did you verify the customer details and address info?</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q3" v-show="permittedQuestions.includes('q3')">Did you verify that this request was made by this customer with the proper payment information?</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q5" v-show="permittedQuestions.includes('q5')">Ask them, "Did you request this wire?"</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q6" v-show="permittedQuestions.includes('q6')">Ask them, "Read me the wire instructions to make sure we are sending to the right place."</b-checkbox>
              </div>
                <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q4" v-show="permittedQuestions.includes('q4')">Have the customer read you the wire instructions, then verify against our records. <b>DO NOT READ WHAT WE HAVE TO THE CUSTOMER</b></b-checkbox>
              </div>
            </div>
            <div class="tile is-ancestor">
              <div class="tile is-vertical is-parent">
                <div v-if="customer.isBlocked" class="notification is-danger is-flex">
                  <span>
                    <i class="fas fa-exclamation-triangle is-danger fa-lg"></i>
                  </span>
                  <span class="ml-10">
                    <p>Cannot verify BLOCKED customer. Click definition tab below to update.</p>
                  </span>
                </div>
                <form @submit.prevent="validateBeforeSubmitAllScoped">
                <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
                <div class="tile is-child box">
                  <b-tabs size="is-small" v-model="tabIndex" :animated="false" type="is-toggle">
                    <b-tab-item label="Call" icon-pack="fas" icon="fal fa-phone fa-sm">
                      <div class="box" style="background: #f6f8fa;">
                        <div class="notification is-info">Your number: <strong>{{trackingNumber}}</strong></div>
                        <table class="table is-fullwidth" v-if="callLines.length > 0">
                          <thead>
                            <tr>
                              <th><abbr title="Name">Name</abbr></th>
                              <th><abbr title="Line">Line</abbr></th>
                              <th><abbr title="Action">Action</abbr></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(callLine, index) in callLines" :key="callLine.name + index">
                              <td>{{callLine.name}}</td>
                              <td>{{callLine.line}}</td>
                              <td>
                                <span class="quick-action">
                                  <button type="button" class="button" :class="{'is-loading': callLine.callInitiated }" @click="onInitiateCall(callLine)">
                                    <b-icon pack="fal" icon="fal fa-phone" custom-size="fa-lg" :class="{'has-text-danger': callLine.callFailed }"></b-icon>
                                  </button>
                                </span>
                                {{( callLine.callInitiated) ? 'initiating call...' : ''}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="field is-size-7" v-if="callLines.length === 0">No customer phone numbers found.</div>
                        <div class="columns is-size-7 is-multiline">
                          <div class="column is-12">
                            <b-checkbox v-model="chkUseAlternateLine">Alternate lines to dial?</b-checkbox>
                          </div>
                          <div class="column is-4" v-if="chkUseAlternateLine">
                            <label class="label">Your Number<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                            <input name="alternateLine" v-validate="{'min': 16, 'max': 16}" :class="{'input': true, 'is-danger': errors.has('alternateLine') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="alternateLine" data-lpignore="true" autocomplete="off">
                            <span v-show="errors.has('alternateLine')" class="help is-danger">
                              <i v-show="errors.has('alternateLine')" class="fas fa-exclamation-triangle"></i> {{ errors.first('alternateLine') }}
                            </span>
                          </div>
                          <div class="column is-4" v-if="chkUseAlternateLine">
                            <label class="label">Customer Number<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                            <input name="alternateCustomerLine" v-validate="{'min': 16, 'max': 16}" :class="{'input': true, 'is-danger': errors.has('alternateCustomerLine') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="alternateCustomerLine" data-lpignore="true" autocomplete="off">
                            <span v-show="errors.has('alternateCustomerLine')" class="help is-danger">
                              <i v-show="errors.has('alternateCustomerLine')" class="fas fa-exclamation-triangle"></i> {{ errors.first('alternateCustomerLine') }}
                            </span>
                          </div>
                          <div class="column is-1 control" style="margin-top: 5px;" v-if="chkUseAlternateLine">
                            <label class="label">&nbsp;</label>
                            <span class="quick-action">
                              <button type="button" class="button" style="margin-top: -6px;" @click="onInitiateAlternateCall()" :class="{'is-loading': alternateCallInitiated }" :disabled="isAlternateLineBtnDisabled">
                                <b-icon pack="fal" icon="fal fa-phone" custom-size="fa-lg" :class="{'has-text-danger': alternateCallFailed }"></b-icon>
                              </button>
                            </span>
                          </div>
                          <div class="column is-2" v-if="chkUseAlternateLine">
                            {{( alternateCallInitiated) ? 'initiating call...' : ''}}
                          </div>
                        </div>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Definition" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                      <div class="box" style="background: #f6f8fa;">
                        <div class="columns is-multiline">
                          <div class="column is-12 is-flex">
                            <switch-selector v-if="isAdmin" type="is-info" label="Partial Fill" v-model="isPartial"></switch-selector>
                            <span style="margin-left: 40px;"><switch-selector type="is-info" label="Is Blocked" v-model="customer.isBlocked"></switch-selector></span>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">First Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <div class="control">
                                <input data-vv-scope="page-2" name="firstName" data-vv-as="firstName" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-2.firstName') }" v-model="customer.firstName">
                                <span v-show="errors.has('page-2.firstName')" class="help is-danger"><i v-show="errors.has('page-2.firstName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.firstName') }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Last Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <div class="control">
                                <input name="lastName" data-vv-scope="page-2" data-vv-as="legal name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-2.lastName') }" type="text" v-model="customer.lastName" tabindex=2>
                                <span v-show="errors.has('page-2.lastName')" class="help is-danger"><i v-show="errors.has('page-2.lastName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.lastName') }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns is-multiline">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Email<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <div class="control">
                                <input name="email" data-vv-scope="page-2" data-vv-as="email" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-2.email') }" type="text" v-model="customer.email" tabindex=3 data-lpignore="true" autocomplete="off">
                                <span v-show="errors.has('page-2.email')" class="help is-danger"><i v-show="errors.has('page-2.email')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.email') }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Company Name<b-icon pack="fas" icon="star" class="fa-ss" v-if="isCompanyNameRequired"></b-icon></label>
                              <div class="control">
                                <input name="companyName" data-vv-scope="page-2" data-vv-as="company name" v-validate="{'required': isCompanyNameRequired}" :class="{'input': true, 'is-danger': errors.has('page-2.companyName') }" type="text" placeholder="e.g EAG" v-model="customer.companyName">
                              </div>
                              <span v-show="errors.has('page-2.companyName')" class="help is-danger">
                                <i v-show="errors.has('page-2.companyName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.companyName') }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <div class="columns is-multiline">
                          <div class="column is-6">
                            <button-list-selector validateAs="marital status" label="Marital Status" :required="true" v-model="selectedMaritalStatus" :availableValues="definedTypes.customerMaritalStatus.options" scope="page-2"></button-list-selector>
                          </div>
                          <div class="column is-6">
                            <button-list-selector validateAs="employment status" label="Employment Status" :required="true" v-model="selectedEmploymentStatus" :availableValues="definedTypes.customerEmploymentStatus.options" scope="page-2"></button-list-selector>
                          </div>
                        </div>
                        <div class="columns is-multiline">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Mobile<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <div class="control">
                                <input name="mobile" data-vv-scope="page-2" data-vv-as="mobile" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('mobile') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="customer.mobile" tabindex=6 data-lpignore="true" autocomplete="off">
                                <span v-show="errors.has('page-2.mobile')" class="help is-danger"><i v-show="errors.has('page-2.mobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.mobile') }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Office</label>
                              <div class="control">
                                <input name="office" data-vv-as="office" :class="{'input': true, 'is-danger': errors.has('office') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="customer.office" tabindex=7 data-lpignore="true" autocomplete="off">
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Home</label>
                              <div class="control">
                                <input name="home" data-vv-as="home" :class="{'input': true, 'is-danger': errors.has('office') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="customer.home" tabindex=8 data-lpignore="true" autocomplete="off">
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Fax</label>
                              <div class="control">
                                <input name="fax" data-vv-as="fax" :class="{'input': true, 'is-danger': errors.has('fax') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="customer.fax" tabindex=9 data-lpignore="true" autocomplete="off">
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <b-field label="Date of Birth">
                              <el-date-picker
                                  @change="onFormChange()"
                                  v-model="customer.dateOfBirth"
                                  type="date"
                                  format="MM-dd-yyyy"
                                  placeholder="Click to select..."
                                  :clearable="false"
                                  :editable="false">
                              </el-date-picker>
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Bank" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                      <div class="box" style="background: #f6f8fa;">
                        <div v-if="isDefaultPaymentMethodLocked" class="notification is-info is-flex">
                          <span>
                            <i class="fas fa-info-circle is-info fa-lg"></i>
                          </span>
                          <span class="ml-10">
                            <p>Default Payment Method is locked to {{overridePaymentType}}. This is the requested payment method. </p>
                          </span>
                        </div>
                        <bank-details-capture scope="page-3" @formchange="onFormChange()" v-model="bankDetails" :getValidationErrors.sync="getValidationErrors" :bankDetailsRequired="true"
                         :useValidator="thisValidator" :disableDefaultMethod="isDefaultPaymentMethodLocked">
                        </bank-details-capture>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Address" icon-pack="fas" icon="fas fa-address-card fa-sm">
                      <div v-for="(address, index) in addresses" :key="address.id">
                        <address-capture scope="page-4" v-model="addresses[index]" :isCustomer="true" :required="isAddressRequired" :useValidator="thisValidator"></address-capture>
                        <br/>
                      </div>
                      <div class="inner-add-remove-button">
                        <a class="button is-primary" @click="addAddress({})">
                          <span class="icon">
                            <i class="fas fa-plus"></i>
                          </span>
                        </a>
                        <a class="button is-danger" v-if="this.addresses.length > 1" @click="removeAddress()">
                          <span class="icon">
                            <i class="fas fa-minus"></i>
                          </span>
                        </a>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Work History" icon-pack="fas" icon="fas fa-address-card fa-sm">
                      <div v-for="(history, index) in workHistory" :key="history.id">
                        <address-capture scope="page-5" v-model="workHistory[index]" :isWorkHistory="true" :required="true" :useValidator="thisValidator"></address-capture>
                        <br/>
                      </div>
                      <div class="inner-add-remove-button">
                        <a class="button is-primary" @click="addWorkHistory({})">
                          <span class="icon">
                            <i class="fas fa-plus"></i>
                          </span>
                        </a>
                        <a class="button is-danger" v-if="this.workHistory.length > 1" @click="removeWorkHistory()">
                          <span class="icon">
                            <i class="fas fa-minus"></i>
                          </span>
                        </a>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Licenses" icon-pack="fas" icon="fas fa-camera fa-sm">
                      <div class="columns">
                        <div class="column is-6">
                          <file-selector
                          v-model="customerPhotoFiles"
                          label="Customer Photo"
                          :multiple="false"
                          :usePortalViewer="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-6">
                          <license-selector
                          scope="page-6"
                          :useValidator="thisValidator"
                          v-model="driversPhotoFiles"
                          label="Drivers License"
                          :multiple="false"
                          :required="!isPartial"
                          validateAs="drivers license"
                          :usePortalViewer="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </license-selector>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-6">
                          <license-selector
                          scope="page-6"
                          :useValidator="thisValidator"
                          v-model="backOfDriversPhotoFiles"
                          label="Back of Drivers License"
                          :multiple="false"
                          :required="!isPartial"
                          validateAs="back of drivers license"
                          :usePortalViewer="false"
                          v-if="stateRequiredBackOfDriversLicense"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </license-selector>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-6">
                          <file-selector
                          v-model="insurancePhotoFiles"
                          label="Insurance Card"
                          :multiple="false"
                          :usePortalViewer="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                        </div>
                      </div>
                    </b-tab-item>
                  </b-tabs>
                </div>
                </form>
              </div>
              <div class="file-container" style="cursor: pointer;">
                  <i class="fal fa-arrow-alt-from-left is-pulled-right" @click="toggleDocView(false)" title="Click to collapse doc view" v-if="isDocViewActive"></i>
                  <i class="fal fa-arrow-alt-from-right is-pulled-right" title="Click to expand doc view" @click="toggleDocView(true)" v-if="!isDocViewActive"></i>
              </div>
              <div class="tile is-parent is-vertical is-6" style="overflow-y: scroll;height: 68vh;" v-if="isDocViewActive">
                <error-display-component :serverErrors="[]" :serverErrorMessage="''"></error-display-component>
                <div class="tile is-child box">
                  <div class="file-container" v-show="isDocViewActive">
                    <file-selector v-model="customerPhotoFiles" :label="getCustomerPhotoIconTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
                    :showFileOnly="true" :usePortalViewer="false" :inline="true"></file-selector>
                  </div>
                  <div class="file-container" v-show="isDocViewActive">
                    <file-selector v-model="driversPhotoFiles" :label="getDriversLicenceIconTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
                    :showFileOnly="true" :usePortalViewer="false" :inline="true" :printLicense="true" :selectedStateName="selectedStateName" :customerId="this.currentCustomerId"></file-selector>
                  </div>
                  <div class="file-container" v-show="isDocViewActive">
                    <file-selector v-model="backOfDriversPhotoFiles" :label="getBackOfDriversLicenceIconTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
                    :showFileOnly="true" :usePortalViewer="false" :inline="true" :printLicense="true" :selectedStateName="selectedStateName" v-if="stateRequiredBackOfDriversLicense" :customerId="this.currentCustomerId"></file-selector>
                  </div>
                  <div class="file-container" v-show="isDocViewActive">
                    <file-selector v-model="insurancePhotoFiles" :label="getInsuranceCardIconTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
                    :showFileOnly="true" :usePortalViewer="false" :inline="true"></file-selector>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }" type="button" :disabled="!canSave || isUploadingFile" @click="validateBeforeSubmitAllScoped()">Save</button>
            <button class="button is-danger" @click.stop.prevent="onClose()" type="button" id="vs-close">Cancel</button>
          </footer>
          <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
        </div>
      </b-modal>
    </portal>
  </section>
</template>
<script>
import eventBus from '@/eventBus'
import customerService from '@/services/customerService'
import notificationService from '@/services/notificationService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import utilitiesMixin from '@/mixins/generic/utilities'
import BankDetailsCapture from '@/components/generic/BankDetailsCapture'
import AddressCapture from '@/components/generic/AddressCapture'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import FileSelector from '@/components/generic/FileSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import LicenseSelector from '@/components/generic/LicenseSelector'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapAllStatesGetters = createNamespacedHelpers('states').mapGetters

export default {
  name: 'VerifyCustomerControl',
  mixins: [utilitiesMixin, validateBeforeSubmitMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'bank-details-capture': BankDetailsCapture,
    'address-capture': AddressCapture,
    'file-selector': FileSelector,
    'switch-selector': SwitchSelector,
    'button-list-selector': ButtonListSelector,
    'license-selector': LicenseSelector
  },
  props: {
    value: {
      type: Object,
      default: () => ({ firstName: '', lastName: '', email: '', companyName: '', mobile: '', home: '', office: '', fax: '' })
    },
    customerId: {
      type: Number,
      default: null
    },
    isVerifiedLocalProp: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    isWireRequest: {
      type: Boolean,
      default: false
    },
    hasCompletedRequestedWireQuestions: {
      type: Boolean,
      default: false
    },
    overridePaymentType: {
      type: String,
      default: null
    },
    hideActionIcon: {
      type: Boolean,
      default: false
    },
    iconCustomSize: {
      type: String,
      default: 'fa-lg'
    },
    driversLicenseState: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      isLoading: false,
      verifyCustomerControlActive: false,
      customer: this.value,
      chkQuestions: [],
      callLines: [],
      tabIndex: 0,
      bankDetails: {
        accountHolder: '',
        accountNo: '',
        accountType: null,
        branchNo: '',
        branchName: '',
        swiftNo: '',
        selectedBank: null,
        selectedAccountType: null,
        hasValidationErrors: false,
        canSave: null,
        selectedDefaultMethod: null
      },
      getValidationErrors: false,
      addresses: [],
      workHistory: [],
      chkUseAlternateLine: false,
      alternateLine: '',
      alternateCallInitiated: false,
      alternateCallFailed: false,
      alternateCustomerLine: '',
      customerPhotoFiles: [],
      driversPhotoFiles: [],
      insurancePhotoFiles: [],
      selectedMaritalStatus: null,
      selectedEmploymentStatus: null,
      isDocViewActive: true,
      isPartial: false,
      backOfDriversPhotoFiles: [],
      selectedState: null,
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapAllStatesGetters(['allStates']),
    isAdmin () {
      return this.$store.getters['user/isInRole']('Admin Role')
    },
    canSave: function () {
      let totalQuestionsChecked = this.chkQuestions.length
      let permittedTotal = this.permittedQuestions.length
      return totalQuestionsChecked === permittedTotal && !this.customer.isBlocked
    },
    isVerified: function () {
      return (!this.isListMode || this.verifyCustomerControlActive === true) ? this.customer.verified : this.isVerifiedLocalProp
    },
    trackingNumber: function () {
      // need to set tracking number, first check on user then dealer. We use this with InteractiveTel sms gateway
      return (this.currentUser.activeTrackingNumber && this.currentUser.activeTrackingNumber !== '') ? this.currentUser.activeTrackingNumber : this.currentDealer.defaultCallTrackingNumber
    },
    isListMode: function () {
      return this.customerId !== null
    },
    title: function () {
      let title = 'Verify Customer'
      return (this.customer) ? title + ' - ' + this.customer.fullName : title
    },
    hasCustomerVerifyAccess: function () {
      let allowedPermissions = ['customer.verify']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    isAlternateLineBtnDisabled: function () {
      return !(this.alternateLine && this.alternateLine.length === 16 && this.alternateCustomerLine && this.alternateCustomerLine.length === 16)
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    selectedDefaultMethod: function () {
      return this.bankDetails.selectedDefaultMethod
    },
    defaultPaymentMethod: function () {
      return this.selectedDefaultMethod ? this.selectedDefaultMethod.name : 'Check'
    },
    hasCustomerPhoto: function () {
      return this.customerPhotoFiles.length > 0
    },
    hasDriversLicence: function () {
      return this.driversPhotoFiles.length > 0
    },
    hasInsuranceCard: function () {
      return this.insurancePhotoFiles.length > 0
    },
    getInsuranceCardIconTitle: function () {
      return this.hasInsuranceCard ? 'Insurance Card' : 'Insurance Card - Not Provded'
    },
    getCustomerPhotoIconTitle: function () {
      return this.hasCustomerPhoto ? 'Customer Photo' : 'Customer Photo - Not Provided'
    },
    getDriversLicenceIconTitle: function () {
      return this.hasDriversLicence ? 'Drivers License' : 'Drivers License - Not Provided'
    },
    getBackOfDriversLicenceIconTitle: function () {
      return this.hasBackOfDriversLicence ? 'Back Of Drivers License' : 'Back Of Drivers License - Not Provided'
    },
    permittedQuestions: function () {
      if (this.isWireRequest) {
        if (this.isVerified) {
          return ['q4', 'q5', 'q6']
        } else {
          return ['q1', 'q2', 'q3', 'q4', 'q5', 'q6']
        }
      }

      if (this.defaultPaymentMethod === 'Wire') {
        return ['q1', 'q2', 'q3', 'q4']
      }

      return ['q1', 'q2', 'q3']
    },
    isCompanyNameRequired: function () {
      return this.customer && this.customer.type === 'Company'
    },
    isDefaultPaymentMethodLocked: function () {
      return this.overridePaymentType !== null
    },
    isAddressRequired: function () {
      return this.bankDetails && this.bankDetails.selectedDefaultMethod && this.bankDetails.selectedDefaultMethod.name === 'Check'
    },
    hasBackOfDriversLicence: function () {
      return this.backOfDriversPhotoFiles.length > 0
    },
    stateRequiredBackOfDriversLicense: function () {
      if (this.driversLicenseState) {
        return this.driversLicenseState.requiresBackOfDriversLicense
      }
      return false
    },
    selectedStateName: function () {
      let result = ''
      if (this.driversLicenseState && this.driversLicenseState.name) {
        result = this.driversLicenseState.name
      }
      return result
    },
    currentCustomerId: function () {
      if (this.isListMode) {
        return this.customerId
      } else {
        if (this.customer) {
          return this.customer.id
        }
        return 0
      }
    }
  },
  methods: {
    onClose: function () {
      this.$emit('on-open-sendwirecontrol')
      this.callLines = []
      this.customerPhotoFiles = []
      this.driversPhotoFiles = []
      this.insurancePhotoFiles = []
      this.chkQuestions = []
      this.clearErrors()
      this.verifyCustomerControlActive = false
      this.backOfDriversPhotoFiles = []
    },
    onSave: function () {
      if (this.canSave) {
        var customerModel = {
          ...this.customer,
          firstName: this.customer.firstName,
          lastName: this.customer.lastName,
          email: this.customer.email,
          companyName: this.customer.companyName,
          maritalStatus: this.selectedMaritalStatus ? this.selectedMaritalStatus.name : null,
          employmentStatus: this.selectedEmploymentStatus ? this.selectedEmploymentStatus.name : null,
          mobile: this.customer.mobile,
          office: this.customer.office,
          home: this.customer.home,
          fax: this.customer.fax,
          addresses: [],
          workHistory: [],
          bankId: this.bankDetails.selectedBank ? this.bankDetails.selectedBank.id : 0,
          accountHolder: this.bankDetails.accountHolder || '',
          accountNo: this.bankDetails.accountNo || '',
          accountType: this.bankDetails.selectedAccountType ? this.bankDetails.selectedAccountType.name : 'Check',
          branchNo: this.bankDetails.branchNo || '',
          branchName: this.bankDetails.branchName || '',
          swiftNo: this.bankDetails.swiftNo || '',
          defaultPaymentMethod: this.bankDetails.selectedDefaultMethod ? this.bankDetails.selectedDefaultMethod.name : 'Check',
          isPartial: false
        }

        if (this.customerPhotoFiles.length > 0) {
          var customerPhotoFile = this.customerPhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          customerModel.uploadName = customerPhotoFile[0].uploadName
          customerModel.uniqueId = customerPhotoFile[0].uniqueId
        }

        if (this.driversPhotoFiles.length > 0) {
          var driversPhotoFile = this.driversPhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          customerModel.driversUploadName = driversPhotoFile[0].uploadName
          customerModel.driversUniqueId = driversPhotoFile[0].uniqueId
        }

        if (this.backOfDriversPhotoFiles.length > 0) {
          var backOfDriversPhotoFile = this.backOfDriversPhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          customerModel.backOfDriversUploadName = backOfDriversPhotoFile[0].uploadName
          customerModel.backOfDriversUniqueId = backOfDriversPhotoFile[0].uniqueId
        }

        if (this.insurancePhotoFiles.length > 0) {
          let insurancePhotoFile = this.insurancePhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          customerModel.insuranceUploadName = insurancePhotoFile[0].uploadName
          customerModel.insuranceUniqueId = insurancePhotoFile[0].uniqueId
        }

        this.saveAddress(customerModel)
        this.saveWorkHistory(customerModel)
        this.isSaving = true
        this.clearErrors()

        customerService.verifyCustomer(customerModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.failedToast('Oops! Something went wrong')
            this.serverErrors = response.errors
          } else {
            this.customer = response.customer
            this.customer.verified = true
            eventBus.$emit('quickview-refresh')
            eventBus.$emit('reload-customer-list')
            this.$emit('on-sync-customer-callback', this.customer)
            this.$validator.pause()
            this.onClose()
            this.successToast('Success! Customer Verified')
          }
        }).catch((error) => {
          this.failedToast('Oops! Something went wrong')
          this.isSaving = false
          this.serverErrorMessage = error.message
        })
      }
    },
    onInitiateCall: function (callLine) {
      let callModel = { source: this.trackingNumber, destination: callLine.line, apiKey: this.currentDealer.apiKeyInteactiveTel, dealerId: this.currentDealer.id, receiverType: 'Individual', receiverId: this.customer.id }
      callLine.callInitiated = true
      this.initiateCallService(callModel, callLine)
    },
    onInitiateAlternateCall: function () {
      let callModel = { source: this.alternateLine, destination: this.alternateCustomerLine, apiKey: this.currentDealer.apiKeyInteactiveTel, dealerId: this.currentDealer.id, receiverType: 'Individual', receiverId: this.customer.id }
      this.alternateCallInitiated = true
      this.initiateCallService(callModel, null)
    },
    initiateCallService: function (callModel, callLine) {
      let isAlternateCall = !callLine
      this.clearErrors()
      notificationService.initiateCall(callModel).then(response => {
        if (isAlternateCall) {
          this.alternateCallInitiated = false
        } else {
          callLine.callInitiated = false
        }
        if (response.errors) {
          if (isAlternateCall) {
            this.alternateCallFailed = true
          } else {
            callLine.callFailed = true
          }
          this.failedToast('Oops! Something went wrong')
          this.serverErrors = response.errors
        } else {
          if (response.isSuccess) {
            if (isAlternateCall) {
              this.alternateCallFailed = false
            } else {
              callLine.callFailed = false
            }
            this.successToast('Success! call initiated')
          } else {
            if (isAlternateCall) {
              this.alternateCallFailed = true
            } else {
              callLine.callFailed = true
            }
            this.failedToast('Oops! Something went wrong')
            this.serverErrorMessage = response.callResult
          }
        }
      }).catch((error) => {
        this.failedToast('Oops! Something went wrong')
        if (isAlternateCall) {
          this.alternateCallInitiated = false
          this.alternateCallFailed = true
        } else {
          callLine.callInitiated = false
          callLine.callFailed = true
        }
        this.serverErrorMessage = error.message
      })
    },
    clearErrors: function () {
      this.serverErrors = []
      this.serverErrorMessage = ''
    },
    onOpen () {
      if (this.hasCustomerVerifyAccess) {
        this.callLines = []
        this.customerPhotoFiles = []
        this.driversPhotoFiles = []
        this.insurancePhotoFiles = []
        this.chkQuestions = []
        this.backOfDriversPhotoFiles = []
        if (this.customerId > 0) {
          this.loadCustomer(this.customerId)
        } else {
          this.loadCustomer(this.customer.id)
        }
        this.verifyCustomerControlActive = true
      }
    },
    loadCustomer (id) {
      this.isLoading = true
      customerService.single(id).then(response => {
        this.customer = response.customer
        this.init()
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    init () {
      this.alternateLine = this.trackingNumber
      if (this.customer.mobile && this.customer.mobile.trim().length > 0) {
        let callLine = { name: 'Mobile', line: this.customer.mobile, callInitiated: false, callFailed: false }
        this.callLines.push(callLine)
        // set the default if user going to use alternate lines
        this.alternateCustomerLine = this.customer.mobile
      }

      if (this.customer.office && this.customer.office.trim().length > 0) {
        let callLine = { name: 'Office', line: this.customer.office, callInitiated: false, callFailed: false }
        this.callLines.push(callLine)
      }

      if (this.customer.home && this.customer.home.trim().length > 0) {
        let callLine = { name: 'Home', line: this.customer.home, callInitiated: false, callFailed: false }
        this.callLines.push(callLine)
        // primary overrides mobile for default number
        this.alternateCustomerLine = this.customer.home
      }

      this.selectedMaritalStatus = (this.customer.maritalStatus) ? this.definedTypes.customerMaritalStatus.options.find((x) => x.name === this.customer.maritalStatus) : null
      this.selectedEmploymentStatus = (this.customer.employmentStatus) ? this.definedTypes.customerEmploymentStatus.options.find((x) => x.name === this.customer.employmentStatus) : null

      let selectedBank = this.customer.bankId !== 0
        ? { id: this.customer.bankId !== 0 ? this.customer.bankId : null, name: this.customer.bankName }
        : null

      let defaultPaymentMethod = this.overridePaymentType === null ? this.customer.defaultPaymentMethod : this.overridePaymentType
      this.bankDetails = {
        accountHolder: this.customer.accountHolder,
        accountNo: this.customer.accountNo,
        accountType: this.customer.accountType,
        branchNo: this.customer.branchNo,
        branchName: this.customer.branchName,
        swiftNo: this.customer.swiftNo,
        selectedBank: selectedBank,
        selectedAccountType: this.definedTypes.bankAccountTypes.find((x) => x.name === this.customer.accountType),
        hasValidationErrors: false,
        selectedDefaultMethod: this.definedTypes.paymentMethods.find((x) => x.name === defaultPaymentMethod),
        canSave: null
      }

      this.addresses = []
      if (!this.customer.addresses) this.customer.addresses = []
      this.customer.addresses.forEach(function (address) {
        var config = {
          sectionId: this.addresses.length + 100,
          loadCustomerOnly: false
        }
        address.config = config
        this.addresses.push(address)
      }, this)

      this.workHistory = []
      if (!this.customer.workHistory) this.customer.workHistory = []
      this.customer.workHistory.forEach(function (history) {
        var config = {
          sectionId: this.workHistory.length + 100,
          loadCustomerOnly: false
        }
        history.config = config
        this.workHistory.push(history)
      }, this)

      if (this.customer.fileLocation) {
        var photoFile = {
          loaded: true,
          imageData: this.customer.fileLocation,
          fileName: this.customer.fileName,
          fileLocation: this.customer.fileLocation,
          name: this.customer.imageName,
          fileType: this.getFileType(this.customer.fileLocation),
          status: 'Active'
        }

        this.customerPhotoFiles.push(photoFile)
      }

      if (this.customer.driversFileLocation) {
        var driversPhotoFile = {
          loaded: true,
          imageData: this.customer.driversFileLocation,
          fileName: this.customer.driversFileName,
          fileLocation: this.customer.driversFileLocation,
          name: this.customer.driversImageName,
          fileType: this.getFileType(this.customer.driversFileLocation),
          status: 'Active'
        }

        this.driversPhotoFiles.push(driversPhotoFile)
      }

      if (this.customer.backOfDriversFileLocation) {
        let backOfDriversPhotoFile = {
          loaded: true,
          imageData: this.customer.backOfDriversFileLocation,
          fileName: this.customer.backOfDriversFileName,
          fileLocation: this.customer.backOfDriversFileLocation,
          name: this.customer.backOfDriversImageName,
          fileType: this.getFileType(this.customer.backOfDriversFileLocation),
          status: 'Active'
        }

        this.backOfDriversPhotoFiles.push(backOfDriversPhotoFile)
      }

      if (this.customer.insuranceFileLocation) {
        var insurancePhotoFile = {
          loaded: true,
          imageData: this.customer.insuranceFileLocation,
          fileName: this.customer.insuranceFileName,
          fileLocation: this.customer.insuranceFileLocation,
          name: this.customer.insuranceImageName,
          fileType: this.getFileType(this.customer.insuranceFileLocation),
          status: 'Active'
        }

        this.insurancePhotoFiles.push(insurancePhotoFile)
      }

      if (this.customer.driverLicenseState) {
        let usState = this.allStates.find((x) => x.name === this.customer.driverLicenseState)
        this.selectedState = usState
      }
    },
    onValidationFailure: function () {
      this.serverErrors = [{
        errorMessage: 'One or more required fields requires your attention. Please check all tabs for invalid fields.'
      }]
    },
    toggleDocView: function (status) {
      this.isDocViewActive = status
    },
    saveAddress: function (customerModel) {
      this.addresses.forEach(function (item) {
        var address = {
          id: item.id,
          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
          address4: item.address4,
          city: item.city,
          country: item.country,
          postalCode: item.postalCode,
          state: item.state,
          gpsAddress: item.gpsAddress,
          ownershipType: item.ownershipType.name,
          years: item.years,
          months: item.months,
          rate: item.rate
        }

        address = this.ensureIdDataTypeSafety(address)
        customerModel.addresses.push(address)
      }, this)
    },
    saveWorkHistory: function (customerModel) {
      this.workHistory.forEach(function (item) {
        var history = {
          id: item.id,
          employer: item.employer,
          jobTitle: item.jobTitle,
          employerOffice: item.employerOffice,
          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
          address4: item.address4,
          city: item.city,
          country: item.country,
          postalCode: item.postalCode,
          state: item.state,
          gpsAddress: item.gpsAddress,
          years: item.years,
          months: item.months,
          grossIncome: item.grossIncome,
          otherIncome: item.otherIncome,
          otherIncomeSource: item.otherIncomeSource
        }
        history = this.ensureIdDataTypeSafety(history)
        customerModel.workHistory.push(history)
      }, this)
    },
    addAddress: function (configuration) {
      var config = {
        sectionId: this.addresses.length + 100,
        loadCustomerOnly: (configuration) ? configuration.loadCustomerOnly : false
      }

      var address = this.createGenericAddress()
      address.config = config
      this.addresses.push(address)
    },
    addWorkHistory: function (configuration) {
      var config = {
        sectionId: this.workHistory.length + 100,
        loadCustomerOnly: (configuration) ? configuration.loadCustomerOnly : false
      }

      var history = this.createGenericWorkHistory()
      history.config = config
      this.workHistory.push(history)
    },
    removeAddress () {
      var sectionId
      var offset = 100

      sectionId = this.addresses.length + offset - 1
      var addressFiltered = this.addresses.filter(function (el) {
        return el.config.sectionId !== sectionId
      })
      this.addresses = addressFiltered
    },
    removeWorkHistory () {
      var sectionId
      var offset = 100

      sectionId = this.workHistory.length + offset - 1
      var workHistoryFiltered = this.workHistory.filter(function (el) {
        return el.config.sectionId !== sectionId
      })
      this.workHistory = workHistoryFiltered
    },
    createGenericAddress (configuration) {
      return {
        id: this.$uuid.v4(),
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: '',
        ownershipType: null,
        years: 0,
        months: 0,
        rate: 0
      }
    },
    createGenericWorkHistory (configuration) {
      return {
        id: this.$uuid.v4(),
        employer: '',
        jobTitle: '',
        employerOffice: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: '',
        years: 0,
        months: 0,
        grossIncome: 0,
        otherIncome: 0,
        otherIncomeSource: ''
      }
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      this.customer = newValue
    }
  },
  mounted: function () {
    eventBus.$on('on-open-verify-customer', this.onOpen)
  },
  beforeDestroy () {
    eventBus.$off('on-open-verify-customer', this.onOpen)
  }
}

</script>

<style scoped>
  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-card-body {
    overflow:auto;
    height: calc(50vh - 10px);
  }

  .modal-card {
    max-width: inherit !important;
  }

  .file-container {
    margin-bottom: 50px;
  }

</style>

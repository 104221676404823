import mutationTypes from '../mutation-types.js'
import inventoryService from '@/services/inventoryService'
import dealService from '@/services/dealService'

const state = {
  searchResults: [],
  isSearchActive: false,
  searchQuery: '',
  currentDashboardTabIndex: 0
}

const getters = {
  allSearchResults: state => {
    return state.searchResults
  },
  searchActive: state => {
    return state.isSearchActive
  },
  currentSearchQuery: state => {
    return state.searchQuery
  },
  currentDashboardTabIndex: state => {
    return state.currentDashboardTabIndex
  }
}

const mutations = {
  [mutationTypes.SET_SEARCH_RESULTS] (state, results) {
    state.searchResults = results
  },
  [mutationTypes.SET_SEARCH_STATE] (state, isActive) {
    state.isSearchActive = isActive
  },
  [mutationTypes.SET_SEARCH_QUERY] (state, query) {
    state.searchQuery = query
  },
  [mutationTypes.SET_DASHBOARD_TAB_INDEX] (state, selectedTabIndex) {
    state.currentDashboardTabIndex = selectedTabIndex
  }
}

const actions = {
  performSearch ({commit, state}, searchCriteria) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.SET_SEARCH_QUERY, searchCriteria.query)
      commit(mutationTypes.SET_SEARCH_STATE, true)
      commit(mutationTypes.SET_SEARCH_RESULTS, [])
      inventoryService.all(searchCriteria).then(response => {
        var results = []
        response.results.forEach((item) => {
          results.push({entityType: 'Inventory', ...item})
        })
        dealService.all(searchCriteria).then(dealResponse => {
          dealResponse.results.forEach((item) => {
            results.push({entityType: 'Deal', ...item})
          })
          commit(mutationTypes.SET_SEARCH_RESULTS, results)
          commit(mutationTypes.SET_SEARCH_STATE, false)
          resolve(dealResponse)
        }).catch((error) => {
          commit(mutationTypes.SET_SEARCH_STATE, false)
          reject(error)
        })
      }).catch((error) => {
        commit(mutationTypes.SET_SEARCH_STATE, false)
        reject(error)
      })
    })
  },
  resetSearch ({commit, state}) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.SET_SEARCH_QUERY, '')
      commit(mutationTypes.SET_SEARCH_STATE, false)
      commit(mutationTypes.SET_SEARCH_RESULTS, [])
      resolve(true)
    })
  },
  setCurrentDashboardTabIndex ({commit, state}, selectedTabIndex) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.SET_DASHBOARD_TAB_INDEX, selectedTabIndex)
      resolve(true)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

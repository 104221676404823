export default {
  all: [
    {
      subscriberId: 1,
      name: 'Ferrari',
      url: null,
      status: 'Active',
      versions: [
        {
          name: 'F40',
          productionYear: 2017,
          series: null,
          holdbackPercentage: 0.0,
          holdValue: 0.0,
          bonusValue: 0.0,
          status: 'Active',
          subscriberId: 1,
          vehicleMakeId: 1,
          options: [
            {
              name: 'Leather Seats',
              description: 'Creamy leather seats',
              isStandard: false,
              cost: 1000.0,
              status: 'Active',
              subscriberId: 1,
              vehicleMakeVersionId: 1,
              id: 1,
              createdAt: '2018-03-29T21:11:02.69',
              modifiedAt: '2018-03-29T21:11:02.69'
            }
          ],
          id: 1,
          createdAt: '2018-03-29T21:10:45.22',
          modifiedAt: '2018-03-29T21:13:51.697'
        }
      ],
      id: 1,
      createdAt: '2018-03-29T21:10:21.6',
      modifiedAt: '2018-03-29T21:10:21.6'
    },
    {
      subscriberId: 1,
      name: 'Maserati',
      url: null,
      status: 'Active',
      versions: [],
      id: 2,
      createdAt: '2018-03-29T21:10:35.87',
      modifiedAt: '2018-03-29T21:10:35.87'
    },
    {
      subscriberId: 1,
      name: 'Volvo',
      url: null,
      status: 'Active',
      versions: [],
      id: 3,
      createdAt: '2018-03-29T21:10:35.877',
      modifiedAt: '2018-03-29T21:10:35.877'
    },
    {
      subscriberId: 1,
      name: 'Porsche',
      url: null,
      status: 'Active',
      versions: [],
      id: 4,
      createdAt: '2018-03-29T21:10:35.877',
      modifiedAt: '2018-03-29T21:10:35.877'
    }
  ]
}

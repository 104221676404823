import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/users',
    name: 'ListUsers',
    component: () => import('@/components/configuration/users/ListUsers'),
    beforeEnter: requiresAuth
  },
  {
    name: 'UsersQuickView',
    path: '/configuration/users/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/users/ViewUser')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/users/new',
    name: 'NewUser',
    component: () => import('@/components/configuration/users/NewUser'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/users/view/:id',
    name: 'ViewUser',
    component: () => import('@/components/configuration/users/ViewUser'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/users/edit/:id',
    name: 'EditUser',
    component: () => import('@/components/configuration/users/EditUser'),
    beforeEnter: requiresAuth
  }
]

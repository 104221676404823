import axios from 'axios'
import baseService from './baseService'
import productData from './data/product'

const productService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(productData)
      } else {
        axios
          .post('/product/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (productId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(productData.all.find((x) => x.id === productId))
      } else {
        axios
          .post('/product/get?id=' + productId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (productModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var product = {
          id: 999,
          name: productModel.name,
          description: productModel.description,
          isFinanceProduct: productModel.isFinanceProduct,
          price: productModel.price,
          status: 'Active'
        }
        productData.all.push(product)
        resolve(product)
      } else {
        axios
          .post('/product/save', productModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (productModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var product = productData.all.find((x) => x.id === productModel.id)
        if (product) {
          product.name = productModel.name
          product.description = productModel.description
          product.isFinanceProduct = productModel.isFinanceProduct
          product.price = productModel.price
        }
        resolve(product)
      } else {
        axios
          .post('/product/update', productModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (productModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        productModels.forEach(element => {
          productData.all.remove((x) => x.id === element.id)
        })
        resolve(productData.all)
      } else {
        var ids = []
        productModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/product/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllByDealerId (dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(productData.all)
      } else {
        axios
          .get('/product/findAllProductByDealerId?dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllByDealerIdIsDefaultDeal (dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(productData.all)
      } else {
        axios
          .get('/product/findAllProductsByDealerIdIsDefaultDeal?dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default productService

import axios from 'axios'
import baseService from './baseService'

const appService = {
  createInvoice (squareInvoice) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/createinvoice', squareInvoice, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getLocations () {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/getlocations', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getInvoice (sqInvoiceId) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/getinvoice?invoiceId=' + sqInvoiceId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateInvoice (squareInvoice) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/updateinvoice', squareInvoice, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getOrder (sqOrderId) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/getorder?orderId=' + sqOrderId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getCatalogs () {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/getcatalogs', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  refundInvoice (refundInvoice) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/refundinvoice', refundInvoice, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  refundNonStandard (refund) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/refundnonstandard', refund, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  refreshSquareRefundStatus (paymentTrackingId) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/refreshsquarerefundstatus?depositId=' + paymentTrackingId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDownloadInvoiceUrl (url) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios({
          url: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/getinvoicedownloadurl?url=' + url,
          method: 'GET',
          responseType: 'text',
          data: '',
          withCredentials: false
        }).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response)
        })
      }
    })
  },
  getDeviceCodesByDealerId (dealerId) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/getdevicecodesbydealerid?id=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addDevice (squareDeviceCodeModel) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/adddevice', squareDeviceCodeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  editDevice (squareDeviceCodeModel) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/editdevice', squareDeviceCodeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findPairedDeviceCodesByDealerId (dealerId) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/findpaireddevicecodesbydealerid?id=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findPairedDeviceCodesByDealerIdAndUser (dealerId) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/findpaireddevicecodesbydealeridanduser?id=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  terminalCheckOut (sqTerminalCheckoutModel) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/terminalcheckout', sqTerminalCheckoutModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  signatureTerminalAction (sqTerminalCheckoutModel) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/signatureterminalaction', sqTerminalCheckoutModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteDeviceCode (id) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/deletedevicecode?id=' + id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDeviceInfo (id) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/square/getdevice?id=' + id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      }
    })
  },
  cancelTerminalRequest (sqTerminalCheckoutModel) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/cancelterminalrequest', sqTerminalCheckoutModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  dismissTerminalRequest (sqTerminalCheckoutModel) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .post('/square/dismissterminalrequest', sqTerminalCheckoutModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService

export default {
  all: [
    {
      name: 'Admin Role',
      status: 'Active',
      id: 1
    },
    {
      name: 'Salesman Role',
      status: 'Active',
      id: 2
    },
    {
      name: 'Service Advisor Role',
      status: 'Active',
      id: 3
    }
  ]
}

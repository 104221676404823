import axios from 'axios'
import baseService from './baseService'
import teamData from './data/team'

const teamService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(teamData)
      } else {
        axios
          .post('/team/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allTeams (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(teamData)
      } else {
        axios
          .get('/team/list?', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (teamId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(teamData.all.find((x) => x.id === teamId))
      } else {
        axios
          .post('/team/get?id=' + teamId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (teamModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var team = {
          id: 999,
          name: teamModel.name,
          firstName: teamModel.description,
          status: 'Active'
        }
        teamData.all.push(team)
        resolve(team)
      } else {
        axios
          .post('/team/save', teamModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (teamModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var team = teamData.all.find((x) => x.id === teamModel.id)
        if (team) {
          team.name = teamModel.name
          team.description = teamModel.firstName
          team.status = teamModel.status
        }
        resolve(team)
      } else {
        axios
          .post('/team/update', teamModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (teamModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        teamModel.forEach(element => {
          teamData.all.remove((x) => x.id === element.id)
        })
        resolve(teamData.all)
      } else {
        var ids = []
        teamModel.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/team/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default teamService

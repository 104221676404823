<template>
  <div class="box">
    <section-header title="Vehicle Reports" :collapsed="true"></section-header>
    <div v-if="isCompactView">
      <div class="columns is-multiline" v-if="hasAutoCheckReport || hasCarFaxReport || hasvAutoReport">
        <div class="column is-4" v-if="hasAutoCheckReport">
          <file-selector v-model="getAutoCheckReport" label="Latest Auto Check" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="64"></file-selector>
        </div>
        <div class="column is-4" v-if="hasCarFaxReport">
          <file-selector v-model="getCarFaxReport" label="Latest Car Fax" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="64"></file-selector>
        </div>
        <div class="column is-4" v-if="hasvAutoReport">
          <file-selector v-model="getvAutoReport" label="Latest vAuto" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="64"></file-selector>
        </div>
      </div>
      <div class="columns" v-else>
        <div class="column is-12 is-size-7 has-text-info">No reports uploaded</div>
      </div>
      <quick-action-container>
        <div class="is-inline-block">
            <div class="quick-action" >
              <button v-if="!isPpaClosed" type="button" class="button" @click="onUploadVehicleReports()" title="Click to upload vehicle reports.">
                <b-icon pack="fal" icon="cloud-upload" custom-size="fa-lg"></b-icon>
              </button>
              <button type="button" class="button" @click="onViewVehicleReports()" title="Click to view all vehicle reports">
                <b-icon pack="fal" icon="folder-tree" custom-size="fa-lg"></b-icon>
              </button>
            </div>
        </div>
      </quick-action-container>
      <portal to="global-modal-portal" v-if="isVehicleReportUploadActive">
        <b-modal :active.sync="isVehicleReportUploadActive" :width="550" scroll="keep" :has-modal-card="true" v-if="currentInventory">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Vehicle reports for Stock# <a @click.stop.prevent="onStockNoClick(currentInventory)"><span class="has-text-weight-semibold" :title="currentInventory.stockNo">{{ currentInventory.stockNo }}</span></a></p>
            </header>
            <section class="modal-card-body" style="padding:12px">
              <div v-if="reportDataAvailable">
                <div class="columns is-multiline" style="padding-left: 8px;">
                  <div class="column is-12">
                    <file-selector
                    v-model="autoCheckFiles"
                    label="Auto Check"
                    :required="false"
                    :multiple="false"
                    :isBoxed="false"
                    :usePortalViewer="false"
                    @uploadStarted="uploadStarted"
                    @uploadComplete="uploadComplete">
                    </file-selector>
                  </div>
                  <div class="column is-12">
                    <file-selector
                    v-model="carFaxFiles"
                    label="Car Fax"
                    :required="false"
                    :multiple="false"
                    :isBoxed="false"
                    :usePortalViewer="false"
                    @uploadStarted="uploadStarted"
                    @uploadComplete="uploadComplete">
                    </file-selector>
                  </div>
                  <div class="column is-12">
                    <file-selector
                    v-model="vAutoFiles"
                    label="vAuto"
                    :required="false"
                    :multiple="false"
                    :isBoxed="false"
                    :usePortalViewer="false"
                    @uploadStarted="uploadStarted"
                    @uploadComplete="uploadComplete">
                    </file-selector>
                  </div>
                </div>
              </div>
              <div style="text-align: center;" v-else>
                Loading inventory reports...
                <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button :disabled="isUploadingFile" class="button is-primary" :class="{'is-loading': isSaving }"  @click="onSave()">Save</button>
              <button class="button is-danger" @click="isVehicleReportUploadActive = false">Cancel</button>
            </footer>
          </div>
        </b-modal>
      </portal>
      <portal to="global-modal-portal" v-if="isVehicleReportViewActive">
        <b-modal :active.sync="isVehicleReportViewActive" :width="550" scroll="keep" :has-modal-card="true" v-if="currentInventory">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">Vehicle reports for Stock# <a @click.stop.prevent="onStockNoClick(currentInventory)"><span class="has-text-weight-semibold" :title="currentInventory.stockNo">{{ currentInventory.stockNo }}</span></a></p>
            </header>
            <section class="modal-card-body" style="padding:12px">
              <div v-if="allReportDataAvailable">
                <div class="columns is-multiline" style="padding-left: 8px;">
                  <div class="column is-12">
                    <file-selector v-model="allAutoCheckFiles" label="Auto Check" :showDeleted="true" :enabled="false" :showFileOnly="true" :required="false" :multiple="true" :isBoxed="false" :usePortalViewer="false"></file-selector>
                  </div>
                  <div class="column is-12">
                    <file-selector v-model="allCarFaxFiles" label="Car Fax" :showDeleted="true" :enabled="false" :showFileOnly="true" :required="false" :multiple="true" :isBoxed="false" :usePortalViewer="false"></file-selector>
                  </div>
                  <div class="column is-12">
                    <file-selector v-model="allVautoFiles" label="vAuto" :showDeleted="true" :enabled="false" :showFileOnly="true" :required="false" :multiple="true" :isBoxed="false" :usePortalViewer="false"></file-selector>
                  </div>
                </div>
              </div>
              <div style="text-align: center;" v-else>
                Loading inventory reports...
                <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
              </div>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-danger" @click="isVehicleReportViewActive = false">OK</button>
            </footer>
          </div>
        </b-modal>
      </portal>
    </div>
    <div v-else>
      <div class="columns is-multiline" style="padding-left: 8px;">
        <div class="column is-4">
          <file-selector
          v-model="autoCheckFiles"
          label="Auto Check"
          :required="false"
          :multiple="false"
          :isBoxed="false"
          @uploadStarted="uploadStarted"
          @uploadComplete="uploadComplete">
          </file-selector>
        </div>
        <div class="column is-4">
          <file-selector
          v-model="carFaxFiles"
          label="Car Fax"
          :required="false"
          :multiple="false"
          :isBoxed="false"
          @uploadStarted="uploadStarted"
          @uploadComplete="uploadComplete">
          </file-selector>
        </div>
        <div class="column is-4">
          <file-selector
          v-model="vAutoFiles"
          label="vAuto"
          :required="false"
          :multiple="false"
          :isBoxed="false"
          @uploadStarted="uploadStarted"
          @uploadComplete="uploadComplete">
          </file-selector>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import SectionHeader from '@/components/generic/SectionHeader'
import QuickActionContainer from '@/components/generic/QuickActionContainer'
import inventoryService from '@/services/inventoryService'
import FileSelector from '@/components/generic/FileSelector'
import utilitiesMixin from '@/mixins/generic/utilities'

export default {
  name: 'InventoryVehicleReports',
  mixins: [utilitiesMixin, dealInventoryStyleClasses],
  components: {
    'file-selector': FileSelector,
    'section-header': SectionHeader,
    'quick-action-container': QuickActionContainer
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    value: {
      type: Object,
      default: () => {}
    },
    isCompactView: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isLoading: false,
      isVehicleReportUploadActive: false,
      isVehicleReportViewActive: false,
      autoCheckFiles: [],
      carFaxFiles: [],
      vAutoFiles: [],
      allAutoCheckFiles: [],
      allCarFaxFiles: [],
      allVautoFiles: [],
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    hasAutoCheckReport: function () {
      if (!this.currentInventory || !this.currentInventory.attachments) {
        return false
      }
      var primaryImages = this.currentInventory.attachments.filter((x) => x.classType === 'AutoCheck')
      return primaryImages.length > 0
    },
    getAutoCheckReport: function () {
      if (!this.currentInventory || !this.currentInventory.attachments) {
        return []
      }
      var primaryImages = this.currentInventory.attachments.filter((x) => x.classType === 'AutoCheck')
      var result = []
      primaryImages.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        x.fileType = this.getFileType(x.fileLocation)
        result.push(x)
      })
      return result
    },
    hasCarFaxReport: function () {
      if (!this.currentInventory || !this.currentInventory.attachments) {
        return false
      }
      var primaryImages = this.currentInventory.attachments.filter((x) => x.classType === 'CarFax')
      return primaryImages.length > 0
    },
    getCarFaxReport: function () {
      if (!this.currentInventory || !this.currentInventory.attachments) {
        return []
      }
      var primaryImages = this.currentInventory.attachments.filter((x) => x.classType === 'CarFax')
      var result = []
      primaryImages.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        x.fileType = this.getFileType(x.fileLocation)
        result.push(x)
      })
      return result
    },
    hasvAutoReport: function () {
      if (!this.currentInventory || !this.currentInventory.attachments) {
        return false
      }
      var primaryImages = this.currentInventory.attachments.filter((x) => x.classType === 'Vauto')
      return primaryImages.length > 0
    },
    getvAutoReport: function () {
      if (!this.currentInventory || !this.currentInventory.attachments) {
        return []
      }
      var primaryImages = this.currentInventory.attachments.filter((x) => x.classType === 'Vauto')
      var result = []
      primaryImages.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        x.fileType = this.getFileType(x.fileLocation)
        result.push(x)
      })
      return result
    },
    reportDataAvailable: function () {
      if (!this.isLoading || (this.currentInventory !== undefined && this.currentInventory.attachments !== undefined && this.currentInventory.attachments.length > 0)) {
        return true
      }
      return false
    },
    allReportDataAvailable: function () {
      if (!this.isLoading || (this.currentInventory !== undefined && this.currentInventory.allReports !== undefined && this.currentInventory.allReports.length > 0)) {
        return true
      }
      return false
    },
    autoCheckImages: function () {
      if (this.autoCheckDocument) {
        return [{
          id: this.autoCheckDocument.id,
          loaded: true,
          imageData: this.autoCheckDocument.fileLocation,
          fileName: this.autoCheckDocument.fileName,
          uniqueId: this.autoCheckDocument.fileName,
          fileType: this.getFileType(this.autoCheckDocument.fileLocation),
          fileLocation: this.autoCheckDocument.fileLocation,
          name: this.autoCheckDocument.fileName,
          classType: 'AutoCheck'
        }]
      }
      return []
    },
    carFaxReports: function () {
      if (this.carFaxDocument) {
        return [{
          id: this.carFaxDocument.id,
          loaded: true,
          imageData: this.carFaxDocument.fileLocation,
          fileName: this.carFaxDocument.fileName,
          uniqueId: this.carFaxDocument.fileName,
          fileType: this.getFileType(this.carFaxDocument.fileLocation),
          fileLocation: this.carFaxDocument.fileLocation,
          name: this.carFaxDocument.fileName,
          classType: 'CarFax'
        }]
      }
      return []
    },
    vautoReports: function () {
      if (this.vautoDocument) {
        return [{
          id: this.vautoDocument.id,
          loaded: true,
          imageData: this.vautoDocument.fileLocation,
          fileName: this.vautoDocument.fileName,
          uniqueId: this.vautoDocument.fileName,
          fileType: this.getFileType(this.vautoDocument.fileLocation),
          fileLocation: this.vautoDocument.fileLocation,
          name: this.vautoDocument.fileName,
          classType: 'Vauto'
        }]
      }
      return []
    },
    allAutoCheckImages: function () {
      var result = []
      if (this.allAutoCheckDocuments && this.allAutoCheckDocuments.length > 0) {
        this.allAutoCheckDocuments.forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.uniqueId = x.fileName
          x.fileType = this.getFileType(x.fileLocation)
          x.name = x.fileName
          x.classType = 'AutoCheck'
          result.push(x)
        })
        return result
      }
      return []
    },
    allCarFaxReports: function () {
      var result = []
      if (this.allCarFaxDocuments && this.allCarFaxDocuments.length > 0) {
        this.allCarFaxDocuments.forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.uniqueId = x.fileName
          x.fileType = this.getFileType(x.fileLocation)
          x.name = x.fileName
          x.classType = 'CarFax'
          result.push(x)
        })
        return result
      }
      return []
    },
    allVautoReports: function () {
      var result = []
      if (this.allVautoDocuments && this.allVautoDocuments.length > 0) {
        this.allVautoDocuments.forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.uniqueId = x.fileName
          x.fileType = this.getFileType(x.fileLocation)
          x.name = x.fileName
          x.classType = 'Vauto'
          result.push(x)
        })
        return result
      }
      return []
    },
    autoCheckDocument: function () {
      if (this.currentInventory && this.currentInventory.attachments && this.currentInventory.attachments.filter((y) => y.classType === 'AutoCheck').length > 0) {
        var allAutoCheckImages = this.currentInventory.attachments.filter((y) => y.classType === 'AutoCheck')
        if (allAutoCheckImages.length > 0) {
          return allAutoCheckImages[allAutoCheckImages.length - 1]
        }
      }
      return null
    },
    carFaxDocument: function () {
      if (this.currentInventory && this.currentInventory.attachments && this.currentInventory.attachments.filter((y) => y.classType === 'CarFax').length > 0) {
        var allCarFaxReports = this.currentInventory.attachments.filter((y) => y.classType === 'CarFax')
        if (allCarFaxReports.length > 0) {
          return allCarFaxReports[allCarFaxReports.length - 1]
        }
      }
      return null
    },
    vautoDocument: function () {
      if (this.currentInventory && this.currentInventory.attachments && this.currentInventory.attachments.filter((y) => y.classType === 'Vauto').length > 0) {
        var allVautoReports = this.currentInventory.attachments.filter((y) => y.classType === 'Vauto')
        if (allVautoReports.length > 0) {
          return allVautoReports[allVautoReports.length - 1]
        }
      }
      return null
    },
    allAutoCheckDocuments: function () {
      if (this.currentInventory && this.currentInventory.allReports && this.currentInventory.allReports.filter((y) => y.classType === 'AutoCheck').length > 0) {
        var allAutoCheckImages = this.currentInventory.allReports.filter((y) => y.classType === 'AutoCheck')
        if (allAutoCheckImages.length > 0) {
          return allAutoCheckImages
        }
      }
      return []
    },
    allCarFaxDocuments: function () {
      if (this.currentInventory && this.currentInventory.allReports && this.currentInventory.allReports.filter((y) => y.classType === 'CarFax').length > 0) {
        var allCarFaxReports = this.currentInventory.allReports.filter((y) => y.classType === 'CarFax')
        if (allCarFaxReports.length > 0) {
          return allCarFaxReports
        }
      }
      return []
    },
    allVautoDocuments: function () {
      if (this.currentInventory && this.currentInventory.allReports && this.currentInventory.allReports.filter((y) => y.classType === 'Vauto').length > 0) {
        var allVautoReports = this.currentInventory.allReports.filter((y) => y.classType === 'Vauto')
        if (allVautoReports.length > 0) {
          return allVautoReports
        }
      }
      return []
    },
    isPpaClosed: function () {
      return this.currentInventory.type === 'PpaClosed'
    }
  },
  methods: {
    isPdf (file) {
      return file.fileType === 'application/pdf'
    },
    isImage (file) {
      return (file.fileType === 'image/png' || file.fileType === 'image/jpg' || file.fileType === 'image/jpeg' || file.fileType === 'image/gif')
    },
    onUploadVehicleReports: function () {
      this.isVehicleReportUploadActive = true
      if (this.isCompactView) {
        this.loadVehicleReportInfo()
      }
    },
    onViewVehicleReports: function () {
      this.isVehicleReportViewActive = true
    },
    onSave: function () {
      var updateInventory = {
        id: this.currentInventory.id,
        attachments: []
      }

      if (this.autoCheckFiles.length > 0) {
        var autoCheckImage = this.autoCheckFiles.map((x) => {
          return {
            id: this.autoCheckDocument ? this.autoCheckDocument.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'AutoCheck'
          }
        })

        updateInventory.attachments.push(...autoCheckImage)
      }

      if (this.carFaxFiles.length > 0) {
        var carFaxReport = this.carFaxFiles.map((x) => {
          return {
            id: this.carFaxDocument ? this.carFaxDocument.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'CarFax'
          }
        })

        updateInventory.attachments.push(...carFaxReport)
      }

      if (this.vAutoFiles.length > 0) {
        var vautoReport = this.vAutoFiles.map((x) => {
          return {
            id: this.vautoDocument ? this.vautoDocument.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'Vauto'
          }
        })

        updateInventory.attachments.push(...vautoReport)
      }

      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true

      inventoryService.uploadVehicleReports(updateInventory).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          eventBus.$emit('inventory-reports-uploaded', response.inventory)
          this.isVehicleReportUploadActive = false
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    loadVehicleReportInfo: function () {
      if (!this.currentInventory.attachments || this.currentInventory.attachments.length === 0) {
        this.isLoading = true
        inventoryService.getVehicleReportInfo(this.currentInventory).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.$set(this.currentInventory, 'attachments', response.inventory.attachments)
          }
          this.isLoading = false
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isLoading = false
        })
      }
    },
    notifyUpdate: function () {
      if (!this.isCompactView) {
        let updatedValue = {
          autoCheckReportsExternal: this.autoCheckFiles,
          carFaxReportsExternal: this.carFaxFiles,
          vAutoReportsExternal: this.vAutoFiles
        }
        this.$emit('input', updatedValue)
      }
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  watch: {
    'currentInventory.id': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        if (this.isCompactView) {
          this.autoCheckFiles = this.autoCheckImages || []
          this.carFaxFiles = this.carFaxReports || []
          this.vAutoFiles = this.vautoReports || []
          this.allAutoCheckFiles = this.allAutoCheckImages || []
          this.allCarFaxFiles = this.allCarFaxReports || []
          this.allVautoFiles = this.allVautoReports || []
        } else {
          this.autoCheckFiles = this.value.autoCheckReportsExternal || []
          this.carFaxFiles = this.value.carFaxReportsExternal || []
          this.vAutoFiles = this.value.vAutoReportsExternal || []
          this.allAutoCheckFiles = this.value.allAutoCheckImages || []
          this.allCarFaxFiles = this.value.allCarFaxReports || []
          this.allVautoFiles = this.value.allVautoReports || []
        }
      }
    },
    autoCheckFiles: function (newValue, oldValue) {
      this.notifyUpdate()
    },
    carFaxFiles: function (newValue, oldValue) {
      this.notifyUpdate()
    },
    vAutoFiles: function (newValue, oldValue) {
      this.notifyUpdate()
    }
  },
  mounted: function () {
    if (this.isCompactView) {
      this.autoCheckFiles = this.autoCheckImages || []
      this.carFaxFiles = this.carFaxReports || []
      this.vAutoFiles = this.vautoReports || []
      this.allAutoCheckFiles = this.allAutoCheckImages || []
      this.allCarFaxFiles = this.allCarFaxReports || []
      this.allVautoFiles = this.allVautoReports || []
    } else {
      this.autoCheckFiles = this.value.autoCheckReportsExternal || []
      this.carFaxFiles = this.value.carFaxReportsExternal || []
      this.vAutoFiles = this.value.vAutoReportsExternal || []
      this.allAutoCheckFiles = this.value.allAutoCheckImages || []
      this.allCarFaxFiles = this.value.allCarFaxReports || []
      this.allVautoFiles = this.value.allVautoReports || []
    }
  }
}

</script>

<style scoped>

  .image img {
    display: block;
    height:auto;
    width:auto;
  }

  .is-256xauto {
    display: -webkit-box;
    height:256px;
    width:auto;
    align-items: center;
    justify-content: center;
  }

  .is-128xauto {
    display: -webkit-box;
    height:128px;
    width:auto;
    align-items: center;
    justify-content: center;
  }

  .is-64xauto {
    display: -webkit-box;
    height:64px;
    width:auto;
    align-items: center;
    justify-content: center;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 550px !important;
    max-width: 550px !important;
    width: 550px !important;
  }

  .modal-card-body {
    max-height: 550px !important;
    min-height: 550px !important;
    height: 550px !important;
  }
</style>

import mutationTypes from '../mutation-types.js'
import dealerService from '../../services/dealerService.js'
import moment from 'moment-timezone'

const state = {
  currentDealer: {id: null, sellsVehicleMakes: []},
  allDealers: [],
  allDepartments: [],
  allDesignations: []
}

const getters = {
  allDealers: state => {
    return state.allDealers
  },
  allDepartments: state => {
    return state.allDepartments
  },
  allDesignations: state => {
    return state.allDesignations
  },
  getCurrentDealer: state => {
    return state.currentDealer
  }
}

const mutations = {
  [mutationTypes.SYNC_DEALER] (state, dealer) {
    var applicable = state.allDealers.find((x) => x.id === dealer.id)
    if (applicable) {
      applicable = dealer
    } else {
      state.allDealers.push(dealer)
    }
  },
  [mutationTypes.SET_CURRENT_DEALER] (state, dealer) {
    state.currentDealer = dealer
  },
  [mutationTypes.SET_DEALERS] (state, dealers) {
    state.allDealers = dealers
  },
  [mutationTypes.SYNC_DEPARTMENT] (state, department) {
    var applicable = state.allDepartments.find((x) => x.id === department.id)
    if (applicable) {
      applicable = department
    } else {
      state.allDepartments.push(department)
    }
  },
  [mutationTypes.SET_DEPARTMENTS] (state, departments) {
    state.allDepartments = departments
  },
  [mutationTypes.SYNC_DESIGNATION] (state, designation) {
    var applicable = state.allDesignations.find((x) => x.id === designation.id)
    if (applicable) {
      applicable = designation
    } else {
      state.allDesignations.push(designation)
    }
  },
  [mutationTypes.SET_DESIGNATIONS] (state, designations) {
    state.allDesignations = designations
  }
}

const actions = {
  setCurrentDealer ({dispatch, commit, state}, dealer) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.SET_CURRENT_DEALER, dealer)
      dealerService.single(dealer.id).then((data) => {
        commit(mutationTypes.SET_CURRENT_DEALER, data.dealer)
        dispatch('user/setActiveTimeZone', null, { root: true }).then(timezone => {
          console.log('Current timezone set post set dealer ' + moment.defaultZone.name)
        })
        resolve(data.dealer)
      }).catch((error) => {
        console.log(error)
      }
      )
    })
  },
  syncDealer ({commit, state}, dealer) {
    commit(mutationTypes.SYNC_DEALER, dealer)
  },
  updateCurrentDealer ({dispatch, commit, state}, dealer) {
    let currentTimeZone = state.currentDealer.timezone
    commit(mutationTypes.SET_CURRENT_DEALER, dealer)
    if (dealer.timezone !== currentTimeZone) {
      dispatch('user/setActiveTimeZone', null, { root: true }).then(timezone => {
        console.log('Current timezone set post set dealer ' + moment.defaultZone.name)
      })
    }
  },
  setDealers ({commit, state}) {
    commit(mutationTypes.SET_DEALERS, [])
    dealerService.allDealers()
      .then((data) => {
        commit(mutationTypes.SET_DEALERS, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  syncDesignation ({commit, state}, designation) {
    commit(mutationTypes.SYNC_DESIGNATION, designation)
  },
  setDesignations ({commit, state}) {
    commit(mutationTypes.SET_DESIGNATIONS, [])
    dealerService.allDesignations()
      .then((data) => {
        commit(mutationTypes.SET_DESIGNATIONS, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  syncDepartment ({commit, state}, department) {
    commit(mutationTypes.SYNC_DEPARTMENT, department)
  },
  setDepartments ({commit, state}) {
    commit(mutationTypes.SET_DEPARTMENTS, [])
    dealerService.allDepartments()
      .then((data) => {
        commit(mutationTypes.SET_DEPARTMENTS, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <section class="view-container">
    <div class="quick-action">
      <a :disabled="!hasSendDeliveryDateSmsAccess" class="button ml-0" target="_blank" @click="openAppointmentModal()" :title="(showPromisedDate) ? 'Click to set appointment' : 'Click to confirm/cancel appointment'">
        <i class="fal fa-watch fa-lg" :class="getColorClassByAppointmentStatus(currentDeal.appointmentStatus)" />
      </a>
      <span style="vertical-align: middle; margin-left: -10px">{{appointmentStatusFriendlyName}}</span>
    </div>
    <portal to="global-modal-portal" v-if="queueSmsActive">
      <b-modal :active.sync="queueSmsActive" scroll="keep" :has-modal-card="true" :canCancel="false" id="appointment-modal">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">{{this.appointmentModalHeader}}</p>
          </header>
          <section class="modal-card-body">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <form data-vv-scope="queue-sms-form" class="view-container">
              <div class="columns is-multiline">
                <div class="column is-12" v-show="showAppointStatusList">
                  <div class="field">
                    <button-list-selector validateAs="appointment status" label="Appointment Status" v-model="selectedAppointmentStatus" :required="true"
                    :allowDeselection="false" scope="queue-sms-form" :availableValues="availableAppointmentStatuses" :activeValues="activeAppointmentStatuses"></button-list-selector>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Appointment Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div id="appointment-datetime-control" class="control" v-if="!isAppointmentDateDisabled">
                        <el-date-picker
                          @change="this.onAppointmentDateChange()"
                          name="appointmentDate"
                          v-model="currentDeal.appointmentDate"
                          type="date"
                          placeholder="Click to select..."
                          popper-class="datepicker-container"
                          :clearable="false"
                          :editable="false"
                          format="MM-dd-yyyy"
                          :default-value="this.getDefaultDateTimeForCalendar"
                          :disabled="isAppointmentDateDisabled"
                          size="small">
                        </el-date-picker>
                        <span v-show="errors.has('queue-sms-form.appointmentDate')" class="help is-danger"><i v-show="errors.has('queue-sms-form.appointmentDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('queue-sms-form.appointmentDate') }}</span>
                      </div>
                      <div v-else>
                        {{currentDeal.appointmentDateUtc | formatDate}}
                      </div>
                  </div>
                </div>
                <div class="column is-6" v-show="!isAppointmentDateDisabled">
                  <div>
                    <label class="label">Appointment Time<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div id="appointment-time-control" class="control">
                        <b-timepicker
                            @change="this.onAppointmentDateChange()"
                            name="appointmentTime"
                            v-model="appointmentTime"
                            placeholder="Click to select..."
                            icon="clock"
                            hour-format="12"
                            :increment-minutes="15"
                            :disabled="isAppointmentDateDisabled">
                        </b-timepicker>
                        <span v-show="errors.has('queue-sms-form.appointmentTime')" class="help is-danger"><i v-show="errors.has('queue-sms-form.appointmentTime')" class="fas fa-exclamation-triangle"></i> {{ errors.first('queue-sms-form.appointmentTime') }}</span>
                      </div>
                  </div>
                </div>
                <div class="column is-6" v-if="showCancellationSection || !showAppointStatusList">
                  <div class="field">
                    <label class="label">Send SMS?<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control">
                      <b-switch type="is-info" v-model="canScheduleSms">{{ humanizedCanScheduleSms }}</b-switch>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="(showCancellationSection || !showAppointStatusList) && canScheduleSms">
                  <div class="field">
                    <label class="label">Mobile<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <input name="mobile" class="is-width-400" data-vv-as="mobile" v-validate="'required|min:16|max:16'" scope="queue-sms-form" :class="{'input': true, 'is-danger': errors.has('queue-sms-form.mobile') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="customerMobileSms" data-lpignore="true" autocomplete="off">
                      <span v-show="errors.has('queue-sms-form.mobile')" class="help is-danger"><i v-show="errors.has('queue-sms-form.mobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('queue-sms-form.mobile') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-mobile-alt"></i>
                      </span>
                      <div v-if="isCustomerDeal">
                        <label v-for="(contact) in currentDeal.customer.contactNumbers" :key="contact.id" class="label-frontgp is-pulled-right" :class="{'has-text-danger': (customerMobileSms !== contact.contactNumber), 'has-text-success': (customerMobileSms === contact.contactNumber)}" @click="setMobileSmsNumber(contact.contactNumber)" style="margin-left: 5px;">{{contact.contactNumber}}</label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="mobileChanged && isSupplierDeal && hasSupplierPrimaryContact && customerMobileSms.length === 16">
                  <div class="columns is-multiline">
                    <div class="notification is-info column is-12 is-flex">
                      <span>
                        <i class="fas fa-info-circle is-info fa-sm"></i>
                      </span>
                      <switch-selector style="color: white !important;" type="is-info" :label="supplierNumberChangeLabel" v-model="setMobileNumberPrimary" :labelStyle="'color: white !important'"></switch-selector>
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="canScheduleSms && (showCancellationSection || !showAppointStatusList)">
                  <div class="field">
                    <label class="label">Message<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control">
                      <textarea rows="7" name="smsMessage" v-validate="'required'" scope="queue-sms-form" :class="{'textarea': true}" data-vv-as="message" v-model="smsMessage"></textarea>
                      <span v-show="errors.has('queue-sms-form.smsMessage')" class="help is-danger"><i v-show="errors.has('queue-sms-form.smsMessage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('queue-sms-form.smsMessage') }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmQueueSms(true)" type="button" :disabled="!canSave">Save</button>
            <button class="button is-danger" @click="onConfirmQueueSms(false)" type="button">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </section>
</template>

<script>

import moment from 'moment'
import eventBus from '@/eventBus'
import dealService from '@/services/dealService'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import QuickActionContainer from '@/components/generic/QuickActionContainer'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import formattingMixin from '@/mixins/inventory/formatting'
import utilitiesMixin from '@/mixins/generic/utilities'
import notificationService from '@/services/notificationService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import SwitchSelector from '@/components/generic/SwitchSelector'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'AppointmentStatusControl',
  mixins: [humanizedSwitchMixin, validateBeforeSubmitMixin, formattingMixin, utilitiesMixin, dealInventoryStyleClasses],
  components: {
    'button-list-selector': ButtonListSelector,
    'quick-action-container': QuickActionContainer,
    'error-display-component': ErrorDisplayComponent,
    'switch-selector': SwitchSelector
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      currentDeal: this.value,
      isLoading: false,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      queueSmsActive: false,
      customerMobileSms: '',
      smsMessage: '',
      appointmentTime: new Date(),
      canScheduleSms: true,
      selectedAppointmentStatus: null,
      selected: null,
      selectedSaleType: null,
      mobileChanged: false,
      initialCustomerMobileSms: '',
      setMobileNumberPrimary: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapConfigGetters(['definedTypes']),
    ...mapDealerState(['currentDealer']),
    showPromisedDate: function () {
      return this.selected && this.selected.altName !== 'Booked' && this.selected.altName !== 'Delivered' && (this.currentDeal.appointmentStatus === 'NotSet' || this.currentDeal.appointmentStatus === 'Cancelled')
    },
    showDeliveredDate: function () {
      return this.selected && (this.selected.altName === 'Booked' || this.selected.altName === 'Delivered')
    },
    showAppointmentDate: function () {
      return this.selected && this.selected.altName !== 'Booked' && this.selected.altName !== 'Delivered' && this.currentDeal.appointmentStatus !== 'NotSet' && this.currentDeal.appointmentStatus !== 'Cancelled'
    },
    appointmentDate: function () {
      return (this.currentDeal) ? this.currentDeal.appointmentDate : null
    },
    hasSendDeliveryDateSmsAccess: function () {
      let allowedPermissions = ['deal.modify.deliverydatesms']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    getDefaultDateTimeForCalendar: function () {
      let localDateValue = moment.utc(String(new Date())).tz(moment.defaultZone.name)
      let localDate = new Date(localDateValue)
      return new Date(localDate.toDateString() + ' 13:00')
    },
    isAppointmentDateDisabled: function () {
      return this.currentDeal.appointmentStatus !== 'NotSet' && this.currentDeal.appointmentStatus !== 'Cancelled'
    },
    appointmentModalHeader: function () {
      return this.showPromisedDate ? 'Set Appointment' : 'Editing Appointment'
    },
    humanizedCanScheduleSms: function () {
      return this.canScheduleSms ? 'Yes' : 'No'
    },
    availableAppointmentStatuses: function () {
      return this.definedTypes.dealAppointmentTypes.options.filter((x) => x.altName !== 'All Appointments')
    },
    activeAppointmentStatuses: function () {
      return this.definedTypes.dealAppointmentTypes.options.filter((x) => x.altName === 'Confirmed' || x.altName === 'Cancelled')
    },
    showCancellationSection: function () {
      if (this.selectedAppointmentStatus) {
        return this.selectedAppointmentStatus.id === this.definedTypes.dealAppointmentTypes.Cancelled
      }

      return false
    },
    showAppointStatusList: function () {
      return !this.showPromisedDate
    },
    appointmentStatusFriendlyName: function () {
      if (this.currentDeal.appointmentStatus !== null && this.currentDeal.appointmentStatus !== undefined) {
        return this.definedTypes.dealAppointmentTypes.options.find((x) => x.name === this.currentDeal.appointmentStatus || x.altName === this.currentDeal.appointmentStatus).name
      }
      return ''
    },
    soldItems: function () {
      return (this.currentDeal && this.currentDeal.items) ? this.currentDeal.items.filter((x) => x.dealItemType === 'Sold') : []
    },
    isSaleTypeOfRetail: function () {
      if (this.selectedSaleType !== null && this.selectedSaleType.name === 'Retail') {
        return true
      } else {
        return false
      }
    },
    canSave: function () {
      if (this.selectedAppointmentStatus) {
        return (this.selectedAppointmentStatus.id === this.definedTypes.dealAppointmentTypes.Cancelled ||
          this.selectedAppointmentStatus.id === this.definedTypes.dealAppointmentTypes.Confirmed) || this.showPromisedDate
      }

      return true
    },
    forInventoryName: function () {
      if (this.soldItems.length > 0) {
        return this.soldItems[0].forInventory.name
      }
      return 'vehicle'
    },
    isSupplierDeal: function () {
      if (this.currentDeal) {
        return this.currentDeal.saleType === 'Wholesale'
      }
      return false
    },
    isCustomerDeal: function () {
      if (this.currentDeal) {
        return this.currentDeal.saleType === 'Retail'
      }
      return false
    },
    hasSupplierPrimaryContact: function () {
      return this.currentDeal.purchaserContactName !== ''
    },
    supplierNumberChangeLabel: function () {
      if (this.currentDeal) {
        return 'Would you like to update the primary contact\'s (' + this.currentDeal.purchaserContactName + ') mobile number from ' + this.currentDeal.purchaserContactMobile + ' to ' + this.customerMobileSms + '?'
      }
      return ''
    }
  },
  methods: {
    buildAppointmentSmsMessage: function () {
      if (this.currentDeal.appointmentDate && this.appointmentTime && typeof this.currentDeal.appointmentDate.getFullYear === 'function' && typeof this.appointmentTime.getHours === 'function') {
        var appointmentDateLocalValue = this.currentDeal.appointmentDate.getFullYear() + '-' + ('0' + (this.currentDeal.appointmentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + this.currentDeal.appointmentDate.getDate()).slice(-2) + ' ' + ('0' + (this.appointmentTime.getHours())).slice(-2) + ':' + ('0' + (this.appointmentTime.getMinutes())).slice(-2)
        var result = 'Appointment Confirmation: We will be ready to deliver your '
        result += this.forInventoryName + ' at our dealership per your request on ' + moment(appointmentDateLocalValue).format('MMM DD, YYYY hh:mm A')
        result += ' . Our address is ' + this.currentDealer.shortAddress + '.'
        result += '\r\n\r\n'
        return result
      }
      return ''
    },
    buildAppointmentCancellationSmsMessage: function () {
      if (this.currentDeal.appointmentDate && this.appointmentTime && typeof this.currentDeal.appointmentDate.getFullYear === 'function' && typeof this.appointmentTime.getHours === 'function') {
        var appointmentDateLocalValue = this.currentDeal.appointmentDate.getFullYear() + '-' + ('0' + (this.currentDeal.appointmentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + this.currentDeal.appointmentDate.getDate()).slice(-2) + ' ' + ('0' + (this.appointmentTime.getHours())).slice(-2) + ':' + ('0' + (this.appointmentTime.getMinutes())).slice(-2)
        var result = 'Appointment Cancellation: Please note, the appoinment date has been cancelled for delivery of your '
        result += this.forInventoryName + ' at our dealership per your request on ' + moment(appointmentDateLocalValue).format('MMM DD, YYYY hh:mm A') + '.'
        result += '\r\n\r\n'
        return result
      }
      return ''
    },
    loadDeal () {
      if (this.currentDeal.id !== 0) {
        this.selected = this.definedTypes.dealTypes.find((x) => x.name === this.currentDeal.type || x.altName === this.currentDeal.type)
        this.selectedSaleType = this.definedTypes.dealSaleTypes.find((x) => x.name === this.currentDeal.saleType)
        this.initialCustomerMobileSms = this.currentDeal.purchaserContactMobile
        this.customerMobileSms = this.currentDeal.purchaserContactMobile

        this.selectedAppointmentStatus = this.definedTypes.dealAppointmentTypes.options.find((x) => x.name === this.currentDeal.appointmentStatus || x.altName === this.currentDeal.appointmentStatus)
        if (this.currentDeal.appointmentDate !== undefined && this.currentDeal.appointmentDate !== null) {
          this.appointmentTime = new Date(this.currentDeal.appointmentDate)
          this.onAppointmentDateChange()
        }

        if (this.showPromisedDate) {
          if (this.currentDeal.promiseDate !== null || this.currentDeal.promiseDate !== '') {
            if (this.currentDeal.appointmentDate === null || this.currentDeal.appointmentDate === '') {
              this.currentDeal.appointmentDate = this.currentDeal.promiseDate
              this.appointmentTime = new Date(this.currentDeal.promiseDate)
            }
          }
          this.onAppointmentDateChange()
        }
      }
    },
    getPromiseDateColorClassByCurrentDate: function (promiseDate, type) {
      var formattedPromiseDate = new Date(promiseDate)
      var currentDate = new Date()
      var result = (formattedPromiseDate < currentDate && type !== 'Booked') ? 'has-text-danger' : ''
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    saveAppointmentStatus: function (loadDeal = false) {
      let dealId = this.currentDeal.id
      let appointmentStatus = this.currentDeal.appointmentStatus

      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true

      dealService.updateAppointmentStatus(dealId, appointmentStatus).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          eventBus.$emit('reload-deal-list')
          this.queueSmsActive = false
          this.canScheduleSms = true
          this.successToast('Success! appointment updated')
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onConfirmQueueSms: function (queue) {
      if (queue) {
        if (this.selectedAppointmentStatus.id === this.definedTypes.dealAppointmentTypes.Confirmed) {
          this.currentDeal.appointmentStatus = this.selectedAppointmentStatus.altName
          this.saveAppointmentStatus()
        } else {
          this.$validator.validateAll('queue-sms-form').then((result) => {
            if (result) {
              // need to set tracking number, first check on user then dealer. We use this with InteractiveTel sms gateway
              let trackingNumber = (this.currentUser.activeTrackingNumber && this.currentUser.activeTrackingNumber !== '') ? this.currentUser.activeTrackingNumber : this.currentDealer.defaultCallTrackingNumber
              let appointmentDate = this.currentDeal.appointmentDate

              if (!this.isAppointmentDateDisabled) {
                try {
                  var appointmentDateLocalValue = appointmentDate.getFullYear() + '-' + ('0' + (appointmentDate.getMonth() + 1)).slice(-2) + '-' + ('0' + appointmentDate.getDate()).slice(-2) + ' ' + ('0' + (this.appointmentTime.getHours())).slice(-2) + ':' + ('0' + (this.appointmentTime.getMinutes())).slice(-2)
                  let utcValue = moment.tz(appointmentDateLocalValue, moment.defaultZone.name).utc().format('YYYY-MM-DD HH:mm')
                  appointmentDate = utcValue
                } catch (dateConversionError) {
                  console.log('Error converting date to UTC')
                  console.log(this.appointmentDate)
                  console.log(dateConversionError)
                }
              }
              let setSupplierMobile = false
              if (this.isSupplierDeal && (this.setMobileNumberPrimary || this.initialCustomerMobileSms === '')) {
                setSupplierMobile = true
              }

              var smsNotificationModel = {
                subscriberId: this.currentDeal.subscriberId,
                dealerId: this.currentDeal.dealerId,
                mobileNo: this.customerMobileSms,
                customerId: (this.isSaleTypeOfRetail) ? this.currentDeal.purchaserId : null,
                content: this.smsMessage,
                supplierId: (!this.isSaleTypeOfRetail) ? this.currentDeal.purchaserId : null,
                callTrackingNumber: trackingNumber,
                smsType: (this.isAppointmentDateDisabled) ? 'CancelAppointment' : 'ScheduledAppointment',
                dealId: this.currentDeal.id,
                appointmentDate: (this.isAppointmentDateDisabled) ? null : appointmentDate,
                canScheduleSms: this.canScheduleSms,
                setMobileNumberPrimary: setSupplierMobile
              }

              this.isSaving = true
              notificationService.queueSms(smsNotificationModel).then(response => {
                this.isSaving = false
                if (response.errors) {
                  this.failedToast('Oops! Something went wrong')
                  this.serverErrors = response.errors
                } else {
                  if (this.canScheduleSms) {
                    this.successToast('Success! sms queued')
                  } else {
                    this.successToast('Success! appointment updated')
                  }

                  this.currentDeal.appointmentDateUtc = appointmentDate
                  let localValue = moment.utc(String(appointmentDate)).tz(moment.defaultZone.name)
                  this.currentDeal.appointmentDate = new Date(localValue.format('YYYY-MM-DD HH:mm'))
                  this.appointmentTime = new Date(this.currentDeal.appointmentDate)
                  this.currentDeal.appointmentDate = (this.isAppointmentDateDisabled) ? null : this.currentDeal.appointmentDate
                  this.currentDeal.appointmentStatus = (this.isAppointmentDateDisabled) ? 'Cancelled' : 'Set'
                  this.initialCustomerMobileSms = this.customerMobileSms
                  this.currentDeal.purchaserContactMobile = this.customerMobileSms
                  this.mobileChanged = false
                  this.setMobileNumberPrimary = false

                  eventBus.$emit('reload-deal-list')
                  this.queueSmsActive = false
                  this.canScheduleSms = true
                }
              }).catch((error) => {
                this.isSaving = false
                this.failedToast('Oops! Something went wrong')
                this.serverErrorMessage = error.message
              })
            }
          })
        }
      } else {
        this.isSaving = false
        this.queueSmsActive = false
        this.clearErrors()
        this.canScheduleSms = true
        this.selectedAppointmentStatus = this.definedTypes.dealAppointmentTypes.options.find((x) => x.name === this.currentDeal.appointmentStatus || x.altName === this.currentDeal.appointmentStatus)
      }
    },
    clearErrors: function () {
      this.errors.clear()
      this.serverErrors = []
      this.serverErrorMessage = ''
    },
    onAppointmentDateChange: function () {
      this.smsMessage = (this.showPromisedDate) ? this.buildAppointmentSmsMessage() : this.buildAppointmentCancellationSmsMessage()
    },
    openAppointmentModal: function () {
      if (this.hasSendDeliveryDateSmsAccess) {
        this.loadDeal()
        this.queueSmsActive = true
      }
    },
    setMobileSmsNumber (number) {
      this.customerMobileSms = number
    }
  },
  watch: {
    appointmentDate: function (newValue, oldValue) {
      this.smsMessage = (this.showPromisedDate) ? this.buildAppointmentSmsMessage() : this.buildAppointmentCancellationSmsMessage()
    },
    appointmentTime: function (newValue, oldValue) {
      this.smsMessage = (this.showPromisedDate) ? this.buildAppointmentSmsMessage() : this.buildAppointmentCancellationSmsMessage()
    },
    value: function (newValue, oldValue) {
      this.currentDeal = this.value
      this.loadDeal()
    },
    customerMobileSms: function (newValue, oldValue) {
      if (newValue && this.initialCustomerMobileSms !== newValue) {
        this.mobileChanged = true
      } else if (newValue && this.initialCustomerMobileSms === newValue) {
        this.mobileChanged = false
      }
    }
  },
  mounted: function () {
    this.loadDeal()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .modal-card-body {
    overflow:auto;
    height: calc(50vh - 10px);
  }
</style>

<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      :name="instanceName"
      :searchable="searchable"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedInventory"
      :options="allInventory"
      label="listName"
      :placeholder="placeholder"
      trackBy="id"
      :hide-selected="true"
      :loading="isLoading"
      :internal-search="false"
      :options-limit="300"
      @search-change="onSearch"
      :disabled="isInventorySelectorDisabled"
      :data-vv-scope="scope">
        <template slot="option" slot-scope="props">
          <div class="columns">
            <div class="column">
              <label>{{props.option.name}}</label>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label>Stock No: <a @click.stop.prevent="onStockNoClick(props.option)"><span class="has-text-weight-semibold">{{ props.option.stockNo }}</span></a></label>
            </div>
            <div class="column">
              <label>Vin No: {{props.option.vinNo}}</label>
            </div>
            <div class="column" :class="mapDealStatus(props.option.dealStatusName)">
              <label>Status: {{props.option.dealStatusName}} To: {{props.option.customerName || ''}}</label>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <label>Type: {{props.option.type}}</label>
            </div>
            <div class="column">
              <label>Possession Status : {{props.option.possessionStatus}}</label>
            </div>
            <div class="column">
              <label v-if="props.option.askingPrice">Asking Price : {{props.option.askingPrice | currency}}</label>
              <label v-if="!props.option.askingPrice">Asking Price : Unspecified</label>
            </div>
          </div>
        </template>
      </multiselect>
      <a class="button is-primary new-inventory-btn" @click="isNewInventoryActive = true" v-if="allowNew">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <b-modal :active.sync="isNewInventoryActive" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body">
          <new-inventory
          mode="modal"
          actionPortal="modal-footer"
          overviewPortal=""
          headerPortal="modal-header"
          v-on:on-save="onInventorySaved"
          v-on:on-cancel="onInventoryCancelled"
          :soldStockNo="soldStockNo"
          :defaultPossession="createDefaultPossession"
          :defaultType="createDefaultType"
          :defaultSalesPerson="createDefaultSalesPerson"
          :defaultSellerType="createDefaultSellerType"
          :defaultSeller="createDefaultSeller"></new-inventory>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import inventoryService from '@/services/inventoryService'
import eventBus from '@/eventBus'

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'InventorySelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Inventory'
    },
    selectedDealer: {
      type: Object,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    validateAs: {
      type: String,
      default: 'Item'
    },
    allowNew: {
      type: Boolean,
      default: true
    },
    soldStockNo: {
      type: String,
      default: ''
    },
    filteredTypes: {
      type: Array,
      default: () => [-1]
    },
    createDefaultPossession: {
      type: Object,
      default: null
    },
    createDefaultType: {
      type: Object,
      default: () => ({
        id: 0,
        name: 'New',
        altName: 'New'
      })
    },
    createDefaultSalesPerson: {
      type: Object,
      default: null
    },
    createDefaultSellerType: {
      type: Object,
      default: null
    },
    createDefaultSeller: {
      type: Object,
      default: null
    },
    showInstockOnly: {
      type: Boolean,
      default: true
    },
    showSoldOnly: {
      type: Boolean,
      default: false
    },
    isInventorySelectorDisabled: {
      type: Boolean,
      default: false
    },
    showInboundOnly: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    },
    filteredStatuses: {
      type: Array,
      default: () => [-1]
    },
    placeholder: {
      type: String,
      default: 'Type to search for an inventory item'
    },
    searchable: {
      type: Boolean,
      default: true
    },
    defaultVehicleList: {
      type: Array,
      default: () => []
    },
    soldStatus: {
      type: Array,
      default: () => [-1]
    },
    showPreTrades: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'new-inventory': () => import('@/components/inventory/NewInventory')
  },
  data () {
    return {
      queryTimer: undefined,
      isLoading: false,
      isNewInventoryActive: false,
      selectedInventory: this.value,
      data: this.defaultVehicleList,
      storeDealer: this.$store.state.dealer.currentDealer,
      instanceName: 'inventory-component-' + this.$uuid.v4()
    }
  },
  computed: {
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    allInventory () {
      return (this.showInstockOnly) ? this.data.filter((x) => x.inStock) : this.data
    }
  },
  watch: {
    selectedInventory: function (newValue, oldValue) {
      if ((oldValue && newValue && oldValue.id !== newValue.id) || !oldValue) {
        this.loadInventory(newValue.id)
      }
    },
    value: function (newValue, oldValue) {
      this.selectedInventory = newValue
    },
    selectedDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
      // Only reset the selected inventory on dealer change if its no the same dealer as the inventory item already belongs to
      if (this.selectedInventory && this.selectedInventory.dealerId !== this.selectedDealer.id) {
        this.selectedInventory = null
      }
    },
    storeDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
    },
    defaultVehicleList: function (newValue, oldValue) {
      this.data = newValue
    },
    showPreTrades: function (newValue, oldValue) {
      if (newValue === false) {
        this.clearAll()
      }
    }
  },
  methods: {
    mapDealStatus: function (dealStatus) {
      return {
        'has-text-primary': dealStatus === 'Available',
        'has-text-danger': dealStatus !== 'Available'
      }
    },
    onInventorySaved: function (newInventory) {
      this.data.push(newInventory)
      this.selectedInventory = newInventory
      this.isNewInventoryActive = false
    },
    onInventoryCancelled: function () {
      this.isNewInventoryActive = false
    },
    clearAll () {
      this.data = []
    },
    onSearch (query) {
      window.clearTimeout(this.queryTimer)
      if (this.selectedDealer === null || this.selectedDealer.id === null || this.selectedDealer.id === undefined || query === undefined || query === '' || query === null) {
        return
      }
      let that = this
      this.queryTimer = window.setTimeout(function () {
        var searchCommand = {
          page: 0,
          pageSize: 300,
          query: query,
          typeId: that.filteredTypes,
          fullVin: true,
          showInStockOnly: that.showInstockOnly,
          showServiceLoaners: false,
          dealerId: that.selectedDealer.id,
          sortBy: 'stockNo.asc',
          showInboundOnly: that.showInboundOnly,
          isSelector: true,
          showSoldOnly: that.showSoldOnly,
          status: that.filteredStatuses,
          defaultSearchView: 5,
          soldStatus: that.soldStatus,
          showPreTrades: that.showPreTrades
        }
        that.isLoading = true
        that.clearAll()
        inventoryService.all(searchCommand).then(response => {
          that.data = response.results
          that.isLoading = false
        })
      }, 750)
    },
    onPreviewInventory: function () {
      this.isNewInventoryActive = true
    },
    loadInventory (id) {
      this.isLoading = true
      inventoryService.single(id).then(response => {
        this.$emit('input', response.inventory)
        this.$emit('on-inventory-selected', response.inventory)
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    onStockNoClick: function (row) {
      let that = this

      this.$copyText(row.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + row.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  created () {
    eventBus.$on('preview-inventory', this.onPreviewInventory)
  },
  destroyed () {
    eventBus.$off('preview-inventory', this.onPreviewInventory)
  }
}

</script>

<style scoped>

  .new-inventory-btn {
    margin-left:5px;
    height:40px;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(100vh - 300px);
  }

</style>

export default {
  total: 3,
  results: [
    {
      id: 1,
      subscriberId: 1,
      subscriberName: 'EAG',
      name: 'FNB',
      firstName: 'Thomas',
      lastName: 'Anderson',
      email: 'fnb@hostedapp.co.za',
      office: '',
      fax: '',
      address1: 'NO2 Long St.',
      address2: '',
      address3: '',
      address4: '',
      city: 'Cape Town',
      country: 'South Africa',
      postalCode: '8001',
      state: 'Western Cape'
    }
  ]
}

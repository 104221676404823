<template>
  <arrival-capture
    :inventoryItem="activeInventory"
    :key="'A' + activeInventory.id"
    v-if="activeInventory && activeMode === 'Arrival'"
    :showDialog="activeInventory !== null && activeMode === 'Arrival'"
    v-on:arrival-capture-complete="onInventoryArrived"
    v-on:arrival-capture-skipped="onArrivalCaptureSkipped"
    :allowSkip="true"
    :usePortal="usePortal">
  </arrival-capture>

  <title-status-capture
    :currentInventory="activeInventory"
    :isListMode="true"
    :key="'T' + activeInventory.id"
    v-else-if="activeInventory && activeMode === 'Title'"
    :showDialog="activeInventory !== null && activeMode === 'Title'"
    v-on:title-capture-complete="onInventoryTitleCaptured"
    v-on:title-capture-skipped="onTitleCaptureSkipped"
    :usePortal="usePortal">
  </title-status-capture>

  <inventory-sellers-agreement
    :currentInventory="activeInventory"
    :key="'S' + activeInventory.id"
    v-else-if="activeInventory && activeMode === 'Agreement'"
    :showDialog="activeInventory !== null && activeMode === 'Agreement'"
    v-on:sellers-agreement-complete="onInventorySellersAgreementComplete"
    :usePortal="usePortal">
  </inventory-sellers-agreement>

  <inventory-batch-payment-request
    :currentInventory="activeInventory"
    :key="'P' + activeInventory.id"
    v-else-if="activeInventory && activeMode === 'Payment'"
    :showDialog="activeInventory !== null && activeMode === 'Payment'"
    v-on:batch-payment-request-complete="onInventoryCheckRequestComplete"
    :usePortal="usePortal">
  </inventory-batch-payment-request>

  <trade-allowance-approve-capture
    v-else-if="activeInventory && activeMode === 'ApproveTradeAllowance'"
    :key="'AT' + activeInventory.id"
    :currentInventory="activeInventory"
    :showDialog="activeInventory && activeMode === 'ApproveTradeAllowance'"
    v-on:trade-allowance-complete="onInventoryTradeAllowanceComplete"
    :usePortal="usePortal">
  </trade-allowance-approve-capture>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import logger from '@/services/loggingService'
import inventoryService from '@/services/inventoryService'
import InventoryArrivalCaptureComponent from '@/components/generic/inventory/ArrivalCapture'
import InventoryTitleCapture from '@/components/generic/inventory/InventoryTitleCapture'
import InventorySellersAgreementAction from '@/components/inventory/InventorySellersAgreementAction'
import InventoryConsignmentAgreementAction from '@/components/inventory/InventoryConsignmentAgreementAction'
import InventoryBatchPaymentRequest from '@/components/inventory/InventoryBatchPaymentRequestAction'
import TradeAllowanceApproveCapture from '@/components/inventory/TradeAllowanceApproveCapture'

const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'TradeFullFillmentWorkFlow',
  components: {
    'arrival-capture': InventoryArrivalCaptureComponent,
    'title-status-capture': InventoryTitleCapture,
    'inventory-sellers-agreement': InventorySellersAgreementAction,
    'inventory-consignment-agreement': InventoryConsignmentAgreementAction,
    'inventory-batch-payment-request': InventoryBatchPaymentRequest,
    'trade-allowance-approve-capture': TradeAllowanceApproveCapture
  },
  props: {
    forInventory: {
      type: Object,
      default: null
    },
    titleSkipped: {
      type: Boolean,
      default: false
    },
    arrivalCaptureSkipped: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeInventory: null,
      activeMode: 'None',
      allowTitleSkip: false,
      allowArrivalCaptureSkip: false,
      promptForTradeArrivalEvenIfSkipped: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'selectedDealTimeRange'])
  },
  methods: {
    onArrivalCaptureSkipped: function () {
      let inventoryItem = this.activeInventory
      this.activeInventory = null
      this.allowArrivalCaptureSkip = true
      this.$emit('trade-fullfillment-workflow-complete', inventoryItem)
      this.$emit('trade-fullfillment-arrival-capture-skipped', inventoryItem)
    },
    onTitleCaptureSkipped: function () {
      let inventoryItem = this.activeInventory
      this.activeInventory = null
      this.allowTitleSkip = true
      this.$emit('trade-fullfillment-workflow-complete', inventoryItem)
      this.$emit('trade-fullfillment-title-capture-skipped', inventoryItem)
    },
    onInventoryArrived: function (inventoryItem) {
      this.activeInventory = null
      this.$emit('trade-fullfillment-workflow-complete', inventoryItem)
    },
    onInventoryTitleCaptured: function (inventoryItem) {
      this.activeInventory = null
      this.$emit('trade-fullfillment-workflow-complete', inventoryItem)
    },
    onInventoryCheckRequestComplete: function (inventoryItem) {
      this.activeInventory = null
      // background processor sets the checkrequest so we need to manually notifiy that check request was sent to stop being in a loop
      inventoryItem.checkRequestSent = true
      this.$emit('trade-fullfillment-workflow-complete', inventoryItem)
    },
    onInventorySellersAgreementComplete: function (inventoryItem) {
      this.activeInventory = null
      this.$emit('trade-fullfillment-workflow-complete', inventoryItem)
    },
    onInventoryTradeAllowanceComplete: function (inventoryItem) {
      this.activeInventory = null
      this.$emit('trade-fullfillment-workflow-complete', inventoryItem)
    },
    resolveMode: function () {
      this.activeMode = ''

      if (!this.activeInventory) return

      let inventoryId = this.activeInventory.id

      if (this.activeInventory.tradeIncoming && !this.allowArrivalCaptureSkip) {
        if (this.activeInventory.tradeArrivalSkipped) {
          if (this.promptForTradeArrivalEvenIfSkipped) {
            if (this.hasFeatureAccess('inventory.mark-arrived')) {
              this.activeMode = 'Arrival'
              return
            }
          }
        } else {
          if (this.hasFeatureAccess('inventory.mark-arrived')) {
            this.activeMode = 'Arrival'
            return
          }
        }
      }

      if (!this.activeInventory.tradeHasTitle && !this.allowTitleSkip) {
        if (this.hasFeatureAccess('inventory.modify')) {
          this.activeMode = 'Title'
        }
        return
      }
      if (!this.activeInventory.tradeHasAgreement) {
        if (this.hasFeatureAccess('inventory.issue.sales-order')) {
          this.loadTradeInventory(inventoryId).then(response => {
            this.activeInventory = response
            this.activeMode = 'Agreement'
          })
        }
        return
      }

      if (this.activeInventory && (!this.activeInventory.tradeAllowanceApproved || !this.activeInventory.tradeExamined)) {
        this.activeMode = 'ApproveTradeAllowance'
        return
      }

      if (!this.activeInventory.tradeHasCheckRequest) {
        if (this.hasFeatureAccess('inventory.issue.batch-payment-request')) {
          this.loadTradeInventory(inventoryId).then(response => {
            this.activeInventory = response
            this.activeMode = 'Payment'
          })
        }
        return
      }

      if (this.hasFeatureAccess('inventory.view')) {
        this.$router.push({name: 'InventoryQuickView', params: { id: inventoryId, isLoanerPod: false, offerPod: -1, autoLaunchControl: null }})
      }
    },
    loadTradeInventory: function (inventoryId) {
      return new Promise((resolve, reject) => {
        this.isLoading = true
        this.serverErrorMessage = ''
        inventoryService.single(inventoryId).then(response => {
          this.isLoading = false
          resolve(response.inventory)
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isLoading = false
          reject(error.response.data)
        })
      })
    }
  },
  beforeDestroy () {

  },
  mounted: function () {
    logger.logDanger('trade workflow action mounted')
    this.activeInventory = this.forInventory
    this.allowTitleSkip = this.titleSkipped
    this.allowArrivalCaptureSkip = this.arrivalCaptureSkipped
    this.resolveMode()
  },
  watch: {
    forInventory: function (newValue, oldValue) {
      this.activeInventory = newValue
      this.resolveMode()
    }
  }
}
</script>

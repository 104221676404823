<template>
  <div class="is-inline-block" v-if="hasSalesOrderAccess && isValid">
    <div class="is-inline-block" v-if="hasSalesOrderAccess">
      <div class="quick-action" v-if="isValidForSellersAgreementSigning">
        <button type="button" class="button" @click="updateSalesOrderModalState(true)" v-tooltip="getToolTipForSellersAgreement">
          <i data-type="P" class="icon fal fa-envelope-open-text fa-lg" :class="getColorClassByAgreementStatus(activeInventory.sellersAgreementStatusId)" v-if="!currentInventory.isSellersAgreementUploaded"/>
          <i data-type="U" class="icon fal fa-upload fa-lg has-text-success" v-else/>
        </button>
      </div>
      <div class="quick-action" v-else>
        <button type="button" class="button" disabled v-tooltip="invalidForSellersAgreementReasons" :title="invalidForSellersAgreementReasons">
          <i data-type="P" class="icon fal fa-envelope-open-text fa-lg"/>
        </button>
      </div>
    </div>
    <div class="control is-inline-block quick-action" v-if="lastSignedSellersAgreement !== '' && hasSalesOrderAccess">
      <button class="button is-success" @click.prevent="onDownloadLastSellersAgreement" v-tooltip="'Click to download the last signed purchasers agreement.'">
        <b-icon pack="fal" icon="cloud-download" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <title-status-capture
      :currentInventory="currentInventory"
      :isListMode="false"
      :allowTitleSkip="true"
      :subscribeToOpenCloseEventListener="true"
      :showDialog="mustShowTitleDialog && !isMissingCoreDetails"
      v-on:title-capture-complete="onTitleCaptureComplete"
      v-on:title-capture-skipped="onTitleCaptureSkipped"
      :key="'sa' + currentInventory.id"
      v-if="activeDialog === 'TitleCapture'">
    </title-status-capture>
    <portal to="global-modal-portal" v-if="activeDialog === 'MissingDetails'" :order="1" :disabled="!usePortal">
      <b-modal :key="'M'+instanceId" :full-screen="isMobile" :active.sync="isBillOfSale" :has-modal-card="true" v-if="activeDialog === 'MissingDetails'" class="missing-details-modal" :canCancel="false">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">Missing Info Required</p>
          </header>
          <section class="modal-card-body" style="height: 500px;">
            <h3 class="title is-7 has-text-danger">Before an agreement can be finalized and sent the following mandatory information is required.</h3>
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <form @change="onFormChange" onsubmit="return false;">

                <div class="columns is-mobile" v-if="requiresSellerInfo">
                  <div class="column is-narrow">
                    <button-list-selector validateAs="source" label="Source" :required="true" v-model="selectedSource" :availableValues="allowedSourceTypes"></button-list-selector>
                    <span v-show="sourceError" class="help is-danger"><i v-show="sourceError" class="fas fa-exclamation-triangle"></i> {{ sourceError }}</span>
                  </div>
                  <div class="column" v-if="selectedSource && selectedSource.id > this.definedTypes.allowedSourceTypes.Factory">
                    <div class="field is-expanded" v-if="selectedSource && selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler">
                      <supplier-selector v-model="selectedWholeSaler" label="Wholesaler/Dealer" validateAs="purchased from wholesaler"></supplier-selector>
                      <span v-show="wholesalerError" class="help is-danger"><i v-show="wholesalerError" class="fas fa-exclamation-triangle"></i> {{ wholesalerError }}</span>
                    </div>
                    <div class="field is-expanded" v-if="selectedSource && selectedSource.id === this.definedTypes.allowedSourceTypes.Individual">
                      <customer-selector :selectedDealer="selectedDealer" v-model="selectedCustomer" label="Individual" validateAs="purchased from customer"></customer-selector>
                      <span v-show="customerError" class="help is-danger"><i v-show="customerError" class="fas fa-exclamation-triangle"></i> {{ customerError }}</span>
                    </div>
                  </div>
                </div>
                <div class="columns is-mobile is-multiline">
                  <div class="column is-12" v-if="selectedCustomer && !selectedCustomer.email">
                    <div class="field">
                      <label class="label">Customer Email Address<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left has-icons-right">
                        <input key="email" name="email" data-vv-as="customer email address" v-validate="'required|email'" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has('email') }" type="text" placeholder="Email" v-model="purchaseFromEmail" data-lpignore="true" autocomplete="off">
                        <span v-show="errors.has('email')" class="help is-danger">
                          <i v-show="errors.has('email')" class="fas fa-exclamation-triangle"></i> {{ errors.first('email') }}
                        </span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-envelope"></i>
                        </span>
                        <span class="icon is-small is-right" v-show="!errors.has('email')">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="icon is-small is-right" v-show="errors.has('email')">
                          <i class="fas fa-exclamation-triangle"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-12" v-if="selectedWholeSaler && !selectedWholeSaler.email">
                    <div class="field">
                      <label class="label">Supplier Contact Email Address<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left has-icons-right">
                        <input key="email" name="email" data-vv-as="customer email address" v-validate="'required|email'" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has('email') }" type="text" placeholder="Email" v-model="purchaseFromEmail" data-lpignore="true" autocomplete="off">
                        <span v-show="errors.has('email')" class="help is-danger">
                          <i v-show="errors.has('email')" class="fas fa-exclamation-triangle"></i> {{ errors.first('email') }}
                        </span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-envelope"></i>
                        </span>
                        <span class="icon is-small is-right" v-show="!errors.has('email')">
                          <i class="fas fa-check"></i>
                        </span>
                        <span class="icon is-small is-right" v-show="errors.has('email')">
                          <i class="fas fa-exclamation-triangle"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" v-if="requiresPurchaseCost">
                    <div class="field">
                      <label class="label">Purchase Cost<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money key="purchaseCost" name="purchaseCost" data-vv-as="purchase cost" v-validate="'required|decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('purchaseCost') }" type="text" placeholder="0.00" v-model="updatedPurchaseCost" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('purchaseCost')" class="help is-danger"><i v-show="errors.has('purchaseCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('purchaseCost') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" v-if="showMileageCapture">
                    <div class="field">
                      <label class="label">Mileage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control is-vertical-center">
                        <input name="usage" data-vv-as="miles" v-validate="'numeric|required'" :class="{'input': true, 'is-width-100': true, 'is-danger': errors.has('usage') }" type="number" placeholder="#####" v-model="updatedUsage" :min="1">
                        <usage-history-component :inventoryId="activeInventory.id" :vinNo="activeInventory.vinNo" :vinUsageHistory="activeInventory.vinUsageHistory"></usage-history-component>
                      </div>
                      <span v-show="errors.first('usage')" class="help is-danger"><i v-show="errors.first('usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('usage') }}</span>
                    </div>
                  </div>
                  <div class="column is-narrow" v-if="showMileageCapture">
                    <label class="label">Mileage Estimated</label>
                    <b-checkbox name="mileageEstimate" class="mileage-checkbox" size="is-medium" type="is-info" v-model="mileageEstimate"></b-checkbox>
                  </div>
                  <div class="column is-narrow" v-if="mileageEstimate && showMileageCapture">
                    <label class="label">True Mileage Unknown</label>
                    <b-checkbox name="trueMileageUnknown" class="mileage-checkbox" size="is-medium" type="is-info" v-model="trueMileageUnknown"></b-checkbox>
                  </div>
                  <div class="column is-narrow" v-if="mileageEstimate && showMileageCapture">
                    <label class="label">Mileage Exempt</label>
                    <b-checkbox name="mileageExempt" class="mileage-checkbox" size="is-medium" type="is-info" v-model="mileageExempt"></b-checkbox>
                  </div>
                </div>
                <div class="columns is-mobile is-multiline" v-if="requiresDealLinking">
                  <div class="column is-12">
                    <inventory-selector
                      label="This trade in is coming from the purchase of"
                      :required="true"
                      v-model="inventorySoldItem"
                      :selectedDealer="currentDealer"
                      :allowNew="false"
                      :showInstockOnly="false"
                      :showSoldOnly="true"
                      validateAs="purchased inventory item"
                      v-on:on-inventory-selected="onTradeItemSelected">
                    </inventory-selector>
                  </div>
                  <div class="column is-6" v-if="selectedTradeDeal">
                    <div class="field">
                      <label class="label no-content-wrap">Trade Allowance</label>
                      <div class="control has-icons-left">
                        <money name="tradeAllowance" data-vv-as="trade allowance" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('tradeAllowance') }" type="text" placeholder="0.00" v-model="tradeAllowance" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('tradeAllowance')" class="help is-danger"><i v-show="errors.has('tradeAllowance')" class="fas fa-exclamation-triangle"></i> {{ errors.first('tradeAllowance') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" v-if="isTradeSelectionLoading || selectedTradeDeal">
                    <div class="field">
                      <label class="label">Linked Deal</label>
                      <div class="control">
                        <span class="has-text-black title is-6" v-if="isTradeSelectionLoading">Loading matching deals...</span>
                        <span class="has-text-success title is-6" v-if="selectedTradeDeal">{{selectedTradeDeal.purchaserFullName}} - ({{selectedTradeDeal.dealStatus}})</span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-12" v-else-if="showLinkedDealNotFound">
                    <div class="notification is-flex is-danger">
                      <span><i class="fas fa-exclamation-triangle is-danger fa-2x"></i></span>
                      <span class="ml-10">
                        There are no deals found linked to the selected inventory item or if found none was selected. Please load a deal for the purchased vehicle and re-link the trade item or select one from the multiple deals found.
                        <br/><br/>Click <a @click="onTradeItemSelected(inventorySoldItem)">here</a> to check for deals on {{inventorySoldItem.name}}
                      </span>
                    </div>
                  </div>
                </div>

            </form>
          </section>
          <footer class="modal-card-foot">
            <div class="columns" style="width: 100%">
              <div class="column is-4">
                <button class="button is-primary" :class="{'is-loading' : isSaving}" @click="validateBeforeSubmit">Save</button>
                <button class="button is-danger" @click.prevent="onCancelMissingInfo">Cancel</button>
              </div>
              <div class="column is-4"></div>
            </div>
          </footer>
        </div>
      </b-modal>
    </portal>
    <pdf-modal
        :pdfFile="pdfModalFile"
        :usePortal="usePortal"
        :portalOrder="2"
        v-on:on-pdf-downloaded="onPreviewed"
        v-on:on-pdf-closed="onPreviewClosed"
        v-if="pdfModalFile && pdfModalFile.src">
    </pdf-modal>

    <portal to="global-modal-portal" v-else-if="activeDialog === 'FinalizeBillOfSale'" :order="3" :canCancel="false" :disabled="!usePortal">
      <b-modal :key="'BS'+instanceId" :active.sync="isBillOfSale" :has-modal-card="true" :canCancel="false" v-if="activeDialog === 'FinalizeBillOfSale'" class="sellers-agreement-modal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <div class="subtitle is-6 has-text-centered has-text-info is-marginless">Send Bill of Sale to purchase vehicle</div>
          </header>
          <section class="modal-card-body view-container" style="padding: 0 0;">
            <b-tabs size="is-small" :animated="false" type="is-toggle" @change="loadData()" v-model="tabIndex">
              <b-tab-item label="Definition" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                <section style="padding: 12px">
                  <div id="simple-overview" class="box" v-if="!billOfSaleVerificationActive">
                    <h3 class="title is-6">Vehicle Details</h3>
                    <div class="columns is-multiline">
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Vin Number</label>
                          <div class="field">
                            {{ activeInventory.vinNo }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Stock No</label>
                          <div class="field">
                            <a @click.stop.prevent="onStockNoClick(activeInventory)">
                              <span class="has-text-weight-semibold">
                                {{ activeInventory.stockNo }}
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Production Year</label>
                          <div class="field">
                            {{ activeInventory.vehicleProductionYear || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Make</label>
                          <div class="field">
                            {{ activeInventory.vehicleMakeName || activeInventory.customMake || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Model</label>
                          <div class="field">
                            {{ activeInventory.vehicleModelName || activeInventory.customModel || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Trim</label>
                          <div class="field">
                            {{ activeInventory.trim || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Style</label>
                          <div class="field">
                            {{ activeInventory.style || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Mileage</label>
                          <div class="field">
                            {{ activeInventory.usage || 'Unspecified' }}
                            &nbsp;
                            {{ activeInventory.mileageEstimate ? 'Estimated' : '' }}
                            &nbsp;
                            {{ activeInventory.trueMileageUnknown ? 'TMU' : ''}}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3" v-if="activeInventory.purchasedFromName">
                        <div class="field">
                          <label class="label">Purchased From</label>
                          <div class="field">
                            {{ activeInventory.purchasedFromName }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Sale Price</label>
                          <div class="field">
                            {{ applicablePurchaseCost | currency }}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="billOfSaleVerificationActive">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <car-fax-control
                          :inventory="activeInventory"
                          title="CarFax Vehicle Status"
                          :carFaxRating="activeInventory.carFaxRating"
                          :vinNo="activeInventory.vinNo"
                          :displayOnly="false"
                          v-on:on-new-carfax="onCarFaxForBillOfSale"
                          v-on:on-carfax-failed="onCarFaxForBillOfSale"
                          :runAutomatically="true"
                          :autoUpdateInventory="true"
                          :modalContainer="true"
                          v-on:on-show-carfax-report="onShowCarFaxReport">
                        </car-fax-control>
                      </div>
                      <div class="column is-12" v-if="autoCheckEnabled">
                        <auto-check-control
                          :inventory="activeInventory"
                          title="AutoCheck Vehicle Status"
                          :autoCheckRating="activeInventory.autoCheckRating"
                          :vinNo="activeInventory.vinNo"
                          :displayOnly="false"
                          v-on:on-new-autocheck="onAutoCheckForBillOfSale"
                          v-on:on-autocheck-failed="onAutoCheckForBillOfSale"
                          v-on:autocheck-disabled="onAutoCheckDisabled"
                          :runAutomatically="true"
                          :autoUpdateInventory="true"
                          :modalContainer="true"
                          v-on:on-show-autocheck-report="onShowAutoCheckReport"
                          :showRefresh="false"
                          :purchasedFromType="activeInventory.purchasedFromType"
                          :inventoryType="selectedInventoryStatusType">
                        </auto-check-control>
                      </div>
                      <div class="column is-12">
                        <inventory-appraisal-control
                          :appraisedValue="mostRecentAppraisal"
                          displayMode="Compact"
                          :inventory="inventoryAppraisalParams"
                          v-on:on-new-appraisal="onAppraisedForBillOfSale"
                          v-on:on-appraisal-failed="onAppraisedForBillOfSale"
                          :runAutomatically="true"
                          :autoUpdateInventory="true">
                        </inventory-appraisal-control>
                      </div>
                      <div class="column is-12 mt-10" v-if="blackBookEnabled">
                        <inventory-black-book-control
                        :blackBookInformation="mostRecentBlackBookInformation"
                        :inventory="inventoryAppraisalParams"
                        v-on:on-new-blackbook-information="onBlackBookInformationRetrieved"
                        v-on:on-black-book-failed="onBlackBookInformationRetrieved"
                        :runAutomatically="true"
                        :autoUpdateInventory="true"
                        v-on:black-book-disabled="onBlackBookDisabled">
                        </inventory-black-book-control>
                      </div>
                      <div class="verification-container column is-12" v-if="billOfSaleAppraisalCheckComplete && billOfSaleCarFaxCheckComplete && billOfSaleAutoCheckCheckComplete && blackBookCheckComplete && (!billOfSaleCarFaxIsGood || !billOfSaleAppraisalIsGood || !billOfSaleAutoCheckIsGood || !blackBookCheckIsGood)">
                        <h4 class="title is-size-7 has-text-danger" v-if="billOfSaleCarFaxFailed">Unable to get latest CarFax report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="!billOfSaleCarFaxIsGood">The latest CarFax report indicated multiple owners, a recall pending or some form of damage.</h4>
                        <h4 class="title is-size-7 has-text-danger" v-if="autoCheckEnabled && billOfSaleAutoCheckFailed">Unable to get latest AutoCheck report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="autoCheckEnabled && !billOfSaleAutoCheckIsGood">The latest AutoCheck report indicated multiple owners, a recall pending or some form of damage.</h4>
                        <h4 class="title is-size-7 has-text-danger" v-if="billOfSaleAppraisalFailed">Unable to get latest appraisal report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="!billOfSaleAppraisalIsGood">The proposed purchase price OR asking price of this vehicle is outside your dealer's acceptable market value tolerance.</h4>
                        <h4 class="title is-size-7 has-text-danger" v-if="blackBookEnabled && blackBookCheckFailed">Unable to get latest black book report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="blackBookEnabled && !blackBookCheckIsGood">Unable to obtain a complete black book report for this vehicle.</h4>
                        <h4 class="title is-size-7">Please Note: By activating the switch below you acknowledge the above vehicle's current status and accept responsibility for the purchase and/or sale of the vehicle and I am fully aware of the conditions and market value of the vehicle.</h4>
                        <div class="columns">
                          <b-switch class="column is-narrow is-vcentered" type="is-info" v-model="billOfSaleAcceptConditions"></b-switch>
                          <h4 class="title is-size-7 column is-narrow is-vcentered" v-if="billOfSaleAcceptConditions">I ACCEPT the above statement</h4>
                          <h4 class="title is-size-7 column is-narrow is-vcentered" v-if="!billOfSaleAcceptConditions">I DO NOT ACCEPT the above statement</h4>
                        </div>
                      </div>
                      <div class="status-container column is-12" v-if="billOfSaleAppraisalCheckComplete && billOfSaleCarFaxCheckComplete && carFaxApproved && appraisalApproved &&  autoCheckValidated && blackBookCheckComplete && blackBookCheckIsGood">
                        <h4 class="title is-size-7 has-text-primary">{{approvalInformation}}</h4>
                      </div>
                      <div class="status-container column is-12" v-else-if="billOfSaleAppraisalCheckComplete && billOfSaleCarFaxCheckComplete && billOfSaleCarFaxIsGood && billOfSaleAppraisalIsGood && autoCheckValidated && blackBookCheckComplete && blackBookCheckIsGood">
                        <h4 class="title is-size-7 has-text-primary">The vehicle condition matches your dealers requirements and is approved for purchase.</h4>
                      </div>
                      <div class="status-container column is-12" v-if="!billOfSaleAppraisalCheckComplete || !billOfSaleCarFaxCheckComplete || !billOfSaleAutoCheckCheckComplete || !blackBookCheckComplete">
                        Checking latest vehicle reports. Please wait...
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="!isUpload">
                      <form data-vv-scope="recipient-form">
                        <div class="columns is-multiline">
                          <div class="column is-12">
                            <label class="label">Sale Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                            <el-date-picker
                              data-vv-scope="recipient-form"
                              @change="onFormChange()"
                              v-model="saleDate"
                              type="date"
                              format="MM-dd-yyyy"
                              name="saleDate"
                              id="saleDate"
                              data-vv-as="sale date"
                              :class="{'is-danger': errors.has('saleDate') }"
                              v-validate="'required'"
                              popper-class="datepicker-container"
                              placeholder="Click to select...">
                            </el-date-picker>
                            <span v-show="errors.has('recipient-form.saleDate')" class="help is-danger">
                              <i v-show="errors.has('recipient-form.saleDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.saleDate') }}
                            </span>
                          </div>
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Recipients Email<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <multiselect
                                :multiple="true"
                                :taggable="true"
                                v-validate="{'required': true}"
                                data-vv-scope="recipient-form"
                                data-vv-as="signature recipients"
                                data-vv-validate-on="input|close"
                                :class="{'is-danger': errors.has('recipient-form.recipients') }"
                                @tag="onRecipientAdded"
                                name="recipients"
                                label="fullName"
                                trackBy="email"
                                tagPlaceholder="Enter to add email address"
                                v-model="selectedRecipients"
                                :options="selectedRecipients">
                              </multiselect>
                              <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                              <span v-show="mustShowEmailError" class="help is-danger"><i v-show="mustShowEmailError" class="fas fa-exclamation-triangle"></i> {{ emailMatchErrorMessage }}</span>
                            </div>
                          </div>
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Recipients Mobile</label>
                              <multiselect
                                :multiple="true"
                                :taggable="true"
                                data-vv-scope="recipient-form"
                                data-vv-as="signature recipients mobile"
                                data-vv-validate-on="input|close"
                                @tag="onRecipientMobileAdded"
                                name="recipients-mobile"
                                label="fullName"
                                trackBy="mobile"
                                tagPlaceholder="Enter to add mobile number"
                                v-model="selectedRecipientsMobile"
                                :options="selectedRecipientsMobile">
                              </multiselect>
                              <span v-show="mobileInvalidFormat !== ''" class="help is-danger"><i v-show="mobileInvalidFormat !== ''" class="fas fa-exclamation-triangle"></i> {{ mobileInvalidFormat }}</span>
                            </div>
                          </div>
                        </div>
                      </form>
                      <div class="columns">
                        <div class="column is-12">
                          <div class="field">
                            <label class="label">Subject</label>
                            <div class="control">
                              <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-12">
                          <div class="field">
                            <label class="label">Message (optional)</label>
                            <div class="control">
                              <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <div class="columns" style="margin-left:-0.5em">
                        <div class="column is-12">
                          <file-selector
                          v-model="salesOrder"
                          :multiple="false"
                          label="Signed Agreement"
                          :required="true"
                          :isBoxed="false"
                          :usePortalViewer="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </b-tab-item>
              <b-tab-item label="History" icon-pack="fas" icon="fas fa-history fa-sm">
                <section>
                  <b-table
                    class="lists"
                    :data="documentSigningRequestData">
                    <template slot-scope="props">
                      <b-table-column field="status" label="Status" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        <i data-type="P" class="icon fal fa-envelope-open-text fa-lg list-icon" :class="getColorClassByAgreementStatus(props.row.signingStatus)" v-tooltip="'Purchasers Agreement Status: ' + getDescriptionByAgreementStatus(props.row.signingStatus)"/>
                        <a target="live-doc-download" :href="buildSellersAgreementUrl(props.row.dealFileLocation)" v-tooltip="'Click to download the last signed purchasers agreement.'"  v-if="props.row.dealFileLocation && (props.row.signingStatus === 10 || props.row.signingStatus === 3)"><i class="icon fal fa-cloud-download fa-lg list-icon has-text-success"/></a>
                      </b-table-column>
                      <b-table-column field="email" label="Recipients" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.destinationAddresses }}
                      </b-table-column>
                      <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ formateSignatureType(props.row.type) }}
                      </b-table-column>
                      <b-table-column field="date" label="Date" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.sentAt | formatDateOnly }}
                      </b-table-column>
                      <b-table-column field="time" label="Time" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ convertTimeString(props.row.sentAt, props.row.signingStatus) }}
                      </b-table-column>
                      <b-table-column field="id" label="Actions">
                        <a v-if="props.row.cancellationSent === false" @click.stop.prevent="onCancelSellersAgreement(props.row)"><i class="icon fa-fw fal fa-times-circle fa-lg" :class="{'has-text-danger': !isCancellationDisabled(props.row), 'has-text-grey': isCancellationDisabled(props.row)}" v-tooltip="isCancellationDisabled(props.row) ? 'Request cannot be cancelled at this point' : 'Cancel Request'"/></a>
                        <a v-else><i class="icon fa-fw fal fa-times-circle fa-lg has-text-success" v-tooltip="'Cancellation request has been made'"/></a>
                        <a  v-if="props.row.reminderSent === false" @click.stop.prevent="onSellersAgreementReminder(props.row)"><i class="icon fa-fw fal fa-redo fa-lg" :class="{'has-text-link': !isReminderDisabled(props.row), 'has-text-grey': isReminderDisabled(props.row)}" v-tooltip="isReminderDisabled(props.row) ? 'Reminder cannot be sent at this point' : 'Send Reminder'" /></a>
                        <a v-else><i class="icon fa-fw fal fa-envelope fa-lg has-text-success" v-tooltip="'Reminder has been sent'"/></a>
                      </b-table-column>
                    </template>

                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No purchase documents has been sent for this item.</p>
                        </div>
                      </section>
                    </template>

                  </b-table>
                </section>
              </b-tab-item>
            </b-tabs>
          </section>

          <footer class="modal-card-foot">
            <div class="columns" style="width: 100%">
              <div :class="isMobile ? 'column is-2 is-pulled-left' : 'column is-4'">
                <button type="button" class="button is-primary" @click="onVerifySalesOrder(activeInventory)" v-if="!billOfSaleVerificationActive && !isUpload && !isMobile">Verify</button>
                <button type="button" class="button is-primary" @click="onVerifySalesOrder(activeInventory)" v-else-if="!billOfSaleVerificationActive && !isUpload && isMobile"><b-icon pack="fal" icon="badge-check" custom-size="fa-lg"></b-icon></button>
                <button type="button" class="button is-primary" @click="onEmailSalesOrder(activeInventory, false)" v-if="billOfSaleVerificationActive && !isUpload && !isMobile" :disabled="billOfSaleFinalSendDisallowed">Send</button>
                <button type="button" class="button is-primary" @click="onEmailSalesOrder(activeInventory, false)" v-else-if="billOfSaleVerificationActive && !isUpload && isMobile" :disabled="billOfSaleFinalSendDisallowed"><b-icon pack="fal" icon="envelope" custom-size="fa-lg"></b-icon></button>
                <button type="button" class="button is-primary" @click="onVerifySalesOrder(activeInventory)" v-if="!billOfSaleVerificationActive && isUpload && !isMobile">Verify</button>
                <button type="button" class="button is-primary" @click="onVerifySalesOrder(activeInventory)" v-else-if="!billOfSaleVerificationActive && isUpload && isMobile"><b-icon pack="fal" icon="badge-check" custom-size="fa-lg"></b-icon></button>
                <button type="button" class="button is-primary" @click="onEmailSalesOrder(activeInventory, false)" v-if="billOfSaleVerificationActive && isUpload && !isMobile" :disabled="billOfSaleFinalSendDisallowed">Save</button>
                <button type="button" class="button is-primary" @click="onEmailSalesOrder(activeInventory, false)" v-else-if="billOfSaleVerificationActive && isUpload && isMobile" :disabled="billOfSaleFinalSendDisallowed"><b-icon pack="fal" icon="save" custom-size="fa-lg"></b-icon></button>
                <button type="button" class="button is-danger" @click="updateSalesOrderModalState(false)" v-if="!isMobile">Cancel</button>
                <button type="button" class="button is-danger" @click="updateSalesOrderModalState(false)" v-else-if="isMobile"><b-icon pack="fal" icon="ban" custom-size="fa-lg"></b-icon></button>
              </div>
              <div :class="isMobile ? 'column is-2 is-pulled-right' : 'column is-8'">
                <button type="button" class="button is-primary is-pulled-right" :class="{'is-loading': isSaving && isSignNow }" style="margin-right: 5px;" title="This action will save your bill of sale and allow you to capture a signature immediately" @click="onEmailSalesOrder(activeInventory, true)" v-if="billOfSaleVerificationActive && !isUpload && !isMobile" :disabled="billOfSaleFinalSendDisallowed || isUploadingFile">Sign</button>
                <button type="button" class="button is-primary is-pulled-right" :class="{'is-loading': isSaving && isSignNow }" style="margin-right: 5px;" @click="onEmailSalesOrder(activeInventory, true)" v-else-if="billOfSaleVerificationActive && !isUpload && isMobile" :disabled="billOfSaleFinalSendDisallowed || isUploadingFile"><b-icon pack="fal" icon="pencil" custom-size="fa-lg"></b-icon></button>
                <button type="button" class="button is-primary is-pulled-right" style="margin-right: 5px;" @click="onPreview(activeInventory)" v-if="!isUpload && !isMobile">Preview</button>
                <button type="button" class="button is-primary is-pulled-right" style="margin-right: 5px;" @click="onPreview(activeInventory)" v-else-if="!isUpload && isMobile"><b-icon pack="fal" icon="eye" custom-size="fa-lg"></b-icon></button>
                <button type="button" class="button is-primary is-pulled-right" style="margin-right: 5px;" @click="updateUploadState()" v-if="!isMobile">{{ isUpload ? 'Cancel Upload & Send For Signing' : 'Upload Signed Agreement' }}</button>
                <button type="button" class="button is-primary is-pulled-right" style="margin-right: 5px;" :title="isUpload ? 'Cancel Upload & Send For Signing' : 'Upload Signed Agreement'" @click="updateUploadState()" v-else-if="isMobile"><b-icon pack="fal" :icon="isUpload ? 'window-close' : 'file-upload'" custom-size="fa-lg"></b-icon></button>
              </div>
            </div>
          </footer>
        </div>
      </b-modal>
    </portal>
    <portal to="global-modal-portal" style="max-width: 500px;" v-if="activeDialog === 'SignatureViewer'" :order="4" :disabled="!usePortal">
      <b-modal :key="'BP' + instanceId" :active.sync="isSignatureActive" scroll="keep"  :has-modal-card="true" v-if="activeDialog === 'SignatureViewer'" :canCancel="false">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">Signing Bill of Sale</p>
          </header>
          <section class="modal-sign-body">
            <vue-signature ref="signature" :sigOption="option" :w="'500px'" :h="'150px'"></vue-signature>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': loadingSign }" @click.prevent="onSign">Sign</button>
            <button class="button is-warning" @click.prevent="onClearSign">Clear</button>
            <button class="button is-danger" @click.prevent="onCancelSignNow">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
    <pdf-modal
        :pdfFile="signatureBillOfSalePdfModalFile"
        :usePortal="usePortal"
        :portalOrder="5"
        v-on:on-pdf-downloaded="onSignatureBillOfSalePreviewed"
        v-on:on-pdf-closed="onSignatureBillOfSalePreviewClosed"
        v-if="signatureBillOfSalePdfModalFile && signatureBillOfSalePdfModalFile.src">
    </pdf-modal>

    <portal to="global-modal-portal" v-if="showTradeDealSelectorDialog" :order="6" :disabled="!usePortal">
      <b-modal :active.sync="showTradeDealSelectorDialog" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title" v-if="noTradeDealsAvailable === true">Warning - No deals found</p>
            <p class="modal-card-title" v-if="linkedDealsByTradeParent.length > 1">Warning - Trade linked to multiple deals</p>
            <p class="modal-card-title" v-if="matchingDealsByTradeParent.length > 1">Warning - Multiple matching deals</p>
          </header>
          <section class="modal-card-body" v-if="noTradeDealsAvailable === true">
            <div class="title has-text-danger is-6">There are no deals found linked to the selected inventory item. Please load a deal for the purchased vehicle and re-link the trade item</div>
          </section>
          <section class="modal-card-body" v-if="linkedDealsByTradeParent.length > 1">
            <div class="title has-text-danger is-6">The trade inventory item is incorrectly linked to multiple active deals and needs to be fixed</div>
          </section>
          <section class="modal-card-body has-text-info is-6" v-if="matchingDealsByTradeParent.length > 1">
            <div class="title has-text-info is-6">There are multiple deals linked to the selected inventory item. Please select the appropriate deal below</div>
            <div class="buttons has-addons">
              <button :class="{'button': true, 'is-info': selectedTradeDeal && selectedTradeDeal.dealId === matchingDeal.dealId}" v-for="matchingDeal in matchingDealsByTradeParent" :key="matchingDeal.dealId" @click="onSelectedTradeDeal(matchingDeal)">
                {{matchingDeal.purchaserFullName}}
              </button>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="showTradeDealSelectorDialog = false">Ok</button>
          </footer>
        </div>
      </b-modal>
    </portal>

    <auto-check-report-control
      v-if="autoCheckEnabled"
      :inventory="activeInventory"
      title="AutoCheck Vehicle Status"
      :vinNo="activeInventory.vinNo"
      v-on:on-hide-autocheck-report="onHideAutoCheckReport"
      :showAutoCheckReportModal="showReport">
    </auto-check-report-control>
    <car-fax-report-control
      title="CarFax Vehicle Status"
      v-on:on-hide-carfax-report="onHideCarFaxReport"
      :showCarFaxReportModal="showCarFaxReport"
      :carFaxReportUrl="carFaxReportUrl">
    </car-fax-report-control>
    <send-reminder-control
      v-on:on-hide-reminder-control="onHideReminderModal"
      :showConfirmationModal="showConfirmReminder"
      :signatureRequest="signatureRequest"
      v-on:send-reminder="sendReminder">
    </send-reminder-control>
    <cancel-signature-request-control
      v-on:on-hide-cancel-request-control="onHideCancellationRequestModal"
      :showRequestCancellationModal="showCancelRequest"
      :signatureRequest="cancelSignatureRequest"
      v-on:send-cancellation-request="sendCancellationRequest">
    </cancel-signature-request-control>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import logger from '@/services/loggingService'
import inventoryService from '@/services/inventoryService'
import supplierService from '@/services/supplierService'
import customerService from '@/services/customerService'
import documentSigningRequestService from '@/services/documentSigningRequestService'
import formattingMixin from '@/mixins/inventory/formatting'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import CustomerSelector from '@/components/generic/CustomerSelector'
import SupplierSelector from '@/components/generic/SupplierSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import InventorySelector from '@/components/generic/InventorySelector.vue'
import UsageHistoryComponent from '@/components/inventory/UsageHistoryComponent'
import CarFaxControl from '@/components/generic/CarFaxControl'
import InventoryAppraisalControl from '@/components/generic/InventoryAppraisalControl'
import FileSelector from '@/components/generic/FileSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import dealService from '@/services/dealService'
import InventoryTitleCapture from '@/components/generic/inventory/InventoryTitleCapture'
import utilitiesMixin from '@/mixins/generic/utilities'
import Vue from 'vue'
import AutoCheckControl from '@/components/generic/AutoCheckControl'
import AutoCheckReportControl from '@/components/generic/AutoCheckReportControl'
import CarFaxReportControl from '@/components/generic/CarFaxReportControl'
import SendReminderControl from '@/components/generic/SendReminderControl'
import CancelSignatureRequestControl from '@/components/generic/CancelSignatureRequestControl'
import InventoryBlackBookControl from '@/components/generic/InventoryBlackBookControl'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapSubscriberGetters = createNamespacedHelpers('subscriber').mapGetters

export default {
  name: 'InventorySellersAgreementAction',
  mixins: [humanizedSwitchMixin, validateBeforeSubmitMixin, formattingMixin, utilitiesMixin],
  components: {
    'car-fax-control': CarFaxControl,
    'inventory-appraisal-control': InventoryAppraisalControl,
    'customer-selector': CustomerSelector,
    'usage-history-component': UsageHistoryComponent,
    'error-display-component': ErrorDisplayComponent,
    'supplier-selector': SupplierSelector,
    'inventory-selector': InventorySelector,
    'button-list-selector': ButtonListSelector,
    'file-selector': FileSelector,
    'title-status-capture': InventoryTitleCapture,
    'auto-check-control': AutoCheckControl,
    'auto-check-report-control': AutoCheckReportControl,
    'car-fax-report-control': CarFaxReportControl,
    'send-reminder-control': SendReminderControl,
    'cancel-signature-request-control': CancelSignatureRequestControl,
    'inventory-black-book-control': InventoryBlackBookControl,
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    sourceDealInventory: {
      type: Object,
      default: undefined
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeInventory: null,
      documentSigningRequestData: [],
      billOfSaleVerificationActive: false,
      billOfSaleCarFaxCheckComplete: false,
      billOfSaleCarFaxIsGood: false,
      billOfSaleCarFaxFailed: false,
      billOfSaleAcceptConditions: false,
      billOfSaleAppraisalCheckComplete: false,
      latestAppraisal: null,
      billOfSaleAppraisalIsGood: false,
      billOfSaleAppraisalFailed: false,
      isBillOfSale: false,
      instanceId: this.$uuid.v4(),
      selectedRecipients: [],
      selectedDealer: null,
      isUpload: false,
      salesOrder: [],
      isSaving: false,
      selectedTypeStatus: null,
      selectedSource: null,
      selectedWholeSaler: null,
      selectedCustomer: null,
      serverErrorMessage: '',
      serverErrors: [],
      isLoading: false,
      tabIndex: 0,
      updatedUsage: null,
      updatedPurchaseCost: null,
      sourceError: '',
      customerError: '',
      supplierError: '',
      purchaseFromEmail: '',
      selectedRecipientsMobile: [],
      mobileInvalidFormat: '',
      allowTitleCaptureSkip: false,
      emailMatchErrorMessage: '',
      matchingDealsByTradeParent: [],
      linkedDealsByTradeParent: [],
      noTradeDealsAvailable: false,
      isTradeSelectionLoading: false,
      showTradeDealSelectorDialog: false,
      selectedTradeDeal: null,
      inventorySoldItem: null,
      tradeAllowance: null,
      billOfSaleAutoCheckCheckComplete: false,
      billOfSaleAutoCheckIsGood: false,
      billOfSaleAutoCheckFailed: false,
      showReport: false,
      showCarFaxReport: false,
      signatureRequest: null,
      showConfirmReminder: false,
      sendingReminder: false,
      cancelSignatureRequest: null,
      showCancelRequest: false,
      sendingCancellationRequest: false,
      saleDate: null,
      isSignatureActive: false,
      loadingSign: false,
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)'
      },
      loanerSelectedPdf: null,
      downloadUrl: '',
      isMobile: window.innerWidth <= 700,
      carFaxHashMatch: false,
      autoCheckHashMatch: false,
      appraisalRatingHashMatch: false,
      carFaxApproved: false,
      autoCheckApproved: false,
      appraisalApproved: false,
      mileageEstimate: false,
      trueMileageUnknown: false,
      mileageExempt: false,
      showMileageCapture: false,
      isUploadingFile: false,
      fileUploadCount: 0,
      blackBookInformation: null,
      blackBookCheckComplete: false,
      blackBookCheckIsGood: false,
      blackBookCheckFailed: false,
      pdfModalFile: null,
      signatureBillOfSalePdfModalFile: null
    }
  },
  methods: {
    onTradeItemSelected: function (selectedTradeParentItem) {
      let that = this
      this.isTradeSelectionLoading = true
      dealService.getDealsByInventoryForTrade(this.activeInventory, selectedTradeParentItem).then(response => {
        that.isTradeSelectionLoading = false
        if (response.errors) {
          that.serverErrors = response.errors
        } else {
          that.matchingDealsByTradeParent = response.matchingDeals
          that.linkedDealsByTradeParent = response.linkedDeals
          that.noTradeDealsAvailable = false
          that.showTradeDealSelectorDialog = false
          that.selectedTradeDeal = null
          that.tradeAllowance = null

          that.$nextTick(() => {
            if (that.linkedDealsByTradeParent.length === 1) {
              // The good case where this trade is already linked to one deal for the selected inventory
              that.selectedTradeDeal = that.linkedDealsByTradeParent[0]
              that.tradeAllowance = that.selectedTradeDeal.tradeAllowance
            } else if (that.linkedDealsByTradeParent.length > 1) {
              // The bad case where this trade is already linked to multiple deals which should not be the case. We need to prompt for resolution
            } else if (that.linkedDealsByTradeParent.length === 0) {
              // The not linked case and we need to prompt for deal selection
              if (that.matchingDealsByTradeParent.length === 0) {
                that.noTradeDealsAvailable = true
                that.showTradeDealSelectorDialog = true
              } else if (that.matchingDealsByTradeParent.length > 1) {
                that.showTradeDealSelectorDialog = true
              } else if (that.matchingDealsByTradeParent.length === 1) {
                that.selectedTradeDeal = that.matchingDealsByTradeParent[0]
                that.tradeAllowance = that.selectedTradeDeal.tradeAllowance
              }
            }
            // if (that.selectedTradeDeal) {
            //   if (that.selectedTradeDeal.purchaserType === 'C') {
            //     that.selectedSource = that.definedTypes.allowedSourceTypes.options.find((x) => x.id === that.definedTypes.allowedSourceTypes.Individual)
            //     that.selectedCustomer = {
            //       id: that.selectedTradeDeal.purchaserId,
            //       fullName: that.selectedTradeDeal.purchaserFullName
            //     }
            //   }

            //   if (that.selectedTradeDeal.purchaserType === 'S') {
            //     that.selectedSource = that.definedTypes.allowedSourceTypes.options.find((x) => x.id === that.definedTypes.allowedSourceTypes.Wholesaler)
            //     that.selectedWholeSaler = {
            //       id: that.selectedTradeDeal.purchaserId,
            //       name: that.selectedTradeDeal.purchaserFullName
            //     }
            //   }
            // }
          })
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isTradeSelectionLoading = false
        this.matchingDealsByTradeParent = []
        this.linkedDealsByTradeParent = []
        this.selectedTradeDeal = null
        this.noTradeDealsAvailable = false
        this.tradeAllowance = null
      })
    },
    onSelectedTradeDeal: function (deal) {
      this.selectedTradeDeal = deal
    },
    isCancellationDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    isReminderDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    onCancelSellersAgreement: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.cancelSignatureRequest = signatureRequest
        this.onShowCancellationRequestModal()
      } else {
        this.failedToast('Signature request cannot be cancelled at this point.')
      }
    },
    onSellersAgreementReminder: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.signatureRequest = signatureRequest
        this.onShowReminderModal()
      } else {
        this.failedToast('Signature request reminder cannot be sent at this point.')
      }
    },
    formateSignatureType: function (type) {
      return type === 'LeaseReturned' ? 'Lease Returned' : type
    },
    onError: function (error) {
      this.serverErrorMessage = error.message
      this.isLoading = false
      this.isSaving = false
    },
    onSave: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true

      if (this.selectedSource) {
        if (this.selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler) {
          if (!this.selectedWholeSaler) {
            this.wholesalerError = 'Please select a supplier from whom we are purchasing the vehicle.'
            return
          }
        } else {
          if (!this.selectedCustomer) {
            this.customerError = 'Please select a customer from whom we are purchasing the vehicle.'
            return
          }
        }
      } else {
        this.sourceError = 'Please select the type of entity we are purchasing this vehicle from.'
        return
      }

      var inventoryModel = {
        id: this.activeInventory.id,
        purchasedFromType: this.selectedSource.altName,
        purchasedFromId: this.selectedSource.id === this.definedTypes.allowedSourceTypes.Factory ? null : (this.selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler ? this.selectedWholeSaler.id : this.selectedCustomer.id),
        purchasedFromName: this.selectedSource.id === this.definedTypes.allowedSourceTypes.Factory ? null : (this.selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler ? this.selectedWholeSaler.name : this.selectedCustomer.fullName),
        mileageEstimate: this.mileageEstimate,
        trueMileageUnknown: this.trueMileageUnknown,
        mileageExempt: this.mileageExempt,
        usage: this.updatedUsage,
        purchaseCost: this.updatedPurchaseCost,
        purchasedFromContactEmail: this.purchaseFromEmail,
        tradeSourceInventoryId: this.inventorySoldItem ? this.inventorySoldItem.id : null,
        tradeSourceDealId: this.selectedTradeDeal ? this.selectedTradeDeal.dealId : null,
        tradeAllowance: this.selectedTradeDeal ? (this.tradeAllowance || 0.00) : 0.00
      }
      inventoryService.updateForBillOfSale(inventoryModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.onValidationError(response.errors)
        } else {
          this.activeInventory = response.inventory
          if (this.activeInventory && !this.activeInventory.purchasedFromContactEmail) {
            this.activeInventory.purchasedFromContactEmail = this.purchaseFromEmail
          }
          if (this.selectedWholeSaler && !this.selectedWholeSaler.email) {
            this.selectedWholeSaler.email = this.purchaseFromEmail
          }
          if (this.updatedUsage > 0) {
            this.showMileageCapture = false
          }

          this.updateSalesOrderModalState(true)
        }
      }).catch((error) => {
        this.onError(error)
      })
    },
    onValidationError: function (errors) {
      this.serverErrors = errors
      this.$nextTick(() => {
        this.$goToErrorBlock(this.$refs.generalErrorBlock)
      })
    },
    onCancelMissingInfo: function () {
      this.isBillOfSale = false
      eventBus.$emit('sellers-agreement-cancelled', {})
      this.activeInventory.actionCancelled = true
      this.$emit('sellers-agreement-complete', this.activeInventory)
    },
    loadData: function () {
      if (this.tabIndex === 0) return
      let inventoryId = this.activeInventory.id
      const searchCriteria = {
        inventoryId: inventoryId,
        dealerId: this.currentDealer.id,
        signingRequestType: 1,
        query: '',
        pageSize: this.perPage,
        sortBy: 'sentAt',
        page: this.page
      }
      this.isLoading = true
      documentSigningRequestService.searchByType(searchCriteria).then(response => {
        this.documentSigningRequestData = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          item.reminderSent = false
          item.cancellationSent = false
          this.documentSigningRequestData.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.documentSigningRequestData = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    convertTimeString: function (date, status) {
      if (status !== 0) {
        var d = new Date(date)
        var hour = '' + d.getHours()
        var minute = '' + d.getMinutes()

        if (hour.length < 2) hour = '0' + hour
        if (minute.length < 2) minute = '0' + minute

        return [hour, minute].join(':')
      } else {
        return ''
      }
    },
    onSellersAgreementRequired: function () {
      if (this.hasSalesOrderAccess && this.isValidForSellersAgreementSigning) {
        this.updateSalesOrderModalState(true)
      }
    },
    onDownloadLastSellersAgreement: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.lastSignedSellersAgreement)
    },
    onDownloadListSellersAgreement: function (signedAgreementUrl) {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(signedAgreementUrl)
    },
    buildSellersAgreementUrl: function (signedAgreementUrl) {
      return process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(signedAgreementUrl)
    },
    onPrint: function () {
      var pdfControls = this.$refs.pdfViewer
      if (pdfControls) {
        if (Array.isArray(pdfControls) && pdfControls.length > 0) {
          pdfControls[0].print(100)
        } else {
          pdfControls.print(100)
        }
      } else {
        this.failedToast('Printing support is not available in this browser.')
      }
    },
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data,
        email: data
      }

      this.selectedRecipients.push(recipient)
      this.checkEmails(this.selectedRecipients)
    },
    onRecipientMobileAdded: function (data) {
      this.mobileInvalidFormat = ''
      if (data && data.length === 16) {
        var recipient = {
          fullName: data,
          mobile: data
        }
        this.selectedRecipientsMobile.push(recipient)
      } else {
        this.mobileInvalidFormat = 'Invalid format for mobile number:  ' + data + '. Mobile format should be +1(xxx)-xxx-xxxx'
      }
    },
    clearAllRecipients: function () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      this.isUpload = false
      this.salesOrder = []
      this.mobileInvalidFormat = ''
      this.emailMatchErrorMessage = ''
    },
    onCarFaxForBillOfSale: function (carFaxRating) {
      this.billOfSaleCarFaxCheckComplete = true
      this.billOfSaleCarFaxIsGood = carFaxRating && !(carFaxRating.brandedTitle === true || (carFaxRating.totalAccidents && carFaxRating.totalAccidents > 0) || carFaxRating.accidentIndicator === true || carFaxRating.frameDamageOrRepair === true || carFaxRating.airBagDeployed === true || carFaxRating.damaged === true || carFaxRating.majorProblems === true || carFaxRating.recall === true)
      if (this.currentInventory && this.currentInventory.carFaxRating && this.currentInventory.carFaxRating.hashValue && carFaxRating && carFaxRating.hashValue) {
        this.carFaxHashMatch = (this.currentInventory.carFaxRating.hashValue === carFaxRating.hashValue)
      } else {
        this.carFaxHashMatch = false
      }
      this.billOfSaleCarFaxFailed = !carFaxRating
      if (this.carFaxHashMatch === true && this.hasCarFaxRatingApproval === true) {
        this.billOfSaleCarFaxIsGood = true
        this.carFaxApproved = true
        this.billOfSaleCarFaxFailed = false
      } else if (this.currentInventory && (!this.currentInventory.carFaxRating || !carFaxRating) && this.currentInventory.hasRatingApproval === true) {
        this.billOfSaleCarFaxIsGood = true
        this.carFaxApproved = true
        this.billOfSaleCarFaxFailed = false
      }
    },
    onAppraisedForBillOfSale: function (appraisal) {
      this.billOfSaleAppraisalCheckComplete = true
      this.latestAppraisal = appraisal
      this.billOfSaleAppraisalIsGood = appraisal && appraisal.isGood

      if (this.currentInventory && this.currentInventory.appraisalRating && this.currentInventory.appraisalRating.hashValue && appraisal && appraisal.hashValue) {
        this.appraisalRatingHashMatch = (this.currentInventory.appraisalRating.hashValue === appraisal.hashValue)
      } else {
        this.appraisalRatingHashMatch = false
      }
      this.billOfSaleAppraisalFailed = !appraisal
      if (this.appraisalRatingHashMatch === true && this.hasAppraisalRatingApproval === true) {
        this.billOfSaleAppraisalIsGood = true
        this.appraisalApproved = true
        this.billOfSaleAppraisalFailed = false
      } else if (this.currentInventory && !this.currentInventory.appraisalRating && this.currentInventory.hasRatingApproval === true) {
        this.billOfSaleAppraisalIsGood = true
        this.appraisalApproved = true
        this.billOfSaleAppraisalFailed = false
      }
    },
    updateSalesOrderModalState: function (state) {
      this.isBillOfSale = state

      if (state === true) {
        this.billOfSaleVerificationActive = false
        this.billOfSaleCarFaxCheckComplete = false
        this.billOfSaleCarFaxIsGood = false
        this.billOfSaleCarFaxFailed = false
        this.billOfSaleAutoCheckCheckComplete = !this.autoCheckEnabled
        this.billOfSaleAutoCheckIsGood = false
        this.billOfSaleAutoCheckFailed = false
        this.billOfSaleAcceptConditions = false
        this.billOfSaleAppraisalCheckComplete = false
        this.billOfSaleAppraisalIsGood = false
        this.billOfSaleAppraisalFailed = false
        this.blackBookCheckComplete = !this.blackBookEnabled
        this.overridingSubject = 'Bill of Sale - ' + this.activeInventory.name + ' - ' + this.activeInventory.vinNo
        this.optionalMessage = 'Please review and sign the attached Bill of Sale for ' + this.activeInventory.name + ' - ' + this.activeInventory.vinNo + ' to us for ' + this.$formatCurrency(this.applicablePurchaseCost)

        if (this.activeInventory.purchasedFromType === 'Wholesaler' && this.activeInventory.purchasedFromId) {
          supplierService.single(this.activeInventory.purchasedFromId).then(response => {
            let purchasingSupplier = response.supplier
            let validContacts = purchasingSupplier.contacts.filter((x) => x.status === 'Active' && x.receivesSellersOrder === true && x.email !== '')

            // email
            this.availableAddresses = validContacts.map(function (y) {
              return {
                email: y.email,
                fullName: y.fullName ? y.fullName + '(' + y.email + ')' : y.fullName
              }
            })
            if (this.availableAddresses.length > 0) {
              this.selectedRecipients = this.availableAddresses
            } else {
              this.selectedRecipients = this.purchaseFromEmail ? [{email: this.purchaseFromEmail, fullName: this.purchaseFromEmail}] : []
            }

            this.checkEmails(this.selectedRecipients)

            // mobile
            this.availableMobileNumbers = validContacts.filter(x => x.mobile !== '' && x.mobile !== null && x.mobile.length === 16).map(function (y) {
              return {
                mobile: y.mobile,
                fullName: y.fullName ? y.fullName + '(' + y.mobile + ')' : y.fullName
              }
            })
            if (this.availableMobileNumbers.length > 0) {
              this.selectedRecipientsMobile = this.availableMobileNumbers
            } else {
              this.selectedRecipientsMobile = []
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
          })
        } else {
          if (this.activeInventory.purchasedFromContactEmail && this.activeInventory.purchasedFromContactEmail !== '') {
            this.availableAddresses = [{
              email: this.activeInventory.purchasedFromContactEmail,
              fullName: this.activeInventory.purchasedFromContactName ? this.activeInventory.purchasedFromContactName + '(' + this.activeInventory.purchasedFromContactEmail + ')' : this.activeInventory.purchasedFromContactEmail
            }]
            this.selectedRecipients = this.availableAddresses
          } else {
            this.selectedRecipients = []
          }

          this.checkEmails(this.selectedRecipients)

          // mobile
          if (this.currentInventory.purchasedFromContactMobile && this.currentInventory.purchasedFromContactMobile !== '' && this.currentInventory.purchasedFromContactMobile !== null && this.currentInventory.purchasedFromContactMobile.length === 16) {
            this.availableMobileNumbers = [{
              mobile: this.currentInventory.purchasedFromContactMobile,
              fullName: this.currentInventory.purchasedFromContactName ? this.currentInventory.purchasedFromContactName + '(' + this.currentInventory.purchasedFromContactMobile + ')' : this.currentInventory.purchasedFromContactMobile
            }]
            this.selectedRecipientsMobile = this.availableMobileNumbers
          } else {
            this.selectedRecipientsMobile = []
          }
        }

        if (this.requiresTitleSelection) {
          eventBus.$emit('onUpdateTitleStatus', {})
        }
      }

      if (state === false) {
        this.clearAllRecipients()
        eventBus.$emit('sellers-agreement-cancelled', {})
        this.activeInventory.actionCancelled = true
        this.$emit('sellers-agreement-complete', this.activeInventory)
      }
    },
    checkEmails: function (recipients) {
      if (recipients.length > 0) {
        let emails = recipients.map(function (x) {
          return x.email
        })

        let blacklistEmailCheckModel = {
          subscriberId: this.getCurrentSubscriber.id,
          emailAddresses: emails,
          errorMessage: ''
        }

        inventoryService.getVerifiedEmailList(blacklistEmailCheckModel).then(response => {
          blacklistEmailCheckModel = response.blacklistEmailCheck

          let approvedEmails = blacklistEmailCheckModel.emailAddresses.map(function (x) {
            return {
              email: x,
              fullName: x
            }
          })
          this.selectedRecipients = approvedEmails
          if (this.selectedRecipients.length === 0) {
            this.selectedRecipients = []
          }

          if (blacklistEmailCheckModel.errorMessage !== '' || blacklistEmailCheckModel.errorMessage !== null) {
            this.emailMatchErrorMessage = blacklistEmailCheckModel.errorMessage
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
        })
      }
    },
    onVerifySalesOrder: function (inventory) {
      this.$validator.validateAll('recipient-form').then((result) => {
        if (result) {
          this.billOfSaleVerificationActive = true
        }
      })
    },
    onEmailSalesOrder: function (inventory, isSignNow) {
      if (this.isUpload) {
        this.onUploadSalesOrder(inventory)
      } else {
        var emailAddresses = this.selectedRecipients.map(e => e.email).join(', ')
        var mobileNumbers = this.selectedRecipientsMobile.map(e => e.mobile).join(',')
        var orderEmailModel = {
          subscriberId: inventory.subscriberId,
          dealerId: inventory.dealerId,
          inventoryId: inventory.id,
          type: 'Sale',
          destinationAddresses: emailAddresses,
          destinationMobileNumbers: mobileNumbers,
          overridingSubject: this.overridingSubject,
          optionalMessage: this.optionalMessage,
          saleDate: this.saleDate,
          signInPerson: isSignNow,
          inventoryApprovalModel: {
            approvalRequired: !this.billOfSaleCarFaxIsGood || !this.billOfSaleAppraisalIsGood || !this.billOfSaleAutoCheckIsGood,
            badCarFax: !this.billOfSaleCarFaxIsGood,
            badPricing: !this.billOfSaleAppraisalIsGood,
            badAutoCheck: !this.billOfSaleAutoCheckIsGood,
            livePurchasePrice: this.latestAppraisal ? this.latestAppraisal.livePurchasePrice : null,
            marketPurchasePrice: this.latestAppraisal ? this.latestAppraisal.marketPurchasePrice : null,
            liveAskingPrice: this.latestAppraisal ? this.latestAppraisal.liveAskingPrice : null,
            marketAskingPrice: this.latestAppraisal ? this.latestAppraisal.marketAskingPrice : null,
            badPurchasePricing: this.latestAppraisal ? this.latestAppraisal.badPurchasePricing : null,
            badAskingPricing: this.latestAppraisal ? this.latestAppraisal.badAskingPricing : null,
            purchaseVariance: this.latestAppraisal ? this.latestAppraisal.purchaseVariance : null,
            askingVariance: this.latestAppraisal ? this.latestAppraisal.askingVariance : null
          }
        }

        if (isSignNow) {
          this.isSignatureActive = true
        } else {
          inventoryService.addSellersAgreementRequest(orderEmailModel).then(response => {
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              this.clearAllRecipients()
              this.isBillOfSale = false
              this.billOfSaleVerificationActive = false
              this.billOfSaleCarFaxCheckComplete = false
              this.billOfSaleCarFaxIsGood = false
              this.billOfSaleCarFaxFailed = false
              this.billOfSaleAutoCheckCheckComplete = !this.autoCheckEnabled
              this.billOfSaleAutoCheckIsGood = false
              this.billOfSaleAutoCheckFailed = false
              this.billOfSaleAcceptConditions = false
              this.billOfSaleAppraisalCheckComplete = false
              this.billOfSaleAppraisalIsGood = false
              this.billOfSaleAppraisalFailed = false
              this.blackBookCheckComplete = !this.blackBookEnabled
              this.updateAppState(response.inventory)
            }
          }).catch((error) => {
            throw error
          })
        }
      }
    },
    updateUploadState: function () {
      if (this.isUpload === false) {
        this.isUpload = true
      } else {
        this.isUpload = false
      }
    },
    onUploadSalesOrder: function (inventory) {
      if (this.salesOrder.length > 0) {
        var signedDocumentModel = this.salesOrder.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            docName: x.uniqueId,
            FileUri: ''
          }
        })
        var inventoryId = inventory.id
        var signedAgreementUploadModel = {
          inventoryId: inventoryId,
          fileName: signedDocumentModel[0].docName,
          inventoryApprovalModel: {
            approvalRequired: !this.billOfSaleCarFaxIsGood || !this.billOfSaleAppraisalIsGood || !this.billOfSaleAutoCheckIsGood,
            badCarFax: !this.billOfSaleCarFaxIsGood,
            badAutoCheck: !this.billOfSaleAutoCheckIsGood,
            badPricing: !this.billOfSaleAppraisalIsGood,
            livePurchasePrice: this.latestAppraisal ? this.latestAppraisal.livePurchasePrice : null,
            marketPurchasePrice: this.latestAppraisal ? this.latestAppraisal.marketPurchasePrice : null,
            liveAskingPrice: this.latestAppraisal ? this.latestAppraisal.liveAskingPrice : null,
            marketAskingPrice: this.latestAppraisal ? this.latestAppraisal.marketAskingPrice : null,
            badPurchasePricing: this.latestAppraisal ? this.latestAppraisal.badPurchasePricing : null,
            badAskingPricing: this.latestAppraisal ? this.latestAppraisal.badAskingPricing : null,
            purchaseVariance: this.latestAppraisal ? this.latestAppraisal.purchaseVariance : null,
            askingVariance: this.latestAppraisal ? this.latestAppraisal.askingVariance : null
          }
        }

        inventoryService.uploadSignedSellersAgreement(signedAgreementUploadModel).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.clearAllRecipients()
            this.isBillOfSale = false
            this.billOfSaleVerificationActive = false
            this.billOfSaleCarFaxCheckComplete = false
            this.billOfSaleCarFaxIsGood = false
            this.billOfSaleCarFaxFailed = false
            this.billOfSaleAutoCheckCheckComplete = !this.autoCheckEnabled
            this.billOfSaleAutoCheckIsGood = false
            this.billOfSaleAutoCheckFailed = false
            this.billOfSaleAcceptConditions = false
            this.billOfSaleAppraisalCheckComplete = false
            this.billOfSaleAppraisalIsGood = false
            this.billOfSaleAppraisalFailed = false
            this.blackBookCheckComplete = !this.blackBookEnabled
            this.updateAppState(response.inventory)
          }
        }).catch((error) => {
          throw error
        })
      }
    },
    updateAppState: function (updatedInventory) {
      eventBus.$emit('inventory-changed', [updatedInventory])
      eventBus.$emit('sellers-agreement-complete', updatedInventory)
      this.$emit('sellers-agreement-complete', updatedInventory)
      let allSignedSellersAgreements = updatedInventory.attachments.filter((x) => x.classType === 'SellersAgreement')
      if (allSignedSellersAgreements.length > 0) {
        let newAttachment = allSignedSellersAgreements[allSignedSellersAgreements.length - 1]
        Vue.set(this.activeInventory, 'attachments', [...this.activeInventory.attachments, newAttachment])
        this.activeInventory.sellersAgreementStatus = 'Complete'
        this.activeInventory.sellersAgreementStatusId = 10
      }
    },
    onTitleCaptureComplete: function (inventory) {
      if (inventory.titleStatus !== 'Pending') {
        this.activeInventory.titleStatus = inventory.titleStatus
      } else {
        this.updateSalesOrderModalState(false)
      }
    },
    onTitleCaptureSkipped: function () {
      this.allowTitleCaptureSkip = true
      this.updateSalesOrderModalState(true)
    },
    initialize: function () {
      this.billOfSaleVerificationActive = false
      this.billOfSaleCarFaxCheckComplete = false
      this.billOfSaleCarFaxIsGood = false
      this.billOfSaleCarFaxFailed = false
      this.billOfSaleAutoCheckCheckComplete = !this.autoCheckEnabled
      this.billOfSaleAutoCheckIsGood = false
      this.billOfSaleAutoCheckFailed = false
      this.billOfSaleAcceptConditions = false
      this.billOfSaleAppraisalCheckComplete = false
      this.latestAppraisal = null
      this.billOfSaleAppraisalIsGood = false
      this.billOfSaleAppraisalFailed = false
      this.isBillOfSale = false
      this.selectedRecipients = []
      this.isUpload = false
      this.isSaving = false
      this.selectedWholeSaler = null
      this.selectedCustomer = null
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isLoading = false
      this.allowTitleCaptureSkip = false
      this.emailMatchErrorMessage = ''
      this.mileageEstimate = false
      this.trueMileageUnknown = false
      this.mileageExempt = false
      this.blackBookCheckComplete = !this.blackBookEnabled

      this.activeInventory = this.currentInventory
      this.selectedTypeStatus = this.definedTypes.inventoryTypes.find((x) => (x.name === this.activeInventory.type || x.altName === this.activeInventory.type))
      this.selectedDealer = {id: this.activeInventory.dealerId, name: this.activeInventory.dealerName}
      this.selectedSource = this.activeInventory.purchasedFromType === null ? null : this.definedTypes.allowedSourceTypes.options.find((x) => (x.name === this.activeInventory.purchasedFromType || x.altName === this.activeInventory.purchasedFromType))
      this.updatedUsage = this.activeInventory.usage
      this.updatedPurchaseCost = this.activeInventory.purchaseCost
      this.mileageEstimate = this.activeInventory.mileageEstimate
      this.trueMileageUnknown = this.activeInventory.trueMileageUnknown
      this.mileageExempt = this.activeInventory.mileageExempt
      this.showMileageCapture = this.requiresMileageUpdate
      if (this.activeInventory.tradeSourceInventoryId && this.activeInventory.tradeSourceInventoryId > 0) {
        this.inventorySoldItem = {
          id: this.activeInventory.tradeSourceInventoryId,
          name: this.activeInventory.tradeSourceName,
          listName: this.activeInventory.tradeSourceName
        }
      }

      if (this.activeInventory.tradeSourceDealId && this.activeInventory.tradeSourceDealId > 0) {
        this.selectedTradeDeal = {
          dealId: this.activeInventory.tradeSourceDealId,
          purchaserId: this.activeInventory.tradeSourcePurchaserId,
          purchaserFullName: this.activeInventory.tradeSourcePurchaserFullName,
          dealStatus: this.activeInventory.tradeSourceDealStatus,
          tradeAllowance: this.activeInventory.tradeAllowance
        }
        this.tradeAllowance = this.activeInventory.tradeAllowance
      }
      this.saleDate = new Date()
    },
    onAutoCheckForBillOfSale: function (autoCheckRating) {
      this.billOfSaleAutoCheckCheckComplete = true
      this.billOfSaleAutoCheckIsGood = autoCheckRating && !((autoCheckRating.accidentCount && autoCheckRating.accidentCount > 0) || (autoCheckRating.recallCount && autoCheckRating.recallCount > 0))
      if (this.currentInventory && this.currentInventory.autoCheckRating && this.currentInventory.autoCheckRating.hashValue && autoCheckRating && autoCheckRating.hashValue) {
        this.autoCheckHashMatch = (this.currentInventory.autoCheckRating.hashValue === autoCheckRating.hashValue)
      } else {
        this.autoCheckHashMatch = false
      }

      this.billOfSaleAutoCheckFailed = !autoCheckRating
      if (this.autoCheckHashMatch === true && this.hasAutoCheckRatingApproval === true) {
        this.billOfSaleAutoCheckIsGood = true
        this.autoCheckApproved = true
        this.billOfSaleAppraisalFailed = false
      } else if (this.currentInventory && (!this.currentInventory.autoCheckRating || !autoCheckRating) && this.currentInventory.hasRatingApproval === true) {
        this.billOfSaleAutoCheckIsGood = true
        this.autoCheckApproved = true
        this.billOfSaleAppraisalFailed = false
      }
    },
    onShowAutoCheckReport: function () {
      this.isBillOfSale = false
      this.showReport = true
    },
    onHideAutoCheckReport: function () {
      this.showReport = false
      this.isBillOfSale = true
    },
    onShowCarFaxReport: function () {
      this.isBillOfSale = false
      this.showCarFaxReport = true
    },
    onHideCarFaxReport: function () {
      this.showCarFaxReport = false
      this.isBillOfSale = true
    },
    onShowReminderModal: function () {
      this.isBillOfSale = false
      this.showConfirmReminder = true
    },
    onHideReminderModal: function () {
      this.showConfirmReminder = false
      this.isBillOfSale = true
      this.signatureRequest = null
    },
    sendReminder: function () {
      if (!this.sendingReminder) {
        this.sendingReminder = true
        documentSigningRequestService.sendReminder(this.signatureRequest).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.signatureRequest.reminderSent = true
            this.successToast('Signature request reminder has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideReminderModal()
          this.sendingReminder = false
        })
      }
    },
    onShowCancellationRequestModal: function () {
      this.isBillOfSale = false
      this.showCancelRequest = true
    },
    onHideCancellationRequestModal: function () {
      this.showCancelRequest = false
      this.isBillOfSale = true
      this.cancelSignatureRequest = null
    },
    sendCancellationRequest: function () {
      if (!this.sendingCancellationRequest) {
        this.sendingCancellationRequest = true
        documentSigningRequestService.cancelSignatureRequest(this.cancelSignatureRequest.signatureRequestId).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.cancelSignatureRequest.cancellationSent = true
            this.successToast('Signature cancellation request has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideCancellationRequestModal()
          this.sendingCancellationRequest = false
        })
      }
    },
    onSign () {
      this.loadingSign = true
      var png = this.$refs.signature.save()

      var generatePdfModal = {
        signatureString: png,
        inventoryId: this.activeInventory.id,
        userId: this.currentUser.id,
        saleDate: this.saleDate
      }

      inventoryService.generatePdfWithSignature(generatePdfModal).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
          this.loadingSign = false
        } else {
          var fileUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(response.billOfSalePdfUrl)
          this.onSignatureBillOfSalePreview(response.inventory, fileUrl)
          this.isSignatureActive = false
          this.loadingSign = false
          this.updateAppState(response.inventory)
        }
      }).catch((error) => {
        this.loadingSign = false
        this.serverErrorMessage = error.message
      })
    },
    onClearSign () {
      this.$refs.signature.clear()
    },
    onUndoSign () {
      this.$refs.signature.undo()
    },
    onCancelSignNow () {
      this.isSignatureActive = false
      this.loadingSign = false
      this.onPreviewClosed()
    },
    closeSignaturePdf () {
      this.clearAllRecipients()
      this.isBillOfSale = false
      this.billOfSaleVerificationActive = false
      this.billOfSaleCarFaxCheckComplete = false
      this.billOfSaleCarFaxIsGood = false
      this.billOfSaleCarFaxFailed = false
      this.billOfSaleAutoCheckCheckComplete = !this.autoCheckEnabled
      this.billOfSaleAutoCheckIsGood = false
      this.billOfSaleAutoCheckFailed = false
      this.billOfSaleAcceptConditions = false
      this.billOfSaleAppraisalCheckComplete = false
      this.billOfSaleAppraisalIsGood = false
      this.billOfSaleAppraisalFailed = false
      this.isSignatureActive = false
      this.blackBookCheckComplete = !this.blackBookEnabled
    },
    fromDataURL (url) {
      this.$refs.signature.fromDataURL('data:image/png;base64,iVBORw0K...')
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    onAutoCheckDisabled: function () {
      this.billOfSaleAutoCheckIsGood = true
      this.billOfSaleAutoCheckCheckComplete = true
      this.billOfSaleAutoCheckFailed = false
    },
    onBlackBookInformationRetrieved: function (blackBook) {
      this.blackBookCheckComplete = true
      this.blackBookInformation = blackBook
      this.blackBookCheckFailed = !blackBook
      if (blackBook && blackBook.vinNo && blackBook.vinNo.length > 0) {
        this.blackBookCheckIsGood = true
      } else {
        this.blackBookCheckIsGood = false
      }
    },
    onBlackBookDisabled: function () {
      this.blackBookCheckComplete = true
      this.blackBookCheckIsGood = true
      this.blackBookCheckFailed = false
    },
    onPreview: function (inventory = null, documentUrl = '') {
      let docUrl = ''
      let documentFileName = ''
      if (documentUrl) {
        documentFileName = documentUrl.split('/').pop()
        docUrl = documentUrl
      } else if (inventory) {
        docUrl = process.env.VUE_APP_ROOT_DOC_SITE + 'docs/sale/' + inventory.id + '/' + this.currentUser.id + '/' + true + '/' + this.saleDate.toDateString()
        documentFileName = 'sale_order_pack_' + inventory.id + '.pdf'
      }
      let encodedDocumentUrl = encodeURI(docUrl)
      this.pdfModalFile = {
        name: 'Bill of Sale',
        src: encodedDocumentUrl,
        fileName: documentFileName,
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
    },
    onPreviewClosed: function () {
      this.pdfModalFile = null
    },
    onSignatureBillOfSalePreview: function (inventory = null, documentUrl = '') {
      let docUrl = ''
      let documentFileName = ''
      if (documentUrl) {
        documentFileName = documentUrl.split('/').pop()
        docUrl = documentUrl
      } else if (inventory) {
        docUrl = process.env.VUE_APP_ROOT_DOC_SITE + 'docs/sale/' + inventory.id + '/' + this.currentUser.id + '/' + true + '/' + this.saleDate.toDateString()
        documentFileName = 'sale_order_pack_' + inventory.id + '.pdf'
      }
      let encodedDocumentUrl = encodeURI(docUrl)
      this.signatureBillOfSalePdfModalFile = {
        name: 'Bill of Sale',
        src: encodedDocumentUrl,
        fileName: documentFileName,
        key: this.$uuid.v4()
      }
    },
    onSignatureBillOfSalePreviewed: function () {
    },
    onSignatureBillOfSalePreviewClosed: function () {
      this.signatureBillOfSalePdfModalFile = null
      this.closeSignaturePdf()
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    ...mapSubscriberGetters(['getCurrentSubscriber']),
    applicablePurchaseCost: function () {
      var defaultCost = this.activeInventory.tradeAllowance || this.activeInventory.purchaseCost
      return defaultCost
    },
    activeDialog: function () {
      let result = ''
      if (this.isBillOfSale) {
        if (this.pdfModalFile && this.pdfModalFile.src) result = 'PdfViewer'
        else if (this.signatureBillOfSalePdfModalFile && this.signatureBillOfSalePdfModalFile.src) result = 'SignaturePdfViewer'
        else if (this.isMissingCoreDetails) result = 'MissingDetails'
        else if (this.mustShowTitleDialog) result = 'TitleCapture'
        else if (this.isSignatureActive) result = 'SignatureViewer'
        else result = 'FinalizeBillOfSale'
      }
      logger.logWithGroup('Active Dialog', result)
      return result
    },
    allowedSourceTypes: function () {
      let filtered = this.definedTypes.allowedSourceTypes.options.filter((x) => x.id !== 0)
      if (!this.selectedTypeStatus) {
        return filtered
      } else {
        if (this.selectedTypeStatus.id === 0) {
          return this.definedTypes.allowedSourceTypes.options
        } else {
          return filtered
        }
      }
    },
    inventoryAppraisalParams: function () {
      let trimAttribute = this.activeInventory.attributes.filter((x) => x.name === 'Trim')[0]
      let trim = trimAttribute ? trimAttribute.value : ''
      let condition = this.activeInventory.conditionType
      return {
        id: this.activeInventory.id,
        vinNo: this.activeInventory.vinNo,
        mileage: this.activeInventory.usage,
        trim: trim,
        condition: condition,
        msrp: this.activeInventory.msrp,
        purchaseCost: this.applicablePurchaseCost,
        askingPrice: this.activeInventory.askingPrice
      }
    },
    mostRecentAppraisal: function () {
      if (this.activeInventory.appraisals.length > 0) {
        return this.activeInventory.appraisals[0]
      }
      return null
    },
    billOfSaleFinalSendDisallowed: function () {
      if (this.billOfSaleCarFaxIsGood && this.billOfSaleAppraisalIsGood && this.billOfSaleAutoCheckIsGood) {
        return false
      }
      return !this.billOfSaleAcceptConditions
    },
    hasSalesOrderAccess: function () {
      let allowedPermissions = ['inventory.issue.sales-order']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    isValid: function () {
      if (this.activeInventory) {
        if (this.isValidType) {
          if (this.activeInventory.type !== 'Consigned') {
            return true
          } else {
            if ([3, 10, 11].includes(this.activeInventory.consignmentAgreementStatusId)) {
              return true
            }
          }
        }
      }
      return false
    },
    isValidType: function () {
      return this.activeInventory.type === 'New' || this.activeInventory.type === 'Purchased' || this.activeInventory.type === 'TradeIn' || this.activeInventory.type === 'Consigned'
    },
    isReadyForAgreement: function () {
      if (!this.activeInventory) return false

      if (this.activeInventory.type === 'TradeIn') {
        if (this.activeInventory.tradeSourceDealId && this.activeInventory.tradeSourceDealId > 0) {
          return true
        }
        return false
      }

      return true
    },
    isValidForSellersAgreementSigning: function () {
      return this.activeInventory && this.isValidType && this.activeInventory.purchasedFromType !== 'Factory'
    },
    requiresSellerEmail: function () {
      return this.activeInventory.purchasedFromContactEmail === null || this.activeInventory.purchasedFromContactEmail === ''
    },
    requiresSellerInfo: function () {
      return !this.activeInventory.purchasedFromName
    },
    requiresPurchaseCost: function () {
      return !this.activeInventory.purchaseCost || this.activeInventory.purchaseCost <= 0
    },
    requiresMileageUpdate: function () {
      return !this.activeInventory.usage || this.activeInventory.usage <= 0 || (this.activeInventory.mileageEstimate === true && !this.activeInventory.trueMileageUnknown && !this.activeInventory.mileageExempt)
    },
    requiresDealLinking: function () {
      return this.activeInventory.type === 'TradeIn' && (!this.activeInventory.tradeSourceDealId || this.activeInventory.tradeSourceDealId === 0) && !this.isPreEntry
    },
    isMissingCoreDetails: function () {
      let result = this.requiresMileageUpdate || this.requiresPurchaseCost || this.requiresSellerInfo || this.requiresSellerEmail || this.requiresDealLinking
      return result
    },
    invalidForSellersAgreementReasons: function () {
      var result = 'Action disabled. Please ensure the following details are captured.\n'
      if (this.activeInventory) {
        if (this.activeInventory.purchasedFromType === 'Factory' || !(this.isValidType)) {
          result = 'Purchasers agreement is not applicable for this item.'
        } else {
          if (this.activeInventory.mileageEstimate === true) {
            result += 'Actual vehicle mileage is required. Mileage is currently estimated.\n'
          }
          if (this.activeInventory.purchasedFromName === '') {
            result += 'Seller details not set.\n'
          }
          if (!this.activeInventory.purchaseCost || this.activeInventory.purchaseCost <= 0) {
            result += 'Purchase cost not set.\n'
          }
          if (!this.activeInventory.usage || this.activeInventory.usage <= 0) {
            result += 'Mileage not set.\n'
          }
          if (!this.isReadyForAgreement) {
            result += 'Trade is not connected to a source deal which is required to process sellers agreement documents.\n'
          }
        }
      } else {
        result += 'Inventory selected is invalid.\n'
      }
      return result
    },
    lastSignedSellersAgreement: function () {
      if (this.activeInventory && (this.activeInventory.sellersAgreementStatus === 'Complete' || this.activeInventory.sellersAgreementStatus === 'Signed')) {
        var allSignedSellersAgreements = this.activeInventory.attachments.filter((x) => x.classType === 'SellersAgreement' && x.status === 'Active')
        if (allSignedSellersAgreements.length > 0) {
          return allSignedSellersAgreements[allSignedSellersAgreements.length - 1].fileLocation
        }
      }
      return ''
    },
    allowPaymentWithoutTitle: function () {
      return this.selectedWholeSaler && this.selectedWholeSaler.allowPaymentWithoutTitle === true
    },
    requiresTitleSelection: function () {
      if (this.selectedWholeSaler) {
        if (this.allowPaymentWithoutTitle) {
          return false
        } else {
          return (this.activeInventory.titleStatus === '' || this.activeInventory.titleStatus === 'Pending')
        }
      } else {
        return (this.activeInventory.titleStatus === '' || this.activeInventory.titleStatus === 'Pending') && this.activeInventory.type === 'TradeIn'
      }
    },
    mustShowTitleDialog: function () {
      return this.requiresTitleSelection && !this.allowTitleCaptureSkip
    },
    mustShowEmailError: function () {
      return this.emailMatchErrorMessage !== '' && this.emailMatchErrorMessage !== ' ' && this.emailMatchErrorMessage !== null && this.emailMatchErrorMessage !== undefined
    },
    carFaxReportUrl: function () {
      let result = 'https://www.carfaxonline.com/cfm/Display_Dealer_Report.cfm?partner=VAU_0&UID=' + this.currentDealer.carFaxUserName + '&vin=' + this.currentInventory.vinNo
      return result
    },
    selectedInventoryStatusType: function () {
      return this.definedTypes.inventoryTypes.find((x) => (x.name === this.activeInventory.type || x.altName === this.activeInventory.type))
    },
    getToolTipForSellersAgreement: function () {
      let baseText = 'Purchasers Agreement Status: '
      if (this.currentInventory && !this.currentInventory.isSellersAgreementUploaded) {
        baseText += this.getDescriptionByAgreementStatus(this.currentInventory.sellersAgreementStatusId, 'Click to send out the purchasers agreement for for signing.')
      } else {
        if ((this.currentInventory.sellersAgreementUploadedBy !== '' && this.currentInventory.sellersAgreementUploadedBy !== undefined) && (this.currentInventory.sellersAgreementUploadedAt !== null && this.currentInventory.sellersAgreementUploadedAt !== undefined)) {
          baseText += this.getUploadDescriptionByAgreementStatus(this.currentInventory.sellersAgreementUploadedBy, this.currentInventory.sellersAgreementUploadedAt)
        } else {
          baseText += 'No user details or date of upload has been captured for uploaded file.'
        }
      }
      return baseText
    },
    sellersAgreementApprovals: function () {
      if (this.currentInventory && this.currentInventory.approvals) {
        let data = this.currentInventory.approvals.filter((x) => x.approvalRatingType === 'SellersAgreement')
        if (data.length > 0) {
          return data
        }
      }
      return []
    },
    hasAutoCheckRatingApproval: function () {
      if (this.sellersAgreementApprovals && this.sellersAgreementApprovals.length > 0) {
        let data = this.sellersAgreementApprovals.filter((x) => x.ratingType === 'AutoCheckRating')
        if (data.length > 0) {
          return true
        }
      }
      return false
    },
    hasCarFaxRatingApproval: function () {
      if (this.sellersAgreementApprovals && this.sellersAgreementApprovals.length > 0) {
        let data = this.sellersAgreementApprovals.filter((x) => x.ratingType === 'CarFaxRating')
        if (data.length > 0) {
          return true
        }
      }
      return false
    },
    hasAppraisalRatingApproval: function () {
      if (this.sellersAgreementApprovals && this.sellersAgreementApprovals.length > 0) {
        let data = this.sellersAgreementApprovals.filter((x) => x.ratingType === 'AppraisalRating')
        if (data.length > 0) {
          return true
        }
      }
      return false
    },
    approvalInformation: function () {
      if (this.sellersAgreementApprovals && this.sellersAgreementApprovals.length > 0) {
        let latestApproval = this.sellersAgreementApprovals[0]
        let approvedBy = latestApproval.userApprovedBy
        let approvedAt = this.$options.filters.formatDate(latestApproval.approvedAt)
        return 'The vehicle\'s condition was approved by ' + approvedBy + ' (' + approvedAt + ').'
      }
      return ''
    },
    isPreEntry: function () {
      return this.activeInventory.status === 'PreEntry'
    },
    showLinkedDealNotFound: function () {
      return this.requiresDealLinking && this.isLinkedDealNotFound
    },
    isLinkedDealNotFound: function () {
      return !this.selectedTradeDeal
    },
    autoCheckValidated: function () {
      if (this.currentDealer && this.currentDealer.autoCheckEnabledStatus) {
        return this.billOfSaleAutoCheckIsGood && this.billOfSaleAutoCheckCheckComplete
      } else {
        return true
      }
    },
    autoCheckEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.autoCheckEnabledStatus
      }
      return false
    },
    mostRecentBlackBookInformation: function () {
      if (this.blackBookInformation) {
        return this.blackBookInformation
      } else {
        if (this.currentInventory && this.currentInventory.blackBooks && this.currentInventory.blackBooks.length > 0) {
          return this.currentInventory.blackBooks[0]
        }
      }
      return null
    },
    blackBookEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.blackBookEnabledStatus
      }
      return false
    }
  },
  beforeDestroy () {
    eventBus.$off('sellers-agreement-required', this.onSellersAgreementRequired)
  },
  mounted: function () {
    logger.logDanger('Sellers agreement action mounted')

    this.initialize()

    if (this.selectedSource && this.selectedSource.id > this.definedTypes.allowedSourceTypes.Factory && this.activeInventory.purchasedFromId && this.activeInventory.purchasedFromId !== 0) {
      if (this.selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler && this.activeInventory.purchasedFromId) {
        supplierService.single(this.activeInventory.purchasedFromId).then(response => {
          let supplier = response.supplier
          this.selectedWholeSaler = supplier
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isLoading = false
        })
      } else {
        customerService.single(this.activeInventory.purchasedFromId).then(response => {
          let customer = response.customer
          this.selectedCustomer = customer
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isLoading = false
        })
      }
    }

    eventBus.$on('sellers-agreement-required', this.onSellersAgreementRequired)
    if (this.showDialog === true) {
      this.updateSalesOrderModalState(true)
    }
  },
  watch: {
    'currentInventory.sellersAgreementStatus': function (newValue, oldValue) {
      if (newValue) {
        this.initialize()
      }
    },
    selectedSource: function (newValue, oldValue) {
      if (newValue) {
        this.sourceError = ''
      }
    },
    selectedCustomer: function (newValue, oldValue) {
      if (newValue) {
        this.customerError = ''
      }
    },
    selectedWholeSaler: function (newValue, oldValue) {
      if (newValue) {
        this.wholesalerError = ''
      }
    }
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .missing-details-modal .modal-card-body {
    position:relative;
    max-height: 65vh; /* max 80% of the viewport height */
    max-width: 960px; /* max 80% of the viewport height */
    height: unset !important;
    overflow-y: auto;
  }

  .modal-sign-body {
    position:relative;
    max-height: 150px !important; /* max 80% of the viewport height */
    height: 150px !important;
    max-width: 500px;
    width: 500px;
    overflow-y: auto;
    background: white;
  }

  @media screen and (min-width: 769px), print {
    .missing-details-modal .modal-card-body {
      position:relative;
      width: 99vw !important;
      max-height: 80vh !important; /* max 80% of the viewport height */
      max-width: 960px; /* max 80% of the viewport height */
      height: unset !important;
      overflow-y: auto;
    }
  }

  @media screen and (min-width: 769px), print {
    .missing-details-modal .modal-content, .modal-card {
        position:relative;
        width: 99% !important;
        overflow: auto;
        min-width: unset !important;
        max-width: unset !important;
        width: unset !important;
    }
  }

  @media screen and (max-width: 500px) {
    .modal-sign-body {
      position:relative;
      overflow-y: auto;
      background: white;
    }
  }
  .verification-container {
    margin-bottom: 3em;
  }

  .status-container {
    margin-bottom: 3em;
  }

  .sellers-agreement-modal {
    .animation-content {
      max-width: unset !important;
    }
    .modal-card-body {
      position:relative;
      max-height: 65vh;
      max-width: 960px;
      height: 65vh;
      width: 960px;
      overflow-y: auto;
    }
  }
</style>

<template>
  <grid-pod
    v-observe-visibility="visibilityChanged"
    :sortField="sortField"
    :sortDirection="sortOrder"
    :title="title"
    groupRowClass="deal-group-row"
    v-model="boundData"
    v-on:on-refresh="onPodRefreshRequired"
    v-on:on-item-click="onItemClick"
    v-on:on-header-click="onHeaderClick"
    :isLoading="isLoading">
    <template slot="group-header" slot-scope="{data}">
      <td :colspan="data.totalColumns" class="group-header-container">
        <div class="is-group-header">
          <div style="width:60px; max-width:60px; margin-right:10px;" class="button is-smallest is-rounded is-pulled-left" :class="getGroupCountColorClassByGroup(data.groupItem.group)">{{ data.groupItem.count }} deals</div>
          <div style="width:120px; max-width:120px; margin-right:15px;" class="is-7 is-uppercase is-italic has-text-weight-bold" :class="getGroupColorClassByGroup(data.groupItem.group)">{{ data.groupItem.group }}</div>
          <div style="width:100px; max-width:100px; margin-right:15px;" class="is-7 has-text-weight-bold has-text-black">Front: {{ data.groupItem.frontGp | currency }}</div>
          <div style="width:100px; max-width:100px;" class="is-7 has-text-weight-bold has-text-black">PVR: {{ data.groupItem.pvr | currency }}</div>
        </div>
      </td>
    </template>
    <template slot="group-rows-collapsed" slot-scope="{data}">
      <td :colspan="data.totalColumns" class="group-separator">
      </td>
    </template>
    <template slot="group-footer" slot-scope="{data}">
      <td :colspan="data.totalColumns" class="group-footer-container">
        <div class="is-group-footer">
          <div style="width:60px; max-width:60px; margin-right:10px;" class="button is-smallest is-rounded is-pulled-left" :class="getGroupCountColorClassByGroup('Summary')">{{ getSummaryCount(data.groups) }} deals</div>
          <div style="width:120px; max-width:120px; margin-right:15px;" class="is-7 is-uppercase is-italic has-text-weight-bold" :class="getGroupColorClassByGroup('Summary')">Summary</div>
          <div style="width:100px; max-width:100px; margin-right:15px;" class="is-7 has-text-weight-bold has-text-black">Front: {{ getSummaryGp(data.groups) | currency }}</div>
          <div style="width:100px; max-width:100px;" class="is-7 has-text-weight-bold has-text-black">PVR: {{ getSummaryPvr(data.groups) | currency }}</div>
        </div>
      </td>
    </template>
  </grid-pod>
</template>
<script>

import _ from 'lodash'
import axios from 'axios'
import ViewDeal from '@/components/sales/deals/ViewDeal'
import dealService from '@/services/dealService'
import { createNamespacedHelpers } from 'vuex'
import GridPod from '@/components/generic/pods/GridPod'
import eventBus from '@/eventBus'

const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'DealNewMakePod',
  components: {
    'grid-pod': GridPod,
    'view-deal': ViewDeal
  },
  props: {
    forMake: {
      type: Object,
      default: null
    },
    inventoryType: {
      type: Array,
      default: () => []
    },
    vehicleTypeId: {
      type: String,
      default: '1'
    },
    title: {
      type: String,
      default: ''
    },
    searchParameters: {
      type: Object,
      default: function () {
        return {
          query: '',
          selectedStartDate: null,
          selectedEndDate: null
        }
      }
    }
  },
  data () {
    return {
      total: 0,
      isLoading: false,
      page: 0,
      sortField: 'stockNo',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      serverErrorMessage: '',
      perPage: this.$store.state.config.defaultPageSize,
      dealData: {
        columns: [
          {
            id: 'id',
            name: 'Id',
            visible: false
          },
          {
            id: 'customerName',
            name: 'Customer',
            visible: true
          },
          {
            id: 'stockNo',
            name: 'Stock No.',
            visible: true,
            maxWidth: '80px'
          },
          {
            id: 'totalGp',
            name: 'Total',
            visible: true,
            format: 'currency'
          },
          {
            id: 'soldAt',
            name: 'Sold Date',
            visible: true
          },
          {
            id: 'inventoryType',
            name: 'Type',
            visible: true
          }
        ],
        data: []
      },
      query: this.searchParameters.query,
      selectedStartDate: this.searchParameters.selectedStartDate,
      selectedEndDate: this.searchParameters.selectedEndDate
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentUser', 'currentPendingActions', 'currentNotifications']),
    ...mapDealerState(['currentDealer']),
    requestId: function () {
      return 'deal-by-make-' + this.title
    },
    boundData: function () {
      let result = {
        ...this.dealData,
        data: this.groupedData
      }
      return result
    },
    groupedData: function () {
      let result = _.groupBy(this.dealData.data, (x) => {
        return x.groupName
      })
      var groups = Object.keys(result).map(function (key) {
        let count = result[key].length
        let groupFrontGp = result[key].reduce(function (acc, val) { return acc + val.totalFrontValue }, 0)
        let groupTotal = result[key].reduce(function (acc, val) { return acc + val.totalSalesValue }, 0)
        return {
          group: key,
          index: count > 0 ? result[key][0].groupIndex : 0,
          count: count,
          frontGp: groupFrontGp,
          total: groupTotal,
          pvr: (groupFrontGp / count),
          data: result[key],
          collapsed: true
        }
      }).sort((a, b) => a.index - b.index)
      return groups
    }
  },
  methods: {
    getSummaryCount: function (groups) {
      return groups.reduce(function (acc, val) {
        return acc + ((val.group.toUpperCase() === 'CANCELLED' || val.group.toUpperCase() === 'BACKUP DEALS' || val.group.toUpperCase() === 'ORDERED' || val.group.toUpperCase() === 'WHOLESALE' || val.group.toUpperCase() === 'PENDING CREDIT' || val.group.toUpperCase() === 'PRE-DEAL' || val.group.toUpperCase() === 'ALLOCATED') ? 0 : val.count)
      }, 0)
    },
    getSummaryGp: function (groups) {
      return groups.reduce(function (acc, val) {
        return acc + ((val.group.toUpperCase() === 'CANCELLED' || val.group.toUpperCase() === 'BACKUP DEALS' || val.group.toUpperCase() === 'ORDERED' || val.group.toUpperCase() === 'WHOLESALE' || val.group.toUpperCase() === 'PENDING CREDIT' || val.group.toUpperCase() === 'PRE-DEAL' || val.group.toUpperCase() === 'ALLOCATED') ? 0.00 : val.frontGp)
      }, 0)
    },
    getSummaryPvr: function (groups) {
      let summaryCount = this.getSummaryCount(groups)
      return summaryCount === 0 ? 0 : this.getSummaryGp(groups) / summaryCount
    },
    onHeaderClick: function () {
      if (this.hasFeatureAccess('deal.view')) {
        if (this.forMake) {
          this.$router.push({name: 'ListDeals', query: { fromDashboard: true, specificMakeId: this.forMake.id, vehicleType: this.vehicleTypeId }})
        } else {
          this.$router.push({name: 'ListDeals', query: { fromDashboard: true, vehicleType: this.vehicleTypeId }})
        }
      }
    },
    onItemClick: function (item) {
      if (this.hasFeatureAccess('deal.view')) {
        this.$router.push({name: 'DealsQuickView', params: { id: item.dealId }})
      }
    },
    onPodRefreshRequired: function () {
      this.loadData()
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        if (this.currentDealer.id) {
          this.loadData()
        }
      }
    },
    loadData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        makeId: this.forMake ? this.forMake.id : 0,
        inventoryType: this.inventoryType,
        pageSize: this.perPage,
        sortBy: this.sortField,
        page: this.page,
        requestId: this.requestId,
        query: this.query,
        endDate: this.selectedEndDate,
        startDate: this.selectedStartDate
      }

      this.isLoading = true
      dealService.getDealsByItems(searchCriteria).then(response => {
        this.dealData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          this.dealData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.dealData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    onPodSearchUpdate: function (data) {
      this.query = data.searchParameters.query
      this.selectedStartDate = data.searchParameters.selectedStartDate
      this.selectedEndDate = data.searchParameters.selectedEndDate
      this.loadData()
    },
    getGroupColorClassByGroup: function (type) {
      var result = 'has-text-grey-light'
      if (type) {
        switch (type) {
          case 'Allocated':
            result = 'has-text-warning'
            break
          case 'Delivered':
            result = 'has-text-danger'
            break
          case 'Booked':
            result = 'has-text-primary'
            break
          case 'Awaiting Arrival':
            result = 'has-text-link'
            break
          case 'Pending Delivery':
            result = 'has-text-orange'
            break
          case 'Pending Credit':
            result = 'has-text-teal'
            break
          case 'PendingCredit':
            result = 'has-text-teal'
            break
          case 'Cancelled':
            result = 'has-text-black'
            break
          case 'Backup Deals':
            result = 'has-text-purple'
            break
          case 'Wholesale':
            result = 'has-text-light-pink'
            break
          case 'Ordered':
            result = 'has-text-maroon'
            break
          case 'Locating':
            result = 'has-text-brown'
            break
          case 'Summary':
            result = 'has-text-black'
            break
          case 'Pre-Deal':
            result = 'has-text-violet'
            break
        }
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getGroupCountColorClassByGroup: function (group, total) {
      var result = ''
      if (group && group !== '') {
        switch (group) {
          case 'Allocated':
            result += ' is-warning'
            break
          case 'Delivered':
            result += ' is-danger'
            break
          case 'Booked':
            result += ' is-success'
            break
          case 'Awaiting Arrival':
            result += ' is-info'
            break
          case 'Pending Delivery':
            result += ' is-orange'
            break
          case 'Pending Credit':
            result = 'is-teal'
            break
          case 'PendingCredit':
            result = 'is-teal'
            break
          case 'Backup Deals':
            result += ' is-purple'
            break
          case 'Cancelled':
            result += ' is-black'
            break
          case 'Wholesale':
            result += ' is-light-pink'
            break
          case 'Locating':
            result += ' is-brown'
            break
          case 'Ordered':
            result += ' is-maroon'
            break
          case 'Summary':
            result += ' is-grey-light'
            break
          case 'Pre-Deal':
            result += ' is-violet'
            break
          default:
            result += ' is-black'
        }
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.loadData()
      }
    }
  },
  beforeDestroy () {
    axios.cancel(this.requestId)
    eventBus.$off('pod-search-update', this.onPodSearchUpdate)
  },
  mounted: function () {
    eventBus.$on('pod-search-update', this.onPodSearchUpdate)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.group-separator {
  height:1px !important;
  border-bottom: 1px solid #ececf0 !important;
  padding: 0 !important;
}

</style>

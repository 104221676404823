<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== ''), 'has-text-danger': (currentSupplier && currentSupplier.status === 'Rejected')}">Editing Supplier: {{ displayName }}</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmitAllScoped" v-if="currentSupplier.id !== 0" @change="onFormChange">
      <div v-if="fulfillForDepositCaptureMode">
        <div v-for="(contact, index) in contacts.filter(x=>x.isPrimary === true)" :key="contact.id" >
          <contact-capture
            :index="index" @contactBuyerChanged="onNewContactBuyer" @contactSellerChanged="onNewContactSeller" v-model="contacts[index]" :isRequired="true"
            isIcon="star" @autoSelectPrimaryContact="onAutoSelectPrimaryContact" :useValidator="thisValidator" :fulfillForDepositCaptureMode="true">
          </contact-capture>
        </div>
      </div>
      <b-tabs v-if="!fulfillForDepositCaptureMode" size="is-small" v-model="tabIndex" :animated="false" type="is-toggle">
        <b-tab-item label="Definition" icon-pack="fas"  icon="fas fa-info-circle fa-sm">
          <div class="columns">
            <div class="column is-2.5">
              <button-list-selector label="Partial Fill" v-model="selectedState" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Active" v-model="selectedActiveStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Blocked" v-model="selectedBlockedStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Allow Payment without Title" v-model="selectedTitleStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Trusted" v-model="selectedTrustStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
          </div>
          <div class="columns">
            <div class="column is-2.5">
              <button-list-selector label="We buyout their leases" v-model="selectedLeaseBuyoutStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Type" v-model="selectedSupplierType" :availableValues="definedTypes.supplierTypes.options" :tabindex=0></button-list-selector>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <vue-google-autocomplete ref="compName" types="establishment" id="compName" classname="input" type="text" placeholder="e.g EAG" :value="currentSupplier.name" v-on:placechanged="getNameAddressData" v-on:change="onChangeAutoComplete" :enable-geolocation="true" autocomplete="disabled" tabindex=1></vue-google-autocomplete>
                  <input data-vv-scope="page-1" type="hidden" name="name" data-vv-as="name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-1.name') }" v-model="currentSupplier.name">
                  <span v-show="errors.has('page-1.name')" class="help is-danger"><i v-show="errors.has('page-1.name')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.name') }}</span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Legal Name</label>
                <div class="control">
                  <input name="legalName" data-vv-as="legal name" :class="{'input': true }" type="text" v-model="currentSupplier.legalName" tabindex=2>
                </div>
              </div>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-6">
              <div class="field">
                <label class="label">Office</label>
                <div class="control">
                  <input name="office" data-vv-as="office" :class="{'input': true, 'is-danger': errors.has('office') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentSupplier.office" tabindex=3>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Fax</label>
                <div class="control">
                  <input name="fax" data-vv-as="fax" :class="{'input': true, 'is-danger': errors.has('fax') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentSupplier.fax" tabindex=4>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <user-selector scope="page-1" v-model="selectedManagedBy" validateAs="managed by" label="Managed By" :required="true"></user-selector>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Supplier Number</label>
                <div class="control">
                  <input name="supplierNumber" data-vv-as="supplier number" :class="{'input': true, 'is-danger': errors.has('supplierNumber') }" type="text" v-model="currentSupplier.supplierNo">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <switch-selector data-vv-scope="page-1" type="is-info" label="Terms Conditions Accepted" v-model="currentSupplier.isTermsAccepted" :isDisabled="true"></switch-selector>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Terms Conditions Accepted At</label>
                <div class="control">
                  <el-date-picker
                    data-vv-scope="page-1"
                    @change="onFormChange()"
                    v-model="currentSupplier.termsAcceptedAt"
                    type="date"
                    format="MM-dd-yyyy"
                    placeholder="Click to select..."
                    :clearable="false"
                    :editable="false"
                    :disabled="true">
                </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6" v-if="showReview">
              <switch-selector type="is-info" label="Review Completed" v-model="reviewCompleted"></switch-selector>
            </div>
            <div class="column is-6">
              <switch-selector type="is-info" label="Send Wholesale Payment ready SMS notification" v-model="wholesaleSendPaymentReadySms" tabindex="6"></switch-selector>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Parent</label>
                <div class="control">
                  <input disabled name="parentName" data-vv-as="parent name" :class="{'input': true, 'is-danger': errors.has('parentName') }" type="text" v-model="currentSupplier.parentName">
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Contacts" icon-pack="fas" icon="fas fa-user-friends fa-sm">
          <div id="contact-section" class="box">
            <section-header title="Contacts" parentSelector="#contact-section"></section-header>
            <div v-for="(contact, index) in contacts" :key="contact.id">
              <contact-capture scope="page-2" :index="index" @on-contact-remove="removeContact(contact)" @contactBuyerChanged="onNewContactBuyer"
                @contactSellerChanged="onNewContactSeller" v-model="contacts[index]" :isRequired="isPartial === 'required'" :isIcon="isIcon" @autoSelectPrimaryContact="onAutoSelectPrimaryContact"
                :useValidator="thisValidator"></contact-capture>
              <br/>
            </div>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addContact(false)">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Bank" icon-pack="fas" icon="fas fa-info-circle fa-sm">
          <bank-details-capture scope="page-3" @formchange="onFormChange()" v-model="bankDetails" :getValidationErrors.sync="getValidationErrors" :bankDetailsRequired="isPartial === 'required'"
          :useValidator="thisValidator"></bank-details-capture>
        </b-tab-item>
        <b-tab-item label="Product" icon-pack="fas" icon="fas fa-barcode fa-sm">
          <div class="box configuration-content-block">
            <div class="columns">
              <div class="column is-12">
                <b-table :data="this.products" class="table">
                  <template slot-scope="props">
                      <b-table-column field="name" label="Name" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.name }}
                      </b-table-column>

                      <b-table-column field="description" label="Description" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.description }}
                      </b-table-column>

                      <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.type }}
                      </b-table-column>

                      <b-table-column field="cost" label="Cost" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.cost | currency }}
                      </b-table-column>

                      <b-table-column field="sellingPrice" label="Selling Price" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.sellingPrice | currency }}
                      </b-table-column>

                      <b-table-column field="" label="Actions">
                        <span class="icon" @click="editProduct(props.row, props.index)" v-if="props.row.delete !== true">
                          <i class="fal fa-pencil"></i>
                        </span>
                        <span class="icon" @click="removeProduct(props.index)" v-if="props.row.delete !== true">
                          <i class="fal fa-minus-circle"></i>
                        </span>
                        <span class="is-size-7 has-text-danger" v-if="props.row.delete">
                          Pending Delete
                        </span>
                      </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="has-text-centered">
                        <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                        <p>No products have been added.</p>
                      </div>
                    </section>
                  </template>
                  <template slot="footer" v-if="availableProducts.length > 0">
                    <th colspan="4"></th>
                    <th colspan="1"><label class="label">{{productsTotal | currency}}</label></th>
                    <th colspan="1"></th>
                  </template>
                </b-table>
              </div>
            </div>
            <product-cost-capture :value="this.newProduct" :productCosts="this.products"></product-cost-capture>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addProduct()">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Address" icon-pack="fas" icon="fas fas fa-address-card fa-sm">
          <address-capture scope="page-5" v-model="address" :required="selectedState && selectedState.id === 1" :useValidator="thisValidator"></address-capture>
        </b-tab-item>
        <b-tab-item label="Licenses" icon-pack="fas" icon="fas fa-camera fa-sm">
          <div class="columns">
            <div class="column is-6">
              <file-selector
              scope="page-6"
              v-model="dealerLicencePhotoFiles"
              label="Dealer License"
              :enabled="hasDealerLicenceAccess"
              :multiple="false"
              :required="isPartial === 'required'"
              validateAs="dealer license"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete">
              </file-selector>
            </div>
            <div class="column is-6">
              <label class="label">Dealer License Expiration<b-icon pack="fas" :icon="isPartial === 'required' ? 'star' : ''" class="fa-ss"></b-icon></label>
              <el-date-picker
                data-vv-scope="page-6"
                @change="onFormChange()"
                v-model="dealerLicenceExpiration"
                type="date"
                format="MM-dd-yyyy"
                name="dealerLicenceExpiration"
                id="dealerLicenceExpiration"
                data-vv-as="dealer license expiration"
                :class="{'is-danger': errors.has('page-6.dealerLicenceExpiration') }"
                v-validate="isPartial"
                popper-class="datepicker-container"
                placeholder="Click to select...">
              </el-date-picker>
              <span v-show="errors.has('page-6.dealerLicenceExpiration')" class="help is-danger">
                <i v-show="errors.has('page-6.dealerLicenceExpiration')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-6.dealerLicenceExpiration') }}
              </span>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <file-selector
              v-model="salesTaxCertificatePhotoFiles"
              label="Sales Tax Certificate"
              :enabled="hasTaxCertificateAccess"
              :multiple="false"
              :required="false"
              validateAs="sales tax certificate"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete">
              </file-selector>
            </div>
            <div class="column is-6">
              <label class="label">Sales Tax Certificate Expiration<b-icon pack="fas" :icon="''" class="fa-ss"></b-icon></label>
              <el-date-picker
                @change="onFormChange()"
                v-model="salesTaxCertificateExpiration"
                type="date"
                format="MM-dd-yyyy"
                name="salesTaxCertificateExpiration"
                id="salesTaxCertificateExpiration"
                data-vv-as="sales tax certificate expiration"
                :class="{'is-danger': errors.has('salesTaxCertificateExpiration') }"
                v-validate=""
                popper-class="datepicker-container"
                placeholder="Click to select...">
              </el-date-picker>
              <span v-show="errors.has('salesTaxCertificateExpiration')" class="help is-danger">
                <i v-show="errors.has('salesTaxCertificateExpiration')" class="fas fa-exclamation-triangle"></i> {{ errors.first('salesTaxCertificateExpiration') }}
              </span>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <file-selector
              v-model="w9PhotoFiles"
              label="W9"
              :enabled="hasW9Access"
              :multiple="false"
              :required="false"
              validateAs="w9"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete">
              </file-selector>
            </div>
            <div class="column is-6">
              <label class="label">W9 Expiration<b-icon pack="fas" :icon="''" class="fa-ss"></b-icon></label>
              <el-date-picker
                @change="onFormChange()"
                v-model="w9Expiration"
                type="date"
                format="MM-dd-yyyy"
                name="w9Expiration"
                id="w9Expiration"
                data-vv-as="w9 expiration"
                :class="{'is-danger': errors.has('w9Expiration') }"
                v-validate=""
                popper-class="datepicker-container"
                placeholder="Click to select...">
              </el-date-picker>
              <span v-show="errors.has('w9Expiration')" class="help is-danger">
                <i v-show="errors.has('w9Expiration')" class="fas fa-exclamation-triangle"></i> {{ errors.first('w9Expiration') }}
              </span>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Transactions" icon-pack="fas" icon="fas fa-money-check fa-sm">
          <div class="box configuration-content-block">
            <b-table class="lists" :data="currentSupplier.transactions" @click="onViewSupplierTransaction">
              <template slot-scope="props">
                <b-table-column field="createdBy" label="Created By" sortable>
                  {{ props.row.userName }}
                </b-table-column>
                <b-table-column field="referenceId" label="Reference ID" sortable>
                  {{ props.row.referenceId }}
                </b-table-column>
                <b-table-column field="description" label="Description" sortable>
                  {{ props.row.description }}
                </b-table-column>
                <b-table-column field="transactionTypeDescription" label="Transaction Type" sortable>
                  {{ props.row.transactionTypeDescription }}
                </b-table-column>
                <b-table-column field="transactionReferenceType" label="Reference Type" sortable>
                  {{ props.row.transactionReferenceType }}
                </b-table-column>
                <b-table-column field="createdAt" label="Created At" sortable>
                  {{props.row.createdAt | formatDateOnly}}
                </b-table-column>
                <b-table-column field="amount" label="Amount" sortable>
                  <span :class="getColorClassByTransactionType(props.row, props.row.transactionType)">{{props.row.amount | currency}}</span>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="has-text-centered">
                    <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                    <p>No recorded transactions for this supplier.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </b-tab-item>
      </b-tabs>
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button :disabled="isUploadingFile" class="button is-primary" :class="{'is-loading': isSaving }" type="button" @click="validateBeforeSubmitAllScoped">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>

    <b-modal :active.sync="entityTransactionActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">View Transaction</p>
        </header>
        <section class="modal-card-body">
          <view-entity-transaction v-model="currentTransaction" :modalView="true"></view-entity-transaction>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="closeViewTransaction()">Close</button>
        </footer>
      </div>
    </b-modal>

    <b-loading :is-full-page="isFullPage" :active.sync="showLoader" :canCancel="false"></b-loading>
  </section>
</template>

<script>
import supplierService from '@/services/supplierService'
import userService from '@/services/userService'
import AddressCapture from '@/components/generic/AddressCapture'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import completeCancelInitMixin from '@/mixins/generic/completeCancelInit'
import supplierMixin from '@/mixins/supplier/index'
import utilitiesMixin from '@/mixins/generic/utilities'
import FileSelector from '@/components/generic/FileSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import BankDetailsCapture from '@/components/generic/BankDetailsCapture'
import UserSelector from '@/components/generic/UserSelector'
import ContactCapture from '@/components/generic/ContactCapture'
import SectionHeader from '@/components/generic/SectionHeader'
import SwitchSelector from '@/components/generic/SwitchSelector'
import _ from 'lodash'
import ViewEntityTransaction from '@/components/generic/ViewEntityTransaction'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'EditSupplier',
  mixins: [utilitiesMixin, supplierMixin, validateBeforeSubmitMixin, completeCancelInitMixin],
  components: {
    'address-capture': AddressCapture,
    'bank-details-capture': BankDetailsCapture,
    'file-selector': FileSelector,
    'button-list-selector': ButtonListSelector,
    'contact-capture': ContactCapture,
    'section-header': SectionHeader,
    'user-selector': UserSelector,
    'switch-selector': SwitchSelector,
    'view-entity-transaction': ViewEntityTransaction
  },
  props: {
    supplierId: {
      type: Number,
      default: 0
    },
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full-screen'
    },
    fulfillForDepositCaptureMode: {
      type: Boolean,
      default: false
    },
    defaultTab: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      isFullPage: !this.fulfillForDepositCaptureMode,
      currentSupplierId: 0,
      isSaving: false,
      contacts: [],
      serverErrors: [],
      serverErrorMessage: '',
      tabIndex: 0,
      address: {
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: ''
      },
      currentSupplier: {
        id: 0,
        name: '',
        office: '',
        fax: '',
        bankId: 0,
        accountHolder: '',
        accountNo: '',
        accountType: null,
        branchNo: '',
        branchName: '',
        swiftNo: ''
      },
      bankDetails: {
        accountHolder: '',
        accountNo: '',
        accountType: null,
        branchNo: '',
        branchName: '',
        swiftNo: '',
        selectedBank: null,
        selectedAccountType: null,
        hasValidationErrors: false,
        canSave: null,
        selectedDefaultMethod: null
      },
      selectedManagedBy: null,
      getValidationErrors: false,
      dealerLicencePhotoFiles: [],
      salesTaxCertificatePhotoFiles: [],
      dealerLicenceExpiration: null,
      salesTaxCertificateExpiration: null,
      isPartial: 'required',
      isPartialEmail: 'required|email',
      selectedState: null,
      selectedActiveStatus: null,
      selectedBlockedStatus: null,
      selectedTitleStatus: null,
      selectedTrustStutus: null,
      isIcon: 'none',
      addressSelected: false,
      w9PhotoFiles: [],
      w9Expiration: null,
      viewTransaction: false,
      currentTransaction: {},
      showReview: false,
      reviewCompleted: false,
      entityTransactionActive: false,
      selectedLeaseBuyoutStatus: null,
      products: [],
      newProduct: this.createGenericProduct(),
      dealerId: 0,
      selectedSupplierType: null,
      wholesaleSendPaymentReadySms: false
    }
  },
  computed: {
    displayName: function () {
      if (this.currentSupplier && this.currentSupplier.status === 'Rejected') {
        return this.currentSupplier.name + ' (Rejected)'
      }
      return this.currentSupplier.name
    },
    showLoader: function () {
      return this.isLoading || this.isSaving
    },
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    canSave: function () {
      return this.bankDetails.canSave
    },
    humanizedIsTermsAccepted: function () {
      return this.currentSupplier.isTermsAccepted ? 'Yes' : 'No'
    },
    hasDealerLicenceAccess: function () {
      if (this.currentSupplier.dealerLicenceStatus) {
        return this.hasFeatureAccess('manage.supplier.docs', false)
      } else {
        return true
      }
    },
    hasTaxCertificateAccess: function () {
      if (this.currentSupplier.taxCertificateStatus) {
        return this.hasFeatureAccess('manage.supplier.docs', false)
      } else {
        return true
      }
    },
    hasW9Access: function () {
      if (this.currentSupplier.w9Status) {
        return this.hasFeatureAccess('manage.supplier.docs', false)
      } else {
        return true
      }
    }
  },
  methods: {
    onSave: function () {
      this.getValidationErrors = true
      if (this.fulfillForDepositCaptureMode === true) {
        this.onCanSave()
      }
    },
    getNameAddressData: function (addressData, place, id) {
      this.currentSupplier.name = place.name
      this.addressSelected = true

      let addressComponents = place.address_components
      this.address.address1 = ''
      this.address.address2 = ''
      this.address.address3 = ''
      this.address.address4 = ''
      this.address.postalCode = ''
      this.address.state = ''
      this.address.city = ''
      this.address.gpsAddress = ''
      this.address.country = ''

      for (var i = 0; i < addressComponents.length; i++) {
        var addressType = addressComponents[i].types[0]
        if (addressType === 'street_number') {
          var streetNumber = addressComponents[i]['short_name']
          this.address.address1 = streetNumber
        }

        if (addressType === 'route') {
          var streetName = addressComponents[i]['short_name']
          this.address.address1 += ' ' + streetName
        }

        if (place.vicinity) {
          var vicinity = place.vicinity
          this.address.address2 = vicinity
        }

        if (addressType === 'locality') {
          var city = addressComponents[i]['short_name']
          this.address.city = city
        }

        if (addressType === 'administrative_area_level_1') {
          var state = addressComponents[i]['long_name']
          this.address.state = state
        }

        if (addressType === 'country') {
          var country = addressComponents[i]['long_name']
          this.address.country = country
        }

        if (addressType === 'postal_code') {
          var postalCode = addressComponents[i]['short_name']
          this.address.postalCode = postalCode
        }
      }

      if (addressData.latitude && addressData.longitude) {
        this.address.gpsAddress = addressData.latitude + ',' + addressData.longitude
      }
    },
    onChangeAutoComplete: function () {
      if (this.addressSelected) {
        this.$refs.compName.update(this.currentSupplier.name)
        this.addressSelected = false
      }
      this.currentSupplier.name = this.$refs.compName._data.autocompleteText
    },
    getViewRoute: function () {
      return {name: 'ListSuppliers'}
    },
    fetchSupplier: function (id) {
      this.isLoading = true
      supplierService.single(id).then(response => {
        this.isLoading = false
        this.currentSupplier = {
          ...response.supplier
        }
        this.initInputValues()
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    fetchUser: function (id) {
      this.isLoading = true
      userService.single(id).then(response => {
        this.isLoading = false
        this.selectedManagedBy = {
          ...response.user
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    initInputValues: function () {
      if (this.currentSupplier != null) {
        this.dealerId = this.$store.state.dealer.currentDealer.id
        this.selectedState = this.definedTypes.fillTypes.options.find((x) => x.id === (this.currentSupplier.isPartial ? 0 : 1))
        this.selectedActiveStatus = this.definedTypes.fillTypes.options.find((x) => x.id === (this.currentSupplier.status === 'Active' ? 0 : 1))
        this.selectedBlockedStatus = this.definedTypes.fillTypes.options.find((x) => x.id === (this.currentSupplier.isBlocked ? 0 : 1))
        this.selectedTitleStatus = this.definedTypes.fillTypes.options.find((x) => x.id === (this.currentSupplier.allowPaymentWithoutTitle ? 0 : 1))
        this.selectedTrustStatus = this.definedTypes.fillTypes.options.find((x) => x.id === (this.currentSupplier.trusted ? 0 : 1))
        this.selectedLeaseBuyoutStatus = this.definedTypes.fillTypes.options.find((x) => x.id === (this.currentSupplier.supplierLeaseBuyout ? 0 : 1))
        this.selectedSupplierType = this.definedTypes.supplierTypes.options.find((x) => x.altName === this.currentSupplier.supplierType)

        let selectedBank = this.currentSupplier.bankId !== 0
          ? { id: this.currentSupplier.bankId !== 0 ? this.currentSupplier.bankId : null, name: this.currentSupplier.bankName }
          : null

        if (this.currentSupplier.managedBy !== 0) {
          this.fetchUser(this.currentSupplier.managedBy)
        } else {
          this.selectedManagedBy = this.currentUser
        }

        this.bankDetails = {
          accountHolder: this.currentSupplier.accountHolder,
          accountNo: this.currentSupplier.accountNo,
          accountType: this.currentSupplier.accountType,
          branchNo: this.currentSupplier.branchNo,
          branchName: this.currentSupplier.branchName,
          swiftNo: this.currentSupplier.swiftNo,
          selectedBank: selectedBank,
          selectedAccountType: this.definedTypes.bankAccountTypes.find((x) => x.name === this.currentSupplier.accountType),
          hasValidationErrors: false,
          selectedDefaultMethod: this.definedTypes.paymentMethods.find((x) => x.name === this.currentSupplier.defaultPaymentMethod),
          canSave: null
        }

        this.contacts = []

        this.currentSupplier.contacts.forEach(function (contact) {
          let sortOrder = 3
          if (contact.isPrimary) {
            sortOrder = 0
          } else if (contact.status === 'Active') {
            sortOrder = 1
          }
          var config = {
            sectionId: this.contacts.length + 100
          }
          contact.config = config
          contact.selectedReceivesBuyersOrder = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.receivesBuyersOrder ? 0 : 1))
          contact.selectedReceivesSellersOrder = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.receivesSellersOrder ? 0 : 1))
          contact.selectedReceivesPaymentAndTitle = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.receivesPaymentAndTitle ? 0 : 1))
          contact.selectedIsPrimary = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.isPrimary ? 0 : 1))
          contact.selectedActiveStatus = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.status === 'Active' ? 0 : 1))
          contact.sortOrder = sortOrder
          contact.completeFirstContact = this.fulfillForDepositCaptureMode && contact.firstName === '' && contact.lastName === ''
          this.contacts.push(contact)
        }, this)

        this.contacts = _.sortBy(this.contacts, ['sortOrder'])

        if (this.contacts.length === 0) {
          this.addContact(true)
        }

        this.address = {
          address1: this.currentSupplier.address1,
          address2: this.currentSupplier.address2,
          address3: this.currentSupplier.address3,
          address4: this.currentSupplier.address4,
          city: this.currentSupplier.city,
          country: this.currentSupplier.country,
          postalCode: this.currentSupplier.postalCode,
          state: this.currentSupplier.state,
          gpsAddress: this.currentSupplier.gpsAddress
        }

        if (this.currentSupplier.dealerLicenceFileLocation) {
          var dealerLicencePhotoFile = {
            loaded: true,
            imageData: this.currentSupplier.dealerLicenceFileLocation,
            fileName: this.currentSupplier.dealerLicenceFileName,
            uniqueId: this.currentSupplier.dealerLicenceFileName,
            fileType: this.getFileType(this.currentSupplier.dealerLicenceFileName),
            fileLocation: this.currentSupplier.dealerLicenceFileLocation,
            name: this.currentSupplier.dealerLicenceImageName
          }

          this.dealerLicencePhotoFiles.push(dealerLicencePhotoFile)
        }

        if (this.currentSupplier.salesTaxCertificateFileLocation) {
          var salesTaxCertificatePhotoFile = {
            loaded: true,
            imageData: this.currentSupplier.salesTaxCertificateFileLocation,
            fileName: this.currentSupplier.salesTaxCertificateFileName,
            uniqueId: this.currentSupplier.salesTaxCertificateFileName,
            fileType: this.getFileType(this.currentSupplier.salesTaxCertificateFileName),
            fileLocation: this.currentSupplier.salesTaxCertificateFileLocation,
            name: this.currentSupplier.salesTaxCertificateImageName
          }

          this.salesTaxCertificatePhotoFiles.push(salesTaxCertificatePhotoFile)
        }

        if (this.currentSupplier.w9FileLocation) {
          var w9PhotoFile = {
            loaded: true,
            imageData: this.currentSupplier.w9FileLocation,
            fileName: this.currentSupplier.w9FileName,
            uniqueId: this.currentSupplier.w9FileName,
            fileType: this.getFileType(this.currentSupplier.w9FileName),
            fileLocation: this.currentSupplier.w9FileLocation,
            name: this.currentSupplier.w9ImageName
          }

          this.w9PhotoFiles.push(w9PhotoFile)
        }

        this.dealerLicenceExpiration = this.currentSupplier.dealerLicenceExpiration
        this.salesTaxCertificateExpiration = this.currentSupplier.salesTaxCertificateExpiration
        this.w9Expiration = this.currentSupplier.w9Expiration
        this.showReview = this.currentSupplier.forReview
        this.wholesaleSendPaymentReadySms = this.currentSupplier.wholesaleSendPaymentReadySms

        this.populateProducts()
      }
    },
    onCanSave: function () {
      if (!this.bankDetails.hasValidationErrors) {
        let supplierModel
        if (this.fulfillForDepositCaptureMode) {
          supplierModel = {
            ...this.currentSupplier,
            contacts: []
          }
          this.saveContact(supplierModel)
          this.saveProduct(supplierModel)
        } else {
          supplierModel = {
            id: this.currentSupplier.id,
            subscriberId: this.$store.state.user.currentUser.subscriberId,
            name: this.currentSupplier.name,
            office: this.currentSupplier.office,
            fax: this.currentSupplier.fax,
            contacts: [],
            address1: this.address.address1,
            address2: this.address.address2,
            address3: this.address.address3,
            address4: this.address.address4,
            city: this.address.city,
            country: this.address.country,
            postalCode: this.address.postalCode,
            state: this.address.state,
            gpsAddress: this.address.gpsAddress,
            bankId: this.bankDetails.selectedBank ? this.bankDetails.selectedBank.id : 0,
            accountHolder: this.bankDetails.accountHolder || '',
            accountNo: this.bankDetails.accountNo || '',
            accountType: this.bankDetails.selectedAccountType ? this.bankDetails.selectedAccountType.name : 'Check',
            branchNo: this.bankDetails.branchNo || '',
            branchName: this.bankDetails.branchName || '',
            swiftNo: this.bankDetails.swiftNo || '',
            dealerLicenceExpiration: this.dealerLicenceExpiration,
            salesTaxCertificateExpiration: this.salesTaxCertificateExpiration,
            isTermsAccepted: this.currentSupplier.isTermsAccepted,
            termsAcceptedAt: this.currentSupplier.termsAcceptedAt,
            status: this.selectedActiveStatus.id === 0 ? 'Active' : 'InActive',
            isPartial: this.selectedState.id === 0,
            isBlocked: this.selectedBlockedStatus.id === 0,
            allowPaymentWithoutTitle: this.selectedTitleStatus.id === 0,
            trusted: this.selectedTrustStatus.id === 0,
            defaultPaymentMethod: this.bankDetails.selectedDefaultMethod ? this.bankDetails.selectedDefaultMethod.name : 'Check',
            managedBy: this.selectedManagedBy ? this.selectedManagedBy.id : 0,
            w9Expiration: this.w9Expiration,
            legalName: this.currentSupplier.legalName,
            supplierLeaseBuyout: this.selectedLeaseBuyoutStatus.id === 0,
            supplierType: this.selectedSupplierType ? this.selectedSupplierType.altName : 'VehicleSupplier',
            products: [],
            supplierNo: this.currentSupplier.supplierNo,
            wholesaleSendPaymentReadySms: this.wholesaleSendPaymentReadySms
          }

          if (this.showReview) {
            supplierModel.forReview = !this.reviewCompleted
          }

          this.saveContact(supplierModel)
          this.saveProduct(supplierModel)

          if (this.dealerLicencePhotoFiles.length > 0) {
            var dealerLicencePhotoFile = this.dealerLicencePhotoFiles.map((x) => {
              return {
                uploadName: x.name,
                uniqueId: x.uniqueId
              }
            })

            supplierModel.dealerLicenceUploadName = dealerLicencePhotoFile[0].uploadName
            supplierModel.dealerLicenceUniqueId = dealerLicencePhotoFile[0].uniqueId
          }

          if (this.salesTaxCertificatePhotoFiles.length > 0) {
            var salesTaxCertificatePhotoFile = this.salesTaxCertificatePhotoFiles.map((x) => {
              return {
                uploadName: x.name,
                uniqueId: x.uniqueId
              }
            })

            supplierModel.salesTaxCertificateUploadName = salesTaxCertificatePhotoFile[0].uploadName
            supplierModel.salesTaxCertificateUniqueId = salesTaxCertificatePhotoFile[0].uniqueId
          }

          if (this.w9PhotoFiles.length > 0) {
            let w9PhotoFile = this.w9PhotoFiles.map((x) => {
              return {
                uploadName: x.name,
                uniqueId: x.uniqueId
              }
            })

            supplierModel.w9UploadName = w9PhotoFile[0].uploadName
            supplierModel.w9UniqueId = w9PhotoFile[0].uniqueId
          }
        }

        this.isSaving = true
        this.serverErrorMessage = ''
        this.serverErrors = []

        supplierService.update(supplierModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.onChangesSaved()
            if (this.mode === 'full-screen') {
              this.onComplete()
            } else {
              this.$emit('on-save', response.supplier)
            }
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
        })
      }
    },
    onCancel: function () {
      if (this.mode === 'full-screen') {
        this.onComplete()
      } else {
        this.$emit('on-cancel')
      }
    },
    getColorClassByTransactionType: function (row, status) {
      var result = 'has-text-danger'
      switch (status) {
        case 0:
          result = 'has-text-success'
          break
        case 1:
          result = 'has-text-danger'
          break
        case 2:
          result = 'has-text-info'
          break
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    closeViewTransaction: function () {
      this.currentTransaction = {}
      this.entityTransactionActive = false
    },
    onViewSupplierTransaction: function (transaction) {
      this.currentTransaction = transaction
      this.entityTransactionActive = true
    },
    setDefaultTab: function () {
      if (this.defaultTab !== '') {
        switch (this.defaultTab) {
          case 'Definition' || '':
            this.tabIndex = 0
            break
          case 'Contacts':
            this.tabIndex = 1
            break
          case 'Bank':
            this.tabIndex = 2
            break
          case 'Product':
            this.tabIndex = 3
            break
          case 'Address':
            this.tabIndex = 4
            break
          case 'Licenses':
            this.tabIndex = 5
            break
          case 'Transactions':
            this.tabIndex = 6
            break
          default:
            this.tabIndex = 0
            break
        }
      } else if (this.supplierId !== 0) {
        this.tabIndex = 1
      }
    }
  },
  watch: {
    selectedState: function (newValue, oldValue) {
      if (this.selectedState && this.selectedState.id === 1) {
        if (this.selectedSupplierType && this.selectedSupplierType.id !== 1) {
          this.isPartial = 'required'
          this.isPartialEmail = 'required|email'
          this.isIcon = 'star'
        } else {
          this.isPartial = ''
          this.isPartialEmail = 'email'
          this.isIcon = 'none'
        }
      } else {
        this.isPartial = ''
        this.isPartialEmail = 'email'
        this.isIcon = 'none'
      }
    },
    selectedSupplierType: function (newValue, oldValue) {
      if (this.selectedSupplierType && this.selectedSupplierType.id !== 1) {
        if (this.selectedState && this.selectedState.id === 1) {
          this.isPartial = 'required'
          this.isPartialEmail = 'required|email'
          this.isIcon = 'star'
        } else {
          this.isPartial = ''
          this.isPartialEmail = 'required|email'
          this.isIcon = 'star'
        }
      } else {
        this.isPartial = ''
        this.isPartialEmail = 'required|email'
        this.isIcon = 'star'
      }
    },
    canSave: function (newValue, oldValue) {
      if (newValue && newValue === true) {
        this.onCanSave()
      }
      this.getValidationErrors = false
      this.bankDetails.canSave = null
    }
  },
  mounted: function () {
    this.currentSupplierId = (this.supplierId === 0) ? this.$route.params.id : this.supplierId
    this.setDefaultTab()
    this.fetchSupplier(this.currentSupplierId)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

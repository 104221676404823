<template>
  <div v-if="inventoryItem && showReturnedAction">
    <div :id="instanceId" :name="instanceId" class="quick-action" v-if="!isReturnedConfirmationActive">
      <button class="button" @click.stop.prevent="onMarkReturned()" title="Mark this inventory record as returned">
          <b-icon pack="fal" icon="hand-point-left" custom-size="fa-lg"></b-icon>
      </button>
      <span v-if="label.length > 0">{{label}}</span>
    </div>
    <portal to="global-modal-portal" v-else>
      <b-modal :active.sync="isReturnedConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" v-if="inventoryItem">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Confirm Return Of <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold" :title="inventoryItem.stockNo">{{ inventoryItem.stockNo }}</span></a></p>
          </header>
          <section class="modal-card-body">
            <div v-if="isError">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            </div>
            <div v-else>
              Are you sure you wish to mark inventory item <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold" :title="inventoryItem.stockNo">{{ inventoryItem.stockNo }}</span></a> as returned?
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmMarkReturned()">Confirm</button>
            <button class="button is-danger" @click="onCancelMarkReturned()">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import inventoryService from '../../services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'MarkReturnedAction',
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isReturnedConfirmationActive: false,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4()
    }
  },
  methods: {
    onMarkReturned: function () {
      this.isReturnedConfirmationActive = true
    },
    onCancelMarkReturned: function () {
      this.isReturnedConfirmationActive = false
    },
    onConfirmMarkReturned: function () {
      this.isSaving = true
      inventoryService.markReturned(this.inventoryItem).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          eventBus.$emit('inventory-mark-returned', {inventoryItem: this.inventoryItem})
          this.inventoryItem.returnedAt = response.inventory.returnedAt
          this.isReturnedConfirmationActive = false
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    showReturnedAction: function () {
      let allowedPermissions = ['inventory.modify', 'inventory.returned']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      var isTypeLeaseReturn = this.inventoryItem.typeId === 6
      var isReturnable = this.inventoryItem.leaseReturnIntentionId === this.definedTypes.leaseReturnIntentionStatuses.TurnItIn && this.inventoryItem.returnedAt === null
      return userHasAccess && isTypeLeaseReturn && isReturnable && this.inventoryItem.inStock
    },
    isError: function () {
      return this.serverErrorMessage.length > 0 || this.serverErrors.length > 0
    }
  },
  watch: {
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }
</style>

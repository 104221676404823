import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/tags',
    name: 'ListTags',
    component: () => import('@/components/configuration/tags/ListTags'),
    beforeEnter: requiresAuth
  },
  {
    name: 'TagsQuickView',
    path: '/configuration/tags/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/tags/ViewTag')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/tags/new',
    name: 'NewTag',
    component: () => import('@/components/configuration/tags/NewTag'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/tags/view/:id',
    name: 'ViewTag',
    component: () => import('@/components/configuration/tags/ViewTag'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/tags/edit/:id',
    name: 'EditTag',
    component: () => import('@/components/configuration/tags/EditTag'),
    beforeEnter: requiresAuth
  }
]

<template>
  <div>
    <v-chart :option="bar" :init-option="initOptions" ref="bar" theme="ovilia-green" auto-resize style="width:100%;height:80vh"/>
  </div>
</template>

<script>
export default {
  data: function () {
    let data = []

    for (let i = 0; i <= 360; i++) {
      let t = i / 180 * Math.PI
      let r = Math.sin(2 * t) * Math.cos(2 * t)
      data.push([r, i])
    }

    return {
      bar: {
        grid: {
          show: true,
          containLabel: true
        },
        legend: {},
        toolbox: {
          orient: 'vertical',
          show: true,
          feature: {
            saveAsImage: {
              title: 'Export'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        dataset: {
          // Provide data.
          dimensions: ['Type', 'Gross Profit', 'CSI', 'Bonus', 'F&I', 'Wholesaled', 'Total GP'],
          source: [
            ['New Ferrari', 234000, 13300, 223200, 60000, 30000, 67800],
            ['Used Ferrari', 134000, 10300, 183200, 40000, 20000, 37800],
            ['Used', 294000, 11300, 211100, 34000, 12000, 23800],
            ['Total', 184000, 11300, 203200, 43000, 15000, 27800]
          ]
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: {
          name: 'Type',
          show: true,
          type: 'category',
          boundaryGap: true,
          axisLabel: {
            show: true
          },
          axisTick: {
            alignWithLabel: true
          }
        },
        // Declare Y axis, which is a value axis.
        yAxis:
        [
          {
            show: true,
            type: 'value',
            scale: false,
            name: '',
            axisLabel: {
              show: true,
              formatter: this.$formatCurrency
            }
          },
          {
            show: false,
            type: 'value',
            scale: false,
            name: '',
            axisLabel: {
              show: true
            }
          },
          {
            show: false,
            type: 'value',
            scale: false,
            name: '',
            axisLabel: {
              show: true
            }
          },
          {
            show: false,
            type: 'value',
            scale: false,
            name: '',
            axisLabel: {
              show: true
            }
          },
          {
            show: false,
            type: 'value',
            scale: false,
            name: '',
            axisLabel: {
              show: true
            }
          },
          {
            show: true,
            type: 'value',
            scale: false,
            name: '',
            axisLabel: {
              show: true
            }
          }
        ],
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series:
        [
          {
            type: 'bar',
            barGap: '0%',
            label: {
              show: false
            },
            itemStyle: {
              color: '#baddd6',
              opacity: 0.7
            }
          },
          {
            type: 'bar',
            barGap: '0%',
            itemStyle: {
              color: '#ffb5ba',
              opacity: 0.7
            }
          },
          {
            type: 'bar',
            barGap: '0%',
            itemStyle: {
              color: '#61bfbe',
              opacity: 0.7
            }
          },
          {
            type: 'bar',
            barGap: '0%',
            itemStyle: {
              color: '#baddee',
              opacity: 0.7
            }
          },
          {
            type: 'bar',
            barGap: '0%',
            itemStyle: {
              color: '#ffb5ee',
              opacity: 0.7
            }
          },
          {
            type: 'bar',
            barGap: '0%',
            itemStyle: {
              color: '#61bfee',
              opacity: 0.7
            }
          }
        ]
      },
      initOptions: {
        renderer: 'canvas'
      }
    }
  },
  methods: {
    randomize: () => {
      return [0, 0, 0].map(v => {
        return Math.round(300 + Math.random() * 700) / 10
      })
    }
  }
}
</script>

<style>
.echarts {
  height: 300px;
}
</style>

<template>
  <div :class="{box:isBoxed, 'top-margin-is-1':!isBoxed}" :name="instanceName">
    <div class="columns">
      <div class="column is-12">
        <label class="label" style="margin-left:10px;">
          {{label}}
          <b-icon pack="fas" icon="star" class="fa-ss" v-if="required"></b-icon>
          <i v-if="enabled" :id="instanceName + '.paste'" class="fal fa-paste fa-lg has-text-orange" @paste="handlePaste" title="Click here to paste an image from the clipboard. Press CTRL+V to paste" style="margin-left:5px;"></i>
        </label>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div class="item-container">
          <div class="is-inline-flex iPhoto" v-if="enabled">
            <div>
              <b-upload v-model="uploadValue"
                  type="is-info"
                  :multiple="multiple"
                  :disabled="shouldBeDisabled"
                  accept="pdf/*"
                  @input="loadLocalPdfs"
                  drag-drop>
                  <div class="upload-instructions">
                    <div class="has-text-centered">
                      <p>Drop your files here or click to upload</p>
                      <i class="fal fa-upload" style="margin-top:10px;"></i>
                    </div>
                  </div>
              </b-upload>
            </div>
          </div>
          <div class="is-inline-flex iPhoto" v-for="(file, index) in availableFiles" :key="index">
            <div class="box" :title="file.name" v-if="file.loaded === true && (!file.status || file.status !== 'Deleted')">
              <figure class="image is-64xauto" style="cursor: pointer;">
                <img class="image is-64x64" src="@/assets/pdf.jpg" style="max-height:64px" @click="showPdf(file)">
              </figure>
              <div class="tags has-addons extra-top-margin">
                <span class="tag is-link" v-if="file.isUploadError">{{ uploadErrorDescription }}</span>
                <span class="tag is-link" v-if="!file.isUploadError && !file.isUploading">{{ shortened(file.name) }}</span>
                <span class="tag is-link" v-if="!file.isUploadError && file.isUploading">upload progress: {{ file.uploadPercentage }}%</span>
                <span class="tag is-delete" @click="deletePdf(availableFiles, index)" v-if="enabled"></span>
              </div>
            </div>
          </div>
          <br/>
        </div>
        <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
      </div>
    </div>
    <pdf-modal
        :pdfFile="pdfModalFile"
        :usePortal="usePdfModalPortal"
        :portalOrder="2"
        v-on:on-pdf-downloaded="onPreviewed"
        v-on:on-pdf-closed="onPreviewClosed"
        v-if="pdfModalFile && pdfModalFile.src"
        :fullScreenMode="true"
        :enableRotate="true">
    </pdf-modal>
  </div>
</template>

<script>

import axios from 'axios'
import utilitiesMixin from '@/mixins/generic/utilities'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'

export default {
  inject: {
    $validator: '$validator'
  },
  components: {
    'pdf-modal': PdfModalDisplayComponent
  },
  name: 'PdfSelector',
  mixins: [utilitiesMixin],
  props: {
    value: {
      type: Array,
      default: null
    },
    label: {
      type: String,
      default: 'Pdf'
    },
    validateAs: {
      type: String,
      default: 'Item'
    },
    required: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: true
    },
    isBoxed: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    usePdfModalPortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      uploadValue: null,
      selectedPdfs: this.value,
      instanceName: this.$uuid.v4(),
      isLoading: false,
      selectedPdf: null,
      rotateDegrees: 0,
      pdfModalFile: null
    }
  },
  computed: {
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    filesValid: function () {
      return this.selectedPdfs.length > 0
    },
    validFiles: function () {
      return this.selectedPdfs.filter((x) => x.loaded === true && (!x.status || x.status !== 'Deleted'))
    },
    shouldBeDisabled: function () {
      if (this.disabled === true) {
        return true
      }
      if (this.multiple === true) {
        return false
      } else {
        if (this.validFiles.length > 0) {
          return true
        }
      }
      return false
    },
    availableFiles: function () {
      if (!this.multiple && this.selectedPdfs.length > 1) {
        let availableFile = []
        availableFile.push(this.selectedPdfs[0])
        return availableFile
      }
      return this.selectedPdfs
    }
  },
  methods: {
    handlePaste: function (pasteEvent, callback) {
      if (pasteEvent.clipboardData === false) {
        if (typeof (callback) === 'function') {
          callback(undefined)
        }
      }
      var items = pasteEvent.clipboardData.items
      if (items === undefined) {
        if (typeof (callback) === 'function') {
          callback(undefined)
        }
      }
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('pdf') === -1) continue
        var blob = items[i].getAsFile()
        if (blob) this.loadLocalPdfs([blob])
      }
    },
    shortened (name) {
      return this.$lodash.truncate(name, { 'length': 10, 'separator': ' ' }).toLowerCase()
    },
    showPdf (file) {
      this.onPreview(file)
    },
    deletePdf (list, index) {
      var file = list[index]
      if (file.status) {
        this.$set(file, 'status', 'Deleted')
      } else {
        list.splice(index, 1)
      }
      this.$emit('input', this.selectedPdfs)
    },
    loadLocalPdfs (pdfList) {
      if (!Array.isArray(pdfList)) {
        pdfList = [pdfList]
      }
      pdfList.forEach(function (file, index) {
        let found = this.selectedPdfs.find(x => x.name === file.name && x.size === file.size)
        if (!file.imageData && !found) {
          var uniqueFileName = this.$uuid.v4() + this.getFileExtension(file.name)
          uniqueFileName = uniqueFileName.replace(/-/g, '')
          var reader = new FileReader()
          reader.onload = (e) => {
            var clone = { size: file.size, name: file.name, type: 'Pdf', fileType: file.type }
            clone.imageData = e.target.result
            clone.loaded = true
            clone.isUploaded = false
            clone.isUploading = false
            clone.uploadPercentage = 0
            clone.isUploadError = false
            clone.uploadErrorDescription = ''
            clone.uniqueId = uniqueFileName
            clone.status = 'Active'
            this.selectedPdfs.push(clone)
            this.uploadFile(file, uniqueFileName, clone)
          }
          reader.readAsDataURL(file)
        }
      }, this)
    },
    getFileExtension: function (fileName) {
      return '.' + fileName.split('.').pop()
    },
    resetUploadStatus: function (clone) {
      clone.uploadPercentage = 0
      clone.isUploading = true
      clone.isUploadError = false
      clone.uploadErrorDescription = ''
    },
    uploadFile: function (file, uniqueName, clone) {
      let that = this
      let uploadErrorDescription = 'An error occurred uploading your file ' + file.name + '. Please verify that your file is valid and try again: <br/>'
      this.resetUploadStatus(clone)
      var formData = new FormData()
      formData.append(uniqueName, file)
      this.$emit('uploadStarted')
      axios.post('/upload/receive', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          clone.uploadPercentage = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
        }
      }).then((response) => {
        clone.isUploading = false
        const item = response.data.results[0]
        if (!item.isValid) {
          clone.isUploadError = true
          clone.uploadErrorDescription = item.error
          let pdfIndex = that.selectedPdfs.findIndex((x) => x.name === file.name)
          that.deletePdf(that.selectedPdfs, pdfIndex)
          that.failedToast(uploadErrorDescription + item.error, 10000)
          that.$emit('uploadComplete', false)
        } else {
          clone.isUploaded = true
          clone.uploadErrorDescription = ''
          that.$emit('uploadComplete', true)
        }
      }).catch((error) => {
        clone.isUploadError = true
        clone.uploadErrorDescription = error.message
        let pdfIndex = that.selectedPdfs.findIndex((x) => x.name === file.name)
        that.deletePdf(that.selectedPdfs, pdfIndex)
        that.$emit('uploadComplete', false)
      })
    },
    onPreview: function (file) {
      var fileData = file.imageData
      if (fileData && fileData.startsWith('http')) {
        var extractedFileName = fileData.split('/').pop()
        var docUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(fileData)
        docUrl = encodeURI(docUrl)
        this.pdfModalFile = {
          name: extractedFileName,
          src: docUrl,
          fileName: extractedFileName,
          key: this.$uuid.v4()
        }
      } else {
        this.pdfModalFile = {
          name: file.name,
          src: fileData,
          fileName: file.name,
          key: this.$uuid.v4()
        }
      }
    },
    onPreviewed: function () {
    },
    onPreviewClosed: function () {
      this.pdfModalFile = null
    }
  },
  watch: {
    selectedPdfs: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    value: function (newValue, oldValue) {
      if (newValue === null) {
        this.selectedPdfs = []
      } else {
        this.selectedPdfs = newValue
      }
    },
    isPdfViewerActive: function (newValue, oldValue) {
      if (oldValue === true && newValue === false) {
        document.body.classList.toggle('is-noscroll', false)
      }
    }
  },
  mounted: function () {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.iPhoto {
  margin: 5px;
}

.item-container {
  display: flex;
  flex-wrap: wrap;
}

.columns {
  display:flex;
}

.column {
  padding:0 !important
}

.top-margin-is-1 {
  margin-top:12px
}

.is-128xauto {
  display: -webkit-box;
  height:128px;
  width:auto;
  align-items: center;
  justify-content: center
}

.is-64xauto {
  display: -webkit-box;
  height:64px;
  width:auto;
  align-items: center;
  justify-content: center
}

.image img {
  display: block;
  height:auto;
  width:auto;
}

.upload-instructions {
  width:118px;
  height:97px;
  margin:1em;
}

.extra-top-margin {
   margin-top:10px;
   display: flex;
   align-items: center;
   justify-content: center;
}

@media screen and (min-width: 769px), print {
  .modal-content, .modal-card {
      position:relative;
      width: 99% !important;
  }
}

</style>

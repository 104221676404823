import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/departments',
    name: 'ListDepartments',
    component: () => import('@/components/configuration/departments/ListDepartments'),
    beforeEnter: requiresAuth
  },
  {
    name: 'DepartmentsQuickView',
    path: '/configuration/departments/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/departments/ViewDepartment')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/departments/new',
    name: 'NewDepartment',
    component: () => import('@/components/configuration/departments/NewDepartment'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/departments/view/:id',
    name: 'ViewDepartment',
    component: () => import('@/components/configuration/departments/ViewDepartment'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/departments/edit/:id',
    name: 'EditDepartment',
    component: () => import('@/components/configuration/departments/EditDepartment'),
    beforeEnter: requiresAuth
  }
]

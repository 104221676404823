import requiresAuth from './route_guards'

export default [
  {
    path: '/tools/vin-decode',
    name: 'VinDecode',
    component: () => import('@/components/tools/VinDecode'),
    beforeEnter: requiresAuth
  },
  {
    path: '/tools/print-requests',
    name: 'ListPrintRequests',
    component: () => import('@/components/tools/ListPrintRequests'),
    beforeEnter: requiresAuth
  },
  {
    path: '/tools/printrequest/view/:id',
    name: 'ViewPrintRequest',
    component: () => import('@/components/tools/ViewPrintRequest'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    name: 'PrintRequestsQuickView',
    path: '/tools/printrequests/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/tools/ViewPrintRequest')}
    },
    beforeEnter: requiresAuth
  }
]

import DealerSelector from '@/components/generic/DealerSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import CustomerSelector from '@/components/generic/CustomerSelector'
import UserSelector from '@/components/generic/UserSelector'
import LeadSourceSelector from '@/components/generic/LeadSourceSelector'
import ImageSelector from '@/components/generic/ImageSelector'
import PdfSelector from '@/components/generic/PdfSelector'
import MakeSelector from '@/components/generic/MakeSelector'
import LeaseRenewalCapture from '@/components/generic/sales/LeaseRenewalCapture'
import SectionHeader from '@/components/generic/SectionHeader'
import NoteCapture from '@/components/generic/NoteCapture'
import NoteTimelineView from '@/components/generic/NoteTimelineView'
import SupplierSelector from '@/components/generic/SupplierSelector'
import SoldInventoryCapture from '@/components/generic/sales/SoldInventoryCapture'
import PodLockControl from '@/components/generic/PodLockControl'
import SupplementalCostCapture from '@/components/generic/sales/SupplementalCostCapture'
import CommissionCapture from '@/components/generic/sales/CommissionCapture'
import CancelBackupDealsControl from '@/components/generic/sales/CancelBackupDealsControl'
import SelectPrimaryBackupDealControl from '@/components/generic/sales/SelectPrimaryBackupDealControl'
import DealBuyersAgreement from '@/components/sales/deals/DealBuyersAgreementAction'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import FileSelector from '@/components/generic/FileSelector'
import eventBus from '@/eventBus'
import dealService from '@/services/dealService'
import moment from 'moment'
import TagSelector from '@/components/generic/TagSelector'
import PopFillControl from '@/components/generic/PopFillControl'
import utilitiesMixin from '@/mixins/generic/utilities'

import { createNamespacedHelpers } from 'vuex'

const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const { mapGetters } = createNamespacedHelpers('dealer')
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

var mixin = {
  components: {
    'dealer-selector': DealerSelector,
    'button-list-selector': ButtonListSelector,
    'customer-selector': CustomerSelector,
    'user-selector': UserSelector,
    'lead-source-selector': LeadSourceSelector,
    'image-selector': ImageSelector,
    'pdf-selector': PdfSelector,
    'make-selector': MakeSelector,
    'lease-renewal-capture': LeaseRenewalCapture,
    'section-header': SectionHeader,
    'note-capture': NoteCapture,
    'note-timeline-view': NoteTimelineView,
    'supplier-selector': SupplierSelector,
    'sold-inventory-capture': SoldInventoryCapture,
    'pod-lock-control': PodLockControl,
    'supplement-cost-capture': SupplementalCostCapture,
    'deal-buyers-agreement': DealBuyersAgreement,
    'commission-capture': CommissionCapture,
    'cancel-backup-deals-control': CancelBackupDealsControl,
    'select-primary-backup-deal-control': SelectPrimaryBackupDealControl,
    'error-display-component': ErrorDisplayComponent,
    'file-selector': FileSelector,
    'tag-selector': TagSelector,
    'pop-fill-control': PopFillControl
  },
  mixins: [utilitiesMixin],
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    tradeInventoryItem: {
      type: Object,
      default: null
    },
    defaultSaleType: {
      type: Object,
      default: null
    },
    defaultSupplier: {
      type: Object,
      default: null
    },
    defaultCustomer: {
      type: Object,
      default: null
    },
    defaultSalesAgent: {
      type: Object,
      default: null
    },
    preLoadTrade: {
      type: Boolean,
      default: false
    },
    isModalMode: {
      type: Boolean,
      default: false
    },
    id: {
      type: Number,
      default: 0
    },
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    let proposedPromiseDate = new Date()
    proposedPromiseDate.setHours(13, 0, 0, 0)
    proposedPromiseDate = this.addDays(proposedPromiseDate, 3)
    let soldAt = new Date()
    soldAt.setHours(0, 0, 0, 0)
    return {
      selectedDealer: null,
      selectedType: null,
      isBuyersAgreementOptionActive: false,
      isLeaseAgreementOptionActive: false,
      selectedStockType: null,
      selectedSaleType: this.defaultSaleType ? this.defaultSaleType : { id: 0, name: 'Retail', altName: 'Retail' },
      selectedCustomer: this.defaultCustomer,
      selectedSalesAgent: this.defaultSalesAgent,
      selectedFinanceProcessedBy: null,
      selectedMake: null,
      promiseDate: null,
      proposedPromiseDate: proposedPromiseDate,
      soldAt: soldAt,
      selectedLeadSource: null,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      dealPhotoFiles: [],
      dealPdfFiles: [],
      hasDealAttachments: false,
      hasTradeInventory: false,
      hasLeaseInventory: false,
      noteValueArray: [],
      noteSectionArray: [],
      currentDealId: 0,
      currentDeal: {
        id: 0,
        notes: []
      },
      selectedSupplier: this.defaultSupplier,
      vehicleTypes: this.setVehicleTypes(null),
      dealItems: [],
      dealCode: '',
      dealBookedActive: false,
      confirmedBookedSave: false,
      bookedLocked: false,
      bookedLockedNotificationHidden: false,
      isVehicleAlreadyLocatedDialogActive: false,
      locateVehicleIsFound: false,
      activeDealTypeItems: [],
      showSoldItemPlusBtn: true,
      supplementalCosts: [],
      newSupplementalCost: this.createGenericSupplementalCost(),
      commissions: [],
      newCommission: this.createGenericCommission(),
      cancelBackupDealsActive: false,
      backupDeals: [],
      selectedDealIds: [],
      cancelBackupDealsErrors: false,
      selectBackupDealActive: false,
      selectBackupDealErrors: false,
      selectedDealItemId: null,
      isLoading: false,
      modifiedDeal: null,
      selectedCoCustomer: null,
      selectedCoCustomerRelationship: null,
      fundingFile: [],
      selectedTags: [],
      selectedSalesAgentManager: null,
      allowRdrOnCpo: false,
      isGetReadyCapture: false,
      confirmedGetReadySave: false,
      soldServiceRequest: null,
      hasServiceRequest: false,
      selectedCustomerDeals: [],
      customerPossibleDuplicateDealActive: false,
      dealRedirect: false,
      selectedContractType: null,
      selectedLeaseContractTermType: null,
      leaseAmount: 0,
      residualValue: 0,
      isUploadingFile: false,
      fileUploadCount: 0,
      childComponentIsUploadingFile: false,
      cancelLocked: false,
      arrivalStatus: null,
      showInboundSelectionRequired: false,
      isCashDown: false,
      depositAmount: null,
      saleDocuments: [],
      creditCardDocuments: [],
      buyersAgreementDocuments: [],
      invoiceDocuments: [],
      otherDocuments: [],
      wireDocuments: [],
      generalDocuments: [],
      fundingLetterDocuments: [],
      squareDocuments: [],
      cashDocuments: [],
      checkDocuments: [],
      dealJacketDocuments: [],
      customerVerificationDocuments: [],
      cdkRecapDocuments: [],
      dealPresentationDocuments: [],
      bankCreditApplicationApprovalDocuments: [],
      vehicleCostDocuments: [],
      cdkWashoutDocuments: [],
      isCreditApplicationTypeChangeDialogActive: false
    }
  },
  computed: {
    ...mapGetters(['allDealers']),
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    isLeaseContractType: function () {
      return this.selectedContractType ? this.selectedContractType.id === this.definedTypes.dealContractTypes.lease : false
    },
    isRetailContractType: function () {
      return this.selectedContractType ? this.selectedContractType.id === this.definedTypes.dealContractTypes.retail : false
    },
    mustCreateDealPendingServiceRequest: function () {
      let dealer = this.$store.state.dealer.currentDealer
      return this.selectedSaleType.name === 'Retail' || (this.selectedSaleType.name === 'Wholesale' && dealer.wholesaleGetReadyRequired)
    },
    dealCancellationRequired: function () {
      return (this.modifiedDeal.type === 'Booked' || this.modifiedDeal.type === 'Delivered') && this.backupDeals && this.backupDeals.length > 0
    },
    dealPromotionRequired: function () {
      return this.modifiedDeal.type === 'Cancelled' && this.backupDeals && this.backupDeals.length > 0
    },
    buyersAgreementRequired: function () {
      if (this.modifiedDeal.items && this.modifiedDeal.items.length > 0) {
        let dealItem = this.modifiedDeal.items[0]
        return this.modifiedDeal && this.modifiedDeal.saleType === 'Wholesale' && (this.modifiedDeal.type === 'Booked' || this.modifiedDeal.type === 'Delivered' || this.modifiedDeal.type === 'Pending') && (this.modifiedDeal.buyersOrderStatus === 'Pending' ||
        this.modifiedDeal.buyersOrderStatus === 'Declined' ||
        this.modifiedDeal.buyersOrderStatus === 'Cancelled' ||
        this.modifiedDeal.buyersOrderStatus === 'Invalid' ||
        this.modifiedDeal.buyersOrderStatus === 'BadEmail') &&
        this.modifiedDeal.supplierName !== '' &&
        dealItem &&
        dealItem.salePrice &&
        dealItem.salePrice > 0 &&
        (dealItem.forInventory.trueMileageUnknown === true ||
        (dealItem.forInventory.mileageEstimate === false &&
        dealItem.forInventory.usage &&
        dealItem.forInventory.usage > 0))
      } else {
        return false
      }
    },
    leaseAgreementRequired: function () {
      if (this.modifiedDeal.items && this.modifiedDeal.items.length > 0) {
        let dealItem = this.modifiedDeal.items[0]
        return this.modifiedDeal && this.modifiedDeal.saleType === 'Retail' && this.modifiedDeal.contractType === 'Lease' &&
        (this.modifiedDeal.leaseOrderStatus === 'Pending' ||
        this.modifiedDeal.leaseOrderStatus === 'Declined' ||
        this.modifiedDeal.leaseOrderStatus === 'Cancelled' ||
        this.modifiedDeal.leaseOrderStatus === 'Invalid' ||
        this.modifiedDeal.leaseOrderStatus === 'BadEmail') &&
        this.modifiedDeal.supplierName !== '' &&
        dealItem &&
        dealItem.salePrice &&
        dealItem.salePrice > 0 &&
        (dealItem.forInventory.trueMileageUnknown === true ||
        (dealItem.forInventory.mileageEstimate === false &&
        dealItem.forInventory.usage &&
        dealItem.forInventory.usage > 0))
      } else {
        return false
      }
    },
    totalAcvDifference: function () {
      return this.tradeDealItems.map(x => x.acvDifference).reduce((prev, next) => prev + next, 0)
    },
    humanizedHasDealAttachments: function () {
      return this.hasDealAttachments ? 'Yes' : 'No'
    },
    humanizedHasTradeInventory: function () {
      return this.hasTradeInventory ? 'Yes' : 'No'
    },
    humanizedHasLeaseRenewal: function () {
      return this.hasLeaseInventory ? 'Yes' : 'No'
    },
    hasLeaseRenewalSections: function () {
      this.hasLeaseInventory = this.leaseRenewalDealItems.length > 0
      return this.leaseRenewalDealItems.length > 0
    },
    hasTradeSections: function () {
      this.hasTradeInventory = this.tradeDealItems.length > 0
      return this.tradeDealItems.length > 0
    },
    activeStockTypeItems: function () {
      var activeList
      // Filter by sale type. Only In Stock is applicable for a deal of type Wholesale
      if (this.isSaleTypeOfWholesale) {
        activeList = this.definedTypes.dealStockTypes.filter(x => x.altName === 'InStock' || x.altName === 'Locate')
        this.selectedStockType = {id: 0, name: 'In Stock', altName: 'InStock'}
      } else {
        activeList = this.definedTypes.dealStockTypes
      }
      return activeList
    },
    isSaleTypeOfRetail: function () {
      if (this.selectedSaleType !== null && this.selectedSaleType.name === 'Retail') {
        return true
      } else {
        return false
      }
    },
    firstSoldItemStockNo: function () {
      return this.soldDealItems.length > 0 && this.soldDealItems[0].forInventory ? this.soldDealItems[0].forInventory.stockNo : ''
    },
    firstSoldItemSaleType: function () {
      return this.soldDealItems.length > 0 && this.soldDealItems[0].vehicleType ? this.soldDealItems[0].vehicleType.name : ''
    },
    selectedBuyerName: function () {
      if (this.selectedCustomer && this.isSaleTypeOfRetail) {
        return this.selectedCustomer.fullName
      }
      if (this.selectedSupplier && this.isSaleTypeOfWholesale) {
        return this.selectedSupplier.name
      }
      return 'The Buyer'
    },
    selectedSeller: function () {
      if (this.isSaleTypeOfRetail) {
        return this.selectedCustomer
      }
      if (this.isSaleTypeOfWholesale) {
        return this.selectedSupplier
      }
      return null
    },
    selectedSellerType: function () {
      if (this.isSaleTypeOfRetail) {
        return this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Individual)
      }
      if (this.isSaleTypeOfWholesale) {
        return this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Wholesaler)
      }
      return null
    },
    isSaleTypeOfWholesale: function () {
      if (this.selectedSaleType && this.selectedSaleType.name === 'Wholesale') {
        return true
      } else {
        return false
      }
    },
    isLeadSourceRequired: function () {
      if (this.selectedType && this.isSaleTypeOfRetail && (this.selectedType.name === 'Booked' || this.selectedType.name === 'Delivered')) {
        return true
      } else {
        return false
      }
    },
    isSalesAgentRequired: function () {
      if (this.selectedType && this.selectedType.name === 'Cancelled') {
        return false
      } else {
        return true
      }
    },
    isFinanceProcessedByRequired: function () {
      if ((this.selectedSaleType && this.selectedSaleType.name === 'Retail') && (this.selectedType && this.selectedType.name === 'Booked')) {
        return true
      } else {
        return false
      }
    },
    soldDealItems: function () {
      return this.dealItems.filter(x => x.dealItemType === 'Sold')
    },
    tradeDealItems: function () {
      return this.dealItems.filter(x => x.dealItemType === 'Trade')
    },
    leaseRenewalDealItems: function () {
      return this.dealItems.filter(x => x.dealItemType === 'LeaseRenewal')
    },
    showSoldSection: function () {
      if (this.selectedStockType && (this.selectedStockType.name === 'Locate' || this.selectedStockType.name === 'Order')) {
        return false
      } else {
        return true
      }
    },
    showCostsSection: function () {
      if (this.selectedStockType && (this.selectedStockType.name === 'Locate' || this.selectedStockType.name === 'Order')) {
        return false
      } else {
        return true
      }
    },
    showCommissionsSection: function () {
      if (this.selectedStockType && (this.selectedStockType.name === 'Locate' || this.selectedStockType.name === 'Order')) {
        return false
      } else {
        return true
      }
    },
    showNotesSection: function () {
      if (this.selectedStockType && (this.selectedStockType.name === 'Locate' || this.selectedStockType.name === 'Order')) {
        return false
      } else {
        return true
      }
    },
    showTradeSection: function () {
      let validateRetail = this.isSaleTypeOfRetail &&
        (this.firstSoldInventoryItem || this.dealRedirect) &&
        this.selectedStockType && this.selectedType &&
        (this.selectedStockType.name !== 'Locate' || (this.selectedType.altName === 'PendingCredit' || this.isPreDeal))
      if (validateRetail) return true

      return this.isSaleTypeOfWholesale && this.hasTrades
    },
    showWholesaleTradeWarning: function () {
      return this.isSaleTypeOfWholesale && (this.showLeaseRenewalSection || this.showTradeSection)
    },
    hasTrades: function () {
      return this.tradeDealItems && this.tradeDealItems.length > 0
    },
    showLeaseRenewalSection: function () {
      let validateRetail = this.isSaleTypeOfRetail &&
        this.selectedStockType &&
        this.selectedStockType.name !== 'Locate' &&
        this.selectedStockType.name !== 'Order'
      if (validateRetail) return true

      return this.isSaleTypeOfWholesale && this.hasLeaseRenewals
    },
    hasLeaseRenewals: function () {
      return this.leaseRenewalDealItems && this.leaseRenewalDealItems.length > 0
    },
    activeDealTypeItemsComputed: function () {
      if (this.selectedStockType && ['Order'].indexOf(this.selectedStockType.name) >= 0) {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'Ordered')
      }

      if (this.selectedStockType && ['Locate'].indexOf(this.selectedStockType.name) >= 0) {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'Locating')
      }

      if (this.isBackupDeal) {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'AwaitingArrival' || x.altName === 'Pending')
      }

      return this.definedTypes.dealTypes.filter((x) => x.altName === 'AwaitingArrival' || x.altName === 'Pending')

      // keeping this block as client might change his mind to the changes here
      /*
      if (this.selectedStockType && ['Order'].indexOf(this.selectedStockType.name) >= 0) {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'Ordered' || x.altName === 'Cancelled')
      }

      if (this.selectedStockType && ['Locate'].indexOf(this.selectedStockType.name) >= 0) {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'Locating' || x.altName === 'Cancelled')
      }

      if (this.isBackupDeal) {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'AwaitingArrival' || x.altName === 'Pending' || x.altName === 'Cancelled')
      }

      return this.definedTypes.dealTypes.filter((x) => x.altName !== 'Ordered' && x.altName !== 'Locating')
      */
    },
    isBackupDeal: function () {
      return this.soldDealItems.filter(x => !x.isPrimary).length > 0
    },
    additionalCostsTotal: function () {
      if (!this.supplementalCosts) {
        return 0
      }
      return this.supplementalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.sellingPrice)
      }, 0)
    },
    commissionsTotal: function () {
      if (!this.commissions) {
        return 0
      }
      return this.commissions.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.commission)
      }, 0)
    },
    availableAdditionalCosts: function () {
      return this.supplementalCosts.filter(x => !x.delete)
    },
    availableCommissions: function () {
      return this.commissions.filter(x => !x.delete)
    },
    additionalCostsGP: function () {
      if (!this.availableAdditionalCosts) {
        return 0
      }
      return this.availableAdditionalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true || cur.isInGlBalance ? +0 : +(cur.sellingPrice - cur.cost))
      }, 0)
    },
    additionalCostsRecon: function () {
      if (!this.availableAdditionalCosts) {
        return 0
      }
      return this.availableAdditionalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true || !cur.isInGlBalance ? +0 : +(cur.sellingPrice - cur.cost))
      }, 0)
    },
    availableNewDealTypeItems: function () {
      return this.definedTypes.dealTypes.filter((x) => x.altName !== 'Delivered' && x.altName !== 'Booked' && x.altName !== 'Cancelled' && x.altName !== 'Allocated' && x.altName !== 'PendingCredit')
    },
    availableEditDealTypeItems: function () {
      if (this.currentDeal && this.currentDeal.type === 'Allocated') {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'Allocated')
      }

      if (this.currentDeal && this.currentDeal.type === 'PendingCredit' && this.selectedType.altName === 'PendingCredit') {
        return this.definedTypes.dealTypes.filter((x) => x.altName === 'PendingCredit')
      }

      return this.definedTypes.dealTypes.filter((x) => x.altName !== 'Allocated' && x.altName !== 'PendingCredit')
    },
    dealOverview: function () {
      let parts = [(this.selectedBuyerName === 'The Buyer' ? '' : this.selectedBuyerName)]
      parts.push((this.firstSoldInventoryItem) ? this.firstSoldInventoryItem.listName : '')
      return parts.filter((x) => x).join(', ')
    },
    firstSoldInventoryItem: function () {
      return this.soldDealItems[0] && this.soldDealItems[0].forInventory ? this.soldDealItems[0].forInventory : null
    },
    allowedToSavePromisedDate: function () {
      return this.selectedType &&
        this.selectedType.altName !== 'Booked' &&
        this.selectedType.altName !== 'Delivered' &&
        (this.currentDeal.appointmentStatus === 'NotSet' || this.currentDeal.appointmentStatus === 'Cancelled') &&
        !this.isPendingCredit &&
        !this.isPreDeal
    },
    showDeliveredDate: function () {
      return this.selectedType && (this.selectedType.altName === 'Booked' || this.selectedType.altName === 'Delivered')
    },
    showAppointmentDate: function () {
      return this.selectedType && this.selectedType.altName !== 'Booked' && this.selectedType.altName !== 'Delivered' && this.currentDeal.appointmentStatus !== 'NotSet' && this.currentDeal.appointmentStatus !== 'Cancelled'
    },
    getDefaultDateTimeForCalendar: function () {
      let localDateValue = moment.utc(String(new Date())).tz(moment.defaultZone.name)
      let localDate = new Date(localDateValue)
      return new Date(localDate.toDateString() + ' 13:00')
    },
    selectedPurchaser: function () {
      let purchaser = null
      if (this.isSaleTypeOfRetail && this.selectedCustomer) {
        purchaser = {
          PurchaserId: this.selectedCustomer.id,
          PurchaserName: this.selectedCustomer.fullName,
          PurchaserContactName: this.selectedCustomer.fullName,
          PurchaserContactEmail: this.selectedCustomer.email,
          PurchaserContactMobile: this.selectedCustomer.mobile
        }
      }

      if (this.isSaleTypeOfWholesale && this.selectedSupplier) {
        purchaser = {
          PurchaserId: this.selectedSupplier.id,
          PurchaserName: this.selectedSupplier.name,
          PurchaserContactName: this.selectedSupplier.primaryContact,
          PurchaserContactEmail: this.selectedSupplier.email,
          PurchaserContactMobile: this.selectedSupplier.office
        }
      }

      return purchaser
    },
    proposedPromiseDateFormatted: function () {
      return this.proposedPromiseDate.getFullYear() + '-' + ('0' + (this.proposedPromiseDate.getMonth() + 1)).slice(-2) + '-' + ('0' + this.proposedPromiseDate.getDate()).slice(-2) + ' ' + ('0' + this.proposedPromiseDate.getHours()).slice(-2) + ':' + ('0' + this.proposedPromiseDate.getMinutes()).slice(-2)
    },
    getTitlePreDealText () {
      return this.isPreDeal ? 'Pre-Deal' : ''
    },
    isPreDeal: function () {
      return this.currentDeal && this.currentDeal.status === 'PreDeal'
    },
    selectedCustomerLeadVehicles: function () {
      let vehicles = []
      if (this.selectedCustomer && this.selectedCustomer.customerLeads && this.selectedCustomer.customerLeads.length) {
        let leadIndex = this.selectedCustomer.customerLeads.findIndex((x) => x.status.toLowerCase() === 'active')
        if (leadIndex !== -1) {
          vehicles = this.selectedCustomer.customerLeads[leadIndex].vehicleInterests
            .filter((x) => x.inventory !== null && x.type.toLowerCase() === 'interest')
            .map((x) => { return x.inventory })
        }
      }
      return vehicles
    },
    canShowFooterToolbarMiddleRight: function () {
      return this.actionPortal === 'footer-toolbar-left'
    },
    isPendingCredit: function () {
      return this.selectedType && this.selectedType.altName === 'PendingCredit'
    },
    isCancelledDeal: function () {
      return this.selectedType && this.selectedType.name === 'Cancelled'
    }
  },
  methods: {
    cancelGetReady: function () {
      this.confirmedGetReadySave = false
      this.hasServiceRequest = false
      this.isGetReadyCapture = false
    },
    skipGetReady: function () {
      this.confirmedGetReadySave = true
      this.hasServiceRequest = true
      this.isGetReadyCapture = false
      this.onSave()
    },
    onBuyersAgreementCompleted: function (inventory) {
      this.isBuyersAgreementOptionActive = false
      if (this.dealCancellationRequired) {
        this.cancelBackupDealsActive = true
      } else {
        if (this.dealPromotionRequired) {
          this.selectBackupDealActive = true
        } else {
          this.onComplete(this.modifiedDeal)
        }
      }
    },
    onConfirmBuyersAgreement: function () {
      this.isBuyersAgreementOptionActive = false
      eventBus.$emit('buyers-agreement-required', {})
    },
    onCancelBuyersAgreement: function () {
      this.isBuyersAgreementOptionActive = false
      if (this.dealCancellationRequired) {
        this.cancelBackupDealsActive = true
      } else {
        if (this.dealPromotionRequired) {
          this.selectBackupDealActive = true
        } else {
          this.onComplete(this.modifiedDeal)
        }
      }
    },
    onLeaseAgreementCompleted: function (inventory) {
      this.isLeaseAgreementOptionActive = false
      if (this.dealCancellationRequired) {
        this.cancelBackupDealsActive = true
      } else {
        if (this.dealPromotionRequired) {
          this.selectBackupDealActive = true
        } else {
          this.onComplete(this.modifiedDeal)
        }
      }
    },
    onConfirmLeaseAgreement: function () {
      this.isLeaseAgreementOptionActive = false
      eventBus.$emit('lease-agreement-required', {})
    },
    onCancelLeaseAgreement: function () {
      this.isLeaseAgreementOptionActive = false
      if (this.dealCancellationRequired) {
        this.cancelBackupDealsActive = true
      } else {
        if (this.dealPromotionRequired) {
          this.selectBackupDealActive = true
        } else {
          this.onComplete(this.modifiedDeal)
        }
      }
    },
    populateDealAdditionalCosts: function () {
      let that = this
      if (this.selectedStockType && (this.selectedStockType.name === 'Locate' || this.selectedStockType.name === 'Order')) {
        return
      }

      var dealer = this.$store.state.dealer.currentDealer
      let dealSupplementalCosts = dealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
      if (this.firstSoldInventoryItem && this.firstSoldInventoryItem.vehicleMakeId) {
        let vehicleMakeSupplementalCosts = dealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal' && x.forVehicleMakeId === this.firstSoldInventoryItem.vehicleMakeId)
        dealSupplementalCosts = dealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
        dealSupplementalCosts.forEach(function (item, index) {
          let foundIndex = vehicleMakeSupplementalCosts.findIndex(x => x.name === item.name)
          if (foundIndex === -1) {
            if (!item.forVehicleMakeId || (item.forVehicleMakeId === that.firstSoldInventoryItem.vehicleMakeId)) {
              vehicleMakeSupplementalCosts.push(item)
            }
          }
        })
        dealSupplementalCosts = []
        dealSupplementalCosts = [...vehicleMakeSupplementalCosts]
      }
      dealSupplementalCosts = this.uniqueDefaultCosts(dealSupplementalCosts)
      var validSupplementalCosts = dealSupplementalCosts.filter((y) => {
        var isValid = true
        if (that.selectedSaleType && that.selectedSaleType.altName === 'Wholesale') {
          isValid = isValid && y.appliesTo.includes('Wholesale')
        }

        if (that.selectedSaleType && that.selectedSaleType.altName === 'Retail') {
          isValid = isValid && y.appliesTo.includes('Retail')
        }

        if (that.firstSoldItemSaleType && that.firstSoldItemSaleType !== '') {
          isValid = isValid && y.appliesTo.includes(that.firstSoldItemSaleType)
        }
        return isValid === true
      })
      this.supplementalCosts = validSupplementalCosts.map((x) => {
        return {
          ...x,
          id: 0,
          dealerDefaultCostId: x.id,
          delete: false
        }
      })
      this.total = this.supplementalCosts.length
    },
    saveDealItem: function (dealModel, type) {
      this.dealItems.filter(x => x.dealItemType === type).forEach(function (item) {
        let vehicleType = (item.vehicleType !== null && item.vehicleType !== undefined) ? item.vehicleType.name : ''
        var dealItem = {
          id: item.id,
          vehicleType: vehicleType,
          salePrice: item.salePrice ? item.salePrice : null,
          frontValue: item.frontValue ? item.frontValue : null,
          holdValue: (vehicleType === 'New') ? (item.holdValue ? item.holdValue : 0.00) : 0.00,
          mvpValue: item.mvpValue ? item.mvpValue : null,
          warValue: item.warValue ? item.warValue : null,
          finResValue: item.finResValue ? item.finResValue : null,
          tradeNo: item.tradeNo,
          itemProducts: item.itemProducts,
          forInventory: item.forInventory,
          lineComment: item.lineComment,
          status: 'Active',
          dealItemType: type,
          lineAttachments: item.lineAttachments,
          productValue: (item.itemProducts) ? item.itemProducts.filter(y => y.type === 'Product' && !y.delete).map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next, 0) : 0,
          isPrimary: item.isPrimary,
          spiff: item.spiff ? item.spiff : null,
          inventoryPickupLocation: item.inventoryPickupLocation,
          inventoryCostsUpdated: item.inventoryCostsUpdated
        }

        // To prevent posting too much data im deleting these sub objects here to not exceed the json post limit. Better more correct option is to only sent back to the server what we need
        delete dealItem.forInventory.serviceRequests
        delete dealItem.forInventory.accessoryDetailWarnings
        delete dealItem.forInventory.accessoryEvents
        delete dealItem.forInventory.allReports
        delete dealItem.forInventory.appraisalRating
        delete dealItem.forInventory.appraisals
        delete dealItem.forInventory.carFaxRating
        delete dealItem.forInventory.deals
        delete dealItem.forInventory.serviceRequests
        delete dealItem.forInventory.vinUsageHistory
        delete dealItem.forInventory.notes

        if (type === 'Trade' && item.tradeAllowanceApproved) {
          dealItem.tradeAllowanceApproved = item.tradeAllowanceApproved
        }

        dealModel.items.push(dealItem)
      }, this)
    },
    saveSupplementalCost: function (dealModel) {
      this.supplementalCosts.forEach(function (cost) {
        var supplementalCost = {
          id: cost.id,
          dealerDefaultCostId: cost.dealerDefaultCostId,
          name: cost.name,
          payeeType: cost.payeeType,
          vendorId: cost.vendorId,
          vendorName: cost.vendorName,
          attachments: cost.attachments,
          processingAction: cost.processingAction,
          description: cost.description,
          cost: cost.cost,
          sellingPrice: cost.sellingPrice,
          isInGlBalance: cost.isInGlBalance,
          delete: cost.delete === undefined ? false : cost.delete
        }

        dealModel.supplementalCosts.push(supplementalCost)
      }, this)
    },
    saveCommission: function (dealModel) {
      this.commissions.forEach(function (commission) {
        var dealCommission = {
          id: commission.id,
          salesPersonId: commission.salesPersonId,
          commission: commission.commission,
          delete: commission.delete === undefined ? false : commission.delete
        }
        dealModel.commissions.push(dealCommission)
      }, this)
    },
    addDealNote: function (dealModel) {
      this.noteValueArray.forEach(function (item) {
        var note = {
          type: item.dealNoteType,
          note: item.note,
          status: 'Active',
          isHotNote: item.isHotNote,
          pinned: item.pinned
        }
        if (note.type !== '' && note.note !== '') {
          dealModel.notes.push(note)
        }
      }, this)
    },
    onCancel: function () {
      if (this.isModalMode) {
        eventBus.$emit('deal-save-cancel')
        return
      }
      this.$router.push({name: this.lastActiveListPage !== '' ? this.lastActiveListPage : 'ListDeals'})
    },
    onComplete: function (modifiedDeal) {
      if (this.isModalMode) {
        eventBus.$emit('deal-save-complete')
        return
      }
      let nextRouteName = this.lastActiveListPage !== '' ? this.lastActiveListPage : 'ListDeals'
      if (modifiedDeal && modifiedDeal.id > 0) {
        if (nextRouteName.indexOf('Deals') < 0) {
          nextRouteName = 'ListDeals'
        }
        this.$router.push({name: nextRouteName, params: { id: modifiedDeal.id }})
      } else {
        this.$router.push({name: nextRouteName})
      }
    },
    toggleLeaseRenewal: function () {
      if (!this.hasLeaseInventory && this.leaseRenewalDealItems.length === 0) {
        this.AddDealItem({type: 'LeaseRenewal'})
      }
    },
    toggleTrade: function () {
      if (!this.hasTradeInventory && this.tradeDealItems.length === 0) {
        this.AddDealItem({type: 'Trade'})
      }
    },
    AddDealItem: function (configuration) {
      var config = {
        sectionId: this.dealItems.filter(x => x.dealItemType === configuration.type).length + this.getIndexOffset(configuration.type),
        loadInventoryOnly: (configuration) ? configuration.loadInventoryOnly : false
      }

      var dealItem = this.createGenericDealItem(configuration)
      dealItem.dealItemType = configuration.type
      dealItem.config = config
      this.dealItems.push(dealItem)
    },
    addSupplementalCost: function () {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost.isAddingCost = true
    },
    addCommission: function () {
      this.newCommission = this.createGenericCommission()
      this.newCommission.isAddingCommission = true
    },
    editSupplementalCost (supplement, index) {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost = {
        index: index,
        id: supplement.id,
        name: supplement.name,
        payeeType: supplement.payeeType,
        vendorId: supplement.vendorId,
        vendorName: supplement.vendorName,
        attachments: supplement.attachments,
        processingAction: supplement.processingAction,
        description: supplement.description,
        cost: supplement.cost,
        sellingPrice: supplement.sellingPrice,
        dealId: supplement.dealId,
        isInGlBalance: supplement.isInGlBalance,
        dealerDefaultCostId: supplement.dealerDefaultCostId,
        isAddingCost: true
      }
    },
    editCommission (commission, index) {
      this.newCommission = this.createGenericCommission()
      this.newCommission = {
        index: index,
        id: commission.id,
        salesPersonId: commission.salesPersonId,
        salesPersonName: commission.salesPersonName,
        commission: commission.commission,
        dealId: commission.dealId,
        isAddingCommission: true
      }
    },
    AddNoteValueSection: function () {
      var section = {
        id: this.noteSectionArray.length + 1,
        name: 'note-capture'
      }
      this.noteSectionArray.push(section)
    },
    RemoveNoteValueSection: function () {
      this.noteSectionArray.pop()
      this.noteValueArray.pop()
    },
    getDealer: function (id) {
      return this.allDealers.find((x) => x.id === id)
    },
    setVehicleTypes: function (name) {
      if (name !== null && name === 'Wholesale') {
        this.vehicleTypes = [{ id: 0, name: 'New', altName: 'New' }, { id: 1, name: 'Used', altName: 'Used' }]
      } else {
        this.vehicleTypes = [{ id: 0, name: 'New', altName: 'New' }, { id: 1, name: 'Used', altName: 'Used' }, { id: 2, name: 'Cpo', altName: 'Cpo' }]
      }
    },
    createGenericDealItem (configuration) {
      let result = {
        id: 0,
        vehicleType: null,
        salePrice: null,
        frontValue: null,
        holdValue: null,
        mvpValue: null,
        warValue: 0.00,
        finResValue: null,
        tradeNo: '',
        products: [],
        forInventory: (configuration && configuration.forInventory !== null) ? configuration.forInventory : null,
        lineComment: '',
        status: 'Active',
        dealItemType: configuration.type,
        lineAttachments: [],
        isPrimary: true
      }
      return result
    },
    createGenericSupplementalCost () {
      return {
        id: 0,
        name: null,
        vendorId: 0,
        payeeType: 'Supplier',
        vendorName: '',
        attachments: [],
        description: '',
        cost: null,
        sellingPrice: null,
        isAddingCost: false,
        processingAction: null,
        isInGlBalance: false,
        dealerDefaultCostId: null
      }
    },
    createGenericCommission (salesPerson) {
      return {
        id: 0,
        salesPersonId: salesPerson ? salesPerson.id : null,
        salesPersonName: salesPerson ? salesPerson.fullName : null,
        commission: salesPerson ? 0.00 : null,
        isAddingCommission: false
      }
    },
    removeDealItem (type) {
      var sectionId
      var offset = this.getIndexOffset(type)

      if (type === 'Sold') {
        sectionId = this.soldDealItems.length + offset - 1
      } else if (type === 'Trade') {
        sectionId = this.tradeDealItems.length + offset - 1
      } else if (type === 'LeaseRenewal') {
        sectionId = this.leaseRenewalDealItems.length + offset - 1
      }

      var dealItemsFiltered = this.dealItems.filter(function (el) {
        return el.config.sectionId !== sectionId
      })
      this.dealItems = dealItemsFiltered
    },
    removeSupplementalCost (index) {
      var item = this.supplementalCosts[index]
      if (item.id === 0) {
        this.$delete(this.supplementalCosts, index)
      } else {
        item.delete = true
      }
    },
    removeCommission (index) {
      // this.$delete(this.commissions, index)
      var item = this.commissions[index]
      if (item.id === 0) {
        this.$delete(this.commissions, index)
      } else {
        item.delete = true
      }
    },
    getIndexOffset (type) {
      var offset
      if (type === 'Sold') {
        offset = 100
      } else if (type === 'Trade') {
        offset = 200
      } else if (type === 'LeaseRenewal') {
        offset = 300
      }
      return offset
    },
    onConfirmSaveBookedDeal (confirmed) {
      this.dealBookedActive = false
      if (confirmed) {
        this.confirmedBookedSave = true
        this.onSave()
      }
    },
    onConfirmCancelBackupDeals (confirmed) {
      if (this.cancelBackupDealsActive) {
        if (confirmed) {
          if (this.selectedDealIds.length === 0) {
            this.cancelBackupDealsErrors = true
          } else {
            this.cancelBackupDealsErrors = false
            this.isSaving = true
            dealService.cancelBackupDeals(this.selectedDealIds).then(response => {
              if (response.errors) {
                this.serverErrors = response.errors
              } else {
                this.onComplete()
                this.isSaving = false
              }
            }).catch((error) => {
              this.serverErrorMessage = error.message
              this.isSaving = false
            })
          }
        } else {
          this.cancelBackupDealsErrors = false
          this.cancelBackupDealsActive = false
          this.onComplete()
        }
      }
    },
    onConfirmSelectBackupDeal (confirmed) {
      if (this.selectBackupDealActive) {
        if (confirmed) {
          if (!this.selectedDealItemId) {
            this.selectBackupDealErrors = true
          } else {
            this.selectBackupDealErrors = false
            this.isSaving = true
            let dealUpdatePrimaryModel = null
            this.backupDeals.forEach(deal => {
              deal.items.forEach(dealItem => {
                if (dealItem.id === this.selectedDealItemId) {
                  dealUpdatePrimaryModel = {
                    dealItemId: this.selectedDealItemId,
                    isPrimary: true,
                    inventoryId: dealItem.forInventory.id,
                    originalInventoryId: dealItem.forInventory.id,
                    originalIsPrimary: dealItem.isPrimary
                  }
                }
              })
            })
            if (dealUpdatePrimaryModel) {
              dealService.updateIsPrimary(dealUpdatePrimaryModel).then(response => {
                if (response.errors) {
                  this.serverErrors = response.errors
                } else {
                  this.onComplete()
                  this.isSaving = false
                }
              }).catch((error) => {
                this.serverErrorMessage = error.message
                this.isSaving = false
              })
            } else {
              this.onComplete()
              this.isSaving = false
            }
          }
        } else {
          this.selectBackupDealErrors = false
          this.selectBackupDealActive = false
          this.onComplete()
        }
      }
    },
    onStockTypeClick: function () {
      if (this.selectedStockType && (this.selectedStockType.name === 'Order' || this.selectedStockType.name === 'Locate')) {
        // Only show Inventory pop up or make pop if inventory item not pre-loaded from inventory screen
        if (this.inventoryItem === null) {
          if (this.selectedStockType.name === 'Order') {
            eventBus.$emit('preview-make-model')
            return
          }

          if (this.selectedStockType.name === 'Locate') {
            // Get confirmation if the user has already located the vehicle
            this.isVehicleAlreadyLocatedDialogActive = true
          }
        }

        // remove default supplement costs as this is not required at this stage.
        this.supplementalCosts = []
      }
    },
    onVehicleAlreadyLocated: function () {
      this.isVehicleAlreadyLocatedDialogActive = false
      this.locateVehicleIsFound = true
      this.selectedType = { id: 0, name: 'Awaiting Arrival', altName: 'AwaitingArrival' }
      eventBus.$emit('preview-make-model')
    },
    onVehicleNotAlreadyLocated: function () {
      this.isVehicleAlreadyLocatedDialogActive = false
      this.locateVehicleIsFound = false
      this.selectedType = { id: 6, name: 'Locating', altName: 'Locating' }
      eventBus.$emit('preview-make-model')
    },
    restoreDealAdditionalCosts: function () {
      let that = this
      let savedSupplementalCosts = this.supplementalCosts.filter((x) => x.id !== 0)
      savedSupplementalCosts.forEach((x) => {
        x.delete = true
      })
      this.supplementalCosts = savedSupplementalCosts

      let dealer = this.$store.state.dealer.currentDealer
      let dealSupplementalCosts = dealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
      if (this.firstSoldInventoryItem && this.firstSoldInventoryItem.vehicleMakeId) {
        let vehicleMakeSupplementalCosts = dealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal' && x.forVehicleMakeId === this.firstSoldInventoryItem.vehicleMakeId)
        dealSupplementalCosts = dealer.defaultSupplementalCosts.filter(x => x.status !== 'Deleted' && x.type === 'Deal')
        dealSupplementalCosts.forEach(function (item, index) {
          let foundIndex = vehicleMakeSupplementalCosts.findIndex(x => x.name === item.name)
          if (foundIndex === -1) {
            if (!item.forVehicleMakeId || (item.forVehicleMakeId === that.firstSoldInventoryItem.vehicleMakeId)) {
              vehicleMakeSupplementalCosts.push(item)
            }
          }
        })
        dealSupplementalCosts = []
        dealSupplementalCosts = [...vehicleMakeSupplementalCosts]
      }
      dealSupplementalCosts = this.uniqueDefaultCosts(dealSupplementalCosts)
      let validSupplementalCosts = dealSupplementalCosts.filter((y) => {
        var isValid = true
        if (this.selectedSaleType && this.selectedSaleType.altName === 'Wholesale') {
          isValid = isValid && y.appliesTo.includes('Wholesale')
        }

        if (this.selectedSaleType && this.selectedSaleType.altName === 'Retail') {
          isValid = isValid && y.appliesTo.includes('Retail')
        }

        if (this.firstSoldItemSaleType && this.firstSoldItemSaleType !== '') {
          isValid = isValid && y.appliesTo.includes(this.firstSoldItemSaleType)
        }
        return isValid === true
      })
      validSupplementalCosts.forEach((x) => {
        let cost = {
          ...x,
          id: 0,
          dealerDefaultCostId: x.id,
          delete: false
        }
        this.supplementalCosts.push(cost)
      })
      this.total = this.supplementalCosts.length
    },
    setCurrentAsSalesManager: function () {
      this.selectedSalesAgentManager = { dealerId: this.currentUser.dealerId,
        fullName: this.currentUser.fullName,
        id: this.currentUser.id}
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    fileUploadsComplete: function () {
      this.childComponentIsUploadingFile = false
    },
    fileUploadsStarted: function () {
      this.childComponentIsUploadingFile = true
    },
    convertLocalToUtc: function (date) {
      try {
        var localValue = date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).slice(-2) + '-' + ('0' + date.getDate()).slice(-2) + ' ' + ('0' + date.getHours()).slice(-2) + ':' + ('0' + date.getMinutes()).slice(-2)
        let utcValue = moment.tz(localValue, moment.defaultZone.name).utc().format('YYYY-MM-DD HH:mm')
        return utcValue
      } catch (dateConversionError) {
        console.log('Error converting date to UTC')
        console.log(date)
        console.log(dateConversionError)
      }
    }
  },
  watch: {
    defaultCustomer: function (newValue, oldValue) {
      if (newValue) {
        this.selectedCustomer = newValue
      }
    },
    defaultSalesAgent: function (newValue, oldValue) {
      if (newValue) {
        this.selectedSalesAgent = newValue
      }
    },
    selectedSalesAgent: function (newValue, oldValue) {
      if (oldValue) {
        let existingSalesPersonCommissionIndex = this.commissions.findIndex((x) => x.salesPersonId === oldValue.id)
        if (existingSalesPersonCommissionIndex >= 0) {
          let existingSalesPersonCommission = this.commissions[existingSalesPersonCommissionIndex]
          if (existingSalesPersonCommission.commission === 0) {
            this.commissions.splice(existingSalesPersonCommissionIndex, 1)
          }
        }
      }
      if (newValue) {
        let existingSalesPersonCommission = this.commissions.find((x) => x.salesPersonId === newValue.id)
        if (!existingSalesPersonCommission) {
          this.commissions.push(this.createGenericCommission(newValue))
        }
        if (newValue.managerId && newValue.managerId > 0) {
          var agentManager = this.allUsers.find((x) => x.id === newValue.managerId)
          if (agentManager) {
            this.selectedSalesAgentManager = { id: agentManager.id, fullName: agentManager.fullName, dealerId: agentManager.dealerId }
          }
        }
      }
    },
    selectedStockType: function (newValue, oldValue) {
      if ((newValue && oldValue && newValue.id === oldValue.id) || !oldValue) {
        return
      }

      if (newValue && (newValue.name === 'Order' || newValue.name === 'Locate')) {
        if (newValue.name === 'Order') {
          this.locateVehicleIsFound = false
          this.selectedType = { id: 5, name: 'Ordered', altName: 'Ordered' }
        } else {
          if (this.locateVehicleIsFound === true) {
            this.selectedType = { id: 0, name: 'Awaiting Arrival', altName: 'AwaitingArrival' }
          } else {
            this.selectedType = { id: 6, name: 'Locating', altName: 'Locating' }
          }
        }
        this.showSoldItemPlusBtn = false
        var soldItemsCount = this.soldDealItems.length
        for (var i = 1; i < soldItemsCount; i++) {
          this.removeDealItem('Sold')
        }
      } else {
        this.showSoldItemPlusBtn = true
      }

      if (newValue.altName === 'InStock') {
        this.selectedType = { id: 1, name: 'Pending Delivery', altName: 'Pending' }
      }
    },
    selectedSaleType: function (newValue, oldValue) {
      this.setVehicleTypes(newValue.name)
      if (newValue && newValue.id === 1) {
        this.selectedContractType = this.definedTypes.dealContractTypes.options.find((x) => x.id === this.definedTypes.dealContractTypes.retail)
      } else {
        this.selectedContractType = null
      }
    },
    dealItems: function (newValue, oldValue) {
      var hasInboundInventory = false
      newValue.forEach((item) => {
        if (item.forInventory && item.forInventory.possessionStatus === 'Inbound') {
          hasInboundInventory = true
        }
      })

      if (hasInboundInventory) {
        this.activeDealTypeItems = this.definedTypes.dealTypes.filter(x => x.altName === 'AwaitingArrival' || x.altName === 'Cancelled')
      } else {
        this.activeDealTypeItems = []
      }
    },
    selectedType: function (newValue, oldValue) {
      if (newValue && this.selectedStockType && newValue.altName === 'AwaitingArrival' && this.selectedStockType.altName === 'InStock') {
        // this.selectedStockType = { id: 1, name: 'Order', altName: 'Order' }
      }
    },
    hasTradeInventory: function (newValue, oldValue) {
      if (newValue === false) {
        let dealItemsFiltered = this.dealItems.filter(function (dealItem) {
          return dealItem.dealItemType !== 'Trade'
        })
        this.dealItems = dealItemsFiltered
      } else {
        this.selectedMake = null
      }
    },
    hasLeaseInventory: function (newValue, oldValue) {
      if (newValue === false) {
        let dealItemsFiltered = this.dealItems.filter(function (dealItem) {
          return dealItem.dealItemType !== 'LeaseRenewal'
        })
        this.dealItems = dealItemsFiltered
      } else {
        this.selectedMake = null
      }
    }
  },
  beforeDestroy () {
    eventBus.$off('buyers-agreement-complete', this.onBuyersAgreementCompleted)
    eventBus.$off('buyers-agreement-cancelled', this.onBuyersAgreementCompleted)
    eventBus.$off('lease-agreement-complete', this.onLeaseAgreementCompleted)
    eventBus.$off('lease-agreement-cancelled', this.onLeaseAgreementCompleted)
    eventBus.$off('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$off('file-uploads-started', this.fileUploadsStarted)
  },
  created: function () {
    eventBus.$on('buyers-agreement-complete', this.onBuyersAgreementCompleted)
    eventBus.$on('buyers-agreement-cancelled', this.onBuyersAgreementCompleted)
    eventBus.$on('lease-agreement-complete', this.onLeaseAgreementCompleted)
    eventBus.$on('lease-agreement-cancelled', this.onLeaseAgreementCompleted)
    eventBus.$on('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$on('file-uploads-started', this.fileUploadsStarted)
  }
}

export default mixin

import mutationTypes from '../mutation-types'
import dealScheduledPaymentService from '../../services/dealScheduledPaymentService'

const state = {
  scheduledPaymentPayees: []
}

const getters = {
  scheduledPaymentPayees: state => {
    return state.scheduledPaymentPayees
  }
}

const mutations = {
  [mutationTypes.SET_SCHEDULED_PAYMENT_PAYEES] (state, payees) {
    state.scheduledPaymentPayees = payees
  }
}

const actions = {
  setScheduledPaymentPayees ({commit, state}) {
    commit(mutationTypes.SET_SCHEDULED_PAYMENT_PAYEES, [])
    dealScheduledPaymentService.findPayeesByDealer()
      .then((data) => {
        commit(mutationTypes.SET_SCHEDULED_PAYMENT_PAYEES, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

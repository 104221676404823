<template>
  <div>If you see this page please notify developers and send sequence of clicks that yielded this page</div>
</template>

<script>

export default {
  name: 'Redirector',
  props: {
    destination: {
      type: String,
      default: 'Dashboard'
    }
  },
  data () {
    return {
    }
  },
  mounted: function () {
    this.$router.replace({name: this.destination})
  }
}

</script>

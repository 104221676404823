<template>
<div class="field">
  <b-modal id="customer-selection" :width="400" :active.sync="showCustomerMatches" :has-modal-card="true" v-if="showCustomerMatches" :no-close-on-backdrop="true">
      <div class="modal-card">
        <header class="modal-card-head">
          <div class="columns call-overview is-fullwidth is-multiline is-hidden-mobile">
            <div class="column is-12" style="padding-bottom:30px">
              <label class="label">
                The following customer matches was found in dealer peak.
                <br/>
                Please select the one that matches best.
                </label>
            </div>
            </div>
        </header>
        <section class="modal-card-body call-log-body-fit">
          <template v-for="customer in customerMatches">
            <button :key="customer.dealerPeakUserId" type=button :class="{'button': true}" @click="onCustomerSelection(customer)">
              <label class="label">{{customer.fullName}}</label>
            </button>
          </template>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger pull-right" type="button" @click="cancelLeadLookup()">Close</button>
        </footer>
      </div>
    </b-modal>
    <b-modal id="selected-lead" :width="400" :no-close-on-esc="true" :active.sync="showNewLeadDetail" :has-modal-card="true" v-if="showNewLeadDetail" :no-close-on-backdrop="true">
      <div class="modal-card">
        <section class="modal-card-body call-log-body-fit">
          <div class="columns is-multiline">
            <div class="column is-6">
                <label class="label">Lead</label>
                <div class="control is-size-7">
                  {{selectedCustomerMatch.fullName}}
                </div>
            </div>
            <div class="column is-6">
                <label class="label">Sales Person</label>
                <div class="control is-size-7">
                  {{selectedCustomerMatch.agentFullName}}
                </div>
            </div>
            <div class="column is-6">
                <label class="label">Service Advisor</label>
                <div class="control is-size-7">
                  {{'-'}}
                </div>
            </div>
            <div class="column is-6">
                <label class="label">Open repair orders</label>
                <div class="control is-size-7">
                  NO
                </div>
            </div>
            <div class="column is-6">
                <label class="label">Status</label>
                <div class="control is-size-7">
                  {{'-'}}
                </div>
            </div>
            <div class="column is-6" v-if="selectedCustomerMatch == null || selectedCustomerMatch.leads != null && !selectedCustomerMatch.leads.length > 0">
                <label class="label">Open leads</label>
                <div class="control is-size-7">
                  NO
                </div>
            </div>
            <div class="column is-12" v-if="selectedCustomerMatch != null && selectedCustomerMatch.leads != null && selectedCustomerMatch.leads.length > 0">
                <label class="label">All leads</label>
                <div class="control is-size-7">
                  <template v-for="lead in selectedCustomerMatch.leads">
                    <span :key="lead.LeadId" class="has-text-primary lead-profile">{{lead.agent.fullName}}</span>
                    &nbsp;
                  </template>
                </div>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button :class="{'is-loading': isSaving }" class="button is-primary" type="button" @click="onCustomerConfirmation()">Confirm</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import customerService from '@/services/customerService'
import eventBus from '@/eventBus'

export default {
  name: 'DealerPeakCustomerMatch',
  props: {
  },
  components: {
  },
  data () {
    return {
      customer: null,
      noCustomerMatch: false,
      isCheckingForLead: false,
      customerMatches: [],
      showCustomerMatches: false,
      selectedCustomerMatch: null,
      showNewLeadDetail: false,
      componentId: 0,
      isSaving: false
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    onDealerPeakLookup: function (options) {
      let customerId = options.customerId
      this.componentId = options.componentId
      let that = this

      this.findCustomer(customerId).then(customer => {
        that.customer = customer
        if (customer.email) {
          that.findByEmail(customer.email)
        } else if (customer.mobile) {
          that.findByMobile(customer.mobile)
        } else {
          alert('No email or mobile found for customer lookup.')
        }
      })
    },
    findCustomer: function (customerId) {
      return new Promise((resolve, reject) => {
        customerService.single(customerId).then((response) => {
          resolve(response.customer)
        })
      })
    },
    findByEmail: function (email) {
      this.noCustomerMatch = false
      this.isCheckingForLead = true
      let that = this
      customerService.findDealerPeakMatchByEmail(email).then((response) => {
        if (response.result === false) {
          throw response
        }
        that.processCustomerMatchResponse(response)
      }).catch((error) => {
        console.log('Exception: ' + error.message)
        this.isCheckingForLead = false
        this.noCustomerMatch = true
      })
    },
    findByMobile: function (mobile) {
      this.noCustomerMatch = false
      this.isCheckingForLead = true
      let that = this
      customerService.findDealerPeakMatchByMobile(that.unmask(mobile)).then((response) => {
        if (response.result === false) {
          throw response
        }
        that.processCustomerMatchResponse(response)
      }).catch((error) => {
        console.log('Exception: ' + error.message)
        this.isCheckingForLead = false
        this.noCustomerMatch = true
      })
    },
    unmask: function (input) {
      if (!input || input === '') {
        return ''
      }
      return input.replace('+1', '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '')
    },
    processCustomerMatchResponse: function (response) {
      if (response.total === 0) {
        this.isCheckingForLead = false
        this.noCustomerMatch = true
        eventBus.$emit('lead-check-complete-' + this.componentId)
        return
      }
      this.customerMatches = response.results
      this.isCheckingForLead = false
      this.showCustomerMatches = true
      eventBus.$emit('lead-check-complete-' + this.componentId)
    },
    onCustomerSelection: function (customer) {
      this.showCustomerMatches = false
      this.customerMatches = []
      this.selectedCustomerMatch = customer
      this.showNewLeadDetail = true
    },
    cancelLeadLookup: function () {
      this.showCustomerMatches = false
      this.noCustomerMatch = false
    },
    onCustomerConfirmation: function () {
      if (this.isSaving) {
        return
      }
      let that = this
      this.isSaving = true
      this.customer.customerId = this.selectedCustomerMatch.dealerPeakUserId
      this.customer.leadId = this.selectedCustomerMatch.leadId
      this.customer.agentUserId = this.selectedCustomerMatch.agentUserId
      this.customer.agentLocationId = this.selectedCustomerMatch.agentLocationId

      customerService.update(this.customer).then((response) => {
        that.selectedCustomerMatch = null
        that.showNewLeadDetail = false
        that.isSaving = false
        if (this.componentId) {
          eventBus.$emit('activate-dealer-peak-link-' + this.componentId, this.customer.customerId)
        }
      })
    }
  },
  mounted: function () {
    eventBus.$on('dealer-peak-lookup', this.onDealerPeakLookup)
  },
  beforeDestroy () {
    eventBus.$off('dealer-peak-lookup', this.onDealerPeakLookup)
  }
}

</script>

<style scoped>
</style>

import { render, staticRenderFns } from "./ButtonListSelector.vue?vue&type=template&id=23d55754&scoped=true"
import script from "./ButtonListSelector.vue?vue&type=script&lang=js"
export * from "./ButtonListSelector.vue?vue&type=script&lang=js"
import style0 from "./ButtonListSelector.vue?vue&type=style&index=0&id=23d55754&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23d55754",
  null
  
)

export default component.exports
<template>
  <div class="is-inline-block" v-if="hasMatchingPdfDocument && hasFeatureAccess('deal.view')">
    <div v-if="!isPdfViewerActive && isBookingWizard && !listMode">
      <button type="button" class="button is-small" :class="{'is-loading': this.isUploadingFile}" @click.stop.prevent="onPreview" :title="actionTitle">
        <b-icon :icon="captureIcon" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <div class="quick-action" v-else-if="!isPdfViewerActive && !listMode" >
      <button type="button" class="button clickable" :class="{'is-loading': this.isUploadingFile}" @click.stop.prevent="onPreview" :title="actionTitle">
        <b-icon :icon="captureIcon" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <span :class="{'clickable': true, 'icon fa-spin': this.isUploadingFile}" @click.stop.prevent="onPreview" :title="actionTitle" v-else-if="!isPdfViewerActive">
      <b-icon :icon="captureIcon" custom-size="fa-lg"></b-icon>
    </span>
    <pdf-modal
        :pdfFile="currentSelectedPdf"
        :usePortal="usePdfPortal"
        :portalOrder="getPdfPortalOrder"
        v-on:on-pdf-downloaded="onPreviewed"
        v-on:on-pdf-closed="onPreviewClosed"
        v-if="currentSelectedPdf && currentSelectedPdf.src">
        <template slot="extra-footer-actions">
          <button class="button" @click.prevent="onDownloadFilePass">Alt Download</button>
        </template>
    </pdf-modal>
  </div>
</template>

<script>
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import FileSelector from '@/components/generic/FileSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import { createNamespacedHelpers } from 'vuex'
import _ from 'lodash'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'DealPdfDocumentViewer',
  mixins: [utilitiesMixin, dealInventoryStyleClasses, validateBeforeSubmitMixin],
  inject: {
    $validator: '$validator'
  },
  components: {
    'file-selector': FileSelector,
    'error-display-component': ErrorDisplayComponent,
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    allowedDocumentOption: {
      type: String,
      default: ''
    },
    isBookingWizard: {
      type: Boolean,
      default: false
    },
    listMode: {
      type: Boolean,
      default: false
    },
    captureIcon: {
      type: String,
      default: 'fal fa-coins'
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isPdfViewerActive: false,
      documentFiles: [],
      serverErrors: [],
      serverErrorMessage: '',
      isUploadingFile: false,
      fileUploadCount: 0,
      selectedDocumentType: null,
      replaceExisting: false,
      currentSelectedPdf: null
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapConfigGetters(['definedTypes']),
    ...mapDealerState(['currentDealer']),
    hasDocuments: function () {
      if (!this.currentDeal || !this.currentDeal.attachments) {
        return false
      }
      var primaryImages = this.currentDeal.attachments
      return primaryImages.length > 0
    },
    matchingDocuments: function () {
      if (this.hasDocuments) {
        let matchedDocuments = this.currentDeal.attachments.filter((x) => x.classType === this.allowedDocumentOption)
        let orderedAttachments = _.orderBy(matchedDocuments, ['createdAt'], ['desc'])
        return orderedAttachments
      }
      return []
    },
    hasMatchingDocuments: function () {
      return this.matchingDocuments.length > 0
    },
    fileUploadLabel: function () {
      let result = 'File'
      if (this.allowedDocumentOption) {
        let allowedOption = this.allowedDocumentOption
        let allowedDocumentType = this.definedTypes.dealDocumentTypes.options.find((x) => x.altName === allowedOption)
        result = allowedDocumentType.name
      }
      return result
    },
    actionTitle: function () {
      if (this.fileUploadLabel && this.fileUploadLabel !== 'File') {
        return 'Click to view ' + this.fileUploadLabel + ' document.'
      }
      return 'Click to view documents.'
    },
    matchingPdfs: function () {
      if (this.matchingDocuments && this.matchingDocuments.length > 0) {
        let pdfs = this.matchingDocuments.filter((x) => x.fileType === 'application/pdf')
        return pdfs
      }
      return []
    },
    latestPdfUrl: function () {
      if (this.matchingPdfs && this.matchingPdfs.length > 0) {
        return this.matchingPdfs[0].fileLocation
      }
      return ''
    },
    hasMatchingPdfDocument: function () {
      if (this.latestPdfUrl && this.latestPdfUrl.length > 0) {
        return true
      }
      return false
    },
    getPdfPortalOrder: function () {
      if (this.isBookingWizard) {
        return 1
      }
      return 2
    },
    usePdfPortal: function () {
      if (this.isBookingWizard) {
        return false
      }
      return true
    }
  },
  methods: {
    onDownloadFilePass: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.latestPdfUrl)
    },
    onPreview: function () {
      var extractedFileName = this.latestPdfUrl.split('/').pop()
      var docUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.latestPdfUrl)
      docUrl = encodeURI(docUrl)
      this.currentSelectedPdf = {
        name: extractedFileName,
        src: docUrl,
        fileName: extractedFileName,
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
    },
    onPreviewClosed: function () {
      this.currentSelectedPdf = null
    }
  },
  watch: {
  },
  mounted: function () {
  }
}
</script>

<style scoped>
</style>

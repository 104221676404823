<template>
<div>
  <div class="cd columns is-multiline is-centered autocheck-container no-bottom-padding no-bottom-margin" style="position: relative; z-index: 2000;" v-if="displayOnly">
    <div class="column is-12" v-if="autoCheckAvailable && currentAutoCheckRating !== null">
        <div style="width:78px;">
          <i class="icon fal fa-lg status-icon is-pulled-right" :class="getAutoCheckBbgIcons()" :title="getAutoCheckBbgTitle()"/>
          <img class="autocheck-owner-icons is-pulled-right" :src="totalOwnersImageIndicator" :title="totalOwnersImageTitle" v-if="totalOwnersImageIndicator !== null && totalOwners > 0">
        </div>
        <div v-if="autoCheckLink" class="row">
          <a style="width:78px; height:2px;" @click="getAutoCheckReport()"><img src="@/assets/static/autocheck/autocheck_logo_small.png" style="width:78px; height:22px;" :title="getLastCheckedAt"></a>
          <i class="fas fa-sync is-pulled-right" style="margin-right: 10px;margin-top: 3px; font-size:0.8rem;" @click="getUpdatedAutoCheck" :class="{'fa-spin': isRefreshing}" v-if="!inventoryNewFromFactory"></i>
        </div>
        <img v-else  title="Autocheck  report is pending" src="@/assets/static/autocheck/autocheck_logo_small.png" style="width:90px; height:auto;" class="img-disabled">
    </div>
    <div class="column is-12" v-else-if="!hideIfEmpty">
      <label class="label refresh-status-label">
      No AutoCheck report available
      </label>
    </div>
    <auto-check-report-control
      title="AutoCheck Vehicle Status"
      :vinNo="vinNo"
      v-on:on-hide-autocheck-report="onHideAutoCheckReport"
      :showAutoCheckReportModal="showReport">
    </auto-check-report-control>
  </div>
  <div class="columns box is-multiline is-centered autocheck-container" style="position: relative; z-index: 2000;"  v-else>
    <div class="autocheck-container-display-only column is-12">
      <div style="width:100%">
        <h3 class="title is-6 is-inline-block is-marginless">{{title}}</h3>
        <label class="is-inline-block is-size-7 has-text-success refresh-status-label" v-if="isRefreshing"> Fetching latest available report...</label>
        <i class="fal fa-sync-alt clickable is-pulled-right" :class="{'fa-spin': isRefreshing}" @click="getUpdatedAutoCheck()" v-if="!inventoryNewFromFactory"></i>
      </div>
    </div>
    <div class="column is=12"  v-if="autoCheckAvailable && currentAutoCheckRating !== null">
      <div>
        <div style="width:78px;">
          <i class="icon fal fa-lg status-icon is-pulled-right" :class="getAutoCheckBbgIcons()" :title="getAutoCheckBbgTitle()"/>
          <img class="autocheck-owner-icons is-pulled-right" :src="totalOwnersImageIndicator" :title="totalOwnersImageTitle" v-if="totalOwnersImageIndicator !== null && totalOwners > 0">
        </div>
        <div v-if="autoCheckLink">
          <a style="width:78px; height:2px;" @click="getAutoCheckReport()"><img src="@/assets/static/autocheck/autocheck_logo_small.png" style="width:78px; height:22px;" :title="getLastCheckedAt"></a>
          <i class="fas fa-sync is-pulled-right" style="margin-right: 10px;margin-top: 3px;font-size:0.8rem;" @click="getUpdatedAutoCheck" :class="{'fa-spin': isRefreshing}" v-if="showRefresh"></i>
        </div>
        <img v-else  title="Autocheck integration will be available soon" src="@/assets/static/autocheck/autocheck_logo_small.png" style="width:90px; height:auto;" class="img-disabled">
      </div>
    </div>
    <div class="column is=12"  v-if="!autoCheckAvailable || currentAutoCheckRating === null">
      <label class="label refresh-status-label">
      No AutoCheck report available
      </label>
    </div>
    <auto-check-report-control
      title="AutoCheck Vehicle Status"
      :vinNo="vinNo"
      v-on:on-hide-autocheck-report="onHideAutoCheckReport"
      :showAutoCheckReportModal="showReport">
    </auto-check-report-control>
  </div>
</div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import appraisalService from '@/services/appraisalService'
import inventoryService from '@/services/inventoryService'
import AutoCheckReportControl from '@/components/generic/AutoCheckReportControl'
import moment from 'moment'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'AutoCheckControl',
  components: {
    'auto-check-report-control': AutoCheckReportControl
  },
  props: {
    vinNo: {
      type: String,
      default: ''
    },
    autoCheckRating: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'AutoCheck Status'
    },
    autoUpdateInventory: {
      type: Boolean,
      default: false
    },
    runAutomatically: {
      type: Boolean,
      default: false
    },
    displayOnly: {
      type: Boolean,
      default: true
    },
    hideIfEmpty: {
      type: Boolean,
      default: true
    },
    inventory: {
      type: Object,
      default: null
    },
    modalContainer: {
      type: Boolean,
      default: false
    },
    showRefresh: {
      type: Boolean,
      default: true
    },
    inventoryType: {
      type: Object,
      default: null
    },
    purchasedFromType: {
      type: String,
      default: ''
    },
    inventoryTypeRequired: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isRefreshing: false,
      currentAutoCheckRating: null,
      autoCheckLink: true,
      reportText: '',
      showReport: false,
      isLoadingReport: false,
      serverErrorMessage: '',
      serverErrors: [],
      modalWidth: 960
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    autoCheckReportUrl: function () {
      let result = ''
      return result
    },
    autoCheckAvailable: function () {
      if (this.currentAutoCheckRating) {
        let scoreAvailable = this.currentAutoCheckRating && this.currentAutoCheckRating.score && this.currentAutoCheckRating.score > 0
        return scoreAvailable
      }
      return false
    },
    totalOwners: function () {
      if (this.currentAutoCheckRating && this.currentAutoCheckRating.ownerCount !== null) {
        let totalOwners = this.currentAutoCheckRating.ownerCount
        return totalOwners
      }
      return 0
    },
    totalOwnersImageIndicator: function () {
      if (this.currentAutoCheckRating && this.currentAutoCheckRating.ownerCount !== null) {
        let ownerCount = this.currentAutoCheckRating.ownerCount
        ownerCount = ownerCount > 3 ? 3 : ownerCount
        return '@/assets/static/carfax/ico_' + ownerCount + 'owner.png'
      }
      return null
    },
    totalOwnersImageTitle: function () {
      if (this.currentAutoCheckRating && this.currentAutoCheckRating.ownerCount !== null) {
        let ownerCount = this.currentAutoCheckRating.ownerCount
        return ownerCount + ' owner(s) recorded.'
      }
      return ''
    },
    inventoryNewFromFactory: function () {
      if (this.inventoryTypeRequired && !this.inventoryType) {
        return true
      } else if (this.purchasedFromType && this.inventoryType) {
        if (this.inventoryType.id === 0 && this.purchasedFromType === 'Factory') {
          return true
        }
      }
      return false
    },
    getLastCheckedAt: function () {
      let result = ''
      if (this.currentAutoCheckRating && this.currentAutoCheckRating.lastCheckedAt) {
        let lastCheckedAt = this.$options.filters.formatDate(this.currentAutoCheckRating.lastCheckedAt)
        result = 'Last checked at ' + lastCheckedAt + ')'
      }
      return result
    },
    dealerAutoCheckEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.autoCheckEnabledStatus
      }
      return false
    }
  },
  methods: {
    getUpdatedAutoCheck: function () {
      if (this.dealerAutoCheckEnabled) {
        let vinNo = this.vinNo
        if (this.isRefreshing === false && vinNo !== '') {
          this.isRefreshing = true
          appraisalService.getAutoCheck(vinNo, this.currentDealer).then(response => {
            this.isRefreshing = false
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              this.currentAutoCheckRating = response
              this.$emit('on-new-autocheck', this.currentAutoCheckRating)
              if (this.inventory && this.autoUpdateInventory) {
                this.currentAutoCheckRating.inventoryId = this.inventory.id
                inventoryService.addAutoCheckReport(this.currentAutoCheckRating)
              }
            }
          }).catch((error) => {
            this.$emit('on-autocheck-failed')
            this.isRefreshing = false
            this.serverErrorMessage = error.message
            this.currentAutoCheckRating = null
          })
        }
      }
    },
    getAutoCheckBbgTitle: function () {
      let result = ''
      if (this.autoCheckAvailable && this.currentAutoCheckRating !== null) {
        if (this.currentAutoCheckRating.recallCount > 0) {
          result = 'A recall has been flagged'
        } else if (this.currentAutoCheckRating.accidentCount && this.currentAutoCheckRating.accidentCount > 0) {
          result = 'Vehicle has at least one accident reported'
        } else {
          result = 'No major incidents'
        }
      }
      return result
    },
    getAutoCheckBbgIcons: function () {
      let classToAttach = {}
      if (this.autoCheckAvailable && this.currentAutoCheckRating !== null) {
        if (this.currentAutoCheckRating.recallCount > 0) {
          classToAttach['autocheck-recall'] = true
        } else if (this.currentAutoCheckRating.accidentCount && this.currentAutoCheckRating.accidentCount > 0) {
          classToAttach['autocheck-danger'] = true
        } else {
          classToAttach['autocheck-ok'] = true
        }
      }
      return classToAttach
    },
    getAutoCheckReport: function () {
      let vinNo = this.vinNo
      if (this.reportText) {
        this.showReportModal()
      } else if (!this.reportText && this.isLoadingReport === false && vinNo !== '') {
        this.showReportModal()
        this.isLoadingReport = true
        appraisalService.getAutoCheckReport(vinNo, this.currentDealer).then(response => {
          this.isLoadingReport = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.reportText = response
            this.$emit('on-render-report', response)
          }
        }).catch((error) => {
          this.$emit('on-autocheck-report-failed')
          this.isLoadingReport = false
          this.serverErrorMessage = error.message
        })
      }
    },
    hideReportModal: function () {
      if (!this.modalContainer) {
        this.showReport = false
      } else {
        this.$emit('on-hide-autocheck-report')
      }
    },
    showReportModal: function () {
      if (!this.modalContainer) {
        this.showReport = true
      } else {
        this.$emit('on-show-autocheck-report', this.reportText)
      }
    },
    setMaxModalWidth: function () {
      let viewWidth = window.innerWidth
      let newWidth = viewWidth * 90 / 100
      this.modalWidth = newWidth
    },
    onHideAutoCheckReport: function () {
      this.showReport = false
    }
  },
  watch: {
    autoCheckRating: function (newValue, oldValue) {
      this.currentAutoCheckRating = this.autoCheckRating
    }
  },
  mounted: function () {
    if (this.currentDealer.autoCheckEnabledStatus) {
      this.currentAutoCheckRating = this.autoCheckRating
      if (!this.inventoryNewFromFactory && !this.currentAutoCheckRating) {
        this.getUpdatedAutoCheck()
      } else if (!this.inventoryNewFromFactory && this.runAutomatically) {
        let monthAgo = moment.tz(new Date(), moment.tz.guess()).subtract(1, 'month').startOf('day')
        let lastAutoCheckUpdateDate = moment(this.currentAutoCheckRating.createdAt)
        if (lastAutoCheckUpdateDate < monthAgo) {
          this.getUpdatedAutoCheck()
        } else {
          this.$emit('on-new-autocheck', this.currentAutoCheckRating)
        }
      }
    } else {
      this.$emit('autocheck-disabled')
    }
    this.reportText = ''
    this.setMaxModalWidth()
    let that = this
    window.onresize = () => {
      that.setMaxModalWidth()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .status-icon {
    width: 13px;
    height: 12px;
    margin-right:12px;
  }

  .autocheck-container-display-only {
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    margin-top:10px;
  }

  .autocheck-container {
    margin-top: 20px;
    padding: 0;
    margin-left:0;
    margin-right:0;
  }

  .autocheck-owner-icons {
    height: 12px;
    width: 12px;
    margin-top:-15px;
  }

  .autocheck-warning {
    background-image: url('@/assets/static/carfax/ico_accident.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .autocheck-danger {
    background-image: url('@/assets/static/carfax/ico_alert.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .autocheck-recall {
    background-image: url('@/assets/static/carfax/ico_recall.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .autocheck-ok {
    background-image: url('@/assets/static/carfax/ico_green_check.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .refresh-status-label {
    margin-left:1em;
  }

  .cd .column {
    padding:0;
  }

  .modal-card-body {
    position:relative;
    max-height: calc(100vh - 220px);
    max-width: 90vw !important;
    width: 90vw !important;
    height: calc(100vh - 220px);
    overflow-y: auto;
    background: white;
    max-width: 90vw !important;
  }
  .modal-card {
    max-width: 90vw !important;
    width: 90vw !important;
  }
  .content {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
</style>

import requiresAuth from './route_guards'

export default [
  {
    path: '/parts/invoices',
    name: 'ListParts',
    component: () => import('@/components/parts/ListParts'),
    beforeEnter: requiresAuth
  },
  {
    name: 'PartsQuickView',
    path: '/parts/invoices/qview/:id',
    meta: {
      quickViewComponent: {
        component: () => import('@/components/parts/ViewParts'),
        qvHeaderClass: 'deal-qv-header',
        qvBodyClass: 'deal-qv-body',
        qvFooterClass: ''

      }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/parts/invoices/view/:id',
    name: 'ViewParts',
    component: () => import('@/components/parts/ViewParts'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/parts/vehicle-parts',
    name: 'ListVehicleParts',
    component: () => import('@/components/parts/ListVehicleParts'),
    beforeEnter: requiresAuth
  },
  {
    path: '/vehicle-part/new',
    name: 'NewVehiclePart',
    component: () => import('@/components/parts/NewVehiclePart'),
    beforeEnter: requiresAuth
  },
  {
    name: 'VehiclePartQuickView',
    path: '/parts/vehicle-part/qview/:id',
    meta: {
      quickViewComponent: {
        component: () => import('@/components/parts/ViewVehiclePart'),
        qvHeaderClass: 'ro-qv-header',
        qvBodyClass: 'ro-qv-body'
      }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/vehicle-part/edit/:id',
    name: 'EditVehiclePart',
    component: () => import('@/components/parts/EditVehiclePart'),
    beforeEnter: requiresAuth
  }
]

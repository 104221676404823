<template>
  <div class="is-inline-block" v-if="!isNewDealCodeActive && isBookingWizard && !isListMode">
      <button type="button" class="button is-small is-primary" @click.stop.prevent="onAddNewDealCode()" title="click to update deal # and finalize">
        <span v-if="useLabel">Finalize</span>
        <b-icon v-else icon="fal fa-hashtag" custom-size="fa-lg"></b-icon>
      </button>
  </div>
  <span v-else-if="!isNewDealCodeActive">
    <span :id="instanceId" :name="instanceId" class="clickable" v-tooltip="{content:'click to update deal # and finalize'}" @click.stop.prevent="onAddNewDealCode()" v-if="isListMode">
      <i class="fal fa-hashtag" v-if="showActionIcon"></i>
      <a><span class="has-text-weight-semibold" v-if="!showActionIcon">{{actionText}}</span></a>
    </span>
    <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
      <button class="button" @click.stop.prevent="onAddNewDealCode()" v-tooltip="{content:'click to update deal # and finalize'}">
        <b-icon pack="fal" icon="hashtag" custom-size="fa-lg"></b-icon>
      </button>
    </div>
  </span>
  <portal to="global-modal-portal" v-else-if="isNewDealCodeActive && usePortal">
    <b-modal :active.sync="isNewDealCodeActive" scroll="keep" :has-modal-card="true" v-if="dealListModel">
      <div class="modal-card" style="width: 450px;">
        <header class="modal-card-head">
          <p class="modal-card-title">Update Deal # And Finalize</p>
        </header>
        <section class="modal-card-body">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form class="view-container" @submit.prevent="validateBeforeSubmit">
            <div class="columns">
              <div class="column is-12">
                <div class="field is-horizontal">
                  <div class="field-label is-small" style=" margin-right: auto !important;">
                    <label class="label">Deal #<b-icon pack="fas" icon="star" class="fa-ss" /></label>
                  </div>
                  <div class="field-body">
                    <div class="field">
                      <div class="control">
                        <input name="dealCode" data-vv-as="deal #" v-validate="{'required': true, 'max': 10}" :class="{'input': true, 'is-danger': errors.has('dealCode') }" type="text" placeholder="0000000000" v-model="dealCode">
                        <span v-show="errors.has('dealCode')" class="help is-danger">
                          <i v-show="errors.has('dealCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first('dealCode') }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="validateBeforeSubmit">save & finalize</button>
          <button class="button is-danger" @click="isNewDealCodeActive = false">cancel</button>
        </footer>
      </div>
    </b-modal>
  </portal>
  <b-modal :active.sync="isNewDealCodeActive" scroll="keep" :has-modal-card="true" v-else-if="isNewDealCodeActive && !usePortal">
    <div class="modal-card" style="width: 450px;">
      <header class="modal-card-head">
        <p class="modal-card-title">Update Deal # And Finalize</p>
      </header>
      <section class="modal-card-body">
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <form class="view-container" @submit.prevent="validateBeforeSubmit">
          <div class="columns">
            <div class="column is-12">
              <div class="field is-horizontal">
                <div class="field-label is-small" style=" margin-right: auto !important;">
                  <label class="label">Deal #<b-icon pack="fas" icon="star" class="fa-ss" /></label>
                </div>
                <div class="field-body">
                  <div class="field">
                    <div class="control">
                      <input name="dealCode" data-vv-as="deal #" v-validate="{'required': true, 'max': 10}" :class="{'input': true, 'is-danger': errors.has('dealCode') }" type="text" placeholder="0000000000" v-model="dealCode">
                      <span v-show="errors.has('dealCode')" class="help is-danger">
                        <i v-show="errors.has('dealCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first('dealCode') }}
                      </span>
                    </div>
                  </div>
                </div>
                </div>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="validateBeforeSubmit">save & finalize</button>
        <button class="button is-danger" @click="isNewDealCodeActive = false">cancel</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>

import dealService from '@/services/dealService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import eventBus from '@/eventBus'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'

export default {
  name: 'DealCodeCapture',
  mixins: [validateBeforeSubmitMixin],
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    dealListModel: {
      type: Object,
      default: null
    },
    isListMode: {
      type: Boolean,
      default: true
    },
    useLabel: {
      type: Boolean,
      default: false
    },
    showActionIcon: {
      type: Boolean,
      default: true
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    isBookingWizard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isNewDealCodeActive: false,
      isSaving: false,
      instanceId: this.$uuid.v4(),
      serverErrors: [],
      serverErrorMessage: '',
      dealCode: this.dealListModel.dealCode
    }
  },
  computed: {
    actionText: function () {
      return this.dealCode ? this.dealCode : '-'
    }
  },
  methods: {
    onAddNewDealCode: function () {
      this.isNewDealCodeActive = true
    },
    onSave: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      let dealModel = {
        id: this.dealListModel.id,
        dealCode: this.dealCode
      }
      dealService.saveDealCode(dealModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.isNewDealCodeActive = false
          eventBus.$emit('reload-deal-list')
          eventBus.$emit('reload-deal')
          eventBus.$emit('deal-finalized')
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    }
  },
  watch: {
    'dealListModel.dealCode': function (newValue, oldValue) {
      if (newValue !== this.dealCode) {
        this.dealCode = newValue
      }
    }
  },
  mounted () {
  }
}

</script>

<style scoped>
  .modal-card-head, .modal-card-foot {
    padding: 20px !important
  }

  .modal-card-body {
    position:relative;
    height: calc(15vh - 5px) !important;
    overflow-y: auto;
    background: white;
  }

   div.field-label.is-small > label
  {
    display: flex;
    align-items: center;
    vertical-align: middle;
    width:85px;
    text-align:left;
    margin-right: auto !important;
  }
</style>

<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Add Insurer</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmit" @change="onFormChange">
      <div class="columns">
        <div class="column is-6">
          <dealer-selector v-model="selectedDealer"></dealer-selector>
          <div class="field">
            <label class="label">Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="control">
              <input name="name" data-vv-as="name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('name') }" type="text" placeholder="" v-model="name">
              <span v-show="errors.has('name')" class="help is-danger"><i v-show="errors.has('name')" class="fas fa-exclamation-triangle"></i> {{ errors.first('name') }}</span>
            </div>
          </div>
        </div>
      </div>
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  type="button" @click="validateBeforeSubmit">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>
  </section>
</template>

<script>
import insurerService from '@/services/insurerService'
import DealerSelector from '@/components/generic/DealerSelector'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import insurersMixin from '@/mixins/insurers/index'

export default {
  name: 'NewInsurer',
  props: {
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full-screen'
    },
    startWith: {
      type: String,
      default: ''
    }
  },
  mixins: [validateBeforeSubmitMixin, insurersMixin],
  components: {
    'dealer-selector': DealerSelector
  },
  data () {
    return {
      selectedDealer: null,
      id: 0,
      insurerId: null,
      dealerId: null,
      name: this.startWith,
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false
    }
  },
  methods: {
    onSave: function () {
      var insurerModel = {
        isCreate: true,
        subscriberId: this.$store.state.user.currentUser.subscriberId,
        name: this.name,
        status: 'Active',
        dealerId: this.selectedDealer.id
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      insurerService.save(insurerModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.onChangesSaved()
          this.$emit('on-save', response.insurer)
          if (this.mode === 'full-screen') {
            this.onComplete()
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getViewRoute: function () {
      return {name: 'ListInsurers'}
    },
    onCancel: function () {
      this.$emit('on-cancel')
      if (this.mode === 'full-screen') {
        this.onComplete()
      }
    },
    onComplete: function () {
      this.$router.push({name: 'ListInsurers'})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

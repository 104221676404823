import axios from 'axios'
import baseService from './baseService'
import appraisalData from './data/appraisal'
import carFaxData from './data/carfax'
import autoCheckData from './data/autocheck'

const appraisalService = {
  appraise (vinNo, trim, mileage, condition, msrp) {
    return new Promise((resolve, reject) => {
      var serviceUrl = '/appraisal/appraise?vin=' + vinNo + '&trim=' + trim
      if (mileage) {
        serviceUrl += '&mileage=' + mileage
      }
      if (condition) {
        serviceUrl += '&condition=' + condition
      }
      if (msrp) {
        serviceUrl += '&msrp=' + msrp
      }
      if (baseService.isTestMode) {
        resolve(appraisalData)
      } else {
        axios
          .get(serviceUrl, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getCarFax (vinNo, currentDealer) {
    return new Promise((resolve, reject) => {
      var serviceUrl = '/appraisal/getcarfax?vin=' + vinNo + '&did=' + currentDealer.id
      if (baseService.isTestMode) {
        resolve(carFaxData)
      } else {
        axios
          .get(serviceUrl, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getAutoCheck  (vinNo, currentDealer) {
    return new Promise((resolve, reject) => {
      var serviceUrl = '/appraisal/getautocheck?vin=' + vinNo + '&did=' + currentDealer.id
      if (baseService.isTestMode) {
        resolve(autoCheckData)
      } else {
        axios
          .get(serviceUrl, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getAutoCheckReport  (vinNo, currentDealer) {
    return new Promise((resolve, reject) => {
      var serviceUrl = '/appraisal/getautocheckreport?vin=' + vinNo + '&did=' + currentDealer.id
      if (baseService.isTestMode) {
        resolve(autoCheckData)
      } else {
        axios
          .get(serviceUrl, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  },
  generateCarFaxReportPdf (vinNo) {
    return new Promise((resolve, reject) => {
      var serviceUrl = '/appraisal/carfaxreportgenerate?vinno=' + vinNo
      if (baseService.isTestMode) {
        resolve(carFaxData)
      } else {
        axios
          .get(serviceUrl, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  generateAutoCheckReportPdf (vinNo) {
    return new Promise((resolve, reject) => {
      var serviceUrl = '/appraisal/autocheckreportgenerate?vinno=' + vinNo
      if (baseService.isTestMode) {
        resolve(carFaxData)
      } else {
        axios
          .get(serviceUrl, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getBlackBookInformation (vinNo, currentDealer) {
    return new Promise((resolve, reject) => {
      var serviceUrl = '/appraisal/getblackbookbyvin?vin=' + vinNo + '&dealerId=' + currentDealer.id
      if (baseService.isTestMode) {
        resolve(carFaxData)
      } else {
        axios
          .get(serviceUrl, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appraisalService

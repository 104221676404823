<template>
  <div>
    <div v-if="error" class="error-message">
      {{ error }}
    </div>
    <div v-else-if="loading" class="loading-message">
      Loading PDF document
      <div class="is-fullwidth">
        <div class="aligner-item has-text-centered has-background-white mt-20 is-fullwidth">
          <img src="@/assets/ajax-loader.gif">
        </div>
      </div>
    </div>
    <div v-else class="pdf-container">
      <div class="page-navigation" v-if="!isAllPages">
        <button class="button" @click="prevPage" :disabled="currentPage === 1">Previous</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button class="button" @click="nextPage" :disabled="currentPage === totalPages">Next</button>
      </div>
      <div ref="pdfCanvasContainer" style="position: relative;">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PdfInlineDisplayComponent',
  props: {
    pdfFile: {
      type: Object,
      required: true
    },
    src: {
      type: String,
      required: true
    },
    scale: {
      type: Number,
      default: 1
    },
    defaultPage: {
      type: Number,
      default: undefined
    },
    rotate: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      pdfDimensions: { width: 0, height: 0 },
      pdfDocument: null,
      currentPage: 1,
      totalPages: 0,
      loading: true,
      error: null
    };
  },
  computed: {
      isAllPages: function() {
          return this.defaultPage === undefined;
      }
  },
  methods: {
      print: async function(dpi, pageNumbersToPrint) {
          if (this.pdfDocument === null) {
              return;
          }
          await this.printPage(dpi, pageNumbersToPrint);
      },
  printPage: async function(dpi, pageNumbersToPrint) {
          let that = this
          // 1in == 72pt
          // 1in == 96px
          var PRINT_RESOLUTION = dpi === undefined ? 150 : dpi;
          var PRINT_UNITS = PRINT_RESOLUTION / 72.0;
          var CSS_UNITS = 96.0 / 72.0;
          var iframeElt = document.createElement('iframe');
          function removeIframe() {
              iframeElt.parentNode.removeChild(iframeElt);
          }
          new Promise(function(resolve, reject) {
              iframeElt.frameBorder = '0';
              iframeElt.scrolling = 'no';
              iframeElt.width = '0px;'
              iframeElt.height = '0px;'
              iframeElt.style.cssText = 'position: absolute; top: 0; left: 0';
              iframeElt.onload = function() {
                  resolve(this.contentWindow);
              }
              window.document.body.appendChild(iframeElt);
          })
          .then(async function(win) {
              win.document.title = '';
              return await that.pdfDocument.getPage(1)
              .then(function(page) {
                  var viewport = page.getViewport({ scale: 1 });
                  win.document.head.appendChild(win.document.createElement('style')).textContent =
                      '@supports ((size:A4) and (size:1pt 1pt)) {' +
                          '@page { margin: 1pt; size: ' + ((viewport.width * PRINT_UNITS) / CSS_UNITS) + 'pt ' + ((viewport.height * PRINT_UNITS) / CSS_UNITS) + 'pt; }' +
                      '}' +

                      '@media print {' +
                          'body { margin: 0 }' +
                          'canvas { page-break-before: avoid; page-break-after: always; page-break-inside: avoid }' +
                      '}'+

                      '@media screen {' +
                          'body { margin: 0 }' +
                      '}'+

                      ''
                  return win;
              })
          })
          .then(async function (win) {
              var allPages = [];
              for ( var pageNumber = 1; pageNumber <= that.pdfDocument.numPages; ++pageNumber ) {
                  if ( pageNumbersToPrint !== undefined && pageNumbersToPrint.indexOf(pageNumber) === -1 )
                      continue;
                  allPages.push(
                      await that.pdfDocument.getPage(pageNumber)
                      .then(function(page) {
                          var viewport = page.getViewport({ scale: 1 });
                          var printCanvasElt = win.document.body.appendChild(win.document.createElement('canvas'));
                          printCanvasElt.width = (viewport.width * PRINT_UNITS);
                          printCanvasElt.height = (viewport.height * PRINT_UNITS);

                          return page.render({
                              canvasContext: printCanvasElt.getContext('2d'),
                              transform: [ // Additional transform, applied just before viewport transform.
                                  PRINT_UNITS, 0, 0,
                                  PRINT_UNITS, 0, 0
                              ],
                              viewport: viewport,
                              intent: 'print'
                          }).promise;
                      })
                  );
              }

              Promise.all(allPages)
              .then(function() {

                  win.focus(); // Required for IE
                  if (win.document.queryCommandSupported('print')) {
                      win.document.execCommand('print', false, null);
                      } else {
                      win.print();
                  }
                  removeIframe();
              })
              .catch(function(err) {
                  removeIframe();
              })
          })
    },
    async loadPdf() {
      try {
        this.loading = true;
        this.error = null;
        const response = await fetch(this.pdfFile.src);
        this.pdfBytes = await response.arrayBuffer();
        const loadingTask = this.$pdf.getDocument({ data: this.pdfBytes });
        this.pdfDocument = await loadingTask.promise;
        this.totalPages = this.pdfDocument.numPages;
        this.loading = false;
        this.$emit('on-pdf-downloaded')
        if (this.isAllPages) {
          await this.renderAllPages();
        } else {
          let actualPage = this.defaultPage > this.totalPages ? this.totalPages : this.defaultPage;
          this.currentPage = actualPage;
          await this.renderPage(actualPage);
        }
      } catch (err) {
        console.error('Error loading Pdf:', err);
        this.error = 'Failed to load PDf. Please try again.';
        this.loading = false;
      }
    },
    async renderAllPages() {
      for (let i = 1; i <= this.totalPages; i++) {
        await this.renderPage(i);
      }
    },
    async renderPage(pageNumber) {
      const page = await this.pdfDocument.getPage(pageNumber);
      const pdfCanvasContainer = this.$refs.pdfCanvasContainer;
      var canvas = document.createElement('canvas')
      canvas.style.display = 'block'
      pdfCanvasContainer.appendChild( canvas );
      var context = canvas.getContext('2d')
      var pageRotation = (page.rotate === undefined ? 0 : page.rotate) + (this.rotate === undefined ? 0 : this.rotate);
      var scale = canvas.offsetWidth / page.getViewport({ scale: this.scale }).width * (window.devicePixelRatio || 1);
      const viewport = page.getViewport({ scale: scale, rotation: pageRotation });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      this.pdfDimensions = { width: viewport.width, height: viewport.height };
      await page.render({ canvasContext: context, viewport }).promise;
      const annotations = await page.getAnnotations();
      console.log(annotations)
    },
    async prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
        await this.renderPage(this.currentPage);
      }
    },
    async nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
        await this.renderPage(this.currentPage);
      }
    }
  },
  async mounted() {
    await this.loadPdf();
  }
};
</script>

<style scoped>
.error-message {
  color: red;
  font-weight: bold;
  padding: 20px;
  text-align: center;
}

.loading-message {
  font-style: italic;
  padding: 20px;
  text-align: center;
  min-height: 350px;
}

.page-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.pdf-container {
  max-width: 100%;
  overflow-x: auto;
}

</style>


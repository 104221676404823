import axios from 'axios'
import baseService from './baseService'
import dealData from './data/product'

const appService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('scheduled-payment-load-all')
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/scheduledPayment/list', searchCriteria, {...baseService.defaultConfig, requestId: 'scheduled-payment-load-all'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('scheduled-payment all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  single (scheduledPaymentId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(null)
      } else {
        axios
          .post('/scheduledPayment/get?id=' + scheduledPaymentId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getCheckRequestNotificationRecipients (scheduledPaymentId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(null)
      } else {
        axios
          .post('/scheduledPayment/getNotificationRecipients?id=' + scheduledPaymentId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  notifyCheckRequest (notificationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(null)
      } else {
        axios
          .post('/scheduledPayment/notifyCheckRequest', notificationModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (scheduledPaymentModels) {
    return new Promise((resolve, reject) => {
      var ids = []
      scheduledPaymentModels.forEach(element => {
        ids.push(element.id)
      })
      axios
        .post('/scheduledpayment/delete', ids, baseService.defaultConfig)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error.response.data)
        })
    })
  },
  archive (scheduledPaymentModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(null)
      } else {
        var ids = []
        scheduledPaymentModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/scheduledpayment/archive', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restore (scheduledPaymentModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(null)
      } else {
        var ids = []
        scheduledPaymentModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/scheduledpayment/restore', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateScheduledPayment (scheduledPayment) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/scheduledpayment/updateScheduledPayment', scheduledPayment, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  generatePaymentRequestDocument (scheduledPaymentId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios({
          url: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/queue-process-scheduled-payment/' + scheduledPaymentId,
          method: 'GET',
          responseType: 'text',
          data: '',
          withCredentials: false
        }).then((response) => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response)
        })
      }
    })
  },
  markScheduledPaymentUnpaid (schedulePayment) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/scheduledpayment/markschedulepaymentunpaid', schedulePayment, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  issueCheckRequest (schedulePayment) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/scheduledpayment/issueCheckRequest', schedulePayment, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markScheduledPaymentPaid (schedulePayment) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/scheduledpayment/markschedulepaymentpaid', schedulePayment, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (scheduledPaymentNoteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var scheduledPayment = dealData.all.find((x) => x.id === scheduledPaymentNoteModel.inventoryId)
        if (scheduledPayment) {
          scheduledPayment.notes.push(scheduledPaymentNoteModel)
        }
        resolve(scheduledPayment)
      } else {
        axios
          .post('/scheduledpayment/notes', scheduledPaymentNoteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/scheduledpayment/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/scheduledpayment/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/scheduledpayment/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/scheduledpayment/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByScheduledPaymentId (scheduledPaymentId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all)
      } else {
        axios
          .get('/scheduledpayment/scheduledpaymentnotes?id=' + scheduledPaymentId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  changePaymentStatus (schedulePayment) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/scheduledpayment/ChangePaymentStatus', schedulePayment, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findPayeesByDealer () {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .get('/scheduledPayment/findPayeesByDealer', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  processBatchPayment (paymentEvents) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/scheduledPayment/processBatchPayment', paymentEvents, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  exportList (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios({
          url: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/scheduled-payment/list',
          method: 'POST',
          responseType: 'blob',
          data: searchCriteria,
          withCredentials: false
        }).then((response) => {
          resolve(response)
        }).catch(error => {
          reject(error.response)
        })
      }
    })
  },
  createScheduledPaymentForWholesaleReceivable (paymentRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var deal = {
          id: 999
        }
        dealData.all.push(deal)
        resolve(deal)
      } else {
        axios
          .post('/scheduledPayment/createScheduledPaymentForWholesaleReceivable', paymentRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService

<template>
  <div :class="containerClass" >
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-if="required"></b-icon>
    </label>
    <div class="control">
      <div class="buttons has-addons instance is-marginless">
        <input ref="control" :data-vv-scope="scope" type="text" :name="instanceName" v-model="getValue" :data-vv-as="this.validateAs" v-validate="{'required': required}" style="width:0px;height:0px;margin:0px;padding:0px;border:0px;"/>
        <button :tabindex="tabindex" type=button :class="{'button': true, ' is-marginless': true,  'is-info': selectedValue && availableValue.id === selectedValue.id, 'small': buttonSize === 'small', 'medium': buttonSize === 'medium', 'large': buttonSize === 'large'}" v-for="availableValue in list" :key="availableValue.id" @click="onAvailableValueSelected(availableValue)" :disabled="enabled === false || isItemDisabled(availableValue)" v-show="!(hideInactiveValues && isItemDisabled(availableValue))">
          <span class="icon is-small" v-if="availableValue.ico">
            <i v-bind:class="[availableValue.ico]"></i>
          </span>
          <span>{{availableValue[itemLabel]}}</span>
        </button>
      </div>
      <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonListSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    containerClass: {
      type: String,
      default: 'field'
    },
    label: {
      type: String,
      default: 'Item'
    },
    validateAs: {
      type: String,
      default: 'Item'
    },
    availableValues: {
      type: Array,
      default: () => [{id: 1, name: 'Sample 1'}, {id: 2, name: 'Sample 2'}]
    },
    required: {
      type: Boolean,
      default: false
    },
    enabled: {
      type: Boolean,
      default: true
    },
    activeValues: {
      type: Array,
      default: () => []
    },
    allowDeselection: {
      type: Boolean,
      default: true
    },
    hideInactiveValues: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    },
    tabindex: {
      type: Number,
      default: 0
    },
    useValidator: {
      type: Object,
      default: null
    },
    itemLabel: {
      type: String,
      default: 'name'
    },
    buttonSize: {
      type: String,
      default: 'large'
    }
  },
  data () {
    return {
      selectedValue: this.value,
      instanceName: '' + this.$uuid.v4(),
      filter: '',
      showAllOptions: false,
      showGroupOptions: false,
      isRolledUp: false
    }
  },
  computed: {
    getSizeClass: function () {
      return this.buttonSize
    },
    getValue: function () {
      return this.selectedValue ? this.selectedValue.id : ''
    },
    list: function () {
      return this.availableValues
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      this.selectedValue = newValue
    },
    activeValues: function () {
      this.availableValues = this.availableValues
    }
  },
  methods: {
    onAvailableValueSelected: function (value) {
      if (this.enabled) {
        if (this.selectedValue) {
          if (value && this.selectedValue === value.name && this.allowDeselection) {
            this.selectedValue = null
          } else {
            this.selectedValue = value
          }
        } else {
          this.selectedValue = value
        }
        this.$emit('input', this.selectedValue)
        this.$emit('on-change', this.selectedValue)
        let form = this.$refs.control.closest('form')
        if (form) {
          var event = new Event('change')
          form.dispatchEvent(event)
        }
        this.$nextTick(() => {
          this.$validator.validate(this.validationName, this.selectedValue)
        })
      }
    },
    isItemDisabled: function (item) {
      if (this.activeValues.length === 0) {
        return false
      }
      return (!this.activeValues.find((x) => x.id === item.id))
    }
  },
  mounted () {
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}
</script>

<style scoped>
.large {
  height:41px !important;
}

.medium {
  height:34px !important;
}

.small {
  height:25px !important;
}

.button-selector-label {
  display:flex;
  align-items:flex-end;
  height:22px;
  margin-bottom:0.75em;
}

.selected-value-display {
  margin-top: 0.5em;
}

.toggle {
  margin-right:10px;
}

.options-visible {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  max-height: 9999px;
  visibility: visible;
  opacity: 1;
}

.options-collapsed {
  -webkit-transition: all .3s ease-in-out;
  transition: all .3s ease-in-out;
  max-height: 0;
  visibility: hidden;
  opacity: 0;
}
</style>

export default {
  all: [
    {
      name: 'West Coast Customs',
      dealerId: 1,
      subscriberId: 1,
      inventoryId: 1,
      status: 'Active',
      id: 1
    },
    {
      name: 'Allgood Automotive',
      dealerId: 1,
      subscriberId: 1,
      inventoryId: 2,
      status: 'Active',
      id: 2
    },
    {
      name: 'American Choppers',
      dealerId: 1,
      subscriberId: 1,
      inventoryId: 3,
      status: 'Active',
      id: 3
    }
  ]
}

<template>
  <section class="error-container">
    <a class="errors" ref="serverErrorBlock" tabindex="500"></a>
    <article class="message is-danger is-small" v-if="serverErrors.length > 0">
      <div class="message-body">
        <p><strong>Please correct the following error(s):</strong></p>
        <ul>
          <li v-for="error in serverErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
        </ul>
        <ul>
          <li v-for="error in serverErrors" :key="error.message">{{ error.message }}</li>
        </ul>
      </div>
    </article>

    <article class="message is-danger  is-small" v-if="serverErrorMessage.length > 0">
      <div class="message-body">
        <p><strong>Please review the following error :</strong></p>
        <ul>
          <li>{{ serverErrorMessage }}</li>
        </ul>
      </div>
    </article>
  </section>
</template>

<script>
export default {
  name: 'ErrorDisplayComponent',
  props: {
    serverErrors: {
      type: Array,
      default: () => []
    },
    serverErrorMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  watch: {
    serverErrors: function (newValue, oldValue) {
      if (newValue && newValue.length > 0) {
        this.$refs.serverErrorBlock.focus()
      }
    },
    serverErrorMessage: function (newValue, oldValue) {
      if (newValue && newValue !== '' && newValue !== null && newValue !== undefined) {
        this.$refs.serverErrorBlock.focus()
      }
    }
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/accessoryBin',
    name: 'ListAccessoryBin',
    component: () => import('@/components/configuration/accessoryBin/ListAccessoryBin'),
    beforeEnter: requiresAuth
  },
  {
    name: 'AccessoryBinQuickView',
    path: '/configuration/accessoryBin/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/accessoryBin/ViewAccessoryBin')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/accessoryBin/new',
    name: 'NewAccessoryBin',
    component: () => import('@/components/configuration/accessoryBin/NewAccessoryBin'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/accessoryBin/view/:id',
    name: 'ViewAccessoryBin',
    component: () => import('@/components/configuration/accessoryBin/ViewAccessoryBin'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/accessoryBin/edit/:id',
    name: 'EditAccessoryBin',
    component: () => import('@/components/configuration/accessoryBin/EditAccessoryBin'),
    beforeEnter: requiresAuth
  }
]

export default {
  all: [
    {
      name: 'Feature 1',
      status: 'Active',
      id: 1
    },
    {
      name: 'Feature 2',
      status: 'Active',
      id: 2
    },
    {
      name: 'Feature 3',
      status: 'Active',
      id: 3
    }
  ]
}

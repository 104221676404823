<template>
  <div class="columns">
    <div class="column">
      <div class="timeline" v-if="hasNotes">
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <br/>
        <div class="timeline-header">
          <span class="tag is-small is-primary">Newer</span>
        </div>
        <div class="timeline-item" v-for="note in limitBy(this.notes, this.noteRange)" :key="note.id">
          <div class="timeline-marker is-image is-32x32">
            <img src="https://bulma.io/assets/images/placeholders/32x32.png">
          </div>
          <div class="timeline-content" style="width: 100%;">
            <p class="heading is-inline-block" :title="note.createdAt | formatDate">{{note.createdAt | formatHumanDate}} - {{note.createdBy}}</p>
            <i class="icon fas fa-trash-alt fa-xs pointer" @click="onRequestDeleteNote(note)" v-if="!note.isDeleted" title="Delete"/>
            <i class="icon fal fa-trash-restore-alt fa-xs pointer" @click="onRequestRestoreNote(note)" v-if="note.isDeleted" title="Restore"/>
            <i class="icon fab fa-hotjar pointer fa-xs" :class="{'has-text-danger': note.isHotNote}" @click="onToggleHotNote(note)"/>
            <i class="icon fas fa-dollar-sign pointer fa-xs" v-if="entity === 'deal' && note.type === 'Finance'" title="Finance"/>
            <i class="icon fas fa-handshake pointer fa-xs" v-if="entity === 'deal' && note.type === 'Sale'" title="Sale"/>
            <i class="icon far fa-thumbtack pointer fa-xs" :class="{'has-text-success': note.pinned}" :title="getPinTitle(note)" @click="onTogglePinned(note)"/>
            <p class="is-size-7 note-item" :class="{'note-item-deleted':note.isDeleted, 'has-text-danger':note.isHotNote}">
              <span v-if="isHtml" v-html="note.note"></span>
              <span v-else>{{note.note}}</span>
            </p>
          </div>
        </div>
        <header class="timeline-header">
          <span class="tag is-small is-primary" @click="showMoreNotes" v-if="hasMoreNotes">Older...</span>
          <span class="tag is-small is-primary" @click="showMoreNotes" v-else>Older</span>
        </header>
      </div>
      <div v-else class="column is-12 is-size-7 has-text-info">
        <p>
          There are no notes available
        </p>
      </div>
    </div>
    <b-modal :active.sync="isDeleteNoteConfirmationActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Confirm Delete Note Action</p>
        </header>
        <section class="modal-card-body">
            Are you sure you wish to delete the selected {{entity}} note?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="onNoteDelete()" type="button">Delete</button>
          <button class="button"  @click="isDeleteNoteConfirmationActive = false" type="button">Cancel</button>
        </footer>
      </div>
    </b-modal>
    <b-loading :is-full-page="false" :active.sync="isSaving" :canCancel="false"></b-loading>
  </div>
</template>

<script>

import inventoryService from '@/services/inventoryService'
import dealService from '@/services/dealService'
import serviceRequestService from '@/services/serviceRequestService'
import scheduledPaymentService from '@/services/dealScheduledPaymentService'
import roServiceService from '@/services/roServiceService'
import paymentTrackingService from '@/services/paymentTrackingService'
import customerService from '@/services/customerService'
import wholesaleReceivableService from '@/services/wholesaleReceivableService'
import partsService from '@/services/partsService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import eventBus from '@/eventBus'
import Vue2Filters from 'vue2-filters'

export default {
  name: 'NoteTimelineView',
  mixins: [Vue2Filters.mixin],
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    value: {
      type: Array,
      default: null
    },
    entity: {
      type: String,
      default: ''
    },
    isHtml: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      notes: this.value,
      defaultNoteRange: 5,
      noteRange: 5,
      isDeleteNoteConfirmationActive: false,
      selectedNote: null,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: ''
    }
  },
  computed: {
    hasNotes: function () {
      return this.notes && this.notes.length > 0
    },
    hasMoreNotes: function () {
      return this.notes.length > this.noteRange
    }
  },
  methods: {
    onToggleHotNote: function (note) {
      this.selectedNote = note
      this.serverErrorMessage = ''
      this.serverErrors = []
      if (this.entity === 'deal') {
        this.dealServiceToggleNoteHotStatus()
      } else if (this.entity === 'inventory') {
        this.inventoryServiceToggleNoteHotStatus()
      } else if (this.entity === 'service-request') {
        this.serviceRequestServiceToggleNoteHotStatus()
      } else if (this.entity === 'scheduled-payment') {
        this.scheduledPaymentServiceToggleNoteHotStatus()
      } else if (this.entity === 'ro-service') {
        this.roServiceServiceToggleNoteHotStatus()
      } else if (this.entity === 'payment-tracking') {
        this.paymentTrackingServiceToggleNoteHotStatus()
      } else if (this.entity === 'customer') {
        this.customerServiceToggleNoteHotStatus()
      } else if (this.entity === 'wholesale-receivable') {
        this.wholesaleReceivableServiceToggleNoteHotStatus()
      } else if (this.entity === 'parts') {
        this.partsServiceToggleNoteHotStatus()
      }
    },
    showMoreNotes: function () {
      this.noteRange += this.defaultNoteRange
    },
    onRequestDeleteNote: function (note) {
      this.selectedNote = note
      this.isDeleteNoteConfirmationActive = true
    },
    onRequestRestoreNote: function (note) {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.selectedNote = note
      if (this.entity === 'deal') {
        this.dealServiceRestore()
      } else if (this.entity === 'inventory') {
        this.inventoryServiceRestore()
      } else if (this.entity === 'service-request') {
        this.serviceRequestServiceRestore()
      } else if (this.entity === 'scheduled-payment') {
        this.scheduledPaymentServiceRestore()
      } else if (this.entity === 'ro-service') {
        this.roServiceServiceRestore()
      } else if (this.entity === 'payment-tracking') {
        this.paymentTrackingServiceRestore()
      } else if (this.entity === 'customer') {
        this.customerServiceRestore()
      } else if (this.entity === 'wholesale-receivable') {
        this.wholesaleReceivableServiceRestore()
      } else if (this.entity === 'parts') {
        this.partsServiceRestore()
      }
    },
    onNoteDelete: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      if (this.entity === 'deal') {
        this.dealServiceDelete()
      } else if (this.entity === 'inventory') {
        this.inventoryServiceDelete()
      } else if (this.entity === 'service-request') {
        this.serviceRequestServiceDelete()
      } else if (this.entity === 'scheduled-payment') {
        this.scheduledPaymentServiceDelete()
      } else if (this.entity === 'ro-service') {
        this.roServiceServiceDelete()
      } else if (this.entity === 'payment-tracking') {
        this.paymentTrackingServiceDelete()
      } else if (this.entity === 'customer') {
        this.customerServiceDelete()
      } else if (this.entity === 'wholesale-receivable') {
        this.wholesaleReceivableServiceDelete()
      } else if (this.entity === 'parts') {
        this.partsServiceDelete()
      }
    },
    dealServiceDelete: function () {
      this.isSaving = true
      dealService.deleteNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = true
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('reload-deal-list')
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    inventoryServiceDelete: function () {
      this.isSaving = true
      inventoryService.deleteNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = true
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('inventory-changed', {id: this.selectedNote.inventoryId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    serviceRequestServiceDelete: function () {
      this.isSaving = true
      serviceRequestService.deleteNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = true
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('service-request-changed', {id: this.selectedNote.serviceRequestId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    scheduledPaymentServiceDelete: function () {
      this.isSaving = true
      scheduledPaymentService.deleteNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = true
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('payment-schedule-item-changed', {id: this.selectedNote.scheduledPaymentId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    roServiceServiceDelete: function () {
      this.isSaving = true
      roServiceService.deleteNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = true
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('ro-service-item-changed', {id: this.selectedNote.roServiceId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    paymentTrackingServiceDelete: function () {
      this.isSaving = true
      paymentTrackingService.deleteNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = true
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('payment-tracking-item-changed', {id: this.selectedNote.paymentTrackingId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    customerServiceDelete: function () {
      this.isSaving = true
      customerService.deleteNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = true
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('reload-customer-list', {id: this.selectedNote.customerId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    wholesaleReceivableServiceDelete: function () {
      this.isSaving = true
      wholesaleReceivableService.deleteNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = true
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('reload-wholesale-receivable-list', {id: this.selectedNote.wholesaleReceivableId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    partsServiceDelete: function () {
      this.isSaving = true
      partsService.deleteNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = true
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('reload-parts-list', {id: this.selectedNote.partsId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    dealServiceToggleNoteHotStatus: function () {
      this.isSaving = true
      dealService.toggleNoteHotStatus(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isHotNote = !this.selectedNote.isHotNote
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('reload-deal-list')
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    inventoryServiceToggleNoteHotStatus: function () {
      this.isSaving = true
      inventoryService.toggleNoteHotStatus(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isHotNote = !this.selectedNote.isHotNote
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('inventory-changed', {id: this.selectedNote.inventoryId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    serviceRequestServiceToggleNoteHotStatus: function () {
      this.isSaving = true
      serviceRequestService.toggleNoteHotStatus(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isHotNote = !this.selectedNote.isHotNote
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('service-request-changed', {id: this.selectedNote.serviceRequestId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    scheduledPaymentServiceToggleNoteHotStatus: function () {
      this.isSaving = true
      scheduledPaymentService.toggleNoteHotStatus(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isHotNote = !this.selectedNote.isHotNote
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('payment-schedule-item-changed', {id: this.selectedNote.scheduledPaymentId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    roServiceServiceToggleNoteHotStatus: function () {
      this.isSaving = true
      roServiceService.toggleNoteHotStatus(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isHotNote = !this.selectedNote.isHotNote
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('ro-service-item-changed', {id: this.selectedNote.roServiceId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    paymentTrackingServiceToggleNoteHotStatus: function () {
      this.isSaving = true
      paymentTrackingService.toggleNoteHotStatus(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isHotNote = !this.selectedNote.isHotNote
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('payment-tracking-item-changed', {id: this.selectedNote.paymentTrackingId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    customerServiceToggleNoteHotStatus: function () {
      this.isSaving = true
      customerService.toggleNoteHotStatus(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isHotNote = !this.selectedNote.isHotNote
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('reload-customer-list', {id: this.selectedNote.customerId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    wholesaleReceivableServiceToggleNoteHotStatus: function () {
      this.isSaving = true
      wholesaleReceivableService.toggleNoteHotStatus(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isHotNote = !this.selectedNote.isHotNote
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('reload-wholesale-receivable-list', {id: this.selectedNote.wholesaleReceivableId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    partsServiceToggleNoteHotStatus: function () {
      this.isSaving = true
      partsService.toggleNoteHotStatus(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isHotNote = !this.selectedNote.isHotNote
          this.isDeleteNoteConfirmationActive = false
          eventBus.$emit('reload-parts-list', {id: this.selectedNote.partsId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getPinTitle: function (note) {
      return note.pinned ? 'Unpin' : 'Pin to the top'
    },
    onTogglePinned: function (note) {
      this.selectedNote = note
      this.serverErrorMessage = ''
      this.serverErrors = []
      if (this.entity === 'deal') {
        this.dealServiceToggleNotePinned()
      } else if (this.entity === 'inventory') {
        this.inventoryServiceToggleNotePinned()
      } else if (this.entity === 'service-request') {
        this.serviceRequestServiceToggleNotePinned()
      } else if (this.entity === 'scheduled-payment') {
        this.scheduledPaymentServiceToggleNotePinned()
      } else if (this.entity === 'ro-service') {
        this.roServiceServiceToggleNotePinned()
      } else if (this.entity === 'payment-tracking') {
        this.paymentTrackingServiceToggleNotePinned()
      } else if (this.entity === 'customer') {
        this.customerServiceToggleNotePinned()
      } else if (this.entity === 'wholesale-receivable') {
        this.wholesaleReceivableServiceToggleNotePinned()
      } else if (this.entity === 'parts') {
        this.partsServiceToggleNotePinned()
      }
    },
    dealServiceToggleNotePinned: function () {
      this.isSaving = true
      dealService.toggleNotePinned(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.pinned = !this.selectedNote.pinned
          this.notes = response.notes
          eventBus.$emit('reload-deal-list')
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    inventoryServiceToggleNotePinned: function () {
      this.isSaving = true
      inventoryService.toggleNotePinned(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.pinned = !this.selectedNote.pinned
          this.notes = response.notes
          eventBus.$emit('inventory-changed', {id: this.selectedNote.inventoryId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    serviceRequestServiceToggleNotePinned: function () {
      this.isSaving = true
      serviceRequestService.toggleNotePinned(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.pinned = !this.selectedNote.pinned
          this.notes = response.notes
          eventBus.$emit('service-request-changed', {id: this.selectedNote.serviceRequestId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    scheduledPaymentServiceToggleNotePinned: function () {
      this.isSaving = true
      scheduledPaymentService.toggleNotePinned(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.pinned = !this.selectedNote.pinned
          this.notes = response.notes
          eventBus.$emit('payment-schedule-item-changed', {id: this.selectedNote.scheduledPaymentId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    roServiceServiceToggleNotePinned: function () {
      this.isSaving = true
      roServiceService.toggleNotePinned(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.pinned = !this.selectedNote.pinned
          this.notes = response.notes
          eventBus.$emit('ro-service-item-changed', {id: this.selectedNote.roServiceId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    paymentTrackingServiceToggleNotePinned: function () {
      this.isSaving = true
      paymentTrackingService.toggleNotePinned(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.pinned = !this.selectedNote.pinned
          this.notes = response.notes
          eventBus.$emit('payment-tracking-item-changed', {id: this.selectedNote.paymentTrackingId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    customerServiceToggleNotePinned: function () {
      this.isSaving = true
      customerService.toggleNotePinned(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.pinned = !this.selectedNote.pinned
          this.notes = response.notes
          eventBus.$emit('reload-customer-list', {id: this.selectedNote.customerId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    wholesaleReceivableServiceToggleNotePinned: function () {
      this.isSaving = true
      wholesaleReceivableService.toggleNotePinned(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.pinned = !this.selectedNote.pinned
          this.notes = response.notes
          eventBus.$emit('reload-wholesale-receivable-list', {id: this.selectedNote.wholesaleReceivableId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    partsServiceToggleNotePinned: function () {
      this.isSaving = true
      partsService.toggleNotePinned(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.pinned = !this.selectedNote.pinned
          this.notes = response.notes
          eventBus.$emit('reload-parts-list', {id: this.selectedNote.partsId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    dealServiceRestore: function () {
      this.isSaving = true
      dealService.restoreNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = false
          eventBus.$emit('reload-deal-list')
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    inventoryServiceRestore: function () {
      this.isSaving = true
      inventoryService.restoreNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = false
          eventBus.$emit('inventory-changed', {id: this.selectedNote.inventoryId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    serviceRequestServiceRestore: function () {
      this.isSaving = true
      serviceRequestService.restoreNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = false
          eventBus.$emit('service-request-changed', {id: this.selectedNote.serviceRequestId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    scheduledPaymentServiceRestore: function () {
      this.isSaving = true
      scheduledPaymentService.restoreNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = false
          eventBus.$emit('payment-schedule-item-changed', {id: this.selectedNote.scheduledPaymentId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    roServiceServiceRestore: function () {
      this.isSaving = true
      roServiceService.restoreNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = false
          eventBus.$emit('ro-service-item-changed', {id: this.selectedNote.roServiceId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    paymentTrackingServiceRestore: function () {
      this.isSaving = true
      paymentTrackingService.restoreNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = false
          eventBus.$emit('payment-tracking-item-changed', {id: this.selectedNote.paymentTrackingId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    customerServiceRestore: function () {
      this.isSaving = true
      customerService.restoreNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = false
          eventBus.$emit('reload-customer-list', {id: this.selectedNote.customerId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    wholesaleReceivableServiceRestore: function () {
      this.isSaving = true
      wholesaleReceivableService.restoreNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = false
          eventBus.$emit('reload-wholesale-receivable-list', {id: this.selectedNote.wholesaleReceivableId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    partsServiceRestore: function () {
      this.isSaving = true
      partsService.restoreNote(this.selectedNote).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedNote.isDeleted = false
          eventBus.$emit('reload-parts-list', {id: this.selectedNote.partsId})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      this.notes = newValue
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .box {
    background-color: #f6f8fa;
  }

  .pointer {
    cursor: pointer;
  }

</style>

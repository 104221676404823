import mutationTypes from '../mutation-types.js'
import leadSourceService from '../../services/leadSourceService.js'

const state = {
  currentLeadSource: {},
  allLeadSources: []
}

const getters = {
  allLeadSources: state => {
    return state.allLeadSources
  }
}
const mutations = {
  [mutationTypes.SET_LEADSOURCES] (state, leadSource) {
    state.allLeadSources = leadSource
  },
  [mutationTypes.SET_CURRENT_LEADSOURCE] (state, leadSource) {
    state.currentLeadSource = leadSource
  },
  [mutationTypes.SYNC_LEADSOURCE] (state, entity) {
    var applicable = state.allLeadSources.find((x) => x.id === entity.id)
    if (applicable) {
      applicable = entity
    } else {
      state.allLeadSources.push(entity)
    }
  }
}
const actions = {
  setLeadSources ({commit, state}) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.SET_LEADSOURCES, [])
      leadSourceService.allLeadSources()
        .then((data) => {
          commit(mutationTypes.SET_LEADSOURCES, data.results)
          resolve(data.results)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        }
        )
    })
  },
  setLeadSourcescopy ({commit, state}) {
    commit(mutationTypes.SET_LEADSOURCES, [])
    leadSourceService.allLeadSources()
      .then((data) => {
        commit(mutationTypes.SET_LEADSOURCES, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  setCurrentLeadSource ({commit, state}, leadSource) {
    commit(mutationTypes.SET_CURRENT_LEADSOURCE, leadSource)
    leadSourceService.single(leadSource.id)
      .then((data) => {
        commit(mutationTypes.SET_CURRENT_LEADSOURCE, data.leadSource)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  syncLeadSource ({commit, state}, entity) {
    commit(mutationTypes.SYNC_LEADSOURCE, entity)
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}

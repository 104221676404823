class LoggingService {
  enabled = process.env.NODE_ENV !== 'production'
  constructor () {
    if (!LoggingService.instance) {
      LoggingService.instance = this
    }
    return LoggingService.instance
  }
  logWithGroup = function (group, message) {
    if (this.enabled) console.log(group, message)
  }
  log = function (message) {
    if (this.enabled) console.log(message)
  }
  logObject = function (data, fields) {
    const indentationLevel = 3
    if (this.enabled) {
      if (Array.isArray(fields)) {
        console.log(JSON.stringify(data, fields, indentationLevel))
      } else {
        console.log(JSON.stringify(data, null, indentationLevel))
      }
    }
  }
  logWarn = function (message) {
    if (this.enabled) console.log('%c ' + message, 'background: #fff; color: #ff00ff')
  }
  logDanger = function (message) {
    if (this.enabled) console.log('%c ' + message, 'background: #fff; color: #ff0000')
  }
  logInfo = function (message) {
    if (this.enabled) console.log('%c ' + message, 'background: #fff; color: #0000ff')
  }
  logSuccess = function (message) {
    if (this.enabled) console.log('%c ' + message, 'background: #fff; color: #04cc82')
  }
}

const loggingService = new LoggingService()

export default loggingService

<template>
<div class="box appraisal-container">
  <appraisal-section-header title="Appraisal Info" :collapsed="isAppraisalCollapsed" v-on:collapsed-status-changed="onAppraisalCollapsedStatusChanged" :appraisalInfo="true" :isAppraising="isAppraising" v-on:sync-clicked="getMarketAppraisal()"></appraisal-section-header>
  <div class="columns is-mobile is-multiline is-centered" v-if="currentAppraisal && currentAppraisal.valid === true" style="margin-top: 5px;margin-bottom: 20px !important;padding: 0">
    <div class="column is-12">
      <div>
        <label class="is-inline-block is-size-7 has-text-success refresh-status-label" v-if="isAppraising"> Fetching latest available report...</label>
      </div>
    </div>
    <template v-if="displayMode === 'Standard'">
      <div class="column is-2">
        <div class="field">
          <label class="label">Appraised At</label>
          <div class="field">
            {{currentAppraisal.appraisedAt | formatDate}}
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <label class="label">Market Availability</label>
          <div class="field">
            {{new Intl.NumberFormat().format(currentAppraisal.availableInMarket)}}
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <label class="label">Avg. Days on Market</label>
          <div class="field">
            {{new Intl.NumberFormat().format(currentAppraisal.avgDaysOnMarket)}}
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <label class="label">Original Msrp</label>
          <div class="field">
            {{currentAppraisal.originalMsrp | currency}}
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <label class="label">Est. Wholesale Value</label>
          <div class="field" :class="{'has-text-danger': !isPurchaseWithinDealerTolerance('Purchase')}">
            {{currentAppraisal.estWholesaleValue | currency}}
            <i class="fal fa-lg fa-exclamation-triangle tolerance-error-icon has-text-danger" v-if="!isPurchaseWithinDealerTolerance('Purchase')" :title="getToleranceTitle('Purchase')"></i>
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <label class="label">Market Asking Price</label>
          <div class="field" :class="{'has-text-danger': !isAskingWithinDealerTolerance('Asking')}">
            {{currentAppraisal.marketAskingPrice | currency}}
            <i class="fal fa-lg fa-exclamation-triangle tolerance-error-icon has-text-danger" v-if="!isAskingWithinDealerTolerance('Asking')" :title="getToleranceTitle('Asking')"></i>
          </div>
        </div>
      </div>
    </template>
    <template v-if="displayMode === 'Compact'">
      <div class="column is-4">
        <div class="field">
          <label class="label">Appraised At</label>
          <div class="field">
            {{currentAppraisal.appraisedAt | formatDate}}
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Market Availability</label>
          <div class="field">
            {{new Intl.NumberFormat().format(currentAppraisal.availableInMarket)}}
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Avg. Days on Market</label>
          <div class="field">
            {{new Intl.NumberFormat().format(currentAppraisal.avgDaysOnMarket)}}
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Original Msrp</label>
          <div class="field">
            {{currentAppraisal.originalMsrp | currency}}
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Est. Wholesale Value</label>
          <div class="field" :class="{'has-text-danger': !isPurchaseWithinDealerTolerance('Purchase')}">
            {{currentAppraisal.estWholesaleValue | currency}}
            <i class="fal fa-lg fa-exclamation-triangle tolerance-error-icon has-text-danger" v-if="!isPurchaseWithinDealerTolerance('Purchase')" :title="getToleranceTitle('Purchase')"></i>
            <br/>
            <br/>
            <span class="has-text-danger" v-if="!isPurchaseWithinDealerTolerance('Purchase')">Proposed Purchase Cost : {{inventory.purchaseCost | currency}}</span>
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Market Asking Price</label>
          <div class="field" :class="{'has-text-danger': !isAskingWithinDealerTolerance('Asking')}">
            {{currentAppraisal.marketAskingPrice | currency}}
            <i class="fal fa-lg fa-exclamation-triangle tolerance-error-icon has-text-danger" v-if="!isAskingWithinDealerTolerance('Asking')" :title="getToleranceTitle('Asking')"></i>
            <br/>
            <br/>
            <span class="has-text-danger" v-if="!isAskingWithinDealerTolerance('Asking')">Proposed Asking Price : {{inventory.askingPrice | currency}}</span>
          </div>
        </div>
      </div>
      <div class="column is-4" v-if="currentAppraisal && currentAppraisal.lastCheckedAt">
        <div class="field">
          <label class="label">Last Updated</label>
          <div class="field">
            {{currentAppraisal.lastCheckedAt | formatDate}}
          </div>
        </div>
      </div>
      <div class="column is-4"></div>
      <div class="column is-4"></div>
    </template>
  </div>
</div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import appraisalService from '@/services/appraisalService'
import inventoryService from '@/services/inventoryService'
import AppraisalSectionHeader from '@/components/generic/AppraisalSectionHeader'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'InventoryAppraisalControl',
  components: {
    'appraisal-section-header': AppraisalSectionHeader
  },
  props: {
    appraisedValue: {
      type: Object,
      default: null
    },
    inventory: {
      type: Object,
      default: null
    },
    autoUpdateInventory: {
      type: Boolean,
      default: false
    },
    displayMode: {
      type: String,
      default: 'Standard'
    },
    runAutomatically: {
      type: Boolean,
      default: false
    },
    inventoryType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      serverErrors: [],
      serverErrorMessage: '',
      isAppraising: false,
      currentAppraisal: this.appraisedValue,
      queryTimer: undefined,
      isAppraisalCollapsed: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    isCurrentAppraisalExpired: function () {
      if (this.currentAppraisal) {
        let currentReportDate = new Date(this.currentAppraisal.appraisedAt)
        let currentDateTime = new Date()
        let firstDateMs = currentReportDate.getTime()
        let secondDateMs = currentDateTime.getTime()
        var differenceMs = secondDateMs - firstDateMs
        differenceMs = differenceMs / 1000
        // let seconds = Math.floor(differenceMs % 60)
        differenceMs = differenceMs / 60
        // let minutes = Math.floor(differenceMs % 60)
        differenceMs = differenceMs / 60
        let hours = Math.floor(differenceMs % 24)
        // let days = Math.floor(differenceMs / 24)
        // console.log(currentReportDate)
        // console.log(currentDateTime)
        // console.log('Days - ' + days)
        // console.log('Hours - ' + hours)
        // console.log('Minutes - ' + minutes)
        // console.log('Seconds - ' + seconds)
        return hours > 6
      }
      return false
    }
  },
  methods: {
    getToleranceTitle: function (type) {
      if (type === 'Purchase') {
        var defaultTitleValuePurchase = 'The proposed purchase cost does not fall within your dealer\'s purchasing dollar or percentage tolerance value.'
        if (this.currentDealer.purchaseToleranceType === 'Dollar') {
          defaultTitleValuePurchase = 'The proposed purchase cost does not fall within your dealer\'s purchasing dollar limit.'
        }
        if (this.currentDealer.purchaseToleranceType === 'Percentage') {
          defaultTitleValuePurchase = 'The proposed purchase cost does not fall within your dealer\'s purchasing percentage tolerance limit.'
        }
        return defaultTitleValuePurchase
      } else {
        var defaultTitleValueAsking = 'The proposed asking price does not fall within your dealer\'s dollar or percentage tolerance value.'
        if (this.currentDealer.purchaseToleranceType === 'Dollar') {
          defaultTitleValueAsking = 'The proposed asking price does not fall within your dealer\'s dollar limit.'
        }
        if (this.currentDealer.purchaseToleranceType === 'Percentage') {
          defaultTitleValueAsking = 'The proposed asking price does not fall within your dealer\'s percentage tolerance limit.'
        }
        return defaultTitleValueAsking
      }
    },
    isPurchaseWithinDealerTolerance: function (type) {
      let liveValueToCompare = this.inventory ? (type === 'Purchase' ? this.inventory.purchaseCost : this.inventory.askingPrice) : null
      let marketValueToCompare = this.currentAppraisal ? ((type === 'Purchase' ? this.currentAppraisal.estWholesaleValue : this.currentAppraisal.marketAskingPrice)) : null
      if (this.currentDealer.purchaseToleranceType === 'Dollar') {
        let currentDealerTolerance = this.currentDealer.appraisalToleranceValue
        if (currentDealerTolerance && marketValueToCompare && liveValueToCompare) {
          if (liveValueToCompare <= marketValueToCompare) {
            return true
          } else {
            let absValue = Math.abs(marketValueToCompare - liveValueToCompare)
            return absValue <= currentDealerTolerance
          }
        }
        return true
      }
      if (this.currentDealer.purchaseToleranceType === 'Percentage') {
        let currentDealerTolerancePercentage = this.currentDealer.appraisalTolerancePercentage
        if (currentDealerTolerancePercentage && marketValueToCompare && liveValueToCompare) {
          if (liveValueToCompare <= marketValueToCompare) {
            return true
          } else {
            let dollarDifference = Math.abs(liveValueToCompare - marketValueToCompare)
            if (marketValueToCompare > 0) {
              let percentageVariance = (dollarDifference / marketValueToCompare) * 100
              return percentageVariance <= currentDealerTolerancePercentage
            }
            return false
          }
        }
        return true
      }
      return true
    },
    purchaseVariance: function () {
      let liveValueToCompare = this.inventory ? this.inventory.purchaseCost : null
      let marketValueToCompare = this.currentAppraisal ? this.currentAppraisal.estWholesaleValue : null
      if (marketValueToCompare && marketValueToCompare > 0 && liveValueToCompare && liveValueToCompare > 0) {
        return Math.abs(marketValueToCompare - liveValueToCompare)
      }
      return null
    },
    askingVariance: function () {
      let liveValueToCompare = this.inventory ? this.inventory.askingPrice : null
      let marketValueToCompare = this.currentAppraisal ? this.currentAppraisal.marketAskingPrice : null
      if (marketValueToCompare && marketValueToCompare > 0 && liveValueToCompare && liveValueToCompare > 0) {
        return Math.abs(marketValueToCompare - liveValueToCompare)
      }
      return null
    },
    isAskingWithinDealerTolerance: function (type) {
      let liveValueToCompare = this.inventory ? (type === 'Purchase' ? this.inventory.purchaseCost : this.inventory.askingPrice) : null
      let marketValueToCompare = this.currentAppraisal ? ((type === 'Purchase' ? this.currentAppraisal.estWholesaleValue : this.currentAppraisal.marketAskingPrice)) : null
      if (this.currentDealer.purchaseToleranceType === 'Dollar') {
        let currentDealerTolerance = this.currentDealer.appraisalToleranceValue
        if (currentDealerTolerance && marketValueToCompare && liveValueToCompare) {
          if (liveValueToCompare >= marketValueToCompare) {
            let absValue = Math.abs(marketValueToCompare - liveValueToCompare)
            return absValue <= currentDealerTolerance
          } else {
            let absValue = Math.abs(liveValueToCompare - marketValueToCompare)
            return absValue <= currentDealerTolerance
          }
        }
        return true
      }
      if (this.currentDealer.purchaseToleranceType === 'Percentage') {
        let currentDealerTolerancePercentage = this.currentDealer.appraisalTolerancePercentage
        if (currentDealerTolerancePercentage && marketValueToCompare && liveValueToCompare) {
          if (liveValueToCompare >= marketValueToCompare) {
            let dollarDifference = Math.abs(liveValueToCompare - marketValueToCompare)
            if (marketValueToCompare > 0) {
              let percentageVariance = (dollarDifference / marketValueToCompare) * 100
              return percentageVariance <= currentDealerTolerancePercentage
            }
            return true
          } else {
            let dollarDifference = Math.abs(marketValueToCompare - liveValueToCompare)
            if (marketValueToCompare > 0) {
              let percentageVariance = (dollarDifference / marketValueToCompare) * 100
              return percentageVariance <= currentDealerTolerancePercentage
            }
            return false
          }
        }
        return true
      }
      return true
    },
    getMarketAppraisal: function () {
      let vinNo = this.inventory.vinNo
      let mileage = this.inventory.mileage
      let trim = this.inventory.trim
      let condition = this.inventory.condition
      let msrp = this.inventory.msrp
      if (this.isAppraising === false && vinNo !== '' && trim !== '') {
        this.isAppraising = true
        appraisalService.appraise(vinNo, trim, mileage, condition, msrp).then(response => {
          this.isAppraising = false
          if (response.errors) {
            this.serverErrors = response.errors
            this.serverErrorMessage = response.message
            this.isAppraising = false
            this.$emit('on-appraisal-failed')
          } else {
            this.currentAppraisal = response
            this.currentAppraisal.isGood = this.isPurchaseWithinDealerTolerance('Purchase') && this.isAskingWithinDealerTolerance('Asking')
            this.currentAppraisal.livePurchasePrice = this.inventory.purchaseCost
            this.currentAppraisal.marketPurchasePrice = this.currentAppraisal.estWholesaleValue
            this.currentAppraisal.liveAskingPrice = this.inventory.askingPrice
            this.currentAppraisal.marketAskingPrice = this.currentAppraisal.marketAskingPrice
            this.currentAppraisal.badPurchasePricing = !this.isPurchaseWithinDealerTolerance('Purchase')
            this.currentAppraisal.badAskingPricing = !this.isAskingWithinDealerTolerance('Asking')
            this.currentAppraisal.purchaseVariance = this.purchaseVariance()
            this.currentAppraisal.askingVariance = this.askingVariance()
            this.currentAppraisal.lastCheckedAt = this.$options.filters.formatDate(new Date())
            if (this.currentAppraisal.lastCheckedAt === 'Invalid date') {
              this.currentAppraisal.lastCheckedAt = new Date()
            }
            this.$emit('on-new-appraisal', this.currentAppraisal)
            if (this.inventory && this.autoUpdateInventory) {
              this.currentAppraisal.inventoryId = this.inventory.id
              inventoryService.addAppraisal(this.currentAppraisal)
            }
          }
        }).catch((error) => {
          this.$emit('on-appraisal-failed')
          this.isAppraising = false
          this.serverErrorMessage = error.message
        })
      } else {
        this.$emit('on-appraisal-failed')
      }
    },
    onAppraisalCollapsedStatusChanged: function (newValue) {
      this.isAppraisalCollapsed = newValue
    }
  },
  watch: {
    inventory: function (newValue, oldValue) {
      if (oldValue) {
        if (newValue && (oldValue.vinNo !== newValue.vinNo || oldValue.mileage !== newValue.mileage || oldValue.trim !== newValue.trim || oldValue.condition !== newValue.condition)) {
          window.clearTimeout(this.queryTimer)
          let that = this
          this.queryTimer = window.setTimeout(function () {
            that.getMarketAppraisal()
          }, 2000)
        }
      }
    }
  },
  mounted: function () {
    // If there are no appraisals for the vehicle then run one
    if ((this.inventory && !this.appraisedValue) || this.runAutomatically) {
      this.getMarketAppraisal()
    }
    this.isAppraisalCollapsed = this.inventoryType === 'New'
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .tolerance-error-icon {
    margin-left:5px;
  }

  .refresh-status-label {
    margin-left:1em;
  }
</style>

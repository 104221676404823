<template>
  <section>
    <div class="columns is-mobile">
      <div class="column">
        <div class="field">
          <label class="label">Time To Deliver</label>
          <div class="field">
            <span class="tag is-primary clickable" :class="getTtdTagClass" @click="onShowDetailedHistory" v-tooltip="{content: 'click to view history'}">{{ttdTitle}}</span>
            <span class="icon clickable" @click="onEdit" v-if="hasFeatureAccess(timeToDeliverModifyPermission) && showEditIcon">
              <i class="fal fa-pencil"></i>
            </span>
          </div>
        </div>
      </div>
    </div>

    <portal to="global-modal-portal" :disabled="!usePortal" v-if="showDetailedHistoryActive">
      <b-modal :active.sync="showDetailedHistoryActive" :width="640" scrollable :has-modal-card="true">
        <div class="modal-card" style="width: 450px;">
          <header class="modal-card-head flex">
            <div class="modal-card-title">Time To Deliver History</div>
          </header>
          <section class="modal-card-body has-background-grey-lighter">
            <div class="notification is-info is-flex" v-if="!hasHistory">
              <div><i class="fas fa-info-circle is-info fa-2x"></i></div>
              <div class="ml-10">There are no logged TTD events.</div>
            </div>
            <table class="table is-bordered is-narrow is-hoverable is-fullwidth" v-if="hasHistory">
              <thead>
                <th>Days</th>
                <th>Note</th>
                <th>Logged By</th>
                <th>Logged At</th>
              </thead>
              <tbody>
                <tr v-for="event in timeToDeliverHistory" :key="event.id">
                  <td>{{event.value}}</td>
                  <td>{{event.eventType}}</td>
                  <td>{{event.createdBy}}</td>
                  <td>{{event.createdAt | formatDateOnly}}</td>
                </tr>
              </tbody>
            </table>
          </section>
          <footer class="modal-card-foot">
              <button type="button" class="button is-success" @click="onCloseDetailedHistory()">Close</button>
          </footer>
        </div>
      </b-modal>
    </portal>

    <portal to="global-modal-portal" :disabled="!usePortal" v-if="isEditActive">
      <b-modal :active.sync="isEditActive" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width: 350px;">
          <header class="modal-card-head flex">
            <div class="modal-card-title">Edit Time To Deliver</div>
          </header>
          <section class="modal-card-body has-background-grey-lighter">
            <form class="box">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
              <div class="columns is-multiline">
                <div class="column is-12 field">
                  <label class="label">Days<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control">
                    <input
                      name="ttd"
                      data-vv-as="time to deliver"
                      v-validate="{'required': true, 'min_value': 0, 'numeric': true}"
                      :class="{'input': true, 'is-danger': errors.has('ttd') }"
                      type="number"
                      placeholder="#####"
                      v-model="ttd">
                    <span v-show="errors.has('ttd')" class="help is-danger">
                      <i v-show="errors.has('ttd')" class="fas fa-exclamation-triangle"></i>{{ errors.first('ttd') }}
                    </span>
                  </div>
                </div>
                <div class="column is-12 field">
                  <label class="label">Note<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control">
                    <textarea
                      name="note"
                      data-vv-as="note"
                      v-validate="{'required': true}"
                      :class="{'textarea': true, 'is-danger': errors.has('note')}"
                      type="text"
                      v-model="note">
                    </textarea>
                    <span v-show="errors.has('note')" class="help is-danger"><i v-show="errors.has('note')" class="fas fa-exclamation-triangle"></i> {{ errors.first('note') }}</span>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="onSave()" type="button" :class="{'is-loading': isSaving }">Save</button>
            <button class="button is-danger" @click="onCloseEdit()" type="button">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </section>
</template>

<script>

import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import dealService from '@/services/dealService'
import eventBus from '@/eventBus'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'DealTimeToDeliverComponent',
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    timeToDeliverHistory: {
      type: Array,
      default: null
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    currentValue: {
      type: Number,
      default: null
    },
    showEditIcon: {
      type: Boolean,
      default: true
    },
    dealId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      showDetailedHistoryActive: false,
      instanceId: this.$uuid.v4(),
      serverErrorMessage: '',
      serverErrors: [],
      isEditActive: false,
      ttd: this.currentValue,
      timeToDeliverModifyPermission: 'deal.time-to-deliver.modify',
      isSaving: false,
      note: ''
    }
  },
  methods: {
    onShowDetailedHistory: function () {
      this.showDetailedHistoryActive = true
    },
    onCloseDetailedHistory: function () {
      this.showDetailedHistoryActive = false
    },
    onEdit: function () {
      this.isEditActive = true
    },
    onCloseEdit: function () {
      this.isEditActive = false
    },
    onSave: function () {
      this.$validator.validate().then((result) => {
        if (result) {
          this.serverErrorMessage = ''
          this.serverErrors = []
          this.isSaving = true
          let dealTimeToDeliverEventModel = {dealId: this.dealId, value: this.ttd, eventType: this.note}
          dealService.updateTimeToDeliver(dealTimeToDeliverEventModel).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              eventBus.$emit('reload-deal')
              eventBus.$emit('reload-deal-list')
              this.onCloseEdit()
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      })
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess']),
    hasHistory: function () {
      return this.timeToDeliverHistory && this.timeToDeliverHistory.length > 0
    },
    firstEvent: function () {
      if (this.hasHistory) {
        return this.timeToDeliverHistory[this.timeToDeliverHistory.length - 1]
      }
      return '-'
    },
    latestEvent: function () {
      if (this.timeToDeliverHistory) {
        return this.timeToDeliverHistory[0]
      }
      return '-'
    },
    ttdTitle: function () {
      if (this.ttd === null || this.ttd === undefined) return '-'
      return this.ttd > 0 ? this.ttd + ' days' : 'SPOT'
    },
    getTtdTagClass: function () {
      let iconClass = this.ttdTitle === 'SPOT' ? 'is-primary' : 'is-info'
      return iconClass
    }
  },
  watch: {
    currentValue: function (newValue, oldValue) {
      if (newValue) {
        this.ttd = newValue
      }
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal.is-active .modal-card-foot {
    padding: 10px !important
  }

  .modal-card-head, .modal-card-body{
    padding: 10px !important
  }

  .modal-card .modal-card-body {
    position:relative;
    max-height: 65vh; /* max 80% of the viewport height */
    max-width: 960px; /* max 80% of the viewport height */
    height: unset !important;
    overflow-y: scroll;
  }

  .table {
    background-color: #f8fafc !important;
    color: #6c6c7a;
    font-size: 0.7rem;
    width: 100%;
  }

</style>

<template>
  <div class="is-inline-block" v-if="!isDocumentUploadActive">
    <div v-if="isBookingWizard && !listMode">
      <button type="button" class="button is-small mr-10" :class="{'is-loading': this.isUploadingFile}" @click.stop.prevent="onUploadDocuments()" :title="actionTitle">
        <b-icon :icon="captureIcon" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <div class="quick-action" v-else-if="!listMode" >
      <button type="button" class="button clickable mr-10" :class="{'is-loading': this.isUploadingFile}" @click.stop.prevent="onUploadDocuments()" :title="actionTitle">
        <b-icon :icon="captureIcon" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <span :class="{'clickable': true, 'icon fa-spin': this.isUploadingFile}" class="mr-10" @click.stop.prevent="onUploadDocuments()" :title="actionTitle" v-else>
      <b-icon :icon="captureIcon" custom-size="fa-lg"></b-icon>
    </span>
  </div>
  <b-modal :active.sync="isDocumentUploadActive" scroll="keep" :has-modal-card="true" v-else-if="isDocumentUploadActive">
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Deal Documents</p>
      </header>
      <section class="modal-card-body" style="width: 960px !important; height: 65vh !important; z-index: 300000 !important;">
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <form @submit.prevent="validateBeforeSubmit">
          <file-selector
          v-model="vehicleCostDocumentFiles"
          label="Vehicle Cost"
          :enabled="!viewOnly"
          :required="false"
          :multiple="true"
          :isBoxed="false"
          :usePortalViewer="false"
          @uploadStarted="uploadStarted"
          @uploadComplete="uploadComplete"
          style="margin-left: 1.3em;">
          </file-selector>
          <file-selector
          v-model="cdkRecapDocumentFiles"
          label="CDK Recap"
          :required="false"
          :enabled="!viewOnly"
          :multiple="true"
          :isBoxed="false"
          :usePortalViewer="false"
          @uploadStarted="uploadStarted"
          @uploadComplete="uploadComplete"
          style="margin-left: 1.3em;">
          </file-selector>
          <file-selector
          v-model="cdkWashoutDocumentFiles"
          label="CDK Washout"
          :required="false"
          :enabled="!viewOnly"
          :multiple="true"
          :isBoxed="false"
          :usePortalViewer="false"
          @uploadStarted="uploadStarted"
          @uploadComplete="uploadComplete"
          style="margin-left: 1.3em;">
          </file-selector>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button type="button" :disabled="saveDisabled" class="button is-primary" :class="{'is-loading': isSaving }"  @click="onSave()" v-if="!viewOnly">Save</button>
        <button type="button" class="button is-danger" @click="onClose">Cancel</button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
// import eventBus from '@/eventBus'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import dealService from '@/services/dealService'
import FileSelector from '@/components/generic/FileSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import { createNamespacedHelpers } from 'vuex'
import _ from 'lodash'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'DealDocumentCapture',
  mixins: [utilitiesMixin, dealInventoryStyleClasses, validateBeforeSubmitMixin],
  inject: {
  },
  components: {
    'file-selector': FileSelector,
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    isBookingWizard: {
      type: Boolean,
      default: false
    },
    listMode: {
      type: Boolean,
      default: false
    },
    captureIcon: {
      type: String,
      default: 'fal fa-file'
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isDocumentUploadActive: false,
      vehicleCostDocumentFiles: [],
      serverErrors: [],
      serverErrorMessage: '',
      isUploadingFile: false,
      fileUploadCount: 0,
      selectedDocumentType: null,
      replaceExisting: false,
      cdkRecapDocumentFiles: [],
      cdkWashoutDocumentFiles: []
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    hasDocuments: function () {
      if (!this.currentDeal || !this.currentDeal.attachments) {
        return false
      }
      var primaryImages = this.currentDeal.attachments
      return primaryImages.length > 0
    },
    getDocuments: function () {
      if (!this.currentDeal || !this.currentDeal.attachments) {
        return []
      }
      var primaryImages = this.currentDeal.attachments
      var result = []
      primaryImages.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        x.fileType = this.getFileType(x.fileLocation)
        result.push(x)
      })
      return result
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    vehicleCostDocuments: function () {
      if (this.hasDocuments) {
        let matchedDocuments = this.currentDeal.attachments.filter((x) => x.classType === 'VehicleCost')
        return this.formatDocuments(matchedDocuments)
      }
      return []
    },
    hasVehicleCostDocuments: function () {
      return this.vehicleCostDocuments.length > 0
    },
    cdkRecapDocuments: function () {
      if (this.hasDocuments) {
        let matchedDocuments = this.currentDeal.attachments.filter((x) => x.classType === 'CdkRecap')
        return this.formatDocuments(matchedDocuments)
      }
      return []
    },
    hasCdkRecapDocuments: function () {
      return this.cdkRecapDocuments.length > 0
    },
    fileUploadLabel: function () {
      let result = 'File'
      if (this.allowedDocumentOption) {
        let allowedOption = this.allowedDocumentOption
        let allowedDocumentType = this.definedTypes.dealDocumentTypes.options.find((x) => x.altName === allowedOption)
        result = allowedDocumentType.name
      }
      return result
    },
    actionTitle: function () {
      if (this.viewOnly) {
        return 'Click to view Vehicle Cost, CDK Recap and CDK Washout documents.'
      }
      return 'Click to upload Vehicle Cost, CDK Recap and CDK Washout documents.'
    },
    saveDisabled: function () {
      let activeCdkRecapDocuments = this.cdkRecapDocumentFiles.filter(x => x.status !== 'Deleted')
      let activeVehicleCostDocuments = this.vehicleCostDocumentFiles.filter(x => x.status !== 'Deleted')
      let activeCdkWashoutDocuments = this.cdkWashoutDocumentFiles.filter(x => x.status !== 'Deleted')
      if (this.isUploadingFile) {
        return true
      } else if (activeCdkRecapDocuments === 0 && activeVehicleCostDocuments.length === 0 && activeCdkWashoutDocuments.length === 0) {
        return true
      }
      return false
    },
    cdkWashoutDocuments: function () {
      if (this.hasDocuments) {
        let matchedDocuments = this.currentDeal.attachments.filter((x) => x.classType === 'CdkWashout')
        return this.formatDocuments(matchedDocuments)
      }
      return []
    },
    hasCdkWashoutDocuments: function () {
      return this.cdkWashoutDocuments.length > 0
    }
  },
  methods: {
    onUploadDocuments: function () {
      this.vehicleCostDocumentFiles = _.cloneDeep(this.vehicleCostDocuments)
      this.cdkRecapDocumentFiles = _.cloneDeep(this.cdkRecapDocuments)
      this.cdkWashoutDocumentFiles = _.cloneDeep(this.cdkWashoutDocuments)
      this.isDocumentUploadActive = true
    },
    onClose: function () {
      this.vehicleCostDocumentFiles = []
      this.cdkRecapDocumentFiles = []
      this.cdkWashoutDocumentFiles = []
      this.isDocumentUploadActive = false
    },
    onSave: function () {
      var updateDeal = {
        dealId: this.currentDeal.id,
        attachments: []
      }
      if (this.vehicleCostDocumentFiles.length > 0) {
        var uploadVehicleCostFiles = this.vehicleCostDocumentFiles.map((x) => {
          return {
            id: isNaN(x.id) ? 0 : x.id,
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'VehicleCost',
            uniqueId: x.uniqueId,
            status: x.status
          }
        })
        updateDeal.attachments.push(...uploadVehicleCostFiles)
      }
      if (this.cdkRecapDocumentFiles.length > 0) {
        var uploadCdkRecapFiles = this.cdkRecapDocumentFiles.map((x) => {
          return {
            id: isNaN(x.id) ? 0 : x.id,
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CdkRecap',
            uniqueId: x.uniqueId,
            status: x.status
          }
        })
        updateDeal.attachments.push(...uploadCdkRecapFiles)
      }
      if (this.cdkWashoutDocumentFiles.length > 0) {
        var uploadCdkWashoutFiles = this.cdkWashoutDocumentFiles.map((x) => {
          return {
            id: isNaN(x.id) ? 0 : x.id,
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CdkWashout',
            uniqueId: x.uniqueId,
            status: x.status
          }
        })
        updateDeal.attachments.push(...uploadCdkWashoutFiles)
      }
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      updateDeal.replaceExistingDocument = this.replaceExisting
      let that = this
      dealService.syncDealAttachments(updateDeal).then(response => {
        that.isSaving = false
        if (response.errors) {
          that.serverErrors = response.errors
        } else {
          that.successToast('Success! uploaded successfully')
          that.documentFiles = []
          // eventBus.$emit('deal-documents-uploaded', response.deal)
          this.$emit('deal-document-uploaded', response.dealAttachments)
          that.resetModal()
          that.isDocumentUploadActive = false
        }
      }).catch((error) => {
        that.failedToast('Oops! Something went wrong')
        that.serverErrorMessage = error.message
        that.isSaving = false
      })
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    resetModal: function () {
      this.uploadDocuments = false
      this.selectedDocumentType = null
      this.replaceExisting = false
    },
    formatDocuments: function (documents) {
      var result = []
      documents.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        x.fileType = this.getFileType(x.fileLocation)
        result.push(x)
      })
      return result
    }
  },
  watch: {
  },
  mounted: function () {
  }
}
</script>

<style scoped>
</style>

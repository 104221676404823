<template>
    <div class="is-inline-block">
      <div v-if="currentInventory && !sendTitleReassignmentControlActive">
          <div :id="instanceId" :name="instanceId" class="quick-action">
              <button class="button" @click.stop.prevent="openModal(false)" :title="getIconTitle()">
                <i data-type="R" class="icon fal fa-ballot fa-lg" :class="getColorClassByAgreementStatus(currentInventory.titleReassignmentStatusId)"/>
              </button>
          </div>
          <b-dropdown
            ref="actionDropdown"
            aria-role="list"
            class="title-reassignment-control"
            v-if="isSignedOrComplete"
            :scrollable="true"
            :close-on-click="false"
            :mobile-modal="true"
            :expanded="true"
            :autoClose="true">
                <b-dropdown-item aria-role="listitem" @click="openModal(true)">Send Again</b-dropdown-item>
                <b-dropdown-item aria-role="listitem" @click="onDowloadLastSignedDocument">Download Last Signed</b-dropdown-item>
          </b-dropdown>
        </div>
        <portal to="global-modal-portal" :order="1" v-else>
            <b-modal :active.sync="sendTitleReassignmentControlActive" scroll="keep" :has-modal-card="true" class="title-reassignment-send-modal">
                <div class="modal-card">
                    <form @submit.prevent="onValidate" data-vv-scope="recipient-form">
                        <header class="modal-card-head">
                            <p class="modal-card-title">Send title reassignment for {{ currentInventory ? currentInventory.stockNo : '' }}</p>
                        </header>
                        <section class="modal-card-body view-container" style="padding:12px" v-if="!isLoading">
                          <b-tabs size="is-small" :animated="false" type="is-toggle" v-model="tabIndex">
                            <b-tab-item label="Definition" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
                              <div class="columns is-multiline is-mobile">
                                  <div id="entity-selector" class="column is-12">
                                      <supplier-selector
                                        v-if="isSupplierRecipient"
                                        class="is-fullwidth"
                                        v-validate="'required'"
                                        label="Wholesaler/Dealer"
                                        validateAs="wholesaler/dealer"
                                        placeholder="Type to search for a loaded wholesaler/dealer"
                                        v-model="selectedRecipientSupplier"
                                        :required="true"
                                        :useValidator="thisValidator"
                                        data-vv-scope="recipient-form"
                                        :isDisabled="true"
                                        :allowEdit="false">
                                      </supplier-selector>

                                      <customer-selector
                                        v-if="isCustomerRecipient"
                                        label="Customer"
                                        validateAs="customer"
                                        class="is-fullwidth"
                                        v-validate="'required'"
                                        v-model="selectedRecipientCustomer"
                                        :selectedDealer="selectedDealer"
                                        :required="true"
                                        :showNewAction="false"
                                        :useValidator="thisValidator"
                                        data-vv-scope="recipient-form"
                                        :isDisabled="true"
                                        :allowEdit="false">
                                      </customer-selector>
                                  </div>
                                  <div class="column is-12" v-if="recipientSelected">
                                      <div class="field">
                                      <label class="label">Recipients<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                                      <multiselect
                                          :multiple="true"
                                          :taggable="true"
                                          v-validate="{'required': true}"
                                          data-vv-scope="recipient-form"
                                          data-vv-as="recipient"
                                          data-vv-validate-on="input|close"
                                          :class="{'is-danger': errors.has('recipient-form.recipients') }"
                                          @tag="onRecipientAdded"
                                          name="recipients"
                                          label="fullName"
                                          trackBy="email"
                                          tagPlaceholder="Enter to add email address"
                                          v-model="selectedRecipients"
                                          :options="selectedRecipients"
                                          :disabled="false">
                                      </multiselect>
                                      <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                                      </div>
                                  </div>
                                  <div class="column is-12" v-if="recipientSelected">
                                      <div class="field">
                                          <label class="label">Subject</label>
                                          <div class="control">
                                          <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                                          </div>
                                      </div>
                                  </div>
                                  <div class="column is-12" v-if="recipientSelected">
                                      <div class="field">
                                          <label class="label">Message (optional)</label>
                                          <div class="control">
                                            <textarea rows="10"  name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                            </b-tab-item>
                            <b-tab-item label="History" icon-pack="fas" icon="fas fa-history fa-sm">
                              <section>
                                <b-table
                                  class="lists"
                                  :data="titleReassignmentSendHistory">
                                  <template slot-scope="props">
                                    <b-table-column field="status" label="Status" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                                      <i data-type="B" class="icon fal fa-ballot fa-lg list-icon" :class="getColorClassByAgreementStatus(props.row.signingStatus)" :title="'Title Reassignment Agreement Status: ' + getDescriptionByAgreementStatus(props.row.signingStatus)"/>
                                      <a target="live-doc-download" :href="buildTitleReassignmentAgreementUrl(props.row.dealFileLocation)" title="Click to download the last signed title reassignment agreement." v-if="props.row.dealFileLocation && (props.row.signingStatus == 10 || props.row.signingStatus === 3)"><i class="icon fal fa-cloud-download fa-lg list-icon has-text-success"/></a>
                                    </b-table-column>
                                    <b-table-column field="email" label="Recipients" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                                      {{ props.row.destinationAddresses }}
                                    </b-table-column>
                                    <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                                      {{ formateSignatureType(props.row.type) }}
                                    </b-table-column>
                                    <b-table-column field="date" label="Date" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                                      {{ props.row.sentAt | formatDateOnly }}
                                    </b-table-column>
                                    <b-table-column field="time" label="Time" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                                      {{ convertTimeString(props.row.sentAt, props.row.signingStatus) }}
                                    </b-table-column>
                                    <b-table-column field="id" label="Actions">
                                      <a v-if="props.row.cancellationSent === false" @click.stop.prevent="onCancelTitleReassignmentAgreement(props.row)"><i class="icon fa-fw fal fa-times-circle fa-lg" :class="{'has-text-danger': !isCancellationDisabled(props.row), 'has-text-grey': isCancellationDisabled(props.row)}" :title="isCancellationDisabled(props.row) ? 'Request cannot be cancelled at this point' : 'Cancel Request'"/></a>
                                      <a v-else><i class="icon fa-fw fal fa-times-circle fa-lg has-text-success" title='Cancellation request has been made'/></a>
                                      <a v-if="props.row.reminderSent === false" @click.stop.prevent="onTitleReassignmentAgreementReminder(props.row)"><i class="icon fa-fw fal fa-redo fa-lg" :class="{'has-text-link': !isReminderDisabled(props.row), 'has-text-grey': isReminderDisabled(props.row)}" :title="isReminderDisabled(props.row) ? 'Reminder cannot be sent at this point' : 'Send Reminder'"/></a>
                                      <a v-else><i class="icon fa-fw fal fa-envelope fa-lg has-text-success" title='Reminder has been sent'/></a>
                                    </b-table-column>
                                  </template>

                                  <template slot="empty">
                                    <section class="section">
                                      <div class="has-text-centered">
                                        <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                                        <p>No title reassignment documents has been sent for this item.</p>
                                      </div>
                                    </section>
                                  </template>

                                </b-table>
                              </section>
                            </b-tab-item>
                          </b-tabs>
                        </section>
                        <section class="modal-card-body" v-else>
                            <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
                        </section>
                        <footer class="modal-card-foot">
                            <button type="button" class="button is-primary" @click="onPreview()" :class="{'is-loading': isPreviewDownloading}">Preview</button>
                            <button class="button is-primary" :class="{'is-loading': isSaving }" type="submit" :disabled="!formDataValid" v-if="wasPreviewed">Send</button>
                            <button class="button is-danger" @click="onCancel()" type="button">Cancel</button>
                        </footer>
                    </form>
                </div>
            </b-modal>
        </portal>
        <pdf-modal
          :pdfFile="titleReassignmentDocument"
          :usePortal="true"
          :portalOrder="2"
          v-on:on-pdf-downloaded="onPreviewed"
          v-on:on-pdf-closed="onPreviewClosed"
          v-if="titleReassignmentDocument && titleReassignmentDocument.src">
        </pdf-modal>
        <send-reminder-control
          v-on:on-hide-reminder-control="onHideReminderModal"
          :showConfirmationModal="showConfirmReminder"
          :signatureRequest="signatureRequest"
          v-on:send-reminder="sendReminder">
        </send-reminder-control>
        <cancel-signature-request-control
          v-on:on-hide-cancel-request-control="onHideCancellationRequestModal"
          :showRequestCancellationModal="showCancelRequest"
          :signatureRequest="cancelSignatureRequest"
          v-on:send-cancellation-request="sendCancellationRequest">
        </cancel-signature-request-control>
      </div>
</template>

<script>
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import { createNamespacedHelpers } from 'vuex'
import inventoryService from '@/services/inventoryService'
import documentSigningRequestService from '@/services/documentSigningRequestService'
import formattingMixin from '@/mixins/inventory/formatting'
import utilitiesMixin from '@/mixins/generic/utilities'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import CustomerSelector from '@/components/generic/CustomerSelector'
import SupplierSelector from '@/components/generic/SupplierSelector'
import SendReminderControl from '@/components/generic/SendReminderControl'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'
import CancelSignatureRequestControl from '@/components/generic/CancelSignatureRequestControl'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  mixins: [validateBeforeSubmitMixin, formattingMixin, utilitiesMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'customer-selector': CustomerSelector,
    'supplier-selector': SupplierSelector,
    'send-reminder-control': SendReminderControl,
    'cancel-signature-request-control': CancelSignatureRequestControl,
    'pdf-modal': PdfModalDisplayComponent
  },
  name: 'SendTradeInTitleReassignmentDocument',
  props: {
    inventoryItem: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isSaving: false,
      isLoading: false,
      wasPreviewed: false,
      isPreviewDownloading: false,
      instanceId: this.$uuid.v4(),
      name: null,
      serverErrors: [],
      serverErrorMessage: '',
      sendTitleReassignmentControlActive: false,
      selectedRecipientCustomer: null,
      selectedRecipientSupplier: null,
      selectedDealer: null,
      selectedRecipients: [],
      currentInventory: null,
      selectedRecipientsMobile: [],
      mobileInvalidFormat: '',
      optionalMessage: '',
      overridingSubject: '',
      titleReassignmentDocument: null,
      isPdfViewerActive: false,
      previewUrl: '',
      tabIndex: 0,
      titleReassignmentSendHistory: [],
      signatureRequest: null,
      showConfirmReminder: false,
      sendingReminder: false,
      cancelSignatureRequest: null,
      showCancelRequest: false,
      sendingCancellationRequest: false
    }
  },
  computed: {
    ...mapUserGetters(['currentUser']),
    ...mapConfigGetters(['definedTypes']),
    ...mapDealerState(['currentDealer']),
    validStatus: function () {
      if (this.currentInventory && this.currentInventory.purchasedFromName && this.currentInventory.purchasedFromId) {
        return true
      }
      return false
    },
    recipientSelected: function () {
      if (this.currentInventory) {
        if (this.currentInventory.purchasedFromType === 'Wholesaler' && this.currentInventory.purchasedFromName && this.selectedRecipientSupplier) {
          return true
        }
        if (this.currentInventory.purchasedFromType === 'Individual' && this.currentInventory.purchasedFromName && this.selectedRecipientCustomer) {
          return true
        }
      }
      return false
    },
    isCustomerRecipient: function () {
      if (this.currentInventory) {
        if (this.currentInventory.purchasedFromType === 'Individual' && this.currentInventory.purchasedFromName) {
          return true
        }
      }
      return false
    },
    isSupplierRecipient: function () {
      if (this.currentInventory) {
        if (this.currentInventory.purchasedFromType === 'Wholesaler' && this.currentInventory.purchasedFromName) {
          return true
        }
      }
      return false
    },
    formDataValid: function () {
      if ((this.selectedRecipientCustomer || this.selectedRecipientSupplier) && this.selectedRecipients && this.selectedRecipients.length > 0) {
        return true
      }
      return false
    },
    currentUserName: function () {
      if (this.currentUser) {
        return this.currentUser.fullName
      }
      return ''
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    isSignedOrComplete: function () {
      return this.currentInventory.titleReassignmentStatusId === 10 || this.currentInventory.titleReassignmentStatusId === 3
    },
    lastSignedAgreement: function () {
      if (this.currentInventory && this.isSignedOrComplete) {
        var allSignedAgreements = this.currentInventory.attachments.filter((x) => x.classType === 'TradeTitleReassignment' && x.status === 'Active')
        if (allSignedAgreements.length > 0) {
          return allSignedAgreements[allSignedAgreements.length - 1].fileLocation
        }
      }
      return ''
    }
  },
  methods: {
    onDowloadLastSignedDocument: function () {
      if (this.lastSignedAgreement === '') return
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.lastSignedAgreement)
    },
    onShowReminderModal: function () {
      this.showConfirmReminder = true
    },
    onHideReminderModal: function () {
      this.showConfirmReminder = false
      this.signatureRequest = null
    },
    sendReminder: function () {
      if (!this.sendingReminder) {
        this.sendingReminder = true
        documentSigningRequestService.sendReminder(this.signatureRequest).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.signatureRequest.reminderSent = true
            this.successToast('Signature request reminder has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideReminderModal()
          this.sendingReminder = false
        })
      }
    },
    onShowCancellationRequestModal: function () {
      this.showCancelRequest = true
    },
    onHideCancellationRequestModal: function () {
      this.showCancelRequest = false
      this.cancelSignatureRequest = null
    },
    sendCancellationRequest: function () {
      if (!this.sendingCancellationRequest) {
        this.sendingCancellationRequest = true
        documentSigningRequestService.cancelSignatureRequest(this.cancelSignatureRequest.signatureRequestId).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.cancelSignatureRequest.cancellationSent = true
            this.successToast('Signature cancellation request has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideCancellationRequestModal()
          this.sendingCancellationRequest = false
        })
      }
    },
    isCancellationDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    isReminderDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    convertTimeString: function (date, status) {
      if (status !== 0) {
        var d = new Date(date)
        var hour = '' + d.getHours()
        var minute = '' + d.getMinutes()

        if (hour.length < 2) hour = '0' + hour
        if (minute.length < 2) minute = '0' + minute

        return [hour, minute].join(':')
      } else {
        return ''
      }
    },
    onCancelTitleReassignmentAgreement: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.cancelSignatureRequest = signatureRequest
        this.onShowCancellationRequestModal()
      } else {
        this.failedToast('Signature request cannot be cancelled at this point.')
      }
    },
    onTitleReassignmentAgreementReminder: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.signatureRequest = signatureRequest
        this.onShowReminderModal()
      } else {
        this.failedToast('Signature request reminder cannot be sent at this point.')
      }
    },
    formateSignatureType: function (type) {
      return type === 'LeaseReturned' ? 'Lease Returned' : type
    },
    buildTitleReassignmentAgreementUrl: function (signedAgreementUrl) {
      return process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(signedAgreementUrl)
    },
    onPreview: function () {
      this.titleReassignmentDocument = {
        name: 'Title Reassignment Form',
        src: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/inventory/title_reassignment/' + this.currentInventory.id,
        fileName: 'trade_title_reassignment_' + this.currentInventory.id + '.pdf',
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
      this.wasPreviewed = true
    },
    onPreviewClosed: function () {
      this.titleReassignmentDocument = null
    },
    openModal (fromDropDown) {
      if (!fromDropDown && this.$refs.actionDropdown) {
        this.$refs.actionDropdown.toggle()
        return
      }
      this.selectedRecipients = []
      this.setOverridingSubject()
      this.setInventoryRecipientInformation()
      this.sendTitleReassignmentControlActive = true
    },
    loadInventory () {
      this.serverErrorMessage = ''
      this.isLoading = true
      this.isSaving = true
      let that = this
      inventoryService.single(this.currentInventory.id).then(response => {
        that.currentInventory = response.inventory
        that.setInventoryRecipientInformation()
        that.isLoading = false
        that.isSaving = false
      }).catch((error) => {
        that.isLoading = false
        that.isSaving = false
        that.serverErrorMessage = error.message
      })
    },
    setInventoryRecipientInformation: function () {
      if (this.currentInventory) {
        if (this.currentInventory && this.currentInventory.purchasedFromType === 'Individual') {
          this.selectedRecipientCustomer = {
            id: this.currentInventory.purchasedFromId,
            fullName: this.currentInventory.purchasedFromName,
            email: this.currentInventory.purchasedFromContactEmail,
            mobile: this.currentInventory.purchasedFromContactMobile
          }
        } else if (this.currentInventory && this.currentInventory.purchasedFromType === 'Wholesaler') {
          this.selectedRecipientSupplier = {
            id: this.currentInventory.purchasedFromId,
            name: this.currentInventory.purchasedFromName,
            primaryContactEmail: this.currentInventory.purchasedFromContactEmail,
            primaryContactMobile: this.currentInventory.purchasedFromContactMobile
          }
        }
      }
    },
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data,
        email: data
      }
      this.selectedRecipients.push(recipient)
    },
    getMessagePreface: function () {
      if (this.recipientSelected) {
        if (this.currentInventory && this.currentInventory.purchasedFromType === 'Individual') {
          let result = ''
          if (this.selectedRecipientCustomer && this.selectedRecipientCustomer.fullName) {
            result += 'Hi ' + this.selectedRecipientCustomer.fullName + ',\n\n'
          }
          result += 'Please review and sign the attached trade in vehicle title reassignment form for the vehicle ' + this.currentInventory.name + ' - ' + this.currentInventory.vinNo + '.'
          result += '\n'
          if (this.currentUser) {
            result += '\nThank you, \n' + this.currentUser.fullName
          }
          return result
        } else if (this.currentInventory.purchasedFromType === 'Wholesaler') {
          let result = ''
          if (this.selectedRecipientSupplier && this.selectedRecipientSupplier.name) {
            result += 'Hi ' + this.selectedRecipientSupplier.name + ',\n\n'
          }
          result = 'Please review and sign the attached trade in vehicle title reassignment form for the vehicle ' + this.currentInventory.name + ' - ' + this.currentInventory.vinNo + '.'
          result += '\n'
          if (this.currentUser) {
            result += '\nThank you,\n' + this.currentUser.fullName
          }
          return result
        }
      }
    },
    onSave: function () {
      var emailAddresses = this.selectedRecipients.map(e => e.email).join(', ')
      var mobileNumbers = this.selectedRecipientsMobile.map(e => e.mobile).join(',')
      let that = this
      that.isSaving = true

      var titleReassignmentSendModel = {
        subscriberId: this.currentInventory.subscriberId,
        dealerId: this.currentInventory.dealerId,
        inventoryId: this.currentInventory.id,
        type: 'TradeTitleReassignment',
        destinationAddresses: emailAddresses,
        destinationMobileNumbers: mobileNumbers,
        overridingSubject: this.overridingSubject,
        optionalMessage: this.optionalMessage,
        attachDocuments: false,
        watermarkTitle: false,
        saleDate: null
      }

      inventoryService.sendTradeInTitleReassignmentDocument(titleReassignmentSendModel).then(response => {
        that.isSaving = false
        if (response.errors) {
          that.failedToast('Oops! Something went wrong')
          that.serverErrors = response.errors
        } else {
          that.$emit('on-save')
          that.successToast('Success! Request created.')
          that.sendTitleReassignmentControlActive = false
          that.resetModal()
        }
      }).catch((error) => {
        that.failedToast('Oops! Something went wrong')
        that.isSaving = false
        that.serverErrorMessage = error.message
      })
    },
    getIconTitle: function () {
      return 'Click to send trade in title reassignment document.'
    },
    onValidate: function () {
      this.validateBeforeSubmitScoped('recipient-form')
    },
    resetModal: function () {
      this.selectedRecipientCustomer = null
      this.selectedRecipientSupplier = null
      this.selectedRecipients = []
      this.optionalMessage = ''
    },
    setOverridingSubject: function () {
      this.overridingSubject = 'Title Reassignment -  ' + this.currentInventory.name
    },
    onCancel: function () {
      this.sendTitleReassignmentControlActive = false
      this.resetModal()
    },
    loadTitleReassignmentSendHistory: function () {
      if (this.tabIndex === 0) return
      let inventoryId = this.currentInventory.id
      const searchCriteria = {
        inventoryId: inventoryId,
        dealerId: this.currentDealer.id,
        signingRequestType: 9,
        query: '',
        pageSize: this.perPage,
        sortBy: 'sentAt',
        page: this.page
      }
      this.isLoading = true
      documentSigningRequestService.searchByType(searchCriteria).then(response => {
        this.titleReassignmentSendHistory = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          item.reminderSent = false
          item.cancellationSent = false
          this.titleReassignmentSendHistory.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.titleReassignmentSendHistory = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    }
  },
  mounted: function () {
    this.selectedDealer = this.$store.state.dealer.currentDealer
    this.currentInventory = this.inventoryItem
  },
  watch: {
    tabIndex: function (newValue, oldValue) {
      if (newValue === 1) {
        this.loadTitleReassignmentSendHistory()
      }
    },
    inventoryItem: function (newValue, oldValue) {
      if (newValue) {
        this.currentInventory = newValue
      }
    },
    selectedRecipientSupplier: function (newValue, oldValue) {
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      if (newValue && newValue.name && newValue.email) {
        var recipient = {
          fullName: newValue.name,
          email: newValue.email
        }
        let existingContact = this.selectedRecipients.find(x => x.email === newValue.email)
        if (!existingContact) {
          this.selectedRecipients.push(recipient)
        }
        this.optionalMessage = this.getMessagePreface()
      }
    },
    selectedRecipientCustomer: function (newValue, oldValue) {
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      if (newValue) {
        if (newValue.email) {
          var recipient = {
            fullName: newValue.fullName,
            email: newValue.email
          }
          let existingContact = this.selectedRecipients.find(x => x.email === newValue.email)
          if (!existingContact) {
            this.selectedRecipients.push(recipient)
          }
        }
        if (newValue.mobile) {
          let recipientMobile = {
            mobile: newValue.mobile,
            fullName: newValue.fullName
          }
          let existingContact = this.selectedRecipients.find(x => x.mobile === newValue.mobile)
          if (!existingContact) {
            this.selectedRecipientsMobile.push(recipientMobile)
          }
        }
        this.optionalMessage = this.getMessagePreface()
      }
    }
  }
}
</script>
<style scoped>
  .title-reassignment-send-modal {
    .modal-card-body {
      position:relative;
      max-height: calc(70vh - 10px) !important;
      height: calc(70vh - 10px) !important;
      max-width: 100% !important;
      min-width: 100% !important;
      overflow-y: auto !important;
      width: 960px !important;
    }
  }

  @media screen and (max-height: 600px), print {
    .title-reassignment-send-modal {
      .modal-card-body {
        max-height: calc(70vh - 60px) !important;
        height: calc(70vh - 60px) !important;
      }
    }
  }
</style>

<template>
  <div>
    <div>
      <div class="columns">
        <div class="column is-12">
          <div class="field">
            <label class="label">Contact Number<b-icon pack="fas" icon="star" class="fa-ss"></b-icon><a class="is-pulled-right" @click="onRemoveContactNumberConfirm"><i class="has-text-danger fas fa-trash fa-sm"></i></a></label>
            <div class="control">
              <input v-validate="{'min': 16, 'max': 16, 'required': isRequired, 'contact_number_unique': isUniqueNumber}" :name="instanceName + '.contactNumber'" :data-vv-scope="scope" data-vv-as="contact number" :class="{'input': true }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="customerContactNumber.contactNumber">
              <span v-show="errors.has(validationName + 'contactNumber')" class="help is-danger">
                <i v-show="errors.has(validationName + 'contactNumber')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'contactNumber') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <b-loading :is-full-page="true" :active.sync="showLoader" :canCancel="false"></b-loading>
    </div>
    <b-modal :active.sync="isDeleteConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" v-if="isDeleteConfirmationActive">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Delete Contact Number</p>
        </header>
        <section class="modal-card-body">
          Are you sure you wish to delete the selected contact number?
        </section>
        <footer class="modal-card-foot">
          <a class="button is-primary" @click="onRemoveContactNumber">Yes</a>
          <a class="button is-danger" @click="isDeleteConfirmationActive = false">No</a>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'ContactNumberCapture',
  props: {
    value: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: 0
    },
    scope: {
      type: String,
      default: null
    },
    getValidationErrors: {
      type: Boolean,
      default: false
    },
    useValidator: {
      type: Object,
      default: null
    },
    isRequired: {
      type: Boolean,
      default: false
    },
    isUniqueNumber: {
      type: Boolean,
      default: false
    }
  },
  components: {
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      showLoader: false,
      customerContactNumber: this.value,
      isDeleteConfirmationActive: false
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    validationName: function () {
      return (this.scope) ? this.scope + '.' + this.instanceName + '.' : this.instanceName + '.'
    }
  },
  methods: {
    onRemoveContactNumberConfirm: function () {
      this.isDeleteConfirmationActive = true
    },
    onRemoveContactNumber: function () {
      this.$emit('on-contact-number-remove', this.customerContactNumber)
    }
  },
  watch: {
    getValidationErrors: function (newValue, oldValue) {
      if (newValue === true) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.customerContactNumber.hasValidationErrors = false
            this.customerContactNumber.canSave = true
          } else {
            this.customerContactNumber.hasValidationErrors = true
            this.customerContactNumber.canSave = false
          }
        })
      }
    }
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}
</script>

<style scoped>
</style>

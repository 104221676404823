<template>
  <div v-observe-visibility="visibilityChanged" class='pod tile is-parent is-narrow'>
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold">Inventory - <a @click="onNavigateLoanedOut">Loaned Out</a>: {{title}} ({{total}} pending)
        <i class="fas fa-ellipsis-v icon-more"></i>
        <i style="display: inline-block;" class="fas fa-sync" :class="{'fa-spin': isLoading}" @click="onPodRefreshRequired"></i>
        <i class="fas fa-eye icon-more" title="These inventory items are loaned out"></i>
      </h4>
      <div class="pod-content scrollbar">
        <div class="control-bar is-clearfix is-certical-center">
          <b-field class="control-bar-radio-list is-pulled-left">
            <b-checkbox-button v-model="loanerType" size="is-small" native-value="0" type="is-success"><span>Sales Loaner</span></b-checkbox-button>
            <b-checkbox-button v-model="loanerType" size="is-small" native-value="2" type="is-success"><span>Service Loaner</span></b-checkbox-button>
            <b-checkbox-button v-model="loanerType" size="is-small" native-value="3" type="is-success"><span>Test Drive</span></b-checkbox-button>
            <b-checkbox-button v-model="loanerType" size="is-small" native-value="4" type="is-success"><span>Employee Driver</span></b-checkbox-button>
          </b-field>
        </div>
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th class="is-uppercase no-wrap-table-cell" :class="{'is-current-sort': sortField === column.id}" v-for="column in inventoryData.columns.filter((x) => x.visible)" :key="column.name" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                {{column.name}}
                <span class="icon sort-icon"><i class="mdi" :class="{'mdi-arrow-up': sortOrder === 'asc', 'mdi-arrow-down': sortOrder === 'desc'}" v-if="sortField === column.id"></i></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable" v-for="(item, index) in inventoryData.data" :key="item.id" @click="onSummarizedItemClick(item)" :class="{'pod-row-selected': isRowSelected(item)}" :title="getCustomerNameAndTag(item)">
              <td v-for="column in inventoryData.columns.filter((x) => x.visible)" :key="column.name + index.toString()" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }" >
                <template v-if="column.id === 'stockNo'"><a @click.stop.prevent="onItemClick(item)">{{ item[column.id] }}</a></template>
                <template v-else-if="column.id === 'loanerStatus'">{{ getLoanerStatus(item[column.id]) }}</template>
                <template v-else-if="column.id === 'daysLoanedOut'"><i class="fal fa-hourglass-end fa-sm aging-icon" :class="getColorClassByDO(item.loanedOutExpired)" style="margin-right: 5px;"/>{{item.daysLoanedOut}}</template>
                <template v-else-if="column.id === 'returnDate'">
                  <i v-if="item.loanedOutExpired === true" class="has-text-danger fas fa-exclamation-triangle"></i>
                  <span :class="getColorClassByDO(item.loanedOutExpired)">{{item.returnDate}}</span>
                </template>
                <template v-else>{{item[column.id]}}</template>
              </td>
            </tr>
            <tr v-if="this.inventoryData.data.length === 0" class="subtitle is-7">
              <td style="text-align: center; height: 100px; vertical-align: middle;" :colspan="inventoryData.columns.length">No data available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</template>
<script>

import axios from 'axios'
import eventBus from '@/eventBus'
import ViewInventory from '@/components/inventory/ViewInventory'
import inventoryService from '@/services/inventoryService'
import { createNamespacedHelpers } from 'vuex'
import GridPod from '@/components/generic/pods/GridPod'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'InventoryLoanedOutPod',
  data () {
    return {
      title: 'Selected',
      total: 0,
      isLoading: false,
      page: 0,
      sortField: 'stockNo',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      serverErrorMessage: '',
      perPage: this.$store.state.config.defaultPageSize,
      inventoryData: {
        columns: [
          {
            id: 'id',
            name: 'Id',
            visible: false
          },
          {
            id: 'stockNo',
            name: 'Stock No',
            visible: true,
            maxWidth: '50px'
          },
          {
            id: 'vinNo',
            name: 'Vin No.',
            visible: false
          },
          {
            id: 'customName',
            name: 'Name',
            visible: true
          },
          {
            id: 'loanerStatus',
            name: 'Type',
            visible: true
          },
          {
            id: 'daysLoanedOut',
            name: '#Days Out',
            visible: true,
            maxWidth: '80px'
          },
          {
            id: 'returnDate',
            name: 'Return Date',
            visible: !this.$isMobile
          }
        ],
        data: []
      },
      loanerType: [],
      selectedInventory: null
    }
  },
  components: {
    'grid-pod': GridPod,
    'view-inventory': ViewInventory
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentUser', 'currentPendingActions', 'currentNotifications']),
    ...mapDealerState(['currentDealer'])
  },
  methods: {
    onPodRefreshRequired: function () {
      this.loadData()
    },
    onSummarizedItemClick: function (item) {
      this.selectedInventory = item
      eventBus.$emit('pod-item-highlight', {componentId: this._uid})
      if (this.hasFeatureAccess('inventory.view')) {
        this.$router.push({name: 'InventoryQuickView', params: { id: item.id, isLoanerPod: true, offerPod: -1 }})
      }
    },
    onItemClick: function (item) {
      let that = this

      this.$copyText(item.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + item.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )

      if (this.hasFeatureAccess('inventory.view')) {
        this.$router.push({name: 'InventoryQuickView', params: { id: item.id, isLoanerPod: false, offerPod: -1 }})
      }
    },
    loadData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        loanerTypeIds: this.loanerType,
        query: '',
        pageSize: this.perPage,
        sortBy: 'stockNo',
        page: this.page
      }

      if (this.loanerType && this.loanerType.length !== 0) {
        this.isLoading = true
        inventoryService.getLoanedOut(searchCriteria).then(response => {
          this.inventoryData.data = []
          let currentTotal = response.totalAvailable
          if (response.totalAvailable / this.perPage > 1000) {
            currentTotal = this.perPage * 1000
          }
          this.total = currentTotal

          if (this.loanerType && this.loanerType.length > 1) {
            this.title = 'Selected'
          } else {
            if (this.loanerType[0] === '0') {
              this.title = 'Sales Loaner'
            } else if (this.loanerType[0] === '2') {
              this.title = 'Service Loaner'
            } else if (this.loanerType[0] === '3') {
              this.title = 'Test Drive'
            } else if (this.loanerType[0] === '4') {
              this.title = 'Employee Driver'
            }
          }

          response.results.forEach((item) => {
            this.inventoryData.data.push(item)
          })
          this.isLoading = false
        }).catch((error) => {
          this.inventoryData.data = []
          this.total = 0
          this.isLoading = false
          this.serverErrorMessage = error.message
          throw error
        })
      } else {
        this.title = 'None'
        this.inventoryData.data = []
        this.total = 0
      }
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        if (this.currentDealer.id) {
          this.loanerType = [ '0', '3', '4' ]
          this.loadData()
        }
      }
    },
    getLoanerStatus: function (item) {
      if (item === 'SalesLoaner') {
        return 'Sales Loaner'
      } else if (item === 'ServiceLoaner') {
        return 'Service Loaner'
      } else if (item === 'TestDrive') {
        return 'Test Drive'
      } else {
        return 'Employee Driver'
      }
    },
    getColorClassByDO: function (isExpired) {
      var result = ''
      if (isExpired) {
        result = 'has-text-danger'
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    onNavigateLoanedOut: function () {
      this.$router.push({ name: 'ListLoanerTracking', query: { fromDashboard: true, typeId: this.loanerType } })
    },
    isRowSelected: function (item) {
      if (!this.selectedInventory) return false
      if (this.selectedInventory.id === item.id) return true
      return false
    },
    itemHighLightCheck: function (options) {
      let componentId = options.componentId
      if (this._uid !== componentId) {
        this.selectedInventory = null
      }
    },
    getCustomerNameAndTag: function (item) {
      let customerTagInfo = ''
      if (item.loanedByName && !item.dealerTagNumber) {
        customerTagInfo = item.loanedByName
      } else if (!item.loanedByName && item.dealerTagNumber) {
        customerTagInfo = item.dealerTagNumber
      } else if (item.loanedByName && item.dealerTagNumber) {
        customerTagInfo = customerTagInfo.concat(item.loanedByName, ', ', item.dealerTagNumber)
      }
      return customerTagInfo
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.loanerType = [ '0', '3', '4' ]
        this.loadData()
      }
    },
    loanerType: function () {
      this.loadData()
    }
  },
  mounted () {
    eventBus.$on('pod-item-highlight', this.itemHighLightCheck)
  },
  beforeDestroy () {
    axios.cancel('inventory-loaned-out')
    eventBus.$off('pod-item-highlight', this.itemHighLightCheck)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .no-wrap-table-cell {
    white-space: nowrap;
  }

  .sort-icon {
    height:auto !important;
  }

  .is-current-sort {
    color: rgb(0, 177, 31) !important;
  }
</style>

export default {
  all: [
    {
      name: 'Lead Source 1',
      dealerId: 1,
      dealerName: 'Dealer 1',
      status: 'Active',
      id: 1
    },
    {
      name: 'Lead Source 2',
      dealerId: 2,
      dealerName: 'Dealer 2',
      status: 'Active',
      id: 2
    },
    {
      name: 'Lead Source 3',
      dealerId: 3,
      dealerName: 'Dealer 3',
      status: 'Active',
      id: 3
    }
  ]
}

<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== ''), 'has-text-danger': (currentCustomer && currentCustomer.status === 'Rejected')}">Edit Customer: {{ headerFullName }}</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmit" v-if="currentCustomer.id && currentCustomer.id !== 0" @change="onFormChange">
      <div class="columns is-multiline is-mobile" v-if="fulfillForDepositCaptureMode">
        <div class="column is-12">
          <div class="notification is-info is-flex" v-if="fulfillForDepositCaptureMode">
            <span>
              <i class="fas fa-info-circle is-info fa-2x"></i>
            </span>
            <span class="ml-10">
              {{getfulfillForDepositCaptureModeMessage}}
            </span>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Email Address<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="control has-icons-left has-icons-right">
              <input name="email" data-vv-as="email address" v-validate="{'email': true, 'required': true}" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has('page-1.email') }" type="text" placeholder="Email" v-model="currentCustomer.email" data-vv-scope="page-1" data-lpignore="true" autocomplete="work-now">
              <span v-show="errors.has('page-1.email')" class="help is-danger">
                <i v-show="errors.has('page-1.email')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.email') }}
              </span>
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <span class="icon is-small is-right" v-show="!errors.has('page-1.email')">
                <i class="fas fa-check"></i>
              </span>
              <span class="icon is-small is-right" v-show="errors.has('page-1.email')">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Mobile<b-icon pack="fas" icon="star" class="fa-ss" v-if="false"></b-icon></label>
            <div class="control">
              <input name="mobile" v-validate="{'min': 16, 'max': 16, 'required': false}" data-vv-as="page-1.mobile" :class="{'input': true, 'is-danger': errors.has('page-1.mobile') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentCustomer.mobile" data-vv-scope="page-1" data-lpignore="true" autocomplete="work-now">
                <span v-show="errors.has('page-1.mobile')" class="help is-danger">
                <i v-show="errors.has('page-1.mobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.mobile') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <b-tabs v-if="!fulfillForDepositCaptureMode" size="is-small" v-model="tabIndex" :animated="false" type="is-toggle">
        <b-tab-item label="Definition" icon-pack="fas"  icon="fas fa-info-circle fa-sm">
          <div class="columns">
            <div class="column is-6" v-show="false">
              <dealer-selector v-model="selectedDealer"></dealer-selector>
            </div>
            <div class="column is-6">
              <button-list-selector validateAs="customer type" label="Type" :required="true" v-model="selectedType" :availableValues="definedTypes.customerTypes.options"></button-list-selector>
            </div>
            <div class="column is-6 is-flex">
              <switch-selector type="is-info" label="Partial Fill" v-model="isPartial"></switch-selector>
              <span style="margin-left: 40px;"><switch-selector type="is-info" label="Is Trusted" v-model="currentCustomer.isTrusted"></switch-selector></span>
              <span style="margin-left: 40px;"><switch-selector type="is-info" label="Is Blocked" v-model="currentCustomer.isBlocked"></switch-selector></span>
              <span style="margin-left: 40px;"><switch-selector type="is-info" label="Allow Payment without Title" v-model="currentCustomer.allowPaymentWithoutTitle"></switch-selector></span>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">
                  First Name
                  <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                  <dealer-peak-control v-if="currentCustomer && currentCustomer.customerId" :customer="currentCustomer"></dealer-peak-control>
                </label>
                <div class="control">
                  <input name="firstName" data-vv-as="first name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-1.firstName') }" type="text" placeholder="e.g John" v-model="currentCustomer.firstName" data-vv-scope="page-1" autocomplete="work-now">
                  <span v-show="errors.has('page-1.firstName')" class="help is-danger">
                    <i v-show="errors.has('page-1.firstName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.firstName') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Last Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <input name="lastName" data-vv-as="last name"  v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-1.lastName') }" type="text" placeholder="e.g Doe" v-model="currentCustomer.lastName" data-vv-scope="page-1" autocomplete="work-now">
                </div>
                <span v-show="errors.has('page-1.lastName')" class="help is-danger">
                  <i v-show="errors.has('page-1.lastName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.lastName') }}
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Middle Name</label>
                <div class="control">
                  <input name="middleName" data-vv-as="middle name" :class="{'input': true, 'is-danger': errors.has('middleName') }" type="text" placeholder="e.g Ben" v-model="currentCustomer.middleName">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Initials</label>
                <div class="control">
                  <input name="initials" data-vv-as="initials" :class="{'input': true, 'is-danger': errors.has('initials') }" type="text" placeholder="e.g JD" v-model="currentCustomer.initials">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Customer Number</label>
                <div class="control">
                  <input name="customerNumber" data-vv-as="customer number" :class="{'input': true, 'is-danger': errors.has('customerNumber') }" type="text" v-model="currentCustomer.customerNo">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Company Name<b-icon pack="fas" icon="star" class="fa-ss" v-if="isCompanyNameRequired"></b-icon></label>
                <div class="control">
                  <input name="companyName" data-vv-as="company name" v-validate="{'required':isCompanyNameRequired}" :class="{'input': true, 'is-danger': errors.has('companyName') }" type="text" placeholder="e.g EAG" v-model="currentCustomer.companyName">
                </div>
                <span v-show="errors.has('companyName')" class="help is-danger">
                  <i v-show="errors.has('companyName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('companyName') }}
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Email Address<b-icon pack="fas" icon="star" class="fa-ss" v-if="isEmailRequired"></b-icon></label>
                <div class="control has-icons-left has-icons-right">
                  <input name="email" data-vv-as="email address" v-validate="{'email': true, 'required': isEmailRequired}" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has('page-1.email') }" type="text" placeholder="Email" v-model="currentCustomer.email" data-vv-scope="page-1" data-lpignore="true" autocomplete="work-now">
                  <span v-show="errors.has('page-1.email')" class="help is-danger">
                    <i v-show="errors.has('page-1.email')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.email') }}
                  </span>
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <span class="icon is-small is-right" v-show="!errors.has('page-1.email')">
                    <i class="fas fa-check"></i>
                  </span>
                  <span class="icon is-small is-right" v-show="errors.has('page-1.email')">
                    <i class="fas fa-exclamation-triangle"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Parent</label>
                <div class="control">
                  <input disabled name="parentName" data-vv-as="parent name" :class="{'input': true, 'is-danger': errors.has('parentName') }" type="text" v-model="currentCustomer.parentName">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Mobile<b-icon pack="fas" icon="star" class="fa-ss" v-if="isMobileRequired"></b-icon></label>
                <div class="control">
                  <input name="mobile" v-validate="{'min': 16, 'max': 16, 'required': isMobileRequired}" data-vv-as="mobile" :class="{'input': true, 'is-danger': errors.has('page-1.mobile') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentCustomer.mobile" data-vv-scope="page-1" data-lpignore="true" autocomplete="work-now">
                  <span v-show="errors.has('page-1.mobile')" class="help is-danger">
                    <i v-show="errors.has('page-1.mobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.mobile') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Home</label>
                <div class="control">
                  <input name="home" data-vv-as="home" :class="{'input': true, 'is-danger': errors.has('home') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentCustomer.home" data-lpignore="true" autocomplete="work-now">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Office</label>
                <div class="control">
                  <input name="office" data-vv-as="office" :class="{'input': true, 'is-danger': errors.has('office') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentCustomer.office" data-lpignore="true" autocomplete="work-now">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Fax</label>
                <div class="control">
                  <input name="fax" data-vv-as="fax" :class="{'input': true, 'is-danger': errors.has('fax') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentCustomer.fax" data-lpignore="true" autocomplete="work-now">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Social Security No.</label>
                <div class="control">
                  <input v-if="canEditSsn"
                    name="ssn"
                    data-vv-as="social security number"
                    :class="{'input': true, 'is-danger': errors.has('ssn') }"
                    type="text"
                    v-model="currentCustomer.ssn"
                    v-mask="'###-##-####'">
                  <span v-else class="field is-size-7">{{ currentSsn }}</span>
                </div>
                <div class="field is-size-7 is-pulled-right mt-10">
                  <b-checkbox
                    v-if="hasSsnAccess"
                    :native-value="false"
                    name="chkEditSsn"
                    type="is-info"
                    v-model="canEditSsn">
                    edit SSN
                  </b-checkbox>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <b-field label="Date of Birth">
                <el-date-picker
                    @change="onFormChange()"
                    v-model="currentCustomer.dateOfBirth"
                    type="date"
                    format="MM-dd-yyyy"
                    placeholder="Click to select..."
                    :clearable="false"
                    :editable="false">
                </el-date-picker>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <button-list-selector validateAs="marital status" label="Marital Status" :required="false" v-model="selectedMaritalStatus" :availableValues="definedTypes.customerMaritalStatus.options" scope="page-1"></button-list-selector>
            </div>
            <div class="column is-6">
              <button-list-selector validateAs="employment status" label="Employment Status" :required="false" v-model="selectedEmploymentStatus" :availableValues="definedTypes.customerEmploymentStatus.options" scope="page-1"></button-list-selector>
            </div>
          </div>
          <div class="columns">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Drivers License No.</label>
                  <div class="control">
                    <input name="driverLicenseNumber" data-vv-as="driver license number" :class="{'input': true, 'is-danger': errors.has('driverLicenseNumber') }" type="text" v-model="currentCustomer.driverLicenseNumber">
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <state-selector label="Drivers License State" v-model="selectedState" name="driversLicenseStateSelector" data-vv-as="drivers license state" :class="{'is-danger': errors.has('driversLicenseStateSelector') }" placeholder="State" :required="false"></state-selector>
              </div>
          </div>
          <div class="columns" v-if="selectedType && selectedType.name === 'Individual' && selectedMaritalStatus && selectedMaritalStatus.name === 'Married'">
            <div class="column is-6">
              <label class="label">Spouse</label>
              <div class="control">
                <multiselect
                :showLabels="false"
                name="spouse"
                :taggable="false"
                :searchable="true"
                :disabled="false"
                v-model="selectedSpouse"
                :options="allSpouse"
                label="fullName"
                trackBy="id"
                :loading="isSpouseLoading"
                placeholder="Type to search for a customer"
                :internal-search="false"
                :options-limit="300"
                @search-change="onSpouseSearch"></multiselect>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <switch-selector type="is-info" label="Identity Verification Terms Conditions Accepted" v-model="currentCustomer.isTermsAccepted" :isDisabled="true"></switch-selector>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Identity Verification Terms Conditions Accepted At</label>
                <div class="control">
                  <el-date-picker
                    @change="onFormChange()"
                    v-model="currentCustomer.termsAcceptedAt"
                    type="date"
                    format="MM-dd-yyyy"
                    placeholder="Click to select..."
                    :clearable="false"
                    :editable="false"
                    :disabled="true">
                </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <switch-selector type="is-info" label="Credit Application Terms Conditions Accepted" v-model="currentCustomer.isCreditTermsAccepted" :isDisabled="true"></switch-selector>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Credit Application Terms Conditions Accepted At</label>
                <div class="control">
                  <el-date-picker
                    @change="onFormChange()"
                    v-model="currentCustomer.creditTermsAcceptedAt"
                    type="date"
                    format="MM-dd-yyyy"
                    placeholder="Click to select..."
                    :clearable="false"
                    :editable="false"
                    :disabled="true">
                </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6" v-if="showReview">
              <switch-selector type="is-info" label="Review Completed" v-model="reviewCompleted"></switch-selector>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Deals" icon-pack="fas"  icon="fas fa-info-circle fa-sm">
          <div class="columns">
            <div class="column is-12">
              <label class="label">Deals</label>
              <div class="lists">
                <table class="table is-bordered is-striped is-fullwidth is-narrow">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Code</th>
                      <th>Status</th>
                      <th>Sale Type</th>
                      <th>Stock Type</th>
                    </tr>
                  </thead>
                  <tr v-for="(deal, index) in deals" :key="deal.id">
                    <td>{{index + 1}}</td>
                    <td>
                      <a class="is-link" @click="onViewDeal(deal.id)">
                        {{deal.code}}
                      </a>
                    </td>
                    <td>{{deal.type}}</td>
                    <td>{{deal.saleType}}</td>
                    <td>{{deal.stockType}}</td>
                  </tr>
                  <tr v-if="deals.length === 0">
                    <td colspan="6">None specified</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Addresses" icon-pack="fas" icon="fas fas fa-address-card fa-sm">
          <div id="address-section" class="box">
            <section-header title="Address" parentSelector="#address-section"></section-header>
            <div v-for="(address, index) in addresses" :key="address.id">
              <address-capture scope="page-3" :useValidator="thisValidator" v-on:primary-address-set="onAutoSelectPrimaryAddress" v-on:unselect-other="onUnselectNonPrimary" v-model="addresses[index]" :isCustomer="true" :isAccessPrivateInfo="hasPrivateInfoAccess" :box="false" :required="isAddressRequired"></address-capture>
              <div class="inner-add-remove-button">
                <a class="button is-primary" @click="addAddress({})">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" v-if="addresses && addresses.length > 1" @click="removeAddress(address)">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
              <br />
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Work History" icon-pack="fas" icon="fas fa-briefcase fa-sm" v-if="hasPrivateInfoAccess">
          <div id="work-history-section" class="box">
            <section-header title="Work History" parentSelector="#work-history-section"></section-header>
            <div v-for="(history, index) in workHistory" :key="history.id">
              <address-capture v-model="workHistory[index]" :isWorkHistory="true" :box="false"></address-capture>
              <br/>
            </div>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addWorkHistory({})">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
              <a class="button is-danger" v-if="this.workHistory.length > 1" @click="removeWorkHistory()">
                <span class="icon">
                  <i class="fas fa-minus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Personal References" icon-pack="fas" icon="fas fa-user-friends fa-sm" v-if="hasPrivateInfoAccess">
          <div id="personal-reference-section" class="box">
            <section-header title="Personal References" parentSelector="#personal-reference-section"></section-header>
            <div v-for="(personalReference, index) in personalReferences" :key="personalReference.id">
              <address-capture v-model="personalReferences[index]" :isContact="true" :box="false" :useValidator="thisValidator"></address-capture>
              <br/>
            </div>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addPersonalReference({})">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
              <a class="button is-danger" v-if="this.personalReferences.length > 1" @click="removePersonalReference()">
                <span class="icon">
                  <i class="fas fa-minus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Relatives" icon-pack="fas" icon="fas fa-user fa-sm" v-if="hasPrivateInfoAccess">
          <div id="relative-section" class="box">
            <section-header title="Relatives" parentSelector="#relative-section"></section-header>
            <div v-for="(relative, index) in relatives" :key="relative.id">
              <address-capture v-model="relatives[index]" :isContact="true" :box="false" :useValidator="thisValidator"></address-capture>
              <br/>
            </div>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addRelative({})">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
              <a class="button is-danger" v-if="this.relatives.length > 1" @click="removeRelative()">
                <span class="icon">
                  <i class="fas fa-minus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Bank" icon-pack="fas" icon="fas fa-info-circle fa-sm">
          <bank-details-capture @formchange="onFormChange()" v-model="bankDetails" :getValidationErrors.sync="getValidationErrors" :bankDetailsRequired="isBankRequired" :useValidator="thisValidator" scope="page-7"></bank-details-capture>
        </b-tab-item>
        <b-tab-item label="Photo" icon-pack="fas" icon="fas fa-camera fa-sm">
          <div class="columns">
            <div class="column is-6">
              <license-selector
                v-model="driversPhotoFiles"
                label="Drivers License"
                :multiple="false"
                @uploadStarted="uploadStarted"
                @uploadComplete="cropDriverPhoto(0)"
                :croppedCustomerImages="croppedDriverLicenseImages">
              </license-selector>
              <div class="inner-add-remove-button" v-if="showCropDriversLicenseExactSize">
                <a class="button is-info" title="Crop drivers license photo to match exact size?" @click="showCropDriversPhotoModal(1)">
                  <span class="icon">
                    <i class="fas fa-crop"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="columns" v-if="stateRequiredBackOfDriversLicense">
            <div class="column is-6">
              <license-selector
                v-model="backOfDriversPhotoFiles"
                label="Back of Drivers License"
                :multiple="false"
                @uploadStarted="uploadStarted"
                @uploadComplete="backOfDriversLicenseUploaded"
                :croppedCustomerImages="croppedBackOfDriverLicenseImages">
              </license-selector>
              <div class="inner-add-remove-button" v-if="showCropDriversLicenseExactSize">
                <a class="button is-info" title="Crop drivers license photo to match exact size?" @click="showCropDriversPhotoModal(2)">
                  <span class="icon">
                    <i class="fas fa-crop"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <file-selector
              v-model="insurancePhotoFiles"
              label="Insurance Card"
              :multiple="false"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete"></file-selector>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <file-selector
              v-model="customerPhotoFiles"
              :multiple="false" label="Customer Photo"
              :required="false"
              :usePortalViewer="false"
              :croppedCustomerImages="croppedCustomerImages"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete">
              </file-selector>
              <div class="inner-add-remove-button" v-if="showCropDriverPhotoButton">
                <button :disabled="isUploadingFile" class="button is-info" title="Crop drivers license photo to use as customer photo?" @click="showCropDriversPhotoModal(0)">
                  <i class="fas fa-crop"></i>
                </button>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field" v-if="currentCustomer">
                <span style="margin-left: 10px;">
                  <file-viewer
                  :files="archivedDriversImages"
                  :isListMode="false"
                  dialogTitle="Drivers License"
                  icon="fa-id-card"
                  iconTitle="View archived license files"
                  modalTitle="Archived license files">
                  </file-viewer>
                </span>
                <span style="margin-left: 10px;">
                  <file-viewer
                  :files="archivedInsuranceImages"
                  :isListMode="false"
                  dialogTitle="Insurance Card"
                  icon="fa-car-bump"
                  iconTitle="View archived insurance card files"
                  modalTitle="Archived insurance card files">
                  </file-viewer>
                </span>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Transactions" icon-pack="fas" icon="fas fa-money-check fa-sm">
          <div class="box configuration-content-block">
            <b-table class="lists" :data="currentCustomer.transactions" @click="onViewCustomerTransaction">
              <template slot-scope="props">
                <b-table-column field="createdBy" label="Created By" sortable>
                  {{ props.row.userName }}
                </b-table-column>
                <b-table-column field="referenceId" label="Reference ID" sortable>
                  {{ props.row.referenceId }}
                </b-table-column>
                <b-table-column field="description" label="Description" sortable>
                  {{ props.row.description }}
                </b-table-column>
                <b-table-column field="transactionTypeDescription" label="Transaction Type" sortable>
                  {{ props.row.transactionTypeDescription }}
                </b-table-column>
                <b-table-column field="transactionReferenceType" label="Reference Type" sortable>
                  {{ props.row.transactionReferenceType }}
                </b-table-column>
                <b-table-column field="createdAt" label="Created At" sortable>
                  {{props.row.createdAt | formatDateOnly}}
                </b-table-column>
                <b-table-column field="amount" label="Amount" sortable>
                  <span :class="getColorClassByTransactionType(props.row, props.row.transactionType)">{{props.row.amount | currency}}</span>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="has-text-centered">
                    <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                    <p>No recorded transactions for this customer.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>
        </b-tab-item>
        <b-tab-item label="Additional Email/Mobile" icon-pack="fas" icon="fas fa-envelope fa-sm">
          <div class="box configuration-content-block">
            <div class="columns">
              <div class="column is-6">
                <div id="email-section" class="box">
                  <section-header title="Email Addresses" parentSelector="#email-section"></section-header>
                  <div v-for="(emailAddress, index) in emailAddresses" :key="emailAddress.id">
                    <email-capture v-if="emailAddress && emailAddress.status === 'Active'" scope="page-10" :index="index" @on-email-remove="removeEmail(emailAddress)" v-model="emailAddresses[index]" :useValidator="thisValidator" :isRequired="true" :isUniqueEmail="true"></email-capture>
                    <br/>
                  </div>
                  <div class="inner-add-remove-button">
                    <a class="button is-primary" @click="addEmailAddress()">
                      <span class="icon">
                        <i class="fas fa-plus"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div id="contact-number-section" class="box">
                  <section-header title="Contact Numbers" parentSelector="#contact-number-section"></section-header>
                  <div v-for="(contactNumber, index) in contactNumbers" :key="contactNumber.id">
                    <contact-number-capture v-if="contactNumber && contactNumber.status === 'Active'" scope="page-10" :index="index" @on-contact-number-remove="removeContactNumber(contactNumber)" v-model="contactNumbers[index]" :isRequired="true" :useValidator="thisValidator" :isUniqueNumber="true"></contact-number-capture>
                    <br/>
                  </div>
                  <div class="inner-add-remove-button">
                    <a class="button is-primary" @click="addContactNumber()">
                      <span class="icon">
                        <i class="fas fa-plus"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <br />
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-primary" :disabled="isSaving || isUploadingFile" :class="{'is-loading': isSaving }" type="button" @click="validateBeforeSubmitAllScoped">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>

    <b-modal :active.sync="entityTransactionActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">View Transaction</p>
        </header>
        <section class="modal-card-body">
          <view-entity-transaction v-model="currentTransaction" :modalView="true"></view-entity-transaction>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="closeViewTransaction()">Close</button>
        </footer>
      </div>
    </b-modal>

    <b-loading :is-full-page="isFullPage" :active.sync="showLoader" :canCancel="false"></b-loading>
    <dealer-peak-lookup></dealer-peak-lookup>
    <b-modal :active.sync="cropDriverPhotoActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{cropModalHeader}}</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column" v-if="!isLoadingPhoto">
              <vue-cropper v-show="selectedFile" ref='cropper' :src="selectedFile" alt='Source Image'></vue-cropper>
            </div>
            <div class="aligner-item column" style="text-align:center" v-else>
              <img src="@/assets/ajax-loader.gif">
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isLoadingPhoto }" @click="onSaveCroppedDriverPhoto">Save</button>
          <button class="button is-danger" :class="{'is-loading': isLoadingPhoto }" @click="closeCropDriverPhoto">Cancel</button>
        </footer>
      </div>
    </b-modal>
    <portal to="global-modal-portal" v-if="driversLicenseSizeConfirmationActive">
      <b-modal :active.sync="driversLicenseSizeConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">Confirm Exact Size</p>
          </header>
          <section class="modal-status-body">
            <div class="notification is-flex is-warning">
              <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
              <span class="ml-10">
                {{driversLicenseExactSizeModalText}}
              </span>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmDriversLicenseExactSize(true)" type="button">Yes</button>
            <button class="button is-danger" @click="onConfirmDriversLicenseExactSize(false)" type="button">No</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </section>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import customerService from '@/services/customerService'
import DealerSelector from '@/components/generic/DealerSelector'
import AddressCapture from '@/components/generic/AddressCapture'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import FileSelector from '@/components/generic/FileSelector'
import ImageSelector from '@/components/generic/ImageSelector'
import dealService from '@/services/dealService'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import customerMixin from '@/mixins/customer/index'
import completeCancelInitMixin from '@/mixins/generic/completeCancelInit'
import SectionHeader from '@/components/generic/SectionHeader'
import BankDetailsCapture from '@/components/generic/BankDetailsCapture'
import SwitchSelector from '@/components/generic/SwitchSelector'
import CustomerSelector from '@/components/generic/CustomerSelector'
import DealerPeakControl from '@/components/generic/DealerPeakControl'
import DealerPeakLookup from '@/components/generic/DealerPeakLookup'
import utilitiesMixin from '@/mixins/generic/utilities'
import ViewEntityTransaction from '@/components/generic/ViewEntityTransaction'
import EmailCapture from '@/components/generic/EmailCapture'
import ContactNumberCapture from '@/components/generic/ContactNumberCapture'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import axios from 'axios'
import baseService from '@/services/baseService'
import util from '@/utils'
import FileViewer from '@/components/generic/FileViewer'
import stateSelector from '@/components/generic/StateSelector'
import LicenseSelector from '@/components/generic/LicenseSelector'

import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('dealer')
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapAllStatesGetters = createNamespacedHelpers('states').mapGetters

export default {
  name: 'EditCustomer',
  mixins: [utilitiesMixin, validateBeforeSubmitMixin, customerMixin, completeCancelInitMixin],
  components: {
    'address-capture': AddressCapture,
    'dealer-selector': DealerSelector,
    'section-header': SectionHeader,
    'button-list-selector': ButtonListSelector,
    'file-selector': FileSelector,
    'image-selector': ImageSelector,
    'bank-details-capture': BankDetailsCapture,
    'switch-selector': SwitchSelector,
    'customer-selector': CustomerSelector,
    'dealer-peak-control': DealerPeakControl,
    'dealer-peak-lookup': DealerPeakLookup,
    'view-entity-transaction': ViewEntityTransaction,
    'email-capture': EmailCapture,
    'contact-number-capture': ContactNumberCapture,
    'vue-cropper': VueCropper,
    'file-viewer': FileViewer,
    'state-selector': stateSelector,
    'license-selector': LicenseSelector
  },
  props: {
    customerId: {
      type: Number,
      default: 0
    },
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full-screen'
    },
    fulfillForDepositCaptureMode: {
      type: Boolean,
      default: false
    },
    defaultTab: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      isFullPage: !this.fulfillForDepositCaptureMode,
      currentCustomerId: 0,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      tabIndex: 0,
      selectedType: null,
      selectedDealer: null,
      customerPhotoFiles: [],
      driversPhotoFiles: [],
      insurancePhotoFiles: [],
      selectedMaritalStatus: null,
      selectedEmploymentStatus: null,
      creditApplication: null,
      deals: [],
      addresses: [],
      workHistory: [],
      personalReferences: [],
      relatives: [],
      selectedBank: null,
      selectedAccountType: null,
      selectedSpouse: null,
      currentCustomer: {
        id: 0,
        dealerId: 0,
        spouseId: 0,
        companyName: '',
        firstName: '',
        middleName: '',
        lastName: '',
        initials: '',
        email: '',
        mobile: '',
        office: '',
        home: '',
        fax: '',
        bankId: 0,
        accountHolder: '',
        accountNo: '',
        accountType: null,
        branchNo: '',
        branchName: '',
        swiftNo: '',
        ssn: '',
        driverLicenseNumber: '',
        driverLicenseState: '',
        dateOfBirth: null,
        isTermsAccepted: false,
        isCreditTermsAccepted: false,
        termsAcceptedAt: new Date(),
        creditTermsAcceptedAt: new Date(),
        isTrusted: false,
        isBlocked: false
      },
      bankDetails: {
        accountHolder: '',
        accountNo: '',
        accountType: null,
        branchNo: '',
        branchName: '',
        swiftNo: '',
        selectedBank: null,
        selectedAccountType: null,
        hasValidationErrors: false,
        canSave: null,
        selectedDefaultMethod: null
      },
      getValidationErrors: false,
      viewTransaction: false,
      currentTransaction: {},
      entityTransactionActive: false,
      emailAddresses: [],
      contactNumbers: [],
      showReview: false,
      reviewCompleted: false,
      cropDriverPhotoActive: false,
      selectedFile: '',
      croppedCustomerImages: [],
      showCropDriverPhotoButton: false,
      isLoadingPhoto: false,
      selectedState: null,
      backOfDriversPhotoFiles: [],
      driversLicenseExactSizeConfirmed: true,
      backOfDriversLicenseExactSizeConfirmed: true,
      driversLicenseSizeConfirmationActive: false,
      driversLicenseExactSizeMode: 0,
      croppedDriverLicenseImages: [],
      croppedBackOfDriverLicenseImages: [],
      croppedDriverPhotoMode: 0,
      frontOfDriverLicenseExactSizeCheckCompleted: false,
      croppedDriverLicenseImagesCount: 0,
      canEditSsn: false,
      currentSsn: '',
      spouseList: [],
      isSpouseLoading: false
    }
  },
  computed: {
    fullName: function () {
      if (this.currentCustomer.id) {
        var fullName = this.currentCustomer.firstName + ' ' + this.currentCustomer.lastName
        if (fullName === ' ') {
          return this.currentCustomer.companyName
        }
        return fullName
      }
      return ''
    },
    showLoader: function () {
      return this.isLoading || this.isSaving
    },
    ...mapGetters(['allDealers']),
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapAllStatesGetters(['allStates']),
    allSpouse () {
      return this.spouseList
    },
    canSave: function () {
      return this.bankDetails.canSave
    },
    humanizedIsTermsAccepted: function () {
      return this.currentCustomer.isTermsAccepted ? 'Yes' : 'No'
    },
    hasPrivateInfoAccess: function () {
      let allowedPermissions = ['customer.view.private-info']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    isCompanyNameRequired: function () {
      return this.selectedType && this.selectedType.name === 'Company'
    },
    getfulfillForDepositCaptureModeMessage: function () {
      let text = 'You need to complete the customer info below to be able to capture a deposit request.'
      return text
    },
    archivedDriversImages: function () {
      let formattedDriverImages = []
      if (this.currentCustomer) {
        let archivedDriverImages = this.currentCustomer.licenseAttachments.filter((x) => x.status === 'Archived')
        if (archivedDriverImages.length > 0) {
          archivedDriverImages.forEach((x) => {
            x.loaded = true
            x.imageData = x.fileLocation ? x.fileLocation : x.imageData
            x.fileType = x.fileLocation ? this.getFileType(x.fileLocation) : x.fileType
            x.name = x.name ? x.name : x.uploadName
            formattedDriverImages.push(x)
          })
        }
      }
      return formattedDriverImages
    },
    archivedInsuranceImages: function () {
      let formattedInsuranceImages = []
      if (this.currentCustomer) {
        let archivedInsuranceImages = this.currentCustomer.insuranceAttachments.filter((x) => x.status === 'Archived')
        if (archivedInsuranceImages.length > 0) {
          archivedInsuranceImages.forEach((x) => {
            x.loaded = true
            x.imageData = x.fileLocation ? x.fileLocation : x.imageData
            x.fileType = x.fileLocation ? this.getFileType(x.fileLocation) : x.fileType
            x.name = x.name ? x.name : x.uploadName
            formattedInsuranceImages.push(x)
          })
        }
      }
      return formattedInsuranceImages
    },
    stateRequiredBackOfDriversLicense: function () {
      if (this.selectedState) {
        return this.selectedState.requiresBackOfDriversLicense
      }
      return false
    },
    driversLicenseExactSizeModalText: function () {
      let selectedStateName = ''
      let result = 'The copies of drivers licenses are required to match the original documents exact size. Does the provided drivers license copy come to the exact size on a A4 page?'
      if (this.selectedState) {
        selectedStateName = this.selectedState.name
      }
      if (selectedStateName) {
        if (selectedStateName) {
          result = 'The state of ' + selectedStateName + ' requires the copies of drivers licenses match the original documents exact size. Does the provided drivers license copy come to the exact size on a A4 page?'
        }
      }
      return result
    },
    cropModalHeader: function () {
      let result = 'Crop drivers license photo to use as customer photo?'
      if (this.croppedDriverPhotoMode === 1 || this.croppedDriverPhotoMode === 2) {
        result = 'Crop drivers license removing any surrounding background.'
      }
      return result
    },
    selectedStateRequiresExactSizeDriversLicense: function () {
      if (this.selectedState && this.selectedState.requiresExactDriversLicenseSize) {
        return true
      }
      return false
    },
    showCropDriversLicenseExactSize: function () {
      let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
      if (this.selectedStateRequiresExactSizeDriversLicense && activeDriverPhotoFiles && activeDriverPhotoFiles.length > 0) {
        return true
      }
      return false
    },
    showCropBackOfDriversLicenseExactSize: function () {
      let activeDriverPhotoFiles = this.backOfDriversPhotoFiles.filter((x) => x.status === 'Active')
      if (this.selectedStateRequiresExactSizeDriversLicense && activeDriverPhotoFiles && activeDriverPhotoFiles.length > 0) {
        return true
      }
      return false
    },
    hasSsnAccess: function () {
      let allowedPermissions = ['customer.ssn']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    headerFullName: function () {
      let result = ''
      if (this.currentCustomer) {
        result = this.currentCustomer.firstName + ' ' + this.currentCustomer.lastName
        if (result === ' ') {
          result = this.currentCustomer.companyName
        }

        if (this.currentCustomer.status === 'Rejected') {
          result += ' (Rejected)'
        }
      }
      return result
    }
  },
  methods: {
    onViewDeal: function (dealId) {
      if (this.hasFeatureAccess('deal.view')) {
        this.$emit('close')
        this.$router.push({ name: 'DealsQuickView', params: { id: dealId } })
      }
    },
    onSave: function () {
      if (this.cropDriverPhotoActive === true) {
        return
      }
      this.getValidationErrors = true
      if (this.fulfillForDepositCaptureMode === true) {
        this.onCanSave()
      }
    },
    getViewRoute: function () {
      return { name: 'ListCustomers' }
    },
    getDealer: function (id) {
      return this.allDealers.find((x) => x.id === id)
    },
    clearAllSpouse () {
      this.spouseList = []
    },
    loadAsyncSpouseData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.selectedDealer.id,
        sortBy: 'firstName.asc'
      }
      this.isSpouseLoading = true
      this.clearAllSpouse()
      customerService.all(searchCommand).then(response => {
        this.spouseList = response.results || response
        this.isSpouseLoading = false
      })
    },
    doSpouseSearch: _.debounce(function (query) { this.loadAsyncSpouseData(query) }, 500),
    onSpouseSearch (query) {
      this.doSpouseSearch(query)
    },
    fetchCustomer: function (id) {
      this.isLoading = true
      customerService.single(id).then(response => {
        this.isLoading = false
        this.currentCustomer = {
          ...response.customer
        }
        if (this.currentCustomer.id) {
          this.initInputValues()
        } else {
          this.serverErrorMessage = 'could not load customer with id: ' + id
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    initInputValues: function () {
      if (this.currentCustomer != null) {
        this.selectedAccountType = this.definedTypes.bankAccountTypes.find((x) => x.name === this.currentCustomer.accountType)
        this.selectedType = this.definedTypes.customerTypes.options.find((x) => x.name === this.currentCustomer.type || x.altName === this.currentCustomer.type)
        this.selectedMaritalStatus = (this.currentCustomer.maritalStatus) ? this.definedTypes.customerMaritalStatus.options.find((x) => x.name === this.currentCustomer.maritalStatus) : null
        this.selectedEmploymentStatus = (this.currentCustomer.employmentStatus) ? this.definedTypes.customerEmploymentStatus.options.find((x) => x.name === this.currentCustomer.employmentStatus) : null
        this.selectedDealer = this.getDealer(this.currentCustomer.dealerId)

        if (this.currentCustomer.spouseId && this.currentCustomer.spouseId > 0) {
          this.isLoading = true
          customerService.single(this.currentCustomer.spouseId).then(response => {
            this.isLoading = false
            this.selectedSpouse = {
              ...response.customer
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isLoading = false
          })
        }

        let selectedBank = this.currentCustomer.bankId !== 0
          ? { id: this.currentCustomer.bankId !== 0 ? this.currentCustomer.bankId : null, name: this.currentCustomer.bankName }
          : null
        this.bankDetails = {
          accountHolder: this.currentCustomer.accountHolder,
          accountNo: this.currentCustomer.accountNo,
          accountType: this.currentCustomer.accountType,
          branchNo: this.currentCustomer.branchNo,
          branchName: this.currentCustomer.branchName,
          swiftNo: this.currentCustomer.swiftNo,
          selectedBank: selectedBank,
          selectedAccountType: this.definedTypes.bankAccountTypes.find((x) => x.name === this.currentCustomer.accountType),
          hasValidationErrors: false,
          selectedDefaultMethod: this.definedTypes.paymentMethods.find((x) => x.name === this.currentCustomer.defaultPaymentMethod),
          canSave: null
        }

        dealService.byCustomer(this.currentCustomer.id).then(response => {
          this.deals = response.results
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          this.serverErrorMessage = error.message
        })

        if (this.currentCustomer.fileLocation) {
          var photoFile = {
            loaded: true,
            imageData: this.currentCustomer.fileLocation,
            fileName: this.currentCustomer.fileName,
            fileLocation: this.currentCustomer.fileLocation,
            name: this.currentCustomer.imageName,
            fileType: this.getFileType(this.currentCustomer.fileLocation),
            status: 'Active'
          }

          this.customerPhotoFiles.push(photoFile)
        }

        if (this.currentCustomer.driversFileLocation) {
          var driversPhotoFile = {
            loaded: true,
            imageData: this.currentCustomer.driversFileLocation,
            fileName: this.currentCustomer.driversFileName,
            fileLocation: this.currentCustomer.driversFileLocation,
            name: this.currentCustomer.driversImageName,
            fileType: this.getFileType(this.currentCustomer.driversFileLocation),
            status: 'Active'
          }

          this.driversPhotoFiles.push(driversPhotoFile)
          this.frontOfDriverLicenseExactSizeCheckCompleted = true
        }

        if (this.currentCustomer.insuranceFileLocation) {
          var insurancePhotoFile = {
            loaded: true,
            imageData: this.currentCustomer.insuranceFileLocation,
            fileName: this.currentCustomer.insuranceFileName,
            fileLocation: this.currentCustomer.insuranceFileLocation,
            name: this.currentCustomer.insuranceImageName,
            fileType: this.getFileType(this.currentCustomer.insuranceFileLocation),
            status: 'Active'
          }

          this.insurancePhotoFiles.push(insurancePhotoFile)
        }

        if (this.currentCustomer.backOfDriversFileLocation) {
          let backOfDriversPhotoFile = {
            loaded: true,
            imageData: this.currentCustomer.backOfDriversFileLocation,
            fileName: this.currentCustomer.backOfDriversFileName,
            fileLocation: this.currentCustomer.backOfDriversFileLocation,
            name: this.currentCustomer.backOfDriversImageName,
            fileType: this.getFileType(this.currentCustomer.backOfDriversFileLocation),
            status: 'Active'
          }

          this.backOfDriversPhotoFiles.push(backOfDriversPhotoFile)
        }

        this.addresses = []

        if (this.currentCustomer.addresses && this.currentCustomer.addresses.length > 0) {
          this.currentCustomer.addresses.forEach(function (address) {
            var config = {
              sectionId: this.addresses.length + 100,
              loadCustomerOnly: false
            }
            address.config = config
            this.addresses.push(address)
          }, this)
          const primary = this.addresses.find((x) => x.isPrimary)
          if (!primary) {
            const newPrimary = this.addresses[0]
            if (newPrimary) {
              newPrimary.isPrimary = true
            }
          }
        } else {
          this.addAddress({ loadCustomerOnly: true })
          this.addresses[0].isPrimary = true
        }

        this.workHistory = []

        this.currentCustomer.workHistory.forEach(function (history) {
          var config = {
            sectionId: this.workHistory.length + 100,
            loadCustomerOnly: false
          }
          history.config = config
          this.workHistory.push(history)
        }, this)

        this.personalReferences = []

        this.currentCustomer.personalReferences.forEach(function (personalReference) {
          var config = {
            sectionId: this.personalReferences.length + 100,
            loadCustomerOnly: false
          }
          personalReference.config = config
          this.personalReferences.push(personalReference)
        }, this)

        this.relatives = []

        this.currentCustomer.relatives.forEach(function (relative) {
          var config = {
            sectionId: this.relatives.length + 100,
            loadCustomerOnly: false
          }
          relative.config = config
          this.relatives.push(relative)
        }, this)

        this.emailAddresses = []

        this.currentCustomer.emailAddresses.forEach(function (emailAddress) {
          var config = {
            sectionId: this.emailAddresses.length + 100,
            loadCustomerOnly: false
          }
          emailAddress.config = config
          this.emailAddresses.push(emailAddress)
        }, this)

        this.contactNumbers = []

        this.currentCustomer.contactNumbers.forEach(function (contactNumber) {
          var config = {
            sectionId: this.contactNumbers.length + 100,
            loadCustomerOnly: false
          }
          contactNumber.config = config
          this.contactNumbers.push(contactNumber)
        }, this)

        this.showReview = this.currentCustomer.forReview
        if (this.currentCustomer.driverLicenseState) {
          let usState = this.allStates.find((x) => x.name === this.currentCustomer.driverLicenseState)
          this.selectedState = usState
        }

        this.currentSsn = this.currentCustomer.ssn
      }
    },
    onCanSave: function () {
      if (!this.bankDetails.hasValidationErrors) {
        let customerModel
        if (this.fulfillForDepositCaptureMode) {
          customerModel = {
            ...this.currentCustomer
          }
        } else {
          customerModel = {
            id: this.currentCustomer.id,
            subscriberId: this.$store.state.user.currentUser.subscriberId,
            dealerId: this.selectedDealer.id,
            type: this.selectedType ? this.selectedType.altName : null,
            companyName: this.currentCustomer.companyName,
            firstName: this.currentCustomer.firstName,
            middleName: this.currentCustomer.middleName,
            lastName: this.currentCustomer.lastName,
            initials: this.currentCustomer.initials,
            email: this.currentCustomer.email,
            mobile: this.currentCustomer.mobile,
            office: this.currentCustomer.office,
            home: this.currentCustomer.home,
            fax: this.currentCustomer.fax,
            addresses: [],
            workHistory: [],
            personalReferences: [],
            relatives: [],
            bankId: this.bankDetails.selectedBank ? this.bankDetails.selectedBank.id : 0,
            accountHolder: this.bankDetails.accountHolder || '',
            accountNo: this.bankDetails.accountNo || '',
            accountType: this.bankDetails.selectedAccountType ? this.bankDetails.selectedAccountType.name : 'Check',
            branchNo: this.bankDetails.branchNo || '',
            branchName: this.bankDetails.branchName || '',
            swiftNo: this.bankDetails.swiftNo || '',
            ssn: this.currentCustomer.ssn || '',
            driverLicenseNumber: this.currentCustomer.driverLicenseNumber || '',
            driverLicenseState: this.selectedState ? this.selectedState.name : '',
            dateOfBirth: this.currentCustomer.dateOfBirth ? moment(this.currentCustomer.dateOfBirth).format('MM/DD/YYYY') : null,
            isTermsAccepted: this.currentCustomer.isTermsAccepted,
            isCreditTermsAccepted: this.currentCustomer.isCreditTermsAccepted,
            termsAcceptedAt: this.currentCustomer.termsAcceptedAt,
            creditTermsAcceptedAt: this.currentCustomer.creditTermsAcceptedAt,
            status: this.currentCustomer.status,
            defaultPaymentMethod: this.bankDetails.selectedDefaultMethod ? this.bankDetails.selectedDefaultMethod.name : 'Check',
            maritalStatus: this.selectedMaritalStatus ? this.selectedMaritalStatus.name : null,
            employmentStatus: this.selectedEmploymentStatus ? this.selectedEmploymentStatus.name : null,
            spouseId: this.selectedSpouse ? this.selectedSpouse.id : 0,
            emailAddresses: [],
            contactNumbers: [],
            isTrusted: this.currentCustomer.isTrusted,
            isBlocked: this.currentCustomer.isBlocked,
            allowPaymentWithoutTitle: this.currentCustomer.allowPaymentWithoutTitle,
            driversLicenseResizeRequired: !this.driversLicenseExactSizeConfirmed,
            backOfDriversLicenseResizeRequired: !this.backOfDriversLicenseExactSizeConfirmed,
            customerNo: this.currentCustomer.customerNo
          }

          if (this.showReview) {
            customerModel.forReview = !this.reviewCompleted
          }
          this.saveAddress(customerModel)
          this.saveWorkHistory(customerModel)
          this.savePersonalReference(customerModel)
          this.saveRelative(customerModel)
          this.saveEmailAddresses(customerModel)
          this.saveContactNumbers(customerModel)

          let validCustomerFiles = this.customerPhotoFiles.filter((y) => y.status !== 'Deleted')
          if (validCustomerFiles.length > 0) {
            var photoFile = validCustomerFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
              return {
                uploadName: x.name,
                uniqueId: x.uniqueId
              }
            })

            customerModel.uploadName = photoFile[0].uploadName
            customerModel.uniqueId = photoFile[0].uniqueId
          } else {
            customerModel.uploadName = ''
            customerModel.uniqueId = ''
          }

          let validDriverFiles = this.driversPhotoFiles.filter((y) => y.status !== 'Deleted')
          if (validDriverFiles.length > 0) {
            var driversPhotoFile = validDriverFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
              return {
                uploadName: x.name,
                uniqueId: x.uniqueId
              }
            })

            customerModel.driversUploadName = driversPhotoFile[0].uploadName
            customerModel.driversUniqueId = driversPhotoFile[0].uniqueId
          } else {
            customerModel.driversUploadName = ''
            customerModel.driversUniqueId = ''
          }

          let validInsuranceFiles = this.insurancePhotoFiles.filter((y) => y.status !== 'Deleted')
          if (validInsuranceFiles.length > 0) {
            var insurancePhotoFile = validInsuranceFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
              return {
                uploadName: x.name,
                uniqueId: x.uniqueId
              }
            })

            customerModel.insuranceUploadName = insurancePhotoFile[0].uploadName
            customerModel.insuranceUniqueId = insurancePhotoFile[0].uniqueId
          } else {
            customerModel.insuranceUploadName = ''
            customerModel.insuranceUniqueId = ''
          }

          let validBackOfDriverFiles = this.backOfDriversPhotoFiles.filter((y) => y.status !== 'Deleted')
          if (validBackOfDriverFiles.length > 0) {
            let backOfDriversPhotoFile = validBackOfDriverFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
              return {
                uploadName: x.name,
                uniqueId: x.uniqueId
              }
            })

            customerModel.backOfDriversUploadName = backOfDriversPhotoFile[0].uploadName
            customerModel.backOfDriversUniqueId = backOfDriversPhotoFile[0].uniqueId
          } else {
            customerModel.backOfDriversUploadName = ''
            customerModel.backOfDriversUniqueId = ''
          }
        }

        this.isSaving = true
        this.serverErrorMessage = ''
        this.serverErrors = []

        customerService.update(customerModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.onChangesSaved()
            if (this.mode === 'full-screen') {
              this.onComplete()
            } else {
              this.$emit('on-save', response.customer)
            }
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
        })
      }
    },
    onCancel: function () {
      if (this.mode === 'full-screen') {
        this.onComplete()
      } else {
        this.$emit('on-cancel')
      }
    },
    getColorClassByTransactionType: function (row, status) {
      var result = 'has-text-danger'
      switch (status) {
        case 0:
          result = 'has-text-success'
          break
        case 1:
          result = 'has-text-danger'
          break
        case 2:
          result = 'has-text-info'
          break
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    onViewTransaction: function (transaction) {
      this.currentTransaction = transaction
      this.viewTransaction = true
    },
    closeViewTransaction: function () {
      this.currentTransaction = {}
      this.entityTransactionActive = false
    },
    onViewCustomerTransaction: function (transaction) {
      this.currentTransaction = transaction
      this.entityTransactionActive = true
    },
    closeCropDriverPhoto: function () {
      this.cropDriverPhotoActive = false
      this.driversLicenseExactSizeMode = 0
      if (this.selectedStateRequiresExactSizeDriversLicense && !this.frontOfDriverLicenseExactSizeCheckCompleted) {
        this.frontOfDriverLicenseExactSizeCheckCompleted = true
        this.driversLicenseSizeConfirmationActive = true
      }
    },
    onSaveCroppedDriverPhoto: function () {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        var fileName = 'cropped_photo_' + new Date().getTime() + '.png'
        var file = new File([blob], fileName, {type: 'image/png', lastModified: new Date()})
        if (this.croppedDriverPhotoMode === 1) {
          this.driversPhotoFiles = []
          this.croppedDriverLicenseImages = []
          this.croppedDriverLicenseImages.push(file)
        } else if (this.croppedDriverPhotoMode === 2) {
          this.backOfDriversPhotoFiles = []
          this.croppedBackOfDriverLicenseImages = []
          this.croppedBackOfDriverLicenseImages.push(file)
        } else {
          this.croppedCustomerImages = []
          this.croppedCustomerImages.push(file)
          this.showCropDriverPhotoButton = false
          if (this.selectedStateRequiresExactSizeDriversLicense) {
            this.driversLicenseSizeConfirmationActive = true
          }
        }
        this.croppedDriverPhotoMode = 0
        this.driversLicenseExactSizeMode = 0
      })
      this.cropDriverPhotoActive = false
    },
    cropDriverPhoto: function (mode) {
      this.uploadComplete()
      this.croppedDriverPhotoMode = mode
      let activeCustomerPhotoFiles = this.customerPhotoFiles.filter((x) => x.status === 'Active')
      let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeCustomerPhotoFiles.length === 0 && activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (util.fileIsImage(fileName)) {
          this.cropDriverPhotoActive = true
          this.selectedFile = activeDriverPhotoFiles[0].imageData
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.selectedFile)
          }
        } else if (this.selectedStateRequiresExactSizeDriversLicense) {
          this.driversLicenseSizeConfirmationActive = true
        }
      } else if (mode === 0 && activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (!fileName.includes('cropped_photo') && this.selectedStateRequiresExactSizeDriversLicense) {
          this.driversLicenseSizeConfirmationActive = true
        }
      }
    },
    showCropDriversPhotoModal: function (mode) {
      let activePhotoFiles = []
      var pattern = /^((http|https):\/\/)/
      if (mode === 0) {
        let activeCustomerPhotoFiles = this.customerPhotoFiles.filter((x) => x.status === 'Active')
        if (activeCustomerPhotoFiles.length === 0 && this.driversPhotoFiles.length > 0) {
          let imageData = this.driversPhotoFiles[0].imageData
          if (pattern.test(imageData)) {
            this.cropDriverPhotoActive = true
            let fileName = this.driversPhotoFiles[0].fileName
            let that = this
            let changedConfig = baseService.defaultConfig
            changedConfig.responseType = 'blob'
            this.isLoadingPhoto = true
            axios.get('/document/downloadfile?url=' + imageData + '&fileName=' + fileName, changedConfig)
              .then(function (response) {
                var reader = new window.FileReader()
                reader.readAsDataURL(response.data)
                reader.onload = function () {
                  that.isLoadingPhoto = false
                  var imageDataUrl = reader.result
                  that.selectedFile = imageDataUrl
                  if (that.$refs.cropper) {
                    that.$refs.cropper.replace(that.selectedFile)
                  }
                  that.showCropDriverPhotoButton = false
                }
              }).catch(function (error) {
                console.log(error)
                this.isLoadingPhoto = false
              }).then(function () {
                changedConfig.responseType = 'json'
              })
          } else {
            this.cropDriverPhoto(mode)
          }
        }
      } else if (mode === 1 || mode === 2) {
        if (mode === 1) {
          activePhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
        } else if (mode === 2) {
          activePhotoFiles = this.backOfDriversPhotoFiles.filter((x) => x.status === 'Active')
        }
        if (activePhotoFiles && activePhotoFiles.length > 0) {
          this.croppedDriverPhotoMode = mode
          let imageData = activePhotoFiles[0].imageData
          if (pattern.test(imageData)) {
            this.cropDriverPhotoActive = true
            let fileName = activePhotoFiles[0].fileName
            let that = this
            let changedConfig = baseService.defaultConfig
            changedConfig.responseType = 'blob'
            this.isLoadingPhoto = true
            axios.get('/document/downloadfile?url=' + imageData + '&fileName=' + fileName, changedConfig)
              .then(function (response) {
                var reader = new window.FileReader()
                reader.readAsDataURL(response.data)
                reader.onload = function () {
                  that.isLoadingPhoto = false
                  var imageDataUrl = reader.result
                  that.selectedFile = imageDataUrl
                  if (that.$refs.cropper) {
                    that.$refs.cropper.replace(that.selectedFile)
                  }
                  that.showCropDriverPhotoButton = false
                }
              }).catch(function (error) {
                console.log(error)
                this.isLoadingPhoto = false
              }).then(function () {
                changedConfig.responseType = 'json'
              })
          } else {
            this.cropDriverPhoto(mode)
          }
        }
      }
    },
    setDefaultTab: function () {
      if (this.defaultTab !== '') {
        switch (this.defaultTab) {
          case 'Definition' || '':
            this.tabIndex = 0
            break
          case 'Deals':
            this.tabIndex = 1
            break
          case 'Address':
            this.tabIndex = 2
            break
          case 'Work History':
            this.tabIndex = 3
            break
          case 'Personal References':
            this.tabIndex = 4
            break
          case 'Relatives':
            this.tabIndex = 5
            break
          case 'Bank':
            this.tabIndex = 6
            break
          case 'Photo':
            this.tabIndex = 7
            break
          case 'Transactions':
            this.tabIndex = 8
            break
          case 'Additional Email/Mobile':
            this.tabIndex = 9
            break
          default:
            this.tabIndex = 0
            break
        }
      }
    },
    cropDriverPhotoExactSize: function () {
      let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (util.fileIsImage(fileName)) {
          this.cropDriverPhotoActive = true
          this.selectedFile = activeDriverPhotoFiles[0].imageData
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.selectedFile)
          }
          this.frontOfDriverLicenseExactSizeCheckCompleted = true
        } else {
          this.croppedDriverPhotoMode = 0
        }
      }
    },
    onConfirmDriversLicenseExactSize: function (confirmed) {
      if (this.driversLicenseExactSizeMode === 1) {
        this.backOfDriversLicenseExactSizeConfirmed = confirmed
      } else {
        this.driversLicenseExactSizeConfirmed = confirmed
        this.frontOfDriverLicenseExactSizeCheckCompleted = true
      }
      this.driversLicenseSizeConfirmationActive = false
      if (confirmed === false && this.driversLicenseExactSizeMode === 0) {
        this.croppedDriverPhotoMode = 1
        this.cropDriverPhotoExactSize()
      } else if (confirmed === false && this.driversLicenseExactSizeMode === 1) {
        this.croppedDriverPhotoMode = 2
        this.cropBackOfDriverPhotoExactSize()
      }
    },
    cropBackOfDriverPhotoExactSize: function () {
      let activeDriverPhotoFiles = this.backOfDriversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (util.fileIsImage(fileName)) {
          this.croppedDriverPhotoMode = 2
          this.cropDriverPhotoActive = true
          this.selectedFile = activeDriverPhotoFiles[0].imageData
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.selectedFile)
          }
        } else {
          this.croppedDriverPhotoMode = 0
        }
      }
    },
    backOfDriversLicenseUploaded: function () {
      this.uploadComplete()
      let activeDriverPhotoFiles = this.backOfDriversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (util.fileIsImage(fileName) && !fileName.includes('cropped_photo') && this.stateRequiredBackOfDriversLicense) {
          this.driversLicenseExactSizeMode = 1
          this.driversLicenseSizeConfirmationActive = true
        }
      }
    }
  },
  mounted: function () {
    this.currentCustomerId = (this.customerId === 0) ? this.$route.params.id : this.customerId
    if (this.$route.params.defaultTab) {
      this.defaultTab = this.$route.params.defaultTab
    }
    this.setDefaultTab()
    this.fetchCustomer(this.currentCustomerId)
  },
  watch: {
    selectedMaritalStatus: function (newValue, oldValue) {
      if (this.selectedMaritalStatus && this.selectedMaritalStatus.id === 0) {
        this.selectedSpouse = null
      }
    },
    canSave: function (newValue, oldValue) {
      if (newValue && newValue === true) {
        this.onCanSave()
      }
      this.getValidationErrors = false
      this.bankDetails.canSave = null
    },
    driversPhotoFiles: {
      handler (newValue, oldValue) {
        let activeCustomerPhotoFiles = this.customerPhotoFiles.filter((x) => x.status === 'Active')
        let activeDriverPhotoFiles = newValue.filter((x) => x.status === 'Active')
        if ((activeCustomerPhotoFiles.length === 0) && (activeDriverPhotoFiles.length > 0)) {
          let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
          this.showCropDriverPhotoButton = util.fileIsImage(fileName)
        } else {
          this.showCropDriverPhotoButton = false
        }
        if (activeDriverPhotoFiles && activeDriverPhotoFiles.length === 0) {
          this.frontOfDriverLicenseExactSizeCheckCompleted = false
        }
      },
      deep: true
    },
    customerPhotoFiles: {
      handler (newValue, oldValue) {
        let activeCustomerPhotoFiles = newValue.filter((x) => x.status === 'Active')
        let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
        if (activeDriverPhotoFiles.length === 0 || (activeCustomerPhotoFiles.length > 0)) {
          this.showCropDriverPhotoButton = false
        } else {
          let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
          this.showCropDriverPhotoButton = util.fileIsImage(fileName)
        }
      },
      deep: true
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  .modal-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }
</style>

<template>
  <div v-if="isArchiveable" :name="instanceId" class="is-inline-block">
    <div v-if="hasArchiveAccess && isArchiveAllowed">
      <span v-if="isListMode" class="list-icon clickable" :id="instanceId" title="Click to archive the selected item" @click.stop.prevent="onArchive">
        <i class="fal fa-trash fa-lg"></i>
      </span>
      <div class="quick-action" v-else>
        <button :id="instanceId" class="button" @click.stop.prevent="onArchive" title="Click to archive the selected item">
          <b-icon pack="fal" icon="trash" custom-size="fa-lg"></b-icon>
        </button>
      </div>
      <portal to="global-modal-portal" v-if="isArchiveConfirmationActive" :disabled="!isListMode">
        <b-modal :active.sync="isArchiveConfirmationActive" :width="500" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Confirm Archive Action</p>
          </header>
          <section class="modal-card-body view-container">
            <div v-if="isError">
              <p v-if="serverErrors.length > 0" ref="generalErrorBlock">
                <b class="title is-7 is-paddingless">Please correct the following error(s):</b>
                <ul class="errors">
                <li v-for="error in serverErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
                </ul>
                <br/>
              </p>
              <p v-if="serverErrorMessage.length">
                <ul class="errors">
                <li>{{ serverErrorMessage }}</li>
                </ul>
                <br/>
              </p>
            </div>
            <div v-else>
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="notification is-flex is-warning">
                    <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
                      <span class="ml-10">
                        Are you sure you wish to archive this scheduled payment: {{scheduledPaymentItem.name}}?
                      </span>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Stock No</label>
                    <div class="field">
                      <a @click.stop.prevent="onStockNoClick(scheduledPaymentItem)">
                        <span class="has-text-weight-semibold" :title="scheduledPaymentItem.stockNo">{{ scheduledPaymentItem.stockNo }}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Customer / Payee</label>
                    <div class="field">
                      {{ getCustomerName(scheduledPaymentItem) }}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Source</label>
                    <div class="field">
                      {{ scheduledPaymentItem.paymentTypeDescription }}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Amount</label>
                    <div class="field">
                      {{ scheduledPaymentItem.amount | currency('$', 2)  }}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Requested By</label>
                    <div class="field">
                      {{ scheduledPaymentItem.requestedByName || scheduledPaymentItem.createdByFullName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-success" :class="{'is-loading': isSaving }" @click="onConfirmArchive()">Confirm</button>
            <button class="button is-danger" :class="{'is-loading': isSaving }" @click="onCancelArchive">Cancel</button>
          </footer>
          </div>
        </b-modal>
      </portal>
    </div>
  </div>
  <div v-else class="is-inline-block">
    <div v-if="hasRestoreAccess && isRestorable">
      <section :id="instanceId" v-if="isListMode">
        <span class="list-icon clickable" title="Click to restore the selected item" @click.stop.prevent="onRestore">
          <i class="fal fa-heart-rate fa-lg"></i>
        </span>
      </section>
      <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
        <button class="button" @click.stop.prevent="onRestore" title="Click to restore the selected item">
          <b-icon pack="fal" icon="heart-rate" custom-size="fa-lg"></b-icon>
        </button>
      </div>
      <portal to="global-modal-portal" v-if="isRestoreConfirmationActive">
        <b-modal :active.sync="isRestoreConfirmationActive" :width="640" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Confirm Restore Action</p>
          </header>
          <section class="modal-card-body view-container">
            <div v-if="isError">
              <p v-if="serverErrors.length > 0" ref="generalErrorBlock">
                <b class="title is-7 is-paddingless">Please correct the following error(s):</b>
                <ul class="errors">
                <li v-for="error in serverErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
                </ul>
                <br/>
              </p>

              <p v-if="serverErrorMessage.length">
                <ul class="errors">
                <li>{{ serverErrorMessage }}</li>
                </ul>
                <br/>
              </p>
            </div>
            <div v-else>
              <h3 class="title is-6">Are you sure you wish to restore deal scheduled payment: {{scheduledPaymentItem.name}}?</h3>
              <div class="columns is-multiline">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Stock No</label>
                    <div class="field">
                      <a @click.stop.prevent="onStockNoClick(scheduledPaymentItem)">
                        <span class="has-text-weight-semibold" :title="scheduledPaymentItem.stockNo">{{ scheduledPaymentItem.stockNo }}</span>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Customer / Payee</label>
                    <div class="field">
                      {{ getCustomerName(scheduledPaymentItem) }}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Source</label>
                    <div class="field">
                      {{ scheduledPaymentItem.paymentTypeDescription }}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Amount</label>
                    <div class="field">
                      {{ scheduledPaymentItem.amount | currency('$', 2)  }}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Requested By</label>
                    <div class="field">
                      {{ scheduledPaymentItem.requestedByName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" :class="{'is-loading': isSaving }" @click="onConfirmRestore()">Confirm</button>
            <button class="button" :class="{'is-loading': isSaving }" @click="onCancelRestore">Cancel</button>
          </footer>
          </div>
        </b-modal>
      </portal>
    </div>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import dealScheduledPaymentService from '../../../services/dealScheduledPaymentService'
import { createNamespacedHelpers } from 'vuex'
import utilitiesMixin from '@/mixins/generic/utilities'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  mixins: [utilitiesMixin],
  name: 'ArchiveScheduledPaymentAction',
  props: {
    scheduledPaymentItem: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isArchiveConfirmationActive: false,
      isRestoreConfirmationActive: false,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4()
    }
  },
  methods: {
    onArchive: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isArchiveConfirmationActive = true
    },
    onRestore: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isRestoreConfirmationActive = true
    },
    onConfirmArchive: function () {
      this.isSaving = true
      dealScheduledPaymentService.archive([this.scheduledPaymentItem]).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          eventBus.$emit('reload-payment-schedule-list')
          this.$emit('on-sync-inventory-callback', this.scheduledPaymentItem.id)
          this.successToast('Scheduled payment has been marked as archived.', 2500)
        }
        this.isSaving = false
        this.isArchiveConfirmationActive = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
      this.isSaving = true
    },
    onCancelArchive: function () {
      this.isArchiveConfirmationActive = false
    },
    onConfirmRestore: function () {
      this.isSaving = true
      dealScheduledPaymentService.restore([this.scheduledPaymentItem]).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          eventBus.$emit('reload-payment-schedule-list')
          this.successToast('Scheduled payment has been restored.', 2500)
        }
        this.isSaving = false
        this.isRestoreConfirmationActive = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
      this.isSaving = true
    },
    onCancelRestore: function () {
      this.isRestoreConfirmationActive = false
    },
    getCustomerName: function (row) {
      if (row.customerName) {
        return row.customerName
      } else if (row.supplierName) {
        return row.supplierName
      } else if (row.vendorName) {
        return row.vendorName
      } else {
        return row.bankName
      }
    },
    onStockNoClick: function (scheduledPaymentItem) {
      let that = this

      this.$copyText(scheduledPaymentItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + scheduledPaymentItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    isArchiveAllowed: function () {
      return !this.scheduledPaymentItem.isPaid
    },
    invalidForArchivingReasons: function () {
      if (!this.hasArchiveAccess) {
        return 'You do not have access to the archive function.'
      }
      return 'This current items status prevents archiving.'
    },
    isArchiveable: function () {
      return this.scheduledPaymentItem.status !== 'Archived'
    },
    isRestorable: function () {
      return this.scheduledPaymentItem.status === 'Archived'
    },
    invalidForRestoreReasons: function () {
      if (!this.hasRestoreAccess) {
        return 'You do not have access to the restore function.'
      }
      return 'This item cannot be restored as it is not archived.'
    },
    hasArchiveAccess: function () {
      let allowedPermissions = ['payment-schedule.archive']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    hasRestoreAccess: function () {
      let allowedPermissions = ['payment-schedule.restore']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isError: function () {
      return this.serverErrorMessage.length > 0 || this.serverErrors.length > 0
    }
  },
  watch: {
  },
  mounted () {
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }
</style>

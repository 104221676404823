<template>
  <portal to="global-modal-portal" v-if="showReport">
    <b-modal :active.sync="showReport" :width="modalWidth" scroll="keep" :has-modal-card="true" :canCancel="false" id="autocheck-report-modal">
      <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">AutoCheck Report</p>
        </header>
        <section class="modal-card-body">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <div class="content" v-if="!isLoadingReport  && serverErrorMessage.length === 0 && serverErrors.length === 0">
              <iframe :srcdoc="reportText" class="content" frameborder="0"></iframe>
            </div>
            <div class="aligner-item" style="text-align:center" v-else-if="isLoadingReport && serverErrorMessage.length === 0 && serverErrors.length === 0">
              <img src="@/assets/ajax-loader.gif">
            </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" :class="{'is-loading': isGeneratingReport }" @click="hideReportModal" type="button">Close</button>
          <button class="button" :class="{'is-loading': isGeneratingReport }" @click="generateReport" v-if="vinNo">Download</button>
        </footer>
      </div>
    </b-modal>
  </portal>
  <pdf-modal
      :pdfFile="autoCheckSelectedPdf"
      :usePortal="true"
      :portalOrder="2"
      v-on:on-pdf-downloaded="onPreviewed"
      v-on:on-pdf-closed="onPreviewClosed"
      v-else-if="autoCheckSelectedPdf && autoCheckSelectedPdf.src">
  </pdf-modal>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import appraisalService from '@/services/appraisalService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'AutoCheckReportControl',
  components: {
    'error-display-component': ErrorDisplayComponent,
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    vinNo: {
      type: String,
      default: ''
    },
    showAutoCheckReportModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isRefreshing: false,
      autoCheckLink: true,
      reportText: '',
      showReport: false,
      isLoadingReport: false,
      serverErrorMessage: '',
      serverErrors: [],
      modalWidth: 960,
      isGeneratingReport: false,
      autoCheckSelectedPdf: null,
      downloadUrl: ''
    }
  },
  computed: {
    ...mapDealerState(['currentDealer'])
  },
  methods: {
    getAutoCheckReport: function () {
      let vinNo = this.vinNo
      if (this.reportText) {
        this.showReportModal()
      } else if (!this.reportText && this.isLoadingReport === false && vinNo !== '') {
        this.showReportModal()
        this.isLoadingReport = true
        appraisalService.getAutoCheckReport(vinNo, this.currentDealer).then(response => {
          this.isLoadingReport = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.reportText = response
            this.$emit('on-render-report', response)
          }
        }).catch((error) => {
          this.$emit('on-autocheck-report-failed')
          this.isLoadingReport = false
          this.serverErrorMessage = error.message
        })
      }
    },
    showReportModal: function () {
      this.showReport = true
    },
    hideReportModal: function () {
      this.$emit('on-hide-autocheck-report')
      this.showReport = false
    },
    setMaxModalWidth: function () {
      let viewWidth = window.innerWidth
      let newWidth = viewWidth * 90 / 100
      this.modalWidth = newWidth
    },
    generateReport () {
      this.isGeneratingReport = true
      appraisalService.generateAutoCheckReportPdf(this.vinNo).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
          this.isGeneratingReport = false
        } else {
          this.downloadUrl = response.autoCheckPdfUrl
          this.isGeneratingReport = false
          this.showReport = false
          this.onPreview()
        }
      }).catch((error) => {
        this.isGeneratingReport = false
        this.serverErrorMessage = error.message
      })
    },
    onPreview: function () {
      var extractedFileName = this.downloadUrl.split('/').pop()
      var docUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.downloadUrl)
      docUrl = encodeURI(docUrl)
      this.autoCheckSelectedPdf = {
        name: 'Autocheck Report',
        src: docUrl,
        fileName: extractedFileName,
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
    },
    onPreviewClosed: function () {
      this.autoCheckSelectedPdf = null
    }
  },
  watch: {
    showAutoCheckReportModal: function (newValue, oldValue) {
      if (newValue) {
        this.getAutoCheckReport()
      }
    }
  },
  mounted: function () {
    this.reportText = ''
    this.setMaxModalWidth()
    let that = this
    window.onresize = () => {
      that.setMaxModalWidth()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .status-icon {
    width: 13px;
    height: 12px;
    margin-right:12px;
  }

  .autocheck-container-display-only {
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    margin-top:10px;
  }

  .autocheck-container {
    margin-top: 20px;
    padding: 0;
    margin-left:0;
    margin-right:0;
  }

  .autocheck-owner-icons {
    height: 12px;
    width: 12px;
    margin-top:-15px;
  }

  .autocheck-warning {
    background-image: url('@/assets/static/carfax/ico_accident.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .autocheck-danger {
    background-image: url('@/assets/static/carfax/ico_alert.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .autocheck-recall {
    background-image: url('@/assets/static/carfax/ico_recall.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .autocheck-ok {
    background-image: url('@/assets/static/carfax/ico_green_check.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .refresh-status-label {
    margin-left:1em;
  }

  .cd .column {
    padding:0;
  }

  .modal-card-body {
    position:relative;
    max-height: calc(100vh - 220px);
    max-width: 90vw !important;
    width: 90vw !important;
    height: calc(100vh - 220px);
    overflow-y: auto;
    background: white;
  }
  .modal-card {
    max-width: 90vw !important;
    width: 90vw !important;
  }
  .content {
    display: inline-block;
    height: 100%;
    width: 100%;
  }
</style>

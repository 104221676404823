import axios from 'axios'
import axiosCancel from 'axios-cancel'
import config from './config'
import NProgress from 'nprogress'
import store from '@/store'
import defaultAppConfig from '@/config/index'

const isTestMode = false
var baseApiUrl = config.apiUrl
axios.defaults.baseURL = baseApiUrl
axios.defaults.withCredentials = true
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest'
axios.defaults.headers.common['X-App-Version'] = defaultAppConfig.appVersion

const defaultConfig = {
}

axiosCancel(axios, {
  debug: false
})

axios.interceptors.request.use(function (config) {
  if (typeof window === 'undefined') {
    return config
  }
  return config
})

// before a request is made start the nprogress
axios.interceptors.request.use(config => {
  NProgress.start()
  return config
})

// before a response is returned stop nprogress
axios.interceptors.response.use(response => {
  NProgress.done()
  return response
}, function (error) {
  NProgress.done()
  if (axios.isCancel(error) === false) {
    if (error && error.response && error.response.status) {
      if (error.response.status === 409) {
        var dt = new Date()
        let rnd = Math.round(dt.getTimezoneOffset() / 60) + 'dds' + dt.getTime() + '-' + Math.random()
        let destination = error.response.data || error.response.statusText || '/index.html?rnd=' + rnd
        window.location.href = destination
      }
      if (error.response.status === 401 && error.config && !error.config.__isRetryRequest) {
        store.dispatch('auth/logoutRequest')
      }
    }
  }
  return Promise.reject(error)
})

export default { isTestMode, defaultConfig, baseApiUrl }

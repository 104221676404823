<template>
  <div class="columns" :class="containerClass" :name='instanceName' :id='instanceName' v-if="actionsAvailable()">
    <div class="column is-narrow">
      <label class="label is-inline-block is-size-7" v-if="!hideTitle">{{title}}</label>
      <p class="item-container"><slot></slot></p>
    </div>
  </div>
</template>

<script>

export default {
  name: 'QuickActionContainer',
  props: {
    containerClass: {
      type: String,
      default: 'quick-action-container'
    },
    title: {
      type: String,
      default: 'Quick Actions'
    },
    hideTitle: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      instanceName: 'quick-action-container-' + this.$uuid.v4()
    }
  },
  computed: {
  },
  watch: {
  },
  methods: {
    actionsAvailable () {
      return !!this.$slots.default
    }
  },
  mounted () {

  },
  created () {
  }
}

</script>

<style scoped>
  .quick-action-container {
    position:relative;
    z-index: 9999 !important;
  }

  .quick-action-no-z-container {
    position:relative;
  }

  .item-container {
    display: flex;
    justify-content: flex-start;
    max-width: 420px;
    flex-wrap: wrap;
    line-height: 42px;
  }
</style>

<template>
  <span v-if="!isNewNoteActive">
    <span :id="instanceId" :name="instanceId" class="icon is-inline-block has-text-centered" :class="noteColorClass" :title="applicableTitle" @click.stop.prevent="onAddNewNote()" v-if="isListMode">
      <i class="fal fa-comment fa-lg"/>
    </span>
    <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
        <button class="button" @click.stop.prevent="onAddNewNote()" :title="applicableTitle">
          <b-icon pack="fal" icon="comment" custom-size="fa-lg"></b-icon>
        </button>
        <span v-if="label.length > 0">{{label}}</span>
    </div>
  </span>
  <portal to="global-modal-portal" v-else>
    <b-modal :active.sync="isNewNoteActive" :width="640" scroll="keep" :has-modal-card="true" v-if="inventoryItem">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <div class="columns">
            <div class="column is-narrow is-7">
              <p class="modal-card-title">Adding new note for item <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a></p>
            </div>
            <div class="column is-narrow" style="padding-top:1px;">
              <div class="field">
                <div class="field-label is-small">
                  <label class="label">Wholesale Now</label>
                </div>
                <div class="field-body" style="flex-grow:1">
                  <div class="field">
                    <div class="control">
                      <b-switch name="wholesaleNow" v-model="wholesaleNow" type="is-info" size="is-small" @click.native="onWholesaleNowClicked"></b-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="column is-narrow" style="padding-top:1px;">
              <div class="field">
                <div class="field-label is-small">
                  <label class="label">For Review</label>
                </div>
                <div class="field-body" style="flex-grow:unset">
                  <div class="field">
                    <div class="control">
                      <b-switch name="forReview" v-model="forReview" type="is-info" size="is-small"></b-switch>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section class="modal-card-body" style="padding:12px">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form class="view-container">
            <div class="columns is-multiline">
              <div class="column is-6">
                <div class="field">
                  <label class="label">
                    Vehicle
                  </label>
                  <div class="field">
                    {{inventoryItem.name}}
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">
                    Vin No
                  </label>
                  <div class="field">
                    {{inventoryItem.vinNo}}
                  </div>
                </div>
              </div>
              <div class="column is-12" v-if="inventoryItem.note !== ''">
                <label class="label">
                  Last Entry
                </label>
                <div class="field">
                  {{inventoryItem.note}}
                </div>
              </div>
              <div class="column is-12">
                <div class="field">
                  <label class="label">
                    Add Entry
                  </label>
                  <div class="control">
                    <note-capture v-model="inlineNotes" :showInventoryNoteTypeSelector="true" label="Content" :startWith="startWith" :required="contentRequired" :entityId="this.inventoryItem.id"></note-capture>
                  </div>
                </div>
              </div>
              <div class="column is-12">
                <note-timeline-view v-model="inventoryItemNotes" entity="inventory"></note-timeline-view>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="saveNote()">Save</button>
          <button class="button is-danger" @click="isNewNoteActive = false">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </portal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import inventoryService from '../../services/inventoryService'
import NoteCapture from '@/components/generic/NoteCapture'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import NoteTimelineView from '@/components/generic/NoteTimelineView'
import eventBus from '@/eventBus'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'CaptureNewInventoryNote',
  components: {
    'note-capture': NoteCapture,
    'error-display-component': ErrorDisplayComponent,
    'note-timeline-view': NoteTimelineView
  },
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: 'is-width-600'
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    },
    lastNote: {
      type: String,
      default: ''
    },
    specialNote: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      wholesaleNowChanged: false,
      originalWholesaleNow: this.inventoryItem.wholesaleNow,
      wholesaleNow: this.inventoryItem.wholesaleNow,
      forReview: this.inventoryItem.forReview,
      isNewNoteActive: false,
      inlineNotes: [],
      startWith: '',
      isSaving: false,
      instanceId: this.$uuid.v4(),
      serverErrors: [],
      serverErrorMessage: '',
      inventoryItemNotes: []
    }
  },
  methods: {
    onWholesaleNowClicked: function () {
      this.wholesaleNowChanged = true
    },
    onAddNewNote: function () {
      this.wholesaleNow = this.inventoryItem.wholesaleNow
      this.inlineNotes = []
      this.isNewNoteActive = true
      this.fetchNotes()
    },
    saveNote: function () {
      if (this.inlineNotes.length > 0 || !this.contentRequired) {
        let inlineNote = this.inlineNotes.length > 0 ? this.inlineNotes[0] : ''

        // Ensure that they didnt remove the auto added wholesale now copy
        if (this.wholesaleNowChanged) {
          if (this.wholesaleNow === true) {
            if (inlineNote && !inlineNote.note.startsWith(this.wholesaleNowCopy)) {
              inlineNote.note = this.wholesaleNowCopy + ' ' + inlineNote.note
            }
          }

          if (this.wholesaleNow === false) {
            if (inlineNote && !inlineNote.note.startsWith(this.unWholesaleNowCopy)) {
              inlineNote.note = this.unWholesaleNowCopy + ' ' + inlineNote.note
            }
          }
        }

        this.serverErrorMessage = ''
        this.serverErrors = []
        this.isSaving = true
        inventoryService.addNote({inventoryId: this.inventoryItem.id, ...inlineNote, wholesaleNow: this.wholesaleNow, forReview: this.forReview}).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.inventoryItem.note = inlineNote.note
            this.inventoryItem.isHotNote = inlineNote.isHotNote
            this.inventoryItem.wholesaleNow = this.wholesaleNow
            this.originalWholesaleNow = this.wholesaleNow
            this.inventoryItem.forReview = this.forReview
            if (this.inventoryItem.notes) {
              this.inventoryItem.notes = response.inventory.notes
            }
            this.inlineNotes = []
            this.isNewNoteActive = false
            eventBus.$emit('inventory-changed', {id: this.inventoryItem.id})
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
        })
      }
    },
    fetchNotes: function () {
      inventoryService.fetchNotesByInventoryId(this.inventoryItem.id).then(response => {
        this.inventoryItemNotes = response
      }).catch((error) => {
        this.serverErrorMessage = error.message
      })
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  computed: {
    ...mapUserGetters(['currentUser', 'hasFeatureAccess']),
    wholesaleNowCopy: function () {
      return 'Marked for wholesale by ' + this.currentUser.fullName + '. '
    },
    unWholesaleNowCopy: function () {
      return this.currentUser.fullName + ' removed this from Wholesale Now list. '
    },
    contentRequired: function () {
      return !this.forReview
    },
    applicableTitle: function () {
      let defaultNote = 'Click to add a new note to this inventory record'
      if (this.lastNote !== '' && this.specialNote !== '') {
        return 'Note: ' + this.lastNote + '\r\n' + 'Special Note: ' + this.specialNote + '\r\n\r\n' + defaultNote
      } else if (this.lastNote !== '' && this.specialNote === '') {
        return this.lastNote + '\r\n\r\n' + defaultNote
      } else if (this.lastNote === '' && this.specialNote !== '') {
        return 'Special Note: ' + this.specialNote + '\r\n\r\n' + defaultNote
      } else {
        return defaultNote
      }
    },
    noteColorClass: function () {
      let baseColor = 'has-text-black'
      if (this.specialNote !== '') {
        baseColor = 'has-text-danger'
      } else if (this.lastNote !== '') {
        baseColor = 'has-text-primary'
      }

      return baseColor
    }
  },
  watch: {
    wholesaleNow: function (newValue, oldValue) {
      if (newValue === true) {
        if (this.originalWholesaleNow === true) {
          this.startWith = ''
        } else {
          this.startWith = this.wholesaleNowCopy
        }
      } else {
        if (!this.originalWholesaleNow || this.originalWholesaleNow === false) {
          this.startWith = ''
        } else {
          this.startWith = this.unWholesaleNowCopy
        }
      }
    },
    inventoryItem: function (newValue, oldValue) {
      if (newValue) {
        this.wholesaleNow = newValue.wholesaleNow
        this.forReview = newValue.forReview
      }
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-card-body {
    overflow:auto;
    height: calc(50vh - 10px);
  }
</style>

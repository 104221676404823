<template>
    <div class="is-inline-block">
      <div class="is-inline-block">
        <div class="quick-action">
          <button type="button" :class="getColorClassByWorkflowStatus" class="button" @click="updateIdentityVerifcationModalState(true)" :title="getTitle">
            <i class="icon fal fa-id-card fa-lg" v-if="!isLoading"/>
            <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
          </button>
        </div>
      </div>
      <portal to="global-modal-portal" v-if="isDialogOpen">
        <b-modal :active.sync="isDialogOpen" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <div class="subtitle is-6 has-text-centered has-text-info is-marginless">{{headerTitle}}</div>
            </header>
            <section class="modal-card-body view-container" style="padding: 12px; width: 769px !important;">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
              <div class="columns">
                <div class="column is-12">
                  <form data-vv-scope="recipient-form">
                    <div class="field">
                      <label class="label">Recipients<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <multiselect
                        :multiple="true"
                        :taggable="true"
                        v-validate="{'required': true}"
                        data-vv-scope="recipient-form"
                        data-vv-as="recipient"
                        data-vv-validate-on="input|close"
                        :class="{'is-danger': errors.has('recipient-form.recipients') }"
                        @tag="onRecipientAdded"
                        name="recipients"
                        label="fullName"
                        trackBy="email"
                        tagPlaceholder="Enter to add email address"
                        v-model="selectedRecipients"
                        :options="selectedRecipients"
                        :disabled="true">
                      </multiselect>
                      <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Subject</label>
                    <div class="control">
                      <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Message (optional)</label>
                    <div class="control">
                      <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-6 field" v-if="isCoCustomerSpouse">
                  <p class="has-text-danger is-pulled-left" style="margin-left:10px;">
                    {{'Spouse is co-applicant, only primary applicant will be mailed.'}}
                  </p>
                </div>
                <div class="column is-12 field">
                  <a class="is-link is-pulled-right" @click="onEditCustomer(currentDeal.purchaserId)" style="margin-left:10px;">
                    {{this.currentDeal.purchaserContactName}}
                  </a>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <div class="columns" style="width: 100%">
                <div class="column is-12">
                  <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onEmailCreditApplication()" type="button">Send</button>
                  <button class="button is-danger" @click="updateIdentityVerifcationModalState(false)" type="button">Cancel</button>
                </div>
              </div>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="editCustomerActive">
        <b-modal :active.sync="editCustomerActive" scroll="keep" :has-modal-card="true" id="edit-customer-modal">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
            </header>
            <section class="modal-card-body">
              <edit-customer :customerId="editCustomerId" mode="modal" actionPortal="modal-footer" headerPortal="modal-header" v-on:on-save="onCustomerSaved" v-on:on-cancel="onCustomerCancelled"></edit-customer>
            </section>
            <footer class="modal-card-foot">
              <portal-target name="modal-footer" class="actions" slim/>
          </footer>
          </div>
        </b-modal>
      </portal>
    </div>
</template>

<script>
import eventBus from '@/eventBus'
import customerService from '@/services/customerService'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import EditCustomer from '@/components/configuration/customers/EditCustomer'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'DealCustomerIdentityVerificationAction',
  mixins: [validateBeforeSubmitMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'edit-customer': EditCustomer
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isDialogOpen: false,
      instanceId: this.$uuid.v4(),
      selectedRecipients: [],
      optionalMessage: '',
      overridingSubject: '',
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      editCustomerActive: false,
      editCustomerId: 0,
      currentCustomer: null,
      termsAccepted: false,
      showCreditAppCompletedMsg: true,
      isLoading: false
    }
  },
  computed: {
    ...mapUserGetters(['currentUser']),
    ...mapConfigGetters(['definedTypes']),
    headerTitle: function () {
      return 'Send Title Verification'
    },
    isCoCustomerSpouse: function () {
      return this.currentDeal.coCustomerRelationship === 'Spouse' && this.currentDeal.coCustomerId > 0
    },
    getColorClassByWorkflowStatus: function () {
      var result = 'has-text-grey'
      if (this.currentCustomer) {
        if (this.currentCustomer.identityVerificationStatus === 'Sent') {
          result = 'has-text-orange'
        } else if (this.currentCustomer.identityVerificationStatus === 'Completed') {
          result = 'has-text-success'
        }
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getTitle: function () {
      let result = 'Click to send out identity verification form to customer.'
      if (this.currentCustomer) {
        if (this.currentCustomer.identityVerificationStatus === 'Sent') {
          result = 'Title verification has been sent.'
        } else if (this.currentCustomer.identityVerificationStatus !== 'Completed') {
          result = 'Click to send out identity verification form to customer.'
        } else if (this.currentCustomer.identityVerificationStatus === 'Completed') {
          result = 'Identity verification has been sent and completed'
        }
      }
      return result
    }
  },
  methods: {
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data,
        email: data
      }

      this.selectedRecipients.push(recipient)
    },
    clearAllRecipients: function () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedRecipients = []
      this.currentCustomer = null
    },
    updateIdentityVerifcationModalState: function (state) {
      this.isDialogOpen = state
      if (this.currentDeal.purchaserId !== null && state === true) {
        this.overridingSubject = 'Title Verification. - Your info is required.'
        this.optionalMessage = 'Please navigate to our secure webpage to complete your title verification process.'
        this.selectedRecipients = []

        if (this.currentDeal && (this.currentDeal.purchaserContactEmail !== '' || this.currentDeal.purchaserContactMobile !== '')) {
          let proposedName = this.currentDeal.purchaserContactName !== '' ? this.currentDeal.purchaserContactName : this.currentDeal.purchaserName
          var data = {
            id: this.currentDeal.purchaserId,
            mobile: this.currentDeal.purchaserContactMobile,
            email: this.currentDeal.purchaserContactEmail,
            fullName: proposedName + '(' + this.currentDeal.purchaserContactEmail + '  ' + this.currentDeal.purchaserContactMobile + ')',
            primaryApp: true,
            name: proposedName
          }
          this.selectedRecipients.push(data)
        }
      }

      this.loadData()

      if (state === false) {
        this.clearAllRecipients()
      }
    },
    onEmailCreditApplication: function () {
      if (this.currentDeal) {
        let emailAddresses = this.selectedRecipients.map(e => e.email).join(', ')
        let primaryApplicantMobile = (this.selectedRecipients.find(x => x.primaryApp)) ? this.selectedRecipients.find(x => x.primaryApp).mobile : ''

        var customerCreditApplicationRequestModel = {
          dealId: this.currentDeal.id,
          customerId: this.currentDeal.purchaserId,
          userId: this.currentUser.id,
          dealerId: this.currentDeal.dealerId,
          toEmailAddress: emailAddresses,
          subject: this.overridingSubject,
          content: this.optionalMessage,
          type: null,
          hasCoApplicant: false,
          primaryApplicantMobile: primaryApplicantMobile,
          coApplicantMobile: ''
        }

        this.isSaving = true
        this.serverErrorMessage = ''
        this.serverErrors = []

        customerService.createIdentityVerificationRequest(customerCreditApplicationRequestModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.clearAllRecipients()
            this.isDialogOpen = false
            eventBus.$emit('reload-deal-list')
            eventBus.$emit('reload-deal')
          }
        }).catch((error) => {
          this.isSaving = false
          this.serverErrorMessage = error.message
        })
      }
    },
    onCustomerSaved: function (editCustomer) {
      if (editCustomer.id === this.currentDeal.purchaserId) {
        this.currentDeal.purchaserContactEmail = editCustomer.email
        this.currentDeal.purchaserContactMobile = editCustomer.mobile
        this.currentDeal.purchaserContactName = editCustomer.firstName + ' ' + editCustomer.lastName
      }
      this.updateIdentityVerifcationModalState(true)
      this.editCustomerId = 0
      this.editCustomerActive = false
      this.isDialogOpen = true
    },
    onCustomerCancelled: function () {
      this.editCustomerId = 0
      this.editCustomerActive = false
      this.isDialogOpen = true
    },
    onEditCustomer: function (recipientId) {
      this.editCustomerId = recipientId
      this.editCustomerActive = true
      this.isDialogOpen = false
    },
    loadData: function () {
      let customerId = this.currentDeal.purchaserId
      this.isLoading = true
      this.serverErrorMessage = ''
      this.serverErrors = []
      if (customerId !== null) {
        customerService.single(customerId).then(response => {
          this.termsAccepted = response.customer.isTermsAccepted
          this.currentCustomer = response.customer
          this.isLoading = false
        }).catch((error) => {
          this.isTermsAccepted = false
          this.isLoading = false
          this.serverErrorMessage = error.message
        })
      }
    }
  },
  mounted: function () {
    this.loadData()
  },
  watch: {
    currentDeal: function (newValue, oldValue) {
      if (newValue) {
        this.loadData()
      }
    }
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .verification-container {
    margin-bottom: 3em;
  }

  .status-container {
    margin-bottom: 3em;
  }

</style>

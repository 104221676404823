<template>
  <div v-observe-visibility="visibilityChanged" class="pod tile is-parent is-narrow">
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold">Inventory to Review - {{title}} ({{total}})
        <i class="fas fa-ellipsis-v icon-more"></i>
        <i style="display: inline-block;" class="fas fa-sync" :class="{'fa-spin': isLoading}" @click="onPodRefreshRequired"></i>
      </h4>
      <div class="pod-content scrollbar">
        <div class="control-bar is-clearfix is-certical-center">
          <b-field  class="control-bar-radio-list is-pulled-left">
            <b-radio-button v-model="saleType" size="is-small" native-value="-1" type="is-default-selected"><span>All</span></b-radio-button>
            <b-radio-button v-model="saleType" size="is-small" native-value="0" type="is-success" @click="loadDispositionExpiredData"><span>Disposition Expired</span></b-radio-button>
            <b-radio-button v-model="saleType" size="is-small" native-value="1" type="is-success" @click="loadWholesaleNowData"><span>Wholesale Now</span></b-radio-button>
            <b-radio-button v-model="saleType" size="is-small" native-value="2" type="is-success" @click="loadForReviewData"><span>For Review</span></b-radio-button>
          </b-field>
        </div>
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th class="is-uppercase no-wrap-table-cell" :class="{'is-current-sort': sortField === column.id}" v-for="column in inventoryData.columns.filter((x) => x.visible)" :key="column.name" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                {{column.name}}
                <span class="icon sort-icon"><i class="mdi" :class="{'mdi-arrow-up': sortOrder === 'asc', 'mdi-arrow-down': sortOrder === 'desc'}" v-if="sortField === column.id"></i></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable" v-for="(item, index) in inventoryData.data" :key="item.id" @click="onItemClick(item)" :class="{'pod-row-selected': isRowSelected(item)}">
              <td v-for="column in inventoryData.columns.filter((x) => x.visible)" :title="item.note" :key="column.name + index.toString()" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                <template v-if="column.format && column.format === 'currency'">{{item[column.id] | currency('$', 0)}}</template>
                <template v-else-if="column.format && column.format === 'datetime'">{{item[column.id] | formatDateOnly}}</template>
                <template v-else-if="column.name === 'Status'">
                <a @click.stop.prevent="onInventoryBookDealCaptureClick($event, item)" v-if="showDealCompletedIcon({dealStatus: item.dealStatus, dealGenericStatusName: item.dealGenericStatusName})">
                  <i class="icon far fa-dollar-sign fa-lg" :class="getColorClassByDealItemStatus({dealStatus: item.dealStatus, dealGenericStatusName: item.dealGenericStatusName})" />
                  <i style="text-shadow: 0 0 3px #000; margin-left: -1.25rem;" class="grid-icon fa-fw fal fa-check fa-lg has-text-white" v-tooltip="getDealStatusTitleForView(item.dealStatus, { promiseDate: item.dealPromiseDate, soldAt: item.dealSoldAt, salesPersonName: item.dealSalesPersonName, customerName: item.customerPersonName, status: item.dealGenericStatusName}, false)"/>
                </a>
                <a @click.stop.prevent="onInventoryBookDealCaptureClick($event, item)" v-else><i class="icon far fa-dollar-sign fa-lg" :class="getColorClassByDealItemStatus({dealStatus: item.dealStatus, dealGenericStatusName: item.dealGenericStatusName})" v-tooltip="getDealStatusTitleForView(item.dealStatus, { promiseDate: item.dealPromiseDate, soldAt: item.dealSoldAt, salesPersonName: item.dealSalesPersonName, customerName: item.customerPersonName, status: item.dealGenericStatusName}, false)"/></a>
                </template>
                <template v-else-if="column.name === '...'">
                  <span class="actionButtonFormat">
                    <inventory-note-capture :inventoryItem="item"></inventory-note-capture>
                  </span>
                  <span class="actionButtonFormat">
                    <div @click.stop.prevent="onAddDeal(item.id)">
                      <b-icon pack="fal" icon="handshake" custom-size="fa-lg"></b-icon>
                    </div>
                  </span>
                </template>
                <template v-else>{{item[column.id]}}</template>
              </td>
            </tr>
            <tr v-if="this.inventoryData.data.length === 0" class="subtitle is-7">
              <td style="text-align:center; height:100px;vertical-align:middle;" :colspan="inventoryData.columns.length">No data available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
    <portal to="global-modal-portal" v-if="bookDealCaptureActive">
      <b-modal :active.sync="bookDealCaptureActive" scroll="keep" :has-modal-card="true" v-if="listInventory" :canCancel="['escape', 'x']" class="modal-fullscreen">
        <book-deal-capture
          v-model="currentDeal" :bookDealCaptureActive.sync="bookDealCaptureActive" :progressStatus="false"
          :isReadOnly="bookedLocked || isCancelledDeal" :statusProgressionWizardActive="false" :keepLocked="true">
        </book-deal-capture>
      </b-modal>
    </portal>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import axios from 'axios'
import ViewInventory from '@/components/inventory/ViewInventory'
import utilitiesMixin from '@/mixins/generic/utilities'
import formattingMixin from '@/mixins/inventory/formatting'
import inventoryService from '@/services/inventoryService'
import CaptureNewInventoryNote from '@/components/inventory/CaptureNewInventoryNote'
import { createNamespacedHelpers } from 'vuex'
import BookDealCapture from '@/components/generic/sales/BookDealCapture'
import dealService from '@/services/dealService'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'WholesaleNowDispositionExpiredGridPod',
  mixins: [utilitiesMixin, formattingMixin, dealInventoryStyleClasses],
  components: {
    'inventory-note-capture': CaptureNewInventoryNote,
    'view-inventory': ViewInventory,
    'book-deal-capture': BookDealCapture
  },
  data () {
    return {
      total: 0,
      title: '',
      isLoading: false,
      page: 0,
      sortField: 'stockNo',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      serverErrorMessage: '',
      perPage: this.$store.state.config.defaultPageSize,
      currentInventory: null,
      inventoryData: {
        columns: [
          {
            id: 'id',
            name: 'Id',
            visible: false
          },
          {
            id: 'stockNo',
            name: 'Stock No',
            visible: true,
            maxWidth: '80px'
          },
          {
            id: 'vinNo',
            name: 'Vin No.',
            visible: true,
            maxWidth: '130px'
          },
          {
            id: 'name',
            name: 'Name',
            visible: true
          },
          {
            id: 'type',
            name: 'Type',
            visible: false
          },
          {
            id: 'status',
            name: 'Status',
            visible: !this.$isMobile,
            maxWidth: '60px'
          },
          {
            id: 'actions',
            name: '...',
            visible: true,
            maxWidth: '80px'
          }
        ],
        data: []
      },
      saleType: '-1',
      selectedInventory: null,
      bookDealCaptureActive: false,
      currentDeal: null,
      listInventory: null
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentUser', 'currentPendingActions', 'currentNotifications']),
    ...mapDealerState(['currentDealer']),
    bookedLocked: function () {
      return this.listInventory && this.listInventory.dealStatus === '3'
    },
    isCancelledDeal: function () {
      return this.listInventory && this.listInventory.dealStatus === '4'
    }
  },
  methods: {
    onAddDeal: function (id) {
      this.isLoading = true

      inventoryService.single(id).then(response => {
        this.currentInventory = response.inventory
        this.$router.push({name: 'NewDeal', params: { inventoryItem: this.currentInventory }})
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    onPodRefreshRequired: function () {
      this.saleType = '-1'
      this.loadData()
    },
    onItemClick: function (item) {
      this.selectedInventory = item
      eventBus.$emit('pod-item-highlight', {componentId: this._uid})
      if (this.hasFeatureAccess('inventory.view')) {
        this.$router.push({name: 'InventoryQuickView', params: { id: item.id, isLoanerPod: false, offerPod: -1 }})
      }
    },
    onDealClick: function (inventoryItem) {
      if (+inventoryItem.dealId !== -1 && this.hasFeatureAccess('deal.view')) {
        this.$router.push({name: 'DealsQuickView', params: { id: inventoryItem.dealId }})
      }
    },
    loadDispositionExpiredData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        query: '',
        pageSize: this.perPage,
        sortBy: 'stockNo',
        page: this.page
      }

      this.isLoading = true
      inventoryService.getDispositionExpired(searchCriteria).then(response => {
        this.inventoryData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        this.title = 'Disposition Expired'
        response.results.forEach((item) => {
          this.inventoryData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.inventoryData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    loadWholesaleNowData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        query: '',
        pageSize: this.perPage,
        sortBy: 'stockNo',
        page: this.page
      }

      this.isLoading = true
      inventoryService.getWholesaleNow(searchCriteria).then(response => {
        this.inventoryData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        this.title = 'Wholesale Now'
        response.results.forEach((item) => {
          this.inventoryData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.inventoryData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    loadForReviewData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        query: '',
        pageSize: this.perPage,
        sortBy: 'stockNo',
        page: this.page
      }

      this.isLoading = true
      inventoryService.getForReview(searchCriteria).then(response => {
        this.inventoryData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        this.title = 'Flagged For Review'
        response.results.forEach((item) => {
          this.inventoryData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.inventoryData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    loadData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        query: '',
        pageSize: this.perPage,
        sortBy: 'stockNo',
        page: this.page
      }

      this.isLoading = true
      inventoryService.getWholesaleNowOrDispositionExpired(searchCriteria).then(response => {
        this.inventoryData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        this.title = 'All'
        response.results.forEach((item) => {
          this.inventoryData.data.push(item)
        })

        this.isLoading = false
      }).catch((error) => {
        this.inventoryData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        if (this.currentDealer.id) {
          this.loadData()
        }
      }
    },
    isRowSelected: function (item) {
      if (!this.selectedInventory) return false
      if (this.selectedInventory.id === item.id) return true
      return false
    },
    itemHighLightCheck: function (options) {
      let componentId = options.componentId
      if (this._uid !== componentId) {
        this.selectedInventory = null
      }
    },
    onInventoryBookDealCapture: function (inventoryItem, showBookingWizard) {
      if (+inventoryItem.dealId !== -1 && this.hasFeatureAccess('deal.view') && showBookingWizard === true) {
        eventBus.$emit('close-global-quick-view', {})
        let that = this
        dealService.single(inventoryItem.dealId).then(response => {
          that.currentDeal = response.deal
          that.listInventory = inventoryItem
          that.bookDealCaptureActive = true
        }).catch((error) => {
          that.failedToast('Oops! Something went wrong')
          that.serverErrorMessage = error.message
          that.loading = false
        })
      } else {
        this.onDealClick(inventoryItem)
      }
    },
    onInventoryBookDealCaptureClick: function (ev, inventoryItem) {
      this.onInventoryBookDealCapture(inventoryItem, ev.ctrlKey)
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.loadData()
      }
    },
    saleType: function (newValue, oldValue) {
      if (newValue === '0') {
        this.loadDispositionExpiredData()
      } else if (newValue === '1') {
        this.loadWholesaleNowData()
      } else if (newValue === '2') {
        this.loadForReviewData()
      } else {
        this.loadData()
      }
    },
    bookDealCaptureActive: function (newValue, oldValue) {
      if (!newValue) {
        this.currentDeal = null
        this.listInventory = null
      }
    }
  },
  beforeDestroy () {
    axios.cancel('inventory-wholesale-or-disposition')
    eventBus.$off('pod-item-highlight', this.itemHighLightCheck)
  },
  mounted: function () {
    eventBus.$on('pod-item-highlight', this.itemHighLightCheck)
  }
}
</script>

<style scoped>
  .actionButtonFormat
  {
    text-align: left;
    display: table-cell;
    min-width: 10px;
    padding-right: 10px;
  }

  .no-wrap-table-cell {
    white-space: nowrap;
  }

  .sort-icon {
    height:auto !important;
  }

  .is-current-sort {
    color: rgb(0, 177, 31) !important;
  }
</style>

<template>
  <div class="is-inline-flex" style="align-items: center" v-if="hasSsnAccess">
    {{ssnForDisplay || '-'}}
    <user-pin-control
      v-if="hasFeatureAccess('customer.ssn')"
      useIcon="fa-eye"
      v-on:on-pin-verified="fetchSsn">
    </user-pin-control>
  </div>
</template>

<script>
import customerService from '@/services/customerService'
import UserPinControl from '@/components/generic/UserPinControl.vue'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  components: {
    'user-pin-control': UserPinControl
  },
  props: {
    customer: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      isLoading: false,
      ssnModel: null,
      showSsn: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess']),
    ssnForDisplay: function () {
      return this.ssnModel ? this.ssnModel.value : this.customer.ssn
    },
    hasSsnAccess: function () {
      let allowedPermissions = ['customer.ssn']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    }
  },
  methods: {
    fetchSsn () {
      if (!this.customer || this.customer.id === 0) return
      let that = this
      that.isLoading = true
      customerService.getCustomerSsn(that.customer.id).then(response => {
        that.ssnModel = response
        if (response.errors) {
          this.failedToast('Oops! Something went wrong')
        } else {
          that.ssnModel = response.ssnModel
        }
        that.isLoading = false
      }).catch(() => {
        that.isLoading = false
      })
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

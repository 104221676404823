<template>
  <div>
    <portal to="global-modal-portal" v-if="usePortal">
      <b-modal :active.sync="value.isAddingCommission" :width="640" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="min-width: 500px; min-height: 400px">
          <header class="modal-card-head">
            <p class="modal-card-title">Adding New Commission</p>
          </header>
          <section class="modal-card-body">
            <form data-vv-scope="commissions-form">
              <div class="box">
                <div class="columns is-multiline">
                  <div class="column is-12">
                    <user-selector v-model="selectedSalesAgent" filter="Sales" label="Sales Agent" validateAs="sales agent" :selectedDealer="this.selectedDealer" :required="true" :isDisabled="isReadOnly"></user-selector>
                    <span v-show="errors.has('commissions-form.selectedSalesAgent')" class="help is-danger"><i v-show="errors.has('commissions-form.selectedSalesAgent')" class="fas fa-exclamation-triangle"></i> {{ errors.first('commissions-form.selectedSalesAgent') }}</span>
                  </div>
                  <div class="column is-12">
                    <label class="label">Commission<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <money name="commission" data-vv-as="commission" v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has('commissions-form.commission') }" type="text" placeholder="0000.00" v-model="value.commission" v-bind="$globalMoneyFormat" :disabled="isReadOnly"></money>
                      <span v-show="errors.has('commissions-form.commission')" class="help is-danger">
                        <i v-show="errors.has('commissions-form.commission')" class="fas fa-exclamation-triangle"></i> {{ errors.first('commissions-form.commission') }}
                      </span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="onSave()" type="button" :disabled="isReadOnly">Confirm</button>
            <button class="button is-danger" @click="onCancel()" type="button">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>

    <b-modal :active.sync="value.isAddingCommission" :width="640" scroll="keep" :has-modal-card="true" v-if="!usePortal">
      <div class="modal-card" style="min-width: 500px">
        <header class="modal-card-head">
          <p class="modal-card-title">Adding New Commission</p>
        </header>
        <section class="modal-card-body">
          <form data-vv-scope="commissions-form">
            <div class="box">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <user-selector v-model="selectedSalesAgent" filter="Sales" label="Sales Agent" validateAs="sales agent" :selectedDealer="this.selectedDealer" :required="true" :isDisabled="isReadOnly"></user-selector>
                  <span v-show="errors.has('commissions-form.selectedSalesAgent')" class="help is-danger"><i v-show="errors.has('commissions-form.selectedSalesAgent')" class="fas fa-exclamation-triangle"></i> {{ errors.first('commissions-form.selectedSalesAgent') }}</span>
                </div>
                <div class="column is-12">
                  <label class="label">Commission<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control has-icons-left">
                    <money name="commission" data-vv-as="commission" v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has('commissions-form.commission') }" type="text" placeholder="0000.00" v-model="value.commission" v-bind="$globalMoneyFormat" :disabled="isReadOnly"></money>
                    <span v-show="errors.has('commissions-form.commission')" class="help is-danger">
                      <i v-show="errors.has('commissions-form.commission')" class="fas fa-exclamation-triangle"></i> {{ errors.first('commissions-form.commission') }}
                    </span>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="onSave()" type="button" :disabled="isReadOnly">Confirm</button>
          <button class="button is-danger" @click="onCancel()" type="button">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import UserSelector from '@/components/generic/UserSelector'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'CommissionCapture',
  components: {
    'user-selector': UserSelector
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    commissions: {
      type: Array,
      default: () => []
    },
    usePortal: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      selectedSalesAgent: null
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    selectedDealer: function () {
      return this.$store.state.dealer.currentDealer
    }
  },
  methods: {
    onCancel: function () {
      this.errors.clear('commissions-form')
      this.value.isAddingCommission = false
    },
    onSave: function () {
      if (this.selectedSalesAgent.id === null && this.selectedSalesAgent.fullName === null) {
        this.errors.items.push({field: 'selectedSalesAgent', scope: 'commissions-form', msg: 'The sales agent field is required'})
        return
      } else if (this.value.commission !== null && this.errors.items.filter((x) => x.field === 'selectedSalesAgent').length === 0) {
        this.errors.clear('commissions-form')
      }

      this.$validator.validateAll('commissions-form').then((result) => {
        if (result) {
          this.$delete(this.commissions, this.value.index)
          this.commissions.push(this.value)
          this.value.isAddingCommission = false
        }
      })
    }
  },
  watch: {
    selectedSalesAgent: function (newValue, oldValue) {
      if (newValue !== null) {
        this.value.salesPersonId = newValue.id
        this.value.salesPersonName = newValue.fullName
        this.errors.clear('commissions-form')
      }
    },
    commissions: function (newValue, oldValue) {
      this.$emit('input', {commissions: newValue})
    },
    value: function (newValue, oldValue) {
      if (newValue !== null && this.selectedDealer !== null) {
        this.selectedSalesAgent = { id: newValue.salesPersonId, fullName: newValue.salesPersonName, dealerId: this.selectedDealer.id }
      } else {
        this.selectedSalesAgent = null
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
  .box {
      background-color: #f6f8fa;
    }
</style>

<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      :name="instanceName"
      :taggable="true"
      @tag="bankAdded"
      tagPlaceholder="Press enter to create a new entity"
      tagPosition="bottom"
      :disabled="isDisabled"
      :searchable="true"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedBank"
      :options="allBanks"
      label="name"
      :placeholder="placeholder"
      trackBy="id"
      :loading="isBankLoading"
      :internal-search="false"
      :options-limit="300"
      @search-change="onSearch"
      :data-vv-scope="scope"
      :hideSelected="true"></multiselect>
      <a class="button is-primary new-bank-btn" @click="isNewBankActive = true" v-if="allowNew && showNewAction">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <b-modal :active.sync="isNewBankActive" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body" style="min-width:400px !important; height:450px;">
          <new-bank
            viewMode="modal"
            actionPortal="modal-footer"
            headerPortal="modal-header"
            v-on:on-save="onBankSaved"
            v-on:on-cancel="onBankCancelled"
            :startWith="nameOfBankToCreate">
          </new-bank>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import _ from 'lodash'
import NewBank from '@/components/configuration/banks/NewBank'
import bankService from '@/services/bankService'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'BankSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Bank'
    },
    placeholder: {
      type: String,
      default: 'Type to search for a loaded bank'
    },
    validateAs: {
      type: String,
      default: 'Bank'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    allowNew: {
      type: Boolean,
      default: true
    },
    showNewAction: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  components: {
    'new-bank': NewBank
  },
  data () {
    return {
      isBankLoading: false,
      isNewBankActive: false,
      selectedBank: this.value,
      nameOfBankToCreate: '',
      data: [],
      instanceName: 'bank-selector-' + this.$uuid.v4()
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    allBanks () {
      return this.data
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
    },
    selectedBank: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentBank: function (newValue, oldValue) {
      this.selectedBank = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedBank = newValue
      if (newValue) {
        this.data.push(newValue)
      }
    }
  },
  methods: {
    bankAdded: function (data) {
      if (this.allowNew) {
        this.nameOfBankToCreate = data
        this.isNewBankActive = true
      }
    },
    onBankSaved: function (newBank) {
      this.data.push(newBank)
      this.selectedBank = newBank
      this.isNewBankActive = false
    },
    onBankCancelled: function () {
      this.isNewBankActive = false
    },
    clearAllBanks () {
      this.data = []
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.currentDealer.id,
        sortBy: 'name.asc'
      }
      this.isBankLoading = true
      this.clearAllBanks()
      bankService.all(searchCommand).then(response => {
        this.data = response.results
        this.isBankLoading = false
      })
    },
    doBankSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onSearch (query) {
      this.doBankSearch(query)
    }
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}

</script>

<style scoped>
  .new-bank-btn {
    margin-left:5px;
    height:40px;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(100vh - 185px);
  }
</style>

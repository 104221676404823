<template>
  <span v-if="!isEditTagActive">
    <span :id="instanceId" :name="instanceId" class="icon is-inline-block has-text-centered" title="Edit tags for this inventory record" @click.stop.prevent="onEditTags()" v-if="isListMode">
      <i class="fal fa-comment fa-lg"/>
    </span>
    <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
        <button class="button" @click.stop.prevent="onEditTags()" title="Edit tags for this inventory record">
          <b-icon pack="fal" icon="tags" custom-size="fa-lg"></b-icon>
        </button>
        <span v-if="label.length > 0">{{label}}</span>
    </div>
  </span>
  <portal to="global-modal-portal" v-else>
    <b-modal :active.sync="isEditTagActive" :width="640" scroll="keep" :has-modal-card="true" v-if="inventoryItem">
      <div class="modal-card" style="width: 640px" v-if="!isAddingTag">
        <header class="modal-card-head">
          <div class="columns">
            <div class="column is-12">
              <p class="modal-card-title">Editing tags for item <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a></p>
            </div>
          </div>
        </header>
        <section class="modal-card-body" style="padding:12px">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form class="view-container">
            <div class="columns is-multiline">
              <div class="column is-12">
                <section-header title="Tags" :allowCollapse="false"></section-header>
                <tag-selector v-model="selectedTags" :required="false" :inModal="true" v-on:on-add-tag-modal="addNewTag"></tag-selector>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="saveTags()">Save</button>
          <button class="button is-danger" @click="isEditTagActive = false">Cancel</button>
        </footer>
      </div>
      <div class="modal-card" style="width: 640px" v-else>
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body" style="min-width: 400px; !important">
          <new-tag
            mode="modal"
            actionPortal="modal-footer"
            headerPortal="modal-header"
            v-on:on-save="onTagSaved"
            v-on:on-cancel="onTagCancelled"
            :startWith="nameOfTagToCreate">
          </new-tag>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
  </portal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import inventoryService from '../../services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import eventBus from '@/eventBus'
import TagSelector from '@/components/generic/TagSelector'
import SectionHeader from '@/components/generic/SectionHeader'
import NewTag from '@/components/configuration/tags/NewTag'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'CaptureInventoryTags',
  components: {
    'error-display-component': ErrorDisplayComponent,
    'tag-selector': TagSelector,
    'section-header': SectionHeader,
    'new-tag': NewTag
  },
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: 'is-width-600'
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isEditTagActive: false,
      isSaving: false,
      instanceId: this.$uuid.v4(),
      serverErrors: [],
      serverErrorMessage: '',
      selectedTags: [],
      isAddingTag: false,
      nameOfTagToCreate: ''
    }
  },
  methods: {
    onEditTags: function () {
      this.isEditTagActive = true
    },
    saveTags: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      inventoryService.editTags({id: this.inventoryItem.id, tags: this.selectedTags}).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          if (this.inventoryItem.tags) {
            this.inventoryItem.tags = response.inventory.tags
          }
          this.isEditTagActive = false
          eventBus.$emit('inventory-changed', {id: this.inventoryItem.id})
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    addNewTag: function (nameOfTagToCreate) {
      this.nameOfTagToCreate = nameOfTagToCreate
      this.isAddingTag = true
    },
    onTagSaved: function (newTag) {
      this.selectedTags.push(newTag)
      this.isAddingTag = false
    },
    onTagCancelled: function () {
      this.isAddingTag = false
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  computed: {
    ...mapUserGetters(['currentUser', 'hasFeatureAccess'])
  },
  watch: {
    inventoryItem: function (newValue, oldValue) {
      if (newValue) {
        this.selectedTags = newValue.tags
      }
    }
  },
  mounted () {
    this.selectedTags = (this.inventoryItem.tags && this.inventoryItem.tags.length > 0) ? this.inventoryItem.tags : []
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-card-body {
    overflow:auto;
    height: calc(50vh - 10px);
  }
</style>

import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/customers',
    name: 'ListCustomers',
    component: () => import('@/components/configuration/customers/ListCustomers'),
    beforeEnter: requiresAuth
  },
  {
    name: 'CustomersQuickView',
    path: '/configuration/customers/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/customers/ViewCustomer')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/customers/new',
    name: 'NewCustomer',
    component: () => import('@/components/configuration/customers/NewCustomer'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/customers/view/:id',
    name: 'ViewCustomer',
    component: () => import('@/components/configuration/customers/ViewCustomer'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/customers/edit/:id',
    name: 'EditCustomer',
    component: () => import('@/components/configuration/customers/EditCustomer'),
    beforeEnter: requiresAuth
  }
]

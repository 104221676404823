<template>
  <div v-observe-visibility="visibilityChanged" class="pod tile is-parent is-narrow">
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold">{{title}}<i class="fas fa-ellipsis-v icon-more"></i><i style="display: inline-block;" class="fas fa-sync" :class="{'fa-spin': isLoading}" @click="onRefresh"></i>
      </h4>
      <v-chart v-if="this.value" :option="graphData" :init-option="initOptions" ref="bar" auto-resize style="margin-top:10px; width:95%;height:345px"/>
      <div v-else class="subtitle is-7" style="height:100%; display: flex;align-items: center;justify-content: center">
        <span style="margin-bottom:10px">No data available</span>
      </div>
    </article>
  </div>
</template>

<script>

import axios from 'axios'
import dealService from '@/services/dealService'
import eventBus from '@/eventBus'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'GpSummaryByMakePod',
  props: {
    searchParameters: {
      type: Object,
      default: function () {
        return {
          query: '',
          selectedStartDate: null,
          selectedEndDate: null
        }
      }
    }
  },
  data () {
    return {
      id: this.$uuid.v4(),
      localSearchParameters: {
        query: '',
        selectedStartDate: null,
        selectedEndDate: null
      },
      title: 'GP Summary',
      active: true,
      isLoading: false,
      type: 'bar',
      value: null,
      initOptions: {
        renderer: 'canvas'
      }
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentUser', 'currentPendingActions', 'currentNotifications']),
    ...mapDealerState(['currentDealer']),
    graphData: function () {
      return {
        grid: {
          show: true,
          containLabel: true,
          bottom: 10,
          left: 10,
          right: 0
        },
        legend: {},
        toolbox: {
          top: '20',
          orient: 'vertical',
          show: true,
          feature: {
            saveAsImage: {
              title: 'Export'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        dataset: {
          // Provide data.
          dimensions: ['Type', ...this.value.series],
          source: this.value.labels.map((a, b) => {
            return [a, ...this.value.data.map((x, y, d) => {
              return x[b]
            })]
          })
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: {
          name: '',
          show: true,
          type: 'category',
          boundaryGap: true,
          axisLabel: {
            show: true,
            rotate: 45
          },
          axisTick: {
            alignWithLabel: true
          }
        },
        // Declare Y axis, which is a value axis.
        yAxis: [...this.value.series.map((x, y) => {
          return {
            show: y === 0,
            type: 'value',
            scale: false,
            name: '',
            axisLabel: {
              show: true,
              formatter: this.$formatCurrency
            }
          }
        })
        ],
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: [...this.value.series.map((x, y) => {
          return {
            type: 'bar',
            barGap: '0%',
            label: {
              show: false
            },
            itemStyle: {
              color: this.value.backgroundColors[y],
              opacity: 0.7
            }
          }
        })
        ]
      }
    }
  },
  methods: {
    onSearchParametersChanged: function (data) {
      this.localSearchParameters = data.searchParameters
      this.loadData()
    },
    onRefresh: function () {
      this.loadData()
    },
    loadData: function () {
      if (!this.currentDealer || !this.currentDealer.id || !this.currentDealer.sellsVehicleMakes) {
        return
      }
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        makeIds: this.currentDealer.sellsVehicleMakes.map((x) => x.vehicleMakeId),
        query: this.localSearchParameters.query || '',
        requestedStartDate: this.localSearchParameters.selectedStartDate || '',
        requestedEndDate: this.localSearchParameters.selectedEndDate || ''
      }
      this.isLoading = true
      dealService.getGpSummaryData(searchCriteria).then(response => {
        this.value = response
        this.isLoading = false
      }).catch((error) => {
        this.value = null
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        if (this.currentDealer.id) {
          this.loadData()
        }
      }
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.loadData()
      }
    }
  },
  beforeDestroy () {
    axios.cancel('gp-summary-by-make')
    eventBus.$off('pod-search-update', this.onSearchParametersChanged)
  },
  mounted: function () {
    this.localSearchParameters.query = this.searchParameters.query
    this.localSearchParameters.selectedStartDate = this.searchParameters.selectedStartDate
    this.localSearchParameters.selectedEndDate = this.searchParameters.selectedEndDate
    eventBus.$on('pod-search-update', this.onSearchParametersChanged)
  }
}
</script>

<style scoped>
</style>

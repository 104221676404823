<template>
  <span v-if="!isNewArrivalCapture">
    <template v-if="hasMarkArrivalAccess">
      <span :id="instanceId" :name="instanceId" style="width:100%" class="icon is-block has-text-centered" title="Change arrival status of vehicle" @click.stop.prevent="onAddArrivalCapture()" v-if="isListMode">
        <i class="fal fa-hand-receiving fa-lg"/>
      </span>
      <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
        <button class="button" @click.stop.prevent="onAddArrivalCapture()" title="Change arrival status of vehicle">
          <b-icon pack="fal" icon="hand-receiving" custom-size="fa-lg"></b-icon>
        </button>
      </div>
    </template>
    <template v-else>
      <span disabled :id="instanceId" :name="instanceId" style="width:100%" class="icon is-block has-text-centered" title="You do not have access to this feature" v-if="isListMode">
        <i class="fal fa-hand-receiving fa-lg"/>
      </span>
      <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
        <button disabled class="button" title="You do not have access to this feature">
          <b-icon pack="fal" icon="hand-receiving" custom-size="fa-lg"></b-icon>
        </button>
      </div>
    </template>
  </span>
  <portal to="global-modal-portal" v-else :disabled="!usePortal">
    <b-modal :active.sync="isNewArrivalCapture" :full-screen="isMobile" scroll="keep" :has-modal-card="true" v-if="inventoryItem" :canCancel="false">
      <arrival-capture-detail
        :inventoryItem="inventoryItem"
        :useModal="false"
        v-on:arrival-capture-cancelled="onCancelled"
        v-on:arrival-capture-complete="onComplete"
        v-on:arrival-capture-skipped="onArrivalCaptureSkipped"
        :allowSkip="allowSkip">
      </arrival-capture-detail>
    </b-modal>
  </portal>
</template>

<script>

import ArrivalCaptureDetail from '@/components/generic/inventory/ArrivalCaptureDetail'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'ArrivalCapture',
  components: {
    'arrival-capture-detail': ArrivalCaptureDetail
  },
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    isListMode: {
      type: Boolean,
      default: true
    },
    allowSkip: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isNewArrivalCapture: false,
      instanceId: this.$uuid.v4(),
      isMobile: window.innerWidth <= 700,
      arrivalCaptureSkipped: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    hasMarkArrivalAccess: function () {
      let allowedPermissions = ['inventory.mark-arrived']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    }
  },
  methods: {
    onArrivalCaptureSkipped: function () {
      this.arrivalCaptureSkipped = true
      this.$emit('arrival-capture-skipped', true)
    },
    onCancelled: function () {
      this.isNewArrivalCapture = false
      this.$emit('reset-dialog', false)
      this.inventoryItem.actionCancelled = true
      this.$emit('arrival-capture-complete', this.inventoryItem)
    },
    onComplete: function (inventoryItem) {
      this.isNewArrivalCapture = false
      this.$emit('arrival-capture-complete', this.inventoryItem)
      this.$emit('reset-dialog', false)
    },
    onAddArrivalCapture: function () {
      this.isNewArrivalCapture = true
    }
  },
  watch: {
  },
  mounted: function () {
    if (this.showDialog === true) {
      this.onAddArrivalCapture()
    }
  }
}

</script>

<style scoped>
  .content ul {
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important
  }

  .modal-card-body {
    max-height: 500px !important;
    min-height: 500px !important;
    height: 500px !important;
  }
</style>

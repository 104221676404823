<template>
  <b-dropdown aria-role="list" :class="dropdownClass">
    <button :class="paymentStatusClass" class="dropdown-btn button is-pulled-left" type="button" slot="trigger" v-tooltip="title" :disabled="disableSendWireDetailBtn">
      <i v-if="paymentStatusForDataType === 'Paid'" :data-type="dataType" class="fal fa-money-bill-wave fa-lg" custom-size="fa-lg"></i>
      <i v-else class="fal fa-money-bill-wave fa-lg" custom-size="fa-lg"></i>
    </button>

    <b-dropdown-item aria-role="listitem" @click="onDropdownItemClicked('wire')" v-if="hasWireCreateAccess">wire</b-dropdown-item>
    <b-dropdown-item aria-role="listitem" v-if="hasSquareCreateAccess" @click="onDropdownItemClicked('square')">square invoice</b-dropdown-item>
    <b-dropdown-item aria-role="listitem" v-if="showPartsTerminalMenuItem" @click="onDropdownItemClicked(dropDownPartsInvoiceType, currentParts.invoicePrintRequestId)">{{ partsTerminalText }}</b-dropdown-item>
    <b-dropdown-item aria-role="listitem" v-if="showRoInvoiceTerminalMenuItem" @click="onDropdownItemClicked(dropDownInvoiceType, currentRoService.invoicePrintRequestId)">{{ invoiceTerminalText }}</b-dropdown-item>
    <b-dropdown-item aria-role="listitem" v-if="showRoWorkOrderTerminalMenuItem" @click="onDropdownItemClicked(dropDownWorkOrderType, currentRoService.workOrderPrintRequestId)">{{ workOrderTerminalText }}</b-dropdown-item>
    <b-dropdown-item aria-role="listitem" @click="onDropdownItemClicked('check')" v-if="hasCheckCashOtherCreateAccess">check</b-dropdown-item>
    <b-dropdown-item aria-role="listitem" @click="onDropdownItemClicked('cash')" v-if="hasCheckCashOtherCreateAccess">cash</b-dropdown-item>
    <b-dropdown-item aria-role="listitem" @click="onDropdownItemClicked('other')" v-if="hasCheckCashOtherCreateAccess">other</b-dropdown-item>
  </b-dropdown>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'PaymentControlMenuDropdown',
  components: {
  },
  props: {
    dropdownClass: {
      type: String,
      default: 'send-wire-control'
    },
    paymentStatusClass: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    disableSendWireDetailBtn: {
      type: Boolean,
      default: false
    },
    paymentStatusForDataType: {
      type: String,
      default: ''
    },
    dataType: {
      type: String,
      default: ''
    },
    dropDownInvoiceType: {
      type: String,
      default: ''
    },
    dropDownWorkOrderType: {
      type: String,
      default: ''
    },
    currentRoService: {
      type: Object,
      default: null
    },
    invoiceTerminalText: {
      type: String,
      default: ''
    },
    workOrderTerminalText: {
      type: String,
      default: ''
    },
    isPartsDeposit: {
      type: Boolean,
      default: false
    },
    showRoInvoiceTerminalMenuItem: {
      type: Boolean,
      default: false
    },
    showRoWorkOrderTerminalMenuItem: {
      type: Boolean,
      default: false
    },
    showPartsTerminalMenuItem: {
      type: Boolean,
      default: false
    },
    dropDownPartsInvoiceType: {
      type: String,
      default: ''
    },
    currentParts: {
      type: Object,
      default: null
    },
    partsTerminalText: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4()
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    hasCheckCashOtherCreateAccess: function () {
      return this.hasFeatureAccess('deposit.create.check')
    },
    hasSquareCreateAccess: function () {
      return this.hasFeatureAccess('deposit.create.square')
    },
    hasWireCreateAccess: function () {
      return this.hasFeatureAccess('deposit.create.wire')
    }
  },
  methods: {
    async onDropdownItemClicked (type, printRequestId) {
      let dropDownObject = {
        type: type,
        printRequestId: printRequestId
      }

      this.$emit('on-dropdown-item-clicked', dropDownObject)
    }
  },
  watch: {
  },
  mounted: function () {
  },
  beforeDestroy () {
  }
}

</script>

<style scoped>
  .dropdown-btn {
    border: 1px dashed #e0e0e3;
    border-radius: 50%;
    background: white;
    width: 2.25em;
    color: #4b6271;
    cursor: pointer;
    margin-left: 3px;
    margin-right: 3px;
  }

</style>

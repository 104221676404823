import store from '../store'
import util from '@/utils'

function requiresAuth (to, from, next) {
  let isLoginPage = to.name === 'Login'
  let storeCurrentUser = store.getters['user/currentUser']
  if (storeCurrentUser && storeCurrentUser.loginRequested) {
    store.dispatch('auth/logoutRequest')
  } else {
    if (!store.getters['auth/isAuthenticated']) {
      let prefix = '/auth/login?redir='
      if (util.isInTabletMode() === true) {
        prefix = '/auth/tabletlogin?redir='
      }
      let loginPath = prefix + encodeURI(!isLoginPage ? to.fullPath : '/')
      next({
        path: loginPath
      })
    } else {
      if (util.isInTabletMode() === true) {
        let validPathNames = [null, '', 'Dashboard', 'NewLoanerEvent', 'TabletLogin', 'Login', 'LoanerEventsQuickView', 'EditLoanerEvent']
        if (validPathNames.includes(to.name.trim())) {
          next()
        } else {
          next({
            path: '/'
          })
        }
      } else {
        next()
      }
    }
  }
}

export default requiresAuth

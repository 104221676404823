import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/banks',
    name: 'ListBanks',
    component: () => import('@/components/configuration/banks/ListBanks'),
    beforeEnter: requiresAuth
  },
  {
    name: 'BanksQuickView',
    path: '/configuration/banks/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/banks/ViewBank')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/banks/new',
    name: 'NewBank',
    component: () => import('@/components/configuration/banks/NewBank'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/banks/view/:id',
    name: 'ViewBank',
    component: () => import('@/components/configuration/banks/ViewBank'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/banks/edit/:id',
    name: 'EditBank',
    component: () => import('@/components/configuration/banks/EditBank'),
    beforeEnter: requiresAuth
  }
]

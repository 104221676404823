<template>
  <div id="dashboard" v-if="!inTabletMode">
    <b-tabs type="is-toggle" size="is-small" expanded v-model="tabIndex" @change="onTabChanged" :animated="false">
        <b-tab-item label="Reporting" icon="chart-bar" :visible="reportingPods.length > 0">
          <pod-container :pods="reportingPods"></pod-container>
        </b-tab-item>
        <b-tab-item label="Pending Actions" icon="briefcase" :visible="inventoryPods.length > 0">
          <pod-container :pods="inventoryPods"></pod-container>
        </b-tab-item>
        <b-tab-item label="Sales Targets" pack="fas" icon="bullseye">
          <h1>New sales target container to go here</h1>
        </b-tab-item>
        <b-tab-item label="Notifications" icon="bullhorn">
          <h1>New notification container to go here</h1>
        </b-tab-item>
        <b-tab-item label="Search Results" icon="search" :visible="allSearchResults.length > 0 || currentSearchQuery !== ''">
          <search-results></search-results>
        </b-tab-item>
    </b-tabs>
    <portal to="global-search-portal">
      <pod-search-control
        v-model="searchParameters"
        v-on:query="onQuery"
        v-on:selectedStartDate="onStartDate"
        v-on:selectedEndDate="onEndDate"
        v-if="tabIndex === 0">
      </pod-search-control>
    </portal>
  </div>
  <section class="hero is-fullheight-with-navbar" v-else>
    <div class="hero-body">
      <div class="container">
        <div class='container has-text-centered'>
          <div class='columns is-centered is-vcentered' v-if="dealerLogoValid">
            <div class='column is-6'>
              <div>
                  <img :src="dealerLogo" :alt="dealerName"/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import PodContainer from '@/components/generic/pods/PodContainer'
import ChartPod from '@/components/generic/pods/ChartPod'
import GridPod from '@/components/generic/pods/GridPod'
import InventoryPendingArrivalPod from '@/components/generic/pods/InventoryPendingArrivalPod'
import InventoryLoanedOutPod from '@/components/generic/pods/InventoryLoanedOutPod'
import InventoryLocatePod from '@/components/generic/pods/InventoryLocatePod'
import WholesaleNowDispositionExpiredGridPod from '@/components/generic/pods/WholesaleNowDispositionExpiredGridPod'
import GpSumaryByMakePod from '@/components/generic/pods/GpSummaryByMakePod'
import PvrSumaryByMakePod from '@/components/generic/pods/PvrSummaryByMakePod'
import UnitCountSumaryByMakePod from '@/components/generic/pods/UnitCountSummaryByMakePod'
import DealByMakePod from '@/components/generic/pods/DealByMakePod'
import InventoryImportPod from '@/components/generic/pods/InventoryImportPod'
import InventoryMissingPod from '@/components/generic/pods/InventoryMissingPod'
import InventorySoldStockPod from '@/components/generic/pods/InventorySoldStockPod'
import GlobalSearchResults from '@/components/generic/GlobalSearchResults'
import PodSearchControl from '@/components/generic/PodSearchControl'
import InventoryPreEntryPod from '@/components/generic/pods/InventoryPreEntryPod'
import SupplierReviewPod from '@/components/generic/pods/SupplierReviewPod'
import CustomerReviewPod from '@/components/generic/pods/CustomerReviewPod'
import moment from 'moment'
import eventBus from '@/eventBus'
import util from '@/utils'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapUserActions = createNamespacedHelpers('user').mapActions
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapGlobalGetters = createNamespacedHelpers('global').mapGetters

export default {
  name: 'Dashboard',
  data () {
    return {
      title: 'Dashboard',
      tabIndex: 0,
      oldTabIndex: 0,
      reportingPods: [],
      inventoryPods: [],
      query: '',
      selectedStartDate: null,
      selectedEndDate: null,
      inTabletMode: false
    }
  },
  components: {
    'pod-container': PodContainer,
    'chart-pod': ChartPod,
    'grid-pod': GridPod,
    'search-results': GlobalSearchResults,
    'pod-search-control': PodSearchControl
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentUser', 'currentPendingActions', 'currentNotifications', 'selectedDealTimeRange']),
    ...mapDealerState(['currentDealer']),
    ...mapGlobalGetters(['allSearchResults', 'searchActive', 'currentSearchQuery', 'currentDashboardTabIndex']),
    searchParameters: function () {
      return {
        query: this.query,
        selectedStartDate: this.selectedStartDate,
        selectedEndDate: this.selectedEndDate
      }
    },
    dealerLogo () {
      var defaultLogo = require('@/assets/logo.png')
      if (this.currentDealer) {
        return this.currentDealer.fileLocation !== '' ? this.currentDealer.fileLocation : defaultLogo
      }
      return defaultLogo
    },
    dealerLogoValid () {
      return this.dealerLogo !== ''
    },
    dealerName () {
      var defaultName = 'Experience Auto Group'
      if (this.currentDealer) {
        return this.currentDealer.name
      }
      return defaultName
    }
  },
  methods: {
    ...mapUserActions(['setDealSelectedTimeRange']),
    onTabChanged (index) {
      this.$store.dispatch('global/setCurrentDashboardTabIndex', index)
    },
    initialize: function () {
      this.reportingPods = []
      if (this.hasFeatureAccess('dashboard.view.gp-summary-pod', false)) {
        this.reportingPods.push({component: GpSumaryByMakePod, props: {searchParameters: this.searchParameters}})
      }
      if (this.hasFeatureAccess('dashboard.view.pvr-summary-pod', false)) {
        this.reportingPods.push({component: PvrSumaryByMakePod, props: {searchParameters: this.searchParameters}})
      }
      if (this.hasFeatureAccess('dashboard.view.unit-count-summary-pod', false)) {
        this.reportingPods.push({component: UnitCountSumaryByMakePod, props: {searchParameters: this.searchParameters}})
      }
      if (this.currentDealer && this.currentDealer.sellsVehicleMakes) {
        this.currentDealer.sellsVehicleMakes.forEach(element => {
          if (this.hasFeatureAccess('dashboard.view.new-make-deal-pod', false)) {
            this.reportingPods.push({
              component: DealByMakePod,
              props: {vehicleTypeId: '0', title: 'New ' + element.name + ' Deals', forMake: {id: element.vehicleMakeId, name: element.name}, inventoryType: ['New'], searchParameters: this.searchParameters}
            })
          }
        })
        this.currentDealer.sellsVehicleMakes.forEach(element => {
          if (this.hasFeatureAccess('dashboard.view.used-make-deal-pod', false)) {
            this.reportingPods.push({
              component: DealByMakePod,
              props: {vehicleTypeId: '1', title: 'Used ' + element.name + ' Deals', forMake: {id: element.vehicleMakeId, name: element.name}, inventoryType: ['Purchased', 'TradeIn', 'Consigned'], searchParameters: this.searchParameters}
            })
          }
        })
      }
      if (this.hasFeatureAccess('dashboard.view.all-used-deal-pod', false)) {
        this.reportingPods.push({component: DealByMakePod, props: {vehicleTypeId: '1', title: 'All Used Deals', inventoryType: ['Purchased', 'TradeIn', 'Consigned'], searchParameters: this.searchParameters}})
      }
      if (this.hasFeatureAccess('dashboard.view.unsold-inventory-in-stock-pod', false)) {
        this.reportingPods.push({component: InventorySoldStockPod, props: {title: 'Unsold Inventory In Stock', inStock: true, isSold: false, searchParameters: this.searchParameters}})
      }
      if (this.hasFeatureAccess('dashboard.view.sold-inventory-in-stock-pod', false)) {
        this.reportingPods.push({component: InventorySoldStockPod, props: {title: 'Sold In-Stock Inventory', inStock: true, isSold: true, searchParameters: this.searchParameters}})
      }

      this.inventoryPods = []
      if (this.hasFeatureAccess('inventory.view.pending-arrival', false)) {
        this.inventoryPods.push({component: InventoryPendingArrivalPod})
      }
      if (this.hasFeatureAccess('inventory.view.locate', false)) {
        this.inventoryPods.push({component: InventoryLocatePod})
      }
      if (this.hasFeatureAccess('inventory.view.loaned-out', false)) {
        if (this.currentDealer.trackLoanerInfo === true) {
          this.inventoryPods.push({component: InventoryLoanedOutPod})
        }
      }
      if (this.hasFeatureAccess('inventory.view.disposition-expired', false) || this.hasFeatureAccess('inventory.view.wholesale-now', false)) {
        this.inventoryPods.push({component: WholesaleNowDispositionExpiredGridPod})
      }
      if (this.hasFeatureAccess('dashboard.view.inventory-pending-import-pod', false)) {
        this.inventoryPods.push({component: InventoryImportPod})
      }
      if (this.hasFeatureAccess('dashboard.view.inventory-missing-pod', false)) {
        this.inventoryPods.push({component: InventoryMissingPod})
      }
      if (this.hasFeatureAccess('notification.inventory.create.pre-entry', false)) {
        this.inventoryPods.push({component: InventoryPreEntryPod})
      }
      if (this.hasFeatureAccess('dashboard.view.supplier-review-pod', false)) {
        this.inventoryPods.push({component: SupplierReviewPod, props: {inStock: true, isSold: false, searchParameters: this.searchParameters}})
      }
      if (this.hasFeatureAccess('dashboard.view.customer-review-pod', false)) {
        this.inventoryPods.push({component: CustomerReviewPod, props: {inStock: true, isSold: false, searchParameters: this.searchParameters}})
      }

      if (this.tabIndex === 0 && this.reportingPods.length === 0) {
        this.tabIndex = 1
      }

      if (this.tabIndex === 1 && this.inventoryPods.length === 0) {
        this.tabIndex = 2
      }
    },
    onQuery: function (query) {
      this.query = query
      eventBus.$emit('pod-search-update', { searchParameters: this.searchParameters })
    },
    onStartDate: function (selectedStartDate) {
      let tz = moment.tz.guess()
      this.selectedStartDate = selectedStartDate ? moment.tz(selectedStartDate, tz).format('YYYY-MM-DD 00:00:00') : undefined
      this.setDealSelectedTimeRange({startDate: this.selectedStartDate, endDate: this.selectedDealTimeRange ? this.selectedDealTimeRange.endDate : undefined})
      eventBus.$emit('pod-search-update', { searchParameters: this.searchParameters })
    },
    onEndDate: function (selectedEndDate) {
      let tz = moment.tz.guess()
      this.selectedEndDate = selectedEndDate ? moment.tz(selectedEndDate, tz).format('YYYY-MM-DD 23:59:59') : undefined
      this.setDealSelectedTimeRange({startDate: this.selectedDealTimeRange ? this.selectedDealTimeRange.startDate : undefined, endDate: this.selectedEndDate})
      eventBus.$emit('pod-search-update', { searchParameters: this.searchParameters })
    }
  },
  watch: {
    tabIndex: function (newValue, oldValue) {
      if (newValue === 4 && oldValue !== 4) {
        this.oldTabIndex = oldValue
      }
      if (newValue !== 4 && oldValue !== 4) {
        this.oldTabIndex = oldValue
      }
    },
    allSearchResults: function (newValue, oldValue) {
      if (this.searchActive) {
        return
      }
      if (newValue.length > 0) {
        if (this.tabIndex !== 4) {
          this.oldTabIndex = this.tabIndex
          this.tabIndex = 4
        }
      } else {
        if (this.currentSearchQuery === '' && this.tabIndex !== this.oldTabIndex) {
          this.tabIndex = this.oldTabIndex
        }
      }
    },
    currentDealer: function (newValue, oldValue) {
      this.initialize()
    }
  },
  mounted: function () {
    if (this.$route.meta && this.$route.meta.tabIndex) {
      this.tabIndex = this.$route.meta.tabIndex
    } else {
      this.tabIndex = this.currentDashboardTabIndex
    }
    this.initialize()
    let routeName = this.$route.name
    this.$store.dispatch('user/setCurrentListPageQuery', {route: routeName, criteria: {}})
  },
  beforeMount () {
    this.selectedStartDate = this.selectedDealTimeRange ? this.selectedDealTimeRange.startDate : moment().startOf('month').format('YYYY-MM-DD 00:00:00')
    this.selectedEndDate = this.selectedDealTimeRange ? this.selectedDealTimeRange.endDate : moment().endOf('month').format('YYYY-MM-DD 23:59:59')
    this.inTabletMode = util.isInTabletMode()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  html body .b-tabs .tabs ul {
    padding:0;
  }
</style>

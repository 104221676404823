export default {
  total: 3,
  results: [
    {
      id: 1,
      subscriberId: 1,
      subscriberName: 'EAG',
      name: 'Tygerwheel',
      email: 'tyger@hostedapp.co.za',
      office: '9173942910',
      fax: '9173942910',
      address1: 'Far',
      address2: 'Far',
      address3: 'Further',
      address4: 'Away',
      city: 'City',
      country: 'Country',
      postalcode: '26548',
      state: 'Free State'
    },
    {
      id: 2,
      subscriberId: 1,
      subscriberName: 'EAG',
      name: 'Powerflow',
      email: 'powerflow@hostedapp.co.za',
      office: '1561514333',
      fax: '1561514333',
      address1: '15 Farberry Drive',
      address2: 'Somerset',
      address3: 'Agartha',
      address4: '',
      city: 'Atlantis',
      country: 'Ferer',
      postalcode: '1616',
      state: 'Jupiter'
    },
    {
      id: 3,
      subscriberId: 1,
      subscriberName: 'EAG',
      name: 'Underglow',
      email: 'ug@hostedapp.co.za',
      office: '',
      fax: '',
      address1: '',
      address2: '',
      address3: '',
      address4: '',
      city: '',
      country: '',
      postalcode: '',
      state: ''
    }
  ]
}

<template>
  <div>
    <div class="tags">
      <span class="tag" v-for="tag in data" :key="tag.id" v-tooltip="{
        content: getTagDescription(tag),
        classes: ['info']
      }">{{ tag.name }}</span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'TagView',
  props: {
    value: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      data: []
    }
  },
  methods: {
    getTagDescription (tag) {
      return tag.description
    }
  },
  computed: {
  },
  watch: {
    value: function (newValue, oldValue) {
      this.data = newValue
    }
  },
  mounted: function () {
    this.data = this.value
  }
}
</script>

<style scoped>
  .column {
    padding-bottom:0.3em
  }
</style>

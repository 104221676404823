import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/vendors',
    name: 'ListVendors',
    component: () => import('@/components/configuration/vendors/ListVendors'),
    beforeEnter: requiresAuth
  },
  {
    name: 'VendorsQuickView',
    path: '/configuration/vendors/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/vendors/ViewVendor')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/vendors/new',
    name: 'NewVendor',
    component: () => import('@/components/configuration/vendors/NewVendor'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/vendors/view/:id',
    name: 'ViewVendor',
    component: () => import('@/components/configuration/vendors/ViewVendor'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/vendors/edit/:id',
    name: 'EditVendor',
    component: () => import('@/components/configuration/vendors/EditVendor'),
    beforeEnter: requiresAuth
  }
]

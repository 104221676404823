<template>
  <div class="fullpage" v-if="!isAuthenticated && !isLoading">
    <div class="columns is-marginless">
      <div class="column is-5" style="margin-top:20px;">
        <form @submit.prevent="validateBeforeSubmit" v-if="!requestSubmitted">
          <div class="container" style="margin-left:40px;float:left;max-width:600px;">
            <h1 class="logo">
              <div style="width:200px"><img src="@/assets/logo_standard.png" alt="logo"></div>
            </h1>
            <div class="heading">Live</div>
            <div class="field">
              <p class="sub-heading">Please enter your email address</p>
              <label class="input-title">Email<b-icon pack="fas" icon="star" class="fa-ss" /></label>
              <div class="control has-icons-left has-icons-right">
                <input name="currentEmail" data-vv-as="email address" v-validate="'required|email'"
                  :class="{'input': true, 'is-danger': errors.has('currentEmail') }" type="text" placeholder="user@email.com"
                  v-model="currentEmail" tabindex=4 @keyup.enter="validateBeforeSubmit" data-lpignore="true" autocomplete="off">
                <span v-show="errors.has('currentEmail')" class="help is-danger">
                  <i v-show="errors.has('currentEmail')" class="fas fa-exclamation-triangle"></i>
                  {{ errors.first('currentEmail') }}
                </span>
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right" v-show="!errors.has('currentEmail')">
                  <i class="fas fa-check"></i>
                </span>
                <span class="icon is-small is-right" v-show="errors.has('currentEmail')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
            <button class="button is-primary" :class="{'is-loading': isLoading }" type="button"
              @click="validateBeforeSubmit">Send</button>
            <div class="loading-container">
              <div class="title is-size-7 has-text-primary" v-if="isLoading">
                <span>{{loadingMessage}}</span>
              </div>
            </div>
            <div class="loading-container has-text-centered" style="padding:10px">
              <p v-if="errorMessage.length">
                <ul class="errors">
                  <li class="has-text-danger has-text-centered">{{ errorMessage }}</li>
                </ul>
                <br />
              </p>
            </div>
          </div>
        </form>
        <form @submit.prevent="validateBeforeSubmit" v-else>
          <div class="container" style="margin-left:40px;float:left;max-width:600px;">
            <h1 class="logo">
              <div style="width:200px"><img src="@/assets/logo_standard.png" alt="logo"></div>
            </h1>
            <div class="heading">Live</div>
            <div class="field">
              <p class="sub-heading">If you have entered a valid email, a verification code was sent your registered email or mobile number. Please enter the code and enter your new password below.</p>
              <label class="input-title">Email<b-icon pack="fas" icon="star" class="fa-ss" /></label>
              <div class="control has-icons-left has-icons-right">
                <input name="currentEmail" data-vv-as="email address" v-validate="'required|email'"
                  :class="{'input': true, 'is-danger': errors.has('currentEmail') }" type="text" placeholder="user@email.com"
                  v-model="currentEmail" tabindex=4 @keyup.enter="validateBeforeSubmit" data-lpignore="true" autocomplete="off" :disabled="true">
                <span v-show="errors.has('currentEmail')" class="help is-danger">
                  <i v-show="errors.has('currentEmail')" class="fas fa-exclamation-triangle"></i>
                  {{ errors.first('currentEmail') }}
                </span>
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right" v-show="!errors.has('currentEmail')">
                  <i class="fas fa-check"></i>
                </span>
                <span class="icon is-small is-right" v-show="errors.has('currentEmail')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="input-title">Password<b-icon pack="fas" icon="star" class="fa-ss" /></label>
              <div class="control has-icons-left has-icons-right">
                <input ref="loginPassword" name="loginPassword" data-vv-as="password" v-validate="'required'"
                :class="{'input': true, 'is-danger': errors.has('loginPassword') }" type="password" placeholder="*********"
                v-model="loginPassword" tabindex=4 v-tooltip="{content: passwordToolTip}">
                <b-progress :value="passwordStrength" format="percent" :type="passwordStrengthType"></b-progress>
                <p v-if="hasStrengthError">
                    <span class="help is-danger"><i class="fas fa-exclamation-triangle"></i> {{ strengthErrorDescription }}</span>
                </p>
                <span v-show="errors.has('loginPassword')" class="help is-danger">
                  <i v-show="errors.has('loginPassword')" class="fas fa-exclamation-triangle"></i> {{
                  errors.first('loginPassword') }}
                </span>
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right" v-show="!errors.has('loginPassword')">
                  <i class="fas fa-check"></i>
                </span>
                <span class="icon is-small is-right" v-show="errors.has('loginPassword')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="input-title">Confirm Password<b-icon pack="fas" icon="star" class="fa-ss" /></label>
              <div class="control has-icons-left has-icons-right">
                <input name="loginPasswordConfirmation" data-vv-as="password confirm" v-validate="'required|confirmed:loginPassword'"
                :class="{'input': true, 'is-danger': errors.has('loginPasswordConfrim') }" type="password" placeholder="*********"
                v-model="loginPasswordConfirmation" tabindex=4 >
                <span v-show="errors.has('loginPasswordConfirmation')" class="help is-danger">
                  <i v-show="errors.has('loginPasswordConfirmation')" class="fas fa-exclamation-triangle"></i> {{
                  errors.first('loginPasswordConfirmation') }}
                </span>
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right" v-show="!errors.has('loginPasswordConfirmation')">
                  <i class="fas fa-check"></i>
                </span>
                <span class="icon is-small is-right" v-show="errors.has('loginPasswordConfirmation')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="input-title">Code<b-icon pack="fas" icon="star" class="fa-ss" /></label>
              <div class="control has-icons-right">
                <input
                  ref="twoFactorInput"
                  id="code"
                  inputmode="numeric"
                  pattern="[0-9]*"
                  autocomplete="one-time-code"
                  name="code"
                  v-model="code"
                  data-vv-as="code"
                  v-validate="'required'"
                  :class="{'input': true, 'is-danger': errors.has('code') }"
                  type="text">
                <span v-show="errors.has('code')" class="help is-danger"><i v-show="errors.has('code')" class="fas fa-exclamation-triangle"></i> {{ errors.first('code') }}</span>
                <span class="icon is-small is-right" v-show="!errors.has('code')"><i class="fas fa-check"></i></span>
                <span class="icon is-small is-right" v-show="errors.has('code')"><i class="fas fa-exclamation-triangle"></i></span>
              </div>
            </div>
            <button class="button is-primary" :class="{'is-loading': isLoading }" type="button"
              @click="validateBeforeSubmit" :disabled="code.length === 0 || loginPassword.length === 0 || loginPasswordConfirmation.length === 0">Reset Password</button>
            <button class="button is-danger" @click="isReturnToLoginActive = true" type="button">Back to login</button>
            <div class="loading-container">
              <div class="title is-size-7 has-text-primary" v-if="isLoading">
                <span>{{loadingMessage}}</span>
              </div>
            </div>
            <div class="loading-container has-text-centered" style="padding:10px">
              <p v-if="errorMessage.length">
                <ul class="errors">
                  <li class="has-text-danger has-text-centered">{{ errorMessage }}</li>
                </ul>
                <br />
              </p>
            </div>
          </div>
        </form>
      </div>
      <b-modal :active.sync="isReturnToLoginActive" :width="640" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Confirm Return Action</p>
          </header>
          <section class="modal-card-body">
            Are you sure you want to return to the login screen?
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" @click="onCancelReset()">Back</button>
            <button class="button" @click="isReturnToLoginActive = false">Cancel</button>
          </footer>
        </div>
      </b-modal>
      <div class="column is-7 is-paddingless is-hidden-mobile is-hidden-touch swiper-container" id="banner-slider">
        <swiper class="swiper" :options="swiperOption">
          <swiper-slide class="feat-login-image"></swiper-slide>
          <swiper-slide class="hero-image-1"></swiper-slide>
          <swiper-slide class="hero-image-2"></swiper-slide>
          <swiper-slide class="hero-image-3"></swiper-slide>
          <swiper-slide class="hero-image-4"></swiper-slide>
          <swiper-slide class="hero-image-5"></swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import { Swiper, SwiperSlide} from 'swiper/vue'
import 'swiper/css'
import { createNamespacedHelpers } from 'vuex'
import logger from '@/services/loggingService'
const mapAuthGetters = createNamespacedHelpers('auth').mapGetters

export default {
  name: 'ResetPassword',
  mixins: [validateBeforeSubmitMixin],
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      currentEmail: '',
      isLoading: false,
      loadingMessage: '',
      error: false,
      errorMessage: '',
      swiperOption: {
        speed: 1000,
        spaceBetween: 0,
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        preloadImages: true,
        updateOnImagesReady: true,
        grabCursor: false,
        autoplay: {
          delay: 10000
        }
      },
      requestSubmitted: false,
      isReturnToLoginActive: false,
      loginPassword: '',
      loginPasswordConfirmation: '',
      hasStrengthError: false,
      strengthErrorDescription: '',
      passwordStrength: 0,
      code: '',
      passwordToolTip: `<ul> <li>The password must be a minimum of 6 characters in length</li> <li>Include uppercase and lowercase letters (a-z, A-Z)</li> <li>Include numbers (0-9)</li> <li>Include special characters (+,%.*@@)</li> </ul> <br/>`,
      passwordStrengthType: 'is-danger',
      checkPasswordStrength: '',
      passwordStrengthProgress: 0,
      userId: null
    }
  },
  computed: {
    ...mapAuthGetters(['isAuthenticated'])
  },
  methods: {
    resetErrors: function () {
      this.error = false
      this.errorMessage = ''
    },
    onSave: async function () {
      if (!this.requestSubmitted) {
        this.resetErrors()
        this.isLoading = true
        this.loadingMessage = 'Sending your request'
        let credentialModel = {
          email: this.currentEmail
        }
        try {
          await this.$store.dispatch('auth/resetPassword', credentialModel)
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
          this.error = true
          this.errorMessage = error.message
        }
        this.requestSubmitted = true
      } else {
        this.hasStrengthError = false
        this.strengthErrorDescription = ''
        let passwordStrength = this.checkPasswordStrength
        if (passwordStrength === 'Weak' || passwordStrength === 'Too short') {
          if (passwordStrength === 'Too short') {
            this.strengthErrorDescription = 'The entered password is too short in length.'
          } else {
            this.strengthErrorDescription = 'The entered password is too weak, please try adding a combination of uppercase, lowercase, numbers and special characters (e.g. Password_123).'
          }
          this.hasStrengthError = true
          return
        }
        this.isLoading = true
        this.loadingMessage = 'Sending your request'
        let credentialModel = {
          email: this.currentEmail,
          codeParts: this.code,
          password: this.loginPassword
        }
        this.resetErrors()
        try {
          let response = await this.$store.dispatch('auth/setPasswordAuthRequest', credentialModel)
          if (response && response.id) {
            this.userId = response.id
            this.onVerifyTwoFactorCode()
          }
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
          this.error = true
          if (error && error.message) {
            this.errorMessage = error.message
          } else {
            this.errorMessage = 'An unknown error occured'
          }
        }
      }
    },
    removeNavBar: function () {
      document.getElementsByTagName('html')[0].classList.remove('has-navbar-fixed-top')
    },
    addNavBar: function () {
      if (!document.getElementsByTagName('html')[0].classList.contains('has-navbar-fixed-top')) {
        document.getElementsByTagName('html')[0].classList.add('has-navbar-fixed-top')
      }
    },
    onCancelReset: function () {
      this.$router.push('/auth/login')
    },
    loadConfiguration: async function () {
      // If the user is not authenticated client side first do a check to the API to see if they authenticated server side via secure auth cookies
      this.isLoading = true
      if (!this.isAuthenticated) {
        try {
          await this.$store.dispatch('auth/authVerificationRequest')
          await this.loadBaseData()
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
          logger.log(error)
        }
      } else {
        await this.loadBaseData()
        this.isLoading = false
      }
    },
    loadBaseData: async function () {
      try {
        this.isLoadingConfiguration = true
        let checkResult = await this.$store.dispatch('user/setCurrentUser')
        if (checkResult) {
          let allActions = []
          allActions.push(this.$store.dispatch('subscriber/setCurrentSubscriber', { id: this.$store.state.user.currentUser.subscriberId }))
          allActions.push(this.$store.dispatch('dealer/setCurrentDealer', { id: this.$store.state.user.currentUser.dealerId }))
          allActions.push(this.$store.dispatch('role/setRoles'))
          allActions.push(this.$store.dispatch('bank/setBanks'))
          allActions.push(this.$store.dispatch('feature/setFeatures'))
          allActions.push(this.$store.dispatch('dealer/setDealers'))
          allActions.push(this.$store.dispatch('dealer/setDesignations'))
          allActions.push(this.$store.dispatch('dealer/setDepartments'))
          allActions.push(this.$store.dispatch('user/setUsers'))
          allActions.push(this.$store.dispatch('location/setLocations'))
          allActions.push(this.$store.dispatch('vehicleMake/setVehicleMakes'))
          allActions.push(this.$store.dispatch('leadSource/setLeadSources'))
          allActions.push(this.$store.dispatch('states/setAllStates'))
          allActions.push(this.$store.dispatch('roServiceVehicleMake/setDealerRoServiceVehicleMakes', { dealerId: this.$store.state.user.currentUser.dealerId }))
          allActions.push(this.$store.dispatch('scheduledPayment/setScheduledPaymentPayees'))
          await Promise.all(allActions)
          this.isLoadingConfiguration = false
          this.isLoading = false
          await this.$store.dispatch('auth/setSystemReady')
          if (this.$route.query && this.$route.query.redir && this.$route.query.redir.length > 1) {
            this.$router.push(this.$route.query.redir)
          } else {
            this.$router.push('/')
          }
        } else {
          this.isLoading = false
          this.$router.push('/auth/login')
        }
      } catch (error) {
        this.isLoadingConfiguration = false
        this.isLoading = false
        logger.log(error)
      }
    },
    onVerifyTwoFactorCode: async function () {
      let parts = this.userId + '|' + this.code
      let authModel = {codeParts: parts}
      try {
        await this.$store.dispatch('auth/codeAuthRequest', authModel)
        this.isVerifyingTwoFactorCode = false
        this.twoFactorCodeActive = false
        await this.loadConfiguration()
      } catch (error) {
        this.isVerifyingTwoFactorCode = false
        this.error = true
        this.errorMessage = error.message
      }
    }
  },
  watch: {
    loginPassword: function (newValue, oldValue) {
      this.passwordStrength = 0
      let password = this.loginPassword
      if (password.length > 7) this.passwordStrength += 20
      // If password contains both lower and uppercase characters, increase strength value.
      if (password.match(/([a-z].*[A-Z])|([A-Z].*[a-z])/)) this.passwordStrength += 20
      // If it has numbers and characters, increase strength value.
      if (password.match(/([a-zA-Z])/) && password.match(/([0-9])/)) this.passwordStrength += 20
      // If it has one special character, increase strength value.
      if (password.match(/([!,%,&,@@,#,$,^,*,?,_,~])/)) this.passwordStrength += 20
      // If it has two special characters, increase strength value.
      if (password.match(/(.*[!,%,&,@@,#,$,^,*,?,_,~].*[!,%,&,@@,#,$,^,*,?,_,~])/)) this.passwordStrength += 20

      // If value is less than 2
      if (password.length < 6) {
        this.checkPasswordStrength = 'Too short'
        this.passwordStrengthType = 'is-danger'
      } else if (this.passwordStrength < 40) {
        this.checkPasswordStrength = 'Weak'
        this.passwordStrengthType = 'is-danger'
      } else if (this.strength === 40) {
        this.checkPasswordStrength = 'Good'
        this.passwordStrengthType = 'is-success'
      } else {
        this.checkPasswordStrength = 'Strong'
        this.passwordStrengthType = 'is-success'
      }
    }
  },
  beforeMount () {
    this.removeNavBar()
  },
  beforeDestroy () {
    this.addNavBar()
  },
  mounted: async function () {
    // await this.loadConfiguration()
  }
}
</script>

<style scoped lang="scss" media="screen">
  .footer {
    font-family: "Roboto Regular",sans-serif;
    color: #3f4353;
    line-height: 1.6rem;
    font-size: 13px;
    font-weight: bold;
  }
  .input-title {
    color:#d4d5db;
    font-family: "Roboto Regular",sans-serif;
    font-size: .8rem;
  }

  .fullpage {
    background: #fff;
  }

  .heading {
    font-size: 1.55rem;
    font-family: "Roboto Regular",sans-serif;
    padding-bottom:100px;
    text-transform: none;
  }

  .swiper {
    width: 64vw;
    max-height:100%
  }

  .sub-heading {
    font-family: "Roboto Regular",sans-serif;
    color: #3f4353;
    margin: 0 0 1em;
    word-break: normal;
    font-size: 0.8rem;
  }
</style>

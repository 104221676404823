<template>
  <div class="box">
    <div class="columns">
      <div class="column is-narrow" v-if="showDealNoteTypeSelector" >
        <button-list-selector validateAs="deal note type" label="Deal Note Type" :required="true" v-model="selectedDealNoteType" :availableValues="dealNoteTypes" @input="updateValues()"></button-list-selector>
      </div>
      <div class="column is-narrow">
        <div class="field">
          <label class="label">
            Hot Note
            <i class="fab fa-hotjar has-text-danger" />
          </label>
          <div class="control" style="margin-top:5px;">
            <b-switch type="is-info" size="is-small" v-model="isHotNote" @input="updateValues()"></b-switch>
          </div>
        </div>
      </div>
      <div class="column is-narrow">
        <div class="field">
          <label class="label">
            Pin to the top
            <i class="fal fa-thumbtack"/>
          </label>
          <div class="control" style="margin-top:5px;">
            <b-switch type="is-info" size="is-small" v-model="pinned" @input="updateValues()"></b-switch>
          </div>
        </div>
      </div>
    </div>
    <div class="field" v-if="subscriberNotes && subscriberNotes.length > 0">
      <label class="label">Select standard note or alternatively type a custom note below.</label>
      <div class="control">
        <multiselect id="standardNote" v-model="selectedNote" :options="subscriberNotes" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select Standard Note" label="title" track-by="id" name="note" @select="updateNote" :hideSelected="true">
        </multiselect>
      </div>
    </div>
    <div class="field">
      <label class="label">{{label}}<b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon></label>
      <div class="control">
        <textarea :data-vv-scope="scope" :name="instanceName + 'note'" :data-vv-as="this.validateAs" v-validate="{'required': required}" :class="{'textarea': true, 'is-danger': errors.has(validationName + 'note') }" placeholder="" v-model="note" @input="updateValues()"></textarea>
      </div>
      <span v-show="errors.has(validationName + 'note')" class="help is-danger"><i v-show="errors.has(validationName + 'note')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'note') }}</span>
    </div>
  </div>
</template>

<script>

import ButtonListSelector from '@/components/generic/ButtonListSelector'
import standardNoteService from '../../services/standardNoteService'

export default {
  mixins: [],
  components: {
    'button-list-selector': ButtonListSelector
  },
  name: 'NoteCapture',
  props: {
    value: {
      type: Array,
      default: null
    },
    startWith: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Item'
    },
    validateAs: {
      type: String,
      default: 'Item'
    },
    required: {
      type: Boolean,
      default: true
    },
    showDealNoteTypeSelector: {
      type: Boolean,
      default: false
    },
    showInventoryNoteTypeSelector: {
      type: Boolean,
      default: false
    },
    noteSectionValue: {
      type: Object,
      default: null
    },
    scope: {
      type: String,
      default: null
    },
    entityId: {
      type: Number,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      returnedArray: this.value,
      note: this.startWith,
      instanceName: this.$uuid.v4(),
      selectedDealNoteType: { id: 0, name: 'Sale' },
      dealNoteTypes: [
        {
          id: 0,
          name: 'Sale'
        },
        {
          id: 1,
          name: 'Finance'
        }
      ],
      isHotNote: false,
      pinned: false,
      subscriberNotes: [],
      selectedNote: {}
    }
  },
  computed: {
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    validationNameOther: function () {
      return (this.scope) ? this.scope + '.' + this.instanceName + '.' : this.instanceName + '.'
    }
  },
  methods: {
    updateValues () {
      if (this.returnedArray === null) {
        this.returnedArray.length = 0
      }

      var values = {
        instanceName: this.instanceName,
        id: (this.noteSectionValue !== null) ? this.noteSectionValue.id : 0,
        dealNoteType: (this.selectedDealNoteType !== null) ? this.selectedDealNoteType.name : '',
        note: this.note,
        isHotNote: this.isHotNote,
        pinned: this.pinned
      }

      var filteredReturnedArray = this.returnedArray.filter(function (el) {
        return el.instanceName !== this.instanceName
      }, this)

      this.returnedArray.length = 0

      filteredReturnedArray.forEach(function (item) {
        this.returnedArray.push(item)
      }, this)

      this.returnedArray.push(values)
      this.$emit(
        'input',
        this.returnedArray.sort((a, b) => a.instanceName - b.instanceName)
      )
    },
    fetchSubscriberDealNotes: function () {
      let that = this
      const subscriberId = this.$store.state.user.currentUser.subscriberId
      standardNoteService.fetchDealNotesBySubscriberId(subscriberId).then(response => {
        that.subscriberNotes = response.results
      }).catch((error) => {
        that.serverErrorMessage = error.message
      })
    },
    fetchSubscriberInventoryNotes: function () {
      let that = this
      const subscriberId = this.$store.state.user.currentUser.subscriberId
      standardNoteService.fetchInventoryNotesBySubscriberId(subscriberId).then(response => {
        that.subscriberNotes = response.results
      }).catch((error) => {
        that.serverErrorMessage = error.message
      })
    },
    updateNote (selectedNote) {
      this.note = selectedNote.note
      this.updateValues()
    }
  },
  watch: {
    startWith: function (newValue, oldValue) {
      this.note = newValue
      this.updateValues()
    }
  },
  created () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }

    if (this.showDealNoteTypeSelector) {
      this.fetchSubscriberDealNotes()
    }

    if (this.showInventoryNoteTypeSelector) {
      this.fetchSubscriberInventoryNotes()
    }
  },
  mounted () {
    if (this.startWith) {
      this.note = this.startWith
      this.updateValues()
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .box {
    background-color: #f6f8fa;
  }

  textarea.textarea {
    background-color: white !important;
  }
</style>

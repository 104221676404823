import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/roles',
    name: 'ListRoles',
    component: () => import('@/components/configuration/roles/ListRoles'),
    beforeEnter: requiresAuth
  },
  {
    name: 'RolesQuickView',
    path: '/configuration/roles/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/roles/ViewRole')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/roles/new/:id?',
    name: 'NewRole',
    component: () => import('@/components/configuration/roles/NewRole'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/roles/view/:id',
    name: 'ViewRole',
    component: () => import('@/components/configuration/roles/ViewRole'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/roles/edit/:id',
    name: 'EditRole',
    component: () => import('@/components/configuration/roles/EditRole'),
    beforeEnter: requiresAuth
  }
]

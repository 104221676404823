import axios from 'axios'
import { setupCache } from 'axios-cache-interceptor'
import baseService from './baseService'
import customerData from './data/customer'

const cachedAxios = setupCache(axios.create(), {
  ttl: 5000,
  methods: ['get'],
  cachePredicate: {
    statusCheck: (status) => status >= 200 && status < 400
  },
  staleIfError: false
})

const customerService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('customer-load-all')
      if (baseService.isTestMode) {
        resolve(customerData)
      } else {
        axios
          .post('/customer/list', searchCriteria, {...baseService.defaultConfig, requestId: 'customer-load-all'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('customer all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  allCustomers (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData)
      } else {
        axios
          .get('/customer/list?', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all.find((x) => x.id === customerId))
      } else {
        cachedAxios
          .get('/customer/get?id=' + customerId, {}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (customerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var customer = {
          id: 999,
          dealerId: customerModel.dealerId,
          type: customerModel.selectedTypeStatus,
          companyName: customerModel.companyName,
          firstName: customerModel.firstName,
          middleName: customerModel.middleName,
          lastName: customerModel.lastName,
          initials: customerModel.initials,
          email: customerModel.email,
          mobile: customerModel.mobile,
          office: customerModel.office,
          home: customerModel.home,
          fax: customerModel.fax,
          deliveryAddress1: customerModel.deliveryAddress1,
          deliveryAddress2: customerModel.deliveryAddress2,
          deliveryAddress3: customerModel.deliveryAddress3,
          deliveryAddress4: customerModel.deliveryAddress4,
          deliveryCity: customerModel.deliveryCity,
          deliveryCountry: customerModel.deliveryCountry,
          deliveryPostalCode: customerModel.deliveryPostalCode,
          deliveryState: customerModel.deliveryState,
          deliveryGpsAddress: customerModel.deliveryGpsAddress,
          postalAddress1: customerModel.postalAddress1,
          postalAddress2: customerModel.postalAddress2,
          postalAddress3: customerModel.postalAddress3,
          postalAddress4: customerModel.postalAddress4,
          postalCity: customerModel.postalCity,
          postalCountry: customerModel.postalCountry,
          postalPostalCode: customerModel.postalPostalCode,
          postalState: customerModel.postalState,
          postalGpsAddress: customerModel.postalGpsAddress,
          status: 'Active'
        }
        customerData.all.push(customer)
        resolve(customer)
      } else {
        axios
          .post('/customer/save', customerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (customerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var customer = customerData.all.find((x) => x.id === customerModel.id)
        if (customer) {
          customer.dealerId = customerModel.dealerId
          customer.type = customerModel.type
          customer.companyName = customerModel.companyName
          customer.firstName = customerModel.firstName
          customer.middleName = customerModel.middleName
          customer.lastName = customerModel.lastName
          customer.initials = customerModel.initials
          customer.email = customerModel.email
          customer.mobile = customerModel.mobile
          customer.office = customerModel.office
          customer.home = customerModel.home
          customer.fax = customerModel.fax
          customer.deliveryAddress1 = customerModel.deliveryAddress1
          customer.deliveryAddress2 = customerModel.deliveryAddress2
          customer.deliveryAddress3 = customerModel.deliveryAddress3
          customer.deliveryAddress4 = customerModel.deliveryAddress4
          customer.deliveryCity = customerModel.deliveryCity
          customer.deliveryCountry = customerModel.deliveryCountry
          customer.deliveryPostalCode = customerModel.deliveryPostalCode
          customer.deliveryState = customerModel.deliveryState
          customer.deliveryGpsAddress = customerModel.deliveryGpsAddress
          customer.postalAddress1 = customerModel.postalAddress1
          customer.postalAddress2 = customerModel.postalAddress2
          customer.postalAddress3 = customerModel.postalAddress3
          customer.postalAddress4 = customerModel.postalAddress4
          customer.postalCity = customerModel.postalCity
          customer.postalCountry = customerModel.postalCountry
          customer.postalPostalCode = customerModel.postalPostalCode
          customer.postalState = customerModel.postalState
          customer.postalGpsAddress = customerModel.postalGpsAddress
          customer.status = customerModel.status
        }
        resolve(customer)
      } else {
        axios
          .post('/customer/update', customerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (customerModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        customerModels.forEach(element => {
          customerData.all.remove((x) => x.id === element.id)
        })
        resolve(customerData.all)
      } else {
        var ids = []
        customerModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/customer/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllByDealerId (dealerId, excludeNullMobile) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .get('/customer/findAllByDealerId?dealerId=' + dealerId + '&excludeNullMobile=' + excludeNullMobile, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getCreditApplicationById (creditApplicationId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .get('/customer/getCreditApplicationById?creditApplicationId=' + creditApplicationId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getCreditApplicationByCustomerId (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .get('/customer/getCreditApplicationByCustomerId?customerId=' + customerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateCreditApproval (creditApprovalModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post('/customer/updateCreditApproval', creditApprovalModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createCreditApplication (customerCreditApplicationRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/customer/createCreditApplicationRequest', customerCreditApplicationRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateCreditApplication (customerCreditApplicationRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/customer/updateCreditApplicationByDeal', customerCreditApplicationRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createIdentityVerificationRequest (customerCreditApplicationRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/customer/createIdentityVerificationRequest', customerCreditApplicationRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createDocumentVerificationRequest (customerCreditApplicationRequestModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/customer/createDocumentVerificationRequest', customerCreditApplicationRequestModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createIdentityVerificationLink (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get('/customer/createIdentityVerificationLink?id=' + customerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createCreditApplicationLink (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get('/customer/createCreditApplicationLink?id=' + customerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllByDealerIdForNotification (searchModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post('/customer/findAllByDealerIdForNotification', searchModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  ofacGetByIdNo (ofacModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post(process.env.VUE_APP_ROOT_OFAC_API + 'alias/GetByIdNo', ofacModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  ofacGetDetailByIdentityId (ofacModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post(process.env.VUE_APP_ROOT_OFAC_API + 'alias/GetDetailByIdentityId', ofacModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  ofacGetIdentificationsByIdentityId (ofacModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post(process.env.VUE_APP_ROOT_OFAC_API + 'alias/GetIdentificationsByIdentityId', ofacModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  ofacGetAddressesByIdentityId (ofacModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post(process.env.VUE_APP_ROOT_OFAC_API + 'alias/GetAddressesByIdentityId', ofacModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  ofacGetAliasesByIdentityId (ofacModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post(process.env.VUE_APP_ROOT_OFAC_API + 'alias/GetAliasesByIdentityId', ofacModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  ofacGetByName (ofacModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post(process.env.VUE_APP_ROOT_OFAC_API + 'alias/GetByName', ofacModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  ofacUpdateStatus (ofacModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post('/customer/UpdateOfacStatus', ofacModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findDealerPeakMatchByMobile (mobileNumber) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .get('/customer/findDealerPeakCustomerByMobile?mobileNumber=' + mobileNumber, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findDealerPeakMatchByEmail (email) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .get('/customer/findDealerPeakCustomerByEmail?email=' + email, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updatePhotoFiles (customerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post('/customer/UpdatePhotoFiles', customerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  merge (mergeModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post('/customer/merge', mergeModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  customerMatchSkipNotification (mergeSkipModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post('/customer/customerMatchSkipNotification', mergeSkipModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getCustomerByIds (customerIds) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .post('/customer/getcustomerbyids', customerIds, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  verifyCustomer (customerModel) {
    return new Promise((resolve, reject) => {
      axios
        .post('/customer/verifyCustomer', customerModel, baseService.defaultConfig)
        .then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error.response.data)
        })
    })
  },
  allStates () {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve([])
      } else {
        axios
          .get('/customer/allStates', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            console.log('states error: ', error)
            reject(error.response)
          })
      }
    })
  },
  updateCustomerNumber (customerNumberModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/customer/updatecustomernumber', customerNumberModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByCustomerId (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all)
      } else {
        axios
          .get('/customer/customernotes?id=' + customerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (customerNoteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var customer = customerData.all.find((x) => x.id === customerNoteModel.customerId)
        if (customer) {
          customer.notes.push(customerNoteModel)
        }
        resolve(customer)
      } else {
        axios
          .post('/customer/notes', customerNoteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/customer/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/customer/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/customer/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/customer/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getCustomerSsn (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all.find((x) => x.id === customerId))
      } else {
        cachedAxios
          .get('/customer/getcustomerssn?id=' + customerId, {}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getCustomerSsnForOfac (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(customerData.all.find((x) => x.id === customerId))
      } else {
        cachedAxios
          .get('/customer/getcustomerssnforofac?id=' + customerId, {}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  creditApplicationExistsCheck (customerCreditApplicationMatchModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/customer/existingCustomerCreditApplicationCheck', customerCreditApplicationMatchModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default customerService

<template>
  <div :id="identity" class="columns clickable is-mobile" @click="onClick" style="margin-top: -20px">
    <div class="column is-narrow" style="padding-right:0">
      <span class="title is-6 is-inline-block" v-html="title"></span>
    </div>
    <div class="column ellipsis subtitle is-7" v-if="isHtmlOverview">
      <span v-html="overview"></span>
    </div>
    <div class="column ellipsis" v-else-if="!forInventoryView">
      <span class="subtitle is-7" style="margin-left:5px;" v-if="overview && overview.length > 0" :title="overview">{{overview}}</span>
    </div>
    <div class="column ellipsis" v-else-if="forInventoryView && !inventoryUpdateHeader">
      <span class="subtitle is-7" style="margin-left:5px;" v-html="overview"></span>
    </div>
    <div class="column ellipsis" v-else>
      <span :class="headerClass" style="margin-left:5px;" v-if="overview && overview.length > 0" :title="overview">{{overview}}</span>
    </div>
    <div class="column is-narrow" v-if="allowCollapse">
      <b-icon pack="fas" icon="minus" class="xp-icon-minus is-pulled-right has-text-primary" v-if="!isCollapsed"></b-icon>
      <b-icon pack="fas" icon="plus" class="xp-icon-plus is-pulled-right has-text-primary" v-if="isCollapsed"></b-icon>
    </div>
  </div>
</template>

<script>

export default {
  name: 'SectionHeader',
  props: {
    title: {
      type: String,
      default: 'Header'
    },
    overview: {
      type: String,
      default: ''
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    parentSelector: {
      type: String,
      default: ''
    },
    allowCollapse: {
      type: Boolean,
      default: true
    },
    forInventoryView: {
      type: Boolean,
      default: false
    },
    inventoryUpdateHeader: {
      type: Boolean,
      default: false
    },
    infoHeader: {
      type: Boolean,
      default: false
    },
    isHtmlOverview: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      isCollapsed: false
    }
  },
  methods: {
    onClick: function (evt) {
      if (!this.allowCollapse) {
        return
      }
      this.isCollapsed = !this.isCollapsed
    },
    onCollapse: function () {
      let element = this.baseElement
      element.classList.add('header-collapsed')
      this.$emit('collapsed-status-changed', true)
    },
    onExpand: function () {
      let element = this.baseElement
      element.classList.remove('header-collapsed')
      this.$emit('collapsed-status-changed', false)
    }
  },
  computed: {
    baseElement: function () {
      return this.parentSelector === '' ? document.querySelector('#' + this.identity).parentElement : document.querySelector(this.parentSelector)
    },
    identity: function () {
      return 'section-header-' + this.instanceName
    },
    headerClass: function () {
      if (this.infoHeader) {
        return 'subtitle is-7 has-text-info'
      }
      return 'subtitle is-7'
    }
  },
  watch: {
    isCollapsed: function (newValue, oldValue) {
      newValue ? this.onCollapse() : this.onExpand()
    },
    collapsed: function (newValue, oldValue) {
      this.isCollapsed = this.collapsed
    }
  },
  mounted: function () {
    this.isCollapsed = this.collapsed
    this.isCollapsed ? this.onCollapse() : this.onExpand()
  }
}
</script>

<style scoped>

.xp-icon-minus {
  font-size:0.75em;
  vertical-align:middle;
}

.xp-icon-plus {
  font-size:0.75em;
  vertical-align:middle;
}

.ellipsis {
  width:auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>

<template>
  <div v-if="hasRetireServiceLoanerAccess && isServiceLoanerRetireable">
    <div class="quick-action" v-if="!isServiceLoanerRetireConfirmationActive">
        <button class="button" @click="onRequestServiceLoanerRetire()" title="Click to retire this service loaner">
            <b-icon pack="fal" icon="exchange" custom-size="fa-lg"></b-icon>
        </button>
    </div>
    <portal to="global-modal-portal" v-else>
        <b-modal :active.sync="isServiceLoanerRetireConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
            <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title" v-if="convertedInventory === null">Confirm Retire Service Loaner</p>
                <p class="modal-card-title" v-else>Service Loaner Successfully Retired</p>
            </header>
            <section class="modal-card-body">
                <div v-if="isError">
                <p v-if="serverErrors.length > 0" ref="generalErrorBlock">
                    <b class="title is-7 is-paddingless">Please correct the following error(s):</b>
                    <ul class="errors">
                    <li v-for="error in serverErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
                    </ul>
                    <br/>
                </p>

                <p v-if="serverErrorMessage.length">
                    <ul class="errors">
                    <li>{{ serverErrorMessage }}</li>
                    </ul>
                    <br/>
                </p>
                </div>
                <div v-else>
                    <p v-if="convertedInventory === null">Are you sure you wish to retire service loaner <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold" :title="inventoryItem.stockNo">{{ inventoryItem.stockNo }}</span></a>?</p>
                    <div v-else>
                      <p>Service loaner <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold" :title="inventoryItem.stockNo">{{ inventoryItem.stockNo }}</span></a> has been succesfully retired.</p>
                      <br/>
                      <p>A new inventory item has been generated with stock number <a @click.stop.prevent="onStockNoClick(convertedInventory)"><span class="has-text-weight-semibold" :title="convertedInventory.stockNo">{{ convertedInventory.stockNo }}</span></a>.</p>
                      <br/>
                      <p>Additional updates are required. Please click the view button below to complete the conversion process.</p>
                    </div>
                </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-danger" :class="{'is-loading': isSaving }"  @click="onRetireServiceLoaner()" v-if="convertedInventory === null">Confirm Retire</button>
                <button class="button is-success" @click="onShowConvertedInventory()" v-if="convertedInventory !== null">Complete New Stock-In</button>
                <button class="button" @click="onCancelServiceLoanerRetire" v-if="convertedInventory === null">Close</button>
            </footer>
            </div>
        </b-modal>
    </portal>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import inventoryService from '../../services/inventoryService'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'RetireServiceLoanerAction',
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isServiceLoanerRetireConfirmationActive: false,
      convertedInventory: null,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4()
    }
  },
  methods: {
    onShowConvertedInventory: function () {
      this.isServiceLoanerRetireConfirmationActive = false
      this.$emit('close')
      this.$router.push({name: 'EditInventory', params: { id: this.convertedInventory.id, defaultSalesPerson: this.currentUser }})
    },
    onRequestServiceLoanerRetire: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isServiceLoanerRetireConfirmationActive = true
    },
    onRetireServiceLoaner: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      inventoryService.retireServiceLoaner(this.inventoryItem).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.convertedInventory = response.convertedInventory
          this.$emit('inventory-service-loaner-retired', this.convertedInventory)
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
      this.isSaving = true
    },
    onCancelServiceLoanerRetire: function () {
      if (this.convertedInventory) {
        eventBus.$emit('inventory-changed', [this.convertedInventory])
      }
      this.isServiceLoanerRetireConfirmationActive = false
    },
    reset: function () {
      this.convertedInventory = null
      this.serverErrors = []
      this.serverErrorMessage = []
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    hasRetireServiceLoanerAccess: function () {
      let allowedPermissions = ['inventory.modify.retire-service-loaner']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isServiceLoanerRetireable: function () {
      return this.inventoryItem.isServiceLoaner && !this.inventoryItem.isServiceLoanerRetired
    },
    isError: function () {
      return this.serverErrorMessage.length > 0 || this.serverErrors.length > 0
    },
    inventoryItemId: function () {
      return this.inventoryItem.id
    }
  },
  watch: {
    inventoryItemId: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.reset()
      }
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }
</style>

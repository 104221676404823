<template>
<div class="quick-action">
  <div>
    <button :class="getStatusClass()" type="button" @click="searchActive = true" :title="getStatusTitle()" >
      <b-icon pack="fal" icon="fal fa-gavel" custom-size="fa-lg"></b-icon>
    </button>
  </div>
  <portal to="global-modal-portal" v-if="searchActive">
    <b-modal :active.sync="searchActive" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            OFAC Sanctions List Search
        </header>
        <section class="modal-card-body view-container" style="max-height:500px;padding: 12px; width: 769px !important;">
          <div class="box">
            <div class="columns">
              <div class="column is-4">
                <div class="field">
                  <label class="label">Full Name</label>
                  <div class="control">
                    <label name="ofacFirstName">{{currentCustomer.firstName + " " + currentCustomer.lastName}}</label>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <div class="field">
                  <label class="label">Social Security No.</label>
                  <div class="control is-flex">
                    <label name="ofacFirstName">{{currentCustomer.ssn}}</label>
                  </div>
                </div>
              </div>
              <div class="column is-4">
                <button class="button is-primary" :class="{'is-loading': isSaving }" @click.prevent="onSearch" type="button">Search Ofac</button>
              </div>
            </div>
          </div>
          <div class="row" v-if="searchListActive">
              <div v-if="aliasListData.length > 0">
                <div class="label">Results for {{searchTerm}} : </div>
                <div class="columns">
                  <div class="column is-12">
                    <div>
                      <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Alias Type</th>
                            <th>Type</th>
                          </tr>
                        </thead>
                        <tr v-for="a in aliasListData" :key="a.alias">
                          <td :style="a.primary == 'true'?'':'padding-left:10px'"><i :class="a.primary == 'true'?'':'fa fa-level-up fa-rotate-90'"></i> <a href="#" @click="getInfo(a.id)">{{a.alias}}</a></td>
                          <td>{{a.aliasType}}</td>
                          <td>{{a.type}}</td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
          </div>
          <div class="row" v-if="searchDetailActive">
            <div v-if="aliasDetailData.length > 0">
              <div class="label">Results for {{searchTerm}} : </div>
              <div class="box">
                <section-header title="Details"></section-header>
                <div class="columns" v-for="(a, index) in aliasDetailData" :key="index">
                  <div class="column is-6" >
                    <div class="field">
                      <label class="label">Fullname</label>
                      <div class="field">
                        {{ a.alias }}
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Citizenship</label>
                      <div class="field">
                        {{ a.citizenship }}
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Place Of Birth</label>
                      <div class="field">
                        {{ a.placeOfBirth }}
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Type</label>
                      <div class="field">
                        {{ a.type }}
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" >
                    <div class="field">
                      <label class="label">List</label>
                      <div class="field">
                        {{ a.list }}
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Program</label>
                      <div class="field">
                        {{ a.program }}
                      </div>
                    </div>

                    <div class="field">
                      <label class="label">Remarks</label>
                      <div class="field">
                        {{ a.remarks }}
                      </div>
                    </div>
                    <div class="field">
                      <label class="label">Linked To</label>
                      <div class="field">
                        {{ a.linkedTo }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div v-if="aliasIdentificationData.length > 0">
            <div class="box">
              <section-header :collapsed="true" :title="'Identifications (' + aliasIdentificationData.length + ')'"></section-header>
              <div class="columns">
                <div class="column is-12">
                  <div>
                    <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>Id No.</th>
                          <th>Issue Date</th>
                          <th>Expire Date</th>
                        </tr>
                      </thead>
                      <tr v-for="(a, index) in aliasIdentificationData" :key="index">
                        <td>{{a.type}} </td>
                        <td>{{a.identityNo}}</td>
                        <td>{{a.issueDate}}</td>
                        <td>{{a.expireDate}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <br>
          <div v-if="aliasData.length > 0">
            <div class="box">
              <section-header :collapsed="true" :title="'Aliases (' + aliasData.length + ')'"></section-header>
              <div class="columns">
                <div class="column is-12">
                  <div>
                    <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                      <thead>
                        <tr>
                          <th>Name</th>
                          <th>Type</th>
                        </tr>
                      </thead>
                      <tr v-for="(a, index) in aliasData" :key="index">
                        <td>{{a.alias}} {{a.primary == "true"?"(Primary)":""}}</td>
                        <td>{{a.aliasType}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="aliasAddressData.length > 0">
            <div class="box">
              <section-header :collapsed="true" :title="'Addresses (' + aliasAddressData.length + ')'"></section-header>
              <div class="columns">
                <div class="column is-12">
                  <div>
                    <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                      <thead>
                        <tr>
                          <th>Address</th>
                          <th>Country</th>
                        </tr>
                      </thead>
                      <tr v-for="(a, index) in aliasAddressData" :key="index">
                        <td>{{a.address}} </td>
                        <td>{{a.country}}</td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p>{{ResultsMessage}}</p>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="columns" style="width: 100%">
            <div class="column is-12">
              <button class="button is-primary" :class="{'is-loading': isApproving }" @click.prevent="approveStatus">Approve</button>
              <button class="button is-danger" :class="{'is-loading': isDenying }" @click.prevent="denyStatus">Deny</button>
              <button class="button" v-if="backActive" @click.prevent="back">Back</button>
              <button class="button" @click.prevent="close">Close</button>
            </div>
          </div>
        </footer>
      </div>
    </b-modal>
  </portal>
</div>
</template>

<script>
import eventBus from '@/eventBus'
import customerService from '@/services/customerService'
import SectionHeader from '@/components/generic/SectionHeader'
import moment from 'moment'
import ViewSsnControl from '@/components/generic/ViewSsnControl.vue'
import UserPinControl from '@/components/generic/UserPinControl.vue'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  components: {
    'section-header': SectionHeader,
    'view-ssn-control': ViewSsnControl,
    'user-pin-control': UserPinControl
  },
  props: {
    currentCustomer: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isSaving: false,
      isApproving: false,
      isDenying: false,
      searchActive: false,
      searchDetailActive: false,
      searchListActive: false,
      backActive: false,
      aliasData: [],
      aliasListData: [],
      aliasDetailData: [],
      aliasIdentificationData: [],
      aliasAddressData: [],
      searchTerm: '',
      ResultsMessage: '',
      ssnModel: null,
      isPinValidated: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    showViewSsnControl: function () {
      return this.currentCustomer && this.currentCustomer.ssn && this.currentCustomer.ssn !== '' && this.currentCustomer.ssn !== null
    }
  },
  methods: {
    back () {
      this.searchListActive = true
      this.searchDetailActive = false
      this.aliasData = []
      this.aliasDetailData = []
      this.aliasIdentificationData = []
      this.aliasAddressData = []
      this.backActive = false
    },
    close () {
      this.searchActive = false
      this.searchDetailActive = false
      this.searchListActive = false
      this.aliasData = []
      this.aliasListData = []
      this.aliasDetailData = []
      this.aliasIdentificationData = []
      this.aliasAddressData = []
      this.ResultsMessage = ''
    },
    approveStatus () {
      var searchCommand = {
        customerId: this.currentCustomer.id,
        status: 'Approved'
      }

      this.isApproving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      customerService.ofacUpdateStatus(searchCommand).then(response => {
        this.isApproving = false
        if (response.errors) {
          this.serverErrors = response.errors
          this.failedToast('Error: ' + this.serverErrors)
        } else {
          eventBus.$emit('customer-ofac-status-changed', {})
          this.close()
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isApproving = false
      })
    },
    denyStatus () {
      var searchCommand = {
        customerId: this.currentCustomer.id,
        status: 'Denied'
      }

      this.isDenying = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      customerService.ofacUpdateStatus(searchCommand).then(response => {
        this.isDenying = false
        if (response.errors) {
          this.serverErrors = response.errors
          this.failedToast('Error: ' + this.serverErrors)
        } else {
          eventBus.$emit('customer-ofac-status-changed', {})
          this.close()
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isDenying = false
      })
    },
    async fetchSsn () {
      let that = this
      that.isSaving = true
      await customerService.getCustomerSsnForOfac(that.currentCustomer.id).then(response => {
        if (response.errors) {
          that.failedToast('Oops! Something went wrong')
          this.serverErrors = response.errors
        } else {
          that.ssnModel = response.ssnModel
        }
        that.isSaving = false
      }).catch((error) => {
        that.serverErrorMessage = error.message
        that.isSaving = false
      })
    },
    async onSearch () {
      await this.fetchSsn()
      this.ofacSearchBySsn()
    },
    ofacSearchBySsn: function () {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        idNo: this.ssnModel.value,
        sortBy: 'name.asc'
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      customerService.ofacGetByIdNo(searchCommand).then(response => {
        this.isSaving = false
        this.backActive = false
        if (response.errors) {
          this.serverErrors = response.errors
          this.failedToast('Error: ' + this.serverErrors)
        } else {
          this.aliasData = response.data.results
          if (response.data.results.length === 0) {
            this.searchListActive = true
            this.searchDetailActive = false
            this.searchTerm = this.currentCustomer.lastName
            this.getByName()
          } else {
            this.searchDetailActive = true
            this.searchListActive = false
            this.searchTerm = this.currentCustomer.ssn
            this.getDetailByIdentityId(response.data.results[0].id)
            this.getIdentificationsByIdentityId(response.data.results[0].id)
            this.getAddressesByIdentityId(response.data.results[0].id)
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getDetailByIdentityId: function (identityId) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        identityId: identityId,
        sortBy: 'name.asc'
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      customerService.ofacGetDetailByIdentityId(searchCommand).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
          this.failedToast('Error: ' + this.serverErrors)
        } else {
          this.aliasDetailData = response.data.results
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getIdentificationsByIdentityId: function (identityId) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        identityId: identityId,
        sortBy: 'name.asc'
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      customerService.ofacGetIdentificationsByIdentityId(searchCommand).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
          this.failedToast('Error: ' + this.serverErrors)
        } else {
          this.aliasIdentificationData = response.data.results
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getAddressesByIdentityId: function (identityId) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        identityId: identityId,
        sortBy: 'name.asc'
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      customerService.ofacGetAddressesByIdentityId(searchCommand).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
          this.failedToast('Error: ' + this.serverErrors)
        } else {
          this.aliasAddressData = response.data.results
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getAliasesByIdentityId: function (identityId) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        identityId: identityId,
        sortBy: 'name.asc'
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      customerService.ofacGetAliasesByIdentityId(searchCommand).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
          this.failedToast('Error: ' + this.serverErrors)
        } else {
          this.aliasData = response.data.results
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getByName: function () {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        name: this.currentCustomer.lastName,
        sortBy: 'name.asc'
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      customerService.ofacGetByName(searchCommand).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
          this.failedToast('Error: ' + this.serverErrors)
        } else {
          if (response.data.results.length === 0) {
            this.ResultsMessage = 'Your search query matched no results.'
          } else {
            this.aliasListData = response.data.results
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getStatusClass: function () {
      let classToAttach = {}
      classToAttach['button'] = true
      classToAttach['is-pulled-left'] = true
      switch (this.currentCustomer.ofacStatus) {
        case 'Approved':
          classToAttach['has-background-success'] = true
          break
        case 'Denied':
          classToAttach['has-background-danger'] = true
          break
        case 'Multiple':
          classToAttach['has-background-warning'] = true
          break
        default:
          classToAttach['has-background-warning'] = true
          break
      }
      return classToAttach
    },
    getStatusTitle: function () {
      let title = ''
      let utcValue = moment.tz(this.currentCustomer.ofacRequestedAt, moment.defaultZone.name).utc().format('YYYY-MM-DD HH:mm')
      switch (this.currentCustomer.ofacStatus) {
        case 'Approved':
          title = 'Customer Ofac status approved on ' + utcValue
          break
        case 'Denied':
          title = 'Customer Ofac status denied on ' + utcValue
          break
        case 'Multiple':
          title = 'One or more Ofac entries found. Click to set status.'
          break
        default:
          title = 'Click to set customer Ofac status'
          break
      }
      return title
    },
    getInfo: function (id) {
      this.backActive = true
      this.searchDetailActive = true
      this.searchListActive = false
      this.getDetailByIdentityId(id)
      this.getIdentificationsByIdentityId(id)
      this.getAddressesByIdentityId(id)
      this.getAliasesByIdentityId(id)
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

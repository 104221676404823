<template>
  <nav class="navbar is-fixed-top" :class="appHeaderBackgroundColour">
    <div id="logo" class="navbar-brand" style="flex-grow: 1; flex-shrink: 1;">
      <span class="navbar-item is-hidden-desktop is-size-4" @click.stop="onToggleMenu"><i class="fal fa-sm" :class="{'fa-chevron-circle-down': !isSideBarActive, 'fa-chevron-circle-up': isSideBarActive}"></i></span>
      <a class="navbar-item is-hidden-touch is-hidden-mobile" v-if="subscriberLogoValid && showSubscriberLogo">
        <img :src="subscriberLogo" :alt="subscriberName"/>
      </a>
      <a class="navbar-item is-hidden-mobile" v-if="dealerLogoValid">
        <img :src="dealerLogo" :alt="dealerName"/>
      </a>
      <a class="navbar-item is-hidden-mobile"  title="Favourites" @click="onFavouritesClick" v-if="showFavourites">
        <span class="is-size-4"><i class="fas fa-fw fa-star"/></span>
        <span class="is-hidden-touch">Favourites</span>
      </a>
      <a class="navbar-item" title="Notifications" @click="onNotificationsClick" v-if="showNotifications">
        <span class="badge is-badge-small is-size-4" :data-badge="currentNotifications.length"><i class="fas fa-fw fa-bullhorn"/></span>
        <span class="is-hidden-touch">Notifications</span>
      </a>
      <global-search class="navbar-item is-pulled-right is-expanded"></global-search>
      <fast-action-button
        icon-size="medium"
        :position="fabPosition"
        :bg-color="fabBgColor"
        :actions="availableActions"
        @inventory="onNewInventory"
        @deal="onNewDeal"
        @service="onNewServiceRequest"
        @roservice="onNewRoService"
        @loan="onLoanerEvent"
        @inventory-pre-entry="onPreEntryInventory">
      </fast-action-button>
    </div>
    <div id="navbarItems" class="navbar-menu is-hidden-mobile" style="flex-grow: 0; flex-shrink: 0;">
      <div id="profile" class="navbar-end">
        <figure class="image is-32x32 is-flex" v-if="userLogoValid">
          <img :src="userLogo" />
        </figure>
        <div class="info">
          <profile></profile>
          <span>Administrator</span>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>

import Profile from '@/components/Profile'
import GlobalSearch from '@/components/generic/GlobalSearch'
import FastActionButton from '@/components/generic/FastActionButton'
import { createNamespacedHelpers } from 'vuex'
import util from '@/utils'
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapUserActions = createNamespacedHelpers('user').mapActions
const mapSubscriberState = createNamespacedHelpers('subscriber').mapState

export default {
  name: 'AppHeader',
  components: {
    profile: Profile,
    'global-search': GlobalSearch,
    'fast-action-button': FastActionButton
  },
  data () {
    return {
      showSubscriberLogo: false,
      fabPosition: 'top-right',
      fabBgColor: '#ff0000',
      availableActions: [],
      showFavourites: false,
      showNotifications: false,
      isInTabletMode: false
    }
  },
  methods: {
    ...mapUserActions(['toggleSideBar']),
    onNewInventory () {
      this.$router.push({name: 'NewInventory'})
    },
    onPreEntryInventory () {
      this.$router.push({name: 'NewInventory', query: {preEntry: true}})
    },
    onNewDeal () {
      this.$router.push({name: 'NewDeal'})
    },
    onNewServiceRequest () {
      this.$router.push({name: 'NewServiceRequest'})
    },
    onLoanerEvent () {
      this.$router.push({name: 'NewLoanerEvent'})
    },
    onNewRoService () {
      this.$router.push({name: 'NewRoService'})
    },
    onToggleMenu: function () {
      this.toggleSideBar()
    },
    toggleMenu () {
      document.getElementById('navMenu').classList.toggle('is-active')
    },
    onFavouritesClick () {

    },
    onNotificationsClick () {
      this.$router.push({name: 'Notifications'})
    },
    getPermittedActions () {
      this.availableActions = []
      if (!this.isInTabletMode) {
        if (this.hasInventoryCreateAccessPermission) {
          this.availableActions.push({
            name: 'inventory',
            icon: 'fa-car',
            tooltip: 'Add a new inventory item',
            color: '#ff0000'
          })
        }
        if (this.hasFeatureAccess('inventory.create.pre-entry')) {
          this.availableActions.push({
            name: 'inventory-pre-entry',
            icon: 'fa-car-tilt',
            tooltip: 'Add a new pre-entry inventory item',
            color: '#ff0000'
          })
        }
        if (this.hasFeatureAccess('deal.create')) {
          this.availableActions.push({
            name: 'deal',
            icon: 'fa-handshake',
            tooltip: 'Add a new deal',
            color: '#ff0000'
          })
        }
        if (this.hasFeatureAccess('servicerequest.create')) {
          this.availableActions.push({
            name: 'service',
            icon: 'fa-wrench',
            tooltip: 'Add a new service request',
            color: '#ff0000'
          })
        }
      }

      if (this.hasFeatureAccess('loanerevent.create')) {
        this.availableActions.push({
          name: 'loan',
          icon: 'fa-arrow-circle-right',
          tooltip: 'Add a new loaner event',
          color: '#ff0000'
        })
      }

      if (this.hasFeatureAccess('ro.service.create')) {
        this.availableActions.push({
          name: 'roservice',
          icon: 'fa-car-mechanic',
          tooltip: 'Add a repair order',
          color: '#ff0000'
        })
      }
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentPendingActions', 'currentNotifications', 'isSideBarActive']),
    ...mapSubscriberState(['currentSubscriber']),
    dealerLogo () {
      var defaultLogo = require('@/assets/logo.png')
      if (this.currentDealer) {
        return this.currentDealer.fileLocation !== '' ? this.currentDealer.fileLocation : defaultLogo
      }
      return defaultLogo
    },
    dealerName () {
      var defaultName = 'Experience Auto Group'
      if (this.currentDealer) {
        return this.currentDealer.name
      }
      return defaultName
    },
    subscriberLogo () {
      var defaultLogo = require('@/assets/eag_logo.png')
      if (this.currentSubscriber) {
        return this.currentSubscriber.fileLocation !== '' ? this.currentSubscriber.fileLocation : defaultLogo
      }
      return defaultLogo
    },
    subscriberName () {
      var defaultName = 'Experience Auto Group'
      if (this.currentSubscriber) {
        return this.currentSubscriber.name
      }
      return defaultName
    },
    userLogo () {
      var defaultLogo = require('@/assets/default_profile.png')
      if (this.currentUser) {
        return this.currentUser.fileLocation !== '' ? this.currentUser.fileLocation : defaultLogo
      }
    },
    subscriberLogoValid () {
      return this.subscriberLogo !== ''
    },
    dealerLogoValid () {
      return this.dealerLogo !== ''
    },
    userLogoValid () {
      return this.userLogo !== ''
    },
    appHeaderBackgroundColour () {
      return (this.currentSubscriber) ? this.currentSubscriber.appHeaderBackgroundColour : ''
    },
    hasInventoryCreateAccessPermission: function () {
      let allowedPermissions = ['inventory.create', 'inventory.create.type-new', 'inventory.create.type-purchased', 'inventory.create.type-consigned', 'inventory.create.type-tradein', 'inventory.create.type-ppa', 'inventory.create.type-leasereturn']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    }
  },
  mounted () {
    var navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0)
    if (navbarBurgers.length > 0) {
      navbarBurgers.forEach(function (el) {
        el.addEventListener('click', function () {
          var target = document.getElementById(el.dataset.target)
          el.classList.toggle('is-active')
          target.classList.toggle('is-active')
        })
      })
    }
    this.isInTabletMode = util.isInTabletMode()
    this.getPermittedActions()
  }
}
</script>

<style scoped>
  .navbar-item {
    padding-left: 0.5em;
    padding-right: 0.5em;
  }
</style>

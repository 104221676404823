import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'

var mixin = {
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  data () {
    return {
      query: '',
      versionSelectionEnabled: false
    }
  },
  computed: {
  },
  methods: {
  }
}

export default mixin

<template>
  <section>
    <form data-vv-scope="service-request-line" @submit.prevent="validateBeforeSubmit" @change="clearErrors">
      <div class="columns is-mobile is-multiline is-marginless">
        <div class="column is-12 ">
          <label class="label">Work Description<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
          <div class="control">
            <textarea
              placeholder="Enter a detailed description of the work required"
              name="workRequired"
              v-validate="'required'"
              data-vv-as="work description"
              :class="{'textarea': true, 'is-danger': errors.has('service-request-line.workRequired') }"
              rows="4"
              v-model="currentServiceRequestLine.workRequired"
              :disabled="inspectionCompleted && !specialModifyRights">
            </textarea>
            <span v-show="errors.has('service-request-line.workRequired')" class="help is-danger">
              <i v-show="errors.has('service-request-line.workRequired')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.workRequired') }}
            </span>
          </div>
        </div>

        <div class="column is-6">
          <label class="label">Vehicle Charge</label>
          <div class="control has-icons-left">
            <money name="vehicleCost" data-vv-as="cost" v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('vehicleCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.vehicleCost" v-bind="$globalMoneyFormat" :disabled="inspectionCompleted && !specialModifyRights"></money>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
            <span v-show="errors.has('service-request-line.vehicleCost')" class="help is-danger">
              <i v-show="errors.has('service-request-line.vehicleCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.vehicleCost') }}
            </span>
          </div>
        </div>

        <div class="column is-6">
          <label class="label">Vehicle Hours</label>
          <div class="control has-icons-left">
            <input name="vehicleHours" data-vv-as="vehicle hours" v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('vehicleCost') }" type="text" placeholder="0" v-model="currentServiceRequestLine.vehicleHours" :disabled="inspectionCompleted && !specialModifyRights">
            <span class="icon is-small is-left">
              <i class="fas fa-clock"></i>
            </span>
            <span v-show="errors.has('service-request-line.vehicleHours')" class="help is-danger">
              <i v-show="errors.has('service-request-line.vehicleHours')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.vehicleHours') }}
            </span>
          </div>
        </div>

        <div class="column is-6">
          <label class="label">Mfg. Cost</label>
          <div class="control has-icons-left">
            <money name="manufacturerCost" data-vv-as="manufacturer cost" v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('manufacturerCost') }" type="text" placeholder="0.00" v-model="currentServiceRequestLine.manufacturerCost" v-bind="$globalMoneyFormat" :disabled="inspectionCompleted && !specialModifyRights"></money>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
            <span v-show="errors.has('service-request-line.manufacturerCost')" class="help is-danger">
              <i v-show="errors.has('service-request-line.manufacturerCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.manufacturerCost') }}
            </span>
          </div>
        </div>

        <div class="column is-6">
          <label class="label">Mfg. Hours</label>
          <div class="control has-icons-left">
            <input name="manufacturerHours" data-vv-as="manufacturer hours" v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('manufacturerHours') }" type="text" placeholder="0" v-model="currentServiceRequestLine.manufacturerHours" :disabled="inspectionCompleted && !specialModifyRights">
            <span class="icon is-small is-left">
              <i class="fas fa-clock"></i>
            </span>
            <span v-show="errors.has('service-request-line.manufacturerHours')" class="help is-danger">
              <i v-show="errors.has('service-request-line.manufacturerHours')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.manufacturerHours') }}
            </span>
          </div>
        </div>

        <div class="column is-12 " v-if="currentServiceRequestLine.approvalInformation">
          <label class="label">Approval information</label>
          <div class="control">
            <textarea
              name="approvalInformation"
              data-vv-as="approval information"
              :class="{'textarea': true, 'is-danger': errors.has('service-request-line.approval information') }"
              rows="4"
              v-model="currentServiceRequestLine.approvalInformation"
              :disabled="true">
            </textarea>
            <span v-show="errors.has('service-request-line.approvalInformation')" class="help is-danger">
              <i v-show="errors.has('service-request-line.approvalInformation')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-line.approvalInformation') }}
            </span>
          </div>
        </div>

        <div class="column is-12">
          <button-list-selector
            validateAs="status"
            label="Status"
            :required="true"
            scope="service-request-line"
            v-model="selectedServiceRequestLineStatus"
            :disabled="!isServiceAdvisor"
            :activeValues="isServiceAdvisor"
            :availableValues="this.definedTypes.serviceRequestLineStatusType.options"
            :enabled="specialModifyRights || !inspectionCompleted"></button-list-selector>
        </div>
        <div class="column is-12 ">
          <file-selector
          v-model="requestLineAttachments"
          :enabled="specialModifyRights || (!inspectionCompleted && hasFeatureAccess(editAccessPermission))"
          label="Work Item Documents"
          :multiple="true"
          :isBoxed="false"
          :usePortalViewer="false"
          @uploadStarted="uploadStarted"
          @uploadComplete="uploadComplete">
          </file-selector>
        </div>
      </div>
      <portal :to="useFooterPortal">
        <div class="field is-grouped">
          <p class="control" v-if="hasFeatureAccess(editAccessPermission)">
            <button class="button is-primary" type="button" @click="validateBeforeSubmit" :disabled="(inspectionCompleted && !specialModifyRights) || isUploadingFile">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click.prevent="onCancel()">Cancel</a>
          </p>
          <p class="control" v-if="hasFeatureAccess(deleteAccessPermission)">
            <button class="button is-warning" type="button" @click.prevent="onDelete" :disabled="inspectionCompleted && !specialModifyRights">Delete</button>
          </p>
        </div>
      </portal>
    </form>

    <b-modal :active.sync="isDeleteConfirmationActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Delete Action</p>
        </header>
        <section class="modal-card-body">
          Are you sure you wish to delete this work item?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="onConfirmDelete()">Delete</button>
          <button class="button" @click="isDeleteConfirmationActive = false">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import FileSelector from '@/components/generic/FileSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'ServiceRequestLine',
  mixins: [utilitiesMixin],
  props: {
    useFooterPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    forServiceRequest: {
      type: Object,
      default: function () {
        return {
          id: 0,
          lines: []
        }
      }
    },
    currentServiceRequestLineId: {
      default: function () {
        return '0'
      }
    }
  },
  components: {
    'button-list-selector': ButtonListSelector,
    'file-selector': FileSelector
  },
  data () {
    return {
      currentServiceRequestLine: {
        id: this.$uuid.v4(),
        status: 'Active',
        workRequired: '',
        serviceRequestLineStatusId: null,
        requestLineAttachments: []
      },
      isDeleteConfirmationActive: false,
      selectedServiceRequestLineStatus: null,
      requestLineAttachments: [],
      createAccessPermission: 'servicerequest.create',
      viewAccessPermission: 'servicerequest.view',
      editAccessPermission: 'servicerequest.modify',
      deleteAccessPermission: 'servicerequest.delete',
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapConfigGetters(['definedTypes']),
    isServiceAdvisor: function () {
      return this.hasFeatureAccess('servicerequest.line-status.modify') ? this.definedTypes.serviceRequestLineStatusType.options : this.definedTypes.serviceRequestLineStatusType.options.filter(x => x.id !== 0)
    },
    inspectionCompleted: function () {
      return (this.forServiceRequest && this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.forServiceRequest.requestStatusTypeId)) && this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.forServiceRequest.requestStatusTypeId).altName === 'Complete'
    },
    specialModifyRights: function () {
      return this.hasFeatureAccess('servicerequest.special.edit', false)
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll('service-request-line').then((result) => {
        if (result) {
          this.onSave()
        } else {
          this.$goToValidationError(this.$validator.errors.items[0].id)
        }
      })
    },
    clearErrors: function () {
      this.errors.clear()
    },
    onConfirmDelete: function () {
      this.isDeleteConfirmationActive = false
      this.currentServiceRequestLine.status = 'Deleted'
      this.$emit('save-edit-service-request-line', this.currentServiceRequestLine, this.selectedServiceRequestLineStatus.id)
    },
    onDelete: function () {
      this.isDeleteConfirmationActive = true
    },
    onSave: function () {
      this.currentServiceRequestLine.requestLineAttachments = this.requestLineAttachments
      if (this.currentServiceRequestLine.new === true) {
        this.currentServiceRequestLine.new = false
        this.$emit('save-new-service-request-line', this.currentServiceRequestLine, this.selectedServiceRequestLineStatus.id)
      } else {
        this.$emit('save-edit-service-request-line', this.currentServiceRequestLine, this.selectedServiceRequestLineStatus.id)
      }
    },
    onCancel: function () {
      this.$emit('cancel-service-request-line', {})
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  mounted: function () {
    if (this.currentServiceRequestLineId !== '0') {
      var that = this
      let found = this.forServiceRequest.lines.find(function (x) {
        return x.id === that.currentServiceRequestLineId
      })

      if (found) {
        this.currentServiceRequestLine = Object.assign({}, found)
        this.selectedServiceRequestLineStatus = this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.currentServiceRequestLine.serviceRequestLineStatusId)
      }

      this.currentServiceRequestLine.requestLineAttachments.forEach((attach) => {
        if (attach.id) {
          var requestLineAttachments = {
            id: attach.id,
            loaded: true,
            imageData: attach.fileLocation,
            fileName: attach.fileName,
            uniqueId: attach.fileName,
            fileType: this.getFileType(attach.fileName),
            fileLocation: attach.fileLocation,
            name: attach.name
          }
          this.requestLineAttachments.push(requestLineAttachments)
        } else {
          this.requestLineAttachments.push(attach)
        }
      })
    } else {
      if (this.hasFeatureAccess('servicerequest.line-status.modify')) {
        this.selectedServiceRequestLineStatus = { id: 0, name: 'New', altName: 'New' }
      } else {
        this.selectedServiceRequestLineStatus = { id: 2, name: 'Awaiting Approval', altName: 'AwaitingApproval' }
      }
    }
  }
}
</script>

<style scoped>

</style>

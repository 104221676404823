<template>
  <div class="modal-card">
    <header class="modal-card-head">
      <p class="modal-card-title is-size-6 has-text-weight-semibold">Configure Located Vehicle</p>
    </header>
    <section class="modal-card-body">
      <div id="deal-status">
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <make-model-selector
          :label="makeModelSelectorTitle"
          data-vv-as="located vehicle"
          name="selectedMakeModel"
          :v-validate="{'required': true}"
          v-model="selectedMakeModel"
          :stockType="selectedStockType"
          :forceColorSelection="true"
          :showNotes="false"
          :showTags="false"
          :showAlternateColors="false"
          :showSourceSelector="true"
          :showVinSelector="true"
          :showCostSelector="true"
          :showAskingPriceSelector="true"
          :showTypeSelector="true"
          :showMileageSelector="true"
          :captureTitleStatus="true"
          :isLocated="true"
          :useModal="false"
          :canCancelDialog="true"
          :purchasedFromType="currentInventory.purchasedFromType"
          :inventoryType="selectedInventoryStatusType">
        </make-model-selector>
        <span v-show="errors.has('selectedMakeModel')" class="help is-danger"><i v-show="errors.has('selectedMakeModel')" class="fas fa-exclamation-triangle"></i> {{ errors.first('selectedMakeModel') }}</span>
      </div>
    </section>
    <footer class="modal-card-foot">
      <button class="button is-danger" type="button" @click="onCancel()">Close</button>
      <button :disabled="isUploadingFile" class="button is-primary" :class="{'is-loading': isSaving }" @click="onSubmitForm()">Save</button>
    </footer>

    <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import eventBus from '@/eventBus'
import moment from 'moment'
import inventoryService from '@/services/inventoryService'
import dealService from '@/services/dealService'
import DealerSelector from '@/components/generic/DealerSelector'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import SectionHeader from '@/components/generic/SectionHeader'
import MakeModelSelector from '@/components/generic/MakeModelSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'

const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapVehicleMakeGetters = createNamespacedHelpers('vehicleMake').mapGetters

export default {
  name: 'LocateDealCapture',
  mixins: [validateBeforeSubmitMixin],
  components: {
    'dealer-selector': DealerSelector,
    'section-header': SectionHeader,
    'make-model-selector': MakeModelSelector,
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    inventoryId: {
      type: Number,
      default: null
    },
    isLocatedCaptureActive: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      currentInventory: {
      },
      currentDeal: {
      },
      selectedDealer: null,
      selectedInventory: null,
      selectedStockType: null,
      selectedMakeModel: null,
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      isLoading: false,
      isUploadingFile: false
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapVehicleMakeGetters(['allVehicleMakes']),
    availableVehicleMakes () {
      return this.allVehicleMakes
    },
    overview: function () {
      if (!this.currentInventory) {
        return ''
      } else {
        var result = ''
        if (this.currentInventory.vinNo !== '') {
          result += 'VIN: ' + this.currentInventory.vinNo
        } else {
          result += 'VIN: Pending'
        }
        if (this.currentInventory.stockNo !== '') {
          result += ', STK: ' + this.currentInventory.stockNo
        } else {
          result += ', STK - Pending'
        }
        if (this.currentInventory.name !== '') {
          result += ', ITEM: ' + this.currentInventory.name
        } else {
          result += ', ITEM: -'
        }
        if (this.currentInventory.type !== '') {
          result += ', TYPE: ' + this.currentInventory.type
        } else {
          result += ', TYPE: -'
        }
        if (this.currentInventory.cpoStatus !== '') {
          result += ', CPO: ' + this.currentInventory.cpoStatus
        } else {
          result += ', CPO: -'
        }
        return result
      }
    },
    makeModelSelectorTitle: function () {
      return 'Located Vehicle'
    },
    selectedInventoryStatusType: function () {
      return this.definedTypes.inventoryTypes.find((x) => (x.name === this.currentInventory.type || x.altName === this.currentInventory.type))
    }
  },
  methods: {
    onCancel: function () {
      this.$emit('locate-capture-complete')
    },
    onComplete: function () {
      eventBus.$emit('reload-locate-list')
      this.$emit('locate-capture-complete')
    },
    reset: function () {
      this.currentDeal = null
      this.selectedDealer = null
      this.selectedPossessionStatus = null
      this.selectedMakeModel = null
      this.selectedSource = null
      this.selectedTypeStatus = null
      this.selectedCpoStatus = null
      this.selectedCustomer = null
      this.selectedWholeSaler = null
      this.selectedModel = null
      this.selectedDamageStatus = null
      this.selectedKeyStatus = null
      this.selectedBooksStatus = null
      this.selectedCarCoverStatus = null
      this.selectedBatteryChargerStatus = null
      this.selectedTitleStatus = null
      this.selectedWindowStickerStatus = null
      this.lastServiceDate = null
      this.warrantyStartDate = null
      this.warrantyExpirationDate = null
      this.selectedLocation = null
      this.isUsageVerificationActive = false
      this.active = 0
      this.damageNote = ''
      this.damageFiles = []
      this.titleFiles = []
      this.windowStickerFiles = []
      this.inventoryPhotoFiles = []
      this.windowStickerPdfFiles = []
      this.notes = []
    },
    loadInventory: function (id) {
      this.reset()
      this.currentInventoryId = id
      this.isLoading = true
      this.serverErrorMessage = ''

      inventoryService.single(this.currentInventoryId).then(response => {
        this.currentInventory = {
          ...response.inventory
        }
        dealService.byInventory(this.currentInventoryId).then(response => {
          this.currentDeal = response.results.find((x) => x.items.find((y) => y.isPrimary === true) !== null)
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          this.serverErrorMessage = error.message
        })
        this.selectedDealer = {id: this.currentInventory.dealerId, name: this.currentInventory.dealerName}
        this.initialInventoryModel()
        this.isLoading = false
      }).catch((error) => {
        this.onError(error)
      })
    },
    initialInventoryModel: function () {
      if (!this.currentInventory) {
        return
      }

      this.selectedInventory = this.currentInventory
      if (this.selectedInventory.attributes) {
        var exteriorColor = this.selectedInventory.attributes.find((x) => x.name === 'ExteriorColor')
        var interiorColor = this.selectedInventory.attributes.find((x) => x.name === 'InteriorColor')
        var vehicleStyle = this.selectedInventory.attributes.find((x) => x.name === 'Style')
        var damageNoteAttribute = this.selectedInventory.attributes.find((x) => x.name === 'DamageNotes')
        var damageNote = damageNoteAttribute ? damageNoteAttribute.value : ''
        var vehicleTrim = this.selectedInventory.attributes.find((x) => x.name === 'Trim')
        var searchType = this.selectedInventory.attributes.find((x) => x.name === 'SearchType')
        var altExteriorColors = this.selectedInventory.attributes.find((x) => x.name === 'AlternateExteriorColors')
        var altInteriorColors = this.selectedInventory.attributes.find((x) => x.name === 'AlternateInteriorColors')
        var searchTags = this.selectedInventory.attributes.find((x) => x.name === 'SearchTags')
        var searchNotes = this.selectedInventory.attributes.find((x) => x.name === 'SearchNotes')
      }

      var purchaseCost = this.selectedInventory.purchaseCost
      var usage = this.selectedInventory.usage
      var askingPrice = this.selectedInventory.askingPrice

      let selectedTitleStatus = this.definedTypes.titleStatusOptions.options.find((x) => (x.name === this.selectedInventory.titleStatus || x.altName === this.selectedInventory.titleStatus))
      let selectedDamageStatus = this.selectedInventory.isDamaged === null ? false : this.selectedInventory.isDamaged
      let warrantyExpirationDate = this.selectedInventory.warrantyExpirationDate ? new Date(this.selectedInventory.warrantyExpirationDate) : null
      let goodUntilDate = this.selectedInventory.goodUntilDate ? new Date(this.selectedInventory.goodUntilDate) : null
      let vinNo = this.selectedInventory.vinNo
      let accNo = this.selectedInventory.accNo
      let payoffAmount = this.selectedInventory.payoffAmount
      let selectedSource = this.selectedInventory.purchasedFromType === null ? null : this.definedTypes.allowedSourceTypes.options.find((x) => (x.name === this.selectedInventory.purchasedFromType || x.altName === this.selectedInventory.purchasedFromType))
      var selectedBank = null
      if (this.selectedInventory.bankId !== null && this.selectedInventory.bankId !== 0) {
        selectedBank = {
          id: this.selectedInventory.bankId,
          name: this.selectedInventory.bankName
        }
      }
      var selectedWholesaler = null
      var selectedCustomer = null
      if (selectedSource && selectedSource.id > this.definedTypes.allowedSourceTypes.Factory) {
        if (selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler) {
          var supplier = {
            id: this.selectedInventory.purchasedFromId,
            name: this.selectedInventory.purchasedFromName
          }
          selectedWholesaler = supplier
        } else {
          var customer = {
            id: this.selectedInventory.purchasedFromId,
            fullName: this.selectedInventory.purchasedFromName
          }
          selectedCustomer = customer
        }
      }

      let titleFiles = []
      this.selectedInventory.attachments.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        if (x.classType === 'Title') {
          titleFiles.push(x)
        }
      })

      let damageFiles = []
      this.selectedInventory.attachments.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        if (x.classType === 'Damage') {
          damageFiles.push(x)
        }
      })

      var vehicleAge = moment().year() - (+this.selectedInventory.vehicleProductionYear)
      var cpoAllowed = true
      var autoCpoStatus = 'Ineligible'

      if (this.selectedDealer && vehicleAge >= 0 && vehicleAge <= this.selectedDealer.autoCpoAgeLimit) {
        cpoAllowed = true
        autoCpoStatus = 'Cpo'
      } else if (this.selectedDealer && vehicleAge > this.selectedDealer.autoCpoAgeLimit && vehicleAge <= this.selectedDealer.cpoQualifyingAge) {
        cpoAllowed = true
        autoCpoStatus = 'Eligible'
      }

      let selectedMake = this.selectedInventory.vehicleMakeId > 0 ? this.allVehicleMakes.find((x) => x.id === this.selectedInventory.vehicleMakeId) : null
      let selectedModel = selectedMake && selectedMake.versions ? (selectedMake.versions.find((x) => x.id === this.selectedInventory.vehicleMakeVersionId)) : null

      this.selectedMakeModel = {
        exteriorColor: exteriorColor ? exteriorColor.value : '',
        interiorColor: interiorColor ? interiorColor.value : '',
        style: vehicleStyle ? vehicleStyle.value : '',
        makeId: this.selectedInventory.vehicleMakeId,
        modelId: this.selectedInventory.vehicleMakeVersionId,
        productionYear: this.selectedInventory.vehicleProductionYear,
        customMakeName: this.selectedInventory.customMake === '' ? null : this.selectedInventory.customMake,
        customModelName: this.selectedInventory.customModel === '' ? null : this.selectedInventory.customModel,
        customMakeModel: this.selectedInventory.customVehicleMakeVersion,
        trim: vehicleTrim ? vehicleTrim.value : '',
        selectedMake: selectedMake,
        selectedModel: selectedModel,
        vehicleAge: vehicleAge,
        cpoAllowed: cpoAllowed,
        autoCpoStatus: autoCpoStatus,
        purchaseCost: purchaseCost,
        usage: usage,
        askingPrice: askingPrice,
        locateType: (searchType && searchType.value !== '') ? this.definedTypes.allowedLocateTypes.options.find((x) => x.name === searchType.value) : null,
        altExteriorColors: (altExteriorColors && altExteriorColors.value && altExteriorColors.value.length > 0) ? altExteriorColors.value.split(',') : [],
        altInteriorColors: (altInteriorColors && altInteriorColors.value && altInteriorColors.value.length > 0) ? altInteriorColors.value.split(',') : [],
        tags: (searchTags && searchTags.value && searchTags.value.length > 0) ? searchTags.value.split(',') : [],
        notes: searchNotes ? searchNotes.value : '',
        source: selectedSource,
        wholesaler: selectedWholesaler,
        customer: selectedCustomer,
        titleStatus: selectedTitleStatus,
        warrantyExpirationDate: warrantyExpirationDate,
        selectedBank: selectedBank,
        accountNo: accNo,
        payoffAmount: payoffAmount,
        goodUntilDate: goodUntilDate,
        isDamaged: selectedDamageStatus,
        vinNo: vinNo,
        titleFiles: titleFiles,
        damageFiles: damageFiles,
        damageNote: damageNote,
        transmissionType: this.selectedInventory.transmissionType
      }
    },
    onError: function (error) {
      this.serverErrorMessage = error.message
      this.isLoading = false
      this.isSaving = false
    },
    onSave: function () {
      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      inventoryService.updatePossessionStatus(this.currentInventory).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.currentDeal.stockType = 'InStock'
          this.currentDeal.type = 'AwaitingArrival'
          dealService.update(this.currentDeal).then(response => {
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              this.onComplete()
            }
          })
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onSubmitForm () {
      eventBus.$emit('submit-make-model-form')
    },
    fileUploadsComplete: function () {
      this.isUploadingFile = false
    },
    fileUploadsStarted: function () {
      this.isUploadingFile = true
    }
  },
  watch: {
    selectedMakeModel: {
      handler: function (newValue, oldValue) {
        var stockNo = ''
        var vinNo = ''

        if (this.selectedInventory !== null) {
          stockNo = this.selectedInventory.stockNo
          vinNo = this.selectedInventory.vinNo
        }

        stockNo = (newValue.stockNo) ? newValue.stockNo : stockNo
        vinNo = (newValue.vinNo) ? newValue.vinNo : vinNo

        let setAttribute = function (model, name, value) {
          var existingAttribute = (model.attributes) ? model.attributes.find((x) => x.name === name) : null

          if ((value === '') && existingAttribute) {
            existingAttribute.status = 'Deleted'
            return
          }

          if (existingAttribute) {
            existingAttribute.value = value || ''
          } else {
            if (!model.attributes) {
              model.attributes = []
            }
            model.attributes.push({name: name, value: value})
          }
        }

        let newInventoryStatus = 'Active'
        let newInventoryType = (newValue.locateType && newValue.locateType.name === 'New') ? 'New' : 'Purchased'
        let newInventoryPossessionStatus = 'Inbound'
        let newInventoryDealerId = (this.selectedDealer) ? this.selectedDealer.id : null
        let newInventoryPurchasedFromType = (newValue.source && newValue.source !== '') ? newValue.source.altName : null
        let newInventoryPurchasedFromId = newValue.wholesaler ? newValue.wholesaler.id : (newValue.customer ? newValue.customer.id : null)
        let newTitleStatus = newValue.titleStatus ? newValue.titleStatus.name : null
        let newWarrantyExpirationDate = newValue.warrantyExpirationDate
        let newBankId = newValue.selectedBank ? newValue.selectedBank.id : null
        let newAccountNo = newValue.accountNo
        let newPayOffAmount = newValue.payoffAmount
        let newGoodUntilDate = newValue.goodUntilDate
        let isDamaged = newValue.isDamaged

        var inventoryModel = {
          ...this.selectedInventory,
          dealerId: newInventoryDealerId,
          stockNo: stockNo,
          vinNo: vinNo,
          isDamaged: isDamaged,
          type: newInventoryType,
          purchasedFromType: newInventoryPurchasedFromType,
          purchasedFromId: newInventoryPurchasedFromId,
          status: newInventoryStatus,
          possessionStatus: newInventoryPossessionStatus,
          purchaseCost: newValue.purchaseCost,
          usage: newValue.usage,
          askingPrice: newValue.askingPrice,
          bankId: newBankId,
          accNo: newAccountNo,
          goodUntilDate: newGoodUntilDate,
          payoffAmount: newPayOffAmount,
          titleStatus: newTitleStatus,
          warrantyExpirationDate: newWarrantyExpirationDate,
          vehicleMakeId: !newValue || newValue.makeId === -1 ? null : newValue.makeId,
          vehicleMakeVersionId: !newValue || newValue.modelId === -1 ? null : newValue.modelId,
          customVehicleMakeVersion: newValue ? newValue.customMakeModel : '',
          locationId: null,
          attachments: [],
          transmissionType: newValue.transmissionType
        }

        if (newValue.titleFiles.length > 0) {
          inventoryModel.attachments.push(...newValue.titleFiles)
        }

        if (newValue.damageFiles.length > 0) {
          inventoryModel.attachments.push(...newValue.damageFiles)
        }

        if (newValue !== null) {
          setAttribute(inventoryModel, 'ExteriorColor', newValue.exteriorColor)
          setAttribute(inventoryModel, 'InteriorColor', newValue.interiorColor)
          setAttribute(inventoryModel, 'DamageNotes', newValue.damageNote)
          setAttribute(inventoryModel, 'Style', newValue.style)
          setAttribute(inventoryModel, 'Trim', newValue.trim)
          setAttribute(inventoryModel, 'ProductionYear', newValue.productionYear)
          setAttribute(inventoryModel, 'Make', newValue.customMakeName)
          setAttribute(inventoryModel, 'Model', newValue.customModelName)

          setAttribute(inventoryModel, 'SearchType', (newValue.locateType && newValue.locateType.name) ? newValue.locateType.name : '')
          setAttribute(inventoryModel, 'AlternateExteriorColors', (newValue.altExteriorColors && newValue.altExteriorColors.length > 0) ? newValue.altExteriorColors.join(',') : '')
          setAttribute(inventoryModel, 'AlternateInteriorColors', (newValue.altInteriorColors && newValue.altInteriorColors.length > 0) ? newValue.altInteriorColors.join(',') : '')
          setAttribute(inventoryModel, 'SearchTags', (newValue.tags && newValue.tags.length > 0) ? newValue.tags.join(',') : '')
          setAttribute(inventoryModel, 'SearchNotes', (newValue.notes && newValue.notes.length > 0) ? newValue.notes : '')
        }

        this.currentInventory = inventoryModel
      },

      deep: true
    }
  },
  mounted: function () {
    this.loadInventory(this.inventoryId)
  },
  created () {
    eventBus.$on('parent-make-model-onSave', this.onSave)
    eventBus.$on('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$on('file-uploads-started', this.fileUploadsStarted)
  },
  destroyed () {
    eventBus.$off('parent-make-model-onSave', this.onSave)
    eventBus.$off('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$off('file-uploads-started', this.fileUploadsStarted)
  }
}

</script>

<style scoped>
  .modal-card-head, .modal-card-foot {
    padding: 20px !important
  }

  .modal-card-body {
    position:relative;
    max-height: 70vh !important;
    max-width: 960px;
    height: 70vh !important;
    overflow-y: auto;
    padding:0 !important;
  }

  @media screen and (max-width: 769px) {
    .modal-card-body {
      max-height: unset !important;
      height: unset !important;
    }
  }
</style>

<template>
  <div>
    <div class="columns">
      <div class="column is-6">
        <button-list-selector :scope="scope" validateAs="default payment method" label="Default Payment Method" :required="true" v-model="bankDetails.selectedDefaultMethod"
        :availableValues="allPaymentMethods" :enabled="!disableDefaultMethod"></button-list-selector>
      </div>
    </div>
    <div class="columns" v-if="showBankFields">
      <div class="column is-width-300">
        <bank-selector v-model="bankDetails.selectedBank" label="Bank" validateAs="bank" :required="isBankDetailsRequired" :scope="scope" :useValidator="thisValidator"></bank-selector>
      </div>
      <div class="column is-6">
        <button-list-selector :scope="scope" validateAs="bank account type" label="Account Type" :required="isBankDetailsRequired" v-model="bankDetails.selectedAccountType" :availableValues="allAccountTypes"></button-list-selector>
      </div>
    </div>
    <div class="columns" v-if="showBankFields">
      <div class="column is-6">
        <div class="field">
          <label class="label">Account Holder<b-icon v-if="isBankDetailsRequired" pack="fas" icon="star" class="fa-ss"></b-icon></label>
          <div class="control">
            <input :data-vv-scope="scope" :name="instanceName + '.accountHolder'" data-vv-as="account holder" v-validate="{'required': isBankDetailsRequired}" :class="{'input': true, 'is-danger': errors.has(accountHolderName) }" type="text" v-model="bankDetails.accountHolder">
            <span v-show="errors.has(accountHolderName)" class="help is-danger"><i v-show="errors.has(accountHolderName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(accountHolderName) }}</span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Branch Number</label>
          <div class="control">
            <input name="branchNo" :class="{'input': true }" type="text" v-model="bankDetails.branchNo">
          </div>
        </div>
      </div>
    </div>
    <div class="columns" v-if="showBankFields">
      <div class="column is-6">
        <div class="field">
          <label class="label">Account Number<b-icon v-if="isBankDetailsRequired" pack="fas" icon="star" class="fa-ss"></b-icon></label>
          <div class="control">
            <input :data-vv-scope="scope" :name="instanceName + '.accountNo'" data-vv-as="account no" v-validate="{'required': isBankDetailsRequired}" :class="{'input': true, 'is-danger': errors.has(accountNoName) }" type="text" v-model="bankDetails.accountNo">
            <span v-show="errors.has(accountNoName)" class="help is-danger"><i v-show="errors.has(accountNoName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(accountNoName) }}</span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Branch Name</label>
          <div class="control">
            <input name="branchName" :class="{'input': true }" type="text" v-model="bankDetails.branchName">
          </div>
        </div>
      </div>
    </div>
    <div class="columns" v-if="showBankFields">
      <div class="column is-6">
        <div class="field">
          <label class="label">ABA or Swift Number<b-icon v-if="isBankDetailsRequired" pack="fas" icon="star" class="fa-ss"></b-icon></label>
          <div class="control">
            <input :data-vv-scope="scope" :name="instanceName + '.swiftNo'" data-vv-as="ABA or swift number" v-validate="{'required': isBankDetailsRequired}" :class="{'input': true, 'is-danger': errors.has(swiftNoName) }" type="text" v-model="bankDetails.swiftNo">
            <span v-show="errors.has(swiftNoName)" class="help is-danger"><i v-show="errors.has(swiftNoName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(swiftNoName) }}</span>
          </div>
        </div>
      </div>
    </div>
    <b-loading :is-full-page="true" :active.sync="showLoader" :canCancel="false"></b-loading>
  </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
import bankService from '@/services/bankService'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import BankSelector from '@/components/generic/BankSelector'
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'BankDetailsCapture',
  props: {
    value: {
      type: Object,
      default: null
    },
    scope: {
      type: String,
      default: null
    },
    getValidationErrors: {
      type: Boolean,
      default: false
    },
    bankDetailsRequired: {
      type: Boolean,
      default: false
    },
    useValidator: {
      type: Object,
      default: null
    },
    disableDefaultMethod: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'button-list-selector': ButtonListSelector,
    'bank-selector': BankSelector
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      showLoader: false,
      bankDetails: this.value,
      bankData: []
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    accountHolderName: function () {
      return this.scope ? this.scope + '.' + this.instanceName + '.accountHolder' : this.instanceName + '.accountHolder'
    },
    accountNoName: function () {
      return this.scope ? this.scope + '.' + this.instanceName + '.accountNo' : this.instanceName + '.accountNo'
    },
    swiftNoName: function () {
      return this.scope ? this.scope + '.' + this.instanceName + '.swiftNo' : this.instanceName + '.swiftNo'
    },
    ...mapConfigGetters(['definedTypes']),
    allAccountTypes: function () {
      return this.definedTypes.bankAccountTypes
    },
    allPaymentMethods: function () {
      return this.definedTypes.paymentMethods
    },
    isBankDetailsRequired: function () {
      if (!this.bankDetails) {
        return false
      }

      if (!this.bankDetails.selectedDefaultMethod) {
        return false
      }

      if (this.bankDetails.selectedDefaultMethod.id === 0) {
        return false
      }

      if (this.bankDetailsRequired) {
        return true
      } else {
        var bankId = this.bankDetails.selectedBank ? this.bankDetails.selectedBank.id : 0
        var accountHolder = this.bankDetails.accountHolder || ''
        var accountNo = this.bankDetails.accountNo || ''
        // var accountType = this.bankDetails.selectedAccountType ? this.bankDetails.selectedAccountType.name : ''
        var branchNo = this.bankDetails.branchNo || ''
        var branchName = this.bankDetails.branchName || ''

        if (bankId !== 0 || accountHolder !== '' || accountNo !== '' || branchNo !== '' || branchName !== '') {
          return true
        } else {
          return false
        }
      }
    },
    showBankFields: function () {
      return this.bankDetails.selectedDefaultMethod && this.bankDetails.selectedDefaultMethod.name !== 'Check'
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    }
  },
  watch: {
    getValidationErrors: function (newValue, oldValue) {
      if (newValue === true) {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.bankDetails.hasValidationErrors = false
            this.bankDetails.canSave = true
          } else {
            this.bankDetails.hasValidationErrors = true
            this.bankDetails.canSave = false
          }
        })
      }
    },
    value: function (newValue, oldValue) {
      this.bankDetails = newValue
    }
  },
  methods: {
    loadBankData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.$store.state.dealer.currentDealer.id,
        sortBy: 'name.asc'
      }

      this.bankData = []
      this.showLoader = true
      bankService.all(searchCommand).then(response => {
        this.bankData = response.results
        this.showLoader = false
      }).catch((error) => {
        this.showLoader = false
        this.bankData = []
        throw error
      })
    }
  },
  mounted: function () {
    this.loadBankData()
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}
</script>

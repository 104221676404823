import requiresAuth from './route_guards'

export default [
  {
    path: '/conversations',
    name: 'ListConversations',
    component: () => import('@/components/conversations/ListConversations'),
    beforeEnter: requiresAuth
  }
]

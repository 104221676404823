
<template>
  <div class="is-inline-block" v-if="hasUpdateServiceLoanerServiceAccess && isServiceLoanerServiceable">
    <div class="is-inline-block" v-if="hasUpdateServiceLoanerServiceAccess && isView">
      <div class="quick-action">
        <button class="button" type="button" @click.stop.prevent="onMarkOos()" title="Click to mark service loaner out of service." >
          <b-icon pack="fal" icon="arrow-alt-to-right" custom-size="fa-lg"></b-icon>
        </button>
      </div>
    </div>
    <div class="is-inline-block" v-else>
      <switch-selector v-if="hasUpdateServiceLoanerServiceAccess" type="is-info" label="Mark Service Loaner Out of Service" v-on:on-change="onServiceLoanerStatusChange" v-model="serviceServiceLoaner"></switch-selector>
    </div>
    <portal to="global-modal-portal" v-if="isServiceLoanerOosActive">
      <b-modal :active.sync="isServiceLoanerOosActive" :width="640" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width: auto;">
          <header class="modal-card-head">
            <div class="columns is-multiline">
              <div class="column is-12">
                <p class="modal-card-title" v-if="convertedInventory === null">Confirm Remove Service Loaner From Service</p>
                <p class="modal-card-title" v-else>Service Loaner Successfully Removed From Service</p>
              </div>
            </div>
          </header>
          <section class="modal-card-body">
            <p v-if="convertedInventory === null">Are you sure you wish to remove service loaner <a @click.stop.prevent="onStockNoClick(inventory)"><span class="has-text-weight-semibold">{{ inventory.stockNo }}</span></a> from active service?</p>
            <div v-else>
              <p>Service loaner <a @click.stop.prevent="onStockNoClick(inventory)"><span class="has-text-weight-semibold">{{ inventory.stockNo }}</span></a> has been removed from active service succesfully.</p>
            </div>
          </section>
          <footer class="modal-card-foot">
              <button class="button is-danger" :class="{ 'is-loading': isSaving }"  @click.stop.prevent="markServiceLoanerOutOfService()" v-if="convertedInventory === null">Remove</button>
              <button class="button" @click="onCancel()">Close</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>
// import eventBus from '@/eventBus'
import inventoryService from '@/services/inventoryService'
import SwitchSelector from '@/components/generic/SwitchSelector'
import ErrorDisplayControl from '@/components/generic/ErrorDisplayControl'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'InventoryLoanerRemoveServiceAction',
  mixins: [],
  components: {
    'error-display-control': ErrorDisplayControl,
    'switch-selector': SwitchSelector
  },
  props: {
    inventory: {
      type: Object,
      default: null
    },
    isView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      isServiceLoanerOosActive: false,
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      serviceServiceLoaner: false,
      convertedInventory: null
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    hasUpdateServiceLoanerServiceAccess: function () {
      let allowedPermissions = ['inventory.modify']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isServiceLoanerServiceable: function () {
      if (this.inventory.isServiceLoanerOutOfService) {
        return false
      }
      return this.inventory.isServiceLoaner && !this.inventory.isServiceLoanerRetired
    },
    inventoryItemId: function () {
      return this.inventory.id
    }
  },
  methods: {
    onMarkOos: function () {
      this.isServiceLoanerOosActive = true
    },
    onCancel: function () {
      this.isServiceLoanerOosActive = false
    },
    markServiceLoanerOutOfService: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      inventoryService.markServiceLoanerOutOfService(this.inventory).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.convertedInventory = response.convertedInventory
          this.isServiceLoanerOosActive = false
          this.$emit('inventory-service-loaner-updated')
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    reset: function () {
      this.convertedInventory = null
      this.serverErrors = []
      this.serverErrorMessage = []
    },
    onServiceLoanerStatusChange: function () {
      if (this.serviceServiceLoaner) {
        this.isServiceLoanerOosActive = true
      }
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  watch: {
    inventoryItemId: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.reset()
      }
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
</style>

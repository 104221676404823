<template>
  <div>
    <p class="errors" v-if="serverErrors.length">
      Please correct the following error(s):
      <br/>
      <ul class="error-list">
        <li class="error-list-item" v-for="error in serverErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
      </ul>
      <br/>
    </p>
    <p class="errors" v-if="serverErrorMessage.length">
      An unforseen error has occurred:
      <br/>
      <ul class="error-list">
        <li class="error-list-item">{{ serverErrorMessage }}</li>
      </ul>
      <br/>
    </p>
  </div>
</template>

<script>

export default {
  name: 'ErrorDisplayControl',
  props: {
    serverErrors: {
      type: Array,
      default: () => []
    },
    serverErrorMessage: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  watch: {
  },
  mounted: function () {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-fullwidth">
        <multiselect @select="$markDirty()"
        :showLabels="false"
        :name="instanceName"
        :data-vv-scope="scope"
        :disabled="isDisabled"
        data-vv-validate-on="input|close"
        :data-vv-as="this.validateAs"
        v-validate="{'required': required}"
        :class="{'is-danger': errors.has(validationName) }"
        v-model="selectedDeviceCode"
        :options="availableDeviceCodes"
        :placeholder="defaultPlaceholder"
        label="name"
        trackBy="id"
        :hideSelected="true"
        :loading="isLoading"></multiselect>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
  </div>
</template>

<script>

import squareService from '@/services/squareService'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'UserSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Square Terminal'
    },
    validateAs: {
      type: String,
      default: 'square terminal'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    selectedDealer: {
      type: Object,
      default: null
    },
    filter: {
      type: String,
      default: ''
    },
    defaultPlaceholder: {
      type: String,
      default: 'Type to search for a paired device'
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      selectedDeviceCode: this.value,
      data: [],
      instanceName: 'square-device-code-component-' + this.$uuid.v4(),
      storeDealer: this.$store.state.dealer.currentDealer,
      isLoading: false
    }
  },
  computed: {
    ...mapUserGetters(['allUsers', 'currentUser']),
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    availableDeviceCodes: function () {
      return this.data
    },
    dealerId: function () {
      return this.storeDealer ? this.storeDealer.id : 0
    },
    availableDeviceCodesCount: function () {
      return this.availableDeviceCodes.length
    }
  },
  methods: {
    fetchDeviceCodes: function () {
      let that = this
      that.isLoading = true
      squareService.findPairedDeviceCodesByDealerId(that.dealerId).then(response => {
        that.isLoading = false
        let serviceDevices = response.filter(x => x.name.toLowerCase().includes('service'))
        that.data = serviceDevices
        this.$emit('codes-loaded', this.availableDeviceCodesCount)
        if (this.data.length > 0) {
          that.fetchDeviceCodesForThisUser()
        }
      }).catch((error) => {
        that.isLoading = false
        that.serverErrorMessage = error.message
      })
    },
    fetchDeviceCodesForThisUser: function () {
      let that = this
      that.isLoading = true
      squareService.findPairedDeviceCodesByDealerIdAndUser(that.dealerId).then(response => {
        that.isLoading = false
        that.setDefault(response)
      }).catch((error) => {
        that.isLoading = false
        that.serverErrorMessage = error.message
      })
    },
    setDefault: function (userCodes) {
      if (userCodes.length > 0 && !this.selectedDeviceCode) {
        this.selectedDeviceCode = userCodes[0]
      }
    }
  },
  watch: {
    selectedDeviceCode: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    value: function (newValue, oldValue) {
      this.selectedDeviceCode = newValue
    }
  },
  mounted: function () {
    this.fetchDeviceCodes()
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    }
  }
}

</script>

import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import ProductCostCapture from '@/components/generic/sales/ProductCostCapture'
import { createNamespacedHelpers } from 'vuex'
import VueGoogleAutocomplete from 'vue-google-autocomplete'

const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

var mixin = {
  data () {
    return {
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  components: {
    'vue-google-autocomplete': VueGoogleAutocomplete,
    'error-display-component': ErrorDisplayComponent,
    'product-cost-capture': ProductCostCapture
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['currentUser']),
    ...mapDealerState(['currentDealer']),
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    productsTotal: function () {
      if (!this.products) {
        return 0
      }
      return this.products.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.sellingPrice)
      }, 0)
    },
    availableProducts: function () {
      return this.products.filter(x => !x.delete)
    }
  },
  methods: {
    onNewContactBuyer: function (newBuyerContact) {
      var found = false
      for (var i = 0; i < this.contacts.length; i++) {
        var contact = this.contacts[i]
        if (contact.id !== newBuyerContact.id) {
          if (contact.selectedReceivesBuyersOrder !== null && contact.selectedReceivesBuyersOrder.name === 'Yes') {
            found = true
          }
          contact.selectedReceivesBuyersOrder = { id: 1, name: 'No', ico: 'fas fa-times has-text-danger' }
          contact.key = this.$uuid.v4()
        }
      }

      if (found) {
        this.showToast(newBuyerContact.firstName + ' ' + newBuyerContact.lastName + ' has been set as the contact to receive paperwork to buy a car from us')
      }
    },
    onNewContactSeller: function (newSellerContact) {
      var found = false
      for (var i = 0; i < this.contacts.length; i++) {
        var contact = this.contacts[i]
        if (contact.id !== newSellerContact.id) {
          if (contact.selectedReceivesSellersOrder !== null && contact.selectedReceivesSellersOrder.name === 'Yes') {
            found = true
          }
          contact.selectedReceivesSellersOrder = { id: 1, name: 'No', ico: 'fas fa-times has-text-danger' }
          contact.key = this.$uuid.v4()
        }
      }

      if (found) {
        this.showToast(newSellerContact.firstName + ' ' + newSellerContact.lastName + ' has been set as the contact to receive paperwork to sell a car to us')
      }
    },
    saveContact: function (supplierModel) {
      this.contacts.forEach(function (item) {
        var contact = {
          id: isNaN(item.id) ? 0 : item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          mobile: item.mobile,
          receivesBuyersOrder: item.selectedReceivesBuyersOrder.id === 0,
          receivesSellersOrder: item.selectedReceivesSellersOrder.id === 0,
          receivesPaymentAndTitle: item.selectedReceivesPaymentAndTitle.id === 0,
          isPrimary: item.selectedIsPrimary.id === 0,
          status: item.selectedActiveStatus.id === 0 ? 'Active' : 'InActive'
        }

        supplierModel.contacts.push(contact)
      }, this)
    },
    addContact: function (isFirstContact) {
      var config = {
        sectionId: this.contacts.length + 100
      }

      var contact = this.createGenericContact(isFirstContact)
      contact.config = config
      this.contacts.push(contact)
    },
    removeContact (contactToRemove) {
      let indexToDelete = this.contacts.findIndex((x) => x.id === contactToRemove.id)
      this.contacts.splice(indexToDelete, 1)
    },
    createGenericContact (isFirstContact) {
      return {
        id: this.$uuid.v4(),
        key: this.$uuid.v4(),
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        receivesBuyersOrder: 0,
        receivesSellersOrder: 0,
        receivesPaymentAndTitle: 0,
        isPrimary: 0,
        selectedReceivesBuyersOrder: this.definedTypes.fillTypes.options.find((x) => x.id === (isFirstContact ? 0 : 1)),
        selectedReceivesSellersOrder: this.definedTypes.fillTypes.options.find((x) => x.id === (isFirstContact ? 0 : 1)),
        selectedReceivesPaymentAndTitle: this.definedTypes.fillTypes.options.find((x) => x.id === (isFirstContact ? 0 : 1)),
        selectedIsPrimary: this.definedTypes.fillTypes.options.find((x) => x.id === (isFirstContact ? 0 : 1)),
        selectedActiveStatus: this.definedTypes.fillTypes.options.find((x) => x.id === 0),
        completeFirstContact: isFirstContact && this.fulfillForDepositCaptureMode
      }
    },
    showToast (message) {
      this.$buefy.toast.open(
        {
          duration: 2500,
          message: message,
          position: 'is-top',
          type: 'is-info'
        })
    },
    onAutoSelectPrimaryContact (updatedContact) {
      // check if another contact is selected as primary, if true then dont auto select
      let primary = this.contacts.find((x) => x.selectedIsPrimary.name === 'Yes' && x.selectedActiveStatus.name === 'Yes' && x.id !== updatedContact.id)
      if (!primary) {
        let newPrimary = this.contacts.find((x) => x.selectedIsPrimary.name === 'No' && x.selectedActiveStatus.name === 'Yes' && x.id !== updatedContact.id)
        if (newPrimary) {
          newPrimary.selectedIsPrimary = this.definedTypes.fillTypes.options.find(x => x.id === this.definedTypes.fillTypes.Yes)
          newPrimary.key = this.$uuid.v4()
          this.showToast(newPrimary.firstName + ' ' + newPrimary.lastName + ' has been set as the primary contact')
        }
      }
    },
    onValidationFailure: function () {
      this.serverErrors = [{
        errorMessage: 'One or more required fields requires your attention. Please check all tabs for invalid fields.'
      }]
    },
    populateProducts: function () {
      this.products = []
      if (this.currentSupplier && this.currentSupplier.products.length > 0) {
        this.currentSupplier.products.forEach(function (product) {
          this.products.push(product)
        }, this)
      }
    },
    addProduct: function () {
      this.newProduct = this.createGenericProduct()
      this.newProduct.isAddingCost = true
    },
    editProduct (product, index) {
      this.newProduct = this.createGenericProduct()
      this.newProduct = {
        index: index,
        id: product.id,
        name: product.name,
        dealerId: this.dealerId,
        description: product.description,
        cost: product.cost,
        sellingPrice: product.sellingPrice,
        dealItemId: product.dealItemId,
        isAddingCost: true,
        type: product.type
      }
    },
    createGenericProduct () {
      return {
        id: 0,
        name: null,
        dealerId: this.dealerId,
        description: '',
        cost: null,
        sellingPrice: null,
        isAddingCost: false
      }
    },
    removeProduct (index) {
      var item = this.currentSupplier.products[index]
      item.delete = true
    },
    saveProduct: function (supplierModel) {
      supplierModel.products = []
      this.products.forEach(function (item) {
        var product = {
          id: item.id,
          name: item.name,
          dealerId: this.dealerId,
          description: item.description,
          cost: item.cost,
          sellingPrice: item.sellingPrice,
          supplierId: item.supplierId,
          isAddingCost: true,
          delete: item.delete,
          type: item.type
        }
        supplierModel.products.push(product)
      }, this)
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  watch: {
    name: function (newValue, oldValue) {
      let titleCaseVersion = this.$toTitleCase(newValue)
      if (titleCaseVersion !== newValue) {
        this.name = titleCaseVersion
      }
    },
    firstName: function (newValue, oldValue) {
      let titleCaseVersion = this.$toTitleCase(newValue)
      if (titleCaseVersion !== newValue) {
        this.firstName = titleCaseVersion
      }
    },
    lastName: function (newValue, oldValue) {
      let titleCaseVersion = this.$toTitleCase(newValue)
      if (titleCaseVersion !== newValue) {
        this.lastName = titleCaseVersion
      }
    },
    'currentSupplier.name': function (newValue, oldValue) {
      let titleCaseVersion = this.$toTitleCase(newValue)
      if (titleCaseVersion !== newValue) {
        this.currentSupplier.name = titleCaseVersion
      }
    },
    'currentSupplier.firstName': function (newValue, oldValue) {
      let titleCaseVersion = this.$toTitleCase(newValue)
      if (titleCaseVersion !== newValue) {
        this.currentSupplier.firstName = titleCaseVersion
      }
    },
    'currentSupplier.lastName': function (newValue, oldValue) {
      let titleCaseVersion = this.$toTitleCase(newValue)
      if (titleCaseVersion !== newValue) {
        this.currentSupplier.lastName = titleCaseVersion
      }
    }
  }
}

export default mixin

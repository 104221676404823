export default {
  all: [
    {
      id: 1,
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      subscriberId: 1,
      dealerId: 1,
      vinNo: '',
      name: '',
      description: 'Test Service Video',
      fileName: '',
      status: 'Active',
      createdBy: 1,
      createdByCompact: 'System Admin',
      uniqueId: '',
      fileLocation: '',
      linkedId: '',
      attachments: [
        {
          id: 1,
          createdAt: '01/01/2000',
          modifiedAt: '01/01/2000',
          subscriberId: 1,
          dealerId: 1,
          name: '',
          description: 'Test Service Video Attachment',
          fileName: '',
          type: 'Video',
          classType: 'Primary',
          status: 'Active',
          uniqueId: '',
          fileLocation: '',
          linkedId: '',
          createdBy: 1
        }
      ]
    }
  ]
}

<template>
  <section class="history-container">
    <div :id="'history-container-' + instanceId" @click="onDispositionHistoryClick" v-if="hasHistoricalDispositionIntention">
      <i class="icon fal fa-lg fa-history clickable" :class="{'is-loading': isLoading}" title="Click to the disposition intention history for this inventory item"/>
    </div>

    <portal to="global-modal-portal" :disabled="!usePortal" v-if="showDispositionHistoryActive">
      <b-modal :active.sync="showDispositionHistoryActive" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Historical Disposition Intention</p>
          </header>
          <section class="modal-card-body">
            <table class="table history-table is-bordered is-narrow is-hoverable">
              <thead>
                <tr>
                  <td>#</td>
                  <td>Intention</td>
                  <td>Period</td>
                  <td>Commitement From</td>
                  <td>At Price</td>
                  <td>Created At</td>
                  <td>Created By</td>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(historyEntry, index) in allRecordedDispositionHistory" :key="historyEntry.id">
                  <td>{{index}}.</td>
                  <td>{{historyEntry.dispositionIntention}}</td>
                  <td>{{historyEntry.dispositionValidDays}}</td>
                  <td>{{historyEntry.commitmentFromName}}</td>
                  <td>{{historyEntry.commitmentValue | currency}}</td>
                  <td>{{historyEntry.createdAt | formatDate}}</td>
                  <td>{{historyEntry.createdByFullName}}</td>
                </tr>
              </tbody>
            </table>
          </section>
          <footer class="modal-card-foot">
              <button type="button" class="button is-success" @click="onCloseDispositionHistory()">Close</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </section>
</template>

<script>

import inventoryService from '@/services/inventoryService'

export default {
  name: 'InventoryDispositionIntentionHistoryComponent',
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      showDispositionHistoryActive: false,
      allRecordedDispositionHistory: [],
      isLoading: false,
      serverErrorMessage: ''
    }
  },
  methods: {
    onCloseDispositionHistory: function () {
      this.showDispositionHistoryActive = false
      this.allRecordedDispositionHistory = []
    },
    onDispositionHistoryClick: function () {
      this.isLoading = true
      inventoryService.getDispositionHistory(this.currentInventory).then(response => {
        this.showDispositionHistoryActive = true
        this.allRecordedDispositionHistory = response || []
        this.isLoading = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
        this.allRecordedDispositionHistory = []
      })
    }
  },
  computed: {
    hasHistoricalDispositionIntention: function () {
      return this.currentInventory && this.currentInventory.originalDispositionIntention
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal.is-active .modal-card-foot {
    padding: 10px !important
  }

  .modal-card-head, .modal-card-body{
    padding: 10px !important
  }

  .history-container {
    display:inline-flex;
    vertical-align: middle;
  }

  .table {
    background-color: #f8fafc !important;
    color: #6c6c7a;
    font-size: 0.7rem;
    width: 100%;
  }

  .history-table {
    background-color: #f8fafc !important;
    color: #6c6c7a;
    font-size: 0.75rem;
    width: 100%;
  }

</style>

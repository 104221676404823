import requiresAuth from './route_guards'

export default [
  {
    path: '/signature',
    name: 'Signature',
    component: () => import('@/components/generic/Signature'),
    props: true,
    beforeEnter: requiresAuth
  }
]

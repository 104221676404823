<template>
  <div class="pod tile is-parent is-narrow">
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold clickable" @click="onTitleClick">{{title}}
        <span v-if="showCount"> ({{total}})</span>
        <i title="Click for more options" @click.stop="onMoreClick" class="fas fa-ellipsis-v icon-more"></i>
        <i title="Click to refresh this pod" class="is-inline-block fas fa-sync" :class="{'fa-spin': isLoading}" @click.stop="onRefresh"></i>
        <i title="Click to expand all" v-if="isAllCollapsed" class="fas fa-angle-double-down clickable" @click.stop="onToggleCollapseAll"></i>
        <i title="Click to collapse all" v-if="!isAllCollapsed" class="fas fa-angle-double-up clickable" @click.stop="onToggleCollapseAll"></i>
      </h4>
      <div class="pod-content scrollbar">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th class="is-uppercase no-wrap-table-cell" :class="{'is-current-sort': sortField === column.id}" v-for="column in value.columns.filter((x) => x.visible)" :key="column.name" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                {{column.name}}
                <span class="icon sort-icon"><i class="mdi" :class="{'mdi-arrow-up': sortDirection === 'asc', 'mdi-arrow-down': sortDirection === 'desc'}" v-if="sortField === column.id"></i></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template v-if="value.data && value.data.length > 0 && value.data[0].group && value.data[0].group !== ''">
              <template v-for="groupItem in value.data">
                <tr :key="groupItem.group" :class="groupRowClass" @click="onToggleGroup(groupItem)">
                  <slot name="group-header" :data="{groupItem: groupItem, totalColumns: value.columns.filter((x) => x.visible).length - 1}">
                    <td :colspan="value.columns.filter((x) => x.visible).length - 1" class="subtitle is-7 is-uppercase is-italic has-text-weight-bold has-text-primary">
                      <div class="is-inline-block" style="margin-right:15px;">{{ groupItem.count }}</div>
                      <div class="is-inline-block">{{ groupItem.group }}</div>
                    </td>
                  </slot>
                  <td>
                    <div class="is-pulled-right">
                      <i title="Click to expand group" v-if="groupState[groupItem.group]" class="fal fa-angle-double-down fa-lg clickable"></i>
                      <i title="Click to collapse group" v-if="!groupState[groupItem.group]" class="fal fa-angle-double-up fa-lg clickable"></i>
                    </div>
                  </td>
                </tr>
                <template v-if="groupState[groupItem.group] === false">
                  <tr v-for="(item, index) in groupItem.data" class="clickable" :key="item.id" @click="onItemClick(item)">
                    <td v-for="column in value.columns.filter((x) => x.visible)" :key="column.name + index.toString()" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                      <template v-if="column.format && column.format === 'currency'">{{item[column.id] | currency('$', 0)}}</template>
                      <template v-else-if="column.format && column.format === 'datetime'">{{item[column.id] | formatDateOnly}}</template>
                      <template v-else>{{item[column.id]}}</template>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr :key="groupItem.group + '+' + groupItem.count">
                    <slot name="group-rows-collapsed" :data="{groupItem: groupItem, totalColumns: value.columns.filter((x) => x.visible).length}">
                      <td :colspan="value.columns.filter((x) => x.visible).length">
                        <div class="has-text-danger" style="margin-left:25px;">{{groupItem.count}} rows collapsed</div>
                      </td>
                    </slot>
                  </tr>
                </template>
              </template>
            </template>
            <template v-else>
              <tr class="clickable" v-for="(item, index) in value.data" :key="item.id" @click="onItemClick(item)">
                <td v-for="column in value.columns.filter((x) => x.visible)" :key="column.name + index.toString()" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                  <template v-if="column.format && column.format === 'currency'">{{item[column.id] | currency('$', 0)}}</template>
                  <template v-else-if="column.format && column.format === 'datetime'">{{item[column.id] | formatDateOnly}}</template>
                  <template v-else>{{item[column.id]}}</template>
                </td>
              </tr>
            </template>
            <tr v-if="this.value.data.length === 0" class="subtitle is-7">
              <td style="text-align:center; height:100px;vertical-align:middle;" :colspan="value.columns.length">No data available</td>
            </tr>
          </tbody>
          <tfoot v-if="value.data && value.data.length > 0">
            <tr :class="groupRowClass">
              <slot name="group-footer" :data="{totalColumns: value.columns.filter((x) => x.visible).length, groups: value.data }">
                <td :colspan="value.columns.filter((x) => x.visible).length" class="subtitle is-7 is-uppercase is-italic has-text-weight-bold has-text-black">
                  <div class="is-inline-block" style="margin-right:15px;">{{ total }}</div>
                  <div class="is-inline-block">Summary</div>
                </td>
              </slot>
            </tr>
          </tfoot>
        </table>
      </div>
    </article>
  </div>
</template>

<script>

export default {
  name: 'GridPod',
  components: {
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Grid Pod'
    },
    sortField: {
      type: String,
      default: ''
    },
    sortDirection: {
      type: String,
      default: 'asc'
    },
    groupRowClass: {
      type: String,
      default: ''
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showCount: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      id: this.$uuid.v4(),
      active: true,
      isAllCollapsed: true,
      groupState: {}
    }
  },
  computed: {
    total: function () {
      if (this.value && this.value.data && this.value.data.length > 0) {
        if (this.value.data[0].group && this.value.data[0].group !== '') {
          return this.value.data.reduce(function (acc, val) { return acc + val.data.length }, 0)
        } else {
          return this.value.data.length
        }
      }
      return 0
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      if (newValue && newValue.data && newValue.data.length > 0) {
        if (newValue.data[0].group && newValue.data[0].group !== '') {
          newValue.data.forEach(x => {
            this.$set(this.groupState, x.group, true)
          })
        } else {
          this.groupState = {}
        }
      } else {
        this.groupState = {}
      }
    }
  },
  methods: {
    onToggleGroup: function (groupItem) {
      this.$set(this.groupState, groupItem.group, !this.groupState[groupItem.group])
    },
    onMoreClick: function () {
    },
    onToggleCollapseAll: function () {
      this.isAllCollapsed = !this.isAllCollapsed
      if (this.value && this.value.data && this.value.data.length > 0) {
        if (this.value.data[0].group && this.value.data[0].group !== '') {
          this.value.data.forEach(x => {
            this.$set(this.groupState, x.group, this.isAllCollapsed)
          })
        } else {
          this.groupState = {}
        }
      } else {
        this.groupState = {}
      }
    },
    onRefresh: function () {
      this.$emit('on-refresh')
    },
    onItemClick: function (item) {
      this.$emit('on-item-click', item)
    },
    onTitleClick: function () {
      this.$emit('on-header-click')
    }
  }
}
</script>

<style scoped>
  .no-wrap-table-cell {
    white-space: nowrap;
  }

  .sort-icon {
    height:auto !important;
  }

  .is-current-sort {
    color: rgb(0, 177, 31) !important;
  }

  .is-group-header {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .is-group-footer {
    display: flex;
    align-items: center;
    justify-content: start;
  }

  .group-header-container {
    background-color: unset;
  }

  .group-footer-container {
    background-color: unset;
  }
</style>

function closeQuickView (item) {
  var quickView = document.getElementById('qviewApp')
  if (quickView) {
    quickView.classList.remove('is-active')
  }
}
function isInTabletMode () {
  let name = 'tabletmode='
  let tabletMode = ''
  var decodedCookie = decodeURIComponent(document.cookie)
  var ca = decodedCookie.split(';')
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i]
    while (c.charAt(0) === ' ') {
      c = c.substring(1)
    }
    if (c.indexOf(name) === 0) {
      tabletMode = c.substring(name.length, c.length)
    }
  }
  if (tabletMode && tabletMode.trim() === '1') {
    return true
  }
  return false
}

function fileIsImage (fileName) {
  if (!fileName.match(/.(jpg|jpeg|png)$/i)) {
    return false
  } else {
    return true
  }
}

export default {
  closeQuickView,
  isInTabletMode,
  fileIsImage
}

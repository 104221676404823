<template>
  <span :style="{'display': displayBlock ? 'block' : 'inline'}">
    <label
      :class="getClass"
      @click="onFill"
      :title="title">
      <div v-if="applyCurrencyFormat">{{fillableValue | currency}}</div>
      <div v-else>{{fillableValue}}</div>
    </label>
  </span>
</template>

<script>
export default {
  name: 'PopFillControl',
  props: {
    fillableValue: {
      type: [String, Number],
      default: undefined
    },
    title: {
      type: String,
      default: ''
    },
    displayBlock: {
      type: Boolean,
      default: false
    },
    onFillAction: {
      type: Function,
      default: undefined
    },
    customClass: {
      type: String,
      default: 'popfill-link'
    },
    value: {
      type: [String, Number],
      default: undefined
    },
    doComparison: {
      type: Boolean,
      default: false
    },
    applyCurrencyFormat: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    isValuesDifferent: function () {
      return this.value !== this.fillableValue
    },
    getColourClass: function () {
      if (!this.doComparison) return 'has-text-success'
      return this.isValuesDifferent ? 'has-text-danger' : 'has-text-success'
    },
    getClass: function () {
      let classToAttach = {}
      classToAttach[this.getColourClass] = true
      classToAttach[this.customClass] = true
      return classToAttach
    }
  },
  methods: {
    onFill () {
      if (this.onFillAction) this.onFillAction(this.fillableValue)
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
  .popfill-link {
    font-size: 0.75rem;
    cursor: pointer;
    margin-top: 3px;
    float: right;
    font-weight: 600;
  }

  .popfill-link-date {
    font-size: 0.75rem;
    cursor: pointer;
    margin-top: 3px;
    font-weight: 600;
  }

</style>

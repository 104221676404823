<template>
<div class="box payoff-container">
  <payoff-section-header title="Payoff Info" :collapsed="isPayoffCollapsed" v-on:collapsed-status-changed="onPayoffCollapsedStatusChanged" :isLoading="isLoading"></payoff-section-header>
  <div class="columns is-mobile is-multiline is-centered" v-if="inventory" style="margin-top: 5px;margin-bottom: 20px !important;padding: 0">
    <div class="column is-12">
      <div>
        <label class="is-inline-block is-size-7 has-text-success refresh-status-label" v-if="isLoading"> Fetching latest available payoff info...</label>
      </div>
    </div>
    <template v-if="displayMode === 'Standard'">
      <div class="column is-2">
        <div class="field">
          <label class="label">Lienholder Bank</label>
          <div class="field">
            {{ inventory.bankName }}
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <label class="label">Account No.</label>
          <div class="field">
            {{ inventory.accNo }}
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <label class="label">Payoff Amount</label>
          <div class="field">
            {{ inventory.payoffAmount | currency }}
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <label class="label">Monthly Payment Due</label>
          <div class="field">
            {{ inventory.monthlyPaymentDue | currency }}
          </div>
        </div>
      </div>
      <div class="column is-2">
        <div class="field">
          <label class="label">Good Until Date</label>
          <div class="field">
            {{ inventory.goodUntilDate | formatDateOnly }}
          </div>
        </div>
      </div>
      <div class="column is-2">
      </div>
    </template>
    <template v-if="displayMode === 'Compact'">
      <div class="column is-4">
        <div class="field">
          <label class="label">Lienholder Bank</label>
          <div class="field">
            {{ inventory.bankName }}
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Account No.</label>
          <div class="field">
            {{ inventory.accNo }}
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Payoff Amount</label>
          <div class="field">
            {{ inventory.payoffAmount | currency }}
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Monthly Payment Due</label>
          <div class="field">
            {{ inventory.monthlyPaymentDue | currency }}
          </div>
        </div>
      </div>
      <div class="column is-4">
        <div class="field">
          <label class="label">Good Until Date</label>
          <div class="field">
            {{ inventory.goodUntilDate | formatDateOnly }}
          </div>
        </div>
      </div>
      <div class="column is-4">
      </div>
    </template>
  </div>
</div>
</template>

<script>

import PayoffSectionHeader from '@/components/generic/inventory/InventoryPayoffSectionHeader'

export default {
  name: 'InventoryPayoffControl',
  components: {
    'payoff-section-header': PayoffSectionHeader
  },
  props: {
    inventory: {
      type: Object,
      default: null
    },
    displayMode: {
      type: String,
      default: 'Standard'
    },
    inventoryType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      serverErrors: [],
      serverErrorMessage: '',
      isLoading: false,
      queryTimer: undefined,
      isPayoffCollapsed: false
    }
  },
  computed: {
  },
  methods: {
    onPayoffCollapsedStatusChanged: function (newValue) {
      this.isPayoffCollapsed = newValue
    }
  },
  watch: {
  },
  mounted: function () {
    this.isPayoffCollapsed = this.inventoryType === 'New'
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .refresh-status-label {
    margin-left:1em;
  }
</style>

<template>
  <span v-if="hasConvertPpaAccess && canShowConvertAction">
    <div class="quick-action" v-if="!isConvertPpaConfirmationActive && !showStatusIcon">
        <button class="button" @click.exact.stop.prevent="onRequestConvertPpa()" title="Click to convert PPA">
          <b-icon pack="fal" icon="exchange" custom-size="fa-lg"></b-icon>
        </button>
    </div>
    <span v-if="!isConvertPpaConfirmationActive && showStatusIcon" @click.exact.stop.prevent="onRequestConvertPpa()" @click.ctrl.exact.stop.prevent="onShowTrade()">
      <i class="grid-icon fa-fw fal fa-exchange fa-lg clickable" title="Click to convert PPA"/>
    </span>

    <portal to="global-modal-portal" v-if="isConvertPpaConfirmationActive" :disabled="!usePortal">
      <b-modal :active.sync="isConvertPpaConfirmationActive" :width="800" scroll="keep" :has-modal-card="true" :canCancel="false" :key="'cptp-3' + inventoryItem.id">
        <div class="modal-card" style="max-width: 640px !important; z-index: 10000;">
          <header class="modal-card-head">
              <p class="modal-card-title" v-if="convertedInventory === null">Confirm Convert Pre-Purchase Appraisal</p>
              <p class="modal-card-title" v-else>Pre-Purchase Appraisal Converted Successfully</p>
          </header>
          <section class="modal-card-body view-container modal-section is-mobile has-background-grey-lighter">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <div class="notification is-danger is-flex" v-if="convertedInventory === null && (isOriginalNotExamined || isOriginalPurchaseCostExpired)">
              <i class="fas fa-exclamation-triangle is-danger fa-2x"></i>
              <div class="ml-10">
                <div class="block" v-if="isOriginalNotExamined">This PPA has not been examined!</div>
                <div class="block" v-if="isOriginalPurchaseCostExpired">The proposed purchase cost has passed it's expiration date and sale's manager must re-confirm price and set new expiration date before conversion can proceed.</div>
                <div class="block" v-if="isOriginalInspectionDependent">PPA Vehicle needs to be visually inspected by the sale's manager!</div>
                <div class="columns ml-5 box is-multiline" v-if="((isOriginalPurchaseCostExpired || isOriginalNotExamined) && isAdmin)">
                  <div class="column is-6" v-if="isOriginalPurchaseCostExpired">
                    <label class="label">{{ proposedCostLabel }}</label>
                    <div class="control has-icons-left is-width-200">
                      <money name="proposedCost" data-vv-as="proposed cost" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('proposedCost') }" type="text" placeholder="0.00" v-model="inventoryItem.proposedCost" v-bind="$globalMoneyFormat"></money>
                      <span v-show="errors.has('proposedCost')" class="help is-danger"><i v-show="errors.has('proposedCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('proposedCost') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                  <div class="column is-6" v-if="isOriginalPurchaseCostExpired">
                    <b-field :label="proposedCostExpirationLabel">
                      <el-date-picker
                        @change="onFormChange()"
                        v-model="inventoryItem.proposedCostExpirationDate"
                        type="datetime"
                        placeholder="Click to select..."
                        popper-class="datepicker-container"
                        :picker-options="expirationDatePickerOptions">
                      </el-date-picker>
                    </b-field>
                  </div>
                  <div class="columns is-6" v-if="isOriginalNotExamined">
                    <div class="column">
                      <div class="field">
                        <switch-selector type="is-info" label="Has vehicle been visually inspected?" v-model="ppaExamined"></switch-selector>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="notification is-success is-flex" v-if="!isPurchaseCostExpirationDatePassed && convertedInventory !== null">
              <i class="fas fa-check-circle is-success fa-2x"></i>
              <div class="ml-10">
                <p>Pre-Purchase appraisal <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a> has been succesfully converted.</p><br/>
                <p>A new inventory item has been generated with stock number <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ convertedInventory.stockNo }}</span></a>.</p><br/>
                <p>Additional updates are required. Please click the view button below to complete the conversion process.</p>
              </div>
            </div>
            <form @submit.prevent="onSave()" v-if="convertedInventory === null">
              <div class="columns is-multiline">
                <div class="column is-12" v-if="isDifferenceInCosts">
                  <div class="columns">
                    <div class="column is-12">
                      <div class="box has-background-warning">
                        <h4 class="title is-size-7 has-text-danger">
                        The approved proposed cost is not the same as the purchase cost
                        </h4>
                        <h4 class="title is-size-7">Please Note: By activating the switch below you acknowledge the above vehicle's current status and accept responsibility for overriding the approved purchase cost</h4>
                        <div class="columns">
                          <b-switch class="column is-narrow is-vcentered" type="is-info" v-model="purchaseCostAcceptConditions"></b-switch>
                          <h4 class="title is-size-7 column is-narrow is-vcentered" v-if="purchaseCostAcceptConditions">I ACCEPT the above statement</h4>
                          <h4 class="title is-size-7 column is-narrow is-vcentered" v-if="!purchaseCostAcceptConditions">I DO NOT ACCEPT the above statement</h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="columns">
                    <div class="column">
                      <button-list-selector validateAs="inventory type" label="Type" :required="true" v-model="selectedTypeStatus" :availableValues="allowedInventoryTypes" :allowDeselection="false"></button-list-selector>
                    </div>
                    <div class="column">
                      <div class="field">
                        <label class="label">{{getTypeLabel}}</label>
                        <div class="control has-icons-left">
                          <money name="purchaseCost" data-vv-as="purchase cost" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('purchaseCost') }" type="text" placeholder="0.00" v-model="inventoryItem.purchaseCost" v-bind="$globalMoneyFormat"></money>
                          <span v-show="errors.has('purchaseCost')" class="help is-danger"><i v-show="errors.has('purchaseCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('purchaseCost') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="column is-12 is-mobile">
                  <div class="columns">
                    <div class="column is-narrow">
                      <div class="field">
                        <label class="label">Mileage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control is-vertical-center">
                          <input name="usage" data-vv-as="miles" class="mileage-input" v-validate="{'numeric': true, 'required': true}" :class="{'input': true, 'is-width-100': true, 'is-danger': errors.has('usage') }" type="number" placeholder="#####" v-model="inventoryItem.usage" :min="mileageEstimate ? 0 : minimumUsageAllowed">
                        </div>
                        <span v-show="errors.has('usage')" class="help is-danger"><i v-show="errors.has('usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('usage') }}</span>
                      </div>
                    </div>
                    <div class="column is-narrow">
                      <div class="field">
                        <label class="label mt-5">Mileage Estimated</label>
                        <b-checkbox name="mileageEstimate" class="mileage-checkbox" size="is-medium" type="is-info" v-model="mileageEstimate"></b-checkbox>
                      </div>
                    </div>
                    <div class="column is-narrow" v-if="mileageEstimate">
                      <label class="label">True Mileage Unknown</label>
                      <b-checkbox name="trueMileageUnknown" class="mileage-checkbox" size="is-medium" type="is-info" v-model="trueMileageUnknown"></b-checkbox>
                    </div>
                    <div class="column is-narrow" v-if="mileageEstimate">
                      <label class="label">Mileage Exempt</label>
                      <b-checkbox name="mileageExempt" class="mileage-checkbox" size="is-medium" type="is-info" v-model="mileageExempt"></b-checkbox>
                    </div>
                  </div>
                  <div class="box">
                    <section-header title="Pricing Info"></section-header>
                    <div class="columns">
                      <div class="column">
                        <label class="label">{{ proposedCostLabel }}</label>
                        <div class="field">{{ inventoryItem.proposedCost | currency }}</div>
                      </div>
                      <div class="column">
                        <label class="label">{{ proposedCostExpirationLabel }}</label>
                        <div class="field">
                          {{ inventoryItem.proposedCostExpirationDate | formatDateOnly }}
                        </div>
                      </div>
                    </div>
                    <div class="columns" v-if="isTrade">
                      <div class="column">
                        <label class="label">Proposed Trade Allowance</label>
                        <div class="field">
                          {{ inventoryItem.proposedTradeAllowance | currency }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box">
                    <section-header title="Vehicle Info"></section-header>
                    <div class="columns">
                      <div class="column">
                        <label class="label">Vehicle</label>
                        <div class="field">
                          {{ inventoryDetails }}
                        </div>
                      </div>
                      <div class="column">
                        <label class="label">Vin No</label>
                        <div class="field">
                          {{inventoryItem.vinNo}}
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <label class="label">Mileage</label>
                        <div class="field">
                          {{ inventoryItem.usage || 'Unspecified' }}
                        </div>
                      </div>
                      <div class="column">
                        <label class="label is-inline-block is-size-7">Damage</label>
                        <div class="field">
                          <i class="icon fal fa-lg status-icon" :class="getIconByLastDamageStatus(inventoryItem)" :title="'Damage History: ' + getLastDamageDescription(inventoryItem)"/>
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <label class="label">Exterior Color</label>
                        <div class="field">
                          {{ exteriorColor || 'Unspecified' }}
                        </div>
                      </div>
                      <div class="column">
                        <label class="label">Interior Color</label>
                        <div class="field">
                          {{ interiorColor || 'Unspecified' }}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="box" v-if="purchaser">
                      <section-header title="Purchaser Info"></section-header>
                      <div class="columns">
                        <div class="column">
                          <label class="label">
                            Purchased From
                            <dealer-peak-control v-if="isPurchasedFromIndividual" :key="purchaser.id" :customerId="purchaser.id"></dealer-peak-control>
                          </label>
                          <div class="field">
                            {{ isPurchasedFromIndividual ? purchaser.fullName : purchaser.name}}
                          </div>
                        </div>
                        <div class="column">
                          <label class="label">Contact No.</label>
                          <div class="field">
                            <span class="has-text-link clickable" v-tooltip="purchaser.mobile + ' - Click to copy contact mobile to your clipboard'" @click.stop.prevent="onCopyInfo(purchaser.mobile)">{{ purchaser.mobile }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column">
                          <label class="label">Contact Email</label>
                          <div class="field">
                            <span class="has-text-link clickable" v-tooltip="purchaser.email + ' - Click to copy contact email to your clipboard'" @click.stop.prevent="onCopyInfo(purchaser.email)">{{ purchaser.email }}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  <div class="box">
                    <section-header title="Approval Info"></section-header>
                    <div class="columns">
                      <div class="column">
                        <label class="label">Approved By</label>
                        <div class="field">
                          {{ inventoryItem.ppaApprovedByName }}
                        </div>
                      </div>
                      <div class="column">
                        <label class="label">Approved At</label>
                        <div class="field">
                          {{ inventoryItem.ppaApprovedAt | formatDateOnly }}
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column">
                        <label class="label">Days Since Approval</label>
                        <div class="field">
                          {{ inventoryItem.daysSincePpaApprovalDisplay }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-success" :class="{'is-loading': isSaving }" @click="onSave()" v-if="convertedInventory === null" :disabled="isConfirmBtnDisabled">Confirm Conversion</button>
            <button class="button is-success" @click="onShowConvertedInventory()" v-if="convertedInventory !== null">Complete New Stock-In</button>
            <button class="button is-danger" @click="onCancel" v-if="convertedInventory === null">Close</button>
          </footer>
        </div>
      </b-modal>
    </portal>

    <portal to="global-modal-portal" v-if="editInventoryActive" :disabled="!usePortal">
      <b-modal :active.sync="editInventoryActive" scroll="keep">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
            </header>
            <section class="modal-card-body modal-section">
              <edit-inventory
                :inventoryId="convertedInventory.id"
                mode="modal"
                actionPortal="modal-footer"
                overviewPortal=""
                headerPortal="modal-header"
                :defaultPreEntry="false"
                v-on:on-save="onInventorySaved"
                v-on:on-cancel="onInventoryCancelled"
                :defaultSalesPerson="currentUser"></edit-inventory>
            </section>
            <footer class="modal-card-foot">
              <portal-target name="modal-footer" class="actions" slim/>
            </footer>
          </div>
      </b-modal>
    </portal>

    <portal to="global-modal-portal" v-if="dealActiveSync" :disabled="!usePortal">
      <b-modal :active.sync="dealActiveSync" scroll="keep" :has-modal-card="true" :canCancel="false" :key="'cptp-4' + inventoryItem.id" :full-screen="false">
        <div class="modal-card" style="padding-top:0px !important; z-index: 10000;">
          <header class="modal-card-head">
            <portal-target name="modal-header" slim/>
          </header>
          <section class="modal-card-body view-container modal-section is-mobile" style="padding-top:0px !important; z-index: 10000;">
            <new-deal v-if="createDealActive"
              :isModalMode="true"
              :defaultSalesAgent="dealSalesAgent"
              :defaultSupplier="purchaser"
              :defaultCustomer="purchaser"
              :tradeInventoryItem="inventoryItem"
              :defaultSaleType="getDealSaleTypeFromInventory"
              actionPortal="modal-footer"
              headerPortal="modal-header"
              :usePortal="!usePortalForDeal">
            </new-deal>

            <edit-deal v-if="editDealActive"
              :isModalMode="true"
              :id="selectedDealId"
              :defaultSalesAgent="dealSalesAgent"
              :defaultSupplier="purchaser"
              :defaultCustomer="purchaser"
              :inventoryItem="inventoryItem"
              :tradeInventoryItem="inventoryItem"
              actionPortal="modal-footer"
              headerPortal="modal-header"
              :usePortal="!usePortalForDeal">
            </edit-deal>
          </section>
          <footer class="modal-card-foot">
            <portal-target name="modal-footer" class="actions" slim/>
          </footer>
          <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
        </div>
      </b-modal>
    </portal>

    <portal to="global-modal-portal" v-if="conversionTypeActive" :disabled="!usePortal">
      <b-modal :active.sync="conversionTypeActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
        <div class="modal-card customer-modal-card" style="width: auto">
          <header class="modal-card-head customer-modal-card-head">
            <p class="modal-card-title">Select Conversion Type</p>
          </header>
          <section class="modal-card-body conversion-type-body has-background-grey-lighter">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <div class="content box">
              <ol type="1">
                <li>
                  <button class="button is-info" @click="onOpenConvertPpa">
                    <span class="icon is-small"><i class="fal fa-car"></i></span><span>Purchase/Consigned</span>
                  </button>
                </li>
                <li class="mt-20">
                  <button class="button is-success" @click="openCreateTradeDeal">
                    <span class="icon is-small"><i class="fal fa-handshake" data-type="T"/></span><span>Create Trade Deal</span>
                  </button>
                </li>
                <li  class="mt-20" v-if="hasExistingDeals">
                  Select Existing Deal
                  <br />
                  <table class="table is-bordered is-striped is-fullwidth is-narrow mt-10">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Status</th>
                        <th>Sale Type</th>
                        <th>Stock Type</th>
                        <th>Vehicle Description</th>
                        <th>Created At</th>
                        <th>Created By</th>
                      </tr>
                    </thead>
                    <tr v-for="(deal, index) in deals" :key="deal.id">
                      <td>{{index + 1}}</td>
                      <td>
                        <a class="is-link" @click="onEditDeal(deal.id)">
                          {{deal.code}}
                        </a>
                      </td>
                      <td>{{deal.typeDescription}}</td>
                      <td>{{deal.saleTypeDescription}}</td>
                      <td>{{deal.stockTypeDescription}}</td>
                      <td>{{deal.vehicleDisplayName}}</td>
                      <td>{{deal.createdAt | formatDateOnly}}</td>
                      <td>{{deal.createdBy}}</td>
                    </tr>
                    <tr v-if="deals.length === 0">
                      <td colspan="6">None specified</td>
                    </tr>
                  </table>
                </li>
              </ol>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" @click="onCloseConversionType">Close</button>
          </footer>
        </div>
      </b-modal>
    </portal>
    <b-loading :is-full-page="false" :active.sync="initializing" :canCancel="false"></b-loading>
  </span>
</template>
<script>

import eventBus from '@/eventBus'
import inventoryService from '@/services/inventoryService'
import customerService from '@/services/customerService'
import supplierService from '@/services/supplierService'
import dealService from '@/services/dealService'
import userService from '@/services/userService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import InventorySelector from '@/components/generic/InventorySelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import SwitchSelector from '@/components/generic/SwitchSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import NewDealComponent from '@/components/sales/deals/NewDeal'
import EditDealComponent from '@/components/sales/deals/EditDeal'
import SectionHeader from '@/components/generic/SectionHeader'
import DealerPeakControl from '@/components/generic/DealerPeakControl'

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'ConvertPpaAction',
  mixins: [dealInventoryStyleClasses, utilitiesMixin, validateBeforeSubmitMixin],
  props: {
    inventory: {
      type: Object,
      default: null
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    defaultType: {
      type: Object,
      default: () => ({ id: 1, name: 'Purchased', altName: 'Purchased' })
    },
    dealId: {
      type: Number,
      default: undefined
    },
    openEditInventoryInModal: {
      type: Boolean,
      default: false
    },
    showStatusIcon: {
      type: Boolean,
      default: false
    },
    displayConvertPpa: {
      type: Boolean,
      default: false
    },
    invertSecondUsePortal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isConvertPpaConfirmationActive: false,
      conversionTypeActive: false,
      convertedInventory: null,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4(),
      purchaseCostAcceptConditions: false,
      selectedTypeStatus: null,
      editInventoryActive: false,
      createDealActive: false,
      editDealActive: false,
      inventoryItem: this.inventory,
      ppaExamined: false,
      mileageEstimate: false,
      trueMileageUnknown: false,
      mileageExempt: false,
      purchaser: null,
      newDeal: false,
      selectedDealId: 0,
      deals: [],
      vehicleOfInterests: [],
      dealSalesAgent: null,
      initializing: false,
      isLoading: false,
      expirationDatePickerOptions: {
        disabledDate (date) {
          return date < new Date()
        }
      },
      originalInventory: {...this.inventory}
    }
  },
  components: {
    'error-display-component': ErrorDisplayComponent,
    'button-list-selector': ButtonListSelector,
    'edit-inventory': () => import('@/components/inventory/EditInventory'),
    'switch-selector': SwitchSelector,
    'inventory-selector': InventorySelector,
    'new-deal': NewDealComponent,
    'edit-deal': EditDealComponent,
    'section-header': SectionHeader,
    'dealer-peak-control': DealerPeakControl
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'allowedRoles']),
    isAdmin: function () {
      return this.allowedRoles.indexOf('Admin Role') >= 0 || this.hasFeatureAccess('inventory.modify.ppa.expiration-date', false)
    },
    hasConvertPpaAccess: function () {
      let allowedPermissions = ['inventory.modify.convert-ppa-purchase']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    hasInventoryModifyAccess: function () {
      let allowedPermissions = ['inventory.modify']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isTypePpaAppraisal: function () {
      return this.inventoryItem && this.inventoryItem.type === 'ForPpaAppraisal'
    },
    inventoryItemId: function () {
      return this.inventoryItem ? this.inventoryItem.id : null
    },
    canShowConvertAction () {
      return this.inventoryItem && this.isTypePpaAppraisal && this.inventoryItem.ppaApproved
    },
    isDifferenceInCosts () {
      return this.inventoryItem && this.inventoryItem.proposedCost !== this.inventoryItem.purchaseCost
    },
    isConfirmBtnDisabled () {
      return this.isInspectionDependent || (this.isDifferenceInCosts && !this.purchaseCostAcceptConditions) || this.isPurchaseCostExpirationDatePassed
    },
    isInspectionDependent: function () {
      return this.convertedInventory === null && this.inventoryItem.inspectionDependent === true && this.inventoryItem.ppaExamined === false
    },
    isOriginalInspectionDependent: function () {
      return this.convertedInventory === null && this.originalInventory.inspectionDependent === true && this.originalInventory.ppaExamined === false
    },
    allowedInventoryTypes: function () {
      return this.definedTypes.inventoryTypes.filter(x => x.id === 1 || x.id === 3 || x.id === 4)
    },
    isPurchaseCostExpirationDatePassed: function () {
      let today = new Date()
      if (this.inventoryItem && this.inventoryItem.proposedCostExpirationDate) {
        let proposedCostExpirationDate = new Date(this.inventoryItem.proposedCostExpirationDate)
        if (today > proposedCostExpirationDate) {
          return true
        }
      }
      return false
    },
    isOriginalPurchaseCostExpired: function () {
      let today = new Date()
      if (this.originalInventory && this.originalInventory.proposedCostExpirationDate) {
        let proposedCostExpirationDate = new Date(this.originalInventory.proposedCostExpirationDate)
        if (today > proposedCostExpirationDate) {
          return true
        }
      }
      return false
    },
    isOriginalNotExamined: function () {
      return this.convertedInventory === null && this.originalInventory.ppaExamined === false
    },
    exteriorColor: function () {
      let exterior = (this.inventoryItem && this.inventoryItem.attributes) ? this.inventoryItem.attributes.find((x) => x.name === 'ExteriorColor') : undefined
      return exterior ? exterior.value : ''
    },
    interiorColor: function () {
      let interior = (this.inventoryItem && this.inventoryItem.attributes) ? this.inventoryItem.attributes.find((x) => x.name === 'InteriorColor') : undefined
      return interior ? interior.value : ''
    },
    getTypeLabel: function () {
      if (this.selectedTypeStatus && this.selectedTypeStatus.altName === 'TradeIn') {
        return 'Max ACV'
      }
      return 'Purchased Cost'
    },
    proposedCostLabel: function () {
      return this.selectedTypeStatus && this.selectedTypeStatus.altName === 'TradeIn' ? 'ACV Value' : 'Proposed Purchase Cost'
    },
    proposedCostExpirationLabel: function () {
      return this.selectedTypeStatus && this.selectedTypeStatus.altName === 'TradeIn' ? 'ACV Value Expiration Date' : 'Proposed Purchase Cost Expiration Date'
    },
    inventoryDetails () {
      return this.inventoryItem && this.inventoryItem.vehicleInfo ? this.inventoryItem.vehicleInfo : this.inventoryItem.name
    },
    isTrade: function () {
      return this.selectedTypeStatus && this.selectedTypeStatus.altName === 'TradeIn'
    },
    minimumUsageAllowed: function () {
      if (this.inventoryItem && this.inventoryItem.vinUsageHistory && this.inventoryItem.vinUsageHistory.length > 0) {
        return this.inventoryItem.vinUsageHistory[this.inventoryItem.vinUsageHistory.length - 1].usage
      }
      return this.lastVinUsage || 0
    },
    dealerId: function () {
      return this.$store.state.dealer.currentDealer.id
    },
    hasExistingDeals: function () {
      return this.deals && this.deals.length > 0
    },
    getDealSaleTypeFromInventory: function () {
      if (this.inventoryItem && this.inventoryItem.purchasedFromType === 'Factory') return null
      let typeName = (this.isPurchasedFromIndividual) ? 'Retail' : 'Wholesale'
      return this.definedTypes.dealSaleTypes.find((x) => x.altName === typeName)
    },
    isPurchasedFromIndividual: function () {
      return this.inventoryItem && this.inventoryItem.purchasedFromType === 'Individual'
    },
    isPurchasedFromWholesaler: function () {
      return this.inventoryItem && this.inventoryItem.purchasedFromType === 'Wholesaler'
    },
    dealActiveSync: function () {
      return this.createDealActive || this.editDealActive
    },
    usePortalForDeal: function () {
      if (this.invertSecondUsePortal) return !this.usePortal
      return this.usePortal
    }
  },
  methods: {
    onShowTrade: function () {
      let inventoryId = this.inventoryItem.id || -1
      if (inventoryId !== -1) {
        if (this.hasFeatureAccess('inventory.view')) {
          this.$router.push({name: 'InventoryQuickView', params: { id: inventoryId, isLoanerPod: false, offerPod: -1, autoLaunchControl: null }})
        }
      }
    },
    onShowConvertedInventory: function () {
      this.isConvertPpaConfirmationActive = false
      if (!this.openEditInventoryInModal) {
        this.$router.push({name: 'EditInventory', params: { id: this.convertedInventory.id, defaultSalesPerson: this.currentUser, defaultPreEntry: false }})
      } else {
        this.editInventoryActive = true
      }
      this.isConvertPpaConfirmationActive = false
    },
    onRequestConvertPpa: async function () {
      this.initializing = true
      this.serverErrorMessage = ''
      this.serverErrors = []
      await this.loadPurchaser()
      await this.loadSalesAgent()

      if (this.isPurchasedFromIndividual) {
        if (this.selectedDealId === 0) {
          await this.loadInProgressDealsByPurchaser()
          this.conversionTypeActive = true
        } else {
          this.onOpenConvertPpa()
        }
      } else if (this.isPurchasedFromWholesaler) {
        // we can skip the conversion type selection modal and go straight to converting modal
        this.onOpenConvertPpa()
      }

      this.onOpenConvertPpa()
      this.initializing = false
    },
    onSave: function () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.serverErrorMessage = ''
          this.serverErrors = []
          this.isSaving = true
          let inventoryModel = {
            ...this.inventoryItem,
            type: this.selectedTypeStatus ? this.selectedTypeStatus.altName : null,
            ppaExamined: this.ppaExamined ? this.ppaExamined : null,
            mileageEstimate: this.mileageEstimate,
            trueMileageUnknown: this.trueMileageUnknown,
            mileageExempt: this.mileageExempt
          }
          inventoryService.convertPpa(inventoryModel).then(response => {
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              this.convertedInventory = response
            }
            this.isSaving = false
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      })
    },
    onCancel: function () {
      this.$validator.pause()
      if (this.convertedInventory) {
        eventBus.$emit('inventory-changed', [this.convertedInventory])
      }
      eventBus.$emit('reload-deal')
      eventBus.$emit('reload-deal-list')
      this.isConvertPpaConfirmationActive = false
      this.ppaExamined = false
    },
    reset: function () {
      this.convertedInventory = null
      this.ppaExamined = false
      this.serverErrors = []
      this.serverErrorMessage = []
      this.mileageEstimate = false
      this.trueMileageUnknown = false
      this.mileageExempt = false
    },
    onInventorySaved: function (modifiedInventoryItem) {
      eventBus.$emit('reload-deal')
      eventBus.$emit('reload-deal-list')
      this.editInventoryActive = false
    },
    onInventoryCancelled: function () {
      eventBus.$emit('reload-deal')
      eventBus.$emit('reload-deal-list')
      this.editInventoryActive = false
    },
    onDealSaveComplete: function () {
      this.createDealActive = false
      this.editDealActive = false
      this.isConvertPpaConfirmationActive = true
    },
    onDealCancel: function () {
      this.createDealActive = false
      this.editDealActive = false
      this.isConvertPpaConfirmationActive = false
    },
    openCreateTradeDeal: function () {
      this.conversionTypeActive = false
      this.createDealActive = true
    },
    loadInProgressDealsByPurchaser: async function () {
      this.isLoading = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      let response = await dealService.getInProgressDealsByPurchaser(this.purchaser.id, this.dealerId, this.inventoryItem.purchasedFromType)
      this.isLoading = false
      if (response.errors) {
        this.serverErrors = response.errors
      } else {
        this.deals = response.results
      }
    },
    loadSalesAgent: async function () {
      if (this.inventoryItem && this.inventoryItem.buyerId) {
        this.isLoading = true
        let response = await userService.single(this.inventoryItem.buyerId)
        this.dealSalesAgent = response.user
        this.isLoading = false
      }
    },
    loadPurchaser: async function () {
      if (this.inventoryItem) {
        this.isLoading = true
        if (this.isPurchasedFromIndividual) {
          let response = await customerService.single(this.inventoryItem.purchasedFromId)
          this.purchaser = response.customer
          let lead = this.purchaser.customerLeads.find((lead) => lead.status === 'Active')
          if (lead) {
            this.vehicleOfInterests = lead.vehicleInterests.filter((x) => { return x.type === 'Interest' })
          }
        } else if (this.isPurchasedFromWholesaler) {
          let response = await supplierService.single(this.inventoryItem.purchasedFromId)
          this.purchaser = response.supplier
        }
        this.isLoading = false
      }
    },
    onEditDeal: function (dealId) {
      this.selectedDealId = dealId
      this.conversionTypeActive = false
      this.editDealActive = true
    },
    onCopyInfo: function (text) {
      let that = this
      this.$copyText(text).then(function (e) {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: text + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
      }, function (e) {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: 'Could not copy info to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
      })
    },
    onCloseConversionType: function () {
      this.conversionTypeActive = false
    },
    onOpenConvertPpa: function () {
      this.conversionTypeActive = false
      this.isConvertPpaConfirmationActive = true
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  watch: {
    inventoryItemId: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.reset()
      }

      if (this.inventoryItem) {
        this.inventoryItem.purchaseCost = this.inventoryItem.proposedCost
      }
    },
    isConvertPpaConfirmationActive: function (newValue, oldValue) {
      if (newValue && newValue === true) {
        if (this.inventoryItem) {
          this.inventoryItem.purchaseCost = this.inventoryItem.proposedCost
        }
      }
    },
    ppaExamined: function (newValue, oldValue) {
      if (newValue && newValue === true) {
        if (this.inventoryItem) {
          this.inventoryItem.ppaExamined = true
        }
      } else {
        if (this.inventoryItem) {
          this.inventoryItem.ppaExamined = false
        }
      }
    },
    mileageEstimate: function (newValue, oldValue) {
      if (newValue === false) {
        this.trueMileageUnknown = false
        this.mileageExempt = false
      }
    },
    inventory: function (newValue, oldValue) {
      if (newValue) {
        this.inventoryItem = newValue
        this.originalInventory = {...newValue}
      }
    }
  },
  mounted: async function () {
    this.selectedTypeStatus = this.defaultType
    if (this.inventoryItem) {
      this.inventoryItem.purchaseCost = this.inventoryItem.proposedCost
      this.mileageEstimate = this.inventoryItem.mileageEstimate
      this.trueMileageUnknown = this.inventoryItem.trueMileageUnknown
      this.mileageExempt = this.inventoryItem.mileageExempt
      if (this.inventoryItem.ppaIntention) {
        if (this.inventoryItem.ppaIntention === 'Trade') {
          this.selectedTypeStatus = this.definedTypes.inventoryTypes.find((x) => x.id === 4)
        }
        if (this.inventoryItem.ppaIntention === 'Consigned') {
          this.selectedTypeStatus = this.definedTypes.inventoryTypes.find((x) => x.id === 3)
        }
      }
    }
    if (this.displayConvertPpa) {
      this.onRequestConvertPpa()
      // this.createDealActive = true
    }

    if (this.dealId !== undefined && this.dealId > 0) {
      this.selectedDealId = this.dealId
      this.conversionTypeActive = false
      this.createDealActive = false
      this.editDealActive = false
    }
    this.$emit('on-mounted-callback')
    eventBus.$on('deal-save-complete', this.onDealSaveComplete)
    eventBus.$on('deal-save-cancel', this.onDealCancel)
  },
  beforeDestroy () {
    this.$validator.pause()
    eventBus.$off('deal-save-complete', this.onDealSaveComplete)
    eventBus.$off('deal-save-cancel', this.onDealCancel)
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0;
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important;
  }

  .modal-section {
    overflow: auto;
    height: calc(100vh - 300px);
  }

  .conversion-type-body {
    padding: 15px !important;
    min-width: 400px !important;
  }

</style>

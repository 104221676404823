<template>
  <div>
    <br />
    <div class="box" v-if="true">
      <form class="view-container">
        <h1>Bin Selector</h1>
        <accessory-bin-selector
          :required="true"
          validateAs="stored in bins"
          v-model="selectedAccesoryBins">
        </accessory-bin-selector>
        <br />
      </form>
      <button @click="onSaveTest"> Save</button>
    </div>
    <div class="box" v-if="false">
      <h1>Field Rotator</h1>
      <field-rotator v-model="fieldsToRotate"></field-rotator>
      <br />
    </div>
    <div class="box" v-if="false">
      <h1>Dealer Selector</h1>
      <dealer-selector v-model="selectedDealer"></dealer-selector>
      <br />
    </div>
    <div class="box" v-if="1===1">
      <h1>Switch Selector</h1>
      <switch-selector switchType="is-danger" v-model="selectedSwitch"></switch-selector>
      <br />
    </div>
    <div class="box" v-if="false">
      <h1>Button List Selector</h1>
      <button-list-selector validateAs="disposition intention" label="Disposition Intention" :required="true" v-model="selectedOption" :availableValues="dispositionIntentionStatuses"></button-list-selector>
      <br />
    </div>
    <div class="box" v-if="false">
      <h1>Customer Selector</h1>
      <customer-selector v-model="selectedCustomer"></customer-selector>
      <br />
    </div>
    <div class="box" v-if="false">
      <h1>User Selector</h1>
      <user-selector v-model="selectedSalesAgent" label="Sales Agent" validateAs="sales agent"></user-selector>
      <br />
    </div>
    <div class="box" v-if="false">
      <h1>Image Selector</h1>
      <image-selector v-model="photos" label="Inventory Media"></image-selector>
      <br/>
    </div>
    <div class="box" v-if="true">
      <h1>File Selector</h1>
      <file-selector v-model="files" label="Inventory Files"></file-selector>
      <br/>
    </div>
    <div class="box" v-if="false">
      <h1>Pod Lock Control</h1>
      <pod-lock-control></pod-lock-control>
      <br/>
    </div>
    <div class="box" v-if="true">
      <h1>Money Input Control</h1>
      <money-input-control
      moneyType="text"
      label=""
      v-model="selectedMoneyInput"></money-input-control>
      <br/>
    </div>
    <div class="box" v-if="true">
      <h1>Text Input Control</h1>
      <text-input-control
      textType="text"
      label=""
      v-model="selectedTextInput">
      </text-input-control>
      <br/>
    </div>
    <div class="box" v-if='false'>
      <h1>Phone Pop Demo</h1>
      <div id="pods">
        <div class="tile is-ancestor" style="flex-wrap: wrap;">
          <call-pod v-for="callLog in callLogs" :key="callLog.key" :callLog="callLog"></call-pod>
        </div>
      </div>
      <br/>
    </div>
  </div>
</template>

<script>
import DealerSelector from '@/components/generic/DealerSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import CustomerSelector from '@/components/generic/CustomerSelector'
import UserSelector from '@/components/generic/UserSelector'
import ImageSelector from '@/components/generic/ImageSelector'
import FileSelector from '@/components/generic/FileSelector'
import PodLockControl from '@/components/generic/PodLockControl'
import FieldRotator from '@/components/generic/FieldRotator'
import CallPod from '@/components/generic/pods/CallPod'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import AccesoryBinSelector from '@/components/generic/AccessoryBinSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import MoneyInputControl from '@/components/generic/MoneyInputControl'
import TextInputControl from '@/components/generic/TextInputControl'
const signalR = require('@microsoft/signalr')

export default {
  name: 'Examples',
  mixins: [validateBeforeSubmitMixin],
  components: {
    'dealer-selector': DealerSelector,
    'button-list-selector': ButtonListSelector,
    'customer-selector': CustomerSelector,
    'user-selector': UserSelector,
    'image-selector': ImageSelector,
    'file-selector': FileSelector,
    'pod-lock-control': PodLockControl,
    'field-rotator': FieldRotator,
    'call-pod': CallPod,
    'accessory-bin-selector': AccesoryBinSelector,
    'switch-selector': SwitchSelector,
    'money-input-control': MoneyInputControl,
    'text-input-control': TextInputControl
  },
  data () {
    return {
      selectedDealer: null,
      selectedSwitch: false,
      selectedMoneyInput: null,
      selectedTextInput: null,
      selectedOption: null,
      selectedCustomer: null,
      selectedSalesAgent: null,
      selectedAccesoryBins: [],
      showDate: new Date(),
      selectedDateRange: {
        startDate: null,
        endDate: null
      },
      callLogs: [],
      photos: [],
      files: [],
      show: false,
      fieldsToRotate: [
        {
          label: 'Days On Lot',
          value: '43 days'
        },
        {
          label: 'Days On Market',
          value: '13 days'
        },
        {
          label: 'Days On Ice',
          value: '4 days'
        }
      ],
      dispositionIntentionStatuses: [
        {
          id: 0,
          name: 'Retail'
        },
        {
          id: 1,
          name: 'Wholesale'
        },
        {
          id: 2,
          name: 'Wholesale Immediately'
        },
        {
          id: 3,
          name: 'Retail for X Days'
        }
      ]
    }
  },
  methods: {
    onSaveTest: function () {
      this.$validator.validateAll().then((result) => {
        alert('Saving...')
      })
    },
    setShowDate (d) {
      this.showDate = d
    },
    getCalendarTime (calendarEvent) {
      let sa = calendarEvent.start.format('a')
      let ea = calendarEvent.end.format('a')
      let sh = calendarEvent.start.format('h')
      let eh = calendarEvent.end.format('h')

      if (calendarEvent.start.minute !== 0) {
        sh += calendarEvent.start.format(':mm')
      }

      if (calendarEvent.end.minute !== 0) {
        eh += calendarEvent.end.format(':mm')
      }

      return (sa === ea) ? (sh + ' - ' + eh + ea) : (sh + sa + ' - ' + eh + ea)
    },
    onCallLogReceived: function (callLog) {
      let existingIndex = this.callLogs.findIndex((x) => x.CallID === callLog.CallID)
      if (existingIndex >= 0) {
        var existing = {...existing, ...callLog}
        this.$set(this.callLogs, existingIndex, existing)
      } else {
        this.callLogs.push({...callLog, key: this.callLogs.length + 1})
      }
    }
  },
  mounted: function () {
    var that = this
    var connection = new signalR.HubConnectionBuilder().withUrl('https://iatinboundreceiverapi.azurewebsites.net/targetHub').build()
    connection.on('CallReceived', function (target, message) {
      var call = JSON.parse(message)
      that.onCallLogReceived(call)
    })

    connection.onclose(() => {
      console.log('RPC disconnected')
      setTimeout(function () {
        connection.start()
      }, 5000)
    })

    connection.start().catch(function (err) {
      return console.error(err.toString())
    })
  }
}
</script>

<style scoped>
</style>

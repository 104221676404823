<template>
  <div>
    <form @submit.prevent="onValidate" :data-vv-scope="dataVvScope">
      <div v-if="useModal">
        <label class="label">
          {{ label }}
          <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
        </label>
        <a class="button is-info selected-make-model" @click="isConfiguring = true">{{overview}}</a>
        <span class="is-size-7 is-inline-block" v-html="colorOverview"></span>
      </div>
      <b-modal :active.sync="isConfiguring" :full-screen="isMobile" scroll="keep" :has-modal-card="true" id="makeModelSelector" v-if="useModal" :canCancel="canCancelDialog">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">{{label}}</p>
              <div class="is-pulled-right" style="margin-right:15px;" v-if="isLocated">
                <i class="icon fal fa-lg status-icon fa-exclamation-triangle has-text-danger" :title="'Vehicle will be automatically added to stock'"/>
                <label class="label is-inline-block is-size-7 has-text-danger">Vehicle will be added to stock</label>
              </div>
              <div class="columns">
                <div class="column">
                  <car-fax-control
                    :carFaxRating="selectedMakeModel.lookupResult ? selectedMakeModel.lookupResult.carFaxRating : null"  :vinNo="selectedVinNo"
                    :modalContainer="true" v-on:on-show-carfax-report="onShowCarFaxReport">
                  </car-fax-control>
                </div>
                <div class="column" v-if="autoCheckEnabled">
                  <auto-check-control
                    :autoCheckRating="selectedMakeModel && selectedMakeModel.lookupResult ? selectedMakeModel.lookupResult.autoCheckVehicleRating : null" :vinNo="selectedVinNo"
                    :modalContainer="true"
                    v-on:on-show-autocheck-report="onShowAutoCheckReport"
                    :purchasedFromType="purchasedFromType"
                    :inventoryType="inventoryType"
                    :inventoryTypeRequired="newInventory">
                  </auto-check-control>
                </div>
                <div class="column"></div>
              </div>
          </header>
          <section class="modal-card-body" style="height: 550px;">
            <div class="box" v-if="showTypeSelector || showCostSelector || showAskingPriceSelector">
              <div class="columns">
                <div class="column is-4" v-if="showTypeSelector">
                  <button-list-selector :scope="dataVvScope" ref="locateType" validateAs="type of locate" label="Type" :required="true" v-model="selectedLocateType" :availableValues="availableLocateTypes" v-on:on-change="onSelectedLocateType" :tabindex="1"></button-list-selector>
                </div>
                <div class="column is-4" v-if="showCostSelector">
                  <div class="field">
                    <label class="label">Purchase Price<b-icon pack="fas" :icon="purchasePriceRequired ? 'star' : ''" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <money :data-vv-scope="dataVvScope" ref="purchaseCost" name="purchaseCost" data-vv-as="purchase price" v-validate="{'required': purchasePriceRequired,'decimal': true}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has(dataVvScope + '.purchaseCost') }" type="text" placeholder="0.00" v-model="purchaseCost" v-bind="$globalMoneyFormat" tabindex="2"></money>
                      <span v-show="errors.has(dataVvScope + '.purchaseCost')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.purchaseCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.purchaseCost') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="column is-4" v-if="showAskingPriceSelector">
                  <div class="field">
                    <label class="label">{{askingPriceLabel}}<b-icon pack="fas" icon="" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <money :data-vv-scope="dataVvScope" ref="askingPrice" name="askingPrice" data-vv-as="sale price" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has(dataVvScope + '.askingPrice') }" type="text" placeholder="0.00" v-model="askingPrice" v-bind="$globalMoneyFormat" tabindex="3"></money>
                      <span v-show="errors.has(dataVvScope + '.askingPrice')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.askingPrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.askingPrice') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box" v-if="showVinSelector || showMileageSelector">
              <p v-if="vinDecodeErrors.length > 0">
                <ul class="errors">
                  <li v-for="error in vinDecodeErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
                </ul>
                <br/>
              </p>
              <div class="columns is-multiline">
                <vin-no-selector :data-vv-scope="dataVvScope" :vinRequired="forceVinCollection" v-model="selectedVinNo" v-on:vin-resolved="onVinDecodeComplete" v-on:error="onValidationError" class="column is-5" :tabIndex="4" v-if="showVinSelector"></vin-no-selector>
                <div class="column is-4" v-if="showMileageSelector">
                  <div class="field">
                    <label class="label">{{mileageTitle}}<b-icon pack="fas" :icon="usageRequired ? 'star' : ''" class="fa-ss"></b-icon></label>
                    <div class="control is-vertical-center">
                      <input name="usage" :data-vv-scope="dataVvScope"  data-vv-as="miles" v-validate="{'numeric': true, 'required': usageRequired}" :class="{'input': true, 'is-width-100': true, 'is-danger': errors.has(dataVvScope + '.usage') }" type="number" placeholder="#####" v-model="usage" :min="0" tabindex="5">
                      <usage-history-component :vinNo="selectedVinNo" :vinUsageHistory="value && value.vinUsageHistory"></usage-history-component>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.usage')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.usage') }}</span>
                  </div>
                </div>
                <div class="column is-3" >
                  <switch-selector type="is-danger" v-model="isDamaged" :isDisabled="isNewLocateType" tabindex="6" extraClasses="is-vertical-center">
                    <template slot="custom-label">
                      <label class="label">Damaged<b-icon pack="fal" icon="info-circle fa-sm has-text-info" :title="damageTitle"></b-icon></label>
                    </template>
                  </switch-selector>
                </div>
                <div class="column is-12" v-if="this.isDamaged">
                  <div class="field">
                    <label class="label">Description of Damage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control">
                      <textarea :data-vv-scope="dataVvScope" rows="5" name="damageNote" data-vv-as="damage description" v-validate="'required'" :class="{'textarea': true, 'is-danger': errors.has(dataVvScope + '.damageNote') }" placeholder="" v-model="damageNote" tabindex="7"></textarea>
                      <span v-show="errors.has(dataVvScope + '.damageNote')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.damageNote')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.damageNote') }}</span>
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="this.isDamaged">
                  <image-selector
                   :data-vv-scope="dataVvScope"
                   v-model="damageFiles"
                   label="Damage Visual Survey"
                   :required="false"
                   :isBoxed="false"
                   tabindex="8"
                    @uploadStarted="uploadStarted"
                    @uploadComplete="uploadComplete"></image-selector>
                </div>
              </div>
            </div>

            <div class="box" v-if="showSourceSelector">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <button-list-selector :scope="dataVvScope" validateAs="source" label="Source" :required="true" v-model="selectedSource" :availableValues="allowedSourceTypes" :tabindex="9"></button-list-selector>
                </div>
                <div class="column is-12" v-if="selectedSource && selectedSource.id > this.definedTypes.allowedSourceTypes.Factory">
                  <div class="field is-expanded" v-if="selectedSource && selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler">
                    <supplier-selector :data-vv-scope="dataVvScope" v-model="selectedWholeSaler" label="Wholesaler/Dealer" validateAs="purchased from wholesaler" :required="true" tabindex="10"></supplier-selector>
                  </div>
                  <div class="field is-expanded" v-if="selectedSource && selectedSource.id === this.definedTypes.allowedSourceTypes.Individual">
                    <customer-selector :data-vv-scope="dataVvScope" :selectedDealer="getCurrentDealer" v-model="selectedCustomer" label="Individual" validateAs="purchased from customer" :required="true" tabindex="11"></customer-selector>
                  </div>
                </div>
              </div>
            </div>

            <div class="box" v-if="captureTitleStatus && showTitleStatus">
              <div class="columns is-multiline">
                <div class="column is-7">
                  <button-list-selector :scope="dataVvScope" validateAs="title status" label="Title Status" :required="titleStatusRequired" v-model="selectedTitleStatus" :availableValues="allowedTitleStatusOptions" :tabindex="12"></button-list-selector>
                </div>
                <div class="column is-5">
                  <label class="label">
                    Warranty Expiration Date
                    <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                  </label>
                  <el-date-picker
                    :data-vv-scope="dataVvScope"
                    @change="onFormChange()"
                    v-model="warrantyExpirationDate"
                    type="date"
                    placeholder="Click to select..."
                    :tabindex="13"
                    popper-class="datepicker-container">
                  </el-date-picker>
                </div>
                <div class="column is-12" v-if="isTitleReceived">
                  <file-selector
                  :data-vv-scope="dataVvScope"
                  v-model="titleFiles"
                  :label="titleStatusMediaLabel"
                  :required="false"
                  :isBoxed="false"
                  tabindex="14"
                  :usePortalViewer="true"
                  @uploadStarted="uploadStarted"
                  @uploadComplete="uploadComplete">
                  </file-selector>
                </div>
              </div>
            </div>

            <div class="box" v-if="isTitlePayOff">
              <div class="columns is-multiline">
                <div class="column is-6">
                  <bank-selector :scope="dataVvScope" v-model="selectedBank" label="Title Holding Bank" validateAs="bank" :required="true"></bank-selector>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Account No<b-icon pack="fas" icon="star" class="fa-ss" ></b-icon></label>
                    <div class="control">
                      <input :data-vv-scope="dataVvScope" name="accNo" data-vv-as="account no" v-validate="{'required': true}" :class="{'input': true, 'is-danger': errors.has(dataVvScope + '.accNo') }" type="text" placeholder="#####" v-model="accNo" tabindex="16">
                      <span v-show="errors.has(dataVvScope + '.accNo')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.accNo')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.accNo') }}</span>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label no-content-wrap">Good Until Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <el-date-picker
                        :data-vv-scope="dataVvScope"
                        @change="onFormChange()"
                        v-model="goodUntilDate"
                        type="date"
                        data-vv-as="good until date"
                        v-validate="{'required': true}"
                        data-vv-validate-on="input|close"
                        name="goodUntilDate"
                        placeholder="Click to select..."
                        :tabindex="17"
                        popper-class="datepicker-container">
                      </el-date-picker>
                      <span v-show="errors.has(dataVvScope + '.goodUntilDate')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.goodUntilDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.goodUntilDate') }}</span>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label no-content-wrap">Payoff Amount<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <money :data-vv-scope="dataVvScope" name="payoffAmount" data-vv-as="payoff amount" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has(dataVvScope + '.payoffAmount') }" type="text" placeholder="0.00" v-model="payoffAmount" v-bind="$globalMoneyFormat" tabindex="18"></money>
                      <span v-show="errors.has(dataVvScope + '.payoffAmount')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.payoffAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.payoffAmount') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box">
              <div class="model-error-container" v-if="modelError === true">You have selected model that is no longer active. Please select a valid model to continue</div>
              <div class="columns">
                <div class="column is-12">
                  <div class="field" v-show="!showDealerMakes">
                    <label class="label">
                      Make
                      <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" ref="make"  :taggable="false" @tag="makeAdded" name="selectedMake" data-vv-validate-on="input|close" data-vv-as="make" v-validate="'required'" :class="{'is-danger': errors.has(dataVvScope + '.selectedMake') }" v-model="selectedMake" :options="makes" label="name" trackBy="id" @select="onSelectedMake" :tabIndex="19" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedMake')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedMake')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedMake') }}</span>
                  </div>
                  <button-list-selector :scope="dataVvScope" ref="makeButtons" validateAs="make" label="Make" :required="true" v-model="selectedMake" :availableValues="availableDealerMakes" v-on:on-change="onSelectedMake" v-show="showDealerMakes" :tabindex="20" :enabled="!disabled"></button-list-selector>
                </div>
              </div>

              <div class="columns">
                <div class="column is-6">
                  <div class="field" v-show="this.selectedMake">
                    <label class="label">
                      Production Year ({{vehicleAge}})
                      <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" ref="year" :maxHeight="200"  :taggable="true" @tag="yearAdded" name="selectedYear" data-vv-validate-on="input|close" data-vv-as="year" v-validate="'required'" :class="{'is-danger': errors.has(dataVvScope + '.selectedYear') }" v-model="selectedYear" :options="productionYears" @select="onSelectedYear" :tabIndex="21" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedYear')" class="help is-danger is-inline-block"><i v-show="errors.has(dataVvScope + '.selectedYear')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedYear') }}</span>
                    <pop-fill-control title="Click to use this value as the production year. This is a value from vindecode that we do not have in our system" :fillableValue="decodedProductionYear" v-if="decodedProductionYear" :onFillAction="onFillProductionYear"></pop-fill-control>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field" v-show="this.selectedMake && this.selectedYear">
                    <label class="label">
                      Model
                      <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" ref="model" :maxHeight="200" :taggable="true" @tag="modelAdded" name="selectedModel" data-vv-validate-on="input|close" data-vv-as="model" v-validate="'required'" :class="{'is-danger': errors.has(dataVvScope + '.selectedModel') }" v-model="selectedModel" :options="models" label="name" trackBy="id" @select="onSelectedModel" :tabIndex="22" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedModel')" class="help is-danger is-inline-block"><i v-show="errors.has(dataVvScope + '.selectedModel')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedModel') }}</span>
                    <pop-fill-control title="Click to use this value as the model. This is a value from vindecode that we do not have in our system" :fillableValue="decodedModel.name" v-if="decodedModel" :onFillAction="onFillModel"></pop-fill-control>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column is-6">
                  <div class="field" v-show="this.selectedModel">
                    <label class="label">
                      Trim
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" ref="trim" :maxHeight="200" :taggable="true" @tag="trimAdded" name="selectedTrim" data-vv-validate-on="input|close" data-vv-as="trim" :class="{'is-danger': errors.has(dataVvScope + '.selectedTrim') }" v-model="selectedTrim" :options="trims" @select="onSelectedTrim" :tabIndex="23" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedTrim')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedTrim')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedTrim') }}</span>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field" v-show="this.selectedModel">
                    <label class="label">
                      Style
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" ref="style" :maxHeight="200" :taggable="true" @tag="styleAdded" name="selectedStyle" data-vv-validate-on="input|close" data-vv-as="style" :class="{'is-danger': errors.has(dataVvScope + '.selectedStyle') }" v-model="selectedStyle" :options="styles" @select="onSelectedStyle" :tabIndex="24" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedStyle')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedStyle')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedStyle') }}</span>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column is-6">
                  <div class="field" v-show="this.selectedMake">
                    <label class="label">
                      Exterior Color
                      <b-icon pack="fas" :icon="(forceColorSelection ? 'star': '')" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" :maxHeight="200" openDirection="top" ref="extColor" :taggable="true" @tag="onExteriorColorAdded" name="selectedExteriorColor" data-vv-validate-on="input|close" data-vv-as="exterior color" v-validate="{'required': this.forceColorSelection}" :class="{'is-danger': errors.has(dataVvScope + '.selectedExteriorColor') }" v-model="selectedExteriorColor" :options="exteriorColors" @select="onSelectedExteriorColor" :tabIndex="25" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedExteriorColor')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedExteriorColor')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedExteriorColor') }}</span>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field" v-show="this.selectedMake">
                    <label class="label">
                      Interior Color
                      <b-icon pack="fas" :icon="(forceColorSelection ? 'star': '')" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" :maxHeight="200" openDirection="top" ref="intColor" :taggable="true" @tag="onInteriorColorAdded" name="selectedInteriorColor" data-vv-validate-on="input|close" data-vv-as="interior color" v-validate="{'required': this.forceColorSelection}" :class="{'is-danger': errors.has(dataVvScope + '.selectedInteriorColor') }" v-model="selectedInteriorColor" :options="interiorColors" @select="onSelectedInteriorColor" :tabIndex="26" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedInteriorColor')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedInteriorColor')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedInteriorColor') }}</span>
                  </div>
                </div>
              </div>

              <div class="columns" v-show="showAlternateColors">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">
                      Alternate Exterior Color
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" :maxHeight="200" :hideSelected="true" multiple openDirection="top" ref="altExtColor" :taggable="true" @tag="onAltExteriorColorAdded" name="selectedAltExteriorColor" v-model="selectedAltExteriorColor" :options="altExteriorColors" :tabIndex="27" :disabled="disabled"></multiselect>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">
                      Alternate Interior Color
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" :maxHeight="200" :hideSelected="true" multiple openDirection="top" ref="altIntColor" :taggable="true" @tag="onAltInteriorColorAdded" name="selectedAltInteriorColor" v-model="selectedAltInteriorColor" :options="altInteriorColors" :tabIndex="28" :disabled="disabled"></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns" v-show="this.selectedModel">
                <div class="column is-12">
                  <button-list-selector :scope="dataVvScope" validateAs="transmission type" label="Transmission" :required="forceTransmissionSelection" v-model="selectedTransmissionType" :availableValues="allowedTransmissionTypes" v-on:on-change="onSelectedTransmissionType" :enabled="!disabled"></button-list-selector>
                </div>
              </div>

              <div class="columns" v-show="showTags">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">
                      Tags
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" :maxHeight="200" :hideSelected="true" multiple openDirection="top" ref="tags" :taggable="true" @tag="onTagAdded" name="selectedTags" v-model="selectedTags" :options="availableTags" :tabIndex="29" :disabled="disabled"></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns" v-show="showNotes">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">
                      Notes
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <textarea :data-vv-scope="dataVvScope" name="notes" :class="{'textarea': true }" rows="5" v-model="notes" @change="onNoteChanged" :tabindex="30"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </section>
          <footer class="modal-card-foot is-block">
            <button :disabled="isUploadingFile" ref="done" class="button is-primary" type="submit" tabindex="31">Done</button>
            <button ref="startOver" class="button is-danger" type="button" @click="onStartOver" tabindex="32" v-if="allowRestore === true">Start Over</button>
            <div class="is-pulled-right" style="margin-right:15px;" v-if="historicalDamageDataAvailable">
              <label class="label is-inline-block is-size-7">Damage History</label>
              <i class="icon fal fa-lg status-icon" :class="getIconByLastDamageStatus()" :title="'Damage History: ' + getLastDamageDescription()"/>
            </div>
            <div class="is-pulled-right" style="margin-right:15px;" v-if="historicalAccessoryDataAvailable">
              <label class="label is-inline-block is-size-7">Accessory History</label>
              <i class="icon fal fa-lg status-icon" :class="getIconByLastAccessoryStatus()" :title="'Accessory History: ' + getLastAccessoryStatusDescription()"/>
            </div>
          </footer>
        </div>
      </b-modal>

      <div v-else>
          <section class="modal-card-body make-model-inline">
            <div class="box" v-if="showTypeSelector || showCostSelector || showAskingPriceSelector">
              <div class="columns" v-if="isLocated">
                <div class="column is-12">
                  <i class="icon fal fa-lg fa-exclamation-triangle has-text-danger" :title="'Vehicle will be automatically added to stock'"/>
                  <label class="label is-inline-block is-size-7 has-text-danger">Vehicle will be added to stock</label>
                </div>
              </div>
              <div class="columns">
                <div class="column is-4" v-if="showTypeSelector">
                  <button-list-selector ref="locateType" validateAs="type of locate" label="Type" :required="true" v-model="selectedLocateType" :availableValues="availableLocateTypes" v-on:on-change="onSelectedLocateType" :tabindex="1"></button-list-selector>
                </div>
                <div class="column is-4" v-if="showCostSelector">
                  <div class="field">
                    <label class="label">Purchase Price<b-icon pack="fas" :icon="purchasePriceRequired ? 'star' : ''" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <money ref="purchaseCost" name="purchaseCost" data-vv-as="purchase price" v-validate="{'required': purchasePriceRequired,'decimal': true}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.first(dataVvScope + '.purchaseCost') }" type="text" placeholder="0.00" v-model="purchaseCost" v-bind="$globalMoneyFormat" tabindex="2"></money>
                      <span v-show="errors.first(dataVvScope + '.purchaseCost')" class="help is-danger"><i v-show="errors.first(dataVvScope + '.purchaseCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.purchaseCost') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
                <div class="column is-4" v-if="showAskingPriceSelector">
                  <div class="field">
                    <label class="label">{{askingPriceLabel}}<b-icon pack="fas" icon="" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <money ref="askingPrice" name="askingPrice" data-vv-as="sale price" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.first(dataVvScope + '.askingPrice') }" type="text" placeholder="0.00" v-model="askingPrice" v-bind="$globalMoneyFormat" tabindex="3"></money>
                      <span v-show="errors.first(dataVvScope + '.askingPrice')" class="help is-danger"><i v-show="errors.first(dataVvScope + '.askingPrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.askingPrice') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box" v-if="showVinSelector || showMileageSelector">
              <p v-if="vinDecodeErrors.length > 0">
                <ul class="errors">
                  <li v-for="error in vinDecodeErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
                </ul>
                <br/>
              </p>
              <div class="columns is-multiline">
                <vin-no-selector :vinRequired="forceVinCollection" v-model="selectedVinNo" v-on:vin-resolved="onVinDecodeComplete" v-on:error="onValidationError" class="column is-5" :tabIndex="3" v-if="showVinSelector"></vin-no-selector>
                <div class="column is-4" v-if="showMileageSelector">
                  <div class="field">
                    <label class="label">{{mileageTitle}}<b-icon pack="fas" :icon="usageRequired ? 'star' : ''" class="fa-ss"></b-icon></label>
                    <div class="control is-vertical-center">
                      <input name="usage" data-vv-as="miles" v-validate="{'numeric': true, 'required': usageRequired}" :class="{'input': true, 'is-width-100': true, 'is-danger': errors.first(dataVvScope + '.usage') }" type="number" placeholder="#####" v-model="usage" :min="0">
                      <usage-history-component :vinNo="selectedVinNo" :vinUsageHistory="value && value.vinUsageHistory"></usage-history-component>
                    </div>
                    <span v-show="errors.first(dataVvScope + '.usage')" class="help is-danger"><i v-show="errors.first(dataVvScope + '.usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.usage') }}</span>
                  </div>
                </div>
                <div class="column is-3" >
                  <switch-selector type="is-danger" v-model="isDamaged" :isDisabled="isNewLocateType" tabindex="6" extraClasses="is-vertical-center">
                    <template slot="custom-label">
                      <label class="label">Damaged<b-icon pack="fal" icon="info-circle fa-sm has-text-info" :title="damageTitle"></b-icon></label>
                    </template>
                  </switch-selector>
                </div>
                <div class="column is-12" v-if="this.isDamaged">
                  <div class="field">
                    <label class="label">Description of Damage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control">
                      <textarea rows="5" name="damageNote" data-vv-as="damage description" v-validate="'required'" :class="{'textarea': true, 'is-danger': errors.first(dataVvScope + '.damageNote') }" placeholder="" v-model="damageNote"></textarea>
                      <span v-show="errors.first(dataVvScope + '.damageNote')" class="help is-danger"><i v-show="errors.first(dataVvScope + '.damageNote')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.damageNote') }}</span>
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="this.isDamaged">
                  <image-selector
                    v-model="damageFiles"
                    label="Damage Visual Survey"
                    :required="false"
                    :isBoxed="false"
                    @uploadStarted="uploadStarted"
                    @uploadComplete="uploadComplete">
                  </image-selector>
                </div>
              </div>
            </div>

            <div class="box" v-if="showSourceSelector">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <button-list-selector :scope="dataVvScope" validateAs="source" label="Source" :required="true" v-model="selectedSource" :availableValues="allowedSourceTypes"></button-list-selector>
                </div>
                <div class="column is-12" v-if="selectedSource && selectedSource.id > this.definedTypes.allowedSourceTypes.Factory">
                  <div class="field is-expanded" v-if="selectedSource && selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler">
                    <supplier-selector :scope="dataVvScope" v-model="selectedWholeSaler" label="Wholesaler/Dealer" validateAs="purchased from wholesaler" :required="true"></supplier-selector>
                  </div>
                  <div class="field is-expanded" v-if="selectedSource && selectedSource.id === this.definedTypes.allowedSourceTypes.Individual">
                    <customer-selector :scope="dataVvScope" :selectedDealer="getCurrentDealer" v-model="selectedCustomer" label="Individual" validateAs="purchased from customer" :required="true"></customer-selector>
                  </div>
                </div>
              </div>
            </div>

            <div class="box" v-if="captureTitleStatus && showTitleStatus">
              <div class="columns is-multiline">
                <div class="column is-7">
                  <button-list-selector :scope="dataVvScope" validateAs="title status" label="Title Status" :required="titleStatusRequired" v-model="selectedTitleStatus" :availableValues="allowedTitleStatusOptions"></button-list-selector>
                </div>
                <div class="column is-5">
                  <label class="label">
                    Warranty Expiration Date
                    <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                  </label>
                  <el-date-picker
                    @change="onFormChange()"
                    v-model="warrantyExpirationDate"
                    type="date"
                    placeholder="Click to select..."
                    popper-class="datepicker-container">
                  </el-date-picker>
                </div>
                <div class="column is-12" v-if="isTitleReceived">
                  <file-selector
                  v-model="titleFiles"
                  :label="titleStatusMediaLabel"
                  :required="false"
                  :isBoxed="false"
                  :usePortalViewer="false"
                  @uploadStarted="uploadStarted"
                  @uploadComplete="uploadComplete">
                  </file-selector>
                </div>
              </div>
            </div>

            <div class="box" v-if="isTitlePayOff">
              <div class="columns is-multiline">
                <div class="column is-6">
                  <bank-selector :scope="dataVvScope" v-model="selectedBank" label="Title Holding Bank" validateAs="bank" :required="true"></bank-selector>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Account No<b-icon pack="fas" icon="star" class="fa-ss" ></b-icon></label>
                    <div class="control">
                      <input name="accNo" data-vv-as="account no" v-validate="{'required': true}" :class="{'input': true, 'is-danger': errors.first(dataVvScope + '.accNo') }" type="text" placeholder="#####" v-model="accNo">
                      <span v-show="errors.first(dataVvScope + '.accNo')" class="help is-danger"><i v-show="errors.first(dataVvScope + '.accNo')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.accNo') }}</span>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label no-content-wrap">Good Until Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <el-date-picker
                        @change="onFormChange()"
                        v-model="goodUntilDate"
                        type="date"
                        data-vv-as="good until date"
                        v-validate="{'required': true}"
                        data-vv-validate-on="input|close"
                        name="goodUntilDate"
                        placeholder="Click to select..."
                        popper-class="datepicker-container">
                      </el-date-picker>
                      <span v-show="errors.first(dataVvScope + '.goodUntilDate')" class="help is-danger"><i v-show="errors.first(dataVvScope + '.goodUntilDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.goodUntilDate') }}</span>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label no-content-wrap">Payoff Amount<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control has-icons-left">
                      <money name="payoffAmount" data-vv-as="payoff amount" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.first(dataVvScope + '.payoffAmount') }" type="text" placeholder="0.00" v-model="payoffAmount" v-bind="$globalMoneyFormat"></money>
                      <span v-show="errors.first(dataVvScope + '.payoffAmount')" class="help is-danger"><i v-show="errors.first(dataVvScope + '.payoffAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.payoffAmount') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="box" v-if="!isModelInfoCollapsed">
              <div class="model-error-container" v-if="modelError === true">You have selected model that is no longer active. Please select a valid model to continue</div>
              <div class="columns">
                <div class="column is-12">
                  <div class="field" v-show="!showDealerMakes">
                    <label class="label">
                      Make
                      <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" ref="make"  :taggable="false" @tag="makeAdded" name="selectedMake" data-vv-validate-on="input|close" data-vv-as="make" v-validate="'required'" :class="{'is-danger': errors.has(dataVvScope + '.selectedMake') }" v-model="selectedMake" :options="makes" label="name" trackBy="id" @select="onSelectedMake" :tabindex="2" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedMake')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedMake')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedMake') }}</span>
                  </div>
                  <button-list-selector ref="makeButtons" validateAs="make" label="Make" :required="true" v-model="selectedMake" :availableValues="availableDealerMakes" v-on:on-change="onSelectedMake" v-if="showDealerMakes" :tabindex="2" :enabled="!disabled"></button-list-selector>
                </div>
              </div>

              <div class="columns">
                <div class="column is-6">
                  <div class="field" v-show="this.selectedMake">
                    <label class="label">
                      Production Year ({{vehicleAge}})
                      <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" :maxHeight="200" ref="year"  :taggable="true" @tag="yearAdded" name="selectedYear" data-vv-validate-on="input|close" data-vv-as="year" v-validate="'required'" :class="{'is-danger': errors.has(dataVvScope + '.selectedYear') }" v-model="selectedYear" :options="productionYears" @select="onSelectedYear" :tabindex="3" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedYear')" class="help is-danger is-inline-block"><i v-show="errors.has(dataVvScope + '.selectedYear')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedYear') }}</span>
                    <pop-fill-control title="Click to use this value as the production year. This is a value from vindecode that we do not have in our system" :fillableValue="decodedProductionYear" v-if="decodedProductionYear" :onFillAction="onFillProductionYear"></pop-fill-control>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field" v-show="this.selectedMake && this.selectedYear">
                    <label class="label">
                      Model
                      <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" ref="model" :maxHeight="200" :taggable="true" @tag="modelAdded" name="selectedModel" data-vv-validate-on="input|close" data-vv-as="model" v-validate="'required'" :class="{'is-danger': errors.has(dataVvScope + '.selectedModel') }" v-model="selectedModel" :options="models" label="name" trackBy="id" @select="onSelectedModel" :tabindex="4" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedModel')" class="help is-danger is-inline-block"><i v-show="errors.has(dataVvScope + '.selectedModel')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedModel') }}</span>
                    <pop-fill-control title="Click to use this value as the model. This is a value from vindecode that we do not have in our system" :fillableValue="decodedModel.name" v-if="decodedModel" :onFillAction="onFillModel"></pop-fill-control>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column is-6">
                  <div class="field" v-show="this.selectedModel">
                    <label class="label">
                      Trim
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" ref="trim" :maxHeight="200" :taggable="true" @tag="trimAdded" name="selectedTrim" data-vv-validate-on="input|close" data-vv-as="trim" :class="{'is-danger': errors.has(dataVvScope + '.selectedTrim') }" v-model="selectedTrim" :options="trims" @select="onSelectedTrim" :tabindex="5" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedTrim')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedTrim')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedTrim') }}</span>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field" v-show="this.selectedModel">
                    <label class="label">
                      Style
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" ref="style" :maxHeight="200" :taggable="true" @tag="styleAdded" name="selectedStyle" data-vv-validate-on="input|close" data-vv-as="style" :class="{'is-danger': errors.has(dataVvScope + '.selectedStyle') }" v-model="selectedStyle" :options="styles" @select="onSelectedStyle" :tabindex="6" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedStyle')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedStyle')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedStyle') }}</span>
                  </div>
                </div>
              </div>

              <div class="columns">
                <div class="column is-6">
                  <div class="field" v-show="this.selectedMake">
                    <label class="label">
                      Exterior Color
                      <b-icon pack="fas" :icon="(forceColorSelection ? 'star': '')" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" :maxHeight="200" openDirection="top" ref="extColor" :taggable="true" @tag="onExteriorColorAdded" name="selectedExteriorColor" data-vv-validate-on="input|close" data-vv-as="exterior color" v-validate="{'required': this.forceColorSelection}" :class="{'is-danger': errors.has(dataVvScope + '.selectedExteriorColor') }" v-model="selectedExteriorColor" :options="exteriorColors" @select="onSelectedExteriorColor" :tabindex="7" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedExteriorColor')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedExteriorColor')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedExteriorColor') }}</span>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field" v-show="this.selectedMake">
                    <label class="label">
                      Interior Color
                      <b-icon pack="fas" :icon="(forceColorSelection ? 'star': '')" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :data-vv-scope="dataVvScope" :maxHeight="200" openDirection="top" ref="intColor" :taggable="true" @tag="onInteriorColorAdded" name="selectedInteriorColor" data-vv-validate-on="input|close" data-vv-as="interior color" v-validate="{'required': this.forceColorSelection}" :class="{'is-danger': errors.has(dataVvScope + '.selectedInteriorColor') }" v-model="selectedInteriorColor" :options="interiorColors" @select="onSelectedInteriorColor" :tabindex="8" :disabled="disabled" :hideSelected="true"></multiselect>
                    </div>
                    <span v-show="errors.has(dataVvScope + '.selectedInteriorColor')" class="help is-danger"><i v-show="errors.has(dataVvScope + '.selectedInteriorColor')" class="fas fa-exclamation-triangle"></i> {{ errors.first(dataVvScope + '.selectedInteriorColor') }}</span>
                  </div>
                </div>
              </div>

              <div class="columns" v-show="showAlternateColors">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">
                      Alternate Exterior Color
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :hideSelected="true" :maxHeight="200" multiple openDirection="top" ref="altExtColor" :taggable="true" @tag="onAltExteriorColorAdded" name="selectedAltExteriorColor" v-model="selectedAltExteriorColor" :options="altExteriorColors" :tabindex="9" :disabled="disabled"></multiselect>
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">
                      Alternate Interior Color
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :hideSelected="true" :maxHeight="200" multiple openDirection="top" ref="altIntColor" :taggable="true" @tag="onAltInteriorColorAdded" name="selectedAltInteriorColor" v-model="selectedAltInteriorColor" :options="altInteriorColors" :tabindex="10" :disabled="disabled"></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns" v-show="this.selectedModel">
                <div class="column is-12">
                  <button-list-selector :scope="dataVvScope" validateAs="transmission type" label="Transmission" :required="false" v-model="selectedTransmissionType" :availableValues="allowedTransmissionTypes" v-on:on-change="onSelectedTransmissionType" :enabled="!disabled"></button-list-selector>
                </div>
              </div>

              <div class="columns" v-show="showTags">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">
                      Tags
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <multiselect :hideSelected="true" :maxHeight="200" multiple openDirection="top" ref="tags" :taggable="true" @tag="onTagAdded" name="selectedTags" v-model="selectedTags" :options="availableTags" :tabindex="11" :disabled="disabled"></multiselect>
                    </div>
                  </div>
                </div>
              </div>

              <div class="columns" v-show="showNotes">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">
                      Notes
                      <b-icon pack="fas" icon="" class="fa-ss"></b-icon>
                    </label>
                    <div class="control is-fullwidth">
                      <textarea name="notes" :class="{'textarea': true }" rows="5" v-model="notes" @change="onNoteChanged" :tabindex="12"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="box" v-if="historicalDamageDataAvailable || historicalAccessoryDataAvailable">
              <div class="is-pulled-right" style="margin-right:15px;" v-if="historicalDamageDataAvailable">
                <label class="label is-inline-block is-size-7">Damage History</label>
                <i class="icon fal fa-lg status-icon" :class="getIconByLastDamageStatus()" :title="'Damage History: ' + getLastDamageDescription()"/>
              </div>
              <div class="is-pulled-right" style="margin-right:15px;" v-if="historicalAccessoryDataAvailable">
                <label class="label is-inline-block is-size-7">Accessory History</label>
                <i class="icon fal fa-lg status-icon" :class="getIconByLastAccessoryStatus()" :title="'Accessory History: ' + getLastAccessoryStatusDescription()"/>
              </div>
            </div>

            <button ref="done" class="button is-primary" type="submit" tabindex="13" style="visibility:hidden">Done</button>

          </section>
      </div>
      <p></p>
    </form>
    <auto-check-report-control
      v-if="autoCheckEnabled"
      title="AutoCheck Vehicle Status"
      :vinNo="selectedVinNo"
      v-on:on-hide-autocheck-report="onHideAutoCheckReport"
      :showAutoCheckReportModal="showReport">
    </auto-check-report-control>
    <car-fax-report-control
      title="CarFax Vehicle Status"
      v-on:on-hide-carfax-report="onHideCarFaxReport"
      :showCarFaxReportModal="showCarFaxReport"
      :carFaxReportUrl="carFaxReportUrl">
    </car-fax-report-control>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import PopFillControl from '@/components/generic/PopFillControl'
import CarFaxControl from '@/components/generic/CarFaxControl'
import ImageSelector from '@/components/generic/ImageSelector'
import FileSelector from '@/components/generic/FileSelector'
import VinNoSelector from '@/components/generic/VinNoSelector'
import CustomerSelector from '@/components/generic/CustomerSelector'
import SupplierSelector from '@/components/generic/SupplierSelector'
import SectionHeader from '@/components/generic/SectionHeader'
import UsageHistoryComponent from '@/components/inventory/UsageHistoryComponent'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import BankSelector from '@/components/generic/BankSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import AutoCheckControl from '@/components/generic/AutoCheckControl'
import AutoCheckReportControl from '@/components/generic/AutoCheckReportControl'
import CarFaxReportControl from '@/components/generic/CarFaxReportControl'

const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapVehicleMakeGetters = createNamespacedHelpers('vehicleMake').mapGetters
const mapDealerGetters = createNamespacedHelpers('dealer').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'MakeModelSelector',
  mixins: [validateBeforeSubmitMixin],
  components: {
    'button-list-selector': ButtonListSelector,
    'car-fax-control': CarFaxControl,
    'customer-selector': CustomerSelector,
    'supplier-selector': SupplierSelector,
    'vin-no-selector': VinNoSelector,
    'section-header': SectionHeader,
    'usage-history-component': UsageHistoryComponent,
    'image-selector': ImageSelector,
    'file-selector': FileSelector,
    'bank-selector': BankSelector,
    'switch-selector': SwitchSelector,
    'auto-check-control': AutoCheckControl,
    'auto-check-report-control': AutoCheckReportControl,
    'pop-fill-control': PopFillControl,
    'car-fax-report-control': CarFaxReportControl
  },
  props: {
    label: {
      type: String,
      default: 'Select/Configure Vehicle'
    },
    value: {
      type: Object,
      default: null
    },
    inventoryType: {
      type: Object,
      default: null
    },
    stockType: {
      type: Object,
      default: null
    },
    forceColorSelection: {
      type: Boolean,
      default: true
    },
    showTypeSelector: {
      type: Boolean,
      default: false
    },
    showCostSelector: {
      type: Boolean,
      default: false
    },
    showAskingPriceSelector: {
      type: Boolean,
      default: false
    },
    showMileageSelector: {
      type: Boolean,
      default: false
    },
    captureTitleStatus: {
      type: Boolean,
      default: false
    },
    showVinSelector: {
      type: Boolean,
      default: false
    },
    showSourceSelector: {
      type: Boolean,
      default: false
    },
    showAlternateColors: {
      type: Boolean,
      default: false
    },
    showTags: {
      type: Boolean,
      default: false
    },
    showNotes: {
      type: Boolean,
      default: false
    },
    allowRestore: {
      type: Boolean,
      default: false
    },
    isLocated: {
      type: Boolean,
      default: false
    },
    forceVinCollection: {
      type: Boolean,
      default: false
    },
    useModal: {
      type: Boolean,
      default: true
    },
    dataVvScope: {
      type: String,
      default: 'mms'
    },
    canCancelDialog: {
      type: Boolean,
      default: false
    },
    forceTransmissionSelection: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    purchasedFromType: {
      type: String,
      default: ''
    },
    newInventory: {
      type: Boolean,
      default: false
    },
    collapseMakeModelInfo: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isConfiguring: false,
      selectedLocateType: null,
      selectedMake: null,
      selectedYear: null,
      selectedModel: null,
      selectedStyle: null,
      selectedTrim: null,
      selectedVinNo: '',
      selectedSource: null,
      selectedWholeSaler: null,
      selectedCustomer: null,
      purchaseCost: null,
      askingPrice: null,
      usage: null,
      selectedBank: null,
      goodUntilDate: null,
      isDamaged: false,
      accNo: null,
      payoffAmount: null,
      selectedTitleStatus: null,
      warrantyExpirationDate: null,
      localTrims: [],
      localStyles: [],
      titleFiles: [],
      damageFiles: [],
      damageNote: '',
      originalSelectedExteriorColor: '',
      originalSelectedInteriorColor: '',
      selectedExteriorColor: '',
      selectedInteriorColor: '',
      selectedAltExteriorColor: [],
      selectedAltInteriorColor: [],
      selectedTransmissionType: null,
      altInteriorColors: [],
      altExteriorColors: [],
      selectedTags: [],
      availableTags: [],
      notes: '',
      vinDecodeErrors: [],
      selectedMakeModel: {
        makeId: null,
        selectedMake: null,
        modelId: null,
        selectedModel: null,
        productionYear: null,
        style: '',
        trim: '',
        exteriorColor: '',
        interiorColor: '',
        locateType: null,
        altExteriorColors: [],
        altInteriorColors: [],
        tags: [],
        notes: '',
        customMakeModel: '',
        cpoAllowed: false,
        autoCpoStatus: '',
        transmissionType: ''
      },
      isDamageStatusActive: false,
      isAccessoryStatusActive: false,
      showForm: true,
      isMobile: window.innerWidth <= 700,
      showReport: false,
      showCarFaxReport: false,
      versions: [],
      isModelInfoCollapsed: false,
      decodedProductionYear: undefined,
      decodedMake: undefined,
      decodedModel: undefined,
      modelError: false,
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    ...mapVehicleMakeGetters(['allVehicleMakes']),
    ...mapDealerGetters(['getCurrentDealer']),
    ...mapConfigGetters(['definedTypes']),
    allowedSourceTypes: function () {
      let filtered = this.definedTypes.allowedSourceTypes.options.filter((x) => x.id !== 0)
      if (!this.selectedLocateType) {
        return filtered
      } else {
        if (this.selectedLocateType.id === 0) {
          return this.definedTypes.allowedSourceTypes.options
        } else {
          return filtered
        }
      }
    },
    purchasePriceRequired: function () {
      return this.isLocated
    },
    showTitleStatus: function () {
      if (!this.selectedLocateType) {
        return true
      } else {
        if (this.selectedLocateType.id === 0) {
          return !(this.selectedSource && this.selectedSource.id === this.definedTypes.allowedSourceTypes.Factory)
        } else {
          return true
        }
      }
    },
    titleStatusMediaLabel: function () {
      if (this.isTitlePayOff) return 'Payoff Info'
      if (this.isTitleLost) return 'Lost Title Affidavit'
      return 'Title (Front)'
    },
    titleStatusRequired: function () {
      let isFactorySource = this.selectedSource && this.selectedSource.id === this.definedTypes.allowedSourceTypes.Factory
      return !isFactorySource
    },
    isTitleReceived: function () {
      return this.selectedTitleStatus && (this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.Yes || this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.PayOff || this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.Lost)
    },
    isTitlePayOff: function () {
      return this.selectedTitleStatus && this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.PayOff
    },
    isTitleLost: function () {
      return this.selectedTitleStatus && this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.Lost
    },
    isNewLocateType: function () {
      if (!this.selectedLocateType) {
        return false
      } else {
        return this.selectedLocateType.id === 0
      }
    },
    allowedTitleStatusOptions: function () {
      if (!this.selectedLocateType) {
        return this.definedTypes.titleStatusOptions.options
      } else {
        if (this.selectedLocateType.id === 0) {
          if (this.selectedSource && this.selectedSource.id !== this.definedTypes.allowedSourceTypes.Factory) {
            return this.definedTypes.titleStatusOptions.options.filter((x) => x.id !== this.definedTypes.titleStatusOptions.Lost && x.id !== this.definedTypes.titleStatusOptions.PayOff)
          } else {
            return this.definedTypes.titleStatusOptions.options
          }
        } else {
          return this.definedTypes.titleStatusOptions.options
        }
      }
    },
    historicalDamageDataAvailable: function () {
      return this.value && this.value.damageHistory && this.value.damageHistory.length > 0
    },
    historicalAccessoryDataAvailable: function () {
      return this.value && this.value.accessoryStatusHistory && this.value.accessoryStatusHistory.length > 0
    },
    usageRequired: function () {
      return false
    },
    mileageTitle: function () {
      if (this.stockType && this.stockType.name === 'Locate') {
        return this.isLocated === true ? 'Mileage' : 'Minimum Mileage'
      } else {
        return 'Mileage'
      }
    },
    showDealerMakes: function () {
      if (this.isValidForDealerMakes()) {
        return true
      }
      return false
    },
    availableLocateTypes: function () {
      if (this.stockType && this.stockType.name === 'Order') {
        return this.definedTypes.allowedLocateTypes.options.filter(x => x.id === this.definedTypes.allowedLocateTypes.New)
      }
      return this.definedTypes.allowedLocateTypes.options
    },
    availableDealerMakes: function () {
      let sellsMakeIds = this.getCurrentDealer.sellsVehicleMakes.map((y) => y.vehicleMakeId)
      let result = this.allVehicleMakes.filter((x) => sellsMakeIds.includes(x.id))
      return result
    },
    makes: function () {
      return this.allVehicleMakes
    },
    productionYears: function () {
      if (this.selectedMake && this.selectedMake.versions.length > 0) {
        let result = this.$lodash.uniqBy(this.$lodash.map(this.versions, (y) => {
          return y.productionYear
        }))
        return result
      }
      return []
    },
    models: function () {
      var that = this
      if (this.selectedMake && this.selectedMake.versions.length > 0) {
        if (this.selectedYear) {
          let result = this.selectedMake.versions.filter((x) => x.productionYear === +that.selectedYear)
          let year = new Date().getFullYear()
          if (this.selectedYear > year) {
            var currentYearResults = this.selectedMake.versions.filter((x) => x.productionYear === +year)
            currentYearResults.forEach(element => {
              var index = result.findIndex(x => x.name === element.name && x.series === element.series)
              if (index === -1) {
                result.push(element)
              }
            })
            return result
          } else {
            return result
          }
        } else {
          return this.selectedMake.versions
        }
      }
      return []
    },
    styles: function () {
      if (this.selectedModel && this.selectedModel.id > 0) {
        return [...this.selectedModel.styles, ...this.localStyles]
      }
      return [...this.localStyles]
    },
    trims: function () {
      if (this.selectedModel && this.selectedModel.id > 0) {
        return [...this.selectedModel.trims, ...this.localTrims]
      }
      return [...this.localTrims]
    },
    exteriorColors: function () {
      var result = []
      if (this.selectedMake && this.selectedMake.definedColors.length > 0) {
        const exteriorColors = this.$lodash.filter(this.selectedMake.definedColors, (x) => x.colorType === 'Exterior')
        result.push(...this.$lodash.map(exteriorColors, (y) => { return y.primaryColor + ' - ' + y.colorVariation })
        )
      }
      result.push(...this.definedTypes.defaultExteriorColors)
      if (this.originalSelectedExteriorColor && this.originalSelectedExteriorColor !== '' && !result.includes(this.originalSelectedExteriorColor)) {
        result.push(this.originalSelectedExteriorColor)
      }
      return this.$lodash.uniq(result)
    },
    interiorColors: function () {
      var result = []
      if (this.selectedMake && this.selectedMake.definedColors.length > 0) {
        const interiorColors = this.$lodash.filter(this.selectedMake.definedColors, (x) => x.colorType === 'Interior')
        result.push(...this.$lodash.map(interiorColors, (y) => { return y.primaryColor + ' - ' + y.colorVariation })
        )
      }
      result.push(...this.definedTypes.defaultInteriorColors)
      if (this.originalSelectedInteriorColor && this.originalSelectedInteriorColor !== '' && !result.includes(this.originalSelectedInteriorColor)) {
        result.push(this.originalSelectedInteriorColor)
      }
      return this.$lodash.uniq(result)
    },
    colorOverview: function () {
      var result = ''
      if (this.selectedExteriorColor) {
        result = 'Ext. Color (' + this.selectedExteriorColor + ')'
      }
      if (this.selectedInteriorColor) {
        result += '<br/> Int. Color (' + this.selectedInteriorColor + ')'
      }
      return result
    },
    smallColorOverview: function () {
      var result = ''
      if (this.selectedExteriorColor) {
        result = 'Ext. Color (' + this.selectedExteriorColor + ')'
      }
      if (this.selectedInteriorColor) {
        result += ' , Int. Color (' + this.selectedInteriorColor + ')'
      }
      return result
    },
    overview: function () {
      var result = ''
      if (this.selectedYear) {
        result = this.selectedYear
      }
      if (this.selectedMake) {
        result += ' ' + this.selectedMake.name
      }
      if (this.selectedModel) {
        result += ' ' + this.selectedModel.name
      }
      if (this.selectedTrim) {
        result += ' ' + this.selectedTrim
      }
      if (this.selectedStyle) {
        result += ' ' + this.selectedStyle
      }
      if (result === '') {
        result = 'Click to configure vehicle'
      }
      return result
    },
    allowedTransmissionTypes: function () {
      return this.definedTypes.transmissionTypes
    },
    vehicleAge: function () {
      if (!this.selectedYear || this.selectedYear === 0) {
        return -1
      } else {
        return moment().year() - (+this.selectedYear)
      }
    },
    askingPriceLabel: function () {
      if (this.showAlternateColors === true) {
        return 'Approx. Sale Price/MSRP'
      }
      return 'Approx. Sale Price/MSRP'
    },
    carFaxReportUrl: function () {
      let result = 'https://www.carfaxonline.com/cfm/Display_Dealer_Report.cfm?partner=VAU_0&UID=' + this.getCurrentDealer.carFaxUserName + '&vin=' + this.selectedVinNo
      return result
    },
    autoCheckEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.autoCheckEnabledStatus
      }
      return false
    },
    damageTitle: function () {
      if (this.autoCheckEnabled) {
        return 'Known damage or bad Carfax/Autocheck'
      }
      return 'Known damage or bad Carfax'
    }
  },
  methods: {
    onFillProductionYear: function () {
      this.productionYears.push(this.decodedProductionYear)
      this.selectedYear = this.decodedProductionYear
    },
    onFillModel: function () {
      this.models.push(this.decodedModel)
      this.selectedModel = this.decodedModel
    },
    onStartOver: function () {
      this.$emit('restore')
      this.isConfiguring = false
    },
    onValidate: function () {
      if (this.dataVvScope && this.dataVvScope !== '') {
        this.validateBeforeSubmitScoped(this.dataVvScope)
      } else {
        this.validateBeforeSubmit()
      }
    },
    onVinDecodeComplete: function (selectedMakeModel) {
      this.vinDecodeErrors = []
      this.initFromValue(selectedMakeModel)
    },
    onValidationError: function (errors) {
      this.vinDecodeErrors = errors
    },
    onNoteChanged: function () {
      this.selectedMakeModel.notes = this.notes
    },
    packTitleFiles: function () {
      if (this.titleFiles.length > 0) {
        var titleFiles = this.titleFiles.map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: this.isTitlePayOff ? 'PayOffInfo' : (this.isTitleLost ? 'LostTitleAffidavit' : 'TitleFront')
          }
        })
        return titleFiles
      }
      return []
    },
    packDamageFiles: function () {
      if (this.damageFiles.length > 0) {
        var damageFiles = this.damageFiles.map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'Damage'
          }
        })
        return damageFiles
      }
      return []
    },
    getIconByLastDamageStatus: function () {
      let classToAttach = {}
      if (this.value.damageHistory.length > 0) {
        let lastDamageStatus = this.value.damageHistory[0]
        if (lastDamageStatus.isDamaged === true) {
          classToAttach['fa-exclamation-triangle'] = true
          classToAttach['has-text-danger'] = true
        } else {
          classToAttach['fa-check-circle'] = true
          classToAttach['has-text-success'] = true
        }
      }
      return classToAttach
    },
    getLastDamageDescription: function () {
      var result = 'No damage recorded'
      if (this.value.damageHistory.length > 0) {
        let lastDamageStatus = this.value.damageHistory[0]
        result = lastDamageStatus.notes !== null ? lastDamageStatus.notes : 'No damage recorded'
      }
      return result
    },
    getIconByLastAccessoryStatus: function () {
      let classToAttach = {}
      if (this.value.accessoryStatusHistory.length > 0) {
        let lastAccessoryStatus = this.value.accessoryStatusHistory[0]
        if (lastAccessoryStatus.isGood === true) {
          classToAttach['fa-check-circle'] = true
          classToAttach['has-text-success'] = true
        } else {
          classToAttach['fa-exclamation-triangle'] = true
          classToAttach['has-text-danger'] = true
        }
      }
      return classToAttach
    },
    getLastAccessoryStatusDescription: function () {
      var result = 'All accessories provided'
      if (this.value.accessoryStatusHistory.length > 0) {
        let lastAccessoryStatus = this.value.accessoryStatusHistory[0]
        result = lastAccessoryStatus.notes !== null ? lastAccessoryStatus.notes : result
      }
      return result
    },
    onShowHistory: function () {
      alert('Coming shortly the ability to see historical damage, accesory status and in the future service requests...')
    },
    isValidForDealerMakes: function () {
      if (this.getCurrentDealer.sellsVehicleMakes.length === 0) {
        return false
      }
      if (this.isDealerMake()) {
        return true
      }
      return false
    },
    isDealerMake: function () {
      if (!this.selectedMake) {
        return false
      }
      let dealerMake = this.getCurrentDealer.sellsVehicleMakes.find((x) => x.vehicleMakeId === this.selectedMake.id)
      return dealerMake !== undefined
    },
    titleCase: function (str) {
      return str.toLowerCase().split(' ').map(function (word) {
        return word.replace(word[0], word[0].toUpperCase())
      }).join(' ')
    },
    focusElement: function (elementName) {
      if (this.$refs && this.$refs[elementName] && this.$refs[elementName].$el) {
        let that = this
        that.$nextTick(() => {
          that.$refs[elementName].$el.focus()
        })
      }
    },
    onSave: function () {
      this.modelError = false
      if (this.selectedMakeModel && this.selectedMakeModel.selectedModel && this.selectedMakeModel.selectedModel.name.indexOf('_DELETED') > 0) {
        this.modelError = true
        return
      }
      this.isConfiguring = false
      if (!this.useModal) {
        this.showForm = false
        eventBus.$emit('parent-make-model-onSave')
      }
      eventBus.$emit('parent-make-model-onSave-book-deal-capture')
      this.onChangesSaved()
    },
    updateCustomMakeModel: function () {
      if (this.selectedYear) {
        this.selectedMakeModel.customMakeModel = this.selectedYear
      }
      if (this.selectedMake) {
        this.selectedMakeModel.customMakeModel += ' ' + this.selectedMake.name
      }
      if (this.selectedModel) {
        this.selectedMakeModel.customMakeModel += ' ' + this.selectedModel.name
      }
      if (this.selectedTrim && this.selectedTrim.toLowerCase() !== 'base') {
        this.selectedMakeModel.customMakeModel += ' ' + this.selectedTrim
      }
      if (this.selectedStyle && this.selectedStyle.toLowerCase() !== 'base') {
        this.selectedMakeModel.customMakeModel += ' ' + this.selectedStyle
      }
    },
    makeAdded: function (data) {
      data = this.titleCase(data)
      let newMake = {id: -1, name: data, versions: [], definedColors: []}
      this.selectedMake = newMake
      this.selectedYear = null
      this.selectedModel = null
      this.selectedTrim = null
      this.selectedStyle = null
      this.selectedExteriorColor = ''
      this.selectedInteriorColor = ''
      this.selectedMakeModel.makeId = -1
      this.selectedMakeModel.selectedMake = null
      this.selectedMakeModel.customMakeName = data
      if (!this.makes.includes(newMake)) {
        this.makes.push(newMake)
      }
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('year')
    },
    yearAdded: function (data) {
      let newProductionYear = data
      this.selectedYear = newProductionYear
      this.selectedModel = null
      this.selectedTrim = null
      this.selectedStyle = null
      this.selectedMakeModel.productionYear = +data
      if (this.productionYears.find((x) => x === data)) {
        this.productionYears.push(newProductionYear)
      }
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('model')
    },
    modelAdded: function (data) {
      data = this.titleCase(data)
      let newModel = {id: -1, name: data, style: ''}
      this.selectedModel = newModel
      this.selectedTrim = null
      this.selectedStyle = null
      this.selectedMakeModel.modelId = -1
      this.selectedMakeModel.selectedModel = null
      this.selectedMakeModel.customModelName = data
      if (!this.models.includes(newModel)) {
        this.models.push(newModel)
      }
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('trim')
    },
    addToUniqueList: function (valueToAdd, listToAmmend) {
      if (!listToAmmend.includes(valueToAdd)) {
        listToAmmend.push(valueToAdd)
      }
    },
    trimAdded: function (data) {
      data = this.titleCase(data)
      this.selectedTrim = data
      this.addToUniqueList(this.selectedTrim, this.localTrims)
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('style')
    },
    styleAdded: function (data) {
      data = this.titleCase(data)
      this.selectedStyle = data
      this.addToUniqueList(this.selectedStyle, this.localStyles)
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('extColor')
    },
    onExteriorColorAdded: function (data) {
      data = this.titleCase(data)
      this.selectedExteriorColor = data
      this.addToUniqueList(this.selectedExteriorColor, this.exteriorColors)
      this.exteriorColors.push(data)
      this.fireOnChange()
      this.focusElement('intColor')
    },
    onInteriorColorAdded: function (data) {
      data = this.titleCase(data)
      this.selectedInteriorColor = data
      this.addToUniqueList(this.selectedInteriorColor, this.interiorColors)
      this.fireOnChange()
      if (this.showAlternateColors) {
        this.focusElement('altExtColor')
      } else {
        this.focusElement('done')
      }
    },
    onAltExteriorColorAdded: function (data) {
      data = this.titleCase(data)
      this.addToUniqueList(data, this.selectedAltExteriorColor)
      this.addToUniqueList(data, this.altExteriorColors)
      this.fireOnChange()
      this.$nextTick(() => {
        this.focusElement('altExtColor')
      })
    },
    onAltInteriorColorAdded: function (data) {
      data = this.titleCase(data)
      this.addToUniqueList(data, this.selectedAltInteriorColor)
      this.addToUniqueList(data, this.altInteriorColors)
      this.fireOnChange()
      this.$nextTick(() => {
        this.focusElement('altIntColor')
      })
    },
    onTagAdded: function (data) {
      data = this.titleCase(data)
      this.addToUniqueList(data, this.selectedTags)
      this.addToUniqueList(data, this.availableTags)
      this.fireOnChange()
      this.$nextTick(() => {
        this.focusElement('tags')
      })
    },
    onSelectedLocateType: function (newValue) {
      if (newValue) {
        this.selectedLocateType = newValue
        if (this.selectedLocateType.name === 'New') {
          this.selectedSource = this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Factory)
        } else {
          this.selectedSource = this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Wholesaler)
        }
        this.fireOnChange()
        if (this.showCostSelector) {
          this.focusElement('purchaseCost')
        }
      }
    },
    onSelectedMake: function (newValue) {
      this.selectedYear = null
      this.selectedModel = null
      this.selectedTrim = null
      this.selectedStyle = null
      this.selectedExteriorColor = ''
      this.selectedInteriorColor = ''
      this.selectedMakeModel.makeId = newValue ? newValue.id : null
      this.selectedMakeModel.selectedMake = newValue
      this.selectedMakeModel.customMakeModel = ''
      this.localTrims = []
      this.localStyles = []
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('year')
    },
    onSelectedYear: function (newValue) {
      this.selectedModel = null
      this.selectedTrim = null
      this.selectedStyle = null
      this.selectedMakeModel.productionYear = newValue
      this.localTrims = []
      this.localStyles = []
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('model')
    },
    onSelectedModel: function (newValue) {
      this.modelError = newValue && newValue.name.indexOf('_DELETED') > 0
      this.selectedTrim = null
      this.selectedStyle = null
      this.localTrims = []
      this.localStyles = []
      this.selectedMakeModel.modelId = newValue ? newValue.id : null
      this.selectedMakeModel.selectedModel = newValue

      if (this.selectedMakeModel) {
        this.selectedTransmissionType = this.definedTypes.transmissionTypes.find((x) => x.altName === this.selectedMakeModel.selectedModel.defaultTransmissionType)
        // there must always be a make when selecting a model
        if (this.selectedMakeModel.makeId === -1) {
          this.selectedMakeModel.makeId = this.selectedMake ? this.selectedMake.id : null
          this.selectedMakeModel.selectedMake = this.selectedMake
        }
      }
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('trim')
    },
    onSelectedTrim: function (newValue) {
      this.selectedTrim = newValue
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('style')
    },
    onSelectedStyle: function (newValue) {
      this.selectedStyle = newValue
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('extColor')
    },
    onSelectedExteriorColor: function (newValue) {
      this.selectedExteriorColor = newValue
      this.updateCustomMakeModel()
      this.fireOnChange()
      this.focusElement('intColor')
    },
    onSelectedInteriorColor: function (newValue) {
      this.selectedInteriorColor = newValue
      this.updateCustomMakeModel()
      this.fireOnChange()
      if (this.showAlternateColors) {
        this.focusElement('altExtColor')
      } else {
        this.focusElement('done')
      }
    },
    onSelectedTransmissionType: function (newValue) {
      this.selectedTransmissionType = newValue
      this.fireOnChange()
      this.focusElement('done')
    },
    setPropertiesFromSelectedMakeModel: function () {
      var foundMake = this.makes.find((x) => x.id === this.selectedMakeModel.makeId)
      var foundProductionYearModel = false
      if (foundMake) {
        console.log('Found make...')
        this.selectedMake = foundMake
        if (foundMake.versions && foundMake.versions.length > 0) {
          foundProductionYearModel = foundMake.versions.find((x) => x.id === this.selectedMakeModel.modelId)
          if (foundProductionYearModel) {
            console.log('Found production year...')
            this.selectedYear = foundProductionYearModel.productionYear
            this.selectedModel = foundProductionYearModel
          }
        }
      } else {
        console.log('Deriving customer make model...')
        if (this.selectedMakeModel.customMakeName) {
          this.selectedMake = {id: -1, name: this.selectedMakeModel.customMakeName, versions: [], definedColors: []}
        }
      }

      if (!foundProductionYearModel) {
        console.log('Deriving custom production year...')
        if (this.selectedMakeModel.productionYear) {
          let selectedYear = this.selectedMakeModel.productionYear
          if (!this.productionYears.includes(selectedYear)) {
            this.selectedYear = selectedYear
            this.decodedProductionYear = selectedYear
            this.productionYears.push(selectedYear)
          }
        } else {
          this.selectedYear = null
        }

        if (this.selectedMakeModel.customModelName) {
          console.log('Deriving custom model...')
          let selectedModel = {id: -1, name: this.selectedMakeModel.customModelName, productionYear: this.selectedMakeModel.productionYear, trim: '', style: ''}
          if (!this.models.includes(selectedModel)) {
            this.selectedModel = selectedModel
            this.decodedModel = selectedModel
            this.models.push(selectedModel)
          }
        } else {
          this.selectedModel = null
        }
      }

      if (this.selectedMakeModel.trim && this.selectedMakeModel.trim.trim() !== '') {
        this.selectedTrim = this.selectedMakeModel.trim
        if (!this.trims.includes(this.selectedTrim)) {
          this.localTrims.push(this.selectedTrim)
        }
      } else {
        this.selectedTrim = null
      }

      if (this.selectedMakeModel.style && this.selectedMakeModel.style.trim() !== '') {
        this.selectedStyle = this.selectedMakeModel.style
        if (!this.styles.includes(this.selectedStyle)) {
          this.localStyles.push(this.selectedStyle)
        }
      } else {
        this.selectedStyle = null
      }

      this.originalSelectedInteriorColor = this.selectedMakeModel.interiorColor
      this.originalSelectedExteriorColor = this.selectedMakeModel.exteriorColor

      if (this.selectedMakeModel.interiorColor && this.selectedMakeModel.interiorColor.trim() !== '') {
        this.selectedInteriorColor = this.selectedMakeModel.interiorColor
        if (!this.interiorColors.includes(this.selectedInteriorColor)) {
          this.interiorColors.push(this.selectedInteriorColor)
        }
      } else {
        this.selectedInteriorColor = null
      }

      if (this.selectedMakeModel.exteriorColor && this.selectedMakeModel.exteriorColor.trim() !== '') {
        this.selectedExteriorColor = this.selectedMakeModel.exteriorColor
        if (!this.exteriorColors.includes(this.selectedExteriorColor)) {
          this.exteriorColors.push(this.selectedExteriorColor)
        }
      } else {
        this.selectedExteriorColor = null
      }

      if (this.selectedMakeModel.lookupResult && this.selectedMakeModel.lookupResult.valid === true) {
        let availableColors = this.selectedMakeModel.lookupResult.availableColors
        for (var availableColor of availableColors) {
          if (!this.exteriorColors.includes(availableColor)) {
            this.exteriorColors.push(availableColor)
          }
        }
        let availableTrims = this.selectedMakeModel.lookupResult.availableTrims
        for (var availableTrim of availableTrims) {
          if (!this.trims.includes(availableTrim)) {
            this.localTrims.push(availableTrim)
          }
        }
        let availableStyles = this.selectedMakeModel.lookupResult.availableStyles
        for (var availableStyle of availableStyles) {
          if (!this.styles.includes(availableStyle)) {
            this.localStyles.push(availableStyle)
          }
        }
      }

      this.modelError = this.selectedMakeModel && this.selectedMakeModel.selectedModel && this.selectedMakeModel.selectedModel.name.indexOf('_DELETED') > 0

      this.selectedLocateType = this.selectedMakeModel.locateType || this.selectedLocateType

      this.purchaseCost = this.selectedMakeModel.purchaseCost || this.purchaseCost

      this.selectedTitleStatus = this.selectedMakeModel.titleStatus || this.selectedTitleStatus

      this.warrantyExpirationDate = this.selectedMakeModel.warrantyExpirationDate || this.warrantyExpirationDate

      this.selectedBank = this.selectedMakeModel.selectedBank || this.selectedBank

      this.accNo = this.selectedMakeModel.accountNo || this.accNo

      this.payoffAmount = this.selectedMakeModel.payoffAmount || this.payoffAmount

      this.goodUntilDate = this.selectedMakeModel.goodUntilDate || this.goodUntilDate

      this.isDamaged = this.selectedMakeModel.isDamaged || this.isDamaged

      this.selectedVinNo = this.selectedMakeModel.vinNo || this.selectedVinNo

      this.selectedSource = this.selectedMakeModel.source || this.selectedSource

      if (this.selectedMakeModel.wholesaler && this.selectedMakeModel.wholesaler.id === null) this.selectedMakeModel.wholesaler = null
      this.selectedWholeSaler = this.selectedMakeModel.wholesaler || this.selectedWholeSaler

      this.selectedCustomer = this.selectedMakeModel.customer || this.selectedCustomer

      this.titleFiles = this.selectedMakeModel.titleFiles || this.packTitleFiles()

      this.damageFiles = this.selectedMakeModel.damageFiles || this.packDamageFiles()

      this.damageNote = this.selectedMakeModel.damageNote || this.damageNote

      this.usage = this.selectedMakeModel.usage || this.usage

      this.askingPrice = this.selectedMakeModel.askingPrice || this.askingPrice

      this.altExteriorColors = this.selectedMakeModel.altExteriorColors
      this.selectedAltExteriorColor = this.selectedMakeModel.altExteriorColors

      this.altInteriorColors = this.selectedMakeModel.altInteriorColors
      this.selectedAltInteriorColor = this.selectedMakeModel.altInteriorColors

      this.availableTags = this.selectedMakeModel.tags
      this.selectedTags = this.selectedMakeModel.tags

      this.notes = this.selectedMakeModel.notes
      this.selectedTransmissionType = this.definedTypes.transmissionTypes.find((x) => x.altName === this.selectedMakeModel.transmissionType)

      this.selectedMakeModel.locateType = this.selectedLocateType
      this.selectedMakeModel.titleStatus = this.selectedTitleStatus
      this.selectedMakeModel.warrantyExpirationDate = this.warrantyExpirationDate
      this.selectedMakeModel.selectedBank = this.selectedBank
      this.selectedMakeModel.accountNo = this.accNo
      this.selectedMakeModel.payoffAmount = this.payoffAmount
      this.selectedMakeModel.goodUntilDate = this.goodUntilDate
      this.selectedMakeModel.isDamaged = this.isDamaged
      this.selectedMakeModel.titleFiles = this.packTitleFiles()
      this.selectedMakeModel.damageFiles = this.packDamageFiles()
      this.selectedMakeModel.damageNote = this.damageNote
      this.selectedMakeModel.vinNo = this.selectedVinNo
      this.selectedMakeModel.source = this.selectedSource
      this.selectedMakeModel.wholesaler = this.selectedWholeSaler
      this.selectedMakeModel.customer = this.selectedCustomer
      this.selectedMakeModel.purchaseCost = this.purchaseCost
      this.selectedMakeModel.usage = this.usage
      this.selectedMakeModel.askingPrice = this.askingPrice
    },
    setCpoStatus: function () {
      this.selectedMakeModel.cpoAllowed = true
      this.selectedMakeModel.autoCpoStatus = 'Ineligible'
      this.selectedMakeModel.vehicleAge = null
      this.selectedMakeModel.isDealerMake = this.isDealerMake()
      this.selectedMakeModel.defaultMakeWarrantyPeriod = this.selectedMake && this.selectedMake.defaultWarrantyPeriod ? this.selectedMake.defaultWarrantyPeriod : null
      this.selectedMakeModel.defaultModelWarrantyPeriod = this.selectedModel && this.selectedModel.defaultWarrantyPeriod ? this.selectedModel.defaultWarrantyPeriod : null
      if (this.selectedMakeModel && this.isDealerMake()) {
        var age = this.vehicleAge
        this.selectedMakeModel.vehicleAge = age
        if (age >= 0 && age <= this.getCurrentDealer.autoCpoAgeLimit) {
          this.selectedMakeModel.cpoAllowed = true
          this.selectedMakeModel.autoCpoStatus = 'Cpo'
        } else if (age > this.getCurrentDealer.autoCpoAgeLimit && age <= this.getCurrentDealer.cpoQualifyingAge) {
          this.selectedMakeModel.cpoAllowed = true
          this.selectedMakeModel.autoCpoStatus = 'Eligible'
        }
      }
    },
    fireOnChange: function () {
      this.selectedMakeModel.locateType = this.selectedLocateType
      this.selectedMakeModel.titleStatus = this.selectedTitleStatus
      this.selectedMakeModel.warrantyExpirationDate = this.warrantyExpirationDate
      this.selectedMakeModel.selectedBank = this.selectedBank
      this.selectedMakeModel.accountNo = this.accNo
      this.selectedMakeModel.payoffAmount = this.payoffAmount
      this.selectedMakeModel.goodUntilDate = this.goodUntilDate
      this.selectedMakeModel.isDamaged = this.isDamaged
      this.selectedMakeModel.titleFiles = this.packTitleFiles()
      this.selectedMakeModel.damageFiles = this.packDamageFiles()
      this.selectedMakeModel.damageNote = this.damageNote
      this.selectedMakeModel.exteriorColor = this.selectedExteriorColor
      this.selectedMakeModel.interiorColor = this.selectedInteriorColor
      this.selectedMakeModel.altExteriorColors = this.selectedAltExteriorColor
      this.selectedMakeModel.altInteriorColors = this.selectedAltInteriorColor
      this.selectedMakeModel.trim = this.selectedTrim
      this.selectedMakeModel.style = this.selectedStyle
      this.selectedMakeModel.tags = this.selectedTags
      this.selectedMakeModel.overview = this.overview
      this.selectedMakeModel.completeOverview = this.overview + ' ' + this.smallColorOverview
      this.selectedMakeModel.isLocated = this.isLocated
      this.selectedMakeModel.transmissionType = this.selectedTransmissionType ? this.selectedTransmissionType.altName : null

      this.setCpoStatus()
      this.$emit('input', this.selectedMakeModel)
      this.$markDirty()
    },
    onPreviewMakeModelListener: function () {
      this.isConfiguring = true
      /// eventBus.$emit('hide-located-modal')
    },
    initFromValue: function (newValue) {
      this.selectedMakeModel = newValue
      this.isModelInfoCollapsed = this.collapseMakeModelInfo
      this.setPropertiesFromSelectedMakeModel()
    },
    onSubmitForm () {
      this.$refs.done.click()
    },
    onShowAutoCheckReport: function () {
      this.useModal = false
      this.showReport = true
    },
    onHideAutoCheckReport: function () {
      this.showReport = false
      this.useModal = true
    },
    onShowCarFaxReport: function () {
      this.useModal = false
      this.showCarFaxReport = true
    },
    onHideCarFaxReport: function () {
      this.showCarFaxReport = false
      this.useModal = true
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
      eventBus.$emit('file-uploads-started')
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
        eventBus.$emit('file-uploads-completed')
      }
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      if (!newValue) {
        return
      }
      this.initFromValue(newValue)
    },
    isConfiguring: function (newValue, oldValue) {
      if (newValue === true) {
      }
    },
    purchaseCost: function (newValue, oldValue) {
      this.selectedMakeModel.purchaseCost = newValue
      this.fireOnChange()
    },
    usage: function (newValue, oldValue) {
      this.selectedMakeModel.usage = newValue
      this.fireOnChange()
    },
    askingPrice: function (newValue, oldValue) {
      this.selectedMakeModel.askingPrice = newValue
      this.fireOnChange()
    },
    selectedVinNo: function (newValue, oldValue) {
      this.selectedMakeModel.vinNo = newValue
      this.fireOnChange()
    },
    selectedTitleStatus: function (newValue, oldValue) {
      this.selectedMakeModel.titleStatus = newValue
      this.fireOnChange()
    },
    warrantyExpirationDate: function (newValue, oldValue) {
      this.selectedMakeModel.warrantyExpirationDate = newValue
      this.fireOnChange()
    },
    selectedBank: function (newValue, oldValue) {
      this.selectedMakeModel.selectedBank = newValue
      this.fireOnChange()
    },
    accNo: function (newValue, oldValue) {
      this.selectedMakeModel.accountNo = newValue
      this.fireOnChange()
    },
    damageNote: function (newValue, oldValue) {
      this.selectedMakeModel.damageNote = newValue
      this.fireOnChange()
    },
    payoffAmount: function (newValue, oldValue) {
      this.selectedMakeModel.payoffAmount = newValue
      this.fireOnChange()
    },
    isDamaged: function (newValue, oldValue) {
      this.selectedMakeModel.isDamaged = newValue
      this.fireOnChange()
    },
    goodUntilDate: function (newValue, oldValue) {
      this.selectedMakeModel.goodUntilDate = newValue
      this.fireOnChange()
    },
    selectedSource: function (newValue, oldValue) {
      this.selectedMakeModel.source = newValue
      this.fireOnChange()
    },
    selectedWholeSaler: function (newValue, oldValue) {
      this.selectedMakeModel.wholesaler = newValue
      this.fireOnChange()
    },
    selectedCustomer: function (newValue, oldValue) {
      this.selectedMakeModel.customer = newValue
      this.fireOnChange()
    },
    selectedTags: function (newValue, oldValue) {
      this.fireOnChange()
      this.$nextTick(() => {
        this.focusElement('tags')
      })
    },
    selectedAltExteriorColor: function (newValue, oldValue) {
      this.fireOnChange()
      this.$nextTick(() => {
        this.focusElement('altExtColor')
      })
    },
    selectedAltInteriorColor: function (newValue, oldValue) {
      this.fireOnChange()
      this.$nextTick(() => {
        this.focusElement('altIntColor')
      })
    },
    selectedMake: function (newValue, oldValue) {
      if (newValue && newValue.versions && newValue.versions.length > 0) {
        this.versions = this.$lodash.reverse(newValue.versions)
      } else {
        this.versions = []
      }
    }
  },
  created: function () {
    if (this.useModal) {

    } else {

    }

    eventBus.$on('preview-make-model', this.onPreviewMakeModelListener)
    eventBus.$on('submit-make-model-form', this.onSubmitForm)
  },
  destroyed: function () {
    eventBus.$off('preview-make-model', this.onPreviewMakeModelListener)
    eventBus.$off('submit-make-model-form', this.onSubmitForm)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  html body .modal.is-active .modal-card-body {
    padding: 20px 10px;
  }

  .status-icon {
    margin-left:15px;
  }

  .selected-make-model {
    width: 250px;
    white-space: normal;
    min-height: 3em;
    height: auto;
  }

  .column {
    padding: 0.5rem;
  }

  .modal-card-body {
    position:relative;
    max-height: calc(100vh - 220px);
    overflow-y: auto;
    background: white;
  }

  .model-error-container {
    color:red;
    font-weight: bold;
    font-size: 12px;
    margin-bottom: 10px;
  }
</style>

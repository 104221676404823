<template>
  <div>
    <div class="field">
      <label class="label" v-if="showTitle">{{title}}<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
      <div class="control is-fullwidth">
        <multiselect
        @select="$markDirty()"
        :showLabels="false"
        :disabled="isDisabled"
        name="dealer"
        data-vv-validate-on="input|close"
        data-vv-as="dealer"
        v-validate="'required'"
        :class="{'is-danger': errors.has('dealer') }"
        v-model="selectedDealer"
        :options="filteredDealers"
        label="name"
        :allowEmpty="false"
        trackBy="id"
        :hideSelected="true"></multiselect>
      </div>
      <span v-show="errors.has('dealer')" class="help is-danger"><i v-show="errors.has('dealer')" class="fas fa-exclamation-triangle"></i> {{ errors.first('dealer') }}</span>
    </div>

  <portal to="global-modal-portal" v-if="cannotSwitchDealerActive">
    <b-modal :active.sync="cannotSwitchDealerActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Warning</p>
        </header>
        <section class="modal-card-body">
          You are on a screen that has an item selected that belongs to the current dealer. Please save or cancel before switching the dealer.
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="onCannotSwitchDealer()" type="button">Ok</button>
        </footer>
      </div>
    </b-modal>
  </portal>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const mapDealerGetters = createNamespacedHelpers('dealer').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const { mapState } = createNamespacedHelpers('user')

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'DealerSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Dealer'
    },
    showTitle: {
      type: Boolean,
      default: true
    },
    updateWorkingDealer: {
      type: Boolean,
      default: false
    },
    isSwitcher: {
      type: Boolean,
      default: false
    },
    showAll: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedDealer: null,
      errorMessage: '',
      alertShown: false,
      tempSelectedDealer: null,
      cannotSwitchDealerActive: false
    }
  },
  computed: {
    ...mapDealerGetters(['allDealers', 'getCurrentDealer']),
    ...mapDealerState(['currentDealer']),
    ...mapState(['isAuthenticated', 'currentUser']),
    isDisabled () {
      if (this.isSwitcher) {
        return false
      }
      return !this.isAdmin
    },
    isAdmin () {
      return this.$store.getters['user/isInRole']('Admin Role')
    },
    filteredDealers () {
      if (this.permittedDealers[0] === -1 || this.showAll) {
        return this.allDealers
      } else {
        return this.allDealers.filter((x) => this.permittedDealers.includes(x.id))
      }
    },
    permittedDealers () {
      return (this.currentUser.permittedDealers) ? this.currentUser.permittedDealers : [-1]
    }
  },
  methods: {
    onCannotSwitchDealer: function () {
      this.cannotSwitchDealerActive = false
      let tempSelectedDealer2 = this.tempSelectedDealer
      this.selectedDealer = tempSelectedDealer2
    }
  },
  watch: {
    selectedDealer: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.id === oldValue.id) return

      this.$emit('input', newValue)
      if (this.updateWorkingDealer) {
        let canSwitchGlobalDealer = true
        if (this.$router && this.$router.currentRoute && this.$router.currentRoute.fullPath) {
          var quickView = document.getElementById('qviewApp')
          if (quickView.classList.contains('is-active')) {
            quickView.classList.remove('is-active')
          }
          canSwitchGlobalDealer = !(this.$router.currentRoute.fullPath.toLowerCase().includes('edit') || quickView.classList.contains('is-active'))
        }
        if (canSwitchGlobalDealer) {
          this.$store.dispatch('dealer/updateCurrentDealer', newValue).then((x) => {
            this.onComplete()
          }).catch((error) => {
            this.errorMessage = error.message
          })
        } else {
          if (oldValue && (this.tempSelectedDealer === null || this.tempSelectedDealer.id !== newValue.id)) {
            this.cannotSwitchDealerActive = true
            this.tempSelectedDealer = oldValue
          } else {
            this.tempSelectedDealer = null
          }
        }
      }
    },
    currentDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
    },
    value: function (newValue, oldValue) {
      if (newValue === null) {
        this.selectedDealer = this.$store.state.dealer.currentDealer
      } else {
        this.selectedDealer = newValue
      }
    }
  },
  mounted: function () {
    this.selectedDealer = this.value ? this.value : this.$store.state.dealer.currentDealer
  }
}

</script>

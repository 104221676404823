<template>
  <section>
    <form :data-vv-scope="scope" @submit.prevent="validateBeforeSubmit" @change="clearErrors">
      <div class="columns">
        <div class="column is-12">
          <div class="field">
            <label class="label">Description<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="control">
              <textarea :data-vv-scope="scope" name="description" data-vv-as="description" v-validate="'required'" style="width: 500px; height: 300px;" :class="{'textarea': true, 'is-danger': errors.has(validationName + 'description')}" v-model="currentServiceRequestLine.description"></textarea>
            </div>
            <span v-show="errors.has(validationName + 'description')" class="help is-danger">
              <i v-show="errors.has(validationName + 'description')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'description') }}
            </span>
          </div>
        </div>
      </div>
      <portal :to="useFooterPortal">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-primary" type="button" @click="validateBeforeSubmit">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click.prevent="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>
  </section>
</template>

<script>
import utilitiesMixin from '@/mixins/generic/utilities'

export default {
  name: 'DealServiceRequest',
  mixins: [utilitiesMixin],
  props: {
    useFooterPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    forServiceRequest: {
      type: Object,
      default: function () {
        return {
          id: 0,
          serviceRequestLines: []
        }
      }
    },
    currentServiceRequestId: {
      default: function () {
        return '0'
      }
    },
    scope: {
      type: String,
      default: null
    }
  },
  components: {
  },
  data () {
    return {
      currentServiceRequestLine: {
        id: this.$uuid.v4(),
        description: '',
        status: 'Active',
        isActive: true
      },
      instanceName: this.$uuid.v4()
    }
  },
  computed: {
    validationName: function () {
      return (this.scope) ? this.scope + '.' + this.instanceName + '.' : this.instanceName + '.'
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll(this.scope).then((result) => {
        if (result) {
          this.onSave()
        } else {
          this.$goToValidationError(this.$validator.errors.items[0].id)
        }
      })
    },
    clearErrors: function () {
      this.errors.clear()
    },
    onSave: function () {
      this.$emit('save-edit-service-request', this.currentServiceRequestLine)
    },
    onCancel: function () {
      this.$emit('cancel-service-request', {})
    }
  },
  mounted: function () {
    if (this.currentServiceRequestId !== '0') {
      var that = this
      let found = this.forServiceRequest.serviceRequestLines.find(function (x) {
        return x.id === that.currentServiceRequestId
      })

      if (found) {
        this.currentServiceRequestLine = Object.assign({}, found)
      }
    }
  }
}
</script>

<style scoped>
  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-card-body {
    overflow: auto;
    height: calc(50vh - 10px);
  }
</style>

export default {
  all: [
    {
      id: 1,
      status: 'Active',
      subscriberId: 1,
      invoiceNumber: '654123',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      closeDate: '01/01/2000',
      openDate: '01/01/2000',
      hasCustomerPay: false,
      partsDealer: {
        id: 1,
        subscriberId: 1,
        dealerCode: 'FFL-S',
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1
      },
      partsAccountingAccount: {
        id: 1,
        subscriberId: 1,
        accountingCode: 'FFL-S',
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1
      },
      partsVehicle: {
        id: 1,
        partsId: 1,
        mileage: 5269.23,
        vehicleId: 'H1228991',
        vin: 'ZAM56RRA0H1228991',
        color: 'White',
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1,
        make: 'Volkswagen',
        model: 'Polo',
        productionYear: 2021,
        mileageLastVisit: 1000.00,
        mileageOut: 5300.00
      },
      partsCustomer: {
        id: 1,
        subscriberId: 1,
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1,
        partsId: 1,
        customerNo: '64345',
        address: '145 ROUTE 17 SOUTH',
        cityStateZip: 'UPPER SADDLE RIVER, NJ 07458',
        contactEmailAddress: 'test@hostedapp.co.za',
        contactPhoneNumber: '123456789',
        name1: 'Test User',
        name2: 'Test Organization'
      },
      partsReportDate: {
        id: 1,
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1,
        partsId: 1,
        partsCost: 2.5,
        partsCostCustomerPay: 2.5,
        partsCostInternal: 2.5,
        partsCostWarranty: 2.5,
        partsSale: 2.5,
        partsSaleCustomerPay: 2.5,
        partsSaleInternal: 2.5,
        partsSaleWarranty: 2.5
      }
    }
  ]
}

import { render, staticRenderFns } from "./TradeAllowanceApproveCapture.vue?vue&type=template&id=1b2ba636&scoped=true"
import script from "./TradeAllowanceApproveCapture.vue?vue&type=script&lang=js"
export * from "./TradeAllowanceApproveCapture.vue?vue&type=script&lang=js"
import style0 from "./TradeAllowanceApproveCapture.vue?vue&type=style&index=0&id=1b2ba636&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b2ba636",
  null
  
)

export default component.exports
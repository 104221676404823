export default {
  all: [
    {
      modifiedBy: 1,
      createdBy: 1,
      modifiedAt: '01/01/2000',
      createdAt: '01/01/2000',
      issuer: 'Payee Name',
      type: 0,
      paymentStatus: 0,
      customerId: 1,
      customerName: 'Thomas Monk',
      amount: 350.00,
      subscriberId: 1,
      dealerId: 1,
      dealerName: 'Ferrari of Ft. Lauderdale',
      subscriberName: 'Experience Auto Group',
      id: 1,
      notes: []
    },
    {
      modifiedBy: 1,
      createdBy: 1,
      modifiedAt: '01/01/2000',
      createdAt: '01/01/2000',
      issuer: 'Payee Name',
      type: 0,
      paymentStatus: 0,
      customerId: 1,
      customerName: 'Thomas Monk',
      amount: 500.00,
      subscriberId: 1,
      dealerId: 1,
      dealerName: 'Ferrari of Ft. Lauderdale',
      subscriberName: 'Experience Auto Group',
      id: 2,
      notes: []
    },
    {
      modifiedBy: 1,
      createdBy: 1,
      modifiedAt: '01/01/2000',
      createdAt: '01/01/2000',
      issuer: 'Payee Name',
      type: 0,
      paymentStatus: 0,
      customerId: 1,
      customerName: 'Thomas Monk',
      amount: 30000.00,
      subscriberId: 1,
      dealerId: 1,
      dealerName: 'Ferrari of Ft. Lauderdale',
      subscriberName: 'Experience Auto Group',
      id: 3,
      notes: []
    }
  ]
}

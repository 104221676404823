<template>
<div class="field">
    <label class="label">
    {{label}}
    <b-icon pack="fas" icon="star" class="fa-ss" v-if="required"></b-icon>
    </label>
    <div class="control" v-if="size === 'Standard'">
        <input
        :name="instanceName"
        :data-vv-as="this.validateAs"
        v-validate="'required'"
        :class="{'input': true, 'is-danger': errors.has('instanceName') }"
        type="text"
        placeholder=""
        :style="extraStyles"
        v-model="textValue">
            <span v-show="errors.has('instanceName')" class="help is-danger">
            <i v-show="errors.has('instanceName')" class="fas fa-exclamation-triangle"></i>
            {{ errors.first('instanceName') }}
            </span>
    </div>
    <div class="control has-icons-left has-icons-right" v-else>
        <textarea
        :name="instanceName"
        :data-vv-as="this.validateAs"
        :class="{'textarea': true, 'is-danger': errors.has('instanceName') }"
        rows="5"
        type="textarea"
        v-model="textareaValue">
        </textarea>
            <span v-show="errors.has('instanceName')" class="help is-danger">
                <i v-show="errors.has('instanceName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('instanceName') }}
            </span>
</div>
</div>
</template>

<script>

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'TextInputControl',
  props: {
    value: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    validateAs: {
      type: String,
      default: 'Item'
    },
    extraStyles: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: 'Standard'
    }
  },
  data () {
    return {
      textValue: this.value,
      textareaValue: this.value,
      instanceName: this.$uuid.v4()
    }
  },
  computed: {
  },
  watch: {
    value: function (newValue, oldValue) {
      this.textValue = this.value
    },
    textValue: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>

<template>
    <portal to="global-modal-portal" v-if="isPdfViewerActive" :order="portalOrder" :disabled="!usePortal">
        <b-modal custom-class="pdf-modal" :active.sync="isPdfViewerActive" scroll="keep" :has-modal-card="true" v-if="isPdfViewerActive"  :canCancel="false" :full-screen="isFullScreenRequired">
        <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">{{localPdfDocument ? localPdfDocument.name : "Loading your pdf ...."}}</p>
            </header>
            <section class="modal-card-body" :class="{'pdf-modal-body-full-screen': isFullScreenRequired}" v-if="localPdfDocument != null">
              <pdf :rotate="rotateDegrees" ref="pdfViewer" :src="localPdfDocument.src"></pdf>
            </section>
            <section class="modal-card-body small-bottom-padding small-top-padding" v-else>
              <b-loading :is-full-page="false" :active="isPreviewDownloading" :canCancel="false"></b-loading>
            </section>
            <footer class="modal-card-foot">
              <button class="button" @click.prevent="closePdf">Close</button>
              <button class="button" @click.prevent="onDownloadFile">Download</button>
              <button class="button" @click.prevent="onPrint">Print</button>
              <button class="button" @click.prevent="onRotate" style="margin-left:auto;" v-if="enableRotate">Rotate</button>
              <slot name="extra-footer-actions" v-if="pdfFile"></slot>
            </footer>
        </div>
        </b-modal>
    </portal>
</template>

<script>
import utilitiesMixin from '@/mixins/generic/utilities'
export default {
  name: 'PdfModalDisplayComponent',
  mixins: [utilitiesMixin],
  props: {
    pdfFile: {
      type: Object,
      default: null
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    portalOrder: {
      type: Number,
      default: 1
    },
    fullScreenMode: {
      type: Boolean,
      default: false
    },
    enableRotate: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      localPdfDocument: null,
      isPdfViewerActive: false,
      rotateDegrees: 0,
      isPreviewDownloading: false
    }
  },
  computed: {
    isMobile: function () {
      return window.innerWidth <= 700
    },
    isFullScreenRequired: function () {
      return this.isMobile || this.fullScreenMode
    }    
  },
  methods: {
    closePdf () {
      this.isPdfViewerActive = false
      this.$emit('on-pdf-closed')
    },
    onDownloadFile: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.pdfFile.src) + '&fileName=' + encodeURI(this.pdfFile.fileName)
    },
    onPrint: function () {
      var pdfControls = this.$refs.pdfViewer
      if (pdfControls) {
        if (Array.isArray(pdfControls) && pdfControls.length > 0) {
          pdfControls[0].print(100)
        } else {
          pdfControls.print(100)
        }
      } else {
        this.failedToast('Printing support is not available in this browser.')
      }
    },
    loadPdf: function () {
      this.isPreviewDownloading = true
      var srcDocument = {
        name: this.pdfFile.name,
        src: this.pdfFile.src
      }
      this.localPdfDocument = srcDocument
      this.isPreviewDownloading = false
      this.$emit('on-pdf-downloaded')
    },
    onRotate: function () {
      let val = this.rotateDegrees + 90
      if (val === 450) val = 0
      this.rotateDegrees = val
    }
  },
  mounted: function () {
    this.isPdfViewerActive = true
    this.$nextTick(() => {
      this.loadPdf()
    })
  }
}
</script>
<style scoped>

.pdf-modal {
  .b-tabs {
    height:100%
  }  

  .tab-content {
    display: block !important;
    height:95%;
    margin: 0;
    padding: 0;
  }  

  .tab-item  {
    height:100%
  }  

  .animation-content {
    max-width: 100% !important;
    .annotationLayer {
      position: unset !important;
    }
  }

  .modal-card {
    width: 100% !important;
    max-width: 100% !important;
  }

  .modal-card-body {
    padding: 0.5em !important;
    position:relative;
    max-height: calc(70vh - 10px) !important;
    height: calc(70vh - 10px) !important;
    max-width: 100% !important;
    min-width: 960px !important;
    overflow-y: auto !important;
  }

  .pdf-modal-body-full-screen {
    padding: 0.5em !important;
    position:relative;
    overflow-y: auto !important;
    max-height: unset !important;
    height: unset !important;
    max-width: unset !important;
    min-width: unset !important;
    width: unset !important;
  }
}

@media screen and (max-height: 600px), print {
  .pdf-modal {
    .modal-card-body {
      max-height: calc(70vh - 60px) !important;
      height: calc(70vh - 60px) !important;
    }
  }
}

</style>

<template>
  <div class="is-inline-block" v-if="currentDeal.type === 'Booked' && hasFeatureAccess('deal.view.washout')">
    <div v-if="isBookingWizard && !listMode">
      <button type="button" class="button is-small" :class="{'is-loading': this.dealWashoutPreviewDownloading}" @click.stop.prevent="onPreviewDealWashout(currentDeal)" title="Click to view deal washout">
        <b-icon icon="fal fa-receipt" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <div class="quick-action" v-else-if="!listMode" >
      <button type="button" class="button clickable" :class="{'is-loading': this.dealWashoutPreviewDownloading}" @click.stop.prevent="onPreviewDealWashout(currentDeal)" title="Click to view deal washout">
        <b-icon icon="fal fa-receipt" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <span :class="{'clickable': true, 'icon fa-spin': this.dealWashoutPreviewDownloading}" @click.stop.prevent="onPreviewDealWashout(currentDeal)" title="Click to view deal washout" v-else>
      <b-icon icon="fal fa-receipt" custom-size="fa-lg"></b-icon>
    </span>

    <pdf-modal
      :pdfFile="dealWashoutSelectedPdf"
      :usePortal="!isBookingWizard"
      :portalOrder="2"
      v-on:on-pdf-downloaded="onPreviewed"
      v-on:on-pdf-closed="onPreviewClosed"
      v-if="dealWashoutSelectedPdf && dealWashoutSelectedPdf.src">
     </pdf-modal>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'ViewDealWashoutAction',
  components: {
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    listMode: {
      type: Boolean,
      default: false
    },
    isBookingWizard: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dealWashoutPreviewDownloading: false,
      dealWashoutSelectedPdf: null,
      instanceId: this.$uuid.v4()
    }
  },
  methods: {
    onPreviewDealWashout: function (deal) {
      this.dealWashoutPreviewDownloading = true
      this.dealWashoutSelectedPdf = {
        name: 'Deal Washout',
        src: this.documentUri,
        fileName: 'deal_washout_' + this.currentDeal.id + '.pdf',
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
      this.dealWashoutPreviewDownloading = false
    },
    onPreviewClosed: function () {
      this.dealWashoutSelectedPdf = null
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    documentUri: function () {
      return process.env.VUE_APP_ROOT_DOC_SITE + 'docs/deals/washout/' + this.currentDeal.id
    }
  },
  mounted: function () {
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .my-modal .modal-card-body {
    position:relative;
    max-height: 65vh; /* max 80% of the viewport height */
    max-width: 960px; /* max 80% of the viewport height */
    height: unset !important;
    overflow-y: auto;
  }

  @media screen and (min-width: 769px), print {
    .my-modal .modal-card-body {
      position:relative;
      width: 99vw !important;
      max-height: 80vh !important; /* max 80% of the viewport height */
      max-width: 960px; /* max 80% of the viewport height */
      height: unset !important;
      overflow-y: auto;
    }
  }

  @media screen and (min-width: 769px), print {
    .my-modal .modal-content, .modal-card {
        position:relative;
        width: 99% !important;
        overflow: auto;
        min-width: unset !important;
        max-width: unset !important;
        width: unset !important;
    }
  }

  .status-container {
    margin-bottom: 3em;
  }

</style>

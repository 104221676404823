<template>
  <div class="quick-action">
    <button type="button" class="button" @click.stop.prevent="openDeskingLink()" v-tooltip="'Click to view desk deal.'">
      <b-icon pack="fal" icon="edit" custom-size="fa-lg"></b-icon>
    </button>
  </div>
</template>

<script>

import moment from 'moment'

export default {
  name: 'InventoryDeskDeal',
  mixins: [],
  components: {
  },
  props: {
    crmInventoryId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
    }
  },
  methods: {
    openDeskingLink: function () {
      let date = moment()

      let url = 'https://eagfortlauderdale.dealerpeak.net/?fuseaction=Desk.DealScreen' +
        `&LeadID=&CarID=${this.crmInventoryId}&Layout=Popup` +
        `&ts=${date.valueOf()}`

      let windowOptions = 'width=500,height=400,resizable=yes'
      window.open(url, '_blank', windowOptions)
    }
  },
  watch: {

  },
  mounted: function () {

  }
}

</script>

<style scoped>
  .desk-deal {
    padding:0px 10px 0px 5px;
    cursor:pointer;
  }
</style>

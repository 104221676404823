<template>
  <div :class="{ box:isBoxed, 'top-margin-is-1':!isBoxed }" :name="instanceName">
    <section-header :title="title" v-if="title !== ''"></section-header>
    <div class="columns">
      <div class="column is-12">
        <label class="label" style="margin-left:10px;">
          {{ label }}
          <b-icon pack="fas" icon="star" class="fa-ss" v-if="required"></b-icon>
          <i v-if="!disabled" :id="instanceName + '.paste'" class="fal fa-paste fa-lg has-text-orange" @paste="handlePaste" title="Click here to paste an image from the clipboard. Press CTRL+V to paste" style="margin-left:5px;"></i>
        </label>
      </div>
    </div>
    <div class="columns">
      <div class="column is-12">
        <div v-viewer="{title: false}" class="item-container">
          <div class="is-inline-flex iPhoto" v-if="!disabled">
            <div>
              <b-upload v-model="uploadValue"
                type="is-info"
                :multiple="multiple"
                :disabled="shouldBeDisabled"
                accept="image/*"
                @input="loadLocalImages"
                drag-drop>
                <div class="upload-instructions">
                  <div class="has-text-centered">
                    <p>Drop your files here or click to upload</p>
                    <i class="fal fa-upload" style="margin-top:10px;"></i>
                  </div>
                </div>
              </b-upload>
            </div>
          </div>
          <div class="is-inline-flex iPhoto" v-for="(file, index) in selectedImages" :key="index">
            <div class="box" :title="file.name" v-if="file.loaded === true && (!file.status || file.status !== 'Deleted')">
              <figure class="image is-64xauto">
                <img :src="file.imageData" style="max-height:64px" @click.stop.prevent="showImage(file)">
              </figure>
              <div class="tags has-addons extra-top-margin">
                <span class="tag is-link" v-if="file.isUploadError">{{ uploadErrorDescription }}</span>
                <span class="tag is-link" v-if="!file.isUploadError && !file.isUploading">{{ shortened(file.name) }}</span>
                <span class="tag is-link" v-if="!file.isUploadError && file.isUploading">upload progress: {{ file.uploadPercentage }}%</span>
                <span class="tag is-delete" @click="deleteImage(selectedImages, index)" v-if="!disabled"></span>
              </div>
            </div>
          </div>
          <br/>
        </div>
      </div>
    </div>
    <input :data-vv-scope="scope" :name="instanceName" v-validate="{'required': required}" :data-vv-as="label" :class="{'input': true, 'is-danger': errors.has('instanceName')}" type="hidden" v-model="isFileUploaded">
    <span v-if="isFileUploaded !== 'yes'" v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(instanceName) }}</span>
    <portal to="global-modal-portal" :disabled="!portalizeImageViewer">
      <b-modal id="image-viewer" :active.sync="isLicenseViewerActive" scroll="keep" :has-modal-card="true" v-if="isLicenseViewerActive" :full-screen="true">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">{{selectedImage.name}}</p>
          </header>
          <section class="modal-card-body image-viewer-body">
            <img :src="selectedImage.imageData">
          </section>
          <footer class="modal-card-foot">
            <button class="button" @click.prevent="closeImage">Close</button>
            <button class="button" @click.prevent="onDownloadFile(selectedImage)" v-if="selectedImage.imageData.startsWith('http')">Download</button>
            <button class="button" @click.prevent="onPrintImage(selectedImage)">Print</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>

import SectionHeader from '@/components/generic/SectionHeader'
import axios from 'axios'
import utilitiesMixin from '@/mixins/generic/utilities'

export default {
  components: {
    'section-header': SectionHeader
  },
  name: 'LicenseSelector',
  mixins: [utilitiesMixin],
  props: {
    value: {
      type: Array,
      default: null
    },
    title: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: 'Images'
    },
    validateAs: {
      type: String,
      default: 'Item'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isBoxed: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    useValidator: {
      type: Object,
      default: null
    },
    croppedCustomerImages: {
      type: Array,
      default: () => []
    },
    usePortalViewer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      uploadValue: null,
      selectedImages: this.value,
      instanceName: this.$uuid.v4(),
      isLicenseViewerActive: false
    }
  },
  computed: {
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    filesValid: function () {
      return this.selectedImages.length > 0
    },
    validFiles: function () {
      return this.selectedImages.filter((x) => x.loaded === true && (!x.status || x.status !== 'Deleted'))
    },
    shouldBeDisabled: function () {
      if (this.disabled === true) {
        return true
      }
      if (this.multiple === true) {
        return false
      } else {
        if (this.validFiles.length > 0) {
          return true
        }
      }
      return false
    },
    isFileUploaded: function () {
      return this.selectedImages.length > 0 ? 'yes' : ''
    },
    portalizeImageViewer: function () {
      return this.usePortalViewer
    }
  },
  methods: {
    handlePaste: function (pasteEvent, callback) {
      if (pasteEvent.clipboardData === false) {
        if (typeof (callback) === 'function') {
          callback(undefined)
        }
      }
      var items = pasteEvent.clipboardData.items
      if (items === undefined) {
        if (typeof (callback) === 'function') {
          callback(undefined)
        }
      }
      for (var i = 0; i < items.length; i++) {
        if (items[i].type.indexOf('image') === -1) continue
        var blob = items[i].getAsFile()
        if (blob) this.loadLocalImages([blob])
      }
    },
    shortened (name) {
      return this.$lodash.truncate(name, {'length': 10, 'separator': ' '}).toLowerCase()
    },
    deleteImage (list, index) {
      var file = list[index]
      if (file.status) {
        this.$set(file, 'status', 'Deleted')
      } else {
        list.splice(index, 1)
      }
      this.$emit('input', this.selectedImages)
    },
    loadLocalImages (imageList) {
      if (!Array.isArray(imageList)) {
        imageList = [imageList]
      }
      imageList.forEach(function (file, index) {
        let found = this.selectedImages.find(x => x.name === file.name && x.size === file.size && x.status === 'Active')
        if (!file.imageData && !found) {
          var uniqueFileName = this.$uuid.v4() + this.getFileExtension(file.name)
          uniqueFileName = uniqueFileName.replace(/-/g, '')
          var reader = new FileReader()
          reader.onload = (e) => {
            var clone = {size: file.size, name: file.name, type: 'Image', fileType: file.type}
            clone.imageData = e.target.result
            clone.loaded = true
            clone.isUploaded = false
            clone.isUploading = false
            clone.uploadPercentage = 0
            clone.isUploadError = false
            clone.uploadErrorDescription = ''
            clone.uniqueId = uniqueFileName
            clone.status = 'Active'
            this.selectedImages.push(clone)
            this.uploadFile(file, uniqueFileName, clone)
          }
          reader.readAsDataURL(file)
        }
      }, this)
    },
    getFileExtension: function (fileName) {
      return '.' + fileName.split('.').pop()
    },
    resetUploadStatus: function (clone) {
      clone.uploadPercentage = 0
      clone.isUploading = true
      clone.isUploadError = false
      clone.uploadErrorDescription = ''
    },
    uploadFile: function (file, uniqueName, clone) {
      let that = this
      let uploadErrorDescription = 'An error occurred uploading your file ' + file.name + '. Please verify that your file is valid and try again: <br/>'
      this.resetUploadStatus(clone)
      var formData = new FormData()
      formData.append(uniqueName, file)
      this.$emit('uploadStarted')
      axios.post('/upload/receive', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        onUploadProgress: progressEvent => {
          clone.uploadPercentage = ((progressEvent.loaded / progressEvent.total) * 100).toFixed(2)
        }
      }).then((response) => {
        clone.isUploading = false
        const item = response.data.results[0]
        if (!item.isValid) {
          clone.isUploadError = true
          let imageIndex = that.selectedImages.findIndex((x) => x.name === file.name)
          that.deleteImage(that.selectedImages, imageIndex)
          that.failedToast(uploadErrorDescription + item.error, 10000)
          that.$emit('uploadComplete', false)
        } else {
          clone.isUploaded = true
          clone.uploadErrorDescription = ''
          that.$emit('uploadComplete', true)
        }
      }).catch((error) => {
        clone.isUploadError = true
        clone.uploadErrorDescription = error.message
        let imageIndex = that.selectedImages.findIndex((x) => x.name === file.name)
        that.deleteImage(that.selectedImages, imageIndex)
        that.failedToast(uploadErrorDescription + error.message, 10000)
        that.$emit('uploadComplete', false)
      })
    },
    showImage (file) {
      this.selectedImage = this.selectedImages[0]
      this.isLicenseViewerActive = true
    },
    closeImage: function () {
      this.isLicenseViewerActive = false
      this.selectedImage = null
    },
    onDownloadFile: function (file) {
      let completeUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(file.imageData)
      window.location = completeUrl
    },
    onPrint: function () {
      var pdfControls = this.$refs.pdfViewer
      if (pdfControls) {
        if (Array.isArray(pdfControls) && pdfControls.length > 0) {
          pdfControls[0].print(300)
        } else {
          pdfControls.print(300)
        }
      } else {
        alert('Printing support is not available in this browser.')
      }
    },
    onPrintImage: function (imageFile) {
      let imageHtml = '<html><head><scri' + 'pt>function step1(){\n' +
                "setTimeout('step2()', 10);}\n" +
                'function step2(){window.print();window.close()}\n' +
                '</scri' + "pt></head><body onload='step1()'>\n" +
                "<img style='max-width:100%;max-height:100%;' src='" + imageFile.imageData + "' /></body></html>"
      let pagelink = 'about:blank'
      var pwa = window.open(pagelink, '_new')
      pwa.document.open()
      pwa.document.write(imageHtml)
      pwa.document.close()
    }
  },
  watch: {
    selectedImages: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    value: function (newValue, oldValue) {
      if (newValue === null) {
        this.selectedImages = []
      } else {
        this.selectedImages = newValue
      }
    },
    croppedCustomerImages: function (newValue, oldValue) {
      if (newValue && newValue.length > 0) {
        this.loadLocalImages(newValue)
      }
    }
  },
  mounted: function () {
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .iPhoto {
    margin: 5px;
  }

  .item-container {
    display: flex;
    flex-wrap: wrap;
  }

  .columns {
    display:flex;
  }

  .column {
    padding:0 !important
  }

  .top-margin-is-1 {
    margin-top:12px
  }

  .is-128xauto {
    display: -webkit-box;
    height:128px;
    width:auto;
    align-items: center;
    justify-content: center
  }

  .is-64xauto {
    display: -webkit-box;
    height:64px;
    width:auto;
    align-items: center;
    justify-content: center
  }

  .image img {
    display: block;
    height:auto;
    width:auto;
  }

  .upload-instructions {
    width:118px;
    height:97px;
    margin:1em;
  }

  .extra-top-margin {
    margin-top:10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .image-viewer-body {
  position:relative;
  margin-left:30px;
  margin-right:30px;
  overflow-y: scroll;
  overflow-x: clip;
  }
</style>

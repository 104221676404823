import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'

var mixin = {
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  computed: {
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    }
  }
}
export default mixin

import axios from 'axios'
import baseService from './baseService'
import config from './config'

const dealerPeakService = {
  syncCustomer (customerModel) {
    return new Promise((resolve, reject) => {
      axios
        .post(config.dealerPeakApiUrl + 'customer/sync', customerModel, baseService.defaultConfig)
        .then(response => {
          resolve(response.data)
        })
        .catch(error => {
          reject(error)
        })
    })
  }
}

export default dealerPeakService

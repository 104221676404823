export default {
  result: true,
  description: '',
  user: {
    name: 'Garrett',
    surname: 'Hayim',
    status: 'Active',
    email: 'gh@ferrarifl.com',
    mobile: null,
    telephone: null,
    subscriberId: 1,
    dealerId: 1,
    dealerName: 'Ferrari of Ft. Lauderdale',
    subscriberName: 'Experience Auto Group',
    roles: ['SuperAdmin'],
    designations: [{
      id: 1,
      name: 'CEO'
    }],
    id: 3003,
    createdAt: '0001-01-01T00:00:00',
    modifiedAt: '0001-01-01T00:00:00',
    menus: [
      {
        name: 'Home',
        path: '/',
        meta: {
          icon: 'fa-home',
          expanded: false,
          link: '/'
        },
        component: ''
      },
      {
        name: 'Inventory',
        path: '',
        meta: {
          icon: 'fa-car',
          expanded: false,
          link: '/'
        },
        component: '',
        children: [
          {
            name: 'Overview',
            path: '/inventory/overview',
            component: '',
            meta: {
              link: '/inventory/overview'
            }
          },
          {
            name: 'All',
            path: '/inventory/all',
            component: '',
            meta: {
              link: '/inventory/all'
            }
          },
          {
            name: 'Recent',
            path: '/inventory/recent',
            component: '',
            meta: {
              link: '/inventory/recent'
            }
          },
          {
            name: 'Active',
            path: '/inventory/active',
            component: '',
            meta: {
              link: '/inventory/active'
            }
          },
          {
            name: 'Sold',
            path: '/inventory/sold',
            component: '',
            meta: {
              link: '/inventory/sold'
            }
          },
          {
            name: 'Inbound',
            path: '/inventory/inbound',
            component: '',
            meta: {
              link: '/inventory/inbound'
            }
          }
        ]
      },
      {
        name: 'Sales',
        path: '',
        meta: {
          icon: 'fa-handshake',
          expanded: false,
          link: '/'
        },
        component: '',
        children: [
          {
            name: 'Overview',
            path: '/sales/overview',
            component: '',
            meta: {
              link: '/sales/overview'
            }
          },
          {
            name: 'All',
            path: '/sales/all',
            component: '',
            meta: {
              link: '/sales/all'
            }
          },
          {
            name: 'Pending',
            path: '/sales/pending',
            component: '',
            meta: {
              link: '/sales/pending'
            }
          },
          {
            name: 'Closed',
            path: '/sales/closed',
            component: '',
            meta: {
              link: '/sales/closed'
            }
          },
          {
            name: 'Cancelled',
            path: '/sales/cancelled',
            component: '',
            meta: {
              link: '/sales/cancelled'
            }
          }
        ]
      },
      {
        name: 'Service',
        path: '',
        meta: {
          icon: 'fa-wrench',
          expanded: false,
          link: '/'
        },
        component: '',
        children: [
          {
            name: 'Overview',
            path: '/service/overview',
            component: '',
            meta: {
              link: '/service/overview'
            }
          },
          {
            name: 'All',
            path: '/service/all',
            component: '',
            meta: {
              link: '/service/all'
            }
          },
          {
            name: 'Pending',
            path: '/service/pending',
            component: '',
            meta: {
              link: '/service/pending'
            }
          },
          {
            name: 'Closed',
            path: '/service/closed',
            component: '',
            meta: {
              link: '/service/closed'
            }
          },
          {
            name: 'Cancelled',
            path: '/service/cancelled',
            component: '',
            meta: {
              link: '/service/cancelled'
            }
          }
        ]
      },
      {
        name: 'Configuration',
        path: '',
        meta: {
          icon: 'fa-cogs',
          expanded: false,
          link: '/'
        },
        component: '',
        children: [
          {
            name: 'Overview',
            path: '/configuration/overview',
            component: '',
            meta: {
              link: '/configuration/overview'
            }
          },
          {
            name: 'Dealers',
            path: '/configuration/dealers',
            component: '',
            meta: {
              link: '/configuration/dealers'
            }
          },
          {
            name: 'Users',
            path: '/configuration/users',
            component: '',
            meta: {
              link: '/configuration/users'
            }
          },
          {
            name: 'Makes & Models',
            path: '/configuration/makemodels',
            component: '',
            meta: {
              link: '/configuration/makemodels'
            }
          },
          {
            name: 'Types',
            path: '/configuration/types',
            component: '',
            meta: {
              link: '/configuration/types'
            }
          }
        ]
      },
      {
        name: 'Parts',
        path: '',
        meta: {
          icon: 'fa-wrench',
          expanded: false,
          link: '/'
        },
        component: '',
        children: [
          {
            name: 'Overview',
            path: '/parts/overview',
            component: '',
            meta: {
              link: '/parts/overview'
            }
          },
          {
            name: 'All',
            path: '/parts/all',
            component: '',
            meta: {
              link: '/parts/all'
            }
          },
          {
            name: 'Pending',
            path: '/parts/pending',
            component: '',
            meta: {
              link: '/parts/pending'
            }
          },
          {
            name: 'Closed',
            path: '/parts/closed',
            component: '',
            meta: {
              link: '/parts/closed'
            }
          },
          {
            name: 'Cancelled',
            path: '/parts/cancelled',
            component: '',
            meta: {
              link: '/parts/cancelled'
            }
          }
        ]
      }
    ]
  }
}

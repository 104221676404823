<template>
  <div class="fullpage" v-if="!isAuthenticated && !isLoading">
    <div class="columns is-marginless" v-show="!isAuthenticated && !isLoading" >
      <div class="column is-5" style="margin-top:20px;">
        <form @submit.prevent="validateBeforeSubmit">
          <div style="margin-left:30px;">
            <h1 class="logo">
              <div style="width:200px"><img src="@/assets/logo_standard.png" alt="logo"></div>
            </h1>
            <div class="heading">Live</div>
            <div class="field">
              <p class="sub-heading">Please log in to access your account</p>
              <label class="input-title">Email</label>
              <div class="control has-icons-left has-icons-right">
                <input name="loginEmail" data-vv-as="email address" v-validate="'required|email'"
                :class="{'input': true, 'is-danger': errors.has('loginEmail') }" type="text" placeholder="user@email.com"
                v-model="loginEmail" tabindex=4 @keyup.enter="validateBeforeSubmit">
                <span v-show="errors.has('loginEmail')" class="help is-danger">
                  <i v-show="errors.has('loginEmail')" class="fas fa-exclamation-triangle"></i> {{ errors.first('loginEmail') }}
                </span>
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right" v-show="!errors.has('loginEmail')">
                  <i class="fas fa-check"></i>
                </span>
                <span class="icon is-small is-right" v-show="errors.has('loginEmail')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
            <div class="field">
              <label class="input-title">Password</label>
              <div class="control has-icons-left has-icons-right">
                <input name="loginPassword" data-vv-as="password" v-validate="'required'"
                :class="{'input': true, 'is-danger': errors.has('loginPassword') }" type="password" placeholder="*********"
                v-model="loginPassword" tabindex=4 @keyup.enter="validateBeforeSubmit">
                <span v-show="errors.has('loginPassword')" class="help is-danger">
                  <i v-show="errors.has('loginPassword')" class="fas fa-exclamation-triangle"></i> {{
                  errors.first('loginPassword') }}
                </span>
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right" v-show="!errors.has('loginPassword')">
                  <i class="fas fa-check"></i>
                </span>
                <span class="icon is-small is-right" v-show="errors.has('loginPassword')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
            <a class="button is-danger" :class="{'is-loading': isLoading }" @click="validateBeforeSubmit">Login</a>
            <a class="is-pulled-right" style="font-size: 0.75rem; cursor: pointer; color: inherit;" @click="resetPassword">Forgot
              your password?
              <strong>Reset it here</strong>
            </a>
            <div class="loading-container">
              <div class="title is-size-7 has-text-primary" v-if="isLoading">
                <span>{{loadingMessage}}</span>
              </div>
            </div>
            <div class="loading-container has-text-centered">
              <p v-if="errorMessage.length">
                <ul class="errors">
                  <li class="has-text-danger has-text-centered">{{ errorMessage }}</li>
                </ul>
                <br />
              </p>
            </div>
          </div>
        </form>
      </div>
      <div class="column is-7 is-paddingless is-hidden-mobile is-hidden-touch swiper-container" id="banner-slider" v-if="!isAuthenticated && !isLoading">
        <swiper class="swiper" :slides-per-view="3"
        :space-between="50" :options="swiperOption">
          <swiper-slide class="feat-login-image"></swiper-slide>
          <swiper-slide class="hero-image-1"></swiper-slide>
          <swiper-slide class="hero-image-2"></swiper-slide>
          <swiper-slide class="hero-image-3"></swiper-slide>
          <swiper-slide class="hero-image-4"></swiper-slide>
          <swiper-slide class="hero-image-5"></swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
  <div class="aligner" v-else>
    <div class="aligner-item aligner-item--top"></div>
    <div class="aligner-item" style="text-align:center">
      <img src="@/assets/ajax-loader.gif">
      <div v-if="isLoading && !isLoadingConfiguration">Verifying your identity</div>
      <div v-if="isLoading && isLoadingConfiguration">Loading configuration data</div>
    </div>
    <div class="aligner-item aligner-item--bottom"></div>

    <b-modal :active.sync="twoFactorCodeActive" scroll="keep" :has-modal-card="true" :canCancel="false" id="login-modal">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Code</p>
          <div class="tags has-addons" v-if="userFullname !== ''">
            <span class="tag is-black"><i class="fal fa-user"></i></span>
            <span class="tag is-primary">{{userFullname}}</span>
          </div>
        </header>
        <section class="modal-card-body">
          <div class="notification is-danger is-flex" v-if="errorMessage && errorMessage.length">
            <div>
              <i class="fas fa-exclamation-triangle fa-2x"></i>
            </div>
            <div class="ml-10">
              <ul><li>{{ errorMessage }}</li></ul>
            </div>
          </div>
          <div class="notification is-info is-flex" v-if="!errorMessage">
            <div>
              <i class="fas fa-info-circle is-info fa-2x"></i>
            </div>
            <div class="ml-10">
              Please verify the code that was sent to your registered email or mobile number to proceed.
            </div>
          </div>
          <form class="view-container" @submit.prevent="">
            <div class="columns is-mobile">
              <div class="column">
                <div class="field">
                  <label class="input-title">Code<b-icon pack="fas" icon="star" class="fa-ss" /></label>
                  <div class="control has-icons-right">
                    <input
                      ref="twoFactorInput"
                      id="code"
                      inputmode="numeric"
                      pattern="[0-9]*"
                      autocomplete="one-time-code"
                      name="code"
                      v-model="code"
                      data-vv-as="code"
                      v-validate="'required'"
                      @keypress.enter="onVerifyTwoFactorCode"
                      :class="{'input': true, 'is-danger': errors.has('code') }"
                      type="text">
                    <span v-show="errors.has('code')" class="help is-danger"><i v-show="errors.has('code')" class="fas fa-exclamation-triangle"></i> {{ errors.first('code') }}</span>
                    <span class="icon is-small is-right" v-show="!errors.has('code')"><i class="fas fa-check"></i></span>
                    <span class="icon is-small is-right" v-show="errors.has('code')"><i class="fas fa-exclamation-triangle"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isVerifyingTwoFactorCode }" @click="onVerifyTwoFactorCode">verify</button>
          <button class="button is-danger" @click="onCancelTwoFactorCode">back</button>
          <button class="button is-info is-pulled-right" :class="{'is-loading': isVerifyingTwoFactorCode }" v-tooltip="{content:'resend code'}" @click="onResendTwoFactorCode">resend code</button>
        </footer>
      </div>
    </b-modal>

  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import logger from '@/services/loggingService'
import utilitiesMixin from '@/mixins/generic/utilities'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
const mapAuthGetters = createNamespacedHelpers('auth').mapGetters

export default {
  name: 'Login',
  mixins: [validateBeforeSubmitMixin, utilitiesMixin],
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      loginEmail: '',
      loginPassword: '',
      isLoading: false,
      isLoadingConfiguration: false,
      loadingMessage: '',
      error: false,
      errorMessage: '',
      swiperOption: {
        speed: 1000,
        spaceBetween: 0,
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        preloadImages: true,
        updateOnImagesReady: true,
        grabCursor: false,
        autoplay: {
          delay: 10000
        }
      },
      twoFactorCodeActive: false,
      isVerifyingTwoFactorCode: false,
      code: '',
      userId: null,
      userFullname: ''
    }
  },
  computed: {
    ...mapAuthGetters(['isAuthenticated'])
  },
  methods: {
    resetPassword () {
      this.$router.push('/auth/resetpassword')
    },
    resetErrors: function () {
      this.error = false
      this.errorMessage = ''
    },
    onSave: async function () {
      this.resetErrors()
      this.isLoading = true
      this.loadingMessage = 'Verifying your credentials'
      let credentialModel = {
        email: this.loginEmail,
        password: this.loginPassword,
        isTwoFactorAuth: true
      }
      try {
        let response = await this.$store.dispatch('auth/authRequest', credentialModel)
        if (response && response.id) {
          this.userId = response.id
          this.userFullname = response.fullName
          this.twoFactorCodeActive = true
          let that = this
          this.$nextTick(() => {
            that.$refs.twoFactorInput.focus()
          })
        }
      } catch (error) {
        this.isLoading = false
        this.error = true
        if (error && error.message) {
          this.errorMessage = error.message
        } else if (error) {
          this.errorMessage = error
        }
        console.log(error.message)
      }
    },
    onVerifyTwoFactorCode: async function () {
      this.$validator.validate().then(async isValid => {
        if (!isValid) {
          this.$nextTick(() => { this.$goToFirstValidationError(this.$validator.errors.items) })
        } else {
          this.resetErrors()
          this.isVerifyingTwoFactorCode = true
          let parts = this.userId + '|' + this.code
          let authModel = {codeParts: parts}
          try {
            await this.$store.dispatch('auth/codeAuthRequest', authModel)
            this.isVerifyingTwoFactorCode = false
            this.twoFactorCodeActive = false
            await this.loadConfiguration()
          } catch (error) {
            this.isVerifyingTwoFactorCode = false
            this.error = true
            if (error && error.message) {
              this.errorMessage = error.message
            } else if (error) {
              this.errorMessage = error
            }
          }
        }
      })
    },
    onCancelTwoFactorCode: async function () {
      await this.$store.dispatch('auth/logoutRequest')
      this.isLoading = false
      this.isVerifyingTwoFactorCode = false
      this.twoFactorCodeActive = false
      this.code = ''
      this.userId = null
      this.userFullname = ''
      this.resetErrors()
    },
    loadBaseData: async function () {
      try {
        this.isLoadingConfiguration = true
        let checkResult = await this.$store.dispatch('user/setCurrentUser')
        if (checkResult) {
          let allActions = []
          allActions.push(this.$store.dispatch('subscriber/setCurrentSubscriber', { id: this.$store.state.user.currentUser.subscriberId }))
          allActions.push(this.$store.dispatch('dealer/setCurrentDealer', { id: this.$store.state.user.currentUser.dealerId }))
          allActions.push(this.$store.dispatch('role/setRoles'))
          allActions.push(this.$store.dispatch('bank/setBanks'))
          allActions.push(this.$store.dispatch('feature/setFeatures'))
          allActions.push(this.$store.dispatch('dealer/setDealers'))
          allActions.push(this.$store.dispatch('dealer/setDesignations'))
          allActions.push(this.$store.dispatch('dealer/setDepartments'))
          allActions.push(this.$store.dispatch('user/setUsers'))
          allActions.push(this.$store.dispatch('location/setLocations'))
          allActions.push(this.$store.dispatch('vehicleMake/setVehicleMakes'))
          allActions.push(this.$store.dispatch('leadSource/setLeadSources'))
          allActions.push(this.$store.dispatch('states/setAllStates'))
          allActions.push(this.$store.dispatch('roServiceVehicleMake/setDealerRoServiceVehicleMakes', { dealerId: this.$store.state.user.currentUser.dealerId }))
          allActions.push(this.$store.dispatch('scheduledPayment/setScheduledPaymentPayees'))
          await Promise.all(allActions)
          this.isLoadingConfiguration = false
          this.isLoading = false
          await this.$store.dispatch('auth/setSystemReady')
          if (this.$route.query && this.$route.query.redir && this.$route.query.redir.length > 1) {
            this.$router.push(this.$route.query.redir)
          } else {
            this.$router.push('/')
          }
        } else {
          this.isLoading = false
          this.$router.push('/auth/login')
        }
      } catch (error) {
        this.isLoadingConfiguration = false
        this.isLoading = false
        logger.log(error)
      }
    },
    loadConfiguration: async function () {
      // If the user is not authenticated client side first do a check to the API to see if they authenticated server side via secure auth cookies
      this.isLoading = true
      if (!this.isAuthenticated) {
        try {
          await this.$store.dispatch('auth/authVerificationRequest')
          await this.loadBaseData()
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
          logger.log(error)
        }
      } else {
        await this.loadBaseData()
        this.isLoading = false
      }
    },
    removeNavBar: function () {
      document.getElementsByTagName('html')[0].classList.remove('has-navbar-fixed-top')
    },
    addNavBar: function () {
      if (!document.getElementsByTagName('html')[0].classList.contains('has-navbar-fixed-top')) {
        document.getElementsByTagName('html')[0].classList.add('has-navbar-fixed-top')
      }
    },
    onResendTwoFactorCode: async function () {
      let credentialModel = {
        email: this.loginEmail,
        password: this.loginPassword
      }
      this.isVerifyingTwoFactorCode = true
      try {
        await this.$store.dispatch('auth/resendTwoFactorCodeRequest', credentialModel)
        this.isVerifyingTwoFactorCode = false
        this.successToast('Success! new code sent')
      } catch (error) {
        this.isVerifyingTwoFactorCode = false
        this.error = true
        this.errorMessage = (error && error.message) ? error.message : 'Oops, something went wrong. Please contact system administrator.'
      }
    }
  },
  watch: {
  },
  beforeMount () {
    this.removeNavBar()
  },
  beforeDestroy () {
    this.addNavBar()
  },
  mounted: async function () {
    await this.loadConfiguration()
  }
}
</script>

<style scoped>

  .aligner {
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aligner-item {
    max-width: 50%;
  }

  .aligner-item--top {
    align-self: flex-start;
  }

  .aligner-item--bottom {
    align-self: flex-end;
  }

  .fullpage {
    background: #fff;
  }

  .swiper {
    width: 64vw;
    max-height:100%
  }

  .content
  {
    padding: 10px;
    text-align: center;
  }

  .inner
  {
    display:inline-block;
  }

  .heading {
    font-size: 1.55rem;
    font-family: "Roboto Regular",sans-serif;
    padding-bottom:100px;
    text-transform: none;
  }

  .sub-heading {
    font-family: "Roboto Regular",sans-serif;
    color: #3f4353;
    margin: 0 0 1em;
    word-break: normal;
    font-size: 0.8rem;
  }

  .loading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5em;
  }

  .footer {
    font-family: "Roboto Regular",sans-serif;
    color: #3f4353;
    line-height: 1.6rem;
    font-size: 13px;
    font-weight: bold;
  }

  .input-title {
    color:#d4d5db;
    font-family: "Roboto Regular",sans-serif;
    font-size: .8rem;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    max-width: 480px !important;
  }

  .modal-card-body {
    position: relative !important;;
    max-height: 65vh !important;; /* max 80% of the viewport height */
    max-width: 480px !important;; /* max 80% of the viewport height */
    height: unset !important;
    overflow-y: auto;
  }

</style>

import axios from 'axios'
import baseService from './baseService'
import documentSigningRequestData from './data/documentSigningRequest'

const documentSigningRequestService = {
  searchByType (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(documentSigningRequestData)
      } else {
        axios
          .post('/documentSigningRequest/searchByType', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  cancelSignatureRequest (signatureRequestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(documentSigningRequestData)
      } else {
        axios
          .post('/documentSigningRequest/cancelSignatureRequest?signatureRequestId=' + signatureRequestId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.reponse.data)
          })
      }
    })
  },
  sendReminder (signatureRequest) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(documentSigningRequestData)
      } else {
        axios
          .post('/documentSigningRequest/SendSignatureRequestReminder', signatureRequest, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.reponse.data)
          })
      }
    })
  }
}

export default documentSigningRequestService

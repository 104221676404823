import axios from 'axios'
import baseService from './baseService'
import dealData from './data/product'

const appService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('deal-load-all')
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        let localSearchCriteria = {...searchCriteria}
        if (localSearchCriteria && localSearchCriteria.saleType && Array.isArray(localSearchCriteria.saleType)) {
          localSearchCriteria.saleType = '-1'
        }
        if (localSearchCriteria && localSearchCriteria.typeId && Array.isArray(localSearchCriteria.typeId)) {
          localSearchCriteria.typeId = '-1'
        }
        if (localSearchCriteria && localSearchCriteria.statusId && !Array.isArray(localSearchCriteria.statusId)) {
          localSearchCriteria.statusId = [localSearchCriteria.statusId]
        }
        axios
          .post('/deal/list', localSearchCriteria, { ...baseService.defaultConfig, requestId: 'deal-load-all' })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('deal load all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getGpSummaryData (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/deal/getGpSummary', searchCriteria, { ...baseService.defaultConfig, requestId: 'gp-summary-by-make' })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getPvrSummaryData (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/deal/getPvrSummary', searchCriteria, { ...baseService.defaultConfig, requestId: 'pvr-summary-by-make' })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  getUnitCountSummaryData (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/deal/getUnitCountSummary', searchCriteria, { ...baseService.defaultConfig, requestId: 'unit-count-by-make' })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  soldByInventory (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/deal/getSoldByInventory?inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  byInventory (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/deal/getByInventory?inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDealsByInventoryForTrade (currentInventory, soldInventory) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/deal/getDealsByInventoryForTrade', {tradeId: currentInventory ? currentInventory.id : 0, inventoryId: soldInventory.id}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDealsByItems (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/deal/getByItemsCriteria', searchCriteria, { ...baseService.defaultConfig, requestId: searchCriteria.requestId ? searchCriteria.requestId : 'deals-by-make' })
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  byCustomer (customerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/deal/getByCustomer?customerId=' + customerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (dealId, requestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all.find((x) => x.id === dealId))
      } else {
        let options = requestId === undefined ? baseService.defaultConfig : {...baseService.defaultConfig, requestId: requestId}
        axios
          .post('/deal/get?id=' + dealId, {}, options)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('deal single cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  save (dealModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var deal = {
          id: 999
        }
        dealData.all.push(deal)
        resolve(deal)
      } else {
        let model = this.optimizeDealModel(dealModel)
        axios
          .post('/deal/save', model, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (dealModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var deal = dealData.all.find((x) => x.id === dealModel.id)
        if (deal) {
          deal.id = dealModel.id
        }
        resolve(deal)
      } else {
        let model = this.optimizeDealModel(dealModel)
        axios
          .post('/deal/update', model, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (dealModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        dealModels.forEach(element => {
          dealData.all.remove((x) => x.id === element.id)
        })
        resolve(dealData.all)
      } else {
        var ids = []
        dealModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/deal/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (dealNoteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var deal = dealData.all.find((x) => x.id === dealNoteModel.dealId)
        if (deal) {
          deal.notes.push(dealNoteModel)
        }
        resolve(deal)
      } else {
        axios
          .post('/deal/notes', dealNoteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/deal/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/deal/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updatePricingInfo (dealPricingModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/updatePricingInfo', dealPricingModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updatePartialDeal (dealPartial) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/updatepartialdeal', dealPartial, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateIsPrimary (dealUpdatePrimaryModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/UpdateIsPrimary', dealUpdatePrimaryModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  GetPrimaryDealForInventory (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all.find((x) => x.id === inventoryId))
      } else {
        axios
          .post('/deal/GetPrimaryDealForInventory?inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDistinctSupplementalCostNamesByDealer (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all)
      } else {
        axios
          .post('/deal/getDistinctSupplementalCostNamesByDealer', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDealerSupplementalCosts (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all)
      } else {
        axios
          .post('/deal/GetDealerSupplementalCosts', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  cancelBackupDeals (ids) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all)
      } else {
        if (ids.length === 0) {
          resolve('must selected at least one deal')
        } else {
          axios
            .post('/deal/CancelBackupDeals', ids, baseService.defaultConfig)
            .then(response => {
              resolve(response.data)
            })
            .catch(error => {
              reject(error.response.data)
            })
        }
      }
    })
  },
  getDefaultAdditionalCosts (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all)
      } else {
        axios
          .post('/deal/getDefaultAdditionalCosts', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  dealStatusWizardSave (dealStatusWizardModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        let model = this.optimizeDealModel(dealStatusWizardModel)
        axios
          .post('/deal/DealStatusWizardSave', model, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  queueMail (orderEmailModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/QueueMail', orderEmailModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateRetailAgreementCosts (costModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/updateRetailAgreementCosts', costModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateLeaseAgreementCosts (costModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/updateLeaseAgreementCosts', costModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  queueCheckRequestMail (checkRequestEmail) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/queueCheckRequestMail', checkRequestEmail, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allProductNames (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all)
      } else {
        axios
          .post('/deal/getProductNamesByDealer', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response)
          })
      }
    })
  },
  cancelLocate (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/CancelLocateItem?inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  uploadSignedBuyersAgreement (signedAgreementUploadModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/uploadSignedBuyersAgreement', signedAgreementUploadModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  uploadDocuments (deal) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/uploadDocuments', deal, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDocuments (deal) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .get('/deal/GetDocuments?id=' + deal.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  exportList (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios({
          url: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/deal/list',
          method: 'POST',
          responseType: 'blob',
          data: searchCriteria,
          withCredentials: false
        }).then((response) => {
          resolve(response)
        }).catch(error => {
          reject(error.response)
        })
      }
    })
  },
  updateAppointmentStatus (dealId, appointmentStatus) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/updateAppointmentStatus', { dealId: dealId, appointmentStatus: appointmentStatus }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/deal/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/deal/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByDealId (dealId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all)
      } else {
        axios
          .get('/deal/dealnotes?id=' + dealId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  editTags (dealTagsModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var deal = dealData.all.find((x) => x.id === dealTagsModel.id)
        if (deal) {
          deal.tags = dealTagsModel.tags
        }
        resolve(deal)
      } else {
        axios
          .post('/deal/tags', dealTagsModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markOrderDealAllocated (dealOrderAllocatedModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all.find((x) => x.id === dealOrderAllocatedModel.dealId))
      } else {
        axios
          .post('/deal/markorderdealallocated', dealOrderAllocatedModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  exportCommissionReport (urlStr) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios({
          url: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/deal/exportCommissionReport/' + urlStr,
          method: 'POST',
          responseType: 'blob',
          withCredentials: false
        }).then((response) => {
          resolve(response)
        }).catch(error => {
          reject(error.response.data)
        })
      }
    })
  },
  getInProgressDealsByPurchaser (purchaserId, dealerId, purchasedFromType) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        const queryString = 'purchaserId=' + purchaserId + '&dealerId=' + dealerId + '&purchasedFromType=' + purchasedFromType
        axios
          .post('/deal/getinProgressDealsByPurchaser?' + queryString, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addTradeToDeal (tradeDealItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/AddTradeToDeal', tradeDealItem, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  saveDealCode (dealModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        let model = this.optimizeDealModel(dealModel)
        axios
          .post('/deal/UpdateDealCode', model, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  clearPreDeal (dealId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var deal = {
          id: 999
        }
        dealData.all.push(deal)
        resolve(deal)
      } else {
        axios
          .post('/deal/clearPreDeal?dealId=' + dealId, {}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateTimeToDeliver (dealTimeToDeliverEventModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/updateTimeToDeliver', dealTimeToDeliverEventModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateReconAdjustment (dealModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        let model = this.optimizeDealModel(dealModel)
        axios
          .post('/deal/updatereconadjustment', model, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  setInboundStatus (dealModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var deal = {
          id: 999
        }
        dealData.all.push(deal)
        resolve(deal)
      } else {
        let model = this.optimizeDealModel(dealModel)
        axios
          .post('/deal/setInboundStatus', model, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  updateCreditApplicationStatusInfo (dealCreditApplicationStatusModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all.find((x) => x.id === dealCreditApplicationStatusModel.dealId))
      } else {
        axios
          .post('/deal/updateCreditApplicationStatusInfo', dealCreditApplicationStatusModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  clearCreditApplicationStatus (dealCreditApplicationStatusModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all.find((x) => x.id === dealCreditApplicationStatusModel.dealId))
      } else {
        axios
          .post('/deal/clearCreditApplicationStatus', dealCreditApplicationStatusModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  optimizeDealModel (dealModel) {
    let optimizeDealModel = dealModel

    if (optimizeDealModel.customer) {
      optimizeDealModel.customer.transactions = null
    }

    if (optimizeDealModel.paymentTrackings) {
      optimizeDealModel.paymentTrackings = null
    }

    if (optimizeDealModel.statusAuditHistory) {
      optimizeDealModel.statusAuditHistory = null
    }
    if (optimizeDealModel.items) {
      optimizeDealModel.items.forEach(item => {
        if (item.forInventory) {
          if (item.forInventory.blackBook) item.forInventory.blackBook = null
          if (item.forInventory.blackBooks) item.forInventory.blackBooks = []
          item.forInventory.addedSupplementalCostsAfterInitialCreate = []
          item.forInventory.allActiveCheckRequests = null
          item.forInventory.allReports = null
          item.forInventory.inActiveSupplementalCosts = []
          item.forInventory.loanerEvents = null
          item.forInventory.serviceRequests = []
          item.forInventory.vinUsageHistory = null
          item.forInventory.currentProposedCost = null
          item.forInventory.deals = null
        }
      })
    }

    return optimizeDealModel
  },
  syncDealAttachments (attachmentsModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/deal/syncDealAttachments', attachmentsModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  sendTitleReassignmentDocument (sendTitleReassignmentModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/deal/sendTitleReassignmentDocument', sendTitleReassignmentModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService

import requiresAuth from './route_guards'

export default [
  {
    path: '/loaner-event',
    name: 'ListLoanerTracking',
    component: () => import('@/components/loanerEvent/ListLoanerTracking'),
    beforeEnter: requiresAuth
  },
  {
    path: '/loaner-event/history',
    name: 'ListLoanerEvents',
    component: () => import('@/components/loanerEvent/ListLoanerEvents'),
    beforeEnter: requiresAuth
  },
  {
    name: 'LoanerEventsQuickView',
    path: '/loaner-event/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/loanerEvent/ViewLoanerEvent')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/loaner-event/new',
    name: 'NewLoanerEvent',
    component: () => import('@/components/loanerEvent/NewLoanerEvent'),
    beforeEnter: requiresAuth
  },
  {
    path: '/loaner-event/view/:id',
    name: 'ViewLoanerEvent',
    component: () => import('@/components/loanerEvent/ViewLoanerEvent'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/loaner-event/edit/:id',
    name: 'EditLoanerEvent',
    component: () => import('@/components/loanerEvent/EditLoanerEvent'),
    beforeEnter: requiresAuth
  }
]

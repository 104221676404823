<template>
  <div :id="identity" class="columns clickable is-mobile" style="margin-top: -20px">
    <div class="column is-narrow" style="padding-right:0">
      <span class="title is-6 is-inline-block" v-html="title" @click="onClick"></span>
    </div>
    <div class="column ellipsis" v-if="!forInventoryView" @click="onClick">
      <span class="subtitle is-7" style="margin-left:5px;" v-if="overview && overview.length > 0" :title="overview">{{overview}}</span>
    </div>
    <div class="column ellipsis" v-else @click="onClick">
      <span class="subtitle is-7" style="margin-left:5px;" v-html="overview"></span>
    </div>
    <div class="column is-narrow" v-if="allowCollapse">
      <i class="fal fa-minus xp-icon-minus clickable is-pulled-right" @click="onClick" v-if="!isCollapsed"></i>
      <i class="fal fa-plus xp-icon-plus clickable is-pulled-right" @click="onClick" v-if="isCollapsed"></i>
    </div>
  </div>
</template>

<script>

export default {
  name: 'InventoryPayoffSectionHeader',
  props: {
    title: {
      type: String,
      default: 'Header'
    },
    overview: {
      type: String,
      default: ''
    },
    collapsed: {
      type: Boolean,
      default: false
    },
    parentSelector: {
      type: String,
      default: ''
    },
    allowCollapse: {
      type: Boolean,
      default: true
    },
    forInventoryView: {
      type: Boolean,
      default: false
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      isCollapsed: false
    }
  },
  methods: {
    onClick: function (evt) {
      if (!this.allowCollapse) {
        return
      }
      this.isCollapsed = !this.isCollapsed
    },
    onCollapse: function () {
      let element = this.baseElement
      element.classList.add('header-collapsed')
      this.$emit('collapsed-status-changed', true)
    },
    onExpand: function () {
      let element = this.baseElement
      element.classList.remove('header-collapsed')
      this.$emit('collapsed-status-changed', false)
    }
  },
  computed: {
    baseElement: function () {
      return this.parentSelector === '' ? document.querySelector('#' + this.identity).parentElement : document.querySelector(this.parentSelector)
    },
    identity: function () {
      return 'section-header-' + this.instanceName
    }
  },
  watch: {
    isCollapsed: function (newValue, oldValue) {
      newValue ? this.onCollapse() : this.onExpand()
    },
    collapsed: function (newValue, oldValue) {
      this.isCollapsed = this.collapsed
    }
  },
  mounted: function () {
    this.isCollapsed = this.collapsed
    this.isCollapsed ? this.onCollapse() : this.onExpand()
  }
}
</script>

<style scoped>

.xp-icon-minus {
  font-size:1.25em;
  vertical-align:middle;
  margin-left: 0.5rem;
}

.xp-icon-plus {
  font-size:1.25em;
  vertical-align:middle;
  margin-left: 0.5rem;
}

.ellipsis {
  width:auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

</style>

<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Add Accessory Bin</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmit" @change="onFormChange">
      <div class="columns is-multiline">
        <div class="column is-3">
          <div class="field">
            <label class="label">Code<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="control">
              <input name="code" data-vv-as="code" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('code') }" type="text" v-model="code">
              <span v-show="errors.has('code')" class="help is-danger">
                <i v-show="errors.has('code')" class="fas fa-exclamation-triangle"></i> {{ errors.first('code') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label class="label">Description</label>
            <div class="control">
              <input name="description" data-vv-as="description" v-validate="''" :class="{'input': true, 'is-danger': errors.has('description') }" type="text" v-model="description">
              <span v-show="errors.has('description')" class="help is-danger">
                <i v-show="errors.has('description')" class="fas fa-exclamation-triangle"></i> {{ errors.first('description') }}
              </span>
            </div>
          </div>
        </div>
        <div class="column is-6">
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Location</label>
            <div class="control">
              <input name="location" data-vv-as="location" v-validate="''" :class="{'input': true, 'is-danger': errors.has('location') }" type="text" v-model="location">
              <span v-show="errors.has('location')" class="help is-danger">
                <i v-show="errors.has('location')" class="fas fa-exclamation-triangle"></i> {{ errors.first('location') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  type="button" @click="validateBeforeSubmit">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>
  </section>
</template>

<script>
import accessoryBinService from '@/services/accessoryBinService'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import accessoryBinMixin from '@/mixins/accessoryBin/index'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'NewAccessoryBin',
  mixins: [validateBeforeSubmitMixin, accessoryBinMixin],
  props: {
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full-screen'
    },
    startWith: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      id: 0,
      name: '',
      code: this.startWith,
      location: '',
      description: '',
      query: '',
      page: 1,
      sortField: 'code',
      sortOrder: 'desc',
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer'])
  },
  methods: {
    onCancel: function () {
      this.$emit('on-cancel')
      if (this.mode === 'full-screen') {
        this.onComplete()
      }
    },
    onComplete: function () {
      this.$router.push({name: 'ListAccessoryBin'})
    },
    onSave: function () {
      var accessoryBinModel = {
        isCreate: true,
        subscriberId: this.$store.state.user.currentUser.subscriberId,
        dealerId: this.currentDealer.id,
        code: this.code,
        location: this.location || '',
        description: this.description || '',
        status: 'Active'
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      accessoryBinService.save(accessoryBinModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.$emit('on-save', response.accessoryBin)
          this.onChangesSaved()
          if (this.mode === 'full-screen') {
            this.onComplete()
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    }
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

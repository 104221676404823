export default {
  total: 2,
  results: [
    {
      id: 1,
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 1,
      dealerName: 'Ferrari',
      code: 'BIN1231',
      location: 'Service Department',
      description: 'This is a test bin.'
    },
    {
      id: 2,
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 2,
      dealerName: 'Maserati',
      code: 'BIN54664',
      location: 'Parts Department',
      description: 'This is another test bin.'
    }
  ]
}

import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/vehiclemakes',
    name: 'ListVehicleMakes',
    component: () => import('@/components/configuration/vehicleMakes/ListVehicleMakes'),
    beforeEnter: requiresAuth
  },
  {
    name: 'VehicleMakesQuickView',
    path: '/configuration/vehicleMakes/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/vehicleMakes/ViewVehicleMake')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/vehiclemakes/new',
    name: 'NewVehicleMake',
    component: () => import('@/components/configuration/vehicleMakes/NewVehicleMake'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/vehiclemakes/view/:id',
    name: 'ViewVehicleMake',
    component: () => import('@/components/configuration/vehicleMakes/ViewVehicleMake'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/vehiclemakes/edit/:id',
    name: 'EditVehicleMake',
    component: () => import('@/components/configuration/vehicleMakes/EditVehicleMake'),
    beforeEnter: requiresAuth
  }
]

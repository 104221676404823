<template>
  <div class="pod tile is-parent is-narrow">
    <article class="tile is-child box is-radiusless is-paddingless">
      <h4 class="has-text-weight-bold">{{title}}<i class="fas fa-ellipsis-v icon-more"></i><i style="display: inline-block;" class="fas fa-sync" :class="{'fa-spin': isLoading}" @click="onRefresh"></i>
      </h4>
      <v-chart :option="graphData" :init-option="initOptions" ref="bar" theme="ovilia-green" auto-resize style="width:100%;height:80vh" v-if="this.value"/>
      <div v-else class="subtitle is-7" style="height:100%; display: flex;align-items: center;justify-content: center">
        <span style="margin-bottom:10px">No data available</span>
      </div>
    </article>
  </div>
</template>

<script>

export default {
  name: 'ChartPod',
  props: {
    type: {
      type: String,
      default: 'bar'
    },
    value: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Chart Pod'
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: this.$uuid.v4(),
      active: true,
      initOptions: {
        renderer: 'canvas'
      }
    }
  },
  computed: {
    graphData: function () {
      return {
        grid: {
          show: true,
          containLabel: true
        },
        legend: {},
        toolbox: {
          orient: 'vertical',
          show: true,
          feature: {
            saveAsImage: {
              title: 'Export'
            }
          }
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        dataset: {
          // Provide data.
          dimensions: ['Type', ...this.value.series],
          source: () => {
            let result = this.value.labels.map((a, b) => {
              return [a, ...this.value.data.map((x, y, d) => {
                return d[b]
              })]
            })
            return result
          }
        },
        // Declare X axis, which is a category axis, mapping
        // to the first column by default.
        xAxis: {
          name: 'Type',
          show: true,
          type: 'category',
          boundaryGap: true,
          axisLabel: {
            show: true
          },
          axisTick: {
            alignWithLabel: true
          }
        },
        // Declare Y axis, which is a value axis.
        yAxis: () => {
          return [...this.value.series.map((x, y) => {
            return [
              {
                show: y === 0,
                type: 'value',
                scale: false,
                name: '',
                axisLabel: {
                  show: true,
                  formatter: this.$formatCurrency
                }
              }
            ]
          })]
        },
        // Declare several series, each of them mapped to a
        // column of the dataset by default.
        series: () => {
          return [...this.value.series.map((x, y) => {
            return [
              {
                type: 'bar',
                barGap: '0%',
                label: {
                  show: false
                },
                itemStyle: {
                  color: '#baddd6',
                  opacity: 0.7
                }
              }
            ]
          })
          ]
        }
      }
    }
  },
  methods: {
    onRefresh: function () {
      this.$emit('on-refresh')
    }
  }
}
</script>

<style scoped>
</style>

import logger from '@/services/loggingService'

const externalWindowService = {
  postMessage (t, d) {
    logger.log('Message posted to parent iframe. Type : ' + t + ', Data : ' + d)
    window.parent.postMessage({type: t, data: d}, '*')
  },
  isFrameReady (isReady) {
    logger.log('Message posted to parent iframe. IsSystemReady : ' + isReady)
    window.parent.postMessage({type: 'is-frame-ready', isSystemReady: isReady}, '*')
  },
  onCancel () {
    logger.log('Message posted to parent iframe onCancel.')
    window.parent.postMessage({type: 'on-cancel', data: undefined}, '*')
  }
}

export default externalWindowService

<template>
  <footer :class="{'content-footer': true, 'inactive': !isActive, 'menu-active':!isSideBarActive}">
    <div class="columns is-mobile">
      <div class="column is-4">
        <portal-target name="footer-toolbar-left" class="actions" @change="onPortalLeftChanged" slim/>
      </div>
      <div class="column is-4">
        <portal-target name="footer-toolbar-middle" @change="onPortalMiddleChanged" slim/>
      </div>
      <div class="column is-4 is-flex side-padding-md" style="justify-content: flex-end; align-items: center;">
        <portal-target name="footer-toolbar-right"  @change="onPortalRightChanged" slim/>
      </div>
    </div>
    <portal to="main-footer-spacer" :disabled="!isActive">
      <div style="padding:20px;"></div>
    </portal>
  </footer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  name: 'AppFooter',
  data () {
    return {
      leftCount: 0,
      midCount: 0,
      rightCount: 0
    }
  },
  computed: {
    ...mapGetters(['isToolBarActive', 'hasFeatureAccess', 'currentUser', 'isSideBarActive']),
    isActive: function () {
      return this.isToolBarActive && this.totalActive > 0
    },
    totalActive: function () {
      return this.leftCount + this.midCount + this.rightCount
    }
  },
  methods: {
    ...mapActions(['setToolBarState']),
    onPortalLeftChanged: function (newContent, oldContent) {
      if (newContent) {
        this.leftCount = 1
      } else {
        this.leftCount = 0
      }
    },
    onPortalMiddleChanged: function (newContent, oldContent) {
      if (newContent) {
        this.midCount = 1
      } else {
        this.midCount = 0
      }
    },
    onPortalRightChanged: function (newContent, oldContent) {
      if (newContent) {
        this.right = 1
      } else {
        this.rightCount = 0
      }
    }
  }
}
</script>

function documentPositionComparator (a, b) {
  if (a === b) {
    return 0
  }
  var position = a.compareDocumentPosition(b)
  if (position & Node.DOCUMENT_POSITION_FOLLOWING || position & Node.DOCUMENT_POSITION_CONTAINED_BY) {
    return -1
  } else if (position & Node.DOCUMENT_POSITION_PRECEDING || position & Node.DOCUMENT_POSITION_CONTAINS) {
    return 1
  } else {
    return 0
  }
}

function sortByDocumentAppearence (ids) {
  var elementArray = ids.map((x) => document.querySelector('[data-vv-id="' + x + '"]'))
  return elementArray.sort(documentPositionComparator)
}

function addPathSupport () {
  if (!('path' in Event.prototype)) {
    Object.defineProperty(Event.prototype, 'path', {
      get: function () {
        var path = []
        var currentElem = this.target
        while (currentElem) {
          path.push(currentElem)
          currentElem = currentElem.parentElement
        }
        if (path.indexOf(window) === -1 && path.indexOf(document) === -1) { path.push(document) }
        if (path.indexOf(window) === -1) { path.push(window) }
        return path
      }
    })
  }
}
function addClosestSupport () {
  if (!Element.prototype.matches) {
    Element.prototype.matches = Element.prototype.msMatchesSelector || Element.prototype.webkitMatchesSelector
  }

  if (!Element.prototype.closest) {
    Element.prototype.closest = function (s) {
      var el = this
      if (!document.documentElement.contains(el)) return null
      do {
        if (el.matches(s)) return el
        el = el.parentElement || el.parentNode
      } while (el !== null && el.nodeType === 1)
      return null
    }
  }
}

function byClassName (el, query) {
  if (el && el.className) {
    return el.className !== '' && el.className.indexOf(query) >= 0
  }
  return undefined
}

function closestAncestor (el, fn, query) {
  return el && (fn(el, query) ? el : closestAncestor(el.parentNode, fn, query))
}

var scrollTimer = null

function scrollHandler (force) {
  if (scrollTimer !== null) {
    clearTimeout(scrollTimer)
  }
  scrollTimer = setTimeout(function () {
    var mobileSorter = document.getElementsByClassName('table-mobile-sort')[0]
    var isMobileMode = mobileSorter && getComputedStyle(mobileSorter).display !== 'none'
    if (isMobileMode) {
      return
    }
    var headerElements = document.getElementsByTagName('THEAD')
    var bodyElements = document.getElementsByTagName('TBODY')
    if (headerElements.length > 0 && bodyElements.length > 0) {
      let table = document.getElementsByClassName('table')[0]
      let header = headerElements[0]
      var body = bodyElements[0]
      var isAlreadyAdded = headerElements.length > 1 || header.classList.contains('fc-head')
      let headerRow = header.firstChild
      let dataRow = body.querySelector('tr:not(.group-row):not(.inventory-group-row):not(.deal-group-row)')
      if (table && header && dataRow) {
        var distanceToTop = table.getBoundingClientRect().top
        if (distanceToTop <= 0) {
          if (isAlreadyAdded && force === true) {
            var est = document.getElementsByClassName('sticky')
            var ec = est.length > 0 ? est[0] : undefined
            if (ec) {
              ec.parentNode.removeChild(ec)
              isAlreadyAdded = false
            }
          }

          if (!isAlreadyAdded) {
            var clone = window.$(header).clone(true, true)
            clone = clone.length > 0 ? clone[0] : undefined
            if (clone) {
              var children = headerRow.children
              for (var i = 0; i < children.length; i++) {
                var clonedChild = clone.firstChild.children[i]
                if (clonedChild.nodeType === 1) {
                  clonedChild.style.width = (children[i].clientWidth + 1) + 'px'
                }
              }
              table.appendChild(clone)
              clone.classList.add('sticky')
            }
          }
        } else {
          var existingStickyItems = document.getElementsByClassName('sticky')
          var existingClone = existingStickyItems.length > 0 ? existingStickyItems[0] : undefined
          if (existingClone) {
            existingClone.parentNode.removeChild(existingClone)
          }
        }
      }
    }
  }, 100)
}

function globalInit (Vue) {
  Vue.prototype.$defaultScrollIntoViewOptions = {
    behavior: 'smooth',
    block: 'start',
    inline: 'nearest'
  }
  Vue.prototype.$isMobile = window.innerWidth <= 700
  Vue.prototype.$goToValidationError = function (el) {
    var offensiveElement = this.$el.querySelector('[data-vv-id="' + el + '"]')
    var closest = closestAncestor(offensiveElement, byClassName, 'field')
    if (closest) {
      closest.scrollIntoView(this.$defaultScrollIntoViewOptions)
    } else if (offensiveElement) {
      offensiveElement.parentElement.scrollIntoView(this.$defaultScrollIntoViewOptions)
    }
  }
  Vue.prototype.$goToFirstValidationError = function (errors) {
    var sorted = sortByDocumentAppearence(errors.filter((x) => x.id).map((x) => x.id))
    var el = sorted[0].getAttribute('data-vv-id')
    var offensiveElement = this.$el.querySelector('[data-vv-id="' + el + '"]')
    if (!offensiveElement) {
      offensiveElement = document.querySelector('[data-vv-id="' + el + '"]')
    }
    var closest = closestAncestor(offensiveElement, byClassName, 'field')
    if (closest) {
      closest.scrollIntoView(this.$defaultScrollIntoViewOptions)
    } else {
      if (offensiveElement && offensiveElement.parentElement) {
        offensiveElement.parentElement.scrollIntoView(this.$defaultScrollIntoViewOptions)
      }
    }
  }
  Vue.prototype.$goToErrorBlock = function (el) {
    if (el) {
      el.scrollIntoView(this.$defaultScrollIntoViewOptions)
    }
  }
  Vue.prototype.$goToBlock = function (el, scrollOptions) {
    if (el) {
      el.scrollIntoView(scrollOptions || this.$defaultScrollIntoViewOptions)
    }
  }
  Vue.prototype.$markDirty = function () {
    if (this.$el) {
      let form = this.$el.closest('form')
      if (form) {
        var event = new Event('change')
        form.dispatchEvent(event)
      }
    }
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  Vue.prototype.$formatCurrency = function (value) {
    if (value && value !== '') {
      return formatter.format(value)
    }
    return '-.--'
  }

  Vue.filter('number', function (value) {
    return new Intl.NumberFormat('en-US', {
      style: 'decimal'
    }).format(value)
  })

  Vue.prototype.$toTitleCase = function (str) {
    return str.toLowerCase().split(' ').map(function (word) {
      if (word.length > 0) {
        if (word.length === 1) {
          return word
        } else {
          return word.replace(word[0], word[0].toUpperCase())
        }
      } else {
        return ''
      }
    }).join(' ')
  }
  Vue.prototype.$supportsGlobalEvent = 'event' in window
  Vue.prototype.$handleScroll = scrollHandler

  Vue.prototype.$globalMoneyFormat = {
    decimal: '.',
    thousands: ',',
    prefix: '',
    suffix: '',
    precision: 2,
    masked: false,
    allowBlank: true
  }

  addClosestSupport()
  addPathSupport()
}

export default globalInit

<template>
    <div class="is-inline-block">
      <div class="is-inline-block">
        <div class="quick-action">
          <button type="button" :class="getColorClassByWorkflowStatus" class="button" @click="updateCreditApplicationModalState(true)" :title="getTitle">
            <i class="icon fal fa-credit-card-front fa-lg" v-if="!isLoading"/>
            <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
          </button>
        </div>
      </div>
      <portal to="global-modal-portal" v-if="isDialogOpen">
        <b-modal :active.sync="isDialogOpen" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <div class="subtitle is-6 has-text-centered has-text-info is-marginless">{{headerTitle}}</div>
            </header>
            <section class="modal-card-body view-container" style="padding: 12px; width: 769px !important;">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
              <div v-if="latestCreditApplication && (latestCreditApplication.applicationStatus === 'Pending' || latestCreditApplication.applicationStatus === 'Completed' || latestCreditApplication.applicationStatus === 'Failed')">
                <b-tabs size="is-small" :animated="false" type="is-toggle" v-model="tabIndex">
                  <b-tab-item label="Definition" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                    <section style="padding: 12px">
                      <div class="columns">
                        <div class="column is-12">
                          <form data-vv-scope="recipient-form">
                            <div class="field">
                              <label class="label">Recipients<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <multiselect
                                :multiple="true"
                                :taggable="true"
                                v-validate="{'required': true}"
                                data-vv-scope="recipient-form"
                                data-vv-as="recipient"
                                data-vv-validate-on="input|close"
                                :class="{'is-danger': errors.has('recipient-form.recipients') }"
                                @tag="onRecipientAdded"
                                name="recipients"
                                label="fullName"
                                trackBy="email"
                                tagPlaceholder="Enter to add email address"
                                v-model="selectedRecipients"
                                :options="selectedRecipients"
                                :disabled="true">
                              </multiselect>
                              <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-6 field" v-if="isCoCustomerSpouse">
                          <p class="has-text-danger is-pulled-left" style="margin-left:10px;">
                            {{'Spouse is co-applicant, only primary applicant will be mailed.'}}
                          </p>
                        </div>
                        <div class="column field" :class="isCoCustomerSpouse ? 'is-6' : 'is-12'">
                          <a class="is-link is-pulled-right" @click="onEditCustomer(currentDeal.purchaserId)" style="margin-left:10px;">
                            {{this.currentDeal.purchaserContactName}}
                          </a>
                          <a v-if="currentDeal.hasCoApplicant && !currentDeal.isSpouseCoApplicant" class="is-link is-pulled-right" @click="onEditCustomer(currentDeal.coCustomerId)" style="margin-left:10px;">
                            {{this.currentDeal.coCustomerName}}
                          </a>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-12">
                          <div class="field">
                            <label class="label">Subject</label>
                            <div class="control">
                              <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-12">
                          <div class="field">
                            <label class="label">Message (optional)</label>
                            <div class="control">
                              <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </b-tab-item>
                  <b-tab-item label="History" icon-pack="fas" icon="fas fa-history fa-sm">
                    <section style="padding: 12px;">
                      <div class="box">
                        <div class="columns">
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Status: </label>
                              <div class="field">
                                {{ latestCreditApplication.applicationStatus || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Last Failure Reason: </label>
                              <div class="field">
                                {{ latestCreditApplication.lastFailureReason || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </b-tab-item>
                  <b-tab-item label="Completion Info" icon-pack="fas" icon="fas fa-info fa-sm">
                    <div class="notification is-success is-light" v-if="showCreditAppCompletedMsg">
                      <button class="delete"  @click="showCreditAppCompletedMsg = false"></button>
                      Customer has completed and submitted credit application for processing, below are the tracking details of where the credit application has been completed
                    </div>
                    <section style="padding: 12px;">
                      <div class="box">
                        <div class="columns">
                          <div class="column is-12">
                              <label class="label">Completed Date: </label>
                              <div class="field">
                                {{ completedDate }}
                              </div>
                          </div>
                        </div>
                      </div>
                      <div class="box">
                        <div class="columns">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">IP Address: </label>
                              <div class="field">
                                {{ latestCreditApplication.clientIpAddress || "" }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Country Map Error: </label>
                              <div class="field">
                                {{ latestCreditApplication.error || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">ISP: </label>
                              <div class="field">
                                {{ latestCreditApplication.isp || "" }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Organization: </label>
                              <div class="field">
                                {{ latestCreditApplication.organization || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Country Code: </label>
                              <div class="field">
                                {{ latestCreditApplication.countryCode || "" }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Country Name: </label>
                              <div class="field">
                                {{ latestCreditApplication.countryName || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Region Code: </label>
                              <div class="field">
                                {{ latestCreditApplication.regionCode || "" }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Region Name: </label>
                              <div class="field">
                                {{ latestCreditApplication.region || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Metro Code: </label>
                              <div class="field">
                                {{ latestCreditApplication.metroCode || "" }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Area Code: </label>
                              <div class="field">
                                {{ latestCreditApplication.areaCode || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">City: </label>
                              <div class="field">
                                {{ latestCreditApplication.city || "" }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Postal: </label>
                              <div class="field">
                                {{ latestCreditApplication.postal || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Latitude: </label>
                              <div class="field">
                                {{ latestCreditApplication.latitude || "" }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Longitude: </label>
                              <div class="field">
                                {{ latestCreditApplication.longitude || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Local Latitude: </label>
                              <div class="field">
                                {{ latestCreditApplication.localLatitude || "" }}
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Local Longitude: </label>
                              <div class="field">
                                {{ latestCreditApplication.localLongitude || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Acceptance Address: </label>
                              <div class="field">
                                {{ latestCreditApplication.acceptanceAddress || "" }}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </b-tab-item>
                </b-tabs>
              </div>
              <div v-else>
                <div class="columns">
                  <div class="column is-12">
                    <form data-vv-scope="recipient-form">
                      <div class="field">
                        <label class="label">Recipients<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <multiselect
                          :multiple="true"
                          :taggable="true"
                          v-validate="{'required': true}"
                          data-vv-scope="recipient-form"
                          data-vv-as="recipient"
                          data-vv-validate-on="input|close"
                          :class="{'is-danger': errors.has('recipient-form.recipients') }"
                          @tag="onRecipientAdded"
                          name="recipients"
                          label="fullName"
                          trackBy="email"
                          tagPlaceholder="Enter to add email address"
                          v-model="selectedRecipients"
                          :options="selectedRecipients"
                          :disabled="true">
                        </multiselect>
                        <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                      </div>
                    </form>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-6 field" v-if="isCoCustomerSpouse">
                    <p class="has-text-danger is-pulled-left" style="margin-left:10px;">
                      {{'Spouse is co-applicant, only primary applicant will be mailed.'}}
                    </p>
                  </div>
                  <div class="column field" :class="isCoCustomerSpouse ? 'is-6' : 'is-12'">
                    <a class="is-link is-pulled-right" @click="onEditCustomer(currentDeal.purchaserId)" style="margin-left:10px;">
                      {{this.currentDeal.purchaserContactName}}
                    </a>
                    <a v-if="currentDeal.hasCoApplicant && !currentDeal.isSpouseCoApplicant" class="is-link is-pulled-right" @click="onEditCustomer(currentDeal.coCustomerId)" style="margin-left:10px;">
                      {{this.currentDeal.coCustomerName}}
                    </a>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-12">
                    <div class="field">
                      <label class="label">Subject</label>
                      <div class="control">
                        <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                      </div>
                    </div>
                  </div>
                </div>
                <div class="columns">
                  <div class="column is-12">
                    <div class="field">
                      <label class="label">Message (optional)</label>
                      <div class="control">
                        <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <div class="columns" style="width: 100%">
                <div class="column is-12">
                  <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onEmailCreditApplication('Individual')" type="button">Send Individual Application</button>
                  <button v-if="canSendBusinessCreditApplication" class="button is-primary" :class="{'is-loading': isSaving }" @click="onEmailCreditApplication('Business')" type="button">Send Business Application</button>
                  <button class="button is-danger" @click="updateCreditApplicationModalState(false)" type="button">Cancel</button>
                </div>
              </div>
            </footer>
          </div>
        </b-modal>
      </portal>

      <portal to="global-modal-portal" v-if="editCustomerActive">
        <b-modal :active.sync="editCustomerActive" scroll="keep" :has-modal-card="true" id="edit-customer-modal">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
            </header>
            <section class="modal-card-body">
              <edit-customer :customerId="editCustomerId" mode="modal" actionPortal="modal-footer" headerPortal="modal-header" v-on:on-save="onCustomerSaved" v-on:on-cancel="onCustomerCancelled"></edit-customer>
            </section>
            <footer class="modal-card-foot">
              <portal-target name="modal-footer" class="actions" slim/>
          </footer>
          </div>
        </b-modal>
      </portal>
      <portal to="global-modal-portal" v-if="isActiveCreditApplication">
        <b-modal :active.sync="isActiveCreditApplication" scroll="keep" :has-modal-card="true" id="active-credit-application-modal">
          <div class="modal-card">
            <header class="modal-card-head">
              <div class="subtitle is-6 has-text-centered has-text-info is-marginless">Possible Credit Application Match Found</div>
            </header>
            <section class="modal-card-body">
              {{ creditApplicationMatch.creditApplicationInfo }}
              <span v-if="!creditApplicationMatch.isIncomplete">
                <span v-if="creditApplicationMatch.isDifferentDeal">
                  Would you like to link this deal to credit application and re-send?
                </span>
                <span v-else>
                  Would you like to re-send credit application?
                </span>
              </span>
              <span v-else>
                A credit application for the current purchaser exists for a different deal, would you like to set it to this deal and re-send to customer?
              </span>
            </section>
            <footer class="modal-card-foot">
              <div class="columns" style="width: 100%">
                <div class="column is-12">
                  <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onUpdateCreditAppStatus()" type="button">Send</button>
                  <button class="button is-danger" @click="isActiveCreditApplication = false" type="button">Cancel</button>
                </div>
              </div>
          </footer>
          </div>
        </b-modal>
      </portal>
    </div>
</template>

<script>
import moment from 'moment'
import eventBus from '@/eventBus'
import utilitiesMixin from '@/mixins/generic/utilities'
import customerService from '@/services/customerService'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import EditCustomer from '@/components/configuration/customers/EditCustomer'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'DealCustomerCreditApplicationAction',
  mixins: [validateBeforeSubmitMixin, utilitiesMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'edit-customer': EditCustomer
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isDialogOpen: false,
      instanceId: this.$uuid.v4(),
      selectedRecipients: [],
      optionalMessage: '',
      overridingSubject: '',
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      editCustomerActive: false,
      editCustomerId: 0,
      tabIndex: 0,
      latestCreditApplication: null,
      currentCustomer: null,
      termsAccepted: false,
      showCreditAppCompletedMsg: true,
      isLoading: false,
      isActiveCreditApplication: false,
      creditApplicationMatch: null
    }
  },
  computed: {
    ...mapUserGetters(['currentUser']),
    ...mapConfigGetters(['definedTypes']),
    headerTitle: function () {
      return 'Send Credit Application'
    },
    canSendBusinessCreditApplication: function () {
      return this.currentDeal && (!this.currentDeal.coCustomerId || this.currentDeal.coCustomerId === 0)
    },
    completedDate: function () {
      if (this.latestCreditApplication && this.latestCreditApplication.completedAt) {
        return moment(this.latestCreditApplication.completedAt).format('YYYY/MM/DD')
      } else {
        return ''
      }
    },
    isCoCustomerSpouse: function () {
      return this.currentDeal.coCustomerRelationship === 'Spouse' && this.currentDeal.coCustomerId > 0
    },
    getColorClassByWorkflowStatus: function () {
      var result = 'has-text-grey'
      if (this.currentCustomer && this.currentCustomer.creditApplicationDealId === this.currentDeal.id) {
        if (this.currentCustomer.creditApplicationStatus === 'Sent' || this.currentCustomer.creditApplicationStatus === 'Viewed' || this.currentCustomer.creditApplicationStatus === 'Pending') {
          result = 'has-text-orange'
        } else if (this.currentCustomer.creditApplicationStatus === 'Completed') {
          result = 'has-text-success'
        } else if (this.currentCustomer.creditApplicationStatus === 'Failed') {
          result = 'has-text-danger'
        }
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getTitle: function () {
      let result = 'Click to send out credit application form to customer.'
      if (this.currentCustomer) {
        if (this.currentCustomer.creditApplicationStatus === 'Sent' || this.currentCustomer.creditApplicationStatus === 'Viewed' || this.currentCustomer.creditApplicationStatus === 'Pending') {
          result = 'Credit application has been sent.'
        } else if (this.currentCustomer.creditApplicationStatus === 'Completed') {
          result = 'Credit application completed.'
        } else if (this.currentCustomer.creditApplicationStatus === 'Failed') {
          result = 'Credit application has failed.'
        }
      }
      return result
    },
    showResend: function () {
      return this.creditApplicationMatch && this.creditApplicationMatch.hasCreditApplication && this.creditApplicationMatch.isDifferentDeal
    },
    hasCreditApplication: function () {
      return this.creditApplicationMatch && this.creditApplicationMatch.hasCreditApplication
    },
    isDifferentDeal: function () {
      return this.creditApplicationMatch && this.creditApplicationMatch.isDifferentDeal
    },
    isIncomplete: function () {
      return this.creditApplicationMatch && this.creditApplicationMatch.isIncomplete
    }
  },
  methods: {
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data,
        email: data
      }

      this.selectedRecipients.push(recipient)
    },
    clearAllRecipients: function () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedRecipients = []
      this.currentCustomer = null
    },
    updateCreditApplicationModalState: function (state) {
      this.isDialogOpen = state
      this.isActiveCreditApplication = false
      if (this.currentDeal.purchaserId !== null && state === true) {
        this.overridingSubject = 'Credit Application - Your info is required.'
        this.optionalMessage = 'Please navigate to our secure webpage to complete your credit application.'
        this.selectedRecipients = []

        if (this.currentDeal && (this.currentDeal.purchaserContactEmail !== '' || this.currentDeal.purchaserContactMobile !== '')) {
          let proposedName = this.currentDeal.purchaserContactName !== '' ? this.currentDeal.purchaserContactName : this.currentDeal.purchaserName
          var data = {
            id: this.currentDeal.purchaserId,
            mobile: this.currentDeal.purchaserContactMobile,
            email: this.currentDeal.purchaserContactEmail,
            fullName: proposedName + '(' + this.currentDeal.purchaserContactEmail + '  ' + this.currentDeal.purchaserContactMobile + ')',
            primaryApp: true,
            name: proposedName
          }
          this.selectedRecipients.push(data)
        }

        if (this.currentDeal.hasCoApplicant) {
          if (!this.isCoCustomerSpouse) {
            if (this.currentDeal.coCustomerEmail && (this.currentDeal.coCustomerEmail !== '' || this.currentDeal.coCustomerMobile !== '')) {
              this.selectedRecipients.push({
                id: this.currentDeal.coCustomerId,
                mobile: this.currentDeal.coCustomerMobile,
                email: this.currentDeal.coCustomerEmail,
                fullName: this.currentDeal.coCustomerName + ' (' + this.currentDeal.coCustomerEmail + ' ' + this.currentDeal.coCustomerMobile + ')',
                primaryApp: false,
                name: this.currentDeal.coCustomerName
              })
            }
          }
        }

        var customerCreditApplicationMatchModel = {
          dealId: this.currentDeal.id,
          customerId: this.currentDeal.purchaserId,
          creditApplicationInfo: '',
          hasCreditApplication: false,
          isDifferentDeal: false
        }

        customerService.creditApplicationExistsCheck(customerCreditApplicationMatchModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.creditApplicationMatch = response.customerCreditApplicationMatchModel
            if (this.hasCreditApplication) {
              if (this.isIncomplete && this.isDifferentDeal) {
                this.isActiveCreditApplication = true
              }
              if (!this.isIncomplete) {
                this.isActiveCreditApplication = true
              }
            }
          }
        }).catch((error) => {
          this.isSaving = false
          this.serverErrorMessage = error.message
        })
      }

      this.latestCreditApplication = null
      this.loadData()

      if (state === false) {
        this.clearAllRecipients()
      }
    },
    onUpdateCreditAppStatus: function () {
      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      var customerCreditApplicationRequestModel = {
        dealId: this.currentDeal.id,
        customerId: this.currentDeal.purchaserId
      }

      customerService.updateCreditApplication(customerCreditApplicationRequestModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.clearAllRecipients()
          this.isDialogOpen = false
          this.isActiveCreditApplication = false
          eventBus.$emit('reload-deal-list')
          eventBus.$emit('reload-deal')
        }
      }).catch((error) => {
        this.isSaving = false
        this.serverErrorMessage = error.message
      })
    },
    onEmailCreditApplication: function (applicationType) {
      if (this.currentDeal) {
        let emailAddresses = this.selectedRecipients.map(e => e.email).join(', ')
        let primaryApplicantMobile = (this.selectedRecipients.find(x => x.primaryApp)) ? this.selectedRecipients.find(x => x.primaryApp).mobile : ''
        let coApplicantMobile = (this.selectedRecipients.find(x => !x.primaryApp)) ? this.selectedRecipients.find(x => !x.primaryApp).mobile : ''

        var customerCreditApplicationRequestModel = {
          dealId: this.currentDeal.id,
          customerId: this.currentDeal.purchaserId,
          userId: this.currentUser.id,
          dealerId: this.currentDeal.dealerId,
          toEmailAddress: emailAddresses,
          subject: this.overridingSubject,
          content: this.optionalMessage,
          type: null,
          hasCoApplicant: this.currentDeal.coCustomerId > 0 && !this.isCoCustomerSpouse,
          primaryApplicantMobile: primaryApplicantMobile,
          coApplicantMobile: coApplicantMobile,
          creditApplicationType: applicationType
        }

        this.isSaving = true
        this.serverErrorMessage = ''
        this.serverErrors = []

        customerService.createCreditApplication(customerCreditApplicationRequestModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.clearAllRecipients()
            this.isDialogOpen = false
            eventBus.$emit('reload-deal-list')
            eventBus.$emit('reload-deal')
          }
        }).catch((error) => {
          this.isSaving = false
          this.serverErrorMessage = error.message
        })
      }
    },
    onCustomerSaved: function (editCustomer) {
      if (editCustomer.id === this.currentDeal.purchaserId) {
        this.currentDeal.purchaserContactEmail = editCustomer.email
        this.currentDeal.purchaserContactMobile = editCustomer.mobile
        this.currentDeal.purchaserContactName = editCustomer.firstName + ' ' + editCustomer.lastName
      } else if (editCustomer.id === this.currentDeal.coCustomerId) {
        this.currentDeal.coCustomerEmail = editCustomer.email
        this.currentDeal.coCustomerMobile = editCustomer.mobile
        this.currentDeal.coCustomerName = editCustomer.firstName + ' ' + editCustomer.lastName
      }
      this.updateCreditApplicationModalState(true)
      this.editCustomerId = 0
      this.editCustomerActive = false
      this.isDialogOpen = true
    },
    onCustomerCancelled: function () {
      this.editCustomerId = 0
      this.editCustomerActive = false
      this.isDialogOpen = true
    },
    onEditCustomer: function (recipientId) {
      this.editCustomerId = recipientId
      this.editCustomerActive = true
      this.isDialogOpen = false
    },
    loadData: function () {
      let customerId = this.currentDeal.purchaserId
      this.isLoading = true
      this.serverErrorMessage = ''
      this.serverErrors = []
      if (customerId !== null) {
        customerService.single(customerId).then(response => {
          this.termsAccepted = response.customer.isTermsAccepted
          this.currentCustomer = response.customer
          this.isLoading = false
        }).catch((error) => {
          this.isTermsAccepted = false
          this.isLoading = false
          this.serverErrorMessage = error.message
        })

        this.isLoading = true
        customerService.getCreditApplicationByCustomerId(customerId).then(response => {
          this.latestCreditApplication = null
          this.latestCreditApplication = response.creditApplication
          this.isLoading = false
        }).catch((error) => {
          this.latestCreditApplication = null
          this.isLoading = false
          this.serverErrorMessage = error.message
        })
      }
    }
  },
  mounted: function () {
    this.loadData()
  },
  watch: {
    currentDeal: function (newValue, oldValue) {
      if (newValue) {
        this.loadData()
      }
    }
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .verification-container {
    margin-bottom: 3em;
  }

  .status-container {
    margin-bottom: 3em;
  }

</style>

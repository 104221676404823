<template>
  <div class="is-inline-block" v-if="hasServiceRequestAccess">
    <div class="is-inline-block" v-if="hasServiceRequestAccess">
      <div class="quick-action">
        <button class="button" type="button" @click="onAddServiceRequest()" :title="addBtnTitle" :disabled="isAddBtnDisabled">
          <b-icon pack="fal" icon="plus" custom-size="fa-lg"></b-icon>
        </button>
      </div>
    </div>
    <portal to="global-modal-portal" v-if="isAddingServiceRequest">
      <b-modal :active.sync="isAddingServiceRequest" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width:auto;">
          <header class="modal-card-head">
            <p class="modal-card-title">Adding Service Request</p>
          </header>
          <section class="modal-card-body side-padding-sm small-top-padding small-bottom-padding" style="max-height: calc(100vh - 220px);">
            <template v-if="isRequestDefinition">
              <form data-vv-scope="service-request-form" class="view-container">
                <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
                <div class="box">
                  <section-header title="Vehicle Selection"></section-header>
                  <div class="columns is-mobile is-multiline">
                    <div class="column is-12">
                      <label class="label">Vehicle</label>
                      <div class="field" v-html="inventoryOverview"></div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Stock No</label>
                      <div class="field">
                        <a @click.stop.prevent="onStockNoClick(currentInventory)">
                          <span class="has-text-weight-semibold">{{ currentInventory.stockNo }}</span>
                        </a>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Vin No</label>
                      <div class="field">
                        {{currentInventory.vinNo}}
                      </div>
                    </div>
                  </div>
                </div>
                <div class="box">
                  <section-header title="Request Details"></section-header>
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <button-list-selector name="selectedServiceRequestType" scope="service-request-form" validateAs="service request type" label="Type"  v-model="selectedServiceRequestType" :availableValues="this.definedTypes.serviceRequestType.options" :hideInactiveValues="true" :activeValues="activeServiceRequestTypes"></button-list-selector>
                      <span v-show="selectedServiceRequestType === null" class="help is-danger"><i v-show="selectedServiceRequestType === null" class="fas fa-exclamation-triangle"></i> The service request type field is required</span>
                    </div>
                    <div class="column is-12">
                      <button-list-selector name="selectedServiceRequestStatus" scope="service-request-form" validateAs="status" label="Status"  v-model="selectedServiceRequestStatus" :availableValues="this.definedTypes.serviceRequestStatusType.options" :hideInactiveValues="true" :activeValues="activeServiceRequestStatusTypes"></button-list-selector>
                      <span v-show="selectedServiceRequestStatus === null" class="help is-danger"><i v-show="selectedServiceRequestStatus === null" class="fas fa-exclamation-triangle"></i> The status field is required.</span>
                    </div>
                    <div class="column is-4">
                      <button-list-selector  scope="service-request-form" validateAs="priority status" label="Priority" v-model="selectedPriority" :availableValues="allPriorities"></button-list-selector>
                      <span v-show="selectedPriority === null" class="help is-danger"><i v-show="selectedPriority === null" class="fas fa-exclamation-triangle"></i> The priority status field is required.</span>
                    </div>
                    <div class="column is-4">
                      <div class="field">
                        <label class="label">RO Number</label>
                        <div class="control">
                          <input name="roNumber" class="is-width-150" :class="{'input': true}" type="text" placeholder="" v-model="roNumber">
                        </div>
                      </div>
                    </div>
                    <div class="column is-4">
                      <label class="label">Due Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control">
                        <el-date-picker
                          data-vv-scope="service-request-form"
                          @change="onFormChange()"
                          name="requiredBy"
                          v-model="requiredBy"
                          type="date"
                          size="small"
                          data-vv-validate-on="input|change"
                          v-validate="'required'"
                          :class="{'is-danger': errors.has('service-request-form.requiredBy') }"
                          data-vv-as="due date"
                          placeholder="Click to select..."
                          popper-class="datepicker-container"
                          scope="service-request-form">
                        </el-date-picker>
                        <span v-show="errors.has('service-request-form.requiredBy')" class="help is-danger"><i v-show="errors.has('service-request-form.requiredBy')" class="fas fa-exclamation-triangle"></i> {{ errors.first('service-request-form.requiredBy') }}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="box">
                  <section-header :collapsed="isInspectionTypesCollapsed" title="Inspection Types Required"></section-header>
                  <div class="columns">
                    <div class="column is-12 no-h-padding">
                      <b-table
                        class="lists"
                        :mobile-cards="false"
                        :data="validServiceInspectionTypes"
                        @click="onEditServiceRequestInspectionType">

                        <template slot-scope="props">
                          <b-table-column field="name" label="Name" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ convertInspectionType(props.row.inspectionType) }}
                          </b-table-column>

                          <b-table-column field="filesAdded" label="Number of Files added" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ props.row.inspectionTypeAttachments.length }}
                          </b-table-column>
                        </template>

                        <template slot="empty">
                          <section class="section">
                            <div class="has-text-centered">
                              <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                              <p>No inspection types have been added.</p>
                            </div>
                          </section>
                        </template>
                      </b-table>
                      <button class="button is-info is-small small-top-margin" @click.prevent="addNewServiceRequestInspectionType()">Add Inspection Type</button>
                    </div>
                  </div>
                </div>

                <div class="box">
                  <section-header title="Work Items"></section-header>
                    <div class="columns">
                      <div class="column is-12 no-h-padding">
                        <b-table
                          class="lists"
                          :data="validServiceLines"
                          :mobile-cards="false"
                          @click="onEditServiceRequestLine">
                          <template slot-scope="props">
                            <b-table-column field="status" label="Status" :class="{'striked': props.row.delete === true && props.row.id > 0}">
                              <i class="icon fal fa-tools fa-sm" :class="getColorClassByServiceRequestLineStatus(props.row.serviceRequestLineStatusId)" title="Work Item Status"/>
                              {{ convertIdToStatusType(props.row.serviceRequestLineStatusId).name }}
                            </b-table-column>

                            <b-table-column field="workRequired" label="Description" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                              {{ props.row.workRequired }}
                            </b-table-column>

                            <b-table-column field="vehicleCost" label="Veh. Charge" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                              {{ props.row.vehicleCost | currency}}
                            </b-table-column>

                            <b-table-column field="vehicleHours" label="Veh. Hours" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                              {{ props.row.vehicleHours}}
                            </b-table-column>

                            <b-table-column field="manufacturerCost" label="Mfg. Cost" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                              {{ props.row.manufacturerCost | currency}}
                            </b-table-column>

                            <b-table-column field="manufacturerHours" label="Mfg. Hours" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                              {{ props.row.manufacturerHours }}
                            </b-table-column>

                          </template>
                          <template slot="empty">
                            <section class="section">
                              <div class="has-text-centered">
                                <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                                <p>No work items have been added.</p>
                              </div>
                            </section>
                          </template>
                        </b-table>
                        <button class="button is-info is-small small-top-margin" @click.prevent="addNewServiceRequestLine()">Create Work Item</button>
                      </div>
                    </div>
                </div>
              </form>
            </template>
            <template v-else-if="isInspectionTypeDefinition" class="box">
              <service-request-inspection-type
                useFooterPortal="modal-footer"
                :currentServiceRequestInspectionTypeId="currentServiceRequestInspectionTypeId"
                :forServiceRequest="currentServiceRequest"
                v-on:save-edit-service-request-inspection-type="onSaveEditServiceRequestInspectionType"
                v-on:save-new-service-request-inspection-type="onSaveServiceRequestInspectionType"
                v-on:cancel-service-request-inspection-type="onCancelServiceRequestInspectionType()">
              </service-request-inspection-type>
            </template>
            <template v-else class="box">
              <service-request-line
                useFooterPortal="modal-footer"
                :currentServiceRequestLineId="currentServiceRequestLineId"
                :viewModal="true"
                :forServiceRequest="currentServiceRequest"
                v-on:save-edit-service-request-line="onSaveEditServiceRequestLine"
                v-on:save-new-service-request-line="onSaveServiceRequestLine"
                v-on:cancel-service-request-line="onCancelServiceRequestLine()">
              </service-request-line>
            </template>
            <b-modal :active.sync="isCopyDeclinedLines" scroll="keep" width="640px" :has-modal-card="true" v-if="isCopyDeclinedLines" :canCancel="false">
              <div class="modal-card">
                <header class="modal-card-head">
                  <p class="modal-card-title">Copy Previously Declined Work Items</p>
                </header>
                <section class="modal-card-body" style="max-height: 600px">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      Would you like to add previously declined work to this repair order?
                    </div>
                    <div class="column is-12">
                      <b-table
                        class="table"
                        :data="previouslyDeclinedWorkItems">
                        <template slot-scope="props">
                          <b-table-column field="workRequired" label="Description of Work" class="service-request-event-column">
                            <span :class="{'is-striked': !props.row.isActive}">{{ props.row.workRequired }}</span>
                          </b-table-column>

                          <b-table-column field="id" label="Actions" class="service-request-event-column">
                            <b-checkbox type="is-success" v-model="props.row.isActive"></b-checkbox>
                          </b-table-column>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <button class="button is-primary" @click="onCopyPreviouslyDeclinedLines()">Yes</button>
                  <button class="button is-danger" @click="isCopyDeclinedLines = false">No</button>
                  <button class="button is-warning" style="margin-left: auto;" @click="onToggleAll">Toggle All</button>
                </footer>
              </div>
            </b-modal>
            <b-modal :active.sync="isLoadDefaultInspectionTypes" scroll="keep" width="640px" :has-modal-card="true" v-if="isLoadDefaultInspectionTypes" :canCancel="false">
              <div class="modal-card">
                <header class="modal-card-head">
                  <p class="modal-card-title">Load Default Dealer Inspection Types</p>
                </header>
                <section class="modal-card-body" style="max-height: 600px">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      Would you like to add default inspection types to this first repair order?
                    </div>
                    <div class="column is-12">
                      <b-table
                        class="table"
                        :data="defaultDealerInspectionTypes">
                        <template slot-scope="props">
                          <b-table-column field="inspectionType" label="Inspection Type" class="service-request-event-column">
                            <span :class="{'is-striked': !props.row.isActive}">{{ convertInspectionType(props.row.inspectionType) }}</span>
                          </b-table-column>
                          <b-table-column field="id" label="Actions" class="service-request-event-column">
                            <b-checkbox type="is-success" v-model="props.row.isActive"></b-checkbox>
                          </b-table-column>
                        </template>
                      </b-table>
                    </div>
                  </div>
                </section>
                <footer class="modal-card-foot">
                  <button class="button is-primary" @click="onCopyDefaultDealerInspectionTypes()">Yes</button>
                  <button class="button is-danger" @click="isLoadDefaultInspectionTypes = false">No</button>
                  <button class="button is-warning" style="margin-left: auto;" @click="onToggleAllInspectionTypes">Toggle All</button>
                </footer>
              </div>
            </b-modal>
          </section>
          <footer class="modal-card-foot">
            <button v-if="isRequestDefinition" class="button is-primary" :class="{'is-loading': isSaving }" @click="onSave()" type="button">Save</button>
            <button v-if="isRequestDefinition" class="button is-danger" @click="onCancel()" type="button">Cancel</button>
            <portal-target name="modal-footer" class="actions" slim/>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import serviceRequestService from '@/services/serviceRequestService'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import serviceRequestMixin from '@/mixins/serviceRequest/index'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'ServiceRequetCapture',
  mixins: [validateBeforeSubmitMixin, serviceRequestMixin],
  components: {
    'button-list-selector': ButtonListSelector,
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      mode: 0,
      isAddingServiceRequest: false,
      selectedPriority: null,
      requiredBy: null,
      selectedServiceRequestType: null,
      selectedServiceRequestStatus: null,
      notes: '',
      roNumber: '',
      currentServiceRequest: {
        id: 0,
        inventoryId: this.currentInventory.id,
        lines: [],
        inspectionTypes: [],
        notes: [],
        requestAttachments: []
      },
      requestAttachments: [],
      serverErrors: [],
      serverErrorMessage: '',
      previouslyDeclinedWorkItems: [],
      isCopyDeclinedLines: false,
      isSaving: false
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    inventoryOverview: function () {
      if (!this.currentInventory) {
        return ''
      }
      var result = ''
      result = this.currentInventory.name
      if (this.currentInventory.usage) {
        result += '<br /> ' + new Intl.NumberFormat().format(this.currentInventory.usage) + ' miles'
      } else {
        result += '<br /> Milage not captured.'
      }
      var exteriorColor = this.currentInventory.attributes.find((x) => x.name === 'ExteriorColor')
      var interiorColor = this.currentInventory.attributes.find((x) => x.name === 'InteriorColor')
      if (exteriorColor && exteriorColor.value) {
        result += '<br /> Ext. Color (' + exteriorColor.value + ')'
      }
      if (interiorColor && interiorColor.value) {
        result += '<br /> Int. Color (' + interiorColor.value + ')'
      }

      return result
    },
    hasServiceRequestAccess: function () {
      let allowedPermissions = ['servicerequest.create']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    allPriorities: function () {
      return this.definedTypes.serviceRequestPriorityType.options
    },
    allInspectionTypes: function () {
      var allTypes = []
      this.definedTypes.inspectionTypes.forEach((type) => {
        allTypes.push(type.name)
      })
      return allTypes
    },
    nonCancelledDeals: function () {
      return this.currentInventory.deals.length > 0 ? this.currentInventory.deals.filter((x) => x.type !== 'Cancelled' && x.items.find((y) => y.forInventory.id === this.currentInventory.id && y.dealItemType === 'Sold')) : []
    },
    deliveredOrSoldDeals: function () {
      return this.nonCancelledDeals.filter((x) => x.type === 'Booked' || x.type === 'Delivered')
    },
    hasDeliveredOrSoldDeals: function () {
      return this.deliveredOrSoldDeals.length > 0
    },
    addBtnTitle: function () {
      return this.hasDeliveredOrSoldDeals && !this.isAdmin ? 'Add disabled because of a linked delivered/booked deal' : 'Click to add a new service request.'
    },
    isAddBtnDisabled: function () {
      return this.hasDeliveredOrSoldDeals && !this.isAdmin
    },
    isAdmin () {
      return this.$store.getters['user/isInRole']('Admin Role')
    }
  },
  methods: {
    onToggleAll: function () {
      this.previouslyDeclinedWorkItems.forEach((item) => {
        item.isActive = !item.isActive
      })
    },
    onToggleAllInspectionTypes: function () {
      this.defaultDealerInspectionTypes.forEach((item) => {
        item.isActive = !item.isActive
      })
    },
    onCopyPreviouslyDeclinedLines: function () {
      this.previouslyDeclinedWorkItems.filter(x => x.isActive === true).forEach((item) => {
        item.serviceRequestInspectionTypeId = 0
        item.status = 0
        this.currentServiceRequest.lines.push(item)
      })

      this.isCopyDeclinedLines = false
    },
    onCopyDefaultDealerInspectionTypes: function () {
      this.defaultDealerInspectionTypes.filter(x => x.isActive === true).forEach((item) => {
        this.currentServiceRequest.inspectionTypes.push(item)
      })

      this.isLoadDefaultInspectionTypes = false
    },
    onAddServiceRequest: function () {
      this.errors.clear('service-request-form')
      this.isAddingServiceRequest = true
      this.setDefaults()
      this.loadDefaultInspectionTypes()
      this.loadPreviouslyDecliedWorkItems()
    },
    onCancel: function () {
      this.setDefaults()
      this.isAddingServiceRequest = false
    },
    loadPreviouslyDecliedWorkItems: function () {
      serviceRequestService.previouslyDeclinedWorkItemsByInventory(this.selectedInventory.id).then(response => {
        this.previouslyDeclinedWorkItems = response.results
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    onSave: function () {
      this.$validator.validateAll('service-request-form').then((result) => {
        if (result && this.selectedServiceRequestType !== null && this.selectedServiceRequestStatus !== null && this.selectedPriority !== null) {
          var selectedInspectionTypes = []
          this.currentServiceRequest.inspectionTypes.forEach((item) => {
            item.inspectionTypeAttachments.forEach((attach) => {
              attach.imageData = ''
              attach.classType = 'Report'
            })

            var type = this.definedTypes.inspectionTypes.find((x) => x.id === item.inspectionType)
            var inspectionType = {
              subscriberId: this.$store.state.user.currentUser.subscriberId,
              dealerId: this.currentDealer.id,
              inspectionTypeAttachments: item.inspectionTypeAttachments,
              inspectionType: type.altName,
              status: item.status
            }
            selectedInspectionTypes.push(inspectionType)
          })

          var selectedRequestLines = []
          this.currentServiceRequest.lines.forEach((item) => {
            item.requestLineAttachments.forEach((attach) => {
              attach.imageData = ''
              attach.classType = 'Report'
            })
            var line = {
              dealerId: this.currentDealer.id,
              serviceRequestLineStatusId: item.serviceRequestLineStatusId,
              serviceRequestId: 0,
              workRequired: item.workRequired,
              status: item.status,
              requestLineAttachments: item.requestLineAttachments,
              vehicleCost: item.vehicleCost,
              vehicleHours: item.vehicleHours,
              manufacturerCost: item.manufacturerCost,
              manufacturerHours: item.manufacturerHours
            }
            selectedRequestLines.push(line)
          })

          this.requestAttachments.forEach((attach) => {
            attach.imageData = ''
            attach.classType = 'Report'
          })

          var serviceRequestModel = {
            isCreate: true,
            dealerId: this.currentDealer.id,
            inventoryId: this.currentInventory.id,
            serviceRequestType: this.selectedServiceRequestType.altName,
            requestPriority: this.selectedPriority ? this.selectedPriority.name : 'NotSpecified',
            requestStatusType: this.selectedServiceRequestStatus.altName,
            inspectionResult: 'New',
            requiredAt: this.requiredBy,
            lines: selectedRequestLines,
            inspectionTypes: selectedInspectionTypes,
            name: 'Manual Service Request',
            roNumber: this.roNumber,
            departmentId: 0,
            notes: [],
            status: 'Active',
            requestAttachments: this.requestAttachments
          }

          this.isSaving = true
          this.serverErrorMessage = ''
          this.serverErrors = []
          serviceRequestService.save(serviceRequestModel).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              this.currentInventory.serviceRequests.unshift(response.serviceRequest)
              eventBus.$emit('inventory-changed', [this.currentInventory])
              this.isAddingServiceRequest = false
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      })
    },
    setDefaults: function () {
      this.isCopyDeclinedLines = false
      this.isLoadDefaultInspectionTypes = false
      this.mode = 0
      this.selectedPriority = null
      this.requiredBy = null
      this.selectedServiceRequestStatus = null
      this.selectedServiceRequestType = null
      this.selectedInventory = this.currentInventory
      this.currentServiceRequest = {
        id: 0,
        inventoryId: this.currentInventory.id,
        lines: [],
        inspectionTypes: [],
        notes: [],
        requestAttachments: []
      }
      this.defaultDealerInspectionTypes = []
      this.roNumber = ''
      if (this.currentServiceRequest) {
        this.$nextTick(() => {
          this.selectedServiceRequestStatus = this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.definedTypes.serviceRequestStatusType.New)
          this.selectedPriority = this.definedTypes.serviceRequestPriorityType.options.find((x) => x.id === this.definedTypes.serviceRequestPriorityType.High)
        })
      }
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  watch: {
    currentInventory: function (newValue, oldValue) {
      this.selectedInventory = newValue
    },
    '$route.params.id': function (id) {
      this.setDefaults()
    },
    selectedServiceRequestType: function (newValue, oldValue) {
      if (newValue && (newValue.id === 4 || newValue.id === 6)) {
        if (this.previouslyDeclinedWorkItems && this.previouslyDeclinedWorkItems.length > 0) {
          this.isCopyDeclinedLines = true
        }
      } else if (newValue && newValue.id === 0) {
        if (this.defaultDealerInspectionTypes && this.defaultDealerInspectionTypes.length > 0) {
          this.isLoadDefaultInspectionTypes = true
        }
      }
    }
  },
  mounted: function () {
    this.setDefaults()
  }
}
</script>

<style scoped>
  .box {
      background-color: #f6f8fa;
  }

  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .status-icon {
    margin-right:5px;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
  }

  .service-request-event-column {
    text-align: left;
    vertical-align:middle;
  }
</style>

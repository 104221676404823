<template>
  <div>
    <label class="label" @click="onGenerateVin($event)">
      Vin No
      <b-icon pack="fas" :icon="requiredIcon" class="fa-ss"></b-icon>
      <i v-if="showWarning" class="fas fa-exclamation-triangle is-not-valid fa-lg fa-throb"></i>
      <span class="is-not-valid" v-if="showWarning">INVALID</span>
    </label>
    <div class="field has-addons is-marginless">
      <div class="control">
        <input name="vinNo" :data-vv-scope="scope" data-vv-as="vin no" v-validate="{'required': vinRequired, 'verify_vin': vinRequired, 'min': vinRequired ? 11 : 0 }"
        style="width:175px" :class="{'input': true, 'is-danger': errors.has(validationName), 'is-valid': isValid, 'is-not-valid': !isValid }" type="text"
        placeholder="FF29387492" maxlength="17" v-model="selectedVinNo" v-on:blur="onVinBlur" :tabindex="tabIndex == null ? '' : tabIndex" :disabled="disabled">
        <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
      </div>
      <div class="control">
        <button class="button" :disabled="disabled" :class="{'is-loading': decodingVin}" @click.prevent="onVinDecodeClick" v-tooltip="{content: 'Click to run a vin-decode. This function is disabled if previously decoded or if year, make and model is already set. CTRL + Click will force a new vin-decode run', delay: {show: 500, hide: 100}}">
        <b-icon pack="fas" icon="barcode"></b-icon>
        </button>
      </div>
    </div>
    <p></p>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import inventoryService from '@/services/inventoryService'
import { createNamespacedHelpers } from 'vuex'
const mapVehicleMakeGetters = createNamespacedHelpers('vehicleMake').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'VinNoSelector',
  props: {
    value: {
      type: String,
      default: ''
    },
    vehicleId: {
      type: Number,
      default: 0
    },
    productionYear: {
      type: String,
      default: ''
    },
    make: {
      type: String,
      default: ''
    },
    model: {
      type: String,
      default: ''
    },
    vinRequired: {
      type: Boolean,
      default: true
    },
    tabIndex: {
      type: Number,
      default: null
    },
    scope: {
      type: String,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    getAutoCheck: {
      type: Boolean,
      default: true
    },
    ppaClosedCheck: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      decodingVin: false,
      selectedVinNo: this.value,
      selectedMakeModel: {
        decoded: false,
        vin: '',
        makeId: null,
        modelId: null,
        selectedMake: null,
        selectedModel: null,
        titleStatus: null,
        isDamaged: false,
        titleFiles: [],
        damageFiles: [],
        damageNote: '',
        productionYear: null,
        trim: '',
        style: '',
        exteriorColor: '',
        interiorColor: '',
        customMakeModel: '',
        locateType: null,
        altExteriorColors: [],
        altInteriorColors: [],
        tags: [],
        notes: '',
        lastInventory: null,
        accessoryStatusHistory: [],
        damageHistory: [],
        serviceHistory: []
      }
    }
  },
  computed: {
    ...mapVehicleMakeGetters(['allVehicleMakes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    showWarning: function () {
      if (!this.vinRequired) return false
      return this.errors.has(this.validationName) || this.isValid === false
    },
    makes: function () {
      return this.allVehicleMakes
    },
    requiredIcon: function () {
      return this.vinRequired ? 'star' : ''
    },
    isValid: function () {
      return this.selectedVinNo && this.selectedVinNo.length === 17
    },
    validationName: function () {
      return this.scope ? this.scope + '.vinNo' : 'vinNo'
    }
  },
  methods: {
    onVinDecodeClick: function (ev) {
      this.onDecodeVin(false, false, ev.ctrlKey)
    },
    onVinBlur: function () {
      if (this.productionYear && this.make && this.model) return
      this.onDecodeVin(false, false, false)
    },
    resetDefaults: function () {
      this.selectedMakeModel = {
        decoded: false,
        dcResult: null,
        vin: '',
        makeId: -1,
        modelId: -1,
        selectedMake: null,
        selectedModel: null,
        productionYear: null,
        trim: '',
        style: '',
        exteriorColor: '',
        interiorColor: '',
        customMakeModel: '',
        locateType: null,
        isDamaged: false,
        titleStatus: null,
        titleFiles: [],
        damageFiles: [],
        damageNote: '',
        source: null,
        wholesaler: null,
        customer: null,
        altExteriorColors: [],
        altInteriorColors: [],
        tags: [],
        notes: '',
        lastInventory: null,
        accessoryStatusHistory: [],
        damageHistory: [],
        serviceHistory: []
      }
    },
    buildCustomMakeModel: function (make, productionYear, model, trim, style) {
      if (productionYear) {
        this.selectedMakeModel.customMakeModel = productionYear
      }
      if (make) {
        this.selectedMakeModel.customMakeModel += ' ' + make
        this.selectedMakeModel.customMakeName = make
      }
      if (model) {
        this.selectedMakeModel.customMakeModel += ' ' + model
        this.selectedMakeModel.customModelName = model
      }
      if (trim) {
        this.selectedMakeModel.customMakeModel += ' ' + trim
      }
      if (style) {
        this.selectedMakeModel.customMakeModel += ' ' + style
      }
    },
    onGenerateVin: function (ev) {
      if (this.currentUser.id === 14018 || this.currentUser.id === 41041 || this.currentUser.id === 27027 || this.currentUser.id === 1) {
        this.onDecodeVin(true, !ev.ctrlKey, true)
      }
    },
    onDecodeVin: function (autoGenerate, internalMake, forceRun) {
      if (forceRun === false) {
        if (!this.isValid && !autoGenerate) {
          return
        }

        if (this.selectedVinNo.trim() === '' && !autoGenerate) {
          return
        }

        if (this.selectedMakeModel.decoded === true && this.selectedMakeModel.vinNo === this.selectedVinNo.trim()) {
          return
        }
      }

      this.resetDefaults()
      this.decodingVin = true
      this.selectedVinNo = this.selectedVinNo.trim()
      this.selectedMakeModel.vinNo = this.selectedVinNo
      let vinNoModel = {
        id: this.vehicleId,
        dealerId: this.currentDealer.id,
        vinNo: this.selectedVinNo,
        generate: (typeof autoGenerate === 'boolean') ? autoGenerate : false,
        brandId: internalMake ? 13 : null,
        getAutoCheck: this.getAutoCheck
      }
      if (this.ppaClosedCheck) {
        this.decodeVinPpa(autoGenerate, vinNoModel)
      } else {
        this.decodeVin(autoGenerate, vinNoModel)
      }
    },
    decodeVinPpa (autoGenerate, vinNoModel) {
      inventoryService.decodeVinPpa(vinNoModel).then(response => {
        if (response.errors) {
          this.decodingVin = false
          this.$emit('error', response.errors)
          return
        }
        this.selectedMakeModel.lookupResult = response.lookupResult
        if (response.lookupResult.valid && response.lookupResult.valid === true) {
          var make = response.lookupResult.make
          var productionYear = +response.lookupResult.productionYear
          var model = response.lookupResult.model
          var trim = response.lookupResult.trim
          var style = response.lookupResult.style
          if (autoGenerate && response.lookupResult.vin) {
            this.selectedVinNo = response.lookupResult.vin
            this.selectedMakeModel.vinNo = this.selectedVinNo
          }
          this.selectedMakeModel.decoded = true
          this.selectedMakeModel.trim = trim
          this.selectedMakeModel.style = style
          this.selectedMakeModel.productionYear = productionYear
          this.selectedMakeModel.vinUsageHistory = response.usageHistory || []
          if (response.lastInventory) {
            this.selectedMakeModel.lastInventory = response.lastInventory
            this.selectedMakeModel.accessoryStatusHistory = response.accessoryStatusHistory
            this.selectedMakeModel.damageHistory = response.damageHistory
            this.selectedMakeModel.serviceHistory = response.serviceHistory
            this.selectedMakeModel.exteriorColor = response.lastInventory.exteriorColor
            this.selectedMakeModel.interiorColor = response.lastInventory.interiorColor
          }
          var foundMake = make ? this.makes.find((x) => x.name.toUpperCase() === make.toUpperCase()) : null
          if (foundMake) {
            this.selectedMakeModel.makeId = foundMake.id
            this.selectedMakeModel.selectedMake = foundMake
            if (foundMake.versions && foundMake.versions.length > 0) {
              var foundProductionYearModel = foundMake.versions.find((x) => x.productionYear === productionYear && x.name.toUpperCase() === model.toUpperCase())
              if (foundProductionYearModel) {
                this.selectedMakeModel.modelId = foundProductionYearModel.id
                this.selectedMakeModel.selectedModel = foundProductionYearModel
                this.selectedMakeModel.productionYear = foundProductionYearModel.productionYear
              } else {
                this.buildCustomMakeModel(make, productionYear, model, trim, style)
              }
            } else {
              this.buildCustomMakeModel(make, productionYear, model, trim, style)
            }
          } else {
            this.buildCustomMakeModel(make, productionYear, model, trim, style)
          }
        }
        this.decodingVin = false
        this.$emit('vin-resolved', this.selectedMakeModel)
        if (response.existingPpaInventory) {
          this.$emit('existing-ppa', response.existingPpaInventory)
        }
      }).catch((error) => {
        console.log('error', error)
        this.decodingVin = false
        this.$emit('error', [error])
      })
    },
    decodeVin (autoGenerate, vinNoModel) {
      inventoryService.decode(vinNoModel).then(response => {
        if (response.errors) {
          this.decodingVin = false
          this.$emit('error', response.errors)
          return
        }
        this.selectedMakeModel.lookupResult = response.lookupResult
        if (response.lookupResult.valid && response.lookupResult.valid === true) {
          var make = response.lookupResult.make
          var productionYear = +response.lookupResult.productionYear
          var model = response.lookupResult.model
          var trim = response.lookupResult.trim
          var style = response.lookupResult.style
          if (autoGenerate && response.lookupResult.vin) {
            this.selectedVinNo = response.lookupResult.vin
            this.selectedMakeModel.vinNo = this.selectedVinNo
          }
          this.selectedMakeModel.decoded = true
          this.selectedMakeModel.trim = trim
          this.selectedMakeModel.style = style
          this.selectedMakeModel.productionYear = productionYear
          this.selectedMakeModel.vinUsageHistory = response.usageHistory || []
          if (response.lastInventory) {
            this.selectedMakeModel.lastInventory = response.lastInventory
            this.selectedMakeModel.accessoryStatusHistory = response.accessoryStatusHistory
            this.selectedMakeModel.damageHistory = response.damageHistory
            this.selectedMakeModel.serviceHistory = response.serviceHistory
            this.selectedMakeModel.exteriorColor = response.lastInventory.exteriorColor
            this.selectedMakeModel.interiorColor = response.lastInventory.interiorColor
          }
          if (make) {
            var foundMake = this.makes.find((x) => x.name.toUpperCase() === make.toUpperCase())
            if (foundMake) {
              this.selectedMakeModel.makeId = foundMake.id
              this.selectedMakeModel.selectedMake = foundMake
              if (foundMake.versions && foundMake.versions.length > 0) {
                var foundProductionYearModel = foundMake.versions.find((x) => x.productionYear === productionYear && x.name.toUpperCase() === model.toUpperCase())
                if (foundProductionYearModel) {
                  this.selectedMakeModel.modelId = foundProductionYearModel.id
                  this.selectedMakeModel.selectedModel = foundProductionYearModel
                  this.selectedMakeModel.productionYear = foundProductionYearModel.productionYear
                } else {
                  this.buildCustomMakeModel(make, productionYear, model, trim, style)
                }
              } else {
                this.buildCustomMakeModel(make, productionYear, model, trim, style)
              }
            } else {
              this.buildCustomMakeModel(make, productionYear, model, trim, style)
            }
          }
        }

        this.decodingVin = false
        this.$emit('vin-resolved', this.selectedMakeModel)
      }).catch((error) => {
        this.decodingVin = false
        this.$emit('error', [error])
      })
    }
  },
  watch: {
    selectedVinNo: function (newValue, oldValue) {
      if (newValue) {
        this.$emit('input', newValue.toUpperCase())
      }
    },
    value: function (newValue, oldValue) {
      this.selectedVinNo = newValue
    }
  },
  beforeDestroy () {
    eventBus.$off('on-decode-vin', this.onDecodeVin)
  },
  mounted: function () {
    eventBus.$on('on-decode-vin', this.onDecodeVin)
    this.selectedVinNo = this.value || ''
  }
}
</script>

<style scoped>

.is-valid {
  color:green !important;
  text-transform: uppercase;
}

.is-not-valid {
  color:red !important;
  text-transform: uppercase;
}
</style>

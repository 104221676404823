<template>
  <div class="field">
    <label class="label" v-if="label !== ''" :style="labelStyle">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-if="required"></b-icon>
    </label>
    <slot name="custom-label"></slot>
    <div class="control" :class="extraClasses" :style="extraStyles">
      <b-switch :size="size" :tabindex="tabindex" :data-vv-scope="validateAs" :type="type" v-model="switchValue" :disabled="isDisabled" >{{ humanizedSwitch(switchValue) }}</b-switch>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SwitchSelector',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    label: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'is-info'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    validateAs: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: '0'
    },
    size: {
      type: String,
      default: ''
    },
    extraClasses: {
      type: String,
      default: ''
    },
    extraStyles: {
      type: String,
      default: ''
    },
    yesLabel: {
      type: String,
      default: 'Yes'
    },
    noLabel: {
      type: String,
      default: 'No'
    },
    labelStyle: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      switchValue: false
    }
  },
  computed: {
  },
  watch: {
    value: function (newValue, oldValue) {
      this.switchValue = this.value
    },
    switchValue: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue)
        this.$emit('on-change', newValue)
      }
    }
  },
  methods: {
    humanizedSwitch: function (switchValue, positiveValue, negativeValue) {
      return switchValue ? (positiveValue != null ? positiveValue : this.yesLabel) : (negativeValue != null ? negativeValue : this.noLabel)
    }
  },
  mounted () {
    this.switchValue = this.value
  }
}
</script>

<style scoped>
</style>

export default {
  all: [
    {
      name: '3 year service plan',
      description: 'This is full cover for 3 years',
      status: 'Active',
      price: 350.00,
      isFinanceProduct: false,
      subscriberId: 1,
      dealerId: 1,
      dealerName: 'Ferrari of Ft. Lauderdale',
      subscriberName: 'Experience Auto Group',
      id: 1
    },
    {
      name: '5 year service plan',
      description: 'This is full cover for 5 years',
      status: 'Active',
      price: 500.00,
      isFinanceProduct: false,
      subscriberId: 1,
      dealerId: 1,
      dealerName: 'Ferrari of Ft. Lauderdale',
      subscriberName: 'Experience Auto Group',
      id: 2
    },
    {
      name: '36 month loan',
      description: 'loan product',
      status: 'Active',
      price: 30000.00,
      isFinanceProduct: true,
      subscriberId: 1,
      dealerId: 1,
      dealerName: 'Ferrari of Ft. Lauderdale',
      subscriberName: 'Experience Auto Group',
      id: 3
    }
  ]
}

<template>
  <div v-observe-visibility="visibilityChanged" class="pod tile is-parent is-narrow">
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold">{{title}} ({{total}} imports)
        <i class="fas fa-ellipsis-v icon-more"></i>
        <i style="display: inline-block;" class="fas fa-sync" :class="{'fa-spin': isLoading}" @click="onPodRefreshRequired"></i>
        <i class="fas fa-eye icon-more" title="Items on this list are in the CDK feed but they are not in-stock in LIVE. Either no entry has been made in LIVE or the car is sold in LIVE but not booked in CDK."></i>
      </h4>
      <div class="pod-content scrollbar">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th class="is-uppercase no-wrap-table-cell" :class="{'is-current-sort': sortField === column.id}" v-for="column in inventoryImportData.columns.filter((x) => x.visible)" :key="column.name" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                {{column.name}}
                <span class="icon sort-icon"><i class="mdi" :class="{'mdi-arrow-up': sortOrder === 'asc', 'mdi-arrow-down': sortOrder === 'desc'}" v-if="sortField === column.id"></i></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable" v-for="(item, index) in inventoryImportData.data" :key="item.id" @click="onItemClick(item)" :class="{'pod-row-selected': isRowSelected(item)}">
              <td v-for="column in inventoryImportData.columns.filter((x) => x.visible)" :title="cleanNote(item.note)" :key="column.name + index.toString()" :style="{ 'max-width': column.maxWidth && sortField !== column.id ? column.maxWidth : 'unset' }">
                <template v-if="column.format && column.format === 'currency'">{{item[column.id] | currency('$', 0)}}</template>
                <template v-else-if="column.format && column.format === 'datetime'">{{item[column.id] | formatDateOnly}}</template>
                <template v-else-if="column.name && column.name === 'DSP'" :style="getDspAgingStyle(item)"><i class="fal fa-hourglass-end fa-sm aging-icon" :style="getDspAgingStyle(item)"/>{{item.daysSincePurchase}}</template>
                <template v-else-if="column.name === '...'">
                  <span class="actionButtonFormat">
                    <div @click.stop.prevent="onTakeAction(item)">
                      <b-icon pack="fal" icon="hand-receiving" custom-size="fa-lg"></b-icon>
                    </div>
                  </span>
                </template>
                <template v-else>{{item[column.id]}}</template>
              </td>
            </tr>
            <tr v-if="this.inventoryImportData.data.length === 0" class="subtitle is-7">
              <td style="text-align:center; height:100px;vertical-align:middle;" :colspan="inventoryImportData.columns.length">No data available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
    <b-modal :active.sync="isSoldWarningActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title is-size-6 has-text-weight-semibold">Warning - Vehicle Sold</p>
        </header>
        <section class="modal-card-body">
          This vehicle has been sold but is still in the CDK feed. Make sure the deal gets booked or unwind the deal in LIVE.
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="isSoldWarningActive = false">Ok</button>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import axios from 'axios'
import ViewInventory from '@/components/inventory/ViewInventory'
import utilitiesMixin from '@/mixins/generic/utilities'
import inventoryService from '@/services/inventoryService'
import formattingMixin from '@/mixins/inventory/formatting'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'InventoryImportPod',
  mixins: [utilitiesMixin, formattingMixin],
  components: {
    'view-inventory': ViewInventory
  },
  data () {
    return {
      total: 0,
      isLoading: false,
      page: 0,
      sortField: 'stockNo',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      serverErrorMessage: '',
      perPage: this.$store.state.config.defaultPageSize,
      currentInventory: null,
      isSoldWarningActive: false,
      inventoryImportData: {
        columns: [
          {
            id: 'id',
            name: 'Id',
            visible: false
          },
          {
            id: 'stockNo',
            name: 'Stock No',
            visible: true,
            maxWidth: '80px'
          },
          {
            id: 'vinNo',
            name: 'Vin No.',
            visible: true,
            maxWidth: '130px'
          },
          {
            id: 'name',
            name: 'Name',
            visible: true
          },
          {
            id: 'type',
            name: 'Type',
            visible: false
          },
          {
            id: 'daysSincePurchase',
            name: 'DSP',
            visible: !this.$isMobile
          },
          {
            id: 'actions',
            name: '...',
            visible: true
          }
        ],
        data: []
      },
      selectedInventory: null
    }
  },
  computed: {
    title: function () {
      return 'CDK - Import'
    },
    ...mapUserGetters(['hasFeatureAccess']),
    ...mapDealerState(['currentDealer'])
  },
  methods: {
    onTakeAction: function (item) {
      if (item.isSold === true) {
        this.isSoldWarningActive = true
      } else {
        this.$router.push({name: 'EditInventory', params: { id: item.id }})
      }
    },
    onPodRefreshRequired: function () {
      this.loadData()
    },
    onItemClick: function (item) {
      this.selectedInventory = item
      eventBus.$emit('pod-item-highlight', {componentId: this._uid})
      if (this.hasFeatureAccess('inventory.view')) {
        this.$router.push({name: 'InventoryQuickView', params: { id: item.id, isLoanerPod: false, offerPod: -1 }})
      }
    },
    loadData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        query: '',
        pageSize: this.perPage,
        sortBy: 'stockNo',
        page: this.page
      }
      this.isLoading = true
      inventoryService.getImportList(searchCriteria).then(response => {
        this.inventoryImportData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          this.inventoryImportData.data.push(item)
        })

        this.isLoading = false
      }).catch((error) => {
        this.inventoryImportData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        if (this.currentDealer.id) {
          this.loadData()
        }
      }
    },
    isRowSelected: function (item) {
      if (!this.selectedInventory) return false
      if (this.selectedInventory.id === item.id) return true
      return false
    },
    itemHighLightCheck: function (options) {
      let componentId = options.componentId
      if (this._uid !== componentId) {
        this.selectedInventory = null
      }
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.loadData()
      }
    }
  },
  mounted () {
    eventBus.$on('pod-item-highlight', this.itemHighLightCheck)
  },
  beforeDestroy () {
    axios.cancel('inventory-import')
    eventBus.$off('pod-item-highlight', this.itemHighLightCheck)
  }
}
</script>

<style scoped>
  .actionButtonFormat
  {
  text-align: left;
  display: table-cell;
  min-width: 10px;
  padding-right: 10px;
  }

  .no-wrap-table-cell {
    white-space: nowrap;
  }

  .sort-icon {
    height:auto !important;
  }

  .is-current-sort {
    color: rgb(0, 177, 31) !important;
  }

</style>

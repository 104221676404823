<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-fullwidth">
        <multiselect @select="$markDirty()"
        :showLabels="false"
        :name="instanceName"
        :data-vv-scope="scope"
        :disabled="isDisabled"
        data-vv-validate-on="input|close"
        :data-vv-as="this.validateAs"
        v-validate="{'required': required}"
        :class="{'is-danger': errors.has(validationName) }"
        v-model="selectedUser"
        :options="availableUsers"
        :placeholder="defaultPlaceholder"
        label="fullName"
        trackBy="id"
        :hideSelected="true"></multiselect>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'UserSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Employee'
    },
    validateAs: {
      type: String,
      default: 'Employee'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    selectedDealer: {
      type: Object,
      default: null
    },
    filter: {
      type: String,
      default: ''
    },
    defaultPlaceholder: {
      type: String,
      default: 'Type to search for a registered user'
    },
    useValidator: {
      type: Object,
      default: null
    },
    filterByRole: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedUser: this.value,
      data: [],
      instanceName: 'user-component-' + this.$uuid.v4(),
      storeDealer: this.$store.state.dealer.currentDealer
    }
  },
  computed: {
    ...mapUserGetters(['allUsers', 'currentUser']),
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    },
    availableUsers: function () {
      if (this.selectedDealer !== null && this.filter !== '') {
        if (this.filterByRole) {
          let filteredUsers = this.allUsers.filter(function (user) {
            var found = user.roles.filter((y) => y.name.indexOf(this.filter) >= 0).length > 0
            return found
          }, this)
          return filteredUsers.filter((x) => x.dealerId === this.selectedDealer.id && x.status === 'Active').sort(this.sorter)
        } else {
          let filteredUsers = this.allUsers.filter(function (user) {
            var found = user.designations.filter((y) => y.name.indexOf(this.filter) >= 0).length > 0
            return found
          }, this)
          return filteredUsers.filter((x) => x.dealerId === this.selectedDealer.id && x.status === 'Active').sort(this.sorter)
        }
      }

      if (this.selectedDealer !== null) {
        return this.allUsers.filter((x) => x.dealerId === this.selectedDealer.id && x.status === 'Active').sort(this.sorter)
      }

      return this.allUsers.filter((x) => x.status === 'Active').sort(this.sorter)
    }
  },
  methods: {
    sorter: function (a, b) {
      var x = a.fullName.toLowerCase()
      var y = b.fullName.toLowerCase()
      if (x < y) {
        return -1
      }
      if (x > y) {
        return 1
      }
      return 0
    }
  },
  watch: {
    selectedUser: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentUser: function (newValue, oldValue) {
      this.selectedUser = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedUser = newValue
    },
    selectedDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
      // Only reset the user on dealer change if its no the same dealer as the user already belongs to
      if (this.selectedUser && this.selectedDealer !== null && this.selectedUser.dealerId !== this.selectedDealer.id) {
        this.selectedUser = null
      }
    },
    storeDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
    }
  },
  mounted: function () {
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}

</script>

<template>
  <pdfjs-viewer-element
    ref="pdfViewer"
    class="pdf-viewer"
    :src="src"
    viewer-path="/pdfjs-4.4.168"
    viewer-extra-styles="#openFile { display: none }"
    pagemode="none"
    :zoom="scale">
  </pdfjs-viewer-element>
</template>
  
  <script>
  import 'pdfjs-viewer-element'
  export default {
    name: 'pdf',
    props: {
      src: {
        type: String,
        required: true
      },
      scale: {
        type: String,
        default: 'page-width'
      },
      defaultPage: {
        type: Number,
        default: undefined
      },
      rotate: {
        type: Number,
        default: 0
      }
    },
    data() {
      return {
        pdfViewer: null,
      };
    },
    mounted() {
      // pdfjs is used in an iframe, however "webviewerloaded" triggered on the original document
      let that = this
      document.addEventListener('webviewerloaded', function initPdfViewer(event) {
        that.pdfViewer = event.detail.source
        // hide unnecessary buttons:
        let buttons = ['#presentationMode', '#editorModeButtons', '#viewBookmark']
        buttons.forEach(selector => {
          that.pdfViewer.document.querySelector(selector)?.setAttribute('hidden', 'true')
        })
      })      
    },
    methods: {
      print(scale) {
        this.pdfViewer['PDFViewerApplicationOptions'].set('printResolution', scale || 600)
        this.pdfViewer['PDFViewerApplicationOptions'].set('enablePrintAutoRotate', false)
        this.pdfViewer['PDFViewerApplication'].initializedPromise.then(() => {
          this.pdfViewer['PDFViewerApplication'].eventBus.dispatch('print')
        })
      },
      download() {
        this.pdfViewer['PDFViewerApplication'].initializedPromise.then(() => {
          this.pdfViewer['PDFViewerApplication'].eventBus.dispatch('download')
        })
      }

    }
  }
  </script>
  
  <style scoped>
  .pdf-viewer {
    width: 100%;
    height: 100%;
  }
  </style>
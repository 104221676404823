<template>
  <portal to="global-modal-portal" v-if="showReport">
    <b-modal :active.sync="showReport" :width="modalWidth" scroll="keep" :has-modal-card="true" :canCancel="false" id="carfax-report-modal">
      <div class="modal-card">
        <header class="modal-card-head">
            <p class="modal-card-title">CarFax Report</p>
        </header>
        <section class="modal-card-body">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <div class="aligner-item" style="text-align:center" v-if="isLoadingReport">
              <img src="@/assets/ajax-loader.gif">
            </div>
            <div class="content" v-if="!hasErrors && carFaxReportUrl && !isLoadingReport">
              <iframe :src="carFaxReportUrl" class="content" frameborder="0"></iframe>
            </div>
            <div class="column is-12" v-else-if="!hasErrors && !isLoadingReport && !carFaxReportUrl">
              <label class="label refresh-status-label">
              No CarFax report available
              </label>
            </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" :class="{'is-loading': isGeneratingReport }" @click="hideReportModal" type="button">Close</button>
          <button class="button" :class="{'is-loading': isGeneratingReport }" @click="generateReport" v-if="carFaxReportUrl">Download</button>
        </footer>
      </div>
    </b-modal>
  </portal>
  <pdf-modal
      :pdfFile="carFaxSelectedPdf"
      :usePortal="true"
      :portalOrder="2"
      v-on:on-pdf-downloaded="onPreviewed"
      v-on:on-pdf-closed="onPreviewClosed"
      v-else-if="carFaxSelectedPdf && carFaxSelectedPdf.src">
  </pdf-modal>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import appraisalService from '@/services/appraisalService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'CarFaxReportControl',
  components: {
    'error-display-component': ErrorDisplayComponent,
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    carFaxReportUrl: {
      type: String,
      default: ''
    },
    showCarFaxReportModal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      carfaxLink: true,
      showReport: false,
      isLoadingReport: false,
      serverErrorMessage: '',
      serverErrors: [],
      modalWidth: 960,
      isGeneratingReport: false,
      carFaxSelectedPdf: null,
      downloadUrl: ''
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    urlVinNo: function () {
      var str = this.carFaxReportUrl
      var n = str.lastIndexOf('&vin=')
      var result = str.substring(n + 5)
      return result
    },
    hasErrors: function () {
      if (this.serverErrors.length > 0 || this.serverErrorMessage.length > 0) {
        return true
      }
      return false
    }
  },
  methods: {
    showReportModal: function () {
      this.showReport = true
    },
    hideReportModal: function () {
      this.$emit('on-hide-carfax-report')
      this.showReport = false
    },
    setMaxModalWidth: function () {
      let viewWidth = window.innerWidth
      let newWidth = viewWidth * 90 / 100
      this.modalWidth = newWidth
    },
    generateReport () {
      this.isGeneratingReport = true
      appraisalService.generateCarFaxReportPdf(this.urlVinNo).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
          this.isGeneratingReport = false
        } else {
          this.downloadUrl = response.carFaxPdfUrl
          this.isGeneratingReport = false
          this.showReport = false
          this.onPreview()
        }
      }).catch((error) => {
        this.isGeneratingReport = false
        this.serverErrorMessage = error.message
      })
    },
    onPreview: function () {
      var extractedFileName = this.downloadUrl.split('/').pop()
      var docUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.downloadUrl)
      docUrl = encodeURI(docUrl)
      this.carFaxSelectedPdf = {
        name: 'Carfax Report',
        src: docUrl,
        fileName: extractedFileName,
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
    },
    onPreviewClosed: function () {
      this.carFaxSelectedPdf = null
    }
  },
  watch: {
    showCarFaxReportModal: function (newValue, oldValue) {
      if (newValue) {
        this.showReportModal()
      }
    }
  },
  mounted: function () {
    this.setMaxModalWidth()
    let that = this
    window.onresize = () => {
      that.setMaxModalWidth()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .status-icon {
    width: 13px;
    height: 12px;
    margin-right:12px;
  }

  .carfax-container-display-only {
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    margin-top:10px;
  }

  .carfax-container {
    margin-top: 20px;
    padding: 0;
    margin-left:0;
    margin-right:0;
  }

  .carfax-owner-icons {
    height: 12px;
    width: 12px;
    margin-top:-15px;
  }

  .carfax-warning {
    background-image: url('@/assets/static/carfax/ico_accident.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .carfax-danger {
    background-image: url('@/assets/static/carfax/ico_alert.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .carfax-recall {
    background-image: url('@/assets/static/carfax/ico_recall.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .carfax-ok {
    background-image: url('@/assets/static/carfax/ico_green_check.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .refresh-status-label {
    margin-left:1em;
  }

  .cd .column {
    padding:0;
  }

  .modal-card-body {
    position:relative;
    max-height: calc(100vh - 220px);
    max-width: 90vw !important;
    width: 90vw !important;
    height: calc(100vh - 220px);
    overflow-y: auto;
    background: white;
  }
  .modal-card {
    max-width: 90vw !important;
    width: 90vw !important;
  }
  .content {
    display: inline-block;
    height: 100%;
    width: 100%;
  }

</style>

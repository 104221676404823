<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      :name="instanceName"
      :key="instanceName"
      :multiple="true"
      :taggable="true"
      @tag="tagAdded"
      tagPlaceholder="Press enter to create a new tag"
      tagPosition="bottom"
      :searchable="true"
      :disabled="isDisabled"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedTags"
      :options="allTags"
      label="name"
      trackBy="id"
      :loading="isTagLoading"
      placeholder="Type to search for a tag"
      :internal-search="true"
      :options-limit="300"
      :data-vv-scope="scope"></multiselect>
      <a class="button is-primary new-tag-btn" v-if="allowNew && showNewAction">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <portal to="global-modal-portal" v-if="isNewTagActive">
      <b-modal :active.sync="isNewTagActive" scroll="keep" :has-modal-card="true">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
          </header>
          <section class="modal-card-body" style="min-width: 400px !important;">
            <new-tag
              mode="modal"
              actionPortal="modal-footer"
              headerPortal="modal-header"
              v-on:on-save="onTagSaved"
              v-on:on-cancel="onTagCancelled"
              :startWith="nameOfTagToCreate">
            </new-tag>
          </section>
          <footer class="modal-card-foot">
            <portal-target name="modal-footer" class="actions" slim/>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>

import _ from 'lodash'
import NewTag from '@/components/configuration/tags/NewTag'
import tagService from '@/services/tagService'

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'TagSelector',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Tag'
    },
    validateAs: {
      type: String,
      default: 'Tag'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: true
    },
    allowNew: {
      type: Boolean,
      default: true
    },
    showNewAction: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    },
    inModal: {
      type: Boolean,
      default: false
    }
  },
  components: {
    'new-tag': NewTag
  },
  data () {
    return {
      isTagLoading: false,
      isNewTagActive: false,
      selectedTags: [],
      nameOfTagToCreate: '',
      data: [],
      instanceName: 'tag-selector-' + this.$uuid.v4()
    }
  },
  computed: {
    allTags () {
      return this.data
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    selectedTags: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    value: function (newValue, oldValue) {
      this.selectedTags = newValue
    }
  },
  methods: {
    tagAdded: function (data) {
      if (this.allowNew) {
        if (!this.inModal) {
          this.nameOfTagToCreate = data
          this.isNewTagActive = true
        } else {
          this.$emit('on-add-tag-modal', data)
        }
      }
    },
    onTagSaved: function (newTag) {
      this.data.push(newTag)
      this.selectedTags.push(newTag)
      this.isNewTagActive = false
    },
    onTagCancelled: function () {
      this.isNewTagActive = false
    },
    clearAllTags () {
      this.data = []
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        sortBy: 'name.asc'
      }
      this.isTagLoading = true
      this.clearAllTags()
      tagService.all(searchCommand).then(response => {
        this.data = response.results || response
        this.isTagLoading = false
      })
    },
    doTagSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onTagSearch (query) {
      this.doTagSearch(query)
    },
    async preLoadData () {
      var searchCommand = {
        page: 0,
        pageSize: 500,
        query: '',
        sortBy: 'name.asc'
      }
      this.isTagLoading = true
      this.clearAllTags()
      tagService.all(searchCommand).then(response => {
        this.data = response.results || response
        this.isTagLoading = false
      })
    }
  },
  async mounted () {
    await this.preLoadData()
    this.selectedTags = this.value
  }
}

</script>

<style scoped>
  .new-tag-btn {
    margin-left:5px;
    height:40px;
  }
</style>

import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/locations',
    name: 'ListLocations',
    component: () => import('@/components/configuration/locations/ListLocations'),
    beforeEnter: requiresAuth
  },
  {
    name: 'LocationsQuickView',
    path: '/configuration/locations/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/locations/ViewLocation')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/locations/new',
    name: 'NewLocation',
    component: () => import('@/components/configuration/locations/NewLocation'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/locations/edit/:id',
    name: 'EditLocation',
    component: () => import('@/components/configuration/locations/EditLocation'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/locations/view/:id',
    name: 'ViewLocation',
    component: () => import('@/components/configuration/locations/ViewLocation'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  }
]

<template>
  <div class="box">
    <section-header :collapsed="isSectionCollapsed"  v-on:collapsed-status-changed="(val) => isSectionCollapsed = val" title="Service Videos"></section-header>
    <div class="columns is-multiline">
      <div class="column is-12 no-h-padding">
        <b-table
          :data="serviceVideos"
          :sortable="false"
          :selecteable="false"
          :mobile-cards="false">
          :row-class="() => 'service-request-row'">
          <template slot-scope="props">
            <b-table-column field="createdBy" label="Created By">
              {{ props.row.createdByCompact }}
            </b-table-column>

            <b-table-column field="createdAt" label="Created At">
              {{ props.row.createdAt | formatDateOnly }}
            </b-table-column>

            <b-table-column field="id" label="Actions">
              <video-player-control :url="props.row.fileLocation" :title="'Created by ' + props.row.createdBy" v-if="props.row.fileLocation"></video-player-control>
              <span ref="serviceVideosImageViewer" class="icon" v-viewer.static="{inline: false, title: true}">
                <i class="fal fa-lg fa-images" title="View Images" @click="onViewServiceVideoImages"></i>
                <img v-for="attachment in props.row.attachments" :key="attachment.id" :src="attachment.fileLocation" :alt="attachment.description" style="display:none"/>
              </span>
              <span class="icon" v-if="props.row.fileLocation"><i class="fal fa-lg fa-download" @click.stop.prevent="onDownloadServiceVideo(props.row)" title="Download"></i></span>
            </b-table-column>
          </template>
          <template slot="empty">
            <section class="section">
              <div class="has-text-centered">
                <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                <p>No service videos created for this vehicle.</p>
              </div>
            </section>
          </template>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>

import utilitiesMixin from '@/mixins/generic/utilities'
import { createNamespacedHelpers } from 'vuex'
import serviceVideoService from '@/services/serviceVideoService'
import VideoPlayerControl from '@/components/generic/VideoPlayerControl.vue'
import SectionHeader from '@/components/generic/SectionHeader'

const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'ServiceVideoContainer',
  inject: {
    $validator: '$validator'
  },
  mixins: [utilitiesMixin],
  components: {
    'video-player-control': VideoPlayerControl,
    'section-header': SectionHeader
  },
  props: {
    vinNo: {
      type: String,
      default: ''
    },
    collapsed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isSectionCollapsed: this.collapsed,
      currentVinNo: '',
      serviceVideos: [],
      isLoading: false,
      serverErrors: [],
      serverErrorMessage: ''
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapConfigGetters(['definedTypes']),
    hasVinNo: function () {
      if (this.vinNo) {
        return true
      }
      return false
    },
    dealerId: function () {
      return this.$store.state.dealer.currentDealer.id
    }
  },
  watch: {
    isSectionCollapsed: function (newValue, oldValue) {
      if (newValue === false) {
        this.fetchServiceVideos()
      }
    }
  },
  methods: {
    onDownloadServiceVideo: function (row) {
      window.location = row.fileLocation
    },
    onViewServiceVideoImages: function () {
      const viewer = this.$refs.serviceVideosImageViewer.$viewer
      viewer.show()
    },
    reset: function () {
      this.serviceVideos = []
    },
    fetchServiceVideos: function () {
      this.reset()
      this.currentVinNo = this.vinNo
      this.isLoading = true
      this.serverErrorMessage = ''
      let that = this

      serviceVideoService.getServiceVideosByVin(this.currentVinNo, this.dealerId).then(response => {
        that.serviceVideos = response
        that.isLoading = false
      }).catch((error) => {
        that.isLoading = false
        that.serverErrorMessage = error.message
      })
    }
  }
}
</script>

<style scoped>

  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    width: 40vw;
  }

  .main-dialog-footer {
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: stretch;
    height:auto;
  }

  @media screen and (max-width: 500px) {
    .main-dialog-footer {
      height:120px;
    }
  }

  .modal-info-body {
    position:relative;
    max-height: calc(100vh - 220px);
    height: 60vh !important;
    overflow-y: auto;
    background: white;
  }

  .wire-payment-row {
    text-align: left;
    vertical-align:middle;
  }

  .wire-payment-column {
    text-align: left;
    vertical-align:middle;
  }
</style>

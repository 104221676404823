<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Add Bank</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmit" @change="onFormChange" class="form-class">
      <b-tabs size="is-small" v-model="bankTabIndex" :animated="false" type="is-toggle">
        <b-tab-item label="Definition" icon-pack="fas"  icon="fas fa-info-circle fa-sm">
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <input name="name" data-vv-as="name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('name') }" type="text" placeholder="e.g Absa" v-model="name">
                  <span v-show="errors.has('name')" class="help is-danger">
                    <i v-show="errors.has('name')" class="fas fa-exclamation-triangle"></i> {{ errors.first('name') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Email Address<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control has-icons-left has-icons-right">
                  <input name="email" data-vv-as="email address" v-validate="'required|email'" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has('email') }" type="text" placeholder="Email" v-model="email" data-lpignore="true" autocomplete="off">
                  <span v-show="errors.has('email')" class="help is-danger">
                    <i v-show="errors.has('firstName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('email') }}
                  </span>
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <span class="icon is-small is-right" v-show="!errors.has('email')">
                    <i class="fas fa-check"></i>
                  </span>
                  <span class="icon is-small is-right" v-show="errors.has('email')">
                    <i class="fas fa-exclamation-triangle"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Contact First Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <input name="firstName" data-vv-as="first name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('firstName') }" type="text" placeholder="e.g John" v-model="firstName">
                  <span v-show="errors.has('firstName')" class="help is-danger">
                    <i v-show="errors.has('firstName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('firstName') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Contact Last Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <input name="lastName" data-vv-as="last name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('lastName') }" type="text" placeholder="e.g Doe" v-model="lastName">
                </div>
                <span v-show="errors.has('lastName')" class="help is-danger">
                  <i v-show="errors.has('lastName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('lastName') }}
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Office</label>
                <div class="control">
                  <input name="office" data-vv-as="office" :class="{'input': true, 'is-danger': errors.has('office') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="office">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Fax</label>
                <div class="control">
                  <input name="fax" data-vv-as="fax" :class="{'input': true, 'is-danger': errors.has('fax') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="fax">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-12">
              <file-selector
                v-model="lienPayoffFiles"
                label="Lien Payoff Letter"
                :required="false"
                :multiple="false"
                :isBoxed="false"
                @uploadStarted="uploadStarted"
                @uploadComplete="uploadComplete">
              </file-selector>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Addresses" icon-pack="fas" icon="fas fas fa-address-card fa-sm">
          <div id="address-section" class="box">
            <section-header title="Addresses" parentSelector="#address-section"></section-header>
            <div v-for="(address, index) in addresses" :key="address.id" >
              <address-capture v-model="addresses[index]" :isBank="true" :box="false" :useValidator="thisValidator"></address-capture>
              <br/>
            </div>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addAddress({})">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
              <a class="button is-danger" v-if="this.addresses.length > 1" @click="removeAddress()">
                <span class="icon">
                  <i class="fas fa-minus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button :disabled="isUploadingFile" class="button is-primary" :class="{'is-loading': isSaving }"  type="button" @click="validateBeforeSubmit">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>
  </section>
</template>

<script>
import bankService from '@/services/bankService'
import banksMixin from '@/mixins/banks/index'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import completeCancelInitMixin from '@/mixins/generic/completeCancelInit'

export default {
  name: 'NewBank',
  mixins: [validateBeforeSubmitMixin, completeCancelInitMixin, banksMixin],
  props: {
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    viewMode: {
      type: String,
      default: 'full-screen'
    },
    startWith: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      id: 0,
      name: this.$toTitleCase(this.startWith),
      firstName: '',
      lastName: '',
      email: '',
      office: '',
      fax: '',
      serverErrors: [],
      serverErrorMessage: ''
    }
  },
  methods: {
    onSave: function () {
      var bankModel = {
        isCreate: true,
        subscriberId: this.$store.state.user.currentUser.subscriberId,
        name: this.name,
        firstName: this.firstName,
        lastName: this.lastName,
        email: this.email,
        office: this.office,
        fax: this.fax,
        addresses: [],
        attachments: [],
        status: 'Active'
      }

      this.saveAddress(bankModel)

      if (this.lienPayoffFiles.length > 0) {
        var lienPayoffFile = this.lienPayoffFiles.map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'LienPayoffLetter'
          }
        })

        bankModel.attachments.push(...lienPayoffFile)
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      bankService.save(bankModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.onChangesSaved()
          this.$emit('on-save', response.bank)
          if (this.viewMode === 'full-screen') {
            this.onComplete()
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onCancel: function () {
      this.$emit('on-cancel')
      if (this.viewMode === 'full-screen') {
        this.onComplete()
      }
    }
  },
  mounted: function () {
    this.addAddress({ loadBankOnly: true })
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

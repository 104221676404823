<template>
  <portal to="global-modal-portal" v-if="isAutoGetReadyActive" :disabled="!usePortal">
    <b-modal :key="'gr'+instanceId" :active.sync="isAutoGetReadyActive" :width="640" scroll="keep" :has-modal-card="true" :can-cancel="false">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Create Sale Get Ready For Vehicle</p>
          <p> <span class="tag is-dark">{{buildVehicleInfo}}</span></p>
        </header>
        <section class="modal-card-body" style="padding:12px">
          <form class="view-container">
            <div class="columns is-multiline">
              <div class="column is-12">
                <div class="notification is-warning is-flex">
                  <div class="is-block">
                    <i class="fas fa-info-circle is-info fa-2x"></i>
                  </div>
                  <div class="ml-10">
                    <button-list-selector
                      validateAs="physicalInspection"
                      label="Do you want service to physically inspect the vehicle?"
                      :required="true"
                      v-model="isInspectionTypeRequired"
                      :availableValues="[
                        {
                          id: 0,
                          name: 'Yes',
                          ico: 'fal fa-check has-text-success'
                        },
                        {
                          id: 1,
                          name: 'No',
                          ico: 'fal fa-times has-text-red'
                        }]">
                    </button-list-selector>
                    <span v-show="showInspectionTypeWarning" class="help is-danger"><i class="fas fa-exclamation-triangle"></i> a selection is required</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-multiline">
              <div class="column is-6">
                <button-list-selector
                  validateAs="priority"
                  label="Priority"
                  :required="true"
                  v-model="selectedPriority"
                  :availableValues="allPriorities">
                </button-list-selector>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Due Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control">
                    <el-date-picker
                      popper-class="datepicker-container"
                      @change="onFormChange()"
                      name="requiredAt"
                      v-model="requiredAt"
                      type="datetime"
                      size="small"
                      data-vv-validate-on="input|change"
                      v-validate="'required'"
                      :class="{'is-danger': errors.has('requiredAt') }"
                      data-vv-as="completion date"
                      format="yyyy-MM-dd h:mm A"
                      default-time="13:00:00"
                      placeholder="Click to select...">
                    </el-date-picker>
                    <span v-show="errors.has('requiredAt')" class="help is-danger"><i v-show="errors.has('requiredAt')" class="fas fa-exclamation-triangle"></i> {{ errors.first('requiredAt') }}</span>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Expected Delivery Date<b-icon pack="fas" icon="" class="fa-ss"></b-icon></label>
                  <div class="field medium-top-margin">{{ expectedDeliveryDate  | formatDateOnly }}</div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <span class="label has-text-danger is-size-7">Update expected delivery date?</span>
                  <div class="control">
                    <b-switch type="is-success" v-model="isExpectedDateUpdate">{{ humanizedExpectedDealiveryDate }}</b-switch>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns">
              <div class="column is-12">
                <b-table
                  class="table"
                  :data="currentServiceRequest.serviceRequestLines">

                  <template slot-scope="props">
                    <b-table-column field="description" label="Description" class="service-request-event-column">
                      <span :class="{'is-striked': !props.row.isActive}">{{ props.row.description }}</span>
                    </b-table-column>

                    <b-table-column field="id" label="Actions" class="service-request-event-column">
                      <b-checkbox type="is-success" v-model="props.row.isActive"></b-checkbox>
                    </b-table-column>
                  </template>

                  <template slot="empty">
                    <section class="section">
                      <div class="has-text-centered">
                        <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                        <p>No service requests have been added.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
              </div>
            </div>
            <div class="quick-action" v-if="hasUsedAddAction">
              <button class="button" type="button" @click="addNewServiceRequest()" title="Click to add an additional work item." >
                <b-icon pack="fal" icon="plus" custom-size="fa-lg"></b-icon>
              </button>
            </div>
            <div class="notification is-warning large-top-margin is-flex" v-if="!hasUsedAddAction">
              <div class="quick-action">
                <button class="button" type="button" @click="addNewServiceRequest()" title="Click to add an additional work item." >
                  <b-icon pack="fal" icon="plus" custom-size="fa-lg"></b-icon>
                </button>
              </div>
              <span class="ml-10 mt-10">Add any sales or F&I addons neeeded</span>
            </div>
          </form>
          <b-modal :active.sync="isServiceRequestDefinition" scroll="keep" :width="640" :has-modal-card="true" v-if="isServiceRequestDefinition" :canCancel="false">
            <div class="modal-card">
              <header class="modal-card-head">
                <p class="modal-card-title">Work Item Description</p>
              </header>
              <section class="modal-card-body">
                <deal-service-request
                  scope="service-request"
                  useFooterPortal="modal-footer"
                  :currentServiceRequestId="currentServiceRequestId"
                  :forServiceRequest="currentServiceRequest"
                  v-on:save-edit-service-request="onSaveEditServiceRequest"
                  v-on:cancel-service-request="onCancelServiceRequest()">
                </deal-service-request>
              </section>
              <footer class="modal-card-foot">
                <portal-target name="modal-footer" class="actions" />
              </footer>
            </div>
          </b-modal>
        </section>
        <footer class="modal-card-foot">
          <div class="columns" style="width: 100%;">
            <div class="column is-6">
              <button class="button is-primary" type="button" @click="validateBeforeSubmit">Save</button>
              <button class="button is-danger" type="button" @click="onCancelGetReady" v-if="false">Cancel</button>
            </div>
            <div class="column is-4"></div>
            <div class="column is-2">
              <button class="button is-danger" type="button" @click="onSkipGetReady" v-if="false">Skip</button>
            </div>
          </div>
        </footer>
      </div>
    </b-modal>
  </portal>
</template>

<script>
import DealServiceRequest from '@/components/sales/deals/DealServiceRequest'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import SwitchSelector from '@/components/generic/SwitchSelector'

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'DealGetReadyCapture',
  mixins: [validateBeforeSubmitMixin],
  components: {
    'deal-service-request': DealServiceRequest,
    'button-list-selector': ButtonListSelector,
    'switch-selector': SwitchSelector
  },
  props: {
    dealItems: {
      type: Array,
      default: () => []
    },
    size: {
      type: String,
      default: 'is-width-600'
    },
    openDialog: {
      type: Boolean,
      default: false
    },
    expectedDeliveryDate: {
      type: Date,
      default: null
    },
    usePortal: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isAutoGetReadyActive: false,
      instanceId: this.$uuid.v4(),
      requiredAt: null,
      currentServiceRequest: {
        serviceRequestLines: []
      },
      isServiceRequestDefinition: false,
      isInspectionTypeRequired: null,
      hasUsedAddAction: false,
      isExpectedDateUpdate: false,
      selectedPriority: null,
      showInspectionTypeWarning: false
    }
  },
  methods: {
    removeServiceRequest: function (serviceRequestId) {
      this.currentServiceRequest.serviceRequestLines = this.$lodash.filter(this.currentServiceRequest.serviceRequestLines, function (current) {
        return current.id !== serviceRequestId
      })
    },
    addNewServiceRequest: function () {
      this.currentServiceRequestId = '0'
      this.isServiceRequestDefinition = true
      this.hasUsedAddAction = true
    },
    onEditServiceRequest: function (item) {
      this.currentServiceRequestId = item.id
      this.isServiceRequestDefinition = true
    },
    onSaveEditServiceRequest: function (serviceRequest) {
      if (serviceRequest.id === 0) {
        this.currentServiceRequestId = '0'
        this.currentServiceRequest.serviceRequestLines.push(serviceRequest)
        this.isServiceRequestDefinition = false
      } else {
        var that = this
        this.currentServiceRequest.serviceRequestLines = this.$lodash.filter(this.currentServiceRequest.serviceRequestLines, function (current) {
          return current.id !== that.currentServiceRequestId
        })
        this.currentServiceRequestId = '0'
        if (serviceRequest.status === 'Active' || Number.isInteger(serviceRequest.id)) {
          this.currentServiceRequest.serviceRequestLines.push(serviceRequest)
        }
        this.isServiceRequestDefinition = false
      }
    },
    onCancelServiceRequest: function () {
      this.currentServiceRequestId = '0'
      this.isServiceRequestDefinition = false
    },
    onSave: function () {
      this.showInspectionTypeWarning = false
      var selectedRequestLines = []

      if (this.isInspectionTypeRequired === null) {
        this.showInspectionTypeWarning = true
        return
      }

      this.currentServiceRequest.serviceRequestLines.forEach((item) => {
        if (item.isActive) {
          var line = {
            description: item.description,
            isActive: item.isActive
          }
          selectedRequestLines.push(line)
        }
      })

      var soldServiceRequest = {
        isCreate: true,
        inventoryId: this.dealItem.forInventory.id,
        requestPriority: this.selectedPriority ? this.selectedPriority.name : 'NotSpecified',
        inspectionType: this.isInspectionTypeRequired && this.isInspectionTypeRequired.id === 0 ? 'DeliveryReinspection' : '',
        requestStatusType: 'Pending',
        requiredAt: this.requiredAt,
        lines: selectedRequestLines,
        updateExpectedDeliveryDate: this.isExpectedDateUpdate
      }

      this.$emit('get-ready-created', soldServiceRequest)
      this.isAutoGetReadyActive = false
    },
    onCancelGetReady: function () {
      this.$emit('get-ready-cancelled')
      this.isAutoGetReadyActive = false
    },
    onSkipGetReady: function () {
      this.$emit('get-ready-skipped')
      this.isAutoGetReadyActive = false
    },
    loadDefaults: function () {
      this.selectedServiceRequestType = this.definedTypes.serviceRequestType.options.find((x) => x.id === this.definedTypes.serviceRequestType.GetReady)
      this.selectedServiceRequestStatus = this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.definedTypes.serviceRequestStatusType.New)
      this.selectedPriority = this.definedTypes.serviceRequestPriorityType.options.find((x) => x.id === this.definedTypes.serviceRequestPriorityType.High)
      this.requiredAt = this.expectedDeliveryDate

      this.currentServiceRequest.serviceRequestLines = [
        {
          id: 1,
          description: 'Check for recalls',
          status: 'Active',
          isActive: true
        }
      ]
      if (this.dealItem) {
        if (this.dealItem.vehicleType && this.dealItem.vehicleType.name !== 'New') {
          this.currentServiceRequest.serviceRequestLines.push({
            id: 2,
            description: 'Check if maintenance is due',
            status: 'Active',
            isActive: true
          })
        }
        if (this.dealItem.vehicleType && this.dealItem.vehicleType.name === 'Cpo') {
          this.currentServiceRequest.serviceRequestLines.push({
            id: 3,
            description: 'Check if CPO inspection is required',
            status: 'Active',
            isActive: true
          })
        }
      }
      this.currentServiceRequest.serviceRequestLines.push({
        id: 4,
        description: 'Install front plate',
        status: 'Active',
        isActive: false
      })
      this.currentServiceRequest.serviceRequestLines.push({
        id: 5,
        description: 'Peform NYS Inspection',
        status: 'Active',
        isActive: false
      })
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapDealerState(['currentDealer']),
    allPriorities: function () {
      return this.definedTypes.serviceRequestPriorityType.options
    },
    inspectionType: function () {
      return 'Re-Inspection for Delivery'
    },
    dealItem: function () {
      return this.dealItems && this.dealItems.find((x) => x.dealItemType === 'Sold') ? this.dealItems.find((x) => x.dealItemType === 'Sold') : null
    },
    buildVehicleInfo: function () {
      if (this.dealItem && this.dealItem.forInventory) {
        return this.dealItem.forInventory.listName
      }
    },
    humanizedExpectedDealiveryDate: function () {
      return this.isExpectedDateUpdate ? 'Yes' : 'No'
    }
  },
  watch: {
    openDialog: function (newValue) {
      if (newValue) {
        this.isAutoGetReadyActive = true
        this.loadDefaults()
      }
    }
  },
  mounted () {
    this.loadDefaults()
  }
}

</script>

<style scoped>
  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-card-body {
    overflow: auto;
    height: calc(65vh - 10px);
  }

  .service-request-event-column {
    text-align: left;
    vertical-align:middle;
  }
</style>

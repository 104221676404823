<template>
  <div id="app">
      <app-header v-if="isSystemReady"></app-header>
      <div class="section main-content">
        <div id="app-content" :class="{'login': !isSystemReady}">
          <div id="menu" :class="{'active': isSideBarActive && isSystemReady}">
            <app-menu></app-menu>
          </div>
          <div id="main-page" :class="{'is-flex': true, 'small': isSideBarActive && isSystemReady}">
            <div class="menu-action-container is-hidden-mobile">
              <a class="is-small menu-toggler" :class="{'active': !isSideBarActive}" @click="toggleSideBar" v-if="!isSideBarActive" title="Click to expand menu"><i class="fal fa-arrow-alt-from-left"></i></a>
            </div>
            <div id="primary-container">
              <span id="scroll-marker"></span>
              <router-view></router-view>
              <portal-target name="main-footer-spacer" slim/>
            </div>
            <app-footer v-if="isSystemReady"></app-footer>
          </div>
        </div>
      </div>
      <global-quick-view></global-quick-view>
      <portal-target name="global-modal-portal" slim></portal-target>
  </div>
</template>

<script>
import GlobalQuickView from '@/components/dashboard/GlobalQuickView'
import AppHeader from './components/AppHeader.vue'
import AppFooter from './components/AppFooter.vue'
import AppMenu from './components/AppMenu.vue'
import externalWindowService from '@/services/externalWindowService'
import { createNamespacedHelpers } from 'vuex'
import eventBus from '@/eventBus'
const { mapGetters, mapActions } = createNamespacedHelpers('user')
const mapAuthGetters = createNamespacedHelpers('auth').mapGetters

export default {
  name: 'App',
  data () {
    return {
    }
  },
  components: {
    'app-header': AppHeader,
    'app-footer': AppFooter,
    'app-menu': AppMenu,
    'global-quick-view': GlobalQuickView
  },
  computed: {
    ...mapAuthGetters(['isSystemReady']),
    ...mapGetters(['isSideBarActive'])
  },
  mounted: function () {
    window.addEventListener('message', message => {
      if (message.data.type === 'new-inventory') {
        this.$router.push({name: 'NewInventory', query: {preEntry: message.payLoad ? message.payLoad.preEntry : false}})
      }
      if (message.data.type === 'file-download-failure') {
        this.$buefy.toast.open({
          duration: 3000,
          message: 'File is invalid or failed to download. Please try again later.',
          type: 'is-danger',
          position: 'is-bottom'
        })
      }
    })
    eventBus.$on('reload-vehicle-make-list', this.reloadVehicleMakeList)
    eventBus.$on('reload-ro-service-vehicle-make-list', this.reloadRoServiceVehicleMakeList)
  },
  methods: {
    ...mapActions(['toggleSideBar']),
    reloadVehicleMakeList: function () {
      this.$store.dispatch('vehicleMake/setVehicleMakes')
    },
    reloadRoServiceVehicleMakeList: function () {
      this.$store.dispatch('roServiceVehicleMake/setDealerRoServiceVehicleMakes', { dealerId: this.$store.state.user.currentUser.dealerId })
    }
  },
  watch: {
    isSystemReady: function () {
      externalWindowService.isFrameReady(this.isSystemReady)
    }
  },
  beforeDestroy () {
    eventBus.$off('reload-vehicle-make-list', this.reloadVehicleMakeList)
    eventBus.$off('reload-ro-service-vehicle-make-list', this.reloadRoServiceVehicleMakeList)
  }
}
</script>

<style scoped>
  .loadingContainer {
    height: 100vh;
  }

  .login {
    height: 100vh !important;
  }

  .menu-action-container {
    position: sticky;
    top: 0;
    margin-right:3px;
    margin-top: 0.5em;
  }
</style>

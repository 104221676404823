<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      name="location"
      :searchable="true"
      :data-vv-scope="scope"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedLocation"
      :options="allLocations"
      label="name"
      trackBy="id"
      :maxHeight="120"
      :taggable="allowNew"
      @tag="locationAdded"
      placeholder="Type to search for a location"
      :loading="isLocationLoading"
      :internal-search="false"
      :options-limit="300"
      @search-change="onLocationSearch"
      :hideSelected="true"></multiselect>
      <a class="button is-primary new-location-btn" @click="isNewLocationActive = true" v-if="allowNew">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <b-modal :active.sync="isNewLocationActive" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body">
          <new-location mode="modal" actionPortal="modal-footer" headerPortal="modal-header" v-on:on-save="onLocationSaved" v-on:on-cancel="onLocationCancelled"></new-location>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import NewLocation from '@/components/configuration/locations/NewLocation'
import locationService from '@/services/locationService'

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'LocationSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Location'
    },
    validateAs: {
      type: String,
      default: 'Location'
    },
    required: {
      type: Boolean,
      default: true
    },
    allowNew: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    }
  },
  components: {
    'new-location': NewLocation
  },
  data () {
    return {
      isLocationLoading: false,
      isNewLocationActive: false,
      selectedLocation: this.value,
      data: []
    }
  },
  computed: {
    allLocations () {
      return this.data
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + 'location' : 'location'
    }
  },
  watch: {
    selectedLocation: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentLocation: function (newValue, oldValue) {
      this.selectedLocation = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedLocation = newValue
    }
  },
  methods: {
    onLocationSaved: function (newLocation) {
      this.selectedLocation = newLocation
      this.isNewLocationActive = false
    },
    onLocationCancelled: function () {
      this.isNewLocationActive = false
    },
    locationAdded: function (item) {
      var locationModel = {
        isCreate: true,
        subscriberId: this.$store.state.user.currentUser.subscriberId,
        dealerId: this.$store.state.dealer.currentDealer.id,
        name: item,
        status: 'Active',
        showInList: true,
        isOffSite: false
      }

      this.serverErrorMessage = ''
      this.serverErrors = []

      locationService.save(locationModel).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedLocation = response.location
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    clearAllLocations () {
      this.data = []
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.$store.state.dealer.currentDealer.id,
        sortBy: 'name.asc'
      }
      this.isLocationLoading = true
      this.clearAllLocations()
      locationService.all(searchCommand).then(response => {
        this.data = response.results
        this.isLocationLoading = false
      })
    },
    doLocationSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onLocationSearch (query) {
      this.doLocationSearch(query)
    }
  },
  mounted: function () {
    this.onLocationSearch('')
  }
}

</script>

<style scoped>
  .new-location-btn {
    margin-left:5px;
    height:40px;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(100vh - 185px);
  }
</style>

<template>
  <div class="is-inline-block" v-if="hasConsignmentAgreementAccess && isValid">
    <div class="is-inline-block" v-if="hasConsignmentAgreementAccess">
      <div class="quick-action" v-if="isValidForConsignmentAgreementSigning">
        <button type="button" class="button" @click="updateConsignmentAgreementModalState(true)" v-tooltip="getToolTipForConsignmentAgreement()">
          <i data-type="C" class="icon fal fa-envelope-open-text fa-lg" :class="getColorClassByAgreementStatus(currentInventory.consignmentAgreementStatusId)" v-if="!currentInventory.isConsignmentAgreementUploaded"/>
          <i data-type="U" class="icon fal fa-upload fa-lg has-text-success" v-else/>
        </button>
      </div>
      <div class="quick-action" v-else>
        <button type="button" class="button" disabled v-tooltip="invalidForConsignmentAgreementReasons" :title="invalidForConsignmentAgreementReasons">
          <i data-type="C" class="icon fal fa-envelope-open-text fa-lg"/>
        </button>
      </div>
    </div>
    <div class="control is-inline-block quick-action" v-if="lastSignedConsignmentAgreement !== '' && hasConsignmentAgreementAccess">
      <button class="button is-success" @click.prevent="onDownloadLastConsignmentAgreement" v-tooltip="'Click to download the last signed consignment agreement.'">
        <b-icon pack="fal" icon="cloud-download" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <pdf-modal
        :pdfFile="pdfModalFile"
        :usePortal="true"
        :portalOrder="2"
        v-on:on-pdf-downloaded="onPreviewed"
        v-on:on-pdf-closed="onPreviewClosed"
        v-if="pdfModalFile && pdfModalFile.src">
    </pdf-modal>
    <portal to="global-modal-portal" v-else-if="isConsignmentAgreementActive" :order="1">
      <b-modal :active.sync="isConsignmentAgreementActive" scroll="keep" :has-modal-card="true" :canCancel="false" class="consignment-agreement-modal">
        <div class="modal-card">
          <header class="modal-card-head">
            <div class="subtitle is-6 has-text-centered has-text-info is-marginless">Send Consignment Agreement</div>
          </header>
          <section class="modal-card-body view-container" style="padding: 0 0px">
            <b-tabs size="is-small" :animated="false" type="is-toggle" @change="loadData()" v-model="tabIndex">
              <b-tab-item label="Division of Sale" icon-pack="fas" icon="fas fa-percent fa-sm">
                <form data-vv-scope="rates-form">
                  <section style="padding: 12px;">
                    <div class="columns">
                      <div class="column is-6">
                        <div class="field">
                          <label class="label">Miles</label>
                          <div class="control has-icons-right">
                            <input name="miles" data-vv-scope="rates-form" data-vv-as="miles" class="mileage-input" v-validate="{'numeric': true, 'required': true}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('rates-form.miles') }" type="number" placeholder="#####" v-model="miles">
                            <span v-show="errors.has('rates-form.miles')" class="help is-danger"><i v-show="errors.has('rates-form.miles')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rates-form.miles') }}</span>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label class="label">Lien Amount</label>
                          <div class="control has-icons-left">
                            <money name="lienAmount" data-vv-scope="rates-form" data-vv-as="lien amount" v-validate="{'decimal': true, 'required': true}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('rates-form.lienAmount') }" type="text" placeholder="0.00" v-model="lienAmount"></money>
                            <span v-show="errors.has('rates-form.lienAmount')" class="help is-danger"><i v-show="errors.has('rates-form.lienAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rates-form.lienAmount') }}</span>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns is-multiline">
                      <div class="column is-6">
                        <div class="field">
                          <label class="label">Consignment Minimum</label>
                          <div class="control has-icons-left">
                            <money name="consignmentAmount" data-vv-scope="rates-form" data-vv-as="consignment amount" v-validate="{'decimal': true, 'required': true}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('rates-form.consignmentAmount') }" type="text" placeholder="0.00" v-model="consignmentAmount" v-bind="$globalMoneyFormat"></money>
                            <span v-show="errors.has('rates-form.consignmentAmount')" class="help is-danger"><i v-show="errors.has('rates-form.consignmentAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rates-form.consignmentAmount') }}</span>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label class="label">Sale Price</label>
                          <div class="control has-icons-left">
                            <money name="askingPrice" data-vv-as="asking price" v-validate="{'decimal': true, 'required': true}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('rates-form.askingPrice') }" type="text" placeholder="0.00" v-model="askingPrice" v-bind="$globalMoneyFormat"></money>
                            <span v-show="errors.has('rates-form.askingPrice')" class="help is-danger"><i v-show="errors.has('rates-form.askingPrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rates-form.askingPrice') }}</span>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-12">
                        <div class="field">
                          <label class="label">Sale Proceeds Type</label>
                          <div class="control">
                            <b-field>
                              <b-radio-button v-model="saleProceedsType" size="is-small" native-value="Percentage" type="is-success"><span>Percentage</span></b-radio-button>
                              <b-radio-button v-model="saleProceedsType" size="is-small" native-value="Split" type="is-success"><span>Split</span></b-radio-button>
                              <b-radio-button v-model="saleProceedsType" size="is-small" native-value="Flat" type="is-success"><span>Flat</span></b-radio-button>
                            </b-field>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns" v-if="saleProceedsType === 'Percentage'">
                      <div class="column is-6">
                        <div class="field">
                          <label class="label">Consignment Percentage</label>
                          <div class="control has-icons-right">
                            <input name="consignmentPercentage" data-vv-scope="rates-form" data-vv-as="consignment percentage" v-validate="'required|decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('rates-form.consignmentPercentage') }" type="text" placeholder="0.00" v-model="consignmentPercentage">
                            <span v-show="errors.has('rates-form.consignmentPercentage')" class="help is-danger"><i v-show="errors.has('rates-form.consignmentPercentage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rates-form.consignmentPercentage') }}</span>
                            <span v-show="consignmentPercentage < 10" class="help is-danger"><i v-show="consignmentPercentage < 10" class="fas fa-exclamation-triangle"></i>Special consignment rate concession !!!</span>
                            <span class="icon is-small is-right">
                              <i class="fas fa-percent"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns" v-if="saleProceedsType === 'Split' || saleProceedsType === 'Flat'">
                      <div :class="saleProceedsType === 'Split' ? 'column is-6' : 'column is-12'">
                        <div class="field">
                          <label class="label" v-if="saleProceedsType === 'Split'">Split Value</label>
                          <label class="label" v-else>Dealer Flat - Profit</label>
                          <div class="control has-icons-left">
                            <money name="consignmentAgreementValue" data-vv-scope="rates-form" data-vv-as="consignment agreement value" v-validate="{'decimal': true, 'required': saleProceedsType === 'Split' || saleProceedsType === 'Flat'}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('rates-form.consignmentAgreementValue') }" type="text" placeholder="0.00" v-model="consignmentAgreementValue" v-bind="$globalMoneyFormat"></money>
                            <span v-show="errors.has('rates-form.consignmentAgreementValue')" class="help is-danger"><i v-show="errors.has('rates-form.consignmentAgreementValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rates-form.consignmentAgreementValue') }}</span>
                            <span v-if="saleProceedsType === 'Split'" v-show="consignmentAgreementValue > askingPrice" class="help is-danger"><i v-show="consignmentAgreementValue > askingPrice" class="fas fa-exclamation-triangle"></i>Value cannot be more asking price</span>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6" v-if="saleProceedsType === 'Split'">
                        <div class="field">
                          <label class="label">Expected Dealer Profit</label>
                          <div class="field" style="margin-top:10px;">
                            <div style="color: red">{{ consignmentAgreementAmount | currency }}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns" v-if="saleProceedsType === 'Split'">
                      <div class="column is-6">
                        <div class="field">
                          <label class="label">Split Ratio First</label>
                          <div class="control has-icons-right">
                            <input name="percentageSplitFirst" data-vv-scope="rates-form" data-vv-as="percentage split first" v-validate="{'decimal': true, 'required': saleProceedsType === 'Split'}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('rates-form.percentageSplitFirst') }" type="number" v-model="percentageSplitFirst">
                            <span v-show="errors.has('rates-form.percentageSplitFirst')" class="help is-danger"><i v-show="errors.has('rates-form.percentageSplitFirst')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rates-form.percentageSplitFirst') }}</span>
                            <span class="icon is-small is-right">
                              <i class="fas fa-percent"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                      <div class="column is-6">
                        <div class="field">
                          <label class="label">Split Ratio Second</label>
                          <div class="control has-icons-right">
                            <input name="percentageSplitSecond" data-vv-scope="rates-form" data-vv-as="percentage split second" v-validate="{'decimal': true, 'required': saleProceedsType === 'Split'}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('rates-form.percentageSplitSecond') }" type="number" v-model="percentageSplitSecond">
                            <span v-show="errors.has('rates-form.percentageSplitSecond')" class="help is-danger"><i v-show="errors.has('rates-form.percentageSplitSecond')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rates-form.percentageSplitSecond') }}</span>
                            <span class="icon is-small is-right">
                              <i class="fas fa-percent"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="columns">
                      <div class="column is-12" v-if="saleProceedsType === 'Percentage'">
                        <label class="label">Preview</label>
                        <div class="field">
                          <p style="width: 800px; overflow-wrap: break-word;" class="is-subtitle is-size-7">
                            Consignee shall be entitled to receive {{consignmentPercentage}}% of the “Cash Delivered Price of Unit”, as set forth in the Automobile’s contract of sale with the Buyer (the “Sale Price”),
                            plus those costs of the sale (including for repairs, accessories, detailing, transport, etc.), as set forth above and Owner shall be entitled to receive {{finalPercentage}}% of the sale price,
                            less those costs of the sale, if same have not already been charged to Owner’s credit card.
                            Owner will not be entitled to a percentage of proceeds of any ancillary or “add on” products or services purchased by Buyer along with the Automobile.
                            If the Automobile was previously sold to Owner by Consignee, Owner’s share of the proceeds shall not be greater than the original purchase price of the Automobile from Consignee, excluding taxes, fees, and other products, as set forth in the Automobile’s original purchase agreement.
                          </p>
                        </div>
                      </div>
                      <div class="column is-12" v-if="saleProceedsType === 'Split'">
                        <label class="label">Preview</label>
                        <div class="field">
                          <p style="width: 800px; overflow-wrap: break-word;" class="is-subtitle is-size-7">
                            Consignee and Owner shall split the difference between the actual sale price of the Automobile and ${{consignmentAgreementValue}}, {{percentageSplitFirst}}% / {{percentageSplitSecond}}%, respectively. In addition, Consignee
                            shall be entitled to reimbursement of those costs of the sale (including for repairs, accessories, detailing, transport, etc.), as set forth above, if same have not already been charged to
                            Owner's credit card. In the event of reimbursement, Consignee may deduct those sale costs from Owner's share of the proceeds.
                            Owner will not be entitled to a percentage of proceeds of any ancillary or "add on" products or services purchased by Buyer along with the Automobile.
                            In the event of a dispute, Consignee’s share of the proceeds shall not be less than {{consignmentPercentage}}% of the actual sale price, not including any sales costs to be paid by the Owner to Consignee.
                            If the Automobile was previously sold to Owner by Consignee, Owner’s share of the proceeds shall not be greater than the original purchase price of the Automobile from Consignee, excluding taxes, fees, and other products, as set forth in the Automobile’s original purchase agreement.
                          </p>
                        </div>
                      </div>
                      <div class="column is-12" v-if="saleProceedsType === 'Flat'">
                        <label class="label">Preview</label>
                        <div class="field">
                          <p style="width: 800px; overflow-wrap: break-word;" class="is-subtitle is-size-7">
                            Consignee shall be entitled to a flat fee of ${{consignmentAgreementValue}} for the sale of the Automobile, plus those costs of the sale (including for
                            repairs, accessories, detailing, transport, etc.), as set forth above, if same have not already been charged to the Owner's credit card.
                            Consignee may deduct those sale costs from Owner's share of the proceeds. Owner will not be entitled to a percentage of proceeds of any ancillary or "add on" products or services purchased by Buyer along with the Automobile.
                            In the event of a dispute, Consignee’s share of the proceeds shall not be less than {{consignmentPercentage}}% of the actual sale price, not including any sales costs to be paid by the Owner to Consignee.
                          </p>
                        </div>
                      </div>
                    </div>
                  </section>
                </form>
              </b-tab-item>
              <b-tab-item label="Definition" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                <section style="padding: 12px">
                  <div id="simple-overview" class="box" v-if="!consignmentAgreementVerificationActive">
                    <section-header title="Vehicle Details" :overview="getInventoryOverview" v-clipboard:copy="currentInventory.vinNo" style="position: relative; z-index:2000"></section-header>
                    <div class="columns is-multiline">
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Production Year</label>
                          <div class="field">
                            {{ currentInventory.vehicleProductionYear || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Make</label>
                          <div class="field">
                            {{ currentInventory.vehicleMakeName || currentInventory.customMake || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Model</label>
                          <div class="field">
                            {{ currentInventory.vehicleModelName || currentInventory.customModel || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Trim</label>
                          <div class="field">
                            {{ currentInventory.trim || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Style</label>
                          <div class="field">
                            {{ currentInventory.style || 'Unspecified' }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Mileage</label>
                          <div class="field">
                            <div style="color: red">{{ currentInventory.usage ? formattedMileage : 'Unspecified' }}</div>
                            &nbsp;
                            <div style="color: red">{{ currentInventory.trueMileageUnknown ? 'TMU' : ''}}</div>
                            &nbsp;
                            <div style="color: red">{{ currentInventory.mileageExempt ? 'Exempt' : ''}}</div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-3" v-if="currentInventory.purchasedFromName">
                        <div class="field">
                          <label class="label">Consigned From</label>
                          <div class="field">
                            {{ currentInventory.purchasedFromName }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <label class="label">Notes</label>
                          <div class="field">
                            {{ currentInventory.consignmentNote}}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Consignment Type</label>
                          <div class="field">
                            {{ currentInventory.saleProceedsType }}
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Minimum Sale Price</label>
                          <div class="field">
                            <div style="color: red">{{ currentInventory.consignmentMinimum | currency }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-3" v-if="saleProceedsType === 'Percentage'">
                        <div class="field">
                          <label class="label">Consignment Percentage</label>
                          <div class="field">
                            <div :style="currentInventory.consignmentPercentage < 10 ? 'color: red' : 'color: black'">{{ currentInventory.consignmentPercentage }} %</div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-3" v-if="saleProceedsType === 'Split'">
                        <div class="field">
                          <label class="label">Split Ratio</label>
                          <div class="field">
                            <div style="color: red">{{ currentInventory.percentageSplitFirst }}% / {{ currentInventory.percentageSplitSecond }}%</div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-3" v-if="currentInventory.saleProceedsType === 'Split' || currentInventory.saleProceedsType === 'Flat'">
                        <div class="field">
                          <label class="label" v-if="currentInventory.saleProceedsType === 'Split'">Expected Dealer Profit</label>
                          <label class="label" v-else>Dealer Flat - Profit</label>
                          <div class="field" v-if="currentInventory.saleProceedsType === 'Flat'">
                            <div style="color: red">${{ formattedFlatValue }}</div>
                          </div>
                          <div class="field" v-if="currentInventory.saleProceedsType === 'Split'">
                            <div style="color: red">{{ consignmentAgreementAmount | currency }}</div>
                          </div>
                        </div>
                      </div>
                      <div class="column is-3">
                        <div class="field">
                          <label class="label">Consignment Terms</label>
                          <div class="field">
                            {{ currentInventory.consignmentTerms}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="consignmentAgreementVerificationActive">
                    <div class="columns is-multiline">
                      <div class="column is-12">
                        <car-fax-control
                          :inventory="currentInventory"
                          title="CarFax Vehicle Status"
                          :carFaxRating="currentInventory.carFaxRating"
                          :vinNo="currentInventory.vinNo"
                          :displayOnly="false"
                          v-on:on-new-carfax="onCarFaxForConsignmentAgreement"
                          v-on:on-carfax-failed="onCarFaxForConsignmentAgreement"
                          :runAutomatically="true"
                          :autoUpdateInventory="true"
                          :modalContainer="true"
                          v-on:on-show-carfax-report="onShowCarFaxReport">
                        </car-fax-control>
                      </div>
                      <div class="column is-12" v-if="autoCheckEnabled">
                        <auto-check-control
                          :inventory="currentInventory"
                          title="AutoCheck Vehicle Status"
                          :autoCheckRating="currentInventory.autoCheckRating"
                          :vinNo="currentInventory.vinNo"
                          :displayOnly="false"
                          v-on:on-new-autocheck="onAutoCheckForConsignmentAgreement"
                          v-on:on-autocheck-failed="onAutoCheckForConsignmentAgreement"
                          :runAutomatically="true"
                          :autoUpdateInventory="true"
                          :modalContainer="true"
                          v-on:on-show-autocheck-report="onShowAutoCheckReport"
                          :showRefresh="false"
                          :purchasedFromType="currentInventory.purchasedFromType"
                          :inventoryType="selectedInventoryStatusType"
                          v-on:autocheck-disabled="onAutoCheckDisabled">
                        </auto-check-control>
                      </div>
                      <div class="column is-12">
                        <inventory-appraisal-control
                          :appraisedValue="mostRecentAppraisal"
                          displayMode="Compact"
                          :inventory="inventoryAppraisalParams"
                          v-on:on-new-appraisal="onAppraisedForConsignmentAgreement"
                          v-on:on-appraisal-failed="onAppraisedForConsignmentAgreement"
                          :runAutomatically="true"
                          :autoUpdateInventory="true"></inventory-appraisal-control>
                      </div>
                      <div class="column is-12 mt-10" v-if="blackBookEnabled">
                        <inventory-black-book-control
                        :blackBookInformation="mostRecentBlackBookInformation"
                        :inventory="inventoryAppraisalParams"
                        v-on:on-new-blackbook-information="onBlackBookInformationRetrieved"
                        v-on:on-black-book-failed="onBlackBookInformationRetrieved"
                        :runAutomatically="true"
                        :autoUpdateInventory="true"
                        v-on:black-book-disabled="onBlackBookDisabled">
                        </inventory-black-book-control>
                      </div>
                      <div class="verification-container column is-12" v-if="consignmentAgreementAppraisalCheckComplete && consignmentAgreementCarFaxCheckComplete && consignmentAgreementAutoCheckCheckComplete && (!consignmentAgreementCarFaxIsGood || !consignmentAgreementAppraisalIsGood || !consignmentAgreementAutoCheckIsGood)">
                        <h4 class="title is-size-7 has-text-danger" v-if="consignmentAgreementCarFaxFailed">Unable to get latest CarFax report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="!consignmentAgreementCarFaxIsGood">The latest CarFax report indicated multiple owners, a recall pending or some form of damage.</h4>
                        <h4 class="title is-size-7 has-text-danger" v-if="autoCheckEnabled && consignmentAgreementAutoCheckFailed">Unable to get latest AutoCheck report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="autoCheckEnabled && !consignmentAgreementAutoCheckIsGood">The latest AutoCheck report indicated multiple owners, a recall pending or some form of damage.</h4>
                        <h4 class="title is-size-7 has-text-danger" v-if="consignmentAgreementAppraisalFailed">Unable to get latest appraisal report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="!consignmentAgreementAppraisalIsGood">The proposed consignment minimum price of this vehicle is outside your dealer's acceptable market value tolerance.</h4>
                        <h4 class="title is-size-7 has-text-danger" v-if="blackBookEnabled && blackBookCheckFailed">Unable to get latest black book report. Please contact your administrator for investigation</h4>
                      <h4 class="title is-size-7 has-text-danger" v-else-if="blackBookEnabled && !blackBookCheckIsGood">Unable to obtain a complete black book report for this vehicle.</h4>
                        <h4 class="title is-size-7">Please Note: By activating the switch below you acknowledge the above vehicle's current status and accept responsibility for the consignment of this vehicle and I am fully aware of the conditions and market value of the vehicle.</h4>
                        <div class="columns">
                          <b-switch class="column is-narrow is-vcentered" type="is-info" v-model="consignmentAgreementAcceptConditions"></b-switch>
                          <h4 class="title is-size-7 column is-narrow is-vcentered" v-if="consignmentAgreementAcceptConditions">I ACCEPT the above statement</h4>
                          <h4 class="title is-size-7 column is-narrow is-vcentered" v-if="!consignmentAgreementAcceptConditions">I DO NOT ACCEPT the above statement</h4>
                        </div>
                      </div>
                      <div class="status-container column is-12" v-if="consignmentAgreementAppraisalCheckComplete && consignmentAgreementCarFaxCheckComplete && carFaxApproved && appraisalApproved && autoCheckValidated && blackBookCheckComplete && blackBookCheckIsGood">
                        <h4 class="title is-size-7 has-text-primary">{{approvalInformation}}</h4>
                      </div>
                      <div class="status-container column is-12" v-else-if="consignmentAgreementAppraisalCheckComplete && consignmentAgreementCarFaxCheckComplete && consignmentAgreementCarFaxIsGood && consignmentAgreementAppraisalIsGood && autoCheckValidated && blackBookCheckComplete && blackBookCheckIsGood">
                        <h4 class="title is-size-7 has-text-primary">The vehicle condition matches your dealers requirements and is approved for consignment minimum.</h4>
                      </div>
                      <div class="status-container column is-12" v-if="!consignmentAgreementAppraisalCheckComplete || !consignmentAgreementCarFaxCheckComplete || !consignmentAgreementAutoCheckCheckComplete">
                        Checking latest vehicle reports. Please wait...
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="!isUpload && !consignmentAgreementDamageVerifiedActive">
                      <div class="columns">
                        <div class="column is-12">
                          <form data-vv-scope="recipient-form">
                            <div class="field">
                              <label class="label">Recipients<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <multiselect
                                :multiple="true"
                                :taggable="true"
                                v-validate="{'required': true}"
                                data-vv-scope="recipient-form"
                                data-vv-as="signature recipients"
                                data-vv-validate-on="input|close"
                                :class="{'is-danger': errors.has('recipient-form.recipients') }"
                                @tag="onRecipientAdded"
                                name="recipients"
                                label="fullName"
                                trackBy="email"
                                tagPlaceholder="Enter to add email address"
                                v-model="selectedRecipients"
                                :options="selectedRecipients">
                              </multiselect>
                              <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                              <span v-show="mustShowEmailError" class="help is-danger"><i v-show="mustShowEmailError" class="fas fa-exclamation-triangle"></i> {{ emailMatchErrorMessage }}</span>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-12">
                          <div class="field">
                            <label class="label">Subject</label>
                            <div class="control">
                              <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-12">
                          <div class="field">
                            <label class="label">Message (optional)</label>
                            <div class="control">
                              <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box" v-else-if="!isUpload && consignmentAgreementDamageVerifiedActive">
                      <h3 class="title is-6">Vehicle Damage Details</h3>
                      <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
                      <form data-vv-scope="damage-form">
                        <div class="columns">
                          <div class="column is-12">
                            <switch-selector type="is-info" label="Is there any damage?" v-model="selectedDamaged"></switch-selector>
                          </div>
                        </div>
                        <div class="columns" v-if="selectedDamaged">
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Damage Description (previous and/or current damage)<b-icon v-if="selectedDamaged" pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <div class="control">
                                <textarea name="damageNote" v-validate="{'required': selectedDamaged}" :class="{'textarea': true}" data-vv-as="damage description" v-model="damageVehicleNote"></textarea>
                                <span v-show="errors.has('damage-form.damageNote')" class="help is-danger"><i v-show="errors.has('damage-form.damageNote')" class="fas fa-exclamation-triangle"></i> {{ errors.first('damage-form.damageNote') }}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    <div v-else>
                      <div class="columns" style="margin-left:-0.5em">
                        <div class="column is-12">
                          <file-selector
                          v-model="consignmentAgreement"
                          :multiple="false"
                          label="Signed Agreement"
                          :required="true"
                          :isBoxed="false"
                          :usePortalViewer="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </b-tab-item>
              <b-tab-item label="History" icon-pack="fas" icon="fas fa-history fa-sm">
                <section>
                  <b-table
                    class="lists"
                    :data="documentSigningRequestData">
                    <template slot-scope="props">
                      <b-table-column field="status" label="Status" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        <i data-type="C" class="icon fal fa-envelope-open-text fa-lg list-icon" :class="getColorClassByAgreementStatus(props.row.signingStatus)" v-tooltip="getToolTipForConsignmentAgreement()"/>
                        <a target="live-doc-download" :href="buildConsignmentsAgreementUrl(props.row.dealFileLocation)" v-tooltip="'Click to download the last signed purchasers agreement.'" v-if="props.row.dealFileLocation && (props.row.signingStatus === 10 || props.row.signingStatus === 3)"><i class="icon fal fa-cloud-download fa-lg list-icon has-text-success"/></a>
                      </b-table-column>
                      <b-table-column field="email" label="Recipients" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.destinationAddresses }}
                      </b-table-column>
                      <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ formateSignatureType(props.row.type) }}
                      </b-table-column>
                      <b-table-column field="date" label="Date" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.sentAt | formatDateOnly }}
                      </b-table-column>
                      <b-table-column field="time" label="Time" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ convertTimeString(props.row.sentAt, props.row.signingStatus) }}
                      </b-table-column>
                      <b-table-column field="id" label="Actions">
                        <a v-if="props.row.cancellationSent === false" @click.stop.prevent="onCancelConsignmentAgreement(props.row)"><i class="icon fa-fw fal fa-times-circle fa-lg" :class="{'has-text-danger': !isCancellationDisabled(props.row), 'has-text-grey': isCancellationDisabled(props.row)}" v-tooltip="isCancellationDisabled(props.row) ? 'Request cannot be cancelled at this point' : 'Cancel Request'"/></a>
                        <a v-else><i class="icon fa-fw fal fa-times-circle fa-lg has-text-success" title='Cancellation request has been made'/></a>
                        <a v-if="props.row.reminderSent === false" @click.stop.prevent="onConsignmentAgreementReminder(props.row)"><i class="icon fa-fw fal fa-redo fa-lg" :class="{'has-text-link': !isReminderDisabled(props.row), 'has-text-grey': isReminderDisabled(props.row)}" v-tooltip="isReminderDisabled(props.row) ? 'Reminder cannot be sent at this point' : 'Send Reminder'"/></a>
                        <a v-else><i class="icon fa-fw fal fa-envelope fa-lg has-text-success" title='Reminder has been sent'/></a>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No consignment documents has been sent for this item.</p>
                        </div>
                      </section>
                    </template>
                  </b-table>
                </section>
              </b-tab-item>
            </b-tabs>
          </section>
          <footer class="modal-card-foot">
            <div class="columns" style="width: 100%">
              <div class="column is-4">
                <button class="button is-primary" :disabled="isUploadingFile" :class="{'is-loading': ratesFormSaving}" @click="next()" type="button" v-if="!isUpload && !divisionOfSalesAccepted">Next</button>
                <button class="button is-primary" :disabled="isUploadingFile" :class="{'is-loading': isSaving}" @click="onDamageVerifyConsignmentAgreement(currentInventory)" type="button" v-if="consignmentAgreementDamageVerifiedActive && !isUpload">Next</button>
                <button class="button is-primary" :disabled="isUploadingFile" @click="onVerifyConsignmentAgreement(currentInventory)" type="button" v-if="!consignmentAgreementVerificationActive && !isUpload && consignmentAgreementDamageVerified">Verify</button>
                <button class="button is-primary" :disabled="consignmentAgreementFinalSendDisallowed  || isUploadingFile" @click="onEmailConsignmentAgreement(currentInventory)" type="button" v-if="consignmentAgreementVerificationActive && !isUpload">Send</button>
                <button class="button is-primary" :disabled="isUploadingFile" @click="onVerifyConsignmentAgreement(currentInventory)" type="button" v-if="!consignmentAgreementVerificationActive && isUpload">Verify</button>
                <button class="button is-primary" :disabled="consignmentAgreementFinalSendDisallowed || isUploadingFile" @click="onEmailConsignmentAgreement(currentInventory)" type="button" v-if="consignmentAgreementVerificationActive && isUpload">Save</button>
                <button class="button is-danger" @click="updateConsignmentAgreementModalState(false)" type="button">Cancel</button>
              </div>
              <div class="column is-8">
                <button class="button is-primary is-pulled-right" :disabled="isUploadingFile" style="margin-right: 5px;" @click="updateUploadState()" type="button">{{isUpload ? 'Cancel Upload & Send For Signing': 'Upload Signed Agreement'}}</button>
                <button :disabled="isUploadingFile" class="button is-primary is-pulled-right" style="margin-right: 5px;" :class="{'is-loading': consignmentAgreementPreviewDownloading}" @click="onPreview(currentInventory)" type="button" v-if="!isUpload && divisionOfSalesAccepted">Preview</button>
              </div>
            </div>
          </footer>
        </div>
      </b-modal>
    </portal>
    <auto-check-report-control
      v-if="autoCheckEnabled"
      :inventory="currentInventory"
      title="AutoCheck Vehicle Status"
      :vinNo="currentInventory.vinNo"
      v-on:on-hide-autocheck-report="onHideAutoCheckReport"
      :showAutoCheckReportModal="showReport">
    </auto-check-report-control>
    <car-fax-report-control
      title="CarFax Vehicle Status"
      v-on:on-hide-carfax-report="onHideCarFaxReport"
      :showCarFaxReportModal="showCarFaxReport"
      :carFaxReportUrl="carFaxReportUrl">
    </car-fax-report-control>
    <send-reminder-control
      v-on:on-hide-reminder-control="onHideReminderModal"
      :showConfirmationModal="showConfirmReminder"
      :signatureRequest="signatureRequest"
      v-on:send-reminder="sendReminder">
    </send-reminder-control>
    <cancel-signature-request-control
      v-on:on-hide-cancel-request-control="onHideCancellationRequestModal"
      :showRequestCancellationModal="showCancelRequest"
      :signatureRequest="cancelSignatureRequest"
      v-on:send-cancellation-request="sendCancellationRequest">
    </cancel-signature-request-control>
  </div>
</template>

<script>
import eventBus from '@/eventBus'
import inventoryService from '@/services/inventoryService'
import supplierService from '@/services/supplierService'
import documentSigningRequestService from '@/services/documentSigningRequestService'
import formattingMixin from '@/mixins/inventory/formatting'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import CarFaxControl from '@/components/generic/CarFaxControl'
import InventoryAppraisalControl from '@/components/generic/InventoryAppraisalControl'
import FileSelector from '@/components/generic/FileSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import utilitiesMixin from '@/mixins/generic/utilities'
import Vue from 'vue'
import AutoCheckControl from '@/components/generic/AutoCheckControl'
import AutoCheckReportControl from '@/components/generic/AutoCheckReportControl'
import SectionHeader from '@/components/generic/SectionHeader'
import CarFaxReportControl from '@/components/generic/CarFaxReportControl'
import SendReminderControl from '@/components/generic/SendReminderControl'
import CancelSignatureRequestControl from '@/components/generic/CancelSignatureRequestControl'
import InventoryBlackBookControl from '@/components/generic/InventoryBlackBookControl'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapSubscriberGetters = createNamespacedHelpers('subscriber').mapGetters

export default {
  name: 'InventoryConsignmentAgreementAction',
  mixins: [validateBeforeSubmitMixin, formattingMixin, utilitiesMixin],
  components: {
    'car-fax-control': CarFaxControl,
    'inventory-appraisal-control': InventoryAppraisalControl,
    'file-selector': FileSelector,
    'switch-selector': SwitchSelector,
    'error-display-component': ErrorDisplayComponent,
    'auto-check-control': AutoCheckControl,
    'auto-check-report-control': AutoCheckReportControl,
    'section-header': SectionHeader,
    'car-fax-report-control': CarFaxReportControl,
    'send-reminder-control': SendReminderControl,
    'cancel-signature-request-control': CancelSignatureRequestControl,
    'inventory-black-book-control': InventoryBlackBookControl,
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      documentSigningRequestData: [],
      consignmentAgreementVerificationActive: false,
      consignmentAgreementPreviewDownloading: false,
      consignmentAgreementCarFaxCheckComplete: false,
      consignmentAgreementCarFaxIsGood: false,
      consignmentAgreementCarFaxFailed: false,
      consignmentAgreementAcceptConditions: false,
      consignmentAgreementAppraisalCheckComplete: false,
      consignmentAgreementAppraisalIsGood: false,
      consignmentAgreementAppraisalFailed: false,
      isConsignmentAgreementActive: false,
      divisionOfSalesAccepted: false,
      ratesFormSaving: false,
      instanceId: this.$uuid.v4(),
      latestAppraisal: null,
      selectedRecipients: [],
      isUpload: false,
      consignmentAgreement: [],
      consignmentAgreementDamageVerified: false,
      consignmentAgreementDamageVerifiedActive: false,
      selectedDamaged: false,
      damageNoteAttribute: null,
      damageVehicleNote: '',
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      tabIndex: 0,
      emailMatchErrorMessage: '',
      saleProceedsType: this.currentInventory.saleProceedsType,
      miles: this.currentInventory.usage,
      askingPrice: this.currentInventory.askingPrice,
      consignmentPercentage: this.currentInventory.consignmentPercentage,
      consignmentAmount: this.currentInventory.consignmentMinimum,
      lienAmount: this.currentInventory.payoffAmount,
      consignmentAgreementValue: this.currentInventory.consignmentAgreementValue,
      percentageSplitFirst: this.currentInventory.percentageSplitFirst ? this.currentInventory.percentageSplitFirst : 50,
      percentageSplitSecond: this.currentInventory.percentageSplitSecond ? this.currentInventory.percentageSplitSecond : 50,
      consignmentAgreementAutoCheckCheckComplete: false,
      consignmentAgreementAutoCheckIsGood: false,
      consignmentAgreementAutoCheckFailed: false,
      showReport: false,
      showCarFaxReport: false,
      signatureRequest: null,
      showConfirmReminder: false,
      sendingReminder: false,
      cancelSignatureRequest: null,
      showCancelRequest: false,
      sendingCancellationRequest: false,
      carFaxHashMatch: false,
      autoCheckHashMatch: false,
      appraisalRatingHashMatch: false,
      carFaxApproved: false,
      autoCheckApproved: false,
      appraisalApproved: false,
      isUploadingFile: false,
      fileUploadCount: 0,
      blackBookInformation: null,
      blackBookCheckComplete: false,
      blackBookCheckIsGood: false,
      blackBookCheckFailed: false,
      pdfModalFile: null
    }
  },
  methods: {
    isCancellationDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    isReminderDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    loadData: function () {
      if (this.tabIndex === 0) return
      let inventoryId = this.currentInventory.id
      const searchCriteria = {
        inventoryId: inventoryId,
        dealerId: this.currentDealer.id,
        signingRequestType: 2,
        query: '',
        pageSize: this.perPage,
        sortBy: 'sentAt',
        page: this.page
      }
      this.isLoading = true
      documentSigningRequestService.searchByType(searchCriteria).then(response => {
        this.documentSigningRequestData = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          item.reminderSent = false
          item.cancellationSent = false
          this.documentSigningRequestData.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.documentSigningRequestData = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    convertTimeString: function (date, status) {
      if (status !== 0) {
        var d = new Date(date)
        var hour = '' + d.getHours()
        var minute = '' + d.getMinutes()

        if (hour.length < 2) hour = '0' + hour
        if (minute.length < 2) minute = '0' + minute

        return [hour, minute].join(':')
      } else {
        return ''
      }
    },
    onConsignmentAgreementRequired: function () {
      if (this.hasConsignmentAgreementAccess && this.isValidForConsignmentAgreementSigning) {
        this.updateConsignmentAgreementModalState(true)
      }
    },
    onCancelConsignmentAgreement: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.cancelSignatureRequest = signatureRequest
        this.onShowCancellationRequestModal()
      } else {
        this.failedToast('Signature request cannot be cancelled at this point.')
      }
    },
    onConsignmentAgreementReminder: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.signatureRequest = signatureRequest
        this.onShowReminderModal()
      } else {
        this.failedToast('Signature request reminder cannot be sent at this point.')
      }
    },
    formateSignatureType: function (type) {
      return type === 'LeaseReturned' ? 'Lease Returned' : type
    },
    onDownloadLastConsignmentAgreement: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.lastSignedConsignmentAgreement)
    },
    onDownloadListConsignmentAgreement: function (signedAgreementUrl) {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(signedAgreementUrl)
    },
    buildConsignmentsAgreementUrl: function (signedAgreementUrl) {
      return process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(signedAgreementUrl)
    },
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data,
        email: data
      }

      this.selectedRecipients.push(recipient)
      this.checkEmails(this.selectedRecipients)
    },
    clearAllRecipients: function () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedRecipients = []
      this.isUpload = false
      this.consignmentAgreement = []
      this.emailMatchErrorMessage = ''
    },
    onCarFaxForConsignmentAgreement: function (carFaxRating) {
      this.consignmentAgreementCarFaxCheckComplete = true
      this.consignmentAgreementCarFaxIsGood = carFaxRating && !(carFaxRating.brandedTitle === true || (carFaxRating.totalAccidents && carFaxRating.totalAccidents > 0) || carFaxRating.accidentIndicator === true || carFaxRating.frameDamageOrRepair === true || carFaxRating.airBagDeployed === true || carFaxRating.damaged === true || carFaxRating.majorProblems === true || carFaxRating.recall === true)
      if (this.currentInventory && this.currentInventory.carFaxRating && this.currentInventory.carFaxRating.hashValue && carFaxRating && carFaxRating.hashValue) {
        this.carFaxHashMatch = (this.currentInventory.carFaxRating.hashValue === carFaxRating.hashValue)
      } else {
        this.carFaxHashMatch = false
      }
      this.consignmentAgreementCarFaxFailed = !carFaxRating
      if (this.carFaxHashMatch === true && this.hasCarFaxRatingApproval === true) {
        this.consignmentAgreementCarFaxIsGood = true
        this.carFaxApproved = true
        this.consignmentAgreementCarFaxFailed = false
      } else if (this.currentInventory && (!this.currentInventory.carFaxRating || !carFaxRating) && this.currentInventory.hasRatingApproval === true) {
        this.consignmentAgreementCarFaxIsGood = true
        this.carFaxApproved = true
        this.consignmentAgreementCarFaxFailed = false
      }
    },
    onAppraisedForConsignmentAgreement: function (appraisal) {
      this.consignmentAgreementAppraisalCheckComplete = true
      if (appraisal && appraisal !== '') {
        this.latestAppraisal = appraisal
        this.consignmentAgreementAppraisalIsGood = appraisal && appraisal.isGood
        if (this.currentInventory && this.currentInventory.appraisalRating && this.currentInventory.appraisalRating.hashValue && appraisal && appraisal.hashValue) {
          this.appraisalRatingHashMatch = (this.currentInventory.appraisalRating.hashValue === appraisal.hashValue)
        } else {
          this.appraisalRatingHashMatch = false
        }
        if (this.appraisalRatingHashMatch === true && this.hasAppraisalRatingApproval === true) {
          this.consignmentAgreementAppraisalIsGood = true
          this.appraisalApproved = true
          this.consignmentAgreementAppraisalFailed = false
        } else if (this.currentInventory && !this.currentInventory.appraisalRating && this.currentInventory.hasRatingApproval === true) {
          this.consignmentAgreementAppraisalIsGood = true
          this.appraisalApproved = true
          this.consignmentAgreementAppraisalFailed = false
        }
      } else {
        this.consignmentAgreementAppraisalFailed = !appraisal
        if (this.currentInventory && this.currentInventory.hasRatingApproval === true) {
          this.consignmentAgreementAppraisalIsGood = true
          this.appraisalApproved = true
          this.consignmentAgreementAppraisalFailed = false
        }
      }
    },
    updateConsignmentAgreementModalState: function (state) {
      this.isConsignmentAgreementActive = state

      if (state === true) {
        this.consignmentAgreementDamageVerifiedActive = false
        this.consignmentAgreementDamageVerified = false
        this.consignmentAgreementVerificationActive = false
        this.consignmentAgreementCarFaxCheckComplete = false
        this.consignmentAgreementCarFaxIsGood = false
        this.consignmentAgreementCarFaxFailed = false
        this.consignmentAgreementAutoCheckCheckComplete = !this.autoCheckEnabled
        this.consignmentAgreementAutoCheckIsGood = false
        this.consignmentAgreementAutoCheckFailed = false
        this.consignmentAgreementAcceptConditions = false
        this.consignmentAgreementAppraisalCheckComplete = false
        this.consignmentAgreementAppraisalIsGood = false
        this.consignmentAgreementAppraisalFailed = false
        this.divisionOfSalesAccepted = false
        this.blackBookCheckComplete = !this.blackBookEnabled
        this.overridingSubject = 'Consignment Agreement - ' + this.currentInventory.name + ' - ' + this.currentInventory.vinNo
        this.optionalMessage = 'Please review and sign the attached Consignment Agreement for vehicle ' + this.currentInventory.name + ' - ' + this.currentInventory.vinNo
        this.selectedDamaged = this.currentInventory ? this.currentInventory.isDamaged : false
        this.damageNoteAttribute = this.currentInventory ? this.currentInventory.attributes.find((x) => x.name === 'DamageNotes') : null
        this.damageVehicleNote = this.damageNoteAttribute !== null && this.damageNoteAttribute !== undefined ? this.damageNoteAttribute.value : ''
        if (this.currentInventory.purchasedFromType === 'Wholesaler' && this.currentInventory.purchasedFromId) {
          supplierService.single(this.currentInventory.purchasedFromId).then(response => {
            let purchasingSupplier = response.supplier
            let validContacts = purchasingSupplier.contacts.filter((x) => x.status === 'Active' && x.receivesSellersOrder === true && x.email !== '')
            this.availableAddresses = validContacts.map(function (y) {
              return {
                email: y.email,
                fullName: y.fullName ? y.fullName + '(' + y.email + ')' : y.fullName
              }
            })
            if (this.availableAddresses.length > 0) {
              this.selectedRecipients = this.availableAddresses
            } else {
              this.selectedRecipients = []
            }

            this.checkEmails(this.selectedRecipients)
          }).catch((error) => {
            this.serverErrorMessage = error.message
          })
        } else {
          if (this.currentInventory.purchasedFromContactEmail && this.currentInventory.purchasedFromContactEmail !== '') {
            this.availableAddresses = [{
              email: this.currentInventory.purchasedFromContactEmail,
              fullName: this.currentInventory.purchasedFromContactName ? this.currentInventory.purchasedFromContactName + '(' + this.currentInventory.purchasedFromContactEmail + ')' : this.currentInventory.purchasedFromContactEmail
            }]
            this.selectedRecipients = this.availableAddresses
          } else {
            this.selectedRecipients = []
          }
        }
      }

      if (state === false) {
        this.clearAllRecipients()
        eventBus.$emit('consignment-agreement-cancelled', {})
      }
    },
    checkEmails: function (recipients) {
      if (recipients.length > 0) {
        let emails = recipients.map(function (x) {
          return x.email
        })

        let blacklistEmailCheckModel = {
          subscriberId: this.getCurrentSubscriber.id,
          emailAddresses: emails,
          errorMessage: ''
        }

        inventoryService.getVerifiedEmailList(blacklistEmailCheckModel).then(response => {
          blacklistEmailCheckModel = response.blacklistEmailCheck

          let approvedEmails = blacklistEmailCheckModel.emailAddresses.map(function (x) {
            return {
              email: x,
              fullName: x
            }
          })
          this.selectedRecipients = approvedEmails
          if (this.selectedRecipients.length === 0) {
            this.selectedRecipients = []
          }

          if (blacklistEmailCheckModel.errorMessage !== '' || blacklistEmailCheckModel.errorMessage !== null) {
            this.emailMatchErrorMessage = blacklistEmailCheckModel.errorMessage
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
        })
      }
    },
    next: function () {
      this.ratesFormSaving = true
      this.$validator.validateAll('rates-form').then((result) => {
        if (result) {
          var saleDivisionRequestModel = {
            inventoryId: this.currentInventory.id,
            miles: this.miles,
            askingPrice: this.askingPrice,
            lienAmount: this.lienAmount,
            saleProceedsType: this.saleProceedsType,
            consignmentPercentage: this.consignmentPercentage,
            consignmentMinimum: this.consignmentAmount,
            consignmentAgreementValue: this.consignmentAgreementValue,
            percentageSplitFirst: this.percentageSplitFirst,
            percentageSplitSecond: this.percentageSplitSecond
          }

          inventoryService.updateDivisionOfSaleRates(saleDivisionRequestModel).then(response => {
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              this.tabIndex = 1
              this.divisionOfSalesAccepted = true
              this.consignmentAgreementDamageVerifiedActive = true
              this.ratesFormSaving = false
              eventBus.$emit('inventory-changed', [response.inventory])
            }
          }).catch((error) => {
            throw error
          })
        } else {
          this.ratesFormSaving = false
        }
      })
    },
    onVerifyConsignmentAgreement: function (inventory) {
      this.$validator.validateAll('recipient-form').then((result) => {
        if (result) {
          this.consignmentAgreementVerificationActive = true
        }
      })
    },
    onEmailConsignmentAgreement: function (inventory) {
      if (this.isUpload) {
        this.onUploadConsignmentAgreement(inventory)
      } else {
        var emailAddresses = this.selectedRecipients.map(e => e.email).join(', ')
        var requestModel = {
          subscriberId: inventory.subscriberId,
          dealerId: inventory.dealerId,
          inventoryId: inventory.id,
          type: 'Consignment',
          destinationAddresses: emailAddresses,
          overridingSubject: this.overridingSubject,
          optionalMessage: this.optionalMessage,
          inventoryApprovalModel: {
            approvalRequired: !this.consignmentAgreementCarFaxIsGood || !this.consignmentAgreementAppraisalIsGood || !this.consignmentAgreementAutoCheckIsGood,
            badCarFax: !this.consignmentAgreementCarFaxIsGood,
            badAutoCheck: !this.consignmentAgreementAutoCheckIsGood,
            badPricing: !this.consignmentAgreementAppraisalIsGood,
            livePurchasePrice: this.latestAppraisal ? this.latestAppraisal.livePurchasePrice : null,
            marketPurchasePrice: this.latestAppraisal ? this.latestAppraisal.marketPurchasePrice : null,
            liveAskingPrice: this.latestAppraisal ? this.latestAppraisal.liveAskingPrice : null,
            marketAskingPrice: this.latestAppraisal ? this.latestAppraisal.marketAskingPrice : null,
            badPurchasePricing: this.latestAppraisal ? this.latestAppraisal.badPurchasePricing : null,
            badAskingPricing: this.latestAppraisal ? this.latestAppraisal.badAskingPricing : null,
            purchaseVariance: this.latestAppraisal ? this.latestAppraisal.purchaseVariance : null,
            askingVariance: this.latestAppraisal ? this.latestAppraisal.askingVariance : null
          }
        }

        inventoryService.addConsignmentAgreementRequest(requestModel).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.clearAllRecipients()
            this.isConsignmentAgreementActive = false
            this.consignmentAgreementVerificationActive = false
            this.consignmentAgreementCarFaxCheckComplete = false
            this.consignmentAgreementCarFaxIsGood = false
            this.consignmentAgreementCarFaxFailed = false
            this.consignmentAgreementAutoCheckCheckComplete = !this.autoCheckEnabled
            this.consignmentAgreementAutoCheckIsGood = false
            this.consignmentAgreementAutoCheckFailed = false
            this.consignmentAgreementAcceptConditions = false
            this.consignmentAgreementAppraisalCheckComplete = false
            this.consignmentAgreementAppraisalIsGood = false
            this.consignmentAgreementAppraisalFailed = false
            this.divisionOfSalesAccepted = false
            this.blackBookCheckComplete = !this.blackBookEnabled
            eventBus.$emit('inventory-changed', [response.inventory])
            eventBus.$emit('consignment-agreement-complete', response.inventory)
          }
        }).catch((error) => {
          throw error
        })
      }
    },
    updateUploadState: function () {
      if (this.isUpload === false) {
        this.isUpload = true
        this.tabIndex = 1
      } else {
        this.isUpload = false
      }
    },
    onUploadConsignmentAgreement: function (inventory) {
      if (this.consignmentAgreement.length > 0) {
        var signedDocumentModel = this.consignmentAgreement.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            docName: x.uniqueId,
            FileUri: ''
          }
        })
        var inventoryId = inventory.id
        var signedAgreementUploadModel = {
          inventoryId: inventoryId,
          fileName: signedDocumentModel[0].docName,
          inventoryApprovalModel: {
            approvalRequired: !this.consignmentAgreementCarFaxIsGood || !this.consignmentAgreementAppraisalIsGood || !this.consignmentAgreementAutoCheckIsGood,
            badCarFax: !this.consignmentAgreementCarFaxIsGood,
            badAutoCheck: !this.consignmentAgreementAutoCheckIsGood,
            badPricing: !this.consignmentAgreementAppraisalIsGood,
            livePurchasePrice: this.latestAppraisal ? this.latestAppraisal.livePurchasePrice : null,
            marketPurchasePrice: this.latestAppraisal ? this.latestAppraisal.marketPurchasePrice : null,
            liveAskingPrice: this.latestAppraisal ? this.latestAppraisal.liveAskingPrice : null,
            marketAskingPrice: this.latestAppraisal ? this.latestAppraisal.marketAskingPrice : null,
            badPurchasePricing: this.latestAppraisal ? this.latestAppraisal.badPurchasePricing : null,
            badAskingPricing: this.latestAppraisal ? this.latestAppraisal.badAskingPricing : null,
            purchaseVariance: this.latestAppraisal ? this.latestAppraisal.purchaseVariance : null,
            askingVariance: this.latestAppraisal ? this.latestAppraisal.askingVariance : null
          }
        }

        inventoryService.uploadSignedConsignmentAgreement(signedAgreementUploadModel).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.clearAllRecipients()
            this.isConsignmentAgreementActive = false
            this.consignmentAgreementVerificationActive = false
            this.consignmentAgreementCarFaxCheckComplete = false
            this.consignmentAgreementCarFaxIsGood = false
            this.consignmentAgreementCarFaxFailed = false
            this.consignmentAgreementAutoCheckCheckComplete = !this.autoCheckEnabled
            this.consignmentAgreementAutoCheckIsGood = false
            this.consignmentAgreementAutoCheckFailed = false
            this.consignmentAgreementAcceptConditions = false
            this.consignmentAgreementAppraisalCheckComplete = false
            this.consignmentAgreementAppraisalIsGood = false
            this.consignmentAgreementAppraisalFailed = false
            this.divisionOfSalesAccepted = false
            this.blackBookCheckComplete = !this.blackBookEnabled
            eventBus.$emit('inventory-changed', [response.inventory])
            eventBus.$emit('consignment-agreement-complete', response.inventory)
            let allSignedConsignmentAgreements = response.inventory.attachments.filter((x) => x.classType === 'SignedConsignmentAgreement')
            if (allSignedConsignmentAgreements.length > 0) {
              let newAttachment = allSignedConsignmentAgreements[allSignedConsignmentAgreements.length - 1]
              Vue.set(this.currentInventory, 'attachments', [...this.currentInventory.attachments, newAttachment])
              this.currentInventory.consignmentAgreementStatus = 'Complete'
              this.currentInventory.consignmentAgreementStatusId = 10
            }
          }
        }).catch((error) => {
          throw error
        })
      }
    },
    onDamageVerifyConsignmentAgreement: function (inventory) {
      this.$validator.validateAll('damage-form').then((result) => {
        if (result) {
          let currentInventoryDamageNote = this.damageNoteAttribute !== null && this.damageNoteAttribute !== undefined ? this.damageNoteAttribute.value : ''
          this.damageVehicleNote = this.selectedDamaged ? this.damageVehicleNote : ''

          if (this.selectedDamaged !== this.currentInventory.isDamaged || this.damageVehicleNote !== currentInventoryDamageNote) {
            let setAttribute = function (model, name, value) {
              var existingAttribute = model.attributes.find((x) => x.name === name)

              if ((value === '') && existingAttribute) {
                existingAttribute.status = 'Deleted'
                return
              }

              if (existingAttribute) {
                existingAttribute.value = value || ''
              } else {
                model.attributes.push({ name: name, value: value })
              }
            }

            setAttribute(this.currentInventory, 'DamageNotes', this.damageVehicleNote)
            this.currentInventory.isDamaged = this.selectedDamaged

            this.isSaving = true
            this.serverErrorMessage = ''
            this.serverErrors = []

            inventoryService.update(this.currentInventory).then(response => {
              this.isSaving = false
              if (response.errors) {
                this.onValidationError(response.errors)
              }
            }).catch((error) => {
              this.onError(error)
            })
          }

          this.consignmentAgreementDamageVerifiedActive = false
          this.consignmentAgreementDamageVerified = true
        }
      })
    },
    onError: function (error) {
      this.serverErrorMessage = error.message
      this.isSaving = false
    },
    onAutoCheckForConsignmentAgreement: function (autoCheckRating) {
      this.consignmentAgreementAutoCheckCheckComplete = true
      this.consignmentAgreementAutoCheckIsGood = autoCheckRating && !((autoCheckRating.accidentCount && autoCheckRating.accidentCount > 0) || (autoCheckRating.recallCount && autoCheckRating.recallCount > 0))
      if (this.currentInventory && this.currentInventory.autoCheckRating && this.currentInventory.autoCheckRating.hashValue && autoCheckRating && autoCheckRating.hashValue) {
        this.autoCheckHashMatch = (this.currentInventory.autoCheckRating.hashValue === autoCheckRating.hashValue)
      } else {
        this.autoCheckHashMatch = false
      }
      this.consignmentAgreementAutoCheckFailed = !autoCheckRating
      if (this.autoCheckHashMatch === true && this.hasAutoCheckRatingApproval === true) {
        this.consignmentAgreementAutoCheckIsGood = true
        this.autoCheckApproved = true
        this.consignmentAgreementAutoCheckFailed = false
      } else if (this.currentInventory && (!this.currentInventory.autoCheckRating || !autoCheckRating) && this.currentInventory.hasRatingApproval === true) {
        this.consignmentAgreementAutoCheckIsGood = true
        this.autoCheckApproved = true
        this.consignmentAgreementAutoCheckFailed = false
      }
    },
    onShowAutoCheckReport: function () {
      this.isConsignmentAgreementActive = false
      this.showReport = true
    },
    onHideAutoCheckReport: function () {
      this.showReport = false
      this.isConsignmentAgreementActive = true
    },
    onShowCarFaxReport: function () {
      this.isConsignmentAgreementActive = false
      this.showCarFaxReport = true
    },
    onHideCarFaxReport: function () {
      this.showCarFaxReport = false
      this.isConsignmentAgreementActive = true
    },
    onShowReminderModal: function () {
      this.isConsignmentAgreementActive = false
      this.showConfirmReminder = true
    },
    onHideReminderModal: function () {
      this.showConfirmReminder = false
      this.isConsignmentAgreementActive = true
      this.signatureRequest = null
    },
    sendReminder: function () {
      if (!this.sendingReminder) {
        this.sendingReminder = true
        documentSigningRequestService.sendReminder(this.signatureRequest).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.signatureRequest.reminderSent = true
            this.successToast('Signature request reminder has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideReminderModal()
          this.sendingReminder = false
        })
      }
    },
    onShowCancellationRequestModal: function () {
      this.isConsignmentAgreementActive = false
      this.showCancelRequest = true
    },
    onHideCancellationRequestModal: function () {
      this.showCancelRequest = false
      this.isConsignmentAgreementActive = true
      this.cancelSignatureRequest = null
    },
    sendCancellationRequest: function () {
      if (!this.sendingCancellationRequest) {
        this.sendingCancellationRequest = true
        documentSigningRequestService.cancelSignatureRequest(this.cancelSignatureRequest.signatureRequestId).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.cancelSignatureRequest.cancellationSent = true
            this.successToast('Signature cancellation request has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideCancellationRequestModal()
          this.sendingCancellationRequest = false
        })
      }
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    onAutoCheckDisabled: function () {
      this.consignmentAgreementAutoCheckCheckComplete = true
      this.consignmentAgreementAutoCheckIsGood = true
      this.consignmentAgreementAutoCheckFailed = false
    },
    onBlackBookInformationRetrieved: function (blackBook) {
      this.blackBookCheckComplete = true
      this.blackBookInformation = blackBook
      this.blackBookCheckFailed = !blackBook
      if (blackBook && blackBook.vinNo && blackBook.vinNo.length > 0) {
        this.blackBookCheckIsGood = true
      } else {
        this.blackBookCheckIsGood = false
      }
    },
    onBlackBookDisabled: function () {
      this.blackBookCheckComplete = true
      this.blackBookCheckIsGood = true
      this.blackBookCheckFailed = false
    },
    getToolTipForConsignmentAgreement: function () {
      let baseText = 'Consignment Agreement Status: '
      if (this.currentInventory && !this.currentInventory.isConsignmentAgreementUploaded) {
        baseText += this.getDescriptionByAgreementStatus(this.currentInventory.consignmentAgreementStatusId, 'Click to send out the consignment agreement for for signing.')
      } else {
        if ((this.currentInventory.consignmentAgreementUploadedBy !== '' && this.currentInventory.consignmentAgreementUploadedBy !== undefined) && (this.currentInventory.consignmentAgreementUploadedAt !== null && this.currentInventory.consignmentAgreementUploadedAt !== undefined)) {
          baseText += this.getUploadDescriptionByAgreementStatus(this.currentInventory.consignmentAgreementUploadedBy, this.currentInventory.consignmentAgreementUploadedAt)
        } else {
          baseText += 'No user details or date of upload has been captured for uploaded file.'
        }
      }
      return baseText
    },
    onPreview: function (inventory) {
      let docUrl = process.env.VUE_APP_ROOT_DOC_SITE + 'docs/consignment/' + inventory.id + '/' + this.currentUser.id
      docUrl = encodeURI(docUrl)
      this.pdfModalFile = {
        name: 'Consignment Agreement',
        src: docUrl,
        fileName: 'consignment_agreement_pack_' + inventory.id + '.pdf',
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
    },
    onPreviewClosed: function () {
      this.pdfModalFile = null
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapSubscriberGetters(['getCurrentSubscriber']),
    formattedMileage: function () {
      return new Intl.NumberFormat().format(this.currentInventory.usage)
    },
    formattedFlatValue: function () {
      return `${new Intl.NumberFormat().format(this.currentInventory.consignmentAgreementValue)}`
    },
    getInventoryOverview: function () {
      var result = this.currentInventory.stockNo + ' - ' + this.currentInventory.vinNo
      return result
    },
    inventoryAppraisalParams: function () {
      let trimAttribute = this.currentInventory.attributes.filter((x) => x.name === 'Trim')[0]
      let trim = trimAttribute ? trimAttribute.value : ''
      let condition = this.currentInventory.conditionType
      return {
        id: this.currentInventory.id,
        vinNo: this.currentInventory.vinNo,
        mileage: this.currentInventory.usage,
        trim: trim,
        condition: condition,
        msrp: this.currentInventory.msrp,
        purchaseCost: this.currentInventory.consignmentMinimum,
        askingPrice: this.currentInventory.askingPrice
      }
    },
    mostRecentAppraisal: function () {
      if (this.currentInventory.appraisals.length > 0) {
        return this.currentInventory.appraisals[0]
      }
      return null
    },
    consignmentAgreementFinalSendDisallowed: function () {
      if (this.consignmentAgreementCarFaxIsGood && this.consignmentAgreementAppraisalIsGood && this.consignmentAgreementAutoCheckIsGood) {
        return false
      }
      return !this.consignmentAgreementAcceptConditions
    },
    hasConsignmentAgreementAccess: function () {
      let allowedPermissions = ['inventory.issue.consignment-agreement']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    isValid: function () {
      return this.currentInventory && this.currentInventory.type === 'Consigned'
    },
    isValidForConsignmentAgreementSigning: function () {
      return this.currentInventory && this.currentInventory.purchasedFromName !== '' &&
      this.currentInventory.type === 'Consigned' &&
      this.currentInventory.purchasedFromType !== 'Factory' &&
      this.currentInventory.consignmentMinimum &&
      this.currentInventory.consignmentMinimum > 0 &&
      this.currentInventory.usage &&
      this.currentInventory.usage > 0
    },
    invalidForConsignmentAgreementReasons: function () {
      var result = 'Action disabled. Please ensure the following details are captured.\n'
      if (this.currentInventory) {
        if (this.currentInventory.purchasedFromType === 'Factory' || this.currentInventory.type !== 'Consigned') {
          result = 'Consignment agreement is not applicable for this item.'
        } else {
          if (this.currentInventory.purchasedFromName === '') {
            result += 'Consignee details not set.\n'
          }
          if (!this.currentInventory.consignmentMinimum || this.currentInventory.consignmentMinimum <= 0) {
            result += 'Consignment minimum not set.\n'
          }
          if (!this.currentInventory.usage || this.currentInventory.usage <= 0) {
            result += 'Mileage not set.\n'
          }
        }
      } else {
        result += 'Inventory selected is invalid.\n'
      }
      return result
    },
    lastSignedConsignmentAgreement: function () {
      if (this.currentInventory && (this.currentInventory.consignmentAgreementStatus === 'Complete' || this.currentInventory.consignmentAgreementStatus === 'Signed')) {
        var allSignedConsignmentAgreements = this.currentInventory.attachments.filter((x) => x.classType === 'SignedConsignmentAgreement' && x.status === 'Active')
        if (allSignedConsignmentAgreements.length > 0) {
          return allSignedConsignmentAgreements[allSignedConsignmentAgreements.length - 1].fileLocation
        }
      }
      return ''
    },
    inventoryId: function () {
      return this.currentInventory.id
    },
    mustShowEmailError: function () {
      return this.emailMatchErrorMessage !== '' && this.emailMatchErrorMessage !== ' ' && this.emailMatchErrorMessage !== null && this.emailMatchErrorMessage !== undefined
    },
    finalPercentage: function () {
      return this.consignmentPercentage > 0 && this.consignmentPercentage < 100 ? 100 - this.consignmentPercentage : 0
    },
    consignmentAgreementAmount: function () {
      if (this.consignmentAgreementValue <= 0) return 0.00
      if (this.askingPrice <= 0) return 0.00
      if (this.percentageSplitFirst <= 0) return 0.00
      return (this.percentageSplitFirst / 100.00) * (this.askingPrice - this.consignmentAgreementValue)
    },
    carFaxReportUrl: function () {
      let result = 'https://www.carfaxonline.com/cfm/Display_Dealer_Report.cfm?partner=VAU_0&UID=' + this.currentDealer.carFaxUserName + '&vin=' + this.currentInventory.vinNo
      return result
    },
    selectedInventoryStatusType: function () {
      return this.definedTypes.inventoryTypes.find((x) => (x.name === this.currentInventory.type || x.altName === this.currentInventory.type))
    },
    consignmentAgreementApprovals: function () {
      if (this.currentInventory && this.currentInventory.approvals) {
        let data = this.currentInventory.approvals.filter((x) => x.approvalRatingType === 'ConsignmentAgreement')
        if (data.length > 0) {
          return data
        }
      }
      return []
    },
    hasAutoCheckRatingApproval: function () {
      if (this.consignmentAgreementApprovals && this.consignmentAgreementApprovals.length > 0) {
        let data = this.consignmentAgreementApprovals.filter((x) => x.ratingType === 'AutoCheckRating')
        if (data.length > 0) {
          return true
        }
      }
      return false
    },
    hasCarFaxRatingApproval: function () {
      if (this.consignmentAgreementApprovals && this.consignmentAgreementApprovals.length > 0) {
        let data = this.consignmentAgreementApprovals.filter((x) => x.ratingType === 'CarFaxRating')
        if (data.length > 0) {
          return true
        }
      }
      return false
    },
    hasAppraisalRatingApproval: function () {
      if (this.consignmentAgreementApprovals && this.consignmentAgreementApprovals.length > 0) {
        let data = this.consignmentAgreementApprovals.filter((x) => x.ratingType === 'AppraisalRating')
        if (data.length > 0) {
          return true
        }
      }
      return false
    },
    approvalInformation: function () {
      if (this.consignmentAgreementApprovals && this.consignmentAgreementApprovals.length > 0) {
        let latestApproval = this.consignmentAgreementApprovals[0]
        let approvedBy = latestApproval.userApprovedBy
        let approvedAt = this.$options.filters.formatDate(latestApproval.approvedAt)
        return 'The vehicle\'s condition was approved by ' + approvedBy + ' (' + approvedAt + ').'
      }
      return ''
    },
    autoCheckValidated: function () {
      if (this.currentDealer && this.currentDealer.autoCheckEnabledStatus) {
        return this.consignmentAgreementAutoCheckIsGood && this.consignmentAgreementAutoCheckCheckComplete
      } else {
        return true
      }
    },
    autoCheckEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.autoCheckEnabledStatus
      }
      return false
    },
    mostRecentBlackBookInformation: function () {
      if (this.blackBookInformation) {
        return this.blackBookInformation
      } else {
        if (this.currentInventory && this.currentInventory.blackBooks && this.currentInventory.blackBooks.length > 0) {
          return this.currentInventory.blackBooks[0]
        }
      }
      return null
    },
    blackBookEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.blackBookEnabledStatus
      }
      return false
    }
  },
  beforeDestroy () {
    eventBus.$off('consignment-agreement-required', this.onConsignmentAgreementRequired)
  },
  mounted: function () {
    eventBus.$on('consignment-agreement-required', this.onConsignmentAgreementRequired)
  },
  watch: {
    consignmentAgreementValue: function (newValue, oldValue) {
      if (newValue > this.askingPrice) {
        this.consignmentAgreementValue = 0
      }
    },
    percentageSplitFirst: function (newValue, oldValue) {
      if (newValue <= 100 && newValue > 0) {
        this.percentageSplitSecond = 100 - newValue
      } else if (newValue < 0) {
        this.percentageSplitSecond = 100
      } else {
        this.percentageSplitSecond = 0
      }
    },
    percentageSplitSecond: function (newValue, oldValue) {
      if (newValue <= 100 && newValue > 0) {
        this.percentageSplitFirst = 100 - newValue
      } else if (newValue < 0) {
        this.percentageSplitFirst = 100
      } else {
        this.percentageSplitFirst = 0
      }
    },
    tabIndex: function (newValue, oldValue) {
      if (newValue === 0 && this.divisionOfSalesAccepted) {
        this.divisionOfSalesAccepted = false
        this.consignmentAgreementDamageVerifiedActive = false
      }
    }
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .verification-container {
    margin-bottom: 3em;
  }

  .status-container {
    margin-bottom: 3em;
  }

  .animation-content {
    max-width: 960px;
  }

  .consignment-agreement-modal {
    .animation-content {
      max-width: unset !important;
    }
    .modal-card-body {
      position:relative;
      max-height: 65vh;
      max-width: 960px;
      height: 65vh;
      width: 960px;
      overflow-y: auto;
    }
  }
</style>

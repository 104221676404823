import requiresAuth from './route_guards'

export default [
  {
    path: '/accounting/customer-deposits',
    name: 'AccountingListPaymentTracking',
    component: () => import('@/components/accounting/ListPaymentTracking'),
    beforeEnter: requiresAuth
  },
  {
    path: '/accounting/customer-deposits/qview/:id',
    name: 'AccountingPaymentTrackingQuickView',
    meta: {
      quickViewComponent: { component: () => import('@/components/accounting/ViewPaymentTracking') }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/accounting/customer-deposits/view/:id',
    name: 'AccountingViewPaymentTracking',
    component: () => import('@/components/accounting/ViewPaymentTracking'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/accounting/payment-schedule',
    name: 'AccountingListPaymentSchedule',
    component: () => import('@/components/sales/payment-schedule/ListPaymentSchedule'),
    beforeEnter: requiresAuth
  },
  {
    path: '/accounting/payment-schedule/qview/:id',
    name: 'AccountingPaymentScheduleQuickView',
    meta: {
      quickViewComponent: { component: () => import('@/components/sales/payment-schedule/ViewPaymentSchedule') }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/accounting/wholesale-receivables',
    name: 'AccountingListWholesaleReceivables',
    component: () => import('@/components/sales/wholesale-receivable/ListWholesaleReceivables'),
    beforeEnter: requiresAuth
  },
  {
    name: 'AccountingWholesaleReceivablesQuickView',
    path: '/accounting/wholesale-receivables/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/sales/wholesale-receivable/ViewWholesaleReceivable')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/accounting/wholesale-receivables/view/:id',
    name: 'AccountingViewWholesaleReceivables',
    component: () => import('@/components/sales/wholesale-receivable/ViewWholesaleReceivable'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/accounting/customer-deposits/:roNumber?',
    name: 'AccountingListPaymentTrackingWithRoNumber',
    component: () => import('@/components/accounting/ListPaymentTracking'),
    beforeEnter: requiresAuth
  },
  {
    path: '/accounting/customer-deposits/:partInvoiceNo?',
    name: 'AccountingListPaymentTrackingWithPartInvoiceNo',
    component: () => import('@/components/accounting/ListPaymentTracking'),
    beforeEnter: requiresAuth
  }
]

<template>
  <div v-if="hasConvertPreEntryAccess && isPreEntry">
    <div class="quick-action" v-if="!isConvertPreEntryConfirmationActive">
        <button class="button" @click="onRequestConvertPreEntry()" title="Click to convert Pre-Entry to Active">
            <b-icon pack="fal" icon="location-arrow" custom-size="fa-lg"></b-icon>
        </button>
    </div>
    <portal to="global-modal-portal" v-else>
        <b-modal :active.sync="isConvertPreEntryConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
            <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title" v-if="convertedInventory === null">Confirm Convert Pre-Entry</p>
                <p class="modal-card-title" v-else>Pre-Entry Converted Successfully</p>
            </header>
            <section class="modal-card-body">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
              <div>
                  <p v-if="convertedInventory === null">Are you sure you wish to convert Pre-Entry <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a>?</p>
                  <div v-else>
                    <p>Pre-Entry <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a> has been succesfully converted.</p>
                    <br/>
                    <p>Please click the edit button below to make any further updates.</p>
                  </div>
              </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-danger" :class="{'is-loading': isSaving }"  @click="onConvertPreEntry()" v-if="convertedInventory === null">Confirm Conversion</button>
                <button class="button is-success" @click="onShowConvertedInventory()" v-if="convertedInventory !== null">Edit</button>
                <button class="button" @click="onCancel">Close</button>
            </footer>
            </div>
        </b-modal>
    </portal>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import inventoryService from '../../services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'ConvertPreEntryAction',
  props: {
    inventoryItem: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isConvertPreEntryConfirmationActive: false,
      convertedInventory: null,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4()
    }
  },
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    hasConvertPreEntryAccess: function () {
      let allowedPermissions = ['inventory.modify.convert-pre-entry']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isPreEntry: function () {
      return this.inventoryItem.status === 'PreEntry'
    },
    inventoryItemId: function () {
      return this.inventoryItem.id
    }
  },
  methods: {
    onShowConvertedInventory: function () {
      this.isConvertPpaConfirmationActive = false
      this.$router.push({name: 'EditInventory', params: { id: this.convertedInventory.id, defaultSalesPerson: this.currentUser, defaultPreEntry: false }})
    },
    onRequestConvertPreEntry: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isConvertPreEntryConfirmationActive = true
    },
    onConvertPreEntry: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      inventoryService.convertPreEntry(this.inventoryItem).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.convertedInventory = response
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onCancel: function () {
      if (this.convertedInventory) {
        eventBus.$emit('inventory-changed', [this.convertedInventory])
      }
      this.isConvertPreEntryConfirmationActive = false
    },
    reset: function () {
      this.convertedInventory = null
      this.serverErrors = []
      this.serverErrorMessage = []
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  watch: {
    inventoryItemId: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.reset()
      }
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }
</style>

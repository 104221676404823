<template>
<portal to="global-modal-portal" v-if="showModal" :order="3">
  <b-modal :active.sync="showModal" scroll="keep" :has-modal-card="true" :canCancel="false" id="reminder-confirmation-modal">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">Send Reminder</p>
          </header>
        <section class="modal-card-body">
          <p>{{getModalText}}</p>
        </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="sendReminder(signatureRequest)" type="button">Yes</button>
            <button class="button is-danger" @click="hideReminderModal" type="button">No</button>
          </footer>
        </div>
      </b-modal>
  </portal>
</template>

<script>

export default {
  name: 'SendReminderControl',
  components: {
  },
  props: {
    showConfirmationModal: {
      type: Boolean,
      default: false
    },
    signatureRequest: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
    getModalText: function () {
      let result = ''
      if (this.signatureRequest && this.signatureRequest.destinationAddresses) {
        result = 'This has last been sent to "' + this.signatureRequest.destinationAddresses + '". Do you want to send a reminder?'
      }
      return result
    }
  },
  methods: {
    showReminderModal: function () {
      this.showModal = true
    },
    hideReminderModal: function () {
      this.$emit('on-hide-reminder-control')
      this.showModal = false
    },
    sendReminder: function () {
      this.$emit('send-reminder', this.signatureRequest)
    }
  },
  watch: {
    showConfirmationModal: function (newValue, oldValue) {
      if (newValue) {
        this.showReminderModal()
      } else {
        this.showModal = false
      }
    }
  },
  mounted: function () {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

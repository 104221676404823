<template>
    <div class="is-inline-block">
      <div class="is-inline-block">
        <div class="quick-action is-pulled-left">
          <button type="button" class="button" :disabled="this.isLoading" :class="getColorClassByWorkflowStatus()" @click="onOpenIdentityVerification()" v-tooltip="identityVerificationStatusDescription">
            <i class="icon fa-fw fal fa-eye fa-lg" />
          </button>
        </div>
      </div>
    </div>
</template>

<script>
import utilitiesMixin from '@/mixins/generic/utilities'
import customerService from '@/services/customerService'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'DealCustomerIdentityVerificationStatus',
  mixins: [utilitiesMixin],
  components: {
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      identityVerificationLink: '',
      isLoading: false
    }
  },
  computed: {
    ...mapUserGetters(['currentUser']),
    identityVerificationStatusDescription: function () {
      if (this.isLoading) return 'Identity verification link is being generated.'
      if (!this.isIdentityVerificationComplete && this.identityVerificationStatus === 'Pending') return 'Identity verification has not been sent.'
      if (!this.isIdentityVerificationComplete && this.identityVerificationStatus === 'Sent') return 'Identity verification has been sent. Awaiting customer completion.'
      if (this.isIdentityVerificationComplete && this.identityVerificationStatus === 'Completed') return 'Identity verification has been completed.'
      return ''
    },
    isIdentityVerificationComplete: function () {
      if (this.currentDeal === null) return false
      return this.currentDeal.isCustomerTermsAccepted
    },
    identityVerificationStatus: function () {
      if (this.currentDeal === null) return 'Pending'
      return this.currentDeal.customerIdentityVerificationStatus
    }
  },
  methods: {
    onOpenIdentityVerification: function () {
      window.open(this.identityVerificationLink)
    },
    getColorClassByWorkflowStatus: function () {
      var result = 'has-text-grey-light'
      if (!this.isIdentityVerificationComplete && this.identityVerificationStatus === 'Sent') {
        result = 'has-text-orange'
      } else if (!this.isIdentityVerificationComplete && this.identityVerificationStatus === 'Pending') {
        result = 'has-text-danger'
      } else if (this.isIdentityVerificationComplete && this.identityVerificationStatus === 'Completed') {
        result = 'has-text-success'
      }

      let classToAttach = { }
      classToAttach[result] = true
      return classToAttach
    },
    fetchIdentityVerificationLink: function (customerId) {
      this.isLoading = true
      customerService.createIdentityVerificationLink(customerId).then(response => {
        this.identityVerificationLink = response
        this.isLoading = false
      }).catch((error) => {
        this.failedToast('Oops! Something went wrong while fetching customer identity verification link')
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    }
  },
  created: function () {
    if (this.currentDeal && this.currentDeal.customerId !== 0) {
      this.fetchIdentityVerificationLink(this.currentDeal.customerId)
    }
  },
  mounted: function () {
  },
  watch: {
  }
}

</script>

<style scoped>
</style>

import mutationTypes from '../mutation-types'
import authService from '../../services/authService'
import subscriberService from '../../services/subscriberService'
import logger from '@/services/loggingService'
import router from '@/router'
import util from '@/utils'

const state = {
  token: undefined,
  status: '',
  systemReady: false,
  securityToken: ''
}

const getters = {
  isAuthenticated: state => !!state.token,
  isSystemReady: state => !!state.systemReady,
  authStatus: state => state.status,
  securityToken: state => state.securityToken
}

const mutations = {
  [mutationTypes.AUTH_REQUEST]: (state) => {
    state.status = 'loading'
  },
  [mutationTypes.AUTH_VERIFICATION_REQUEST]: (state) => {
    state.status = 'verifying'
  },
  [mutationTypes.AUTH_SUCCESS]: (state, token) => {
    state.status = 'success'
    state.token = token
  },
  [mutationTypes.AUTH_ERROR]: (state) => {
    state.status = 'error'
  },
  [mutationTypes.AUTH_LOGOUT]: (state) => {
    state.status = ''
    state.token = ''
  },
  [mutationTypes.SET_SYSTEM_READY]: (state, isReady) => {
    state.systemReady = isReady
  },
  [mutationTypes.SET_SECURITY_TOKEN]: (state, securityToken) => {
    state.securityToken = securityToken
  }
}

const actions = {
  resetPassword ({ commit, dispatch }, authModel) {
    return new Promise((resolve, reject) => {
      resolve()
      authService.resetPassword(authModel).then((response) => {
        resolve(response)
      }).catch((error) => {
        commit(mutationTypes.AUTH_ERROR, error)
        commit(mutationTypes.SET_SYSTEM_READY, false)
      })
    })
  },
  authVerificationRequest ({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.AUTH_VERIFICATION_REQUEST)
      authService.verifyAuth().then((response) => {
        const token = response.token
        commit(mutationTypes.AUTH_SUCCESS, token)
        resolve(response)
      }).catch((error) => {
        commit(mutationTypes.AUTH_ERROR, error)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        logger.log('Auth Store ' + error)
        reject(error)
      })
    })
  },
  authRequest ({commit}, authModel) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.AUTH_REQUEST)
      authService.login(authModel).then((response) => {
        // const token = response.token
        // commit(mutationTypes.AUTH_SUCCESS, token)
        resolve(response)
      }).catch((error) => {
        commit(mutationTypes.AUTH_ERROR, error)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        logger.log('Auth Store ' + error)
        reject(error)
      })
    })
  },
  logoutRequest ({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      authService.logout().then((response) => {
        commit(mutationTypes.AUTH_LOGOUT)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        if (util.isInTabletMode() === true) {
          router.push('/auth/tabletlogin')
        } else {
          router.push('/auth/login')
        }
        resolve()
      }).catch((error) => {
        commit(mutationTypes.AUTH_ERROR, error)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        logger.log('Auth Store ' + error)
        reject(error)
      })
    })
  },
  revealSecurityToken ({commit, dispatch}, {subscriberId}) {
    return new Promise((resolve, reject) => {
      subscriberService.getSecurityToken(subscriberId).then(response => {
        let securityToken = response.securityToken + ',' + response.lastSecurityToken
        commit(mutationTypes.SET_SECURITY_TOKEN, securityToken)
      }).catch((error) => {
        commit(mutationTypes.SET_SECURITY_TOKEN, '')
        logger.log('Auth Store ' + error)
      })
    })
  },
  setSystemReady ({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.SET_SYSTEM_READY, true)
      resolve()
    })
  },
  tabletMode ({commit, dispatch}) {
    return new Promise((resolve, reject) => {
      authService.logout().then((response) => {
        commit(mutationTypes.AUTH_LOGOUT)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        var d = new Date()
        d.setTime(d.getTime() + (10 * 365 * 24 * 60 * 60 * 1000))
        var expires = 'expires=' + d.toGMTString()
        document.cookie = 'tabletmode=1;' + expires + ';path=/'
        router.push('/auth/tabletlogin')
        resolve()
      }).catch((error) => {
        commit(mutationTypes.AUTH_ERROR, error)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        logger.log('Auth Store ' + error)
        reject(error)
      })
    })
  },
  pinAuthRequest ({commit}, authModel) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.AUTH_REQUEST)
      authService.tabletLogin(authModel).then((response) => {
        const token = response.token
        commit(mutationTypes.AUTH_SUCCESS, token)
        resolve(response)
      }).catch((error) => {
        commit(mutationTypes.AUTH_ERROR, error)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        logger.log('Auth Store ' + error)
        reject(error)
      })
    })
  },
  codeAuthRequest ({commit}, authModel) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.AUTH_REQUEST)
      authService.verifyCode(authModel).then((response) => {
        const token = response.token
        commit(mutationTypes.AUTH_SUCCESS, token)
        resolve(response)
      }).catch((error) => {
        commit(mutationTypes.AUTH_ERROR, error)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        logger.log('Auth Store' + error)
        reject(error)
      })
    })
  },
  resendTwoFactorCodeRequest ({commit}, authModel) {
    return new Promise((resolve, reject) => {
      authService.resendTwoFactorCode(authModel).then((response) => {
        resolve(response)
      }).catch((error) => {
        commit(mutationTypes.AUTH_ERROR, error)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        logger.log('Auth Store' + error)
        reject(error)
      })
    })
  },
  setPasswordAuthRequest ({commit}, authModel) {
    return new Promise((resolve, reject) => {
      authService.setPassword(authModel).then((response) => {
        resolve(response)
      }).catch((error) => {
        commit(mutationTypes.AUTH_ERROR, error)
        commit(mutationTypes.SET_SYSTEM_READY, false)
        reject(error)
      })
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <div class="dropdown" :id="instanceName" v-mousedown-outside="closeDropDown">
    <div class="dropdown-trigger">
      <a v-clipboard:copy="unmask(destinationLine)" @click.stop.prevent="onPhoneClicked(unmask(destinationLine))">{{destinationLine}}</a>
    </div>
    <div class="dropdown-menu">
      <div class="dropdown-content">
        <div class="columns is-paddingless is-multiline box has-background-grey-lighter is-mobile">
          <div class="column is-5">
            <label class="label" v-if="callInitiated">
              <span >initiating call...</span>
              <progress class="progress is-small is-success" max="100">15%</progress>
            </label>
          </div>
          <div class="column is-7 is-flex">
            <span class="quick-action">
              <button type="button" class="button has-text-success" @click.stop.prevent="onInitiateCall" :class="{'is-loading': callInitiated }" :disabled="isCallBtnDisabled">
                <b-icon pack="fal" icon="fal fa-phone" custom-size="fa-lg" :class="{'has-text-danger': callFailed }"></b-icon>
              </button>
            </span>
            <send-sms-control
              :key="'ctc' + instanceName"
              :customer="currentCustomer"
              :mobileNo="destinationLine"
              :smsType="receiverTypeProp"
              :autoLoadQuickViewId="selectedRowId"
              :usePortal="usePortal"
              :customTrackingNumber="customTrackingNumber">
            </send-sms-control>
            <span class="delete close-btn" @click.stop.prevent="toggleDropdown"></span>
          </div>

          <div class="column is-12 click-to-call-column" v-if="hasErrors">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          </div>
          <div class="column is-12 click-to-call-column">
            <label class="label">Source<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <input name="sourceLine" v-validate="{'min': 16, 'max': 16, 'required': true}" class="has-background-white" :class="{'input': true, 'is-danger': errors.has('sourceLine') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="sourceLine" data-lpignore="true" autocomplete="off">
            <span v-show="errors.has('sourceLine')" class="help is-danger">
              <i v-show="errors.has('sourceLine')" class="fas fa-exclamation-triangle"></i> {{ errors.first('sourceLine') }}
            </span>
          </div>
          <div class="column is-12 click-to-call-column">
            <label class="label">Destination<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <input disabled name="destinationLine" v-validate="{'min': 16, 'max': 16}" class="has-background-white" :class="{'input': true, 'is-danger': errors.has('destinationLine') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="destinationLine" data-lpignore="true" autocomplete="off">
            <span v-show="errors.has('destinationLine')" class="help is-danger">
              <i v-show="errors.has('destinationLine')" class="fas fa-exclamation-triangle"></i> {{ errors.first('destinationLine') }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import eventBus from '@/eventBus'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import utilitiesMixin from '@/mixins/generic/utilities'
import notificationService from '@/services/notificationService'

const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
export default {
  name: 'ClickToCall',
  mixins: [utilitiesMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'send-sms-control': () => import('./SendSmsControl.vue')
  },
  props: {
    destinationLineProp: {
      type: String,
      default: null
    },
    receiverTypeProp: {
      type: String,
      default: 'Customer'
    },
    receiverIdProp: {
      type: Number,
      default: 0
    },
    customerName: {
      type: String,
      default: ''
    },
    selectedRow: {
      type: Object,
      default: null
    },
    customTrackingNumber: {
      type: String,
      default: ''
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      serverErrors: [],
      serverErrorMessage: '',
      sourceLine: null,
      destinationLine: this.destinationLineProp,
      callInitiated: false,
      callFailed: false,
      showForm: false,
      currentCustomer: null
    }
  },
  computed: {
    ...mapUserGetters(['currentUser']),
    ...mapDealerState(['currentDealer']),
    trackingNumber: function () {
      // need to set tracking number, first check on user then dealer.
      return this.customTrackingNumber === '' ? ((this.currentUser.activeTrackingNumber && this.currentUser.activeTrackingNumber !== '') ? this.currentUser.activeTrackingNumber : this.currentDealer.defaultCallTrackingNumber) : this.customTrackingNumber
    },
    canShowActionBtn: function () {
      return this.sourceLine && this.destinationLine
    },
    hasErrors: function () {
      return this.serverErrors.length > 0 || this.serverErrorMessage !== ''
    },
    isCallBtnDisabled: function () {
      return !(this.sourceLine && this.sourceLine.length === 16 && this.destinationLine && this.destinationLine.length === 16)
    },
    selectedRowId: function () {
      return this.selectedRow ? this.selectedRow.id : null
    }
  },
  methods: {
    init: function () {
      this.sourceLine = this.trackingNumber
    },
    closeDropDown: function () {
      let clickToCallDropDown = document.getElementById(this.instanceName)
      if (clickToCallDropDown && clickToCallDropDown.classList.contains('is-active')) {
        this.onClose(clickToCallDropDown)
      }
    },
    toggleDropdown: function () {
      let clickToCallDropDown = document.getElementById(this.instanceName)
      if (!clickToCallDropDown) return
      if (clickToCallDropDown.classList.contains('is-active')) {
        this.onClose(clickToCallDropDown)
      } else {
        let instanceToKeepAlive = this.instanceName
        eventBus.$emit('close-active-click-to-call', instanceToKeepAlive)
        clickToCallDropDown.classList.add('is-active')
        const child = document.getElementById(this.instanceName)
        const parentWithClass = child.closest('.vue-recycle-scroller__item-view')
        if (parentWithClass) parentWithClass.style['z-index'] = 2
        this.clearErrors()
      }
    },
    onCloseFromEmit: function (instanceToKeepAlive) {
      if (instanceToKeepAlive === this.instanceName) return
      let clickToCallDropDown = document.getElementById(this.instanceName)
      if (clickToCallDropDown && clickToCallDropDown.classList.contains('is-active')) {
        this.onClose(clickToCallDropDown)
      }
    },
    onClose: function (clickToCallDropDown) {
      clickToCallDropDown.classList.remove('is-active')
      const parentWithClass = clickToCallDropDown.closest('.vue-recycle-scroller__item-view')
      if (parentWithClass) parentWithClass.style.removeProperty('z-index')
    },
    onInitiateCall: function () {
      this.callInitiated = true
      let callModel = {
        source: this.sourceLine,
        destination: this.destinationLine,
        apiKey: this.currentUser.dealerApiKeyInteactiveTel,
        dealerId: this.currentDealer.id,
        isPopCall: false,
        receiverType: this.receiverTypeProp,
        receiverId: this.receiverIdProp
      }
      this.clearErrors()
      notificationService.initiateCall(callModel).then(response => {
        this.callInitiated = false
        if (response.errors) {
          this.callFailed = true
          this.failedToast('Oops! Something went wrong')
          this.serverErrors = response.errors
        } else {
          if (response.isSuccess) {
            this.successToast('Success! call initiated')
            // this.toggleDropdown()
          } else {
            this.callFailed = true
            this.failedToast('Oops! Something went wrong')
            this.serverErrorMessage = response.callResult
          }
        }
      }).catch((error) => {
        this.failedToast('Oops! Something went wrong')
        this.callFailed = true
        this.callInitiated = false
        this.serverErrorMessage = error.message
      })
    },
    clearErrors: function () {
      this.serverErrors = []
      this.serverErrorMessage = ''
      this.callFailed = false
    },
    onPhoneClicked: function (phoneNumber) {
      this.toggleDropdown()
      this.onCopy(phoneNumber)
    },
    onCopy: function (phoneNumber) {
      let that = this
      this.$copyText(phoneNumber).then(function () {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: phoneNumber + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
      }, function () {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: 'Could not copy ' + phoneNumber + ' to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
      })
    }
  },
  watch: {
    destinationLineProp: function (newValue) {
      this.destinationLine = newValue
    }
  },
  mounted: function () {
    this.init()
    eventBus.$on('close-active-click-to-call', this.onCloseFromEmit)
    this.currentCustomer = { id: this.receiverIdProp, fullName: this.customerName, mobile: this.destinationLineProp }
  },
  beforeDestroy () {
    eventBus.$on('close-active-click-to-call', this.onCloseFromEmit)
  }
}
</script>

<style scoped>
  .dropdown-menu {
    z-index: 20000;
  }

  .close-btn {
    margin: 5px;
  }

  .click-to-call-column {
    margin-top: -15px !important;
  }
</style>

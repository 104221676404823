<template>
    <div class="is-inline-block" v-if="hasLeaseAgreementAccess">
      <div class="is-inline-block" v-if="hasLeaseAgreementAccess">
        <div class="quick-action" v-if="isValidForLeaseAgreementSigning">
          <button type="button" class="button" @click="updateLeaseAgreementModalState(true)" v-tooltip="getToolTipForLeaseAgreement">
            <i v-if="!currentDeal.isLeaseAgreementUploaded" data-type="L" class="icon fal fa-envelope-open-text fa-lg" :class="getColorClassByAgreementStatus(currentDeal.leaseAgreementStatusId)"/>
            <i data-type="U" class="icon fal fa-upload fa-lg has-text-success" v-else/>
          </button>
        </div>
        <div class="quick-action" v-else>
          <button type="button" class="button" disabled :title="invalidForLeaseAgreementReasons">
            <b-icon pack="fal" icon="envelope-open-text" custom-size="fa-lg"></b-icon>
          </button>
        </div>
      </div>
      <div class="control is-inline-block quick-action" v-if="lastSignedLeaseAgreement !== '' && hasLeaseAgreementAccess">
        <button class="button is-success" @click.prevent="onDownloadLastLeaseAgreement" title="Click to download the last signed lease agreement.">
          <b-icon pack="fal" icon="cloud-download" custom-size="fa-lg"></b-icon>
        </button>
      </div>
      <pdf-modal
          :pdfFile="leaseAgreementSelectedPdf"
          :usePortal="true"
          :portalOrder="2"
          v-on:on-pdf-downloaded="onPreviewed"
          v-on:on-pdf-closed="onPreviewClosed"
          v-if="leaseAgreementSelectedPdf && leaseAgreementSelectedPdf.src">
      </pdf-modal>
      <portal to="global-modal-portal" v-if="isNewLeaseAgreement" :order="1">
        <b-modal :active.sync="isNewLeaseAgreement" :full-screen="isMobile" class="lease-order-modal" :has-modal-card="true" :canCancel="false">
          <div class="modal-card">
            <header class="modal-card-head is-block">
              <div class="subtitle is-7 has-text-centered has-text-info is-marginless">Send Retail Lease Agreement for signing for this deal</div>
              <el-steps :active="active" :space="300" finish-status="wait" simple style="background-color: whitesmoke !important;" v-show="currentDeal.saleType === 'Retail'">
                <el-step class="el-step" title="Pricing" icon="fal fa-dollar-sign fa-lg" @click.native="active = 0"></el-step>
                <el-step class="el-step" title="Definition" icon="fal fa-users-class fa-lg" @click.native="next"></el-step>
              </el-steps>
            </header>
            <section class="modal-card-body view-container" style="padding: 0 0;">
              <div name="Pricing" v-show="currentDeal.saleType === 'Retail' && active === 0">
                <section class="field-container">
                  <div class="columns is-mobile is-multiline">
                    <div class="column is-3">
                      <button-list-selector
                        scope="page-1"
                        validateAs="contract sale type"
                        label="Contract Sale Type"
                        :required="true"
                        v-model="selectedVehicleType"
                        :useValidator="thisValidator"
                        :availableValues="definedTypes.dealSaleVehicleType.options">
                      </button-list-selector>
                    </div>

                    <div class="column is-3 is-flex" style="flex-direction: column;">
                        <label class="label">Contract Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <el-date-picker
                          name="contractSoldAt"
                          v-model="contractSoldAt"
                          type="date"
                          placeholder="Click to select..."
                          :clearable="true"
                          :editable="true"
                          :class="{'is-danger': errors.has('page-1.contractSoldAt') }"
                          format="MM-dd-yyyy"
                          data-vv-scope="page-1"
                          popper-class="datepicker-container"
                          v-validate="'required'"
                          data-vv-as="contract date">
                        </el-date-picker>
                        <span v-show="errors.has('page-1.contractSoldAt')" class="help is-danger">
                          <i v-show="errors.has('page-1.contractSoldAt')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.contractSoldAt') }}
                        </span>
                        <pop-fill-control title="Click to replace contract date with this value" :fillableValue="dealSoldAt" :onFillAction="onSetContractDate"></pop-fill-control>
                    </div>

                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Current Mileage<b-icon pack="fas" icon="star" class="fa-ss" v-if="true"></b-icon></label>
                        <div class="control is-vertical-center">
                          <input name="usage" data-vv-scope="page-1" data-vv-as="miles" class="mileage-input" v-validate="{'numeric': true, 'required': true, 'min_value': currentInventory.mileageEstimate ? 0 : minimumUsageAllowed}"
                            :class="{'input': true, 'is-width-100': true, 'is-danger': errors.has('page-1.usage') }" type="number" placeholder="#####" v-model="updatedUsage">
                          <usage-history-component
                            :inventoryId="currentInventory.id"
                            :vinNo="currentInventory.vinNo"
                            :vinUsageHistory="currentInventory.vinUsageHistory"
                            :usage="Number(currentInventory.usage)"
                            :usageRequired="true"
                            v-on:on-last-mileage-click="onLastMileageClick"
                            :minimumUsageAllowed="currentInventory.mileageEstimate ? 0 : minimumUsageAllowed"
                            :usePortal="false"
                            :showEditIcon="false">
                          </usage-history-component>
                        </div>
                        <span v-show="errors.has('page-1.usage')" class="help is-danger"><i v-show="errors.has('page-1.usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.usage') }}</span>
                      </div>
                    </div>

                    <div class="column is-3">
                      <div class="field" >
                        <label class="label">Lease Contract Term<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control">
                          <multiselect
                            @select="onFormChange()"
                             v-validate="{'required': true}"
                             data-vv-validate-on="input|close"
                             data-vv-scope="page-1"
                             :hideSelected="true"
                             data-vv-as="lease contract term"
                             :multiple="false"
                             name="leaseContractTerm"
                             :class="{'is-danger': errors.has('page-1.leaseContractTerm') }"
                             v-model="selectedLeaseContractTermType"
                             label="name" :options="orderedDealLeaseContractTermTypes"
                             placeholder="Lease Contract Term"
                             track-by="name">
                          </multiselect>
                          <span v-show="errors.has('page-1.leaseContractTerm')" class="help is-danger">
                            <i v-show="errors.has('page-1.leaseContractTerm')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.leaseContractTerm') }}
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="column is-3">
                      <div class="field">
                        <label class="label">Allowed Miles Per Term<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control is-vertical-center">
                          <input name="milesPerTerm" data-vv-scope="page-1" data-vv-as="miles per term" class="mileage-input" v-validate="{'numeric': true, 'required': true, 'min_value': 0}"
                            :class="{'input': true, 'is-width-100': true, 'is-danger': errors.has('page-1.milesPerTerm') }" type="number" placeholder="#####" v-model="milesPerTerm">
                        </div>
                        <span v-show="errors.has('page-1.milesPerTerm')" class="help is-danger"><i v-show="errors.has('page-1.milesPerTerm')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.milesPerTerm') }}</span>
                      </div>
                    </div>

                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Lease Contract Amount<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control has-icons-left">
                          <money name="leaseAmount" data-vv-scope="page-1" data-vv-as="lease contract amount" v-validate="{'decimal': 2, 'required': true}" :class="{'input': true, 'is-danger': errors.has('page-1.leaseAmount') }" type="text" placeholder="0000.00" v-model="leaseAmount" v-bind="$globalMoneyFormat"></money>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                        <span v-show="errors.has('page-1.leaseAmount')" class="help is-danger">
                          <i v-show="errors.has('page-1.leaseAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.leaseAmount') }}
                        </span>
                      </div>
                    </div>

                    <div class="column is-3">
                      <label class="label">Security Deposit<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="deposit" data-vv-scope="page-1" data-vv-as="security deposit" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="deposit" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.deposit')" class="help is-danger">
                          <i v-show="errors.has('page-1.deposit')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.deposit') }}
                        </span>
                      </div>
                    </div>

                    <div class="column is-3">
                      <label class="label">Use Tax Percentage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="salesTax" data-vv-scope="page-1" data-vv-as="sales tax" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="salesTax" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-percentage"></i>
                        </span>
                        <span v-show="errors.has('page-1.salesTax')" class="help is-danger">
                          <i v-show="errors.has('page-1.salesTax')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.salesTax') }}
                        </span>
                      </div>
                    </div>

                    <div class="column is-6">
                      <label class="label">Residual Value<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="residualValue" data-vv-scope="page-1" data-vv-as="residual value" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="residualValue" v-bind="$globalMoneyFormat"></money>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                        <span v-show="errors.has('page-1.residualValue')" class="help is-danger">
                          <i v-show="errors.has('page-1.residualValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.residualValue') }}
                        </span>
                      </div>
                    </div>

                    <div class="column is-6 mt-10">
                      <div class="columns is-multiline">
                        <div class="column is-6">
                          <label class="label">First or Single Payment<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="firstPayment" data-vv-scope="page-1" data-vv-as="first payment" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="firstPayment" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.firstPayment')" class="help is-danger">
                              <i v-show="errors.has('page-1.firstPayment')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.firstPayment') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-6" style="align-content: center;">
                          <switch-selector type="is-info" label="Capped" v-model="firstPaymentCapped"></switch-selector>
                        </div>

                        <div class="column is-6">
                          <label class="label">Pre-Delivery Service Charge<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="preDeliveryServiceCharge" data-vv-scope="page-1" data-vv-as="pre-delivery service charge" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="preDeliveryServiceCharge" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.preDeliveryServiceCharge')" class="help is-danger">
                              <i v-show="errors.has('page-1.preDeliveryServiceCharge')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.preDeliveryServiceCharge') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-6" style="align-content: center;">
                          <switch-selector type="is-info" label="Capped" v-model="preDeliveryServiceChargeCapped"></switch-selector>
                        </div>

                        <div class="column is-6">
                          <label class="label">Battery/Tire Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="batteryTireFee" data-vv-scope="page-1" data-vv-as="battery or tire fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="batteryTireFee" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.batteryTireFee')" class="help is-danger">
                              <i v-show="errors.has('page-1.batteryTireFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.batteryTireFee') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-6" style="align-content: center;">
                          <switch-selector type="is-info" label="Capped" v-model="batteryTireFeeCapped"></switch-selector>
                        </div>

                        <div class="column is-6">
                          <label class="label">Electronic Registration Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="electronicRegistrationFilingFee" data-vv-scope="page-1" data-vv-as="electronic registration fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="electronicRegistrationFilingFee" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.electronicRegistrationFilingFee')" class="help is-danger">
                              <i v-show="errors.has('page-1.electronicRegistrationFilingFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.electronicRegistrationFilingFee') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-6" style="align-content: center;">
                          <switch-selector type="is-info" label="Capped" v-model="electronicRegistrationFilingFeeCapped"></switch-selector>
                        </div>

                        <div class="column is-6">
                          <label class="label">Acquisition Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="acquisitionFee" data-vv-scope="page-1" data-vv-as="acquisition fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="acquisitionFee" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.acquisitionFee')" class="help is-danger">
                              <i v-show="errors.has('page-1.acquisitionFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.acquisitionFee') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-6" style="align-content: center;">
                          <switch-selector type="is-info" label="Capped" v-model="acquisitionFeeCapped"></switch-selector>
                        </div>

                        <div class="column is-6">
                          <label class="label">Licence Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="licenceFee" data-vv-scope="page-1" data-vv-as="licence fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="licenceFee" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.licenceFee')" class="help is-danger">
                              <i v-show="errors.has('page-1.licenceFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.licenceFee') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-6" style="align-content: center;">
                          <switch-selector type="is-info" label="Capped" v-model="licenceFeeCapped"></switch-selector>
                        </div>

                        <div class="column is-6">
                          <label class="label">Rental Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="rentalFee" data-vv-scope="page-1" data-vv-as="rental fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="rentalFee" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.rentalFee')" class="help is-danger">
                              <i v-show="errors.has('page-1.rentalFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.rentalFee') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-6" style="align-content: center;">
                          <switch-selector type="is-info" label="Capped" v-model="rentalFeeCapped"></switch-selector>
                        </div>

                        <div class="column is-6">
                          <label class="label">Mvwa Fee<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left has-icons-right">
                            <money name="mvwaFee" data-vv-scope="page-1" data-vv-as="mvwa fee" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="mvwaFee" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.mvwaFee')" class="help is-danger">
                              <i v-show="errors.has('page-1.mvwaFee')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.mvwaFee') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-6" style="align-content: center;">
                          <switch-selector type="is-info" label="Capped" v-model="mvwaFeeCapped"></switch-selector>
                        </div>

                      </div>
                    </div>

                    <div class="column is-6 mt-10">
                      <div class="columns is-multiline">
                        <div class="column is-12">
                          <label class="label">Sales Price<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="salePrice" data-vv-scope="page-1" data-vv-as="sales price" v-validate="{'decimal': 2, 'required': true,  'min_value': 1.00}" class="is-small" :class="{'input': true}" type="text" v-model="salePrice" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.salePrice')" class="help is-danger">
                              <i v-show="errors.has('page-1.salePrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.salePrice') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-12">
                          <label class="label">Gross Capitalized Cost<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="grossCapitalizedCost" class="is-small" :class="{'input': true}" type="text" disabled :value="calcGrossCapitalizedCost" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                          </div>
                        </div>

                        <div class="column is-12">
                          <label class="label">Net Trade-In as Cap Reduction<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="tradeCapReduction" data-vv-scope="page-1" data-vv-as="trade cap reduction" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="tradeCapReduction" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.tradeCapReduction')" class="help is-danger">
                              <i v-show="errors.has('page-1.tradeCapReduction')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.tradeCapReduction') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-12">
                          <label class="label">Net Cash as Cap Reduction<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="cashCapReduction" data-vv-scope="page-1" data-vv-as="cash cap reduction" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="cashCapReduction" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.cashCapReduction')" class="help is-danger">
                              <i v-show="errors.has('page-1.cashCapReduction')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.cashCapReduction') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-12">
                          <label class="label">Net Rebate(s) as Cap Reduction<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="rebateCapReduction" data-vv-scope="page-1" data-vv-as="rebate cap reduction" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="rebateCapReduction" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.rebateCapReduction')" class="help is-danger">
                              <i v-show="errors.has('page-1.rebateCapReduction')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.rebateCapReduction') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-12">
                          <label class="label">Nett Capitalized Cost Reduction<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="nettCapitalizedCostReduction" class="is-small" :class="{'input': true}" type="text" disabled :value="calcNettCapitalizedCostReduction" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                          </div>
                        </div>

                        <div class="column is-12">
                          <label class="label">Adjusted Capitalized Cost<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="adjustedCapitalizedCost" class="is-small" :class="{'input': true}" type="text" disabled :value="calcAdjustedCapitalizedCost" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                          </div>
                        </div>

                        <div class="column is-12">
                          <label class="label">Capitalized Cost Reduction Tax<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="costReductionTax" data-vv-scope="page-1" data-vv-as="capitalized cost reduction tax" v-validate="{'decimal': 2, 'required': true}" class="is-small" :class="{'input': true}" type="text" v-model="costReductionTax" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <span v-show="errors.has('page-1.costReductionTax')" class="help is-danger">
                              <i v-show="errors.has('page-1.costReductionTax')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.costReductionTax') }}
                            </span>
                          </div>
                        </div>

                        <div class="column is-12">
                          <label class="label">Upfront Sales/Use Tax<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="salesUseTax" class="is-small" :class="{'input': true}" type="text" disabled :value="calcUpfrontSalesUseTax" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                          </div>
                        </div>

                        <div class="column is-12">
                          <label class="label">Due At Signing<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <div class="control has-icons-left">
                            <money name="totalDueAtSigning" class="is-small" data-vv-scope="page-1" data-vv-as="due at signing" v-validate="{'decimal': 2, 'required': true}"  :class="{'input': true}" type="text" v-model="totalDueAtSigning" v-bind="$globalMoneyFormat"></money>
                            <span class="icon is-small is-left">
                              <i class="fas fa-dollar-sign"></i>
                            </span>
                            <pop-fill-control title="Click to use this value" :fillableValue="calcDueAtSigning" :onFillAction="onSetTotalDueAtSigning"></pop-fill-control>
                            <span v-show="errors.has('page-1.totalDueAtSigning')" class="help is-danger">
                              <i v-show="errors.has('page-1.totalDueAtSigning')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.totalDueAtSigning') }}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="column is-12">
                      <div class="field">
                        <label class="label">Additional Notes</label>
                        <div class="control">
                          <textarea rows="3" name="retailAgreementNotes" v-validate="{'required': false}" :class="{'textarea': true}" data-vv-scope="page-1" data-vv-as="notes" v-model="retailAgreementNotes"></textarea>
                          <span v-show="errors.has('page-1.retailAgreementNotes')" class="help is-danger"><i v-show="errors.has('page-1.retailAgreementNotes')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.retailAgreementNotes') }}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div name="Definition" v-show="(currentDeal.saleType === 'Retail' && active === 1) || currentDeal.saleType === 'Wholesale'">
                <b-tabs size="is-small" :animated="false" type="is-toggle" @change="loadData()" v-model="tabIndex">
                  <b-tab-item label="Definition" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                    <section style="padding: 12px">
                      <div id="simple-overview" class="box">
                        <section-header :title="'Sale Details'"></section-header>
                        <div class="columns is-multiline is-mobile">
                          <div class="column is-4">
                            <label class="label">Deal #</label>
                            <div class="field">
                              {{ currentDeal.dealCode || '-' }}
                            </div>
                          </div>
                          <div class="column is-4">
                            <div class="field">
                              <label class="label">Sold To</label>
                              <div class="field">
                                {{currentDeal.supplierName}}
                              </div>
                            </div>
                          </div>
                          <div class="column is-4">
                            <div class="field">
                              <label class="label">Sales Person</label>
                              <div class="field">
                                {{currentDeal.salesPersonName || '-' }}
                              </div>
                            </div>
                          </div>
                        </div>
                        <br/>
                        <div v-for="dealItem in this.soldItems" :key="dealItem.id">
                          <div class="columns is-multiline is-mobile">
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Vehicle</label>
                                <div class="field">
                                  {{ dealItem.forInventory.name}}
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Vin</label>
                                <div class="field">
                                  {{ dealItem.forInventory.vinNo}}
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Stock No</label>
                                <div class="field">
                                  <a @click.stop.prevent="onStockNoClick(dealItem.forInventory)">
                                    <span class="has-text-weight-semibold" :title="dealItem.forInventory.stockNo">{{ dealItem.forInventory.stockNo}}</span>
                                  </a>
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Mileage</label>
                                <div class="field">
                                  {{ dealItem.forInventory.usage }}
                                </div>
                              </div>
                            </div>
                            <div class="column is-4">
                              <div class="field">
                                <label class="label">Sale Price</label>
                                <div class="field">
                                  {{ dealItem.salePrice | currency }}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div v-if="!isUpload">
                        <form data-vv-scope="page-2">
                          <div class="columns is-multiline">
                            <div class="column is-12">
                              <label class="label">Sale Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <el-date-picker
                                data-vv-scope="page-2"
                                @change="onFormChange()"
                                v-model="saleDate"
                                type="date"
                                format="MM-dd-yyyy"
                                name="saleDate"
                                id="saleDate"
                                data-vv-as="sale date"
                                :class="{'is-danger': errors.has('saleDate') }"
                                v-validate="'required'"
                                popper-class="datepicker-container"
                                placeholder="Click to select...">
                              </el-date-picker>
                              <span v-show="errors.has('page-2.saleDate')" class="help is-danger">
                                <i v-show="errors.has('page-2.saleDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.saleDate') }}
                              </span>
                            </div>
                            <div class="column is-12">
                              <div class="field">
                                <label class="label">Recipients Email: <b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                                <multiselect
                                  :multiple="true"
                                  :taggable="true"
                                  data-vv-scope="page-2"
                                  @tag="onRecipientAdded"
                                  v-validate="'required'"
                                  name="recipients"
                                  label="fullName"
                                  trackBy="email"
                                  tagPlaceholder="Enter to add a new destination email address"
                                  v-model="selectedRecipients"
                                  :options="selectedRecipients"
                                  data-vv-validate-on="input|close"
                                  data-vv-as="recipients email"
                                  :class="{'is-danger': errors.has('page-2.recipients') }">
                                </multiselect>
                                <span v-show="errors.has('page-2.recipients')" class="help is-danger"><i v-show="errors.has('page-2.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.recipients') }}</span>
                                <span v-show="mustShowEmailError" class="help is-danger"><i v-show="mustShowEmailError" class="fas fa-exclamation-triangle"></i> {{ emailMatchErrorMessage }}</span>
                              </div>
                            </div>
                            <div class="column is-12">
                              <div class="field">
                                <label class="label">Recipients Mobile</label>
                                <multiselect
                                  :multiple="true"
                                  :taggable="true"
                                  data-vv-as="recipients mobile"
                                  data-vv-validate-on="input|close"
                                  @tag="onRecipientMobileAdded"
                                  name="recipients-mobile"
                                  label="fullName"
                                  trackBy="mobile"
                                  tagPlaceholder="Enter to add mobile number"
                                  v-model="selectedRecipientsMobile"
                                  :options="selectedRecipientsMobile">
                                </multiselect>
                                <span v-show="mobileInvalidFormat !== ''" class="help is-danger"><i v-show="mobileInvalidFormat !== ''" class="fas fa-exclamation-triangle"></i> {{ mobileInvalidFormat }}</span>
                              </div>
                            </div>
                          </div>
                        </form>
                        <div class="columns is-multiline">
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Overriding Subject</label>
                              <div class="control">
                                <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                              </div>
                            </div>
                          </div>
                          <div class="column is-12">
                            <div class="field">
                              <label class="label">Optional Message</label>
                              <div class="control">
                                <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                              </div>
                            </div>
                          </div>
                          <div class="column is-6 no-v-padding">
                            <switch-selector type="is-info" label="Would you like to attach title documents?" v-model="attachDocuments"></switch-selector>
                          </div>
                          <div class="column is-6 no-v-padding">
                            <switch-selector type="is-info" label="Watermark Title?" v-model="watermarkTitle"></switch-selector>
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <div class="columns" style="margin-left:-0.5em">
                          <div class="column is-12">
                            <file-selector v-model="leaseAgreementDoc" label="Signed Agreement" :multiple="false" :required="true" :isBoxed="false" :usePortalViewer="false"></file-selector>
                          </div>
                        </div>
                      </div>
                    </section>
                  </b-tab-item>
                  <b-tab-item label="History" icon-pack="fas" icon="fas fa-history fa-sm">
                    <section>
                      <b-table
                        class="lists"
                        :data="documentSigningRequestData">
                        <template slot-scope="props">
                          <b-table-column field="status" label="Status" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            <i data-type="B" class="icon fal fa-envelope-open-text fa-lg list-icon" :class="getColorClassByAgreementStatus(props.row.signingStatus)" :title="'Lease Agreement Status: ' + getDescriptionByAgreementStatus(props.row.signingStatus)"/>
                            <a target="live-doc-download" :href="buildLeaseAgreementUrl(props.row.dealFileLocation)" title="Click to download the last signed lease agreement." v-if="props.row.dealFileLocation && (props.row.signingStatus == 10 || props.row.signingStatus === 3)"><i class="icon fal fa-cloud-download fa-lg list-icon has-text-success"/></a>
                          </b-table-column>
                          <b-table-column field="email" label="Recipients" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ props.row.destinationAddresses }}
                          </b-table-column>
                          <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ formateSignatureType(props.row.type) }}
                          </b-table-column>
                          <b-table-column field="date" label="Date" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ props.row.sentAt | formatDateOnly }}
                          </b-table-column>
                          <b-table-column field="time" label="Time" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                            {{ convertTimeString(props.row.sentAt, props.row.signingStatus) }}
                          </b-table-column>
                          <b-table-column field="id" label="Actions">
                            <a v-if="props.row.cancellationSent === false" @click.stop.prevent="onCancelLeaseAgreement(props.row)"><i class="icon fa-fw fal fa-times-circle fa-lg" :class="{'has-text-danger': !isCancellationDisabled(props.row), 'has-text-grey': isCancellationDisabled(props.row)}" :title="isCancellationDisabled(props.row) ? 'Request cannot be cancelled at this point' : 'Cancel Request'"/></a>
                            <a v-else><i class="icon fa-fw fal fa-times-circle fa-lg has-text-success" title='Cancellation request has been made'/></a>
                            <a v-if="props.row.reminderSent === false" @click.stop.prevent="onLeaseAgreementReminder(props.row)"><i class="icon fa-fw fal fa-redo fa-lg" :class="{'has-text-link': !isReminderDisabled(props.row), 'has-text-grey': isReminderDisabled(props.row)}" :title="isReminderDisabled(props.row) ? 'Reminder cannot be sent at this point' : 'Send Reminder'"/></a>
                            <a v-else><i class="icon fa-fw fal fa-envelope fa-lg has-text-success" title='Reminder has been sent'/></a>
                          </b-table-column>
                        </template>

                        <template slot="empty">
                          <section class="section">
                            <div class="has-text-centered">
                              <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                              <p>No deal documents has been sent for this item.</p>
                            </div>
                          </section>
                        </template>

                      </b-table>
                    </section>
                  </b-tab-item>
                </b-tabs>
              </div>
            </section>
            <footer class="modal-card-foot">
              <div class="columns" style="width: 100%;">
                <div :class="isMobile ? 'column is-2 is-pulled-left' : 'column is-8'">
                  <button type="button" class="button is-link" @click="previous" v-if="currentDeal.saleType === 'Retail' && active > 0 && !isMobile">Previous</button>
                  <button type="button" class="button is-link" @click="previous" v-else-if="currentDeal.saleType === 'Retail' && active > 0 && isMobile"><b-icon pack="fal" icon="arrow-alt-left" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-link" :class="{ 'is-loading' : isCostSaving }" @click="next" v-if="currentDeal.saleType === 'Retail' && active < 1 && !isMobile">Next</button>
                  <button type="button" class="button is-link" @click="next" v-else-if="currentDeal.saleType === 'Retail' && active < 1 && isMobile"><b-icon pack="fal" icon="arrow-alt-right" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-primary" @click="onDownloadLeaseAgreement(currentDeal)" v-if="showPreviewButton && !isMobile">Preview</button>
                  <button type="button" class="button is-primary" @click="onDownloadLeaseAgreement(currentDeal)" v-else-if="showPreviewButton && isMobile"><b-icon pack="fal" icon="eye" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-primary" @click="onVerifyLeaseAgreement(currentDeal)" v-if="showVerifyButton && !isMobile">Verify</button>
                  <button type="button" class="button is-primary" @click="onVerifyLeaseAgreement(currentDeal)" v-else-if="showVerifyButton && isMobile"><b-icon pack="fal" icon="badge-check" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-primary" @click="onEmailLeaseAgreement(currentDeal)" v-if="showSendButton && !isMobile" :disabled="leaseAgreementFinalSendDisallowed">Send</button>
                  <button type="button" class="button is-primary" @click="onEmailLeaseAgreement(currentDeal)" v-else-if="showSendButton && isMobile" :disabled="leaseAgreementFinalSendDisallowed"><b-icon pack="fal" icon="envelope" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-primary" @click="onUploadLeaseAgreement(currentDeal)" v-if="showSaveButton && !isMobile">Save</button>
                  <button type="button" class="button is-primary" @click="onUploadLeaseAgreement(currentDeal)" v-else-if="showSaveButton && isMobile"><b-icon pack="fal" icon="save" custom-size="fa-lg"></b-icon></button>
                  <button type="button" class="button is-danger" @click="updateLeaseAgreementModalState(false)" v-if="!isMobile">Cancel</button>
                  <button type="button" class="button is-danger" @click="updateLeaseAgreementModalState(false)" v-else-if="isMobile"><b-icon pack="fal" icon="ban" custom-size="fa-lg"></b-icon></button>
                </div>
                <div :class="isMobile ? 'column is-2 is-pulled-right' : 'column is-4'">
                  <button type="button" class="button is-primary is-pulled-right" @click="updateUploadState()" v-if="!isMobile">{{isUpload ? 'Cancel Upload & Send For Signing' : 'Upload Signed Agreement'}}</button>
                  <button type="button" class="button is-primary is-pulled-right" @click="updateUploadState()" :title="isUpload ? 'Cancel Upload & Send For Signing' : 'Upload Signed Agreement'" v-else-if="isMobile"><b-icon pack="fal" :icon="isUpload ? 'window-close' : 'file-upload'" custom-size="fa-lg"></b-icon></button>
                </div>
              </div>
            </footer>
          </div>
        </b-modal>
      </portal>
      <send-reminder-control
        v-on:on-hide-reminder-control="onHideReminderModal"
        :showConfirmationModal="showConfirmReminder"
        :signatureRequest="signatureRequest"
        v-on:send-reminder="sendReminder">
      </send-reminder-control>
      <cancel-signature-request-control
        v-on:on-hide-cancel-request-control="onHideCancellationRequestModal"
        :showRequestCancellationModal="showCancelRequest"
        :signatureRequest="cancelSignatureRequest"
        v-on:send-cancellation-request="sendCancellationRequest">
      </cancel-signature-request-control>
    </div>
</template>

<script>

import eventBus from '@/eventBus'
import dealService from '@/services/dealService'
import inventoryService from '@/services/inventoryService'
import documentSigningRequestService from '@/services/documentSigningRequestService'
import formattingMixin from '@/mixins/inventory/formatting'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import supplierService from '@/services/supplierService'
import customerService from '@/services/customerService'
import SectionHeader from '@/components/generic/SectionHeader'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import FileSelector from '@/components/generic/FileSelector'
import PopFillControl from '@/components/generic/PopFillControl'
import SwitchSelector from '@/components/generic/SwitchSelector'
import UsageHistoryComponent from '@/components/inventory/UsageHistoryComponent'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
import Vue from 'vue'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'
import SendReminderControl from '@/components/generic/SendReminderControl'
import CancelSignatureRequestControl from '@/components/generic/CancelSignatureRequestControl'
import { Steps, Step } from 'element-ui'
import _ from 'lodash'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapSubscriberGetters = createNamespacedHelpers('subscriber').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'DealLeaseAgreementAction',
  mixins: [validateBeforeSubmitMixin, formattingMixin, dealInventoryStyleClasses, utilitiesMixin],
  components: {
    'pdf-modal': PdfModalDisplayComponent,
    'section-header': SectionHeader,
    'file-selector': FileSelector,
    'switch-selector': SwitchSelector,
    'send-reminder-control': SendReminderControl,
    'pop-fill-control': PopFillControl,
    'usage-history-component': UsageHistoryComponent,
    'button-list-selector': ButtonListSelector,
    'cancel-signature-request-control': CancelSignatureRequestControl,
    'el-steps': Steps,
    'el-step': Step
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      documentSigningRequestData: [],
      leaseAgreementVerificationActive: false,
      leaseAgreementRequiresVerification: false,
      leaseAgreementPreviewed: false,
      leaseAgreementAcceptConditions: false,
      leaseAgreementSelectedPdf: null,
      isNewLeaseAgreement: false,
      instanceId: this.$uuid.v4(),
      selectedRecipients: [],
      isUpload: false,
      attachDocuments: false,
      watermarkTitle: false,
      leaseAgreementDoc: [],
      tabIndex: 0,
      selectedRecipientsMobile: [],
      mobileInvalidFormat: '',
      emailMatchErrorMessage: '',
      signatureRequest: null,
      showConfirmReminder: false,
      sendingReminder: false,
      cancelSignatureRequest: null,
      showCancelRequest: false,
      sendingCancellationRequest: false,
      saleDate: null,
      active: 0,
      preDeliveryServiceCharge: null,
      preDeliveryServiceChargeCapped: null,
      electronicRegistrationFilingFee: null,
      electronicRegistrationFilingFeeCapped: null,
      tradeCapReduction: null,
      salesTax: 7.00,
      cashCapReduction: null,
      rebateCapReduction: null,
      batteryTireFee: null,
      batteryTireFeeCapped: null,
      deposit: null,
      residualValue: null,
      firstPayment: null,
      firstPaymentCapped: null,
      costReductionTax: null,
      selectedVehicleType: null,
      salePrice: null,
      updatedUsage: null,
      retailAgreementNotes: '',
      contractSoldAt: null,
      contractSaleVehicleTypeId: null,
      milesPerTerm: null,
      rentalFee: null,
      rentalFeeCapped: null,
      mvwaFee: null,
      mvwaFeeCapped: null,
      acquisitionFee: null,
      totalDueAtSigning: null,
      acquisitionFeeCapped: null,
      licenceFee: null,
      licenceFeeCapped: null,
      selectedLeaseContractTermType: null,
      leaseAmount: null,
      isMobile: window.innerWidth <= 700,
      nettCapitalizedCostReductionOffset: null,
      isCostSaving: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapSubscriberGetters(['getCurrentSubscriber']),
    ...mapConfigGetters(['definedTypes']),
    calcDueAtSigning: function () {
      let total = this.salePrice || 0.00
      total += !this.firstPaymentCapped ? (this.firstPayment || 0.00) : 0.00
      total += (!this.licenceFeeCapped && !this.rentalFeeCapped) ? ((this.licenceFee || 0.00) + (this.rentalFee || 0.00)) : 0.00
      total += !this.preDeliveryServiceChargeCapped ? (this.preDeliveryServiceCharge || 0.00) : 0.00
      total += !this.electronicRegistrationFilingFeeCapped ? (this.electronicRegistrationFilingFee || 0.00) : 0.00
      total += !this.acquisitionFeeCapped ? (this.acquisitionFee || 0.00) : 0.00
      total += (!this.mvwaFeeCapped && !this.batteryTireFeeCapped) ? ((this.mvwaFee || 0.00) + (this.batteryTireFee || 0.00)) : 0.00
      total += this.deposit || 0.00
      total += this.calcNettCapitalizedCostReduction || 0.00
      total += this.calcUpfrontSalesUseTax || 0.00
      total += this.costReductionTax || 0.00
      total += (-1 * this.nettCapitalizedCostReductionOffset) || 0.00
      total -= this.salePrice || 0.00
      return total.toFixed(2)
    },
    calcUpfrontSalesUseTax: function () {
      let taxableTotal = 0.00
      taxableTotal += !this.preDeliveryServiceChargeCapped ? (this.preDeliveryServiceCharge || 0.00) : 0.00
      taxableTotal += !this.electronicRegistrationFilingFeeCapped ? (this.electronicRegistrationFilingFee || 0.00) : 0.00
      taxableTotal += !this.batteryTireFeeCapped ? this.batteryTireFee || 0.00 : 0.00
      return taxableTotal * (this.salesTax * 0.01)
    },
    calcGrossCapitalizedCost: function () {
      let total = this.salePrice || 0.00
      total += this.firstPaymentCapped ? (this.firstPayment || 0.00) : 0.00
      total += (this.licenceFeeCapped || this.rentalFeeCapped) ? ((this.licenceFee || 0.00) + (this.rentalFee || 0.00)) : 0.00
      total += this.preDeliveryServiceChargeCapped ? (this.preDeliveryServiceCharge || 0.00) : 0.00
      total += this.electronicRegistrationFilingFeeCapped ? (this.electronicRegistrationFilingFee || 0.00) : 0.00
      total += this.acquisitionFeeCapped ? (this.acquisitionFee || 0.00) : 0.00
      total += (this.mvwaFeeCapped || this.batteryTireFeeCapped) ? ((this.mvwaFee || 0.00) + (this.batteryTireFee || 0.00)) : 0.00
      return total
    },
    calcNettCapitalizedCostReduction: function () {
      let total = 0.00
      total += this.tradeCapReduction || 0.00
      total += this.cashCapReduction || 0.00
      total += this.rebateCapReduction || 0.00
      total += this.nettCapitalizedCostReductionOffset || 0.00
      return total
    },
    calcAdjustedCapitalizedCost: function () {
      let total = this.calcGrossCapitalizedCost
      total -= this.calcNettCapitalizedCostReduction
      return total
    },
    orderedDealLeaseContractTermTypes: function () {
      let dealLeaseContractTermTypes = this.definedTypes.dealLeaseContractTermTypes.options
      return _.sortBy(dealLeaseContractTermTypes, ['name'])
    },
    isNewSale: function () {
      return this.selectedVehicleType ? this.selectedVehicleType.id === this.definedTypes.dealSaleVehicleType.New : false
    },
    currentInventory: function () {
      return this.currentDeal ? this.soldItems[0].forInventory : null
    },
    minimumUsageAllowed: function () {
      if (this.currentInventory && this.currentInventory.vinUsageHistory && this.currentInventory.vinUsageHistory.length > 0) {
        return this.currentInventory.vinUsageHistory[this.currentInventory.vinUsageHistory.length - 1].usage
      }
      return 0
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    dealSoldAt: function () {
      if (!this.currentDeal.soldAt) return ''
      return ('0' + (this.currentDeal.soldAt.getMonth() + 1)).slice(-2) + '-' + ('0' + this.currentDeal.soldAt.getDate()).slice(-2) + '-' + this.currentDeal.soldAt.getFullYear()
    },
    isDealBooked: function () {
      return this.currentDeal && this.currentDeal.type === 'Booked'
    },
    soldItems: function () {
      return (this.currentDeal && this.currentDeal.items) ? this.currentDeal.items.filter((x) => x.dealItemType === 'Sold') : []
    },
    leaseAgreementFinalSendDisallowed: function () {
      return false
    },
    hasLeaseAgreementAccess: function () {
      let allowedPermissions = ['deal.issue.buyers-order']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    lastSignedLeaseAgreement: function () {
      if (this.currentDeal && (this.currentDeal.leaseAgreementStatus === 'Complete' || this.currentDeal.leaseAgreementStatus === 'Signed')) {
        var allSignedLeaseAgreements = this.currentDeal.attachments.filter((x) => x.classType === 'LeaseAgreement' && x.status === 'Active')
        if (allSignedLeaseAgreements.length > 0) {
          return allSignedLeaseAgreements[allSignedLeaseAgreements.length - 1].fileLocation
        }
      }
      return ''
    },
    isOrderedDeal: function () {
      return this.currentDeal && this.currentDeal.type === 'Ordered'
    },
    isValidForLeaseAgreementSigning: function () {
      if (this.soldItems && this.soldItems.length > 0) {
        let dealItem = this.soldItems[0]
        if (this.currentDeal.purchaserName === '') return false

        if (this.isOrderedDeal) return true

        if (!dealItem) return false

        if (!dealItem.salePrice || dealItem.salePrice <= 0) return false

        return (dealItem.forInventory.trueMileageUnknown === true ||
          (dealItem.forInventory.mileageEstimate === false &&
          dealItem.forInventory.usage &&
          dealItem.forInventory.usage > 0))
      } else {
        return false
      }
    },
    invalidForLeaseAgreementReasons: function () {
      let dealItem = this.soldItems.length > 0 ? this.soldItems[0] : null
      var result = 'Action disabled. Please ensure the following details are captured.\n'
      if (this.currentDeal && dealItem) {
        if (this.currentDeal.purchaserName === '') {
          result += 'Buyer details not set.\n'
        }

        if (this.isOrderedDeal) return result

        if (!dealItem.salePrice || dealItem.salePrice <= 0) {
          result += 'Sale price not set.\n'
        }
        if (dealItem.forInventory.mileageEstimate === true) {
          result += 'Actual vehicle mileage is required. Mileage is currently estimated.\n'
        }
        if (!dealItem.forInventory.usage || dealItem.forInventory.usage <= 0) {
          result += 'Vehicle mileage not set.\n'
        }
      } else {
        result += 'Deal selected is invalid.\n'
      }
      return result
    },
    mustShowEmailError: function () {
      return this.emailMatchErrorMessage !== '' && this.emailMatchErrorMessage !== ' ' && this.emailMatchErrorMessage !== null && this.emailMatchErrorMessage !== undefined
    },
    showPreviewButton: function () {
      if (this.currentDeal) {
        if (this.isUpload) return false
        return this.currentDeal.leaseAgreementCostsSet
      }
      return false
    },
    showVerifyButton: function () {
      if (this.currentDeal && this.currentDeal.saleType === 'Retail') {
        return (!this.leaseAgreementVerificationActive && this.leaseAgreementRequiresVerification) && this.leaseAgreementPreviewed && !this.isUpload
      } else {
        if (this.active === 1) {
          return (!this.leaseAgreementVerificationActive && this.leaseAgreementRequiresVerification) && this.leaseAgreementPreviewed && !this.isUpload
        } else {
          return false
        }
      }
    },
    showSendButton: function () {
      if (this.currentDeal && this.currentDeal.saleType === 'Retail') {
        return (this.leaseAgreementVerificationActive || !this.leaseAgreementRequiresVerification) && this.leaseAgreementPreviewed && !this.isUpload
      } else {
        if (this.active === 1) {
          return (this.leaseAgreementVerificationActive || !this.leaseAgreementRequiresVerification) && this.leaseAgreementPreviewed && !this.isUpload
        } else {
          return false
        }
      }
    },
    showSaveButton: function () {
      if (this.currentDeal && this.currentDeal.saleType === 'Retail') {
        return this.isUpload
      } else {
        if (this.active === 1) {
          return this.isUpload
        } else {
          return false
        }
      }
    },
    getToolTipForLeaseAgreement: function () {
      let baseText = 'Lease Agreement Status: '
      if (this.currentDeal && !this.currentDeal.isLeaseAgreementUploaded) {
        baseText += this.getDescriptionByAgreementStatus(this.currentDeal.leaseAgreementStatusId, 'Click to send a lease agreement for this deal for signing.')
      } else {
        if ((this.currentDeal.leaseAgreementUploadedBy !== '' && this.currentDeal.leaseAgreementUploadedBy !== undefined) && (this.currentDeal.leaseAgreementUploadedAt !== null && this.currentDeal.leaseAgreementUploadedAt !== undefined)) {
          baseText += this.getUploadDescriptionByAgreementStatus(this.currentDeal.leaseAgreementUploadedBy, this.currentDeal.leaseAgreementUploadedAt)
        } else {
          baseText += 'No user details or date of upload has been captured for uploaded file.'
        }
      }
      return baseText
    }
  },
  methods: {
    onLastMileageClick: function (lastMileage) {
      this.updatedUsage = lastMileage
    },
    setBatteryTireFee: function (recalculate) {
      let useDealerDefaults = !this.currentDeal.leaseAgreementCostsSet
      if (useDealerDefaults || recalculate) {
        this.batteryTireFee = this.isNewSale ? this.currentDealer.batteryTireFee : this.currentDealer.batteryTireFeeUsed
        return
      }
      this.batteryTireFee = this.currentDeal.batteryTireFee
    },
    setAcquisitionFee: function (recalculate) {
      let useDealerDefaults = !this.currentDeal.leaseAgreementCostsSet
      if (useDealerDefaults || recalculate) {
        this.acquisitionFee = this.currentDealer.defaultAcquisitionFee
        return
      }
      this.acquisitionFee = this.currentDeal.acquisitionFee
    },
    setLicenceFee: function (recalculate) {
      let useDealerDefaults = !this.currentDeal.leaseAgreementCostsSet
      if (useDealerDefaults || recalculate) {
        this.licenceFee = this.currentDealer.defaultLicenceFee
        return
      }
      this.licenceFee = this.currentDeal.licenceFee
    },
    setRentalFee: function (recalculate) {
      let useDealerDefaults = !this.currentDeal.leaseAgreementCostsSet
      if (useDealerDefaults || recalculate) {
        this.rentalFee = this.currentDealer.defaultRentalFee
        return
      }
      this.rentalFee = this.currentDeal.rentalFee
    },
    setMvwaFee: function (recalculate) {
      let useDealerDefaults = !this.currentDeal.leaseAgreementCostsSet
      if (useDealerDefaults || recalculate) {
        this.mvwaFee = this.currentDealer.defaultMvwaFee
        return
      }
      this.mvwaFee = this.currentDeal.mvwaFee
    },
    onSetContractDate: function (date) {
      this.contractSoldAt = date
    },
    onSetTotalDueAtSigning: function (total) {
      this.totalDueAtSigning = total
    },
    next () {
      var scope = 'page-' + (this.active + 1) + '.*'
      switch (this.active) {
        case 0: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.updateCostData()
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
        case 1: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.onVerifyLeaseAgreement(this.currentDeal)
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
      }
    },
    previous () {
      if (this.active-- < 1) this.active = 0
    },
    isCancellationDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    isReminderDisabled: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        return false
      }
      return true
    },
    loadData: function () {
      if (this.tabIndex === 0) return
      let dealId = this.currentDeal.id
      const searchCriteria = {
        dealId: dealId,
        dealerId: this.currentDealer.id,
        signingRequestType: 0,
        query: '',
        pageSize: this.perPage,
        sortBy: 'sentAt',
        page: this.page
      }
      this.isLoading = true
      documentSigningRequestService.searchByType(searchCriteria).then(response => {
        this.documentSigningRequestData = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          item.reminderSent = false
          item.cancellationSent = false
          this.documentSigningRequestData.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.documentSigningRequestData = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    updateCostData: function () {
      let that = this
      this.isCostSaving = true
      var costModel = {
        dealId: this.currentDeal.id,
        preDeliveryServiceCharge: this.preDeliveryServiceCharge,
        preDeliveryServiceChargeCapped: this.preDeliveryServiceChargeCapped,
        electronicRegistrationFilingFee: this.electronicRegistrationFilingFee,
        electronicRegistrationFilingFeeCapped: this.electronicRegistrationFilingFeeCapped,
        tradeCapReduction: this.tradeCapReduction,
        salesTax: this.salesTax,
        cashCapReduction: this.cashCapReduction,
        rebateCapReduction: this.rebateCapReduction,
        firstPayment: this.firstPayment,
        firstPaymentCapped: this.firstPaymentCapped,
        costReductionTax: this.costReductionTax,
        salePrice: this.salePrice,
        deposit: this.deposit,
        residualValue: this.residualValue,
        retailAgreementNotes: this.retailAgreementNotes,
        batteryTireFee: this.batteryTireFee,
        batteryTireFeeCapped: this.batteryTireFeeCapped,
        contractSoldAt: this.contractSoldAt,
        contractSaleVehicleTypeId: this.selectedVehicleType.id,
        updatedUsage: this.updatedUsage,
        acquisitionFee: this.acquisitionFee,
        acquisitionFeeCapped: this.acquisitionFeeCapped,
        licenceFee: this.licenceFee,
        licenceFeeCapped: this.licenceFeeCapped,
        rentalFee: this.rentalFee,
        rentalFeeCapped: this.rentalFeeCapped,
        mvwaFee: this.mvwaFee,
        mvwaFeeCapped: this.mvwaFeeCapped,
        milesPerTerm: this.milesPerTerm,
        leaseContractTermTypeId: this.selectedLeaseContractTermType.id,
        leaseAmount: this.leaseAmount,
        amountDueAtSigning: this.totalDueAtSigning,
        capitalizedCostReduction: this.calcNettCapitalizedCostReduction,
        upfrontTaxValue: this.calcUpfrontSalesUseTax,
        grossCapitalizedCost: this.calcGrossCapitalizedCost
      }

      dealService.updateLeaseAgreementCosts(costModel).then(response => {
        if (response.errors) {
          that.isCostSaving = false
          that.serverErrors = response.errors
        } else {
          that.isCostSaving = false
          that.active++
          eventBus.$emit('deal-changed', [response.deal])
          eventBus.$emit('reload-deal', [response.deal])
        }
      }).catch((error) => {
        that.isCostSaving = false
        throw error
      })
    },
    convertTimeString: function (date, status) {
      if (status !== 0) {
        var d = new Date(date)
        var hour = '' + d.getHours()
        var minute = '' + d.getMinutes()

        if (hour.length < 2) hour = '0' + hour
        if (minute.length < 2) minute = '0' + minute

        return [hour, minute].join(':')
      } else {
        return ''
      }
    },
    onCancelLeaseAgreement: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.cancelSignatureRequest = signatureRequest
        this.onShowCancellationRequestModal()
      } else {
        this.failedToast('Signature request cannot be cancelled at this point.')
      }
    },
    onLeaseAgreementReminder: function (signatureRequest) {
      if (signatureRequest.sent === true && (signatureRequest.signingStatus === 0 || signatureRequest.signingStatus === 1 || signatureRequest.signingStatus === 2)) {
        this.signatureRequest = signatureRequest
        this.onShowReminderModal()
      } else {
        this.failedToast('Signature request reminder cannot be sent at this point.')
      }
    },
    formateSignatureType: function (type) {
      return type === 'LeaseReturned' ? 'Lease Returned' : type
    },
    onLeaseAgreementRequired: function () {
      if (this.hasLeaseAgreementAccess && this.isValidForLeaseAgreementSigning) {
        this.updateLeaseAgreementModalState(true)
      } else {
        eventBus.$emit('lease-agreement-cancelled', {})
      }
    },
    onVerifyLeaseAgreement: function (deal) {
      var scope = 'page-2.*'
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.leaseAgreementVerificationActive = true
        }
      })
    },
    onDownloadLastLeaseAgreement: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.lastSignedLeaseAgreement)
    },
    buildLeaseAgreementUrl: function (signedAgreementUrl) {
      return process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(signedAgreementUrl)
    },
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data,
        email: data
      }

      this.selectedRecipients.push(recipient)
      this.checkEmails(this.selectedRecipients)
    },
    clearAllRecipients: function () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      this.isUpload = false
      this.leaseAgreementDoc = []
      this.attachDocuments = false
      this.watermarkTitle = false
      this.mobileInvalidFormat = ''
      this.emailMatchErrorMessage = ''
    },
    updateLeaseAgreementModalState: function (state) {
      this.isNewLeaseAgreement = state
      let that = this

      if (state === true && this.soldItems.length > 0) {
        let firstDealItem = this.soldItems[0]
        let firstSoldVehicle = firstDealItem.forInventory
        this.leaseAgreementPreviewed = false
        this.leaseAgreementVerificationActive = false
        this.leaseAgreementAcceptConditions = false
        this.overridingSubject = 'Lease Agreement - '
        this.overridingSubject += firstSoldVehicle ? firstSoldVehicle.name + ' - ' + firstSoldVehicle.vinNo : ''
        this.optionalMessage = 'Please review and sign the attached Lease Agreement to purchase stock #'
        this.optionalMessage += firstSoldVehicle ? firstSoldVehicle.stockNo : ''
        this.optionalMessage += ' for lease amount of ' + this.$formatCurrency(firstDealItem.salePrice)
        if (this.currentDeal.saleType === 'Wholesale' && this.currentDeal.purchaserId) {
          supplierService.single(this.currentDeal.purchaserId).then(response => {
            let purchasingSupplier = response.supplier
            let validContacts = purchasingSupplier.contacts.filter((x) => x.status === 'Active' && x.receivesBuyersOrder === true && x.email !== '')
            // email
            that.availableAddresses = validContacts.map(function (y) {
              return {
                email: y.email,
                fullName: y.fullName ? y.fullName + '(' + y.email + ')' : y.fullName
              }
            })
            if (that.availableAddresses.length > 0) {
              that.selectedRecipients = that.availableAddresses
            } else {
              that.selectedRecipients = []
            }

            that.checkEmails(that.selectedRecipients)

            // mobile
            that.availableMobileNumbers = validContacts.filter(x => x.mobile !== '' && x.mobile.length === 16).map(function (y) {
              return {
                mobile: y.mobile,
                fullName: y.fullName ? y.fullName + '(' + y.mobile + ')' : y.fullName
              }
            })
            if (that.availableMobileNumbers.length > 0) {
              that.selectedRecipientsMobile = that.availableMobileNumbers
            } else {
              that.selectedRecipientsMobile = []
            }
          }).catch((error) => {
            that.serverErrorMessage = error.message
          })
        } else if (this.currentDeal.saleType === 'Retail' && this.currentDeal.purchaserId) {
          customerService.single(this.currentDeal.purchaserId).then(response => {
            that.active = 0
            let purchasingCustomer = response.customer

            if (purchasingCustomer.email !== '') {
              let availableAddresses = [{
                email: purchasingCustomer.email,
                fullName: purchasingCustomer.fullName ? purchasingCustomer.fullName + '(' + purchasingCustomer.email + ')' : purchasingCustomer.fullName
              }]
              if (availableAddresses.length > 0) {
                that.selectedRecipients = availableAddresses
              } else {
                that.selectedRecipients = []
              }
            }

            let useDealerDefaults = !that.currentDeal.leaseAgreementCostsSet
            that.preDeliveryServiceCharge = useDealerDefaults ? that.currentDealer.preDeliveryServiceCharge : that.currentDeal.preDeliveryServiceCharge
            that.preDeliveryServiceChargeCapped = that.currentDeal.preDeliveryServiceChargeCapped
            that.electronicRegistrationFilingFee = useDealerDefaults ? that.currentDealer.electronicRegistrationFilingFee : that.currentDeal.electronicRegistrationFilingFee
            that.electronicRegistrationFilingFeeCapped = that.currentDeal.electronicRegistrationFilingFeeCapped
            that.tradeCapReduction = that.currentDeal.tradeCapReduction
            that.salesTax = useDealerDefaults ? that.currentDealer.salesTax : that.currentDeal.salesTax
            that.cashCapReduction = that.currentDeal.cashCapReduction
            that.rebateCapReduction = that.currentDeal.rebateCapReduction
            that.firstPayment = that.currentDeal.firstPayment
            that.firstPaymentCapped = that.currentDeal.firstPaymentCapped
            let firstDealItem = that.soldItems[0]
            that.costReductionTax = that.currentDeal.costReductionTax
            that.salePrice = firstDealItem.salePrice
            that.deposit = that.currentDeal.deposit
            that.residualValue = that.currentDeal.residualValue
            that.retailAgreementNotes = that.currentDeal.retailAgreementNotes
            that.contractSoldAt = that.currentDeal.contractSoldAt
            that.milesPerTerm = that.currentDeal.milesPerTerm
            if (that.currentDeal.contractSaleVehicleTypeId != null) {
              that.selectedVehicleType = that.definedTypes.dealSaleVehicleType.options.find(x => x.id === that.currentDeal.contractSaleVehicleTypeId)
            }
            if (that.currentDeal.leaseContractTermTypeId != null) {
              that.selectedLeaseContractTermType = that.definedTypes.dealLeaseContractTermTypes.options.find(x => x.id === that.currentDeal.leaseContractTermTypeId)
            }
            that.leaseAmount = that.currentDeal.leaseAmount
            that.totalDueAtSigning = that.currentDeal.amountDueAtSigning
            that.setBatteryTireFee(false)
            that.batteryTireFeeCapped = that.currentDeal.batteryTireFeeCapped
            that.setAcquisitionFee(false)
            that.acquisitionFeeCapped = that.currentDeal.acquisitionFeeCapped
            that.setLicenceFee(false)
            that.licenceFeeCapped = that.currentDeal.licenceFeeCapped
            that.setRentalFee(false)
            that.rentalFeeCapped = that.currentDeal.rentalFeeCapped
            that.setMvwaFee(false)
            that.mvwaFeeCapped = that.currentDeal.mvwaFeeCapped
            that.checkEmails(that.selectedRecipients)

            if (purchasingCustomer.mobile !== '' && purchasingCustomer.mobile.length === 16) {
              let availableMobileNumbers = [{
                mobile: purchasingCustomer.mobile,
                fullName: purchasingCustomer.fullName ? purchasingCustomer.fullName + '(' + purchasingCustomer.mobile + ')' : purchasingCustomer.fullName
              }]
              if (availableMobileNumbers.length > 0) {
                that.selectedRecipientsMobile = availableMobileNumbers
              } else {
                that.selectedRecipientsMobile = []
              }
            }
          }).catch((error) => {
            that.serverErrorMessage = error.message
          })
        } else {
          // email
          if (this.currentDeal.purchaserContactEmail && this.currentDeal.purchaserContactEmail !== '') {
            let purchaser = {
              email: this.currentDeal.purchaserContactEmail,
              fullName: this.currentDeal.purchaserName + ' (' + this.currentDeal.purchaserContactEmail + ')'
            }
            this.selectedRecipients = purchaser
          } else {
            this.selectedRecipients = []
          }

          this.checkEmails(this.selectedRecipients)

          // mobile
          if (this.currentDeal.purchaserContactMobile && this.currentDeal.purchaserContactMobile !== '' && this.currentDeal.purchaserContactMobile.length === 16) {
            this.availableMobileNumbers = [{
              mobile: this.currentDeal.purchaserContactMobile,
              fullName: this.currentDeal.purchaserName + ' (' + this.currentDeal.purchaserContactName + ')'
            }]
            this.selectedRecipientsMobile = this.availableMobileNumbers
          } else {
            this.selectedRecipientsMobile = []
          }
        }
      }

      if (state === false) {
        eventBus.$emit('lease-agreement-cancelled', {})
        this.clearAllRecipients()
        this.preDeliveryServiceCharge = null
        this.preDeliveryServiceChargeCapped = null
        this.electronicRegistrationFilingFee = null
        this.electronicRegistrationFilingFeeCapped = null
        this.tradeCapReduction = null
        this.salesTax = 7.00
        this.cashCapReduction = null
        this.rebateCapReduction = null
        this.firstPayment = null
        this.firstPaymentCapped = null
        this.costReductionTax = null
        this.salePrice = null
        this.deposit = null
        this.residualValue = null
        this.contractSoldAt = null
        this.selectedVehicleType = null
        this.updatedUsage = null
        this.batteryTireFee = null
        this.batteryTireFeeCapped = null
        this.acquisitionFee = null
        this.acquisitionFeeCapped = null
        this.licenceFee = null
        this.licenceFeeCapped = null
        this.rentalFee = null
        this.rentalFeeCapped = null
        this.mvwaFee = null
        this.mvwaFeeCapped = null
        this.milesPerTerm = null
        this.selectedLeaseContractTermType = null
        this.leaseAmount = null
      }
    },
    checkEmails: function (recipients) {
      if (recipients.length > 0) {
        let emails = recipients.map(function (x) {
          return x.email
        })

        let blacklistEmailCheckModel = {
          subscriberId: this.getCurrentSubscriber.id,
          emailAddresses: emails,
          errorMessage: ''
        }

        inventoryService.getVerifiedEmailList(blacklistEmailCheckModel).then(response => {
          blacklistEmailCheckModel = response.blacklistEmailCheck

          let approvedEmails = blacklistEmailCheckModel.emailAddresses.map(function (x) {
            return {
              email: x,
              fullName: x
            }
          })
          this.selectedRecipients = approvedEmails
          if (this.selectedRecipients.length === 0) {
            this.selectedRecipients = []
          }

          if (blacklistEmailCheckModel.errorMessage !== '' || blacklistEmailCheckModel.errorMessage !== null) {
            this.emailMatchErrorMessage = blacklistEmailCheckModel.errorMessage
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
        })
      }
    },
    onDownloadLeaseAgreement: function (deal) {
      let date = new Date()
      if (this.saleDate) {
        date = this.saleDate
      }

      let docUrl = this.currentDeal.saleType === 'Wholesale'
        ? process.env.VUE_APP_ROOT_DOC_SITE + 'docs/deals/' + this.currentDeal.id + '/' + this.currentUser.id + '/' + date.toDateString()
        : process.env.VUE_APP_ROOT_DOC_SITE + 'docs/deals/retail/lease/' + this.currentDeal.id + '/' + this.currentUser.id + '/' + date.toDateString()
      docUrl = encodeURI(docUrl)
      this.leaseAgreementSelectedPdf = {
        name: 'Lease Agreement',
        src: docUrl,
        fileName: 'lease_agreement_' + this.currentDeal.id + '.pdf',
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
      this.leaseAgreementPreviewed = true
    },
    onPreviewClosed: function () {
      this.leaseAgreementSelectedPdf = null
    },
    onEmailLeaseAgreement: function (deal) {
      let that = this
      this.$validator.validateAll('recipient-form').then((result) => {
        if (result) {
          var emailAddresses = this.selectedRecipients.map((x) => x.email).join(',')
          var mobileNumbers = this.selectedRecipientsMobile.map(e => e.mobile).join(',')
          var commandModel = {
            subscriberId: deal.subscriberId,
            dealerId: deal.dealerId,
            dealId: deal.id,
            type: 'Lease',
            destinationAddresses: emailAddresses,
            destinationMobileNumbers: mobileNumbers,
            overridingSubject: this.overridingSubject,
            optionalMessage: this.optionalMessage,
            attachDocuments: this.attachDocuments,
            watermarkTitle: this.watermarkTitle,
            saleDate: this.saleDate
          }

          dealService.queueMail(commandModel).then(response => {
            if (response.errors) {
              that.serverErrors = response.errors
            } else {
              that.clearAllRecipients()
              that.isNewLeaseAgreement = false
              that.leaseAgreementVerificationActive = false
              that.leaseAgreementPreviewed = false
              that.leaseAgreementAcceptConditions = false
              that.active = 0
              eventBus.$emit('deal-changed', [response.deal])
              eventBus.$emit('lease-agreement-complete', response.deal)
            }
          }).catch((error) => {
            throw error
          })
        }
      })
    },
    updateUploadState: function () {
      this.isUpload = this.isUpload === false
      if (this.active !== 1) this.active = 1
    },
    onUploadLeaseAgreement: function (deal) {
      if (this.leaseAgreementDoc.length > 0) {
        var signedDocumentModel = this.leaseAgreementDoc.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            docName: x.uniqueId,
            FileUri: ''
          }
        })
        var dealId = deal.id
        var signedAgreementUploadModel = {
          dealId: dealId,
          fileName: signedDocumentModel[0].docName,
          dealMediaClassType: 'LeaseAgreement'
        }

        dealService.uploadSignedLeaseAgreement(signedAgreementUploadModel).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.clearAllRecipients()
            this.isNewLeaseAgreement = false
            this.leaseAgreementVerificationActive = false
            this.leaseAgreementPreviewed = false
            this.leaseAgreementAcceptConditions = false
            let allSignedLeaseAgreements = response.deal.attachments.filter((x) => x.classType === 'LeaseAgreement')
            if (allSignedLeaseAgreements.length > 0) {
              let newAttachment = allSignedLeaseAgreements[allSignedLeaseAgreements.length - 1]
              Vue.set(this.currentDeal, 'attachments', [...this.currentDeal.attachments, newAttachment])
              this.currentDeal.leaseAgreementStatus = 'Complete'
              this.currentDeal.leaseAgreementStatusId = 10
            }
            eventBus.$emit('deal-changed', [response.deal])
            eventBus.$emit('lease-agreement-complete', response.deal)
          }
        }).catch((error) => {
          throw error
        })
      }
    },
    onRecipientMobileAdded: function (data) {
      this.mobileInvalidFormat = ''
      if (data && data.length === 16) {
        var recipient = {
          fullName: data,
          mobile: data
        }
        this.selectedRecipientsMobile.push(recipient)
      } else {
        this.mobileInvalidFormat = 'Invalid format for mobile number:  ' + data + '. Mobile format should be +1(xxx)-xxx-xxxx'
      }
    },
    onShowReminderModal: function () {
      this.isNewLeaseAgreement = false
      this.showConfirmReminder = true
    },
    onHideReminderModal: function () {
      this.showConfirmReminder = false
      this.isNewLeaseAgreement = true
      this.signatureRequest = null
    },
    sendReminder: function () {
      if (!this.sendingReminder) {
        this.sendingReminder = true
        documentSigningRequestService.sendReminder(this.signatureRequest).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.signatureRequest.reminderSent = true
            this.successToast('Signature request reminder has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideReminderModal()
          this.sendingReminder = false
        })
      }
    },
    onShowCancellationRequestModal: function () {
      this.isNewLeaseAgreement = false
      this.showCancelRequest = true
    },
    onHideCancellationRequestModal: function () {
      this.showCancelRequest = false
      this.isNewLeaseAgreement = true
      this.cancelSignatureRequest = null
    },
    sendCancellationRequest: function () {
      if (!this.sendingCancellationRequest) {
        this.sendingCancellationRequest = true
        documentSigningRequestService.cancelSignatureRequest(this.cancelSignatureRequest.signatureRequestId).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.cancelSignatureRequest.cancellationSent = true
            this.successToast('Signature cancellation request has been sent.')
          }
        }).catch((error) => {
          throw error
        }).finally(() => {
          this.onHideCancellationRequestModal()
          this.sendingCancellationRequest = false
        })
      }
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  beforeDestroy: function () {
    eventBus.$off('lease-agreement-required', this.onLeaseAgreementRequired)
  },
  mounted: function () {
    eventBus.$on('lease-agreement-required', this.onLeaseAgreementRequired)
    this.saleDate = new Date()
  },
  watch: {
    totalDueAtSigning: function (newVal, oldVal) {
      if (newVal !== this.calcDueAtSigning) {
        this.nettCapitalizedCostReductionOffset = newVal - this.calcDueAtSigning
      } else {
        this.nettCapitalizedCostReductionOffset = null
      }
    },
    selectedVehicleType: function (newVal, oldVal) {
      if (newVal) {
        this.setBatteryTireFee(true)
      }
    }
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  #simple-overview  {
    margin-bottom:0.25em !important;
  }

  #simple-overview .columns .column {
    padding-top: 0.25em !important;
    padding-bottom: 0 !important;
    margin-top:0.25em !important;
    margin-bottom:0 !important;
  }

  .modal-card-body {
    position:relative;
    max-height: calc(70vh - 10px) !important;
    height: calc(70vh - 10px) !important;
    max-width: 100% !important;
    min-width: 100% !important;
    overflow-y: auto !important;
    width: 960px !important;
  }

  @media screen and (max-height: 600px), print {
    .modal-card-body {
      max-height: calc(70vh - 60px) !important;
      height: calc(70vh - 60px) !important;
    }
  }

  .verification-container {
    margin-bottom: 3em;
  }

  .status-container {
    margin-bottom: 3em;
  }

  .datepicker-container {
    position: fixed !important;
    z-index: 10000 !important;
  }

  .field-container {
    padding: 12px;

    .column {
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    }

    .label {
      padding-bottom: 0px !important;
      margin-bottom: 0px !important;
    }
  }

</style>

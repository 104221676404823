import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/policyExpenses',
    name: 'ListPolicyExpenses',
    component: () => import('@/components/configuration/policyExpenses/ListPolicyExpenses'),
    beforeEnter: requiresAuth
  },
  {
    name: 'PolicyExpensesQuickView',
    path: '/configuration/policyExpenses/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/policyExpenses/ViewPolicyExpenses')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/policyExpenses/new',
    name: 'NewPolicyExpenses',
    component: () => import('@/components/configuration/policyExpenses/NewPolicyExpenses'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/policyExpenses/view/:id',
    name: 'ViewPolicyExpenses',
    component: () => import('@/components/configuration/policyExpenses/ViewPolicyExpenses'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/policyExpenses/edit/:id',
    name: 'EditPolicyExpenses',
    component: () => import('@/components/configuration/policyExpenses/EditPolicyExpenses'),
    beforeEnter: requiresAuth
  }
]

import eventBus from '@/eventBus'
import ServiceRequestLine from '@/components/serviceRequest/ServiceRequestLine'
import ServiceRequestInspectionType from '@/components/serviceRequest/ServiceRequestInspectionType'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import InventorySelector from '@/components/generic/InventorySelector'
import NoteCapture from '@/components/generic/NoteCapture'
import NoteTimelineView from '@/components/generic/NoteTimelineView'
import SectionHeader from '@/components/generic/SectionHeader'
import inventoryService from '@/services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

var mixin = {
  components: {
    'service-request-line': ServiceRequestLine,
    'service-request-inspection-type': ServiceRequestInspectionType,
    'button-list-selector': ButtonListSelector,
    'inventory-selector': InventorySelector,
    'note-capture': NoteCapture,
    'note-timeline-view': NoteTimelineView,
    'section-header': SectionHeader,
    'error-display-component': ErrorDisplayComponent
  },
  data () {
    return {
      mode: 0,
      selectedInventory: null,
      selectedPriority: null,
      requiredAt: null,
      roNumber: '',
      selectedServiceRequestStatus: null,
      selectedServiceRequestType: null,
      notes: [],
      currentServiceRequest: {
        id: 0,
        lines: []
      },
      serverErrors: [],
      serverErrorMessage: '',
      isLoading: false,
      isFullPage: true,
      isSaving: false,
      isWorkItemDefinition: false,
      isInspectionTypeDefinition: false,
      defaultDealerInspectionTypes: [],
      isLoadDefaultInspectionTypes: false,
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    isInspectionTypesCollapsed: function () {
      if (!this.selectedServiceRequestType) return true
      if (this.selectedServiceRequestType.id === this.definedTypes.serviceRequestType.AdditionalReconditioning) return true
      return false
    },
    isRequestDefinition: function () {
      return this.mode === 0
    },
    allPriorities: function () {
      return this.definedTypes.serviceRequestPriorityType.options
    },
    allInspectionTypes: function () {
      var allTypes = []
      this.definedTypes.inspectionTypes.forEach((type) => {
        allTypes.push(type)
      })
      return allTypes
    },
    validServiceLines: function () {
      return this.currentServiceRequest.lines.filter(x => x.status !== 'Deleted')
    },
    validServiceInspectionTypes: function () {
      return this.currentServiceRequest.inspectionTypes.filter(x => x.status !== 'Deleted')
    },
    activeServiceRequestStatusTypes: function () {
      let notApplicaableTypes = [
        this.definedTypes.serviceRequestStatusType.NotSet,
        this.definedTypes.serviceRequestStatusType.Cancelled,
        this.definedTypes.serviceRequestStatusType.OnHold,
        this.definedTypes.serviceRequestStatusType.Declined
      ]
      return this.definedTypes.serviceRequestStatusType.options.filter(
        (x) => !notApplicaableTypes.includes(x.id)
      )
    },
    activeServiceRequestTypes: function () {
      let defaults = [
        this.definedTypes.serviceRequestType.GetReady,
        this.definedTypes.serviceRequestType.FirstRo,
        this.definedTypes.serviceRequestType.AdditionalReconditioning
      ]
      if (!this.selectedInventory) {
        return this.definedTypes.serviceRequestType.options.filter((x) => defaults.includes(x.id))
      }
      if (this.selectedInventory.hasFirstRo === false) {
        return this.definedTypes.serviceRequestType.options.filter((x) => defaults.includes(x.id))
      }

      if (this.currentServiceRequest && this.currentServiceRequest.id > 0) {
        return this.definedTypes.serviceRequestType.options.filter((x) => defaults.includes(x.id))
      }

      defaults = [
        this.definedTypes.serviceRequestType.GetReady,
        this.definedTypes.serviceRequestType.AdditionalReconditioning
      ]
      return this.definedTypes.serviceRequestType.options.filter((x) => defaults.includes(x.id))
    },
    nonCancelledDeals: function () {
      return this.selectedInventory && this.selectedInventory.deals.length > 0 ? this.selectedInventory.deals.filter((x) => x.type !== 'Cancelled' && x.items.find((y) => y.forInventory.id === this.selectedInventory.id && y.dealItemType === 'Sold')) : []
    },
    deliveredOrSoldDeals: function () {
      return this.nonCancelledDeals.filter((x) => x.type === 'Booked' || x.type === 'Delivered')
    },
    hasDeliveredOrSoldDeals: function () {
      return this.deliveredOrSoldDeals.length > 0
    },
    addInspectionBtnTitle: function () {
      return this.hasDeliveredOrSoldDeals ? 'Add disabled because of a linked delivered/booked deal' : 'Click to add new inspection type.'
    },
    addWorkItemBtnTitle: function () {
      return this.hasDeliveredOrSoldDeals && !this.isAdmin ? 'Add disabled because of a linked delivered/booked deal' : 'Click to add new work item.'
    },
    isAddBtnDisabled: function () {
      return this.currentServiceRequest.requestStatusType === 'Complete' || (this.hasDeliveredOrSoldDeals && !this.isAdmin)
    },
    isAdmin () {
      return this.$store.getters['user/isInRole']('Admin Role')
    },
    isComplete: function () {
      return (this.currentServiceRequest && this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.currentServiceRequest.requestStatusTypeId)) && this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.currentServiceRequest.requestStatusTypeId).altName === 'Complete'
    }
  },
  methods: {
    lineTitleDescription: function (serviceRequestLine) {
      var lineDescription = this.$toTitleCase(serviceRequestLine.workRequired) + '\n\n'
      lineDescription += 'Vehicle Charge: ' + this.$formatCurrency(serviceRequestLine.vehicleCost) + '\n'
      lineDescription += 'Vehicle Hours: ' + this.$formatCurrency(serviceRequestLine.vehicleHours) + '\n'
      lineDescription += 'Manufacturer Cost: ' + this.$formatCurrency(serviceRequestLine.manufacturerCost) + '\n'
      lineDescription += 'Manufacturer Hours: ' + this.$formatCurrency(serviceRequestLine.manufacturerHours) + '\n'
      return lineDescription
    },
    actionTitleDescription: function (action, serviceRequestLine) {
      var lineDescription = 'Click to ' + action + ' this work item. \n\n'
      lineDescription += 'Vehicle Charge: ' + this.$formatCurrency(serviceRequestLine.vehicleCost) + '\n'
      lineDescription += 'Vehicle Hours: ' + this.$formatCurrency(serviceRequestLine.vehicleHours) + '\n'
      lineDescription += 'Manufacturer Cost: ' + this.$formatCurrency(serviceRequestLine.manufacturerCost) + '\n'
      lineDescription += 'Manufacturer Hours: ' + this.$formatCurrency(serviceRequestLine.manufacturerHours) + '\n'
      return lineDescription
    },
    loadInspectionTypesCost: function (inventoryId, inspectionTypeId) {
      return new Promise((resolve, reject) => {
        inventoryService.getApplicableInspectionTypeCosts(inventoryId, inspectionTypeId).then(response => {
          resolve(response)
        })
          .catch(error => {
            reject(error.response.data)
          })
      })
    },
    loadDefaultInspectionTypes: function () {
      if (this.currentDealer.inspectionTypes && this.currentDealer.inspectionTypes.length > 0) {
        this.currentDealer.inspectionTypes.forEach((y) => {
          var inspectionType = {
            id: 0,
            inspectionType: y.inspectionType,
            inspectionTypeAttachments: [],
            isActive: true,
            status: 'Active'
          }

          this.defaultDealerInspectionTypes.push(inspectionType)
        })
      }
    },
    onCancel: function () {
      this.onComplete()
    },
    onComplete: function () {
      this.$router.push({name: this.lastActiveListPage !== '' ? this.lastActiveListPage : 'ListServiceRequests'})
    },
    convertInspectionType: function (id) {
      return this.definedTypes.inspectionTypes.find((x) => x.id === id).name
    },
    getColorClassByServiceRequestLineStatus: function (status) {
      var result = 'has-text-grey-light'
      switch (status) {
        case 0:
          result = 'has-text-danger'
          break
        case 1:
          result = 'has-text-danger'
          break
        case 2:
          result = 'has-text-danger'
          break
        case 3:
          result = 'has-text-success'
          break
        case 4:
          result = 'has-text-success'
          break
        case 5:
          result = 'has-text-danger'
          break
        case 6:
          result = 'has-text-danger'
          break
        case 7:
          result = 'has-text-success'
          break
        case 8:
          result = 'has-text-success'
          break
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    convertIdToStatusType: function (id) {
      return this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === +id)
    },
    addNewServiceRequestLine: function () {
      this.currentServiceRequestLineId = '0'
      this.mode = 1
      this.isWorkItemDefinition = true
    },
    onEditServiceRequestLine: function (serviceRequestLine) {
      if (!serviceRequestLine.id) {
        alert('This work line item was added as a result of the newly added inspection type. Please save your changes first before editing this work line item.')
      } else {
        this.currentServiceRequestLineId = serviceRequestLine.id
        this.mode = 1
        this.isWorkItemDefinition = true
      }
    },
    onCancelServiceRequestLine: function () {
      this.currentServiceRequestLineId = '0'
      this.mode = 0
      this.isWorkItemDefinition = false
    },
    onSaveServiceRequestLine: function (line, statusId) {
      this.currentServiceRequestLineId = '0'
      line.serviceRequestLineStatusId = statusId
      this.currentServiceRequest.lines.push(line)
      this.mode = 0
      this.isWorkItemDefinition = false
      eventBus.$emit('reload-service-request-list')
    },
    onSaveEditServiceRequestLine: function (line, statusId, typeStatus) {
      var that = this
      if (this.currentServiceRequestLineId !== undefined) {
        this.currentServiceRequest.lines = this.$lodash.filter(this.currentServiceRequest.lines, function (current) {
          return current.id !== that.currentServiceRequestLineId
        })
        this.currentServiceRequestLineId = '0'
      }

      if (line.inspectionTypeId !== undefined && line.inspectionTypeId > 0) {
        if (this.currentServiceRequest.lines.find((x) => x.inspectionTypeId === line.inspectionTypeId)) {
          this.mode = 0
          this.isWorkItemDefinition = false
          eventBus.$emit('reload-service-request-list')
          return
        }
      }

      line.serviceRequestLineStatusId = statusId
      let foundLine = this.currentServiceRequest.lines.find((x) => x.workRequired === line.workRequired)
      if (typeStatus !== 'Deleted' && foundLine === undefined) {
        this.currentServiceRequest.lines.push(line)
      }
      this.mode = 0
      this.isWorkItemDefinition = false
      eventBus.$emit('reload-service-request-list')
    },
    addNewServiceRequestInspectionType: function () {
      this.currentServiceRequestInspectionTypeId = '0'
      this.mode = 2
      this.isInspectionTypeDefinition = true
    },
    onEditServiceRequestInspectionType: function (item) {
      this.currentServiceRequestInspectionTypeId = item.id
      this.mode = 2
      this.isInspectionTypeDefinition = true
    },
    onCancelServiceRequestInspectionType: function () {
      this.currentServiceRequestInspectionTypeId = '0'
      this.mode = 0
      this.isInspectionTypeDefinition = false
    },
    onSaveServiceRequestInspectionType: function (inspectionType, typeId) {
      this.currentServiceRequestInspectionTypeId = '0'
      inspectionType.serviceRequestInspectionTypeId = typeId
      this.currentServiceRequest.inspectionTypes.push(inspectionType)
      this.mode = 0
      this.isInspectionTypeDefinition = false
    },
    onSaveEditServiceRequestInspectionType: function (inspectionType, typeId) {
      var that = this
      this.currentServiceRequest.inspectionTypes = this.$lodash.filter(this.currentServiceRequest.inspectionTypes, function (current) {
        return current.id !== that.currentServiceRequestInspectionTypeId
      })
      this.currentServiceRequestInspectionTypeId = '0'
      if (inspectionType.status === 'Active' || Number.isInteger(inspectionType.id)) {
        inspectionType.inspectionType = typeId
        this.currentServiceRequest.inspectionTypes.push(inspectionType)
        this.loadInspectionTypesCost(this.currentServiceRequest.inventoryId, typeId).then((response) => {
          let inspectionTypeData = response.applicableCosts
          if (inspectionTypeData === undefined) {
            this.onSaveEditServiceRequestLine({status: 'InActive', workRequired: this.convertInspectionType(inspectionType.inspectionType), requestLineAttachments: [], inspectionTypeId: inspectionType.id, vehicleCost: 0, vehicleHours: 0, manufacturerCost: 0, manufacturerHours: 0, serviceRequestLineStatusId: 3}, 0, inspectionType.status)
          } else {
            if (inspectionType.id !== undefined && isNaN(inspectionType.id)) {
              this.currentServiceRequest.lines = this.$lodash.filter(this.currentServiceRequest.lines, function (current) {
                return current.inspectionTypeId !== inspectionType.id
              })
            }
            this.onSaveEditServiceRequestLine({status: 'InActive', workRequired: this.convertInspectionType(inspectionType.inspectionType), requestLineAttachments: [], inspectionTypeId: inspectionType.id, vehicleCost: inspectionTypeData.vehicleCost, vehicleHours: inspectionTypeData.vehicleHours, manufacturerCost: inspectionTypeData.manufacturerCost, manufacturerHours: inspectionTypeData.manufacturerHours, serviceRequestLineStatusId: 3}, 0, inspectionType.status)
            // if cost definitions add them
            if (inspectionTypeData.inspectionTypeCostDefinitions && inspectionTypeData.inspectionTypeCostDefinitions.length > 0) {
              // clear existing items
              inspectionTypeData.inspectionTypeCostDefinitions.forEach(element => {
                this.onSaveEditServiceRequestLine({status: 'InActive', workRequired: element.description, requestLineAttachments: [], inspectionTypeId: inspectionType.id, vehicleCost: element.vehicleCost, vehicleHours: element.vehicleHours, manufacturerCost: element.manufacturerCost, manufacturerHours: element.manufacturerHours, serviceRequestLineStatusId: 3}, 0, inspectionType.status)
              })
            }
          }
        })
      }
      this.mode = 0
      this.isInspectionTypeDefinition = false
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  }
}

export default mixin

<template>
  <div class="pod tile is-parent is-narrow">
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold clickable" @click="onTitleClick">{{title}} ({{total}})<i @click.stop="onMoreClick" class="fas fa-ellipsis-v icon-more"></i><i style="display: inline-block;" class="fas fa-sync" :class="{'fa-spin': isLoading}" @click.stop="onRefresh"></i>
      </h4>
      <div class="pod-content scrollbar">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th class="is-uppercase no-wrap-table-cell" :class="{'is-current-sort': sortField === column.id}" v-for="column in value.columns.filter((x) => x.visible)" :key="column.name" :style="{ 'max-width': column.maxWidth && sortField !== column.id  ? column.maxWidth : 'unset' }">
                {{column.name}}
                <span class="icon sort-icon"><i class="mdi" :class="{'mdi-arrow-up': sortDirection === 'asc', 'mdi-arrow-down': sortDirection === 'desc'}" v-if="sortField === column.id"></i></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable" v-for="(item, index) in value.data" :key="item.id" @click="onItemClick(item)">
              <td v-for="column in value.columns.filter((x) => x.visible)" :key="column.name + index.toString()" :class="getApplicableStyles(column)" :style="{ 'max-width': column.maxWidth && sortField !== column.id  ? column.maxWidth : 'unset' }">
                <template v-if="column.format && column.format === 'currency'">{{item[column.id] | currency('$', 0)}}</template>
                <template v-else-if="column.format && column.format === 'datetime'">{{item[column.id] | formatDateOnly}}</template>
                <template v-else-if="column.format && column.format === 'status'">
                  <a @click.stop.prevent="onInventoryBookDealCaptureClick($event, item)" v-if="showDealCompletedIcon({dealStatus: item.dealStatus, dealGenericStatusName: item.dealGenericStatusName, isDealBookingFinalised: item.isDealBookingFinalised})">
                    <i class="icon far fa-dollar-sign fa-lg" :class="getColorClassByDealItemStatus({dealStatus: item.dealStatus, dealGenericStatusName: item.dealGenericStatusName})" />
                    <i style="text-shadow: 0 0 3px #000; margin-left: -1.25rem;" class="grid-icon fa-fw fal fa-check fa-lg has-text-white" v-tooltip="getDealStatusTitleForView(item.dealStatus, { promiseDate: item.dealPromiseDate, soldAt: item.dealSoldAt, salesPersonName: item.dealSalesPersonName, customerName: item.customerPersonName, status: item.dealGenericStatusName}, false)"/>
                  </a>
                  <a @click.stop.prevent="onInventoryBookDealCaptureClick($event, item)" v-else><i class="icon far fa-dollar-sign fa-lg" :class="getColorClassByDealItemStatus({dealStatus: item.dealStatus, dealGenericStatusName: item.dealGenericStatusName})" v-tooltip="getDealStatusTitleForView(item.dealStatus, { promiseDate: item.dealPromiseDate, soldAt: item.dealSoldAt, salesPersonName: item.dealSalesPersonName, customerName: item.customerPersonName, status: item.dealGenericStatusName}, false)"/></a>
                  <i class="icon fal fa-arrow-circle-right fa-lg list-icon fa-fw" :class="getColorClassByLoanerStatus(item.loanerStatusType)" title="Loaner Status" v-if="currentDealer.trackLoanerInfo === true"/>
                  <i class="icon fal fa-exclamation-circle fa-lg list-icon fa-fw" :class="getColorClassByDetailsStatus(item.detailsStatusType)" :title="'Accessory Details: ' + item.detailsStatus" v-if="currentDealer.trackVehicleAccessories === true"/>
                </template>
                <template v-else-if="column.format && column.format === 'age'">
                  <p style="width:35px"><i class="fal fa-hourglass-end fa-sm aging-icon" :style="getDolAgingStyle(item, true)"/>{{ item.daysOnLotDisplay.replace('Pending', '') }}</p>
                </template>
                <template v-else-if="column.format && column.format === 'vin'">
                  {{formatVin(item[column.id])}}
                </template>
                <template v-else>{{item[column.id]}}</template>
              </td>
            </tr>
            <tr v-if="this.value.data.length === 0" class="subtitle is-7">
              <td style="text-align:center; height:100px;vertical-align:middle;" :colspan="value.columns.length">No data available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
    <portal to="global-modal-portal" v-if="bookDealCaptureActive">
      <b-modal :active.sync="bookDealCaptureActive" scroll="keep" :has-modal-card="true" v-if="listInventory" :canCancel="['escape', 'x']" class="modal-fullscreen">
        <book-deal-capture
          v-model="currentDeal" :bookDealCaptureActive.sync="bookDealCaptureActive" :progressStatus="false"
          :isReadOnly="bookedLocked || isCancelledDeal" :statusProgressionWizardActive="false" :keepLocked="true">
        </book-deal-capture>
      </b-modal>
    </portal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import formattingMixin from '@/mixins/inventory/formatting'
import BookDealCapture from '@/components/generic/sales/BookDealCapture'
import dealService from '@/services/dealService'
import eventBus from '@/eventBus'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
const { mapGetters } = createNamespacedHelpers('user')
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'InventoryGridPod',
  mixins: [formattingMixin, dealInventoryStyleClasses],
  components: {
    'book-deal-capture': BookDealCapture
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'Grid Pod'
    },
    sortField: {
      type: String,
      default: ''
    },
    sortDirection: {
      type: String,
      default: 'asc'
    },
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      id: this.$uuid.v4(),
      active: true,
      bookDealCaptureActive: false,
      currentDeal: null,
      listInventory: null
    }
  },
  computed: {
    ...mapGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    total: function () {
      if (this.value && this.value.data) {
        return this.value.data.length
      }
      return 0
    },
    bookedLocked: function () {
      return this.listInventory && this.listInventory.dealStatus === '3'
    },
    isCancelledDeal: function () {
      return this.listInventory && this.listInventory.dealStatus === '4'
    }
  },
  methods: {
    formatVin: function (vinNo) {
      return vinNo.substring(vinNo.length - 6)
    },
    getApplicableStyles: function (columnDefinition) {
      var result = ''
      if (columnDefinition.format && columnDefinition.format === 'status') {
        result = 'no-wrap-table-cell'
      }
      let stylesToAttach = {}
      stylesToAttach[result] = true
      return stylesToAttach
    },
    onDealClick: function (item) {
      if (item.dealId && item.dealId !== -1 && this.hasFeatureAccess('deal.view')) {
        this.$router.push({name: 'DealsQuickView', params: { id: item.dealId }})
      }
    },
    onMoreClick: function () {

    },
    onRefresh: function () {
      this.$emit('on-refresh')
    },
    onItemClick: function (item) {
      this.$emit('on-item-click', item)
    },
    onTitleClick: function () {
      this.$emit('on-header-click')
    },
    onInventoryBookDealCapture: function (inventoryItem, showBookingWizard) {
      if (+inventoryItem.dealId !== -1 && this.hasFeatureAccess('deal.view') && showBookingWizard === true) {
        eventBus.$emit('close-global-quick-view', {})
        let that = this
        dealService.single(inventoryItem.dealId).then(response => {
          that.currentDeal = response.deal
          that.listInventory = inventoryItem
          that.bookDealCaptureActive = true
        }).catch((error) => {
          that.failedToast('Oops! Something went wrong')
          that.serverErrorMessage = error.message
          that.loading = false
        })
      } else {
        this.onDealClick(inventoryItem)
      }
    },
    onInventoryBookDealCaptureClick: function (ev, inventoryItem) {
      this.onInventoryBookDealCapture(inventoryItem, ev.ctrlKey)
    }
  },
  watch: {
    bookDealCaptureActive: function (newValue, oldValue) {
      if (!newValue) {
        this.currentDeal = null
        this.listInventory = null
      }
    }
  }
}
</script>

<style scoped>
  .no-wrap-table-cell {
    white-space: nowrap;
  }

  .icon {
    width: 1.0rem !important;
  }

  .sort-icon {
    height:auto !important;
  }

  .is-current-sort {
    color: rgb(0, 177, 31) !important;
  }

  .list-icon {
    margin-right: 0px;
  }

</style>

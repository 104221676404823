<template>
<div :class="getContainerClass">
  <div v-if="label !== ' '">
    <h3>{{ label }}</h3>
    <br />
  </div>
  <div class="columns is-multiline">
    <div class="column is-12" v-if="!readOnly">
      <div class="field">
        <label class="label">Address Lookup</label>
        <div class="control">
          <vue-google-autocomplete ref="address" :id="instanceName + 'map'" classname="input" placeholder="Type to fill in address" v-on:placechanged="getAddressData" :enable-geolocation="true" autocomplete="disabled"></vue-google-autocomplete>
        </div>
      </div>
    </div>
  </div>
  <div v-if="isCustomer">
    <div class="address-capture">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Address Line 1<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.address1'" ref="address1" data-vv-as="address line 1" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'address1') }" type="text" placeholder="" v-model="internalValue.address1" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'address1')" class="help is-danger"><i v-show="errors.has(validationName + 'address1')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'address1') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Address Line 2</label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.address2'" ref="address2" data-vv-as="address line 2" :class="{'input': true, 'is-danger': errors.has(validationName + 'address2') }" type="text" placeholder="" v-model="internalValue.address2" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'address2')" class="help is-danger"><i v-show="errors.has(validationName + 'address2')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'address2') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6" v-show="false">
          <div class="field">
            <label class="label">{{type}}Address Line 3</label>
            <div class="control">
              <input name="address3" ref="address3" :class="{'input': true, 'is-danger': errors.has('address3') }" type="text" placeholder="" v-model="internalValue.address3" :readonly="readOnly">
            </div>
          </div>
        </div>
        <div class="column is-6" v-show="false">
          <div class="field">
            <label class="label">{{type}}Address Line 4</label>
            <div class="control">
              <input name="address4" ref="address4" :class="{'input': true, 'is-danger': errors.has('address4') }" type="text" placeholder="" v-model="internalValue.address4" :readonly="readOnly">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}City<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.city'" ref="city" data-vv-as="city" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'city') }" type="text" placeholder="" v-model="internalValue.city" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'city')" class="help is-danger"><i v-show="errors.has(validationName + 'city')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'city') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}State<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.state'" ref="state" data-vv-as="state" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'state') }" type="text" placeholder="" v-model="internalValue.state" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'state')" class="help is-danger"><i v-show="errors.has(validationName + 'state')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'state') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Country<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.country'" ref="country" data-vv-as="country" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'country') }" type="text" placeholder="" v-model="internalValue.country" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'country')" class="help is-danger"><i v-show="errors.has(validationName + 'country')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'country') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Zip Code<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.postalCode'" ref="postalCode" data-vv-as="code" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has('postalCode') }" type="text" placeholder="" v-model="internalValue.postalCode" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'postalCode')" class="help is-danger"><i v-show="errors.has(validationName + 'postalCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'postalCode') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}GPS Address</label>
            <div class="control">
              <input name="gpsAddress" ref="gpsAddress" :class="{'input': true, 'is-danger': errors.has('gpsAddress') }" type="text" placeholder="" v-model="internalValue.gpsAddress" :readonly="readOnly">
            </div>
          </div>
        </div>
      </div>
      <div class="columns" v-if='isAccessPrivateInfo'>
        <div class="column is-6">
          <div class="field">
            <label class="label">Years (Living at this address)</label>
            <div class="control">
              <input name="years" data-vv-as="years" :class="{'input': true, 'is-danger': errors.has('years') }" type="number" placeholder="#####" v-model="internalValue.years">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Cost per month?</label>
            <div class="control has-icons-left">
              <money name="rate" data-vv-as="rate" v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('rate') }" type="text" placeholder="0.00" v-model="internalValue.rate" v-bind="$globalMoneyFormat"></money>
              <span v-show="errors.has('rate')" class="help is-danger"><i v-show="errors.has('rate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rate') }}</span>
              <span class="icon is-small is-left">
                <i class="fas fa-dollar-sign"></i>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline" v-if='isAccessPrivateInfo'>
        <div class="column is-6">
          <div class="field">
            <label class="label">Months (Living at this address)</label>
            <div class="control">
              <input name="months" data-vv-as="months" :class="{'input': true, 'is-danger': errors.has('months') }" type="number" placeholder="#####" v-model="internalValue.months">
            </div>
          </div>
        </div>
        <div class="column is-4">
          <button-list-selector :scope="scope" :useValidator="useValidator" validateAs="ownership" label="Do you Own or Rent?" :required="required" v-model="selectedOwnershipType" :availableValues="ownershipTypes"></button-list-selector>
        </div>
        <div class="column is-narrow" style="padding-top: 50px;">
          <div class="field">
            <div class="control has-icons-left">
              <b-checkbox
                :native-value="false"
                name="isPrimary"
                type="is-info"
                v-model="internalValue.isPrimary">
                Is Primary Address?
              </b-checkbox>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isWorkHistory">
    <div class="address-capture">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label class="label">Address Line 1</label>
            <div class="control">
              <input name="address1" ref="address1" :class="{'input': true, 'is-danger': errors.has('address1') }" type="text" placeholder="" v-model="internalValue.address1">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Address Line 2</label>
            <div class="control">
              <input name="address2" ref="address2" :class="{'input': true, 'is-danger': errors.has('address2') }" type="text" placeholder="" v-model="internalValue.address2">
            </div>
          </div>
        </div>
        <div class="column is-6" v-show="false">
          <div class="field">
            <label class="label">Address Line 3</label>
            <div class="control">
              <input name="address3" ref="address3" :class="{'input': true, 'is-danger': errors.has('address3') }" type="text" placeholder="" v-model="internalValue.address3">
            </div>
          </div>
        </div>
        <div class="column is-6" v-show="false">
          <div class="field">
            <label class="label">Address Line 4</label>
            <div class="control">
              <input name="address4" ref="address4" :class="{'input': true, 'is-danger': errors.has('address4') }" type="text" placeholder="" v-model="internalValue.address4">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">City</label>
            <div class="control">
              <input name="city" ref="city" :class="{'input': true, 'is-danger': errors.has('city') }" type="text" placeholder="" v-model="internalValue.city">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">State</label>
            <div class="control">
              <input name="state" ref="state" :class="{'input': true, 'is-danger': errors.has('state') }" type="text" placeholder="" v-model="internalValue.state">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Country</label>
            <div class="control">
              <input name="country" ref="country" :class="{'input': true, 'is-danger': errors.has('country') }" type="text" placeholder="" v-model="internalValue.country">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Zip Code</label>
            <div class="control">
              <input name="postalCode" ref="postalCode" :class="{'input': true, 'is-danger': errors.has('postalCode') }" type="text" placeholder="" v-model="internalValue.postalCode">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">GPS Address</label>
            <div class="control">
              <input name="gpsAddress" ref="gpsAddress" :class="{'input': true, 'is-danger': errors.has('gpsAddress') }" type="text" placeholder="" v-model="internalValue.gpsAddress">
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label class="label">Employer</label>
            <div class="control">
              <input name="employer" data-vv-as="employer" :class="{'input': true, 'is-danger': errors.has('employer') }" type="text" placeholder="" v-model="internalValue.employer">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Job Title</label>
            <div class="control">
              <input name="jobTitle" data-vv-as="job title" maxlength="26" :class="{'input': true, 'is-danger': errors.has('jobTitle') }" type="text" placeholder="" v-model="internalValue.jobTitle">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Employer Office No.</label>
            <div class="control">
              <input name="employerOffice" data-vv-as="employer office no." :class="{'input': true, 'is-danger': errors.has('employerOffice') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'"  v-model="internalValue.employerOffice" data-lpignore="true" autocomplete="off">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Monthly Income</label>
            <div class="control has-icons-left">
              <money name="grossIncome" data-vv-as="monthly income" v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('grossIncome') }" type="text" placeholder="0.00" v-model="internalValue.grossIncome" v-bind="$globalMoneyFormat"></money>
              <span v-show="errors.has('grossIncome')" class="help is-danger"><i v-show="errors.has('grossIncome')" class="fas fa-exclamation-triangle"></i> {{ errors.first('grossIncome') }}</span>
              <span class="icon is-small is-left">
                <i class="fas fa-dollar-sign"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Other Income Source</label>
            <div class="control">
              <input name="otherIncomeSource" data-vv-as="other income source" :class="{'input': true, 'is-danger': errors.has('otherIncomeSource') }" type="text" placeholder="" v-model="internalValue.otherIncomeSource">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Other Income Total</label>
            <div class="control has-icons-left">
              <money name="otherIncome" data-vv-as="other income" v-validate="'decimal'" :class="{'input': true, 'is-danger': errors.has('otherIncome') }" type="text" placeholder="0.00" v-model="internalValue.otherIncome" v-bind="$globalMoneyFormat"></money>
              <span v-show="errors.has('otherIncome')" class="help is-danger"><i v-show="errors.has('otherIncome')" class="fas fa-exclamation-triangle"></i> {{ errors.first('otherIncome') }}</span>
              <span class="icon is-small is-left">
                <i class="fas fa-dollar-sign"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Years (Employed)</label>
            <div class="control">
              <input name="years" data-vv-as="years" :class="{'input': true, 'is-danger': errors.has('years') }" type="number" placeholder="#####" v-model="internalValue.years">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Months (Employed)</label>
            <div class="control">
              <input name="months" data-vv-as="months" :class="{'input': true, 'is-danger': errors.has('months') }" type="number" placeholder="#####" v-model="internalValue.months">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isContact">
    <div class="address-capture">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label class="label">First Name</label>
            <div class="control">
              <input name="firstName" ref="firstName" :class="{'input': true, 'is-danger': errors.has('firstName') }" type="text" placeholder="" v-model="internalValue.firstName">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Last Name</label>
            <div class="control">
              <input name="lastName" ref="lastName" :class="{'input': true, 'is-danger': errors.has('lastName') }" type="text" placeholder="" v-model="internalValue.lastName">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Relationship</label>
            <div class="control">
              <input name="relationship" ref="relationship" :class="{'input': true, 'is-danger': errors.has('relationship') }" type="text" placeholder="" v-model="internalValue.relationship">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Phone Number</label>
            <div class="control">
              <input name="phoneNumber" ref="phoneNumber" :class="{'input': true, 'is-danger': errors.has('phoneNumber') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="internalValue.phoneNumber" data-lpignore="true" autocomplete="off">
            </div>
          </div>
        </div>
      </div>
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label class="label">Address Line 1</label>
            <div class="control">
              <input name="address1" ref="address1" :class="{'input': true, 'is-danger': errors.has('address1') }" type="text" placeholder="" v-model="internalValue.address1">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Address Line 2</label>
            <div class="control">
              <input name="address2" ref="address2" :class="{'input': true, 'is-danger': errors.has('address2') }" type="text" placeholder="" v-model="internalValue.address2">
            </div>
          </div>
        </div>
        <div class="column is-6" v-show="false">
          <div class="field">
            <label class="label">Address Line 3</label>
            <div class="control">
              <input name="address3" ref="address3" :class="{'input': true, 'is-danger': errors.has('address3') }" type="text" placeholder="" v-model="internalValue.address3">
            </div>
          </div>
        </div>
        <div class="column is-6" v-show="false">
          <div class="field">
            <label class="label">Address Line 4</label>
            <div class="control">
              <input name="address4" ref="address4" :class="{'input': true, 'is-danger': errors.has('address4') }" type="text" placeholder="" v-model="internalValue.address4">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">City</label>
            <div class="control">
              <input name="city" ref="city" :class="{'input': true, 'is-danger': errors.has('city') }" type="text" placeholder="" v-model="internalValue.city">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">State</label>
            <div class="control">
              <input name="state" ref="state" :class="{'input': true, 'is-danger': errors.has('state') }" type="text" placeholder="" v-model="internalValue.state">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Country</label>
            <div class="control">
              <input name="country" ref="country" :class="{'input': true, 'is-danger': errors.has('country') }" type="text" placeholder="" v-model="internalValue.country">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Zip Code</label>
            <div class="control">
              <input name="postalCode" ref="postalCode" :class="{'input': true, 'is-danger': errors.has('postalCode') }" type="text" placeholder="" v-model="internalValue.postalCode">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">GPS Address</label>
            <div class="control">
              <input name="gpsAddress" ref="gpsAddress" :class="{'input': true, 'is-danger': errors.has('gpsAddress') }" type="text" placeholder="" v-model="internalValue.gpsAddress">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isBank">
    <div class="address-capture">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Address Line 1<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.address1'" ref="address1" data-vv-as="address line 1" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'address1') }" type="text" placeholder="" v-model="internalValue.address1">
              <span v-show="errors.has(validationName + 'address1')" class="help is-danger"><i v-show="errors.has(validationName + 'address1')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'address1') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Address Line 2</label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.address2'" ref="address2" data-vv-as="address line 2" :class="{'input': true, 'is-danger': errors.has(validationName + 'address2') }" type="text" placeholder="" v-model="internalValue.address2">
              <span v-show="errors.has(validationName + 'address2')" class="help is-danger"><i v-show="errors.has(validationName + 'address2')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'address2') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6" v-show="false">
          <div class="field">
            <label class="label">{{type}}Address Line 3</label>
            <div class="control">
              <input name="address3" ref="address3" :class="{'input': true, 'is-danger': errors.has('address3') }" type="text" placeholder="" v-model="internalValue.address3">
            </div>
          </div>
        </div>
        <div class="column is-6" v-show="false">
          <div class="field">
            <label class="label">{{type}}Address Line 4</label>
            <div class="control">
              <input name="address4" ref="address4" :class="{'input': true, 'is-danger': errors.has('address4') }" type="text" placeholder="" v-model="internalValue.address4">
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}City<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.city'" ref="city" data-vv-as="city" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'city') }" type="text" placeholder="" v-model="internalValue.city">
              <span v-show="errors.has(validationName + 'city')" class="help is-danger"><i v-show="errors.has(validationName + 'city')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'city') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}State<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.state'" ref="state" data-vv-as="state" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'state') }" type="text" placeholder="" v-model="internalValue.state">
              <span v-show="errors.has(validationName + 'state')" class="help is-danger"><i v-show="errors.has(validationName + 'state')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'state') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Country<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.country'" ref="country" data-vv-as="country" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'country') }" type="text" placeholder="" v-model="internalValue.country">
              <span v-show="errors.has(validationName + 'country')" class="help is-danger"><i v-show="errors.has(validationName + 'country')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'country') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Zip Code<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.postalCode'" ref="postalCode" data-vv-as="code" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has('postalCode') }" type="text" placeholder="" v-model="internalValue.postalCode">
              <span v-show="errors.has(validationName + 'postalCode')" class="help is-danger"><i v-show="errors.has(validationName + 'postalCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'postalCode') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}GPS Address</label>
            <div class="control">
              <input name="gpsAddress" ref="gpsAddress" :class="{'input': true, 'is-danger': errors.has('gpsAddress') }" type="text" placeholder="" v-model="internalValue.gpsAddress">
            </div>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column is-6">
           <button-list-selector :enabled="!forPaymentRequest" validateAs="address type" label="Address Type?" :required="false" v-model="selectedAddressType" :availableValues="addressTypes"></button-list-selector>
        </div>
      </div>
    </div>
  </div>
  <div v-else-if="isRoCustomer">
    <div class="address-capture">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Address Line 1<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.address1'" ref="address1" data-vv-as="address line 1" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'address1') }" type="text" placeholder="" v-model="internalValue.address1" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'address1')" class="help is-danger"><i v-show="errors.has(validationName + 'address1')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'address1') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}City<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.city'" ref="city" data-vv-as="city" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'city') }" type="text" placeholder="" v-model="internalValue.city" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'city')" class="help is-danger"><i v-show="errors.has(validationName + 'city')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'city') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}State<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.state'" ref="state" data-vv-as="state" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'state') }" type="text" placeholder="" v-model="internalValue.state" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'state')" class="help is-danger"><i v-show="errors.has(validationName + 'state')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'state') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">{{type}}Zip Code<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
            <div class="control">
              <input :data-vv-scope="scope" :name="instanceName + '.postalCode'" ref="postalCode" data-vv-as="code" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has('postalCode') }" type="text" placeholder="" v-model="internalValue.postalCode" :readonly="readOnly">
              <span v-show="errors.has(validationName + 'postalCode')" class="help is-danger"><i v-show="errors.has(validationName + 'postalCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'postalCode') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="address-capture" v-else>
    <div class="columns is-multiline">
      <div class="column is-6">
        <div class="field">
          <label class="label">{{type}}Address Line 1<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
          <div class="control">
            <input :data-vv-scope="scope" :name="instanceName + '.address1'" ref="address1" data-vv-as="address line 1" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'address1') }" type="text" placeholder="" v-model="internalValue.address1" :readonly="readOnly">
            <span v-show="errors.has(validationName + 'address1')" class="help is-danger"><i v-show="errors.has(validationName + 'address1')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'address1') }}</span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">{{type}}Address Line 2</label>
          <div class="control">
            <input :data-vv-scope="scope" :name="instanceName + '.address2'" ref="address2" data-vv-as="address line 2" :class="{'input': true, 'is-danger': errors.has(validationName + 'address2') }" type="text" placeholder="" v-model="internalValue.address2" :readonly="readOnly">
            <span v-show="errors.has(validationName + 'address2')" class="help is-danger"><i v-show="errors.has(validationName + 'address2')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'address2') }}</span>
          </div>
        </div>
      </div>
      <div class="column is-6" v-show="false">
        <div class="field">
          <label class="label">{{type}}Address Line 3</label>
          <div class="control">
            <input name="address3" ref="address3" :class="{'input': true, 'is-danger': errors.has('address3') }" type="text" placeholder="" v-model="internalValue.address3" :readonly="readOnly">
          </div>
        </div>
      </div>
      <div class="column is-6" v-show="false">
        <div class="field">
          <label class="label">{{type}}Address Line 4</label>
          <div class="control">
            <input name="address4" ref="address4" :class="{'input': true, 'is-danger': errors.has('address4') }" type="text" placeholder="" v-model="internalValue.address4" :readonly="readOnly">
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">{{type}}City<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
          <div class="control">
            <input :data-vv-scope="scope" :name="instanceName + '.city'" ref="city" data-vv-as="city" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'city') }" type="text" placeholder="" v-model="internalValue.city" :readonly="readOnly">
            <span v-show="errors.has(validationName + 'city')" class="help is-danger"><i v-show="errors.has(validationName + 'city')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'city') }}</span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">{{type}}State<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
          <div class="control">
            <input :data-vv-scope="scope" :name="instanceName + '.state'" ref="state" data-vv-as="state" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'state') }" type="text" placeholder="" v-model="internalValue.state" :readonly="readOnly">
            <span v-show="errors.has(validationName + 'state')" class="help is-danger"><i v-show="errors.has(validationName + 'state')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'state') }}</span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">{{type}}Country<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
          <div class="control">
            <input :data-vv-scope="scope" :name="instanceName + '.country'" ref="country" data-vv-as="country" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has(validationName + 'country') }" type="text" placeholder="" v-model="internalValue.country" :readonly="readOnly">
            <span v-show="errors.has(validationName + 'country')" class="help is-danger"><i v-show="errors.has(validationName + 'country')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'country') }}</span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">{{type}}Zip Code<b-icon pack="fas" :icon="required ? 'star' : ''" class="fa-ss"></b-icon></label>
          <div class="control">
            <input :data-vv-scope="scope" :name="instanceName + '.postalCode'" ref="postalCode" data-vv-as="code" v-validate="{'required': required}" :class="{'input': true, 'is-danger': errors.has('postalCode') }" type="text" placeholder="" v-model="internalValue.postalCode" :readonly="readOnly">
            <span v-show="errors.has(validationName + 'postalCode')" class="help is-danger"><i v-show="errors.has(validationName + 'postalCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName + 'postalCode') }}</span>
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">{{type}}GPS Address</label>
          <div class="control">
            <input name="gpsAddress" ref="gpsAddress" :class="{'input': true, 'is-danger': errors.has('gpsAddress') }" type="text" placeholder="" v-model="internalValue.gpsAddress" :readonly="readOnly">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>

import VueGoogleAutocomplete from 'vue-google-autocomplete'
import ButtonListSelector from '@/components/generic/ButtonListSelector'

export default {
  name: 'AddressCapture',
  components: {
    'vue-google-autocomplete': VueGoogleAutocomplete,
    'button-list-selector': ButtonListSelector
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ' '
    },
    addressType: {
      type: String,
      default: ''
    },
    box: {
      type: Boolean,
      default: true
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    isCustomer: {
      type: Boolean,
      default: false
    },
    isRoCustomer: {
      type: Boolean,
      default: false
    },
    isWorkHistory: {
      type: Boolean,
      default: false
    },
    isContact: {
      type: Boolean,
      default: false
    },
    isBank: {
      type: Boolean,
      default: false
    },
    forPaymentRequest: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    },
    useBoxClass: {
      type: Boolean,
      default: true
    },
    isAccessPrivateInfo: {
      type: Boolean,
      default: false
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      internalValue: null,
      selectedOwnershipType: null,
      selectedAddressType: null,
      ownershipTypes: [
        {
          id: 0,
          name: 'Own'
        },
        {
          id: 1,
          name: 'Rent'
        },
        {
          id: 2,
          name: 'NotApplicable'
        }
      ],
      addressTypes: [
        {
          id: 0,
          name: 'Business',
          altName: 'Business'
        },
        {
          id: 1,
          name: 'Lease Payoff',
          altName: 'LeasePayoff'
        }
      ]
    }
  },
  methods: {
    getAddressData: function (addressData, place, id) {
      let addressComponents = place.address_components
      this.internalValue.address1 = ''
      this.internalValue.address2 = ''
      this.internalValue.address3 = ''
      this.internalValue.address4 = ''
      this.internalValue.postalCode = ''
      this.internalValue.state = ''
      if ('stateCode' in this.internalValue) {
        this.internalValue.stateCode = ''
      }
      this.internalValue.city = ''
      this.internalValue.gpsAddress = ''

      for (var i = 0; i < addressComponents.length; i++) {
        var addressType = addressComponents[i].types[0]
        if (addressType === 'street_number') {
          var streetNumber = addressComponents[i]['short_name']
          this.internalValue.address1 = streetNumber
        }

        if (addressType === 'route') {
          var streetName = addressComponents[i]['short_name']
          this.internalValue.address1 += ' ' + streetName
        }

        if (addressType === 'neighborhood') {
          const vicinity = addressComponents[i].short_name
          this.internalValue.address2 = vicinity
        }

        if (addressType === 'locality') {
          var city = addressComponents[i]['short_name']
          this.internalValue.city = city
        }

        if (addressType === 'administrative_area_level_1') {
          var state = addressComponents[i]['long_name']
          this.internalValue.state = state
          if ('stateCode' in this.internalValue) {
            var stateCode = addressComponents[i]['short_name']
            this.internalValue.stateCode = stateCode
          }
        }

        if (addressType === 'country') {
          var country = addressComponents[i]['long_name']
          this.internalValue.country = country
        }

        if (addressType === 'postal_code') {
          var postalCode = addressComponents[i]['short_name']
          this.internalValue.postalCode = postalCode
        }
      }

      if (addressData.latitude && addressData.longitude) {
        this.internalValue.gpsAddress = addressData.latitude + ',' + addressData.longitude
      }
      this.$emit('input', this.internalValue)
    },
    initValues: function () {
      this.selectedOwnershipType = this.value.ownershipType ? this.ownershipTypes.find((x) => (x.name === this.value.ownershipType || x.altName === this.value.ownershipType)) : this.ownershipTypes.find((x) => x.name === 'Own')
      this.selectedAddressType = this.value.addressType ? this.addressTypes.find((x) => (x.name === this.value.addressType || x.altName === this.value.addressType)) : this.addressTypes.find((x) => x.altName === 'Business')
    }
  },
  watch: {
    selectedOwnershipType: function (newValue, oldValue) {
      this.internalValue.ownershipType = newValue
      this.$emit('input', this.internalValue)
    },
    selectedAddressType: function (newValue, oldValue) {
      this.internalValue.addressType = newValue
      this.$emit('input', this.internalValue)
    },
    value: function (newValue, oldValue) {
      this.internalValue = newValue
    },
    'internalValue.isPrimary': function (newValue, oldValue) {
      if (oldValue) {
        this.$emit('primary-address-set', this.internalValue)
      }
      if (newValue) {
        this.$emit('unselect-other', this.internalValue)
      }
    }
  },
  computed: {
    type: function () {
      if (this.addressType !== '') {
        return this.addressType + ' '
      }
      return ''
    },
    validationName: function () {
      return (this.scope) ? this.scope + '.' + this.instanceName + '.' : this.instanceName + '.'
    },
    getContainerClass: function () {
      return (this.useBoxClass) ? 'box' : ''
    }
  },
  mounted: function () {
    if (this.isCustomer || this.isBank) {
      this.initValues()
    }
    let that = this
    setTimeout(function () {
      if (that.$refs && that.$refs.address) {
        that.$refs.address.$el.setAttribute('autocomplete', 'disabled')
      }
    }, 250)
  },
  created: function () {
    this.internalValue = this.value
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}
</script>

<style scoped>
  .flat-table {
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 0.8rem;
    overflow: auto;
    width: 100%;
    border-collapse:separate;
    border-radius: 6px;
    -moz-border-radius:6px;
    -webkit-border-radius: 6px;
  }

  .flat-table th {
    background-color: #41b883;
    color: white;
    font-weight: normal;
    padding: 10px 15px;
  }

  .flat-table td {
    background-color: rgb(238, 238, 238);
    color: rgb(111, 111, 111);
    padding: 10px 15px;
  }

  .flat-table th:first-child {
    border-radius: 6px 0 0 0;
  }

  .flat-table th:last-child {
    border-radius: 0 6px 0 0;
  }

  .flat-table tr:last-child td:first-child {
    border-radius: 0 0 0 6px;
  }

  .flat-table tr:last-child td:last-child {
    border-radius: 0 0 6px 0;
  }

  .box {
    background-color: #f6f8fa;
  }

</style>

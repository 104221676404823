import mutationTypes from '../mutation-types'
import vehicleMakeService from '../../services/vehicleMakeService'

const state = {
  allVehicleMakes: []
}

const getters = {
  allVehicleMakes: state => {
    return state.allVehicleMakes
  }
}

const mutations = {
  [mutationTypes.SYNC_VEHICLE_MAKE] (state, vehicleMake) {
    var applicable = state.allVehicleMakes.find((x) => x.id === vehicleMake.id)
    if (applicable) {
      applicable = vehicleMake
    } else {
      state.allVehicleMakes.push(vehicleMake)
    }
  },
  [mutationTypes.SET_VEHICLE_MAKES] (state, vehicleMakes) {
    state.allVehicleMakes = vehicleMakes
  }
}

const actions = {
  syncVehicleMake ({commit, state}, vehicleMake) {
    commit(mutationTypes.SYNC_VEHICLE_MAKE, vehicleMake)
  },
  setVehicleMakes ({commit, state}) {
    commit(mutationTypes.SET_VEHICLE_MAKES, [])
    vehicleMakeService.allVehicleMakes()
      .then((data) => {
        commit(mutationTypes.SET_VEHICLE_MAKES, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

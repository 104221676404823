<template>
  <div class="is-inline-block">
    <span v-if="hasFiles && !isViewerModalActive">
        <div>
            <span class="icon is-small clickable" @click="onOpenFileViewer" :title="iconTitle">
                <i class="fal fa-lg" :class="icon"></i>
            </span>
        </div>
    </span>
    <portal to="global-modal-portal" v-else>
      <b-modal :active.sync="isViewerModalActive" :width="640" scroll="keep" :has-modal-card="true" v-if="hasFiles" :canCancel="false">
        <div class="modal-card" style="width: auto" id="crop-card">
          <header class="modal-card-head">
            <p class="modal-card-title">{{modalTitle}}</p>
          </header>
          <section class="modal-card-body">
            <div class="columns">
              <div class="column">
                <div class="box">
                    <h3 class="title is-6">Files</h3>
                    <div class="columns is-multiline">
                    <div class="column is-12" v-if="hasFiles">
                        <file-selector
                        v-model="files"
                        :enabled="false"
                        :multiple="true"
                        :showFileOnly="true"
                        :isBoxed="false"
                        :usePortalViewer="false">
                        </file-selector>
                    </div>
                    <div class="column is-12" v-else>
                        <label class="label">No files available.</label>
                    </div>
                    </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-danger" @click="closeFileViewer">Close</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>
import utilitiesMixin from '@/mixins/generic/utilities'
import FileSelector from '@/components/generic/FileSelector'

export default {
  name: 'FileViewer',
  mixins: [utilitiesMixin],
  components: {
    'file-selector': FileSelector
  },
  props: {
    size: {
      type: String,
      default: 'is-width-600'
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    iconTitle: {
      type: String,
      default: ''
    },
    modalTitle: {
      type: String,
      default: ''
    },
    files: {
      type: Array,
      default: null
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isLoading: false,
      isViewerModalActive: false
    }
  },
  computed: {
    hasFiles: function () {
      if (this.files && this.files.length > 0) {
        return true
      }
      return false
    }
  },
  methods: {
    onOpenFileViewer: function () {
      this.isViewerModalActive = true
    },
    closeFileViewer: function () {
      this.isViewerModalActive = false
    }
  },
  mounted: function () {
    if (this.showDialog === true) {
      this.onOpenFileViewer()
    }
  },
  updated: function () {
  },
  watch: {
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  .modal-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }

  #crop-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  #crop-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }
</style>

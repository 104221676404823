import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/teams',
    name: 'ListTeams',
    component: () => import('@/components/configuration/teams/ListTeams'),
    beforeEnter: requiresAuth
  },
  {
    name: 'TeamsQuickView',
    path: '/configuration/teams/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/teams/ViewTeam')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/teams/new',
    name: 'NewTeam',
    component: () => import('@/components/configuration/teams/NewTeam'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/teams/view/:id',
    name: 'ViewTeam',
    component: () => import('@/components/configuration/teams/ViewTeam'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/teams/edit/:id',
    name: 'EditTeam',
    component: () => import('@/components/configuration/teams/EditTeam'),
    beforeEnter: requiresAuth
  }
]

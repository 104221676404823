<template>
  <div class="control is-clearfix" size="is-small">
    <b-field class="is-flex">
      <b-checkbox-button v-for="status in availableTypes" v-model="internalSelectedLeadTypes" size="is-small" :native-value="status.id" type="is-success" :key="status.id"><span>{{status.name}}</span></b-checkbox-button>
    </b-field>
  </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'LeadTypeSelector',
  props: {
    selectedLeadTypes: {
      type: Array,
      default: () => []
    },
    small: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceName: 'lead-type-select' + this.$uuid.v4(),
      internalSelectedLeadTypes: []
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    availableTypes: function () {
      return this.definedTypes.leadType.options
    },
    selectorSize: function () {
      return this.small ? 'small' : 'large'
    }
  },
  watch: {
    internalSelectedLeadTypes: function (newValue, oldValue) {
      this.onSelectionChanged(newValue)
    },
    selectedLeadTypes: function (newValue, oldValue) {
      this.internalSelectedLeadTypes = this.selectedLeadTypes
    }
  },
  mounted: function () {
    this.internalSelectedLeadTypes = this.selectedLeadTypes
  },
  methods: {
    onSelectionChanged: function (newValue) {
      this.$emit('selection-changed', newValue)
    }
  }
}

</script>

<style scoped>
</style>

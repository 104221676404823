<template>
  <div class="field-container clickable is-unselectable is-primary" title="Click to rotate" @click="onRotate" v-if="this.value.length > 0">
    <div class="field">
      <label class="label">{{value[activeIndex].label}}</label>
      <div class="field">
        <span v-if="value[activeIndex].valueStyle" class="is-inline-block" :class="value[activeIndex].valueStyle">{{value[activeIndex].value}}</span>
        <span v-else-if="isWarrantyDate" class="is-inline-block">
          <p v-if="(value[activeIndex].daysWarrantyExpiration === null || value[activeIndex].daysWarrantyExpiration === undefined) || value[activeIndex].daysWarrantyExpiration < 0" v-tooltip="buildWarrantyExpirationTitle(value[activeIndex])">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{value[activeIndex].value}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p>
          <p v-else v-tooltip="buildWarrantyExpirationTitle(value[activeIndex])">{{value[activeIndex].value}}</p>
        </span>
        <span v-else class="is-inline-block">{{value[activeIndex].value}}</span>
        <i class="fal fa-lg fa-sync is-pulled-right" style="margin-right: 10px;margin-top: 3px;"></i>
        <slot v-if="enableSlotForIndex === activeIndex"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'FieldRotator',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    enableSlotForIndex: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      activeIndex: 0,
      instanceName: 'field-rotator-' + this.$uuid.v4()
    }
  },
  computed: {
    isWarrantyDate: function () {
      return this.value[this.activeIndex].isWarranty
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      this.activeIndex = 0
    }
  },
  methods: {
    onRotate: function () {
      if (this.activeIndex < (this.value.length - 1)) {
        this.activeIndex += 1
      } else {
        this.activeIndex = 0
      }
    },
    buildWarrantyExpirationTitle: function (value) {
      let tz = moment.tz.guess()
      let content = ''
      if (value !== null && value !== undefined) {
        if (value.warrantyExpirationDate !== null && value.warrantyExpirationDate !== undefined) {
          if ((value.daysWarrantyExpiration !== null && value.daysWarrantyExpiration !== undefined) && (value.daysWarrantyExpiration > 0 && value.daysWarrantyExpiration <= 60)) {
            content += '<i class="fal fa-exclamation-triangle tip-icon has-text-orange"></i>' + moment.tz(value.warrantyExpirationDate, tz).format('MM-DD-YYYY')
          }
          if ((value.daysWarrantyExpiration !== null && value.daysWarrantyExpiration !== undefined) && value.daysWarrantyExpiration < 0) {
            content += '<i class="fal fa-exclamation-triangle tip-icon has-text-danger"></i>' + moment.tz(value.warrantyExpirationDate, tz).format('MM-DD-YYYY')
          }
          if ((value.daysWarrantyExpiration !== null && value.daysWarrantyExpiration !== undefined) && value.daysWarrantyExpiration > 60) {
            content += '<i class="fal fa-exclamation-triangle tip-icon has-text-success"></i>' + moment.tz(value.warrantyExpirationDate, tz).format('MM-DD-YYYY')
          }
        } else {
          content += '<i class="fal fa-exclamation-triangle tip-icon has-text-danger"></i>Not Set'
        }
      } else {
        content += '<i class="fal fa-exclamation-triangle tip-icon has-text-danger"></i>Not Set'
      }

      return {
        content: content,
        delay: {
          show: 500,
          hide: 100
        }
      }
    }
  },
  mounted () {
    this.activeIndex = 0
  },
  created () {
    this.activeIndex = 0
  }
}

</script>

<style scoped>

.field-container {
  width:100%;
  margin-bottom:5px;
}
</style>

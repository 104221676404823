export default {
  all: {
    valid: true,
    vin: 'WP0AD2A9XFS166895',
    inventoryId: 165165,
    recallCount: 0,
    mixedOdometer: false,
    lastOdometer: 1359,
    count: 1,
    odometer: true,
    rollBack: false,
    estimatedAverageMiles: 625,
    accidentCount: 0,
    compareScoreRangeLow: 68,
    compareScoreRangeHigh: 95,
    age: 10,
    score: 80,
    ownerCount: 1
  }
}

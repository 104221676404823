import mutationTypes from '../mutation-types'
import partsService from '../../services/partsService'

const state = {
  dealerPartsVehicleMakes: [],
  partsVehicleMakesDealerId: 0
}

const getters = {
  dealerPartsVehicleMakes: state => {
    return state.dealerPartsVehicleMakes
  },
  partsVehicleMakesDealerId: state => {
    return state.partsVehicleMakesDealerId
  }
}

const mutations = {
  [mutationTypes.SET_DEALER_PARTS_VEHICLE_MAKES] (state, partsVehicleMakes) {
    state.dealerPartsVehicleMakes = partsVehicleMakes
  },
  [mutationTypes.SET_PARTS_VEHICLE_MAKES_DEALER_ID] (state, dealerId) {
    state.partsVehicleMakesDealerId = dealerId
  }
}

const actions = {
  setDealerPartsVehicleMakes ({commit, state}, searchCriteria) {
    commit(mutationTypes.SET_PARTS_SERVICE_VEHICLE_MAKES, [])
    commit(mutationTypes.SET_PARTS_VEHICLE_MAKES_DEALER_ID, searchCriteria.dealerId)
    partsService.getDistinctVehicleMakeNamesByDealer(searchCriteria).then((data) => {
      commit(mutationTypes.SET_DEALER_PARTS_VEHICLE_MAKES, data)
    }).catch((error) => {
      console.log(error)
    }
    )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

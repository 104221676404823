import axios from 'axios'
import baseService from './baseService'
import inventoryLoanerEventData from './data/inventoryLoanerEvent'

const appService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryLoanerEventData)
      } else {
        axios
          .post('/inventoryLoanerEvent/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (inventoryLoanerEventId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryLoanerEventData.all.find((x) => x.id === inventoryLoanerEventId))
      } else {
        axios
          .post('/inventoryLoanerEvent/get?id=' + inventoryLoanerEventId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (inventoryLoanerEventModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventoryLoanerEvent = {
          id: 999,
          name: 'Borrow',
          status: 'Active'
        }
        inventoryLoanerEventData.all.push(inventoryLoanerEvent)
        resolve(inventoryLoanerEvent)
      } else {
        axios
          .post('/inventoryLoanerEvent/save', inventoryLoanerEventModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (inventoryLoanerEventModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var inventoryLoanerEvent = inventoryLoanerEventData.all.find((x) => x.id === inventoryLoanerEventModel.id)
        if (inventoryLoanerEvent) {
          inventoryLoanerEvent.name = inventoryLoanerEventModel.name
          inventoryLoanerEvent.status = inventoryLoanerEventModel.status
        }
        resolve(inventoryLoanerEvent)
      } else {
        axios
          .post('/inventoryLoanerEvent/update', inventoryLoanerEventModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  generatePdfWithSignature (generatePdfModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var generatePdf = {
          signatureString: 'data:image/png;base64,...',
          inventoryLoanerEventId: 1
        }
        resolve(generatePdf)
      } else {
        axios
          .post('/inventoryLoanerEvent/generatePdfWithSignature', generatePdfModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (inventoryLoanerEventModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        inventoryLoanerEventModels.forEach(element => {
          inventoryLoanerEventData.all.remove((x) => x.id === element.id)
        })
        resolve(inventoryLoanerEventData.all)
      } else {
        var ids = []
        inventoryLoanerEventModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/inventoryLoanerEvent/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllByInventoryId (inventoryId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryLoanerEventData.all)
      } else {
        axios
          .get('/inventoryLoanerEvent/FindAllByInventoryId?inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getDecrypted (inventoryLoanerEventId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryLoanerEventData.all.find((x) => x.id === inventoryLoanerEventId))
      } else {
        axios
          .get('/inventoryLoanerEvent/getSafeLoaner?id=' + inventoryLoanerEventId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getLatestEmployeeLoaner (employeeId, currentLoanerEventId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(inventoryLoanerEventData.all.find((x) => x.id === employeeId))
      } else {
        axios
          .post('/inventoryLoanerEvent/getLatestEmployeeLoaner?id=' + employeeId + '&currentLoanerEventId=' + currentLoanerEventId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService

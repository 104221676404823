import mutationTypes from '../mutation-types.js'
import appService from '../../services/conversationService.js'

const state = {
  all: [],
  overview: {}
}

const getters = {
  all: state => {
    return state.all
  },
  count: (state, getters) => {
    return getters.all ? getters.all.length : 0
  },
  inbound: (state, getters) => {
    return getters.all.filter(x => x.type === 'Inbound')
  }
}

const mutations = {
  [mutationTypes.SET_CONVERSATION_OVERVIEW] (state, overview) {
    state.overview = overview
  },
  [mutationTypes.SET_ALL_CONVERSATION] (state, allConversation) {
    state.all = allConversation
  }
}

const actions = {
  setAllConversation ({commit, state}) {
    commit(mutationTypes.SET_ALL_CONVERSATION, [])
    appService.getAllConversation()
      .then((data) => {
        commit(mutationTypes.SET_ALL_CONVERSATION, data.all)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  setAllOverview ({commit, state}) {
    commit(mutationTypes.SET_CONVERSATION_OVERVIEW, {})
    appService.getInventoryOverview()
      .then((data) => {
        commit(mutationTypes.SET_CONVERSATION_OVERVIEW, data.overview)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

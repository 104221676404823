<template>
  <section>
    <portal to="global-modal-portal" v-if="showHistoryActive">
      <b-modal :active.sync="showHistoryActive" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head is-block">
            <div class="subtitle is-6 has-text-centered has-text-info is-marginless">Check/Wire Request History</div>
          </header>
          <section class="modal-card-body view-container has-background-grey-lighter" style="padding: 12px">
            <div v-if="showHistoryList">
              <div class="box" v-for="(batchPayment, index) in batchPaymentHistory" :key="batchPayment.id">
                <section-header :title="batchPayment.sentAt | formatDateOnly"></section-header>
                <b-table class="lists" :data="batchPayment.paymentRequests" @click="onViewPaymentRequest">
                  <template slot-scope="props">
                    <b-table-column field="payeeType" label="Payee Type" sortable>
                      {{ getPayeeTypeDisplayName(props.row.payeeType) }}
                    </b-table-column>
                    <b-table-column field="type" label="Payment Type" sortable>
                      {{ props.row.paymentType }}
                    </b-table-column>
                    <b-table-column field="buyerName" label="Payee" sortable>
                      {{ props.row.buyerName }}
                    </b-table-column>
                    <b-table-column field="requestedBy" label="Requested By" sortable>
                      {{ props.row.requestedBy }}
                    </b-table-column>
                    <b-table-column field="amount" label="Amount" sortable>
                      {{ props.row.amount | currency('$', 2)  }}
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="has-text-centered">
                        <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                        <p>No payment requests defined</p>
                      </div>
                    </section>
                  </template>
                  <template slot="footer" v-if="currentInventory !== undefined && currentInventory !== null">
                    <td colspan="3">&nbsp;</td>
                    <td style="text-align:right">
                      <div class="columns is-multiline" style="width:200px">
                        <div class="column is-12">
                          <span class="has-text-weight-bold">Total:</span>
                        </div>
                        <div class="column is-12" v-if="getPreviousPurchaseCost(index) > 0">
                          <span class="has-text-weight-bold">Previous Purchase Cost:</span>
                        </div>
                        <div class="column is-12">
                          <span class="has-text-weight-bold">Purchase Cost:</span>
                        </div>
                        <div class="column is-12">
                          <span class="has-text-weight-bold">Unallocated:</span>
                        </div>
                      </div>
                    </td>
                    <td style="text-align:left">
                      <div class="columns is-multiline" style="width:150px">
                        <div class="column is-12">
                          <span class="is-inline-block">{{totalPaymentRequests(batchPayment) | currency('$', 2)}}</span>
                        </div>
                        <div class="column is-12" v-if="getPreviousPurchaseCost(index) > 0">
                          <span>{{getPreviousPurchaseCost(index) | currency('$', 2)}}</span>
                        </div>
                        <div class="column is-12">
                          <span :class="{'is-inline-block': true, 'has-text-danger': batchPayment.inventoryPurchaseCost - (getPreviousPurchaseCost(index) + totalPaymentRequests(batchPayment)) > 0, 'has-text-primary': batchPayment.inventoryPurchaseCost - (getPreviousPurchaseCost(index) + totalPaymentRequests(batchPayment)) <= 0}">{{batchPayment.inventoryPurchaseCost | currency('$', 2)}}</span>
                        </div>
                        <div class="column is-12">
                          <span class="is-inline-block">{{ (batchPayment.inventoryPurchaseCost - getPreviousPurchaseCost(index)) - totalPaymentRequests(batchPayment) | currency('$', 2) }}</span>
                        </div>
                      </div>
                    </td>
                  </template>
                </b-table>
              </div>
              <div class="notification is-info" v-if="batchPaymentHistory.length === 0">
                0 records found
              </div>
            </div>
            <capture-payment-request v-if="!showHistoryList" :currentPaymentRequestId="selectedPaymentRequestId" :forBatchPaymentRequest="selectedBatchPaymentRequest" :inventory="currentInventory" :readOnly="true"></capture-payment-request>
          </section>
          <footer class="modal-card-foot">
            <div class="columns" style="width: 100%;">
              <div class="column is-2 is-pulled-left">
                <button class="button is-danger" @click="onClose()">Close</button>
              </div>
              <div class="column is-10 is-vcentered">
                <h3 class="title is-6">{{ inventoryDetails }}</h3>
              </div>
            </div>
          </footer>
          <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
        </div>
      </b-modal>
    </portal>
  </section>
</template>

<script>
import batchPaymentRequestService from '@/services/batchPaymentRequestService'
import utilitiesMixin from '@/mixins/generic/utilities'
import SectionHeader from '@/components/generic/SectionHeader'
import CapturePaymentRequest from '@/components/inventory/CapturePaymentRequest'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'InventoryBatchPaymentRequestHistoryAction',
  mixins: [utilitiesMixin],
  components: {
    'section-header': SectionHeader,
    'capture-payment-request': CapturePaymentRequest
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    isNewBatchPaymentRequest: {
      type: Boolean,
      default: true
    },
    showHistoryActive: {
      type: Boolean,
      default: false
    },
    newCheckRequestAllowed: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isLoading: false,
      batchPaymentHistory: [],
      showHistoryList: true,
      selectedPaymentRequestId: 0,
      selectedBatchPaymentRequest: null
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    inventoryDetails () {
      return this.currentInventory ? this.currentInventory.name + ' - ' + this.currentInventory.stockNo + ' - ' + this.currentInventory.vinNo : ''
    },
    canSendCheckRequest: function () {
      return this.lastSignedSellersAgreement !== '' && this.currentInventory.purchasedFromType !== 'Factory' && this.hasBatchPaymentRequestAccess
    },
    lastSignedSellersAgreement: function () {
      if (this.currentInventory && (this.currentInventory.sellersAgreementStatus === 'Complete' || this.currentInventory.sellersAgreementStatus === 'Signed')) {
        var allSignedSellersAgreements = this.currentInventory.attachments.filter((x) => x.classType === 'SellersAgreement' && x.status === 'Active')
        if (allSignedSellersAgreements && allSignedSellersAgreements.length > 0) {
          return allSignedSellersAgreements[allSignedSellersAgreements.length - 1].fileLocation
        }
      }
      return ''
    },
    hasBatchPaymentRequestAccess: function () {
      let allowedPermissions = ['inventory.issue.batch-payment-request']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    allPayeeTypes: function () {
      return this.definedTypes.payeeTypes
    }
  },
  methods: {
    fetchHistory: function () {
      this.isLoading = true
      let inventoryId = this.currentInventory.id
      batchPaymentRequestService.getBatchPaymentRequestListByInventoryId(inventoryId).then(response => {
        this.batchPaymentHistory = response
        this.isLoading = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
      })
    },
    totalPaymentRequests: function (item) {
      if (item && item.paymentRequests && item.paymentRequests.length > 0) {
        let paymentRequests = item.paymentRequests
        var sum = paymentRequests.reduce(function (a, b) {
          return a + b.amount
        }, 0)
        return sum
      }
      return 0
    },
    onViewPaymentRequest: function (item) {
      this.selectedPaymentRequestId = item.id
      this.selectedBatchPaymentRequest = this.batchPaymentHistory.find(x => x.id === item.batchPaymentRequestId)
      if (this.selectedBatchPaymentRequest) {
        this.showHistoryList = false
      }
    },
    onClose: function () {
      if (this.showHistoryList) {
        this.$emit('update:showHistoryActive', false)
        if (this.newCheckRequestAllowed) {
          this.$emit('update:isNewBatchPaymentRequest', true)
        }
      } else {
        this.selectedPaymentRequestId = '0'
        this.selectedBatchPaymentRequest = null
        this.showHistoryList = true
      }
    },
    getPayeeTypeDisplayName: function (payeeType) {
      let type = this.allPayeeTypes.find(x => x.name === payeeType || x.altName === payeeType)
      return (type) ? type.name : ''
    },
    getPreviousPurchaseCost: function (index) {
      let isNextIndexValid = this.batchPaymentHistory.length > (+index + 1)
      return isNextIndexValid ? this.batchPaymentHistory[+index + 1].inventoryPurchaseCost : 0
    }
  },
  watch: {
    showHistoryActive: function (newValue, oldValue) {
      if (newValue) {
        this.fetchHistory()
      }
    }
  },
  mounted: function () {
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  .modal-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }

  .el-steps--simple {
    padding-left :0;
    padding-right :0;
    padding-bottom :0;
  }
</style>

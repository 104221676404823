<template>
  <div class="is-inline-block">
    <div v-if="isListMode">
      <i :data-type="getDataTypeByCreditStatus" v-if="currentDeal.dealCreditApplicationStatus === '' || currentDeal.dealCreditApplicationStatus === null ||currentDeal.dealCreditApplicationStatus === undefined" class="grid-icon fa-fw fal fa-landmark ml-5 fa-lg clickable" :disabled="!isCreditApplicationAvailable" :class="getColorClassByWorkflowStatus()" v-tooltip="creditApplicationStatusDescription" @click.stop.prevent="onDownloadCreditApplication(activeCustomer)"/>
      <i v-else :data-type="getDataTypeByCreditStatus" class="grid-icon fa-fw fal fa-landmark ml-5 fa-lg clickable" :disabled="!isCreditApplicationAvailable" :class="getColorClassByWorkflowStatus()" v-tooltip="creditApplicationStatusDescription" @click.stop.prevent="onDownloadCreditApplication(activeCustomer)"/>
    </div>
    <div class="is-inline-block" v-else>
      <div class="quick-action is-pulled-left">
        <button type="button" class="button" :disabled="!isCreditApplicationAvailable" :class="getColorClassByWorkflowStatus()" @click="onDownloadCreditApplication(activeCustomer)" v-tooltip="creditApplicationStatusDescription">
          <i :data-type="getDataTypeByCreditStatus" v-if="!currentDeal.dealCreditApplicationStatus" class="icon fal fa-landmark fa-lg" />
          <i v-else :data-type="getDataTypeByCreditStatus" class="icon fal fa-landmark fa-lg" />
        </button>
      </div>
    </div>
    <portal to="global-modal-portal" v-if="creditApplicationIsPdfViewerActive">
      <b-modal :active.sync="creditApplicationIsPdfViewerActive" scroll="keep" :has-modal-card="true" class="credit-application-modal" id="credit-application-modal">
        <div class="modal-card">
          <header class="modal-card-head">
            <p class="modal-card-title" v-if="creditApplicationSelectedPdf">{{ creditApplicationSelectedPdf.name }}</p>
          </header>
          <section class="modal-card-body is-paddingless" style="min-height:400px;">
            <b-tabs v-model="tabIndex" size="is-small" :animated="false" type="is-toggle" class="credit-app-tab">
              <b-tab-item :label="customerTabTitle" icon-pack="fal" icon="fal fa-user fa-sm">
                <pdf-inline
                  v-if="creditApplicationCustomerPdf && creditApplicationCustomerPdf.src"
                  id="pdfViewerCustomer"
                  ref="pdfViewerCustomer"
                  :pdfFile="creditApplicationCustomerPdf"
                  v-on:on-pdf-downloaded="onCustomerPdfDownloaded"
                  :key="creditApplicationCustomerPdf.key">
                </pdf-inline>
              </b-tab-item>
              <b-tab-item v-if="hasCoCustomer" :label="coCustomerTabTitle" icon-pack="fal" icon="fal fa-user-friends fa-sm">
                <pdf-inline
                  v-if="creditApplicationCoCustomerPdf && creditApplicationCoCustomerPdf.src"
                  id="pdfViewerCoCustomer"
                  ref="pdfViewerCoCustomer"
                  :pdfFile="creditApplicationCoCustomerPdf"
                  v-on:on-pdf-downloaded="onCoCustomerPdfDownloaded"
                  :key="creditApplicationCoCustomerPdf.key">
                </pdf-inline>
              </b-tab-item>
            </b-tabs>
          </section>
          <footer class="modal-card-foot">
            <button class="button" @click.prevent="closePdf" v-if="!isMobile">Close</button>
            <button type="button" class="button is-danger" @click.prevent="closePdf" v-else-if="isMobile"><b-icon pack="fal" icon="ban" custom-size="fa-lg"></b-icon></button>
            <button class="button" @click.prevent="onDownloadCreditFile" v-if="!isMobile">Download</button>
            <button type="button" class="button" @click.prevent="onDownloadCreditFile" v-else-if="isMobile"><b-icon pack="fal" icon="download" custom-size="fa-lg"></b-icon></button>
            <button class="button" :disabled="!isCreditApplicationFinalized" @click.prevent="onPrint" v-if="!isMobile">Print</button>
            <button type="button" class="button" :disabled="!isCreditApplicationFinalized" @click.prevent="onPrint" v-else-if="isMobile"><b-icon pack="fal" icon="print" custom-size="fa-lg"></b-icon></button>
            <div class="is-pulled-right">
              <credit-application-action :isListMode="isListMode" :currentCustomerId="customerId" :currentCustomer="currentDeal.customer"></credit-application-action>
              <credit-application-action :isListMode="isListMode" :currentCustomerId="coCustomerId" :currentCustomer="currentDeal.coCustomer" v-if="coCustomerId && coCustomerId > 0" ></credit-application-action>
            </div>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>
</template>

<script>
import PdfInlineDisplayComponent from '@/components/generic/PdfInlineDisplayComponent'
import CustomerCreditApplicationAction from '@/components/configuration/customers/CustomerCreditApplicationAction'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'DealCustomerCreditApplicationStatus',
  mixins: [],
  components: {
    'pdf-inline': PdfInlineDisplayComponent,
    'credit-application-action': CustomerCreditApplicationAction
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    creditApplicationStatus: {
      type: String,
      default: 'Initial'
    },
    isListMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tabIndex: 0,
      creditApplicationCustomerPreviewDownloading: false,
      creditApplicationCoCustomerPreviewDownloading: false,
      creditApplicationCustomerPdf: null,
      creditApplicationCoCustomerPdf: null,
      creditApplicationIsPdfViewerActive: false,
      activeCustomer: null,
      isMobile: window.innerWidth <= 700
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    creditApplicationSelectedPdf: function () {
      return this.tabIndex === 0 ? this.creditApplicationCustomerPdf : this.creditApplicationCoCustomerPdf
    },
    creditApplicationStatusDescription: function () {
      let creditAppStatus = this.currentDeal.dealCreditApplicationStatus
      if (this.creditApplicationStatus === 'Initial') return 'Credit application has not been sent.'
      if (this.creditApplicationStatus === 'Sent') return 'Credit application has been sent. Awaiting customer completion.'
      if (this.creditApplicationStatus === 'Viewed') return 'Credit application has been opened by customer.'
      if (this.creditApplicationStatus === 'Pending') return 'Credit application has been issued. Waiting for response from Dealer Track.'
      if (this.creditApplicationStatus === 'Failed') return 'Credit application has failed send to Dealer Track.'
      if (!creditAppStatus && this.creditApplicationStatus === 'Completed') return 'Credit application has been successfully processed by Dealer Track.'
      if (creditAppStatus === 'Approved' && this.creditApplicationStatus === 'Completed') {
        return 'Credit application has been successfully processed by Dealer Track. (Approved)'
      } else if (creditAppStatus === 'NeedPOI' && this.creditApplicationStatus === 'Completed') {
        return 'Credit application has been successfully processed by Dealer Track. (Need Proof of Income)'
      } else if (creditAppStatus === 'NeedPOR' && this.creditApplicationStatus === 'Completed') {
        return 'Credit application has been successfully processed by Dealer Track. (Need Proof of Residence)'
      } else if (creditAppStatus === 'NeedSSCard' && this.creditApplicationStatus === 'Completed') {
        return 'Credit application has been successfully processed by Dealer Track. (Need Social Security Card)'
      } else if (creditAppStatus === 'NeedInterview' && this.creditApplicationStatus === 'Completed') {
        return 'Credit application has been successfully processed by Dealer Track. (Need Interview)'
      } else if (creditAppStatus === 'SingleVehicleOnly' && this.creditApplicationStatus === 'Completed') {
        return 'Credit application has been successfully processed by Dealer Track. (Single Vehicle Only)'
      }
      return ''
    },
    hasCreditApplicationAccess: function () {
      let allowedPermissions = ['customer.credit-application-approval']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    isCreditApplicationAvailable: function () {
      return this.creditApplicationStatus !== 'Initial'
    },
    isCreditApplicationFinalized: function () {
      if (this.currentDeal === null) return false
      return this.creditApplicationStatus && (this.creditApplicationStatus === 'Pending' || this.creditApplicationStatus === 'Completed')
    },
    getDataTypeByCreditStatus: function () {
      if (!this.currentDeal.customer) return undefined
      if (!this.isCreditApplicationAvailable) return undefined
      if (this.currentDeal.customer.creditApplicationType === 'Business') {
        return 'B'
      } else {
        return 'I'
      }
    },
    hasCoCustomer: function () {
      return this.currentDeal.coCustomerId > 0
    },
    customerTabTitle: function () {
      return this.currentDeal.customer.fullName
    },
    coCustomerTabTitle: function () {
      return this.currentDeal.coCustomer.fullName
    },
    customerId: function () {
      return this.currentDeal && this.currentDeal.customerId > 0 && this.currentDeal.customerId !== null ? this.currentDeal.customerId : 0
    },
    coCustomerId: function () {
      return this.currentDeal && this.currentDeal.coCustomerId !== null && this.currentDeal.coCustomerId > 0 ? this.currentDeal.coCustomerId : 0
    }
  },
  methods: {
    onCustomerPdfDownloaded: function () {
      this.creditApplicationCustomerPreviewDownloading = false
    },
    onCoCustomerPdfDownloaded: function () {
      this.creditApplicationCoCustomerPreviewDownloading = false
    },
    switchCustomer: function (customer) {
      this.creditApplicationCustomerPdf = null
      this.creditApplicationCoCustomerPdf = null
      this.activeCustomer = customer
      this.onDownloadCreditApplication(this.activeCustomer)
    },
    onDownloadCreditApplication: function (customer) {
      this.creditApplicationCustomerPreviewDownloading = false
      this.creditApplicationCoCustomerPreviewDownloading = false
      this.creditApplicationCustomerPdf = null
      this.creditApplicationCoCustomerPdf = null
      var file = {
        name: 'Customer Credit Application - ' + customer.fullName,
        fileName: 'credit_application_' + customer.id + '.pdf',
        key: this.$uuid.v4()
      }
      let src = 'docs/customer/credit-application/'
      if (customer.creditApplicationType === 'Business') {
        src = 'docs/customer/business-credit-application/'
      } else {
        if (customer.type === 'Lead') {
          src = 'docs/customer/lead-credit-application/'
        }
      }
      let that = this
      setTimeout(() => {
        if (that.isCreditApplicationAvailable) {
          that.creditApplicationIsPdfViewerActive = true
          file.src = process.env.VUE_APP_ROOT_DOC_SITE + src + customer.id + '/' + that.currentUser.id
          if (customer.id === that.customerId) {
            that.creditApplicationCustomerPreviewDownloading = true
            that.creditApplicationCustomerPdf = file
          }
          if (customer.id === that.coCustomerId) {
            that.creditApplicationCoCustomerPreviewDownloading = true
            that.creditApplicationCoCustomerPdf = file
          }
        }
      }, 10)
    },
    closePdf () {
      this.creditApplicationIsPdfViewerActive = false
    },
    onPrint: function () {
      var pdfControls = this.tabIndex === 0 ? this.$refs.pdfViewerCustomer : this.$refs.pdfViewerCoCustomer
      pdfControls.print()
    },
    onDownloadCreditFile: function () {
      let src = 'docs/customer/credit-application/'
      if (this.currentDeal.customer.creditApplicationType === 'Business') {
        src = 'docs/customer/business-credit-application/'
      } else {
        if (this.currentDeal.customer.type === 'Lead') {
          src = 'docs/customer/lead-credit-application/'
        }
      }
      window.location = process.env.VUE_APP_ROOT_DOC_SITE + src + this.activeCustomer.id + '/' + this.currentUser.id
    },
    getColorClassByWorkflowStatus: function () {
      var result = 'has-text-grey-light'
      if (this.creditApplicationStatus === 'Sent' || this.creditApplicationStatus === 'Viewed' || this.creditApplicationStatus === 'Pending') {
        result = 'has-text-orange'
      } else if (this.creditApplicationStatus === 'Failed') {
        result = 'has-text-danger'
      } else if (this.creditApplicationStatus === 'Completed') {
        result = 'has-text-success'
      }

      let classToAttach = {'is-loading': this.creditApplicationCoCustomerPreviewDownloading || this.creditApplicationCustomerPreviewDownloading}
      classToAttach[result] = true
      return classToAttach
    }
  },
  mounted: function () {
    this.activeCustomer = this.currentDeal ? this.currentDeal.customer : null
    this.tabIndex = 0
  },
  watch: {
    currentDeal: function (newValue, oldValue) {
      this.activeCustomer = newValue ? newValue.customer : null
      this.tabIndex = 0
    },
    tabIndex: function (newValue, oldValue) {
      if (newValue === oldValue) return
      if (!this.creditApplicationIsPdfViewerActive) return
      if (newValue === 1) {
        this.$nextTick(() => {
          this.switchCustomer(this.currentDeal.coCustomer)
        })
      } else {
        this.$nextTick(() => {
          this.switchCustomer(this.currentDeal.customer)
        })
      }
    }
  }
}

</script>

<style scoped>
.credit-application-modal .modal-card-body {
  position:relative;
  max-height: 65vh; /* max 80% of the viewport height */
  max-width: 960px; /* max 80% of the viewport height */
  height: unset !important;
  overflow-y: auto;
  .animation-content {
      max-width: unset !important;
  }
}

@media screen and (min-width: 769px), print {
  .credit-application-modal .modal-card-body {
    position:relative;
    width: 99vw !important;
    max-height: 80vh !important; /* max 80% of the viewport height */
    max-width: 960px; /* max 80% of the viewport height */
    height: unset !important;
    overflow-y: auto;
  }
}

@media screen and (min-width: 769px), print {
  .credit-application-modal .modal-content, .modal-card {
      position:relative;
      width: 99% !important;
      overflow: auto;
      min-width: unset !important;
      max-width: unset !important;
      width: unset !important;
  }
}
</style>

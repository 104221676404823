import axios from 'axios'
import logger from '@/services/loggingService'

const authService = {
  verifyAuth () {
    return new Promise((resolve, reject) => {
      axios.defaults.withCredentials = true
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'auth/verify').then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          logger.log(error.response.data)
          logger.log(error.response.status)
          logger.log(error.response.headers)
          reject(error.response.data)
        } else if (error.request) {
          logger.log(error.request)
          reject(new Error('Unable to reach authentication services. Please check network connection and try again.'))
        } else {
          logger.log('Error', error.message)
          reject(error)
        }
      })
    })
  },
  logout () {
    return new Promise((resolve, reject) => {
      axios.defaults.withCredentials = true
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'auth/logout').then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          logger.log(error.response.data)
          logger.log(error.response.status)
          logger.log(error.response.headers)
          reject(error.response.data)
        } else if (error.request) {
          logger.log(error.request)
          reject(new Error('Unable to reach authentication services. Please check network connection and try again.'))
        } else {
          logger.log('Error', error.message)
          reject(error)
        }
      })
    })
  },
  login (credentials) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'auth', credentials).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          logger.log(error.response.data)
          logger.log(error.response.status)
          logger.log(error.response.headers)
          reject(error.response.data)
        } else if (error.request) {
          logger.log(error.request)
          reject(new Error('Unable to reach authentication services. Please check network connection and try again.'))
        } else {
          logger.log('Error', error.message)
          reject(error)
        }
      })
    })
  },
  resetPassword (credentials) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'auth/forgotpassword', credentials).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          reject(error.response.data)
        } else if (error.request) {
          console.log(error.request)
          reject(new Error('Unable to reach authentication services. Please check network connection and try again.'))
        } else {
          console.log('Error', error.message)
          reject(error)
        }
      })
    })
  },
  tabletLogin (credentials) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'auth/tabletlogin', credentials).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          logger.log(error.response.data)
          logger.log(error.response.status)
          logger.log(error.response.headers)
          reject(error.response.data)
        } else if (error.request) {
          logger.log(error.request)
          reject(new Error('Unable to reach authentication services. Please check network connection and try again.'))
        } else {
          logger.log('Error', error.message)
          reject(error)
        }
      })
    })
  },
  verifyCode (authModel) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'auth/verifycode', authModel).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          logger.log(error.response.data)
          logger.log(error.response.status)
          logger.log(error.response.headers)
          reject(error.response.data)
        } else if (error.request) {
          logger.log(error.request)
          reject(new Error('Unable to reach authentication services. Please check network connection and try again.'))
        } else {
          logger.log('Error', error.message)
          reject(error)
        }
      })
    })
  },
  resendTwoFactorCode (authModel) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'auth/resendtwofactorcode', authModel).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          logger.log(error.response.data)
          logger.log(error.response.status)
          logger.log(error.response.headers)
          reject(error.response.data)
        } else if (error.request) {
          logger.log(error.request)
          reject(new Error('Unable to reach authentication services. Please check network connection and try again.'))
        } else {
          logger.log('Error', error.message)
          reject(error)
        }
      })
    })
  },
  setPassword (authModel) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'auth/setpassword', authModel).then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          logger.log(error.response.data)
          logger.log(error.response.status)
          logger.log(error.response.headers)
          reject(error.response.data)
        } else if (error.request) {
          logger.log(error.request)
          reject(new Error('Unable to reach authentication services. Please check network connection and try again.'))
        } else {
          logger.log('Error', error.message)
          reject(error)
        }
      })
    })
  }
}

export default authService

import mutationTypes from '../mutation-types.js'
import appService from '../../services/inventoryService.js'

const state = {
  all: [],
  overview: {}
}

const getters = {
  all: state => {
    return state.all
  },
  count: (state, getters) => {
    return getters.all ? getters.all.length : 0
  },
  inbound: (state, getters) => {
    return getters.all.filter(x => x.type === 'Inbound')
  }
}

const mutations = {
  [mutationTypes.SET_INVENTORY_OVERVIEW] (state, overview) {
    state.overview = overview
  },
  [mutationTypes.SET_ALL_INVENTORY] (state, allInventory) {
    state.all = allInventory
  }
}

const actions = {
  setAllInventory ({commit, state}) {
    commit(mutationTypes.SET_ALL_INVENTORY, [])
    appService.getAllInventory()
      .then((data) => {
        commit(mutationTypes.SET_ALL_INVENTORY, data.all)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  setAllOverview ({commit, state}) {
    commit(mutationTypes.SET_INVENTORY_OVERVIEW, {})
    appService.getInventoryOverview()
      .then((data) => {
        commit(mutationTypes.SET_INVENTORY_OVERVIEW, data.overview)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
<span v-if="isTypePpaAppraisal">
    <span v-if="!isApprovedPpaConfirmationActive">
      <span v-if="hasApprovePpaAccess && canShowPpaAction" :id="instanceId" :name="instanceId">
        <div class="quick-action" v-if="!isApprovedPpaConfirmationActive && !showStatusIcon">
          <button class="button" @click="onRequestApprovePpa()" :title="applicableIconTitle" v-if="!showStatusIcon">
            <b-icon pack="fal" icon="check-circle" custom-size="fa-lg" :class="formatIconByStatus"></b-icon>
          </button>
        </div>
        <span v-if="showStatusIcon && isApprovable" @click.stop.prevent="onRequestApprovePpa()">
          <i class="grid-icon fa-fw fal fa-check-circle fa-lg clickable has-text-danger" :title="applicableIconTitle"/>
        </span>
        <span v-if="showStatusIcon && !isApprovable" @click.stop.prevent="onRequestApprovePpa()">
          <i class="grid-icon fa-fw fal fa-check-circle fa-lg clickable" :class="formatIconByStatus" :title="applicableIconTitle"/>
        </span>
      </span>
    </span>
    <portal to="global-modal-portal" v-else :disabled="!usePortal">
      <b-modal :active.sync="isApprovedPpaConfirmationActive" :full-screen="true" scroll="keep" :has-modal-card="true" :canCancel="false" class="modal-fullscreen">
        <div class="modal-card">
          <header class="modal-card-head">
            <div class="columns" style="width: 100%">
              <div class="column is-4 is-pulled-left">
                <span class="modal-card-title">{{modalTitle}}</span>
              </div>
              <div class="column is-8">
                <div class="tags are-medium is-pulled-right">
                  <a class="tag is-primary" @click.stop.prevent="onStockNoClick(inventoryItem)">
                    {{ stockNo }}
                  </a>
                  <span class="tag is-primary">{{ inventoryDetails }}</span>
                  <span class="tag is-primary">{{ inventoryItem.vinNo }}</span>
                </div>
              </div>
            </div>
          </header>
          <section class="modal-card-body" v-if="isLoading">
            <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
          </section>
          <section class="modal-card-body" v-else>
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <form @submit.prevent="validateBeforeSubmit" v-if="!approvedSuccessfully">
              <div class="columns">
                <div class="column is-6">
                  <div class="columns is-multiline">
                    <div class="column is-3 is-narrow">
                      <button-list-selector
                        name="PpaIntention"
                        validateAs="ppa intention"
                        label="PPA Intention"
                        v-model="selectedPpaIntention"
                        :allowDeselection="true"
                        v-validate="'required'"
                        :required="true"
                        :availableValues="ppaIntentionTypes">
                      </button-list-selector>
                      <span v-show="errors.has('PpaIntention')" class="help is-danger"><i v-show="errors.has('PpaIntention')" class="fas fa-exclamation-triangle"></i> The PPA Intention field is required</span>
                    </div>
                    <div class="column is-narrow is-4">
                      <button-list-selector :allowDeselection="true" validateAs="cpo intention" label="CPO Intention" v-model="selectedCpoStatus" :availableValues="definedTypes.cpoStatusTypes" v-if="this.currentDealer && !this.currentDealer.isWholesaleDealer"></button-list-selector>
                    </div>
                    <div class="column is-narrow is-5">
                      <button-list-selector validateAs="condition type" label="Condition" :required="false" v-model="selectedConditionType" :availableValues="definedTypes.inventoryConditionType"></button-list-selector>
                    </div>

                    <div class="column is-6 is-narrow">
                      <button-list-selector validateAs="disposition intention" label="Disposition Intention" v-model="selectedDispositionIntentionStatus" :availableValues="definedTypes.dispositionIntentionStatuses"></button-list-selector>
                    </div>
                    <div class="column is-6 is-narrow">
                      <div class="field" v-if="selectedDispositionIntentionStatus && selectedDispositionIntentionStatus.id === 3">
                        <label class="label">Retail Day Limit<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control">
                          <multiselect @select="onFormChange()" :taggable="true" @tag="wholesaleDayAdded" :showLabels="false" name="dispositionValidDays" data-vv-validate-on="input|close" data-vv-as="wholesale after days" v-validate="'required'" :class="{'is-danger': errors.has('dispositionValidDays') }" v-model="currentInventory.dispositionValidDays" :options="wholesaleLimitPeriods" :hideSelected="true"></multiselect>
                          <span v-show="errors.has('dispositionValidDays')" class="help is-danger"><i v-show="errors.has('dispositionValidDays')" class="fas fa-exclamation-triangle"></i> {{ errors.first('dispositionValidDays') }}</span>
                        </div>
                      </div>
                    </div>

                    <div class="column is-3 is-narrow">
                      <div class="field">
                        <label class="label">Mileage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control is-vertical-center">
                          <input name="usage" data-vv-as="miles" class="mileage-input" v-validate="{'numeric': true, 'required': true}" :class="{'input': true, 'is-width-100': true, 'is-danger': errors.has('usage') }" type="number" placeholder="#####" v-model="currentInventory.usage" :min="mileageEstimate ? 0 : minimumUsageAllowed">
                        </div>
                        <span v-show="errors.has('usage')" class="help is-danger"><i v-show="errors.has('usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('usage') }}</span>
                      </div>
                    </div>
                    <div class="column is-3 is-narrow">
                        <label class="label">Mileage Estimated<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <b-checkbox name="mileageEstimate" class="mileage-checkbox mt-5" size="is-medium" type="is-info" v-model="mileageEstimate"></b-checkbox>
                    </div>
                    <div class="column is-3 is-narrow">
                      <label  v-if="mileageEstimate" class="label">True Mileage Unknown<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <b-checkbox v-if="mileageEstimate" name="trueMileageUnknown" class="mileage-checkbox mt-5" size="is-medium" type="is-info" v-model="trueMileageUnknown"></b-checkbox>
                    </div>
                    <div class="column is-3 is-narrow" v-if="mileageEstimate">
                      <label class="label">Mileage Exempt</label>
                      <b-checkbox name="mileageExempt" class="mileage-checkbox mt-5" size="is-medium" type="is-info" v-model="mileageExempt"></b-checkbox>
                    </div>

                    <div class="column is-narrow is-6" v-if="selectedDispositionIntentionStatus && selectedDispositionIntentionStatus.id === 2">
                      <switch-selector type="is-info" label="Has Commitment" :required="false" v-model="hasCommitment" extraStyles="margin-top:12px;"></switch-selector>
                    </div>
                    <div class="column is-narrow is-6" v-if="selectedDispositionIntentionStatus && selectedDispositionIntentionStatus.id === 2 && hasCommitment">
                      <supplier-selector validateAs="commitment from" label="Commitment From" v-model="selectedCommitmentFrom" :required="true"></supplier-selector>
                    </div>

                    <div class="column is-narrow is-6" v-if="selectedDispositionIntentionStatus && selectedDispositionIntentionStatus.id === 2 && hasCommitment">
                      <switch-selector type="is-info" label="Firm Commitment" :required="false" v-model="currentInventory.firmCommitment" extraStyles="margin-top:12px;"></switch-selector>
                    </div>
                    <div class="column is-narrow is-6" v-if="selectedDispositionIntentionStatus && selectedDispositionIntentionStatus.id === 2 && hasCommitment">
                      <div class="field">
                        <label class="label no-content-wrap">Commitment Value<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control has-icons-left">
                          <money name="commitmentValue" data-vv-as="commitment value" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('commitmentValue') }" type="text" placeholder="0.00" v-model="currentInventory.commitmentValue" v-bind="$globalMoneyFormat"></money>
                          <span v-show="errors.has('commitmentValue')" class="help is-danger"><i v-show="errors.has('commitmentValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('commitmentValue') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>

                    <div class="column is-6 is-narrow">
                      <label class="label">{{ proposedCostLabel }}<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="field is-horizontal">
                        <div class="control has-icons-left">
                          <money name="proposedCost" data-vv-as="proposed cost" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('proposedCost'), 'is-width-200': true }" type="text" placeholder="0.00" v-on:blur="onProposedCostChange" v-model="currentInventory.proposedCost" v-bind="$globalMoneyFormat"></money>
                          <span v-show="errors.has('proposedCost')" class="help is-danger"><i v-show="errors.has('proposedCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('proposedCost') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                        <i v-show="showCopyAcv" class="fal fa-arrow-alt-from-top fa-lg clickable has-text-success has-text-weight-bold" title="Click to copy ACV value to proposed trade allowance." style="margin-top: 10px; margin-left: 15px;" @click.stop.prevent="onCopyProposedCostToTradeAllowance()"/>
                      </div>
                      <label v-if="initialProposedCost > 0" class="label-frontgp is-pulled-left mr-5" :class="{'has-text-danger':(initialProposedCost && currentInventory && initialProposedCost !== currentInventory.proposedCost), 'has-text-success': initialProposedCost && currentInventory && initialProposedCost === currentInventory.proposedCost}" @click="setInitialProposedCostAsCurrent" title="Click to set the current proposed cost to the initial value.">{{initialProposedCost | currency}}</label>
                      <label v-if="onLoadProposedCost !== currentInventory.proposedCost" class="label-frontgp is-pulled-left" :class="{'has-text-danger':(onLoadProposedCost && currentInventory && onLoadProposedCost !== currentInventory.proposedCost), 'has-text-success': onLoadProposedCost && currentInventory && onLoadProposedCost === currentInventory.proposedCost}" @click="resetProposedCostAsCurrent" title="Click to reset proposed cost.">{{onLoadProposedCost | currency}}</label>
                    </div>
                    <div class="column is-6 is-narrow">
                      <b-field :label="proposedCostExpirationLabel" class="is-marginless">
                        <el-date-picker
                          class="is-width-200"
                          @change="onFormChange()"
                          v-model="currentInventory.proposedCostExpirationDate"
                          type="datetime"
                          placeholder="Click to select..."
                          popper-class="datepicker-container"
                          :picker-options="expirationDatePickerOptions">
                        </el-date-picker>
                      </b-field>
                      <label class="label-frontgp is-pulled-left" :class="{'has-text-success': true}" @click="onSetProposedExpirationDate" title="Click to use this date as a proposed expiration date.">{{defaultProposedExpirationDate}}</label>
                    </div>
                    <div class="column is-6 is-narrow"  v-if="isTradePpaIntention">
                      <label class="label">Proposed Trade Allowance</label>
                      <div class="control has-icons-left">
                        <money name="proposedTradeAllowance" data-vv-as="proposed trade allowance" v-validate="{'required': proposedTradeAllowanceRequired, 'decimal': true}" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('proposedTradeAllowance'), 'is-width-200': true }" type="text" placeholder="0.00" v-model="proposedTradeAllowance" v-bind="$globalMoneyFormat" :disabled="!hasTradeAllowanceModifyAccess"></money>
                        <span v-show="errors.has('proposedTradeAllowance')" class="help is-danger"><i v-show="errors.has('proposedTradeAllowance')" class="fas fa-exclamation-triangle"></i> {{ errors.first('proposedTradeAllowance') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                    <div class="column is-6 is-narrow">
                      <label class="label">Payoff Amount</label>
                      <div class="control has-icons-left">
                        <money name="payoffAmount" data-vv-as="payoff amount" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('payoffAmount'), 'is-width-200': true }" type="text" placeholder="0.00" v-model="currentInventory.payoffAmount" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('payoffAmount')" class="help is-danger"><i v-show="errors.has('payoffAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('payoffAmount') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>

                    <div class="column is-narrow is-4">
                      <div class="field">
                        <switch-selector type="is-info" label="Has vehicle been visually inspected?" v-model="currentInventory.ppaExamined"></switch-selector>
                        <label class="label-frontgp is-pulled-left" v-tooltip="'Examined By/Examined At'" v-if="currentInventory.ppaExamined && currentInventory.PpaExaminedBy !== ''">{{ppaInfo}}</label>
                      </div>
                    </div>
                    <div class="column is-narrow is-4" style="display: flex !important;">
                      <switch-selector type="is-info" label="Create service inspection?" v-model="currentInventory.serviceInspectionRequest" :isDisabled="currentInventory.hasPpaPreInspection"></switch-selector>
                      <i class="grid-icon fa-fw fal fa-wrench fa-lg" style="padding-top: 30px !important;" :class="getColorClassByGetReadyStatus(currentInventory.getReadyStatusId)" v-tooltip="getReadyStatus" v-if="currentInventory.hasPpaPreInspection"/>
                    </div>
                    <div class="column is-narrow is-4" style="display: flex !important;">
                      <switch-selector type="is-info" label="Value is dependent on vehicle inspection?" v-model="currentInventory.inspectionDependent"></switch-selector>
                    </div>

                    <div class="column is-narrow is-12 is-paddingless" v-if="proposedCostChanged">
                      <div class="field">
                        <label class="label">Reason for price re-evaluation<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control is-fullwidth">
                          <multiselect
                              :multiple="false"
                              v-validate="{'required': true}"
                              data-vv-as="reason for price re-evaluation"
                              data-vv-validate-on="input|close"
                              :class="{'is-danger': errors.has('priceAmendmentReason') }"
                              name="priceAmendmentReason"
                              label="name"
                              track-by="altName"
                              v-model="selectedPriceAmendmentReason"
                              :options="this.definedTypes.ppaRevisionReasonType.options"
                              :disabled="false">
                            </multiselect>
                            <span v-show="errors.has('priceAmendmentReason')" class="help is-danger"><i v-show="errors.has('priceAmendmentReason')" class="fas fa-exclamation-triangle"></i> {{ errors.first('priceAmendmentReason') }}</span>
                        </div>
                      </div>
                      <div class="field" v-if="proposedCostChanged">
                        <label class="label">Note</label>
                        <div class="control">
                          <textarea name="note" rows="1" data-vv-as="note" v-validate="{'required': false}" :class="{'textarea': true, 'is-danger': errors.has('note') }" placeholder="" v-model="currentInventory.ppaPriceAmmendmentNote"></textarea>
                        </div>
                        <span v-show="errors.has('note')" class="help is-danger"><i v-show="errors.has('note')" class="fas fa-exclamation-triangle"></i> {{ errors.first('note') }}</span>
                      </div>
                    </div>

                    <div class="column is-12">
                      <div class="box">
                        <h6 class="title is-size-6">Proposed Costs</h6>
                        <b-table
                          :sortable="false"
                          :mobile-cards="false"
                          :selecteable="false"
                          :data="currentInventory.proposedCosts">
                          <template slot-scope="props">
                            <b-table-column field="proposedCost" label="Proposed Cost">
                              {{ props.row.proposedCost | currency }}
                            </b-table-column>
                            <b-table-column field="proposedTradeAllowance" label="Proposed Trade Allowance">
                              {{ props.row.proposedTradeAllowance | currency }}
                            </b-table-column>
                            <b-table-column field="ppaIntention" label="PPA Intention">
                              {{ props.row.ppaIntention }}
                            </b-table-column>
                            <b-table-column field="createdAt" label="Created At">
                              {{ props.row.createdAt | formatDateOnly }}
                            </b-table-column>
                            <b-table-column field="createdByName" label="Created By">
                              {{ props.row.createdByName }}
                            </b-table-column>
                          </template>

                          <template slot="empty">
                            <section class="section">
                              <div class="has-text-centered">
                                <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                                <p>No proposed costs.</p>
                              </div>
                            </section>
                          </template>
                        </b-table>
                      </div>
                    </div>
                    <div class="column">
                      <div class="verification-container column is-12" v-if="approvePpaAppraisalCheckComplete && approvePpaCarFaxCheckComplete && approvePpaAutoCheckCheckComplete && blackBookCheckComplete && (!approvePpaCarFaxIsGood || !approvePpaAppraisalIsGood || !approvePpaAutoCheckIsGood || !blackBookCheckIsGood)">
                        <h4 class="title is-size-7 has-text-danger" v-if="approvePpaCarFaxFailed">Unable to get latest CarFax report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="!approvePpaCarFaxIsGood">The latest CarFax report indicated multiple owners, a recall pending or some form of damage.</h4>
                        <!-- <h4 class="title is-size-7 has-text-danger" v-if="autoCheckEnabled && approvePpaAutoCheckFailed">Unable to get latest AutoCheck report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="autoCheckEnabled && !approvePpaAutoCheckIsGood">The latest AutoCheck report indicated multiple owners, a recall pending or some form of damage.</h4> -->
                        <h4 class="title is-size-7 has-text-danger" v-if="approvePpaAppraisalFailed">Unable to get latest appraisal report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="!approvePpaAppraisalIsGood">The proposed purchase price OR asking price of this vehicle is outside your dealer's acceptable market value tolerance.</h4>
                        <h4 class="title is-size-7 has-text-danger" v-if="blackBookEnabled && blackBookCheckFailed">Unable to get latest black book report. Please contact your administrator for investigation</h4>
                        <h4 class="title is-size-7 has-text-danger" v-else-if="blackBookEnabled && !blackBookCheckIsGood">Unable to obtain a complete black book report for this vehicle.</h4>
                        <h4 class="title is-size-7">Please Note: By activating the switch below you acknowledge the above vehicle's current status and accept responsibility for the purchase and/or sale of the vehicle and I am fully aware of the conditions and market value of the vehicle.</h4>
                        <div class="columns">
                          <b-switch class="column is-narrow is-vcentered" type="is-info" v-model="approvePpaAcceptConditions"></b-switch>
                          <h4 class="title is-size-7 column is-narrow is-vcentered" v-if="approvePpaAcceptConditions">I ACCEPT the above statement</h4>
                          <h4 class="title is-size-7 column is-narrow is-vcentered" v-if="!approvePpaAcceptConditions">I DO NOT ACCEPT the above statement</h4>
                        </div>
                      </div>
                      <div class="status-container column is-12" v-if="approvePpaAppraisalCheckComplete && approvePpaCarFaxCheckComplete && carFaxApproved && appraisalApproved && autoCheckValidated && blackBookCheckComplete && blackBookCheckIsGood">
                          <h4 class="title is-size-7 has-text-primary">{{approvalInformation}}</h4>
                        </div>
                      <div class="status-container column is-12" v-else-if="approvePpaAppraisalCheckComplete && approvePpaCarFaxCheckComplete && approvePpaCarFaxIsGood && approvePpaAppraisalIsGood && autoCheckValidated && blackBookCheckComplete && blackBookCheckIsGood">
                        <h4 class="title is-size-7 has-text-primary">The vehicle condition matches your dealers requirements and is approved for purchase.</h4>
                      </div>
                      <div class="status-container column is-12" v-if="!approvePpaAppraisalCheckComplete || !approvePpaCarFaxCheckComplete || !approvePpaAutoCheckCheckComplete || !blackBookCheckComplete">
                        Checking latest vehicle reports. Please wait...
                      </div>
                    </div>
                  </div>
                </div>

                <div class="column is-6">
                  <div class="columns is-multiline">
                    <div class="column is-12">
                      <car-fax-control
                        :inventory="currentInventory"
                        title="CarFax Vehicle Status"
                        :carFaxRating="currentInventory.carFaxRating"
                        :vinNo="currentInventory.vinNo"
                        :displayOnly="false"
                        v-on:on-new-carfax="onCarFaxForApprovePpa"
                        v-on:on-carfax-failed="onCarFaxForApprovePpa"
                        :runAutomatically="true"
                        :autoUpdateInventory="true"
                        :modalContainer="true"
                        v-on:on-show-carfax-report="onShowCarFaxReport">
                      </car-fax-control>
                    </div>
                    <!-- <div class="column is-12">
                      <auto-check-control
                        v-if="autoCheckEnabled"
                        :inventory="currentInventory"
                        title="AutoCheck Vehicle Status"
                        :autoCheckRating="currentInventory.autoCheckRating"
                        :vinNo="currentInventory.vinNo"
                        :displayOnly="false"
                        v-on:on-new-autocheck="onAutoCheckForApprovePpa"
                        v-on:on-autocheck-failed="onAutoCheckForApprovePpa"
                        :runAutomatically="true"
                        :autoUpdateInventory="true"
                        :modalContainer="true"
                        v-on:on-show-autocheck-report="onShowAutoCheckReport"
                        :showRefresh="false"
                        :purchasedFromType="currentInventory.purchasedFromType"
                        :inventoryType="selectedInventoryStatusType"
                        v-on:autocheck-disabled="onAutoCheckDisabled">
                      </auto-check-control>
                    </div> -->
                    <div class="column is-12">
                      <div>
                        <div class="columns box is-multiline is-centered vauto-container" style="position: relative; z-index: 2000;">
                          <div class="vauto-container-display-only column is-12">
                            <div style="width:100%">
                              <h3 data-v-74fd810e="" class="title is-6 is-inline-block is-marginless">vAuto Vehicle Status</h3>
                            </div>
                          </div>
                          <div class="column is-12">
                            <a v-if="hasVautoAppraisal" :href="currentInventory.vAutoAppId" target="_vauto" title="Click to view the latest vAuto report for this vehicle."><img src="@/assets/static/vauto/vauto_logo_small.png" style="width:90px; height:auto;"></a>
                            <img v-else title="vAuto report is pending" src="@/assets/static/vauto/vauto_logo_small.png" style="width:90px; height:auto;" class="img-disabled">
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12 mt-10">
                      <inventory-appraisal-control
                        :key="currentInventory.id"
                        v-if="hasFeatureAccess('inventory.appraisal.view')"
                        :appraisedValue="mostRecentAppraisal"
                        displayMode="Compact"
                        :inventory="inventoryAppraisalParams"
                        v-on:on-new-appraisal="onAppraised"
                        v-on:on-appraisal-failed="onAppraised"
                        :runAutomatically="true"
                        :autoUpdateInventory="true"
                        :inventoryType="currentInventory.type">
                      </inventory-appraisal-control>
                    </div>
                    <div class="column is-12 mt-10" v-if="blackBookEnabled">
                      <inventory-black-book-control
                      :blackBookInformation="mostRecentBlackBookInformation"
                      :inventory="inventoryAppraisalParams"
                      v-on:on-new-blackbook-information="onBlackBookInformationRetrieved"
                      :runAutomatically="true"
                      :autoUpdateInventory="true"
                      :inventoryType="currentInventory.type"
                      v-on:on-black-book-failed="onBlackBookInformationRetrieved"
                      v-on:black-book-disabled="onBlackBookDisabled">
                      </inventory-black-book-control>
                    </div>
                    <div class="column is-12 mt-10">
                      <customer-info-control
                        :key="currentInventory.id"
                        :runAutomatically="true"
                        :customerNameLabel="'Purchased From'"
                        :customerId="currentInventory.purchasedFromId"
                        :customerMobile="currentInventory.purchasedFromContactMobile"
                        :customerEmail="currentInventory.purchasedFromContactEmail">
                      </customer-info-control>
                    </div>
                    <div class="column is-12 mt-10">
                      <div class="box">
                        <section-header title="Attachments"></section-header>
                        <div class="columns">
                          <div class="column is-4">
                            <button-list-selector validateAs="window sticker status" label="Window Sticker Status" :required="windowStickerStatusRequired" v-model="selectedWindowStickerStatus" :availableValues="definedTypes.windowStickerStatusOptions"></button-list-selector>
                          </div>
                          <div class="column is-4">
                            <image-selector
                              v-model="windowStickerFiles"
                              label="Window Sticker Image"
                              :disabled="windowStickerDisabled"
                              :required="false"
                              :isBoxed="false"
                              v-if="selectedWindowStickerStatus && selectedWindowStickerStatus.id === 0"
                              @uploadStarted="uploadStarted"
                              @uploadComplete="uploadComplete">
                            </image-selector>
                          </div>
                          <div class="column is-4">
                            <pdf-selector
                            v-model="windowStickerPdfFiles"
                            label="Window Sticker Pdf"
                            :enabled="!windowStickerDisabled"
                            :required="false"
                            :isBoxed="false"
                            v-if="selectedWindowStickerStatus && selectedWindowStickerStatus.id === 0"
                            @uploadStarted="uploadStarted"
                            @uploadComplete="uploadComplete"></pdf-selector>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
          <footer class="modal-card-foot">
            <div class="columns" style="width: 100%">
              <div class="column is-8 is-pulled-left">
              <button class="button is-success" v-if="!approvedSuccessfully && !isPpaClosed" :class="{'is-loading': isSaving }" @click="validateBeforeSubmit" :disabled="approvePpaConfirmDisallowed || isUploadingFile">Confirm</button>
                <button class="button is-danger" @click="onCancel()">{{approvedSuccessfully ? "Close" : "Cancel"}}</button>
              </div>
              <div class="column is-4">
                <div class="is-pulled-right is-flex">
                  <convert-ppa-to-purchase-action  :inventory="currentInventory" v-if="showPpaConversionButton" :usePortal="false" :invertSecondUsePortal="true"></convert-ppa-to-purchase-action>
                  <close-ppa-action v-if="!isPpaClosed" :inventoryItem="currentInventory" :usePortal="false" v-on:on-ppa-cancelled="onPpaCancelled"></close-ppa-action>
                  <div class="tags are-medium" v-if="currentInventory && (currentInventory.salesPerson && currentInventory.salesPerson !== '')">
                    <span class="tag is-info">{{ currentInventory.salesPerson }}</span>
                  </div>
                </div>
              </div>
              <!-- <div class="column is-4">
                <title-status-capture v-if="currentInventory" :currentInventory="currentInventory" :showDialog="false" :isListMode="false" :forPpaApproval="true"></title-status-capture>
              </div> -->
            </div>
          </footer>
        </div>
      </b-modal>
    </portal>
    <portal to="global-modal-portal" v-if="confirmTradeAllowanceChangeActive">
      <b-modal :active.sync="confirmTradeAllowanceChangeActive" :width="640" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">Change Proposed Trade Allowance</p>
          </header>
          <section class="modal-card-body">
              Do you want to change the proposed trade allowance of this ppa to match the ACV value?
          </section>
          <footer class="modal-card-foot">
            <button class="button is-success" @click="confirmTradeAllowanceChange()" type="button">Yes</button>
            <button class="button is-danger" @click="confirmTradeAllowanceChangeActive = false" type="button">No</button>
          </footer>
        </div>
      </b-modal>
    </portal>
    <auto-check-report-control
      :inventory="currentInventory"
      title="AutoCheck Vehicle Status"
      :vinNo="currentInventory.vinNo"
      v-on:on-hide-autocheck-report="onHideAutoCheckReport"
      :showAutoCheckReportModal="showReport"
      v-if="currentInventory && autoCheckEnabled">
    </auto-check-report-control>
    <car-fax-report-control
      title="CarFax Vehicle Status"
      v-on:on-hide-carfax-report="onHideCarFaxReport"
      :showCarFaxReportModal="showCarFaxReport"
      :carFaxReportUrl="carFaxReportUrl"
      v-if="currentInventory">
    </car-fax-report-control>
  </span>
</template>

<script>

import eventBus from '@/eventBus'
import moment from 'moment'
import inventoryService from '../../services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import SwitchSelector from '@/components/generic/SwitchSelector'
import formattingMixin from '@/mixins/inventory/formatting'
import AutoCheckControl from '@/components/generic/AutoCheckControl'
import AutoCheckReportControl from '@/components/generic/AutoCheckReportControl'
import CarFaxControl from '@/components/generic/CarFaxControl'
import CarFaxReportControl from '@/components/generic/CarFaxReportControl'
import CustomerInfoControl from '@/components/generic/CustomerInfoControl'
import InventoryAppraisalControl from '@/components/generic/InventoryAppraisalControl'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import SupplierSelector from '@/components/generic/SupplierSelector'
import InventoryTitleCapture from '@/components/generic/inventory/InventoryTitleCapture'
import utilitiesMixin from '@/mixins/generic/utilities'
import ClosePpaAction from '@/components/inventory/ClosePpaAction'
import SectionHeader from '@/components/generic/SectionHeader'
import DealerPeakControl from '@/components/generic/DealerPeakControl'
import ConvertPpaToPurchaseAction from '@/components/inventory/ConvertPpaToPurchaseAction'
import ImageSelector from '@/components/generic/ImageSelector'
import PdfSelector from '@/components/generic/PdfSelector'
import InventoryBlackBookControl from '@/components/generic/InventoryBlackBookControl'

import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'ApprovePpaAction',
  mixins: [validateBeforeSubmitMixin, formattingMixin, utilitiesMixin],
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    showStatusIcon: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    reloadList: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isApprovedPpaConfirmationActive: false,
      approvedSuccessfully: false,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4(),
      approvedPpaItem: null,
      selectedPriceAmendmentReason: null,
      onLoadProposedCost: 0,
      currentInventory: null,
      isLoading: false,
      initialProposedCost: 0,
      expirationDatePickerOptions: {
        disabledDate (date) {
          return date < new Date()
        }
      },
      approvePpaAutoCheckCheckComplete: true,
      approvePpaAutoCheckIsGood: true,
      approvePpaAutoCheckFailed: false,
      showReport: false,
      tabIndex: 0,
      modalWidth: (window.innerWidth * 70 / 100),
      approvePpaCarFaxCheckComplete: false,
      approvePpaCarFaxIsGood: false,
      approvePpaCarFaxFailed: false,
      showCarFaxReport: false,
      approvePpaAppraisalCheckComplete: false,
      approvePpaAppraisalIsGood: false,
      approvePpaAppraisalFailed: false,
      approvePpaAcceptConditions: false,
      selectedPpaIntention: null,
      selectedDispositionIntentionStatus: null,
      hasCommitment: false,
      selectedCommitmentFrom: null,
      selectedCpoStatus: null,
      selectedConditionType: null,
      confirmTradeAllowanceChangeActive: false,
      proposedTradeAllowance: 0,
      mileageEstimate: false,
      trueMileageUnknown: false,
      carFaxHashMatch: false,
      autoCheckHashMatch: false,
      appraisalRatingHashMatch: false,
      carFaxApproved: false,
      autoCheckApproved: false,
      appraisalApproved: false,
      windowStickerPdfFiles: [],
      windowStickerFiles: [],
      onLoadEstWholesaleValue: 0,
      windowStickerDisabled: false,
      selectedWindowStickerStatus: null,
      isUploadingFile: false,
      fileUploadCount: 0,
      blackBookInformation: null,
      blackBookCheckComplete: false,
      blackBookCheckIsGood: false,
      blackBookCheckFailed: false
    }
  },
  components: {
    'error-display-component': ErrorDisplayComponent,
    'switch-selector': SwitchSelector,
    'auto-check-control': AutoCheckControl,
    'auto-check-report-control': AutoCheckReportControl,
    'car-fax-control': CarFaxControl,
    'car-fax-report-control': CarFaxReportControl,
    'customer-info-control': CustomerInfoControl,
    'inventory-appraisal-control': InventoryAppraisalControl,
    'button-list-selector': ButtonListSelector,
    'supplier-selector': SupplierSelector,
    'title-status-capture': InventoryTitleCapture,
    'close-ppa-action': ClosePpaAction,
    'section-header': SectionHeader,
    'dealer-peak-control': DealerPeakControl,
    'convert-ppa-to-purchase-action': ConvertPpaToPurchaseAction,
    'image-selector': ImageSelector,
    'pdf-selector': PdfSelector,
    'inventory-black-book-control': InventoryBlackBookControl
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    isTradePpaIntention: function () {
      return this.selectedPpaIntention && this.selectedPpaIntention.name === 'Trade'
    },
    defaultProposedExpirationDate: function () {
      let defaultDate = moment.tz(new Date(), moment.tz.guess()).add((this.currentDealer.prePurchaseInspectionExpiry || 0), 'day').format('YYYY-MM-DD 00:00:00')
      return defaultDate
    },
    ppaInfo: function () {
      return this.currentInventory && (this.currentInventory.ppaExaminedBy !== '' && this.currentInventory.ppaExaminedAt !== null) ? this.currentInventory.ppaExaminedBy + ' - ' + this.$options.filters.formatDateOnlyShort(this.currentInventory.ppaExaminedAt) : ''
    },
    hasApprovePpaAccess: function () {
      let allowedPermissions = ['inventory.approve-ppa']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isTypePpaAppraisal: function () {
      return this.inventoryItem && this.inventoryItem.type === 'ForPpaAppraisal'
    },
    inventoryItemId: function () {
      return this.inventoryItem ? this.inventoryItem.id : null
    },
    modalTitle () {
      return this.approvedSuccessfully ? 'Pre-Purchase Appraisal Approved Successfully' : 'Confirm Value on Pre-Purchase Appraisal'
    },
    modalContent () {
      return this.approvedSuccessfully ? 'Inventory ' + this.stockNo + ' has been succesfully approved' : 'Are you sure you want to commit a value to the Pre-Purchase Appraisal - Inventory ' + this.stockNo
    },
    stockNo () {
      return this.inventoryItem ? this.inventoryItem.stockNo : ''
    },
    inventoryDetails () {
      return this.inventoryItem && this.inventoryItem.vehicleInfo ? this.inventoryItem.vehicleInfo : this.inventoryItem.name
    },
    canShowPpaAction () {
      return true
    },
    isApprovable () {
      return this.inventoryItem && this.isTypePpaAppraisal && !this.inventoryItem.ppaApproved
    },
    applicableIconTitle () {
      if (!this.isTypePpaAppraisal) return 'Action is not applicable to this inventory item.'
      if (this.inventoryItem && this.inventoryItem.ppaApproved) {
        if (this.isTradePpaIntention && !this.proposedTradeAllowance) {
          return 'PPA is approved for purchase but missing proposed trade allowance value.'
        }
        return 'PPA is approved for purchase.'
      }
      return 'Click to approve PPA'
    },
    formatIconByStatus () {
      var result = 'has-text-grey-light'
      if (this.isTypePpaAppraisal) {
        if (this.inventoryItem && this.inventoryItem.ppaApproved && (this.inventoryItem.ppaApprovalAutoExpired === 1 || this.inventoryItem.ppaApprovalAutoExpired === true)) {
          result = 'has-text-warning'
        } else if (this.inventoryItem && this.inventoryItem.ppaApproved && this.isTradePpaIntention && !this.proposedTradeAllowance) {
          result = 'has-text-orange'
        } else if (this.inventoryItem && this.inventoryItem.ppaApproved) {
          result = 'has-text-success'
        } else {
          result = 'has-text-danger'
        }
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    },
    getNotificationClass: function () {
      return this.approvedSuccessfully ? 'is-success' : 'is-info'
    },
    proposedCostChanged: function () {
      if (this.onLoadProposedCost && this.onLoadProposedCost > 0.01 && this.onLoadProposedCost !== this.currentInventory.proposedCost) {
        return true
      }
      return false
    },
    getReadyStatus: function () {
      return this.definedTypes.serviceRequestStatusType.options.find(x => x.id === this.currentInventory.getReadyStatusId).name
    },
    selectedInventoryStatusType: function () {
      return this.definedTypes.inventoryTypes.find((x) => (x.name === this.currentInventory.type || x.altName === this.currentInventory.type))
    },
    carFaxReportUrl: function () {
      let result = 'https://www.carfaxonline.com/cfm/Display_Dealer_Report.cfm?partner=VAU_0&UID=' + this.currentDealer.carFaxUserName + '&vin=' + this.currentInventory.vinNo
      return result
    },
    hasVautoAppraisal: function () {
      return this.currentInventory.vAutoAppId && this.currentInventory.vAutoAppId !== ''
    },
    mostRecentAppraisal: function () {
      if (this.currentInventory && this.currentInventory.appraisals && this.currentInventory.appraisals.length > 0) {
        return this.currentInventory.appraisals[0]
      }
      return null
    },
    inventoryAppraisalParams: function () {
      let trimAttribute = this.currentInventory.attributes.filter((x) => x.name === 'Trim')[0]
      let trim = trimAttribute ? trimAttribute.value : ''
      let condition = this.currentInventory.conditionType
      return {
        id: this.currentInventory.id,
        vinNo: this.currentInventory.vinNo,
        mileage: this.currentInventory.usage,
        trim: trim,
        condition: condition,
        msrp: this.currentInventory.msrp,
        purchaseCost: this.currentInventory.type === 'Consigned' ? this.currentInventory.consignmentMinimum : this.currentInventory.purchaseCostTotal,
        askingPrice: this.currentInventory.askingPrice
      }
    },
    approvePpaConfirmDisallowed: function () {
      if (this.approvePpaCarFaxIsGood && this.approvePpaAppraisalIsGood && this.approvePpaAutoCheckIsGood) {
        return false
      }
      return !this.approvePpaAcceptConditions
    },
    ppaIntentionTypes: function () {
      return this.definedTypes.ppaIntentionType.options
    },
    proposedCostLabel: function () {
      return this.isTradePpaIntention ? 'ACV Value' : 'Proposed Purchase Price'
    },
    proposedCostExpirationLabel: function () {
      return this.isTradePpaIntention ? 'ACV Value Expiration Date' : 'Purchase Price Expiration Date'
    },
    proposedTradeAllowanceRequired: function () {
      return false
    },
    wholesaleLimitPeriods: function () {
      return this.currentDealer.wholesaleLimitPeriods
    },
    hasTradeAllowanceModifyAccess: function () {
      return this.hasFeatureAccess('inventory.modify.ppa-trade-allowance')
    },
    minimumUsageAllowed: function () {
      if (this.currentInventory && this.currentInventory.vinUsageHistory && this.currentInventory.vinUsageHistory.length > 0) {
        return this.currentInventory.vinUsageHistory[this.currentInventory.vinUsageHistory.length - 1].usage
      }
      return this.lastVinUsage || 0
    },
    showPpaConversionButton: function () {
      return this.currentInventory && this.currentInventory.ppaApproved && this.currentInventory.status !== 'Archived' && !this.isPpaClosed
    },
    ppaAgreementApprovals: function () {
      if (this.inventoryItem && this.inventoryItem.approvals) {
        let data = this.inventoryItem.approvals.filter((x) => x.approvalRatingType === 'PpaApproval')
        if (data.length > 0) {
          return data
        }
      }
      return []
    },
    hasAutoCheckRatingApproval: function () {
      if (this.ppaAgreementApprovals && this.ppaAgreementApprovals.length > 0) {
        let data = this.ppaAgreementApprovals.filter((x) => x.ratingType === 'AutoCheckRating')
        if (data.length > 0) {
          return true
        }
      }
      return false
    },
    hasCarFaxRatingApproval: function () {
      if (this.ppaAgreementApprovals && this.ppaAgreementApprovals.length > 0) {
        let data = this.ppaAgreementApprovals.filter((x) => x.ratingType === 'CarFaxRating')
        if (data.length > 0) {
          return true
        }
      }
      return false
    },
    hasAppraisalRatingApproval: function () {
      if (this.ppaAgreementApprovals && this.ppaAgreementApprovals.length > 0) {
        let data = this.ppaAgreementApprovals.filter((x) => x.ratingType === 'AppraisalRating')
        if (data.length > 0) {
          return true
        }
      }
      return false
    },
    approvalInformation: function () {
      if (this.ppaAgreementApprovals && this.ppaAgreementApprovals.length > 0) {
        let latestApproval = this.ppaAgreementApprovals[0]
        let approvedBy = latestApproval.userApprovedBy
        let approvedAt = this.$options.filters.formatDate(latestApproval.approvedAt)
        return 'The vehicle\'s condition was approved by ' + approvedBy + ' (' + approvedAt + ').'
      }
      return ''
    },
    hasWindowStickerFiles: function () {
      if (this.windowStickerFiles && this.windowStickerFiles.length > 0) {
        return true
      }
      return false
    },
    hasWindowStickerPdfFiles: function () {
      if (this.windowStickerPdfFiles && this.windowStickerPdfFiles.length > 0) {
        return true
      }
      return false
    },
    isPpaClosed: function () {
      return this.currentInventory && this.currentInventory.type === 'PpaClosed'
    },
    showCopyAcv: function () {
      return this.isTradePpaIntention && this.currentInventory.proposedCost > 0 && (!this.proposedTradeAllowance || this.proposedTradeAllowance === 0)
    },
    windowStickerStatusRequired: function () {
      return false
    },
    autoCheckValidated: function () {
      if (this.currentDealer && this.currentDealer.autoCheckEnabledStatus) {
        return this.approvePpaAutoCheckIsGood && this.approvePpaAutoCheckCheckComplete
      } else {
        return true
      }
    },
    autoCheckEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.autoCheckEnabledStatus
      }
      return false
    },
    mostRecentBlackBookInformation: function () {
      if (this.blackBookInformation) {
        return this.blackBookInformation
      } else {
        if (this.currentInventory && this.currentInventory.blackBooks && this.currentInventory.blackBooks.length > 0) {
          return this.currentInventory.blackBooks[0]
        }
      }
      return null
    },
    blackBookEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.blackBookEnabledStatus
      }
      return false
    }
  },
  methods: {
    onPpaCancelled: function () {
      this.onCancel()
    },
    onSetProposedExpirationDate: function () {
      this.currentInventory.proposedCostExpirationDate = this.defaultProposedExpirationDate
    },
    onRequestApprovePpa: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      if (this.inventoryItem && this.inventoryItem.id) {
        this.isApprovedPpaConfirmationActive = true
        this.loadInventory(this.inventoryItem.id)
      }
    },
    onSave: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true

      let ppaApprovalModel = {
        id: this.currentInventory.id,
        proposedCost: this.currentInventory.proposedCost,
        dispositionIntention: this.selectedDispositionIntentionStatus ? this.selectedDispositionIntentionStatus.name : null,
        dispositionValidDays: +this.currentInventory.dispositionValidDays,
        commitmentFromId: this.selectedCommitmentFrom ? this.selectedCommitmentFrom.id : null,
        firmCommitment: !this.hasCommitment ? null : this.currentInventory.firmCommitment,
        commitmentValue: this.currentInventory.commitmentValue,
        ppaIntention: this.selectedPpaIntention.name,
        cpoStatus: this.selectedCpoStatus ? this.selectedCpoStatus.altName : null,
        conditionType: this.selectedConditionType ? this.selectedConditionType.altName : null,
        ppaPriceAmendmentReason: '',
        ppaAccepted: null,
        ppaExamined: this.currentInventory.ppaExamined,
        inspectionDependent: this.currentInventory.inspectionDependent,
        payoffAmount: this.currentInventory.payoffAmount,
        proposedCostExpirationDate: null,
        proposedTradeAllowance: null,
        usage: this.currentInventory.usage,
        mileageEstimate: this.mileageEstimate,
        trueMileageUnknown: this.trueMileageUnknown,
        mileageExempt: this.mileageExempt,
        attachments: [],
        windowStickerStatus: this.selectedWindowStickerStatus ? this.selectedWindowStickerStatus.name : null
      }

      if (this.currentInventory.proposedCost && this.currentInventory.proposedCost >= 0) {
        ppaApprovalModel.proposedCostExpirationDate = this.currentInventory.proposedCostExpirationDate
      }
      if (this.selectedPriceAmendmentReason) {
        ppaApprovalModel.ppaPriceAmendmentReason = this.selectedPriceAmendmentReason.altName
      }
      if (this.approvePpaAcceptConditions) {
        ppaApprovalModel.ppaAccepted = this.approvePpaAcceptConditions
      }
      if (this.isTradePpaIntention && this.proposedTradeAllowance !== null) {
        ppaApprovalModel.proposedTradeAllowance = this.proposedTradeAllowance
      }

      if (this.windowStickerPdfFiles.length > 0) {
        var stickerPdfFiles = this.windowStickerPdfFiles.map((x) => {
          return {
            id: x.id && x.id > 0 ? x.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'WindowSticker',
            status: x.status
          }
        })

        ppaApprovalModel.attachments.push(...stickerPdfFiles)
      }
      if (this.windowStickerFiles.length > 0) {
        var stickerFiles = this.windowStickerFiles.map((x) => {
          return {
            id: x.id && x.id > 0 ? x.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'WindowSticker',
            status: x.status
          }
        })

        ppaApprovalModel.attachments.push(...stickerFiles)
      }

      inventoryService.approvePpa(ppaApprovalModel).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.approvedPpaItem = response
          this.approvedSuccessfully = true
          this.approvePpaAutoCheckCheckComplete = true
          this.approvePpaAutoCheckIsGood = true
          this.approvePpaAutoCheckFailed = false
          this.approvePpaCarFaxCheckComplete = false
          this.approvePpaCarFaxIsGood = false
          this.approvePpaCarFaxFailed = false
          this.approvePpaAppraisalCheckComplete = false
          this.approvePpaAppraisalIsGood = false
          this.approvePpaAppraisalFailed = false
          this.approvePpaAcceptConditions = false
          this.blackBookCheckComplete = !this.blackBookEnabled

          // use emit here so that if we in inventory edit screen we dont get prompted with changes alert
          eventBus.$emit('on-changes-cancelled')
          this.onCancel()
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onCancel: function () {
      this.$validator.pause()
      if (this.approvedSuccessfully && this.approvedPpaItem && this.reloadList) {
        eventBus.$emit('inventory-changed', [this.approvedPpaItem])
        this.$router.push({name: this.lastActiveListPage !== '' ? this.lastActiveListPage : 'ListInventory'})
        this.isApprovedPpaConfirmationActive = false
        this.reset()
      }

      if (this.approvedSuccessfully && this.approvedPpaItem && !this.reloadList) {
        eventBus.$emit('reload-deal')
        eventBus.$emit('inventory-changed', [this.approvedPpaItem])
        this.isApprovedPpaConfirmationActive = false
        this.reset()
      }

      this.isApprovedPpaConfirmationActive = false
      this.reset()
    },
    reset: function () {
      this.approvedSuccessfully = false
      this.approvedPpaItem = null
      this.serverErrors = []
      this.serverErrorMessage = []
      this.approvePpaAutoCheckCheckComplete = true
      this.approvePpaAutoCheckIsGood = true
      this.approvePpaAutoCheckFailed = false
      this.approvePpaCarFaxCheckComplete = false
      this.approvePpaCarFaxIsGood = false
      this.approvePpaCarFaxFailed = false
      this.approvePpaAppraisalCheckComplete = false
      this.approvePpaAppraisalIsGood = false
      this.approvePpaAppraisalFailed = false
      this.approvePpaAcceptConditions = false
      this.mileageEstimate = false
      this.trueMileageUnknown = false
      this.mileageExempt = false
      this.selectedWindowStickerStatus = null
      this.windowStickerFiles = []
      this.windowStickerPdfFiles = []
      this.blackBookCheckComplete = !this.blackBookEnabled
    },
    loadInventory (id) {
      this.isLoading = true
      inventoryService.getForPpaApproval(id).then(response => {
        this.currentInventory = response.inventory
        this.currentInventory.inspectionDependent = true
        this.selectedPriceAmendmentReason = this.definedTypes.ppaRevisionReasonType.options.find(x => x.altName === this.currentInventory.ppaPriceAmendmentReason)
        this.selectedPpaIntention = this.definedTypes.ppaIntentionType.options.find(x => x.name === this.currentInventory.ppaIntention)
        this.selectedDispositionIntentionStatus = this.definedTypes.dispositionIntentionStatuses.find(x => x.altName === this.currentInventory.dispositionIntention)
        this.mileageEstimate = this.currentInventory.mileageEstimate
        this.trueMileageUnknown = this.currentInventory.trueMileageUnknown
        this.mileageExempt = this.currentInventory.mileageExempt
        if (this.currentInventory.cpoStatus) {
          this.selectedCpoStatus = this.definedTypes.cpoStatusTypes.find(x => x.altName === this.currentInventory.cpoStatus)
        }
        if (this.currentInventory.conditionType) {
          this.selectedConditionType = this.definedTypes.inventoryConditionType.find(x => x.altName === this.currentInventory.conditionType)
        }
        this.onLoadProposedCost = this.currentInventory.proposedCost
        if (!this.currentInventory.proposedCostExpirationDate) {
          this.currentInventory.proposedCostExpirationDate = this.defaultProposedExpirationDate
        }
        this.proposedTradeAllowance = this.currentInventory.proposedTradeAllowance
        if (this.currentInventory.commitmentFromId > 0) {
          this.selectedCommitmentFrom = {
            id: this.currentInventory.commitmentFromId,
            name: this.currentInventory.commitmentFromName
          }
        }
        this.setInitialProposedCost()
        this.selectedWindowStickerStatus = this.definedTypes.windowStickerStatusOptions.find((x) => (x.name === this.currentInventory.windowStickerStatus || x.altName === this.currentInventory.windowStickerStatus))
        if (this.currentInventory.lockWindowSticker) {
          this.windowStickerDisabled = true
        }
        this.currentInventory.attachments.forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.fileType = this.getFileType(x.fileLocation)

          if (x.type !== 'Pdf' && x.classType === 'WindowSticker' && x.status === 'Active') {
            this.windowStickerFiles.push(x)
          }
          if (x.type === 'Pdf') {
            if (x.classType === 'WindowSticker' && x.status === 'Active') {
              this.windowStickerPdfFiles.push(x)
            }
          }
        })
        this.isLoading = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
      })
    },
    setInitialProposedCostAsCurrent: function () {
      this.currentInventory.proposedCost = this.initialProposedCost
    },
    setInitialProposedCost: function () {
      if (this.currentInventory && this.currentInventory.proposedCosts && this.currentInventory.proposedCosts.length > 0) {
        let lastPosition = this.currentInventory.proposedCosts.length - 1
        this.initialProposedCost = this.currentInventory.proposedCosts[lastPosition].proposedCost
      }
    },
    resetProposedCostAsCurrent: function () {
      this.currentInventory.proposedCost = this.onLoadProposedCost
    },
    onAutoCheckForApprovePpa: function (autoCheckRating) {
      this.approvePpaAutoCheckCheckComplete = true
      this.approvePpaAutoCheckIsGood = autoCheckRating && !((autoCheckRating.accidentCount && autoCheckRating.accidentCount > 0) || (autoCheckRating.recallCount && autoCheckRating.recallCount > 0))
      if (this.inventoryItem && this.inventoryItem.autoCheckRating && this.inventoryItem.autoCheckRating.hashValue && autoCheckRating && autoCheckRating.hashValue) {
        this.autoCheckHashMatch = (this.inventoryItem.autoCheckRating.hashValue === autoCheckRating.hashValue)
      } else {
        this.autoCheckHashMatch = false
      }
      this.approvePpaAutoCheckFailed = !autoCheckRating
      if (this.autoCheckHashMatch === true && this.hasAutoCheckRatingApproval === true) {
        this.approvePpaAutoCheckIsGood = true
        this.autoCheckApproved = true
        this.approvePpaAutoCheckFailed = false
      } else if (this.inventoryItem && !autoCheckRating && this.inventoryItem.hasRatingApproval === true) {
        this.approvePpaAutoCheckIsGood = true
        this.autoCheckApproved = true
        this.approvePpaAutoCheckFailed = false
      }
    },
    onShowAutoCheckReport: function () {
      this.isApprovedPpaConfirmationActive = false
      this.showReport = true
    },
    onHideAutoCheckReport: function () {
      this.showReport = false
      this.isApprovedPpaConfirmationActive = true
    },
    onCarFaxForApprovePpa: function (carFaxRating) {
      this.approvePpaCarFaxCheckComplete = true
      this.approvePpaCarFaxIsGood = carFaxRating && !(carFaxRating.brandedTitle === true || (carFaxRating.totalAccidents && carFaxRating.totalAccidents > 0) || carFaxRating.accidentIndicator === true || carFaxRating.frameDamageOrRepair === true || carFaxRating.airBagDeployed === true || carFaxRating.damaged === true || carFaxRating.majorProblems === true || carFaxRating.recall === true)

      if (this.inventoryItem && this.inventoryItem.carFaxRating && this.inventoryItem.carFaxRating.hashValue && carFaxRating && carFaxRating.hashValue) {
        this.carFaxHashMatch = (this.inventoryItem.carFaxRating.hashValue === carFaxRating.hashValue)
      } else {
        this.carFaxHashMatch = false
      }
      this.approvePpaCarFaxFailed = !carFaxRating
      if (this.carFaxHashMatch === true && this.hasCarFaxRatingApproval === true) {
        this.approvePpaCarFaxIsGood = true
        this.carFaxApproved = true
        this.approvePpaCarFaxFailed = false
      } else if (this.inventoryItem && (!this.inventoryItem.carFaxRating || !carFaxRating) && this.inventoryItem.hasRatingApproval === true) {
        this.approvePpaCarFaxIsGood = true
        this.carFaxApproved = true
        this.approvePpaCarFaxFailed = false
      }
    },
    onShowCarFaxReport: function () {
      this.isApprovedPpaConfirmationActive = false
      this.showCarFaxReport = true
    },
    onHideCarFaxReport: function () {
      this.showCarFaxReport = false
      this.isApprovedPpaConfirmationActive = true
    },
    onAppraised: function (appraisal) {
      this.approvePpaAppraisalCheckComplete = true
      if (appraisal && appraisal !== '') {
        this.approvePpaAppraisalIsGood = appraisal && appraisal.isGood
        this.currentInventory.appraisals.unshift(appraisal)
        if (this.inventoryItem && this.inventoryItem.appraisalRating && this.inventoryItem.appraisalRating.hashValue && appraisal && appraisal.hashValue) {
          this.appraisalRatingHashMatch = (this.inventoryItem.appraisalRating.hashValue === appraisal.hashValue)
        } else {
          this.appraisalRatingHashMatch = false
        }
        if (this.appraisalRatingHashMatch === true && this.hasAppraisalRatingApproval === true) {
          this.approvePpaAppraisalIsGood = true
          this.appraisalApproved = true
          this.approvePpaAppraisalFailed = false
        } else if (this.inventoryItem && !this.inventoryItem.appraisalRating && this.inventoryItem.hasRatingApproval === true) {
          this.approvePpaAppraisalIsGood = true
          this.appraisalApproved = true
          this.approvePpaAppraisalFailed = false
        }
      } else {
        this.approvePpaAppraisalFailed = !appraisal
        if (this.inventoryItem && this.inventoryItem.hasRatingApproval === true) {
          this.approvePpaAppraisalIsGood = true
          this.appraisalApproved = true
          this.approvePpaAppraisalFailed = false
        }
      }
    },
    wholesaleDayAdded: function (newItem) {
      this.currentInventory.dispositionValidDays = newItem
    },
    confirmTradeAllowanceChange: function () {
      let that = this
      that.$nextTick(() => {
        that.proposedTradeAllowance = that.currentInventory.proposedCost
        that.confirmTradeAllowanceChangeActive = false
      })
    },
    onProposedCostChange: function () {
      if (this.isTradePpaIntention && this.proposedCostChanged) {
        if (this.proposedTradeAllowance && this.proposedTradeAllowance > 0) {
          this.confirmTradeAllowanceChangeActive = false
        }
      }
    },
    onCopyInfo: function (text) {
      let that = this
      this.$copyText(text).then(function (e) {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: text + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
      }, function (e) {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: 'Could not copy info to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
      })
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      if (inventoryItem && inventoryItem.stockNo !== '') {
        this.$copyText(inventoryItem.stockNo).then(
          function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
              position: 'is-top',
              type: 'is-info'
            })
          }, function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Could not copy stock no. to clipboard',
              position: 'is-top',
              type: 'is-warning'
            })
          }
        )
      }
    },
    onCopyProposedCostToTradeAllowance: function () {
      this.proposedTradeAllowance = this.currentInventory.proposedCost
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    onAutoCheckDisabled: function () {
      this.approvePpaAutoCheckCheckComplete = true
    },
    onBlackBookInformationRetrieved: function (blackBook) {
      this.blackBookCheckComplete = true
      this.blackBookInformation = blackBook
      this.blackBookCheckFailed = !blackBook
      if (blackBook && blackBook.vinNo && blackBook.vinNo.length > 0) {
        this.blackBookCheckIsGood = true
      } else {
        this.blackBookCheckIsGood = false
      }
    },
    onBlackBookDisabled: function () {
      this.blackBookCheckComplete = true
      this.blackBookCheckIsGood = true
      this.blackBookCheckFailed = false
    }
  },
  watch: {
    inventoryItem: function (newValue, oldValue) {
      if (newValue) {
        this.selectedPpaIntention = this.definedTypes.ppaIntentionType.options.find(x => x.name === newValue.ppaIntention)
        this.proposedTradeAllowance = newValue.proposedTradeAllowance
      } else {
        this.selectedPpaIntention = null
        this.proposedTradeAllowance = null
      }
    },
    inventoryItemId: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.reset()
      }
    },
    selectedDispositionIntentionStatus: function (newValue, oldValue) {
      if (this.currentInventory) {
        let originalDispositionIntention = this.currentInventory.dispositionIntention
        if (originalDispositionIntention === newValue) {
          if (newValue && (newValue.id === 2 || newValue.id === 3)) {
            if (this.currentInventory.commitmentFromId !== null && this.currentInventory.commitmentFromId > 0) {
              this.hasCommitment = true
              this.selectedCommitmentFrom = {
                id: this.currentInventory.commitmentFromId,
                name: this.currentInventory.commitmentFromName
              }
            } else {
              this.hasCommitment = false
            }
          }
        } else {
          this.hasCommitment = false
          this.currentInventory.firmCommitment = null
          this.selectedCommitmentFrom = null
          this.currentInventory.dispositionValidDays = null
        }
      }
    },
    mileageEstimate: function (newValue, oldValue) {
      if (newValue === false) {
        this.trueMileageUnknown = false
        this.mileageExempt = false
      }
    },
    mostRecentAppraisal: function (newValue, oldValue) {
      if (newValue) {
        this.onLoadEstWholesaleValue = newValue.estWholesaleValue
      }
    }
  },
  mounted () {
    this.selectedPpaIntention = this.definedTypes.ppaIntentionType.options.find(x => x.name === this.inventoryItem.ppaIntention)
    this.proposedTradeAllowance = this.inventoryItem.proposedTradeAllowance
    this.$emit('on-mounted-callback')
    if (!this.currentDealer || !this.currentDealer.blackBookEnabled) {
      this.blackBookCheckComplete = true
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .vauto-container {
    margin-top: 20px;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
  }

  .vauto-container-display-only {
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    margin-top:10px;
  }

  .modal-fullscreen .modal-card {
    max-width: 100%;
    margin: 0;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    position: fixed;
    z-index: 100000;
    max-height: 100% !important;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(110vh - 350px);
  }

</style>

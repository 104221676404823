<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Add Customer</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmit" @change="onFormChange">
      <b-tabs size="is-small" v-model="tabIndex" :animated="false" type="is-toggle">
        <b-tab-item label="Definition" icon-pack="fas"  icon="fas fa-info-circle fa-sm">
          <div class="columns">
            <div class="column is-6" v-show="false">
              <dealer-selector v-model="selectedDealer"></dealer-selector>
            </div>
            <div class="column is-6">
              <button-list-selector validateAs="customer type" label="Type" :required="true" v-model="selectedType" :availableValues="definedTypes.customerTypes.options"></button-list-selector>
            </div>
            <div class="column is-6 is-flex">
              <switch-selector type="is-info" label="Partial Fill" v-model="isPartial"></switch-selector>
              <span style="margin-left: 40px;"><switch-selector type="is-info" label="Is Trusted" v-model="isTrusted"></switch-selector></span>
              <span style="margin-left: 40px;"><switch-selector type="is-info" label="Is Blocked" v-model="isBlocked"></switch-selector></span>
              <span style="margin-left: 40px;"><switch-selector type="is-info" label="Allow Payment without Title" v-model="allowPaymentWithoutTitle"></switch-selector></span>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">
                  First Name
                  <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                  <dealer-peak-control v-if="selectedCustomerMatch && selectedCustomerMatch.customerId" :customer="selectedCustomerMatch"></dealer-peak-control>
                  </label>
                <div class="control">
                  <input name="firstName" data-vv-as="first name"  v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-1.firstName') }" type="text" placeholder="e.g John" v-model="firstName" data-vv-scope="page-1" autocomplete="work-now">
                  <span v-show="errors.has('page-1.firstName')" class="help is-danger">
                    <i v-show="errors.has('page-1.firstName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.firstName') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Last Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <input name="lastName" data-vv-as="last name"  v-validate="'required'"  :class="{'input': true, 'is-danger': errors.has('page-1.lastName') }" type="text" placeholder="e.g Doe" v-model="lastName" data-vv-scope="page-1" autocomplete="work-now">
                </div>
                <span v-show="errors.has('page-1.lastName')" class="help is-danger">
                  <i v-show="errors.has('page-1.lastName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.lastName') }}
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Middle Name</label>
                <div class="control">
                  <input name="middleName" data-vv-as="middle name" :class="{'input': true, 'is-danger': errors.has('middleName') }" type="text" placeholder="e.g Ben" v-model="middleName">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Initials</label>
                <div class="control">
                  <input name="initials" data-vv-as="initials" :class="{'input': true, 'is-danger': errors.has('initials') }" type="text" placeholder="e.g JD" v-model="initials">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Customer Number</label>
                <div class="control">
                  <input name="customerNumber" data-vv-as="customer number" :class="{'input': true, 'is-danger': errors.has('customerNumber') }" type="text" v-model="customerNo">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Company Name<b-icon pack="fas" icon="star" class="fa-ss" v-if="isCompanyNameRequired"></b-icon></label>
                <div class="control">
                  <input name="companyName" data-vv-as="company name" v-validate="{'required':isCompanyNameRequired}" :class="{'input': true, 'is-danger': errors.has('companyName') }" type="text" placeholder="e.g EAG" v-model="companyName">
                </div>
                <span v-show="errors.has('companyName')" class="help is-danger">
                  <i v-show="errors.has('companyName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('companyName') }}
                </span>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Email Address<b-icon pack="fas" icon="star" class="fa-ss" v-if="isEmailRequired"></b-icon></label>
                <div class="control has-icons-left has-icons-right">
                  <input name="email" data-vv-as="email address" v-validate="{'email': true, 'required': isEmailRequired}" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has('page-1.email') }" type="text" placeholder="Email" v-model="email" @change="findByEmail" data-vv-scope="page-1" data-lpignore="true" autocomplete="work-now">
                  <span v-show="errors.has('page-1.email')" class="help is-danger">
                    <i v-show="errors.has('page-1.email')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.email') }}
                  </span>
                  <span class="icon is-small is-left">
                    <i class="fas fa-envelope"></i>
                  </span>
                  <span class="icon is-small is-right" v-show="!errors.has('page-1.email')">
                    <i class="fas fa-check"></i>
                  </span>
                  <span class="icon is-small is-right" v-show="errors.has('page-1.email')">
                    <i class="fas fa-exclamation-triangle"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-6"></div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Mobile<b-icon pack="fas" icon="star" class="fa-ss" v-if="isMobileRequired"></b-icon></label>
                <div class="control">
                  <input name="mobile" v-validate="{'min': 16, 'max': 16, 'required': isMobileRequired}" data-vv-as="mobile" :class="{'input': true, 'is-danger': errors.has('page-1.mobile') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="mobile" @change="findByMobile" data-vv-scope="page-1" data-lpignore="true" autocomplete="work-now">
                  <span v-show="errors.has('page-1.mobile')" class="help is-danger">
                    <i v-show="errors.has('page-1.mobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.mobile') }}
                  </span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Home</label>
                <div class="control">
                  <input name="home" data-vv-as="home" :class="{'input': true, 'is-danger': errors.has('home') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="home" data-lpignore="true" autocomplete="work-now">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Office</label>
                <div class="control">
                  <input name="office" data-vv-as="office" :class="{'input': true, 'is-danger': errors.has('office') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="office" data-lpignore="true" autocomplete="work-now">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Fax</label>
                <div class="control">
                  <input name="fax" data-vv-as="fax" :class="{'input': true, 'is-danger': errors.has('fax') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="fax" data-lpignore="true" autocomplete="work">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Social Security No.</label>
                <div class="control">
                  <input name="ssn" data-vv-as="social security number" :class="{'input': true, 'is-danger': errors.has('ssn') }" type="text" v-model="ssn" v-mask="'###-##-####'">
                </div>
              </div>
            </div>
            <div class="column is-narrow">
              <b-field label="Date of Birth">
                <el-date-picker
                    @change="onFormChange()"
                    v-model="dateOfBirth"
                    type="date"
                    format="MM-dd-yyyy"
                    placeholder="Click to select..."
                    :clearable="false"
                    :editable="false">
                </el-date-picker>
              </b-field>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <button-list-selector validateAs="marital status" label="Marital Status" :required="false" v-model="selectedMaritalStatus" :availableValues="definedTypes.customerMaritalStatus.options" scope="page-1"></button-list-selector>
            </div>
            <div class="column is-6">
              <button-list-selector validateAs="employment status" label="Employment Status" :required="false" v-model="selectedEmploymentStatus" :availableValues="definedTypes.customerEmploymentStatus.options" scope="page-1"></button-list-selector>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <div class="field">
                <label class="label">Drivers License No.</label>
                <div class="control">
                  <input name="driverLicenseNumber" data-vv-as="driver license number" :class="{'input': true, 'is-danger': errors.has('driverLicenseNumber') }" type="text" v-model="driverLicenseNumber">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <state-selector label="Drivers License State" v-model="selectedState" name="driversLicenseStateSelector" data-vv-as="drivers license state" :class="{'is-danger': errors.has('driversLicenseStateSelector') }" placeholder="State" :required="false"></state-selector>
            </div>
          </div>
          <div class="columns" v-if="selectedType && selectedType.name === 'Individual' && selectedMaritalStatus && selectedMaritalStatus.name === 'Married'">
            <div class="column is-6">
              <div class="field">
                <label class="label">Spouse</label>
                <div class="control">
                  <multiselect
                  :showLabels="false"
                  name="spouse"
                  :taggable="false"
                  :searchable="true"
                  :disabled="false"
                  v-model="selectedSpouse"
                  :options="allSpouse"
                  label="fullName"
                  trackBy="id"
                  :loading="isSpouseLoading"
                  placeholder="Type to search for a customer"
                  :internal-search="false"
                  :options-limit="300"
                  @search-change="onSpouseSearch"></multiselect>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <switch-selector type="is-info" label="Identity Verification Terms Conditions Accepted" v-model="isTermsAccepted" :isDisabled="true"></switch-selector>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Identity Verification Terms Conditions Accepted At</label>
                <div class="control">
                  <el-date-picker
                    @change="onFormChange()"
                    v-model="this.termsAcceptedAt"
                    type="date"
                    format="MM-dd-yyyy"
                    placeholder="Click to select..."
                    :clearable="false"
                    :editable="false"
                    :disabled="true">
                </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <switch-selector type="is-info" label="Credit Application Terms Conditions Accepted" v-model="isCreditTermsAccepted" :isDisabled="true"></switch-selector>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Credit Application Terms Conditions Accepted At</label>
                <div class="control">
                  <el-date-picker
                    @change="onFormChange()"
                    v-model="this.creditTermsAcceptedAt"
                    type="date"
                    format="MM-dd-yyyy"
                    placeholder="Click to select..."
                    :clearable="false"
                    :editable="false"
                    :disabled="true">
                </el-date-picker>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Addresses" icon-pack="fas" icon="fas fas fa-address-card fa-sm">
          <div id="address-section" class="box">
            <section-header title="Addresses" parentSelector="#address-section"></section-header>
            <div v-for="(address, index) in addresses" :key="address.id" >
              <address-capture scope="page-2" :useValidator="thisValidator" v-on:primary-address-set="onAutoSelectPrimaryAddress" v-on:unselect-other="onUnselectNonPrimary" v-model="addresses[index]" :isAccessPrivateInfo="hasPrivateInfoAccess" :isCustomer="true" :box="false" :required="isAddressRequired"></address-capture>
              <div class="inner-add-remove-button">
                <a class="button is-primary" @click="addAddress({})">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" v-if="addresses && addresses.length > 1" @click="removeAddress(address)">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
              <br />
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Work History" icon-pack="fas" icon="fas fa-briefcase fa-sm">
          <div id="work-history-section" class="box">
            <section-header title="Work History" parentSelector="#work-history-section"></section-header>
            <div v-for="(history, index) in workHistory" :key="history.id">
              <address-capture v-model="workHistory[index]" :isWorkHistory="true" :box="false"></address-capture>
              <br/>
            </div>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addWorkHistory({})">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
              <a class="button is-danger" v-if="this.workHistory.length > 1" @click="removeWorkHistory()">
                <span class="icon">
                  <i class="fas fa-minus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Personal References" icon-pack="fas" icon="fas fa-user-friends fa-sm">
          <div id="personal-reference-section" class="box">
            <section-header title="Personal References" parentSelector="#personal-reference-section"></section-header>
            <div v-for="(reference, index) in personalReferences" :key="reference.id">
              <address-capture v-model="personalReferences[index]" :isContact="true" :box="false" :useValidator="thisValidator"></address-capture>
              <br/>
            </div>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addPersonalReference({})">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
              <a class="button is-danger" v-if="this.personalReferences.length > 1" @click="removePersonalReference()">
                <span class="icon">
                  <i class="fas fa-minus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Relatives" icon-pack="fas" icon="fas fa-user fa-sm">
          <div id="relative-section" class="box">
            <section-header title="Relatives" parentSelector="#relative-section"></section-header>
            <div v-for="(relative, index) in relatives" :key="relative.id">
              <address-capture v-model="relatives[index]" :isContact="true" :box="false" :useValidator="thisValidator"></address-capture>
              <br/>
            </div>
            <div class="inner-add-remove-button">
              <a class="button is-primary" v-if="this.relatives.length === 0" @click="addRelative({})">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
              <a class="button is-danger" v-if="this.relatives.length > 1" @click="removeRelative()">
                <span class="icon">
                  <i class="fas fa-minus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Bank" icon-pack="fas" icon="fas fa-info-circle fa-sm">
          <bank-details-capture @formchange="onFormChange()" v-model="bankDetails" :getValidationErrors.sync="getValidationErrors" :useValidator="thisValidator" scope="page-6" :bankDetailsRequired="isBankRequired"></bank-details-capture>
        </b-tab-item>
        <b-tab-item label="Photo" icon-pack="fas" icon="fas fa-camera fa-sm">
          <div class="columns">
            <div class="column is-6">
              <license-selector
               v-model="driversPhotoFiles"
               label="Drivers License"
               :multiple="false"
               @uploadStarted="uploadStarted"
               @uploadComplete="cropDriverPhoto(0)"
               :croppedCustomerImages="croppedDriverLicenseImages">
              </license-selector>
              <div class="inner-add-remove-button" v-if="showCropDriversLicenseExactSize">
                <a class="button is-info" title="Crop drivers license photo to match exact size?" @click="showCropDriversPhotoModal(1)">
                  <span class="icon">
                    <i class="fas fa-crop"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="columns" v-if="stateRequiredBackOfDriversLicense">
            <div class="column is-6">
              <license-selector
                v-model="backOfDriversPhotoFiles"
                label="Back of Drivers License"
                :multiple="false"
                @uploadStarted="uploadStarted"
                @uploadComplete="backOfDriversLicenseUploaded"
                :croppedCustomerImages="croppedBackOfDriverLicenseImages">
              </license-selector>
              <div class="inner-add-remove-button" v-if="showCropBackOfDriversLicenseExactSize">
                <a class="button is-info" title="Crop back of drivers license photo to match exact size?" @click="showCropDriversPhotoModal(2)">
                  <span class="icon">
                    <i class="fas fa-crop"></i>
                  </span>
                </a>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <file-selector
              v-model="insurancePhotoFiles"
              label="Insurance Card"
              :multiple="false"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete">
              </file-selector>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <file-selector
              v-model="customerPhotoFiles"
              :multiple="false"
              label="Customer Photo"
              :required="false"
              :usePortalViewer="false"
              :croppedCustomerImages="croppedCustomerImages"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete">
              </file-selector>
              <div class="inner-add-remove-button" v-if="showCropDriverPhotoButton">
                <button :disabled="isUploadingFile" class="button is-info" title="Crop drivers license photo to use as customer photo?" @click="showCropDriversPhotoModal(0)">
                  <i class="fas fa-crop"></i>
                </button>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Additional Email/Mobile" icon-pack="fas" icon="fas fa-envelope fa-sm">
          <div class="box configuration-content-block">
            <div class="columns">
              <div class="column is-6">
                <div id="email-section" class="box">
                  <section-header title="Emails" parentSelector="#email-section"></section-header>
                  <div v-for="(emailAddress, index) in emailAddresses" :key="emailAddress.id">
                    <email-capture scope="page-8" :index="index" @on-email-remove="removeEmail(emailAddress)" v-model="emailAddresses[index]" :useValidator="thisValidator" :isRequired="true" :isUniqueEmail="true"></email-capture>
                    <br/>
                  </div>
                  <div class="inner-add-remove-button">
                    <a class="button is-primary" @click="addEmailAddress()">
                      <span class="icon">
                        <i class="fas fa-plus"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div id="contact-number-section" class="box">
                  <section-header title="Contact Numbers" parentSelector="#contact-number-section"></section-header>
                  <div v-for="(contactNumber, index) in contactNumbers" :key="contactNumber.id">
                    <contact-number-capture scope="page-8" :index="index" @on-contact-number-remove="removeContactNumber(contactNumber)" v-model="contactNumbers[index]" :isRequired="true" :useValidator="thisValidator" :isUniqueNumber="true"></contact-number-capture>
                    <br/>
                  </div>
                  <div class="inner-add-remove-button">
                    <a class="button is-primary" @click="addContactNumber()">
                      <span class="icon">
                        <i class="fas fa-plus"></i>
                      </span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-primary" :disabled="isSaving || isUploadingFile" :class="{'is-loading': isSaving }"  type="button" @click="validateBeforeSubmitAllScoped">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>
    <b-modal :active.sync="duplicateCustomerActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Matching Customers Found</p>
        </header>
        <section class="modal-card-body small-top-padding has-background-grey-lighter">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <div class="box">
            <div class="notification is-flex is-warning mt-5">
              <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
              <span class="ml-10">
                These customer records have matching emails or phone numbers
              </span>
            </div>
            <table id="duplicate-customers-table" class="table is-bordered is-fullwidth mt-10">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Mobile</th>
                  <th>Active Deals</th>
                  <th>Booked Deals</th>
                  <th>DP Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tr v-for="(customer) in this.customerList" :key="customer.id">
                <td>
                  <a class="is-link" @click="openCustomerRecord(customer)">
                    {{customer.fullName}}
                  </a>
                </td>
                <td>{{customer.email}}</td>
                <td>{{customer.mobile}}</td>
                <td>{{customer.activeDealCount}}</td>
                <td>{{customer.bookedDealCount}}</td>
                <td v-if="customer.isDpLinked"><dealer-peak-control v-if="customer && customer.customerId" :customerId="customer.id"></dealer-peak-control></td>
                <td v-else>Not Linked</td>
                <td><a class="button is-success" @click="openCustomerRecord(customer)">Select</a></td>
              </tr>
            </table>
          </div>
        </section>
        <footer class="modal-card-foot">
          <div class="columns is-multiline">
            <div class="column is-12"><span class="tag is-danger is-medium">Please select a matching customer above OR create a new customer record</span></div>
            <div class="column is-12">
              <button class="button is-success" @click="closeDuplicateCustomer">Create New Customer</button>
              <button class="button is-danger" @click="cancelCustomerCreate">Cancel</button>
            </div>
          </div>
        </footer>
      </div>
    </b-modal>
    <b-modal :active.sync="cropDriverPhotoActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">{{cropModalHeader}}</p>
        </header>
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <vue-cropper v-show="selectedFile" ref='cropper' :src="selectedFile" alt='Source Image'></vue-cropper>
            </div>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="onSaveCroppedDriverPhoto">Save</button>
          <button class="button is-danger" @click="closeCropDriverPhoto">Cancel</button>
        </footer>
      </div>
    </b-modal>
    <portal to="global-modal-portal" v-if="driversLicenseSizeConfirmationActive">
      <b-modal :active.sync="driversLicenseSizeConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
              <p class="modal-card-title">Confirm Exact Size</p>
          </header>
          <section class="modal-status-body">
            <div class="notification is-flex is-warning">
              <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
              <span class="ml-10">
                {{driversLicenseExactSizeModalText}}
              </span>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmDriversLicenseExactSize(true)" type="button">Yes</button>
            <button class="button is-danger" @click="onConfirmDriversLicenseExactSize(false)" type="button">No</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </section>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
import customerService from '@/services/customerService'
import AddressCapture from '@/components/generic/AddressCapture'
import DealerSelector from '@/components/generic/DealerSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import FileSelector from '@/components/generic/FileSelector'
import ImageSelector from '@/components/generic/ImageSelector'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import SectionHeader from '@/components/generic/SectionHeader'
import customerMixin from '@/mixins/customer/index'
import BankDetailsCapture from '@/components/generic/BankDetailsCapture'
import SwitchSelector from '@/components/generic/SwitchSelector'
import DealerPeakControl from '@/components/generic/DealerPeakControl'
import EmailCapture from '@/components/generic/EmailCapture'
import ContactNumberCapture from '@/components/generic/ContactNumberCapture'
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css'
import util from '@/utils'
import stateSelector from '@/components/generic/StateSelector'
import LicenseSelector from '@/components/generic/LicenseSelector'

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapSubscriberGetters = createNamespacedHelpers('subscriber').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapAllStatesGetters = createNamespacedHelpers('states').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'NewCustomer',
  mixins: [validateBeforeSubmitMixin, customerMixin],
  components: {
    'address-capture': AddressCapture,
    'dealer-selector': DealerSelector,
    'section-header': SectionHeader,
    'button-list-selector': ButtonListSelector,
    'file-selector': FileSelector,
    'image-selector': ImageSelector,
    'bank-details-capture': BankDetailsCapture,
    'switch-selector': SwitchSelector,
    'dealer-peak-control': DealerPeakControl,
    'email-capture': EmailCapture,
    'contact-number-capture': ContactNumberCapture,
    'vue-cropper': VueCropper,
    'state-selector': stateSelector,
    'license-selector': LicenseSelector
  },
  props: {
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full-screen'
    },
    startWith: {
      type: String,
      default: ''
    },
    customerLead: {
      type: Object,
      default: null
    },
    rejected: {
      type: Boolean,
      default: false
    },
    contactData: {
      type: Object,
      default: null
    },
    requiredParentId: {
      type: Number,
      default: 0
    }
  },
  data () {
    let prePopulation = this.$toTitleCase(this.startWith)
    let parts = (prePopulation && prePopulation !== '') ? prePopulation.split(' ') : []
    var fName = ''
    var mName = ''
    var lName = ''
    var derivedIntials = ''
    if (parts.length > 0) {
      fName = parts[0]
    }
    if (parts.length === 2) {
      lName = parts[1]
    }
    if (parts.length > 2) {
      lName = parts[parts.length - 1]
      mName = parts.slice(1, parts.length - 1).join(' ')
    }
    derivedIntials = (fName ? fName[0] : '') + (mName ? mName[0] : '') + (lName ? lName[0] : '')
    return {
      id: 0,
      spouseId: 0,
      parentId: 0,
      selectedDealer: null,
      selectedType: undefined,
      selectedSpouse: null,
      isNewCustomerActive: false,
      companyName: '',
      firstName: fName,
      middleName: mName,
      lastName: lName,
      initials: derivedIntials,
      email: '',
      mobile: '',
      office: '',
      home: '',
      fax: '',
      query: '',
      page: 1,
      sortField: 'lastName',
      sortOrder: 'desc',
      duplicateCustomerActive: false,
      customerList: [],
      spouseList: [],
      serverErrors: [],
      serverErrorMessage: '',
      tabIndex: 0,
      isSaving: false,
      customerPhotoFiles: [],
      driversPhotoFiles: [],
      insurancePhotoFiles: [],
      selectedMaritalStatus: null,
      selectedEmploymentStatus: null,
      addresses: [],
      workHistory: [],
      personalReferences: [],
      relatives: [],
      bankDetails: {
        accountHolder: '',
        accountNo: '',
        accountType: null,
        branchNo: '',
        branchName: '',
        swiftNo: '',
        selectedBank: null,
        selectedAccountType: null,
        hasValidationErrors: false,
        canSave: null,
        selectedDefaultMethod: null
      },
      getValidationErrors: false,
      ssn: '',
      dateOfBirth: null,
      driverLicenseNumber: '',
      driverLicenseState: '',
      isTermsAccepted: false,
      isCreditTermsAccepted: false,
      termsAcceptedAt: new Date(),
      creditTermsAcceptedAt: new Date(),
      isSpouseLoading: false,
      selectedCustomerMatch: null,
      emailAddresses: [],
      contactNumbers: [],
      cropDriverPhotoActive: false,
      selectedFile: '',
      croppedCustomerImages: [],
      showCropDriverPhotoButton: false,
      isTrusted: false,
      isBlocked: false,
      allowPaymentWithoutTitle: false,
      selectedState: null,
      backOfDriversPhotoFiles: [],
      driversLicenseExactSizeConfirmed: true,
      backOfDriversLicenseExactSizeConfirmed: true,
      driversLicenseSizeConfirmationActive: false,
      driversLicenseExactSizeMode: 0,
      croppedDriverLicenseImages: [],
      croppedBackOfDriverLicenseImages: [],
      croppedDriverPhotoMode: 0,
      frontOfDriverLicenseExactSizeCheckCompleted: false,
      croppedDriverLicenseImagesCount: 0,
      customerNo: ''
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapSubscriberGetters(['getCurrentSubscriber']),
    ...mapDealerState(['currentDealer']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapAllStatesGetters(['allStates']),
    canSave: function () {
      return this.bankDetails.canSave
    },
    humanizedIsTermsAccepted: function () {
      return this.isTermsAccepted ? 'Yes' : 'No'
    },
    allSpouse () {
      return this.spouseList
    },
    stateRequiredBackOfDriversLicense: function () {
      if (this.selectedState) {
        return this.selectedState.requiresBackOfDriversLicense
      }
      return false
    },
    driversLicenseExactSizeModalText: function () {
      let selectedStateName = ''
      let result = 'The copies of drivers licenses are required to match the original documents exact size. Does the provided drivers license copy come to the exact size on a A4 page?'
      if (this.selectedState) {
        selectedStateName = this.selectedState.name
      }
      if (selectedStateName) {
        if (selectedStateName) {
          result = 'The state of ' + selectedStateName + ' requires the copies of drivers licenses match the original documents exact size. Does the provided drivers license copy come to the exact size on a A4 page?'
        }
      }
      return result
    },
    cropModalHeader: function () {
      let result = 'Crop drivers license photo to use as customer photo?'
      if (this.croppedDriverPhotoMode === 1 || this.croppedDriverPhotoMode === 2) {
        result = 'Crop drivers license removing any surrounding background.'
      }
      return result
    },
    selectedStateRequiresExactSizeDriversLicense: function () {
      if (this.selectedState && this.selectedState.requiresExactDriversLicenseSize) {
        return true
      }
      return false
    },
    showCropDriversLicenseExactSize: function () {
      let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
      if (this.selectedStateRequiresExactSizeDriversLicense && activeDriverPhotoFiles && activeDriverPhotoFiles.length > 0) {
        return true
      }
      return false
    },
    showCropBackOfDriversLicenseExactSize: function () {
      let activeDriverPhotoFiles = this.backOfDriversPhotoFiles.filter((x) => x.status === 'Active')
      if (this.selectedStateRequiresExactSizeDriversLicense && activeDriverPhotoFiles && activeDriverPhotoFiles.length > 0) {
        return true
      }
      return false
    },
    hasPrivateInfoAccess: function () {
      let allowedPermissions = ['customer.view.private-info']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    }
  },
  methods: {
    findByLastName: function () {
      if (this.email !== '' || this.mobile !== '') {
        if (this.email !== '') {
          this.query = this.lastName + ' ' + this.email
          this.loadAsyncCustomerData()
        } else if (this.mobile !== '') {
          this.query = this.lastName + ' ' + this.mobile
          this.loadAsyncCustomerData()
        }
      }
    },
    findByEmail: function () {
      if (this.email !== '' && !this.rejected) {
        this.query = this.email
        this.loadAsyncCustomerData()
      }
    },
    findByMobile: function () {
      if (this.mobile !== '' && !this.rejected) {
        this.query = this.mobile
        this.loadAsyncCustomerData()
      }
    },
    findByEmailOrMobile: function () {
      if (this.rejected) return
      this.query = this.mobile + ' ' + this.email
      this.loadAsyncCustomerData(true)
    },
    openCustomerRecord: function (customer) {
      if (this.mode === 'full-screen') {
        this.duplicateCustomerActive = false
        this.$router.push({name: 'EditCustomer', params: { id: customer.id }})
      } else {
        this.duplicateCustomerActive = false
        this.$emit('on-save', customer)
      }
    },
    cancelCustomerCreate: function () {
      this.duplicateCustomerActive = false
      this.onCancel()
    },
    closeDuplicateCustomer: function () {
      let matchSkipModel = {
        subscriberId: this.getCurrentSubscriber.id,
        dealerId: this.currentDealer.id,
        firstName: this.firstName,
        lastName: this.lastName,
        mobile: this.mobile,
        email: this.email,
        customerList: this.customerList
      }

      customerService.customerMatchSkipNotification(matchSkipModel).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
          this.duplicateCustomerActive = false
        } else {
          this.duplicateCustomerActive = false
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.duplicateCustomerActive = false
      })
    },
    getSearchCriteriaForCustomer: function (useOrRule) {
      return {
        lastName: this.lastName,
        mobile: this.mobile,
        email: this.email,
        dealerId: this.selectedDealer.id,
        query: this.query,
        pageSize: 10,
        sortBy: this.sortField + '.' + this.sortOrder,
        page: this.page - 1,
        useOrRule: useOrRule || false
      }
    },
    clearAllSpouse () {
      this.spouseList = []
    },
    loadAsyncSpouseData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.selectedDealer.id,
        sortBy: 'firstName.asc'
      }
      this.isSpouseLoading = true
      this.clearAllSpouse()
      customerService.all(searchCommand).then(response => {
        this.spouseList = response.results || response
        this.isSpouseLoading = false
      })
    },
    doSpouseSearch: _.debounce(function (query) { this.loadAsyncSpouseData(query) }, 500),
    onSpouseSearch (query) {
      this.doSpouseSearch(query)
    },
    loadAsyncCustomerData (useOrRule) {
      const searchCriteria = this.getSearchCriteriaForCustomer(useOrRule)
      this.loading = true
      this.serverErrorMessage = ''
      this.serverErrors = []
      customerService.all(searchCriteria).then(response => {
        this.loading = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.customerList = []
          if (response.results.length > 0) {
            response.results.forEach((item) => {
              this.customerList.push(item)
            })
            this.duplicateCustomerActive = true
          }
        }
      }).catch((error) => {
        this.customerList = []
        this.loading = false
        this.serverErrorMessage = error.message
      })
    },
    onSave: function () {
      this.getValidationErrors = true
    },
    onCancel: function () {
      this.$emit('on-cancel')
      if (this.mode === 'full-screen') {
        this.onComplete()
      }
    },
    onComplete: function () {
      this.$router.push({name: 'ListCustomers'})
    },
    onCanSave: function () {
      if (!this.bankDetails.hasValidationErrors) {
        var customerModel = {
          isCreate: true,
          subscriberId: this.$store.state.user.currentUser.subscriberId,
          dealerId: this.selectedDealer.id,
          parentId: this.parentId,
          type: this.selectedType ? this.selectedType.altName : null,
          companyName: this.companyName,
          firstName: this.firstName,
          middleName: this.middleName,
          lastName: this.lastName,
          initials: this.initials,
          email: this.email,
          mobile: this.mobile,
          office: this.office,
          home: this.home,
          fax: this.fax,
          addresses: [],
          workHistory: [],
          personalReferences: [],
          relatives: [],
          bankId: this.bankDetails.selectedBank ? this.bankDetails.selectedBank.id : 0,
          accountHolder: this.bankDetails.accountHolder || '',
          accountNo: this.bankDetails.accountNo || '',
          accountType: this.bankDetails.selectedAccountType ? this.bankDetails.selectedAccountType.name : 'Check',
          branchNo: this.bankDetails.branchNo || '',
          branchName: this.bankDetails.branchName || '',
          swiftNo: this.bankDetails.swiftNo || '',
          status: 'Active',
          ssn: this.ssn || '',
          driverLicenseNumber: this.driverLicenseNumber || '',
          driverLicenseState: this.selectedState ? this.selectedState.name : '',
          dateOfBirth: this.dateOfBirth ? moment(this.dateOfBirth).format('MM/DD/YYYY') : null,
          isTermsAccepted: this.isTermsAccepted,
          isCreditTermsAccepted: this.isCreditTermsAccepted,
          termsAcceptedAt: this.termsAcceptedAt,
          creditTermsAcceptedAt: this.creditTermsAcceptedAt,
          defaultPaymentMethod: this.bankDetails.selectedDefaultMethod ? this.bankDetails.selectedDefaultMethod.name : 'Check',
          maritalStatus: this.selectedMaritalStatus ? this.selectedMaritalStatus.name : null,
          employmentStatus: this.selectedEmploymentStatus ? this.selectedEmploymentStatus.name : null,
          spouseId: this.selectedSpouse ? this.selectedSpouse.id : 0,
          emailAddresses: [],
          contactNumbers: [],
          isTrusted: this.isTrusted,
          isBlocked: this.isBlocked,
          allowPaymentWithoutTitle: this.allowPaymentWithoutTitle,
          driversLicenseResizeRequired: !this.driversLicenseExactSizeConfirmed,
          backOfDriversLicenseResizeRequired: !this.backOfDriversLicenseExactSizeConfirmed,
          customerNo: this.customerNo
        }

        if (this.rejected) {
          customerModel.status = 'Rejected'
        }

        this.saveAddress(customerModel)
        this.saveWorkHistory(customerModel)
        this.savePersonalReference(customerModel)
        this.saveRelative(customerModel)
        this.saveEmailAddresses(customerModel)
        this.saveContactNumbers(customerModel)

        let validCustomerFiles = this.customerPhotoFiles.filter((y) => y.status !== 'Deleted')
        if (validCustomerFiles.length > 0) {
          var photoFile = validCustomerFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          customerModel.uploadName = photoFile[0].uploadName
          customerModel.uniqueId = photoFile[0].uniqueId
        }

        let validDriverFiles = this.driversPhotoFiles.filter((y) => y.status !== 'Deleted')
        if (validDriverFiles.length > 0) {
          var driversPhotoFile = validDriverFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          customerModel.driversUploadName = driversPhotoFile[0].uploadName
          customerModel.driversUniqueId = driversPhotoFile[0].uniqueId
        }

        let validBackOfDriverFiles = this.backOfDriversPhotoFiles.filter((y) => y.status !== 'Deleted')
        if (validBackOfDriverFiles.length > 0) {
          var backOfDriversPhotoFile = validBackOfDriverFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          customerModel.backOfDriversUploadName = backOfDriversPhotoFile[0].uploadName
          customerModel.backOfDriversUniqueId = backOfDriversPhotoFile[0].uniqueId
        }

        let validInsuranceFiles = this.insurancePhotoFiles.filter((y) => y.status !== 'Deleted')
        if (validInsuranceFiles.length > 0) {
          var insurancePhotoFile = validInsuranceFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          customerModel.insuranceUploadName = insurancePhotoFile[0].uploadName
          customerModel.insuranceUniqueId = insurancePhotoFile[0].uniqueId
        }

        customerModel.customerId = this.selectedCustomerMatch ? this.selectedCustomerMatch.dealerPeakUserId : null
        customerModel.leadId = this.selectedCustomerMatch ? this.selectedCustomerMatch.leadId : null
        customerModel.agentUserId = this.selectedCustomerMatch ? this.selectedCustomerMatch.agentUserId : null
        customerModel.agentLocationId = this.selectedCustomerMatch ? this.selectedCustomerMatch.agentLocationId : null

        this.isSaving = true
        this.serverErrorMessage = ''
        this.serverErrors = []
        customerService.save(customerModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.$emit('on-save', response.customer)
            this.onChangesSaved()
            if (this.mode === 'full-screen') {
              this.onComplete()
            }
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
        })
      }
    },
    onCustomerSelection: function (customer) {
      this.setCustomerData(customer)
      this.showCustomerMatches = false
      this.customerMatches = []
    },
    unmask: function (input) {
      if (!input || input === '') {
        return ''
      }
      return input.replace('+1', '').replace(/\(/g, '').replace(/\)/g, '').replace(/-/g, '')
    },
    setCustomerData: function (customerRecord) {
      if (customerRecord.firstName) {
        this.firstName = customerRecord.firstName
      }
      if (customerRecord.lastName) {
        this.lastName = customerRecord.lastName
      }
      if (customerRecord.contactEmail) {
        this.email = customerRecord.contactEmail
      }
      if (customerRecord.contactNumber) {
        this.mobile = customerRecord.contactNumber
      }
      if (customerRecord.addresses && customerRecord.addresses.length) {
        this.addresses = customerRecord.addresses
      }

      if (customerRecord.contactEmail && customerRecord.contactNumber) {
        this.findByEmailOrMobile()
      } else if (customerRecord.contactEmail) {
        this.findByEmail()
      } else if (customerRecord.contactNumber) {
        this.findByMobile()
      }
      this.selectedCustomerMatch = customerRecord
    },
    closeCropDriverPhoto: function () {
      this.cropDriverPhotoActive = false
      this.driversLicenseExactSizeMode = 0
      if (this.selectedStateRequiresExactSizeDriversLicense && !this.frontOfDriverLicenseExactSizeCheckCompleted) {
        this.frontOfDriverLicenseExactSizeCheckCompleted = true
        this.driversLicenseSizeConfirmationActive = true
      }
    },
    onSaveCroppedDriverPhoto: function () {
      this.$refs.cropper.getCroppedCanvas().toBlob((blob) => {
        var fileName = 'cropped_photo_' + new Date().getTime() + '.png'
        var file = new File([blob], fileName, {type: 'image/png', lastModified: new Date()})
        if (this.croppedDriverPhotoMode === 1) {
          this.driversPhotoFiles = []
          this.croppedDriverLicenseImages = []
          this.croppedDriverLicenseImages.push(file)
        } else if (this.croppedDriverPhotoMode === 2) {
          this.backOfDriversPhotoFiles = []
          this.croppedBackOfDriverLicenseImages = []
          this.croppedBackOfDriverLicenseImages.push(file)
        } else {
          this.croppedCustomerImages = []
          this.croppedCustomerImages.push(file)
          this.showCropDriverPhotoButton = false
          if (this.selectedStateRequiresExactSizeDriversLicense) {
            this.driversLicenseSizeConfirmationActive = true
          }
        }
        this.croppedDriverPhotoMode = 0
        this.driversLicenseExactSizeMode = 0
      })
      this.cropDriverPhotoActive = false
    },
    cropDriverPhoto: function (mode) {
      this.uploadComplete()
      this.croppedDriverPhotoMode = mode
      let activeCustomerPhotoFiles = this.customerPhotoFiles.filter((x) => x.status === 'Active')
      let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeCustomerPhotoFiles.length === 0 && activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (util.fileIsImage(fileName)) {
          this.cropDriverPhotoActive = true
          this.selectedFile = activeDriverPhotoFiles[0].imageData
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.selectedFile)
          }
        } else if (this.selectedStateRequiresExactSizeDriversLicense) {
          this.driversLicenseSizeConfirmationActive = true
        }
      } else if (mode === 0 && activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (!fileName.includes('cropped_photo') && this.selectedStateRequiresExactSizeDriversLicense) {
          this.driversLicenseSizeConfirmationActive = true
        }
      }
    },
    showCropDriversPhotoModal: function (mode) {
      let activePhotoFiles = []
      if (mode === 0) {
        let activeCustomerPhotoFiles = this.customerPhotoFiles.filter((x) => x.status === 'Active')
        if (activeCustomerPhotoFiles.length === 0 && this.driversPhotoFiles.length > 0) {
          this.cropDriverPhoto(mode)
        }
      } else if (mode === 1 || mode === 2) {
        if (mode === 1) {
          activePhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
          if (activePhotoFiles && activePhotoFiles.length > 0) {
            this.croppedDriverPhotoMode = mode
            this.cropDriverPhotoExactSize()
          }
        } else if (mode === 2) {
          activePhotoFiles = this.backOfDriversPhotoFiles.filter((x) => x.status === 'Active')
          if (activePhotoFiles && activePhotoFiles.length > 0) {
            this.croppedDriverPhotoMode = mode
            this.cropBackOfDriverPhotoExactSize()
          }
        }
      }
    },
    cropDriverPhotoExactSize: function () {
      let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (util.fileIsImage(fileName)) {
          this.cropDriverPhotoActive = true
          this.selectedFile = activeDriverPhotoFiles[0].imageData
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.selectedFile)
          }
          this.frontOfDriverLicenseExactSizeCheckCompleted = true
        } else {
          this.croppedDriverPhotoMode = 0
        }
      }
    },
    onConfirmDriversLicenseExactSize: function (confirmed) {
      if (this.driversLicenseExactSizeMode === 1) {
        this.backOfDriversLicenseExactSizeConfirmed = confirmed
      } else {
        this.driversLicenseExactSizeConfirmed = confirmed
        this.frontOfDriverLicenseExactSizeCheckCompleted = true
      }
      this.driversLicenseSizeConfirmationActive = false
      if (confirmed === false && this.driversLicenseExactSizeMode === 0) {
        // if image crop image
        this.croppedDriverPhotoMode = 1
        this.cropDriverPhotoExactSize()
      } else if (confirmed === false && this.driversLicenseExactSizeMode === 1) {
        this.croppedDriverPhotoMode = 2
        this.cropBackOfDriverPhotoExactSize()
      }
    },
    cropBackOfDriverPhotoExactSize: function () {
      let activeDriverPhotoFiles = this.backOfDriversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (util.fileIsImage(fileName)) {
          this.croppedDriverPhotoMode = 2
          this.cropDriverPhotoActive = true
          this.selectedFile = activeDriverPhotoFiles[0].imageData
          if (this.$refs.cropper) {
            this.$refs.cropper.replace(this.selectedFile)
          }
        } else {
          this.croppedDriverPhotoMode = 0
        }
      }
    },
    backOfDriversLicenseUploaded: function () {
      this.uploadComplete()
      let activeDriverPhotoFiles = this.backOfDriversPhotoFiles.filter((x) => x.status === 'Active')
      if (activeDriverPhotoFiles.length > 0) {
        let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
        if (!fileName.includes('cropped_photo') && this.stateRequiredBackOfDriversLicense) {
          this.driversLicenseExactSizeMode = 1
          this.driversLicenseSizeConfirmationActive = true
        }
      }
    }
  },
  watch: {
    selectedMaritalStatus: function (newValue, oldValue) {
      if (this.selectedMaritalStatus && this.selectedMaritalStatus.id === 0) {
        this.selectedSpouse = null
      }
    },
    canSave: function (newValue, oldValue) {
      if (newValue && newValue === true) {
        this.onCanSave()
      }
      this.getValidationErrors = false
      this.bankDetails.canSave = null
    },
    driversPhotoFiles: {
      handler (newValue, oldValue) {
        let activeCustomerPhotoFiles = this.customerPhotoFiles.filter((x) => x.status === 'Active')
        let activeDriverPhotoFiles = newValue.filter((x) => x.status === 'Active')
        if ((activeCustomerPhotoFiles.length === 0) && (activeDriverPhotoFiles.length > 0)) {
          let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
          this.showCropDriverPhotoButton = util.fileIsImage(fileName)
        } else {
          this.showCropDriverPhotoButton = false
        }
        if (activeDriverPhotoFiles && activeDriverPhotoFiles.length === 0) {
          this.frontOfDriverLicenseExactSizeCheckCompleted = false
        }
      },
      deep: true
    },
    customerPhotoFiles: {
      handler (newValue, oldValue) {
        let activeCustomerPhotoFiles = newValue.filter((x) => x.status === 'Active')
        let activeDriverPhotoFiles = this.driversPhotoFiles.filter((x) => x.status === 'Active')
        if (activeDriverPhotoFiles.length === 0 || (activeCustomerPhotoFiles.length > 0)) {
          this.showCropDriverPhotoButton = false
        } else {
          let fileName = activeDriverPhotoFiles[0].fileName ? activeDriverPhotoFiles[0].fileName : activeDriverPhotoFiles[0].name
          this.showCropDriverPhotoButton = util.fileIsImage(fileName)
        }
      },
      deep: true
    }
  },
  beforeMount () {
    this.selectedDealer = this.$store.state.dealer.currentDealer
  },
  mounted: function () {
    if (this.$route.params.customerLead) {
      this.customerLead = this.$route.params.customerLead
    }
    if (this.$route.params.customer) {
      this.setCustomerData(this.$route.params.customer)
    } else if (this.customerLead) {
      let primaryNumber = this.customerLead.phoneNumbers.find((x) => x.type.toLowerCase() === 'mobilephone')
      let primaryEmail = this.customerLead.emails && this.customerLead.emails.length > 0 ? this.customerLead.emails[0] : null
      let customerRecord = {
        id: 0,
        customerId: this.customerLead.dealerPeakUserId,
        firstName: this.customerLead.firstName,
        lastName: this.customerLead.lastName,
        contactNumber: primaryNumber ? primaryNumber.number : '',
        contactEmail: primaryEmail ? primaryEmail.address : '',
        addresses: (this.customerLead.addresses && this.customerLead.addresses.length > 0) ? [...this.customerLead.addresses] : []
      }
      this.setCustomerData(customerRecord)
    }
    if (this.addresses && this.addresses.length === 0) {
      this.addAddress({ loadCustomerOnly: true })
    }
    this.addWorkHistory({ loadCustomerOnly: true })
    this.addPersonalReference({ loadCustomerOnly: true })
    this.addRelative({ loadCustomerOnly: true })
    this.selectedType = this.definedTypes.customerTypes.options.find((x) => x.id === 0)
    this.selectedMaritalStatus = this.definedTypes.customerMaritalStatus.options.find((x) => x.id === 0)
    this.selectedEmploymentStatus = this.definedTypes.customerEmploymentStatus.options.find((x) => x.id === this.definedTypes.customerEmploymentStatus.Employed)
    this.bankDetails.selectedDefaultMethod = this.definedTypes.paymentMethods.find((x) => x.id === 0)
    this.parentId = this.requiredParentId
    if (this.rejected) {
      this.isPartial = true
      if (this.contactData !== null) {
        this.email = this.contactData.email
        this.mobile = this.contactData.mobile
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  .modal-card-body {
    max-height: 575px !important;
  }

  #duplicate-customers-table td {
    vertical-align: middle;
  }
</style>

<template>
<div class="box appraisal-container">
  <appraisal-section-header title="Black Book" :collapsed="isBlackBookCollapsed" v-on:collapsed-status-changed="onBlackBookCollapsedStatusChanged" :blackBookInfo="true" :isAppraising="isRetreivingBlackBook" v-on:sync-clicked="getBlackBookInformation()"></appraisal-section-header>
  <div class="columns is-mobile is-multiline is-centered" style="margin-top: 5px;margin-bottom: 20px !important;padding: 0">
    <div class="column is-12">
      <div>
        <label class="is-inline-block is-size-7 has-text-success refresh-status-label" v-if="isRetreivingBlackBook"> Fetching latest available black book information...</label>
      </div>
    </div>
    <template>
      <!-- <div class="column is-3">
        <img src="@/assets/static/blackbook/bb-logo.png" style="width:87px; height:45px;">
      </div> -->
      <div class="columns is-multiline" v-if="currentBlackBookInformation && currentBlackBookInformation.vinNo && currentBlackBookInformation.vinNo.length > 0">
        <div class="column is-4">
          <div class="field">
            <label class="label">Base Price</label>
            <div class="field">
              {{currentBlackBookInformation.adjustedWholesaleAverage | currency}}
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Base Price</label>
            <div class="field">
              {{currentBlackBookInformation.adjustedPrivateAverage | currency}}
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Base Price</label>
            <div class="field">
              {{currentBlackBookInformation.adjustedRetailAverage | currency}}
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Adjustment</label>
            <div class="field">
              {{currentBlackBookInformation.wholesaleAdjustment | currency}}
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Adjustment</label>
            <div class="field">
              {{currentBlackBookInformation.privateAdjustment | currency}}
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Adjustment</label>
            <div class="field">
              {{currentBlackBookInformation.retailAdjustment | currency}}
            </div>
          </div>
        </div>
        <div class="column is-12">
          <hr class="block-hr-divider" />
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Wholesale</label>
            <div class="field">
              {{currentBlackBookInformation.adjustedWholesaleClean | currency}}
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Finance Advance</label>
            <div class="field">
              {{currentBlackBookInformation.adjustedPrivateClean | currency}}
            </div>
          </div>
        </div>
        <div class="column is-4">
          <div class="field">
            <label class="label">Retail</label>
            <div class="field">
              {{currentBlackBookInformation.adjustedRetailClean | currency}}
            </div>
          </div>
        </div>
        <div class="column is-12">
          <div class="field">
            <label class="label">Last Updated</label>
            <div class="field">
              {{currentBlackBookInformation.lastCheckedAt | formatDate}}
            </div>
          </div>
        </div>
      </div>
      <div v-else-if="!currentBlackBookInformation || (currentBlackBookInformation && !currentBlackBookInformation.vinNo && !isRetreivingBlackBook)">
        <div class="column is-12">
          <div>
            <span class="help is-danger">
              Blackbook information unavailable for this vehicle
            </span>
          </div>
        </div>
      </div>
      </template>
  </div>
</div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import appraisalService from '@/services/appraisalService'
import inventoryService from '@/services/inventoryService'
import AppraisalSectionHeader from '@/components/generic/AppraisalSectionHeader'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'InventoryBlackBookControl',
  components: {
    'appraisal-section-header': AppraisalSectionHeader
  },
  props: {
    blackBookInformation: {
      type: Object,
      default: null
    },
    inventory: {
      type: Object,
      default: null
    },
    autoUpdateInventory: {
      type: Boolean,
      default: false
    },
    displayMode: {
      type: String,
      default: 'Standard'
    },
    runAutomatically: {
      type: Boolean,
      default: false
    },
    inventoryType: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      serverErrors: [],
      serverErrorMessage: '',
      isRetreivingBlackBook: false,
      currentBlackBookInformation: this.blackBookInformation,
      queryTimer: undefined,
      isBlackBookCollapsed: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    isCurrentBlackBookExpired: function () {
      if (this.currentBlackBookInformation) {
        let currentReportDate = new Date(this.currentBlackBookInformation.appraisedAt)
        let currentDateTime = new Date()
        let firstDateMs = currentReportDate.getTime()
        let secondDateMs = currentDateTime.getTime()
        var differenceMs = secondDateMs - firstDateMs
        differenceMs = differenceMs / 1000
        // let seconds = Math.floor(differenceMs % 60)
        differenceMs = differenceMs / 60
        // let minutes = Math.floor(differenceMs % 60)
        differenceMs = differenceMs / 60
        let hours = Math.floor(differenceMs % 24)
        // let days = Math.floor(differenceMs / 24)
        // console.log(currentReportDate)
        // console.log(currentDateTime)
        // console.log('Days - ' + days)
        // console.log('Hours - ' + hours)
        // console.log('Minutes - ' + minutes)
        // console.log('Seconds - ' + seconds)
        return hours > 6
      }
      return false
    },
    dealerBlackBookEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.blackBookEnabledStatus
      }
      return false
    }
  },
  methods: {
    onBlackBookCollapsedStatusChanged: function (newValue) {
      this.isAppraisalCollaisBlackBookCollapsedsed = newValue
    },
    getBlackBookInformation () {
      if (this.dealerBlackBookEnabled) {
        let vinNo = this.inventory.vinNo
        if (this.isRetreivingBlackBook === false && vinNo && vinNo !== '') {
          this.isRetreivingBlackBook = true
          appraisalService.getBlackBookInformation(vinNo, this.currentDealer).then(response => {
            this.isRetreivingBlackBook = false
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              this.currentBlackBookInformation = response
              this.currentBlackBookInformation.lastCheckedAt = this.$options.filters.formatDate(new Date())
              if (this.currentBlackBookInformation.lastCheckedAt === 'Invalid date') {
                this.currentBlackBookInformation.lastCheckedAt = new Date()
              }
              this.$emit('on-new-blackbook-information', this.currentBlackBookInformation)
              if (this.inventory && this.autoUpdateInventory) {
                this.currentBlackBookInformation.inventoryId = this.inventory.id
                inventoryService.addBlackBook(this.currentBlackBookInformation)
              }
            }
          }).catch((error) => {
            this.$emit('on-blackbook-information-failed')
            this.isRetreivingBlackBook = false
            this.serverErrorMessage = error.message
            this.currentBlackBookInformation = null
          })
        } else {
          this.$emit('on-blackbook-information-failed')
        }
      }
    }
  },
  watch: {
    inventory: function (newValue, oldValue) {
      if (oldValue) {
        if (newValue && (oldValue.vinNo !== newValue.vinNo)) {
          window.clearTimeout(this.queryTimer)
          let that = this
          this.queryTimer = window.setTimeout(function () {
            that.getBlackBookInformation()
          }, 2000)
        }
      }
    }
  },
  mounted: function () {
    if (this.currentDealer && this.currentDealer.blackBookEnabledStatus) {
      // If there are no appraisals for the vehicle then run one
      if ((this.inventory && !this.blackBookInformation) || this.runAutomatically) {
        this.getBlackBookInformation()
      }
    } else {
      this.$emit('black-book-disabled')
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .tolerance-error-icon {
    margin-left:5px;
  }

  .refresh-status-label {
    margin-left:1em;
  }
</style>

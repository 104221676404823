<template>
  <div class="tile is-ancestor" v-if="inventoryTitleDetails">
    <div class="titleInfo tile is-vertical is-parent" style="width: 100%;">
      <h3 class="title is-7 large-bottom-margin">Details</h3>
      <div class="tile is-child box">
        <div class="columns">
          <div class="column is-12">
            <button-list-selector :scope="scope" validateAs="title status" label="Title Status" :required="!allowTitleSkip" v-model="selectedTitleStatus"
            :availableValues="allowedTitleStatusOptions" :activeValues="allowedTitleStatusOptions" :useValidator="thisValidator"></button-list-selector>
          </div>
        </div>
        <div class="columns" style="padding-left: 8px;" v-if="showTitleDocs">
          <div class="column is-5">
            <file-selector
              :scope="scope" v-model="inventoryTitleDetails.frontTitleFiles" key="10"
              :useValidator="thisValidator" validateAs="front title" label="Front Title File" :required="isTitleDocsRequired" :multiple="false" :isBoxed="false"
              :enabled="true" :usePortalViewer="false">
            </file-selector>
          </div>
          <div class="column is-2 is-vcentered">
            <i v-if="canShowFrontClone" class="fal fa-arrow-alt-from-left fa-lg clickable has-text-success has-text-weight-bold" title="Click to clone this document to the back title" @click.stop.prevent="onFrontTitleClone()"/>
            <i v-if="canShowBackClone" class="fal fa-arrow-alt-from-right fa-lg clickable has-text-success has-text-weight-bold" title="Click to clone this document to the front title" @click.stop.prevent="onBackTitleClone()"/>
          </div>
          <div class="column is-5">
            <file-selector
              :scope="scope" v-model="inventoryTitleDetails.backTitleFiles" key="11"
              :useValidator="thisValidator" validateAs="back title" label="Back Title File" :required="isTitleDocsRequired" :multiple="false" :isBoxed="false"
              :enabled="true" :usePortalViewer="false">
            </file-selector>
          </div>
        </div>
        <div class="columns" style="padding-left: 8px;" v-if="showTitleDocs || showTitlePayOffDocs">
          <div class="column is-5">
            <file-selector
              :scope="scope" v-model="inventoryTitleDetails.payOffInfoFiles" key="12"
              :useValidator="thisValidator" validateAs="payoff info" label="Payoff Info File" :required="isTitlePayOffDocsRequired" :multiple="false" :isBoxed="false"
              :enabled="true" :usePortalViewer="false">
            </file-selector>
          </div>
          <div class="column is-2 is-vcentered">
          </div>
          <div class="column is-5">
            <file-selector
              :scope="scope" v-model="inventoryTitleDetails.payOffLienSatisfactionFiles" key="13"
              :useValidator="thisValidator" validateAs="lein satisfaction letter" label="Payoff Lien Satisfaction File" :required="false" :multiple="false" :isBoxed="false"
              :enabled="true" :usePortalViewer="false">
            </file-selector>
          </div>
        </div>
        <div class="columns" style="padding-left: 8px;" v-if="showLostTitleDocs">
          <div class="column is-12">
            <file-selector
              :scope="scope" v-model="inventoryTitleDetails.lostTitleAffidavitFiles" key="14"
              :useValidator="thisValidator" validateAs="lost title affidavit" label="Lost Title Affidavit" :required="false" :multiple="false" :isBoxed="false"
              :enabled="true" :usePortalViewer="false">
            </file-selector>
          </div>
        </div>
        <div class="column is-12 is-paddingless medium-top-margin" v-if="isTitlePayOff">
          <h3 class="title is-7">Payoff Details</h3>
          <div class="columns is-multiline">
            <div :class="{'column': true, 'is-12' : isDocViewActive, 'is-6': !isDocViewActive}">
              <bank-selector :scope="scope" v-model="selectedBank" label="Lienholder Bank" validateAs="bank" :required="true" :useValidator="thisValidator"></bank-selector>
            </div>
            <div :class="{'column': true, 'is-12' : isDocViewActive, 'is-6': !isDocViewActive}">
              <div class="field">
                <label class="label">Account No<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <input :data-vv-scope="scope" data-vv-as="account no" name="accNo"  v-validate="{'required': true}" :class="{'input': true, 'is-danger': errors.has(scope + '.accNo') }" type="text" placeholder="#####" v-model="inventoryTitleDetails.accNo">
                  <span v-show="errors.has(scope + '.accNo')" class="help is-danger"><i v-show="errors.has(scope + '.accNo')" class="fas fa-exclamation-triangle"></i> {{ errors.first(scope + '.accNo') }}</span>
                </div>
              </div>
            </div>
            <div :class="{'column': true, 'is-12' : isDocViewActive, 'is-6': !isDocViewActive}">
              <div class="field">
                <label class="label no-content-wrap">Payoff Amount<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control has-icons-left">
                  <money :data-vv-scope="scope" name="payoffAmount" data-vv-as="payoff amount" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has(scope + '.payoffAmount') }" type="text" placeholder="0.00" v-model="inventoryTitleDetails.payoffAmount" v-bind="$globalMoneyFormat"></money>
                  <span v-show="errors.has(scope + '.payoffAmount')" class="help is-danger"><i v-show="errors.has(scope + '.payoffAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first(scope + '.payoffAmount') }}</span>
                  <span class="icon is-small is-left">
                    <i class="fas fa-dollar-sign"></i>
                  </span>
                </div>
              </div>
            </div>
            <div :class="{'column': true, 'is-12' : isDocViewActive, 'is-6': !isDocViewActive}">
              <div class="field">
                <label class="label no-content-wrap">Monthly Payment Due<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control has-icons-left">
                  <money :data-vv-scope="scope" name="monthlyPaymentDue" data-vv-as="monthly payment due" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has(scope + '.monthlyPaymentDue') }" type="text" placeholder="0.00" v-model="inventoryTitleDetails.monthlyPaymentDue" v-bind="$globalMoneyFormat"></money>
                  <span v-show="errors.has(scope + '.monthlyPaymentDue')" class="help is-danger"><i v-show="errors.has(scope + '.monthlyPaymentDue')" class="fas fa-exclamation-triangle"></i> {{ errors.first(scope + '.monthlyPaymentDue') }}</span>
                  <span class="icon is-small is-left">
                    <i class="fas fa-dollar-sign"></i>
                  </span>
                </div>
              </div>
            </div>
            <div :class="{'column': true, 'is-12' : isDocViewActive, 'is-6': !isDocViewActive}">
              <div class="field">
                <label class="label no-content-wrap">Good Until Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control has-icons-left">
                  <el-date-picker
                    :data-vv-scope="scope"
                    v-model="goodUntilDate"
                    type="date"
                    data-vv-as="good until date"
                    v-validate="{'required': true}"
                    data-vv-validate-on="input|close"
                    name="goodUntilDate"
                    placeholder="Click to select..."
                    popper-class="datepicker-container">
                  </el-date-picker>
                  <span v-show="errors.has(scope + '.goodUntilDate')" class="help is-danger"><i v-show="errors.has(scope + '.goodUntilDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first(scope + '.goodUntilDate') }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <h3 class="title is-7 large-top-margin">Title Verification</h3>
        <div class="columns is-multiline">
          <div class="column is-9">
            <switch-selector :scope="scope" type="is-info" :label="verifiedDocsLabel" :isDisabled="true"></switch-selector>
          </div>
          <div class="column is-3 is-align-vmiddle">
            <i v-show="!allDocsVerified" class="has-text-danger fas fa-lg fa-exclamation-triangle"></i>
          </div>
          <div class="column is-9">
            <switch-selector :scope="scope" type="is-info" :label="'Is the last owner transferring to us on the title \'' + inventoryTitleDetails.purchasedFromName + '\'?'" v-model="inventoryTitleDetails.sameTitle"></switch-selector>
          </div>
          <div class="column is-3 is-align-vmiddle">
            <i v-show="!inventoryTitleDetails.sameTitle" class="has-text-danger fas fa-lg fa-exclamation-triangle"></i>
          </div>
          <div class="column is-9">
            <switch-selector :scope="scope" type="is-info" label="Is the title properly assigned?" v-model="inventoryTitleDetails.titleReassigned"></switch-selector>
          </div>
          <div class="column is-3 is-align-vmiddle">
            <i v-show="!inventoryTitleDetails.titleReassigned" class="has-text-danger fas fa-lg fa-exclamation-triangle"></i>
          </div>
          <div class="column is-9">
            <switch-selector :scope="scope" type="is-info" label="Any lienholders listed on title?" v-model="inventoryTitleDetails.lienholderListed"></switch-selector>
          </div>
          <div class="column is-3 is-align-vmiddle">
            <i v-show="inventoryTitleDetails.lienholderListed" class="has-text-danger fas fa-lg fa-exclamation-triangle"></i>
          </div>
          <div class="column is-9" v-if="inventoryTitleDetails.lienholderListed">
            <label class="label large-bottom-margin">Is the lien already satisfied or are we making a payoff against the vehicle?</label>
            <b-field>
              <b-radio-button v-model="lienStatus" size="is-small" native-value="Satisfied" type="is-success" @input="onLienStatusChanged"><span>Lien Satisfied</span></b-radio-button>
              <b-radio-button v-model="lienStatus" size="is-small" native-value="PayOff" type="is-success" @input="onLienStatusChanged"><span>Making a PayOff</span></b-radio-button>
            </b-field>
          </div>
          <div class="column is-3 is-align-vmiddle" v-if="inventoryTitleDetails.lienholderListed">
            <i v-show="!lienStatus" class="has-text-danger fas fa-lg fa-exclamation-triangle"></i>
          </div>
          <div class="column is-9">
            <switch-selector :isDisabled="inventoryTitleDetails.vehicleArrived" :scope="scope" type="is-info" label="Do we physically have control of said vehicle?" v-model="inventoryTitleDetails.vehicleControl"></switch-selector>
          </div>
          <div class="column is-3 is-align-vmiddle">
            <span class="has-text-weight-bold has-text-success is-sixe-6" v-if="inventoryTitleDetails.vehicleArrived">Arrived</span>
            <i v-show="!inventoryTitleDetails.vehicleControl" class="has-text-danger fas fa-lg fa-exclamation-triangle"></i>
          </div>
          <div class="column is-9" v-if="!isBatchRequest">
            <switch-selector :scope="scope" type="is-info" label="Hard Copy Received" v-model="inventoryTitleDetails.originalTitleReceived"></switch-selector>
            <label class="is-pulled-left has-text-info" v-if="titleReceivedLabel">{{ titleReceivedLabel }}</label>
          </div>
          <div class="column is-3 is-align-vmiddle" v-if="!isBatchRequest">
            <i v-show="!inventoryTitleDetails.originalTitleReceived" class="has-text-danger fas fa-lg fa-exclamation-triangle"></i>
          </div>
          <div v-if="mustShowArrivalWarning" class="column is-12 has-text-danger has-text-weight-bold is-size-6">Warning : We do not have possession of the vehicle. Please process the arrival of this vehicle.</div>
        </div>
      </div>
    </div>
    <div class="file-container" style="cursor: pointer;">
        <i class="fal fa-arrow-alt-from-left is-pulled-right" @click="toggleDocView(false)" title="Click to collapse doc view" v-if="isDocViewActive"></i>
        <i class="fal fa-arrow-alt-from-right is-pulled-right" title="Click to expand doc view" @click="toggleDocView(true)" v-if="!isDocViewActive"></i>
    </div>
    <div class="titleInfo tile is-vertical is-parent" style="width: 100%; overflow-y: scroll;" v-if="isDocViewActive">
      <h3 class="title is-7 large-bottom-margin">Document Preview</h3>
      <div class="tile is-child box">
        <div class="file-container" v-show="isDocViewActive">
          <file-selector key="1" :scope="scope" v-model="inventoryTitleDetails.frontTitleFiles" :label="getFrontTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
          :showFileOnly="true" :usePortalViewer="false" :inline="true" :showVerifiedCheck="true"></file-selector>
        </div>
        <div class="file-container" v-show="isDocViewActive">
          <file-selector key="2" :scope="scope" v-model="inventoryTitleDetails.backTitleFiles" :label="getBackTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
          :showFileOnly="true" :usePortalViewer="false" :inline="true" :showVerifiedCheck="true"></file-selector>
        </div>
        <div class="file-container" v-show="isDocViewActive">
          <file-selector key="3" :scope="scope" v-model="inventoryTitleDetails.payOffInfoFiles" :label="getPayOffInfoTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
          :showFileOnly="true" :usePortalViewer="false" :inline="true" :showVerifiedCheck="true"></file-selector>
        </div>
        <div class="file-container" v-show="isDocViewActive">
          <file-selector key="4" :scope="scope" v-model="inventoryTitleDetails.payOffLienSatisfactionFiles" :label="getPayOffLienSatisfactionTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
          :showFileOnly="true" :usePortalViewer="false" :inline="true" :showVerifiedCheck="true"></file-selector>
        </div>
        <div class="file-container" v-show="isDocViewActive">
          <file-selector key="5" :scope="scope" v-model="inventoryTitleDetails.lostTitleAffidavitFiles" :label="getLostAffidavitTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
          :showFileOnly="true" :usePortalViewer="false" :inline="true" :showVerifiedCheck="true"></file-selector>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import logger from '@/services/loggingService'
import FileSelector from '@/components/generic/FileSelector'
import SwitchSelector from '@/components/generic/SwitchSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import BankSelector from '@/components/generic/BankSelector'
import { createNamespacedHelpers } from 'vuex'

const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'InventoryTitleCaptureDetail',
  mixins: [],
  components: {
    'file-selector': FileSelector,
    'switch-selector': SwitchSelector,
    'button-list-selector': ButtonListSelector,
    'bank-selector': BankSelector
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    disabled: {
      type: Boolean,
      default: false
    },
    isBatchRequest: {
      type: Boolean,
      default: false
    },
    allowTitleSkip: {
      type: Boolean,
      default: false
    },
    useValidator: {
      type: Object,
      default: null
    },
    scope: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      inventoryTitleDetails: null,
      isDocViewActive: true,
      selectedTitleStatus: null,
      selectedBank: null,
      goodUntilDate: null,
      lienStatus: null
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    canShowFrontClone: function () {
      return this.hasFrontTitleFiles && !this.hasBackTitleFiles
    },
    canShowBackClone: function () {
      return this.hasBackTitleFiles && !this.hasFrontTitleFiles
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    mustShowArrivalWarning: function () {
      return this.inventoryTitleDetails.possessionStatus !== 'Arrived' && this.inventoryTitleDetails.vehicleControl === false
    },
    hasFrontTitleFiles: function () {
      return this.inventoryTitleDetails.frontTitleFiles.filter(x => x.status !== 'Deleted').length > 0
    },
    hasBackTitleFiles: function () {
      return this.inventoryTitleDetails.backTitleFiles.filter(x => x.status !== 'Deleted').length > 0
    },
    getFrontTitle: function () {
      return this.inventoryTitleDetails.frontTitleFiles.filter(x => x.status !== 'Deleted').length > 0 ? 'Front Title File' : 'Front Title File - Not Provded'
    },
    getBackTitle: function () {
      return this.inventoryTitleDetails.backTitleFiles.filter(x => x.status !== 'Deleted').length > 0 ? 'Back Title File' : 'Back Title File - Not Provided'
    },
    getPayOffInfoTitle: function () {
      return this.inventoryTitleDetails.payOffInfoFiles.filter(x => x.status !== 'Deleted').length > 0 ? 'Payoff Info File' : 'Payoff Info File - Not Provided'
    },
    getLostAffidavitTitle: function () {
      return this.inventoryTitleDetails.lostTitleAffidavitFiles.filter(x => x.status !== 'Deleted').length > 0 ? 'Lost Title Affidavit' : 'Lost Title Affidavit - Not Provided'
    },
    getPayOffLienSatisfactionTitle: function () {
      return this.inventoryTitleDetails.payOffLienSatisfactionFiles.filter(x => x.status !== 'Deleted').length > 0 ? 'Payoff Lien Satisfaction File' : 'Payoff Lien Satisfaction File - Not Provided'
    },
    allowedTitleStatusOptions: function () {
      return this.definedTypes.titleStatusOptions.options.filter((x) => x.id !== this.definedTypes.titleStatusOptions.Pending)
    },
    showTitleDocs: function () {
      return (this.selectedTitleStatus && this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.Yes)
    },
    showTitlePayOffDocs: function () {
      return (this.selectedTitleStatus && this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.PayOff)
    },
    showLostTitleDocs: function () {
      return (this.selectedTitleStatus && this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.Lost)
    },
    isTitleYes: function () {
      return (this.selectedTitleStatus && (this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.Yes))
    },
    isTitlePayOff: function () {
      return (this.selectedTitleStatus && (this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.PayOff))
    },
    isLostTitle: function () {
      return (this.selectedTitleStatus && (this.selectedTitleStatus.id === this.definedTypes.titleStatusOptions.Lost))
    },
    isTitlePayOffDocsRequired: function () {
      return this.isTitlePayOff
    },
    isTitleLostDocsRequired: function () {
      return this.isLostTitle
    },
    isTitleDocsRequired: function () {
      return this.isTitleYes
    },
    allDocsVerified: function () {
      return this.totalVerifiedUploadedDocs === this.totalValidUploadedDocs && this.totalVerifiedUploadedDocs > 0
    },
    verifiedDocsLabel: function () {
      return this.totalVerifiedUploadedDocs + ' of ' + this.totalValidUploadedDocs + ' uploaded documents verifed.'
    },
    validFiles: function () {
      return this.inventoryTitleDetails.frontTitleFiles.concat(this.inventoryTitleDetails.backTitleFiles, this.inventoryTitleDetails.payOffInfoFiles, this.inventoryTitleDetails.lostTitleAffidavitFiles, this.inventoryTitleDetails.payOffLienSatisfactionFiles).filter((x) => x.loaded === true && (!x.status || x.status !== 'Deleted'))
    },
    totalValidUploadedDocs: function () {
      if (this.showTitleDocs) {
        return this.inventoryTitleDetails.frontTitleFiles.concat(this.inventoryTitleDetails.backTitleFiles, this.inventoryTitleDetails.payOffInfoFiles, this.inventoryTitleDetails.payOffLienSatisfactionFiles).filter((x) => x.loaded === true && (!x.status || x.status !== 'Deleted')).length
      }

      if (this.showTitlePayOffDocs) {
        return this.inventoryTitleDetails.payOffInfoFiles.concat(this.inventoryTitleDetails.payOffLienSatisfactionFiles).filter((x) => x.loaded === true && (!x.status || x.status !== 'Deleted')).length
      }

      if (this.showLostTitleDocs) {
        return this.inventoryTitleDetails.lostTitleAffidavitFiles.filter((x) => x.loaded === true && (!x.status || x.status !== 'Deleted')).length
      }
      return this.validFiles.length
    },
    totalVerifiedUploadedDocs: function () {
      return this.validFiles.map((x) => x.verified).filter(y => y === true).length
    },
    titleReceivedLabel: function () {
      if (this.inventoryTitleDetails && this.inventoryTitleDetails.titleReceivedStatusModifiedByFullName && this.inventoryTitleDetails.titleReceivedStatusModifiedAt) {
        return this.inventoryTitleDetails.titleReceivedStatusModifiedByFullName + ' ' + this.$options.filters.formatDate(this.inventoryTitleDetails.titleReceivedStatusModifiedAt)
      }
      return ''
    }
  },
  methods: {
    onFrontTitleClone: function () {
      let that = this
      this.inventoryTitleDetails.frontTitleFiles.filter(x => x.status !== 'Deleted').forEach((x) => {
        if (x.id) {
          that.inventoryTitleDetails.backTitleFiles.push({
            isClone: true,
            type: x.type,
            classType: 'TitleBack',
            imageData: x.imageData,
            loaded: true,
            isPublic: false,
            uploadName: x.fileName,
            name: x.name,
            uniqueId: x.uniqueId,
            fileType: x.fileType,
            fileName: x.fileName
          })
        } else {
          that.inventoryTitleDetails.backTitleFiles.push(x)
        }
      })
    },
    onBackTitleClone: function () {
      let that = this
      this.inventoryTitleDetails.backTitleFiles.filter(x => x.status !== 'Deleted').forEach((x) => {
        if (x.id) {
          that.inventoryTitleDetails.frontTitleFiles.push({
            isClone: true,
            type: x.type,
            classType: 'TitleFront',
            imageData: x.imageData,
            loaded: true,
            isPublic: false,
            uploadName: x.fileName,
            name: x.name,
            uniqueId: x.uniqueId,
            fileType: x.fileType,
            fileName: x.fileName
          })
        } else {
          that.inventoryTitleDetails.frontTitleFiles.push(x)
        }
      })
    },
    onLienStatusChanged: function () {
      this.inventoryTitleDetails.lienholderSatisfied = this.lienStatus === 'Satisfied'
      if (this.inventoryTitleDetails.lienholderSatisfied === false) {
        this.selectedTitleStatus = this.definedTypes.titleStatusOptions.options.find((x) => (x.id === this.definedTypes.titleStatusOptions.PayOff))
      }
      if (this.inventoryTitleDetails.lienholderSatisfied === true) {
        this.selectedTitleStatus = this.definedTypes.titleStatusOptions.options.find((x) => (x.id === this.definedTypes.titleStatusOptions.Yes))
      }
    },
    toggleDocView: function (status) {
      this.isDocViewActive = status
    },
    initValues: function () {
      logger.logInfo('Title capture detail control is initializing')
      this.inventoryTitleDetails = this.value
      if (this.inventoryTitleDetails) {
        this.lienStatus = ''
        if (this.inventoryTitleDetails.lienholderListed && this.inventoryTitleDetails.lienholderSatisfied === false) {
          this.lienStatus = 'PayOff'
          this.selectedTitleStatus = this.definedTypes.titleStatusOptions.options.find((x) => (x.id === this.definedTypes.titleStatusOptions.PayOff))
        }
        if (this.inventoryTitleDetails.lienholderListed && this.inventoryTitleDetails.lienholderSatisfied === true) {
          this.lienStatus = 'Satisfied'
          this.selectedTitleStatus = this.definedTypes.titleStatusOptions.options.find((x) => (x.id === this.definedTypes.titleStatusOptions.Yes))
        }
        if (!this.selectedTitleStatus && this.inventoryTitleDetails.titleStatus !== 'Pending') {
          this.selectedTitleStatus = this.definedTypes.titleStatusOptions.options.find((x) => (x.name === this.inventoryTitleDetails.titleStatus || x.altName === this.inventoryTitleDetails.titleStatus))
        }
        if (this.inventoryTitleDetails.bankId !== null && this.inventoryTitleDetails.bankId !== 0) {
          this.selectedBank = { id: this.inventoryTitleDetails.bankId, name: this.inventoryTitleDetails.bankName }
        }
        this.goodUntilDate = this.inventoryTitleDetails.goodUntilDate ? new Date(this.inventoryTitleDetails.goodUntilDate) : null
      }
    },
    deleteForTitleYes: function () {
      this.inventoryTitleDetails.lostTitleAffidavitFiles.forEach((x) => {
        this.$set(x, 'status', 'Deleted')
        this.$set(x, 'verified', false)
      })
    },
    deleteForTitlePayOff: function () {
      this.inventoryTitleDetails.frontTitleFiles.forEach((x) => {
        this.$set(x, 'status', 'Deleted')
        this.$set(x, 'verified', false)
      })

      this.inventoryTitleDetails.backTitleFiles.forEach((x) => {
        this.$set(x, 'status', 'Deleted')
        this.$set(x, 'verified', false)
      })

      this.inventoryTitleDetails.lostTitleAffidavitFiles.forEach((x) => {
        this.$set(x, 'status', 'Deleted')
        this.$set(x, 'verified', false)
      })
    },
    deleteForTitleLost: function () {
      this.inventoryTitleDetails.frontTitleFiles.forEach((x) => {
        this.$set(x, 'status', 'Deleted')
        this.$set(x, 'verified', false)
      })

      this.inventoryTitleDetails.backTitleFiles.forEach((x) => {
        this.$set(x, 'status', 'Deleted')
        this.$set(x, 'verified', false)
      })

      this.inventoryTitleDetails.payOffInfoFiles.forEach((x) => {
        this.$set(x, 'status', 'Deleted')
        this.$set(x, 'verified', false)
      })

      this.inventoryTitleDetails.payOffLienSatisfactionFiles.forEach((x) => {
        this.$set(x, 'status', 'Deleted')
        this.$set(x, 'verified', false)
      })
    }
  },
  watch: {
    value: function (newValue, oldvalue) {
      this.initValues()
    },
    selectedTitleStatus: function (newValue, oldvalue) {
      if (newValue && newValue.name !== this.inventoryTitleDetails.titleStatus) {
        this.value.titleStatus = newValue.name
      }
    },
    selectedBank: function (newValue, oldValue) {
      if (newValue && newValue.id !== this.inventoryTitleDetails.bankId) {
        this.value.bankId = newValue.id
        this.value.bankName = newValue.name
      }
    },
    goodUntilDate: function (newValue, oldValue) {
      if (newValue && oldValue) {
        this.value.goodUntilDate = newValue
      }
    }
  },
  mounted: function () {
    this.initValues()
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 900px !important;
    max-width: 900px !important;
    width: 900px !important;
  }

  .modal-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }

  .el-steps--simple {
    padding-left :0;
    padding-right :0;
    padding-bottom :0;
  }

  .file-container {
    margin-bottom: 50px;
  }
</style>

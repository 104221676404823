<template>
  <div class="navbar-item has-dropdown is-hoverable">
    <a class="navbar-link">
        {{currentUser.firstName + ' ' + currentUser.lastName}}
    </a>
    <div class="navbar-dropdown">
      <a class="navbar-item" v-show="isAuthenticated" >
        Profile
      </a>
      <a class="navbar-item" v-show="isAuthenticated" @click="showLogout" >
        Signout
      </a>
      <a class="navbar-item" v-show="isAuthenticated && isAdmin && !isInTabletMode" @click="tabletMode" >
        Enable Tablet Mode
      </a>
      <a class="navbar-item" v-show="isAuthenticated && canAccessSecurityToken" @click="revealSecurityToken" v-tooltip="securityTokenTitle">
        {{ securityTokenDisplay }}
      </a>
      <hr class="navbar-divider">
      <div class="navbar-item">
        <span @click="onVersionClick">Version {{currentAppVersion}}</span>
      </div>
      <div class="navbar-item">
        <counter></counter>
      </div>
    </div>
  </div>
</template>

<script>
import defaultAppConfig from '@/config/index'
import counterComponent from './CounterComponent'
import { createNamespacedHelpers } from 'vuex'
import util from '@/utils'
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('user')
const mapAuthGetters = createNamespacedHelpers('auth').mapGetters

export default {
  name: 'Profile',
  components: {
    'counter': counterComponent
  },
  data () {
    return {
      isLoading: false,
      defaultSecurityToken: '********',
      serverErrorMessage: '',
      isSecurityTokenRevealed: false
    }
  },
  computed: {
    showProfile () {
      return true
    },
    ...mapState(['isAuthenticated', 'currentUser']),
    ...mapGetters(['allowedRoles', 'hasFeatureAccess']),
    ...mapAuthGetters(['securityToken']),

    securityTokenDisplay () {
      let securityTokenParts = this.securityToken.split(',')
      return this.isSecurityTokenRevealed ? securityTokenParts[0] : (this.isLoading ? 'Loading...' : this.defaultSecurityToken)
    },
    securityTokenTitle () {
      let securityTokenParts = this.securityToken.split(',')
      return this.isSecurityTokenRevealed ? '<b>PREVIOUS:</b> ' + securityTokenParts[1] + '<br/>' + '<b>CURRENT:</b> ' + securityTokenParts[0] : (this.isLoading ? 'Loading...' : this.defaultSecurityToken)
    },
    availableRolesOther () {
      return this.$store.getters['user/allowedRoles']
    },
    canAccessSecurityToken: function () {
      return this.hasFeatureAccess('view.security.token')
    },
    isAdmin () {
      return this.$store.getters['user/isInRole']('Admin Role')
    },
    currentAppVersion: function () {
      return defaultAppConfig.appVersion
    },
    isInTabletMode: function () {
      return util.isInTabletMode()
    }
  },
  methods: {
    ...mapActions(['toggleDesign']),
    revealSecurityToken: function () {
      let that = this
      if (this.isSecurityTokenRevealed) return
      this.$store.dispatch('auth/revealSecurityToken', { subscriberId: this.currentUser.subscriberId })
      that.isSecurityTokenRevealed = true
      setTimeout(function () {
        that.isSecurityTokenRevealed = false
      }, 60000)
    },
    onVersionClick: function () {
      this.toggleDesign()
    },
    showLogout: function () {
      this.$store.dispatch('auth/logoutRequest')
    },
    tabletMode: function () {
      this.$store.dispatch('auth/tabletMode')
    }
  }
}
</script>

import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import SectionHeader from '@/components/generic/SectionHeader'

var mixin = {
  components: {
    'error-display-component': ErrorDisplayComponent,
    'section-header': SectionHeader
  }
}
export default mixin

<template>
  <div v-if="hasConvertConsignedAccess && isTypeConsigned">
    <div class="quick-action" v-if="!isConvertConsignedConfirmationActive">
        <button class="button" @click="onRequestConvertConsigned()" title="Click to convert Consigned to Purchased">
            <b-icon pack="fal" icon="exchange" custom-size="fa-lg"></b-icon>
        </button>
    </div>
    <portal to="global-modal-portal" v-else>
        <b-modal :active.sync="isConvertConsignedConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
            <div class="modal-card" style="width: auto" v-if="!showTradeDealSelectorDialog">
              <header class="modal-card-head">
                  <p class="modal-card-title" v-if="!inventoryConversionComplete">Convert Consigned Inventory Item <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a>?</p>
                  <p class="modal-card-title" v-else>Consigned Converted Successfully</p>
              </header>
              <section class="modal-card-body" style="padding-left: 10px; padding-right: 10px; max-width: 640px; min-width: 640px;">
                <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
                <div v-if="purchaseConversion">
                  <p>Are you sure you wish to convert Consignment <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a>?</p>
                </div>
                <div v-else-if="tradeInConversion">
                    <div class="trade-in-container">
                      <inventory-selector
                        label="This trade in is coming from the purchase of"
                        :required="true"
                        v-model="inventorySoldItem"
                        :selectedDealer="currentDealer"
                        :allowNew="false"
                        :showInstockOnly="false"
                        :showSoldOnly="true"
                        validateAs="purchased inventory item"
                        v-on:on-inventory-selected="onTradeItemSelected">
                      </inventory-selector>
                    </div>
                    <div v-if="selectedTradeDeal" class="trade-in-container">
                      <div class="field">
                        <label class="label no-content-wrap">Trade Allowance</label>
                        <div class="control has-icons-left">
                          <money name="tradeAllowance" data-vv-as="trade allowance" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('tradeAllowance') }" type="text" placeholder="0.00" v-model="tradeAllowance" v-bind="$globalMoneyFormat"></money>
                          <span v-show="errors.has('tradeAllowance')" class="help is-danger"><i v-show="errors.has('tradeAllowance')" class="fas fa-exclamation-triangle"></i> {{ errors.first('tradeAllowance') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div v-if="isTradeSelectionLoading || selectedTradeDeal" class="trade-in-container">
                      <div class="field">
                        <label class="label">Linked Deal</label>
                        <div class="control">
                          <span class="has-text-black title is-6" v-if="isTradeSelectionLoading">Loading matching deals...</span>
                          <span class="has-text-success title is-6" v-if="selectedTradeDeal">{{selectedTradeDeal.purchaserFullName}} - ({{selectedTradeDeal.dealStatus}})</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="!inventoryConversionComplete">
                      <div class="columns is-multiline" style="width: 640px">
                        <div class="column is-6">
                          <div class="has-text-centered">
                            <button class="button is-danger" :class="{'is-loading': isSaving }"  @click="onConvertPurchase()" v-if="!inventoryConversionComplete">Convert as purchase</button>
                          </div>
                        </div>
                        <div class="column is-6">
                          <div class="has-text-centered">
                            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConvertTradeIn()" v-if="!inventoryConversionComplete">Convert as trade in</button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <p>Consigned <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a> has been succesfully converted.</p>
                      <br/>
                      <p>A new inventory item has been generated with stock number <a @click.stop.prevent="onStockNoClick(convertedInventory)"><span class="has-text-weight-semibold">{{ convertedInventory.stockNo }}</span></a>.</p>
                      <br/>
                      <p>Additional updates are required. Please click the view button below to complete the conversion process.</p>
                    </div>
                </div>
              </section>
              <footer class="modal-card-foot">
                  <button class="button is-danger" :class="{'is-loading': isSaving }"  @click="onConvertConsigned()" v-if="purchaseConversion">Confirm Purchase Conversion</button>
                  <button class="button is-danger" :class="{'is-loading': isSaving }"  @click="onConfirmTradeIn()" v-if="selectedTradeDeal && tradeInConversion">Confirm Trade In Conversion</button>
                  <button class="button is-success" @click="onShowConvertedInventory()" v-if="inventoryConversionComplete">Complete New Stock-In</button>
                  <button class="button" @click="onCancel" v-if="!inventoryConversionComplete">Close</button>
              </footer>
            </div>
            <div class="modal-card" style="width: auto" v-else>
              <header class="modal-card-head">
                <p class="modal-card-title" v-if="noTradeDealsAvailable === true">Warning - No deals found</p>
                <p class="modal-card-title" v-if="linkedDealsByTradeParent.length > 1">Warning - Trade linked to multiple deals</p>
                <p class="modal-card-title" v-if="matchingDealsByTradeParent.length > 1">Warning - Multiple matching deals</p>
              </header>
              <section class="modal-card-body" v-if="noTradeDealsAvailable === true">
                <div class="title has-text-danger is-6">There are no deals found linked to the selected inventory item. Please load a deal for the purchased vehicle and re-link the trade item</div>
              </section>
              <section class="modal-card-body" v-if="linkedDealsByTradeParent.length > 1">
                <div class="title has-text-danger is-6">The trade inventory item is incorrectly linked to multiple active deals and needs to be fixed</div>
              </section>
              <section class="modal-card-body has-text-info is-6" v-if="matchingDealsByTradeParent.length > 1">
                <div class="title has-text-info is-6">There are multiple deals linked to the selected inventory item. Please select the appropriate deal below</div>
                <div class="buttons has-addons">
                  <button :class="{'button': true, 'is-info': selectedTradeDeal && selectedTradeDeal.dealId === matchingDeal.dealId}" v-for="matchingDeal in matchingDealsByTradeParent" :key="matchingDeal.dealId" @click="onSelectedTradeDeal(matchingDeal)">
                    {{matchingDeal.purchaserFullName}}
                  </button>
                </div>
              </section>
              <footer class="modal-card-foot">
                <button class="button is-primary" @click="showTradeDealSelectorDialog = false">Ok</button>
              </footer>
            </div>
        </b-modal>
    </portal>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import inventoryService from '../../services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import InventorySelector from '@/components/generic/InventorySelector.vue'
import { createNamespacedHelpers } from 'vuex'
import dealService from '@/services/dealService'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'ConvertConsignedAction',
  props: {
    inventoryItem: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isConvertConsignedConfirmationActive: false,
      convertedInventory: null,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4(),
      inventoryConversionComplete: false,
      tradeInConversion: false,
      inventorySoldItem: null,
      selectedTradeDeal: null,
      isTradeSelectionLoading: false,
      purchaseConversion: false,
      showTradeDealSelectorDialog: false,
      noTradeDealsAvailable: false,
      linkedDealsByTradeParent: [],
      matchingDealsByTradeParent: [],
      tradeAllowance: 0.00,
      saleConversion: false
    }
  },
  components: {
    'error-display-component': ErrorDisplayComponent,
    'inventory-selector': InventorySelector
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    hasConvertConsignedAccess: function () {
      let allowedPermissions = ['inventory.modify.convert-consigned-purchase']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isTypeConsigned: function () {
      return this.inventoryItem.type === 'Consigned'
    },
    inventoryItemId: function () {
      return this.inventoryItem.id
    }
  },
  methods: {
    onShowConvertedInventory: function () {
      if (this.saleConversion) {
        this.isConvertPpaConfirmationActive = false
        this.$router.push({name: 'EditInventory', params: { id: this.convertedInventory.id, defaultSalesPerson: this.currentUser, defaultPreEntry: false }})
      } else {
        this.isConvertPpaConfirmationActive = false
        this.$router.push({name: 'EditInventory', params: { id: this.convertedInventory.id, defaultSalesPerson: this.currentUser, defaultPreEntry: false, postConversion: 1, allowStockNoGeneration: 1 }})
      }
    },
    onRequestConvertConsigned: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isConvertConsignedConfirmationActive = true
    },
    onConvertConsigned: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      inventoryService.convertConsigned(this.inventoryItem).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.saleConversion = true
          this.convertedInventory = response
          this.inventoryConversionComplete = true
          this.purchaseConversion = false
          this.tradeInConversion = false
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onCancel: function () {
      if (this.convertedInventory) {
        eventBus.$emit('inventory-changed', [this.convertedInventory])
      }
      this.isConvertConsignedConfirmationActive = false
      this.tradeInConversion = false
      this.purchaseConversion = false
    },
    reset: function () {
      this.convertedInventory = null
      this.serverErrors = []
      this.serverErrorMessage = []
      this.inventoryConversionComplete = false
      this.tradeInConversion = false
      this.tradeAllownce = 0.00
      this.saleConversion = false
    },
    onConvertTradeIn: function () {
      this.tradeInConversion = true
    },
    onConvertPurchase: function () {
      this.purchaseConversion = true
    },
    onTradeItemSelected: function (selectedTradeParentItem) {
      this.isTradeSelectionLoading = true
      dealService.getDealsByInventoryForTrade(null, selectedTradeParentItem).then(response => {
        this.isTradeSelectionLoading = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.matchingDealsByTradeParent = response.matchingDeals
          this.linkedDealsByTradeParent = response.linkedDeals
          this.noTradeDealsAvailable = false
          this.showTradeDealSelectorDialog = false
          this.selectedTradeDeal = null

          if (this.linkedDealsByTradeParent.length === 1) {
            // The good case where this trade is already linked to one deal for the selected inventory
            this.selectedTradeDeal = this.linkedDealsByTradeParent[0]
          } else if (this.linkedDealsByTradeParent.length > 1) {
            // The bad case where this trade is already linked to multiple deals which should not be the case. We need to prompt for resolution
          } else if (this.linkedDealsByTradeParent.length === 0) {
            // The not linked case and we need to prompt for deal selection
            if (this.matchingDealsByTradeParent.length === 0) {
              this.noTradeDealsAvailable = true
              this.showTradeDealSelectorDialog = true
            } else if (this.matchingDealsByTradeParent.length > 1) {
              this.showTradeDealSelectorDialog = true
            } else if (this.matchingDealsByTradeParent.length === 1) {
              this.selectedTradeDeal = this.matchingDealsByTradeParent[0]
            }
          }
          if (this.selectedTradeDeal) {
            if (this.selectedTradeDeal.purchaserType === 'C') {
              this.selectedSource = this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Individual)
              this.selectedCustomer = {
                id: this.selectedTradeDeal.purchaserId,
                fullName: this.selectedTradeDeal.purchaserFullName
              }
            }

            if (this.selectedTradeDeal.purchaserType === 'S') {
              this.selectedSource = this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Wholesaler)
              this.selectedWholeSaler = {
                id: this.selectedTradeDeal.purchaserId,
                name: this.selectedTradeDeal.purchaserFullName
              }
            }
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isTradeSelectionLoading = false
        this.matchingDealsByTradeParent = []
        this.linkedDealsByTradeParent = []
        this.selectedTradeDeal = null
        this.noTradeDealsAvailable = false
      })
    },
    onConfirmTradeIn: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      let consignmentToTradeInModel = {inventoryId: this.inventoryItem.id, soldInventoryId: this.inventorySoldItem.id, dealId: this.selectedTradeDeal.dealId, tradeAllowance: this.tradeAllowance}
      inventoryService.convertConsignedToTradeIn(consignmentToTradeInModel).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.convertedInventory = response
          this.inventoryConversionComplete = true
          this.purchaseConversion = false
          this.tradeInConversion = false
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onSelectedTradeDeal: function (deal) {
      this.selectedTradeDeal = deal
    },
    closeTradeDealSelectorDialog: function () {
      this.showTradeDealSelectorDialog = false
      this.inventorySoldItem = null
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  watch: {
    inventoryItemId: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.reset()
      }
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }
  .selection-button{
    margin: 5px !important
  }

  .modal-card-body {
    max-height: 450px !important;
    min-height: 450px !important;
    height: 450px !important;
  }

  .trade-in-container {
    margin-top: 1em;
  }
</style>

<template>
  <inventory-grid-pod
    v-observe-visibility="visibilityChanged"
    :sortField="sortField"
    :sortDirection="sortOrder"
    :title="title"
    :value="inventoryData"
    v-on:on-refresh="onPodRefreshRequired"
    v-on:on-item-click="onItemClick"
    v-on:on-header-click="onHeaderClick"
    :isLoading="isLoading">
  </inventory-grid-pod>
</template>
<script>

import axios from 'axios'
import ViewInventory from '@/components/inventory/ViewInventory'
import inventoryService from '@/services/inventoryService'
import { createNamespacedHelpers } from 'vuex'
import InventoryGridPod from '@/components/generic/pods/InventoryGridPod'
import eventBus from '@/eventBus'

const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'InventorySoldStockPod',
  props: {
    title: {
      type: String,
      default: ''
    },
    inStock: {
      type: Boolean,
      default: true
    },
    isSold: {
      type: Boolean,
      default: false
    },
    searchParameters: {
      type: Object,
      default: function () {
        return {
          query: '',
          selectedStartDate: null,
          selectedEndDate: null
        }
      }
    }
  },
  data () {
    return {
      total: 0,
      isLoading: false,
      page: 0,
      sortField: 'daysOnLot',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      serverErrorMessage: '',
      perPage: this.$store.state.config.defaultPageSize,
      inventoryData: {
        columns: [
          {
            id: 'id',
            name: 'Id',
            visible: false
          },
          {
            id: 'stockNo',
            name: 'Stock No.',
            visible: true,
            maxWidth: '80px'
          },
          {
            id: 'status',
            name: 'Status',
            visible: true,
            format: 'status',
            maxWidth: '70px'
          },
          {
            id: 'name',
            name: 'Name',
            visible: true
          },
          {
            id: 'vinNo',
            name: 'Vin',
            visible: true,
            maxWidth: '50px',
            format: 'vin'
          },
          {
            id: 'daysOnLot',
            name: 'DOL',
            visible: true,
            format: 'age'
          },
          {
            id: 'source',
            name: 'From',
            visible: true
          }
        ],
        data: []
      },
      query: this.searchParameters.query,
      selectedStartDate: this.searchParameters.selectedStartDate,
      selectedEndDate: this.searchParameters.selectedEndDate
    }
  },
  components: {
    'inventory-grid-pod': InventoryGridPod,
    'view-inventory': ViewInventory
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentUser', 'currentPendingActions', 'currentNotifications']),
    ...mapDealerState(['currentDealer']),
    requestId: function () {
      return 'inventory-by-criteria-' + this.title
    }
  },
  methods: {
    onHeaderClick: function () {
      if (this.hasFeatureAccess('inventory.view')) {
        this.$router.push({name: 'ListInventory', query: { fromDashboard: true, inStock: true, sold: false }})
      }
    },
    onItemClick: function (item) {
      if (this.hasFeatureAccess('inventory.view')) {
        this.$router.push({name: 'InventoryQuickView', params: { id: item.id, isLoanerPod: false, offerPod: -1 }})
      }
    },
    onPodRefreshRequired: function () {
      this.loadData()
    },
    loadData: function () {
      const searchCriteria = {
        dealerId: this.currentDealer.id,
        inStock: this.inStock,
        sold: this.isSold,
        pageSize: this.perPage,
        sortBy: this.sortField + '.' + this.sortOrder,
        page: this.page,
        requestId: this.requestId,
        query: this.query,
        endDate: this.selectedEndDate,
        startDate: this.selectedStartDate
      }
      this.isLoading = true
      inventoryService.getInventoryByCriteria(searchCriteria).then(response => {
        this.inventoryData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          this.inventoryData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.inventoryData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        if (this.currentDealer.id) {
          this.loadData()
        }
      }
    },
    onPodSearchUpdate: function (data) {
      this.query = data.searchParameters.query
      this.selectedStartDate = data.searchParameters.selectedStartDate
      this.selectedEndDate = data.searchParameters.selectedEndDate
      this.loadData()
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.loadData()
      }
    }
  },
  beforeDestroy () {
    axios.cancel(this.requestId)
    eventBus.$off('pod-search-update', this.onPodSearchUpdate)
  },
  mounted: function () {
    eventBus.$on('pod-search-update', this.onPodSearchUpdate)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

<template>
    <div v-if="!isSetInboundStatusActive && hasFeatureAccess(editAccessPermission)" class="is-inline-block">
      <span :id="instanceId" :name="instanceId" class="icon is-inline-block has-text-centered" title="Update inbound status." @click.stop.prevent="onChangeInboundStatus()" v-if="isListMode">
        <i class="fal fa-truck fa-lg"/>
      </span>
      <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
          <button class="button" @click.stop.prevent="onChangeInboundStatus()" title="Update inbound status.">
            <b-icon pack="fal" icon="truck" custom-size="fa-lg"></b-icon>
          </button>
          <span v-if="label.length > 0">{{label}}</span>
      </div>
    </div>
    <portal to="global-modal-portal" v-else>
      <b-modal :active.sync="isSetInboundStatusActive" :width="640" scroll="keep" :has-modal-card="true" v-if="dealItem && dealItem.id">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <div class="columns">
              <div class="column is-narrow is-12">
                <p class="modal-card-title">{{ modalTitle }} </p>
              </div>
            </div>
          </header>
          <section class="modal-card-body" style="padding:12px" v-if="!isLoading">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <form class="view-container">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Inbound status</label>
                    <div class="control">
                      <multiselect v-model="arrivalStatus" :options="definedTypes.dealInboundStatusTypes.options" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select inbound arrival status" label="name" track-by="altName" data-vv-as="type" :class="errors.has('type') ? 'is-danger' : '' " name="type" :hideSelected="true"></multiselect>
                      <span v-show="showInboundSelectionRequired" class="help is-danger">
                        <i v-show="showInboundSelectionRequired" class="fas fa-exclamation-triangle"></i> Inbound status is required
                      </span>
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <label class="label">Inbound Status History</label>
                  <table class="table is-bordered is-hoverable is-fullwidth">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Initial Status</th>
                        <th>New Status</th>
                        <th>Actioned By</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tr v-for="(inboundStatusHistoryItem, index) in currentInboundStatusHistoryItems" :key="inboundStatusHistoryItem.id">
                      <td>{{index + 1}}</td>
                      <td>
                        {{getMatchingInboundStatus(inboundStatusHistoryItem.fromStatus)}}
                      </td>
                      <td>{{getMatchingInboundStatus(inboundStatusHistoryItem.toStatus)}}</td>
                      <td>{{inboundStatusHistoryItem.actionedBy}}</td>
                      <td>{{inboundStatusHistoryItem.actionedAt | formatDateOnly}}</td>
                    </tr>
                    <tr v-if="currentInboundStatusHistoryItems.length === 0">
                      <td colspan="5">None</td>
                    </tr>
                  </table>
                </div>
              </div>
            </form>
          </section>
          <section class="modal-card-body"  style="height:500px; width: 640px;" v-else>
            <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="saveInboundStatus()">Save</button>
            <button class="button is-danger" @click="isSetInboundStatusActive = false">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </template>
<script>

import dealService from '@/services/dealService'
import { createNamespacedHelpers } from 'vuex'
import eventBus from '@/eventBus'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'ArrivalStatusChangeAction',
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    dealItem: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: 'is-width-600'
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isSetInboundStatusActive: false,
      startWith: '',
      isSaving: false,
      instanceId: this.$uuid.v4(),
      serverErrors: [],
      serverErrorMessage: '',
      currentInboundStatusHistoryItems: [],
      arrivalStatus: null,
      editAccessPermission: 'deal.modify',
      showInboundSelectionRequired: false,
      currentDeal: null
    }
  },
  methods: {
    setArrivalStatus: function () {
      if (this.currentDeal && this.currentDeal.inboundStatusType) {
        this.arrivalStatus = this.definedTypes.dealInboundStatusTypes.options.find(x => x.altName === this.currentDeal.inboundStatusType)
      } else {
        this.arrivalStatus = null
      }
    },
    loadDeal () {
      this.serverErrorMessage = ''
      this.isLoading = true
      this.isSaving = true
      let that = this
      this.currentInboundStatusHistoryItems = []
      dealService.single(that.dealItem.id).then(response => {
        that.currentDeal = response.deal
        that.currentInboundStatusHistoryItems = that.currentDeal.inboundStatusHistoryItems
        that.setArrivalStatus()
        that.isLoading = false
        that.isSaving = false
      }).catch((error) => {
        that.isLoading = false
        that.isSaving = false
        that.serverErrorMessage = error.message
      })
    },
    getMatchingInboundStatus: function (statusValue) {
      if (statusValue) {
        let result = this.definedTypes.dealInboundStatusTypes.options.find((x) => x.altName === statusValue)
        if (result && result.name) {
          return result.name
        }
      }
      return ''
    },
    onChangeInboundStatus: function () {
      this.isSetInboundStatusActive = true
      if (this.isListMode) {
        this.loadDeal()
      } else {
        this.setArrivalStatus()
        this.currentDeal = this.dealItem
        this.currentInboundStatusHistoryItems = this.currentDeal.inboundStatusHistoryItems
      }
    },
    saveInboundStatus: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.showInboundSelectionRequired = false
      if (!this.arrivalStatus || !this.arrivalStatus.altName) {
        this.showInboundSelectionRequired = true
        return
      }
      this.isSaving = true
      let selectedInboundStatus = this.arrivalStatus ? this.arrivalStatus.altName : ''
      this.currentDeal.inboundStatusType = selectedInboundStatus
      let dealModel = {
        ...this.currentDeal
      }
      let that = this
      dealService.setInboundStatus(dealModel).then(response => {
        that.isSaving = false
        if (response.errors) {
          that.serverErrors = response.errors
        } else {
          eventBus.$emit('reload-deal-list')
          eventBus.$emit('reload-deal')
          that.isSetInboundStatusActive = false
        }
      }).catch((error) => {
        that.serverErrorMessage = error.message
        that.isSaving = false
      })
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['currentUser', 'hasFeatureAccess']),
    modalTitle: function () {
      let result = 'Update inbound arrival status for deal '
      if (this.dealItem && this.dealItem.customerName && this.dealItem.dealCode) {
        result = result + '(' + this.dealItem.customerName + ' - ' + this.dealItem.dealCode + ')'
      } else if (this.dealItem && this.dealItem.customerName) {
        result = result + '(' + this.dealItem.customerName + ')'
      } else if (this.dealItem && this.dealItem.dealCode) {
        result = result + '(' + this.dealItem.dealCode + ')'
      }
      return result
    }
  }
}
</script>

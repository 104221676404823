<template>
  <div class="tile is-ancestor is-flex search-results" style="flex-wrap: wrap;">
    <div class="tile is-parent is-3 search-result" v-for="searchResult in allSearchResults" :key="searchResult.id" v-if="isGridMode">
      <article class="tile is-child box view-container is-shadowless is-radiusless" v-if="searchResult.entityType === 'Inventory'">
        <form>
          <div>
            <h4 class="is-uppercase has-text-weight-bold">Type: {{searchResult.entityType}}<i class="fas fa-car icon-more"></i></h4>
          </div>
          <div>
            <div class="columns">
              <div class="column is-6">
                <label class="label">Dealer</label>
                <div class="field">
                  {{searchResult.dealerName}}
                </div>
                <label class="label">Type</label>
                <div class="field">
                  {{searchResult.type}}
                </div>
                <label class="label">Vin No</label>
                <div class="field">
                  {{searchResult.vinNo}}
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Vehicle</label>
                <div class="field">
                  {{searchResult.name}}
                </div>
                <label class="label">Stock No</label>
                <div class="field">
                  <a @click.stop.prevent="onStockNoClick(searchResult)">
                    <span class="has-text-weight-semibold">{{ searchResult.stockNo }}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <a href="#" class="button is-primary" @click="onInspectInventory(searchResult)" v-if="hasFeatureAccess('inventory.modify')">Inspect</a>
            <a href="#" class="button is-primary" @click="onMarkArrived(searchResult)" v-if="hasFeatureAccess('inventory.modify.arrive')">Mark Arrived</a>
            <a href="#" class="button is-primary" @click="onLoanOut(searchResult)" v-if="hasFeatureAccess('loan.create')">Loan Out</a>
          </div>
        </form>
      </article>
      <article class="tile is-child box view-container is-shadowless is-radiusless" v-if="searchResult.entityType === 'Deal'">
        <form>
          <div>
            <h4 class="is-uppercase has-text-weight-bold">Type: {{searchResult.entityType}}<i class="fas fa-dollar-sign icon-more"></i></h4>
          </div>
          <div>
            <div class="columns">
              <div class="column is-6">
                <label class="label">Customer</label>
                <div class="field">
                  {{searchResult.customerName}}
                </div>
                <label class="label" v-if="showPromisedDate(searchResult.type)">Expected Delivery Date</label>
                <div class="field" v-if="showPromisedDate(searchResult.type)">
                  {{searchResult.promiseDate | formatDate}}
                </div>
                <label class="label" v-if="!showPromisedDate(searchResult.type)">Delivery Date</label>
                <div class="field" v-if="!showPromisedDate(searchResult.type)">
                  {{searchResult.deliveryDate | formatDate}}
                </div>
                <label class="label">Status</label>
                <div class="field">
                  {{searchResult.type}}
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Sales Person</label>
                <div class="field">
                  {{searchResult.salesPersonName}}
                </div>
                <label class="label">Sold Date</label>
                <div class="field">
                  {{searchResult.soldAt | formatDateOnly}}
                </div>
                <label class="label">Deal Value</label>
                <div class="field">
                  {{searchResult.totalValue | currency}}
                </div>
              </div>
            </div>
          </div>
          <div class="buttons">
            <a href="#" class="button is-primary" @click="onInspectDeal(searchResult)" v-if="hasFeatureAccess('deal.modify')">Inspect</a>
          </div>
        </form>
      </article>
    </div>
    <div v-if="!searchActive && allSearchResults.length === 0" class="no-search-results">
      <div class="has-text-centered">
          <p>
              <b-icon
                  icon="emoticon-sad"
                  size="is-large">
              </b-icon>
          </p>
          <p>Your query matched no results.</p>
      </div>
    </div>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapGlobalGetters = createNamespacedHelpers('global').mapGetters

export default {
  name: 'GlobalSearchResults',
  data () {
    return {
      title: 'Items matching your query',
      renderMode: 0
    }
  },
  components: {
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    ...mapGlobalGetters(['allSearchResults', 'searchActive', 'currentSearchQuery']),
    isGridMode: function () {
      return this.renderMode === 0
    },
    isListMode: function () {
      return this.renderMode === 1
    }
  },
  methods: {
    onTabChanged (index) {
    },
    onInspectInventory (inventoryItem) {
      if (this.hasFeatureAccess('inventory.modify')) {
        this.$router.push({name: 'EditInventory', params: { id: inventoryItem.id }})
      }
    },
    onInspectDeal (dealItem) {
      if (this.hasFeatureAccess('deal.modify')) {
        this.$router.push({name: 'EditDeal', params: { id: dealItem.id }})
      }
    },
    onMarkArrived (inventoryItem) {
      if (this.hasFeatureAccess('inventory.modify.arrive')) {
        this.$router.push({name: 'EditInventory', params: { id: inventoryItem.id }})
      }
    },
    onLoanOut (inventoryItem) {
      if (this.hasFeatureAccess('loan.create')) {
        this.$router.push({name: 'EditInventory', params: { id: inventoryItem.id }})
      }
    },
    showPromisedDate: function (type) {
      return type && type !== 'Booked' && type !== 'Delivered'
    },
    onStockNoClick: function (results) {
      let that = this
      if (results && results.stockNo !== null && results.stockNo !== '') {
        this.$copyText(results.stockNo).then(
          function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Stock No. ' + results.stockNo + ' copied to clipboard',
              position: 'is-top',
              type: 'is-info'
            })
          }, function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Could not copy stock no. to clipboard',
              position: 'is-top',
              type: 'is-warning'
            })
          }
        )
      }
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      // Need to do something here
    }
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/designations',
    name: 'ListDesignations',
    component: () => import('@/components/configuration/designations/ListDesignations'),
    beforeEnter: requiresAuth
  },
  {
    name: 'DesignationsQuickView',
    path: '/configuration/designations/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/designations/ViewDesignation')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/designations/new',
    name: 'NewDesignation',
    component: () => import('@/components/configuration/designations/NewDesignation'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/designations/view/:id',
    name: 'ViewDesignation',
    component: () => import('@/components/configuration/designations/ViewDesignation'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/designations/edit/:id',
    name: 'EditDesignation',
    component: () => import('@/components/configuration/designations/EditDesignation'),
    beforeEnter: requiresAuth
  }
]

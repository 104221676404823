export default {
  total: 3,
  results: [
    {
      id: 1,
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 3,
      dealerName: 'Service',
      type: 0,
      companyName: 'EAG',
      firstName: 'Ben',
      middleName: 'Laden',
      initials: 'BLS',
      lastName: 'Smith',
      email: 'ben@hostedapp.co.za',
      mobile: '9173942910',
      office: '9173942910',
      home: '9173942910',
      fax: '9173942910',
      deliveryAddress1: 'Far',
      deliveryAddress2: 'Far',
      deliveryAddress3: 'Further',
      deliveryAddress4: 'Away',
      deliveryCity: 'City',
      deliveryCountry: 'Country',
      deliveryPostalcode: '26548',
      deliveryState: 'Free State',
      postalAddress1: '',
      postalAddress2: '',
      postalAddress3: '',
      postalAddress4: '',
      postalCity: '',
      postalCountry: '',
      postalPostalcode: '',
      postalState: ''
    },
    {
      id: 2,
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 3,
      dealerName: 'Service',
      type: 0,
      companyName: 'EAG',
      firstName: 'Ted',
      middleName: 'Allen',
      initials: 'TAS',
      lastName: 'Smith',
      email: 'ted@hostedapp.co.za',
      mobile: '1561514333',
      office: '1561514333',
      home: '1561514333',
      fax: '1561514333',
      deliveryAddress1: '15 Farberry Drive',
      deliveryAddress2: 'Somerset',
      deliveryAddress3: 'Agartha',
      deliveryAddress4: '',
      deliveryCity: 'Atlantis',
      deliveryCountry: 'Ferer',
      deliveryPostalcode: '1616',
      deliveryState: 'Jupiter',
      postalAddress1: '15 Farberry Drive',
      postalAddress2: 'Somerset',
      postalAddress3: 'Agartha',
      postalAddress4: '',
      postalCity: 'Atlantis',
      postalCountry: 'Ferer',
      postalPostalcode: '1616',
      postalState: 'Jupiter'
    },
    {
      id: 3,
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 3,
      dealerName: 'Service',
      type: 0,
      companyName: 'EAG',
      firstName: 'Jim',
      middleName: '',
      initials: 'JJ',
      lastName: 'Jones',
      email: 'jj@hostedapp.co.za',
      mobile: '1561654666',
      office: '',
      home: '',
      fax: '',
      deliveryAddress1: '',
      deliveryAddress2: '',
      deliveryAddress3: '',
      deliveryAddress4: '',
      deliveryCity: '',
      deliveryCountry: '',
      deliveryPostalcode: '',
      deliveryState: '',
      postalAddress1: '23 Avenue',
      postalAddress2: 'Middle Earth',
      postalAddress3: 'Second Left',
      postalAddress4: '',
      postalCity: 'Waypoint',
      postalCountry: 'Sevant',
      postalPostalcode: '0303',
      postalState: 'Not Here'
    }
  ]
}

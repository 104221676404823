<template>
  <section>
    <form data-vv-scope="service-request-inspection-type" @submit.prevent="validateBeforeSubmit" @change="clearErrors">
      <div class="columns is-multiline">
        <div class="column is-12">
          <button-list-selector
            validateAs="inspectiontype"
            label="Inspection Type"
            :required="true"
            scope="service-request-inspection-type"
            v-model="selectedServiceRequestInspectionType"
            :availableValues="availableInspectionTypes"></button-list-selector>
        </div>
        <div class="column is-12">
          <file-selector
          v-model="inspectionTypeAttachments"
          :enabled="hasFeatureAccess(editAccessPermission)"
          label="Inspection Type Documents"
          :multiple="true"
          :isBoxed="false"
          :usePortalViewer="false"
          @uploadStarted="uploadStarted"
          @uploadComplete="uploadComplete">
          </file-selector>
        </div>
      </div>
      <portal :to="useFooterPortal">
        <div class="field is-grouped">
          <p class="control" v-if="hasFeatureAccess(editAccessPermission)">
            <button :disabled="isUploadingFile" class="button is-primary" type="button" @click="validateBeforeSubmit">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click.prevent="onCancel()">Cancel</a>
          </p>
          <p class="control" v-if="hasFeatureAccess(deleteAccessPermission)">
            <button class="button is-warning" type="button" @click.prevent="onDelete">Delete</button>
          </p>
        </div>
      </portal>
    </form>

    <b-modal :active.sync="isDeleteConfirmationActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Confirm Delete Action</p>
        </header>
        <section class="modal-card-body">
          Are you sure you wish to delete this inspection type item?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-danger" @click="onConfirmDelete()">Delete</button>
          <button class="button" @click="isDeleteConfirmationActive = false">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import FileSelector from '@/components/generic/FileSelector'
import utilitiesMixin from '@/mixins/generic/utilities'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'ServiceRequestInspectionType',
  mixins: [utilitiesMixin],
  props: {
    useFooterPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    forServiceRequest: {
      type: Object,
      default: function () {
        return {
          id: 0,
          inspectionTypes: []
        }
      }
    },
    currentServiceRequestInspectionTypeId: {
      default: function () {
        return '0'
      }
    }
  },
  components: {
    'button-list-selector': ButtonListSelector,
    'file-selector': FileSelector
  },
  data () {
    return {
      currentServiceRequestInspectionType: {
        id: this.$uuid.v4(),
        status: 'Active',
        inspectionTypeAttachments: [],
        serviceRequestInspectionTypeId: null
      },
      inspectionTypeAttachments: [],
      isDeleteConfirmationActive: false,
      selectedServiceRequestInspectionType: null,
      createAccessPermission: 'servicerequest.create',
      viewAccessPermission: 'servicerequest.view',
      editAccessPermission: 'servicerequest.modify',
      deleteAccessPermission: 'servicerequest.delete',
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    availableInspectionTypes: function () {
      var usedTypes = []
      this.forServiceRequest.inspectionTypes.forEach((type) => {
        if (this.currentServiceRequestInspectionTypeId !== type.id) {
          var found = this.definedTypes.inspectionTypes.find(function (element) {
            return element.id === type.inspectionType
          })
          if (found) {
            usedTypes.push(found)
          }
        }
      })
      return this.definedTypes.inspectionTypes.filter(f => !usedTypes.includes(f))
    }
  },
  methods: {
    validateBeforeSubmit () {
      this.$validator.validateAll('service-request-inspection-type').then((result) => {
        if (result) {
          this.onSave()
        } else {
          this.$goToValidationError(this.$validator.errors.items[0].id)
        }
      })
    },
    clearErrors: function () {
      this.errors.clear()
    },
    onConfirmDelete: function () {
      this.isDeleteConfirmationActive = false
      this.currentServiceRequestInspectionType.status = 'Deleted'
      this.$emit('save-edit-service-request-inspection-type', this.currentServiceRequestInspectionType, this.selectedServiceRequestInspectionType.id)
    },
    onDelete: function () {
      this.isDeleteConfirmationActive = true
    },
    onSave: function () {
      this.currentServiceRequestInspectionType.inspectionTypeAttachments = this.inspectionTypeAttachments
      if (this.currentServiceRequestInspectionType.new === true) {
        this.currentServiceRequestInspectionType.new = false
        this.$emit('save-new-service-request-inspection-type', this.currentServiceRequestInspectionType, this.selectedServiceRequestInspectionType.id)
      } else {
        this.$emit('save-edit-service-request-inspection-type', this.currentServiceRequestInspectionType, this.selectedServiceRequestInspectionType.id)
      }
    },
    onCancel: function () {
      this.$emit('cancel-service-request-inspection-type', {})
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  mounted: function () {
    if (this.currentServiceRequestInspectionTypeId !== '0') {
      var that = this
      let found = this.forServiceRequest.inspectionTypes.find(function (x) {
        return x.id === that.currentServiceRequestInspectionTypeId
      })

      if (found) {
        this.currentServiceRequestInspectionType = Object.assign({}, found)
        this.selectedServiceRequestInspectionType = this.definedTypes.inspectionTypes.find((x) => x.id === this.currentServiceRequestInspectionType.inspectionType)
      }

      this.currentServiceRequestInspectionType.inspectionTypeAttachments.forEach((attach) => {
        if (attach.id) {
          var inspectionTypeAttachments = {
            id: attach.id,
            loaded: true,
            imageData: attach.fileLocation,
            fileName: attach.fileName,
            uniqueId: attach.fileName,
            fileType: this.getFileType(attach.fileName),
            fileLocation: attach.fileLocation,
            name: attach.name
          }
          this.inspectionTypeAttachments.push(inspectionTypeAttachments)
        } else {
          this.inspectionTypeAttachments.push(attach)
        }
      })
    }
  }
}
</script>

<style scoped>

</style>

export default {
  total: 3,
  results: [
    {
      id: 1,
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 1,
      dealerName: 'Fort Lauderdale',
      loanedById: 1,
      insurerId: 1,
      insurerName: 'FNB',
      name: 'Borrow',
      description: 'Borrowed for a week',
      status: 0,
      currentUsage: 50,
      damageDescription: '',
      tagNumber: '123',
      policyNumber: '123',
      agentName: 'Andre Moses',
      agentContatNo: '123',
      creditCardType: 'Visa',
      creditCardNo: '123',
      creditCardCvr: '321',
      creditCardExpiryDate: '10/2020',
      creditCardHolderName: 'Thomas Anderson',
      isDamaged: 0,
      address1: 'NO2 Long St.',
      address2: '',
      address3: '',
      address4: '',
      city: 'Cape Town',
      country: 'South Africa',
      postalCode: '8001',
      state: 'Western Cape',
      type: '',
      loanedByType: ''
    }
  ]
}

import mutationTypes from '../mutation-types.js'
import roleService from '../../services/roleService.js'

const state = {
  allRoles: []
}

const getters = {
  allRoles: state => {
    return state.allRoles
  }
}

const mutations = {
  [mutationTypes.SET_ROLES] (state, roles) {
    state.allRoles = roles
  },
  [mutationTypes.SYNC_ROLE] (state, entity) {
    var applicable = state.allRoles.find((x) => x.id === entity.id)
    if (applicable) {
      applicable = entity
    } else {
      state.allRoles.push(entity)
    }
  }
}

const actions = {
  setRoles ({commit, state}) {
    commit(mutationTypes.SET_ROLES, [])
    roleService.allRoles()
      .then((data) => {
        commit(mutationTypes.SET_ROLES, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  syncRole ({commit, state}, entity) {
    commit(mutationTypes.SYNC_ROLE, entity)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

import AddressCapture from '@/components/generic/AddressCapture'
import FileSelector from '@/components/generic/FileSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import SectionHeader from '@/components/generic/SectionHeader'

var mixin = {
  components: {
    'address-capture': AddressCapture,
    'file-selector': FileSelector,
    'error-display-component': ErrorDisplayComponent,
    'section-header': SectionHeader
  },
  data () {
    return {
      bankTabIndex: 0,
      addresses: [],
      lienPayoffFiles: [],
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    }
  },
  methods: {
    saveAddress: function (bankModel) {
      this.addresses.forEach(function (item) {
        var address = {
          id: isNaN(item.id) ? 0 : item.id,
          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
          address4: item.address4,
          city: item.city,
          country: item.country,
          postalCode: item.postalCode,
          state: item.state,
          gpsAddress: item.gpsAddress,
          addressType: item.addressType.altName
        }

        bankModel.addresses.push(address)
      }, this)
    },
    addAddress: function (configuration) {
      var config = {
        sectionId: this.addresses.length + 100,
        loadBankOnly: (configuration) ? configuration.loadBankOnly : false
      }

      var address = this.createGenericAddress()
      address.config = config
      this.addresses.push(address)
    },
    removeAddress () {
      var sectionId
      var offset = 100

      sectionId = this.addresses.length + offset - 1
      var addressFiltered = this.addresses.filter(function (el) {
        return el.config.sectionId !== sectionId
      })
      this.addresses = addressFiltered
    },
    createGenericAddress (configuration) {
      return {
        id: this.$uuid.v4(),
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: '',
        addressType: null
      }
    },
    getViewRoute: function () {
      return {name: 'ListBanks'}
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  }
}
export default mixin

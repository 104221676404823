<template>
  <section v-if="!this.isSquare">
    <button-list-selector
      label="Convert to another deposit type"
      v-model="selectedDepositType"
      :availableValues="availableDepositTypes"
      :allowDeselection="false">
    </button-list-selector>

    <payment-control-convert
      v-show="isDepositConverting"
      :name="'pc' + paymentTrackingId"
      :key="'pc' + paymentTrackingId"
      v-model="paymentTracking"
      :purchaser="purchaser"
      :isWholesaleProp="isWholesaleProp"
      :usePortal="true"
      :convertTo="convertTo"
      :isDepositConverting="isDepositConverting"
      :currentDealId="dealId"
      :defaultNote="defaultNote"
      :showActionButton="false"
      :noteRequired="false"
      v-on:on-save="onSavedPaymentControl"
      v-on:on-cancel="onCancelPaymentControl">
    </payment-control-convert>
  </section>
</template>
<script>

import ButtonListSelector from '@/components/generic/ButtonListSelector'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'PaymentConversionControl',
  components: {
    'button-list-selector': ButtonListSelector,
    'payment-control-convert': () => import('@/components/generic/PaymentControl')
  },
  props: {
    paymentTracking: {
      type: Object,
      default: null
    },
    isAddingPaymentActive: {
      type: Boolean,
      default: false
    },
    confirmProcessedInCdkActive: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedDepositType: null
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    availableDepositTypes: function () {
      let types
      types = this.definedTypes.paymentTrackingTypes.options
      types = this.isStandardDeposit ? types.filter(x => x.altName !== 'SquareTerminal') : types.filter(x => x.altName !== 'Square' && x.altName !== 'SquareTerminal')
      return types
    },
    purchaser: function () {
      if (!this.paymentTracking) return { id: 0 }
      return {
        id: this.paymentTracking.buyersId,
        displayName: this.paymentTracking.customerName,
        companyName: this.paymentTracking.customerCompanyName
      }
    },
    isWholesaleProp: function () {
      return this.paymentTracking && this.paymentTracking.buyersType === 'Supplier'
    },
    paymentTrackingId: function () {
      return this.paymentTracking ? this.paymentTracking.id : 0
    },
    convertTo: function () {
      if (this.selectedDepositType && this.currentDepositType && this.selectedDepositType.id !== this.currentDepositType.id) {
        return this.selectedDepositType.altName
      }
      return ''
    },
    isPaymentControlAvailable: function () {
      return this.selectedDepositType !== null
    },
    dealId: function () {
      return this.paymentTracking && this.paymentTracking.dealId ? this.paymentTracking.dealId : null
    },
    isStandardDeposit: function () {
      return this.paymentTracking && this.paymentTracking.category === 'Standard'
    },
    isWire: function () {
      return this.paymentTracking && this.paymentTracking.type === 'Wire'
    },
    isCheck: function () {
      return this.paymentTracking && this.paymentTracking.type === 'Check'
    },
    isCash: function () {
      return this.paymentTracking && this.paymentTracking.type === 'Cash'
    },
    isSquare: function () {
      return this.paymentTracking && (this.paymentTracking.type === 'Square' || this.paymentTracking.type === 'SquareTerminal')
    },
    isOther: function () {
      return this.paymentTracking && this.paymentTracking.type === 'Other'
    },
    defaultNote: function () {
      return this.paymentTracking && this.paymentTracking.note ? this.paymentTracking.note : ''
    },
    currentDepositType: function () {
      if (this.isWire) {
        return this.definedTypes.paymentTrackingTypes.options.find(x => x.altName === 'Wire')
      }
      if (this.isCheck) {
        return this.definedTypes.paymentTrackingTypes.options.find(x => x.altName === 'Check')
      }
      if (this.isCash) {
        return this.definedTypes.paymentTrackingTypes.options.find(x => x.altName === 'Cash')
      }
      if (this.isOther) {
        return this.definedTypes.paymentTrackingTypes.options.find(x => x.altName === 'Other')
      }
    },
    isDepositConverting: function () {
      if (this.currentDepositType && this.selectedDepositType) {
        return this.currentDepositType.altName !== this.selectedDepositType.altName
      }
      return this.selectedDepositType !== null
    }
  },
  methods: {
    onSavedPaymentControl: function () {
      this.$emit('update:isAddingPaymentActive', false)
      this.$emit('update:confirmProcessedInCdkActive', false)
    },
    setCurrentDepositType: function () {
      if (this.currentDepositType) {
        this.selectedDepositType = this.currentDepositType
      }
    },
    onCancelPaymentControl: function () {
      this.setCurrentDepositType()
    }
  },
  watch: {
    paymentTracking: function () {
    }
  },
  beforeMount: function () {
    this.setCurrentDepositType()
  },
  mounted () {
  },
  beforeDestroy () {
  }
}

</script>

<style scoped>

</style>

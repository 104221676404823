<template>
  <section class="large-bottom-margin" style="width: -webkit-fill-available;">
    <div class="box has-background-grey-lighter">
      <section-header
        title="Active Requests"
        :collapsed="true"
        :isHtmlOverview="true"
        :overview="overview">
      </section-header>
      <b-table class="lists" :data="checkRequests">
        <template slot-scope="props">
          <b-table-column field="payeeType" label="Payee Type" sortable>
            {{ getPayeeTypeDisplayName(props.row.payeeType) }}
          </b-table-column>
          <b-table-column field="type" label="Payment Type" sortable>
            {{ props.row.paymentType }}
          </b-table-column>
          <b-table-column field="buyerName" label="Payee" sortable>
            {{ props.row.buyerName }}
          </b-table-column>
          <b-table-column field="requestedBy" label="Requested By" sortable>
            {{ props.row.createdByFullName }}
          </b-table-column>
          <b-table-column field="amount" label="Amount" sortable>
            <span :class="props.row.isPaid ? 'has-text-primary' : 'has-text-danger'">
              {{ getAmount(props.row) }}
            </span>
          </b-table-column>
          <b-table-column field="paymentStatus" label="Pay Status" sortable>
            <span :class="props.row.isPaid ? 'has-text-primary' : 'has-text-danger'">
              {{ getPaymentStatusName(props.row) }}
            </span>
          </b-table-column>
          <b-table-column field="action" label="Action" sortable>
            <archive-scheduled-payment
              v-if="props.row.id > 0"
              :scheduledPaymentItem="props.row"
              :key="'asp' + props.row.id"
              :isListMode="false"
              v-on:on-sync-inventory-callback="onSyncInventoryCallBack">
            </archive-scheduled-payment>
          </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="has-text-centered">
              <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
              <p>No payment requests defined</p>
            </div>
          </section>
        </template>
      </b-table>
    </div>
  </section>
</template>

<script>
import utilitiesMixin from '@/mixins/generic/utilities'
import SectionHeader from '@/components/generic/SectionHeader'
import CapturePaymentRequest from '@/components/inventory/CapturePaymentRequest'
import ArchiveScheduledPaymentAction from '@/components/sales/payment-schedule/ArchiveScheduledPaymentAction'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'InventoryBatchPaymentListControl',
  mixins: [utilitiesMixin],
  components: {
    'section-header': SectionHeader,
    'capture-payment-request': CapturePaymentRequest,
    'archive-scheduled-payment': ArchiveScheduledPaymentAction
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      internalValue: this.value
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    hasBatchPaymentRequestAccess: function () {
      let allowedPermissions = ['inventory.issue.batch-payment-request']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    checkRequests: function () {
      return this.internalValue.allActiveCheckRequests
    },
    allPayeeTypes: function () {
      return this.definedTypes.costPayeeTypes
    },
    totalPaid: function () {
      let total = this.checkRequests.reduce(function (prev, cur) {
        return prev + (!cur.isPaid ? +0 : +cur.amountPaid)
      }, 0)
      return total
    },
    totalPaidFormatted: function () {
      return this.$options.filters.currency(this.totalPaid)
    },
    unallocated: function () {
      return this.internalValue.purchaseCost - this.totalPaid
    },
    unallocatedFormatted: function () {
      return this.$options.filters.currency(this.unallocated)
    },
    purchaseCostFormatted: function () {
      return this.$options.filters.currency(this.internalValue.purchaseCost)
    },
    paidRequests: function () {
      return this.checkRequests.filter(x => x.isPaid)
    },
    paidCount: function () {
      return this.paidRequests ? this.paidRequests.length : 0
    },
    pendingRequests: function () {
      return this.checkRequests.filter(x => !x.isPaid)
    },
    pendingCount: function () {
      return this.pendingRequests ? this.pendingRequests.length : 0
    },
    overview: function () {
      return '<div class="is-flex is-pulled-right">' +
        '<div class="tags has-addons"><span class="tag is-maroon">Paid</span><span class="tag is-primary">' + this.paidCount + '</span></div>' +
        '<div class="tags has-addons ml-5" style="height:24px !important"><span class="tag is-maroon">Pending</span><span class="tag is-primary">' + this.pendingCount + '</span></div>' +
        '</div>'
    }
  },
  methods: {
    totalPaymentRequests: function (item) {
      if (item && item.paymentRequests && item.paymentRequests.length > 0) {
        let paymentRequests = item.paymentRequests
        var sum = paymentRequests.reduce(function (a, b) {
          return a + b.amount
        }, 0)
        return sum
      }
      return 0
    },
    getPayeeTypeDisplayName: function (payeeType) {
      let type = this.allPayeeTypes.find(x => x.name === payeeType || x.altName === payeeType)
      return type ? type.name : ''
    },
    getAmount: function (checkRequest) {
      let amount = this.$options.filters.currency(checkRequest.amount)
      let amountPaid = this.$options.filters.currency(checkRequest.amountPaid)
      return checkRequest.isPaid ? amountPaid : amount
    },
    getPaymentStatusName: function (checkRequest) {
      if (checkRequest.paymentType === 'Accounting') {
        return checkRequest.isPaid ? 'Processed' : 'Unprocessed'
      }

      let selectedPaymentStatus = this.definedTypes.paymentStatus.options.find(x => x.altName === checkRequest.paymentStatus)
      return selectedPaymentStatus ? selectedPaymentStatus.name : ''
    },
    onSyncInventoryCallBack: function (rowId) {
      this.internalValue.allActiveCheckRequests.splice(this.internalValue.allActiveCheckRequests.findIndex(x => x.id === rowId), 1)
      this.value.allActiveCheckRequests = this.internalValue.allActiveCheckRequests
      this.$emit('input', this.value)
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      this.internalValue = newValue
    }
  },
  mounted: function () {
  }
}

</script>

<style scoped>

</style>

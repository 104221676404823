<template>
    <div class="field">
        <label class="label no-content-wrap">
          {{label}}<b-icon pack="fas" icon="star" class="fa-ss" v-if="required"></b-icon>
        </label>
        <div class="control has-icons-left">
            <money
            :name="instanceName"
            :data-vv-as="this.validateAs"
            v-validate="'decimal|required'"
            data-vv-delay="250"
            :class="{'input': true, 'is-danger': errors.has('instanceName') }"
            type="text"
            placeholder="0.00"
            v-model="moneyValue"
            v-bind="$globalMoneyFormat">
            </money>
                <span v-show="errors.has('instanceName')" class="help is-danger">
                  <i v-show="errors.has('instanceName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('instanceName') }}
                </span>
                <span class="icon is-small is-left">
                  <i class="fas fa-dollar-sign"></i>
                </span>
        </div>
    </div>
</template>

<script>
export default {
  inject: {
    $validator: '$validator'
  },
  name: 'MoneyInputControl',
  props: {
    value: {
      type: Number,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    validateAs: {
      type: String,
      default: 'Item'
    },
    required: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      moneyValue: this.value,
      instanceName: this.$uuid.v4()
    }
  },
  computed: {
  },
  watch: {
    value: function (newValue, oldValue) {
      this.moneyValue = this.value
    },
    moneyValue: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$emit('input', newValue)
      }
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>

import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/insurers',
    name: 'ListInsurers',
    component: () => import('@/components/configuration/insurers/ListInsurers'),
    beforeEnter: requiresAuth
  },
  {
    name: 'InsurersQuickView',
    path: '/configuration/insurers/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/insurers/ViewInsurer')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/insurer/view/:id',
    name: 'ViewInsurer',
    component: () => import('@/components/configuration/insurers/ViewInsurer'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/insurer/NewInsurer',
    name: 'NewInsurer',
    component: () => import('@/components/configuration/insurers/NewInsurer'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/insurer/edit/:id',
    name: 'EditInsurer',
    component: () => import('@/components/configuration/insurers/EditInsurer'),
    beforeEnter: requiresAuth
  }
]

<template>
  <i v-if="!isAddTradeActive"
    data-type="+"
    style="margin-top: 10px;"
    class="grid-icon fa-fw fal fa-lg clickable has-text-grey-light fa-car-alt"
    v-tooltip="{content:'Click to add trade in.'}"
    @click="onOpenModal()"
  />

  <portal to="global-modal-portal" v-else :disabled="!usePortal">
    <b-modal :active.sync="isAddTradeActive" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Add Trade In</p>
        </header>
        <section class="modal-card-body trade-inventory">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <form class="view-container" @change="onFormChange" @submit.prevent="validateBeforeSubmit" v-if="!hasTradeInventory">
            <lease-renewal-capture
              v-for="(trade, index) in trades"
              :key="index"
              :showPrice="true"
              :showStockNoLabel="true"
              :selectedDealer="selectedDealer"
              v-model="trades[index]"
              :soldStockNo="firstSoldItemStockNo"
              :defaultSalesPerson="salesAgent"
              :defaultSellerType="sellerType"
              :defaultSeller="seller"
              :isTitleStatusRequiredOveride="false">
            </lease-renewal-capture>
          </form>
        </section>
        <footer class="modal-card-foot">
            <button class="button is-success" :disabled="isUploadingFile" :class="{'is-loading': isSaving }" v-if="!hasTradeInventory" @click="validateBeforeSubmit">Save</button>
            <button class="button is-danger" @click="onCloseModal()">Cancel</button>
        </footer>
        </div>
    </b-modal>
  </portal>
</template>
<script>

import LeaseRenewalCapture from '@/components/generic/sales/LeaseRenewalCapture'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import dealService from '@/services/dealService'
import eventBus from '@/eventBus'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'TradeDealAction',
  mixins: [validateBeforeSubmitMixin],
  props: {
    deal: {
      type: Object,
      default: null
    },
    selectedDealer: {
      type: Object,
      default: null
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isAddTradeActive: false,
      trades: [],
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      isUploadingFile: false
    }
  },
  components: {
    'lease-renewal-capture': LeaseRenewalCapture,
    'error-display-component': ErrorDisplayComponent
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    soldItems: function () {
      return (this.deal && this.deal.items) ? this.deal.items.filter((x) => x.dealItemType === 'Sold') : []
    },
    firstSoldItemStockNo: function () {
      return this.soldItems.length > 0 ? this.soldItems[0].forInventory.stockNo : ''
    },
    salesAgent: function () {
      return (this.deal.salesPersonId) ? { id: this.deal.salesPersonId, fullName: this.deal.salesPersonName, dealerId: this.deal.dealerId } : null
    },
    seller: function () {
      if (this.isSaleTypeOfRetail) {
        return this.customer
      }
      if (this.isSaleTypeOfWholesale) {
        return this.supplier
      }
      return null
    },
    sellerType: function () {
      if (this.isSaleTypeOfRetail) {
        return this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Individual)
      }
      if (this.isSaleTypeOfWholesale) {
        return this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Wholesaler)
      }
      return null
    },
    isSaleTypeOfRetail: function () {
      return this.deal !== null && this.deal.saleType === 'Retail'
    },
    isSaleTypeOfWholesale: function () {
      return this.deal && this.deal.saleType === 'Wholesale'
    },
    customer: function () {
      return this.deal && this.deal.customer && this.deal.customer.id !== 0 ? this.deal.customer : null
    },
    supplier: function () {
      return this.deal && this.deal.supplierId !== 0 && this.deal.supplierId !== null ? {id: this.deal.supplierId, name: this.deal.supplierName} : null
    },
    hasTradeInventory: function () {
      return this.tradeDealItems.length > 0
    },
    tradeDealItems: function () {
      return (this.deal && this.deal.items) ? this.deal.items.filter((x) => x.dealItemType === 'Trade') : []
    },
    dealId: function () {
      return (this.deal) ? this.deal.id : 0
    },
    tradeItem: function () {
      return this.trades && this.trades.length > 0 ? this.trades[0] : null
    }
  },
  methods: {
    createGenericDealItem () {
      return {
        id: 0,
        vehicleType: null,
        salePrice: null,
        frontValue: null,
        holdValue: null,
        mvpValue: null,
        warValue: null,
        finResValue: null,
        tradeNo: '',
        itemProducts: [],
        forInventory: null,
        lineComment: '',
        status: 'Active',
        dealItemType: 'Trade',
        lineAttachments: [],
        isPrimary: true,
        dealId: this.dealId
      }
    },
    AddDealItem: function () {
      var dealItem = this.createGenericDealItem()
      this.trades.push(dealItem)
    },
    onOpenModal: function () {
      if (this.hasTradeInventory) {
        this.serverErrorMessage = 'This deal already has a linked trade in.'
      } else {
        this.AddDealItem()
      }
      this.isAddTradeActive = true
    },
    onCloseModal: function () {
      this.isAddTradeActive = false
      this.trades = []
    },
    onSave: function () {
      if (this.tradeItem) {
        this.serverErrorMessage = ''
        this.serverErrors = []
        this.isSaving = true
        this.isLoading = true
        dealService.addTradeToDeal(this.tradeItem).then(response => {
          this.isSaving = false
          this.isLoading = false
          if (response.errors) {
            this.serverErrorMessage = response.message
            this.serverErrors = response.errors
          } else {
            eventBus.$emit('reload-deal')
            eventBus.$emit('reload-deal-list')
            this.onCloseModal()
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
          this.isLoading = false
        })
      } else {
        this.serverErrorMessage = 'A trade in must be selected and completed.'
      }
    },
    fileUploadsComplete: function () {
      this.isUploadingFile = false
    },
    fileUploadsStarted: function () {
      this.isUploadingFile = true
    }
  },
  watch: {

  },
  beforeMount () {
    eventBus.$on('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$on('file-uploads-started', this.fileUploadsStarted)
  },
  beforeDestroy () {
    this.$validator.pause()
    eventBus.$off('file-uploads-completed', this.fileUploadsComplete)
    eventBus.$off('file-uploads-started', this.fileUploadsStarted)
  }
}

</script>

<style scoped>
  .trade-inventory {
    overflow:auto;
    height: calc(100vh - 500px);
    width: 750px;
  }
</style>

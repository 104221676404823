export default {
  allInsurers: [
    {
      name: 'Insurer1',
      dealerId: 1,
      status: 'Active',
      id: 1
    },
    {
      name: 'Insurer2',
      dealerId: 2,
      status: 'Active',
      id: 2
    },
    {
      name: 'Insurer3',
      dealerId: 3,
      status: 'Active',
      id: 3
    }
  ]
}

import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/suppliers',
    name: 'ListSuppliers',
    component: () => import('@/components/configuration/suppliers/ListSuppliers'),
    beforeEnter: requiresAuth
  },
  {
    name: 'SuppliersQuickView',
    path: '/configuration/suppliers/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/suppliers/ViewSupplier')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/suppliers/new',
    name: 'NewSupplier',
    component: () => import('@/components/configuration/suppliers/NewSupplier'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/suppliers/view/:id',
    name: 'ViewSupplier',
    component: () => import('@/components/configuration/suppliers/ViewSupplier'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/suppliers/edit/:id',
    name: 'EditSupplier',
    component: () => import('@/components/configuration/suppliers/EditSupplier'),
    beforeEnter: requiresAuth
  }
]

<template>
  <div class="pod tile is-parent is-narrow view-container">
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold">Client - {{callLog.Name}} ({{callLog.CallID}})
      </h4>
      <div class="pod-content" style="margin:3px; overflow-x:hidden">
        <div class="columns">
          <div class="column is-6">
            <div class="field">
              <label class="label">Name</label>
              <div class="control">
                {{callLog.Name}}
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label">Status</label>
              <div class="control">
                {{callLog.Status}}
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="field">
              <label class="label">Started</label>
              <div class="control">
                {{callLog.Start | formatDate}}
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label">Duration</label>
              <div class="control">
                {{callLog.Duration}} mins
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="field">
              <label class="label">Source</label>
              <div class="control">
                {{callLog.Source}}
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label">Destination</label>
              <div class="control">
                {{callLog.Destination}}
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="field">
              <label class="label">Direction</label>
              <div class="control">
                {{callLog.Direction}}
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label">Campaign</label>
              <div class="control">
                {{callLog.Campaign}}
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <div class="field">
              <label class="label">Recording</label>
              <div class="control">
                {{callLog.RecordingURL}}
              </div>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-12">
            <div class="field">
              <label class="label">Notes</label>
              <div class="control">
                {{callLog.Notes}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </article>
  </div>
</template>
<script>

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'CallPod',
  props: {
    callLog: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      title: 'Call Pod'
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'currentUser', 'currentPendingActions', 'currentNotifications']),
    ...mapDealerState(['currentDealer'])
  },
  methods: {
    onPodRefreshRequired: function () {
    },
    onItemClick: function (item) {
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
    }
  },
  mounted: function () {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

import axios from 'axios'
import baseService from './baseService'
import profileData from './data/profile'
import userData from './data/user'

const userService = {
  allUsers (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData.all)
      } else {
        axios
          .get('/user/selectionlist?', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  /* checkLogin () {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(profileData)
      } else {
        axios.post('/auth/weblogincheck', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }, */
  loadCurrentUser () {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'users/me').then(response => {
        resolve(response.data)
      }).catch(error => {
        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
          reject(error)
        } else if (error.request) {
          // The request was made but no response was received
          // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
          // http.ClientRequest in node.js
          console.log(error.request)
          reject(new Error('Unable to reach authentication services. Please check network connection and try again.'))
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
          reject(error)
        }
      })
    })
  },
  verifyPin (pin) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(profileData)
      } else {
        axios.post('/user/verifypin', {pin: pin}, baseService.defaultConfig).then(response => {
          resolve(response.data)
        }).catch(error => {
          reject(error)
        })
      }
    })
  },
  /* showAuthentication () {
    if (window.location.href === process.env.VUE_APP_ROOT_SITE) {
      window.location.href = process.env.VUE_APP_ROOT_AUTH_API + '/auth/login?redir=' + window.location.href + 'index.html'
    } else {
      window.location.href = process.env.VUE_APP_ROOT_AUTH_API + '/auth/login?redir=' + window.location.href
    }
  },
  showLogout () {
    window.location.href = process.env.VUE_APP_ROOT_AUTH_API + '/auth/logout?redir=' + process.env.VUE_APP_ROOT_SITE + 'index.html'
  }, */
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData)
      } else {
        axios
          .post('/user/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (userId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData.all.find((x) => x.id === userId))
      } else {
        axios
          .post('/user/get?id=' + userId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (userModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var user = {
          id: 999,
          firstName: userModel.firstName,
          middleName: userModel.middleName,
          lastName: userModel.lastName,
          email: userModel.email,
          status: 'Requested',
          mobile: userModel.mobile
        }
        userData.all.push(user)
        resolve(user)
      } else {
        axios
          .post('/user/save', userModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (userModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var user = userData.all.find((x) => x.id === userModel.id)
        if (user) {
          user.firstName = userModel.firstName
          user.middleName = userModel.middleName
          user.lastName = userModel.lastName
          user.email = userModel.email
          user.mobile = userModel.mobile
        }
        resolve(user)
      } else {
        axios
          .post('/user/update', userModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (userModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        userModels.forEach(element => {
          userData.all.remove((x) => x.id === element.id)
        })
        resolve(userData.all)
      } else {
        var ids = []
        userModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/user/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllByDealerId (dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData)
      } else {
        axios
          .get('/user/findAllByDealerId?dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  saveUserSettings (updateSettingModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData)
      } else {
        axios
          .post('/user/saveUserSetting', updateSettingModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getEmailDomain () {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData.emailDomain)
      } else {
        axios
          .post('/user/getemaildomain', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  uniquePin (pin, userId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve({result: false})
      } else {
        axios
          .post('/user/UniquePin', {pin: pin, userId: userId}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  changeUsersAvailabilityStatus (changeRequest) {
    return new Promise((resolve, reject) => {
      axios.post(process.env.VUE_APP_ROOT_AUTH_API + 'users/' + changeRequest.userId + '/updateAvailability', {
        userId: changeRequest.userId,
        isAvailable: changeRequest.isAvailable,
        reason: changeRequest.reason
      }).then(response => {
        resolve(response.data)
      }).catch(error => {
        reject(error.response.data)
      })
    })
  },
  getAllUserLoginHistoryById (userId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData)
      } else {
        axios
          .post('/user/getAllUserLoginHistory?id=' + userId, {...baseService.defaultConfig, requestId: 'user-login-history'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('all user login history load cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  findDealerUsersByRole (dealerId, roleId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData)
      } else {
        axios
          .get('/user/findDealerUsersByRole?dealerId=' + dealerId + '&roleId=' + roleId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  requestLogin (userId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData)
      } else {
        axios
          .post('/user/userRequestLogout?userId=' + userId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  loadDealerValetVehicleWashingUsers (dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(userData)
      } else {
        axios
          .get('/user/loadDealerValetVehicleWashingUsers?dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default userService

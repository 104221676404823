<template>
  <div class="is-inline-block">
    <div class="quick-action" v-if="!listMode" >
      <button type="button" class="button clickable" :class="{'is-loading': this.dealPackStickerPreviewDownloading}" @click="onPreviewDealPackStickerPdfDocument(currentDeal)" title="Click to view/download deal pack sticker">
        <b-icon icon="fal fa-book" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <span :class="{'clickable': true, 'icon fa-spin': this.dealPackStickerPreviewDownloading}" @click="onPreviewDealPackStickerPdfDocument(currentDeal)" title="Click to view/download deal pack sticker" v-else>
      <b-icon icon="fal fa-book" custom-size="fa-lg"></b-icon>
    </span>
    <pdf-modal
      :pdfFile="dealPackStickerSelectedPdf"
      :usePortal="true"
      :portalOrder="2"
      v-on:on-pdf-downloaded="onDealPackStickerPdfPreviewed"
      v-on:on-pdf-closed="onDealPackStickerPdfPreviewClosed"
      v-if="isDisplayPdfFileValid">
    </pdf-modal>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'ViewDealPackStickerAction',
  components: {
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    listMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      dealPackStickerPreviewDownloading: false,
      dealPackStickerSelectedPdf: null,
      instanceId: this.$uuid.v4()
    }
  },
  methods: {
    onDealPackStickerPdfPreviewed: function () {
    },
    onDealPackStickerPdfPreviewClosed: function () {
      this.dealPackStickerSelectedPdf = null
      this.currentReceiptDocumentUrl = ''
      this.showSquareTerminalControl = false
    },
    onPreviewDealPackStickerPdfDocument: function (deal) {
      let fileName = 'deal_pack_sticker_' + deal.id + '.pdf'
      let uri = process.env.VUE_APP_ROOT_DOC_SITE + 'docs/deals/packsticker/' + deal.id
      this.dealPackStickerSelectedPdf = {
        name: 'Deal Pack Sticker',
        src: uri,
        fileName: fileName,
        key: this.$uuid.v4()
      }
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    isDisplayPdfFileValid: function () {
      return this.dealPackStickerSelectedPdf && this.dealPackStickerSelectedPdf.src
    }
  },
  mounted: function () {
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .modal-card-body {
    height: 100% !important;
    min-width:650px;
  }

  @media screen and (max-width: 500px) {
    .modal-card-body {
      position:relative;
      width: 100vw;
      min-width:unset;
      overflow-y: auto;
      background: white;
    }
  }

  .status-container {
    margin-bottom: 3em;
  }
</style>

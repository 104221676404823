import requiresAuth from './route_guards'

export default [
  {
    path: '/sales/deals',
    name: 'ListDeals',
    component: () => import('@/components/sales/deals/ListDeals'),
    beforeEnter: requiresAuth
  },
  {
    name: 'DealsQuickView',
    path: '/sales/deals/qview/:id',
    meta: {
      quickViewComponent: {
        component: () => import('@/components/sales/deals/ViewDeal'),
        qvHeaderClass: 'deal-qv-header',
        qvBodyClass: 'deal-qv-body',
        qvFooterClass: ''

      }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/sales/deals/new',
    name: 'NewDeal',
    component: () => import('@/components/sales/deals/NewDeal'),
    beforeEnter: requiresAuth,
    props: true
  },
  {
    path: '/sales/deals/view/:id',
    name: 'ViewDeal',
    component: () => import('@/components/sales/deals/ViewDeal'),
    props: {
      actionPortal: '',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/sales/deals/edit/:id',
    name: 'EditDeal',
    component: () => import('@/components/sales/deals/EditDeal'),
    beforeEnter: requiresAuth
  },
  {
    path: '/sales/delivery-schedule',
    name: 'ListDeliverySchedule',
    component: () => import('@/components/sales/delivery-schedule/ListDeliverySchedule'),
    beforeEnter: requiresAuth
  },
  {
    path: '/sales/payment-schedule',
    name: 'ListPaymentSchedule',
    component: () => import('@/components/sales/payment-schedule/ListPaymentSchedule'),
    beforeEnter: requiresAuth
  },
  {
    path: '/sales/payment-schedule/qview/:id',
    name: 'PaymentScheduleQuickView',
    meta: {
      quickViewComponent: {
        component: () => import('@/components/sales/payment-schedule/ViewPaymentSchedule'),
        qvHeaderClass: 'deposit-qv-header',
        qvBodyClass: 'deposit-qv-body'
      }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/sales/heat-sheet',
    name: 'ListHeatSheet',
    component: () => import('@/components/sales/heat-sheet/ListHeatSheet'),
    beforeEnter: requiresAuth
  },
  {
    path: '/sales/customer-deposits',
    name: 'ListPaymentTracking',
    component: () => import('@/components/accounting/ListPaymentTracking'),
    beforeEnter: requiresAuth
  },
  {
    name: 'PaymentTrackingQuickView',
    path: '/sales/customer-deposits/qview/:id',
    meta: {
      quickViewComponent: {
        component: () => import('@/components/accounting/ViewPaymentTracking'),
        qvHeaderClass: 'deposit-qv-header',
        qvBodyClass: 'deposit-qv-body'
      }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/sales/customer-deposits/view/:id',
    name: 'ViewPaymentTracking',
    component: () => import('@/components/accounting/ViewPaymentTracking'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  }
]

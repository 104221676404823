<template>
  <section class="inventory-sales-description-container">
    <div :id="'inventory-sales-description-container-' + instanceId" @click="onShowSalesDescription" v-if="currentInventory && currentInventory.saleDescription">
      <i class="icon fal fa-eye fa-md inline-block clickable" :title="cleanSalesDescription"/>
    </div>

    <portal to="global-modal-portal" :disabled="!usePortal" v-if="isShowingSalesDescription">
      <b-modal :active.sync="isShowingSalesDescription" :width="640" scroll="keep" :has-modal-card="true" v-if="isShowingSalesDescription">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Sales Description</p>
          </header>
          <section class="modal-card-body" v-html="currentInventory.saleDescription">
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="onCloseSalesDescription()" type="button">Close</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </section>
</template>

<script>

export default {
  name: 'InventorySalesDescriptionComponent',
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isShowingSalesDescription: false
    }
  },
  methods: {
    onShowSalesDescription: function () {
      this.isShowingSalesDescription = true
    },
    onCloseSalesDescription: function () {
      this.isShowingSalesDescription = false
    }
  },
  computed: {
    cleanSalesDescription: function () {
      return this.currentInventory.saleDescription.replace(/<br>/g, '\r\n')
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal.is-active .modal-card-foot {
    padding: 10px !important
  }

  .modal-card-head, .modal-card-body{
    padding: 10px !important
  }

  .inventory-sales-description-container {
    display:inline-flex;
    vertical-align: middle;
  }
</style>

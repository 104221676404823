export default {
  all: [
    {
      id: 1,
      status: 'Active',
      subscriberId: 1,
      roNumber: '654123',
      cashierNumber: '6912543',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      closeDate: '01/01/2000',
      openDate: '01/01/2000',
      comeBackFlag: false,
      serviceAdvisorId: 1,
      estimatedCompletionDateTime: '01/01/2000',
      hasCustomerPay: false,
      bookedDateTime: '01/01/2000',
      lastServiceDate: '01/01/2000',
      originalWaiterFlag: false,
      waiterFlaig: false,
      roServiceDealer: {
        id: 1,
        subscriberId: 1,
        dealerCode: 'FFL-S',
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1
      },
      roServiceAccountingAccount: {
        id: 1,
        subscriberId: 1,
        accountingCode: 'FFL-S',
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1
      },
      roServiceVehicle: {
        id: 1,
        roServiceId: 1,
        mileage: 5269.23,
        vehicleId: 'H1228991',
        vin: 'ZAM56RRA0H1228991',
        color: 'White',
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1,
        make: 'Volkswagen',
        model: 'Polo',
        productionYear: 2021,
        mileageLastVisit: 1000.00,
        mileageOut: 5300.00
      },
      roServiceCustomer: {
        id: 1,
        subscriberId: 1,
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1,
        roServiceId: 1,
        customerNo: '64345',
        address: '145 ROUTE 17 SOUTH',
        cityStateZip: 'UPPER SADDLE RIVER, NJ 07458',
        contactEmailAddress: 'test@hostedapp.co.za',
        contactPhoneNumber: '123456789',
        name1: 'Test User',
        name2: 'Test Organization'
      },
      roServiceReportDate: {
        id: 1,
        status: 'Active',
        createdAt: '01/01/2000',
        modifiedAt: '01/01/2000',
        modifiedBy: 1,
        createdBy: 1,
        roServiceId: 1,
        actualHours: 2.5,
        actualHoursCustomerPay: 2.5,
        actualHoursCustomerInternal: 2.5,
        actualHoursCustomerWarranty: 2.5,
        laborCost: 2.5,
        laborCostCustomerPay: 2.5,
        laborCostInternal: 2.5,
        laborCostWarranty: 2.5,
        laborSale: 2.5,
        laborSaleCustomerPay: 2.5,
        laborSaleInternal: 2.5,
        laborSaleWarranty: 2.5,
        miscellaneousCost: 2.5,
        miscellaneousCostCustomerPay: 2.5,
        miscellaneousCostInternal: 2.5,
        miscellaneousCostWarranty: 2.5,
        miscellaneousSale: 2.5,
        miscellaneousSaleCustomerPay: 2.5,
        miscellaneousSaleInternal: 2.5,
        miscellaneousSaleWarranty: 2.5,
        partsCost: 2.5,
        partsCostCustomerPay: 2.5,
        partsCostInternal: 2.5,
        partsCostWarranty: 2.5,
        partsSale: 2.5,
        partsSaleCustomerPay: 2.5,
        partsSaleInternal: 2.5,
        partsSaleWarranty: 2.5,
        soldHours: 2.5,
        soldHoursCustomerPay: 2.5,
        soldHoursInternal: 2.5,
        soldHoursWarranty: 2.5,
        laborSalePostded: 2.5,
        laborSalePostdedCustomer: 2.5,
        laborSalePostdedInternal: 2.5,
        laborSalePostdedWarranty: 2.5
      }
    }
  ]
}

export default {
  all: [
    {
      id: 1,
      status: 'Active',
      payerId: 1,
      payerType: 'Customer',
      type: 'RoWorkOrder',
      signatureRequired: true,
      paymentRequired: true,
      amount: 12.36,
      token: '',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      modifiedBy: 1,
      url: '',
      attachments: [{
        fileLocation: 'http://127.0.0.1:10000/devstoreaccount1/subscriber-1/scheduled-payment/wm_check_request_163163_158158_291780_280820210224.pdf',
        classType: 'Sign'
      }]
    }
  ]
}

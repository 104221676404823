// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import App from './App'
import router from './router'
import store from './store'
import Buefy from 'buefy'
import moment from 'moment-timezone'
import VeeValidate from 'vee-validate'
import vSelect from 'vue-select'
import lodash from 'lodash'
import 'bulma-extensions/bulma-quickview/dist/bulma-quickview.min.js'
import Multiselect from 'vue-multiselect'
import UUID from 'vue-uuid'
import Viewer from 'v-viewer'
import PortalVue from 'portal-vue'
import vOutsideEvents from 'vue-outside-events'
import globalInit from './global_extensions'
import PdfViewer from '@/components/external/vpdf/VeePdfViewer'
import Vue2Filters from 'vue2-filters'
import "echarts";
import VChart from "vue-echarts";
import { DatePicker, ColorPicker, Select, Option, TimeSelect, OptionGroup, Input } from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
import './css/font-awesome/css/all.css'
import 'nprogress/nprogress.css'
import 'buefy/dist/buefy.css'
import './css/main.scss'
import './css/element-variables.scss'
import './css/vue-multiselect.css'
import { VueMaskDirective } from 'v-mask'
import VueClipboard from 'vue-clipboard2'
import money from '@/components/external/vmoney'
import 'intersection-observer'
import VueObserveVisibility from 'vue-observe-visibility'
import VTooltip from 'v-tooltip'
import vueSignature from 'vue-signature'
import 'fix-date'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css'
import 'animate.css/animate.min.css';
import 'viewerjs/dist/viewer.css';
import VueVirtualScroller from 'vue-virtual-scroller'
import VueResize from 'vue-resize'
import defaultAppConfig from '@/config/index'
import drag from 'v-drag'
import 'pdfjs-dist/web/pdf_viewer.css'
import { pdfjs } from 'pdfjs-dist/build/pdf'
var { pdfjsLib } = window.globalThis
pdfjsLib.GlobalWorkerOptions.workerPort = new Worker(new URL('pdfjs-dist/build/pdf.worker.mjs', import.meta.url))
window.pdfjsLib = pdfjsLib

Object.defineProperty(Vue.prototype, '$lodash', { value: lodash })
Object.defineProperty(Vue.prototype, '$pdf', { value: window.pdfjsLib })

// configure language
locale.use(lang)
Vue.use(VueClipboard)
Vue.use(VTooltip)
Vue.use(UUID)
Vue.use(VeeValidate)
Vue.use(VueVirtualScroller)
Vue.use(VueResize)
Vue.use(Buefy, {
  defaultIconPack: 'fal',
  customIconPacks: {
    fal: {
      sizes: {
        default: 'lg',
        'is-small': '',
        'is-medium': '2x',
        'is-large': '3x'
      },
      iconPrefix: 'fa-'
    }
  }
})
Vue.use(Viewer, {
  defaultOptions: {
    zIndex: 9999
  }
})
Vue.use(PortalVue)
Vue.use(vOutsideEvents)
Vue.use(Vue2Filters)
Vue.use(Input)
Vue.use(DatePicker)
Vue.use(TimeSelect)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(ColorPicker)
Vue.use(VueObserveVisibility)
Vue.use(vueSignature)
Vue.component('v-chart', VChart)
Vue.component('v-select', vSelect)
Vue.component('multiselect', Multiselect)
Vue.component('pdf', PdfViewer)

Vue.use(money, {
  precision: 2
})
Vue.use(drag, {
  // options here
})
Vue.filter('formatDate', function (value) {
  if (value) {
    if (moment.defaultZone && moment.defaultZone.name) {
      return moment.utc(String(value)).tz(moment.defaultZone.name).format('MM/DD/YYYY HH:mm')
    } else {
      return moment.utc(String(value)).format('MM/DD/YYYY HH:mm')
    }
  }
})
Vue.filter('formatDateOnly', function (value) {
  if (value) {
    if (moment.defaultZone && moment.defaultZone.name) {
      return moment.utc(String(value)).tz(moment.defaultZone.name).format('MM/DD/YYYY')
    } else {
      return moment.utc(String(value)).format('MM/DD/YYYY')
    }
  }
})
Vue.filter('formatDateIgnoreZone', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
})
Vue.filter('formatDateTimeIgnoreZone', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY HH:mm:ss')
  }
})
Vue.filter('formatHumanDate', function (value) {
  if (value) {
    if (moment.defaultZone && moment.defaultZone.name) {
      return moment.utc(String(value)).tz(moment.defaultZone.name).fromNow()
    } else {
      return moment.utc(String(value)).fromNow()
    }
  }
})
Vue.filter('formatDateNonMilitary', function (value) {
  if (value) {
    if (moment.defaultZone && moment.defaultZone.name) {
      return moment.utc(String(value)).tz(moment.defaultZone.name).format('MM/DD/YYYY h:mm a')
    } else {
      return moment.utc(String(value)).format('MM/DD/YYYY h:mm a')
    }
  }
})
Vue.filter('formatDateOnlyShort', function (value) {
  if (value) {
    if (moment.defaultZone && moment.defaultZone.name) {
      return moment.utc(String(value)).tz(moment.defaultZone.name).format('MM/DD/YY')
    } else {
      return moment.utc(String(value)).format('MM/DD/YY')
    }
  }
})
Vue.filter('formatDateMonthYearOnly', function (value) {
  if (value) {
    if (moment.defaultZone && moment.defaultZone.name) {
      return moment.utc(String(value)).tz(moment.defaultZone.name).format('MMM YYYY')
    } else {
      return moment.utc(String(value)).format('MMM YYYY')
    }
  }
})
Vue.filter('formatYearMonthDateHourMinute', function (value) {
  if (value) {
    if (moment.defaultZone && moment.defaultZone.name) {
      return moment.utc(String(value)).tz(moment.defaultZone.name).format('YYYYMMDDHHmm')
    } else {
      return moment.utc(String(value)).format('YYYYMMDDHHmm')
    }
  }
})
var truncateFilter = function (text, length, clamp) {
  clamp = clamp || '...'
  var node = document.createElement('div')
  node.innerHTML = text
  var content = node.textContent
  return content.length > length ? content.slice(0, length) + clamp : content
}
Vue.filter('yesno', function (value) {
  return value ? 'Yes' : 'No'
})

Vue.filter('truncate', truncateFilter)

Vue.filter('currencyFormatNegative', function (value) {
  if (typeof value !== 'number') {
    return value
  }
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD'
  })
  if (value < 0) {
    let result = '-(' + formatter.format(Math.abs(value)) + ')'
    return result
  }
  return formatter.format(value)
})

window.onerror = function (message, source, lineno, colno, error) {
  console.log('An error occurred:', message, 'at line:', lineno, 'in file:', source)
  return true
}

window.addEventListener('unhandledrejection', function (event) {
  console.log('Unhandled rejection (promise: ', event.promise, ', reason: ', event.reason, ').')
  return true
})

Vue.directive('mask', VueMaskDirective)
Vue.config.productionTip = false
console.log('Setting performance metrics based on setting ' + process.env.NODE_ENV)
Vue.config.performance = process.env.NODE_ENV !== 'production'
globalInit(Vue)
/* eslint-disable no-new */
new Vue({
  el: '#app',
  store,
  router,
  components: { App },
  template: '<App/>',
  created: function () {
    VeeValidate.Validator.extend('verify_vin', {
      // Custom validation message
      getMessage: (field) => `The ${field} is not a valid. Vin cannot contain O, I, Q characters`,
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        resolve({
          valid: value && value.indexOf('LOC') === -1 && value.indexOf('I') === -1 && value.indexOf('O') === -1 && value.indexOf('Q') === -1
        })
      })
    })
    VeeValidate.Validator.extend('valid_payoff_expiry', {
      // Custom validation message
      getMessage: `Payoff values are expired or close to expiry`,
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        let currentDate = moment().format('YYYY/MM/DD')
        let goodUntilDate = moment(value).subtract(2, 'days').format('YYYY/MM/DD')
        let isValid = moment(goodUntilDate).isAfter(currentDate)
        resolve({
          valid: isValid
        })
      })
    })
  },
  mounted: function () {
    if (window.Sentry && process.env.NODE_ENV !== 'development') {
      let sentryConfig = {
        dsn: 'https://6d62f4a26438484dbbc50add372c15b2@o246961.ingest.sentry.io/1427401',
        environment: process.env.NODE_ENV,
        release: 'live@' + defaultAppConfig.appVersion,
        integrations: [],
        tracesSampleRate: 1.0
      }
      window.Sentry.init(sentryConfig)
      console.log('Sentry initialized with environment', sentryConfig)
    }
  }
})

<template>
  <div v-if="hasClosePpaAccess && isTypePpaAppraisal">
    <div class="quick-action" v-if="!isClosePpaConfirmationActive">
        <button class="button" @click="onRequestClosePpa()" title="Click to reject PPA">
            <b-icon pack="fal" icon="times-circle" custom-size="fa-lg"></b-icon>
        </button>
    </div>
    <portal to="global-modal-portal" v-if="isClosePpaConfirmationActive" :disabled="!usePortal">
        <b-modal :active.sync="isClosePpaConfirmationActive" scroll="keep" :has-modal-card="true" :canCancel="false">
          <div class="modal-card">
            <header class="modal-card-head">
              <div class="columns" style="width: 100%">
                <div class="column is-8 is-pulled-left">
                  <span class="modal-card-title">{{modalTitle}}</span>
                </div>
                <div class="column is-4">
                  <a class="tag is-primary is-pulled-right" @click.stop.prevent="onStockNoClick(inventoryItem)">
                    <span class="has-text-weight-semibold">
                      {{ inventoryItem.stockNo }}
                    </span>
                  </a>
                </div>
              </div>
            </header>
            <section class="modal-card-body">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
              <div class="columns is-mobile is-multiline">
                <div class="column is-12">
                  <div class="notification is-flex is-warning">
                    <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
                    <span class="ml-10">
                      {{modalContent}}
                    </span>
                  </div>
                </div>
                <div class="column is-12">
                  <note-capture v-model="notes" label="Reject Reason"></note-capture>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-warning" :class="{'is-loading': isSaving }"  @click="onClosePpa()" v-if="!closedSuccessfully">Confirm</button>
                <button class="button is-danger" @click="onCancel()">{{closedSuccessfully ? "Close" : "Cancel"}}</button>
            </footer>
          </div>
        </b-modal>
    </portal>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import inventoryService from '../../services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import { createNamespacedHelpers } from 'vuex'
import utilitiesMixin from '@/mixins/generic/utilities'
import NoteCapture from '@/components/generic/NoteCapture'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'ClosePpaAction',
  mixins: [utilitiesMixin],
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    usePortal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isClosePpaConfirmationActive: false,
      closedSuccessfully: false,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4(),
      closedPpaItem: null,
      notes: []
    }
  },
  components: {
    'error-display-component': ErrorDisplayComponent,
    'note-capture': NoteCapture
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    hasClosePpaAccess: function () {
      let allowedPermissions = ['inventory.modify.mark-ppaclosed']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isTypePpaAppraisal: function () {
      return this.inventoryItem && this.inventoryItem.type === 'ForPpaAppraisal'
    },
    inventoryItemId: function () {
      return this.inventoryItem ? this.inventoryItem.id : null
    },
    modalTitle () {
      return this.closedSuccessfully ? 'Offer rejected successfully' : 'Reject Offer'
    },
    modalContent () {
      return this.closedSuccessfully ? 'The offer has been succesfully rejected.' : 'You are rejecting this offer ' + this.amountText + ' for this vehicle. Please confirm this action.'
    },
    amountText () {
      return this.proposedPaymentAmount !== '-' ? 'of ' + this.proposedPaymentAmount : ''
    },
    proposedPaymentAmount: function () {
      if (this.inventoryItem && this.inventoryItem.proposedCost > 0) {
        return this.$formatCurrency(this.inventoryItem.proposedCost)
      }
      return '-'
    }
  },
  methods: {
    onRequestClosePpa: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isClosePpaConfirmationActive = true
    },
    onClosePpa: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true

      this.inventoryItem.notes = []
      this.notes.forEach(function (item) {
        var note = {
          note: item.note,
          status: 'Active',
          isHotNote: item.isHotNote,
          pinned: item.pinned
        }

        this.inventoryItem.notes.push(note)
      }, this)

      inventoryService.closePpa(this.inventoryItem).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.closedPpaItem = response
          this.successToast('Offer rejected successfully!')
          this.closedSuccessfully = true
          // use emit here so that if we in inventory edit screen we dont get prompted with changes alert
          eventBus.$emit('on-changes-cancelled')
          eventBus.$emit('on-ppa-cancelled')
          this.onCancel()
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onCancel: function () {
      if (this.closedSuccessfully && this.closedPpaItem) {
        eventBus.$emit('inventory-changed', [this.closedPpaItem])
        eventBus.$emit('close-global-quick-view', {})
        this.$router.push({name: this.lastActiveListPage !== '' ? this.lastActiveListPage : 'ListInventory'})
      }
      this.isClosePpaConfirmationActive = false
    },
    reset: function () {
      this.closedSuccessfully = false
      this.closedPpaItem = null
      this.serverErrors = []
      this.serverErrorMessage = []
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  watch: {
    inventoryItemId: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.reset()
      }
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important
  }

  .modal-card-body {
    padding: 15px !important
  }

  .modal-card-body {
    width: 480px;
    max-width: 100% !important;
    min-width: 100% !important;
    overflow:auto;
    height: calc(50vh - 80px);
  }
</style>

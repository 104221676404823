import mutationTypes from '../mutation-types.js'
import designationService from '../../services/designationService.js'

const state = {
  currentDesignation: {},
  allDesignations: []
}

const getters = {
  allDesignations: state => {
    return state.allDesignations
  }
}

const mutations = {
  [mutationTypes.SET_CURRENT_DESIGNATION] (state, designation) {
    state.currentDesignation = designation
  },
  [mutationTypes.SET_DESIGNATIONS] (state, designations) {
    state.allDesignations = designations
  }
}

const actions = {
  setCurrentDesignation ({commit, state}, designation) {
    commit(mutationTypes.SET_CURRENT_DESIGNATION, designation)
    designationService.single(designation.id)
      .then((data) => {
        commit(mutationTypes.SET_CURRENT_DESIGNATION, data.designation)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  setDesignations ({commit, state}) {
    commit(mutationTypes.SET_DESIGNATIONS, [])
    designationService.allDesignations()
      .then((data) => {
        commit(mutationTypes.SET_DESIGNATIONS, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-fullwidth">
      <multiselect @select="$markDirty()"
      :allowEmpty="false"
      :showLabels="false"
      :name="instanceName"
      data-vv-validate-on="input|close"
      data-vv-as="lead source"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has('leadSource') }"
      v-model="selectedLeadSource"
      :options="availableLeadSources"
      placeholder="Type to search for a lead source"
      label="name"
      trackBy="id"
      :disabled="isDisabled"
      :hideSelected="true"
      :data-vv-scope="scope"></multiselect>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const mapLeadSourceGetters = createNamespacedHelpers('leadSource').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'LeadSourceSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    required: {
      type: Boolean,
      default: true
    },
    label: {
      type: String,
      default: 'Lead Source'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      selectedLeadSource: this.value,
      data: [],
      instanceName: 'accessory-bin-selector-' + this.$uuid.v4()
    }
  },
  computed: {
    ...mapLeadSourceGetters(['allLeadSources']),
    availableLeadSources: function () {
      return this.allLeadSources
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    selectedLeadSource: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentLeadSource: function (newValue, oldValue) {
      this.selectedLeadSource = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedLeadSource = newValue
    }
  }
}

</script>

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

var mixin = {
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['allUsers', 'hasFeatureAccess']),
    ...mapDealerState(['currentDealer'])
  },
  methods: {
    getColorClassByPayments: function (amount, amountPaid) {
      var result = 'has-text-grey'
      if (amountPaid > 0 && amountPaid < amount) {
        result = 'has-text-orange'
      }
      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    }
  }
}

export default mixin

import { render, staticRenderFns } from "./NewLocation.vue?vue&type=template&id=48a27cc2&scoped=true"
import script from "./NewLocation.vue?vue&type=script&lang=js"
export * from "./NewLocation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48a27cc2",
  null
  
)

export default component.exports
<template>
  <div id="pods">
    <div class="tile is-ancestor" style="flex-wrap: wrap;">
      <component v-for="pod in pods" :key="pod.id" :is="pod.component" v-bind="pod.props"></component>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PodContainer',
  props: {
    pods: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
    }
  },
  mounted: function () {
  }
}
</script>

<style scoped>
  #pods {

  }

  .tile.is-ancestor {
    margin-right: 0;
  }

</style>

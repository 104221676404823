import requiresAuth from './route_guards'

export default [
  {
    path: '/sales/wholesale-receivables',
    name: 'ListWholesaleReceivables',
    component: () => import('@/components/sales/wholesale-receivable/ListWholesaleReceivables'),
    beforeEnter: requiresAuth
  },
  {
    name: 'WholesaleReceivablesQuickView',
    path: '/sales/wholesale-receivables/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/sales/wholesale-receivable/ViewWholesaleReceivable')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/sales/wholesale-receivables/view/:id',
    name: 'ViewWholesaleReceivables',
    component: () => import('@/components/sales/wholesale-receivable/ViewWholesaleReceivable'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  }
]

import axios from 'axios'
import baseService from './baseService'
import dealData from './data/product'

const appService = {
  queueWireNotification (wireDetailAuditSendModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/WireDetailAudit/WireDetailAuditSend', wireDetailAuditSendModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService

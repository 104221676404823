<template>
  <span v-if="!isNewMissingCapture">
    <span :id="instanceId" :name="instanceId" style="width:100%" class="icon is-block has-text-centered" title="Change status of missing vehicle" @click.stop.prevent="onAddMissingCapture()" v-if="isListMode">
      <i class="fal fa-hand-receiving fa-lg"/>
    </span>
    <div :id="instanceId" :name="instanceId" class="quick-action" v-else>
      <button class="button" @click.stop.prevent="onAddMissingCapture()" title="Change status of missing vehicle">
          <b-icon pack="fal" icon="hand-receiving" custom-size="fa-lg"></b-icon>
      </button>
    </div>
  </span>
  <portal to="global-modal-portal" v-else>
    <b-modal :active.sync="isNewMissingCapture" scroll="keep" :has-modal-card="true" v-if="inventoryItem">
      <missing-capture-detail :inventoryId="inventoryItem.id" v-on:missing-capture-complete="onComplete" :hasAccessories="hasAccessories"></missing-capture-detail>
    </b-modal>
  </portal>
</template>

<script>
import InventoryMissingCaptureDetail from '@/components/generic/inventory/InventoryMissingCaptureDetail'

export default {
  name: 'InventoryMissingCapture',
  components: {
    'missing-capture-detail': InventoryMissingCaptureDetail
  },
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    isListMode: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isNewMissingCapture: false,
      instanceId: this.$uuid.v4()
    }
  },
  computed: {
    hasAccessories: function () {
      if (this.inventoryItem && this.inventoryItem.accessoryStatusId === 0) {
        return true
      }

      return false
    }
  },
  methods: {
    onComplete: function () {
      this.isNewMissingCapture = false
    },
    onAddMissingCapture: function () {
      this.isNewMissingCapture = true
    }
  }
}

</script>

<style scoped>
  .content ul {
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important
  }

  .modal-card-body {
    max-height: 100px !important;
    min-height: 100px !important;
    height: 100px !important;
  }
</style>

import axios from 'axios'
import baseService from './baseService'

const appService = {
  queueBatchPaymentRequest (batchPaymentRequest) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/batchpaymentrequest/queueBatchPaymentRequest', batchPaymentRequest, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getBatchPaymentRequestListByInventoryId (inventoryId) {
    return new Promise((resolve, reject) => {
      if (!baseService.isTestMode) {
        axios
          .get('/batchpaymentrequest/GetBatchPaymentRequestListByInventoryId?inventoryId=' + inventoryId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService

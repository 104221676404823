import axios from 'axios'
import baseService from './baseService'
import dealData from './data/product'

const appService = {
  bySubscriber (subscriberId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/standardnote/notes?id=' + subscriberId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (noteId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all.find((x) => x.id === noteId))
      } else {
        axios
          .post('/standardnote/get?id=' + noteId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (noteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var deal = {
          id: 999
        }
        dealData.all.push(deal)
        resolve(deal)
      } else {
        axios
          .post('/standardnote/save', noteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (noteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var deal = dealData.all.find((x) => x.id === noteModel.id)
        if (deal) {
          deal.id = noteModel.id
        }
        resolve(deal)
      } else {
        axios
          .post('/standardnote/update', noteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchInventoryNotesBySubscriberId (subscriberId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all)
      } else {
        axios
          .get('/standardnote/inventorynotes?id=' + subscriberId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchDealNotesBySubscriberId (subscriberId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData.all)
      } else {
        axios
          .get('/standardnote/dealnotes?id=' + subscriberId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService

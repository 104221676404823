<template>
  <div class="field">
    <label class="label" v-if="showLabel">{{label}}<b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon></label>
    <div class="control is-fullwidth">
        <multiselect @select="$markDirty()"
        :name="instanceName"
        data-vv-validate-on="input|close"
        :data-vv-as="this.validateAs"
        v-validate="{'required': required}"
        :class="{'is-danger': errors.has(instanceName) }"
        v-model="selectedState"
        :options="allUsStates"
        placeholder="Type to search for a state"
        label="name"
        trackBy="name"
        :disabled="isDisabled"
        :hideSelected="true"></multiselect>
    </div>
    <span v-show="errors.has(instanceName)" class="help is-danger"><i v-show="errors.has(instanceName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(instanceName) }}</span>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const mapAllStatesGetters = createNamespacedHelpers('states').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'StateSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Item'
    },
    validateAs: {
      type: String,
      default: 'Item'
    },
    filter: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedState: this.value,
      data: [],
      instanceName: 'make-component-' + this.$uuid.v4()
    }
  },
  computed: {
    ...mapAllStatesGetters(['allStates']),
    allUsStates: function () {
      return this.allStates
    }
  },
  watch: {
    selectedState: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentState: function (newValue, oldValue) {
      this.selectedState = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedState = newValue
    }
  }
}

</script>

import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/subscriber',
    name: 'EditSubscriber',
    component: () => import('@/components/configuration/subscriber/EditSubscriber'),
    beforeEnter: requiresAuth
  }
]

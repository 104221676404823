var mixin = {
  methods: {
    onCancel: function () {
      this.onComplete()
    },
    onComplete: function () {
      let nextRoute = this.getViewRoute()
      this.$router.push(nextRoute)
    },
    getViewRoute: function () {
      return {}
    }
  }
}

export default mixin

export default {
  all: [
    {
      name: 'Administration',
      status: 'Active',
      id: 1
    },
    {
      name: 'Sales',
      status: 'Active',
      id: 2
    },
    {
      name: 'Service',
      status: 'Active',
      id: 3
    }
  ]
}

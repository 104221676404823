<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Add Location</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmit" @change="onFormChange">
      <b-tabs size="is-small" v-model="locationTabIndex" :animated="false" type="is-toggle">
        <b-tab-item label="Definition" icon-pack="fas"  icon="fas fa-info-circle fa-sm">
          <div class="columns">
            <div class="column is-6">
              <dealer-selector v-model="selectedDealer"></dealer-selector>
              <div class="field">
                <label class="label">Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <input name="name" data-vv-as="name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('name') }" type="text" placeholder="" v-model="name">
                  <span v-show="errors.has('name')" class="help is-danger"><i v-show="errors.has('name')" class="fas fa-exclamation-triangle"></i> {{ errors.first('name') }}</span>
                </div>
              </div>
              <div class="field">
                <label class="label">Description</label>
                <div class="control">
                  <input name="description" data-vv-as="description" :class="{'input': true, 'is-danger': errors.has('description') }" type="text" placeholder="" v-model="description">
                </div>
              </div>
              <div class="field">
                <switch-selector type="is-info" label="Is Off-Site" v-model="isOffSite"></switch-selector>
              </div>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Addresses" icon-pack="fas" icon="fas fas fa-address-card fa-sm">
          <address-capture v-model="address" :useValidator="thisValidator"></address-capture>
        </b-tab-item>
      </b-tabs>
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  type="button" @click="validateBeforeSubmit">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>
  </section>
</template>

<script>
import locationService from '@/services/locationService'
import DealerSelector from '@/components/generic/DealerSelector'
import AddressCapture from '@/components/generic/AddressCapture'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import completeCancelInitMixin from '@/mixins/generic/completeCancelInit'
import SwitchSelector from '@/components/generic/SwitchSelector'
import locationsMixin from '@/mixins/locations/index'

export default {
  name: 'NewLocation',
  mixins: [validateBeforeSubmitMixin, completeCancelInitMixin, locationsMixin],
  components: {
    'dealer-selector': DealerSelector,
    'switch-selector': SwitchSelector,
    'address-capture': AddressCapture
  },
  props: {
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full-screen'
    }
  },
  data () {
    return {
      selectedDealer: null,
      id: 0,
      dealerId: null,
      name: '',
      description: '',
      isOffSite: false,
      address: {
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: ''
      },
      gpsAddress: '',
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      locationTabIndex: 0
    }
  },
  methods: {
    onSave: function () {
      var locationModel = {
        isCreate: true,
        subscriberId: this.$store.state.user.currentUser.subscriberId,
        dealerId: this.selectedDealer.id,
        name: this.name,
        description: this.description,
        address1: this.address.address1,
        address2: this.address.address2,
        address3: this.address.address3,
        address4: this.address.address4,
        city: this.address.city,
        country: this.address.country,
        postalCode: this.address.postalCode,
        state: this.address.state,
        gpsAddress: this.address.gpsAddress,
        status: 'Active',
        showInList: true,
        isOffSite: this.isOffSite
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      locationService.save(locationModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.onChangesSaved()
          this.$store.dispatch('location/syncLocation', response.location).then((x) => {
            this.$emit('on-save', response.location)
            if (this.mode === 'full-screen') {
              this.onComplete()
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onCancel: function () {
      this.$emit('on-cancel')
      if (this.mode === 'full-screen') {
        this.onComplete()
      }
    },
    getViewRoute: function () {
      return {name: 'ListLocations'}
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
    <div class="inline-block" :name="instanceName">
        <div class="quick-action" v-if="isLocked" >
          <button class="button" @click="onUnlock" title="Click to unlock fields for editing">
            <b-icon pack="fal" icon="unlock" custom-size="fa-lg"></b-icon>
          </button>
        </div>
        <div class="quick-action" v-else>
          <button class="button has-text-warning" @click="onLock" title="Click to re-lock opened fields without saving">
            <b-icon pack="fal" icon="lock" custom-size="fa-lg"></b-icon>
          </button>
        </div>
        <portal to="global-modal-portal" v-if="isUnlocking">
            <b-modal :active.sync="isUnlocking" :width="640" scroll="keep" :has-modal-card="true">
                <div class="modal-card" style="width: auto">
                    <header class="modal-card-head">
                        <p class="modal-card-title">Enter Verification Pin </p>
                    </header>
                    <section class="modal-card-body" style="min-width:350px;min-height:130px">
                        <p class="title is-size-7 has-text-danger" v-if="pinError !== ''">{{pinError}}</p>
                        <form>
                            <div class="field">
                                <label class="label">Pin<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                                <div class="control has-icons-left">
                                    <input name="pin" data-vv-as="pin number" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('pin') }" type="password" placeholder="" v-model="pin">
                                    <span v-show="errors.has('pin')" class="help is-danger"><i v-show="errors.has('pin')" class="fal fa-exclamation-triangle"></i> {{ errors.first('pin') }}</span>
                                    <span class="icon is-small is-left">
                                        <i class="fal fa-lock"></i>
                                    </span>
                                </div>
                            </div>
                        </form>
                    </section>
                    <footer class="modal-card-foot">
                    <button class="button is-primary" :class="{'is-loading': isVerifyingPin }" @click="onVerifyPin(pin)">Verify</button>
                    <button class="button is-danger" @click="isUnlocking = false">Cancel</button>
                    </footer>
                </div>
            </b-modal>
        </portal>
    </div>
</template>

<script>

import userService from '@/services/userService'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'PodLockControl',
  props: {
    value: {
      type: Boolean,
      default: true
    },
    bypassPermissions: {
      type: Array,
      default: () => []
    },
    masterBypassPermissions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      isLocked: true,
      isUnlocking: false,
      isVerifyingPin: false,
      pinError: '',
      serverErrors: [],
      serverErrorMessage: '',
      instanceName: this.$uuid.v4(),
      pin: ''
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser'])
  },
  methods: {
    onUnlock: function () {
      if (this.bypassPermissions.length > 0) {
        var canByPass = false
        this.bypassPermissions.forEach((x) => {
          canByPass = canByPass || this.hasFeatureAccess(x)
        })

        if (this.masterBypassPermissions.length > 0) {
          canByPass = false
          this.masterBypassPermissions.forEach((x) => {
            canByPass = canByPass || this.hasFeatureAccess(x)
          })
        }

        if (canByPass) {
          this.unlock()
        }
      } else {
        this.isUnlocking = true
      }
    },
    onLock: function () {
      this.isUnlocking = false
      this.isLocked = true
    },
    unlock: function () {
      this.pin = ''
      this.isLocked = false
      this.isUnlocking = false
    },
    lock: function (error) {
      this.isLocked = true
      this.pinError = error
      this.isUnlocking = error !== ''
    },
    onVerifyPin: function (pin) {
      this.pinError = ''
      this.isVerifyingPin = true
      userService.verifyPin(pin).then(response => {
        if (response.result === true) {
          this.unlock()
        } else {
          this.lock('You have entered an invalid pin.')
        }
        this.isVerifyingPin = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isVerifyingPin = false
      })
    }
  },
  watch: {
    isLocked: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    value: function (newValue, oldValue) {
      this.isLocked = newValue
    }
  },
  mounted: function () {
    this.isLocked = this.value
  }
}
</script>

<style>
</style>

var mixin = {
  data () {
    return {
      isDirty: false
    }
  },
  methods: {
    onChangesSaved: function () {
      this.isDirty = false
    },
    onChangesCancelled: function () {
      this.isDirty = false
    },
    onFormChange: function () {
      this.isDirty = true
    },
    checkDirty: function (next) {
      if (this.isDirty) {
        if (confirm('You have un-saved changes. Do you wish to discard these changes?')) {
          next()
        }
      } else {
        next()
      }
    },
    validateBeforeSubmit () {
      if (this.serverErrors) {
        this.serverErrors = []
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.onSave()
        } else {
          if (this.onValidationFailure) {
            this.onValidationFailure()
          }
          document.querySelectorAll('.header-collapsed').forEach((x) => x.classList.remove('header-collapsed'))
          this.$goToFirstValidationError(this.$validator.errors.items)
        }
      })
    },
    validateBeforeSubmitWithCallBack (onSave) {
      if (this.serverErrors) {
        this.serverErrors = []
      }
      this.$validator.validateAll().then((result) => {
        if (result) {
          if (onSave) {
            onSave()
          } else {
            this.onSave()
          }
        } else {
          document.querySelectorAll('.header-collapsed').forEach((x) => x.classList.remove('header-collapsed'))
          this.$goToFirstValidationError(this.$validator.errors.items)
        }
      })
    },
    validateBeforeSubmitScoped (scope) {
      if (this.serverErrors) {
        this.serverErrors = []
      }
      this.$validator.validateAll(scope).then((result) => {
        if (result) {
          this.onSave()
        } else {
          document.querySelectorAll('.header-collapsed').forEach((x) => x.classList.remove('header-collapsed'))
          this.$goToFirstValidationError(this.$validator.errors.items)
        }
      })
    },
    validateBeforeSubmitAllScoped () {
      if (this.serverErrors) {
        this.serverErrors = []
      }

      this.$validator.validate().then((result) => {
        if (result) {
          this.onSave()
        } else {
          // for some reason the multiselects components returns two error items for one input, first doesnt have scope but the second one has. Inputs on the form return one error item
          let errorScope = this.$validator.errors.items[0].scope ? this.$validator.errors.items[0].scope : null
          if (errorScope === null) {
            errorScope = this.$validator.errors.items[1] && this.$validator.errors.items[1].scope ? this.$validator.errors.items[1].scope : null
          }

          if (errorScope !== null) {
            // cater for double digit tabs length
            let pageIndex = (errorScope.length === 6) ? errorScope.slice(-1) - 1 : errorScope.slice(-2) - 1
            this.tabIndex = pageIndex
          }

          if (this.onValidationFailure) {
            this.onValidationFailure()
          }
          document.querySelectorAll('.header-collapsed').forEach((x) => x.classList.remove('header-collapsed'))

          this.$nextTick(() => { this.$goToFirstValidationError(this.$validator.errors.items) })
        }
      })
    }
  },
  beforeRouteLeave (to, from, next) {
    this.checkDirty(next)
  }
}

export default mixin

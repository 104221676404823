<template>
    <div class="box">
      <portal :to="headerPortal" :disabled="headerPortal === ''">
        <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Edit RO Service Customer: {{ headerFullName }}</h1>
      </portal>
      <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
      <form @submit.prevent="validateBeforeSubmit" v-if="currentCustomer.id && currentCustomer.id !== 0" @change="onFormChange">
        <div class="columns is-multiline is-mobile">
          <div class="column is-6">
            <div class="field">
              <label class="label">
                First Name
                <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
              </label>
              <div class="control">
                <input name="firstName" data-vv-as="first name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('firstName') }" type="text" placeholder="e.g John" v-model="currentCustomer.firstName" data-vv-scope="page-1" autocomplete="work-now">
                <span v-show="errors.has('firstName')" class="help is-danger">
                  <i v-show="errors.has('firstName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('firstName') }}
                </span>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label">Last Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
              <div class="control">
                <input name="lastName" data-vv-as="last name"  v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('lastName') }" type="text" placeholder="e.g Doe" v-model="currentCustomer.lastName" data-vv-scope="page-1" autocomplete="work-now">
              </div>
              <span v-show="errors.has('lastName')" class="help is-danger">
                <i v-show="errors.has('lastName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('lastName') }}
              </span>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label">Phone<b-icon pack="fas" icon="star" class="fa-ss" v-if="isPhoneRequired"></b-icon></label>
              <div class="control">
                <input name="phoneNumber" v-validate="{'min': 16, 'max': 16, 'required': isPhoneRequired}" data-vv-as="phone number" :class="{'input': true, 'is-danger': errors.has('phoneNumber') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentCustomer.contactPhoneNumber" data-lpignore="true" autocomplete="work-now">
                <span v-show="errors.has('phoneNumber')" class="help is-danger">
                  <i v-show="errors.has('phoneNumber')" class="fas fa-exclamation-triangle"></i> {{ errors.first('phoneNumber') }}
                </span>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label">Mobile<b-icon pack="fas" icon="star" class="fa-ss" v-if="isMobileRequired"></b-icon></label>
              <div class="control">
                <input name="mobile" v-validate="{'min': 16, 'max': 16, 'required': isMobileRequired}" data-vv-as="mobile" :class="{'input': true, 'is-danger': errors.has('mobile') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentCustomer.cellularPhoneNumber" data-lpignore="true" autocomplete="work-now">
                <span v-show="errors.has('mobile')" class="help is-danger">
                  <i v-show="errors.has('mobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('mobile') }}
                </span>
              </div>
            </div>
          </div>
          <div class="column is-6">
            <div class="field">
              <label class="label">Email Address<b-icon pack="fas" icon="star" class="fa-ss" v-if="isEmailRequired"></b-icon></label>
              <div class="control has-icons-left has-icons-right">
                <input name="email" data-vv-as="email address" v-validate="{'email': true, 'required': isEmailRequired}" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has('email') }" type="text" placeholder="Email" v-model="currentCustomer.contactEmailAddress" data-lpignore="true" autocomplete="work-now">
                <span v-show="errors.has('email')" class="help is-danger">
                  <i v-show="errors.has('email')" class="fas fa-exclamation-triangle"></i> {{ errors.first('email') }}
                </span>
                <span class="icon is-small is-left">
                  <i class="fas fa-envelope"></i>
                </span>
                <span class="icon is-small is-right" v-show="!errors.has('email')">
                  <i class="fas fa-check"></i>
                </span>
                <span class="icon is-small is-right" v-show="errors.has('email')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
            <div class="column is-6"></div>
          </div>
          <div class="column is-12">
            <div class="field">
              <label class="label">Address</label>
              <div class="control">
                <address-capture v-model="address" :isRoCustomer="true"></address-capture>
              </div>
            </div>
          </div>
        </div>
        <portal :to="actionPortal">
          <div class="field is-grouped">
            <p class="control">
              <button class="button is-primary" :disabled="isSaving" :class="{'is-loading': isSaving }" type="button" @click="validateBeforeSubmitAllScoped">Save</button>
            </p>
            <p class="control">
              <a class="button is-danger" @click="onCancel()">Cancel</a>
            </p>
          </div>
        </portal>
      </form>
      <b-loading :is-full-page="true" :active.sync="showLoader" :canCancel="false"></b-loading>
    </div>
</template>

<script>
import roServiceCustomerService from '@/services/roServiceCustomerService'
import AddressCapture from '@/components/generic/AddressCapture'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'

import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('dealer')

export default {
  name: 'EditRoServiceCustomer',
  mixins: [validateBeforeSubmitMixin],
  components: {
    'address-capture': AddressCapture,
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    customerId: {
      type: Number,
      default: 0
    },
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full-screen'
    },
    fulfillForDepositCaptureMode: {
      type: Boolean,
      default: false
    },
    defaultTab: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isLoading: false,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      currentCustomerId: 0,
      currentCustomer: {
        id: 0,
        dealerId: 0,
        firstName: '',
        lastName: '',
        name: '',
        contactEmailAddress: '',
        cellularPhoneNumber: '',
        contactPhoneNumber: '',
        address: '',
        cityStateZip: ''
      },
      address: {
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        stateCode: '',
        gpsAddress: ''
      }
    }
  },
  computed: {
    ...mapGetters(['allDealers']),
    isPhoneRequired: function () {
      return this.currentCustomer.contactEmailAddress.length === 0 && this.currentCustomer.cellularPhoneNumber.length === 0
    },
    isMobileRequired: function () {
      return this.currentCustomer.contactEmailAddress.length === 0 && this.currentCustomer.contactPhoneNumber.length === 0
    },
    isEmailRequired: function () {
      return this.currentCustomer.cellularPhoneNumber.length === 0 && this.currentCustomer.contactPhoneNumber.length === 0
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    headerFullName: function () {
      let result = ''
      if (this.currentCustomer.id !== 0) {
        result = this.currentCustomer.name
      }
      return result
    },
    showLoader: function () {
      return this.isLoading || this.isSaving
    }
  },
  methods: {
    fetchCustomer: function (id) {
      this.isLoading = true
      roServiceCustomerService.single(id).then(response => {
        this.isLoading = false
        this.currentCustomer = {
          ...response.customer
        }
        if (this.currentCustomer.id) {
          this.initInputValues()
        } else {
          this.serverErrorMessage = 'could not load RO customer with id: ' + id
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    initInputValues: function () {
      if (this.currentCustomer === null) return
      this.selectedDealer = this.getDealer(this.currentCustomer.dealerId)
      this.address.address1 = this.currentCustomer.address
      let [city, remainder] = this.currentCustomer.cityStateZip ? this.currentCustomer.cityStateZip.split(', ') : ['', '']
      let stateZip = remainder ? remainder.split(' ') : ['', '']
      let cityStateZipObj = {
        city: city,
        stateCode: stateZip.slice(0, stateZip.length - 1).join(' '),
        zipCode: stateZip[stateZip.length - 1]
      }
      this.address.city = cityStateZipObj.city
      this.address.state = cityStateZipObj.stateCode
      this.address.stateCode = cityStateZipObj.stateCode
      this.address.postalCode = cityStateZipObj.zipCode
    },
    onSave: function () {
      let address = this.address.address1 ? this.address.address1 : ''
      let cityStateZip = this.address.city ? `${this.address.city}, ${this.address.state} ${this.address.postalCode}` : ''
      var roCustomerModel = {
        id: this.currentCustomer.id,
        subscriberId: this.$store.state.user.currentUser.subscriberId,
        dealerId: this.selectedDealer.id,
        roServiceId: this.currentCustomer.roServiceId,
        firstName: this.currentCustomer.firstName,
        lastName: this.currentCustomer.lastName,
        contactEmailAddress: this.currentCustomer.contactEmailAddress,
        contactPhoneNumber: this.currentCustomer.contactPhoneNumber,
        cellularPhoneNumber: this.currentCustomer.cellularPhoneNumber,
        address: address,
        cityStateZip: cityStateZip
      }
      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = false
      roServiceCustomerService.update(roCustomerModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrorMessage = response.message
          this.serverErrors = response.errors
        } else {
          this.onChangesSaved()
          if (this.mode === 'full-screen') {
            this.onComplete()
          } else {
            this.$emit('on-save', response.customer)
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getDealer: function (id) {
      return this.allDealers.find((x) => x.id === id)
    },
    onCancel: function () {
      if (this.mode === 'full-screen') {
        this.onComplete()
      } else {
        this.$emit('on-cancel')
      }
    },
    onComplete: function () {
      // this.$router.push({name: 'ListCustomers'})
    }
  },
  mounted: function () {
    this.currentCustomerId = (this.customerId === 0) ? this.$route.params.id : this.customerId
    this.fetchCustomer(this.currentCustomerId)
  }
}

</script>

export default {
  overview: {
    current: [{
      id: 1,
      subscriberId: 1,
      dealerId: 1,
      stockNo: 'F12093',
      vin: 'ZFLDJ019287340',
      type: 'New',
      status: 'Active',
      possessionStatus: 'Arrived',
      cpoStatus: 'Eligible',
      modelName: '2017 Ferrari FXX-K',
      makeName: 'Ferrari',
      purchasedFromType: 'Customer',
      purchasedFromId: 1,
      purchasedFromName: 'Raj Moodaley',
      usage: '1024 miles',
      damaged: false,
      askingPrice: null,
      salePrice: 555000.00
    },
    {
      id: 2,
      subscriberId: 1,
      dealerId: 1,
      stockNo: 'F12094',
      vin: 'ZFLDJ0345347340',
      type: 'Inbound',
      status: 'Active',
      possessionStatus: 'Inbound',
      cpoStatus: 'Eligible',
      modelName: '2017 Ferrari California',
      makeName: 'Ferrari',
      purchasedFromType: 'Customer',
      purchasedFromId: 1,
      purchasedFromName: 'Bob Moodaley',
      usage: '2044 miles',
      damaged: false,
      askingPrice: null,
      salePrice: 555000.00
    }],
    salesHistory: {

    },
    earningHistory: {

    },
    notification: [
      {
        id: 1,
        title: 'Dont forget to speak to your sales manager about Spiffs',
        description: 'Some lenghty description if required',
        isTask: false,
        isComplete: false
      },
      {
        id: 2,
        title: 'New inbound vehicles require updating of the EAG live system',
        description: 'Dont forget to update EAG live for any new inbound vehicles.',
        isTask: false,
        isComplete: false
      }
    ]
  },
  all: [{
    id: 1,
    subscriberId: 1,
    dealerId: 1,
    stockNo: 'F12093',
    vin: 'ZFLDJ019287340',
    type: 'New',
    status: 'Active',
    possessionStatus: 'Arrived',
    cpoStatus: 'Eligible',
    modelName: '2017 Ferrari FXX-K',
    makeName: 'Ferrari',
    purchasedFromType: 'Customer',
    purchasedFromId: 1,
    purchasedFromName: 'Raj Moodaley',
    usage: '1024 miles',
    damaged: false,
    askingPrice: null,
    salePrice: 555000.00
  },
  {
    id: 2,
    subscriberId: 1,
    dealerId: 1,
    stockNo: 'F12094',
    vin: 'ZFLDJ0345347340',
    type: 'Inbound',
    status: 'Active',
    possessionStatus: 'Inbound',
    cpoStatus: 'Eligible',
    modelName: '2017 Ferrari California',
    makeName: 'Ferrari',
    purchasedFromType: 'Customer',
    purchasedFromId: 1,
    purchasedFromName: 'Bob Moodaley',
    usage: '2044 miles',
    damaged: false,
    askingPrice: null,
    salePrice: 555000.00
  },
  {
    id: 3,
    subscriberId: 1,
    dealerId: 1,
    stockNo: 'F16094',
    vin: 'ZFLDJ0355347340',
    type: 'Inbound',
    status: 'Active',
    possessionStatus: 'Inbound',
    cpoStatus: 'Eligible',
    modelName: '2017 Ferrari 812 Superfast',
    makeName: 'Ferrari',
    purchasedFromType: 'Customer',
    purchasedFromId: 1,
    purchasedFromName: 'Mike Moore',
    usage: '2044 miles',
    damaged: false,
    askingPrice: null,
    salePrice: 555000.00
  }
  ]
}

import { render, staticRenderFns } from "./InventorySelector.vue?vue&type=template&id=17aff1d2&scoped=true"
import script from "./InventorySelector.vue?vue&type=script&lang=js"
export * from "./InventorySelector.vue?vue&type=script&lang=js"
import style0 from "./InventorySelector.vue?vue&type=style&index=0&id=17aff1d2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17aff1d2",
  null
  
)

export default component.exports
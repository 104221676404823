<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Edit Customer: {{ fullName }}</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmit" v-if="currentCustomer.id && currentCustomer.id !== 0" @change="onFormChange">
      <div class="columns is-multiline">
        <div class="column is-6">
          <div class="field">
            <label class="label">Email Address<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="control has-icons-left has-icons-right">
              <input name="contactEmailAddress" data-vv-as="email address" v-validate="{'email': true, 'required': true}" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has('contactEmailAddress') }" type="text" placeholder="Email" v-model="currentCustomer.contactEmailAddress" data-lpignore="true" autocomplete="work-now">
              <span v-show="errors.has('contactEmailAddress')" class="help is-danger">
                <i v-show="errors.has('contactEmailAddress')" class="fas fa-exclamation-triangle"></i> {{ errors.first('contactEmailAddress') }}
              </span>
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <span class="icon is-small is-right" v-show="!errors.has('contactEmailAddress')">
                <i class="fas fa-check"></i>
              </span>
              <span class="icon is-small is-right" v-show="errors.has('contactEmailAddress')">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Mobile<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="control">
              <input name="cellularPhoneNumber" v-validate="{'min': 16, 'max': 16, 'required': true}" data-vv-as="mobile" :class="{'input': true, 'is-danger': errors.has('cellularPhoneNumber') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="currentCustomer.cellularPhoneNumber" data-lpignore="true" autocomplete="work-now">
              <span v-show="errors.has('cellularPhoneNumber')" class="help is-danger">
                <i v-show="errors.has('cellularPhoneNumber')" class="fas fa-exclamation-triangle"></i> {{ errors.first('cellularPhoneNumber') }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <br />
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-primary" :disabled="isSaving" :class="{'is-loading': isSaving }" type="button" @click="validateBeforeSubmitAllScoped">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>
    <b-loading :is-full-page="isFullPage" :active.sync="showLoader" :canCancel="false"></b-loading>
  </section>
</template>

<script>
import partsService from '@/services/partsService'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import completeCancelInitMixin from '@/mixins/generic/completeCancelInit'
import SectionHeader from '@/components/generic/SectionHeader'
import eventBus from '@/eventBus'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'

export default {
  name: 'EditPartsCustomer',
  mixins: [validateBeforeSubmitMixin, completeCancelInitMixin],
  components: {
    'section-header': SectionHeader,
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    customerId: {
      type: Number,
      default: 0
    },
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    isFullPage: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isLoading: false,
      currentCustomerId: 0,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      currentCustomer: {
        id: 0,
        firstName: '',
        lastName: '',
        contactEmailAddress: '',
        cellularPhoneNumber: ''
      },
      getValidationErrors: false
    }
  },
  computed: {
    fullName: function () {
      if (this.currentCustomer && ((this.currentCustomer.firstName !== '' && this.currentCustomer.firstName !== null) || (this.currentCustomer.lastName !== '' && this.currentCustomer.lastName !== null))) {
        return this.currentCustomer.firstName + ' ' + this.currentCustomer.lastName
      }
      return ''
    },
    showLoader: function () {
      return this.isLoading || this.isSaving
    }
  },
  methods: {
    onSave: function () {
      let customerModel = {
        id: this.currentCustomer.id,
        contactEmailAddress: this.currentCustomer.contactEmailAddress,
        cellularPhoneNumber: this.currentCustomer.cellularPhoneNumber
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      partsService.updateCustomer(customerModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.onChangesSaved()
          eventBus.$emit('parts-item-changed')
          eventBus.$emit('quickview-refresh')
          this.$emit('on-save', response.customer)
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    fetchCustomer: function (id) {
      this.isLoading = true
      partsService.fetchCustomerById(id).then(response => {
        this.isLoading = false
        this.currentCustomer = {
          ...response.customer
        }
        if (!this.currentCustomer) {
          this.serverErrorMessage = 'could not load customer with id: ' + id
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    onCancel: function () {
      this.$emit('on-cancel')
    }
  },
  mounted: function () {
    this.currentCustomerId = (this.customerId === 0) ? this.$route.params.id : this.customerId
    this.fetchCustomer(this.currentCustomerId)
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  .modal-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }
</style>

<template>
  <div class="field">
    <div class="notification is-info">
      Please convert any of these backup deals to be the new primary deal.
    </div>
    <div v-if="hasErrors" class="errors"><p>{{ errorMessage }}</p><br/></div>
    <div>
      <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th></th>
            <th>Status</th>
            <th>Name</th>
            <th>Sales Person</th>
            <th>Total</th>
          </tr>
        </thead>
        <tr v-for="(deal, index) in deals" :key="index">
          <td><div class="block"><b-radio v-model="selectedId" :native-value="deal.id" /></div></td>
          <td>{{ deal.type }}</td>
          <td>{{ deal.buyerName }}</td>
          <td>{{ deal.salesPersonName }}</td>
          <td>{{ deal.totalValue | currency }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    $validator: '$validator'
  },
  name: 'SelectPrimaryBackupDealControl',
  props: {
    value: {
      type: Object,
      default: null
    },
    backupDeals: {
      type: Array,
      default: null
    },
    selectedDealItemId: {
      type: Number,
      default: null
    },
    hasErrors: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedId: null,
      instanceName: this.$uuid.v4(),
      errorMessage: 'One deal must be selected.'
    }
  },
  computed: {
    deals: function () {
      return this.backupDeals
    },
    hasDeals: function () {
      return this.deals && this.deals.length > 0
    }
  },
  watch: {
    selectedId: function () {
      var deal = this.deals.find((x) => x.id === this.selectedId)
      var dealItem = deal.items.find((x) => x.dealItemType === 'Sold')
      this.$emit('update:selectedDealItemId', dealItem.id)
    }
  },
  methods: {
    setDefaultDeal: function () {
      if (this.hasDeals) {
        this.selectedId = this.backupDeals[0].id
      }
    }
  },
  mounted () {
    this.setDefaultDeal()
  }
}
</script>

<style scoped>
</style>

<template>
  <div>
    <portal-target name="global-search-portal" slim style="width:100%" @change="onPortalChanged"></portal-target>
    <p class="control has-icons-left" style="width:100%" v-if="!isOverridden">
      <input class="input is-expanded" type="search" placeholder="Search" v-model="query" v-on:search.prevent="onQuery">
      <span class="icon is-medium is-left" style="z-index:1">
        <i class="fas fa-search fa-xs"></i>
      </span>
    </p>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapGlobalGetters = createNamespacedHelpers('global').mapGetters

export default {
  name: 'GlobalSearch',
  props: {
    value: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      query: this.value,
      total: 0,
      sortField: 'id',
      sortOrder: 'desc',
      defaultSortOrder: 'desc',
      typeId: -1,
      page: 1,
      perPage: this.$store.state.config.defaultPageSize,
      portalContentCount: 0
    }
  },
  methods: {
    loadAsyncData () {
      if (this.query.trim() === '') {
        this.$store.dispatch('global/resetSearch').then((x) => {
        }).catch((error) => {
          console.log(error)
        })
      } else {
        const searchCriteria = {
          dealerId: this.currentDealer.id,
          showServiceLoaners: false,
          showCancelledOnly: false,
          showInStockOnly: false,
          fullVin: true,
          statusId: -1,
          startDate: null,
          endDate: null,
          showBackupDealsOnly: false,
          saleType: -1,
          vehicleType: -1,
          salesmanId: 0,
          fiId: 0,
          query: this.query.trim(),
          typeId: this.typeId,
          pageSize: this.perPage,
          sortBy: this.sortField + '.' + this.sortOrder,
          page: this.page - 1
        }

        this.$store.dispatch('global/performSearch', searchCriteria).then((x) => {
        }).catch((error) => {
          console.log(error)
        })
      }
    },
    onQuery () {
      this.loadAsyncData()
    },
    onPortalChanged: function (newContent, oldContent) {
      if (newContent) {
        this.portalContentCount = 1
      } else {
        this.portalContentCount = 0
      }
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    ...mapGlobalGetters(['allSearchResults', 'searchActive']),
    isOverridden: function () {
      return this.portalContentCount > 0
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (this.query !== '') {
        this.onQuery()
      }
    }
  }
}
</script>

<style scoped>

</style>

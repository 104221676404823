<template>
  <section>
    <b-dropdown
      v-if="!isInLine"
      position="is-top-left"
      :scrollable="true"
      :close-on-click="false"
      :mobile-modal="true"
      :expanded="true"
      :autoClose="false"
      class="z-index-9k">
        <i class="fad fa-calculator-alt fa-2x dropdown-btn clickable"
          slot="trigger"
          title="click for detail"/>
        <div id="list-filter-container" class="calc-container search-filter-container medium-top-margin medium-bottom-margin">
          <calculator></calculator>
        </div>
    </b-dropdown>
    <a v-if="isInLine"
      class="fg-save-action button is-pulled-right has-text-dark clickable mb-5"
      @click="toggleShowCalc()"
      target="_blank"
      v-tooltip="{content: 'toggle soft calculator'}">
      <i class="fad fa-calculator-alt fa-lg"></i>
    </a>
    <calculator v-if="isInLine && showCalc"></calculator>
  </section>
</template>

<script>

import Calculator from '@/components/generic/Calculator'
import eventBus from '@/eventBus'

export default {
  name: 'CalculatorControl',
  props: {
    isInLine: {
      type: Boolean,
      default: true
    }
  },
  components: {
    'calculator': Calculator
  },
  data () {
    return {
      showCalc: false
    }
  },
  computed: {
  },
  methods: {
    toggleShowCalc: function () {
      this.showCalc = !this.showCalc
      this.$emit('onToggleSoftCalculator', this.showCalc)
    },
    openCalculator: function () {
      if (this.showCalc) return
      this.toggleShowCalc()
    }
  },
  mounted: function () {
  },
  created: function () {
    eventBus.$on('open-calculator', this.openCalculator)
  },
  destroyed () {
    eventBus.$off('open-calculator', this.openCalculator)
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.calc-container {
  overflow-y: scroll;
  padding: 5px;
  max-width: 350px !important;
  width: 350px !important;
  max-height: 580px !important;
  height: 580px !important;
}

.fg-save-action {
  border: 1px dashed #e0e0e3;
  border-radius: 50%;
  background: white;
  width: 2.25em;
  color: #4b6271;
  cursor: pointer;
  z-index: 999;
}

</style>

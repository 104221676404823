<template>
  <div v-if="attribute !== null">
    <div class="columns is-multiline">
      <div class="column is-6" v-if="attribute.searchType">
        <div class="field">
          <label class="label">Required Condition</label>
          <div class="field">
            {{ attribute.searchType }}
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Production Year</label>
          <div class="field">
            {{ attribute.productionYear }}
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Make</label>
          <div class="field">
            {{ attribute.make }}
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Model</label>
          <div class="field">
            {{ attribute.model }}
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Style</label>
          <div class="field">
            {{ attribute.style }}
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Trim</label>
          <div class="field">
            {{ attribute.trim }}
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Exterior Color</label>
          <div class="field">
            {{ attribute.exteriorColor }}
          </div>
        </div>
      </div>
      <div class="column is-6">
        <div class="field">
          <label class="label">Interior Color</label>
          <div class="field">
            {{ attribute.interiorColor }}
          </div>
        </div>
      </div>
      <div class="column is-6" v-if="attribute.alternateExteriorColors">
        <div class="field">
          <label class="label">Alt. Exterior Colors</label>
          <div class="field">
            {{ attribute.alternateExteriorColors }}
          </div>
        </div>
      </div>
      <div class="column is-6" v-if="attribute.alternateInteriorColors">
        <div class="field">
          <label class="label">Alt. Interior Colors</label>
          <div class="field">
            {{ attribute.alternateInteriorColors }}
          </div>
        </div>
      </div>
      <div class="column is-6" v-if="attribute.searchTags">
        <div class="field">
          <label class="label">Tags</label>
          <div class="field">
            {{ attribute.searchTags }}
          </div>
        </div>
      </div>
      <div class="column is-12" v-if="attribute.searchNotes">
        <div class="field">
          <label class="label">Additional Information</label>
          <div class="field">
            {{ attribute.searchNotes }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'InventoryAttributeView',
  props: {
    inventory: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      attribute: null
    }
  },
  methods: {
    loadAttributes: function () {
      let styleAttribute = this.inventory.attributes.filter((x) => x.name === 'Style')[0]
      let trimAttribute = this.inventory.attributes.filter((x) => x.name === 'Trim')[0]
      let productionYearAttribute = this.inventory.attributes.filter((x) => x.name === 'ProductionYear')[0]
      let inventoryModelAttribute = this.inventory.attributes.filter((x) => x.name === 'Model')[0]
      let exteriorColorAttribute = this.inventory.attributes.filter((x) => x.name === 'ExteriorColor')[0]
      let interiorColorAttribute = this.inventory.attributes.filter((x) => x.name === 'InteriorColor')[0]
      let alternateExteriorColorsAttribute = this.inventory.attributes.filter((x) => x.name === 'AlternateExteriorColors')[0]
      let alternateInteriorColorsAttribute = this.inventory.attributes.filter((x) => x.name === 'AlternateInteriorColors')[0]
      let searchTypeAttribute = this.inventory.attributes.filter((x) => x.name === 'SearchType')[0]
      let searchTagsAttribute = this.inventory.attributes.filter((x) => x.name === 'SearchTags')[0]
      let searchNotesAttribute = this.inventory.attributes.filter((x) => x.name === 'SearchNotes')[0]
      let damageNotesAttribute = this.inventory.attributes.filter((x) => x.name === 'DamageNotes')[0]

      this.attribute = {
        make: this.inventory.vehicleMakeName,
        model: this.inventory.vehicleModelName || (inventoryModelAttribute ? inventoryModelAttribute.value : ''),
        exteriorColor: exteriorColorAttribute ? exteriorColorAttribute.value : '',
        interiorColor: interiorColorAttribute ? interiorColorAttribute.value : '',
        alternateExteriorColors: alternateExteriorColorsAttribute ? alternateExteriorColorsAttribute.value : '',
        alternateInteriorColors: alternateInteriorColorsAttribute ? alternateInteriorColorsAttribute.value : '',
        style: styleAttribute ? styleAttribute.value : '',
        trim: trimAttribute ? trimAttribute.value : '',
        productionYear: productionYearAttribute ? productionYearAttribute.value : '',
        searchType: searchTypeAttribute ? searchTypeAttribute.value : '',
        searchTags: searchTagsAttribute ? searchTagsAttribute.value : '',
        searchNotes: searchNotesAttribute ? searchNotesAttribute.value : '',
        damageNotes: damageNotesAttribute ? damageNotesAttribute.value : ''
      }
    }
  },
  watch: {
    inventory: function (id) {
      this.loadAttributes()
    }
  },
  mounted: function () {
    this.loadAttributes()
  }
}
</script>

<style scoped>
  .column {
    padding-bottom:0.3em
  }
</style>

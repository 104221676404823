<template>
  <div class="pod tile is-parent is-narrow" v-observe-visibility="visibilityChanged">
    <article class="tile is-child box is-paddingless">
      <h4 class="has-text-weight-bold clickable" @click="onTitleClick">Suppliers For Review ({{total}})<i @click.stop="onMoreClick" class="fas fa-ellipsis-v icon-more"></i><i style="display: inline-block;" class="fas fa-sync" :class="{'fa-spin': isLoading}" @click.stop="onRefresh"></i>
      </h4>
      <div class="pod-content scrollbar">
        <table class="table is-fullwidth is-striped">
          <thead>
            <tr>
              <th class="is-uppercase no-wrap-table-cell" :class="{'is-current-sort': sortField === column.id}" v-for="column in supplierData.columns.filter((x) => x.visible)" :key="column.name" :style="{ 'max-width': column.maxWidth && sortField !== column.id  ? column.maxWidth : 'unset' }">
                {{column.name}}
                <span class="icon sort-icon"><i class="mdi" :class="{'mdi-arrow-up': sortDirection === 'asc', 'mdi-arrow-down': sortDirection === 'desc'}" v-if="sortField === column.id"></i></span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr class="clickable" v-for="(item, index) in supplierData.data" :key="item.id" @click="onSupplierClick(item)">
              <td v-for="column in supplierData.columns.filter((x) => x.visible)" :key="column.name + index.toString()" :class="getApplicableStyles(column)" :style="{ 'max-width': column.maxWidth && sortField !== column.id  ? column.maxWidth : 'unset' }">
                <template>{{item[column.id]}}</template>
              </td>
            </tr>
            <tr v-if="this.supplierData.data.length === 0" class="subtitle is-7">
              <td style="text-align:center; height:100px;vertical-align:middle;" :colspan="supplierData.columns.length">No data available</td>
            </tr>
          </tbody>
        </table>
      </div>
    </article>
  </div>
</template>

<script>
import axios from 'axios'
import supplierService from '@/services/supplierService'
import { createNamespacedHelpers } from 'vuex'
import formattingMixin from '@/mixins/inventory/formatting'
import eventBus from '@/eventBus'
const { mapGetters } = createNamespacedHelpers('user')
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'SupplierReviewPod',
  mixins: [formattingMixin],
  components: {
  },
  props: {
    sortField: {
      type: String,
      default: ''
    },
    sortDirection: {
      type: String,
      default: 'asc'
    },
    searchParameters: {
      type: Object,
      default: function () {
        return {
          query: '',
          selectedStartDate: null,
          selectedEndDate: null
        }
      }
    }
  },
  data () {
    let defaultSearchCriteria = {
      perPage: 32767,
      sortField: 'name',
      sortOrder: 'asc',
      defaultSortOrder: 'asc',
      tabIndex: 0,
      showIncomplete: false,
      showInactive: false,
      showBlocked: false,
      showVerified: false,
      showUnverified: false,
      showForReview: true,
      query: '',
      page: 1
    }
    return {
      supplierData: {
        columns: [
          {
            id: 'id',
            name: 'Id',
            visible: false
          },
          {
            id: 'name',
            name: 'Name',
            visible: true,
            maxWidth: '80px'
          },
          {
            id: 'email',
            name: 'Primary Email',
            visible: true
          },
          {
            id: 'office',
            name: 'Office',
            visible: true
          }
        ],
        data: []
      },
      supplierSearchCriteria: defaultSearchCriteria,
      query: this.searchParameters.query,
      isLoading: false,
      total: 0
    }
  },
  computed: {
    ...mapGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer'])
  },
  methods: {
    getApplicableStyles: function (columnDefinition) {
      var result = ''
      if (columnDefinition.format && columnDefinition.format === 'status') {
        result = 'no-wrap-table-cell'
      }
      let stylesToAttach = {}
      stylesToAttach[result] = true
      return stylesToAttach
    },
    onSupplierClick: function (item) {
      if (item.id && item.id !== -1 && this.hasFeatureAccess('supplier.view')) {
        this.$router.push({name: 'SuppliersQuickView', params: { id: item.id }})
      }
    },
    onMoreClick: function () {

    },
    onRefresh: function () {
      this.loadData()
    },
    onTitleClick: function () {
      if (this.hasFeatureAccess('supplier.view')) {
        this.$router.push({name: 'ListSuppliers'})
      }
    },
    loadData: function () {
      this.isLoading = true
      this.supplierSearchCriteria.query = this.query
      let criteria = this.getSearchCriteria()
      supplierService.all(criteria).then(response => {
        this.supplierData.data = []
        let currentTotal = response.totalAvailable
        if (response.totalAvailable / this.perPage > 1000) {
          currentTotal = this.perPage * 1000
        }
        this.total = currentTotal
        response.results.forEach((item) => {
          this.supplierData.data.push(item)
        })
        this.isLoading = false
      }).catch((error) => {
        this.supplierData.data = []
        this.total = 0
        this.isLoading = false
        this.serverErrorMessage = error.message
        throw error
      })
    },
    onPodRefreshRequired: function () {
      this.loadData()
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        if (this.currentDealer.id) {
          this.loadData()
        }
      }
    },
    onPodSearchUpdate: function (data) {
      this.query = data.searchParameters.query
      this.loadData()
    },
    getSearchCriteria: function () {
      return {
        dealerId: this.currentDealer.id,
        pageSize: this.supplierSearchCriteria.perPage,
        tabIndex: this.supplierSearchCriteria.tabIndex,
        showIncomplete: this.supplierSearchCriteria.showIncomplete,
        showInactive: this.supplierSearchCriteria.showInactive,
        showBlocked: this.supplierSearchCriteria.showBlocked,
        showVerified: this.supplierSearchCriteria.showVerified,
        showUnverified: this.supplierSearchCriteria.showUnverified,
        showForReview: this.supplierSearchCriteria.showForReview,
        sortBy: this.supplierSearchCriteria.sortField + '.' + this.supplierSearchCriteria.sortOrder,
        query: this.query,
        page: this.supplierSearchCriteria.page - 1,
        supplierType: '-1'
      }
    }
  },
  mounted () {
    eventBus.$on('pod-search-update', this.onPodSearchUpdate)
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.loadData()
      }
    }
  },
  beforeDestroy () {
    axios.cancel(this.requestId)
    eventBus.$off('pod-search-update', this.onPodSearchUpdate)
  }
}
</script>

<style scoped>
  .no-wrap-table-cell {
    white-space: nowrap;
  }

  .icon {
    width: 1.0rem !important;
  }

  .sort-icon {
    height:auto !important;
  }

  .is-current-sort {
    color: rgb(0, 177, 31) !important;
  }

  .list-icon {
    margin-right: 0px;
  }

</style>

import axios from 'axios'
import baseService from './baseService'
import productData from './data/product'

const appService = {
  save (productModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var product = {
          id: 999,
          name: productModel.name,
          description: productModel.description,
          isFinanceProduct: productModel.isFinanceProduct,
          price: productModel.price,
          status: 'Active'
        }
        productData.all.push(product)
        resolve(product)
      } else {
        axios
          .post('/product/save', productModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  findAllByDealerId (dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(productData.all)
      } else {
        axios
          .get('/DealerTagNumber/FindAllByDealerId?dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService

import axios from 'axios'
import baseService from './baseService'
import tagData from './data/tag'

const tagService = {
  allTags (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(tagData.all)
      } else {
        axios
          .get('/tag/list?', baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(tagData)
      } else {
        axios
          .post('/tag/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (tagId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(tagData.all.find((x) => x.id === tagId))
      } else {
        axios
          .post('/tag/get?id=' + tagId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (tagModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var tag = {
          id: 999,
          name: tagModel.name,
          status: 'Active'
        }
        tagData.all.push(tag)
        resolve(tag)
      } else {
        axios
          .post('/tag/save', tagModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (tagModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var tag = tagData.all.find((x) => x.id === tagModel.id)
        if (tag) {
          tag.name = tagModel.name
          tag.status = tagModel.status
        }
        resolve(tag)
      } else {
        axios
          .post('/tag/update', tagModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (tagModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        tagModels.forEach(element => {
          tagData.all.remove((x) => x.id === element.id)
        })
        resolve(tagData.all)
      } else {
        var ids = []
        tagModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/tag/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default tagService

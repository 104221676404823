<template>
  <div class="box">
    <div v-if="!value.isPrimary && !this.isPrimaryNotificationHidden && this.selectedDealType.altName !== 'Cancelled'" class="notification is-warning">
      <button class="delete" type="button" @click="isPrimaryNotificationHidden = true"></button>
      <span>
        <i class="fas fa-exclamation-triangle is-warning"></i>
      </span>
        Warning: Stock# <a @click.stop.prevent="onStockNoClick(value.forInventory)"><span class="has-text-weight-semibold">{{( value.forInventory) ? value.forInventory.stockNo : '' }}</span></a> is sold to {{ primaryDealBuyerName }} by {{ primaryDealSalesPersonName }}.
        Please note that this is a backup deal for this inventory record. Click <a @click="inventoryDealIsPrimaryActive = true">here</a> to make this the primary deal.
    </div>
    <div hidden="true" v-else-if="!this.isPrimaryNotificationHidden && selectedInventory && value.isPrimary" class="notification is-success">
      <button class="delete" type="button" @click="isPrimaryNotificationHidden = true"></button>
      <span>
        <i class="fas fa-check-circle is-success"></i>
      </span>
      This is the primary deal for this inventory record.
    </div>
    <div class="columns">
      <div class="column is-6">
        <div>
          <label class="label" v-if="showLeadInventorySelector">Select inventory from<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
          <b-field v-if="showLeadInventorySelector">
            <b-radio-button
              v-model="inventorySelectionSource"
              native-value="Inventory"
              name="inventorySelectionSource"
              type="is-info"
              :required="true">
              <span>Inventory</span>
            </b-radio-button>
            <b-radio-button
              v-model="inventorySelectionSource"
              native-value="Lead"
              name="inventorySelectionSource"
              type="is-info"
              :required="true">
              <span>Customer vehicle interests</span>
            </b-radio-button>
          </b-field>
          <inventory-selector
            v-if="inventorySelectionSource === 'Lead'"
            :required="true"
            v-model="selectedInventory"
            :selectedDealer="this.selectedDealer"
            :isInventorySelectorDisabled="leadInventorySelectorDisabled"
            :placeholder="'Select inventory from customer vehicle interests'"
            :defaultVehicleList="customerLeadVehicles"
            :filteredTypes="allowedDealSoldInventoryTypes"
            :filteredStatuses="allowedInventoryStatuses"
            :showInstockOnly="false"
            :searchable="false"
            :soldStatus="[0]">
          </inventory-selector>
          <inventory-selector
            v-show="inventorySelectionSource === 'Inventory' && showInventorySelector"
            :required="showInventorySelector"
            v-model="selectedInventory"
            :selectedDealer="this.selectedDealer"
            :useValidator="this.$validator"
            validateAs="inventory"
            :createDefaultPossession="defaultInventoryPossessionStatus"
            :createDefaultSellerType="defaultInventorySellerType"
            :isInventorySelectorDisabled="false"
            :showInboundOnly="showInboundOnly"
            :filteredTypes="allowedDealSoldInventoryTypes"
            :filteredStatuses="allowedInventoryStatuses"
            :soldStatus="[0]">
          </inventory-selector>
          <make-model-selector
            :label="makeModelSelectorTitle"
            v-show="showMakeModelSelector"
            data-vv-as="selected vehicle"
            name="selectedMakeModel"
            :v-validate="{'required': showMakeModelSelector}"
            v-model="selectedMakeModel"
            :stockType="selectedStockType"
            :forceColorSelection="selectedStockType && selectedStockType.name !== 'Order'"
            :showNotes="showNotes"
            :showTags="showTags"
            :showAlternateColors="showAlternateColors"
            :showSourceSelector="showSourceSelector"
            :showVinSelector="showVinSelector"
            :showCostSelector="selectedStockType && selectedStockType.name !== 'Order'"
            :showAskingPriceSelector="true"
            :showTypeSelector="true"
            :showMileageSelector="localLocateVehicleIsFound"
            :captureTitleStatus="localLocateVehicleIsFound"
            :isLocated="localLocateVehicleIsFound"
            :forceVinCollection="localLocateVehicleIsFound"
            :canCancelDialog="true"
            :purchasedFromType="selectedPurchaseType"
            :inventoryType="selectedTypeStatus">
          </make-model-selector>
          <span v-show="errors.has('selectedMakeModel')" class="help is-danger"><i v-show="errors.has('selectedMakeModel')" class="fas fa-exclamation-triangle"></i> {{ errors.first('selectedMakeModel') }}</span>
        </div>
        <div class="field">
          <label class="label">Sale Price<b-icon v-if="isSalePriceRequired" pack="fas" icon="star" class="fa-ss"></b-icon></label>
          <div class="control has-icons-left">
            <money v-if="isSalePriceRequired" name="salePrice" data-vv-as="sale price" v-validate="'required|decimal:2|min_value:0.01'" :class="{'input': true, 'is-danger': errors.has('salePrice') }" type="text" placeholder="0000.00" v-model="value.salePrice" v-bind="$globalMoneyFormat"></money>
            <money v-else name="salePrice" data-vv-as="sale price" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('salePrice') }" type="text" placeholder="0000.00" v-model="value.salePrice" v-bind="$globalMoneyFormat"></money>
            <span v-show="errors.has('salePrice')" class="help is-danger">
              <i v-show="errors.has('salePrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('salePrice') }}
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Front GP<b-icon v-if="isGrossRequired" pack="fas" icon="star" class="fa-ss"></b-icon></label>
          <div class="control has-icons-left">
            <money :disabled="true" v-if="isGrossRequired" name="frontValue" data-vv-as="front gp" v-validate="'required|decimal:2'" :class="{'input': true, 'is-danger': errors.has('frontValue') }" type="text" placeholder="0000.00" v-model="value.frontValue" v-bind="$globalMoneyFormat"></money>
            <money :disabled="isGrossDisabled" v-else name="frontValue" data-vv-as="front gp" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('frontValue') }" type="text" placeholder="0000.00" v-model="value.frontValue" v-bind="$globalMoneyFormat"></money>
            <span v-show="errors.has('frontValue')" class="help is-danger">
              <i v-show="errors.has('frontValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('frontValue') }}
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
          <!--
          <label class="label-frontgp is-pulled-right" :class="{'has-text-danger': round(value.frontValue) !== round(calculatedFrontGross), 'has-text-success': round(value.frontValue) === round(calculatedFrontGross)}"
            @click="value.frontValue = calculatedFrontGross" title="This is the expected value for front GP. Click here to make this the front GP value.">{{calculatedFrontGross | currency}}
          </label>
          -->
        </div>
        <br/>
        <div class="field" v-if="isNewVehicleType">
          <label class="label">Holdback</label>
          <div class="control has-icons-left">
            <money name="holdValue" style="width:88%" data-vv-as="holdback" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('holdValue') }" type="text" placeholder="0000.00" v-model="value.holdValue" :disabled="isHoldbackEditable === false" v-bind="$globalMoneyFormat"></money>
            <span class="control" v-if="hasFeatureAccess('deal.modify.holdback', false)">
              <a class="button" v-if="isLocked" @click="onUnlock" title="Click to unlock field for editing">
                <i class="fal fa-unlock fa-sm"></i>
              </a>
              <a class="button" @click="onLock" title="Click to re-lock opened field without saving" v-else>
                <i class="fal fa-lock fa-sm"></i>
              </a>
            </span>
            <span v-show="errors.has('holdValue')" class="help is-danger">
              <i v-show="errors.has('holdValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('holdValue') }}
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
          <label class="label-frontgp is-pulled-right" :class="{'has-text-danger': round(value.holdValue) !== round(inventoryHoldValue), 'has-text-success': round(value.holdValue) === round(inventoryHoldValue)}"
            @click="onSetHoldBack()" title="This is the holdback value for this model. Click here to make this the holdback value for this deal.">{{inventoryHoldValue | currency}}
          </label>
        </div>
        <br>
        <div class="field">
          <label class="label">Spiff</label>
          <div class="control has-icons-left">
            <money name="spiff" data-vv-as="spiff" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('spiff') }" type="text" placeholder="0000.00" v-model="value.spiff" v-bind="$globalMoneyFormat"></money>
            <span v-show="errors.has('spiff')" class="help is-danger">
              <i v-show="errors.has('spiff')" class="fas fa-exclamation-triangle"></i> {{ errors.first('spiff') }}
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Line Comment</label>
          <div class="control has-icons-left has-icons-right">
            <textarea name="lineComment" data-vv-as="line comment" :class="{'textarea': true, 'is-danger': errors.has('lineComment') }" rows="1" v-model="value.lineComment"></textarea>
            <span v-show="errors.has('lineComment')" class="help is-danger">
                <i v-show="errors.has('lineComment')" class="fas fa-exclamation-triangle"></i> {{ errors.first('lineComment') }}
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Pickup Location</label>
            <div class="control">
              <vue-google-autocomplete ref="googleLocation" :value="(this.value && this.value.forInventory) ? this.value.forInventory.pickupLocation : ''" :id="instanceName + 'map'" classname="input" placeholder="Type to fill in pickup location" :enable-geolocation="true" autocomplete="disabled" v-on:placechanged="getAddressData"></vue-google-autocomplete>
            </div>
        </div>
        <div class="field">
          <label class="label">Total MSRP</label>
            <div class="is-size-7">
              {{this.value.forInventory && this.value.forInventory.msrp ? this.value.forInventory.msrp : 0.00 | currency}}
            </div>
        </div>
        <div class="field">
          <label class="label">Base MSRP</label>
            <div class="is-size-7">
              {{this.firstSoldItemVehicleMakeModel && this.firstSoldItemVehicleMakeModel.msrp ? this.firstSoldItemVehicleMakeModel.msrp : 0.00 | currency}}
            </div>
        </div>
    </div>
    <div class="column is-6">
        <button-list-selector validateAs="sale type" label="Sale Type" :required="true" v-model="selectedVehicleType" :availableValues="availableVehicleTypes"></button-list-selector>
        <div class="field" v-if="!isNewVehicleType">
          <label class="label">Mfg Bonus</label>
          <div class="control has-icons-left">
            <money name="mvpValue" data-vv-as="manufacturer bonus" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('mvpValue')}" type="text" placeholder="0000.00" v-model="value.mvpValue" :disabled="!hasFeatureAccess('deal.modify.holdback', false)" v-bind="$globalMoneyFormat"></money>
            <span v-show="errors.has('mvpValue')" class="help is-danger">
              <i v-show="errors.has('mvpValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('mvpValue') }}
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Ext. Warranty GP</label>
          <div class="control has-icons-left">
            <money name="warValue" data-vv-as="ext. warranty gp" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('warValue') }" type="text" placeholder="0000.00" v-model="value.warValue" v-bind="$globalMoneyFormat"></money>
            <span v-show="errors.has('warValue')" class="help is-danger">
              <i v-show="errors.has('warValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('warValue') }}
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
        </div>
        <div class="field">
          <label class="label">Finance Reserve</label>
          <div class="control has-icons-left">
            <money name="finResValue" data-vv-as="finance reserve" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('finResValue') }" type="text" placeholder="0000.00" v-model="value.finResValue" v-bind="$globalMoneyFormat"></money>
            <span v-show="errors.has('finResValue')" class="help is-danger">
              <i v-show="errors.has('finResValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('finResValue') }}
            </span>
            <span class="icon is-small is-left">
              <i class="fas fa-dollar-sign"></i>
            </span>
          </div>
        </div>
        <div v-if="selectedInventory && isNewVehicleType || selectedInventory && allowRdrOnCpo && isCpoVehicleType">
          <switch-selector type="is-info" label="Rdr Complete" v-model="selectedInventory.rdrComplete"></switch-selector>
          <div class="field">
            <label class="label">Rdr Completed Date<b-icon v-if="selectedInventory.rdrComplete" pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <el-date-picker
              name="rdrCompletedAt"
              v-model="selectedInventory.rdrCompletedAt"
              type="date"
              placeholder="Click to select..."
              v-validate="{'required': selectedInventory.rdrComplete}"
              format="MM-dd-yyyy">
            </el-date-picker>
            <span v-show="errors.has('rdrCompletedAt')" class="help is-danger">
              <i v-show="errors.has('rdrCompletedAt')" class="fas fa-exclamation-triangle"></i> {{ errors.first('rdrCompletedAt') }}
            </span>
          </div>
        </div>
        <br/>
        <div class="field">
          <div class="control">
            <label class="label">Front GP Calculation</label>
            <table class="table is-pulled-right" style="margin-top: -10px">
              <tr>
                <th>Item</th>
                <th>CDK</th>
                <th>LIVE</th>
              </tr>
              <tr>
                <td>Purchase Cost</td>
                <td>{{(value.forInventory && value.forInventory.purchaseCostTotal) ? value.forInventory.purchaseCostTotal : 0.00 | currency}}</td>
                <td>{{(value.forInventory && value.forInventory.purchaseCostTotal) ? value.forInventory.purchaseCostTotal : 0.00 | currency}}</td>
              </tr>
              <tr>
                <td>Recon</td>
                <td>{{calculatedReconCDK | currency}}</td>
                <td>{{calculatedReconLive | currency}}</td>
              </tr>
              <tr>
                <td>Inventory Additional Costs</td>
                <td>{{inventoryAdditionalCostsTotal | currency}}</td>
                <td>{{inventoryAdditionalCostsTotal | currency}}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">GL Balance (subtotal)</td>
                <td class="has-text-weight-bold">{{frontGrossSubtotalCDK | currency}}</td>
                <td class="has-text-weight-bold">{{frontGrossSubtotalLive | currency}}</td>
              </tr>
                <tr>
                <td>Sale Price</td>
                <td>{{value.salePrice ? value.salePrice  : 0.00 | currency}}</td>
                <td>{{value.salePrice ? value.salePrice  : 0.00 | currency}}</td>
              </tr>
              <tr>
                <td>Acv</td>
                <td>{{tradeAcvDifference ? tradeAcvDifference : 0.00 | currency}}</td>
                <td>{{tradeAcvDifference ? tradeAcvDifference : 0.00 | currency}}</td>
              </tr>
              <tr>
                <td>Fees</td>
                <td>{{additionalCostsGP | currency}}</td>
                <td>{{additionalCostsGP | currency}}</td>
              </tr>
              <tr>
                <td class="has-text-weight-bold">Total Front GP</td>
                <td class="has-text-weight-bold">{{calculatedFrontGrossCDK | currency}}</td>
                <td class="has-text-weight-bold">{{calculatedFrontGross | currency}}</td>
              </tr>
            </table>
          </div>
        </div>
    </div>
    </div>
      <switch-selector type="is-info" label="Any Supporting Inventory Sold Documentation?" v-model="hasInventorySoldAttachments"></switch-selector>
    <div class="columns deal-attachments" v-if="hasInventorySoldAttachments">
      <image-selector
        v-model="inventorySoldPhotoFiles"
        label="Photos"
        :isBoxed="false"
        @uploadStarted="uploadStarted"
        @uploadComplete="uploadComplete">
      </image-selector>
    </div>
    <div class="columns deal-attachments" v-if="hasInventorySoldAttachments">
      <pdf-selector
        v-model="inventorySoldPdfFiles"
        label="Pdf"
        :required="false"
        :isBoxed="false"
        @uploadStarted="uploadStarted"
        @uploadComplete="uploadComplete">
      </pdf-selector>
    </div>

    <inventory-supplemental-cost-component
      :isCollapsed="true"
      v-if="showCostsSection"
      v-on:inventory-costs-updated="value.inventoryCostsUpdated = true"
      v-model="supplementalCosts"
      :key="value.forInventory.id"
      :selectedInventory="value.forInventory"
      :selectedInventoryId="value.forInventory.id"
      :selectedMakeModel="selectedMakeModel"
      :selectedTypeStatus="selectedTypeStatus"
      :selectedDispositionIntentionStatus="selectedDispositionIntentionStatus"
      dispositionSource="Deal">
    </inventory-supplemental-cost-component>

    <div id="product-section" class="box">
      <section-header title="Inventory Products" parentSelector="#product-section" :collapsed="products.length == 0"></section-header>
      <div class="columns">
        <div class="column is-12">
          <b-table :data="this.products" class="table">
            <template slot-scope="props">
                <b-table-column field="name" label="Name" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                  {{ props.row.name }}
                </b-table-column>

                <b-table-column field="description" label="Description" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                  {{ props.row.description }}
                </b-table-column>

                <b-table-column field="productCount" label="Product Count" :class="{'striked': props.row.delete === true && props.row.id > 0}">
                  {{ props.row.productCount }}
                </b-table-column>

                <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                  {{ props.row.type }}
                </b-table-column>

                <b-table-column field="cost" label="Cost" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                  {{ props.row.cost | currency }}
                </b-table-column>

                <b-table-column field="sellingPrice" label="Selling Price" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                  {{ props.row.sellingPrice | currency }}
                </b-table-column>

                <b-table-column field="" label="Actions">
                  <span class="icon" @click="editProduct(props.row, props.index)" v-if="props.row.delete !== true">
                    <i class="fal fa-pencil"></i>
                  </span>
                  <span class="icon" @click="removeProduct(props.index)" v-if="props.row.delete !== true">
                    <i class="fal fa-minus-circle"></i>
                  </span>
                  <span class="is-size-7 has-text-danger" v-if="props.row.delete">
                    Pending Delete
                  </span>
                </b-table-column>
            </template>
            <template slot="empty">
              <section class="section">
                <div class="has-text-centered">
                  <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                  <p>No products have been added.</p>
                </div>
              </section>
            </template>
            <template slot="footer" v-if="availableProducts.length > 0">
              <th colspan="4"></th>
              <th colspan="1"></th>
              <th colspan="1"><label class="label">{{productsTotal | currency}}</label></th>
              <th colspan="1"></th>
            </template>
          </b-table>
        </div>
      </div>
      <product-cost-capture :value="this.newProduct" :productCosts="this.products"></product-cost-capture>
      <div class="inner-add-remove-button">
        <a class="button is-primary" @click="addProduct()">
          <span class="icon">
            <i class="fas fa-plus"></i>
          </span>
        </a>
      </div>
    </div>
    <b-modal :active.sync="inventoryDealWarningActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Warning</p>
        </header>
        <section class="modal-card-body">
            There is already an active pending deal for the selected inventory item. Your deal will be automatically marked as secondary.
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="onConfirmInventoryDealWarning()" type="button">Confirm</button>
        </footer>
      </div>
    </b-modal>
    <b-modal :active.sync="inventoryDealEligibleActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Warning</p>
        </header>
        <section class="modal-card-body">
          Do you want to sell this CPO or Used?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="onConfirmInventoryDealCpo()" type="button">Cpo</button>
          <button class="button is-danger" @click="onConfirmInventoryDealUsed()" type="button">Used</button>
        </footer>
      </div>
    </b-modal>
    <b-modal :active.sync="inventoryDealIsPrimaryActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Warning</p>
        </header>
        <section class="modal-card-body">
          Do you want to make this deal the primary deal for this inventory record?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmInventoryDealIsPrimary(true)" type="button">Yes</button>
          <button class="button is-danger" @click="onConfirmInventoryDealIsPrimary(false)" type="button">No</button>
        </footer>
      </div>
    </b-modal>
    <b-modal :active.sync="spiffActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
            <p class="modal-card-title">Warning</p>
        </header>
        <section class="modal-card-body">
          Do you want the spiff to apply?
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmSpiff(true)" type="button">Yes</button>
          <p class="control"><a class="button is-danger" @click="onConfirmSpiff(false)">No</a></p>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import ImageSelector from '@/components/generic/ImageSelector'
import PdfSelector from '@/components/generic/PdfSelector'
import InventorySelector from '@/components/generic/InventorySelector'
import productService from '@/services/productService'
import MakeModelSelector from '@/components/generic/MakeModelSelector'
import dealService from '@/services/dealService'
import ProductCostCapture from '@/components/generic/sales/ProductCostCapture'
import SectionHeader from '@/components/generic/SectionHeader'
import InventorySupplementalCostComponent from '@/components/generic/inventory/InventorySupplementalCostComponent'
import utilitiesMixin from '@/mixins/generic/utilities'
import SwitchSelector from '@/components/generic/SwitchSelector'
import moment from 'moment'
import VueGoogleAutocomplete from 'vue-google-autocomplete'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapVehicleMakeGetters = createNamespacedHelpers('vehicleMake').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'SoldInventoryCapture',
  mixins: [utilitiesMixin, humanizedSwitchMixin],
  components: {
    'button-list-selector': ButtonListSelector,
    'inventory-selector': InventorySelector,
    'image-selector': ImageSelector,
    'pdf-selector': PdfSelector,
    'make-model-selector': MakeModelSelector,
    'product-cost-capture': ProductCostCapture,
    'section-header': SectionHeader,
    'vue-google-autocomplete': VueGoogleAutocomplete,
    'switch-selector': SwitchSelector,
    'inventory-supplemental-cost-component': InventorySupplementalCostComponent
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    selectedDealer: {
      type: Object,
      default: null
    },
    selectedStockType: {
      type: Object,
      default: null
    },
    selectedDealType: {
      type: Object,
      default: null
    },
    vehicleTypes: {
      type: Array,
      default: () => [
        {
          id: 0,
          name: 'New'
        },
        {
          id: 1,
          name: 'Used'
        },
        {
          id: 2,
          name: 'Cpo'
        }
      ]
    },
    selectedSaleType: {
      type: Object,
      default: null
    },
    tradeAcvDifference: {
      type: Number,
      default: null
    },
    locateVehicleIsFound: {
      type: Boolean,
      default: false
    },
    additionalCostsGP: {
      type: Number,
      default: 0
    },
    additionalCostsRecon: {
      type: Number,
      default: 0
    },
    customerLeadVehicles: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      selectedInventory: null,
      selectedVehicleType: null,
      hasDealAttachments: false,
      inventorySoldPhotoFiles: [],
      inventorySoldPdfFiles: [],
      hasInventorySoldAttachments: false,
      inventoryDealWarningActive: false,
      isNewVehicleType: false,
      selectedMakeModel: null,
      isHoldbackEditable: false,
      isLocked: true,
      initialRdr: false,
      inventoryDealEligibleActive: false,
      inventoryDealIsPrimaryActive: false,
      isPrimaryNotificationHidden: false,
      isSaving: false,
      showInboundOnly: false,
      spiffActive: false,
      initialLoadComplete: false,
      products: [],
      newProduct: this.createGenericProduct(),
      localLocateVehicleIsFound: false,
      isCpoVehicleType: false,
      allowRdrOnCpo: false,
      leadInventorySelectorDisabled: false,
      inventorySelectionSource: 'Inventory',
      isUploadingFile: false,
      fileUploadCount: 0,
      supplementalCosts: []
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    showCostsSection: function () {
      return this.value && this.value.forInventory
    },
    additionalCostsCostTotal: function () {
      if (!this.supplementalCosts) {
        return 0
      }
      return this.supplementalCosts.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.cost)
      }, 0)
    },
    humanizedRdrComplete: function () {
      return this.selectedInventory.rdrComplete ? 'Yes' : 'No'
    },
    availableVehicleTypes () {
      if (this.selectedInventory && this.selectedInventory.dispositionIntention === 'WholesaleImmediately') {
        return this.vehicleTypes.filter((x) => { return x.name !== 'Cpo' })
      } else if (this.selectedInventory && this.selectedInventory.type !== 'New' && !this.selectedMakeModel) {
        return this.vehicleTypes.filter((x) => { return x.name !== 'New' })
      } else if (this.selectedStockType && this.selectedStockType.name === 'Order') {
        return this.vehicleTypes.filter((x) => { return x.name === 'New' })
      }
      return this.vehicleTypes
    },
    humanizedHasInventorySoldAttachments: function () {
      return this.hasInventorySoldAttachments ? 'Yes' : 'No'
    },
    ...mapUserGetters(['hasFeatureAccess']),
    ...mapVehicleMakeGetters(['allVehicleMakes']),
    availableVehicleMakes () {
      return this.allVehicleMakes
    }, /*
    productsTotalPrice: function () {
      var productSum = this.value.products.map(x => x.price).reduce((prev, next) => prev + next, 0)
      return productSum
    }, */
    showMakeModelSelector: function () {
      return (this.selectedStockType !== null && (this.selectedStockType.name === 'Locate' || this.selectedStockType.name === 'Order'))
    },
    showInventorySelector: function () {
      return (this.selectedStockType !== null && this.selectedStockType.name !== 'Locate' && this.selectedStockType.name !== 'Order')
    },
    showLeadInventorySelector: function () {
      return this.customerLeadVehicles.length > 0
    },
    defaultInventoryPossessionStatus: function () {
      if (this.selectedDealType) {
        if (this.selectedDealType.altName === 'Ordered') {
          return this.definedTypes.possessionStatuses.find(x => x.id === 1)
        }
        if (this.selectedDealType.altName === 'AwaitingArrival') {
          return this.definedTypes.possessionStatuses.find(x => x.id === 1)
        }
      }
      return null
    },
    defaultInventorySellerType: function () {
      if (this.defaultInventoryPossessionStatus && this.defaultInventoryPossessionStatus.id === 1) {
        return this.definedTypes.allowedSourceTypes.options.find(x => x.id === this.definedTypes.allowedSourceTypes.Factory)
      } else {
        return null
      }
    },
    isSalePriceRequired: function () {
      return this.selectedDealType.altName === 'Booked'
    },
    isGrossRequired: function () {
      return this.selectedDealType.altName === 'Booked'
    },
    primaryDeal: function () {
      return this.value.primaryDealForInventory
    },
    primaryDealSalesPersonName: function () {
      return (this.primaryDeal && this.primaryDeal.salesPersonName) ? this.primaryDeal.salesPersonName : '-'
    },
    primaryDealCustomerName: function () {
      return (this.primaryDeal && this.primaryDeal.customerName) ? this.primaryDeal.customerName : '-'
    },
    isInventorySelectorDisabled: function () {
      return (this.selectedDealType && (this.selectedDealType.altName === 'Ordered' || this.selectedDealType.altName === 'Locate'))
    },
    makeModelSelectorTitle: function () {
      if (this.selectedStockType.name === 'Locate') {
        if (this.localLocateVehicleIsFound) {
          return 'Located Vehicle'
        } else {
          return 'Vehicle to Locate'
        }
      } else {
        return 'Vehicle ordered'
      }
    },
    showNotes: function () {
      if (this.selectedStockType.name === 'Locate') {
        return !this.localLocateVehicleIsFound
      }
      if (this.selectedStockType.name === 'Order') {
        return false
      }
      return false
    },
    showTags: function () {
      if (this.selectedStockType.name === 'Locate') {
        return !this.localLocateVehicleIsFound
      }
      if (this.selectedStockType.name === 'Order') {
        return false
      }
      return false
    },
    showAlternateColors: function () {
      if (this.selectedStockType.name === 'Locate') {
        return !this.localLocateVehicleIsFound
      }
      if (this.selectedStockType.name === 'Order') {
        return false
      }
      return false
    },
    showSourceSelector: function () {
      if (this.selectedStockType.name === 'Locate') {
        return this.localLocateVehicleIsFound
      }
      if (this.selectedStockType.name === 'Order') {
        return true
      }
      return false
    },
    showVinSelector: function () {
      if (this.selectedStockType.name === 'Locate') {
        return this.localLocateVehicleIsFound
      }
      if (this.selectedStockType.name === 'Order') {
        return true
      }
      return false
    },
    productsTotal: function () {
      if (!this.products) {
        return 0
      }
      return this.products.reduce(function (prev, cur) {
        return prev + (cur.delete === true ? +0 : +cur.sellingPrice)
      }, 0)
    },
    availableProducts: function () {
      return this.products.filter(x => !x.delete)
    },
    isNewDealItem: function () {
      return this.value.id === 0
    },
    calculatedFrontGross: function () {
      let reconCost = this.calculatedReconLive
      let purchaseCostTotal = 0
      let tradeAcvDifference = this.tradeAcvDifference || 0.00
      if (this.selectedInventory && this.selectedInventory.purchaseCostTotal) {
        purchaseCostTotal = this.selectedInventory.purchaseCostTotal
      }
      let actualInventoryCost = purchaseCostTotal + reconCost + tradeAcvDifference + this.inventoryAdditionalCostsTotal
      let result = this.value.salePrice + this.additionalCostsGP - (actualInventoryCost)
      return this.round2Digits(result)
    },
    salePriceWatcher: function () {
      return this.value.salePrice
    },
    primaryDealBuyerName: function () {
      return (this.primaryDeal && this.primaryDeal.buyerName) ? this.primaryDeal.buyerName : '-'
    },
    inventoryHoldValue: function () {
      let inventoryItem = this.selectedInventory
      if (inventoryItem) {
        var vehicleMake = this.allVehicleMakes.find((x) => x.id === (inventoryItem.makeId || inventoryItem.vehicleMakeId))
        if (vehicleMake !== undefined) {
          var vehicleMakeModel = vehicleMake.versions.find((x) => x.id === (inventoryItem.modelId || inventoryItem.vehicleMakeVersionId))
          if (vehicleMakeModel !== undefined) {
            let msrpToUse = inventoryItem.msrp
            let msrpDeductions = this.defaultMsrpDeductions ? this.defaultMsrpDeductions : 0
            msrpToUse = (vehicleMake.holdbackValueCalculation && vehicleMake.holdbackValueCalculation === 'BaseMsrp') ? vehicleMakeModel.msrp : msrpToUse
            if (msrpToUse && msrpToUse !== 0 && vehicleMakeModel.holdBackPercentage !== null) {
              return (msrpToUse - msrpDeductions) * vehicleMakeModel.holdBackPercentage / 100
            } else {
              return vehicleMakeModel.holdValue - msrpDeductions
            }
          }
        }
      }
      return 0.00
    },
    calculatedReconCDK: function () {
      let purchaseCostTotal = 0.00
      let generalLedgerBalance = 0.00
      if (this.value.forInventory) {
        if (this.value.forInventory.purchaseCostTotal) {
          purchaseCostTotal = this.value.forInventory.purchaseCostTotal
        }
        if (this.value.forInventory.generalLedgerBalance) {
          generalLedgerBalance = this.value.forInventory.generalLedgerBalance
        }
      }
      let result = generalLedgerBalance - purchaseCostTotal - this.inventoryAdditionalCostsTotal
      return this.round2Digits(result)
    },
    calculatedReconLive: function () {
      let result = 0.00
      /*
      if (this.selectedInventory && this.selectedInventory.reconCost) {
        result = this.selectedInventory.reconCost + -(this.additionalCostsRecon)
      } else {
        result = -(this.additionalCostsRecon)
      }
      */
      if (this.selectedInventory && this.selectedInventory.reconCost) {
        result = this.selectedInventory.reconCost
      }
      return this.round2Digits(result)
    },
    frontGrossSubtotalLive: function () {
      let purchaseCostTotal = 0.00
      if (this.value.forInventory && this.value.forInventory.purchaseCostTotal) {
        purchaseCostTotal = this.value.forInventory.purchaseCostTotal
      }
      let result = purchaseCostTotal + this.calculatedReconLive + this.inventoryAdditionalCostsTotal
      return this.round2Digits(result)
    },
    frontGrossSubtotalCDK: function () {
      let result = (this.value.forInventory) ? (this.value.forInventory.generalLedgerBalance) ? this.value.forInventory.generalLedgerBalance : 0.00 : 0.00
      return this.round2Digits(result)
    },
    calculatedFrontGrossCDK: function () {
      let tradeAcvDifference = this.tradeAcvDifference || 0.00
      let actualInventoryCost = this.frontGrossSubtotalCDK + tradeAcvDifference
      let result = this.value.salePrice + this.additionalCostsGP - (actualInventoryCost)
      return this.round2Digits(result)
    },
    selectedTypeStatus: function () {
      if (this.selectedInventory) {
        return this.definedTypes.inventoryTypes.find((x) => (x.name === this.selectedInventory.type || x.altName === this.selectedInventory.type))
      }
      if (this.selectedStockType && this.selectedStockType.name === 'Order') {
        return this.definedTypes.inventoryTypes.find((x) => (x.name === 'New' || x.altName === 'New'))
      }
      return null
    },
    selectedPurchaseType: function () {
      if (this.selectedInventory) {
        return this.selectedInventory.purchasedFromType
      }
      return ''
    },
    allowedDealSoldInventoryTypes: function () {
      return [0, 1, 3, 4]
    },
    allowedInventoryStatuses: function () {
      return [0, 6, 7]
    },
    firstSoldItemVehicleMakeModel: function () {
      let vehicleMakeModel = null
      if (this.selectedInventory) {
        if (this.firstSoldItemVehicleMake) {
          vehicleMakeModel = this.firstSoldItemVehicleMake.versions.find((x) => x.id === (this.selectedInventory.modelId || this.selectedInventory.vehicleMakeVersionId))
        }
      }
      return vehicleMakeModel
    },
    firstSoldItemVehicleMake: function () {
      return this.allVehicleMakes.find((x) => x.id === (this.selectedInventory.makeId || this.selectedInventory.vehicleMakeId))
    },
    defaultMsrpDeductions: function () {
      let defaultMsrpDeductions = this.firstSoldItemVehicleMake && this.firstSoldItemVehicleMake.msrpDeductions ? this.firstSoldItemVehicleMake.msrpDeductions : 0.00
      return this.firstSoldItemVehicleMakeModel && this.firstSoldItemVehicleMakeModel.msrpDeductionsToUse ? this.firstSoldItemVehicleMakeModel.msrpDeductionsToUse : defaultMsrpDeductions
    },
    inventoryAdditionalCostsTotal: function () {
      return this.round2Digits(this.additionalCostsCostTotal)
    },
    selectedDispositionIntentionStatus: function () {
      return this.selectedSaleType
    },
    isGrossDisabled: function () {
      if (this.frontGrossSubtotalCDK < 15000) return false

      if (this.value && this.value.forInventory && this.value.forInventory.purchaseCostTotal) {
        return this.value.forInventory.purchaseCostTotal > 15000
      }
      return false
    }
  },
  methods: {
    onUnlock: function () {
      this.isLocked = false
      this.isHoldbackEditable = true
    },
    onLock: function () {
      this.isLocked = true
      this.isHoldbackEditable = false
    },
    onConfirmInventoryDealWarning: function () {
      this.inventoryDealWarningActive = false
    },
    initValues: function () {
      this.localLocateVehicleIsFound = this.locateVehicleIsFound
      if (this.isNewDealItem) {
        if (this.$store.state.dealer.currentDealer.isWholesaleDealer) {
          this.selectedVehicleType = { id: 1, name: 'Used', altName: 'Used' }
        }
      }

      if (this.value !== null && this.value.config.loadInventoryOnly) {
        this.initialInventoryModel()
        return
      }

      if (this.value !== null) {
        this.selectedVehicleType = this.vehicleTypes.find((x) => x.name === this.value.vehicleType || x.altName === this.value)
        this.initialInventoryModel()
        this.value.lineAttachments.forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation
          if (x.type === 'Image') {
            if (x.dealItemId !== 0) {
              this.inventorySoldPhotoFiles.push(x)
              this.hasInventorySoldAttachments = true
            }
          } else if (x.type === 'Pdf') {
            if (x.dealItemId !== 0) {
              this.inventorySoldPdfFiles.push(x)
              this.hasInventorySoldAttachments = true
            }
          }
        })

        // this.initialRdr = (this.value.forInventory) ? this.value.forInventory.rdrComplete : false
        this.allowRdrOnCpo = this.$store.state.subscriber.currentSubscriber.allowRdrOnCpo
      }
    },
    initialInventoryModel: function () {
      if (!this.value || !this.value.forInventory) {
        return
      }

      this.selectedInventory = this.value.forInventory
      if (this.selectedInventory.attributes) {
        var exteriorColor = this.selectedInventory.attributes.find((x) => x.name === 'ExteriorColor')
        var interiorColor = this.selectedInventory.attributes.find((x) => x.name === 'InteriorColor')
        var vehicleStyle = this.selectedInventory.attributes.find((x) => x.name === 'Style')
        var damageNoteAttribute = this.selectedInventory.attributes.find((x) => x.name === 'DamageNotes')
        var damageNote = damageNoteAttribute ? damageNoteAttribute.value : ''
        var vehicleTrim = this.selectedInventory.attributes.find((x) => x.name === 'Trim')
        var searchType = this.selectedInventory.attributes.find((x) => x.name === 'SearchType')
        var altExteriorColors = this.selectedInventory.attributes.find((x) => x.name === 'AlternateExteriorColors')
        var altInteriorColors = this.selectedInventory.attributes.find((x) => x.name === 'AlternateInteriorColors')
        var searchTags = this.selectedInventory.attributes.find((x) => x.name === 'SearchTags')
        var searchNotes = this.selectedInventory.attributes.find((x) => x.name === 'SearchNotes')
      }

      var purchaseCostTotal = this.selectedInventory.purchaseCostTotal
      var usage = this.selectedInventory.usage
      var askingPrice = this.selectedInventory.askingPrice

      let status = this.selectedInventory.status
      let selectedPossessionStatus = this.definedTypes.possessionStatuses.find((x) => (x.name === this.selectedInventory.possessionStatus || x.altName === this.selectedInventory.possessionStatus))
      var locateVehicleIsFound = true
      if (this.selectedStockType.name === 'Locate') {
        if (status === 'Requested') {
          if (selectedPossessionStatus.name === 'Locating') {
            locateVehicleIsFound = false
          }
        }
      }
      this.localLocateVehicleIsFound = locateVehicleIsFound
      let selectedTitleStatus = this.definedTypes.titleStatusOptions.options.find((x) => (x.name === this.selectedInventory.titleStatus || x.altName === this.selectedInventory.titleStatus))
      let selectedDamageStatus = this.selectedInventory.isDamaged === null ? false : this.selectedInventory.isDamaged
      let warrantyExpirationDate = this.selectedInventory.warrantyExpirationDate ? new Date(this.selectedInventory.warrantyExpirationDate) : null
      let goodUntilDate = this.selectedInventory.goodUntilDate ? new Date(this.selectedInventory.goodUntilDate) : null
      let vinNo = this.selectedInventory.vinNo
      let accNo = this.selectedInventory.accNo
      let payoffAmount = this.selectedInventory.payoffAmount
      let selectedSource = this.selectedInventory.purchasedFromType === null ? null : this.definedTypes.allowedSourceTypes.options.find((x) => (x.name === this.selectedInventory.purchasedFromType || x.altName === this.selectedInventory.purchasedFromType))
      var selectedBank = null
      if (this.selectedInventory.bankId !== null && this.selectedInventory.bankId !== 0) {
        selectedBank = {
          id: this.selectedInventory.bankId,
          name: this.selectedInventory.bankName
        }
      }
      var selectedWholesaler = null
      var selectedCustomer = null
      if (selectedSource && selectedSource.id > this.definedTypes.allowedSourceTypes.Factory) {
        if (selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler) {
          var supplier = {
            id: this.selectedInventory.purchasedFromId,
            name: this.selectedInventory.purchasedFromName
          }
          selectedWholesaler = supplier
        } else {
          var customer = {
            id: this.selectedInventory.purchasedFromId,
            fullName: this.selectedInventory.purchasedFromName
          }
          selectedCustomer = customer
        }
      }
      this.supplementalCosts = []
      this.selectedInventory.supplementalCosts.forEach(function (supplementalCost) {
        this.supplementalCosts.push(supplementalCost)
      }, this)

      let titleFiles = []
      this.selectedInventory.attachments.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        if (x.classType === 'Title') {
          titleFiles.push(x)
        }
      })

      let damageFiles = []
      this.selectedInventory.attachments.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        if (x.classType === 'Damage') {
          damageFiles.push(x)
        }
      })

      var vehicleAge = moment().year() - (+this.selectedInventory.vehicleProductionYear)
      var cpoAllowed = true
      var autoCpoStatus = 'Ineligible'

      if (this.selectedDealer && vehicleAge >= 0 && vehicleAge <= this.selectedDealer.autoCpoAgeLimit) {
        cpoAllowed = true
        autoCpoStatus = 'Cpo'
      } else if (this.selectedDealer && vehicleAge > this.selectedDealer.autoCpoAgeLimit && vehicleAge <= this.selectedDealer.cpoQualifyingAge) {
        cpoAllowed = true
        autoCpoStatus = 'Eligible'
      }

      let selectedMake = this.selectedInventory.vehicleMakeId > 0 ? this.allVehicleMakes.find((x) => x.id === this.selectedInventory.vehicleMakeId) : null
      let selectedModel = selectedMake && selectedMake.versions ? (selectedMake.versions.find((x) => x.id === this.selectedInventory.vehicleMakeVersionId)) : null

      this.selectedMakeModel = {
        exteriorColor: exteriorColor ? exteriorColor.value : '',
        interiorColor: interiorColor ? interiorColor.value : '',
        style: vehicleStyle ? vehicleStyle.value : '',
        makeId: this.selectedInventory.vehicleMakeId,
        modelId: this.selectedInventory.vehicleMakeVersionId,
        productionYear: this.selectedInventory.vehicleProductionYear,
        customMakeName: this.selectedInventory.customMake === '' ? null : this.selectedInventory.customMake,
        customModelName: this.selectedInventory.customModel === '' ? null : this.selectedInventory.customModel,
        customMakeModel: this.selectedInventory.customVehicleMakeVersion,
        trim: vehicleTrim ? vehicleTrim.value : '',
        selectedMake: selectedMake,
        selectedModel: selectedModel,
        vehicleAge: vehicleAge,
        cpoAllowed: cpoAllowed,
        autoCpoStatus: autoCpoStatus,
        purchaseCostTotal: purchaseCostTotal,
        usage: usage,
        askingPrice: askingPrice,
        locateType: (searchType && searchType.value !== '') ? this.definedTypes.allowedLocateTypes.options.find((x) => x.name === searchType.value) : null,
        altExteriorColors: (altExteriorColors && altExteriorColors.value && altExteriorColors.value.length > 0) ? altExteriorColors.value.split(',') : [],
        altInteriorColors: (altInteriorColors && altInteriorColors.value && altInteriorColors.value.length > 0) ? altInteriorColors.value.split(',') : [],
        tags: (searchTags && searchTags.value && searchTags.value.length > 0) ? searchTags.value.split(',') : [],
        notes: searchNotes ? searchNotes.value : '',
        source: selectedSource,
        wholesaler: selectedWholesaler,
        customer: selectedCustomer,
        titleStatus: selectedTitleStatus,
        warrantyExpirationDate: warrantyExpirationDate,
        selectedBank: selectedBank,
        accountNo: accNo,
        payoffAmount: payoffAmount,
        goodUntilDate: goodUntilDate,
        isDamaged: selectedDamageStatus,
        vinNo: vinNo,
        titleFiles: titleFiles,
        damageFiles: damageFiles,
        damageNote: damageNote,
        transmissionType: this.selectedInventory.transmissionType
      }

      this.loadPrimaryDeal()
    },
    loadPrimaryDeal: function () {
      this.isLoading = true
      dealService.byInventory(this.value.forInventory.id).then(response => {
        var currentInventoryDeals = response.results
        // Warn is there is already a pending deal for this item
        if (currentInventoryDeals && currentInventoryDeals.find((x) => x.type !== 'Cancelled' && x.items.find((y) => y.dealId !== this.value.dealId && y.isPrimary))) {
          // this.inventoryDealWarningActive = true
          this.value.isPrimary = false
        } else {
          this.value.isPrimary = true
        }

        // load primary deal for this inventory
        if (currentInventoryDeals && currentInventoryDeals.length > 0) {
          this.value.primaryDealForInventory = currentInventoryDeals.find((x) => x.type !== 'Cancelled' && x.items.find((y) => y.isPrimary) !== null)
        }
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    onConfirmInventoryDealCpo: function () {
      this.selectedVehicleType = {id: 2, name: 'Cpo'}
      this.inventoryDealEligibleActive = false
    },
    onConfirmInventoryDealUsed: function () {
      this.selectedVehicleType = {id: 1, name: 'Used'}
      this.inventoryDealEligibleActive = false
    },
    onConfirmInventoryDealIsPrimary: function (bool) {
      this.value.isPrimary = bool
      this.inventoryDealIsPrimaryActive = false
    },
    updateFrontGross: function () {
      let reconCost = this.calculatedReconLive
      let purchaseCostTotal = 0.00
      let tradeAcvDifference = this.tradeAcvDifference || 0.00
      if (this.selectedInventory && this.selectedInventory.purchaseCostTotal) {
        purchaseCostTotal = this.selectedInventory.purchaseCostTotal
      }
      let actualInventoryCost = purchaseCostTotal + reconCost + tradeAcvDifference
      var fg = this.value.salePrice + this.additionalCostsGP - (actualInventoryCost)
      this.value.frontValue = fg
    },
    onConfirmSpiff: function (bool) {
      if (bool) {
        this.value.spiff = (this.selectedInventory) ? this.selectedInventory.spiff : 0
      }
      this.spiffActive = false
    },
    populateProducts: function () {
      var dealerId = this.$store.state.dealer.currentDealer.id
      this.products = []
      if (this.isNewDealItem) {
        productService.findAllByDealerIdIsDefaultDeal(dealerId).then(response => {
          let currentTotal = response.totalAvailable
          if (response.totalAvailable / this.perPage > 100) {
            currentTotal = this.perPage * 100
          }
          this.total = currentTotal
          response.results.forEach((item) => {
            item.delete = false
            this.products.push(item)
          })
          this.isLoading = false
        }).catch((error) => {
          this.products = []
          this.total = 0
          this.isLoading = false
          this.serverErrorMessage = error.message
          throw error
        })
      } else if (this.value.itemProducts) {
        this.value.itemProducts.forEach(function (product) {
          this.products.push(product)
        }, this)
      }
    },
    addProduct: function () {
      this.newProduct = this.createGenericProduct()
      this.newProduct.isAddingCost = true
    },
    editProduct (product, index) {
      this.newProduct = {
        index: index,
        id: product.id,
        name: product.name,
        description: product.description,
        cost: product.cost,
        sellingPrice: product.sellingPrice,
        dealItemId: product.dealItemId,
        isAddingCost: true,
        type: product.type,
        productCount: product.productCount,
        isEdit: true
      }
    },
    createGenericProduct () {
      return {
        id: 0,
        name: null,
        description: '',
        cost: null,
        sellingPrice: null,
        isAddingCost: false,
        productCount: 0,
        isEdit: false
      }
    },
    removeProduct (index) {
      var item = this.products[index]
      if (item.id === 0 || this.isNewDealItem) {
        this.$delete(this.products, index)
      } else {
        item.delete = true
      }
      var warrantyProducts = this.products.filter((y) => y.type === 'Warranty' && !y.delete)
      this.value.warValue = (warrantyProducts.length > 0) ? warrantyProducts.map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next) : 0.00
    },
    onSetHoldBack () {
      if (this.value.holdValue !== this.inventoryHoldValue) {
        if (this.isLocked) {
          this.failedToast('You need to unlock holdback for editing in order to reset the holdback value!')
        } else {
          this.value.holdValue = this.inventoryHoldValue
        }
      }
    },
    getAddressData: function (addressData, place, id) {
      if (this.$refs && this.$refs.googleLocation) {
        this.value.forInventory.pickupLocation = this.$refs.googleLocation.$el.value
      }
    },
    onStockNoClick: function (inventory) {
      let that = this
      if (inventory) {
        this.$copyText(inventory.stockNo).then(
          function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
              position: 'is-top',
              type: 'is-info'
            })
          }, function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Could not copy stock no. to clipboard',
              position: 'is-top',
              type: 'is-warning'
            })
          }
        )
      }
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
      eventBus.$emit('file-uploads-started')
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
        eventBus.$emit('file-uploads-completed')
      }
    }
  },
  watch: {
    'value.forInventory': function (newValue, oldValue) {
      if (newValue && oldValue && newValue.id !== oldValue.id) {
        this.initialInventoryModel()
      }
    },
    /*
    selectedDealer: function (newValue, oldValue) {
      if (this.value && this.value.dealerId !== this.selectedDealer.id) {
        this.loadAsyncProductData()
      }
    },
    */
    selectedVehicleType: function (newValue, oldValue) {
      this.isNewVehicleType = (newValue && newValue.name === 'New')
      this.isCpoVehicleType = (newValue && newValue.name === 'Cpo')

      /* do not set this automatically. client wants change
      if (this.selectedInventory) {
        if (this.isNewVehicleType && newValue.name === 'New') {
          this.selectedInventory.rdrComplete = true
        } else {
          this.selectedInventory.rdrComplete = false
        }
      }
      */
      // Set the v-model value
      this.value.vehicleType = newValue
      this.$emit('on-vehicle-sale-type-changed', newValue)
    },
    selectedInventory: function (newValue, oldValue) {
      if (newValue && this.value && this.value.forInventory && newValue.id === this.value.forInventory.id && !this.value.config.loadInventoryOnly) {
        return
      }

      if (newValue !== null) {
        // Fire event when the make of the vehicle selected is changed
        if (!oldValue || (oldValue && oldValue.vehicleMakeId !== newValue.vehicleMakeId)) {
          this.$emit('on-vehicle-make-changed', newValue.vehicleMakeId)
        }
        // Set Sale type
        if (newValue.dispositionIntention && newValue.dispositionIntention !== 'Retail' && newValue.dispositionIntention !== '') {
          this.$emit('update:selectedSaleType', { id: 1, name: 'Wholesale', altName: 'Wholesale' })
          // overide sale price with commitment value when wholesale
          this.value.salePrice = newValue.commitmentValue
        } else {
          // Set the sale price to the inventory record asking price only on create
          this.value.salePrice = newValue.askingPrice ? newValue.askingPrice : 0.00
        }

        // holdback and bonus value
        if (newValue.type === 'New') {
          this.value.holdValue = this.inventoryHoldValue
        }
        var vehicleMake = this.allVehicleMakes.find((x) => x.id === (newValue.makeId || newValue.vehicleMakeId))
        if (vehicleMake !== undefined) {
          var vehicleMakeModel = vehicleMake.versions.find((x) => x.id === (newValue.modelId || newValue.vehicleMakeVersionId))
          if (vehicleMakeModel !== undefined) {
            this.value.mvpValue = vehicleMakeModel.bonusValue
          }
        }

        // set deal sale type cpoStatus type
        let isWholesale = newValue.dispositionIntention && newValue.dispositionIntention !== 'Retail' && newValue.dispositionIntention !== ''
        if (newValue.cpoStatus === 'Eligible' && !isWholesale) {
          this.inventoryDealEligibleActive = true
        } else {
          // We ignore Cpo Status on new inventory
          if (newValue.type === 'New') {
            this.selectedVehicleType = {id: 0, name: 'New', altName: 'New'}
          } else {
            if (newValue.cpoStatus === 'Cpo') {
              this.selectedVehicleType = {id: 2, name: 'Cpo', altName: 'Cpo'}
            } else if (newValue.type === 'New') {
              this.selectedVehicleType = {id: 0, name: 'New', altName: 'New'}
            } else {
              this.selectedVehicleType = {id: 1, name: 'Used', altName: 'Used'}
            }
          }
        }

        // set initialRdr
        // this.initialRdr = newValue.rdrComplete

        // set front gross
        let reconCost = 0.00
        let purchaseCostTotal = 0.00
        let tradeAcvDifference = this.tradeAcvDifference || 0.00
        if (newValue.reconCost) {
          reconCost = newValue.reconCost
        }
        if (newValue.purchaseCostTotal) {
          purchaseCostTotal = newValue.purchaseCostTotal
        }

        let actualInventoryCost = purchaseCostTotal + reconCost + tradeAcvDifference
        var fg = this.value.salePrice + this.additionalCostsGP - (actualInventoryCost)
        this.value.frontValue = fg

        // Set spiff
        if (newValue.spiff && newValue.spiff > 0) {
          this.spiffActive = true
        }

        // Set the v-model value
        this.value.forInventory = newValue

        // Set Stock type and deal type
        if (newValue.possessionStatus === 'Inbound') {
          this.$emit('update:selectedStockType', {id: 0, name: 'In Stock', altName: 'InStock'})
          this.$emit('update:selectedDealType', {id: 0, name: 'Awaiting Arrival', altName: 'AwaitingArrival'})
          // this.selectedType = { id: 0, name: 'Awaiting Arrival', altName: 'AwaitingArrival' }
        } else if (newValue.possessionStatus === 'Locating') {
          this.$emit('update:selectedStockType', {id: 2, name: 'Locate', altName: 'Locate'})
          this.$emit('update:selectedDealType', {id: 6, name: 'Locating', altName: 'Locating'})
        }

        this.isLoading = true
        dealService.byInventory(newValue.id).then(response => {
          var currentInventoryDeals = response.results
          // Warn is there is already a pending deal for this item
          if ((newValue.dealStatusName && newValue.dealStatusName !== 'Available') || (currentInventoryDeals.find((x) => x.type !== 'Cancelled' && x.items.find((y) => y.dealId !== this.value.dealId && y.isPrimary)))) {
            // this.inventoryDealWarningActive = true
            this.value.isPrimary = false
          } else {
            this.value.isPrimary = true
          }

          // load primary deal for this inventory
          if (currentInventoryDeals && currentInventoryDeals.length > 0) {
            this.value.primaryDealForInventory = currentInventoryDeals.find((x) => x.items.find((y) => y.isPrimary) !== null)
          }
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          this.serverErrorMessage = error.message
        })
      }
    }, /*
    selectedProducts: function (newValue, oldValue) {
      if (newValue.products.length > 0) {
        var financeProducts = newValue.filter((y) => y.type === 'Finance')
        this.value.warValue = (financeProducts.length > 0) ? financeProducts.map(x => x.price).reduce((prev, next) => prev + next) : 0.00
      }
    }, */
    selectedMakeModel: {
      handler: function (newValue, oldValue) {
        if (!this.showMakeModelSelector) {
          return
        }

        var stockNo = ''
        var vinNo = ''

        if (this.selectedInventory !== null) {
          stockNo = this.selectedInventory.stockNo
          vinNo = this.selectedInventory.vinNo
        }

        stockNo = (newValue.stockNo) ? newValue.stockNo : stockNo
        vinNo = (newValue.vinNo) ? newValue.vinNo : vinNo

        let setAttribute = function (model, name, value) {
          var existingAttribute = (model.attributes) ? model.attributes.find((x) => x.name === name) : null

          if ((value === '') && existingAttribute) {
            existingAttribute.status = 'Deleted'
            return
          }

          if (existingAttribute) {
            existingAttribute.value = value || ''
          } else {
            if (!model.attributes) {
              model.attributes = []
            }
            model.attributes.push({name: name, value: value})
          }
        }

        let newInventoryStatus = this.selectedStockType.name === 'Locate' ? (this.localLocateVehicleIsFound === true ? 'Active' : 'Requested') : 'Active'
        let newInventoryType = (newValue.locateType && newValue.locateType.name === 'New') ? 'New' : 'Purchased'
        let newInventoryPossessionStatus = this.selectedStockType.name === 'Locate' ? (this.localLocateVehicleIsFound === true ? 'Inbound' : 'Locating') : 'Inbound'
        let newInventoryDealerId = (this.selectedDealer) ? this.selectedDealer.id : null
        let newInventoryPurchasedFromType = (newValue.source && newValue.source !== '') ? newValue.source.altName : null
        let newInventoryPurchasedFromId = newValue.wholesaler ? newValue.wholesaler.id : (newValue.customer ? newValue.customer.id : null)
        let newTitleStatus = newValue.titleStatus ? newValue.titleStatus.name : null
        let newWarrantyExpirationDate = newValue.warrantyExpirationDate
        let newBankId = newValue.selectedBank ? newValue.selectedBank.id : null
        let newAccountNo = newValue.accountNo
        let newPayOffAmount = newValue.payoffAmount
        let newGoodUntilDate = newValue.goodUntilDate
        let isDamaged = newValue.isDamaged

        var inventoryModel = {
          ...this.value.forInventory,
          dealerId: newInventoryDealerId,
          stockNo: stockNo,
          vinNo: vinNo,
          isDamaged: isDamaged,
          type: newInventoryType,
          purchasedFromType: newInventoryPurchasedFromType,
          purchasedFromId: newInventoryPurchasedFromId,
          status: newInventoryStatus,
          possessionStatus: newInventoryPossessionStatus,
          purchaseCostTotal: newValue.purchaseCostTotal,
          usage: newValue.usage,
          askingPrice: newValue.askingPrice,
          bankId: newBankId,
          accNo: newAccountNo,
          goodUntilDate: newGoodUntilDate,
          payoffAmount: newPayOffAmount,
          titleStatus: newTitleStatus,
          warrantyExpirationDate: newWarrantyExpirationDate,
          vehicleMakeId: !newValue || newValue.makeId === -1 ? null : newValue.makeId,
          vehicleMakeVersionId: !newValue || newValue.modelId === -1 ? null : newValue.modelId,
          customVehicleMakeVersion: newValue ? newValue.customMakeModel : '',
          locationId: null,
          attachments: [],
          transmissionType: newValue.transmissionType
        }

        if (newValue.titleFiles.length > 0) {
          inventoryModel.attachments.push(...newValue.titleFiles)
        }

        if (newValue.damageFiles.length > 0) {
          inventoryModel.attachments.push(...newValue.damageFiles)
        }

        // Set the deal sale price to teh sale asking price captured in the make model selector
        if (this.isNewDealItem || this.value.forInventory.vehicleMakeVersionId !== newValue.modelId) {
          this.value.salePrice = newValue.askingPrice ? newValue.askingPrice : 0.00
        } else if (this.isNewDealItem || this.value.forInventory.customVehicleMakeVersion !== newValue.customMakeModel) {
          this.value.salePrice = newValue.askingPrice ? newValue.askingPrice : 0.00
        }

        if (newValue !== null) {
          setAttribute(inventoryModel, 'ExteriorColor', newValue.exteriorColor)
          setAttribute(inventoryModel, 'InteriorColor', newValue.interiorColor)
          setAttribute(inventoryModel, 'DamageNotes', newValue.damageNote)
          setAttribute(inventoryModel, 'Style', newValue.style)
          setAttribute(inventoryModel, 'Trim', newValue.trim)
          setAttribute(inventoryModel, 'ProductionYear', newValue.productionYear)
          setAttribute(inventoryModel, 'Make', newValue.customMakeName)
          setAttribute(inventoryModel, 'Model', newValue.customModelName)

          setAttribute(inventoryModel, 'SearchType', (newValue.locateType && newValue.locateType.name) ? newValue.locateType.name : '')
          setAttribute(inventoryModel, 'AlternateExteriorColors', (newValue.altExteriorColors && newValue.altExteriorColors.length > 0) ? newValue.altExteriorColors.join(',') : '')
          setAttribute(inventoryModel, 'AlternateInteriorColors', (newValue.altInteriorColors && newValue.altInteriorColors.length > 0) ? newValue.altInteriorColors.join(',') : '')
          setAttribute(inventoryModel, 'SearchTags', (newValue.tags && newValue.tags.length > 0) ? newValue.tags.join(',') : '')
          setAttribute(inventoryModel, 'SearchNotes', (newValue.notes && newValue.notes.length > 0) ? newValue.notes : '')
        }

        if (this.initialLoadComplete) {
          if (newValue.locateType && newValue.locateType.id === this.definedTypes.allowedLocateTypes.New) {
            this.selectedVehicleType = { id: 0, name: 'New', altName: 'New' }
          } else if (newValue.locateType && newValue.locateType.id === this.definedTypes.allowedLocateTypes.Used) {
            this.selectedVehicleType = { id: 1, name: 'Used', altName: 'Used' }
          }
        } else {
          this.initialLoadComplete = true
        }

        // Set the v-model value
        this.value.forInventory = inventoryModel
        this.value.modelOverview = newValue.completeOverview
      },

      deep: true
    },
    inventorySoldPhotoFiles: function (newValue, oldValue) {
      if (this.inventorySoldPhotoFiles.length > 0) {
        var photoInventorySoldFiles = this.inventorySoldPhotoFiles.filter(y => y.uniqueId).map((x) => {
          return {
            type: x.type,
            classType: 'SaleDocument',
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId
          }
        })
        this.value.lineAttachments.push(...photoInventorySoldFiles)
      }
    },
    inventorySoldPdfFiles: function (newValue, oldValue) {
      if (this.inventorySoldPdfFiles.length > 0) {
        var pdfInventorySoldFiles = this.inventorySoldPdfFiles.filter(y => y.uniqueId).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'SaleDocument'
          }
        })
        this.value.lineAttachments.push(...pdfInventorySoldFiles)
      }
    },
    locateVehicleIsFound: function (newValue, oldValue) {
      if (newValue !== undefined) {
        this.localLocateVehicleIsFound = newValue
      }
    },
    selectedStockType: function (newValue, oldValue) {
      if (newValue && (newValue.name === 'Order' || newValue.name === 'Locate')) {
        if (this.selectedInventory && this.selectedInventory.possessionStatus !== 'Inbound') {
          this.selectedInventory.possessionStatus = 'Inbound'
          this.isPrimaryNotificationHidden = true
        }
        this.showInboundOnly = true
      } else {
        this.showInboundOnly = false
      }
    },
    selectedDealType: function (newValue, oldValue) {
      if (newValue && oldValue && oldValue.altName === 'Pending' && (newValue.altName === 'Ordered' || newValue.altName === 'Locate')) {
        if (this.selectedInventory) {
          this.selectedInventory.possessionStatus = 'Inbound'
        }
      }
    },
    selectedSaleType: function (newValue, oldValue) {
      // Retail
      if (newValue && (newValue.name === 'Retail' || newValue.altName === 'Retail')) {
        // We ignore Cpo Status on new inventory
        if (this.selectedInventory && this.selectedInventory.type === 'New') {
          this.selectedVehicleType = {id: 0, name: 'New', altName: 'New'}
        } else {
          if (this.selectedInventory && this.selectedInventory.cpoStatus === 'Cpo') {
            this.selectedVehicleType = {id: 2, name: 'Cpo', altName: 'Cpo'}
          } else {
            this.selectedVehicleType = {id: 1, name: 'Used', altName: 'Used'}
          }
        }
      }

      // Wholesale
      if (newValue && (newValue.name === 'Wholesale' || newValue.altName === 'Wholesale')) {
        // We ignore Cpo Status Wholesale
        if (this.selectedInventory && this.selectedInventory.type === 'New') {
          this.selectedVehicleType = {id: 0, name: 'New', altName: 'New'}
        } else {
          this.selectedVehicleType = {id: 1, name: 'Used', altName: 'Used'}
        }
      }
    },
    products: function (newValue, oldValue) {
      this.value.itemProducts = []
      this.value.itemProducts = newValue

      if (newValue.length > 0) {
        var warrantyProducts = newValue.filter((y) => y.type === 'Warranty' && !y.delete)
        this.value.warValue = (warrantyProducts.length > 0) ? warrantyProducts.map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next) : 0.00
      }
    },
    salePriceWatcher: function (newValue, oldValue) {
      this.updateFrontGross()
    },
    inventorySelectionSource: function (newValue, oldValue) {
      if (newValue === 'Lead') {
        if (this.customerLeadVehicles.length === 1) {
          this.selectedInventory = this.customerLeadVehicles[0]
        }
      }
    },
    calculatedFrontGross: function (newValue, oldValue) {
      // this.value.frontValue = newValue
    }
  },
  mounted: function () {
    this.initValues()
    this.populateProducts()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .flat-table {
    font-family: sans-serif;
    -webkit-font-smoothing: antialiased;
    font-size: 0.8rem;
    overflow: auto;
    width: 100%;
    border-collapse:separate;
    border-radius: 6px;
    -moz-border-radius:6px;
    -webkit-border-radius: 6px;
  }

  .flat-table th {
    background-color: #41b883;
    color: white;
    font-weight: normal;
    padding: 10px 15px;
  }

  .flat-table td {
    background-color: rgb(238, 238, 238);
    color: rgb(111, 111, 111);
    padding: 10px 15px;
  }

  .flat-table th:first-child {
    border-radius: 6px 0 0 0;
  }

  .flat-table th:last-child {
    border-radius: 0 6px 0 0;
  }

  .flat-table tr:last-child td:first-child {
    border-radius: 0 0 0 6px;
  }

  .flat-table tr:last-child td:last-child {
    border-radius: 0 0 6px 0;
  }

  .box {
    background-color: #f6f8fa;
  }

</style>

<template>
  <div class="field is-grouped search-bar">
    <div class="columns" style="width:100%">
      <div class="column is-hidden-mobile control">
        <input class="input" type="search" placeholder="Type to search" v-model.trim="query" v-on:keyup.prevent="onQuery"/>
      </div>
      <div class="column is-narrow is-paddingless" style="align-self: center;">
        <div class="is-flex">
          <div class="date-picker-global">
            <el-date-picker
                v-model="selectedStartDate"
                type="date"
                align="center"
                size="small"
                placeholder="Start date"
                :picker-options="startPickerOptions">
              </el-date-picker>
          </div>
          <div class="date-picker-global">
            <el-date-picker
              v-model="selectedEndDate"
              type="date"
              align="center"
              size="small"
              placeholder="End date"
              :picker-options="endPickerOptions">
            </el-date-picker>
          </div>
          <div class="date-picker-month-global">
            <el-date-picker
              v-model="selectedYearMonth"
              type="month"
              size="small"
              align="right"
              format="MMM yyyy"
              @change="onYearMonthSelected">
            </el-date-picker>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import moment from 'moment'
import _ from 'lodash'

export default {
  name: 'PodSearchControl',
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          query: '',
          selectedEndDate: '',
          selectedStartDate: ''
        }
      }
    }
  },
  data () {
    return {
      query: this.value.query,
      selectedEndDate: this.value.selectedEndDate,
      selectedStartDate: this.value.selectedStartDate,
      startPickerOptions:
      {
        shortcuts:
        [{
          text: 'start month',
          onClick (picker) {
            let start = moment.tz(new Date(), moment.tz.guess()).startOf('month').format('YYYY-MM-DD 00:00:00')
            picker.$emit('pick', start)
          }
        },
        {
          text: 'start < month',
          onClick (picker) {
            let start = moment.tz(new Date(), moment.tz.guess()).subtract(1, 'month').startOf('month').format('YYYY-MM-DD 00:00:00')
            picker.$emit('pick', start)
          }
        },
        {
          text: 'start year',
          onClick (picker) {
            let start = moment.tz(new Date(), moment.tz.guess()).startOf('year').format('YYYY-MM-DD 00:00:00')
            picker.$emit('pick', start)
          }
        }]
      },
      endPickerOptions:
      {
        shortcuts:
        [{
          text: 'end month',
          onClick (picker) {
            let end = moment.tz(new Date(), moment.tz.guess()).endOf('month').format('YYYY-MM-DD 23:59:59')
            picker.$emit('pick', end)
          }
        },
        {
          text: 'end < month',
          onClick (picker) {
            let end = moment.tz(new Date(), moment.tz.guess()).subtract(1, 'month').endOf('month').format('YYYY-MM-DD 23:59:59')
            picker.$emit('pick', end)
          }
        },
        {
          text: 'end year',
          onClick (picker) {
            let end = moment.tz(new Date(), moment.tz.guess()).endOf('year').format('YYYY-MM-DD 23:59:59')
            picker.$emit('pick', end)
          }
        }]
      },
      selectedYearMonth: null
    }
  },
  methods: {
    onYearMonthSelected: function (value) {
      let tz = moment.tz.guess()
      let start = moment.tz(value, tz).format('YYYY-MM-DD 00:00:00')
      let end = moment.tz(value, tz).endOf('month').format('YYYY-MM-DD 23:59:59')
      this.selectedStartDate = start === 'Invalid date' ? null : start
      this.selectedEndDate = end === 'Invalid date' ? null : end
      this.selectedStartDate = this.selectedStartDate === 'Invalid date' ? null : this.selectedStartDate
      this.selectedEndDate = this.selectedEndDate === 'Invalid date' ? null : this.selectedEndDate
    },
    onQuery: _.debounce(function (query) {
      this.$emit('query', this.query)
    }, 500)
  },
  computed: {

  },
  watch: {
    value: function (newValue, oldValue) {
      this.query = newValue.query
    },
    selectedStartDate: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        let tz = moment.tz.guess()
        let start = newValue ? moment.tz(newValue, tz).format('YYYY-MM-DD 00:00:00') : null
        this.$emit('selectedStartDate', start)
      }
    },
    selectedEndDate: function (newValue, oldValue) {
      if (newValue !== oldValue) {
        let tz = moment.tz.guess()
        let end = newValue ? moment.tz(newValue, tz).format('YYYY-MM-DD 23:59:59') : null
        this.$emit('selectedEndDate', end)
      }
    }
  },
  mounted () {
  }
}

</script>

<style scoped>

</style>

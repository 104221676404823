import mutationTypes from '../mutation-types'
import customerService from '../../services/customerService'

const state = {
  allStates: []
}

const getters = {
  allStates: state => {
    return state.allStates
  }
}

const mutations = {
  [mutationTypes.SET_ALL_STATES] (state, states) {
    state.allStates = states
  }
}

const actions = {
  setAllStates ({commit, state}) {
    commit(mutationTypes.SET_ALL_STATES, [])
    customerService.allStates()
      .then((data) => {
        commit(mutationTypes.SET_ALL_STATES, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

import axios from 'axios'
import baseService from './baseService'
import featureData from './data/feature'

const featureService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(featureData)
      } else {
        axios
          .post('/feature/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (featureId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(featureData.all.find((x) => x.id === featureId))
      } else {
        axios
          .post('/feature/get?id=' + featureId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (featureModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var feature = {
          id: 999,
          name: featureModel.name,
          status: 'Active'
        }
        featureData.all.push(feature)
        resolve(feature)
      } else {
        axios
          .post('/feature/save', featureModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (featureModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var feature = featureData.all.find((x) => x.id === featureModel.id)
        if (feature) {
          feature.name = featureModel.name
          feature.status = featureModel.status
        }
        resolve(feature)
      } else {
        axios
          .post('/feature/update', featureModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (featureModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        featureModels.forEach(element => {
          featureData.all.remove((x) => x.id === element.id)
        })
        resolve(featureData.all)
      } else {
        var ids = []
        featureModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/feature/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default featureService

import mutationTypes from '../mutation-types.js'
import userService from '../../services/userService.js'
import moment from 'moment-timezone'
import router from '../../router'
import jstz from 'jstimezonedetect'
import Vue from 'vue'
import util from '@/utils'

const persistedAutoCloseMenu = window.localStorage.getItem('config-auto-close-menu')

const state = {
  isAuthenticated: false,
  currentUser: {
    roles: []
  },
  isSideBarActive: true,
  isToolBarActive: true,
  lastActiveListPage: '',
  lastListPageConfigs: [],
  autoCloseMenu: persistedAutoCloseMenu === undefined ? true : persistedAutoCloseMenu === 'true',
  allUsers: [],
  notifications: [],
  pendingActions: [],
  dealSelectedDateTimeRange: null,
  paymentScheduleSelectedDateTimeRange: null,
  accountingSelectedDateTimeRange: null
}

const getters = {
  allUsers: state => {
    return state.allUsers
  },
  selectedDealTimeRange: state => {
    return state.dealSelectedDateTimeRange
  },
  selectedPaymentScheduleTimeRange: state => {
    return state.paymentScheduleSelectedDateTimeRange
  },
  selectedAccountingTimeRange: state => {
    return state.accountingSelectedDateTimeRange
  },
  allowedRoles: state => {
    if (state.currentUser && state.currentUser.roles) {
      return state.currentUser.roles.map((x) => {
        return x.name
      })
    } else {
      return []
    }
  },
  allowedFeatures: state => {
    if (state.currentUser && state.currentUser.features) {
      return state.currentUser.features
    } else {
      return []
    }
  },
  allowedFeatureCodes: state => {
    if (state.currentUser && state.currentUser.features) {
      return state.currentUser.features.map((x) => {
        return x.code
      })
    } else {
      return []
    }
  },
  roleCount: (state, getters) => {
    return getters.allowedRoles ? getters.allowedRoles.length : 0
  },
  isInRole: (state, getters) => (requiredRole) => {
    return getters.allowedRoles.includes(requiredRole)
  },
  hasFeatureAccess: (state, getters) => (requiredFeatureCode, deep = true) => {
    var hasAccess = getters.allowedFeatureCodes.includes(requiredFeatureCode)
    if (!hasAccess && deep === true) {
      var parts = requiredFeatureCode.split('.')
      var partToCheck = ''
      for (var i = 1; i < parts.length; i++) {
        partToCheck += parts[i - 1] + '.'
        hasAccess = getters.allowedFeatureCodes.includes(partToCheck + '*')
        if (hasAccess === true) {
          break
        }
      }
    }
    return hasAccess
  },
  menuitems: state => {
    let inTabletMode = util.isInTabletMode()
    if (inTabletMode && state.currentUser.menus) {
      var dashboard = state.currentUser.menus.filter((x) => x.name === 'Dashboard')
      return dashboard
    }
    return state.currentUser.menus
  },
  currentUser: state => {
    return state.currentUser
  },
  currentNotifications: state => {
    return state.notifications
  },
  currentPendingActions: state => {
    return state.pendingActions
  },
  lastActiveListPage: state => {
    return state.lastActiveListPage
  },
  getLastSearchCriteria: state => listPage => {
    return state.lastListPageConfigs.find((x) => x.route === listPage)
  },
  isSideBarActive: state => {
    return state.isSideBarActive
  },
  isToolBarActive: state => {
    return state.isToolBarActive
  },
  autoCloseMenu: state => {
    return state.autoCloseMenu
  }
}

const mutations = {
  [mutationTypes.SET_CURRENT_USER] (state, newUser) {
    state.isAuthenticated = newUser['firstName'] !== undefined
    if (newUser && newUser.menus) {
      let liveTopLevelMenus = newUser.menus.filter((x) => x.app.isLiveApp === true)
      for (let appMenu of liveTopLevelMenus) {
        let liveChildMenus = appMenu.children.filter((x) => x.app.isLiveApp === true)
        appMenu.children = liveChildMenus
      }
    }
    state.currentUser = newUser
  },
  [mutationTypes.SET_DEAL_TIME_RANGE] (state, selectedRange) {
    state.dealSelectedDateTimeRange = selectedRange
  },
  [mutationTypes.SET_PAYMENT_SCHEDULE_DEAL_TIME_RANGE] (state, selectedRange) {
    state.paymentScheduleSelectedDateTimeRange = selectedRange
  },
  [mutationTypes.SET_ACCOUNTING_TIME_RANGE] (state, selectedRange) {
    state.accountingSelectedDateTimeRange = selectedRange
  },
  [mutationTypes.SET_USERS] (state, users) {
    state.allUsers = users
  },
  [mutationTypes.UPDATE_USER_SETTING] (state, setting) {
    var oldSetting = state.currentUser.settings.find(x => x.name === setting.name)
    if (oldSetting) {
      oldSetting.value = setting.value
    } else {
      state.currentUser.settings.push(setting)
    }
  },
  [mutationTypes.TOGGLE_AUTO_CLOSE_MENU] (state) {
    state.autoCloseMenu = !state.autoCloseMenu
    localStorage.setItem('config-auto-close-menu', state.autoCloseMenu)
  },
  [mutationTypes.TOGGLE_SIDEBAR] (state) {
    state.isSideBarActive = !state.isSideBarActive
  },
  [mutationTypes.SET_TOOLBAR_STATE] (state, newState) {
    state.isToolBarActive = newState
  },
  [mutationTypes.SET_CURRENT_LIST_PAGE] (state, listPageQuery) {
    state.lastActiveListPage = listPageQuery.route
    let foundItem = state.lastListPageConfigs.find((x) => x.route === listPageQuery.route)
    if (foundItem) {
      var balance = state.lastListPageConfigs.filter((x) => x.route !== listPageQuery.route)
      Vue.set(state, 'lastListPageConfigs', [...balance, listPageQuery])
    } else {
      state.lastListPageConfigs.push(listPageQuery)
    }
  },
  [mutationTypes.SET_CURRENT_TIMEZONE] (state, timeZone) {
    if (timeZone && timeZone !== '') {
      moment.tz.setDefault(timeZone)
    }
  },
  [mutationTypes.EXPAND_MENU] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.currentUser.menus[menuItem.index] && state.currentUser.menus[menuItem.index].meta) {
        state.currentUser.menus[menuItem.index].meta.expanded = menuItem.expanded
      }
    } else if (menuItem.item && 'expanded' in menuItem.item.meta) {
      menuItem.item.meta.expanded = menuItem.expanded
    }
  },
  [mutationTypes.SET_MENU_ACTIVE_STATE] (state, menuItem) {
    if (menuItem.index > -1) {
      if (state.currentUser.menus[menuItem.index] && state.currentUser.menus[menuItem.index].meta) {
        state.currentUser.menus[menuItem.index].meta.isActive = menuItem.isActive
      }
    } else {

    }
  },
  [mutationTypes.SYNC_USER] (state, entity) {
    var applicable = state.allUsers.find((x) => x.id === entity.id)
    if (applicable) {
      applicable = entity
    } else {
      state.allUsers.push(entity)
    }
  },
  [mutationTypes.SET_CURRENT_USER_TRACKING_NUMBER] (state, activeTrackingNumber) {
    state.currentUser.activeTrackingNumber = activeTrackingNumber
  }
}

const actions = {
  expandMenu ({ commit }, menuItem) {
    if (menuItem) {
      menuItem.expanded = menuItem.expanded || false
      commit(mutationTypes.EXPAND_MENU, menuItem)
    }
  },
  setMenuActiveState ({ commit }, menuItem) {
    if (menuItem) {
      commit(mutationTypes.SET_MENU_ACTIVE_STATE, menuItem)
    }
  },
  toggleDesign ({ commit }) {
    commit(mutationTypes.TOGGLE_DESIGN)
  },
  toggleSideBar ({ commit }) {
    commit(mutationTypes.TOGGLE_SIDEBAR)
  },
  toggleAutoCloseMenu ({ commit }) {
    commit(mutationTypes.TOGGLE_AUTO_CLOSE_MENU)
  },
  setDealSelectedTimeRange ({ commit }, selectedRange) {
    commit(mutationTypes.SET_DEAL_TIME_RANGE, selectedRange)
  },
  setPaymentScheduleSelectedTimeRange ({ commit }, selectedRange) {
    commit(mutationTypes.SET_PAYMENT_SCHEDULE_DEAL_TIME_RANGE, selectedRange)
  },
  setAccountingSelectedTimeRange ({ commit }, selectedRange) {
    commit(mutationTypes.SET_ACCOUNTING_TIME_RANGE, selectedRange)
  },
  setToolBarState ({ commit }, isActive) {
    commit(mutationTypes.SET_TOOLBAR_STATE, isActive)
  },
  updateUserSetting ({ commit }, setting) {
    var oldSetting = state.currentUser.settings.find(x => x.name === setting.name)
    if (!oldSetting || oldSetting.value !== setting.value) {
      var updateCostSettingModel = {
        userId: state.currentUser.id,
        ...setting
      }
      userService.saveUserSettings(updateCostSettingModel).then(data => {
        if (data.errors) {
          console.log(data.errors)
        } else {
          let updateSettingValue = data.userSetting
          commit(mutationTypes.UPDATE_USER_SETTING, updateSettingValue)
        }
      }).catch((error) => {
        console.log(error)
      })
    }
  },
  setCurrentListPageQuery ({ commit }, current) {
    if (current && (current.route.startsWith('List') || current.route.startsWith('Dashboard'))) {
      commit(mutationTypes.SET_CURRENT_LIST_PAGE, current)
    }
  },
  setActiveTimeZone ({ commit, state, rootState }) {
    return new Promise((resolve, reject) => {
      let detected = jstz.determine().name()
      let currentSubscriber = rootState.subscriber.currentSubscriber
      let currentDealer = rootState.dealer.currentDealer
      let timeZoneOptions = [currentDealer.timezone, currentSubscriber.timezone, detected]
      let timeZone = timeZoneOptions.find((x) => x && x !== '')
      commit(mutationTypes.SET_CURRENT_TIMEZONE, timeZone)
      resolve(timeZone)
    })
  },
  setCurrentUser ({ commit, state }) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.SET_CURRENT_USER, { isValid: false })
      userService.loadCurrentUser()
        .then((user) => {
          commit(mutationTypes.SET_CURRENT_USER, user)
          resolve(user)
        })
        .catch((error) => {
          if (error.response.status === 401) {
            router.push('/auth/login')
          }
          console.log(error)
          reject(error)
        }
        )
    })
  },
  setUsers ({commit, state}) {
    return new Promise((resolve, reject) => {
      commit(mutationTypes.SET_USERS, [])
      userService.allUsers()
        .then((data) => {
          commit(mutationTypes.SET_USERS, data.results)
          resolve(data.results)
        })
        .catch((error) => {
          console.log(error)
          reject(error)
        }
        )
    })
  },
  syncUser ({commit, state}, entity) {
    commit(mutationTypes.SYNC_USER, entity)
  },
  setUserCallTrackingNumber ({commit, state}, callTrackingNumber) {
    commit(mutationTypes.SET_CURRENT_USER_TRACKING_NUMBER, callTrackingNumber)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <div class="cd columns is-multiline is-centered carfax-container" style="position: relative; z-index: 2000;" v-if="displayOnly">
    <div class="column is-12" v-if="carFaxAvailable && currentCarFaxRating !== null">
        <div style="width:78px;">
          <i class="icon fal fa-lg status-icon is-pulled-right" :class="getCarFaxBbgIcons()" :title="getCarFaxBbgTitle()"/>
          <img class="carfax-owner-icons is-pulled-right" :src="totalOwnersImageIndicator" :title="totalOwnersImageTitle" v-if="totalOwnersImageIndicator !== null && totalOwners > 0">
        </div>
        <a @click="showCarFaxReport()" style="width:78px; height:2px;"><img src="@/assets/static/carfax/carfax_logo_small.png" style="width:78px; height:22px;" :title="getLastCheckedAt"></a>
    </div>
    <div class="column is-12" v-else-if="!hideIfEmpty">
      <label class="label refresh-status-label">
      No CarFax report available
      </label>
    </div>
    <car-fax-report-control v-if="!modalContainer"
      title="CarFax Vehicle Status"
      v-on:on-hide-carfax-report="onHideCarFaxReport"
      :showCarFaxReportModal="showReport"
      :carFaxReportUrl="carFaxReportUrl">
    </car-fax-report-control>
  </div>
  <div class="columns box is-multiline is-centered carfax-container" style="position: relative; z-index: 2000;"  v-else>
    <div class="carfax-container-display-only column is-12">
      <div style="width:100%">
        <h3 class="title is-6 is-inline-block is-marginless">{{title}}</h3>
        <label class="is-inline-block is-size-7 has-text-success refresh-status-label" v-if="isRefreshing"> Fetching latest available report...</label>
        <i class="fal fa-sync-alt clickable is-pulled-right" :class="{'fa-spin': isRefreshing}" @click="getUpdatedCarFax()"></i>
      </div>
    </div>
    <div class="column is=12"  v-if="carFaxAvailable && currentCarFaxRating !== null">
      <div>
        <div style="width:78px;">
          <i class="icon fal fa-lg status-icon is-pulled-right" :class="getCarFaxBbgIcons()" :title="getCarFaxBbgTitle()"/>
          <img class="carfax-owner-icons is-pulled-right" :src="totalOwnersImageIndicator" :title="totalOwnersImageTitle" v-if="totalOwnersImageIndicator !== null && totalOwners > 0">
        </div>
        <a @click="showCarFaxReport()" style="width:78px; height:2px;"><img src="@/assets/static/carfax/carfax_logo_small.png" style="width:78px; height:22px;" :title="getLastCheckedAt"></a>
      </div>
    </div>
    <div class="column is=12"  v-if="!carFaxAvailable || currentCarFaxRating === null">
      <label class="label refresh-status-label">
      No CarFax report available
      </label>
    </div>
    <car-fax-report-control v-if="!modalContainer"
      title="CarFax Vehicle Status"
      v-on:on-hide-carfax-report="onHideCarFaxReport"
      :showCarFaxReportModal="showReport"
      :carFaxReportUrl="carFaxReportUrl"
      :vinNo="vinNo">
    </car-fax-report-control>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import appraisalService from '@/services/appraisalService'
import inventoryService from '@/services/inventoryService'
import CarFaxReportControl from '@/components/generic/CarFaxReportControl'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'CarFaxControl',
  components: {
    'car-fax-report-control': CarFaxReportControl
  },
  props: {
    vinNo: {
      type: String,
      default: ''
    },
    carFaxRating: {
      type: Object,
      default: null
    },
    title: {
      type: String,
      default: 'CarFax Status'
    },
    autoUpdateInventory: {
      type: Boolean,
      default: false
    },
    runAutomatically: {
      type: Boolean,
      default: false
    },
    displayOnly: {
      type: Boolean,
      default: true
    },
    hideIfEmpty: {
      type: Boolean,
      default: true
    },
    inventory: {
      type: Object,
      default: null
    },
    modalContainer: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      isRefreshing: false,
      currentCarFaxRating: null,
      showReport: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    carFaxReportUrl: function () {
      let result = 'https://www.carfaxonline.com/cfm/Display_Dealer_Report.cfm?partner=VAU_0&UID=' + this.currentDealer.carFaxUserName + '&vin=' + this.vinNo
      return result
    },
    carFaxAvailable: function () {
      return this.currentCarFaxRating
    },
    totalOwnersImageIndicator: function () {
      if (this.currentCarFaxRating && this.currentCarFaxRating.totalOwners !== null) {
        let totalOwners = this.currentCarFaxRating.totalOwners
        totalOwners = totalOwners > 3 ? 3 : totalOwners
        return '/assets/static/carfax/ico_' + totalOwners + 'owner.png'
      }
      return null
    },
    totalOwners: function () {
      if (this.currentCarFaxRating && this.currentCarFaxRating.totalOwners !== null) {
        let totalOwners = this.currentCarFaxRating.totalOwners
        return totalOwners
      }
      return 0
    },
    totalOwnersImageTitle: function () {
      if (this.currentCarFaxRating && this.currentCarFaxRating.totalOwners !== null) {
        let totalOwners = this.currentCarFaxRating.totalOwners
        return totalOwners + ' owner(s) recorded.'
      }
      return ''
    },
    getLastCheckedAt: function () {
      let result = ''
      if (this.currentCarFaxRating && this.currentCarFaxRating.lastCheckedAt) {
        let lastCheckedAt = this.$options.filters.formatDate(this.currentCarFaxRating.lastCheckedAt)
        result = 'Last checked at ' + lastCheckedAt + ')'
      }
      return result
    }
  },
  methods: {
    getUpdatedCarFax: function () {
      let vinNo = this.vinNo
      if (this.isRefreshing === false && vinNo !== '') {
        this.isRefreshing = true
        appraisalService.getCarFax(vinNo, this.currentDealer).then(response => {
          this.isRefreshing = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.currentCarFaxRating = response
            this.$emit('on-new-carfax', this.currentCarFaxRating)
            if (this.inventory && this.autoUpdateInventory) {
              this.currentCarFaxRating.inventoryId = this.inventory.id
              inventoryService.addCarFaxReport(this.currentCarFaxRating)
            }
          }
        }).catch((error) => {
          this.$emit('on-carfax-failed')
          this.isRefreshing = false
          this.serverErrorMessage = error.message
          this.currentCarFaxRating = null
        })
      }
    },
    getCarFaxBbgTitle: function () {
      let result = ''
      if (this.carFaxAvailable && this.currentCarFaxRating !== null) {
        if (this.currentCarFaxRating.accidentIndicator === true || this.currentCarFaxRating.frameDamageOrRepair === true || this.currentCarFaxRating.airBagDeployed === true) {
          result = 'Indication of Accident, frame damage or airbag deployment.'
        } else if (this.currentCarFaxRating.damaged === true || this.currentCarFaxRating.majorProblems === true) {
          result = 'Damage indicated or major problems noted.'
        } else if (this.currentCarFaxRating.recall === true) {
          result = 'A recall has been flagged.'
        } else if (this.currentCarFaxRating.brandedTitle === true) {
          result = 'Vehicle has been flagged with branded title.'
        } else if (this.currentCarFaxRating.totalAccidents && this.currentCarFaxRating.totalAccidents > 0) {
          result = 'Vehicle has at least one accident reported.'
        } else {
          result = 'No major incidents.'
        }
      }
      return result
    },
    getCarFaxBbgIcons: function () {
      let classToAttach = {}
      if (this.carFaxAvailable && this.currentCarFaxRating !== null) {
        if (this.currentCarFaxRating.accidentIndicator === true || this.currentCarFaxRating.frameDamageOrRepair === true || this.currentCarFaxRating.airBagDeployed === true) {
          classToAttach['carfax-warning'] = true
        } else if (this.currentCarFaxRating.damaged === true || this.currentCarFaxRating.majorProblems === true) {
          classToAttach['carfax-danger'] = true
        } else if (this.currentCarFaxRating.recall === true) {
          classToAttach['carfax-recall'] = true
        } else if (this.currentCarFaxRating.brandedTitle === true) {
          classToAttach['carfax-danger'] = true
        } else if (this.currentCarFaxRating.totalAccidents && this.currentCarFaxRating.totalAccidents > 0) {
          classToAttach['carfax-danger'] = true
        } else {
          classToAttach['carfax-ok'] = true
        }
      }
      return classToAttach
    },
    showCarFaxReport: function () {
      if (!this.modalContainer) {
        this.showReport = true
      } else {
        this.$emit('on-show-carfax-report')
      }
    },
    onHideCarFaxReport: function () {
      if (!this.modalContainer) {
        this.showReport = false
      } else {
        this.$emit('on-hide-carfax-report')
      }
    }
  },
  watch: {
    carFaxRating: function (newValue, oldValue) {
      this.currentCarFaxRating = this.carFaxRating
    }
  },
  mounted: function () {
    this.currentCarFaxRating = this.carFaxRating
    if (this.runAutomatically || !this.currentCarFaxRating) {
      this.getUpdatedCarFax()
    }
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .status-icon {
    width: 13px;
    height: 12px;
    margin-right:12px;
  }

  .carfax-container-display-only {
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    margin-top:10px;
  }

  .carfax-container {
    margin-top: 20px;
    padding: 0;
    margin-left:0;
    margin-right:0;
  }

  .carfax-owner-icons {
    height: 12px;
    width: 12px;
    margin-top:-15px;
  }

  .carfax-warning {
    background-image: url('@/assets/static/carfax/ico_accident.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .carfax-danger {
    background-image: url('@/assets/static/carfax/ico_alert.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .carfax-recall {
    background-image: url('@/assets/static/carfax/ico_recall.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .carfax-ok {
    background-image: url('@/assets/static/carfax/ico_green_check.png');
    background-repeat: no-repeat, no-repeat;
    margin-top:-15px;
  }

  .refresh-status-label {
    margin-left:1em;
  }

  .cd .column {
    padding:0;
  }
</style>

<template>
<portal to="global-modal-portal" v-if="showModal" :order="4">
  <b-modal :active.sync="showModal" scroll="keep" :has-modal-card="true" :canCancel="false" id="cancel-signature-request-modal">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">Cancel Signature Request</p>
          </header>
        <section class="modal-card-body">
          <p>Do you want to cancel signature request?</p>
        </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="cancelSignatureRequest(signatureRequest)" type="button">Yes</button>
            <button class="button is-danger" @click="hideSignatureRequestModal" type="button">No</button>
          </footer>
        </div>
      </b-modal>
  </portal>
</template>

<script>

export default {
  name: 'CancelRequestControl',
  components: {
  },
  props: {
    showRequestCancellationModal: {
      type: Boolean,
      default: false
    },
    signatureRequest: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      showModal: false
    }
  },
  computed: {
  },
  methods: {
    showSignatureRequestModal: function () {
      this.showModal = true
    },
    hideSignatureRequestModal: function () {
      this.$emit('on-hide-cancel-request-control')
      this.showModal = false
    },
    cancelSignatureRequest: function () {
      this.$emit('send-cancellation-request', this.signatureRequest)
    }
  },
  watch: {
    showRequestCancellationModal: function (newValue, oldValue) {
      if (newValue) {
        this.showSignatureRequestModal()
      } else {
        this.showModal = false
      }
    }
  },
  mounted: function () {
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

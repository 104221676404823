import requiresAuth from './route_guards'

export default [
  {
    path: '/products',
    name: 'ListProducts',
    component: () => import('@/components/configuration/products/ListProducts'),
    beforeEnter: requiresAuth
  },
  {
    name: 'ProductsQuickView',
    path: '/configuration/products/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/products/ViewProduct')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/products/new',
    name: 'NewProduct',
    component: () => import('@/components/configuration/products/NewProduct'),
    beforeEnter: requiresAuth
  },
  {
    path: '/products/edit/:id',
    name: 'EditProduct',
    component: () => import('@/components/configuration/products/EditProduct'),
    beforeEnter: requiresAuth
  },
  {
    path: '/products/view/:id',
    name: 'ViewProduct',
    component: () => import('@/components/configuration/products/ViewProduct'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  }
]

export default {
  total: 4,
  results: [
    {
      name: 'Admin',
      status: 'Active',
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 1,
      departmentName: 'Administration',
      id: 1
    },
    {
      name: 'Store Manager',
      status: 'Active',
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 1,
      departmentName: 'Administration',
      id: 2
    },
    {
      name: 'Salesman',
      status: 'Active',
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 2,
      departmentName: 'Sales',
      id: 3
    },
    {
      name: 'Sales Advisor',
      status: 'Active',
      subscriberId: 1,
      subscriberName: 'EAG',
      dealerId: 3,
      departmentName: 'Service',
      id: 4
    }
  ]
}

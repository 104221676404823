export const SET_CURRENT_USER = 'SET_CURRENT_USER'
export const SET_USERS = 'SET_USERS'
export const SYNC_USER = 'SYNC_USER'
export const SET_DEAL_TIME_RANGE = 'SET_DEAL_TIME_RANGE'
export const SET_PAYMENT_SCHEDULE_DEAL_TIME_RANGE = 'SET_PAYMENT_SCHEDULE_DEAL_TIME_RANGE'
export const SET_ACCOUNTING_TIME_RANGE = 'SET_ACCOUNTING_TIME_RANGE'
export const EXPAND_MENU = 'EXPAND_MENU'
export const SET_MENU_ACTIVE_STATE = 'SET_MENU_ACTIVE_STATE'
export const TOGGLE_DESIGN = 'TOGGLE_DESIGN'
export const SET_DASHBOARD_TAB_INDEX = 'SET_DASHBOARD_TAB_INDEX'

export const SET_ALL_INVENTORY = 'SET_ALL_INVENTORY'
export const SET_INVENTORY_OVERVIEW = 'SET_INVENTORY_OVERVIEW'

export const SET_CURRENT_SUBSCRIBER = 'SET_CURRENT_SUBSCRIBER'

export const SET_SYSTEM_READY = 'SET_SYSTEM_READY'

export const SET_SECURITY_TOKEN = 'SET_SECURITY_TOKEN'

export const SET_CURRENT_DEALER = 'SET_CURRENT_DEALER'
export const SET_DEALERS = 'SET_DEALERS'
export const SYNC_DEALER = 'SYNC_DEALER'
export const SET_DEPARTMENTS = 'SET_DEPARTMENTS'
export const SYNC_DEPARTMENT = 'SYNC_DEPARTMENT'
export const SET_DESIGNATIONS = 'SET_DESIGNATIONS'
export const SYNC_DESIGNATION = 'SYNC_DESIGNATION'

export const SET_CURRENT_INSURER = 'SET_CURRENT_INSURER'
export const SET_INSURERS = 'SET_INSURERS'

export const SET_CURRENT_LIST_PAGE = 'SET_CURRENT_LIST_PAGE'

export const SET_VEHICLE_MAKES = 'SET_VEHICLE_MAKES'
export const SYNC_VEHICLE_MAKE = 'SYNC_VEHICLE_MAKE'

export const SET_CURRENT_LOCATION = 'SET_CURRENT_LOCATION'
export const SET_LOCATIONS = 'SET_LOCATIONS'
export const SYNC_LOCATION = 'SYNC_LOCATION'

export const SET_LEADSOURCES = 'SET_LEADSOURCES'
export const SET_CURRENT_LEADSOURCE = 'SET_CURRENT_LEADSOURCE'
export const SYNC_LEADSOURCE = 'SYNC_LEADSOURCE'

export const SET_DEFAULT_PAGE_SIZE = 'SET_DEFAULT_PAGE_SIZE'

export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const SET_SEARCH_STATE = 'SET_SEARCH_STATE'
export const SET_SEARCH_QUERY = 'SET_SEARCH_QUERY'

export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR'
export const SET_TOOLBAR_STATE = 'SET_TOOLBAR_STATE'

export const TOGGLE_AUTO_CLOSE_MENU = 'TOGGLE_AUTO_CLOSE_MENU'

export const SET_ROLES = 'SET_ROLES'
export const SYNC_ROLE = 'SYNC_ROLE'

export const SET_VENDOR_PRODUCTS = 'SET_VENDOR_PRODUCTS'

export const SET_ALL_FEATURES = 'SET_ALL_FEATURES'

export const SET_BANKS = 'SET_BANKS'
export const SYNC_BANK = 'SYNC_BANK'

export const SET_CURRENT_TIMEZONE = 'SET_CURRENT_TIMEZONE'

export const UPDATE_USER_SETTING = 'UPDATE_USER_SETTING'

export const AUTH_REQUEST = 'AUTH_REQUEST'
export const AUTH_VERIFICATION_REQUEST = 'AUTH_VERIFICATION_REQUEST'
export const AUTH_ERROR = 'AUTH_ERROR'
export const AUTH_SUCCESS = 'AUTH_SUCCESS'
export const AUTH_LOGOUT = 'auth/AUTH_LOGOUT'

export const SET_CURRENT_USER_TRACKING_NUMBER = 'SET_CURRENT_USER_TRACKING_NUMBER'
export const SET_DEALER_RO_SERVICE_VEHICLE_MAKES = 'SET_DEALER_RO_SERVICE_VEHICLE_MAKES'
export const SET_RO_SERVICE_VEHICLE_MAKES_DEALER_ID = 'SET_RO_SERVICE_VEHICLE_MAKES_DEALER_ID'

export const SET_DEALER_PARTS_VEHICLE_MAKES = 'SET_DEALER_PARTS_VEHICLE_MAKES'
export const SET_PARTS_VEHICLE_MAKES_DEALER_ID = 'SET_PARTS_VEHICLE_MAKES_DEALER_ID'

export const SET_ALL_STATES = 'SET_ALL_STATES'

export const SET_SCHEDULED_PAYMENT_PAYEES = 'SET_SCHEDULED_PAYMENT_PAYEES'

export default {
  AUTH_REQUEST,
  AUTH_VERIFICATION_REQUEST,
  AUTH_LOGOUT,
  AUTH_ERROR,
  AUTH_SUCCESS,
  SET_CURRENT_USER,
  SET_CURRENT_DEALER,
  SYNC_USER,
  EXPAND_MENU,
  SET_SEARCH_RESULTS,
  SET_SEARCH_STATE,
  SET_SEARCH_QUERY,
  SET_MENU_ACTIVE_STATE,
  SET_ALL_INVENTORY,
  SET_INVENTORY_OVERVIEW,
  SET_DEALERS,
  SYNC_DEALER,
  SET_DEPARTMENTS,
  SYNC_DEPARTMENT,
  SET_DESIGNATIONS,
  SYNC_DESIGNATION,
  SET_VEHICLE_MAKES,
  SYNC_VEHICLE_MAKE,
  SET_CURRENT_INSURER,
  SET_INSURERS,
  SET_CURRENT_LOCATION,
  SET_LOCATIONS,
  SYNC_LOCATION,
  SET_LEADSOURCES,
  SET_CURRENT_LEADSOURCE,
  SYNC_LEADSOURCE,
  SET_DEFAULT_PAGE_SIZE,
  SET_USERS,
  SET_CURRENT_SUBSCRIBER,
  TOGGLE_SIDEBAR,
  SET_TOOLBAR_STATE,
  UPDATE_USER_SETTING,
  SET_CURRENT_LIST_PAGE,
  SET_VENDOR_PRODUCTS,
  SET_ROLES,
  SYNC_ROLE,
  SET_ALL_FEATURES,
  SET_BANKS,
  SYNC_BANK,
  SET_DEAL_TIME_RANGE,
  SET_PAYMENT_SCHEDULE_DEAL_TIME_RANGE,
  SET_ACCOUNTING_TIME_RANGE,
  TOGGLE_AUTO_CLOSE_MENU,
  SET_CURRENT_TIMEZONE,
  SET_DASHBOARD_TAB_INDEX,
  TOGGLE_DESIGN,
  SET_SYSTEM_READY,
  SET_SECURITY_TOKEN,
  SET_CURRENT_USER_TRACKING_NUMBER,
  SET_DEALER_RO_SERVICE_VEHICLE_MAKES,
  SET_RO_SERVICE_VEHICLE_MAKES_DEALER_ID,
  SET_DEALER_PARTS_VEHICLE_MAKES,
  SET_PARTS_VEHICLE_MAKES_DEALER_ID,
  SET_ALL_STATES,
  SET_SCHEDULED_PAYMENT_PAYEES
}

import mutationTypes from '../mutation-types.js'
import bankService from '../../services/bankService.js'

const state = {
  allBanks: []
}

const getters = {
  allBanks: state => {
    return state.allBanks
  }
}

const mutations = {
  [mutationTypes.SYNC_BANK] (state, bank) {
    var applicable = state.allBanks.find((x) => x.id === bank.id)
    if (applicable) {
      applicable = bank
    } else {
      state.allBanks.push(bank)
    }
  },
  [mutationTypes.SET_BANKS] (state, banks) {
    state.allBanks = banks
  }
}

const actions = {
  syncBank ({commit, state}, bank) {
    commit(mutationTypes.SYNC_BANK, bank)
  },
  setBanks ({commit, state}) {
    commit(mutationTypes.SET_BANKS, [])
    bankService.allBanks().then((data) => {
      commit(mutationTypes.SET_BANKS, data.results)
    }).catch((error) => {
      console.log(error)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <div v-if="hasAccessoryEventAccess" @click="onViewAccessoryEvent()">
    <b-icon pack="fal" icon="eye" custom-size="fa-sm" title="Click to view this accessory event."></b-icon>
    <portal to="global-modal-portal" v-if="isViewingAccessoryEvent">
      <div class="is-inline-block">
        <b-modal :active.sync="isViewingAccessoryEvent" scroll="keep" :has-modal-card="true">
          <div class="modal-card">
            <header class="modal-card-head">
              <p class="modal-card-title">{{viewAccessoryEventTitle}}</p>
            </header>
            <section class="modal-card-body">
              <form data-vv-scope="accessory-event-form" class="view-container">
                <div class="box">
                  <div class="columns is-mobile is-multiline">
                    <div class="column is-12">
                      <label class="label">Vehicle</label>
                      <div class="field" v-html="inventoryOverview"></div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Stock No</label>
                      <div class="field">
                        <a @click.stop.prevent="onStockNoClick(currentInventory)">
                          <span class="has-text-weight-semibold">{{ currentInventory.stockNo }}</span>
                        </a>
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Vin No</label>
                      <div class="field">
                        {{currentInventory.vinNo}}
                      </div>
                    </div>
                    <div class="column is-12">
                      <label class="label">Event Type</label>
                      <div :class="{'has-text-danger': isCheckOut, 'has-text-success': isCheckIn}" class="title is-size-6">
                        {{ isCheckOut ? 'Checked Out' : 'Checked In' }}
                      </div>
                    </div>
                    <div class="column is-12" v-if="isCheckOut">
                      <label class="label">Given To</label>
                      <div class="field">
                        {{accessoryEvent.givenTo}}
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Keys Status</label>
                      <div class="field">
                        {{accessoryEvent.keyStatus || 'None'}}
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Books Status</label>
                      <div class="field">
                        {{accessoryEvent.booksStatus || 'None'}}
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Car Cover Status</label>
                      <div class="field">
                        {{accessoryEvent.carCoverStatus || 'None'}}
                      </div>
                    </div>
                    <div class="column is-6">
                      <label class="label">Battery Charger Status</label>
                      <div class="field">
                        {{accessoryEvent.batteryChargerStatus || 'None'}}
                      </div>
                    </div>
                  </div>
                  <div class="columns" v-if="isCheckIn">
                    <div class="column is-12">
                      <div class="field">
                        <label class="label">Stored In Bins</label>
                        <div class="field">
                          {{accessoryEvent.binsDescription}}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onClose()" type="button">Ok</button>
            </footer>
          </div>
        </b-modal>
      </div>
    </portal>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'AccessoryEventView',
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    accessoryEvent: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      isViewingAccessoryEvent: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    availableKeyStatus: function () {
      return [
        {
          id: null,
          name: 'None',
          altName: 'None',
          ico: 'fas fa-check has-text-danger'
        },
        ...this.definedTypes.keyOptions
      ]
    },
    availableBooksStatus: function () {
      return [
        {
          id: null,
          name: 'None',
          altName: 'None',
          ico: 'fas fa-check has-text-danger'
        },
        ...this.definedTypes.bookOptions
      ]
    },
    availableCarCoverStatus: function () {
      return [
        {
          id: null,
          name: 'None',
          altName: 'None',
          ico: 'fas fa-check has-text-danger'
        },
        ...this.definedTypes.carCoverOptions
      ]
    },
    availableBatteryChargerStatus: function () {
      return [
        {
          id: null,
          name: 'None',
          altName: 'None',
          ico: 'fas fa-check has-text-danger'
        },
        ...this.definedTypes.batteryChargerOptions
      ]
    },
    currentAccessoryAction: function () {
      return this.selectedAccessoryEventType ? this.selectedAccessoryEventType.actionName : ''
    },
    inventoryOverview: function () {
      if (!this.currentInventory) {
        return ''
      }
      var result = ''
      result = this.currentInventory.stockNo
      result += '<br /> ' + this.currentInventory.name
      if (this.currentInventory.usage) {
        result += '<br /> ' + new Intl.NumberFormat().format(this.currentInventory.usage) + ' miles'
      } else {
        result += '<br /> Milage not captured.'
      }
      var exteriorColor = this.currentInventory.attributes.find((x) => x.name === 'ExteriorColor')
      var interiorColor = this.currentInventory.attributes.find((x) => x.name === 'InteriorColor')
      if (exteriorColor && exteriorColor.value) {
        result += '<br /> Ext. Color (' + exteriorColor.value + ')'
      }
      if (interiorColor && interiorColor.value) {
        result += '<br /> Int. Color (' + interiorColor.value + ')'
      }

      return result
    },
    viewAccessoryEventTitle: function () {
      return 'Viewing accessory event'
    },
    hasAccessoryEventAccess: function () {
      let allowedPermissions = ['inventoryaccessory.view']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    isCheckOut: function () {
      if (this.accessoryEvent !== null) {
        return this.accessoryEvent.type === 'CheckedOut'
      }
      return false
    },
    isCheckIn: function () {
      if (this.accessoryEvent !== null) {
        return this.accessoryEvent.type === 'CheckedIn'
      }
      return true
    }
  },
  methods: {
    onViewAccessoryEvent: function () {
      this.isViewingAccessoryEvent = true
    },
    onClose: function () {
      this.isViewingAccessoryEvent = false
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  watch: {
  },
  mounted: function () {
  }
}
</script>

<style scoped>
  .box {
      background-color: #f6f8fa;
    }
</style>

export default {
  all: [
    {
      name: 'Dealer 1',
      status: 'Active',
      id: 1
    },
    {
      name: 'Dealer 2',
      status: 'Active',
      id: 2
    },
    {
      name: 'Dealer 3',
      status: 'Active',
      id: 3
    }
  ]
}

<template>
  <span>
    <div v-if="!isTitleUpdateActive && !subscribeToOpenCloseEventListener && !showSendTitleConfirmation && wholesaleReceivableListDisplayIcon" class="is-inline-block">
      <span :id="instanceId" :name="instanceId" class="clickable" :class="getColorClassByTitleStatus(currentInventory)" @click.stop.prevent="displayTitle()" v-tooltip="titleStatusDescription" v-if="isListMode">
        <i :data-type="iconPostFix" class="fal fa-fw fa-file-invoice fa-lg"/>
      </span>
      <div :id="instanceId" :name="instanceId" v-else>
        <span :id="instanceId" :name="instanceId" :class="getColorClassByTitleStatus(currentInventory)" v-tooltip="titleStatusDescription" @click.stop.prevent="displayTitle()">
          <i :data-type="iconPostFix" class="icon fal fa-file-invoice fa-lg clickable"/>
        </span>
        <span v-if="label.length > 0">{{label}}</span>
      </div>
    </div>
    <inventory-lost-title
      ref="titleLostAction"
      :currentInventory="currentInventory"
      v-on:close-lost-title="onCloseLostTitle"
      :showDialog="showLostTitleAction"
      :key="'L' + currentInventory.id"
      :showButton="false"
      :order="3">
    </inventory-lost-title>
    <portal to="global-modal-portal" v-if="isTitleUpdateActive && activeDialog === 'TitleCapture'" :disabled="!usePortal" :order="1">
      <b-modal :key="instanceId" :active.sync="isTitleUpdateActive" :width="1200" scroll="keep" :full-screen="true" :has-modal-card="true"  v-if="currentInventory" :canCancel="false" id="batch-payment-request-modal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Title Status - Stock# <a @click.stop.prevent="onStockNoClick(currentInventory)"><span class="has-text-weight-semibold">{{ currentInventory.stockNo }}</span></a></p>
          </header>
          <section class="modal-card-body" style="padding:12px">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <form class="view-container" v-if="titleDataAvailable">
              <b-tabs size="is-small" :animated="false" type="is-toggle" v-model="tabIndex">
                <b-tab-item label="Documents" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                  <title-capture-detail v-model="currentTitleDetails" :disabled="false" :useValidator="thisValidator" :isBatchRequest="isBatchRequest"></title-capture-detail>
                </b-tab-item>
                <b-tab-item label="Send Titles" icon-pack="fas" icon="fas fa-history fa-sm" v-if="hasFrontTitleFile || hasBackTitleFile">
                  <form data-vv-scope="recipient-form">
                    <div class="columns is-multiline is-width-500 mt-10 ml-10">
                      <div class="column is-4">
                        <label class="label">Send Front Title</label>
                        <b-checkbox native-value="front" v-validate="'required'" :disabled="!hasFrontTitleFile" data-vv-scope="recipient-form" name="chkTitle" class="title-checkbox" size="is-medium" type="is-info" v-model="chkTitle"></b-checkbox>
                      </div>
                      <div class="column is-4">
                        <label class="label">Send Back Title</label>
                        <b-checkbox native-value="back" v-validate="'required'" :disabled="!hasBackTitleFile" data-vv-scope="recipient-form" name="chkTitle" class="title-checkbox" size="is-medium" type="is-info" v-model="chkTitle"></b-checkbox>
                      </div>
                      <div class="column is-4">
                        <label class="label">Watermark Title</label>
                        <b-checkbox name="watermarkTitle" class="title-checkbox" size="is-medium" type="is-info" v-model="isWatermark"></b-checkbox>
                      </div>
                      <div class="column is-12 title-error-msg" v-show="errors.has('recipient-form.chkTitle')">
                        <span class="help is-danger"><i class="fas fa-exclamation-triangle"></i> front or back title must be selected</span>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <label class="label">Email Address</label>
                          <div class="control has-icons-left has-icons-right">
                            <input name="email" data-vv-as="email address" data-vv-scope="recipient-form" v-validate="{'email': true, 'required': true}" data-vv-delay="1000" :class="{ 'input': true, 'is-danger': errors.has('recipient-form.email') }" type="text" placeholder="Email" v-model="email" data-lpignore="true" autocomplete="off">
                            <span v-show="errors.has('recipient-form.email')" class="help is-danger">
                              <i v-show="errors.has('recipient-form.email')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.email') }}
                            </span>
                            <span class="icon is-small is-left">
                              <i class="fas fa-envelope"></i>
                            </span>
                            <span class="icon is-small is-right" v-show="!errors.has('recipient-form.email')">
                              <i class="fas fa-check"></i>
                            </span>
                            <span class="icon is-small is-right" v-show="errors.has('recipient-form.email')">
                              <i class="fas fa-exclamation-triangle"></i>
                            </span>
                          </div>
                          <span v-show="mustShowEmailError" class="help is-danger"><i v-show="mustShowEmailError" class="fas fa-exclamation-triangle"></i> {{ emailMatchErrorMessage }}</span>
                        </div>
                      </div>
                      <div class="column is-12">
                        <div class="field">
                          <label class="label">Mobile</label>
                          <div class="control">
                            <input name="mobile" v-validate="{ 'min': 16, 'max': 16, 'required': false }" data-vv-as="mobile" :class="{ 'input': true, 'is-danger': errors.has('recipient-form.mobile') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="mobile" data-vv-scope="recipient-form" data-lpignore="true" autocomplete="off" @blur="checMobile">
                              <span v-show="errors.has('recipient-form.mobile')" class="help is-danger">
                              <i v-show="errors.has('recipient-form.mobile')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.mobile') }}
                            </span>
                          </div>
                          <span v-show="mustShowMobileError" class="help is-danger"><i v-show="mustShowMobileError" class="fas fa-exclamation-triangle"></i> {{ mobileInvalidFormat }}</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </b-tab-item>
              </b-tabs>
            </form>
            <div style="text-align: center;" v-else>
              Loading inventory title information...
              <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
            </div>
          </section>
          <footer class="modal-card-foot">
            <div class="columns is-mobile" style="width: 100%;">
              <div class="column is-6">
                <button v-if="isDocumentTab" class="button is-primary" :class="{'is-loading': isSaving }"  @click="validateBeforeSubmit()">Save</button>
                <button v-if="!isDocumentTab" class="button is-info" :class="{'is-loading': isSaving }" @click="sendTitleDocuments()">Send</button>
                <button class="button is-danger" @click="onTitleCaptureCancelled()">Close</button>
              </div>
              <div class="column is-6">
                <button class="button is-danger" @click="onTitleCaptureSkipped()" v-if="allowTitleSkip && isDocumentTab">Skip all and complete later</button>
              </div>
            </div>
          </footer>
        </div>
      </b-modal>
    </portal>
    <portal to="global-modal-portal" v-if="showSendTitleConfirmation && activeDialog === 'SendTitle'" :order="2">
      <b-modal :active.sync="showSendTitleConfirmation" :width="640" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Send Lost Title Agreement</p>
          </header>
          <section class="modal-card-body">
            {{getSendLostTitleHeader}}
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="sendLostTitle()">Yes</button>
            <button class="button" @click="dontSendLostTitle()">No</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </span>
</template>

<script>

import eventBus from '@/eventBus'
import formattingMixin from '@/mixins/inventory/formatting'
import utilitiesMixin from '@/mixins/generic/utilities'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import inventoryService from '@/services/inventoryService'
import TitleCaptureDetail from '@/components/generic/inventory/InventoryTitleCaptureDetail'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import InventoryLostTitleAction from '@/components/inventory/InventoryLostTitleAction.vue'
import logger from '@/services/loggingService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'

import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'InventoryTitleCapture',
  mixins: [utilitiesMixin, formattingMixin, dealInventoryStyleClasses, validateBeforeSubmitMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'title-capture-detail': TitleCaptureDetail,
    'inventory-lost-title': InventoryLostTitleAction
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: 'is-width-800'
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: true
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    allowTitleSkip: {
      type: Boolean,
      default: false
    },
    subscribeToOpenCloseEventListener: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    isBatchRequest: {
      type: Boolean,
      default: false
    },
    wholesaleReceivableListMode: {
      type: Boolean,
      default: false
    },
    wholesaleReceivableInventoryId: {
      type: Number,
      default: null
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isLoading: false,
      isTitleUpdateActive: false,
      showSendTitleConfirmation: false,
      showLostTitleAction: false,
      responseInventory: null,
      email: '',
      mobile: '',
      tabIndex: 0,
      mobileInvalidFormat: '',
      serverErrors: [],
      serverErrorMessage: '',
      emailMatchErrorMessage: '',
      isWatermark: true,
      isMobile: window.innerWidth <= 700,
      chkTitle: []
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    titleDocsApplicable: function () {
      let result = ![2, 6].includes(this.currentInventory.typeId)
      return result
    },
    titleStatusDescription: function () {
      if ([2, 6].includes(this.currentInventory.typeId)) return 'Title not applicable to this inventory type'
      if (this.currentInventory.titleUploadStatus === -1) return 'Not Applicable'

      const titleStatus = this.currentInventory.titleStatus
      if (titleStatus === 'Yes') {
        if (this.currentInventory.titleUploadStatus === 0) return 'Title Status: Not Received'
        if (this.currentInventory.titleUploadStatus === 1) return 'Title Status: 1 of 2 Received'
        if (this.currentInventory.titleUploadStatus === 2) return 'Title Status: 2 of 2 Received'
      }

      if (titleStatus === 'PayOff') {
        if (this.currentInventory.payoffUploadStatus === 0) return 'PayOff Status: Not Received'
        if (this.currentInventory.payoffUploadStatus === 1) return 'PayOff Status: 1 of 2 Received'
        if (this.currentInventory.payoffUploadStatus === 2) return 'PayOff Status: 2 of 2 Received'
      }

      if (titleStatus === 'Lost') {
        if (this.currentInventory.lostAffidavitUploadStatus === 0) return 'Lost Affidavit Status: Not Received'
        if (this.currentInventory.lostAffidavitUploadStatus === 1) return 'Lost Affidavit: 1 of 1 Received'
      }

      if (titleStatus === 'Pending') {
        return 'Title Status: Not Received'
      }
    },
    iconPostFix: function () {
      const titleStatus = this.currentInventory.titleStatus

      if (this.currentInventory.originalTitleReceived) {
        return 'H'
      } else {
        if (titleStatus === 'PayOff') {
          return 'P'
        }

        if (titleStatus === 'Lost') {
          return 'L'
        }
      }
      return false
    },
    titleDataAvailable: function () {
      if (!this.isLoading || (this.currentInventory !== undefined && this.currentInventory.attachments !== undefined && this.currentInventory.attachments.length > 0 && this.currentInventory.attributes !== undefined && this.currentInventory.attributes.length > 0)) {
        return true
      }
      return false
    },
    currentTitleDetails: function () {
      let result = {
        sameTitle: this.getAttributeValue('SameTitle', 'false').toLowerCase() === 'true',
        titleReassigned: this.getAttributeValue('TitleReassigned', 'false').toLowerCase() === 'true',
        lienholderListed: this.currentInventory.isPayOff && this.currentInventory.payOffRequired ? true : this.getAttributeValue('LienholderListed', 'false').toLowerCase() === 'true',
        lienholderSatisfied: this.getAttributeValue('LienholderSatisfied', 'false').toLowerCase() === 'true',
        payOff: this.getAttributeValue('LienholderSatisfied', 'false').toLowerCase() === 'false',
        vehicleControl: this.currentInventory.possessionStatus === 'Arrived' ? true : this.getAttributeValue('VehicleControl', 'false').toLowerCase() === 'true',
        vehicleArrived: this.currentInventory.possessionStatus === 'Arrived',
        originalTitleReceived: this.currentInventory.originalTitleReceived,
        frontTitleFiles: this.constructFileList('TitleFront'),
        backTitleFiles: this.constructFileList('TitleBack'),
        payOffInfoFiles: this.constructFileList('PayOffInfo'),
        lostTitleAffidavitFiles: this.constructFileList('LostTitleAffidavit'),
        payOffLienSatisfactionFiles: this.constructFileList('PayOffLienSatisfaction'),
        inventoryHasTitleHolder: this.currentInventory.titleStatus === 'PayOff',
        inventoryId: this.currentInventory.id,
        possessionStatus: this.currentInventory.possessionStatus,
        titleStatus: this.currentInventory.titleStatus,
        bankId: this.currentInventory.bankId,
        bankName: this.currentInventory.bankName,
        accNo: this.currentInventory.accNo,
        payoffAmount: this.currentInventory.payoffAmount,
        monthlyPaymentDue: this.currentInventory.monthlyPaymentDue,
        goodUntilDate: this.currentInventory.goodUntilDate,
        purchasedFromName: this.currentInventory.purchasedFromName,
        titleReceivedStatusModifiedByFullName: this.currentInventory.titleReceivedStatusModifiedByFullName,
        titleReceivedStatusModifiedAt: this.currentInventory.titleReceivedStatusModifiedAt
      }
      return result
    },
    getSendLostTitleHeader: function () {
      if (this.currentInventory && this.currentInventory.purchasedFromName) {
        let message = 'Would you like to send lost title agreement to ' + this.currentInventory.purchasedFromName + '?'
        return message
      }
      return 'Would you like to send lost title agreement?'
    },
    activeDialog: function () {
      let result = ''
      if (this.showSendTitleConfirmation) result = 'SendTitle'
      else if (this.showLostTitleAction) result = 'LostTitle'
      else if (this.isTitleUpdateActive) result = 'TitleCapture'
      else result = ''
      logger.logWithGroup('Active Dialog', result)
      return result
    },
    mustShowEmailError: function () {
      return this.emailMatchErrorMessage !== '' && this.emailMatchErrorMessage !== ' ' && this.emailMatchErrorMessage !== undefined && this.emailMatchErrorMessage !== null
    },
    mustShowMobileError: function () {
      return this.mobileInvalidFormat !== '' && this.mobileInvalidFormat !== ' ' && this.mobileInvalidFormat !== undefined && this.mobileInvalidFormat !== null
    },
    emailHasText: function () {
      return this.email !== '' && this.email !== ' ' && this.email !== undefined && this.email !== null
    },
    mobileHasText: function () {
      return this.mobile !== '' && this.mobile !== ' ' && this.mobile !== undefined && this.mobile !== null
    },
    hasFrontTitleFile: function () {
      return this.currentInventory && this.currentInventory.attachments.filter((x) => x.status === 'Active' && x.classType === 'TitleFront').length > 0
    },
    hasBackTitleFile: function () {
      return this.currentInventory && this.currentInventory.attachments.filter((x) => x.status === 'Active' && x.classType === 'TitleBack').length > 0
    },
    isDocumentTab: function () {
      return this.tabIndex === 0
    },
    wholesaleReceivableListDisplayIcon: function () {
      if (this.wholesaleReceivableListMode) {
        if ((this.wholesaleReceivableInventoryId && this.wholesaleReceivableInventoryId > 0)) {
          return true
        }
        return false
      }
      return true
    }
  },
  methods: {
    constructFileList: function (type) {
      let document = this.findInventoryAttachment(type)
      if (document) {
        return [{
          id: document.id,
          loaded: true,
          imageData: document.fileLocation,
          fileName: document.fileName,
          uniqueId: document.fileName,
          fileType: this.getFileType(document.fileLocation),
          fileLocation: document.fileLocation,
          name: document.name,
          classType: type,
          verified: document.verified,
          verifiedAt: document.verifiedAt,
          verifiedByName: document.verifiedByName,
          createdBy: document.createdBy,
          createdAt: document.createdAt
        }]
      }
      return []
    },
    findInventoryAttachment: function (type) {
      if (this.currentInventory && this.currentInventory.attachments && this.currentInventory.attachments.filter((y) => y.classType === type).length > 0) {
        var allDocs = this.currentInventory.attachments.filter((y) => y.classType === type)
        if (allDocs && allDocs.length > 0) {
          return allDocs[allDocs.length - 1]
        }
      }
      return null
    },
    onTitleCaptureCancelled: function () {
      this.resetDefaults()
      this.isTitleUpdateActive = false
      this.currentInventory.actionCancelled = true
      this.$emit('title-capture-complete', this.currentInventory)
    },
    onTitleCaptureSkipped: function () {
      this.isTitleUpdateActive = false
      this.$emit('title-capture-skipped', true)
    },
    getAttributeValue: function (attrName, defaultValue) {
      if (this.currentInventory && this.currentInventory.attributes) {
        let attribute = this.currentInventory.attributes.find((x) => x.name === attrName)
        if (attribute && attribute.value !== '') {
          return attribute.value
        }
      }
      return defaultValue
    },
    onUpdateTitleStatus: function () {
      if (this.titleDocsApplicable) {
        this.isTitleUpdateActive = true
        this.loadTitleInfo()
      }
    },
    onSave: function () {
      var updateInventory = {
        id: this.currentInventory.id,
        originalTitleReceived: false,
        attributes: [],
        attachments: []
      }
      updateInventory.titleStatus = this.currentTitleDetails.titleStatus
      if (updateInventory.titleStatus === 'PayOff') {
        updateInventory.bankId = this.currentTitleDetails.bankId
        updateInventory.bankName = this.currentTitleDetails.bankName
        updateInventory.accNo = this.currentTitleDetails.accNo
        updateInventory.payoffAmount = this.currentTitleDetails.payoffAmount
        updateInventory.monthlyPaymentDue = this.currentTitleDetails.monthlyPaymentDue
        updateInventory.goodUntilDate = this.currentTitleDetails.goodUntilDate
      }

      updateInventory.originalTitleReceived = this.currentTitleDetails.originalTitleReceived
      updateInventory.attributes.push({name: 'SameTitle', value: this.currentTitleDetails.sameTitle})
      updateInventory.attributes.push({name: 'TitleReassigned', value: this.currentTitleDetails.titleReassigned})
      updateInventory.attributes.push({name: 'LienholderListed', value: this.currentTitleDetails.lienholderListed})
      updateInventory.attributes.push({name: 'LienholderSatisfied', value: this.currentTitleDetails.lienholderSatisfied})
      updateInventory.attributes.push({name: 'VehicleControl', value: this.currentTitleDetails.vehicleControl})

      if (this.currentTitleDetails.frontTitleFiles.length > 0) {
        var frontTitleFile = this.currentTitleDetails.frontTitleFiles.map((x) => {
          return {
            id: x.id && x.id > 0 ? x.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'TitleFront',
            verified: x.verified ? x.verified : false,
            isClone: x.isClone
          }
        })

        updateInventory.attachments.push(...frontTitleFile)
      }

      if (this.currentTitleDetails.backTitleFiles.length > 0) {
        var backTitleFile = this.currentTitleDetails.backTitleFiles.map((x) => {
          return {
            id: x.id && x.id > 0 ? x.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'TitleBack',
            verified: x.verified ? x.verified : false,
            isClone: x.isClone
          }
        })

        updateInventory.attachments.push(...backTitleFile)
      }

      if (this.currentTitleDetails.payOffInfoFiles.length > 0) {
        var payOffInfoFile = this.currentTitleDetails.payOffInfoFiles.map((x) => {
          return {
            id: x.id && x.id > 0 ? x.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'PayOffInfo',
            verified: x.verified ? x.verified : false,
            isClone: x.isClone
          }
        })

        updateInventory.attachments.push(...payOffInfoFile)
      }

      if (this.currentTitleDetails.lostTitleAffidavitFiles.length > 0) {
        var lostTitleAffidavitFile = this.currentTitleDetails.lostTitleAffidavitFiles.map((x) => {
          return {
            id: x.id && x.id > 0 ? x.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'LostTitleAffidavit',
            verified: x.verified ? x.verified : false
          }
        })

        updateInventory.attachments.push(...lostTitleAffidavitFile)
      }

      if (this.currentTitleDetails.payOffLienSatisfactionFiles.length > 0) {
        var payOffLienSatisfactionFile = this.currentTitleDetails.payOffLienSatisfactionFiles.map((x) => {
          return {
            id: x.id && x.id > 0 ? x.id : 0,
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'PayOffLienSatisfaction',
            verified: x.verified ? x.verified : false,
            isClone: x.isClone
          }
        })

        updateInventory.attachments.push(...payOffLienSatisfactionFile)
      }

      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      let that = this
      inventoryService.updateTitleStatus(updateInventory).then(response => {
        that.isSaving = false
        if (response.errors) {
          that.serverErrors = response.errors
        } else {
          that.currentInventory.titleUploadStatus = response.inventory.titleUploadStatus
          that.currentInventory.attachments = response.inventory.attachments
          that.isTitleUpdateActive = false
          if (updateInventory.titleStatus === 'Lost' && that.currentTitleDetails.lostTitleAffidavitFiles.length === 0) {
            that.showSendTitleConfirmation = true
            that.responseInventory = response.inventory
          } else {
            eventBus.$emit('inventory-changed', [response.inventory])
            that.$emit('title-capture-complete', response.inventory)
          }
          this.resetDefaults()
        }
      }).catch((error) => {
        that.serverErrorMessage = error.message
        that.isSaving = false
      })
    },
    loadTitleInfo: function () {
      if (!this.currentInventory.attachments || this.currentInventory.attachments.length === 0 || !this.currentInventory.attributes || this.currentInventory.attributes.length === 0) {
        this.isLoading = true
        this.email = ''
        this.mobile = ''
        inventoryService.getTitleInfo(this.currentInventory).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.$set(this.currentInventory, 'titleStatus', response.inventory.titleStatus)
            this.$set(this.currentInventory, 'bankId', response.inventory.bankId)
            this.$set(this.currentInventory, 'bankName', response.inventory.bankName)
            this.$set(this.currentInventory, 'payoffAmount', response.inventory.payoffAmount)
            this.$set(this.currentInventory, 'monthlyPaymentDue', response.inventory.monthlyPaymentDue)
            this.$set(this.currentInventory, 'goodUntilDate', response.inventory.goodUntilDate)
            this.$set(this.currentInventory, 'accNo', response.inventory.accNo)
            this.$set(this.currentInventory, 'originalTitleReceived', response.inventory.originalTitleReceived)
            this.$set(this.currentInventory, 'attachments', response.inventory.attachments)
            this.$set(this.currentInventory, 'attributes', response.inventory.attributes)
          }
          this.isLoading = false
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isLoading = false
        })
      }
    },
    sendLostTitle () {
      this.showSendTitleConfirmation = false
      this.showLostTitleAction = true
    },
    dontSendLostTitle () {
      this.showSendTitleConfirmation = false
      eventBus.$emit('inventory-changed', [this.responseInventory])
      this.$emit('title-capture-complete', this.responseInventory)
      this.responseInventory = null
    },
    onCloseLostTitle () {
      this.showLostTitleAction = false
      eventBus.$emit('inventory-changed', [this.responseInventory])
      this.$emit('title-capture-complete', this.responseInventory)
      this.responseInventory = null
    },
    checMobile: function () {
      this.mobileInvalidFormat = ''
      if (this.mobileHasText && this.mobile.length !== 16) {
        this.mobileInvalidFormat = 'Invalid format for mobile number:  ' + this.mobile + '. Mobile format should be +1(xxx)-xxx-xxxx'
        this.isSendAllowed = false
      } else if (this.mobileHasText && this.mobile.length === 16) {
        this.isSendAllowed = true
      }
    },
    async sendTitleDocuments () {
      this.$validator.validateAll('recipient-form').then(async (result) => {
        if (result) {
          let isEmailVerified = false
          const validateEmail = async () => {
            if (this.emailHasText) {
              let blacklistEmailCheckModel = {
                subscriberId: this.getCurrentSubscriber.id,
                emailAddresses: [ this.email ],
                errorMessage: ''
              }
              await inventoryService.getVerifiedEmailList(blacklistEmailCheckModel).then(response => {
                blacklistEmailCheckModel = response.blacklistEmailCheck
                if (blacklistEmailCheckModel.emailAddresses.length > 0) {
                  this.email = blacklistEmailCheckModel.emailAddresses[0]
                  isEmailVerified = true
                  return
                }

                if (blacklistEmailCheckModel.errorMessage !== '' || blacklistEmailCheckModel.errorMessage !== null) {
                  this.emailMatchErrorMessage = blacklistEmailCheckModel.errorMessage
                }
                isEmailVerified = false
              }).catch((error) => {
                this.serverErrorMessage = error.message
                isEmailVerified = false
              })
            }
          }

          await validateEmail()
          if (isEmailVerified) {
            await this.processSendTitleDocuments()
          }
        } else {
          this.$goToFirstValidationError(this.$validator.errors.items)
        }
      })
    },
    async processSendTitleDocuments () {
      var sendTitleDocumentModel = {
        inventoryId: this.currentInventory.id,
        sendFrontTitle: this.chkTitle.includes('front'),
        sendBackTitle: this.chkTitle.includes('back'),
        isWatermark: this.isWatermark,
        emailTo: this.email,
        smsTo: this.mobile
      }
      this.isSaving = true
      inventoryService.sendTitleDocuments(sendTitleDocumentModel).then(response => {
        if (response.errors) {
          this.isSaving = false
          this.serverErrors = response.errors
          this.failedToast('Oops! Something went wrong.')
        } else {
          this.isSaving = false
          this.tabIndex = 0
          this.successToast('Selected titles sent.')
        }
      }).catch((error) => {
        this.failedToast('Oops! Something went wrong.')
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    resetDefaults: function () {
      this.chkTitle = []
      this.tabIndex = 0
      this.serverErrors = []
      this.serverErrorMessage = ''
      this.email = ''
      this.mobile = ''
      this.mobileInvalidFormat = ''
      this.emailMatchErrorMessage = ''
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    },
    displayTitle: function () {
      if (this.wholesaleReceivableListMode) {
        if (this.currentInventory && this.currentInventory.inventoryId) {
          this.loadInventory(this.currentInventory.inventoryId)
        } else {
          this.failedToast('Error, this wholesale receivable entry\'s stock number does not correspond to any existing inventory.')
        }
      } else {
        this.onUpdateTitleStatus()
      }
    },
    loadInventory: function (inventoryId) {
      this.isLoading = true
      let that = this
      inventoryService.single(inventoryId).then(response => {
        that.currentInventory = response.inventory
        that.isLoading = false
        that.onUpdateTitleStatus()
      }).catch((error) => {
        that.serverErrorMessage = error.message
        that.isLoading = false
      })
    }
  },
  watch: {
    tabIndex: function (newValue, oldValue) {
      if (newValue && newValue === 1) {
        if (this.hasFrontTitleFile) this.chkTitle.push('front')
        if (this.hasBackTitleFile) this.chkTitle.push('back')
      } else {
        this.resetDefaults()
      }
    }
  },
  mounted: function () {
    if (this.subscribeToOpenCloseEventListener) {
      eventBus.$on('onUpdateTitleStatus', this.onUpdateTitleStatus)
    }
    if (this.showDialog === true) {
      this.onUpdateTitleStatus()
    }
  },
  beforeDestroy () {
    if (this.subscribeToOpenCloseEventListener) {
      eventBus.$off('onUpdateTitleStatus', this.onUpdateTitleStatus)
    }
  },
  updated: function () {
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .el-steps--simple {
    padding-left :0;
    padding-right :0;
    padding-bottom :0;
  }

  .title-checkbox {
    margin-top:5px;
    vertical-align: middle;
  }

  .title-error-msg {
    margin-top: -10px;
  }
</style>

<template>
    <div class="is-inline-block">
      <div class="control quick-action" v-if="canSendVehicleVerification">
        <a class="button" target="_blank" @click="updateVehicleVerificationModalState(true)" title="Click to send out vehicle verification to supplier.">
          <b-icon pack="fal" icon="credit-card-front" custom-size="fa-lg"></b-icon>
        </a>
      </div>
      <portal to="global-modal-portal" v-if="isVehicleVerification">
        <b-modal :active.sync="isVehicleVerification" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <div class="subtitle is-6 has-text-centered has-text-info is-marginless">Send Vehicle Verification to Supplier</div>
            </header>
            <section class="modal-card-body view-container" style="padding: 12px; width: 769px !important;">
              <div class="columns">
                <div class="column is-12">
                  <form data-vv-scope="recipient-form">
                    <div class="field">
                      <label class="label">Recipients<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <multiselect
                        :multiple="true"
                        :taggable="true"
                        v-validate="{'required': true}"
                        data-vv-scope="recipient-form"
                        data-vv-as="recipient"
                        data-vv-validate-on="input|close"
                        :class="{'is-danger': errors.has('recipient-form.recipients') }"
                        @tag="onRecipientAdded"
                        name="recipients"
                        label="name"
                        trackBy="email"
                        tagPlaceholder="Enter to add email address"
                        v-model="selectedRecipients"
                        :options="selectedRecipients"
                        :disabled="false">
                      </multiselect>
                      <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                    </div>
                  </form>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Subject</label>
                    <div class="control">
                      <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Message (optional)</label>
                    <div class="control">
                      <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot">
              <div class="columns" style="width: 100%">
                <div class="column is-12">
                  <button class="button is-primary" @click="onEmailVehicleVerification(currentInventory)" type="button">Send</button>
                  <button class="button is-danger" @click="updateVehicleVerificationModalState(false)" type="button">Cancel</button>
                </div>
              </div>
            </footer>
          </div>
        </b-modal>
      </portal>
    </div>
</template>

<script>
import inventoryService from '@/services/inventoryService'
import supplierService from '@/services/supplierService'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'SupplierVehicleVerificationAction',
  mixins: [validateBeforeSubmitMixin],
  components: {
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isVehicleVerification: false,
      instanceId: this._uid,
      selectedRecipients: [],
      optionalMessage: '',
      overridingSubject: '',
      currentSupplier: null
    }
  },
  computed: {
    ...mapUserGetters(['currentUser']),
    ...mapDealerState(['currentDealer']),
    canSendVehicleVerification: function () {
      return this.currentInventory.purchasedFromType === 'Wholesaler'
    }
  },
  methods: {
    onRecipientAdded: function (data) {
      var recipient = {
        name: data,
        email: data
      }

      this.selectedRecipients.push(recipient)
    },
    clearAllRecipients: function () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedRecipients = []
    },
    updateVehicleVerificationModalState: function (state) {
      this.isVehicleVerification = state

      if (state === true) {
        this.overridingSubject = 'Vin: ' + this.currentInventory.vinNo + ' - Complete to receive payment'
        this.optionalMessage = 'Click this link to upload the title and we will expedite payment.'

        if (this.currentInventory.purchasedFromId) {
          supplierService.single(this.currentInventory.purchasedFromId).then(response => {
            this.currentSupplier = response.supplier

            if (this.currentSupplier && (this.currentSupplier.email && this.currentSupplier.email !== '')) {
              this.availableAddresses = [{
                email: this.currentSupplier.email,
                name: this.currentSupplier.name ? this.currentSupplier.name + '(' + this.currentSupplier.email + ')' : this.currentSupplier.email
              }]
              this.selectedRecipients = this.availableAddresses
            } else {
              this.selectedRecipients = []
            }

            this.isLoading = false
          }).catch((error) => {
            this.isLoading = false
            this.serverErrorMessage = error.message
          })
        }
      }

      if (state === false) {
        this.clearAllRecipients()
      }
    },
    onEmailVehicleVerification: function (supplier) {
      var emailAddresses = this.selectedRecipients.map(e => e.email).join(', ')

      var vehicleVerificationRequestModel = {
        supplierId: this.currentSupplier.id,
        userId: this.currentUser.id,
        dealerId: this.currentDealer.id,
        inventoryId: this.currentInventory.id,
        toEmailAddress: emailAddresses,
        subject: this.overridingSubject,
        content: this.optionalMessage
      }

      inventoryService.createVehicleVerificationRequest(vehicleVerificationRequestModel).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.clearAllRecipients()
          this.isVehicleVerification = false
        }
      }).catch((error) => {
        throw error
      })
    }
  }
}
</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }
</style>

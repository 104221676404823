import mutationTypes from '../mutation-types'
import roServiceService from '../../services/roServiceService'

const state = {
  dealerRoServiceVehicleMakes: [],
  roServiceVehicleMakesDealerId: 0
}

const getters = {
  dealerRoServiceVehicleMakes: state => {
    return state.dealerRoServiceVehicleMakes
  },
  roServiceVehicleMakesDealerId: state => {
    return state.roServiceVehicleMakesDealerId
  }
}

const mutations = {
  [mutationTypes.SET_DEALER_RO_SERVICE_VEHICLE_MAKES] (state, roServiceVehicleMakes) {
    state.dealerRoServiceVehicleMakes = roServiceVehicleMakes
  },
  [mutationTypes.SET_RO_SERVICE_VEHICLE_MAKES_DEALER_ID] (state, dealerId) {
    state.roServiceVehicleMakesDealerId = dealerId
  }
}

const actions = {
  setDealerRoServiceVehicleMakes ({commit, state}, searchCriteria) {
    commit(mutationTypes.SET_DEALER_RO_SERVICE_VEHICLE_MAKES, [])
    commit(mutationTypes.SET_RO_SERVICE_VEHICLE_MAKES_DEALER_ID, searchCriteria.dealerId)
    roServiceService.getDistinctVehicleMakeNamesByDealer(searchCriteria).then((data) => {
      commit(mutationTypes.SET_DEALER_RO_SERVICE_VEHICLE_MAKES, data)
    }).catch((error) => {
      console.log(error)
    }
    )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

<template>
  <span>
    <section class="modal-card-body">
      <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
      <div class="columns" :style="(openIn === 'modal') ? 'max-height: 500px' : ''">
        <div class="column">
          <div class="timeline" v-if="hasSmsConversation">
            <br/>
            <div class="row">
              <span class="timeline-header"><span class="tag is-small is-primary">Newer</span></span>
              <i class="fas fa-sync is-pulled-right refreshBtn" @click="refreshData" :class="{'fa-spin': isLoading}"></i>
            </div>
            <div class="timeline-item" v-for="sms in limitBy(this.smsConversation, this.conversationRange)" :key="sms.id">
              <div class="timeline-marker is-icon" :class="getSmsIconColour(sms.isSent)">
                <i :class="getSmsIcon(sms.isSent)" :title="getSmsStatusTitle(sms.type, sms.isSent)"></i>
              </div>
              <div class="timeline-content">
                <p class="heading is-inline-block">{{sms.sendAt | formatHumanDate}} - Mobile: {{(sms.type === 'inbound') ? sms.customerMobile : sms.agentMobile}}</p>
                <article class="message is-size-7" :class="getArticalClassBySmsType(sms.type)">
                  <div class="message-body sms-text-block">
                    {{sms.content}}
                    <file-selector
                      v-if="sms.mediaImages.length > 0"
                      v-model="sms.mediaImages"
                      label=""
                      :required="false"
                      :multiple="false"
                      :isBoxed="false"
                      :enabled="false"
                      :showFileOnly="true">
                    </file-selector>
                  </div>
                </article>
              </div>
            </div>
            <header class="timeline-header">
              <span class="tag is-small is-primary" style="cursor: pointer" @click="showMoreSmsConversation" v-if="hasMoreSmsConversation">Older...</span>
              <span class="tag is-small is-primary" @click="showMoreSmsConversation" v-else>Older</span>
            </header>
          </div>
          <div v-else class="column is-12 is-size-7 has-text-info">
            <p>No Conversation yet</p>
            <i class="fas fa-sync is-pulled-right refreshBtn" @click="refreshData" :class="{'fa-spin': isLoading}"></i>
          </div>
        </div>
      </div>
    </section>

    <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
  </span>
</template>

<script>

import notificationService from '@/services/notificationService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import utilitiesMixin from '@/mixins/generic/utilities'
import FileSelector from '@/components/generic/FileSelector'
import Vue2Filters from 'vue2-filters'

export default {
  name: 'SmsConversationControl',
  mixins: [Vue2Filters.mixin, utilitiesMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'file-selector': FileSelector
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    searchBy: {
      type: String,
      default: 'deal'
    },
    openIn: {
      type: String,
      default: 'modal'
    }
  },
  data () {
    return {
      defaultConversationRange: 5,
      conversationRange: 5,
      isLoading: false,
      smsConversation: null,
      serverErrors: [],
      serverErrorMessage: '',
      valueId: (this.value) ? this.value.id : 0,
      deal: (this.searchBy === 'deal') ? this.value : null,
      customer: (this.searchBy === 'customer') ? this.value : null,
      supplier: (this.searchBy === 'supplier') ? this.value : null
    }
  },
  computed: {
    hasSmsConversation: function () {
      return this.smsConversation && this.smsConversation.length > 0
    },
    hasMoreSmsConversation: function () {
      return this.smsConversation.length > this.conversationRange
    }
  },
  methods: {
    showMoreSmsConversation: function () {
      this.conversationRange += this.defaultConversationRange
    },
    fetchConversationByDeal: function () {
      this.isLoading = true
      notificationService.getConversationByDealId(this.deal.id).then(response => {
        this.isLoading = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.smsConversation = response
          this.formatMedia()
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
      })
    },
    fetchConversationByCustomer: function () {
      this.isLoading = true
      notificationService.getConversation(this.customer.mobile, '').then(response => {
        this.isLoading = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.smsConversation = response
          this.formatMedia()
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
      })
    },
    fetchConversationBySupplier: function () {
      this.isLoading = true
      notificationService.getConversationBySupplierId(this.supplier.id).then(response => {
        this.isLoading = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.smsConversation = response
          this.formatMedia()
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
      })
    },
    getArticalClassBySmsType: function (type) {
      if (type === 'inbound') {
        return 'is-link'
      }
      return 'is-warning'
    },
    getSmsIconColour: function (isSent) {
      if (isSent) {
        return 'is-success'
      } else {
        return 'is-danger'
      }
    },
    getSmsIcon: function (isSent) {
      if (isSent) {
        return 'fal fa-comment-check'
      } else {
        return 'fal fa-comment-exclamation'
      }
    },
    getSmsStatusTitle: function (type, isSent) {
      if (type === 'inbound') {
        if (isSent) {
          return 'sms received'
        } else {
          return 'sms not received'
        }
      }

      if (isSent) {
        return 'sms sent'
      } else {
        return 'sms not sent'
      }
    },
    formatMedia: function () {
      if (this.hasSmsConversation) {
        this.smsConversation.forEach((x) => {
          let mediaImages = []
          if (x.media) {
            let mediaImage = {
              loaded: true,
              imageData: x.media,
              fileType: this.getFileType(x.media)
            }
            mediaImages.push(mediaImage)
          }
          x.mediaImages = mediaImages
        })
      }
    },
    refreshData: function () {
      if (this.deal && this.searchBy === 'deal') {
        this.fetchConversationByDeal()
      } else if (this.customer && this.searchBy === 'customer') {
        this.fetchConversationByCustomer()
      } else if (this.supplier && this.searchBy === 'supplier') {
        this.fetchConversationBySupplier()
      }
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      if (this.customer !== null && this.searchBy === 'customer' && oldValue !== null && newValue.id !== oldValue.id) {
        this.customer = newValue
        this.fetchConversationByCustomer()
      } else if (this.deal !== null && this.searchBy === 'deal' && oldValue !== null && newValue.id !== oldValue.id) {
        this.deal = newValue
        this.fetchConversationByDeal()
      } else if (this.supplier !== null && this.searchBy === 'supplier' && oldValue !== null && newValue.id !== oldValue.id) {
        this.supplier = newValue
        this.fetchConversationBySupplier()
      }
    }
  },
  mounted: function () {
    this.refreshData()
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .box {
    background-color: #f6f8fa;
  }

  .refreshBtn {
    margin-top: -13px;
    font-size:0.8rem;
    cursor: pointer;
  }

</style>

import axios from 'axios'
import baseService from './baseService'
import serviceVideoData from './data/serviceVideo'

const serviceVideoService = {
  getServiceVideosByVin (vinNo, dealerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(serviceVideoData)
      } else {
        axios
          .post('/serviceVideo/getServiceVideosByVin?vinNo=' + vinNo + '&dealerId=' + dealerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default serviceVideoService

<template>
  <section class="ml-5">
    <div id="usage-container" @click="onShowDetailedHistory" v-if="hasUsageHistory">
      <b-icon pack="fal" icon="road" custom-size="fa-lg" class="clickable"></b-icon>
      <div class="is-inline-block has-text-weight-semibold is-size-6 clickable" style="margin-left:10px;">
        <span v-if="inventorySpecificRecordedHistory.length > 1" title="Stock-in mileage recored">{{firstRecordedUsage.usage}} > </span>
        <span v-if="inventorySpecificRecordedHistory.length > 2">... ></span>
        <span title="Last recorded mileage" @click.stop.prevent="onLastMileageClick(lastRecordedUsage)"> {{lastRecordedUsage.usage}}</span>
      </div>
    </div>
    <span class="icon" @click="onShowUsageEdit" v-if="hasFeatureAccess(usageModifyPermission) && showEditIcon">
      <i class="fal fa-pencil" style="cursor: pointer;"></i>
    </span>

    <portal to="global-modal-portal" :disabled="!usePortal" v-if="showDetailedHistoryActive">
      <b-modal :active.sync="showDetailedHistoryActive" :width="640" scrollable :has-modal-card="true">
        <div class="modal-card" style="width: 450px;">
          <header class="modal-card-head flex">
            <div class="modal-card-title">Mileage Events</div>
            <div><span class="tag is-primary">{{currentVinNo}}</span></div>
          </header>
          <section class="modal-card-body has-background-grey-lighter">
            <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
              <thead>
                <th>Stock No</th>
                <th>Mileage</th>
                <th>Type</th>
                <th>Logged At</th>
              </thead>
              <tbody>
                <tr v-for="vinHistoryEntry in allRecordedHistory" :key="vinHistoryEntry.id">
                  <td><a @click.stop.prevent="onStockNoClick(vinHistoryEntry)"><span class="has-text-weight-semibold" :title="vinHistoryEntry.stockNo">{{ vinHistoryEntry.stockNo }}</span></a></td>
                  <td>{{vinHistoryEntry.usage}}</td>
                  <td> {{vinHistoryEntry.eventType}}</td>
                  <td>{{vinHistoryEntry.createdAt | formatDate}}</td>
                </tr>
              </tbody>
            </table>
          </section>
          <footer class="modal-card-foot">
              <button type="button" class="button is-success" @click="onCloseDetailedHistory()">Close</button>
          </footer>
        </div>
      </b-modal>
    </portal>

    <portal to="global-modal-portal" :disabled="!usePortal" v-if="isUsageInLineEditActive">
      <b-modal :active.sync="isUsageInLineEditActive" scroll="keep" :has-modal-card="true">
        <div class="modal-card" style="width: 350px;">
          <header class="modal-card-head flex">
            <div class="modal-card-title">Edit Mileage</div>
            <div><span class="tag is-primary">{{currentVinNo}}</span></div>
          </header>
          <section class="modal-card-body has-background-grey-lighter">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <div class="field box">
              <label class="label">Mileage<b-icon pack="fas" icon="star" class="fa-ss" v-if="isUsageRequired"></b-icon></label>
              <div class="control is-vertical-center">
                <input
                  name="usage_inline_edit"
                  data-vv-as="mileage"
                  v-validate="{'required': isUsageRequired, 'min_value': minimumUsageAmount}"
                  :class="{'input': true, 'is-danger': errors.has('usage_inline_edit') }"
                  type="number"
                  placeholder="#####"
                  v-model="mileage">
              </div>
              <span v-show="errors.has('usage_inline_edit')" class="help is-danger"><i v-show="errors.has('usage_inline_edit')" class="fas fa-exclamation-triangle"></i> {{ errors.first('usage_inline_edit') }}</span>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" @click="onSaveUsage()" type="button" :class="{'is-loading': isSaving }">Save</button>
            <button class="button is-danger" @click="onCloseUsageEdit()" type="button">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </section>
</template>

<script>

import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import inventoryService from '@/services/inventoryService'
import defaultAppConfig from '@/config/index'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'UsageHistoryComponent',
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  props: {
    vinNo: {
      type: String,
      default: ''
    },
    inventoryId: {
      type: Number,
      default: 0
    },
    vinUsageHistory: {
      type: Array,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    usage: {
      type: Number,
      default: null
    },
    usageRequired: {
      type: Boolean,
      default: false
    },
    minimumUsageAllowed: {
      type: Number,
      default: 0
    },
    showEditIcon: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showDetailedHistoryActive: false,
      instanceId: this.$uuid.v4(),
      serverErrorMessage: '',
      serverErrors: [],
      isUsageInLineEditActive: false,
      mileage: this.usage,
      completeVinUsageHistory: [],
      usageModifyPermission: 'inventory.mileage.modify',
      isSaving: false
    }
  },
  methods: {
    onLastMileageClick: function (lastRecordedUsage) {
      this.$emit('on-last-mileage-click', lastRecordedUsage.usage)
    },
    onShowDetailedHistory: function () {
      if (this.appVersion === '6.0.0' && (this.inventoryId && this.inventoryId !== 0)) {
        this.loadUsageHistory()
      }
      this.showDetailedHistoryActive = true
    },
    onCloseDetailedHistory: function () {
      this.showDetailedHistoryActive = false
    },
    onShowUsageEdit: function () {
      this.isUsageInLineEditActive = true
    },
    onCloseUsageEdit: function () {
      this.isUsageInLineEditActive = false
    },
    onSaveUsage: function () {
      this.$validator.validate('usage_inline_edit').then((result) => {
        if (result) {
          this.serverErrorMessage = ''
          this.serverErrors = []
          this.isSaving = true
          let inventoryUsageModel = {inventoryId: this.inventoryId, usage: this.mileage}
          inventoryService.updateMileage(inventoryUsageModel).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              let usageModel = {usage: response.inventory.usage, vinUsageHistory: response.inventory.vinUsageHistory}
              this.$emit('usage-updated', usageModel)
              this.onCloseUsageEdit()
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      })
    },
    loadUsageHistory: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      inventoryService.getVinHistory(this.inventoryId).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.completeVinUsageHistory = response
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onStockNoClick: function (vinHistoryEntry) {
      let that = this

      this.$copyText(vinHistoryEntry.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + vinHistoryEntry.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess']),
    currentVinNo: function () {
      return this.vinNo
    },
    hasUsageHistory: function () {
      return this.inventorySpecificRecordedHistory && this.inventorySpecificRecordedHistory.length > 0
    },
    allRecordedHistory: function () {
      return this.completeVinUsageHistory && this.completeVinUsageHistory.length > 0 ? this.completeVinUsageHistory : this.vinUsageHistory
    },
    inventorySpecificRecordedHistory: function () {
      if (this.inventoryId === 0) {
        return this.vinUsageHistory
      }
      return this.vinUsageHistory.filter((x) => x.inventoryId === this.inventoryId)
    },
    lastRecordedUsage: function () {
      if (this.hasUsageHistory) {
        return this.inventorySpecificRecordedHistory[this.inventorySpecificRecordedHistory.length - 1]
      }
      return '-'
    },
    firstRecordedUsage: function () {
      if (this.inventorySpecificRecordedHistory) {
        return this.inventorySpecificRecordedHistory[0]
      }
      return '-'
    },
    isUsageRequired: function () {
      return this.usageRequired
    },
    minimumUsageAmount: function () {
      return this.minimumUsageAllowed
    },
    appVersion: function () {
      return defaultAppConfig.appPlatformVersion
    }
  },
  watch: {
    usage: function (newValue, oldValue) {
      if (newValue) {
        this.mileage = newValue
      }
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal.is-active .modal-card-foot {
    padding: 10px !important
  }

  .modal-card-head, .modal-card-body{
    padding: 10px !important
  }

  .modal-card .modal-card-body {
    position:relative;
    max-height: 65vh; /* max 80% of the viewport height */
    max-width: 960px; /* max 80% of the viewport height */
    height: unset !important;
    overflow-y: scroll;
  }

  #usage-container {
    display:inline-flex;
    vertical-align: middle;
    align-items: center;
  }

  .table {
    background-color: #f8fafc !important;
    color: #6c6c7a;
    font-size: 0.7rem;
    width: 100%;
  }

</style>

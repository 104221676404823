import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/dealers',
    name: 'ListDealers',
    component: () => import('@/components/configuration/dealers/ListDealers'),
    beforeEnter: requiresAuth
  },
  {
    name: 'DealersQuickView',
    path: '/configuration/dealers/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/dealers/ViewDealer')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/dealers/new',
    name: 'NewDealer',
    component: () => import('@/components/configuration/dealers/NewDealer'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/dealers/view/:id',
    name: 'ViewDealer',
    component: () => import('@/components/configuration/dealers/ViewDealer'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/dealers/edit/:id',
    name: 'EditDealer',
    component: () => import('@/components/configuration/dealers/EditDealer'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/dealers/square/:id',
    name: 'ConfigSquare',
    component: () => import('@/components/configuration/dealers/ConfigSquare'),
    beforeEnter: requiresAuth
  }
]

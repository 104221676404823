<template>
<div class="quick-action">
  <div style="display:inline;">
    <button class="button is-pull-left" type="button" @click="openModal()">
      <i class="fal fa-truck-container" type="button" title='Click to fulfill ordered deal.'></i>
    </button>
  </div>
  <portal to="global-modal-portal" v-if="confirmDealAllocatedActive">
    <b-modal :active.sync="confirmDealAllocatedActive" :width="640" scroll="keep" :has-modal-card="true">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <div class="columns" style="width: 100%">
            <div class="modal-card-title column is-4">Fulfill Ordered Deal</div>
            <div class="modal-card-title column is-8">
              <div class="tags is-pulled-right">
                <span class="tag is-info">{{this.customerName}}</span>
                <span class="tag is-info">{{this.soldVehicle}}</span>
              </div>
            </div>
          </div>
        </header>
        <section class="modal-card-body modal-card-body-less-padding">
          <form @change="onFormChange">
            <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
            <div class="columns is-multiline">
              <div class="column is-6">
                <div class="field">
                  <label class="label">
                    Allocated Month Selection
                    <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                  </label>
                  <div class="control">
                    <el-date-picker
                      v-model="selectedYearMonth"
                      type="month"
                      format="MMM yyyy"
                      placeholder="Click to select..."
                      :clearable="false"
                      popper-class="datepicker-container"
                      name="allocatedMonth"
                      id="allocatedMonth"
                      data-vv-as="allocated month"
                      v-validate="'required'"
                      default-time="00:00:00">
                    </el-date-picker>
                    <span v-show="errors.has('allocatedMonth')" class="help is-danger">
                      <i v-show="errors.has('allocatedMonth')" class="fas fa-exclamation-triangle"></i> {{ errors.first('allocatedMonth') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <label class="label">
                  Expected Delivery Date
                  <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                </label>
                <div style="width:fit-content;">
                  <el-date-picker
                    v-model="selectedPromiseDate"
                    @change="onFormChange()"
                    type="datetime"
                    format="MM-dd-yyyy h:mm A"
                    placeholder="Click to select..."
                    :clearable="false"
                    popper-class="datepicker-container"
                    default-time="13:00:00"
                    :editable="false"
                    :default-value="this.getDefaultDateTimeForCalendar"
                    name="expectedDeliveryDate"
                    id="expectedDeliveryDate"
                    data-vv-as="expected delivery date"
                    v-validate="'required'">
                  </el-date-picker>
                  <br/>
                  <label
                    v-if="potentialPromiseDate !== ''"
                    class="is-pulled-right subtitle has-text-success clickable is-size-7"
                    @click="onSetPromiseDate(potentialPromiseDate)"
                    title="Click to select this date as the promise date for this deal">
                      {{potentialPromiseDate | formatDateOnly}}
                  </label>
                </div>
                <span v-show="errors.has('expectedDeliveryDate')" class="help is-danger">
                  <i v-show="errors.has('expectedDeliveryDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('expectedDeliveryDate') }}
                </span>
              </div>
              <div class="column is-6">
                <label class="label">
                  Order Allocation Submission
                  <b-icon pack="fas" icon="star" class="fa-ss"></b-icon>
                </label>
                <div style="width:fit-content;">
                  <el-date-picker
                    v-model="orderAllocationSubmission"
                    @change="onFormChange()"
                    type="date"
                    format="MM-dd-yyyy"
                    placeholder="Click to select..."
                    :clearable="false"
                    popper-class="datepicker-container"
                    default-time="00:00:00"
                    :editable="false"
                    :default-value="this.getDefaultDateTimeForCalendar"
                    name="orderAllocationSubmission"
                    id="orderAllocationSubmission"
                    data-vv-as="order allocation submission"
                    v-validate="'required'">
                  </el-date-picker>
                </div>
                <span v-show="errors.has('orderAllocationSubmission')" class="help is-danger">
                  <i v-show="errors.has('orderAllocationSubmission')" class="fas fa-exclamation-triangle"></i> {{ errors.first('orderAllocationSubmission') }}
                </span>
              </div>
            </div>
            <div class="columns is-multiline box mt-10" v-for="(dealItem, index) in soldOrderDealItems" :key="dealItem.id">
              <div class="column is-12">
                <section-header :title="'Sold Item (' + (index + 1) +')'" :overview="getInventoryItemOverview(dealItem.forInventory)" :allowCollapse="false"></section-header>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Selling price (Exp. Total MSRP)</label>
                  <div class="control has-icons-left">
                    <money name="salePrice" data-vv-as="selling price" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('salePrice') }" type="text" placeholder="0.00" v-model="dealItem.salePrice" v-bind="$globalMoneyFormat"></money>
                    <span v-show="errors.has('salePrice')" class="help is-danger"><i v-show="errors.has('salePrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('salePrice') }}</span>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Base Price</label>
                  <div class="control has-icons-left">
                    <money readonly name="basePrice" :class="{'input': true}" type="text" v-model="dealItem.modelBasePrice" v-bind="$globalMoneyFormat"></money>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Options Price</label>
                  <div class="control has-icons-left">
                    <money name="optionsPrice" data-vv-as="options price" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('optionsPrice') }" type="text" placeholder="0.00" v-model="optionsPrice" v-bind="$globalMoneyFormat"></money>
                    <span v-show="errors.has('optionsPrice')" class="help is-danger"><i v-show="errors.has('optionsPrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('optionsPrice') }}</span>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                  <label
                    v-if="potentialOptionsPrice !== ''"
                    class="is-pulled-right subtitle has-text-success clickable is-size-7"
                    @click="onSetOptionsPrice(potentialOptionsPrice)"
                    title="Click to select this value as the total options price">
                      {{potentialOptionsPrice | currency}}
                  </label>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Expected Gross Profit</label>
                  <div class="control has-icons-left">
                    <money name="grossProfit" data-vv-as="gross profit" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('grossProfit') }" type="text" placeholder="0.00" v-model="dealItem.frontValue" v-bind="$globalMoneyFormat"></money>
                    <span v-show="errors.has('grossProfit')" class="help is-danger"><i v-show="errors.has('grossProfit')" class="fas fa-exclamation-triangle"></i> {{ errors.first('grossProfit') }}</span>
                    <span class="icon is-small is-left">
                      <i class="fas fa-dollar-sign"></i>
                    </span>
                  </div>
                  <label
                    v-if="potentialGrossProfit !== ''"
                    class="is-pulled-right subtitle has-text-success clickable is-size-7"
                    @click="onSetGrossProfit(potentialGrossProfit)"
                    title="Click to select this value as the expected gross profit">
                      {{potentialGrossProfit | currency}} @ 12%
                  </label>
                </div>
              </div>
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="validateBeforeSubmit" type="button">Allocate</button>
          <button class="button is-danger" :class="{'is-loading': isSaving }" @click="onCancelDealAllocated()" type="button">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </portal>
</div>
</template>

<script>

import dealService from '@/services/dealService'
import eventBus from '@/eventBus'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import moment from 'moment'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import SectionHeader from '@/components/generic/SectionHeader'

export default {
  name: 'FulFillOrderControl',
  mixins: [validateBeforeSubmitMixin],
  props: {
    currentDeal: {
      type: Object,
      default: null
    }
  },
  components: {
    'error-display-component': ErrorDisplayComponent,
    'section-header': SectionHeader
  },
  data () {
    return {
      confirmDealAllocatedActive: false,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      selectedYearMonth: null,
      selectedPromiseDate: null,
      grossProfit: null,
      salePrice: null,
      optionsPrice: null,
      orderAllocationSubmission: null
    }
  },
  computed: {
    potentialGrossProfit: function () {
      if (!this.soldOrderDealItems || this.soldOrderDealItems.length <= 0) return ''
      if (!this.soldOrderDealItems[0].salePrice || this.soldOrderDealItems[0].salePrice <= 0) return ''
      return this.soldOrderDealItems[0].salePrice * 0.12
    },
    potentialOptionsPrice: function () {
      if (!this.soldOrderDealItems || this.soldOrderDealItems.length <= 0) return ''
      if (!this.soldOrderDealItems[0].modelBasePrice || this.soldOrderDealItems[0].modelBasePrice <= 0) return ''
      if (!this.soldOrderDealItems[0].salePrice || this.soldOrderDealItems[0].salePrice <= 0) return ''
      return this.soldOrderDealItems[0].salePrice - this.soldOrderDealItems[0].modelBasePrice
    },
    potentialPromiseDate: function () {
      if (this.selectedYearMonth) {
        return moment(this.selectedYearMonth).add(4, 'M')
      }
      return ''
    },
    getDefaultDateTimeForCalendar: function () {
      let localDateValue = moment.utc(String(new Date())).tz(moment.defaultZone.name)
      let localDate = new Date(localDateValue)
      return new Date(localDate.toDateString() + ' 13:00')
    },
    dealId: function () {
      return this.currentDeal.id
    },
    customerName: function () {
      return this.currentDeal.purchaserName
    },
    soldDealItems: function () {
      return (this.currentDeal && this.currentDeal.items) ? this.currentDeal.items.filter((x) => x.dealItemType === 'Sold') : []
    },
    soldVehicle: function () {
      return this.currentDeal.vehicleProductionYear + ' ' + this.currentDeal.vehicleMakeName + ' ' + this.currentDeal.vehicleModelName
    },
    soldOrderDealItems: function () {
      return this.soldDealItems
    }
  },
  methods: {
    onSetGrossProfit: function (potentialGrossProfit) {
      this.soldOrderDealItems[0].frontValue = potentialGrossProfit
    },
    onSetOptionsPrice: function (potentialOptionsPrice) {
      this.optionsPrice = potentialOptionsPrice
    },
    onSetPromiseDate: function (promiseDate) {
      let date = new Date(promiseDate)
      this.selectedPromiseDate = new Date(date.toDateString() + ' 13:00')
    },
    openModal: function () {
      if (this.dealId > 0) {
        this.confirmDealAllocatedActive = true
      }
    },
    onCancelDealAllocated: function () {
      this.confirmDealAllocatedActive = false
    },
    onSave () {
      var dealOrderAllocatedModel = {
        dealId: this.dealId,
        optionsPrice: this.optionsPrice,
        items: this.soldOrderDealItems,
        promiseDate: this.getDateSaveReady(this.selectedPromiseDate),
        orderAllocationSubmission: this.getDateSaveReady(this.orderAllocationSubmission),
        allocatedMonth: this.getDateSaveReady(this.selectedYearMonth)
      }

      this.isSaving = true
      dealService.markOrderDealAllocated(dealOrderAllocatedModel).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        }
        this.isSaving = false
        eventBus.$emit('reload-deal-list')
        eventBus.$emit('close-global-quick-view', {})
        this.confirmDealAllocatedActive = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    getDateSaveReady: function (dateToFormat) {
      if (dateToFormat) {
        try {
          var localValue = dateToFormat.getFullYear() + '-' + ('0' + (dateToFormat.getMonth() + 1)).slice(-2) + '-' + ('0' + dateToFormat.getDate()).slice(-2) + ' ' + ('0' + dateToFormat.getHours()).slice(-2) + ':' + ('0' + dateToFormat.getMinutes()).slice(-2)
          let utcValue = moment.tz(localValue, moment.defaultZone.name).utc().format('YYYY-MM-DD HH:mm')
          return utcValue
        } catch (dateConversionError) {
          console.log('Error converting date to UTC')
          console.log(this.promiseDate)
          console.log(dateConversionError)
        }
      }
    },
    getInventoryItemOverview: function (inventoryItem) {
      if (inventoryItem && inventoryItem.stockNo) {
        return inventoryItem.stockNo
      }
      return ''
    },
    init () {
      this.selectedPromiseDate = this.currentDeal.promiseDate
    }
  },
  mounted: function () {
    eventBus.$on('on-open-fulfill-order-control', this.openModal)
    this.init()
  },
  beforeDestroy () {
    eventBus.$off('on-open-fulfill-order-control', this.openModal)
  }
}

</script>

<style scoped>

  .link {
    cursor:pointer

  }

  .active-link {
    color:green
  }

  .modal-card-body {
    width: 640px;
    max-width: 100% !important;
    min-width: 100% !important;
    overflow:auto;
    height: calc(58vh - 10px);
  }

</style>

<template>
  <div class="field">
    <div v-if="hasErrors" class="errors"><p>{{ errorMessage }}</p><br/></div>
    <div>
      <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th><b-checkbox v-model="checkBoxAll" true-value="deselect all" false-value="select all"></b-checkbox></th>
            <th>Status</th>
            <th>Name</th>
            <th>Sales Person</th>
            <th>Total</th>
          </tr>
        </thead>
        <tr v-for="(deal, index) in deals" :key="index">
          <td><div class="block"><b-checkbox v-model="selectedIds" :native-value="deal.id" /></div></td>
          <td>{{ deal.type }}</td>
          <td>{{ deal.buyerName }}</td>
          <td>{{ deal.salesPersonName }}</td>
          <td>{{ deal.totalValue | currency }}</td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  inject: {
    $validator: '$validator'
  },
  name: 'CancelBackupDealsControl',
  props: {
    value: {
      type: Object,
      default: null
    },
    backupDeals: {
      type: Array,
      default: null
    },
    selectedDealIds: {
      type: Array,
      default: null
    },
    hasErrors: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedIds: [],
      instanceName: this.$uuid.v4(),
      checkBoxAll: 'select all',
      errorMessage: 'One or more deals must be selected.'
    }
  },
  computed: {
    deals: function () {
      return this.backupDeals
    }
  },
  watch: {
    checkBoxAll: function () {
      if (this.checkBoxAll === 'select all') {
        this.selectedIds = []
      } else {
        this.selectedIds = []
        this.deals.forEach(el => {
          this.selectedIds.push(el.id)
        })
      }
    },
    selectedIds: function () {
      if (this.selectedIds.length === 0) {
        this.checkBoxAll = 'select all'
      } else {
        this.$emit('update:hasErrors', false)
      }
      this.$emit('update:selectedDealIds', this.selectedIds)
    }
  },
  methods: {
  },
  mounted () {
  }
}
</script>

<style scoped>
</style>

import requiresAuth from './route_guards'

export default [
  {
    path: '/configuration/leadsources',
    name: 'ListLeadSources',
    component: () => import('@/components/configuration/leadSources/ListLeadSources'),
    beforeEnter: requiresAuth
  },
  {
    name: 'LeadSourcesQuickView',
    path: '/configuration/leadSources/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/configuration/leadSources/ViewLeadSource')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/leadsources/view/:id',
    name: 'ViewLeadSource',
    component: () => import('@/components/configuration/leadSources/ViewLeadSource'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/leadsources/new',
    name: 'NewLeadSource',
    component: () => import('@/components/configuration/leadSources/NewLeadSource'),
    beforeEnter: requiresAuth
  },
  {
    path: '/configuration/leadsources/edit/:id',
    name: 'EditLeadSource',
    component: () => import('@/components/configuration/leadSources/EditLeadSource'),
    beforeEnter: requiresAuth
  }
]

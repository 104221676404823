<template>
    <section>
        <portal :to="headerPortal" :disabled="headerPortal === ''">
          <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Add Deal</h1>
        </portal>

        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <form @submit.prevent="validateBeforeSubmit" @change="onFormChange">
            <div id="deal-overview" class="box">
              <section-header title="Deal Overview" parentSelector="#deal-overview"></section-header>
              <div class="columns">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Deal #</label>
                    <div class="control is-width-100">
                      <input name="dealCode" data-vv-as="deal number" v-validate="'max:10'" :class="{'input': true, 'is-danger': errors.has('dealCode') }" type="text" placeholder="0000000000" v-model="dealCode">
                      <span v-show="errors.has('dealCode')" class="help is-danger">
                        <i v-show="errors.has('dealCode')" class="fas fa-exclamation-triangle"></i> {{ errors.first('dealCode') }}
                      </span>
                    </div>
                  </div>

                  <div class="is-flex">
                    <button-list-selector validateAs="sale type" label="Sale Type" :required="true" v-model="selectedSaleType" :availableValues="this.definedTypes.dealSaleTypes" :allowDeselection="false" v-on:on-change="onSaleTypeChange"></button-list-selector>
                    <div v-if="showWholesaleTradeWarning" class="ml-10 notification is-danger">
                      <span>
                        <i class="fas fa-exclamation-triangle fa-2x"></i>
                      </span>
                      Wholesale deal cannot have trades or lease renewals
                    </div>
                  </div>

                  <button-list-selector validateAs="stock type" label="Stock Type" :required="true" v-model="selectedStockType" :availableValues="this.definedTypes.dealStockTypes" :activeValues="activeStockTypeItems"  @click.native="onStockTypeClick" :allowDeselection="false"></button-list-selector>
                </div>
                <div class="column is-6">
                  <b-field label="Sold Date">
                    <el-date-picker
                        @change="onFormChange()"
                        v-model="soldAt"
                        type="date"
                        placeholder="Click to select..."
                        :clearable="false"
                        :editable="false"
                        format="MM-dd-yyyy">
                    </el-date-picker>
                  </b-field>
                  <label class="label">Expected Delivery Date<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <el-date-picker
                      @change="onFormChange()"
                      v-model="promiseDate"
                      type="datetime"
                      placeholder="Click to select..."
                      :clearable="false"
                      :editable="false"
                      format="MM-dd-yyyy h:mm A"
                      default-time="13:00:00"
                      :class="{'is-danger': errors.has('promiseDate') }"
                      name="promiseDate"
                      v-validate="'required'"
                      data-vv-as="expected delivery date">
                  </el-date-picker>
                  <span v-show="errors.has('promiseDate')" class="help is-danger">
                    <i v-show="errors.has('promiseDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('promiseDate') }}
                  </span>
                  <pop-fill-control title="Click to replace expected delivery date with suggested date" :fillableValue="proposedPromiseDateFormatted" :onFillAction="onPopFillExpectedDeliveryDate" customClass="popfill-link-date"></pop-fill-control>
                </div>
              </div>
              <div class="columns">
                <div class="column is-6">
                  <button-list-selector validateAs="deal status" label="Deal Status" :required="true" v-model="selectedType" :availableValues="availableNewDealTypeItems" :activeValues="activeDealTypeItemsComputed"></button-list-selector>
                </div>
                <div class="column is-6" v-if="selectedType && selectedType.altName === 'AwaitingArrival'">
                  <div class="field">
                    <label class="label">Inbound arrival status</label>
                    <div class="control">
                      <multiselect v-model="arrivalStatus" :options="definedTypes.dealInboundStatusTypes.options" :searchable="false" :close-on-select="true" :show-labels="false" placeholder="Select inbound arrival status" label="name" track-by="altName" data-vv-as="type" :class="errors.has('type') ? 'is-danger' : '' " name="type" :hideSelected="true" :disabled="isCancelledDeal"></multiselect>
                      <span v-show="showInboundSelectionRequired" class="help is-danger">
                        <i v-show="showInboundSelectionRequired" class="fas fa-exclamation-triangle"></i> Inbound status is required
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns is-multiline">
                <div class="column is-6">
                  <button-list-selector validateAs="contract type" label="Contract Type" :required="true" v-model="selectedContractType" :availableValues="this.definedTypes.dealContractTypes.options" :allowDeselection="false"></button-list-selector>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <div class="control">
                      <switch-selector type="is-info" label="Cash Down" v-model="isCashDown"></switch-selector>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="isCashDown">
                  <div class="field">
                    <label class="label">Deposit Amount</label>
                    <div class="control has-icons-left">
                      <money name="depositAmount" data-vv-as="deposit amount" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('depositAmount') }" type="text" placeholder="0000.00" v-model="depositAmount" v-bind="$globalMoneyFormat"></money>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <span v-show="errors.has('depositAmount')" class="help is-danger">
                      <i v-show="errors.has('depositAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('depositAmount') }}
                    </span>
                  </div>
                </div>
                <div class="column is-6" v-if="isLeaseContractType">
                  <div class="field" >
                    <label class="label">Lease Contract Term</label>
                    <div class="control">
                      <multiselect @select="onFormChange()" :hideSelected="true" data-vv-as="lease contract term" :multiple="false" name="leaseContractTerm" :class="{'is-danger': errors.has('leaseContractTerm') }" v-model="selectedLeaseContractTermType" label="name" :options="orderedDealLeaseContractTermTypes" placeholder="Lease Contract Term" track-by="name"></multiselect>
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="isLeaseContractType">
                  <div class="field">
                    <label class="label">Lease Contract Amount
                    </label>
                    <div class="control has-icons-left">
                      <money name="leaseContractAmount" data-vv-as="lease contract amount" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('leaseContractAmount') }" type="text" placeholder="0000.00" v-model="leaseAmount" v-bind="$globalMoneyFormat"></money>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <span v-show="errors.has('leaseContractAmount')" class="help is-danger">
                      <i v-show="errors.has('leaseContractAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('leaseContractAmount') }}
                    </span>
                  </div>
                </div>
                <div class="column is-6" v-if="isLeaseContractType">
                  <div class="field">
                    <label class="label">Residual Value</label>
                    <div class="control has-icons-left">
                      <money name="residualValue" data-vv-as="residual value" v-validate="'decimal:2'" :class="{'input': true, 'is-danger': errors.has('residualValue') }" type="text" placeholder="0000.00" v-model="residualValue" v-bind="$globalMoneyFormat"></money>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                    <span v-show="errors.has('residualValue')" class="help is-danger">
                      <i v-show="errors.has('residualValue')" class="fas fa-exclamation-triangle"></i> {{ errors.first('residualValue') }}
                    </span>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column is-6">
                  <supplier-selector label="Wholesaled to" validateAs="Wholesaled to" placeholder="Type to search for a loaded wholesaler" v-if="isSaleTypeOfWholesale" v-model="selectedSupplier" :required="true"></supplier-selector>
                  <customer-selector v-if="isSaleTypeOfRetail" v-model="selectedCustomer" :selectedDealer="this.selectedDealer" :required="true"></customer-selector>
                  <user-selector v-model="selectedSalesAgent" filter="Sales" label="Sales Agent" validateAs="sales agent" :selectedDealer="this.selectedDealer" :required="isSalesAgentRequired"></user-selector>
                  <user-selector v-model="selectedSalesAgentManager" v-validate="''" label="Sales Agent Manager" validateAs="sales agent manager" :selectedDealer="this.selectedDealer" :required="true"></user-selector>
                  <label class="label-frontgp is-pulled-right" :class="{'has-text-danger': (selectedSalesAgentManager === null) || (selectedSalesAgentManager && selectedSalesAgentManager.fullName !== currentUser.fullName), 'has-text-success': selectedSalesAgentManager && selectedSalesAgentManager.fullName === currentUser.fullName}" @click="setCurrentAsSalesManager" title="Click to make the current user the sales agent manager.">{{currentUser.fullName}}</label>
                  <dealer-selector v-model="selectedDealer" :required="true" :useValidator="this.$validator" v-show="false"></dealer-selector>
                </div>
                <div class="column is-6">
                  <customer-selector v-if="isSaleTypeOfRetail" v-model="selectedCoCustomer" :selectedDealer="this.selectedDealer" :required="false" label="Co-Customer"></customer-selector>
                  <button-list-selector v-if="isSaleTypeOfRetail && selectedCoCustomer !== null" validateAs="relationship status" label="Co-Customer Relationship Status" :required="true" v-model="selectedCoCustomerRelationship" :availableValues="this.definedTypes.relationshipTypes.options" :allowDeselection="false"></button-list-selector>
                  <user-selector v-model="selectedFinanceProcessedBy" filter="F&I Manager" label="Finance Processed By" validateAs="finance processed by" :selectedDealer="this.selectedDealer" :required="isFinanceProcessedByRequired"></user-selector>
                  <lead-source-selector v-model="selectedLeadSource" :required="isLeadSourceRequired" label="Lead Source" validateAs="lead source" :selectedDealer="this.selectedDealer"></lead-source-selector>
                </div>
              </div>
              <switch-selector type="is-info" label="Any Supporting Documentation?" v-model="hasDealAttachments"></switch-selector>
              <div id="document-section" class="box" v-show="hasDealAttachments">
                <section-header title="Documents" parentSelector="#document-section"></section-header>
                <div class="columns is-multiline">
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="saleDocuments"
                        label="Sale Documents"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6" v-if="false">
                    <div class="field">
                      <file-selector
                        v-model="creditCardDocuments"
                        label="Credit Card"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="buyersAgreementDocuments"
                        label="Buyers Agreement"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="invoiceDocuments"
                        label="Invoice"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="otherDocuments"
                        label="Other"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6" v-if="false">
                    <div class="field">
                      <file-selector
                        v-model="wireDocuments"
                        label="Wire"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="generalDocuments"
                        label="General"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="fundingLetterDocuments"
                        label="Funding Letter"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6" v-if="false">
                    <div class="field">
                      <file-selector
                        v-model="squareDocuments"
                        label="Square"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6" v-if="false">
                    <div class="field">
                      <file-selector
                        v-model="cashDocuments"
                        label="Cash"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6" v-if="false">
                    <div class="field">
                      <file-selector
                        v-model="checkDocuments"
                        label="Check"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="dealJacketDocuments"
                        label="Deal Jacket"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="customerVerificationDocuments"
                        label="Customer Verification"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="cdkRecapDocuments"
                        label="CDK Recap"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="dealPresentationDocuments"
                        label="Deal Presentation"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="bankCreditApplicationApprovalDocuments"
                        label="Bank Credit Application Approval"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="vehicleCostDocuments"
                        label="Vehicle Cost"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <file-selector
                        v-model="cdkWashoutDocuments"
                        label="CDK Washout"
                        :enabled="true"
                        :multiple="true"
                        :showFileOnly="false"
                        :isBoxed="false"
                        :formatImages="true"
                        :usePortalViewer="true"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                      </file-selector>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="columns is-multiline" v-if="hasDealAttachments">
                <div class="column is-6">
                  <div class="field">
                    <file-selector
                      v-model="fundingLetterDocuments"
                      label="Funding Letter"
                      :enabled="true"
                      :multiple="true"
                      :showFileOnly="false"
                      :isBoxed="false"
                      :formatImages="true"
                      :usePortalViewer="true"
                      @uploadStarted="uploadStarted"
                      @uploadComplete="uploadComplete">
                    </file-selector>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <file-selector
                      v-model="cdkRecapDocuments"
                      label="CDK Recap"
                      :enabled="true"
                      :multiple="true"
                      :showFileOnly="false"
                      :isBoxed="false"
                      :formatImages="true"
                      :usePortalViewer="true"
                      @uploadStarted="uploadStarted"
                      @uploadComplete="uploadComplete">
                    </file-selector>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <file-selector
                      v-model="saleDocuments"
                      label="Sale Documents"
                      :enabled="true"
                      :multiple="true"
                      :showFileOnly="false"
                      :isBoxed="false"
                      :formatImages="true"
                      :usePortalViewer="true"
                      @uploadStarted="uploadStarted"
                      @uploadComplete="uploadComplete">
                    </file-selector>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <file-selector
                      v-model="dealPresentationDocuments"
                      label="Deal Presentation"
                      :enabled="true"
                      :multiple="true"
                      :showFileOnly="false"
                      :isBoxed="false"
                      :formatImages="true"
                      :usePortalViewer="true"
                      @uploadStarted="uploadStarted"
                      @uploadComplete="uploadComplete">
                    </file-selector>
                  </div>
                </div>
              </div> -->
            </div>

            <div id="inventory-sold-section" class="box">
              <section-header
                title="Inventory Sold"
                parentSelector="#inventory-sold-section"
                :collapsed="!showSoldSection"
                :overview="soldDealItems.length > 0 ? soldDealItems[0].modelOverview : ''"
                >
              </section-header>
              <sold-inventory-capture
                v-for="(soldItem, index) in soldDealItems"
                :key="100 + index"
                :vehicleTypes="vehicleTypes"
                :selectedDealer="selectedDealer"
                v-model="soldDealItems[index]"
                v-on:on-vehicle-sale-type-changed="onVehicleSaleTypeChanged"
                v-on:on-vehicle-make-changed="onVehicleMakeChanged"
                :locateVehicleIsFound.sync="locateVehicleIsFound"
                :selectedStockType.sync="selectedStockType"
                :selectedDealType.sync="selectedType"
                :selectedSaleType.sync="selectedSaleType"
                :additionalCostsGP="additionalCostsGP"
                :additionalCostsRecon="additionalCostsRecon"
                :customerLeadVehicles="selectedCustomerLeadVehicles">
              </sold-inventory-capture>
              <div class="inner-add-remove-button" v-show="false" v-if="showSoldItemPlusBtn">
                <a class="button is-primary" @click="AddDealItem({type: 'Sold'})">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" v-if="this.soldDealItems.length > 1" @click="removeDealItem('Sold')">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div id="supplement-cost-section" class="box" v-show="showCostsSection">
              <section-header title="Additional Costs" parentSelector="#supplement-cost-section"></section-header>
              <div class="columns">
                <div class="column is-12">
                  <b-table :data="this.supplementalCosts" class="table">
                    <template slot-scope="props">
                      <b-table-column field="name" label="Name" sortable :visible="!props.row.delete">
                        {{ props.row.name }}
                      </b-table-column>

                      <b-table-column field="vendorName" label="Vendor" :visible="!props.row.delete" sortable>
                        {{ props.row.vendorName }}
                      </b-table-column>

                      <b-table-column field="processingAction" label="Nett Action" :visible="!props.row.delete" sortable>
                        {{ props.row.processingAction }}
                      </b-table-column>

                      <b-table-column field="isInGlBalance" label="Is In GL Balance" :visible="!props.row.delete" sortable>
                        {{ humanizedSwitch(props.row.isInGlBalance) }}
                      </b-table-column>

                      <b-table-column field="description" label="Description" sortable :visible="!props.row.delete">
                        {{ props.row.description }}
                      </b-table-column>

                      <b-table-column field="cost" label="Cost" sortable :visible="!props.row.delete">
                        {{ props.row.cost | currency }}
                      </b-table-column>

                      <b-table-column field="sellingPrice" label="Selling Price" sortable :visible="!props.row.delete">
                        {{ props.row.sellingPrice | currency }}
                      </b-table-column>

                      <b-table-column field="" label="Actions" :visible="!props.row.delete">
                        <span class="icon" @click="editSupplementalCost(props.row, props.index)">
                          <i class="fal fa-pencil"></i>
                        </span>
                        <span class="icon" @click="removeSupplementalCost(props.index)">
                          <i class="fal fa-minus-circle"></i>
                        </span>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No additional costs have been added.</p>
                        </div>
                      </section>
                    </template>
                    <template slot="footer" v-if="availableAdditionalCosts.length > 0">
                      <th colspan="6"></th>
                      <th colspan="1"><label class="label">{{additionalCostsTotal | currency}}</label></th>
                      <th colspan="1"></th>
                    </template>
                  </b-table>
                </div>
              </div>
              <supplement-cost-capture :value="this.newSupplementalCost" :supplementalCosts="this.supplementalCosts"></supplement-cost-capture>
              <div class="inner-add-remove-button">
                <a class="button is-primary" @click="addSupplementalCost()">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div id="commission-section" class="box" v-show="showCommissionsSection">
              <section-header title="Commissions" parentSelector="#commission-section"></section-header>
              <div class="columns">
                <div class="column is-12">
                  <b-table :data="this.commissions" class="table">
                    <template slot-scope="props">
                      <b-table-column field="salesPersonName" label="Sales Agent" sortable>
                        {{ props.row.salesPersonName }}
                      </b-table-column>

                      <b-table-column field="commission" label="Commission" sortable>
                        {{ props.row.commission | currency }}
                      </b-table-column>

                      <b-table-column field="" label="Actions">
                        <span class="icon" @click="editCommission(props.row, props.index)">
                          <i class="fal fa-pencil"></i>
                        </span>
                        <span class="icon" @click="removeCommission(props.index)">
                          <i class="fal fa-minus-circle"></i>
                        </span>
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No commissions have been added.</p>
                        </div>
                      </section>
                    </template>
                    <template slot="footer" v-if="commissions.length > 0">
                      <th colspan="1"></th>
                      <th colspan="1"><label class="label">{{commissionsTotal | currency}}</label></th>
                      <th colspan="1"></th>
                    </template>
                  </b-table>
                </div>
              </div>
              <commission-capture :value="this.newCommission" :commissions="this.commissions"></commission-capture>
              <div class="inner-add-remove-button">
                <a class="button is-primary" @click="addCommission()">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div v-if="showWholesaleTradeWarning" class="ml-10 notification is-danger">
              <span>
                <i class="fas fa-exclamation-triangle fa-2x"></i>
              </span>
              Wholesale deal cannot have trades or lease renewals
            </div>

            <div id="trade-section" class="box" v-if="showTradeSection">
              <section-header title="Trades" parentSelector="#trade-section"></section-header>
              <make-selector v-if="!hasTradeInventory && !hasLeaseInventory" label="Previous Vehicle Make" :required="false" v-model="selectedMake" :selectedDealer="this.selectedDealer"></make-selector>
              <div class="field">
                <label class="label">Do you have a trade?</label>
                <div class="control">
                  <b-switch type="is-info" v-model="hasTradeInventory" @click.native="toggleTrade" :disabled="hasLeaseInventory">{{ humanizedHasTradeInventory }}</b-switch>
                </div>
              </div>
              <div v-if="hasTradeInventory">
                <lease-renewal-capture v-for="(tradeItem, index) in tradeDealItems" :key="200 + index"
                  :showPrice="true"
                  :showStockNoLabel="true"
                  :selectedDealer="selectedDealer"
                  v-model="tradeDealItems[index]"
                  :soldStockNo="soldDealItems.length > 0 && soldDealItems[0].forInventory ? soldDealItems[0].forInventory.stockNo : ''"
                  mode="Trade"
                  :defaultSalesPerson="selectedSalesAgent"
                  :defaultSellerType="selectedSellerType"
                  :defaultSeller="selectedSeller">
                </lease-renewal-capture>
              </div>
              <div class="inner-add-remove-button" v-if="hasTradeInventory">
                <a class="button is-primary" @click="AddDealItem({type: 'Trade'})">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" @click="removeDealItem('Trade')" v-if="tradeDealItems.length > 1">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div id="lease-renewal-section" class="box" v-if="showLeaseRenewalSection">
              <section-header title="Lease Renewal / Lease Return" parentSelector="#lease-renewal-section"></section-header>
              <div class="field">
                <label class="label">Is there a lease to return?</label>
                <div class="control">
                  <b-switch type="is-info" v-model="hasLeaseInventory" @click.native="toggleLeaseRenewal" :disabled="hasTradeInventory">{{ humanizedHasLeaseRenewal }}</b-switch>
                </div>
              </div>
              <div v-if="hasLeaseInventory">
                <lease-renewal-capture v-for="(leaseItem, index) in leaseRenewalDealItems" :key="300 + index"
                :selectedDealer="selectedDealer"
                v-model="leaseRenewalDealItems[index]"
                mode="LeaseRenewal"
                :defaultSalesPerson="selectedSalesAgent"
                :defaultSellerType="selectedSellerType"
                :defaultSeller="selectedSeller"></lease-renewal-capture>
              </div>
              <div class="inner-add-remove-button" v-if="hasLeaseInventory">
                <a class="button is-primary" @click="AddDealItem({type: 'LeaseRenewal'})">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" @click="removeDealItem('LeaseRenewal')" v-if="leaseRenewalDealItems.length > 1">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div id="note-section" class="box" v-show="showNotesSection">
              <section-header title="Notes & Comments" parentSelector="#note-section"></section-header>
              <component v-for="noteSection in noteSectionArray" :key="noteSection.id" :is="noteSection.name" v-model="noteValueArray" label="Note" :required="false" :showDealNoteTypeSelector=true></component>
              <div class="inner-add-remove-button">
                <a class="button is-primary" @click="AddNoteValueSection">
                  <span class="icon">
                    <i class="fas fa-plus"></i>
                  </span>
                </a>
                <a class="button is-danger" v-if="this.noteSectionArray.length > 1" @click="RemoveNoteValueSection">
                  <span class="icon">
                    <i class="fas fa-minus"></i>
                  </span>
                </a>
              </div>
            </div>

            <div id="tag-section" class="box">
              <section-header title="Tags" parentSelector="#tag-section"></section-header>
              <tag-selector v-model="selectedTags" :required="false"></tag-selector>
            </div>

            <portal :to="actionPortal">
              <div class="field is-grouped">
                <p class="control" v-if="hasFeatureAccess('deal.modify')">
                  <button :disabled="isUploadingFile" class="button is-primary" :class="{'is-loading': isSaving }"  type="button" @click="validateBeforeSubmit">Save</button>
                </p>
                <p class="control">
                  <a class="button is-danger" @click="onCancel()">Cancel</a>
                </p>
              </div>
            </portal>

            <portal to="footer-toolbar-middle" :disabled="!canShowFooterToolbarMiddleRight">
              <div class="footer-toolbar-content subtitle is-7 is-hidden-mobile">
                {{dealOverview}}
              </div>
            </portal>

            <portal to="footer-toolbar-right" :disabled="!canShowFooterToolbarMiddleRight">
              <div class="quick-action-list">
                <deal-buyers-agreement ref="buyersAgreementAction" :currentDeal="modifiedDeal" v-if="modifiedDeal && modifiedDeal.id > 0"></deal-buyers-agreement>
              </div>
            </portal>
        </form>
        <b-modal :active.sync="isVehicleAlreadyLocatedDialogActive" :width="640" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Confirmation</p>
            </header>
            <section class="modal-card-body">
              Have you already located the required vehicle for this deal?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onVehicleAlreadyLocated()" type="button">Yes</button>
              <button class="button is-danger" @click="onVehicleNotAlreadyLocated()" type="button">No</button>
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="dealBookedActive" :width="640" scroll="keep" :has-modal-card="true">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Warning</p>
            </header>
            <section class="modal-card-body">
              Do you want to book this deal?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmSaveBookedDeal(true)" type="button">Yes</button>
              <button class="button is-danger" @click="onConfirmSaveBookedDeal(false)" type="button">No</button>
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="cancelBackupDealsActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title">Do you want to cancel any backup deals?</p>
            </header>
            <section class="modal-card-body">
              <cancel-backup-deals-control :selectedDealIds.sync="selectedDealIds" :backupDeals="backupDeals" :hasErrors.sync="cancelBackupDealsErrors"/>
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmCancelBackupDeals(true)" type="button">Yes</button>
              <button class="button is-danger" @click="onConfirmCancelBackupDeals(false)" type="button">No</button>
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="isBuyersAgreementOptionActive" :width="640" scroll="keep" :has-modal-card="true" v-if="modifiedDeal">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Issue Bill of Sale</p>
            </header>
            <section class="modal-card-body">
              Do you want to send the Bill of Sale to sell stock #{{firstSoldItemStockNo}} from {{selectedBuyerName}}?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmBuyersAgreement()">Yes</button>
              <button class="button is-danger" @click="onCancelBuyersAgreement">No</button>
            </footer>
          </div>
        </b-modal>

        <b-modal :active.sync="isLeaseAgreementOptionActive" :width="640" scroll="keep" :has-modal-card="true" v-if="modifiedDeal">
          <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
              <p class="modal-card-title">Issue Lease Agreement</p>
            </header>
            <section class="modal-card-body">
              Do you want to send a Lease Agreement for stock #{{firstSoldItemStockNo}} from {{selectedBuyerName}}?
            </section>
            <footer class="modal-card-foot">
              <button class="button is-primary" @click="onConfirmLeaseAgreement()">Yes</button>
              <button class="button is-danger" @click="onCancelLeaseAgreement">No</button>
            </footer>
          </div>
        </b-modal>

        <!--
        <b-modal :active.sync="customerPossibleDuplicateDealActive" :width="800" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
          <div class="modal-card customer-modal-card" style="width: auto">
            <header class="modal-card-head customer-modal-card-head">
              <p class="modal-card-title">Matching Deals Found</p>
            </header>
            <section class="modal-card-body customer-modal-card-body small-top-padding">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
              <div class="columns">
                <div class="column">
                  <i class="fas fa-exclamation-triangle has-text-warning"></i>
                  <label class="title is-6 has-text-danger">Existing active deals found for this customer</label>
                  <table id="duplicate-deals-table" class="table is-bordered is-striped is-fullwidth mt-10">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Code</th>
                        <th>Status</th>
                        <th>Type</th>
                        <th>Vehicle</th>
                        <th>Created</th>
                        <th>Created By</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tr v-for="(deal, index) in selectedCustomerDeals" :key="deal.id">
                      <td>{{index + 1}}</td>
                      <td>
                        <a class="is-link" @click="onViewDeal(deal.id)">
                          {{deal.code}}
                        </a>
                      </td>
                      <td>{{deal.typeDescription}}</td>
                      <td>{{deal.saleTypeDescription}} - {{deal.stockTypeDescription}}</td>
                      <td>{{deal.vehicleDisplayName}}</td>
                      <td>{{deal.createdAt | formatDateOnly}}</td>
                      <td>{{deal.createdBy}}</td>
                      <td><a class="button is-success" @click="onViewDeal(deal.id)">Select</a></td>
                    </tr>
                    <tr v-if="selectedCustomerDeals.length === 0">
                      <td colspan="6">None specified</td>
                    </tr>
                  </table>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot customer-modal-card-foot">
              <div class="columns is-multiline">
                <div class="column is-12"><span class="title is-6 has-text-danger">Please select an existing deal above OR create a new deal for this customer</span></div>
                <div class="column is-12">
                  <button class="button is-success" @click="closeCustomerOrderDeals">Create New Deal</button>
                  <button class="button is-danger" @click="cancelDealCreate">Cancel</button>
                </div>
              </div>
            </footer>
          </div>
        </b-modal>
        -->
        <deal-get-ready-capture
          :dealItems="dealItems"
          :openDialog="isGetReadyCapture"
          :expectedDeliveryDate="expectedDeliveryDateDeal"
          :usePortal="usePortal"
          v-on:get-ready-created="onSaveGetReady"
          v-on:get-ready-cancelled="cancelGetReady"
          v-on:get-ready-skipped="skipGetReady">
        </deal-get-ready-capture>

        <deal-existing-component
          :ref="dealExistingComponent"
          v-model="selectedCustomerDeals"
          v-on:on-cancel="cancelDealCreate"
          v-on:on-selected-deal="onSelectDeal">
        </deal-existing-component>
    </section>
</template>
<script>

import dealMixin from '@/mixins/deal/index'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import dealService from '@/services/dealService'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import SwitchSelector from '@/components/generic/SwitchSelector'
import DealGetReadyCapture from '@/components/sales/deals/DealGetReadyCapture'
import DealExistingComponent from '@/components/generic/sales/DealExistingComponent.vue'
import _ from 'lodash'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'NewDeal',
  mixins: [validateBeforeSubmitMixin, dealMixin, humanizedSwitchMixin],
  components: {
    'switch-selector': SwitchSelector,
    'deal-get-ready-capture': DealGetReadyCapture,
    'deal-existing-component': DealExistingComponent
  },
  methods: {
    onVehicleMakeChanged: function (newVehicleSaleType) {
      this.populateDealAdditionalCosts()
    },
    onVehicleSaleTypeChanged: function (newVehicleSaleType) {
      this.populateDealAdditionalCosts()
    },
    onSaleTypeChange: function (newSaleType) {
      this.populateDealAdditionalCosts()
    },
    onSaveGetReady: function (soldServiceRequest) {
      this.soldServiceRequest = soldServiceRequest
      this.soldServiceRequest.lines = soldServiceRequest.lines.map((x) => {
        return x.description
      })

      if (soldServiceRequest.updateExpectedDeliveryDate) {
        this.promiseDate = soldServiceRequest.requiredAt
      }

      this.hasServiceRequest = true
      this.confirmedGetReadySave = true
      this.isGetReadyCapture = false
      this.onSave()
    },
    onSave: function () {
      if ((this.selectedType.name === 'Pending Delivery' && this.mustCreateDealPendingServiceRequest) && !this.confirmedGetReadySave) {
        this.isGetReadyCapture = true
        return
      }

      if (this.selectedType.name === 'Booked' && !this.confirmedBookedSave) {
        this.dealBookedActive = true
        return
      }

      if (this.isSaleTypeOfRetail && this.selectedType.name === 'Booked' && !(this.hasTradeInventory || this.hasLeaseInventory || this.selectedMake)) {
        this.serverErrorMessage = 'One of previous make or trade or lease renewal / lease return is required for retail a deal.'
        return
      }

      this.soldAt = this.soldAt ? this.convertLocalToUtc(new Date(this.soldAt)) : null
      this.promiseDate = this.promiseDate ? this.convertLocalToUtc(new Date(this.promiseDate)) : null

      var dealModel = {
        isCreate: true,
        dealerId: this.dealerId,
        previousVehicleMakeId: (this.selectedMake !== null) ? this.selectedMake.id : null,
        type: this.selectedType.altName,
        stockType: this.selectedStockType.altName,
        saleType: this.selectedSaleType.name,
        customer: {
          id: (this.selectedCustomer != null && this.selectedSaleType !== null && this.selectedSaleType.name === 'Retail') ? this.selectedCustomer.id : null
        },
        salesPersonId: (this.selectedSalesAgent != null) ? this.selectedSalesAgent.id : null,
        financeProcessedById: (this.selectedFinanceProcessedBy != null) ? this.selectedFinanceProcessedBy.id : null,
        sourceId: (this.selectedLeadSource !== null) ? this.selectedLeadSource.id : null,
        promiseDate: this.promiseDate,
        soldAt: this.soldAt,
        status: 'Active',
        attachments: [],
        items: [],
        notes: [],
        supplementalCosts: [],
        commissions: [],
        tags: [],
        supplierId: (this.selectedSupplier != null && this.selectedSaleType !== null && this.selectedSaleType.name === 'Wholesale') ? this.selectedSupplier.id : null,
        dealCode: this.dealCode,
        appointmentStatus: 'NotSet',
        coCustomer: {
          id: (this.selectedCoCustomer != null && this.selectedSaleType !== null && this.selectedSaleType.name === 'Retail') ? this.selectedCoCustomer.id : null
        },
        coCustomerRelationship: (this.selectedCoCustomerRelationship) ? this.selectedCoCustomerRelationship.altName : null,
        salesPersonManagerId: (this.selectedSalesAgentManager != null) ? this.selectedSalesAgentManager.id : null,
        soldServiceRequest: this.soldServiceRequest,
        contractType: this.selectedContractType.name,
        leaseContractTermType: this.selectedContractType.name === 'Retail' || !this.selectedLeaseContractTermType ? null : this.selectedLeaseContractTermType.altName,
        leaseAmount: this.selectedContractType.name === 'Retail' ? null : this.leaseAmount,
        residualValue: this.selectedContractType.name === 'Retail' ? null : this.residualValue,
        inboundStatusType: this.arrivalStatus ? this.arrivalStatus.altName : null,
        cashDown: this.isCashDown,
        depositAmount: !this.isCashDown ? null : this.depositAmount
      }

      if (this.saleDocuments.length > 0 && this.hasDealAttachments) {
        let saleDocumentFiles = this.saleDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'SaleDocument',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...saleDocumentFiles)
      }
      if (this.creditCardDocuments.length > 0 && this.hasDealAttachments) {
        let creditCardDocumentFiles = this.creditCardDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CreditCard',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...creditCardDocumentFiles)
      }
      if (this.buyersAgreementDocuments.length > 0 && this.hasDealAttachments) {
        let buyersAgreementDocumentFiles = this.buyersAgreementDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'BuyersAgreement',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...buyersAgreementDocumentFiles)
      }
      if (this.invoiceDocuments.length > 0 && this.hasDealAttachments) {
        let invoiceDocumentFiles = this.invoiceDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Invoice',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...invoiceDocumentFiles)
      }
      if (this.otherDocuments.length > 0 && this.hasDealAttachments) {
        let otherDocumentFiles = this.otherDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Other',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...otherDocumentFiles)
      }
      if (this.wireDocuments.length > 0 && this.hasDealAttachments) {
        let wireDocumentFiles = this.wireDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Wire',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...wireDocumentFiles)
      }
      if (this.generalDocuments.length > 0 && this.hasDealAttachments) {
        let generalDocumentFiles = this.generalDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'General',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...generalDocumentFiles)
      }
      if (this.fundingLetterDocuments.length > 0 && this.hasDealAttachments) {
        let fundingLetterDocumentFiles = this.fundingLetterDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'FundingLetter',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...fundingLetterDocumentFiles)
      }
      if (this.squareDocuments.length > 0 && this.hasDealAttachments) {
        let squareDocumentFiles = this.squareDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Square',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...squareDocumentFiles)
      }
      if (this.cashDocuments.length > 0 && this.hasDealAttachments) {
        let cashDocumentFiles = this.cashDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Cash',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...cashDocumentFiles)
      }
      if (this.checkDocuments.length > 0 && this.hasDealAttachments) {
        let checkDocumentFiles = this.checkDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'Check',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...checkDocumentFiles)
      }
      if (this.dealJacketDocuments.length > 0 && this.hasDealAttachments) {
        let dealJacketDocumentFiles = this.dealJacketDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'DealJacket',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...dealJacketDocumentFiles)
      }
      if (this.customerVerificationDocuments.length > 0 && this.hasDealAttachments) {
        let customerVerificationDocumentFiles = this.customerVerificationDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CustomerVerification',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...customerVerificationDocumentFiles)
      }
      if (this.cdkRecapDocuments.length > 0 && this.hasDealAttachments) {
        let cdkRecapDocumentFiles = this.cdkRecapDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CdkRecap',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...cdkRecapDocumentFiles)
      }
      if (this.dealPresentationDocuments.length > 0 && this.hasDealAttachments) {
        let dealPresentationDocumentFiles = this.dealPresentationDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'DealPresentation',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...dealPresentationDocumentFiles)
      }
      if (this.bankCreditApplicationApprovalDocuments.length > 0 && this.hasDealAttachments) {
        let bankCreditApplicationApprovalDocumentFiles = this.bankCreditApplicationApprovalDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'BankCreditApplicationApproval',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...bankCreditApplicationApprovalDocumentFiles)
      }
      if (this.vehicleCostDocuments.length > 0 && this.hasDealAttachments) {
        let vehicleCostDocumentFiles = this.vehicleCostDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'VehicleCost',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...vehicleCostDocumentFiles)
      }
      if (this.cdkWashoutDocuments.length > 0 && this.hasDealAttachments) {
        let cdkWashoutDocumentFiles = this.cdkWashoutDocuments.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            type: x.type,
            classType: 'CdkWashout',
            uniqueId: x.uniqueId
          }
        })
        dealModel.attachments.push(...cdkWashoutDocumentFiles)
      }

      // inventory sold
      this.saveDealItem(dealModel, 'Sold')

      // inventory trade
      if (this.hasTradeInventory) {
        this.saveDealItem(dealModel, 'Trade')
      }

      // inventory lease
      if (this.hasLeaseInventory) {
        this.saveDealItem(dealModel, 'LeaseRenewal')
      }

      // notes
      this.addDealNote(dealModel)

      this.saveSupplementalCost(dealModel)

      this.saveCommission(dealModel)

      if (this.selectedTags.length > 0) {
        dealModel.tags = this.selectedTags
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      dealService.save(dealModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.modifiedDeal = response.deal
          this.backupDeals = response.backupDeals || []
          this.onChangesSaved()
          if (this.dealCancellationRequired) {
            if (this.buyersAgreementRequired) {
              this.isBuyersAgreementOptionActive = true
            } else if (this.leaseAgreementRequired) {
              this.isLeaseAgreementOptionActive = true
            } else {
              this.cancelBackupDealsActive = true
            }
          } else {
            if (this.buyersAgreementRequired) {
              this.isBuyersAgreementOptionActive = true
            } else if (this.leaseAgreementRequired) {
              this.isLeaseAgreementOptionActive = true
            } else {
              this.onComplete(this.modifiedDeal)
            }
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    initDealerValues: function () {
      if (this.$store.state.dealer.currentDealer.isWholesaleDealer) {
        this.selectedSaleType = { id: 1, name: 'Wholesale', altName: 'Wholesale' }
      }
    },
    cancelDealCreate: function () {
      // this.customerPossibleDuplicateDealActive = false
      this.onCancel()
    },
    closeCustomerOrderDeals: function () {
      this.customerPossibleDuplicateDealActive = false
    },
    onPopFillExpectedDeliveryDate: function () {
      this.promiseDate = this.proposedPromiseDate
    },
    inProgressDealsByCustomer: function (customerId, type) {
      this.isLoading = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      dealService.getInProgressDealsByPurchaser(customerId, this.dealerId, type).then(response => {
        this.isLoading = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.selectedCustomerDeals = response.results
          if (this.selectedCustomerDeals.length > 0) {
            let dealExistingComponent = this.$refs[this.dealExistingComponent]
            if (dealExistingComponent) {
              dealExistingComponent.onOpenModal()
            }
          }
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    onSelectDeal: function (deal) {
      if (this.hasFeatureAccess('deal.view')) {
        this.$router.push({name: 'ListDeals', params: { id: deal.id }})
      }
    }
  },
  mounted: function () {
    // need at least one sold section by default
    this.AddDealItem({ loadInventoryOnly: true, forInventory: this.inventoryItem, type: 'Sold' })
    if (this.$route.params.dealRedirect) {
      this.dealRedirect = this.$route.params.dealRedirect
    }
    if (this.tradeInventoryItem) {
      this.hasTradeInventory = true
      this.AddDealItem({ loadInventoryOnly: true, forInventory: this.tradeInventoryItem, type: 'Trade' })
    }
    this.AddNoteValueSection()
    this.initDealerValues()
    this.selectedType = { id: 1, name: 'Pending Delivery', altName: 'Pending' }
    this.selectedStockType = { id: 0, name: 'In Stock', altName: 'InStock' }
    this.populateDealAdditionalCosts()
  },
  computed: {
    ...mapUserGetters(['allUsers', 'currentUser']),
    expectedDeliveryDateDeal: function () {
      return this.promiseDate ? new Date(this.promiseDate) : null
    },
    dealerId: function () {
      return this.$store.state.dealer.currentDealer.id
    },
    orderedDealLeaseContractTermTypes: function () {
      let dealLeaseContractTermTypes = this.definedTypes.dealLeaseContractTermTypes.options
      return _.sortBy(dealLeaseContractTermTypes, ['name'])
    },
    dealExistingComponent: function () {
      return 'dec-' + this.dealId
    }
  },
  watch: {
    selectedCustomer: function (newValue, oldValue) {
      if (newValue) {
        this.inProgressDealsByCustomer(newValue.id, 'Individual')
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .customer-modal-card-head, .customer-modal-card-foot {
    padding: 20px !important;
  }

  .customer-modal-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  .customer-modal-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }

  #duplicate-deals-table td {
      vertical-align: middle;
  }
</style>

import mutationTypes from '../mutation-types.js'
import featureService from '../../services/featureService.js'

const state = {
  allFeatures: []
}

const getters = {
  allFeatures: state => {
    return state.allFeatures
  }
}

const mutations = {
  [mutationTypes.SET_ALL_FEATURES] (state, features) {
    state.allFeatures = features
  }
}

const actions = {
  setFeatures ({commit, state}) {
    commit(mutationTypes.SET_ALL_FEATURES, [])
    featureService.all()
      .then((data) => {
        commit(mutationTypes.SET_ALL_FEATURES, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

import axios from 'axios'
import baseService from './baseService'
import wholesaleReceivableData from './data/wholesaleReceivable'

const wholesaleReceivableService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(wholesaleReceivableData)
      } else {
        axios
          .post('/wholesaleReceivable/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (wholesaleReceivableId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(wholesaleReceivableData.all.find((x) => x.id === wholesaleReceivableId))
      } else {
        axios
          .post('/wholesaleReceivable/get?id=' + wholesaleReceivableId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (wholesaleReceivableModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        wholesaleReceivableModels.forEach(element => {
          wholesaleReceivableData.all.remove((x) => x.id === element.id)
        })
        resolve(wholesaleReceivableData.all)
      } else {
        var ids = []
        wholesaleReceivableModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/wholesaleReceivable/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  fetchNotesByWholesaleReceivableId (wholesaleReceivableId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(wholesaleReceivableData.all)
      } else {
        axios
          .get('/wholesaleReceivable/wholesalereceivablenotes?id=' + wholesaleReceivableId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  addNote (wholesaleReceivableNoteModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var wholesaleReceivable = wholesaleReceivableData.all.find((x) => x.id === wholesaleReceivableNoteModel.wholesaleReceivableId)
        if (wholesaleReceivable) {
          wholesaleReceivable.notes.push(wholesaleReceivableNoteModel)
        }
        resolve(wholesaleReceivable)
      } else {
        axios
          .post('/wholesaleReceivable/notes', wholesaleReceivableNoteModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/wholesaleReceivable/deleteNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  restoreNote (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/wholesaleReceivable/activateNote', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNoteHotStatus (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/wholesaleReceivable/toggleNoteHotStatus', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  toggleNotePinned (note) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/wholesaleReceivable/toggleNotePinned', note, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  setStageStatus (wholesaleReceivableItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(wholesaleReceivableData.all.find((x) => x.id === wholesaleReceivableItem.Id))
      } else {
        axios
          .post('/wholesaleReceivable/setStageStatus', wholesaleReceivableItem, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  exportList (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(wholesaleReceivableData)
      } else {
        axios({
          url: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/wholesalereceivable/list',
          method: 'POST',
          responseType: 'blob',
          data: searchCriteria,
          withCredentials: false
        }).then((response) => {
          resolve(response)
        }).catch(error => {
          reject(error.response)
        })
      }
    })
  },
  setTitleStatus (wholesaleReceivableItem) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(wholesaleReceivableData.all.find((x) => x.id === wholesaleReceivableItem.Id))
      } else {
        axios
          .post('/wholesaleReceivable/setTitleStatus', wholesaleReceivableItem, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  paymentReadyNotification (paymentReadNotificationModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve('')
      } else {
        axios
          .post('/wholesaleReceivable/paymentReadyNotification', paymentReadNotificationModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          }).catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  createReviewRequest (wholesaleReceivable, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/wholesaleReceivable/createReviewRequest', {wholesaleReceivableId: wholesaleReceivable.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  clearReviewRequest (wholesaleReceivable, requestNotes) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve()
      } else {
        axios
          .post('/wholesaleReceivable/clearReviewRequest', {wholesaleReceivableId: wholesaleReceivable.id, requestNote: requestNotes}, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markWholesaleReceivablesCleared (wholesaleReceivableModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        wholesaleReceivableModels.forEach(element => {
          wholesaleReceivableModels.all.remove((x) => x.id === element.id)
        })
        resolve(wholesaleReceivableModels.all)
      } else {
        var ids = []
        wholesaleReceivableModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/wholesaleReceivable/markCleared', { wholesaleReceivableIds: ids }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markPayoffVerified (wholesaleReceivableModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        wholesaleReceivableModels.forEach(element => {
          wholesaleReceivableModels.all.remove((x) => x.id === element.id)
        })
        resolve(wholesaleReceivableModels.all)
      } else {
        var ids = []
        wholesaleReceivableModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/wholesaleReceivable/markPayoffVerified', { wholesaleReceivableIds: ids }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  markPayoffVerificationRequired (wholesaleReceivableModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        wholesaleReceivableModels.forEach(element => {
          wholesaleReceivableModels.all.remove((x) => x.id === element.id)
        })
        resolve(wholesaleReceivableModels.all)
      } else {
        var ids = []
        wholesaleReceivableModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/wholesaleReceivable/markPayoffVerificationRequired', { wholesaleReceivableIds: ids }, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default wholesaleReceivableService

import mutationTypes from '../mutation-types.js'
import teamService from '../../services/teamService.js'

const state = {
  currentTeam: {
    roles: []
  },
  allTeams: []
}

const getters = {
  allTeams: state => {
    return state.allTeams
  },
  allowedLogins: state => {
    if (state.currentUser && state.currentUser.logins) {
      return state.currentUser.logins.map((x) => {
        return x.name
      })
    } else {
      return []
    }
  },
  loginCount: (state, getters) => {
    return getters.allowedLogins ? getters.allowedLogins.length : 0
  },
  isInLogin: (state, getters) => (requiredLogin) => {
    return getters.allowedLogins.includes(requiredLogin)
  }
}

const mutations = {
  [mutationTypes.SYNC_TEAM] (state, team) {
    var applicable = state.allTeams.find((x) => x.id === team.id)
    if (applicable) {
      applicable = team
    } else {
      state.allTeams.push(team)
    }
  },
  [mutationTypes.SET_TEAMS] (state, teams) {
    state.allTeams = teams
  }
}

const actions = {
  syncTeam ({commit, state}, team) {
    commit(mutationTypes.SYNC_TEAM, team)
  },
  setTeams ({commit, state}) {
    commit(mutationTypes.SET_TEAMS, [])
    teamService.allTeams().then((data) => {
      commit(mutationTypes.SET_TEAMS, data.results)
    }).catch((error) => {
      console.log(error)
    })
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

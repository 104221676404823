<template>
  <div v-if="isArchiveable">
    <div v-if="hasArchiveAccess && isArchiveAllowed">
      <div class="quick-action" v-if="!isArchiveConfirmationActive">
        <button class="button" @click="onArchive()" title="Click to archive the selected item">
          <b-icon pack="fal" icon="ban" custom-size="fa-lg"></b-icon>
        </button>
      </div>
      <portal to="global-modal-portal" v-else>
          <b-modal :active.sync="isArchiveConfirmationActive" :width="640" scroll="keep" :has-modal-card="true">
              <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                  <p class="modal-card-title">Confirm Archive Action</p>
              </header>
              <section class="modal-card-body">
                  <div v-if="isError">
                  <p v-if="serverErrors.length > 0" ref="generalErrorBlock">
                      <b class="title is-7 is-paddingless">Please correct the following error(s):</b>
                      <ul class="errors">
                      <li v-for="error in serverErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
                      </ul>
                      <br/>
                  </p>

                  <p v-if="serverErrorMessage.length">
                      <ul class="errors">
                      <li>{{ serverErrorMessage }}</li>
                      </ul>
                      <br/>
                  </p>
                  </div>
                  <div v-else>
                      <p>Are you sure you wish to archive stock# <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a>?</p>
                  </div>
              </section>
              <footer class="modal-card-foot">
                  <button class="button is-danger" @click="onConfirmArchive()">Confirm</button>
                  <button class="button" @click="onCancelArchive">Cancel</button>
              </footer>
              </div>
          </b-modal>
      </portal>
    </div>
    <div class="quick-action" v-else>
      <button class="button" disabled :title="invalidForArchivingReasons">
          <b-icon pack="fal" icon="ban" custom-size="fa-lg"></b-icon>
      </button>
    </div>
  </div>
  <div v-else>
    <div v-if="hasRestoreAccess && isRestorable">
      <div class="quick-action" v-if="!isRestoreConfirmationActive">
        <button class="button" @click="onRestore()" title="Click to restore the selected item">
          <b-icon pack="fal" icon="heart-rate" custom-size="fa-lg"></b-icon>
        </button>
      </div>
      <portal to="global-modal-portal" v-else>
          <b-modal :active.sync="isRestoreConfirmationActive" :width="640" scroll="keep" :has-modal-card="true">
              <div class="modal-card" style="width: auto">
              <header class="modal-card-head">
                  <p class="modal-card-title">Confirm Restore Action</p>
              </header>
              <section class="modal-card-body">
                  <div v-if="isError">
                  <p v-if="serverErrors.length > 0" ref="generalErrorBlock">
                      <b class="title is-7 is-paddingless">Please correct the following error(s):</b>
                      <ul class="errors">
                      <li v-for="error in serverErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
                      </ul>
                      <br/>
                  </p>

                  <p v-if="serverErrorMessage.length">
                      <ul class="errors">
                      <li>{{ serverErrorMessage }}</li>
                      </ul>
                      <br/>
                  </p>
                  </div>
                  <div v-else>
                      <p>Are you sure you wish to restore stock# <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{inventoryItem.stockNo}}</span></a>?</p>
                  </div>
              </section>
              <footer class="modal-card-foot">
                  <button class="button is-danger" @click="onConfirmRestore()">Confirm</button>
                  <button class="button" @click="onCancelRestore">Cancel</button>
              </footer>
              </div>
          </b-modal>
      </portal>
    </div>
    <div class="quick-action" v-else>
      <button class="button" disabled :title="invalidForRestoreReasons">
          <b-icon pack="fal" icon="heart-rate" custom-size="fa-lg"></b-icon>
      </button>
    </div>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import inventoryService from '../../services/inventoryService'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'ArchiveInventoryAction',
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      isArchiveConfirmationActive: false,
      isRestoreConfirmationActive: false,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4()
    }
  },
  methods: {
    onArchive: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isArchiveConfirmationActive = true
    },
    onRestore: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isRestoreConfirmationActive = true
    },
    onConfirmArchive: function () {
      this.isArchiveConfirmationActive = false
      this.isSaving = true
      inventoryService.archive([this.inventoryItem]).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          eventBus.$emit('inventory-changed', [this.inventoryItem])
          this.$emit('inventory-archived', [this.inventoryItem])
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
      this.isSaving = true
    },
    onCancelArchive: function () {
      this.isArchiveConfirmationActive = false
    },
    onConfirmRestore: function () {
      this.isRestoreConfirmationActive = false
      this.isSaving = true
      inventoryService.restore([this.inventoryItem]).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          eventBus.$emit('inventory-changed', [this.inventoryItem])
          this.$emit('inventory-restored', [this.inventoryItem])
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
      this.isSaving = true
    },
    onCancelRestore: function () {
      this.isRestoreConfirmationActive = false
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      if (inventoryItem && inventoryItem.stockNo !== '') {
        this.$copyText(inventoryItem.stockNo).then(
          function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
              position: 'is-top',
              type: 'is-info'
            })
          }, function (e) {
            that.$buefy.toast.open({
              duration: 2500,
              message: 'Could not copy stock no. to clipboard',
              position: 'is-top',
              type: 'is-warning'
            })
          }
        )
      }
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    ...mapDealerState(['currentDealer']),
    isArchivableType: function () {
      if (['New', 'Purchased', 'PpiClosed', 'TradeIn', 'ForPpiInspection', 'Consigned', 'LeaseReturn', 'ForPpaAppraisal'].indexOf(this.inventoryItem.type) >= 0) {
        return true
      }
      return false
    },
    isAccessoriesLocked: function () {
      return this.currentDealer && this.inventoryItem && this.currentDealer.trackVehicleAccessories === true && this.inventoryItem.accessoryStatusId === 0
    },
    isArchiveAllowed: function () {
      let isValidInventoryType = this.isArchivableType
      let isAcessoriesLocked = this.isAccessoriesLocked
      let isArchiveable = this.isArchiveable
      let isValidForArchive = isValidInventoryType && !isAcessoriesLocked && isArchiveable
      return isValidForArchive
    },
    invalidForArchivingReasons: function () {
      if (!this.hasArchiveAccess) {
        return 'You do not have access to the archive function.'
      }
      if (!this.isArchivableType) {
        return 'This inventory type cannot be archived.'
      }
      if (this.isAccessoriesLocked) {
        return 'This items current accessory status prevents archiving.'
      }
      if (this.isArchiveable) {
        return 'This inventory item is already archived.'
      }
      return ''
    },
    isArchiveable: function () {
      return this.inventoryItem.status !== 'Archived'
    },
    isRestorable: function () {
      return this.inventoryItem.status === 'Archived'
    },
    invalidForRestoreReasons: function () {
      if (!this.hasRestoreAccess) {
        return 'You do not have access to the restore function.'
      }
      return 'This item cannot be restored as it is not archived.'
    },
    hasArchiveAccess: function () {
      let allowedPermissions = ['inventory.archive']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    hasRestoreAccess: function () {
      let allowedPermissions = ['inventory.restore']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isError: function () {
      return this.serverErrorMessage.length > 0 || this.serverErrors.length > 0
    }
  },
  watch: {
  },
  mounted () {
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }
</style>

<template>
  <div class="is-inline-block" v-if="hasLostTitleApplicationAccess && isValid">
    <div class="is-inline-block" v-if="hasLostTitleApplicationAccess && showButton">
      <div class="quick-action" v-if="isValidForLostTitleApplication">
          <button type="button" class="button" @click="showLostTitleModal()" v-tooltip="'Lost Title Application Status: ' + getDescriptionByAgreementStatus(currentInventory.lostTitleApplicationStatusId, 'Click to send out the lost title application for for signing.')">
            <i data-type="L" class="icon fal fa-ballot fa-lg" :class="getColorClassByAgreementStatus(currentInventory.lostTitleApplicationStatusId)"/>
          </button>
      </div>
      <div class="quick-action" v-else>
          <button type="button" class="button" disabled v-tooltip="invalidForLostTitleApplicationReasons">
            <i data-type="L" class="icon fal fa-ballot fa-lg"/>
          </button>
      </div>
    </div>
    <div class="control is-inline-block quick-action" v-if="lastLostTitleApplication !== '' && hasLostTitleApplicationAccess && showButton">
      <button class="button is-success" @click.prevent="onDownloadLastLostTitleApplication" v-tooltip="'Click to download the last signed lost title application.'">
        <b-icon pack="fal" icon="cloud-download" custom-size="fa-lg"></b-icon>
      </button>
    </div>
    <portal to="global-modal-portal" :canCancel="false" v-if="activeDialog === 'LostTitle'" :order="1">
      <b-modal :key="'BS'+instanceId" :active.sync="showSendTitleConfirmation" scroll="keep" :has-modal-card="true" :canCancel="false">
        <div class="modal-card" style="width: 900px !important;">
          <header class="modal-card-head">
            <div class="subtitle is-6 has-text-centered has-text-info is-marginless">Send Lost Title Application</div>
          </header>
          <section class="modal-card-body view-container" style="padding: 15px">
            <div id="simple-overview" class="box" v-if="activeInventory">
                <h3 class="title is-6">Vehicle Details</h3>
                <div class="columns is-multiline">
                    <div class="column is-3">
                    <div class="field">
                        <label class="label">Vin Number</label>
                        <div class="field">
                        {{ activeInventory.vinNo }}
                        </div>
                    </div>
                    </div>
                    <div class="column is-3">
                    <div class="field">
                      <label class="label">Stock No</label>
                      <div class="field">
                        <a @click.stop.prevent="onStockNoClick(activeInventory)">
                          <span class="has-text-weight-semibold">
                            {{ activeInventory.stockNo }}
                          </span>
                        </a>
                      </div>
                    </div>
                    </div>
                    <div class="column is-3">
                    <div class="field">
                        <label class="label">Production Year</label>
                        <div class="field">
                        {{ activeInventory.vehicleProductionYear || 'Unspecified' }}
                        </div>
                    </div>
                    </div>
                    <div class="column is-3">
                    <div class="field">
                        <label class="label">Make</label>
                        <div class="field">
                        {{ activeInventory.vehicleMakeName || activeInventory.customMake || 'Unspecified' }}
                        </div>
                    </div>
                    </div>
                    <div class="column is-3">
                    <div class="field">
                        <label class="label">Model</label>
                        <div class="field">
                        {{ activeInventory.vehicleModelName || activeInventory.customModel || 'Unspecified' }}
                        </div>
                    </div>
                    </div>
                    <div class="column is-3">
                    <div class="field">
                        <label class="label">Trim</label>
                        <div class="field">
                        {{ activeInventory.trim || 'Unspecified' }}
                        </div>
                    </div>
                    </div>
                    <div class="column is-3">
                    <div class="field">
                        <label class="label">Style</label>
                        <div class="field">
                        {{ activeInventory.style || 'Unspecified' }}
                        </div>
                    </div>
                    </div>
                    <div class="column is-3">
                    <div class="field">
                        <label class="label">Mileage</label>
                        <div class="field">
                        {{ activeInventory.usage || 'Unspecified' }}
                        &nbsp;
                        {{ activeInventory.trueMileageUnknown ? 'TMU' : ''}}
                        </div>
                    </div>
                    </div>
                    <div class="column is-3" v-if="activeInventory.purchasedFromName">
                    <div class="field">
                        <label class="label">Purchased From</label>
                        <div class="field">
                        {{ activeInventory.purchasedFromName }}
                        </div>
                    </div>
                    </div>
                    <div class="column is-3">
                    <div class="field">
                        <label class="label">Sale Price</label>
                        <div class="field">
                        {{ applicablePurchaseCost | currency }}
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <div>
                <div v-if="!isUpload">
                    <form data-vv-scope="recipient-form">
                    <div class="columns is-multiline">
                        <div class="column is-12">
                            <div class="field">
                                <label class="label">Recipients Email<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                                <multiselect
                                :multiple="true"
                                :taggable="true"
                                v-validate="{'required': true}"
                                data-vv-scope="recipient-form"
                                data-vv-as="signature recipients"
                                data-vv-validate-on="input|close"
                                :class="{'is-danger': errors.has('recipient-form.recipients') }"
                                @tag="onRecipientAdded"
                                name="recipients"
                                label="fullName"
                                trackBy="email"
                                tagPlaceholder="Enter to add email address"
                                v-model="selectedRecipients"
                                :options="selectedRecipients">
                                </multiselect>
                                <span v-show="errors.has('recipient-form.recipients')" class="help is-danger"><i v-show="errors.has('recipient-form.recipients')" class="fas fa-exclamation-triangle"></i> {{ errors.first('recipient-form.recipients') }}</span>
                                <span v-show="mustShowEmailError" class="help is-danger"><i v-show="mustShowEmailError" class="fas fa-exclamation-triangle"></i> {{ emailMatchErrorMessage }}</span>
                            </div>
                        </div>
                        <div class="column is-12">
                            <div class="field">
                                <label class="label">Recipients Mobile</label>
                                <multiselect
                                :multiple="true"
                                :taggable="true"
                                data-vv-scope="recipient-form"
                                data-vv-as="signature recipients mobile"
                                data-vv-validate-on="input|close"
                                @tag="onRecipientMobileAdded"
                                name="recipients-mobile"
                                label="fullName"
                                trackBy="mobile"
                                tagPlaceholder="Enter to add mobile number"
                                v-model="selectedRecipientsMobile"
                                :options="selectedRecipientsMobile">
                                </multiselect>
                                <span v-show="mobileInvalidFormat !== ''" class="help is-danger"><i v-show="mobileInvalidFormat !== ''" class="fas fa-exclamation-triangle"></i> {{ mobileInvalidFormat }}</span>
                            </div>
                        </div>
                    </div>
                    </form>
                    <div class="columns">
                        <div class="column is-12">
                            <div class="field">
                                <label class="label">Subject</label>
                                <div class="control">
                                    <input name="overridingSubject" data-vv-as="overridingSubject" :class="{'input': true }" type="text" v-model="overridingSubject">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="columns">
                        <div class="column is-12">
                            <div class="field">
                                <label class="label">Message (optional)</label>
                                <div class="control">
                                    <textarea name="optionalMessage" :class="{'textarea': true}" data-vv-as="optional message" v-model="optionalMessage"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div class="columns" style="margin-left:-0.5em">
                    <div class="column is-12">
                        <file-selector
                        v-model="lostTitle"
                        :multiple="false"
                        label="Lost Title Application"
                        :required="true"
                        :isBoxed="false"
                        :usePortalViewer="false"
                        @uploadStarted="uploadStarted"
                        @uploadComplete="uploadComplete">
                        </file-selector>
                    </div>
                    </div>
                </div>
            </div>
          </section>

          <footer class="modal-card-foot">
            <div class="columns" style="width: 100%">
              <div class="column is-4">
                <button class="button is-primary" :class="{'is-loading': addingLostTitleApplication}"  :disabled="sendTitleLostDocumentDisallowed || isUploadingFile" @click="onEmailLostTitle(activeInventory, false)" type="button" v-if="!isUpload">Send</button>
                <button class="button is-primary" :class="{'is-loading': addingLostTitleApplication}"  :disabled="sendTitleLostDocumentDisallowed || isUploadingFile" @click="onEmailLostTitle(activeInventory, false)" type="button" v-if="isUpload">Save</button>
                <button class="button is-danger" @click="closeLostTitleModal()" type="button">Cancel</button>
              </div>
              <div class="column is-8">
                <button title="This action will save lost title application and allow you to capture a signature immediately" :disabled="lostTitleApplicationSendDisallowed || isUploadingFile" class="button is-primary is-pulled-right" style="margin-right: 5px;" v-if="!isUpload" :class="{'is-loading': isSaving && isSignNow }" @click="onEmailLostTitle(activeInventory, true)" type="button">Sign</button>
                <button class="button is-primary is-pulled-right" style="margin-right: 5px;" :class="{'is-loading': lostTitleDocumentPreviewDownloading}" @click="onPreview(activeInventory, '')" type="button" v-if="!isUpload">Preview</button>
                <button class="button is-primary is-pulled-right" style="margin-right: 5px;" @click="updateUploadState()" type="button">{{isUpload ? 'Cancel Upload & Send For Signing': 'Upload Signed Application'}}</button>
              </div>
            </div>
          </footer>
        </div>
      </b-modal>
    </portal>
    <pdf-modal
        :pdfFile="pdfModalFile"
        :usePortal="true"
        :portalOrder="2"
        v-on:on-pdf-downloaded="onPreviewed"
        v-on:on-pdf-closed="onPreviewClosed"
        v-if="pdfModalFile && pdfModalFile.src">
    </pdf-modal>
    <portal to="global-modal-portal" style="max-width: 480px;" v-if="activeDialog === 'SignatureViewer'" :order="3">
      <b-modal :key="'BP' + instanceId" :active.sync="isSignatureActive" scroll="keep"  :has-modal-card="true" v-if="activeDialog === 'SignatureViewer'" class="signature-modal" :canCancel="false">
        <div class="modal-card">
          <header class="modal-card-head">
              <p class="modal-card-title">Signing Lost Title Application</p>
          </header>
          <section class="modal-card-body">
            <vue-signature ref="signature" :sigOption="option" :w="'240px;'" :h="'480px'"></vue-signature>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': loadingSign }" @click.prevent="onSign">Sign</button>
            <button class="button is-warning" @click.prevent="onClearSign">Clear</button>
            <button class="button is-danger" @click.prevent="onCancelSignNow">Cancel</button>
          </footer>
        </div>
      </b-modal>
    </portal>
  </div>

</template>
<script>
import inventoryService from '@/services/inventoryService'
import logger from '@/services/loggingService'
import supplierService from '@/services/supplierService'
import customerService from '@/services/customerService'
import eventBus from '@/eventBus'
import utilitiesMixin from '@/mixins/generic/utilities'
import Vue from 'vue'
import formattingMixin from '@/mixins/inventory/formatting'
import FileSelector from '@/components/generic/FileSelector'
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'

import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapSubscriberGetters = createNamespacedHelpers('subscriber').mapGetters

export default {
  name: 'InventoryLostTitleAction',
  mixins: [utilitiesMixin, formattingMixin],
  components: {
    'file-selector': FileSelector,
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    showButton: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      activeInventory: null,
      selectedWholeSaler: null,
      selectedCustomer: null,
      serverErrorMessage: '',
      serverErrors: [],
      isLoading: false,
      lostTitleDocumentPreviewDownloading: false,
      showSendTitleConfirmation: false,
      instanceId: this.$uuid.v4(),
      isUpload: false,
      selectedRecipients: [],
      selectedRecipientsMobile: [],
      emailMatchErrorMessage: '',
      mobileInvalidFormat: '',
      overridingSubject: '',
      optionalMessage: '',
      selectedSource: null,
      isSignatureActive: false,
      isSignNow: false,
      lostTitleAffidavitFile: [],
      downloadUrl: '',
      isSaving: false,
      loadingSign: false,
      option: {
        penColor: 'rgb(0, 0, 0)',
        backgroundColor: 'rgb(255,255,255)'
      },
      closeView: false,
      addingLostTitleApplication: false,
      lostTitle: [],
      redirectOnClose: false,
      isUploadingFile: false,
      fileUploadCount: 0,
      pdfModalFile: null
    }
  },
  methods: {
    onRecipientAdded: function (data) {
      var recipient = {
        fullName: data,
        email: data
      }

      this.selectedRecipients.push(recipient)
      this.checkEmails(this.selectedRecipients)
    },
    onRecipientMobileAdded: function (data) {
      this.mobileInvalidFormat = ''
      if (data && data.length === 16) {
        var recipient = {
          fullName: data,
          mobile: data
        }
        this.selectedRecipientsMobile.push(recipient)
      } else {
        this.mobileInvalidFormat = 'Invalid format for mobile number:  ' + data + '. Mobile format should be +1(xxx)-xxx-xxxx'
      }
    },
    clearAllRecipients: function () {
      this.optionalMessage = ''
      this.overridingSubject = ''
      this.selectedRecipients = []
      this.selectedRecipientsMobile = []
      this.isUpload = false
      this.lostTitle = []
      this.mobileInvalidFormat = ''
      this.emailMatchErrorMessage = ''
    },
    checkEmails: function (recipients) {
      if (recipients.length > 0) {
        let emails = recipients.map(function (x) {
          return x.email
        })

        let blacklistEmailCheckModel = {
          subscriberId: this.getCurrentSubscriber.id,
          emailAddresses: emails,
          errorMessage: ''
        }

        inventoryService.getVerifiedEmailList(blacklistEmailCheckModel).then(response => {
          blacklistEmailCheckModel = response.blacklistEmailCheck

          let approvedEmails = blacklistEmailCheckModel.emailAddresses.map(function (x) {
            return {
              email: x,
              fullName: x
            }
          })
          this.selectedRecipients = approvedEmails
          if (this.selectedRecipients.length === 0) {
            this.selectedRecipients = []
          }

          if (blacklistEmailCheckModel.errorMessage !== '' || blacklistEmailCheckModel.errorMessage !== null) {
            this.emailMatchErrorMessage = blacklistEmailCheckModel.errorMessage
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
        })
      }
    },
    closePdf () {
      if (this.closeView) {
        this.closeLostTitleModal()
        this.updateAppState(this.activeInventory)
      }
    },
    onEmailLostTitle: function (inventory, isSignNow) {
      if (this.isUpload) {
        this.onUploadLostTitleDocument(inventory)
      } else {
        var emailAddresses = this.selectedRecipients.map(e => e.email).join(', ')
        var mobileNumbers = this.selectedRecipientsMobile.map(e => e.mobile).join(',')
        var orderEmailModel = {
          subscriberId: inventory.subscriberId,
          dealerId: inventory.dealerId,
          inventoryId: inventory.id,
          type: 'LostTitle',
          destinationAddresses: emailAddresses,
          destinationMobileNumbers: mobileNumbers,
          overridingSubject: this.overridingSubject,
          optionalMessage: this.optionalMessage,
          saleDate: null,
          signInPerson: isSignNow,
          inventoryApprovalModel: null
        }
        if (isSignNow) {
          this.isSignatureActive = true
        } else {
          this.addingLostTitleApplication = true
          inventoryService.addLostTitleApplicationRequest(orderEmailModel).then(response => {
            if (response.errors) {
              this.serverErrors = response.errors
            } else {
              this.addingLostTitleApplication = false
              this.clearAllRecipients()
              this.closeLostTitleModal()
              this.updateAppState(response.inventory)
            }
          }).catch((error) => {
            this.addingLostTitleApplication = false
            throw error
          })
        }
      }
    },
    updateUploadState: function () {
      if (this.isUpload === false) {
        this.isUpload = true
      } else {
        this.isUpload = false
      }
    },
    onUploadLostTitleDocument: function (inventory) {
      if (this.lostTitle.length > 0) {
        var signedLostTitleApplicationModel = this.lostTitle.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            docName: x.uniqueId,
            FileUri: ''
          }
        })
        var inventoryId = inventory.id
        var signedLostTitleApplicationUploadModel = {
          inventoryId: inventoryId,
          fileName: signedLostTitleApplicationModel[0].docName
        }
        this.addingLostTitleApplication = true
        inventoryService.uploadSignedLostTitleApplication(signedLostTitleApplicationUploadModel).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.addingLostTitleApplication = false
            this.clearAllRecipients()
            this.showSendTitleConfirmation = false
            this.updateAppState(response.inventory)
          }
        }).catch((error) => {
          this.addingLostTitleApplication = false
          throw error
        })
      }
    },
    initialize: function () {
      this.isSignNow = false
      this.activeInventory = this.currentInventory
      this.selectedSource = this.activeInventory === null || this.activeInventory.purchasedFromType === null ? null : this.definedTypes.allowedSourceTypes.options.find((x) => (x.name === this.activeInventory.purchasedFromType || x.altName === this.activeInventory.purchasedFromType))
      if (this.selectedSource && this.selectedSource.id > this.definedTypes.allowedSourceTypes.Factory && this.activeInventory.purchasedFromId && this.activeInventory.purchasedFromId !== 0) {
        if (this.selectedSource.id === this.definedTypes.allowedSourceTypes.Wholesaler && this.activeInventory.purchasedFromId) {
          supplierService.single(this.activeInventory.purchasedFromId).then(response => {
            let supplier = response.supplier
            this.selectedWholeSaler = supplier
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isLoading = false
          })
        } else {
          customerService.single(this.activeInventory.purchasedFromId).then(response => {
            let customer = response.customer
            this.selectedCustomer = customer
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isLoading = false
          })
        }
      }
      if (this.activeInventory !== null && (this.activeInventory.purchasedFromType === 'Wholesaler' && this.activeInventory.purchasedFromId)) {
        supplierService.single(this.activeInventory.purchasedFromId).then(response => {
          let purchasingSupplier = response.supplier
          let validContacts = purchasingSupplier.contacts.filter((x) => x.status === 'Active' && x.receivesSellersOrder === true && x.email !== '')

          // email
          this.availableAddresses = validContacts.map(function (y) {
            return {
              email: y.email,
              fullName: y.fullName ? y.fullName + '(' + y.email + ')' : y.fullName
            }
          })
          if (this.availableAddresses.length > 0) {
            this.selectedRecipients = this.availableAddresses
          } else {
            this.selectedRecipients = this.purchaseFromEmail ? [{email: this.purchaseFromEmail, fullName: this.purchaseFromEmail}] : []
          }

          this.checkEmails(this.selectedRecipients)

          // mobile
          this.availableMobileNumbers = validContacts.filter(x => x.mobile !== '' && x.mobile !== null && x.mobile.length === 16).map(function (y) {
            return {
              mobile: y.mobile,
              fullName: y.fullName ? y.fullName + '(' + y.mobile + ')' : y.fullName
            }
          })
          if (this.availableMobileNumbers.length > 0) {
            this.selectedRecipientsMobile = this.availableMobileNumbers
          } else {
            this.selectedRecipientsMobile = []
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
        })
      } else {
        if (this.activeInventory !== null && (this.activeInventory.purchasedFromContactEmail && this.activeInventory.purchasedFromContactEmail !== '')) {
          this.availableAddresses = [{
            email: this.activeInventory.purchasedFromContactEmail,
            fullName: this.activeInventory.purchasedFromContactName ? this.activeInventory.purchasedFromContactName + '(' + this.activeInventory.purchasedFromContactEmail + ')' : this.activeInventory.purchasedFromContactEmail
          }]
          this.selectedRecipients = this.availableAddresses
        } else {
          this.selectedRecipients = []
        }

        this.checkEmails(this.selectedRecipients)
        // mobile
        if (this.activeInventory !== null && (this.activeInventory.purchasedFromContactMobile && this.activeInventory.purchasedFromContactMobile !== '' && this.activeInventory.purchasedFromContactMobile !== null && this.activeInventory.purchasedFromContactMobile.length === 16)) {
          this.availableMobileNumbers = [{
            mobile: this.activeInventory.purchasedFromContactMobile,
            fullName: this.activeInventory.purchasedFromContactName ? this.activeInventory.purchasedFromContactName + '(' + this.activeInventory.purchasedFromContactMobile + ')' : this.activeInventory.purchasedFromContactMobile
          }]
          this.selectedRecipientsMobile = this.availableMobileNumbers
        } else {
          this.selectedRecipientsMobile = []
        }
      }

      if (this.activeInventory !== null) {
        this.overridingSubject = 'Title lost ' + this.activeInventory.stockNo + ' ' + this.activeInventory.name
        this.optionalMessage = 'Hello ' + this.activeInventory.purchasedFromName + ' your vehicle, ' + this.activeInventory.vehicleProductionYear + ' ' + this.activeInventory.vehicleMakeName + ' ' + this.activeInventory.purchasedFromName + ' ' + this.activeInventory.vehicleModelName + ' - ' + this.activeInventory.vinNo + ', has it\'s title status marked as lost. For your convenience, please find the lost title application form attached.'
      } else {
        this.overridingSubject = ''
        this.optionalMessage = ''
      }
    },
    onUndoSign () {
      this.$refs.signature.undo()
    },
    onCancelSignNow () {
      this.isSignatureActive = false
      this.loadingSign = false
    },
    closeSignaturePdf () {
      this.clearAllRecipients()
    },
    onDownloadSignedFile: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.downloadUrl)
    },
    fromDataURL (url) {
      this.$refs.signature.fromDataURL('data:image/png;base64,iVBORw0K...')
    },
    closeLostTitleModal () {
      this.$emit('close-lost-title')
      if (this.redirectOnClose) {
        this.updateAppState(this.activeInventory)
      }
    },
    onSign () {
      this.loadingSign = true
      this.isSignNow = true
      var png = this.$refs.signature.save()

      var generatePdfModal = {
        signatureString: png,
        inventoryId: this.activeInventory.id,
        requestedById: this.currentUser.id
      }

      inventoryService.uploadLostTitleSignature(generatePdfModal).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
          this.loadingSign = false
        } else {
          var file = {}
          file.name = 'Lost Title Application'
          let inventoryItem = response.inventory
          inventoryItem.attachments.forEach((x) => {
            x.loaded = true
            x.imageData = x.fileLocation
            x.fileType = this.getFileType(x.fileLocation)
            if (x.classType === 'LostTitleAffidavit') {
              this.lostTitleAffidavitFile.push(x)
            }
          })

          if (this.lostTitleAffidavitFile.length > 0 && this.lostTitleAffidavitFile[0].imageData) {
            this.downloadUrl = this.lostTitleAffidavitFile[0].imageData
            var fileUrl = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.downloadUrl)
            this.closeView = true
            this.onPreview(inventoryItem, fileUrl)
            this.isSignatureActive = false
            this.loadingSign = false
            this.activeInventory.attachments = inventoryItem.attachments
            eventBus.$emit('inventory-changed', [this.activeInventory])
          }
        }
      }).catch((error) => {
        this.loadingSign = false
        this.serverErrorMessage = error.message
      })
    },
    onClearSign () {
      this.$refs.signature.clear()
    },
    updateAppState: function (updatedInventory) {
      eventBus.$emit('inventory-changed', [updatedInventory])
      eventBus.$emit('lost-title-application-complete', updatedInventory)
      this.$emit('lost-title-application-complete', updatedInventory)
      let allSignedLostTitleApplications = updatedInventory.attachments.filter((x) => x.classType === 'LostTitleAffidavit')
      if (allSignedLostTitleApplications.length > 0) {
        let newAttachment = allSignedLostTitleApplications[allSignedLostTitleApplications.length - 1]
        Vue.set(this.activeInventory, 'attachments', [...this.activeInventory.attachments, newAttachment])
        this.activeInventory.lostTitleApplicationStatus = 'Complete'
        this.activeInventory.lostTitleApplicationStatusId = 10
      }
    },
    onDownloadLastLostTitleApplication: function () {
      window.location = process.env.VUE_APP_ROOT_API + 'document/download?url=' + encodeURI(this.lastLostTitleApplication)
    },
    showLostTitleModal () {
      this.initialize()
      this.$emit('show-lost-title')
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    },
    onPreview: function (inventory = null, documentUrl = '') {
      let docUrl = ''
      let documentFileName = ''
      if (documentUrl) {
        documentFileName = documentUrl.split('/').pop()
        docUrl = documentUrl
      } else if (inventory) {
        docUrl = process.env.VUE_APP_ROOT_DOC_SITE + 'docs/inventory/titlelost/' + inventory.id + '/' + this.isSignNow + '/' + this.currentUser.id
        documentFileName = 'inventory_title_lost_' + inventory.id + '.pdf'
      }
      let encodedDocumentUrl = encodeURI(docUrl)
      this.pdfModalFile = {
        name: 'Lost Title Application',
        src: encodedDocumentUrl,
        fileName: documentFileName,
        key: this.$uuid.v4()
      }
    },
    onPreviewed: function () {
    },
    onPreviewClosed: function () {
      this.pdfModalFile = null
      this.closePdf()
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    ...mapSubscriberGetters(['getCurrentSubscriber']),
    applicablePurchaseCost: function () {
      var defaultCost = this.activeInventory.tradeAllowance || this.activeInventory.purchaseCost
      return defaultCost
    },
    allowedSourceTypes: function () {
      let filtered = this.definedTypes.allowedSourceTypes.options.filter((x) => x.id !== 0)
      if (!this.selectedTypeStatus) {
        return filtered
      } else {
        if (this.selectedTypeStatus.id === 0) {
          return this.definedTypes.allowedSourceTypes.options
        } else {
          return filtered
        }
      }
    },
    sendTitleLostDocumentDisallowed: function () {
      return false
    },
    hasLostTitleApplicationAccess: function () {
      let allowedPermissions = ['inventory.lost-title-application']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    isValid: function () {
      if (this.activeInventory) {
        if ([3, 10, 11].includes(this.activeInventory.consignmentAgreementStatusId)) {
          return false
        }
      }
      return true
    },
    isValidForLostTitleApplication: function () {
      return this.currentInventory && this.currentInventory.purchasedFromName !== '' && this.currentInventory.titleStatus === 'Lost'
    },
    mustShowEmailError: function () {
      return this.emailMatchErrorMessage !== '' && this.emailMatchErrorMessage !== ' ' && this.emailMatchErrorMessage !== null && this.emailMatchErrorMessage !== undefined
    },
    activeDialog: function () {
      let result = ''
      if (this.pdfModalFile && this.pdfModalFile.src) result = 'PdfViewer'
      else if (this.isSignatureActive) result = 'SignatureViewer'
      else if (this.showSendTitleConfirmation) result = 'LostTitle'
      else result = ''
      logger.logWithGroup('Active Dialog', result)
      return result
    },
    lostTitleApplicationSendDisallowed: function () {
      return false
    },
    invalidForLostTitleApplicationReasons: function () {
      var result = 'Action disabled. \n'
      if (this.activeInventory) {
        if (this.activeInventory.purchasedFromType === 'Factory' || !this.currentInventory.titleStatus !== 'Lost') {
          result = 'Lost title application not applicable for this item.'
        } else {
          if (this.activeInventory.purchasedFromName === '') {
            result += 'Seller details not set.\n'
          }
        }
      } else {
        result += 'Inventory selected is invalid.\n'
      }
      return result
    },
    lastLostTitleApplication: function () {
      if (this.currentInventory && (this.currentInventory.lostTitleApplicationStatus === 'Complete' || this.currentInventory.lostTitleApplicationStatus === 'Signed')) {
        var allSignedLostTitleApplications = this.currentInventory.attachments.filter((x) => x.classType === 'LostTitleAffidavit')
        if (allSignedLostTitleApplications.length > 0) {
          return allSignedLostTitleApplications[allSignedLostTitleApplications.length - 1].fileLocation
        }
      }
      return ''
    }
  },
  beforeDestroy () {
  },
  mounted: function () {
    logger.logDanger('Inventory lost title action mounted')
  },
  watch: {
    'currentInventory.vinNo': function (newValue, oldValue) {
      if (newValue) {
        this.initialize()
      }
    },
    selectedSource: function (newValue, oldValue) {
      if (newValue) {
        this.sourceError = ''
      }
    },
    selectedCustomer: function (newValue, oldValue) {
      if (newValue) {
        this.customerError = ''
      }
    },
    selectedWholeSaler: function (newValue, oldValue) {
      if (newValue) {
        this.wholesalerError = ''
      }
    },
    showDialog: function (newValue, oldValue) {
      if (newValue) {
        this.initialize()
      }
      this.showSendTitleConfirmation = newValue
    }
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .signature-modal .modal-card-body {
    position:relative;
    min-height: 25vh; /* max 80% of the viewport height */
    max-height: 65vh; /* max 80% of the viewport height */
    max-width: 960px; /* max 80% of the viewport height */
    height: unset !important;
    overflow-y: auto;
  }

  @media screen and (min-width: 769px), print {
    .signature-modal .modal-card-body {
      position:relative;
      width: 99vw !important;
      max-height: 80vh !important; /* max 80% of the viewport height */
      max-width: 960px; /* max 80% of the viewport height */
      height: unset !important;
      overflow-y: auto;
    }
  }

  @media screen and (min-width: 769px), print {
    .signature-modal .modal-content, .modal-card {
        position:relative;
        width: 99% !important;
        overflow: auto;
        min-width: unset !important;
        max-width: unset !important;
        width: unset !important;
    }
  }

  .verification-container {
    margin-bottom: 3em;
  }

  .status-container {
    margin-bottom: 3em;
  }
</style>

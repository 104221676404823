export default {
  all: [
    {
      name: 'location 1',
      dealerId: 1,
      subscriber: 1,
      status: 'Active',
      id: 1,
      address1: '12',
      address2: 'Hlala Street',
      address3: 'Makhaza',
      city: 'Cape Town',
      country: 'South Africa',
      postalCode: '7784',
      state: 'South',
      gpsAddress: '1.223654,10.35645'
    },
    {
      name: 'location 2',
      dealerId: 1,
      subscriber: 1,
      status: 'Active',
      id: 2,
      address1: '12',
      address2: 'Hlala Street',
      address3: 'Makhaza',
      city: 'Cape Town',
      country: 'South Africa',
      postalCode: '7784',
      state: 'South',
      gpsAddress: '1.223654,10.35645'
    }
  ]
}

import axios from 'axios'
import baseService from './baseService'
import roServiceCustomerData from './data/roServiceCustomer'

const roServiceCustomerService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      axios.cancel('ro-service-customer-load-all')
      if (baseService.isTestMode) {
        resolve(roServiceCustomerData)
      } else {
        axios
          .post('/roservicecustomer/list', searchCriteria, {...baseService.defaultConfig, requestId: 'ro-service-customer-load-all'})
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('roservicecustomer all cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  single (roServiceCustomerId, requestId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(roServiceCustomerData.all.find((x) => x.id === roServiceCustomerId))
      } else {
        let options = requestId === undefined ? baseService.defaultConfig : {...baseService.defaultConfig, requestId: requestId}
        axios
          .get('/roservicecustomer/get?id=' + roServiceCustomerId, {}, options)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            if (axios.isCancel(error)) {
              console.log('roservicecustomer single cancelled')
            } else {
              reject(error.response.data)
            }
          })
      }
    })
  },
  save (roServiceCustomerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roServiceCustomer = {
          id: 999,
          firstName: 'Raj',
          lastName: 'Moodaley',
          emailAddress: 'raj@gliese-systems.com',
          mobile: '083 555 5555',
          office: '021 555 5555',
          home: '021 555 5555',
          address: '1 Main Road, Observatory, Cape Town',
          customerNo: '123456',
          status: 'Active'
        }
        roServiceCustomerData.all.push(roServiceCustomer)
        resolve(roServiceCustomer)
      } else {
        axios
          .post('/roservicecustomer/save', roServiceCustomerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (roServiceCustomerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var roServiceCustomer = roServiceCustomerData.all.find((x) => x.id === roServiceCustomerModel.id)
        if (roServiceCustomer) {
          roServiceCustomer.firstName = roServiceCustomerModel.firstName
          roServiceCustomer.lastName = roServiceCustomerModel.lastName
          roServiceCustomer.emailAddress = roServiceCustomerModel.emailAddress
          roServiceCustomer.mobile = roServiceCustomerModel.mobile
          roServiceCustomer.office = roServiceCustomerModel.office
          roServiceCustomer.home = roServiceCustomerModel.home
          roServiceCustomer.address = roServiceCustomerModel.address
          roServiceCustomer.customerNo = roServiceCustomerModel.customerNo
          roServiceCustomer.status = roServiceCustomerModel.status
        }
        resolve(roServiceCustomer)
      } else {
        axios
          .post('/roservicecustomer/update', roServiceCustomerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default roServiceCustomerService

<template>
  <div class="columns">
    <div class="column is-12">
      <b-field label="Products" v-show="showLabelHeader"></b-field>
      <b-table :data="this.value.itemProducts" class="table">
        <template slot-scope="props">
            <b-table-column field="name" label="Name" sortable :class="{'striked': props.row.delete === true}">
              {{ props.row.name }}
            </b-table-column>

            <b-table-column field="description" label="Description" sortable :class="{'striked': props.row.delete === true}" :visible="showDescription">
              {{ props.row.description }}
            </b-table-column>

            <b-table-column field="productCount" label="Product Count" :class="{'striked': props.row.delete === true}">
              {{ props.row.productCount }}
            </b-table-column>

            <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true}" :visible="showType">
              {{ props.row.type }}
            </b-table-column>

            <b-table-column field="sellingPrice" label="Selling Price" sortable :class="{'striked': props.row.delete === true}">
              {{ props.row.sellingPrice | currency }}
            </b-table-column>

            <b-table-column field="cost" label="GP" sortable :class="{'striked': props.row.delete === true}">
              {{ props.row.sellingPrice - props.row.cost | currency }}
            </b-table-column>

            <b-table-column field="" label="Actions">
              <span class="icon" @click="editProduct(props.row, props.index)" v-if="!props.row.delete">
                <i :class="editIcon"></i>
              </span>
              <span class="icon" @click="removeProduct(props.index)" v-if="!props.row.delete" v-show="!isReadOnly">
                <i class="fal fa-minus-circle"></i>
              </span>
              <span class="is-size-7 has-text-danger" v-if="props.row.delete === true">
                Pending Delete
              </span>
            </b-table-column>
        </template>
        <template slot="empty">
          <section class="section">
            <div class="has-text-centered">
              <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
              <p>No products have been added.</p>
            </div>
          </section>
        </template>
        <template slot="footer" v-if="availableProducts.length > 0">
          <th :colspan="colspan"></th>
          <th colspan="1"><label class="label">{{gpTotal | currency}}</label></th>
          <th colspan="1"></th>
        </template>
      </b-table>
      <portal to="global-modal-portal" v-if="newProduct.isAddingCost" :disabled="!usePortal">
        <product-cost-capture v-model="this.newProduct" :productCosts="this.value.itemProducts" :isReadOnly="isReadOnly"></product-cost-capture>
      </portal>
      <div class="inner-add-remove-button" v-if="!isReadOnly">
        <div class="quick-action is-pulled-left">
          <button class="button is-pulled-left" type="button" @click="addProduct()" title="Click to add a new product." tabindex="-1">
            <b-icon pack="fal" icon="plus" custom-size="fa-lg"></b-icon>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import ProductCostCapture from '@/components/generic/sales/ProductCostCapture'
import { createNamespacedHelpers } from 'vuex'

const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'ProductCostContainer',
  components: {
    'product-cost-capture': ProductCostCapture
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    showLabelHeader: {
      type: Boolean,
      default: true
    },
    showDescription: {
      type: Boolean,
      default: true
    },
    showType: {
      type: Boolean,
      default: true
    },
    usePortal: {
      type: Boolean,
      default: false
    },
    isReadOnly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      newProduct: this.createGenericProduct(),
      itemProducts: this.value.itemProducts
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapConfigGetters(['definedTypes']),
    gpTotal: function () {
      if (!this.value.itemProducts) {
        return 0
      }

      return this.value.itemProducts.reduce(function (prev, cur) {
        let gp = +cur.sellingPrice - +cur.cost
        return prev + (cur.delete === true ? +0 : gp)
      }, 0)
    },
    availableProducts: function () {
      if (!this.value.itemProducts) {
        return []
      }
      return this.value.itemProducts.filter(x => !x.delete)
    },
    colspan: function () {
      if (this.showType && this.showDescription) {
        return 5
      } else if (this.showType || this.showDescription) {
        return 4
      } else {
        return 3
      }
    },
    editIcon: function () {
      return this.isReadOnly ? 'fal fa-eye' : 'fal fa-pencil'
    }
  },
  methods: {
    populateProducts: function () {
      this.value.itemProducts = []
      if (this.value) {
        this.value.forEach(function (product) {
          this.value.itemProducts.push(product)
        }, this)
      }
    },
    addProduct: function () {
      this.newProduct = this.createGenericProduct()
      this.newProduct.isAddingCost = true
    },
    editProduct (product, index) {
      this.newProduct = this.createGenericProduct()
      this.newProduct = {
        index: index,
        id: product.id,
        name: product.name,
        description: product.description,
        cost: product.cost,
        sellingPrice: product.sellingPrice,
        dealItemId: product.dealItemId,
        isAddingCost: true,
        type: product.type,
        productCount: product.productCount,
        isEdit: true
      }
    },
    createGenericProduct () {
      return {
        name: null,
        description: '',
        cost: null,
        sellingPrice: null,
        isAddingCost: false,
        productCount: 0,
        isEdit: false
      }
    },
    removeProduct (index) {
      var item = this.value.itemProducts[index]
      if (item.id === undefined) {
        this.$delete(this.value.itemProducts, index)
      } else {
        item.delete = true
      }
      var warrantyProducts = this.value.itemProducts.filter((y) => y.type === 'Warranty' && !y.delete)
      this.value.warValue = (warrantyProducts.length > 0) ? warrantyProducts.map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next) : 0.00
    }
  },
  watch: {
    itemProducts: function (newValue, oldValue) {
      if (newValue.length > 0) {
        var warrantyProducts = newValue.filter((y) => y.type === 'Warranty' && !y.delete)
        this.value.warValue = (warrantyProducts.length > 0) ? warrantyProducts.map(x => x.sellingPrice - x.cost).reduce((prev, next) => prev + next) : 0.00
      }
    }
  },
  mounted: function () {
    // this.populateProducts()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

</style>

import requiresAuth from './route_guards'

export default [
  {
    path: '/service-request',
    name: 'ListServiceRequests',
    component: () => import('@/components/serviceRequest/ListServiceRequests'),
    beforeEnter: requiresAuth
  },
  {
    name: 'ServiceRequestsQuickView',
    path: '/service-request/qview/:id',
    meta: {
      quickViewComponent: {component: () => import('@/components/serviceRequest/ViewServiceRequest')}
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/service-request/new',
    name: 'NewServiceRequest',
    component: () => import('@/components/serviceRequest/NewServiceRequest'),
    beforeEnter: requiresAuth
  },
  {
    path: '/ro-service/new',
    name: 'NewRoService',
    component: () => import('@/components/roservice/NewRoService'),
    beforeEnter: requiresAuth
  },
  {
    path: '/service-request/view/:id',
    name: 'ViewServiceRequest',
    component: () => import('@/components/serviceRequest/ViewServiceRequest'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/service-request/edit/:id',
    name: 'EditServiceRequest',
    component: () => import('@/components/serviceRequest/EditServiceRequest'),
    beforeEnter: requiresAuth
  },
  {
    path: '/ro-service/edit/:id',
    name: 'EditRoService',
    component: () => import('@/components/roservice/EditRoService'),
    beforeEnter: requiresAuth
  },
  {
    path: '/service-request/pending-approval',
    name: 'ListPendingApproval',
    component: () => import('@/components/serviceRequest/pending-approval/ListPendingApproval'),
    beforeEnter: requiresAuth
  },
  {
    path: '/service-request/ro-services',
    name: 'ListRoServices',
    component: () => import('@/components/roservice/ListRoServices'),
    beforeEnter: requiresAuth
  },
  {
    path: '/service-request/ro-services/view/:id',
    name: 'ViewRoService',
    component: () => import('@/components/roservice/ViewRoService'),
    props: {
      actionPortal: 'footer-toolbar-left',
      headerPortal: ''
    },
    beforeEnter: requiresAuth
  },
  {
    name: 'RoServiceQuickView',
    path: '/service-request/ro-services/qview/:id',
    meta: {
      quickViewComponent: {
        component: () => import('@/components/roservice/ViewRoService'),
        qvHeaderClass: 'ro-qv-header',
        qvBodyClass: 'ro-qv-body'
      }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/service-request/op-codes',
    name: 'ListRoServiceOpCodes',
    component: () => import('@/components/roservice/ListRoServiceOpCodes'),
    beforeEnter: requiresAuth
  },
  {
    path: '/ro-services-op-code/new',
    name: 'NewRoServiceOpCode',
    component: () => import('@/components/roservice/NewRoServiceOpCode'),
    beforeEnter: requiresAuth
  },
  {
    name: 'RoServiceOpCodeQuickView',
    path: '/service-request/ro-services-op-code/qview/:id',
    meta: {
      quickViewComponent: {
        component: () => import('@/components/roservice/ViewRoServiceOpCode'),
        qvHeaderClass: 'ro-qv-header',
        qvBodyClass: 'ro-qv-body'
      }
    },
    beforeEnter: requiresAuth
  },
  {
    path: '/ro-services-op-code/edit/:id',
    name: 'EditRoServiceOpCode',
    component: () => import('@/components/roservice/EditRoServiceOpCode'),
    beforeEnter: requiresAuth
  }
]

<template>
    <div class="modal-card">
      <header class="modal-card-head is-block" v-if="isNewNoteCapture">
        <div class="title is-7 has-text-centered is-marginless">Add Note</div>
      </header>
      <section class="modal-card-body" v-show="isNewNoteCapture">
        <note-capture v-model="notes" :showInventoryNoteTypeSelector="true" :required="false" label="Details" :startWith="noteStartWith"></note-capture>
        <note-timeline-view v-model="currentInventory.notes" entity="inventory"></note-timeline-view>
      </section>
      <footer class="modal-card-foot" v-show="isNewNoteCapture">
        <div class="columns" style="width: 100%">
          <div class="column is-4">
            <a class="button is-link" @click="saveNote()">Save</a>
            <a class="button is-danger" @click="cancelNote()">Cancel</a>
          </div>
        </div>
      </footer>
      <header id="arrival-modal-header" class="modal-card-head is-block" v-if="!isNewNoteCapture">
        <div class="subtitle is-7 has-text-centered has-text-info is-marginless">{{overview}}</div>
        <el-steps :active="active" :space="300" finish-status="wait" simple>
          <el-step :title="mobile ? '' : 'Identity'" icon="fal fa-fingerprint fa-lg" @click.native="active = 0"></el-step>
          <el-step :title="mobile ? '' : 'Condition'" icon="fal fa-car-mechanic fa-lg" @click.native="active = 1"></el-step>
          <el-step :title="mobile ? '' : 'Location'" icon="fal fa-map-marker-alt fa-lg" @click.native="active = 2"></el-step>
        </el-steps>
      </header>
      <section class="modal-card-body" v-show="!isNewNoteCapture">
        <p v-if="serverErrors.length > 0" ref="generalErrorBlock">
          <span class="title is-7 is-paddingless">Please correct the following error(s):</span>
          <br/>
          <br/>
          <ul class="errors">
            <li v-for="error in serverErrors" :key="error.errorMessage">{{ error.errorMessage }}</li>
          </ul>
          <br/>
        </p>

        <p v-if="serverErrorMessage.length">
          <ul class="errors">
            <li>{{ serverErrorMessage }}</li>
          </ul>
          <br/>
        </p>
        <form class="view-container" @change="onFormChange">
          <div class="title is-7 has-text-centered" style="margin-top:1em;">NB: You are required to capture all information for the arriving vehicle</div>
          <div name="Identity" v-show="active === 0">
            <dealer-selector v-model="selectedDealer" v-show="false"></dealer-selector>
            <div class="box" style="margin:20px">
              <div class="columns is-multiline">
                <div class="column is-6">
                  <vin-no-selector scope="page-1" :vinRequired="true" :vehicleId="currentInventory.id" v-model="currentInventory.vinNo" v-on:vin-resolved="onVinDecodeComplete" :disabled="isPorter" v-on:error="onValidationError"></vin-no-selector>
                </div>
                <div class="column is-5">
                  <div class="field">
                    <label class="label">Stock No<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="field">
                      <div class="control">
                        <input data-vv-scope="page-1" name="stockNo" data-vv-as="stock no" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-1.stockNo') }" type="text" placeholder="#####" v-model="currentInventory.stockNo" :disabled="isPorter">
                      </div>
                    </div>
                    <span v-show="errors.has('page-1.stockNo')" class="help is-danger"><i v-show="errors.has('page-1.stockNo')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.stockNo') }}</span>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Mileage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                    <div class="control is-vertical-center">
                      <input name="usage" data-vv-scope="page-1" data-vv-as="miles" v-validate="'numeric|required'" :class="{'input': true, 'is-width-100': true, 'is-danger': errors.has('page-1.usage') }" type="number" placeholder="#####" v-model="currentInventory.usage" :min="0" :disabled="isPorter">
                      <usage-history-component class="ml-5" :vinNo="currentInventory.vinNo" :vinUsageHistory="currentInventory && currentInventory.vinUsageHistory"></usage-history-component>
                    </div>
                    <span v-show="errors.has('page-1.usage')" class="help is-danger"><i v-show="errors.has('page-1.usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.usage') }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-multiline" :class="{'arrival-capture-detail-margin': useModal}">
              <div class="column is-12">
                <make-model-selector
                  data-vv-scope="page-1"
                  data-vv-as="selected vehicle"
                  name="selectedMakeModel"
                  v-validate="'required'"
                  :forceColorSelection="shouldForceColorSelection"
                  v-model="selectedMakeModel"
                  :inventoryType="selectedTypeStatus"
                  :useModal="useModal"
                  :useValidator="this.$validator"
                  :disabled="isPorter"
                  :purchasedFromType="currentInventory.purchasedFromType"
                  :collapseMakeModelInfo="!showMakeModelInfo">
                </make-model-selector>
                <span v-show="errors.has('page-1.selectedMakeModel')" class="help is-danger"><i v-show="errors.has('page-1.selectedMakeModel')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.selectedMakeModel') }}</span>
              </div>
            </div>
          </div>
          <div name="Condition" class="box" v-show="active === 1" style="margin:20px">
            <div class="columns is-multiline condition-info">
              <div class="column is-6" v-if="showUsage">
                <div class="field">
                  <label class="label">Mileage<b-icon pack="fas" icon="star" class="fa-ss" v-if="usageRequired"></b-icon></label>
                  <div class="control is-vertical-center">
                    <input data-vv-scope="page-2" name="usage" data-vv-as="miles" v-validate="{'numeric': true, 'required': usageRequired}" :class="{'input': true, 'is-width-100': true, 'is-danger': errors.has('page-2.usage') }" type="number" placeholder="#####" v-model="currentInventory.usage" :min="minimumUsageAllowed">
                    <usage-history-component class="ml-5" :inventoryId="currentInventory.id" :vinNo="this.currentInventory.vinNo" :vinUsageHistory="currentInventory.vinUsageHistory" :usePortal="false"></usage-history-component>
                  </div>
                  <span v-show="errors.has('page-2.usage')" class="help is-danger"><i v-show="errors.has('page-2.usage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.usage') }}</span>
                </div>
              </div>
              <div class="column is-6">
                <button-list-selector scope="page-2" validateAs="condition type" label="Condition" :required="true" v-model="selectedConditionType" :availableValues="definedTypes.inventoryConditionType" v-if='!isPorter'></button-list-selector>
              </div>
              <div class="column is-4">
                <button-list-selector scope="page-2" validateAs="damage status" label="Damaged" :required="damageStatusRequired" v-model="selectedDamageStatus" :availableValues="definedTypes.damagedOptions"></button-list-selector>
              </div>
              <div class="column is-3">
                <image-selector
                  scope="page-2"
                  v-model="damageFiles"
                  label="Damage Visual Survey"
                  :required="false"
                  :isBoxed="false"
                  v-if="this.selectedDamageStatus && this.selectedDamageStatus.id === 1"
                  @uploadStarted="uploadStarted"
                  @uploadComplete="uploadComplete">
                </image-selector>
              </div>
              <div class="column is-5">
                <div class="field" v-if="this.selectedDamageStatus && this.selectedDamageStatus.id === 1">
                  <label class="label">Description of Damage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                  <div class="control">
                    <textarea data-vv-scope="page-2" rows="7" name="damageNote" data-vv-as="damage description" v-validate="'required'" :class="{'textarea': true, 'is-danger': errors.has('page-2.damageNote') }" placeholder="" v-model="damageNote"></textarea>
                    <span v-show="errors.has('page-2.damageNote')" class="help is-danger"><i v-show="errors.has('page-2.damageNote')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.damageNote') }}</span>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <button-list-selector scope="page-2" validateAs="key status" label="Keys" :required="keyStatusRequired" v-model="selectedKeyStatus" :availableValues="definedTypes.keyOptions"></button-list-selector>
              </div>
              <div class="column is-6">
                <button-list-selector scope="page-2" validateAs="books status" label="Books" :required="booksStatusRequired" v-model="selectedBooksStatus" :availableValues="definedTypes.bookOptions"></button-list-selector>
              </div>
              <div class="column is-6">
                <button-list-selector scope="page-2" validateAs="car cover status" label="Car Cover" :required="carCoverStatusRequired" v-model="selectedCarCoverStatus" :availableValues="allowedCarCoverOptions"></button-list-selector>
              </div>
              <div class="column is-6">
                <button-list-selector scope="page-2" validateAs="battery charger status" label="Battery Charger" :required="batteryChargerStatusRequired" v-model="selectedBatteryChargerStatus" :availableValues="allowedBatteryChargerOptions"></button-list-selector>
              </div>
              <div class="column is-6">
                <button-list-selector scope="page-2" validateAs="window sticker status" label="Window Sticker Status" :required="windowStickerStatusRequired" v-model="selectedWindowStickerStatus" :availableValues="windowStickerStatusList" :enabled="!windowsStickerUploaded"></button-list-selector>
              </div>
              <div class="column is-3">
                <image-selector
                  scope="page-2"
                  v-model="windowStickerFiles"
                  label="Window Sticker Image"
                  :required="false"
                  :isBoxed="false"
                  v-if="selectedWindowStickerStatus && selectedWindowStickerStatus.id === 0"
                  :disabled="windowsStickerUploaded"
                  @uploadStarted="uploadStarted"
                  @uploadComplete="uploadComplete">
                </image-selector>
              </div>
              <div class="column is-3">
                <pdf-selector
                  scope="page-2"
                  v-model="windowStickerPdfFiles"
                  label="Window Sticker Pdf"
                  :required="false"
                  :isBoxed="false"
                  v-if="selectedWindowStickerStatus && selectedWindowStickerStatus.id === 0"
                  :enabled="!windowsStickerUploaded"
                  @uploadStarted="uploadStarted"
                  @uploadComplete="uploadComplete">
                </pdf-selector>
              </div>
              <div class="column is-4" v-if="showDateOfLastService">
                <b-field label="Date of Last Service">
                  <el-date-picker
                    data-vv-scope="page-2"
                    @change="onFormChange()"
                    v-model="lastServiceDate"
                    type="date"
                    placeholder="Click to select...">
                  </el-date-picker>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="Warranty Start Date">
                  <el-date-picker
                    data-vv-scope="page-2"
                    @change="onFormChange()"
                    v-model="warrantyStartDate"
                    type="date"
                    placeholder="Click to select...">
                  </el-date-picker>
                </b-field>
              </div>
              <div class="column is-4">
                <b-field label="Warranty Expiration Date">
                  <el-date-picker
                    data-vv-scope="page-2"
                    @change="onFormChange()"
                    v-model="warrantyExpirationDate"
                    type="date"
                    placeholder="Click to select...">
                  </el-date-picker>
                </b-field>
              </div>
            </div>
          </div>
          <div name="Location" class="box" v-show="active === 2" style="margin:20px">
            <div class="columns">
              <div class="column is-12">
                <location-selector scope="page-3" v-model="selectedLocation" label="Vehicle Location" validateAs="vehicle location" :required="false"></location-selector>
              </div>
            </div>
          </div>
          <br />
        </form>
      </section>
      <footer class="modal-card-foot" v-show="!isNewNoteCapture">
        <div class="columns is-multiline" :style="mobile ? '' : 'width:100%'">
          <div class="column is-6 is-pulled-left">
            <button class="button is-link" @click="previous" v-if="active > 0"><i title="Previous" class="fal fa-arrow-alt-left fa-lg" v-if="mobile"></i><span v-else>Previous</span></button>
            <button title="Cancel" class="button is-danger" @click="onCancel"><i class="fal fa-times-circle fa-lg" v-if="mobile"></i><span v-else>Cancel</span></button>
            <button class="button is-link" @click="onAddNote()"><i title="Notes" class="fal fa-comment fa-lg" v-if="mobile"></i><span v-else>Notes</span></button>
          </div>
          <div class="column is-6 is-pulled-right">
            <button v-if="skipAllowed" class="button is-warning is-pulled-right" @click="onSkipArrival()"><i title="Skip" class="fal fa-forward fa-lg" v-if="mobile"></i><span v-else>Skip</span></button>
            <button :disabled="isUploadingFile" class="button is-primary is-pulled-right" @click="validateAllTabs()"><i title="Save" class="fal fa-save fa-lg" v-if="mobile"></i><span v-else>Save</span></button>
            <div class="book-deal-next-btn  is-pulled-right">
              <button class="button is-link" @click="next" v-if="active < 2"><i title="Next" class="fal fa-arrow-alt-right fa-lg" v-if="mobile"></i><span v-else>Next</span></button>
            </div>
          </div>
        </div>
      </footer>
    </div>
</template>

<script>
import eventBus from '@/eventBus'
import moment from 'moment'
import inventoryService from '@/services/inventoryService'
import dealService from '@/services/dealService'
import customerService from '@/services/customerService'
import supplierService from '@/services/supplierService'
import DealerSelector from '@/components/generic/DealerSelector'
import VinNoSelector from '@/components/generic/VinNoSelector'
import MakeModelSelector from '@/components/generic/MakeModelSelector'
import UsageHistoryComponent from '@/components/inventory/UsageHistoryComponent'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import ImageSelector from '@/components/generic/ImageSelector'
import PdfSelector from '@/components/generic/PdfSelector'
import LocationSelector from '@/components/generic/LocationSelector'
import NoteCapture from '@/components/generic/NoteCapture'
import NoteTimelineView from '@/components/generic/NoteTimelineView'
import { Steps, Step } from 'element-ui'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapVehicleMakeGetters = createNamespacedHelpers('vehicleMake').mapGetters
const { mapState, mapGetters } = createNamespacedHelpers('user')

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'ArrivalCaptureDetail',
  mixins: [validateBeforeSubmitMixin],
  components: {
    'dealer-selector': DealerSelector,
    'vin-no-selector': VinNoSelector,
    'make-model-selector': MakeModelSelector,
    'usage-history-component': UsageHistoryComponent,
    'button-list-selector': ButtonListSelector,
    'image-selector': ImageSelector,
    'pdf-selector': PdfSelector,
    'location-selector': LocationSelector,
    'note-capture': NoteCapture,
    'note-timeline-view': NoteTimelineView,
    'el-steps': Steps,
    'el-step': Step
  },
  props: {
    inventoryItem: {
      type: Object,
      default: null
    },
    useModal: {
      type: Boolean,
      default: true
    },
    allowSkip: {
      type: Boolean,
      default: false
    },
    setPending: {
      type: Boolean,
      default: false
    },
    isBookingWizard: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      redirectUrl: '',
      redirectMessage: '',
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      selectedDealer: null,
      selectedPossessionStatus: null,
      selectedMakeModel: null,
      selectedSource: null,
      selectedTypeStatus: null,
      selectedCpoStatus: null,
      selectedCustomer: null,
      selectedWholeSaler: null,
      selectedModel: null,
      inventorySoldItem: null,
      lastDealOverview: null,
      selectedDamageStatus: null,
      selectedKeyStatus: null,
      selectedBooksStatus: null,
      selectedCarCoverStatus: null,
      selectedBatteryChargerStatus: null,
      selectedTitleStatus: null,
      selectedWindowStickerStatus: null,
      lastServiceDate: null,
      warrantyStartDate: null,
      warrantyExpirationDate: null,
      selectedLocation: null,
      isUsageVerificationActive: false,
      lastOwnerDetails: null,
      vinNo: '',
      active: 0,
      damageNote: '',
      damageFiles: [],
      titleFiles: [],
      windowStickerFiles: [],
      inventoryPhotoFiles: [],
      windowStickerPdfFiles: [],
      notes: [],
      isNewNoteCapture: false,
      currentInventory: {
      },
      arrivalProcessSkipped: false,
      mobile: window.innerWidth <= 700,
      showMakeModelInfo: true,
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapVehicleMakeGetters(['allVehicleMakes']),
    ...mapState(['currentUser']),
    ...mapGetters(['allowedRoles']),
    skipAllowed: function () {
      return this.allowSkip
    },
    isPorter: function () {
      return this.allowedRoles.indexOf('Porter Role') >= 0
    },
    // windowsStickerAlreadyUploaded: function () {
    //   var hasStickerItems = (this.windowStickerFiles && this.windowStickerFiles.length > 0) || (this.windowsStickerPdfFiles && this.windowsStickerPdfFiles.length > 0)
    //   var windowStickerSelected = this.selectedWindowStickerStatus && this.selectedWindowStickerStatus.id === 0
    //   return windowsStickerUploaded
    // },
    overview: function () {
      if (!this.currentInventory) {
        return ''
      } else {
        var result = ''
        if (this.currentInventory.vinNo !== '') {
          result += 'VIN: ' + this.currentInventory.vinNo
        } else {
          result += 'VIN: Pending'
        }
        if (this.currentInventory.stockNo !== '') {
          result += ', STK: ' + this.currentInventory.stockNo
        } else {
          result += ', STK - Pending'
        }
        return result
      }
    },
    vinRequired: function () {
      return true
    },
    shouldForceColorSelection: function () {
      return true
    },
    minimumUsageAllowed: function () {
      if (this.currentInventory && this.currentInventory.vinUsageHistory && this.currentInventory.vinUsageHistory.length > 0) {
        return this.currentInventory.vinUsageHistory[this.currentInventory.vinUsageHistory.length - 1].usage
      }
      if (this.selectedMakeModel && this.selectedMakeModel.vinUsageHistory && this.selectedMakeModel.vinUsageHistory.length > 0) {
        return this.selectedMakeModel.vinUsageHistory[this.selectedMakeModel.vinUsageHistory.length - 1].usage
      }
      return this.lastVinUsage || 0
    },
    showUsage: function () {
      if (!this.selectedTypeStatus) {
        return false
      } else {
        return [0, 1, 3, 5, 6].indexOf(this.selectedTypeStatus.id) >= 0
      }
    },
    showDateOfLastService: function () {
      if (!this.selectedTypeStatus) {
        return false
      } else {
        return [0].indexOf(this.selectedTypeStatus.id) < 0
      }
    },
    showInventorySpecifications: function () {
      if (!this.selectedTypeStatus) {
        return false
      } else {
        return [0, 1, 3, 4, 5, 6].indexOf(this.selectedTypeStatus.id) >= 0
      }
    },
    showVehicleLocation: function () {
      if (!this.selectedTypeStatus) {
        return false
      } else {
        return [0, 1, 3, 5, 6].indexOf(this.selectedTypeStatus.id) >= 0
      }
    },
    usageRequired: function () {
      return true
    },
    damageStatusRequired: function () {
      return true
    },
    keyStatusRequired: function () {
      return true
    },
    booksStatusRequired: function () {
      return true
    },
    windowStickerStatusRequired: function () {
      return false
    },
    carCoverStatusRequired: function () {
      if (this.selectedMakeModel && this.selectedMakeModel.selectedMake) {
        return this.selectedMakeModel.selectedMake.includesCarCover === true
      }
      return true
    },
    batteryChargerStatusRequired: function () {
      if (this.selectedMakeModel && this.selectedMakeModel.selectedMake) {
        return this.selectedMakeModel.selectedMake.includesBatteryCharger === true
      }
      return true
    },
    allowedCarCoverOptions: function () {
      if (this.selectedMakeModel && this.selectedMakeModel.selectedMake) {
        if (this.selectedMakeModel.selectedMake.includesCarCover === true) {
          return this.definedTypes.carCoverOptions.filter((x) => x.id !== 1)
        }
      }
      return this.definedTypes.carCoverOptions
    },
    allowedBatteryChargerOptions: function () {
      if (this.selectedMakeModel && this.selectedMakeModel.selectedMake) {
        if (this.selectedMakeModel.selectedMake.includesBatteryCharger === true) {
          return this.definedTypes.batteryChargerOptions.filter((x) => x.id !== 1)
        }
      }
      return this.definedTypes.batteryChargerOptions
    },
    noteStartWith: function () {
      return (this.notes.length > 0) ? this.notes[this.notes.length - 1].note : ''
    },
    existsWindowSticker: function () {
      return this.windowStickerFiles && this.windowStickerFiles.length > 0
    },
    existsWindowPdf: function () {
      return this.windowStickerPdfFiles && this.windowStickerPdfFiles.length > 0
    },
    windowStickerStatus: function () {
      return this.selectedWindowStickerStatus && this.selectedWindowStickerStatus.id === 0
    },
    windowsStickerUploaded: function () {
      return this.isPorter && (this.existsWindowSticker || this.existsWindowPdf) && this.windowStickerStatus
    },
    windowStickerStatusList: function () {
      return this.definedTypes.windowStickerStatusOptions
    }
  },
  methods: {
    next: function () {
      var scope = 'page-' + (this.active + 1) + '.*'
      switch (this.active) {
        case 0: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.active++
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
        case 1: {
          this.$validator.validate(scope).then((result) => {
            if (result) {
              this.active++
            } else {
              this.$goToFirstValidationError(this.$validator.errors.items)
            }
          })
          break
        }
      }
    },
    previous: function () {
      if (this.active-- < 1) this.active = 0
    },
    onSave: function () {
      let setAttribute = function (model, name, value) {
        var existingAttribute = model.attributes.find((x) => x.name === name)

        if ((value === '') && existingAttribute) {
          existingAttribute.status = 'Deleted'
          return
        }

        if (existingAttribute) {
          existingAttribute.value = value || ''
        } else {
          model.attributes.push({name: name, value: value})
        }
      }

      let isVehicleDamaged = this.selectedDamageStatus ? this.selectedDamageStatus.id : null

      var inventoryModel = {
        ...this.currentInventory,
        dealerId: this.selectedDealer.id,
        type: 'Active',
        possessionStatus: this.selectedPossessionStatus ? this.selectedPossessionStatus.name : null,
        cpoStatus: this.selectedCpoStatus ? this.selectedCpoStatus.name : null,
        vehicleMakeId: this.selectedMakeModel.makeId < 1 ? null : this.selectedMakeModel.makeId,
        vehicleMakeVersionId: this.selectedMakeModel.modelId < 1 ? null : this.selectedMakeModel.modelId,
        customVehicleMakeVersion: this.selectedMakeModel.customMakeModel,
        isDamaged: isVehicleDamaged,
        carCoverStatus: this.selectedCarCoverStatus ? this.selectedCarCoverStatus.name : null,
        batteryChargerStatus: this.selectedBatteryChargerStatus ? this.selectedBatteryChargerStatus.name : null,
        titleStatus: this.selectedTitleStatus ? this.selectedTitleStatus.name : null,
        keyStatus: this.selectedKeyStatus ? this.selectedKeyStatus.altName : null,
        windowStickerStatus: this.selectedWindowStickerStatus ? this.selectedWindowStickerStatus.name : null,
        booksStatus: this.selectedBooksStatus ? this.selectedBooksStatus.name : null,
        lastServiceDate: this.lastServiceDate,
        warrantyExpirationDate: this.warrantyExpirationDate,
        warrantyStartDate: this.warrantyStartDate,
        locationId: this.selectedLocation ? this.selectedLocation.id : null,
        conditionType: this.selectedConditionType ? this.selectedConditionType.name : null,
        transmissionType: this.selectedMakeModel.transmissionType
      }

      setAttribute(inventoryModel, 'ExteriorColor', this.selectedMakeModel.exteriorColor)
      setAttribute(inventoryModel, 'InteriorColor', this.selectedMakeModel.interiorColor)
      setAttribute(inventoryModel, 'DamageNotes', this.damageNote)
      setAttribute(inventoryModel, 'Style', this.selectedMakeModel.style)
      setAttribute(inventoryModel, 'Trim', this.selectedMakeModel.trim)
      setAttribute(inventoryModel, 'ProductionYear', this.selectedMakeModel.productionYear)
      setAttribute(inventoryModel, 'Make', this.selectedMakeModel.customMakeName)
      setAttribute(inventoryModel, 'Model', this.selectedMakeModel.customModelName)

      setAttribute(inventoryModel, 'SearchType', (this.selectedMakeModel.locateType && this.selectedMakeModel.locateType.name) ? this.selectedMakeModel.locateType.name : '')
      setAttribute(inventoryModel, 'AlternateExteriorColors', (this.selectedMakeModel.altExteriorColors && this.selectedMakeModel.altExteriorColors.length > 0) ? this.selectedMakeModel.altExteriorColors.join(',') : '')
      setAttribute(inventoryModel, 'AlternateInteriorColors', (this.selectedMakeModel.altInteriorColors && this.selectedMakeModel.altInteriorColors.length > 0) ? this.selectedMakeModel.altInteriorColors.join(',') : '')
      setAttribute(inventoryModel, 'SearchTags', (this.selectedMakeModel.tags && this.selectedMakeModel.tags.length > 0) ? this.selectedMakeModel.tags.join(',') : '')
      setAttribute(inventoryModel, 'SearchNotes', (this.selectedMakeModel.notes && this.selectedMakeModel.notes.length > 0) ? this.selectedMakeModel.notes : '')

      var note = this.notes.length > 0 ? this.notes[this.notes.length - 1] : null
      if (note !== null) {
        inventoryModel.notes.push(note)
      }

      if (this.damageFiles.length > 0) {
        var damageFiles = this.damageFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'Damage'
          }
        })

        inventoryModel.attachments.push(...damageFiles)
      }

      if (this.inventoryPhotoFiles.length > 0) {
        var photoFiles = this.inventoryPhotoFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'Primary'
          }
        })

        inventoryModel.attachments.push(...photoFiles)
      }

      if (this.titleFiles.length > 0) {
        var titleFiles = this.titleFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'Title'
          }
        })

        inventoryModel.attachments.push(...titleFiles)
      }

      if (this.windowStickerFiles.length > 0) {
        var stickerFiles = this.windowStickerFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'WindowSticker'
          }
        })

        inventoryModel.attachments.push(...stickerFiles)
      }

      if (this.windowStickerPdfFiles.length > 0) {
        var stickerPdfFiles = this.windowStickerPdfFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'WindowSticker'
          }
        })

        inventoryModel.attachments.push(...stickerPdfFiles)
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      inventoryService.update(inventoryModel).then(response => {
        this.isSaving = false

        if (response.errors) {
          this.onValidationError(response.errors)
        } else {
          inventoryService.markArrived(inventoryModel).then(response => {
            this.isSaving = false

            if (response.errors) {
              this.onValidationError(response.errors)
            } else {
              this.onComplete()
            }
          }).catch((error) => {
            this.onError(error)
          })
        }
      }).catch((error) => {
        this.onError(error)
      })
      this.onComplete()
    },
    onCancel: function () {
      this.inventoryItem.actionCancelled = true
      this.$emit('arrival-capture-complete', {})
    },
    onComplete: function () {
      eventBus.$emit('reload-pending-arrival-list')
      if (this.setPending) {
        eventBus.$emit('inventory-arrived-update-deal-status-to-pending', {})
      }
      this.$emit('arrival-capture-complete', this.inventoryItem)
    },
    onVinDecodeComplete: function (selectedMakeModel) {
      if ((this.selectedMakeModel !== selectedMakeModel) && this.isBookingWizard) {
        this.showMakeModelInfo = true
      }
      this.selectedMakeModel = selectedMakeModel
      eventBus.$emit('preview-make-model')

      if (this.selectedMakeModel && this.selectedMakeModel.lastInventory) {
        if (this.selectedMakeModel.lastInventory.sold === true) {
          dealService.soldByInventory(this.selectedMakeModel.lastInventory.id).then(response => {
            this.lastOwnerDetails = {
              type: response.deal.saleType === 'Retail' ? 'Individual' : 'Wholesaler',
              id: response.deal.previousPurchaser.id,
              name: response.deal.previousPurchaser.name,
              previousAssignee: response.deal.previousPurchaser
            }
          }).catch((error) => {
            this.serverErrorMessage = error.message
          })
        } else {
          if (this.selectedMakeModel.lastInventory.purchasedFromType === 'Individual') {
            customerService.single(this.selectedMakeModel.lastInventory.purchasedFromId).then(response => {
              this.lastOwnerDetails = {
                type: 'Individual',
                id: response.customer.id,
                name: response.customer.fullName,
                previousAssignee: response.customer
              }
            }).catch((error) => {
              this.serverErrorMessage = error.message
            })
            return
          }

          if (this.selectedMakeModel.lastInventory.purchasedFromType === 'Wholesaler' && this.selectedMakeModel.lastInventory.purchasedFromId) {
            supplierService.single(this.selectedMakeModel.lastInventory.purchasedFromId).then(response => {
              this.lastOwnerDetails = {
                type: 'Wholesaler',
                id: response.supplier.id,
                name: response.supplier.name,
                previousAssignee: response.supplier
              }
            }).catch((error) => {
              this.serverErrorMessage = error.message
            })
          }
        }
      }
    },
    onValidationError: function (errors) {
      this.serverErrors = errors
      this.$nextTick(() => {
        this.$goToErrorBlock(this.$refs.generalErrorBlock)
      })
    },
    onError: function (error) {
      this.serverErrorMessage = error.message
      this.isLoading = false
      this.isSaving = false
      this.$nextTick(() => {
        this.$goToErrorBlock(this.$refs.generalErrorBlock)
      })
    },
    getNextStockNo: function () {
      if (this.currentInventory.stockNo !== 'PENDING') {
        return
      }

      if (!this.selectedDealer || !this.selectedMakeModel || !this.selectedTypeStatus) {
        return
      }

      var stockGenerationModel = {
        dealerId: this.selectedDealer.id,
        vinNo: this.currentInventory.vinNo,
        vehicleMakeId: this.selectedMakeModel.makeId,
        type: this.selectedTypeStatus.altName,
        soldStockNumber: this.inventorySoldItem === null ? '' : this.inventorySoldItem.stockNo
      }

      this.currentInventory.stockNo = 'Generating...'
      this.serverErrorMessage = ''
      this.serverErrors = []

      inventoryService.nextStockNo(stockGenerationModel).then(response => {
        this.isSaving = false

        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.currentInventory.stockNo = response
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.currentInventory.stockNo = ''
      })
    },
    reset: function () {
      this.selectedDealer = null
      this.selectedPossessionStatus = null
      this.selectedMakeModel = null
      this.selectedSource = null
      this.selectedTypeStatus = null
      this.selectedCpoStatus = null
      this.selectedCustomer = null
      this.selectedWholeSaler = null
      this.selectedModel = null
      this.selectedDamageStatus = null
      this.selectedKeyStatus = null
      this.selectedBooksStatus = null
      this.selectedCarCoverStatus = null
      this.selectedBatteryChargerStatus = null
      this.selectedTitleStatus = null
      this.selectedConditionType = null
      this.selectedWindowStickerStatus = null
      this.lastServiceDate = null
      this.warrantyStartDate = null
      this.warrantyExpirationDate = null
      this.selectedLocation = null
      this.isUsageVerificationActive = false
      this.active = 0
      this.damageNote = ''
      this.damageFiles = []
      this.titleFiles = []
      this.windowStickerFiles = []
      this.inventoryPhotoFiles = []
      this.windowStickerPdfFiles = []
      this.notes = []
      this.showMakeModelInfo = true
    },
    loadInventory: function (id) {
      this.reset()
      this.currentInventoryId = id
      this.isLoading = true
      this.serverErrorMessage = ''

      inventoryService.single(this.currentInventoryId).then(response => {
        this.currentInventory = {
          ...response.inventory
        }

        this.selectedTypeStatus = this.definedTypes.inventoryTypes.find((x) => (x.name === this.currentInventory.type || x.altName === this.currentInventory.type))
        this.selectedDealer = {id: this.currentInventory.dealerId, name: this.currentInventory.dealerName}
        this.selectedPossessionStatus = this.definedTypes.possessionStatuses.find((x) => (x.name === this.currentInventory.possessionStatus || x.altName === this.currentInventory.possessionStatus))
        this.selectedDamageStatus = this.currentInventory.isDamaged === null ? null : this.definedTypes.damagedOptions.find((x) => x.id === (this.currentInventory.isDamaged === true ? 1 : 0))
        this.selectedKeyStatus = this.definedTypes.keyOptions.find((x) => (x.name === this.currentInventory.keyStatus || x.altName === this.currentInventory.keyStatus))
        this.selectedBooksStatus = this.definedTypes.bookOptions.find((x) => (x.name === this.currentInventory.booksStatus || x.altName === this.currentInventory.booksStatus))
        this.selectedCarCoverStatus = this.definedTypes.carCoverOptions.find((x) => (x.name === this.currentInventory.carCoverStatus || x.altName === this.currentInventory.carCoverStatus))
        this.selectedBatteryChargerStatus = this.definedTypes.batteryChargerOptions.find((x) => (x.name === this.currentInventory.batteryChargerStatus || x.altName === this.currentInventory.batteryChargerStatus))
        this.selectedTitleStatus = this.definedTypes.titleStatusOptions.options.find((x) => (x.name === this.currentInventory.titleStatus || x.altName === this.currentInventory.titleStatus))
        this.selectedWindowStickerStatus = this.definedTypes.windowStickerStatusOptions.find((x) => (x.name === this.currentInventory.windowStickerStatus || x.altName === this.currentInventory.windowStickerStatus))
        this.lastServiceDate = this.currentInventory.lastServiceDate ? this.currentInventory.lastServiceDate : null
        this.warrantyStartDate = this.currentInventory.warrantyStartDate ? this.currentInventory.warrantyStartDate : null
        this.warrantyExpirationDate = this.currentInventory.warrantyExpirationDate ? this.currentInventory.warrantyExpirationDate : null
        this.selectedLocation = (this.currentInventory.locationId === null || this.currentInventory.locationId === 0) ? null : {id: this.currentInventory.locationId, name: this.currentInventory.locationName}
        this.selectedSource = this.currentInventory.purchasedFromType === null ? null : this.definedTypes.allowedSourceTypes.options.find((x) => (x.name === this.currentInventory.purchasedFromType || x.altName === this.currentInventory.purchasedFromType))
        this.selectedConditionType = this.definedTypes.inventoryConditionType.find((x) => (x.name === this.currentInventory.conditionType || x.altName === this.currentInventory.conditionType))

        var damageNoteAttribute = this.currentInventory.attributes.find((x) => x.name === 'DamageNotes')
        this.damageNote = damageNoteAttribute ? damageNoteAttribute.value : ''
        var exteriorColor = this.currentInventory.attributes.find((x) => x.name === 'ExteriorColor')
        var interiorColor = this.currentInventory.attributes.find((x) => x.name === 'InteriorColor')
        var vehicleTrim = this.currentInventory.attributes.find((x) => x.name === 'Trim')
        var vehicleStyle = this.currentInventory.attributes.find((x) => x.name === 'Style')
        var searchType = this.currentInventory.attributes.find((x) => x.name === 'SearchType')
        var altExteriorColors = this.currentInventory.attributes.find((x) => x.name === 'AlternateExteriorColors')
        var altInteriorColors = this.currentInventory.attributes.find((x) => x.name === 'AlternateInteriorColors')
        var searchTags = this.currentInventory.attributes.find((x) => x.name === 'SearchTags')
        var searchNotes = this.currentInventory.attributes.find((x) => x.name === 'SearchNotes')

        var vehicleAge = moment().year() - (+this.currentInventory.vehicleProductionYear)
        var cpoAllowed = true
        var autoCpoStatus = 'Ineligible'

        if (vehicleAge >= 0 && vehicleAge <= this.selectedDealer.autoCpoAgeLimit) {
          cpoAllowed = true
          autoCpoStatus = 'Cpo'
        } else if (vehicleAge > this.selectedDealer.autoCpoAgeLimit && vehicleAge <= this.selectedDealer.cpoQualifyingAge) {
          cpoAllowed = true
          autoCpoStatus = 'Eligible'
        }

        let selectedMake = this.currentInventory.vehicleMakeId > 0 ? this.allVehicleMakes.find((x) => x.id === this.currentInventory.vehicleMakeId) : null
        let selectedModel = selectedMake && selectedMake.versions ? (selectedMake.versions.find((x) => x.vehicleMakeId === this.currentInventory.vehicleMakeId)) : null

        this.selectedMakeModel = {
          exteriorColor: exteriorColor ? exteriorColor.value : '',
          interiorColor: interiorColor ? interiorColor.value : '',
          trim: vehicleTrim ? vehicleTrim.value : '',
          style: vehicleStyle ? vehicleStyle.value : '',
          makeId: this.currentInventory.vehicleMakeId,
          selectedMake: selectedMake,
          modelId: this.currentInventory.vehicleMakeVersionId,
          selectedModel: selectedModel,
          productionYear: this.currentInventory.vehicleProductionYear,
          customMakeName: this.currentInventory.customMake === '' ? null : this.currentInventory.customMake,
          customModelName: this.currentInventory.customModel === '' ? null : this.currentInventory.customModel,
          customMakeModel: this.currentInventory.customVehicleMakeVersion,
          vehicleAge: vehicleAge,
          cpoAllowed: cpoAllowed,
          autoCpoStatus: autoCpoStatus,
          locateType: (searchType && searchType.value && searchType.value !== '') ? this.definedTypes.allowedLocateTypes.options.find((x) => x.name === searchType.value) : null,
          altExteriorColors: (altExteriorColors && altExteriorColors.value && altExteriorColors.value.length > 0) ? altExteriorColors.value.split(',') : [],
          altInteriorColors: (altInteriorColors && altInteriorColors.value && altInteriorColors.value.length > 0) ? altInteriorColors.value.split(',') : [],
          tags: (searchTags && searchTags.value && searchTags.value.length > 0) ? searchTags.value.split(',') : [],
          notes: searchNotes ? searchNotes.value : '',
          transmissionType: this.currentInventory.transmissionType
        }

        this.currentInventory.attachments.forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation

          if (x.type === 'Image') {
            if (x.classType === 'Primary') {
              this.inventoryPhotoFiles.push(x)
            } else if (x.classType === 'Damage') {
              this.damageFiles.push(x)
            } else if (x.classType === 'Title') {
              this.titleFiles.push(x)
            } else if (x.classType === 'WindowSticker' && x.status === 'Active') {
              this.windowStickerFiles.push(x)
            }
          } else if (x.type === 'Pdf') {
            if (x.classType === 'WindowSticker' && x.status === 'Active') {
              this.windowStickerPdfFiles.push(x)
            }
          }
        })

        this.isLoading = false
      }).catch((error) => {
        this.onError(error)
      })
    },
    onAddNote: function () {
      this.isNewNoteCapture = true
    },
    saveNote: function () {
      this.isNewNoteCapture = false
    },
    cancelNote: function () {
      this.isNewNoteCapture = false
    },
    onSkipArrival () {
      this.arrivalProcessSkipped = true
      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      const arrivalSkipModel = {
        id: this.currentInventory.id,
        arrivalSkippedById: this.currentUser.id
      }

      inventoryService.markArrivalSkipped(arrivalSkipModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.onValidationError(response.errors)
        } else {
          this.arrivalProcessSkipped = true
          this.$emit('arrival-capture-skipped', true)
        }
      }).catch((error) => {
        this.onError(error)
      })
    },
    validateAllTabs () {
      this.$validator.validate().then(isValid => {
        if (!isValid) {
          // for some reason the multiselects components returns two error items for one input, first doesnt have scope but the second one has. Inputs on the form return one error item
          let errorScope = this.$validator.errors.items[0].scope ? this.$validator.errors.items[0].scope : this.$validator.errors.items[1].scope
          let pageIndex = errorScope.substr(-1) - 1
          this.active = pageIndex
          this.$nextTick(() => {
            this.$goToFirstValidationError(this.$validator.errors.items)
          })
        } else {
          this.onSave()
        }
      })
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  watch: {
    lastOwnerDetails: function (newValue, oldValue) {
      if (newValue !== null) {
        if (newValue.type === 'Individual') {
          this.selectedSource = this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Individual)
          this.selectedCustomer = newValue.previousAssignee
        } else {
          this.selectedSource = this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Wholesaler)
          this.selectedWholeSaler = newValue.previousAssignee
        }
        this.isLastCustomerNotificationActive = true
      }
    },
    vinNo: function (newValue, oldValue) {
      this.getNextStockNo()
    },
    selectedPossessionStatus: function (newValue, oldValue) {
      if (newValue && oldValue && newValue.id === oldValue.id) {
        return
      }

      if (newValue && this.selectedTypeStatus && (newValue.name === 'Inbound' && this.selectedTypeStatus.altName === 'New') && this.selectedMakeModel === null) {
        eventBus.$emit('preview-make-model')
      }
    },
    lastDealOverview: function (newValue, oldValue) {
      if (newValue !== null) {
        if (newValue.saleType === 'Retail') {
          this.selectedSource = this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Individual)
          this.selectedCustomer = newValue.previousPurchaser
        } else {
          this.selectedSource = this.definedTypes.allowedSourceTypes.options.find((x) => x.id === this.definedTypes.allowedSourceTypes.Wholesaler)
          this.selectedWholeSaler = newValue.previousPurchaser
        }
      }
    },
    'selectedMakeModel': {
      handler: function (newValue, oldValue) {
        this.getNextStockNo()
      },
      deep: true,
      immediate: true
    }
  },
  created: function () {
    if (this.inventoryItem !== null && this.inventoryItem.id !== 0) {
      this.loadInventory(this.inventoryItem.id)
      if (this.isBookingWizard) {
        this.showMakeModelInfo = false
      }
    }
  }
}
</script>

<style scoped>

  .content ul {
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot {
    padding: 10px !important;
  }

  #arrival-modal-header {
    padding: 5px !important;
  }

  .el-steps {
    padding-top: 5px !important;
    padding-bottom: 5px !important;
  }

  .modal-card {
    overflow: auto;
  }

  .el-steps--simple {
    border-radius: 0 !important;
  }

  .box {
    border:0;
  }

  .columns {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }

  .modal-card-body {
    position:relative;
    max-height: 70vh !important;
    min-height: 70vh !important;
    height: 70vh !important;
    max-width: 800px;
    min-width: 800px;
    overflow-y: auto;
    padding:0 !important;
  }

  @media screen and (max-width: 769px) {
    .modal-card-body {
      max-height: unset !important;
      max-width: 100% !important;
      min-width: 100% !important;
    }
  }

</style>

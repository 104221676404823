<template>
  <section class="view-container">
      <portal :to="headerPortal" :disabled="headerPortal === ''">
        <a @click.stop.prevent="onStockNoClick(currentInventory)">
          <h1 class="title is-size-5 has-text-link has-text-weight-semibold">{{getTitleOverview}}</h1>
        </a>
      </portal>
      <div>
        <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
        <div v-if="currentInventory.id !== 0">
          <div class="box" :class="{'watermarked': isObsoleteStatus}" :data-watermark="obsoleteWatermarkText" v-if="!isLocating">
            <section-header :title="buildInventorySectionTitle(currentInventory)" :collapsed="isDetailsCollapsed" :overview="getInventoryOverview" v-clipboard:copy="currentInventory.vinNo" style="position: relative; z-index:2000">
            </section-header>
            <div class="columns" v-show="false">
              <div class="column is-12">
                <div class="field">
                  <label class="label">Dealer</label>
                  <div class="field">
                    {{currentInventory.dealerName}}
                  </div>
                </div>
              </div>
            </div>
            <div class="columns is-mobile" style="position:relative; z-index:2000">
              <div class="column is-vcentered" v-if="isTypePpaAppraisal">
                <i class="fal fa-eye fa-2x" :title="getPpaExaminedTitle" :class="getPpaExaminedColourClass"></i>
              </div>
              <div class="column is-vcentered" v-if="hasPpaRecord">
                <a @click="onViewOriginalPpa()" title="Redirect to original pre-purchase appraisal record">
                  <i class="fal fa-arrow-alt-circle-left fa-2x has-text-success" />
                </a>
              </div>
              <div class="column is-vcentered" v-if="isTypePpaAppraisal && isArchived && convertedInventoryId">
                <a @click="onViewCurrentInventory()" title="Redirect to converted inventory record">
                  <i class="fal fa-arrow-alt-circle-right fa-2x has-text-success" />
                </a>
              </div>
              <div class="column is-vcentered">
                <car-fax-control :key="currentInventory.id" :inventory="currentInventory" :autoUpdateInventory="true" :carFaxRating="currentInventory.carFaxRating" :vinNo="currentInventory.vinNo"></car-fax-control>
              </div>
              <div class="column is-vcentered">
                <a v-if="hasVautoAppraisal" :href="currentInventory.vAutoAppId" target="_vauto" title="Click to view the latest vAuto report for this vehicle."><img src="@/assets/static/vauto/vauto_logo_small.png" style="width:90px; height:auto;"></a>
                <img v-else title="vAuto report is pending" src="@/assets/static/vauto/vauto_logo_small.png" style="width:90px; height:auto;" class="img-disabled">
              </div>
              <div class="column is-vcentered">
                <auto-check-control
                v-if="autoCheckEnabled"
                :key="currentInventory.id"
                :inventory="currentInventory"
                :autoUpdateInventory="true"
                :autoCheckRating="currentInventory.autoCheckRating"
                :vinNo="currentInventory.vinNo"
                :purchasedFromType="currentInventory.purchasedFromType"
                :inventoryType="selectedInventoryStatusType"
                ></auto-check-control>
              </div>
              <div class="column is-vcentered" v-if="isPreEntry && canShowPreEntry">
                <i class="fal fa-car-tilt fa-2x" title="Pre-Entry"></i>
              </div>
            </div>
            <div class="columns is-mobile is-multiline" v-if="currentInventory">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Vehicle <inventory-sales-description-component :currentInventory="currentInventory"></inventory-sales-description-component></label>
                  <div class="field">
                    <span class="is-inline-block" v-html="makeOverview(currentInventory)"></span>
                  </div>
                </div>
              </div>
              <div class="column is-6" style="position: relative; z-index: 2000; display: inline-block;" :key="currentInventory.id">
                <div class="field">
                  <label class="label">Preview</label>
                  <figure class="image is-100x100" v-viewer.static="{inline: false, title: false}" style="padding: 0, 0; margin: 0, 0; float: left;">
                    <img :src="getImage(currentInventory)"/>
                    <img v-for="image in getAllImages(currentInventory)" :key="image.id" :src="image.src" style="display:none"/>
                  </figure>
                  <div class="quick-action" style="max-width: 50px; float: right; border: none; padding: 0, 0; margin: 0, 0;" v-if="currentInventory.hasVehicleImages">
                    <button type="button" class="button" @click="removeAllImages()" v-tooltip="'Remove all uploaded images.'">
                      <i data-type="D" class="icon fal fa-trash-alt fa-lg has-text-danger"/>
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-mobile" v-if="currentInventory && (isPreEntry || isTypePpaAppraisal)">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Proposed Purchased Cost</label>
                  <div class="field">
                    <span v-if="currentInventory.proposedCost">{{ currentInventory.proposedCost | currency }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Proposed Purchased Cost Expiration Date</label>
                <div class="field">
                  {{ currentInventory.proposedCostExpirationDate | formatDate }}
                </div>
              </div>
            </div>

            <div class="columns is-mobile" v-if="currentInventory && isTypePpaAppraisal">
              <div class="column is-6">
                <div class="field">
                  <label class="label">PPA Intention</label>
                  <div class="field">
                    <span v-if="currentInventory.ppaIntention">{{ currentInventory.ppaIntention }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
              <div class="column is-6" v-if="currentInventory.ppaIntention === 'Trade'">
                <label class="label">Proposed Trade Allowance</label>
                <div class="field">
                  <span v-if="currentInventory.proposedTradeAllowance">{{ currentInventory.proposedTradeAllowance | currency }}</span>
                  <span v-else>-</span>
                </div>
              </div>
            </div>

            <div class="columns is-mobile clickable" v-if="currentInventory" @click="onViewToPricingPod" style="position:relative; z-index:2000">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Asking Price</label>
                  <div class="field">
                    <span v-if="currentInventory.askingPrice">{{ currentInventory.askingPrice | currency }}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Lease Price</label>
                  <div class="field">
                    <span v-if="currentInventory.leasePrice">{{currentInventory.leasePrice | currency}}</span>
                    <span v-else>-</span>
                  </div>
                </div>
              </div>
            </div>

            <div v class="columns is-mobile" v-show="false">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Vin No</label>
                  <div class="field">
                    {{currentInventory.vinNo}}
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Stock No</label>
                  <div class="field">
                    <a @click.stop.prevent="onStockNoClick(currentInventory)">
                      <span class="has-text-weight-semibold" :title="currentInventory.stockNo">{{ currentInventory.stockNo }}</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-mobile">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Status</label>
                  <div class="field" :class="{'striked': isObsoleteStatus}">
                    {{ getSoldDeliveredStatus }}
                  </div>
                </div>
              </div>
              <div class="column is-6" v-if="currentInventory.returnedAt">
                <div class="field">
                  <label class="label">Returned At</label>
                  <div class="field">
                    {{currentInventory.returnedAt | formatDate}}
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-mobile" style="position:relative; z-index:2000">
              <div class="column is-6" ref="usage">
                <div class="is-pulled-left" v-if="currentInventory.vinUsageHistory.length > 0">
                  <label class="label is-inline-block is-size-7">Mileage</label>
                  <br/>
                  <usage-history-component
                    :showEditIcon="!isPpaClosed"
                    :inventoryId="currentInventory.id"
                    :vinNo="currentInventory.vinNo"
                    :vinUsageHistory="currentInventory.vinUsageHistory"
                    :usage="currentInventory.usage"
                    :usageRequired="usageRequired"
                    :minimumUsageAllowed="currentInventory.mileageEstimate ? 0 : minimumUsageAllowed"
                    v-on:usage-updated="usageComponentUpdateHandler">
                  </usage-history-component>
                </div>
              </div>
              <div class="column is-2">
                <div class="is-pulled-left">
                  <label class="label is-inline-block is-size-7">Damage</label>
                  <br/>
                  <i class="icon fal fa-lg status-icon" :class="getIconByLastDamageStatus(currentInventory)" :title="'Damage History: ' + getLastDamageDescription(currentInventory)"/>
                </div>
              </div>
              <div class="column is-2">
                <div class="is-pulled-left">
                  <label class="label is-inline-block is-size-7">Sticker</label>
                  <br/>
                  <window-stick-status-capture :currentInventory="currentInventory" :key="currentInventory.id"></window-stick-status-capture>
                </div>
              </div>
              <div class="column is-2">
                <div class="is-pulled-left">
                  <label class="label is-inline-block is-size-7">Publish</label>
                  <br/>
                  <i class="icon fal fa-lg status-icon" :class="getIconByPublishStatus(currentInventory)"/>
                </div>
              </div>
            </div>

            <div class="columns is-mobile">
              <div class="column is-6">
                <field-rotator v-model="locationList" :enableSlotForIndex="1" >
                  <i class="fal fa-pencil fa-lg clickable is-pulled-right" title="Click to edit the current location" style="margin-right: 10px; margin-top: 3px;" @click.stop.prevent="onEditLocation" v-if="hasFeatureAccess(updateInventoryLocationPermission)"></i>
                </field-rotator>
              </div>
              <div class="column is-6" v-if="canShowPickupLocation">
                <div class="field">
                  <label class="label">Pickup Location</label>
                  <div class="field">
                    {{ currentInventory.pickupLocation || "-" }}
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-mobile" style="position: relative; z-index:2000">
              <div class="column is-6">
                <div class="field">
                  <label class="label">
                    Purchased From
                    <dealer-peak-control v-if="currentInventory.purchasedFromType === 'Individual'" :key="currentInventory.purchasedFromId" :customerId="currentInventory.purchasedFromId"></dealer-peak-control>
                  </label>
                  <div class="field">
                    <a class="is-link" @click="onViewPurchasedFrom">
                      {{currentInventory.purchasedFromName || "-"}}
                    </a>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Purchased By</label>
                  <div class="field">
                    <span class="icon-text">
                      <span>{{currentInventory.buyer || "-"}}</span>
                      <span class="icon" v-if="showDealCompletedIcon({dealStatus: primaryDealStatusName, dealGenericStatusName: primaryDealGenericStatusName, isDealBookingFinalised: primaryDealIsBookingFinalised})" v-tooltip="'Booking finalized'">
                        <i style="text-shadow: 0 0 3px #000;" class="grid-icon fa-fw fal fa-check fa-lg has-text-primary is-pulled-right" />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-mobile" style="position:relative; z-index:2000">
              <div class="column is-6">
                <field-rotator v-model="daysList"></field-rotator>
              </div>
              <div class="column is-6">
                <field-rotator v-model="expirationList"></field-rotator>
              </div>
            </div>

            <div class="columns is-mobile" style="position:relative; z-index:2000">
              <div class="column is-12" v-if="showDispositionIntention">
                <div class="field" :title="originalDispositionIntentionDisplayValue">
                  <label class="label">Current Disp. Intention</label>
                  <div class="field is-grouped" style="align-items: baseline; justify-content: space-between;">
                    <div class="field">
                    <i v-show="isCurrentDispositionIntentionExpired" class="fas fa-exclamation-triangle fa-lg has-text-danger"></i>
                    <span>{{ currentDispositionIntentionFormatted}}</span>
                    <inventory-disposition-intention-history-component :currentInventory="currentInventory"></inventory-disposition-intention-history-component>
                    </div>
                    <div class="field">
                      <i v-if="isSavingNewDispositionIntention" class="fas fa-spinner fa-spin fa-lg has-text-success" title="Saving..."></i>
                    </div>
                    <div class="field">
                      <b-field class="dispositionIntentionSwitcher">
                        <b-radio-button :disabled="!hasFeatureAccess('inventory.modify.disposition-intention', false)" v-model="dispositionIntentionValue" size="is-small" native-value="Retail" type="is-success" @input="onDispositionIntentionChanged"><span>Retail</span></b-radio-button>
                        <b-radio-button :disabled="!hasFeatureAccess('inventory.modify.disposition-intention', false)" v-model="dispositionIntentionValue" size="is-small" native-value="Wholesale" type="is-success" @input="onDispositionIntentionChanged"><span>Wholesale</span></b-radio-button>
                      </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <span v-if="!showDispositionIntention"><br /></span>

            <div class="columns is-mobile" style="position:relative; z-index:2000" v-if="hasFeatureAccess('inventory.publishstatus.modify', false)">
              <div class="column is-6">
                <label class="label">Is Published?</label>
                <div class="field">
                  <b-field class="publishStatusSwitcher">
                    <b-radio-button v-model="publishStatusValue" size="is-small" :native-value="true" type="is-success" @input="isPublishStatusChangeActive = true"><span>Yes</span></b-radio-button>
                    <b-radio-button v-model="publishStatusValue" size="is-small" :native-value="false" type="is-success" @input="isPublishStatusChangeActive = true"><span>No</span></b-radio-button>
                  </b-field>
                </div>
              </div>
            </div>
            <span v-else><br /></span>

            <div class="columns is-mobile">
              <div class="column is-12" v-if="currentInventory.archivedAt">
                <div class="field">
                  <label class="label">Returned</label>
                  <div class="field">
                    {{currentInventory.archivedAt | formatDate}}
                  </div>
                </div>
              </div>
            </div>
            <br />
            <div class="columns is-mobile" v-if="isTypePpaAppraisal">
              <div class="column is-6">
                <div class="field">
                  <label class="label">PPA Approved</label>
                  <div class="field">
                    {{ currentInventory.ppaApproved ? "YES" : "NO" }}
                  </div>
                </div>
              </div>
              <div class="column is-6" v-if="currentInventory.ppaApproved">
                <div class="field">
                  <label class="label">PPA Approved At</label>
                  <div class="field">
                    {{ currentInventory.ppaApprovedAt | formatDateOnly }}
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-mobile" v-if="isTypePpaAppraisal">
              <div class="column is-6" v-if="currentInventory.ppaApproved">
                <div class="field">
                  <label class="label">PPA Approved By</label>
                  <div class="field">
                    {{ currentInventory.ppaApprovedByName }}
                  </div>
                </div>
              </div>
              <div class="column is-6" v-if="isTypePpaAppraisal">
                <div class="field" v-if="hasPpaReceipt">
                  <file-selector v-model="getPpaReceipt" label="Pre Purchase Receipt" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="64"></file-selector>
                </div>
              </div>
            </div>

            <div class="columns is-mobile" v-if="isTypePpaAppraisal && currentInventory.ppaExamined">
              <div class="column is-6">
                <div class="field">
                  <label class="label">PPA Examined By</label>
                  <div class="field">
                    {{ currentInventory.ppaExaminedByName }}
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">PPA Examined At</label>
                  <div class="field">
                    {{ currentInventory.ppaExaminedAt | formatDateOnly }}
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-mobile" v-if="currentInventory.sold && currentInventory.soldAt">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Sold At</label>
                  <div class="field">
                    {{ currentInventory.soldAt | formatDateOnly }}
                  </div>
                </div>
              </div>
            </div>

            <div class="columns is-mobile" style="position:relative; z-index:2000">
              <div class="column is-6">
                <div class="is-pulled-left">
                  <label class="label is-inline-block is-size-7">Status</label>
                  <br/>
                  <div class="is-inline-block">
                    <a @click.ctrl.exact.stop.prevent="onViewPrimaryDeal()" @click.exact.stop.prevent="onDealStatusClick()" v-if="showDealCompletedIcon({dealStatus: primaryDealStatusName, dealGenericStatusName: primaryDealGenericStatusName, isDealBookingFinalised: primaryDealIsBookingFinalised})">
                      <i class="far fa-dollar-sign fa-lg fa-fw status-icon" :class="getColorClassByDealItemStatus({dealStatus: primaryDealStatusName, dealGenericStatusName: primaryDealGenericStatusName})" />
                      <i style="text-shadow: 0 0 3px #000; margin-left: -2rem;" class="grid-icon fa-fw fal fa-check fa-lg has-text-white" v-tooltip="getDealStatusTitleForView(primaryDealStatusName, primaryDeal)"/>
                    </a>
                    <a @click.ctrl.exact.stop.prevent="onViewPrimaryDeal() " @click.exact.stop.prevent="onDealStatusClick()" v-else><i class="far fa-dollar-sign fa-lg fa-fw status-icon" :class="getColorClassByDealItemStatus({dealStatus: primaryDealStatusName, dealGenericStatusName: primaryDealGenericStatusName})" v-tooltip="getDealStatusTitleForView(primaryDealStatusName, primaryDeal)"/></a>
                    <a @click.stop.prevent="onServiceRequestStatusClick()"><i class="fal fa-wrench fa-lg fa-fw status-icon" :class="getColorClassByGetReadyStatus(currentInventory.getReadyStatusId)" v-tooltip="buildServiceRequestTitle(currentInventory)"/></a>
                    <a @click.stop.prevent="onAccessoriesStatusClick()"><i class="fal fa-exclamation-circle fa-lg fa-fw status-icon" :class="getColorClassByDetailsStatus(currentInventoryaccessoryDetailStatusType)" v-tooltip="buildAccessoryStatusTitle(currentInventory)"/></a>
                    <i class="fal fa-badge-percent fa-lg fa-fw status-icon" v-tooltip="buildConsignmentStatusTitle(currentInventory)" v-if="isTypeConsigned"/>
                    <i class="fal fa-badge-dollar fa-lg fa-fw status-icon" :class="getColorClassByTitlePayoffStatus(currentInventory)" title="Payoff Status"/>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="is-pulled-left">
                  <label class="label is-inline-block is-size-7">Title</label>
                  <br/>
                  <title-status-capture :currentInventory="currentInventory" :isListMode="false" :key="'qv' + currentInventory.id"></title-status-capture>
                </div>
                <div class="is-pulled-left" v-if="canShowFleet && currentInventory.isFleet">
                  <label class="label is-inline-block is-size-7">Fleet</label>
                  <br/>
                  <i class="icon fal fa-lg status-icon" :class="getIconByFleetStatus(currentInventory)"/>
                </div>
                <div class="is-pulled-left ml-5" v-if="canShowDemo && currentInventory.isDemo">
                  <label class="label is-inline-block is-size-7">Demo</label>
                  <br/>
                  <i class="icon fal fa-lg status-icon" :class="getIconByDemoStatus(currentInventory)"/>
                </div>
                <div class="is-pulled-left ml-5" v-if="canShowMarketable">
                  <label class="label is-inline-block is-size-7">Marketable</label>
                  <br/>
                  <i class="icon fal fa-lg status-icon" :class="getIconByReadyToMarketStatus(currentInventory)"/>
                </div>
              </div>
            </div>

            <quick-action-container>
              <inventory-sellers-agreement :currentInventory="currentInventory" :key="'S' + currentInventory.id"></inventory-sellers-agreement>
              <inventory-consignment-agreement :currentInventory="currentInventory" :key="'C' + currentInventory.id"></inventory-consignment-agreement>
              <inventory-batch-payment-request :ref="batchPaymentRequestRef" :currentInventory="currentInventory" :key="'P' + currentInventory.id"></inventory-batch-payment-request>
              <inventory-vehicle-verification :currentInventory="currentInventory" :key="'V' + currentInventory.id"></inventory-vehicle-verification>
              <send-pickup-location-control :deal="primaryDeal" :inventory="currentInventory"></send-pickup-location-control>
              <inventory-lost-title v-if="titleIsLost" ref="titleLostAction" :currentInventory="currentInventory" v-on:close-lost-title="onCloseLostTitle" :showDialog="showLostTitleAction" :key="'L' + currentInventory.id" v-on:show-lost-title="onShowLostTitle"></inventory-lost-title>
              <send-trade-in-title-reassignment-documentation :inventoryItem="currentInventory" :key="'TT' + currentInventory.id" :isListMode="false" v-if="isTypeTrade"></send-trade-in-title-reassignment-documentation>
              <service-loaner-out-of-service-action :inventory="currentInventory" :isView="true" v-if="currentInventory" v-on:inventory-service-loaner-updated="reloadInventory"></service-loaner-out-of-service-action>
              <inventory-desk-deal v-if="currentInventory.crmInventoryItemId" :crmInventoryId="currentInventory.crmInventoryItemId"></inventory-desk-deal>
            </quick-action-container>
          </div>

          <div class="box" v-else>
            <section-header :collapsed="isDetailsCollapsed" title="Overview"></section-header>
            <div class="columns is-mobile">
              <div class="column is-12">
                <div class="field">
                  <label class="label">Required Vehicle</label>
                  <div class="field">
                    {{ currentInventory.name }}
                  </div>
                </div>
              </div>
            </div>
            <inventory-attribute-view :inventory="currentInventory"></inventory-attribute-view>
          </div>

          <div class="box" ref="pricingPod">
            <section-header title="Pricing" :overview="getPricingOverview" :collapsed="isPricingCollapsed" v-on:collapsed-status-changed="onPricingCollapsedStatusChanged"></section-header>
            <template v-if="this.isEditLocked === true">
              <div class="columns is-mobile is-multiline">
                <div class="column is-4">
                  <div class="field">
                    <label class="label">Msrp</label>
                    <div class="field">
                      <span v-if="currentInventory.msrp">{{currentInventory.msrp | currency}}</span>
                      <span v-else>-</span>
                      <inventory-pricing-history-component :currentInventory="currentInventory" pricingType="msrp"></inventory-pricing-history-component>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="field">
                    <label class="label">Asking Price</label>
                    <div class="field">
                      <span v-if="currentInventory.askingPrice && hasFeatureAccess('inventory.asking-price.view')">{{currentInventory.askingPrice | currency}}</span>
                      <span v-else>-</span>
                      <inventory-pricing-history-component :currentInventory="currentInventory" pricingType="askingPrice"></inventory-pricing-history-component>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="field">
                    <label class="label">Lease Price</label>
                    <div class="field">
                      <span v-if="currentInventory.leasePrice && hasFeatureAccess('inventory.lease-price.view')">{{currentInventory.leasePrice | currency}}</span>
                      <span v-else>-</span>
                      <inventory-pricing-history-component :currentInventory="currentInventory" pricingType="leasePrice"></inventory-pricing-history-component>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="field">
                    <label class="label">GL Balance</label>
                    <div class="field">
                      <span v-if="currentInventory.generalLedgerBalance && hasFeatureAccess('inventory.gl-balance.view')">{{currentInventory.generalLedgerBalance | currency}}</span>
                      <span v-else>-</span>
                      <inventory-pricing-history-component :currentInventory="currentInventory" pricingType="generalLedgerBalance"></inventory-pricing-history-component>
                    </div>
                  </div>
                </div>
                <div class="column is-4" v-if="!isTypePpaAppraisal && !isPreEntry">
                  <div class="field">
                    <label class="label">{{purchaseCostLabel}}</label>
                    <div class="field">
                      <span v-if="currentInventory.purchaseCost && hasFeatureAccess('inventory.purchase-cost.view')">{{currentInventory.purchaseCost | currency}}</span>
                      <span v-else>-</span>
                      <inventory-pricing-history-component :currentInventory="currentInventory" pricingType="purchaseCost"></inventory-pricing-history-component>
                    </div>
                  </div>
                </div>
                <div class="column is-4" v-else>
                  <div class="field">
                    <label class="label">Proposed Purchase Cost</label>
                    <div class="field">
                      <span v-if="currentInventory.proposedCost">{{currentInventory.proposedCost | currency}}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>

                <div class="column is-4">
                  <div class="field">
                    <label class="label">Recon Cost</label>
                    <div class="field">
                      <span v-if="currentInventory.reconCost && hasFeatureAccess('inventory.recon-cost.view')">{{currentInventory.reconCost | currency}}</span>
                      <span v-else>-</span>
                      <inventory-pricing-history-component :currentInventory="currentInventory" pricingType="reconCost"></inventory-pricing-history-component>
                    </div>
                  </div>
                </div>
                <div class="column is-4" v-if="isTypePpaAppraisal || isTypeTrade">
                  <div class="field">
                    <label class="label">ACV</label>
                    <div class="field">
                      <span v-if="currentInventory.purchaseCost">{{currentInventory.purchaseCost | currency}}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>
                <div class="column is-4" v-if="isTypePpaAppraisal || isTypeTrade">
                  <div class="field">
                    <label class="label">Trade Allowance</label>
                    <div class="field">
                      <span v-if="currentInventory.proposedTradeAllowance">{{currentInventory.proposedTradeAllowance | currency}}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="field">
                    <label class="label">Exp. GL Balance</label>
                    <div class="field">
                      <span v-if="expectedGeneralLedgerBalance && hasFeatureAccess('inventory.exp-gl-balance.view')">{{expectedGeneralLedgerBalance | currency}}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="field">
                    <label class="label">Spiff</label>
                    <div class="field">
                      <span v-if="currentInventory.spiff && hasFeatureAccess('inventory.spiff.view')">{{currentInventory.spiff | currency}}</span>
                      <span v-else>-</span>
                      <inventory-pricing-history-component :currentInventory="currentInventory" pricingType="spiff"></inventory-pricing-history-component>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="field">
                    <label class="label">Spiff Expiration Date</label>
                    <div class="field">
                      {{ currentInventory.spiffExpirationDate | formatDateOnly }}
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="is-pulled-left">
                    <label class="label is-inline-block is-size-7">On Special</label>
                    <br/>
                    <i class="icon fal fa-lg status-icon" :class="getIconByOnSpecialStatus(currentInventory)"/>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="field">
                    <label class="label">Special Expiration Date</label>
                    <div class="field">
                      {{ currentInventory.specialExpirationDate | formatDateOnly }}
                    </div>
                  </div>
                </div>
                <div class="column is-4" v-show="false">
                  <div class="field">
                    <label class="label">Pay Off Amount</label>
                    <div class="field">
                      <span v-if="currentInventory.payoffAmount">{{currentInventory.payoffAmount | currency}}</span>
                      <span v-else>-</span>
                    </div>
                  </div>
                </div>
                <div class="column is-4">
                  <div class="field">
                    <label class="label">Purchase Commission</label>
                    <div class="field">
                      <span v-if="currentInventory.commission">{{currentInventory.commission | currency}}</span>
                      <span v-else>-</span>
                      <inventory-pricing-history-component :currentInventory="currentInventory" pricingType="commission"></inventory-pricing-history-component>
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Lease Note</label>
                    <div class="field">
                      {{currentInventory.leaseNote ? (currentInventory.leaseNote) : '-'}}
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Special Note</label>
                    <div class="field">
                      {{currentInventory.specialNote ? (currentInventory.specialNote) : '-'}}
                    </div>
                  </div>
                </div>
                <div class="column is-12" v-if="currentInventory.ppaPriceAmendmentReason">
                  <div class="field">
                    <label class="label">Proposed price re-evaluation ({{currentInventory.ppaPriceAmendmentReasonDescription}})</label>
                    <div class="field">
                      {{currentInventory.ppaPriceAmmendmentNote ? (currentInventory.ppaPriceAmmendmentNote) : '-'}}
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="currentInventory.type === 'Consigned'">
                <div class="columns is-mobile is-multiline" >
                  <div class="column is-6" v-if="currentInventory.saleProceedsType === 'Flat'">
                    <div class="field">
                      <label class="label">Minimum Sale Price</label>
                      <div class="field">
                        <span v-if="currentInventory.consignmentMinimum">{{ currentInventory.consignmentMinimum | currency }}</span>
                        <span v-else>-</span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" v-else>
                    <div class="field">
                      <label class="label">Sale Price</label>
                      <div class="field">
                        <span v-if="currentInventory.askingPrice">{{ currentInventory.askingPrice | currency }}</span>
                        <span v-else>-</span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Consignment Type</label>
                      <div class="field">
                        {{currentInventory.saleProceedsType ? currentInventory.saleProceedsType : '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" v-if="currentInventory.saleProceedsType === 'Percentage'">
                    <div class="field">
                      <label class="label">Consignment Percentage</label>
                      <div class="field">
                        {{currentInventory.consignmentPercentage ? (currentInventory.consignmentPercentage + '%') : '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" v-if="currentInventory.saleProceedsType === 'Split' || currentInventory.saleProceedsType === 'Flat'">
                    <div class="field">
                      <label class="label" v-if="currentInventory.saleProceedsType === 'Split'">Split Value</label>
                      <label class="label" v-else>Delaer Flat Value</label>
                      <div class="field">
                        {{currentInventory.consignmentAgreementValue ? ('$' + currentInventory.consignmentAgreementValue) : '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="column is-6" v-if="currentInventory.saleProceedsType === 'Split'">
                    <div class="field">
                      <label class="label">Split Ratio</label>
                      <div class="field">
                        {{currentInventory.percentageSplitFirst + '% / ' + currentInventory.percentageSplitSecond + '%'}}
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Consignment Terms</label>
                      <div class="field">
                        {{currentInventory.consignmentTerms ? (currentInventory.consignmentTerms) : '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="field">
                      <label class="label">Consignment Note</label>
                      <div class="field">
                        {{currentInventory.consignmentNote ? (currentInventory.consignmentNote) : '-'}}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="currentInventory.commitmentFromName !== ''">
                <div class="columns is-mobile is-multiline">
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Commitment From</label>
                      <div class="field">
                        {{currentInventory.commitmentFromName ? (currentInventory.commitmentFromName) : '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="column is-6">
                    <div class="field">
                      <label class="label">Firm Commitment</label>
                      <div class="field">
                        {{currentInventory.firmCommitment ? (currentInventory.firmCommitment ? "Yes" : "No") : '-'}}
                      </div>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="field">
                      <label class="label">Commitment Value</label>
                      <div class="field">
                        <span v-if="currentInventory.commitmentValue">{{currentInventory.commitmentValue | currency}}</span>
                        <span v-else>-</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <form class="view-container" @change="onFormChange">
                <div class="columns is-mobile is-multiline">
                  <div class="column is-4">
                  <div class="field">
                    <label class="label">Msrp</label>
                    <div class="control has-icons-left">
                      <money name="msrp" data-vv-as="msrp" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('msrp') }" type="text" placeholder="0.00" v-model="currentInventory.msrp" v-bind="$globalMoneyFormat"></money>
                      <span v-show="errors.has('msrp')" class="help is-danger"><i v-show="errors.has('msrp')" class="fas fa-exclamation-triangle"></i> {{ errors.first('msrp') }}</span>
                      <span class="icon is-small is-left">
                        <i class="fas fa-dollar-sign"></i>
                      </span>
                    </div>
                  </div>
                </div>
                  <div class="column is-4">
                    <div class="field">
                      <label class="label">Asking Price</label>
                      <div class="control has-icons-left">
                        <money name="askingPrice" data-vv-as="asking price" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('askingPrice') }" type="text" placeholder="0.00" v-model="currentInventory.askingPrice" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('askingPrice')" class="help is-danger"><i v-show="errors.has('askingPrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('askingPrice') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field">
                      <label class="label">Lease Price</label>
                      <div class="control has-icons-left">
                        <money name="leasePrice" data-vv-as="lease price" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('leasePrice') }" type="text" placeholder="0.00" v-model="currentInventory.leasePrice" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('leasePrice')" class="help is-danger"><i v-show="errors.has('leasePrice')" class="fas fa-exclamation-triangle"></i> {{ errors.first('leasePrice') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field">
                      <label class="label">GL Balance</label>
                      <div class="control has-icons-left">
                        <money name="generalLedgerBalance" data-vv-as="GL balance" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('generalLedgerBalance') }" type="text" placeholder="0.00" v-model="currentInventory.generalLedgerBalance" disabled v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('generalLedgerBalance')" class="help is-danger"><i v-show="errors.has('generalLedgerBalance')" class="fas fa-exclamation-triangle"></i> {{ errors.first('generalLedgerBalance') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field">
                      <label class="label">{{purchaseCostLabel}}</label>
                      <div class="field">
                        <span class="read-only-field" v-if="currentInventory.purchaseCost">{{currentInventory.purchaseCost | currency}}</span>
                        <span class="read-only-field" v-else>-</span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field">
                      <label class="label">Recon Cost</label>
                      <div class="control has-icons-left">
                        <money name="reconCost" data-vv-as="recon cost" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('reconCost') }" type="text" placeholder="0.00" v-model="currentInventory.reconCost" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('reconCost')" class="help is-danger"><i v-show="errors.has('reconCost')" class="fas fa-exclamation-triangle"></i> {{ errors.first('reconCost') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field">
                      <label class="label">Exp. GL Balance</label>
                      <div class="field">
                        <span class="read-only-field" v-if="expectedGeneralLedgerBalance">{{expectedGeneralLedgerBalance | currency}}</span>
                        <span class="read-only-field" v-else>-</span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field">
                      <label class="label">Spiff</label>
                      <div class="control has-icons-left">
                        <money name="spiff" data-vv-as="spiff" v-validate="'decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('spiff') }" type="text" placeholder="0.00" v-model="currentInventory.spiff" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('spiff')" class="help is-danger"><i v-show="errors.has('spiff')" class="fas fa-exclamation-triangle"></i> {{ errors.first('spiff') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-4">
                    <div class="field">
                      <label class="label">Spiff Expiration Date<b-icon pack="fas" :icon="isSpiffExpirationDateRequired === 'required' ? 'star' : ''" class="fa-ss"></b-icon></label>
                      <el-date-picker
                        class="is-width-120"
                        @change="onFormChange()"
                        v-model="currentInventory.spiffExpirationDate"
                        type="date"
                        placeholder="Click to select..."
                        popper-class="datepicker-container"
                        data-vv-validate-on="input|change"
                        v-validate="isSpiffExpirationDateRequired"
                        :class="{'is-danger': errors.has('spiffExpirationDate') }"
                        data-vv-as="spiff expiration date">
                      </el-date-picker>
                      <span v-show="errors.has('spiffExpirationDate')" class="help is-danger">
                        <i v-show="errors.has('spiffExpirationDate')" class="fas fa-exclamation-triangle"></i> {{ errors.first('spiffExpirationDate') }}
                      </span>
                    </div>
                  </div>
                  <div class="column is-4" v-show="false && currentInventory.payoffAmount">
                    <div class="field">
                      <label class="label no-content-wrap">Payoff Amount<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                      <div class="control has-icons-left">
                        <money name="payoffAmount" data-vv-as="payoff amount" v-validate="'decimal|required'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('payoffAmount') }" type="text" placeholder="0.00" v-model="currentInventory.payoffAmount" v-bind="$globalMoneyFormat"></money>
                        <span v-show="errors.has('payoffAmount')" class="help is-danger"><i v-show="errors.has('payoffAmount')" class="fas fa-exclamation-triangle"></i> {{ errors.first('payoffAmount') }}</span>
                        <span class="icon is-small is-left">
                          <i class="fas fa-dollar-sign"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div class="column is-12">
                    <div class="field">
                      <label class="label">Lease Notes</label>
                      <div class="control">
                        <textarea name="leaseNote" :class="{'textarea': true}" type="text" placeholder="" v-model="currentInventory.leaseNote"></textarea>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="currentInventory.type === 'Consigned'">
                  <div class="columns is-mobile is-multiline" >
                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Consignment Percentage<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control has-icons-left">
                          <input name="consignmentPercentage" data-vv-as="consignment percentage" v-validate="'required|decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('consignmentPercentage') }" type="text" placeholder="0.00" v-model="currentInventory.consignmentPercentage">
                          <span v-show="errors.has('consignmentPercentage')" class="help is-danger"><i v-show="errors.has('consignmentPercentage')" class="fas fa-exclamation-triangle"></i> {{ errors.first('consignmentPercentage') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-percent"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Consignment Minimum<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                        <div class="control has-icons-left">
                          <money name="consignmentMinimum" data-vv-as="consignment minimum" v-validate="'required|decimal'" data-vv-delay="250" :class="{'input': true, 'is-danger': errors.has('consignmentMinimum') }" type="text" placeholder="0.00" v-model="currentInventory.consignmentMinimum" v-bind="$globalMoneyFormat"></money>
                          <span v-show="errors.has('consignmentMinimum')" class="help is-danger"><i v-show="errors.has('consignmentMinimum')" class="fas fa-exclamation-triangle"></i> {{ errors.first('consignmentMinimum') }}</span>
                          <span class="icon is-small is-left">
                            <i class="fas fa-dollar-sign"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label class="label">Consignment Terms</label>
                        <div class="control">
                          <input name="consignmentTerms" :class="{'input': true}" type="text" placeholder="" v-model="currentInventory.consignmentTerms">
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label class="label">Consignment Notes</label>
                        <div class="control">
                          <textarea name="consignmentNote" :class="{'textarea': true}" type="text" placeholder="" v-model="currentInventory.consignmentNote"></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="currentInventory.commitmentFromName !== ''">
                  <div class="columns is-mobile is-multiline">
                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Commitment From</label>
                        <div class="field">
                          {{currentInventory.commitmentFromName ? (currentInventory.commitmentFromName) : '-'}}
                        </div>
                      </div>
                    </div>
                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Firm Commitment</label>
                        <div class="field">
                          {{currentInventory.firmCommitment ? (currentInventory.firmCommitment ? "Yes" : "No") : '-'}}
                        </div>
                      </div>
                    </div>
                    <div class="column is-12">
                      <div class="field">
                        <label class="label">Commitment Value</label>
                        <div class="field">
                          <span class="read-only-field" v-if="currentInventory.commitmentValue">{{currentInventory.commitmentValue | currency}}</span>
                          <span class="read-only-field" v-else>-</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </template>
            <inventory-appraisal-control :key="currentInventory.id" v-if="hasFeatureAccess('inventory.appraisal.view')" :appraisedValue="mostRecentAppraisal" displayMode="Compact" :inventory="inventoryAppraisalParams" v-on:on-new-appraisal="onAppraised" :inventoryType="currentInventory.type"></inventory-appraisal-control>
            <inventory-black-book-control :blackBookInformation="mostRecentBlackBookInformation" :inventory="inventoryAppraisalParams" v-on:on-new-blackbook-information="onBlackBookInformationRetrieved" v-if="blackBookEnabled"></inventory-black-book-control>
            <inventory-payoff-control v-if="currentInventory && (currentInventory.isPayOff || currentInventory.titleStatus === 'PayOff')" displayMode="Compact" :inventory="currentInventory" :inventoryType="currentInventory.type"></inventory-payoff-control>
          </div>
          <div class="box offers-leads" ref="offersPod">
            <section-header :collapsed="isOffersCollapsed" title="Offers & Leads" :forInventoryView="true" :overview="getHighestOfferOverview" v-on:collapsed-status-changed="onOffersCollapsedStatusChanged" :inventoryUpdateHeader="true" :infoHeader="hasOfferAmount"></section-header>
            <div class="columns">
              <div class="column is-12 no-h-padding">
                <b-tabs size="is-small" v-model="offersAndLeadsTabIndex" :animated="false" type="is-toggle">
                  <b-tab-item label="Offers" icon="fal fa-hand-holding-usd" icon-pack="fal">
                    <div class="columns is-multiline">
                      <div class="column is-12" v-if="!offersLoading">
                        <div style="margin-top: 1em; margin-bottom: 1em;">
                          <b-field class="offerHistoryType">
                            <b-radio-button v-model="selectedOfferType" size="is-small" native-value="All" type="is-default-selected"><span>All</span></b-radio-button>
                            <b-radio-button v-model="selectedOfferType" size="is-small" native-value="Customer" type="is-success"><span>Customer</span></b-radio-button>
                            <b-radio-button v-model="selectedOfferType" size="is-small" native-value="Sales" type="is-success"><span>Sales</span></b-radio-button>
                          </b-field>
                        </div>
                        <b-table
                          :data="this.filteredPurchaseOffers"
                          :sortable="false"
                          :mobile-cards="false"
                          :selecteable="false"
                          :row-class="() => 'accesory-event-row'">
                          <template slot-scope="props">

                            <b-table-column field="customerName" label="Customer" class="accesory-event-column" :title="props.row.customerLastName">
                              {{ (props.row.customerFirstName + ' ' + props.row.customerLastName) }}
                            </b-table-column>

                            <b-table-column field="offerMadeBy" label="Offer Made By" class="accesory-event-column">
                              {{ props.row.offerMadeBy }}
                            </b-table-column>

                            <b-table-column field="offer" label="Offer" class="accesory-event-column">
                              {{ props.row.offerReceived | currency('$', 2) }}
                            </b-table-column>

                            <b-table-column field="offerType" label="Type Of Offer" class="accesory-event-column">
                              {{ props.row.offerType }}
                            </b-table-column>

                            <b-table-column field="createdAt" label="Date" class="accesory-event-column">
                              {{ props.row.offerReceivedOn | formatDateOnly }}
                            </b-table-column>

                          </template>
                          <template slot="empty">
                            <section class="section">
                              <div class="has-text-centered">
                                <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                                <p>No offers recorded for this vehicle.</p>
                              </div>
                            </section>
                          </template>
                        </b-table>
                        <br />
                      </div>
                      <div class="column is-12 no-h-padding subtitle is-7" style="text-align: center;" v-else>
                        <i class="fas fa-spinner fa-spin fa-lg has-text-success" title="Loading..."></i>
                        Loading all offers ...
                      </div>
                    </div>
                  </b-tab-item>
                  <b-tab-item label="Leads"  icon="fal fa-user-plus" icon-pack="fal">
                    <div class="columns is-multiline">
                      <div class="column is-12" v-if="!leadsLoading">
                        <div style="margin-bottom: 1em;">
                          <lead-type-selector :selectedLeadTypes="selectedLeadTypes" v-on:selection-changed="onLeadTypeSelectionChanged"></lead-type-selector>
                        </div>
                        <b-table
                          :data="filteredLeads"
                          :sortable="false"
                          :mobile-cards="false"
                          :selecteable="false"
                          :row-class="() => 'accesory-event-row'">
                          <template slot-scope="props">

                            <b-table-column field="customerFullName" label="Customer" class="accesory-event-column" :title="props.row.customerFullName">
                              <a @click="onViewLeadCustomer(props.row.customerId)" class="has-text-weight-semibold clickable" :title="props.row.customerFullName">{{ props.row.customerFullName }}</a>
                            </b-table-column>

                            <b-table-column field="leadStatus" label=" Status" class="accesory-event-column">
                              {{ props.row.leadStatus }}
                            </b-table-column>

                            <b-table-column field="salesAgent" label="Agent" class="accesory-event-column">
                              {{ props.row.salesAgent}}
                            </b-table-column>

                            <b-table-column field="leadSource" label="Source" class="accesory-event-column">
                              {{ props.row.leadSource }}
                            </b-table-column>

                            <b-table-column field="createdAt" label="Date" class="accesory-event-column">
                              {{formatDate(props.row.utcEnabled, props.row.dateCreated) }}
                            </b-table-column>

                          </template>
                          <template slot="empty">
                            <section class="section">
                              <div class="has-text-centered">
                                <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                                <p>No leads recorded for this vehicle.</p>
                              </div>
                            </section>
                          </template>
                        </b-table>
                        <br />
                      </div>
                      <div class="column is-12 no-h-padding subtitle is-7" style="text-align: center;" v-else>
                        <i class="fas fa-spinner fa-spin fa-lg has-text-success" title="Loading..."></i>
                        Loading all leads ...
                      </div>
                    </div>
                  </b-tab-item>
                </b-tabs>
              </div>
            </div>
          </div>

          <div class="box"  ref="tagsPod">
            <section-header :collapsed="true" title="Tags"></section-header>
            <tag-view v-model="this.tagsList"></tag-view>
            <quick-action-container>
              <inventory-tags-capture v-if="!isPpaClosed" :inventoryItem="currentInventory" :isListMode="false" :key="currentInventory.id"></inventory-tags-capture>
            </quick-action-container>
            <br/>
            <div class="columns is-multiline">
              <div class="column is-12 is-size-7" v-if="currentInventory && currentInventory.saleDescription && currentInventory.saleDescription !== ''">
                <label class="label is-inline-block">Sales Description</label><i class="fal fa-copy is-pulled-right" @click.stop.prevent="onCopy('Description', currentInventory.saleDescription)"></i>
                <div class="side-padding-sm" v-html="currentInventory.saleDescription"></div>
              </div>
              <div class="column is-12 is-size-7" v-if="currentInventory && currentInventory.features">
                <label class="label is-inline-block">Features</label><i class="fal fa-copy is-pulled-right" @click.stop.prevent="onCopy('Features', currentInventory.features)"></i>
                <ul style="margin-left: 1.5em;">
                  <li type="disc" v-for="feature in features" :key="feature">{{feature}}</li>
                </ul>
              </div>
            </div>
          </div>

          <div id="supplement-cost-section" class="box" v-show="showCostsSection">
            <section-header
              title="Additional Costs"
              parentSelector="#supplement-cost-section"
              :collapsed="true"
              :overview="getTotalAdditionalCostsHeader"></section-header>
            <div class="columns is-multiline">
              <div class="column is-12 no-h-padding">
                <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                  <thead v-if="supplementalCosts.length > 0">
                    <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Cost</th>
                    <th>Selling Price</th>
                    <th v-if="hasFeatureAccess(deleteSupplementalCostAccessPermission)">Actions</th>
                    </tr>
                  </thead>
                  <tr class="clickable" v-for="(supplementalCost, index) in supplementalCosts" :key="supplementalCost.id" @click="editSupplementalCost(supplementalCost, index)">
                    <td>{{index + 1}}</td>
                    <td>{{supplementalCost.name + ' - ' + supplementalCost.description}}</td>
                    <td>{{supplementalCost.cost | currency}}</td>
                    <td>{{supplementalCost.sellingPrice | currency}}</td>
                    <td v-if="hasFeatureAccess(deleteSupplementalCostAccessPermission)">
                      <span class="icon"><i class="fal fa-lg fa-minus-circle" v-if="hasFeatureAccess(deleteSupplementalCostAccessPermission)" @click.stop.prevent="onDeleteSupplementalCostClick(supplementalCost, index)" title="Delete"></i></span>
                    </td>
                  </tr>
                  <tr v-if="supplementalCosts.length === 0">
                    <td>None specified</td>
                  </tr>
                </table>
              </div>
              <div class="column is-12 no-h-padding">
                <quick-action-container>
                  <div class="is-inline-block" v-if="showAddSupplementalCost">
                    <div class="quick-action">
                      <button class="button" type="button" @click="addSupplementalCost()" title="Click to add a new supplement cost." >
                        <b-icon pack="fal" icon="plus" custom-size="fa-lg"></b-icon>
                      </button>
                    </div>
                  </div>
                </quick-action-container>
              </div>
            </div>
          </div>
          <div class="box">
            <section-header title="Notes" :overview="buildNotesSectionOverview(currentInventory, 50)" :collapsed="isNotesCollapsed"></section-header>
            <note-timeline-view v-model="currentInventory.notes" entity="inventory"></note-timeline-view>
            <quick-action-container>
              <inventory-note-capture v-if="!isPpaClosed" :inventoryItem="currentInventory" :isListMode="false" :key="currentInventory.id"></inventory-note-capture>
            </quick-action-container>
          </div>

          <vehicle-reports-capture :currentInventory="currentInventory"></vehicle-reports-capture>

          <div class="box" v-if="this.currentInventory.deals.length === 0">
            <section-header :collapsed="isDealsCollapsed" title="Deals"></section-header>
            <div class="column is-12 is-size-7 has-text-info">No deals available at this time</div>
          </div>

          <div :ref="'deal-' + deal.id" class="box" v-for="deal in this.currentInventory.deals" :key="deal.id" v-else>
            <section-header :collapsed="isDealsCollapsed" :title="buildDealSectionTitleForInventory(deal)" :forInventoryView="true" :overview="buildDealSectionOverview(deal, deal.items.filter(x => x.forInventory.id === currentInventory.id && x.dealItemType === 'Sold')[0])"></section-header>
            <div v-for="dealItem in deal.items.filter(x => x.forInventory.id === currentInventory.id)" :key="dealItem.id">
                <div v-if="!dealItem.isPrimary && dealItem.forInventory.id === currentInventory.id && dealItem.dealItemType === 'Sold' && deal.type !== 'Cancelled'" class="notification is-warning backup-deal-qv" :ref="'notification' + dealItem.id">
                  <button class="delete" type="button" @click="onCloseDuplicateNotification('notification' + dealItem.id)"></button>
                  <span>
                    <i class="fas fa-exclamation-triangle is-warning"></i>
                  </span>
                    Warning: Stock# <a @click.stop.prevent="onStockNoClick(dealItem.forInventory)"><span class="has-text-weight-semibold" :title="dealItem.forInventory.stockNo">{{ dealItem.forInventory.stockNo }}</span></a> is sold to {{ primaryDealCustomerName }} by {{ primaryDealSalesPersonName }}. Please note that this is a backup deal for this inventory record.
                    <span v-if="!isEditLocked">Click <a @click="showNotificationModel(dealItem)">here</a> to make this the primary deal.</span>
                </div>
                <div hidden="true" v-else-if="dealItem.isPrimary && dealItem.dealItemType === 'Sold'" class="notification is-success backup-deal-qv" :ref="'notification' + dealItem.id">
                  <button class="delete" type="button" @click="onCloseDuplicateNotification('notification' + dealItem.id)"></button>
                  <span>
                    <i class="fas fa-check-circle is-success"></i>
                  </span>
                  This is the primary deal for this inventory record.
                </div>
                <div v-if="dealItem.dealItemType === 'Trade'" class="notification is-info backup-deal-qv" :ref="'notification' + dealItem.id">
                  <button class="delete" type="button" @click="onCloseDuplicateNotification('notification' + dealItem.id)"></button>
                  <span>
                    <i class="fas fa-info-circle"></i>
                  </span>
                  This vehicle is the trade-in for this deal.
                </div>
            </div>

            <div class="columns is-mobile">
              <div class="column is-6">
                <div class="field">
                  <label class="label">{{deal.saleType === 'Retail' ? 'Customer' : 'Supplier'}}</label>
                  <div class="field">
                    <a class="is-link" @click="onViewDeal(deal)">
                      {{deal.purchaserName}}
                    </a>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Sales Person</label>
                  <div class="field">
                    {{deal.salesPersonName}}
                  </div>
                </div>
              </div>
            </div>
            <div v-if="deal.items.filter((x) => x.dealItemType === 'Sold' && x.forInventory.id === currentInventoryId).length > 0">
              <div class="columns is-mobile is-multiline">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Sale Date</label>
                    <div class="field">
                      {{deal.soldAt | formatDate}}
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-if="showPromisedDate(deal.type)">
                  <div class="field">
                    <label class="label">Expected Delivery Date</label>
                    <div class="field">
                      {{deal.promiseDate | formatDate}}
                    </div>
                  </div>
                </div>
                <div class="column is-6" v-else>
                  <div class="field">
                    <label class="label">Delivered Date</label>
                    <div class="field">
                      {{deal.deliveryDate | formatDate}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Sale Price</label>
                    <div class="field">
                      {{deal.totalSalesValue | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Front GP</label>
                    <div class="field">
                      {{deal.totalFrontValue | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Total GP</label>
                    <div class="field">
                      {{totalGP | currency}}
                    </div>
                  </div>
                </div>
                <div class="column is-narrow">
                  <span class="icon" v-for="trade in deal.items.filter((x) => x.dealItemType === 'Trade')" :key="trade.id">
                    <a class="is-link" @click="onViewTrade(trade)">
                      <i class="fas fa-car fa-lg" :title="'Trade-In: ' + trade.forInventory.name" :class="getColorClassByVehicleCount(deal.totalTrades)"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>
            <div v-else v-for="(dealItem, index) in deal.items.filter(x => x.forInventory.id === currentInventory.id)" :key="index">
              <div class="columns is-mobile">
                <div class="column is-6">
                  <div class="field">
                    <label class="label">Trade Price</label>
                    <div class="field">
                      {{dealItem.salePrice | currency}}
                    </div>
                  </div>
                </div>
              </div>
              <div class="columns" v-if="dealItem.lineComment !== null">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Note</label>
                    <div class="field">
                      {{dealItem.lineComment}}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div :ref="'deal-' + tradeSourceDeal.id" class="box" v-if="isTradeLinkedToDeal">
            <section-header v-tooltip="'Trade Source Deal - ' + currentInventory.tradeSourcePurchaserFullName" :title="buildDealSectionTitleForInventoryTrade('Traded Deal')" :forInventoryView="true" :overview="buildDealSectionOverview(tradeSourceDeal, tradeSourceDeal.primaryDealItem)"></section-header>
            <div class="columns is-mobile">
              <div class="column is-6">
                <div class="field">
                  <label class="label">Trade Source Inventory</label>
                  <div class="field">
                    <a class="is-link" @click="onViewTrade(tradeSourceDeal.primaryDealItem)">{{currentInventory.tradeSourceName}}</a>
                  </div>
                </div>
              </div>
              <div class="column is-6">
                <div class="field">
                  <label class="label">Trade Source Purchaser</label>
                  <div class="field">
                    <a class="is-link" @click="onViewDeal(tradeSourceDeal)">{{tradeSourceDeal.purchaserName}}</a>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="box" ref="accessoriesPod">
            <section-header :collapsed="isAccessoriesCollapsed" :title="buildAccessorySectionTitle(currentInventory)" :forInventoryView="true" :overview="buildAccessorySectionOverview(currentInventory)"></section-header>
            <div class="columns is-multiline">
              <div class="column is-8">
                <p v-html="accessoryOverview"></p>
              </div>
              <div class="column is-4 side-padding-sm">
                <p class="is-marginless is-paddingless is-pulled-right subtitle is-7">showing top {{ this.$store.state.config.maxAccessoryEvents }}</p>
              </div>
              <div class="column is-12 no-h-padding">
                <b-table
                  :data="this.currentInventory.accessoryEvents"
                  :sortable="false"
                  :mobile-cards="false"
                  :selecteable="false"
                  :row-class="() => 'accesory-event-row'">
                  <template slot-scope="props">
                    <b-table-column field="type" label="Status" class="accesory-event-column">
                      <i class="icon fal fa-key fa-sm" :class="getColorClassByAccessoryStatus(props.row.typeId)" title="Accessory Status"/>
                      {{ props.row.type == 'CheckedOut' ? 'Out' : 'In' }}
                    </b-table-column>

                    <b-table-column field="binsDescription" label="Bins" class="accesory-event-column" :title="props.row.binsDescription">
                      {{ (props.row.binsDescription !== '' ? props.row.binsDescription : 'n/a') | truncate(10) }}
                    </b-table-column>

                    <b-table-column field="givenTo" label="Given To" class="accesory-event-column">
                      {{ props.row.givenTo !== '' ? props.row.givenTo : 'n/a' }}
                    </b-table-column>

                    <b-table-column field="createdAt" label="Created" class="accesory-event-column">
                      {{ props.row.createdAt | formatDateOnly }}
                    </b-table-column>

                    <b-table-column field="createdBy" label="Creator" class="accesory-event-column">
                      {{ props.row.createdBy}}
                    </b-table-column>

                    <b-table-column field="id" label="View" class="accesory-event-column">
                      <accessory-event-view :currentInventory="currentInventory" :accessoryEvent="props.row"></accessory-event-view>
                    </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="has-text-centered">
                        <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                        <p>No accessory events recorded for this vehicle.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
                <br />
                <a class="show-more-link link is-size-7 is-pulled-right" @click="onShowMoreAccessoryEvents" v-if="hasMoreAccessoryEvents">view all</a>
              </div>
            </div>
            <quick-action-container>
              <accessory-event-capture v-if="!isPpaClosed" :currentInventory="currentInventory"></accessory-event-capture>
            </quick-action-container>
          </div>

          <div class="box" ref="serviceRequestPod" style="padding-left: 1rem !important;padding-right: 1rem !important" v-if="currentDealer.trackWorkshopInfo === true && currentInventory.possessionStatus !== 'Locating'">
            <section-header :collapsed="isWorkShopCollapsed" :title="buildWorkshopSectionTitle(currentInventory)" :forInventoryView="true" :overview="buildWorkshopSectionOverview(currentInventory, true)"></section-header>
            <div v-if="hasWorks">
              <div class="columns is-multiline">
                <div class="column is-12 no-h-padding">
                  <b-table
                    :data="this.currentInventory.serviceRequests"
                    :sortable="false"
                    :selecteable="false"
                    :mobile-cards="false"
                    @select="onServiceRequestRowSelected"
                    @dblclick="onServiceRequestRowSelected">
                    :row-class="() => 'service-request-row'">
                    <template slot-scope="props">
                      <b-table-column field="requestStatusType" label="Status" class="service-request-column">
                        <i class="icon fal fa-layer-plus fa-lg" :class="getColorClassByGetReadyStatus(props.row.requestStatusTypeId)" title="Status" v-if="props.row.requestStatusTypeId === 0"/>
                        <i class="icon fal fa-wrench fa-lg" :class="getColorClassByGetReadyStatus(props.row.requestStatusTypeId)" title="Status" v-else/>
                        {{ requestStatusType(props.row.requestStatusType) }}
                      </b-table-column>

                      <b-table-column field="serviceRequestType" label="Type" class="service-request-column">
                        {{ requestType(props.row.serviceRequestType) }}
                      </b-table-column>

                      <b-table-column field="pendingCosts" label="Pending" class="service-request-column has-text-right" :title="props.row.pendingCosts | currency">
                        {{ props.row.pendingCosts | currency }}
                      </b-table-column>

                      <b-table-column field="approvedCosts" label="Approved" class="service-request-column has-text-right" :title="props.row.approvedCosts | currency">
                        {{ props.row.approvedCosts | currency }}
                      </b-table-column>
                    </template>
                    <template slot="empty">
                      <section class="section">
                        <div class="has-text-centered">
                          <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                          <p>No service requests recorded for this vehicle.</p>
                        </div>
                      </section>
                    </template>
                    <template slot="footer" v-if="this.currentInventory.serviceRequests.length > 0">
                      <div class="is-flex" style="justify-content: space-evenly">
                        <span class="tag is-black">Declined: {{ totalDeclinedServiceRequestCosts | currency }}</span>
                        <span class="tag is-warning">Pending: {{ totalPendingServiceRequestCosts | currency }}</span>
                        <span class="tag is-primary">Approved: {{ totalApprovedServiceRequestCosts | currency}}</span>
                      </div>
                  </template>
                  </b-table>
                  <br />
                  <a class="show-more-link link is-size-7 is-pulled-right" @click="onShowMoreServiceRequests">view all</a>
                </div>
              </div>
              <quick-action-container>
                <service-request-capture v-if="!isPpaClosed" :currentInventory="currentInventory"></service-request-capture>
              </quick-action-container>
            </div>
            <div v-else class="column is-12 is-size-7 has-text-info">No information available at this time</div>
          </div>

          <div class="box" ref="loanerPod" v-if="currentDealer.trackLoanerInfo === true && currentInventory.possessionStatus !== 'Locating'">
            <section-header :collapsed="isLoanersCollapsed" :title="buildLoanerSectionTitle(currentInventory)" :forInventoryView="true" :overview="buildLoanerSectionOverview(currentInventory)"></section-header>
            <inventory-loaner-event-container :currentInventory="currentInventory" :allowService="true" v-if="hasLoaners"></inventory-loaner-event-container>
          </div>

          <div class="box" v-if="currentDealer.trackWorkshopInfo === true && hasServiceRequests && currentInventory.possessionStatus !== 'Locating'">
            <section-header :collapsed="isWorkShopCollapsed" title="Service Request"></section-header>
            <div class="columns" v-if="hasServiceRequests">
            </div>
            <div v-else class="column is-12 is-size-7 has-text-info">No information available at this time</div>
          </div>

          <div class="box">
            <section-header :collapsed="true" title="Sales Videos"></section-header>
            <b-table
              :data="currentInventory.salesVideos"
              :sortable="false"
              :selecteable="false"
              :mobile-cards="false">
              :row-class="() => 'service-request-row'">
              <template slot-scope="props">
                <b-table-column field="createdBy" label="Created By">
                  {{ props.row.createdByCompact }}
                </b-table-column>

                <b-table-column field="createdAt" label="Created At">
                  {{ props.row.createdAt | formatDateOnly }}
                </b-table-column>

                <b-table-column field="id" label="Actions">
                  <video-player-control :url="props.row.fileLocation" :title="'Created by ' + props.row.createdBy"></video-player-control>
                  <span ref="salesVideosImageViewer" class="icon" v-viewer.static="{inline: false, title: true}">
                    <i class="fal fa-lg fa-images" title="View Images" @click="onViewSalesVideoImages"></i>
                    <img v-for="attachment in props.row.attachments" :key="attachment.id" :src="attachment.fileLocation" :alt="attachment.description" style="display:none"/>
                  </span>
                  <span class="icon"><i class="fal fa-lg fa-download" @click.stop.prevent="onDownloadSalesVideo(props.row)" title="Download"></i></span>
                  <inventory-media-request-action :isListMode="true" v-if="!currentInventory.sold" :inventorySalesVideoItem="props.row"></inventory-media-request-action>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="has-text-centered">
                    <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                    <p>No sales videos created for this vehicle.</p>
                  </div>
                </section>
              </template>
            </b-table>
          </div>

          <service-video-container :vinNo="currentInventory.vinNo" v-if="currentInventory && currentInventory.vinNo"></service-video-container>

          <div class="box" v-if="currentDealer.trackWorkshopInfo === true && hasServiceRequests && currentInventory.possessionStatus !== 'Locating'">
            <section-header :collapsed="isWorkShopCollapsed" title="Service Request"></section-header>
            <div class="columns" v-if="hasServiceRequests">
            </div>
            <div v-else class="column is-12 is-size-7 has-text-info">No information available at this time</div>
          </div>

          <div class="box" ref="saveGeneralFilesContainer">
            <section-header title="General Files" :collapsed="true"></section-header>
            <div class="field">
              <div class="field">
                <file-selector
                  v-model="inventoryMiscellaneousFiles"
                  label="Miscellaneous Files"
                  :enabled="true"
                  :multiple="true"
                  :showFileOnly="false"
                  :isBoxed="false"
                  :formatImages="true"
                  :usePortalViewer="true">
                </file-selector>
              </div>
            </div>
            <div>
              <div class="control quick-action" v-show="showGeneralFilesSave">
                <button :class="{'is-loading': isSavingFile }" class="button is-success" @click.prevent="onSyncGeneralFiles()" title="Save">
                  <b-icon pack="fal" icon="upload" custom-size="fa-lg"></b-icon>
                </button>
              </div>
            </div>
          </div>

          <div class="box">
            <section-header title="Update History" :collapsed="isAuditCollapsed"></section-header>
            <br />
            <div class="columns is-mobile is-multiline">
              <div class="column is-6">
                <label class="label">Created By:</label>
                <div class="field">
                  {{ currentInventory.createdByFullName }}
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Created At:</label>
                <div class="field">
                  {{ currentInventory.createdAt | formatDateOnly }}
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Modified By:</label>
                <div class="field">
                  {{ currentInventory.modifiedByFullName }}
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Modified At:</label>
                <div class="field">
                  {{ currentInventory.modifiedAt | formatDateOnly }}
                </div>
              </div>
            </div>
          </div>

          <div class="box" ref="publishStatusHistoryPod">
            <section-header :collapsed="isPublishStatusHistoryCollapsed" title="Publish Status History" :forInventoryView="false" overview="" v-on:collapsed-status-changed="onPublishStatusHistoryCollapsedStatusChanged"></section-header>
            <div class="columns is-multiline">
              <div class="column is-12 no-h-padding">
                <b-table
                  :data="this.inventoryPublishHistoryStatus"
                  :sortable="false"
                  :mobile-cards="false"
                  :selecteable="false"
                  :row-class="() => 'accesory-event-row'">
                  <template slot-scope="props">

                    <b-table-column field="createdBy" label="Modified By" class="accesory-event-column" :title="props.row.createdBy">
                      {{ (props.row.createdBy) }}
                    </b-table-column>

                    <b-table-column field="publishStatus" label="Do Not Publish" sortable>
                      {{ props.row.publishStatus ? 'YES' : 'NO' }}
                    </b-table-column>

                    <b-table-column field="createdAt" label="Date" class="accesory-event-column">
                      {{ props.row.createdAt | formatDateOnly }}
                    </b-table-column>

                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="has-text-centered">
                        <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                        <p>No publish status history changes recorded for this vehicle.</p>
                      </div>
                    </section>
                  </template>
                </b-table>
                <br />
                <a class="show-more-link link is-size-7 is-pulled-right" @click="onShowMoreAccessoryEvents" v-if="hasMoreAccessoryEvents">view all</a>
              </div>
            </div>
          </div>

          <div class="box">
            <section-header title="Cdk Integration Status" :collapsed="isCdkCollapsed"></section-header>
            <div class="columns is-mobile is-multiline">
              <div class="column is-4">
                <label class="label">Cdk Id:</label>
                <div class="field">
                  {{ currentInventory.cdkId || "Pending" }}
                </div>
              </div>
              <div class="column is-4">
                <label class="label">Cdk Hash:</label>
                <div class="field">
                  {{ currentInventory.cdkHash || "Pending" }}
                </div>
              </div>
              <div class="column is-4">
                <label class="label">Sync Pending:</label>
                <div class="field">
                  {{ currentInventory.cdkSyncRequired || currentInventory.cdkHash === '' }}
                </div>
              </div>
              <div class="column is-8" v-if="currentInventory.cdkLastError !== ''">
                <label class="label">Cdk Last Error:</label>
                <div class="field">
                  <span class=" has-text-danger">{{ currentInventory.cdkLastError}}</span>
                </div>
              </div>
              <div class="column is-4" v-if="currentInventory.cdkLastError !== ''">
                <label class="label">Reset</label>
                <i class="icon fal fa-sm fa-sync-alt" :class="{'fa-spin': isResettingCdkError}" title="Reset error and retry Cdk update" @click="onResetCdkError"/>
              </div>
            </div>
            <quick-action-container>
              <div class="is-inline-block">
                <div class="quick-action">
                  <button class="button" type="button" @click="markForceCdkPush" title="Click to force CDK push." >
                    <b-icon pack="fal" icon="random" custom-size="fa-lg" :class="{'fa-spin': isSaving}"></b-icon>
                  </button>
                </div>
              </div>
            </quick-action-container>
          </div>

          <div class="box">
            <section-header title="vAuto Integration Status" :collapsed="isVautoCollapsed"></section-header>
            <div class="columns is-mobile is-multiline">
              <div class="column is-6">
                <label class="label">Last Appraisal:</label>
                <div class="field">
                  <a target="_vauto" :href="currentInventory.vAutoAppId" v-if="hasVautoAppraisal">view</a>
                  <span v-else>Pending</span>
                </div>
              </div>
              <div class="column is-6">
                <label class="label">Sync Pending:</label>
                <div class="field">
                  {{ currentInventory.vAutoSyncRequired || currentInventory.vAutoAppId === '' }}
                </div>
              </div>
              <div class="column is-8" v-if="currentInventory.vAutoLastError !== ''">
                <label class="label">Last Error:</label>
                <div class="field">
                  <span class=" has-text-danger">{{ currentInventory.vAutoLastError}}</span>
                </div>
              </div>
              <div class="column is-4" v-if="currentInventory.vAutoLastError !== ''">
                <label class="label">Reset</label>
                <i class="icon fal fa-sm fa-sync-alt" :class="{'fa-spin': isResettingvAutoError}" title="Reset error and retry vAuto sync" @click="onResetvAutoError"/>
              </div>
            </div>
          </div>

          <portal :to="actionPortal">
            <div class="field is-grouped">
              <p class="field">
                <a class="button is-danger" @click="onCancel()">
                  Close
                </a>
              </p>
              <p class="field" v-if="hasFeatureAccess('inventory.modify') && isEditLocked && !isPpaClosed">
                <a class="button is-primary" @click="onEdit()">
                  Edit
                </a>
              </p>
              <p class="field" v-if="hasFeatureAccess('inventory.modify') && !isEditLocked">
                <a class="button is-primary" @click="savePricing()">
                  Save
                </a>
              </p>
              <div class="quick-action-list">
                <pod-lock-control v-if="!isPpaClosed" v-model="isEditLocked" :bypassPermissions="['inventory.modify.*', 'inventory.pricing.modify']"></pod-lock-control>
                <inventory-note-capture v-if="!isPpaClosed" :inventoryItem="currentInventory" :isListMode="false"></inventory-note-capture>
                <div class="quick-action" v-if="currentInventory.inStock && allowedDealInventoryTypes.find((x) => x.altName === currentInventory.type) && !isPreEntry">
                  <button class="button" @click="onAddDeal()" title="Add a new deal to this inventory record">
                    <i :class="getDealColorClass" class="icon fal fa-handshake fa-lg" :data-type="getDealDataType"/>
                  </button>
                </div>
                <div class="quick-action" v-if="currentInventory.type === 'ForPpaAppraisal' && !isPreEntry">
                  <button class="button" @click="onAddInventoryTradeToDeal()" title="Add a new deal to this inventory record">
                    <i :class="getDealColorClass" class="icon fal fa-handshake fa-lg" :data-type="getDealDataType"/>
                  </button>
                </div>
                <div class="quick-action" v-if="currentInventory.type === 'TradeIn' && !isPreEntry">
                  <button class="button" @click="isCreateDealActive = true" title="Add a new deal to this inventory record">
                    <i :class="getDealColorClass" class="icon fal fa-handshake fa-lg" :data-type="getDealDataType"/>
                  </button>
                </div>
                <mark-returned-action :inventoryItem="currentInventory" title=""></mark-returned-action>
                <archive-inventory-action :inventoryItem="currentInventory" v-if="showInventoryArchiveAction"></archive-inventory-action>
                <retire-service-loaner-action :inventoryItem="currentInventory"></retire-service-loaner-action>
                <convert-ppa-to-purchase-action :inventory="currentInventory" ref="convertPpaAction" v-if="showPpaConversionButton" v-on:on-mounted-callback="onConvertPpaActionMounted"></convert-ppa-to-purchase-action>
                <convert-consigned-to-purchase-action :inventoryItem="currentInventory" v-if="isTypeConsigned"></convert-consigned-to-purchase-action>
                <close-ppa-action :inventoryItem="currentInventory" v-if="showPpaCloseButton"></close-ppa-action>
                <approve-ppa-action :inventoryItem="currentInventory" ref="approvePpaAction" v-on:on-mounted-callback="onApprovePpaActionMounted"></approve-ppa-action>
                <convert-pre-entry-action :inventoryItem="currentInventory" v-if="isPreEntry"></convert-pre-entry-action>
                <convert-lease-return-action :inventoryItem="currentInventory" v-if="isLeaseReturn && !isArchived"></convert-lease-return-action>
              </div>
            </div>
          </portal>
        </div>
        <portal to="global-modal-portal" v-if="inventoryDealIsPrimaryActive">
          <b-modal :active.sync="inventoryDealIsPrimaryActive" :width="640" scroll="keep" :has-modal-card="true">
            <div class="modal-card make-deal-primary" style="width: auto">
              <header class="modal-card-head" style="width: 400px !important">
                <p class="modal-card-title">Warning</p>
              </header>
              <section class="modal-card-body make-deal-primary view-container">
                Do you want to make this deal the primary deal for this inventory record?
              </section>
              <footer class="modal-card-foot" style="width: 400px !important">
                <button class="button is-primary" :class="{'is-loading': isSaving }"  @click="onConfirmInventoryDealIsPrimary(true)" type="button">Confirm</button>
                <button class="button is-danger" @click="onConfirmInventoryDealIsPrimary(false)" type="button">Cancel</button>
              </footer>
            </div>
          </b-modal>
        </portal>
      </div>
      <b-loading :is-full-page="isFullPage" :active.sync="isLoading" :canCancel="false"></b-loading>
      <dealer-peak-lookup v-if="currentInventory.purchasedFromType === 'Individual'"></dealer-peak-lookup>
      <portal to="global-modal-portal" v-if="isPublishStatusChangeActive">
        <b-modal :active.sync="isPublishStatusChangeActive" :width="480" scroll="keep" :has-modal-card="true">
          <div class="modal-card confirm">
            <header class="modal-card-head" style="width: 480px !important">
              <p class="modal-card-title">Confirm Publish Status Change</p>
            </header>
            <section class="modal-card-body confirm">
              Are you sure you want to change publish status?
            </section>
            <footer class="modal-card-foot" style="width: 480px !important">
              <button class="button is-primary" :class="{'is-loading': isSavingNewPublishStatus }" @click="onPublishStatusChanged()">Yes</button>
              <button class="button is-danger" @click="onCancelPublishStatusChange">No</button>
            </footer>
          </div>
        </b-modal>
      </portal>
      <portal to="global-modal-portal" v-if="isConfirmRemoveImagesActive">
        <b-modal :active.sync="isConfirmRemoveImagesActive" :width="480" scroll="keep" :has-modal-card="true">
          <div class="modal-card confirm">
            <header class="modal-card-head" style="width: 480px !important">
              <p class="modal-card-title">Confirm Removal of Images</p>
            </header>
            <section class="modal-card-body confirm">
              Are you sure you want to remove all vehicle images?
            </section>
            <footer class="modal-card-foot" style="width: 480px !important">
              <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onConfirmImageRemoval()">Yes</button>
              <button class="button is-danger" @click="isConfirmRemoveImagesActive = false">No</button>
            </footer>
          </div>
        </b-modal>
      </portal>
      <portal to="global-modal-portal" v-if="isEditingLocation">
        <b-modal :active.sync="isEditingLocation" :width="480" scroll="keep" :has-modal-card="true">
          <div class="modal-card location">
            <header class="modal-card-head" style="width: 480px !important">
              <p class="modal-card-title">Confirm New Location</p>
            </header>
            <section class="modal-card-body location view-container">
              <div class="columns is-multiline is-vertical-center">
                <div class="column is-10">
                  <label class="label">Current</label>
                  <div class="field">
                    {{ currentInventory.locationName || '-' }}
                  </div>
                </div>
                <div class="column is-10">
                  <location-selector v-model="selectedLocation" label="New Location" validateAs="vehicle location" :required="false" :allowNew="false"></location-selector>
                </div>
                <div class="column is-2">
                  <i v-if="isSavingNewLocation" class="fas fa-spinner fa-spin fa-lg has-text-success" title="Saving..."></i>
                </div>
              </div>
            </section>
            <footer class="modal-card-foot" style="width: 480px !important">
              <button class="button is-primary" :class="{'is-loading': isSavingNewLocation }" @click="onSaveNewLocation">Update</button>
              <button class="button is-danger" @click="onCancelEditLocation">Cancel</button>
            </footer>
          </div>
        </b-modal>
      </portal>
      <portal to="global-modal-portal">
        <inventory-supplement-cost-capture
          :value="this.newSupplementalCost"
          :supplementalCosts="this.supplementalCosts"
          :inventoryId="this.currentInventory.id"
          :saveNewCost="true"
          :allowDelete="true"
          v-on:on-save-supplemental-cost="onSupplementalCostAdded"
          v-on:on-delete-supplemental-cost="onDeleteSupplementalCostClick">
        </inventory-supplement-cost-capture>
      </portal>
      <portal to="global-modal-portal" v-if="isCreateDealActive">
        <b-modal :active.sync="isCreateDealActive" :width="480" scroll="keep" :has-modal-card="true">
          <div class="modal-card confirm">
            <header class="modal-card-head" style="width: 480px !important">
              <p class="modal-card-title">Add Deal</p>
            </header>
            <section class="modal-card-body confirm" style="padding: 0px !important;">
              <div class="notification is-flex is-link">
                <span><i class="fas fa-exclamation-circle is-warning fa-2x"></i></span>
                  <span class="ml-10">
                    Create a new deal for this inventory record. Please select if this vehicle is being sold or traded as part of the deal.
                  </span>
              </div>
            </section>
            <footer class="modal-card-foot" style="width: 480px !important">
              <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onAddDeal()">Inventory Sold</button>
              <button class="button is-primary" :class="{'is-loading': isSaving }" @click="onAddInventoryTradeToDeal()">Inventory Traded In</button>
              <button class="button is-danger" @click="isCreateDealActive = false">Cancel</button>
            </footer>
          </div>
        </b-modal>
      </portal>
      <portal to="global-modal-portal">
        <b-modal :active.sync="isDeleteConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" v-if="isDeleteConfirmationActive">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">Confirm Delete Supplemental Cost</p>
          </header>
          <section class="modal-card-body">
            <p>
              Are you sure you wish to delete the selected supplemental cost?
            </p>
            <br/>
            <div>
              <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
                <thead v-if="deleteSupplementalCosts.length > 0">
                  <tr>
                    <th>Name</th>
                    <th>Cost</th>
                    <th>Selling Price</th>
                  </tr>
                </thead>
                <tr v-for="(supplementalCost) in deleteSupplementalCosts" :key="supplementalCost.id">
                  <td>{{supplementalCost.name}}</td>
                  <td>{{supplementalCost.cost | currency}}</td>
                  <td>{{supplementalCost.sellingPrice | currency}}</td>
                </tr>
                <tr v-if="deleteSupplementalCosts.length === 0">
                  <td>None specified</td>
                </tr>
              </table>
            </div>
          </section>
          <footer class="modal-card-foot">
            <a class="button is-primary" @click="onSupplementalCostDelete">Yes</a>
            <a class="button is-danger" @click="isDeleteConfirmationActive = false">No</a>
          </footer>
        </div>
      </b-modal>
      </portal>
    </section>
</template>

<script>
import _ from 'lodash'
import axios from 'axios'
import eventBus from '@/eventBus'
import SectionHeader from '@/components/generic/SectionHeader'
import NoteTimelineView from '@/components/generic/NoteTimelineView'
import inventoryService from '@/services/inventoryService'
import dealService from '@/services/dealService'
import CaptureNewInventoryNote from '@/components/inventory/CaptureNewInventoryNote'
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import PodLockControl from '@/components/generic/PodLockControl'
import MarkReturnedAction from '@/components/inventory/MarkReturnedAction'
import FieldRotator from '@/components/generic/FieldRotator'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import ArchiveInventoryAction from '@/components/inventory/ArchiveInventoryAction'
import CarFaxControl from '@/components/generic/CarFaxControl'
import RetireServiceLoanerAction from '@/components/inventory/RetireServiceLoanerAction'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import UsageHistoryComponent from '@/components/inventory/UsageHistoryComponent'
import InventoryPricingHistoryComponent from '@/components/inventory/InventoryPricingHistoryComponent'
import InventoryDispositionIntentionHistoryComponent from '@/components/inventory/InventoryDispositionIntentionHistoryComponent'
import InventorySalesDescriptionComponent from '@/components/inventory/InventorySalesDescriptionComponent'
import InventoryAttributeView from '@/components/inventory/InventoryAttributeView'
import InventoryAppraisalControl from '@/components/generic/InventoryAppraisalControl'
import PdfSelector from '@/components/generic/PdfSelector'
import QuickActionContainer from '@/components/generic/QuickActionContainer'
import InventorySellersAgreementAction from '@/components/inventory/InventorySellersAgreementAction'
import InventoryConsignmentAgreementAction from '@/components/inventory/InventoryConsignmentAgreementAction'
import InventoryBatchPaymentRequest from '@/components/inventory/InventoryBatchPaymentRequestAction'
import InventoryVehicleVerificationAction from '@/components/inventory/InventoryVehicleVerificationAction'
import InventoryTitleCapture from '@/components/generic/inventory/InventoryTitleCapture'
import InventoryWindowStickerCapture from '@/components/generic/inventory/InventoryWindowStickerCapture'
import VehicleReportsCapture from '@/components/inventory/VehicleReportsCapture'
import AccessoryEventCapture from '@/components/generic/inventory/AccessoryEventCapture'
import AccessoryEventView from '@/components/generic/inventory/AccessoryEventView'
import ServiceRequestCapture from '@/components/generic/inventory/ServiceRequestCapture'
import formattingMixin from '@/mixins/inventory/formatting'
import InventoryLoanerEventContainer from '@/components/generic/inventory/InventoryLoanerEventContainer'
import SendPickupLocationControl from '@/components/generic/SendPickupLocationControl'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import TagView from '@/components/generic/TagView'
import DealerPeakControl from '@/components/generic/DealerPeakControl'
import DealerPeakLookup from '@/components/generic/DealerPeakLookup'
import ConvertPpaToPurchaseAction from '@/components/inventory/ConvertPpaToPurchaseAction'
import ConvertConsignedToPurchaseAction from '@/components/inventory/ConvertConsignedToPurchaseAction'
import ClosePpaAction from '@/components/inventory/ClosePpaAction'
import ApprovePpaAction from '@/components/inventory/ApprovePpaAction'
import ConvertPreEntryToActiveAction from '@/components/inventory/ConvertPreEntryToActiveAction'
import ConvertLeaseReturnToUsedAction from '@/components/inventory/ConvertLeaseReturnToUsedAction'
import CaptureInventoryTags from '@/components/inventory/CaptureInventoryTags'
import AutoCheckControl from '@/components/generic/AutoCheckControl'
import utilitiesMixin from '@/mixins/generic/utilities'
import FileSelector from '@/components/generic/FileSelector'
import LocationSelector from '@/components/generic/LocationSelector'
import InventorySupplementalCostCapture from '@/components/generic/sales/InventorySupplementalCostCapture'
import InventoryLostTitleAction from '@/components/inventory/InventoryLostTitleAction.vue'
import LeadTypeSelector from '@/components/generic/LeadTypeSelector'
import InventoryPayoffControl from '@/components/generic/inventory/InventoryPayoffControl'
import InventoryLoanerRemoveServiceAction from '@/components/generic/inventory/InventoryLoanerRemoveServiceAction'
import InventoryBlackBookControl from '@/components/generic/InventoryBlackBookControl'
import InventoryDeskDeal from '@/components/generic/inventory/InventoryDeskDeal'
import VideoPlayerControl from '@/components/generic/VideoPlayerControl.vue'
import InventoryMediaRequestAction from '@/components/inventory/InventoryMediaRequestAction'
import SendTradeInTitleReassignmentDocument from '@/components/generic/sales/SendTradeInTitleReassignmentDocument'
import ServiceVideoContainer from '@/components/generic/ServiceVideoContainer'

import { Steps, Step } from 'element-ui'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'ViewInventoryItem',
  inject: {
    $validator: '$validator'
  },
  mixins: [humanizedSwitchMixin, validateBeforeSubmitMixin, dealInventoryStyleClasses, formattingMixin, utilitiesMixin],
  components: {
    'video-player-control': VideoPlayerControl,
    'inventory-sellers-agreement': InventorySellersAgreementAction,
    'inventory-consignment-agreement': InventoryConsignmentAgreementAction,
    'inventory-batch-payment-request': InventoryBatchPaymentRequest,
    'inventory-vehicle-verification': InventoryVehicleVerificationAction,
    'section-header': SectionHeader,
    'button-list-selector': ButtonListSelector,
    'car-fax-control': CarFaxControl,
    'note-timeline-view': NoteTimelineView,
    'inventory-note-capture': CaptureNewInventoryNote,
    'mark-returned-action': MarkReturnedAction,
    'pod-lock-control': PodLockControl,
    'inventory-appraisal-control': InventoryAppraisalControl,
    'field-rotator': FieldRotator,
    'archive-inventory-action': ArchiveInventoryAction,
    'retire-service-loaner-action': RetireServiceLoanerAction,
    'usage-history-component': UsageHistoryComponent,
    'inventory-attribute-view': InventoryAttributeView,
    'pdf-selector': PdfSelector,
    'quick-action-container': QuickActionContainer,
    'el-steps': Steps,
    'el-step': Step,
    'title-status-capture': InventoryTitleCapture,
    'inventory-pricing-history-component': InventoryPricingHistoryComponent,
    'inventory-disposition-intention-history-component': InventoryDispositionIntentionHistoryComponent,
    'inventory-sales-description-component': InventorySalesDescriptionComponent,
    'window-stick-status-capture': InventoryWindowStickerCapture,
    'vehicle-reports-capture': VehicleReportsCapture,
    'accessory-event-capture': AccessoryEventCapture,
    'accessory-event-view': AccessoryEventView,
    'inventory-loaner-event-container': InventoryLoanerEventContainer,
    'service-request-capture': ServiceRequestCapture,
    'send-pickup-location-control': SendPickupLocationControl,
    'error-display-component': ErrorDisplayComponent,
    'tag-view': TagView,
    'dealer-peak-control': DealerPeakControl,
    'dealer-peak-lookup': DealerPeakLookup,
    'convert-ppa-to-purchase-action': ConvertPpaToPurchaseAction,
    'convert-consigned-to-purchase-action': ConvertConsignedToPurchaseAction,
    'close-ppa-action': ClosePpaAction,
    'approve-ppa-action': ApprovePpaAction,
    'convert-pre-entry-action': ConvertPreEntryToActiveAction,
    'convert-lease-return-action': ConvertLeaseReturnToUsedAction,
    'inventory-tags-capture': CaptureInventoryTags,
    'auto-check-control': AutoCheckControl,
    'file-selector': FileSelector,
    'location-selector': LocationSelector,
    'inventory-supplement-cost-capture': InventorySupplementalCostCapture,
    'inventory-lost-title': InventoryLostTitleAction,
    'lead-type-selector': LeadTypeSelector,
    'inventory-payoff-control': InventoryPayoffControl,
    'service-loaner-out-of-service-action': InventoryLoanerRemoveServiceAction,
    'inventory-black-book-control': InventoryBlackBookControl,
    'inventory-desk-deal': InventoryDeskDeal,
    'inventory-media-request-action': InventoryMediaRequestAction,
    'send-trade-in-title-reassignment-documentation': SendTradeInTitleReassignmentDocument,
    'service-video-container': ServiceVideoContainer
  },
  props: {
    id: {
      type: Number,
      default: null
    },
    isLoanerPod: {
      type: Boolean,
      default: true
    },
    actionPortal: {
      type: String,
      default: 'qv-footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: 'qv-header'
    }
  },
  data () {
    return {
      isLoaner: true,
      isLoading: false,
      isResettingCdkError: false,
      isResettingvAutoError: false,
      isSavingNewDispositionIntention: false,
      isSavingNewPublishStatus: false,
      isFullPage: true,
      currentInventoryId: this.id,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      isEditLocked: true,
      selectedCommitmentFrom: null,
      daysList: [],
      expirationList: [],
      locationList: [],
      tagsList: [],
      selectedAccessoryStatus: null,
      selectedGetReadyStatus: null,
      selectedLoanerStatus: null,
      currentInventory: {
        id: 0,
        vinNo: '',
        stockNo: '',
        notes: []
      },
      loadingSign: false,
      currentDeal: null,
      selectedDealItem: null,
      inventoryDealIsPrimaryActive: false,
      selectedDealer: null,
      isDetailsCollapsed: false,
      isPricingCollapsed: true,
      isLoanersCollapsed: true,
      isDealsCollapsed: true,
      isAccessoriesCollapsed: true,
      isWorkShopCollapsed: true,
      isAuditCollapsed: true,
      isCdkCollapsed: true,
      isVautoCollapsed: true,
      isNotesCollapsed: true,
      selectedPossessionStatus: null,
      isOffersCollapsed: true,
      dispositionIntentionValue: undefined,
      publishStatusValue: undefined,
      isPublishStatusChangeActive: false,
      isConfirmRemoveImagesActive: false,
      inventoryPurchaseOffers: [],
      inventoryPurchaseOffersFetched: false,
      isPublishStatusHistoryCollapsed: true,
      isPublishStatusHistoryFetched: false,
      inventoryPublishHistoryStatus: [],
      offersLoading: false,
      selectedOfferType: 'All',
      inventoryLeads: [],
      inventoryLeadsFetched: false,
      leadsLoading: false,
      filteredPurchaseOffers: [],
      offersAndLeadsTabIndex: 0,
      supplementalCosts: [],
      isLocationCollapsed: false,
      updateInventoryLocationPermission: 'inventory.view.update.location',
      isEditingLocation: false,
      isSavingNewLocation: false,
      newSupplementalCost: this.createGenericSupplementalCost(),
      addInventorySupplementalCost: 'inventory.view.add.supplemental.cost',
      showLostTitleAction: false,
      offerPodIndex: -1,
      selectedLeadTypes: [0],
      hasOfferAmount: false,
      convertedInventoryId: null,
      isCreateDealActive: false,
      deleteSupplementalCostAccessPermission: 'inventory.view.delete.supplemental.cost',
      currentDeleteSupplementalcost: null,
      isDeleteConfirmationActive: false,
      deleteSupplementalCosts: [],
      showGeneralFilesSave: false,
      inventoryMiscellaneousFiles: [],
      initialInventoryMiscellaneousFiles: [],
      isSavingFile: false,
      syncFileTypes: []
    }
  },
  computed: {
    totalDeclinedServiceRequestCosts: function () {
      return this.currentInventory.serviceRequests.reduce((total, item) => {
        return total + (item.requestStatusType === 'Cancelled' || item.requestStatusType === 'Declined' ? 0 : item.declinedCosts)
      }, 0)
    },
    totalPendingServiceRequestCosts: function () {
      return this.currentInventory.serviceRequests.reduce((total, item) => {
        return total + (item.requestStatusType === 'Cancelled' || item.requestStatusType === 'Declined' ? 0 : item.pendingCosts)
      }, 0)
    },
    totalApprovedServiceRequestCosts: function () {
      return this.currentInventory.serviceRequests.reduce((total, item) => {
        return total + (item.requestStatusType === 'Cancelled' || item.requestStatusType === 'Declined' ? 0 : item.approvedCosts)
      }, 0)
    },
    isTradeLinkedToDeal: function () {
      return this.isTypeTrade && this.currentInventory.tradeSourceDealId && this.currentInventory.tradeSourceDealId > 0
    },
    purchaseCostLabel: function () {
      if (this.isTypeTrade) return 'Purchase Cost/ACV'
      return 'Purchase Cost'
    },
    titleIsLost: function () {
      if (this.currentInventory) {
        return this.currentInventory.titleStatus === 'Lost'
      }
      return false
    },
    filteredLeads: function () {
      let filterSelectTypes = this.definedTypes.leadType.options.filter((el) => {
        return this.selectedLeadTypes.some((f) => {
          return f === el.id
        })
      })
      if (filterSelectTypes.length > 0) {
        let list = filterSelectTypes.map(function (x) {
          return x.altName
        })
        if (list.length > 0) {
          let filteredInventoryLeads = this.inventoryLeads.filter((el) => {
            return list.some((f) => {
              return f === el.group
            })
          })
          return filteredInventoryLeads
        }
      }
      return this.inventoryLeads
    },
    canShowPickupLocation: function () {
      return this.currentInventory.possessionStatus === 'Inbound'
    },
    currentDispositionIntentionFormatted: function () {
      if (!this.currentInventory.dispositionIntention) return '-'
      let result = ''
      if (this.currentInventory.dispositionIntention === 'WholesaleImmediately') {
        result += 'Wholesale'
      } else if (this.currentInventory.dispositionIntention === 'RetailForXDays') {
        result += 'Retail'
      } else {
        result += this.currentInventory.dispositionIntention
      }
      if (this.currentInventory.dispositionIntention === 'RetailForXDays' && this.currentInventory.dispositionValidDays) {
        result += ' for ' + this.currentInventory.dispositionValidDays + ' days.'
      }
      return result
    },
    originalDispositionIntentionDisplayValue: function () {
      if (!this.currentInventory.originalDispositionIntention) return ''
      if (this.currentInventory.originalDispositionIntention.dispositionIntention === 'Retail') {
        return 'The original intention was to Retail as set by ' + this.currentInventory.originalDispositionIntention.createdByFullName
      }
      var result = 'The original intention was to '
      if (this.currentInventory.originalDispositionIntention.dispositionIntention === 'WholesaleImmediately') {
        result += 'Wholesale'
        if (this.currentInventory.originalDispositionIntention.commitmentFromId > 0) {
          result += ' to ' + this.currentInventory.originalDispositionIntention.commitmentFromName
          if (this.currentInventory.originalDispositionIntention.firmCommitment) {
            result += ' for ' + this.$formatCurrency(this.currentInventory.originalDispositionIntention.commitmentValue)
          }
        }
      } else {
        result += 'Retail'
        if (this.currentInventory.originalDispositionIntention.dispositionValidDays > 0) {
          result += ' for ' + this.currentInventory.originalDispositionIntention.dispositionValidDays + ' days'
        }
      }
      result += ' as set by ' + this.currentInventory.originalDispositionIntention.createdByFullName
      return result
    },
    isCurrentDispositionIntentionExpired: function () {
      return this.currentInventory.dispositionExpired || (this.currentInventory.originalDispositionIntention && this.currentInventory.originalDispositionIntention.dispositionIntention !== 'Retail')
    },
    accessoryOverview: function () {
      let content = ''
      if (this.currentInventory.accessoryDetailWarnings) {
        Object.keys(this.currentInventory.accessoryDetailWarnings).forEach(x => {
          if (x === 'books') {
            content += '<i title="Books status" class="fal fa-sm fa-books tip-icon ' + (this.currentInventory.accessoryDetailWarnings[x].length > 0 ? (this.currentInventory.accessoryDetailWarnings[x] === 'Good' ? 'has-text-success' : 'has-text-danger') : 'has-text-grey') + '"></i>'
          }
          if (x === 'batteryCharger') {
            content += '<i title="Battery charger status" class="fal fa-sm fa-car-battery tip-icon ' + (this.currentInventory.accessoryDetailWarnings[x].length > 0 ? (this.currentInventory.accessoryDetailWarnings[x] === 'Good' ? 'has-text-success' : 'has-text-danger') : 'has-text-grey') + '"></i>'
          }
          if (x === 'carCover') {
            content += '<i title="Car cover status" class="fal fa-sm fa-car-garage tip-icon ' + (this.currentInventory.accessoryDetailWarnings[x].length > 0 ? (this.currentInventory.accessoryDetailWarnings[x] === 'Good' ? 'has-text-success' : 'has-text-danger') : 'has-text-grey') + '"></i>'
          }
          if (x === 'keys') {
            content += '<i title="Keys status" class="fal fa-sm fa-key tip-icon ' + (this.currentInventory.accessoryDetailWarnings[x].length > 0 ? (this.currentInventory.accessoryDetailWarnings[x] === 'Good' ? 'has-text-success' : 'has-text-danger') : 'has-text-grey') + '"></i>'
          }
        })
      }
      return content
    },
    features: function () {
      if (!this.currentInventory || !this.currentInventory.features) {
        return []
      }
      return this.currentInventory.features.split('|')
    },
    hasAutoCheckAppraisal: function () {
      return false
    },
    hasVautoAppraisal: function () {
      return this.currentInventory.vAutoAppId && this.currentInventory.vAutoAppId !== ''
    },
    hasMoreServiceRequests: function () {
      return this.currentInventory && this.currentInventory.serviceRequests && this.currentInventory.serviceRequests.length === this.$store.state.config.maxServiceRequests
    },
    hasMoreAccessoryEvents: function () {
      return this.currentInventory && this.currentInventory.accessoryEvents && this.currentInventory.accessoryEvents.length === this.$store.state.config.maxAccessoryEvents
    },
    expectedGeneralLedgerBalance: function () {
      return this.currentInventory.purchaseCostTotal + this.currentInventory.reconCost
    },
    lastSignedSellersAgreement: function () {
      if (this.currentInventory && (this.currentInventory.sellersAgreementStatus === 'Complete' || this.currentInventory.sellersAgreementStatus === 'Signed')) {
        var allSignedSellersAgreements = this.currentInventory.attachments.filter((x) => x.classType === 'SellersAgreement' && x.status === 'Active')
        if (allSignedSellersAgreements.length > 0) {
          return allSignedSellersAgreements[allSignedSellersAgreements.length - 1].fileLocation
        }
      }
      return ''
    },
    mostRecentAppraisal: function () {
      if (this.currentInventory.appraisals.length > 0) {
        return this.currentInventory.appraisals[0]
      }
      return null
    },
    inventoryAppraisalParams: function () {
      let trimAttribute = this.currentInventory.attributes.filter((x) => x.name === 'Trim')[0]
      let trim = trimAttribute ? trimAttribute.value : ''
      let condition = this.currentInventory.conditionType
      return {
        id: this.currentInventory.id,
        vinNo: this.currentInventory.vinNo,
        mileage: this.currentInventory.usage,
        trim: trim,
        condition: condition,
        msrp: this.currentInventory.msrp,
        purchaseCost: this.currentInventory.type === 'Consigned' ? this.currentInventory.consignmentMinimum : this.currentInventory.purchaseCostTotal,
        askingPrice: this.currentInventory.askingPrice
      }
    },
    lastTitleDocument: function () {
      if (this.currentInventory.attachments.filter((y) => y.classType === 'TitleFront' || y.classType === 'TitleBack').length > 0) {
        var allTitles = this.currentInventory.attachments.filter((y) => y.classType === 'TitleFront' || y.classType === 'TitleBack')
        if (allTitles.length > 0) {
          return allTitles[0].fileLocation
        }
      }
      return ''
    },
    isLocating: function () {
      return this.currentInventory.possessionStatus === 'Locating'
    },
    showInventoryArchiveAction: function () {
      return false
    },
    isQuickView: function () {
      return this.actionPortal.startsWith('qv-')
    },
    fullName: function () {
      var fullName = '(' + this.currentInventory.stockNo + ')'
      if (fullName === '()') {
        return ''
      }
      return fullName
    },
    getTitleOverview: function () {
      let preEntryText = this.isPreEntry && this.canShowPreEntry ? ' [Pre-Entry]' : ''
      let prefixText = this.isTypePpaAppraisal ? 'PPA' : 'Inventory'
      var result = prefixText + ' - ' + this.currentInventory.stockNo + preEntryText
      return result
    },
    getInventoryOverview: function () {
      var result = this.currentInventory.stockNo + ' - ' + this.currentInventory.vinNo
      return result
    },
    getPricingOverview: function () {
      return 'Asking Price: ' + this.$formatCurrency(this.currentInventory.askingPrice)
    },
    obsoleteWatermarkText: function () {
      let currentStatus = this.getSoldDeliveredStatus
      var watermarkText = ''
      if (currentStatus.indexOf('Sold') >= 0) watermarkText = 'Sold'
      if (currentStatus.indexOf('Cancelled') >= 0) watermarkText = 'Cancelled'
      if (currentStatus.indexOf('Returned') >= 0) watermarkText = 'Returned'
      if (currentStatus.indexOf('Retired Loaner') >= 0) watermarkText = 'Retired Loaner'
      if (currentStatus.indexOf('Located Off-Site') >= 0) watermarkText = 'Located Off-Site'
      if (currentStatus.indexOf('Located Off-Site') >= 0 && currentStatus.indexOf('Sold') >= 0) watermarkText = 'Sold'
      return (watermarkText + ' ').repeat(500)
    },
    isObsoleteStatus: function () {
      let currentStatus = this.getSoldDeliveredStatus
      return currentStatus.indexOf('Sold') >= 0 || currentStatus.indexOf('Returned') >= 0 || currentStatus.indexOf('Cancelled') >= 0 || currentStatus.indexOf('Retired Loaner') >= 0 || currentStatus.indexOf('Located Off-Site') >= 0
    },
    getSoldDeliveredStatus: function () {
      let nonCancelledDeals = this.currentInventory.deals.length > 0 ? this.currentInventory.deals.filter((x) => x.type !== 'Cancelled' && x.items.find((y) => y.forInventory.id === this.currentInventory.id && y.dealItemType === 'Sold')) : []
      if (nonCancelledDeals.length > 0) {
        if (nonCancelledDeals[0].type === 'Booked' || nonCancelledDeals[0].type === 'Delivered') {
          if (this.currentInventory.possessionStatus === 'LocatedOffSite') {
            return this.currentInventory.type + ' - Located Off-Site - Sold'
          } else {
            return this.currentInventory.type + ' - ' + this.currentInventory.possessionStatus + ' - Sold'
          }
        } else {
          if (this.currentInventory.possessionStatus === 'LocatedOffSite') {
            return this.currentInventory.type + ' - Located Off-Site - ' + nonCancelledDeals[0].type
          } else {
            return this.currentInventory.type + ' - ' + this.currentInventory.possessionStatus + ' - ' + nonCancelledDeals[0].type
          }
        }
      } else {
        if (this.currentInventory.status === 'Archived' || this.currentInventory.status === 'Cancelled') {
          return this.currentInventory.type + ' - ' + this.currentInventory.possessionStatus + ' - Cancelled'
        }

        if (this.currentInventory.isServiceLoanerRetired) {
          return this.currentInventory.type + ' - ' + this.currentInventory.possessionStatus + ' - Retired Loaner'
        }

        if (this.currentInventory.possessionStatus === 'LocatedOffSite') {
          return this.currentInventory.type + ' - Located Off-Site'
        }

        return this.currentInventory.type + ' - ' + this.currentInventory.possessionStatus
      }
    },
    totalGP: function () {
      return 0
    },
    hasAccessories: function () {
      return true
    },
    hasLoaners: function () {
      return true
    },
    hasWorks: function () {
      return true
    },
    hasServiceRequests: function () {
      if (this.currentInventory.ServiceRequests && this.currentInventory.ServiceRequests.length > 0) {
        return true
      } else {
        return false
      }
    },
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    primaryDeal: function () {
      if (this.currentInventory.deals.length > 0) {
        return this.currentInventory.deals.find((x) => x.items.find((y) => y.isPrimary === true) !== null)
      }
      return null
    },
    primaryDealSalesPersonName: function () {
      return this.primaryDeal.salesPersonName
    },
    primaryDealCustomerName: function () {
      return this.primaryDeal.buyerName
    },
    primaryDealStatusId: function () {
      if (this.primaryDeal) {
        return this.primaryDeal.typeId
      }
      return -1
    },
    primaryDealStatusName: function () {
      if (this.primaryDeal) {
        return this.primaryDeal.type
      }
      return 'Available'
    },
    primaryDealGenericStatusName: function () {
      if (this.primaryDeal) {
        return this.primaryDeal.status
      }
      return ''
    },
    allowedDealInventoryTypes: function () {
      return this.definedTypes.inventoryTypes.filter(x => x.altName !== 'ForPpaAppraisal' && x.altName !== 'PpaClosed' && x.altName !== 'LeaseReturn' && x.altName !== 'TradeIn')
    },
    isPreEntry: function () {
      return this.currentInventory.status === 'PreEntry'
    },
    minimumUsageAllowed: function () {
      if (this.currentInventory && this.currentInventory.vinUsageHistory && this.currentInventory.vinUsageHistory.length > 0) {
        return this.currentInventory.vinUsageHistory[this.currentInventory.vinUsageHistory.length - 1].usage
      }
      return 0
    },
    usageRequired: function () {
      return this.selectedPossessionStatus && this.selectedPossessionStatus.id !== 1
    },
    isTypePpaAppraisal: function () {
      return this.currentInventory.type === 'ForPpaAppraisal' || this.isPpaClosed
    },
    isTypeTrade: function () {
      return this.currentInventory.type === 'TradeIn'
    },
    isTypePpaTradeAppraisal: function () {
      return (this.currentInventory.type === 'ForPpaAppraisal' || this.isPpaClosed) && this.currentInventory.ppaIntention === 'Trade'
    },
    isStatusArchived: function () {
      return this.currentInventory.status === 'Archived'
    },
    isTypeConsigned: function () {
      return this.currentInventory.type === 'Consigned'
    },
    canShowPreEntry () {
      return !this.isTypePpaAppraisal
    },
    canShowFleet () {
      return !this.isTypePpaAppraisal
    },
    canShowDemo () {
      return !this.isTypePpaAppraisal
    },
    canShowMarketable () {
      return !this.isTypePpaAppraisal
    },
    currentInventoryaccessoryDetailStatusType: function () {
      if (this.currentInventory.booksStatus.length === 0 && this.currentInventory.batteryChargerStatus.length === 0 && this.currentInventory.carCoverStatus.length === 0 && this.currentInventory.keyStatus.length === 0) {
        return 2
      }
      return this.currentInventory.accessoryDetailStatusType
    },
    showDispositionIntention: function () {
      return [1, 2, 4, 5].indexOf(this.currentInventory.typeId) >= 0
    },
    isLeaseReturn: function () {
      return this.currentInventory.type === 'LeaseReturn'
    },
    availableInventoryOfferTypes: function () {
      return this.definedTypes.inventoryOfferTypes
    },
    getTotalAdditionalCostsHeader: function () {
      if (!this.currentInventory) return ''
      return 'Total: ' + (this.currentInventory.additionalCostsTotal ? this.$formatCurrency(this.currentInventory.additionalCostsTotal) : '0.00')
    },
    getHighestOfferOverview: function () {
      if (this.currentInventory.highestOfferAmount > 0) {
        if (this.filteredLeads && this.filteredLeads.length > 0) {
          return 'Highest Offer: ' + this.$formatCurrency(this.currentInventory.highestOfferAmount) + ' (' + this.currentInventory.offersCount + '/' + this.filteredLeads.length + ') '
        } else {
          return 'Highest Offer: ' + this.$formatCurrency(this.currentInventory.highestOfferAmount) + ' (' + this.currentInventory.offersCount + '/' + this.currentInventory.leadsCount + ') '
        }
      } else {
        if (this.filteredLeads && this.filteredLeads.length > 0) {
          return 'No Offers: ' + ' (' + this.currentInventory.offersCount + '/' + this.filteredLeads.length + ') '
        } else {
          return 'No Offers: ' + ' (' + this.currentInventory.offersCount + '/' + this.currentInventory.leadsCount + ') '
        }
      }
    },
    selectedInventoryStatusType: function () {
      return this.definedTypes.inventoryTypes.find((x) => (x.name === this.currentInventory.type || x.altName === this.currentInventory.type))
    },
    hasPpaReceipt: function () {
      if (!this.currentInventory || !this.currentInventory.attachments) {
        return false
      }
      var primaryImages = this.currentInventory.attachments.filter((x) => x.classType === 'PpaApprovedReceipt')
      return primaryImages.length > 0
    },
    getPpaReceipt: function () {
      if (!this.currentInventory || !this.currentInventory.attachments) {
        return []
      }
      var primaryImages = this.currentInventory.attachments.filter((x) => x.classType === 'PpaApprovedReceipt')
      var result = []
      primaryImages.forEach((x) => {
        x.loaded = true
        x.imageData = x.fileLocation
        x.fileType = this.getFileType(x.fileLocation)
        result.push(x)
      })
      return result
    },
    getPpaExaminedTitle: function () {
      return this.currentInventory.ppaExamined ? 'PPA was physically examined.' : 'PPA not physically examined.'
    },
    getPpaExaminedColourClass: function () {
      return this.currentInventory.ppaExamined ? 'has-text-success' : 'has-text-danger'
    },
    showCostsSection: function () {
      if (this.currentInventory && this.currentInventory.type) {
        return true
      } else {
        return false
      }
    },
    showUpdateLocation: function () {
      return this.showVehicleLocation && this.hasFeatureAccess(this.updateInventoryLocationPermission)
    },
    showVehicleLocation: function () {
      if (!this.selectedTypeStatus) {
        return false
      } else {
        return [0, 1, 3, 4, 5, 6].indexOf(this.selectedTypeStatus.id) >= 0
      }
    },
    isArchived: function () {
      return this.currentInventory.status === 'Archived'
    },
    showAddSupplementalCost: function () {
      return this.hasFeatureAccess(this.addInventorySupplementalCost) && !this.isPpaClosed
    },
    isSpiffExpirationDateRequired: function () {
      return this.currentInventory.spiff && this.currentInventory.spiff > 0 ? 'required' : ''
    },
    hasPpaRecord: function () {
      return this.currentInventory && this.currentInventory.cloneSourceInventoryId && this.currentInventory.cloneSourceType === 'PpaClosed'
    },
    showPpaConversionButton: function () {
      return this.currentInventory && this.isTypePpaAppraisal && this.currentInventory.ppaApproved && !this.isStatusArchived
    },
    showPpaCloseButton: function () {
      return this.currentInventory && this.isTypePpaAppraisal && !this.isStatusArchived
    },
    tradeDealInventoryTypes: function () {
      return this.definedTypes.inventoryTypes.filter(x => x.altName === 'ForPpaAppraisal' || x.altName === 'TradeIn')
    },
    getDealColorClass: function () {
      var result = 'has-text-grey'
      if (this.currentInventory) {
        if (this.currentInventory.soldDealItem || this.currentInventory.leaseDealItem) {
          result = 'has-text-success'
        } else if (this.currentInventory.tradeDealItem) {
          result = 'has-text-info'
        }
      }
      let classToAttach = { }
      classToAttach[result] = true
      return classToAttach
    },
    getDealDataType: function () {
      var result = ''
      if (this.currentInventory) {
        if (this.currentInventory.soldDealItem || this.currentInventory.leaseDealItem) {
          result = 'D'
        } else if (this.currentInventory.tradeDealItem) {
          result = 'T'
        }
      }
      return result
    },
    isPpaClosed: function () {
      return this.currentInventory.type === 'PpaClosed'
    },
    tradeSourceDeal: function () {
      if (!this.isTradeLinkedToDeal) return null
      let tradeSourceDeal = {
        id: this.currentInventory.tradeSourceDealId,
        type: this.currentInventory.tradeSourceDealStatus,
        status: this.currentInventory.tradeSourceDealGenericStatus,
        purchaserId: this.currentInventory.tradeSourcePurchaserId,
        purchaserName: this.currentInventory.tradeSourcePurchaserFullName,
        tradeAllowance: this.currentInventory.tradeAllowance,
        primaryDealItem: {isPrimary: this.currentInventory.tradeSourceDealIsPrimary, forInventory: {id: this.currentInventory.tradeSourceInventoryId}}
      }
      return tradeSourceDeal
    },
    primaryDealIsBookingFinalised: function () {
      if (this.primaryDeal) {
        return this.primaryDeal.isBookingFinalised
      }
      return false
    },
    autoCheckEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.autoCheckEnabledStatus
      }
      return false
    },
    mostRecentBlackBookInformation: function () {
      if (this.currentInventory && this.currentInventory.blackBooks && this.currentInventory.blackBooks.length > 0) {
        return this.currentInventory.blackBooks[0]
      }
      return null
    },
    blackBookEnabled: function () {
      if (this.currentDealer) {
        return this.currentDealer.blackBookEnabledStatus
      }
      return false
    },
    batchPaymentRequestRef: function () {
      return 'inventory-batch-payment-request' + this.currentInventoryId
    }
  },
  methods: {
    onViewSalesVideoImages: function () {
      const viewer = this.$refs.salesVideosImageViewer.$viewer
      viewer.show()
    },
    onDownloadSalesVideo: function (row) {
      window.location = row.fileLocation
    },
    onSaveNewLocation: function () {
      if (this.currentInventory && this.selectedLocation && this.currentInventory.locationId !== this.selectedLocation.id) {
        this.onLocationUpdated()
        return
      }
      if (this.currentInventory && this.currentInventory.locationId && !this.selectedLocation) {
        this.onLocationUpdated()
      }
    },
    onCancelEditLocation: function () {
      this.isEditingLocation = false
    },
    onEditLocation: function () {
      this.isEditingLocation = true
    },
    onViewToPricingPod: function () {
      let that = this
      this.isPricingCollapsed = false
      this.$nextTick(() => {
        setTimeout(function () {
          let element = that.$refs['pricingPod']
          if (element) {
            that.$goToBlock(element, {
              behavior: 'smooth',
              block: 'end',
              inline: 'end'
            })
          }
        }, 100)
      })
    },
    onDispositionIntentionChanged: function () {
      this.isSavingNewDispositionIntention = true
      let newDispositionIntentionStatusId = this.dispositionIntentionValue === 'Retail' ? 0 : 2
      inventoryService.updateDispositionIntention(this.currentInventory, newDispositionIntentionStatusId).then(response => {
        this.isSavingNewDispositionIntention = false
        this.currentInventory.dispositionIntention = response.dispositionIntention
        this.currentInventory.originalDispositionIntention = response.originalDispositionIntention
        this.currentInventory.wholesaleNow = response.dispositionIntention !== 'Retail'
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSavingNewDispositionIntention = false
      })
    },
    onConfirmImageRemoval: function () {
      if (this.currentInventory.id) {
        var inventoryModel = {
          id: this.currentInventory.id
        }

        this.serverErrorMessage = ''
        this.serverErrors = []
        this.isSaving = true

        inventoryService.markImagesAsDeleted(inventoryModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.successToast('Images removed successfully')
            this.isConfirmRemoveImagesActive = false
            this.onChangesSaved()
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
        })
      }
    },
    onPublishStatusChanged: function () {
      this.isSavingNewPublishStatus = true
      let newPublishStatus = !this.publishStatusValue
      inventoryService.updatePublishStatus(this.currentInventory, newPublishStatus).then(response => {
        this.isSavingNewPublishStatus = false
        this.currentInventory.doNotPublish = response.doNotPublish
        this.isPublishStatusChangeActive = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSavingNewPublishStatus = false
      })
    },
    onCancelPublishStatusChange: function () {
      if (!this.currentInventory.doNotPublish) {
        this.publishStatusValue = true
      } else {
        this.publishStatusValue = false
      }
      this.isPublishStatusChangeActive = false
    },
    buildServiceRequestTitle: function (inventory) {
      let content = 'Get ready status'
      return {
        content: content,
        delay: {
          show: 500,
          hide: 100
        }
      }
    },
    buildAccessoryStatusTitle: function (inventory) {
      let content = ''
      if (inventory.accessoryDetailWarnings) {
        Object.keys(inventory.accessoryDetailWarnings).forEach(x => {
          if (x === 'books') {
            content += '<i class="fal fa-books tip-icon ' + (inventory.accessoryDetailWarnings[x].length > 0 ? ((inventory.accessoryDetailWarnings[x] === 'Good' ? 'has-text-success' : 'has-text-danger') + '"></i>' + inventory.accessoryDetailWarnings[x] + '</br>') : ('has-text-grey' + '"></i>' + '' + '</br>'))
          }
          if (x === 'batteryCharger') {
            content += '<i class="fal fa-car-battery tip-icon ' + (inventory.accessoryDetailWarnings[x].length > 0 ? ((inventory.accessoryDetailWarnings[x] === 'Good' ? 'has-text-success' : 'has-text-danger') + '"></i>' + inventory.accessoryDetailWarnings[x] + '</br>') : ('has-text-grey' + '"></i>' + '' + '</br>'))
          }
          if (x === 'carCover') {
            content += '<i class="fal fa-car-garage tip-icon ' + (inventory.accessoryDetailWarnings[x].length > 0 ? ((inventory.accessoryDetailWarnings[x] === 'Good' ? 'has-text-success' : 'has-text-danger') + '"></i>' + inventory.accessoryDetailWarnings[x] + '</br>') : ('has-text-grey' + '"></i>' + '' + '</br>'))
          }
          if (x === 'keys') {
            content += '<i class="fal fa-key tip-icon ' + (inventory.accessoryDetailWarnings[x].length > 0 ? ((inventory.accessoryDetailWarnings[x] === 'Good' ? 'has-text-success' : 'has-text-danger') + '"></i>' + inventory.accessoryDetailWarnings[x] + '</br>') : ('has-text-grey' + '"></i>' + '' + '</br>'))
          }
        })
      }
      return {
        content: content,
        delay: {
          show: 500,
          hide: 100
        }
      }
    },
    buildConsignmentStatusTitle: function (inventory) {
      let content = ''
      content += 'Consignment Type: ' + inventory.saleProceedsType + '</br>'
      if (inventory.saleProceedsType === 'Flat') {
        content += 'Minimum Sale Price: $' + new Intl.NumberFormat().format(inventory.consignmentMinimum) + '</br>'
      } else {
        content += 'Sale Price: $' + new Intl.NumberFormat().format(inventory.askingPrice) + '</br>'
      }
      if (inventory.saleProceedsType === 'Percentage') {
        content += 'Consignment Percentage: ' + inventory.consignmentPercentage + '%</br>'
      }
      if (inventory.saleProceedsType === 'Split' || inventory.saleProceedsType === 'Flat') {
        content += inventory.saleProceedsType === 'Split' ? 'Split Value: $' + new Intl.NumberFormat().format(inventory.consignmentAgreementValue) + '</br>' : 'Dealer Flat Value: $' + new Intl.NumberFormat().format(inventory.consignmentAgreementValue) + '</br>'
      }
      if (inventory.saleProceedsType === 'Split') {
        content += 'Split Ratio: ' + inventory.percentageSplitFirst + '% / ' + inventory.percentageSplitSecond + '%</br>'
      }
      return {
        content: content,
        delay: {
          show: 500,
          hide: 100
        }
      }
    },
    onAccessoriesStatusClick: function () {
      let that = this
      that.isAccessoriesCollapsed = false
      this.$nextTick(() => {
        setTimeout(function () {
          let element = that.$refs['accessoriesPod']
          if (element) {
            that.$goToBlock(element, {
              behavior: 'smooth',
              block: 'end',
              inline: 'end'
            })
          }
        }, 100)
      })
    },
    onServiceRequestStatusClick: function () {
      let that = this
      that.isWorkShopCollapsed = false
      this.$nextTick(() => {
        setTimeout(function () {
          let element = that.$refs['serviceRequestPod']
          if (element) {
            that.$goToBlock(element, {
              behavior: 'smooth',
              block: 'end',
              inline: 'end'
            })
          }
        }, 100)
      })
    },
    onDealStatusClick: function () {
      if (!this.primaryDeal) return
      let that = this
      that.isDealsCollapsed = false
      this.$nextTick(() => {
        setTimeout(function () {
          let element = that.$refs['deal-' + that.primaryDeal.id][0]
          if (element) {
            that.$goToBlock(element, {
              behavior: 'smooth',
              block: 'end',
              inline: 'end'
            })
          }
        }, 100)
      })
    },
    onCopy: function (type, data) {
      let that = this
      this.$copyText(data).then(function (e) {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: type + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
      }, function (e) {
        that.$buefy.toast.open(
          {
            duration: 2500,
            message: 'Could not copy ' + type + ' to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
      })
    },
    onResetvAutoError: function () {
      this.isResettingvAutoError = true
      inventoryService.resetvAutoError(this.currentInventory).then(response => {
        if (response.result === true) {
          this.currentInventory.vAutoLastError = ''
        }
        this.isResettingvAutoError = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isResettingvAutoError = false
      })
    },
    onResetCdkError: function () {
      this.isResettingCdkError = true
      inventoryService.resetCdkError(this.currentInventory).then(response => {
        if (response.result === true) {
          this.currentInventory.cdkLastError = ''
        }
        this.isResettingCdkError = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isResettingCdkError = false
      })
    },
    onShowMoreAccessoryEvents: function () {
      this.isLoading = true
      inventoryService.getAllAccessoryEventsByInventory(this.currentInventory).then(response => {
        this.currentInventory.accessoryEvents.push(...response)
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    onShowMoreServiceRequests: function () {
      if (this.hasFeatureAccess('servicerequest.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'ListServiceRequests', query: { filter: true, query: that.currentInventory.stockNo }})
        })
      }
    },
    onServiceRequestRowSelected: function (row) {
      if (this.hasFeatureAccess('servicerequest.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'ServiceRequestsQuickView', params: { id: row.id }})
        })
      }
    },
    onAppraised: function (appraisal) {
      if (appraisal && appraisal !== '') {
        this.currentInventory.appraisals.unshift(appraisal)
      }
    },
    addSpacingToAttributeName: function (name) {
      var s = name.replace(/([A-Z])/g, ' $1').trim()
      return s
    },
    onPricingCollapsedStatusChanged: function (newValue) {
      this.isPricingCollapsed = newValue
    },
    onAddDeal: function () {
      let that = this
      this.checkDirty(function () {
        that.$emit('close')
        that.$router.push({name: 'NewDeal', params: { inventoryItem: that.currentInventory }})
      })
    },
    onViewPurchasedFrom: function () {
      if (this.currentInventory.purchasedFromType !== 'Factory') {
        if (this.currentInventory.purchasedFromType === 'Wholesaler') {
          if (this.hasFeatureAccess('supplier.view')) {
            let that = this
            this.checkDirty(function () {
              that.$emit('close')
              that.$router.push({name: 'SuppliersQuickView', params: { id: that.currentInventory.purchasedFromId }})
            })
          }
        }
        if (this.currentInventory.purchasedFromType === 'Individual') {
          if (this.hasFeatureAccess('customer.view')) {
            let that = this
            this.checkDirty(function () {
              that.$emit('close')
              that.$router.push({name: 'CustomersQuickView', params: { id: that.currentInventory.purchasedFromId }})
            })
          }
        }
      }
    },
    onViewOriginalPpa: function () {
      if (this.currentInventory.cloneSourceInventoryId) {
        if (this.hasFeatureAccess('inventory.view')) {
          let that = this
          this.checkDirty(function () {
            that.$emit('close')
            that.$router.push({name: 'InventoryQuickView', params: { id: that.currentInventory.cloneSourceInventoryId, isLoanerPod: false, offerPod: -1, autoLaunchControl: null }})
          })
        }
      }
    },
    onViewCurrentInventory: function () {
      if (this.convertedInventoryId) {
        if (this.hasFeatureAccess('inventory.view')) {
          let that = this
          this.checkDirty(function () {
            that.$emit('close')
            that.$router.push({name: 'InventoryQuickView', params: { id: that.convertedInventoryId, isLoanerPod: false, offerPod: -1, autoLaunchControl: null }})
          })
        }
      }
    },
    onViewLeadCustomer: function (customerId) {
      if (this.hasFeatureAccess('customer.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'CustomersQuickView', params: { id: customerId }})
        })
      }
    },
    onViewDeal: function (deal) {
      if (this.hasFeatureAccess('deal.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'DealsQuickView', params: { id: deal.id }})
        })
      }
    },
    onViewPrimaryDeal: function () {
      if (!this.primaryDeal) return
      if (this.hasFeatureAccess('deal.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'DealsQuickView', params: { id: that.primaryDeal.id }})
        })
      }
    },
    onViewTrade: function (trade) {
      if (this.hasFeatureAccess('inventory.view')) {
        let that = this
        this.checkDirty(function () {
          that.$emit('close')
          that.$router.push({name: 'InventoryQuickView', params: { id: trade.forInventory.id, isLoanerPod: true, offerPod: -1, autoLaunchControl: null }})
        })
      }
    },
    onEdit: function () {
      let that = this
      this.checkDirty(function () {
        that.$emit('close')
        that.$router.push({name: 'EditInventory', params: { id: that.currentInventoryId }})
      })
    },
    onCancel: function () {
      let that = this
      this.checkDirty(function () {
        that.$emit('close')
        if (!that.isQuickView) {
          that.$router.push({name: that.lastActiveListPage !== '' ? that.lastActiveListPage : 'ListInventory'})
        } else {
          eventBus.$emit('clear-selected-item', {})
          that.$router.replace({name: that.lastActiveListPage !== '' ? that.lastActiveListPage : 'ListInventory'})
        }
      })
    },
    savePricing: function () {
      var inventoryPricingModel = {
        inventoryId: this.currentInventory.id,
        msrp: this.currentInventory.msrp,
        askingPrice: this.currentInventory.askingPrice,
        leasePrice: this.currentInventory.leasePrice,
        spiff: this.currentInventory.spiff,
        spiffExpirationDate: this.currentInventory.spiffExpirationDate,
        generalLedgerBalance: this.currentInventory.generalLedgerBalance,
        purchaseCost: this.currentInventory.purchaseCost,
        payoffAmount: this.currentInventory.payoffAmount,
        reconCost: this.currentInventory.reconCost,
        consignmentTerms: this.currentInventory.consignmentTerms,
        consignmentPercentage: this.currentInventory.consignmentPercentage,
        consignmentMinimum: this.currentInventory.consignmentMinimum,
        consignmentNote: this.currentInventory.consignmentNote,
        commitmentFromId: this.selectedCommitmentFrom ? this.selectedCommitmentFrom.id : null,
        commitmentValue: this.currentInventory.commitmentValue,
        firmCommitment: this.currentInventory.firmCommitment,
        leaseNote: this.currentInventory.leaseNote,
        commission: this.currentInventory.commission
      }

      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true

      inventoryService.updatePricingInfo(inventoryPricingModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.currentInventory = response.inventory
          this.isEditLocked = true
          this.onChangesSaved()
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    loadInventory (id) {
      axios.cancel('view-inventory-quickview')
      if (this.currentInventory && this.currentInventory.blackBooks) {
        this.currentInventory.blackBooks = []
      }
      this.isEditLocked = true
      this.inventoryDealIsPrimaryActive = false
      this.currentInventoryId = id
      this.isLoading = true
      this.isResettingCdkError = false
      this.isDirty = false
      this.isPublishStatusChangeActive = false
      this.isConfirmRemoveImagesActive = false
      this.inventoryPurchaseOffers = []
      this.inventoryPurchaseOffersFetched = false
      this.isPublishStatusHistoryFetched = false
      this.inventoryPublishHistoryStatus = []
      this.inventoryLeads = []
      this.inventoryLeadsFetched = false
      this.filteredPurchaseOffers = []
      this.supplementalCosts = []
      this.isOffersCollapsed = true
      this.offersAndLeadsTabIndex = 0
      this.inventoryMiscellaneousFiles = []
      this.initialInventoryMiscellaneousFiles = []

      inventoryService.single(this.currentInventoryId, 'view-inventory-quickview').then(response => {
        this.currentInventory = response.inventory
        this.currentInventory.note = this.currentInventory.notes.length > 0 ? this.currentInventory.notes[0].note : ''
        this.selectedCommitmentFrom = (this.currentInventory.commitmentFromId === null || this.currentInventory.commitmentFromId === 0) ? null : {id: this.currentInventory.commitmentFromId, name: this.currentInventory.commitmentFromName}
        this.selectedAccessoryStatus = this.definedTypes.accessoryEventType.options.find((x) => x.id === this.currentInventory.accessoryStatusId)
        this.selectedGetReadyStatus = this.definedTypes.serviceRequestStatusType.options.find((x) => x.id === this.currentInventory.getReadyStatusId)
        this.selectedLoanerStatus = this.definedTypes.loanerEventType.options.find((x) => x.id === this.currentInventory.loanerStatusId)
        this.selectedPossessionStatus = this.definedTypes.possessionStatuses.find((x) => (x.name === this.currentInventory.possessionStatus || x.altName === this.currentInventory.possessionStatus))

        if (this.currentInventory.dispositionIntention === 'WholesaleImmediately') {
          this.dispositionIntentionValue = 'Wholesale'
        }

        if (this.currentInventory.dispositionIntention === 'Retail' || this.currentInventory.dispositionIntention === 'RetailForXDays') {
          this.dispositionIntentionValue = 'Retail'
        }

        if (!this.currentInventory.doNotPublish) {
          this.publishStatusValue = true
        } else {
          this.publishStatusValue = false
        }

        this.daysList = []
        this.tagsList = []
        this.currentInventory.tags.forEach(function (tag) {
          this.tagsList.push(tag)
        }, this)

        this.supplementalCosts = []
        this.initialSupplementalCosts = []
        this.currentInventory.supplementalCosts.forEach(function (supplementalCost) {
          this.supplementalCosts.push(supplementalCost)
          this.initialSupplementalCosts.push(supplementalCost)
        }, this)
        this.selectedDealer = { id: this.currentInventory.dealerId, name: this.currentInventory.dealerName }

        this.inventoryMiscellaneousFiles = this.getInventoryAttachmentsByType('Other')
        this.initialInventoryMiscellaneousFiles = _.cloneDeep(this.inventoryMiscellaneousFiles)

        this.daysList.push(
          {
            label: 'Days Since Purchase',
            value: this.currentInventory.daysSincePurchaseDisplay
          },
          {
            label: 'Days on Lot',
            value: this.currentInventory.daysOnLotDisplay
          },
          {
            label: 'Days Since Market',
            value: this.currentInventory.daysToMarketDisplay
          },
          {
            label: 'Days Since Last Service',
            value: this.currentInventory.daysLastServiceDisplay
          }
        )
        this.locationList = []
        this.locationList.push(
          {
            label: 'Current Location',
            value: this.currentInventory.currentLocationName || '-'
          },
          {
            label: 'Location',
            value: this.currentInventory.locationName || '-'
          }
        )
        this.expirationList = []
        if (this.currentInventory.daysToDispositionExpiryDisplay === 'Pending') {
          this.expirationList.push(
            {
              label: 'Days till Warranty Expiration',
              value: this.currentInventory.daysWarrantyExpirationDisplay,
              daysWarrantyExpiration: this.currentInventory.daysWarrantyExpiration,
              warrantyExpirationDate: this.currentInventory.warrantyExpirationDate,
              isWarranty: true
            },
            {
              label: 'Days to Disposition Expiration',
              value: this.currentInventory.daysToDispositionExpiryDisplay
            }
          )
        } else {
          this.expirationList.push(
            {
              label: 'Disposition Expiration Past Due',
              value: this.currentInventory.daysToDispositionExpiryDisplay,
              valueStyle: 'help is-danger'
            },
            {
              label: 'Days till Warranty Expiration',
              value: this.currentInventory.daysWarrantyExpirationDisplay,
              daysWarrantyExpiration: this.currentInventory.daysWarrantyExpiration,
              warrantyExpirationDate: this.currentInventory.warrantyExpirationDate,
              isWarranty: true
            }
          )
        }
        this.selectedLocation = (this.currentInventory.locationId === null || this.currentInventory.locationId === 0) ? null : {id: this.currentInventory.locationId, name: this.currentInventory.locationName}
        this.selectedTypeStatus = this.definedTypes.inventoryTypes.find((x) => (x.name === this.currentInventory.type || x.altName === this.currentInventory.type))
        if (this.offerPodIndex !== -1) {
          this.offersAndLeadsTabIndex = this.offerPodIndex
          this.isOffersCollapsed = false
          this.scrollToTagsPod()
        }

        if (this.isPpaClosed && this.currentInventory.status === 'Archived') {
          inventoryService.findPpaConvertedInventory(this.currentInventoryId).then(response => {
            this.convertedInventoryId = response.results
            this.isLoading = false
          }).catch((error) => {
            this.isLoading = false
            this.serverErrorMessage = error.message
          })
        }

        dealService.byInventory(this.currentInventoryId).then(response => {
          this.currentInventory.deals = response.results
          this.isLoading = false
        }).catch((error) => {
          this.isLoading = false
          this.serverErrorMessage = error.message
        })

        if (this.$refs.batchPaymentRequestRef) {
          this.$refs.batchPaymentRequestRef.initialize()
        }
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
        this.supplementalCosts = []
        this.initialSupplementalCosts = []
      })
    },
    onConfirmInventoryDealIsPrimary: function (bool) {
      if (bool) {
        this.isSaving = true
        let dealUpdatePrimaryModel = {
          dealItemId: this.selectedDealItem.id,
          isPrimary: bool,
          inventoryId: this.selectedDealItem.forInventory.id,
          originalInventoryId: this.selectedDealItem.forInventory.id,
          originalIsPrimary: this.selectedDealItem.isPrimary
        }
        this.selectedDealItem.isPrimary = bool
        if (this.selectedDealItem.id !== 0) {
          dealService.updateIsPrimary(dealUpdatePrimaryModel).then(response => {
            if (response.errors) {
              this.serverErrors = response.errors
            }
            this.loadInventory(this.currentInventoryId)
            this.isSaving = false
          }).catch((error) => {
            this.serverErrorMessage = error.message
            this.isSaving = false
          })
        }
      }
      this.selectedDealItem = null
      this.inventoryDealIsPrimaryActive = false
    },
    onCloseDuplicateNotification: function (index) {
      this.$refs[index][0].hidden = true
    },
    showNotificationModel: function (dealItem) {
      this.inventoryDealIsPrimaryActive = true
      this.selectedDealItem = dealItem
    },
    onAccessoryStatusSelected: function (newValue) {
      let accessoryStatusUpdateModel = {
        inventoryId: this.currentInventory.id,
        accessoryStatusId: newValue ? newValue.id : null
      }
      this.isSaving = true
      inventoryService.updateAccessoryStatus(accessoryStatusUpdateModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          eventBus.$emit('inventory-changed', [this.currentInventory])
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onInventoryChanged: function (data) {
      if (data instanceof Array) {
        this.loadInventory(data[0].id)
        return
      }
      this.loadInventory(data.id)
    },
    requestStatusType: function (type) {
      let found = this.definedTypes.serviceRequestStatusType.options.find((x) => (x.name === type || x.altName === type))
      return (!found) ? '' : found.name
    },
    requestType: function (type) {
      let found = this.definedTypes.serviceRequestType.options.find((x) => (x.name === type || x.altName === type))
      return (!found) ? '' : found.name
    },
    showPromisedDate: function (type) {
      return type && type !== 'Booked' && type !== 'Delivered'
    },
    reloadInventory: function () {
      var inventoryId = this.id ? this.id : this.$route.params.id
      if (inventoryId) {
        this.loadInventory(inventoryId)
      }
    },
    usageComponentUpdateHandler: function (usageModel) {
      this.currentInventory.usage = usageModel.usage
      this.currentInventory.vinUsageHistory = usageModel.vinUsageHistory
      eventBus.$emit('inventory-changed', [this.currentInventory])
    },
    onOffersCollapsedStatusChanged: function (newValue) {
      this.isOffersCollapsed = newValue
    },
    onPublishStatusHistoryCollapsedStatusChanged: function (newValue) {
      this.isPublishStatusHistoryCollapsed = newValue
    },
    displayInventoryOffers: function () {
      if (this.currentInventory.id && !this.inventoryPurchaseOffersFetched) {
        this.loadInventoryOffers()
      } else if (this.currentInventory.id && this.inventoryPurchaseOffersFetched) {
        this.filterInventoryOffers()
      }
    },
    loadInventoryOffers: function () {
      if (this.currentInventory.id) {
        this.offersLoading = true
        inventoryService.getPurchaseOffersByInventoryId(this.currentInventory.id).then(response => {
          this.inventoryPurchaseOffers = response.results
          this.filterInventoryOffers()
        }).catch((error) => {
          this.serverErrorMessage = error.message
        }).then(() => {
          this.inventoryPurchaseOffersFetched = true
          this.offersLoading = false
        })
      }
    },
    loadInventoryLeads: function () {
      if (this.currentInventory.id) {
        this.leadsLoading = true
        inventoryService.getLeadsByInventoryId(this.currentInventory.id).then(response => {
          this.inventoryLeads = response.results
        }).catch((error) => {
          this.serverErrorMessage = error.message
        }).then(() => {
          this.inventoryLeadsFetched = true
          this.leadsLoading = false
        })
      }
    },
    filterInventoryOffers: function () {
      if (this.selectedOfferType === 'All') {
        this.filteredPurchaseOffers = this.inventoryPurchaseOffers
      } else {
        this.filteredPurchaseOffers = this.inventoryPurchaseOffers.filter((x) => x.offerMadeBy === this.selectedOfferType)
      }
    },
    markForceCdkPush: function () {
      if (this.currentInventory.id) {
        var inventoryModel = {
          id: this.currentInventory.id,
          forceCdkPush: true
        }

        this.serverErrorMessage = ''
        this.serverErrors = []
        this.isSaving = true

        inventoryService.markForceCdkPush(inventoryModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.onChangesSaved()
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
        })
      }
    },
    getServiceRequestCostTitle: function (amount) {
      let amountCurrency = this.$options.filters.currency(amount)
      return 'Approved Costs: ' + amountCurrency
    },
    onLocationCollapsedStatusChanged: function (newValue) {
      this.isLocationCollapsed = newValue
    },
    onLocationUpdated: function () {
      this.isSavingNewLocation = true
      let newLocationId = 0
      if (this.selectedLocation) {
        newLocationId = this.selectedLocation.id
      }
      inventoryService.updateLocation(this.currentInventory, newLocationId).then(response => {
        this.isSavingNewLocation = false
        this.isEditingLocation = false
        this.currentInventory.locationId = response.locationId
        let locationName = '-'
        if (this.selectedLocation) {
          locationName = this.selectedLocation.name
        }
        this.currentInventory.locationName = locationName
        this.locationList = []
        this.locationList.push(
          {
            label: 'Current Location',
            value: this.currentInventory.currentLocationName || '-'
          },
          {
            label: 'Location',
            value: this.currentInventory.locationName || '-'
          }
        )
        this.successToast('Location updated successfully')
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSavingNewLocation = false
        this.isEditingLocation = false
      })
    },
    addSupplementalCost: function () {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost.isAddingCost = true
    },
    createGenericSupplementalCost () {
      return {
        id: 0,
        name: null,
        vendorId: 0,
        payeeType: 'Supplier',
        vendorName: '',
        attachments: [],
        description: '',
        cost: null,
        sellingPrice: 0.00,
        isAddingCost: false,
        processingAction: null,
        appliesToDispositionIntention: 'All',
        appliesOnSaleOnly: false,
        requiresMatchedSaleIntention: false,
        isInGlBalance: false,
        additionalIncome: false,
        dealerDefaultCostId: null
      }
    },
    onSupplementalCostAdded: function (inventory) {
      this.supplementalCosts = []
      this.initialSupplementalCosts = []
      inventory.supplementalCosts.forEach(function (supplementalCost) {
        this.supplementalCosts.push(supplementalCost)
        this.initialSupplementalCosts.push(supplementalCost)
      }, this)
      this.reloadInventory()
    },
    editSupplementalCost: function (supplementalCost, index) {
      this.newSupplementalCost = this.createGenericSupplementalCost()
      this.newSupplementalCost = {
        index: index,
        inventoryId: supplementalCost.inventoryId,
        id: supplementalCost.id,
        name: supplementalCost.name,
        payeeType: supplementalCost.payeeType,
        vendorId: supplementalCost.vendorId,
        vendorName: supplementalCost.vendorName,
        attachments: supplementalCost.attachments,
        processingAction: supplementalCost.processingAction,
        description: supplementalCost.description,
        cost: supplementalCost.cost,
        sellingPrice: supplementalCost.sellingPrice,
        dealId: supplementalCost.dealId,
        isInGlBalance: supplementalCost.isInGlBalance,
        appliesToDispositionIntention: supplementalCost.appliesToDispositionIntention,
        appliesOnSaleOnly: supplementalCost.appliesOnSaleOnly,
        requiresMatchedSaleIntention: supplementalCost.requiresMatchedSaleIntention,
        additionalIncome: supplementalCost.additionalIncome,
        dealerDefaultCostId: supplementalCost.dealerDefaultCostId,
        isAddingCost: true
      }
    },
    onCloseLostTitle () {
      this.showLostTitleAction = false
    },
    onShowLostTitle () {
      this.showLostTitleAction = true
    },
    scrollToTagsPod: function () {
      let that = this
      this.$nextTick(() => {
        setTimeout(function () {
          that.$goToBlock(that.$refs.tagsPod, {
            behavior: 'smooth',
            block: 'end',
            inline: 'end'
          })
        }, 500)
      })
    },
    onLeadTypeSelectionChanged: function (newValue) {
      this.selectedLeadTypes = newValue
    },
    removeAllImages: function () {
      this.isConfirmRemoveImagesActive = true
    },
    formatDate: function (utcEnabled, date) {
      if (utcEnabled) {
        return this.$options.filters.formatDateOnly(date)
      }
      return this.$options.filters.formatDateIgnoreZone(date)
    },
    onAddInventoryTradeToDeal: function () {
      let that = this
      this.checkDirty(function () {
        that.$emit('close')
        that.$router.push({name: 'NewDeal', params: { tradeInventoryItem: that.currentInventory, dealRedirect: true }})
      })
    },
    onOpenApprovePpaAction: function () {
      let control = this.$refs['approvePpaAction']
      control.onRequestApprovePpa()
    },
    onApprovePpaActionMounted: function () {
      if (!this.$route.params.autoLaunchControl) return
      if (this.$route.params.autoLaunchControl === 'approvePpaAction') this.onOpenApprovePpaAction()
    },
    onConvertPpaActionMounted: function () {
      if (!this.$route.params.autoLaunchControl) return
      if (this.$route.params.autoLaunchControl === 'convertPpaAction') this.onOpenConvertPpaAction()
    },
    onOpenConvertPpaAction: function () {
      let control = this.$refs['convertPpaAction']
      control.onRequestConvertPpa()
    },
    onDeleteSupplementalCostClick: function (supplementalCost, index) {
      this.currentDeleteSupplementalcost = supplementalCost
      this.deleteSupplementalCosts = []
      this.deleteSupplementalCosts.push(supplementalCost)
      this.isDeleteConfirmationActive = true
    },
    onSupplementalCostDelete: function () {
      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      inventoryService.deleteSupplementalCost(this.currentDeleteSupplementalcost).then(response => {
        this.isSaving = false

        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.reloadInventory()
          this.isDeleteConfirmationActive = false
          this.deleteSupplementalCost = null
          this.deleteSupplementalCosts = []
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    },
    onBlackBookInformationRetrieved: function (blackBook) {
      this.currentInventory.blackBooks = [blackBook]
    },
    getInventoryAttachmentsByType: function (type) {
      let resultAttachments = []
      if (this.currentInventory && this.currentInventory.attachments && this.currentInventory.attachments.length > 0) {
        let orderedAttachments = _.orderBy(this.currentInventory.attachments, ['createdAt'], ['desc'])
        orderedAttachments.filter(y => y.status !== 'Deleted' && y.classType === type).forEach(x => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.fileType = this.getFileType(x.fileLocation)
          resultAttachments.push(x)
        })
      }
      return resultAttachments
    },
    onSyncGeneralFiles: function () {
      this.isSavingFile = true
      var inventorySyncAttachmentsModel = {
        inventoryId: this.currentInventoryId,
        attachments: []
      }
      let existingInventoryMiscellaneousFiles = this.inventoryMiscellaneousFiles.filter((y) => (y.id && y.id > 0))

      if (this.syncFileTypes.includes(10)) {
        let attachmentFile = this.processAttachments(this.inventoryMiscellaneousFiles, existingInventoryMiscellaneousFiles, 10)
        if (attachmentFile) {
          inventorySyncAttachmentsModel.attachments.push(attachmentFile)
        }
      }
      inventorySyncAttachmentsModel.attachments.push(...existingInventoryMiscellaneousFiles)
      let that = this
      inventoryService.syncAttachments(inventorySyncAttachmentsModel).then(response => {
        if (response.errors) {
          that.serverErrorMessage = response.message
          that.serverErrors = response.errors
          that.isSavingFile = false
        } else if (response.result === true) {
          that.isSavingFile = false
          that.reloadInventory()
        } else {
          that.serverErrorMessage = 'Inventory does not exist.'
        }
      }).catch((error) => {
        that.serverErrorMessage = error.message
        that.isSavingFile = false
      })
    },
    processAttachments: function (currentFiles, existingFiles, fileClassType) {
      let attachments = currentFiles.filter((y) => (!y.id || y.id <= 0) && y.status !== 'Deleted').map((x) => {
        return {
          uploadName: x.name,
          uploadSize: x.size,
          uniqueId: x.uniqueId,
          type: x.type
        }
      })
      if (attachments && attachments.length > 0) {
        existingFiles.forEach(x => {
          if (x.status === 'Active') {
            x.status = 'Archived'
          }
        })
        let attachmentFile = this.getAttachmentFile(attachments, fileClassType)
        if (attachmentFile) {
          return attachmentFile
        }
      }
      return null
    },
    getAttachmentFile: function (inventoryAttachments, classType) {
      let classDict = {
        10: 'Other'
      }
      let inventoryAttachmentClassType = classDict[classType]
      if (inventoryAttachmentClassType) {
        let uploadFileIndex = inventoryAttachments.length - 1
        let inventoryAttachmentModel = {
          inventoryId: this.currentInventoryId,
          classType: inventoryAttachmentClassType,
          uploadName: inventoryAttachments[uploadFileIndex].uploadName,
          uploadSize: inventoryAttachments[uploadFileIndex].uploadSize,
          uniqueId: inventoryAttachments[uploadFileIndex].uniqueId,
          type: inventoryAttachments[uploadFileIndex].type
        }
        return inventoryAttachmentModel
      }
      return null
    },
    scrollToGeneralFilesSync: function () {
      let that = this
      this.$nextTick(() => {
        setTimeout(function () {
          that.$goToBlock(that.$refs.saveGeneralFilesContainer, {
            behavior: 'smooth',
            block: 'end',
            inline: 'end'
          })
        }, 500)
      })
    }
  },
  watch: {
    '$route.params.id': function (id) {
      let that = this
      this.checkDirty(function () {
        if (id) {
          that.offerPodIndex = that.$route.params.offerPod !== null || that.$route.params.offerPod !== undefined ? that.$route.params.offerPod : -1
          that.loadInventory(id)
        } else {
          eventBus.$emit('close-global-quick-view', {})
        }
      })
    },
    currentInventory: function () {
      if (this.$refs.loanerPod !== undefined || this.$refs.loanerPod !== null) {
        if (this.isLoaner) {
          this.$nextTick(() => {
            this.$goToBlock(this.$refs.loanerPod)
          })
        }
      }
    },
    isEditLocked: function (newValue, oldValue) {
      if (newValue === false) {
        if (this.isPricingCollapsed === true) {
          this.isPricingCollapsed = false
        }
        this.$goToBlock(this.$refs.usage)
      }
    },
    isDirty: function (newValue, oldValue) {
      this.$emit('dirty-state-change', newValue)
    },
    isOffersCollapsed: function (newValue, oldValue) {
      if (!newValue) {
        this.displayInventoryOffers()
      }
    },
    offersAndLeadsTabIndex: function (newValue, oldValue) {
      if (newValue === 1 && !this.inventoryLeadsFetched) {
        this.loadInventoryLeads()
      }
    },
    isPublishStatusHistoryCollapsed: function (newValue, oldValue) {
      if (!newValue) {
        if (this.currentInventory.id && !this.isPublishStatusHistoryFetched) {
          inventoryService.getPublishStatusHistoryByInventoryId(this.currentInventory.id).then(response => {
            this.inventoryPublishHistoryStatus = response.results
          }).catch((error) => {
            this.serverErrorMessage = error.message
          }).then(() => {
            this.isPublishStatusHistoryFetched = true
          })
        }
      }
    },
    selectedOfferType: function (newValue, oldValue) {
      if (!this.isOffersCollapsed && newValue && !this.inventoryPurchaseOffersFetched) {
        this.loadInventoryOffers()
      } else {
        this.filterInventoryOffers()
      }
    },
    'currentInventory.highestOfferAmount': function (newValue, oldValue) {
      if (newValue && newValue > 0) {
        this.hasOfferAmount = false
      } else {
        this.hasOfferAmount = true
      }
    },
    inventoryMiscellaneousFiles: {
      handler (newValue, oldValue) {
        let comparison = _.isEqual(newValue, this.initialInventoryMiscellaneousFiles)
        if (!comparison) {
          this.showGeneralFilesSave = true
          this.scrollToGeneralFilesSync()
          this.syncFileTypes.push(10)
        } else {
          this.showGeneralFilesSave = false
          this.syncFileTypes = this.syncFileTypes.filter(number => number !== 10)
        }
      },
      deep: true
    }
  },
  mounted: function () {
    var inventoryId = this.id ? this.id : this.$route.params.id
    this.isLoaner = this.$route.params.isLoanerPod !== null || this.$route.params.isLoanerPod !== undefined ? this.$route.params.isLoanerPod : this.isLoanerPod
    this.isLoanersCollapsed = !this.isLoaner
    this.offerPodIndex = this.$route.params.offerPod !== null || this.$route.params.offerPod !== undefined ? this.$route.params.offerPod : this.offerPodIndex
    eventBus.$on('inventory-reports-uploaded', this.onInventoryChanged)
    eventBus.$on('inventory-changed', this.onInventoryChanged)
    eventBus.$on('quickview-refresh', this.reloadInventory)
    if (inventoryId) {
      this.loadInventory(inventoryId)
    }
  },
  beforeDestroy () {
    eventBus.$off('inventory-changed', this.onInventoryChanged)
    eventBus.$off('quickview-refresh', this.reloadInventory)
    eventBus.$off('inventory-reports-uploaded', this.onInventoryChanged)
  }
}
</script>

<style scoped>
  .notes {
    padding-bottom:10px;
  }

  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .status-icon {
    margin-right:0.25rem;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  .modal-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }

  .modal-card .confirm {
    overflow: auto;
    min-width: 480px !important;
    max-width: 480px !important;
    width: 480px !important;
    max-height: 150px !important;
    min-height: 150px !important;
    height: 150px !important;
  }

  .modal-card-body .confirm {
    min-width: 480px !important;
    max-width: 480px !important;
    width: 480px !important;
    max-height: 150px !important;
    min-height: 150px !important;
    height: 150px !important;
  }

  .modal-card .location {
    overflow: auto;
    min-width: 480px !important;
    max-width: 480px !important;
    width: 480px !important;
    max-height: 275px !important;
    min-height: 275px !important;
    height: 275px !important;
  }

  .modal-card-body .location {
    min-width: 480px !important;
    max-width: 480px !important;
    width: 480px !important;
    max-height: 275px !important;
    min-height: 275px !important;
    height: 275px !important;
  }

  .read-only-field {
    height: 3em;
    display: inline-flex;
    align-items: center
  }

  .accesory-event-row {
    text-align: left;
    vertical-align:middle;
  }

  .accesory-event-column {
    text-align: left;
    vertical-align:middle;
  }

  .service-request-row {
    text-align: left;
    vertical-align:middle;
  }

  .service-request-column {
    text-align: left;
    vertical-align:middle;
    padding-left:3px !important;
    padding-right:0 !important
  }

  .iPhoto {
    margin: 5px;
  }

  .is-64xauto {
    display: -webkit-box;
    height:64px;
    width:64px;
    align-items: center;
    justify-content: center
  }

  .image img {
    display: block;
    height:auto;
    width:auto;
  }

  .icon-centered {
    display: inline-block;
    width: 100%;
    text-align: center;
  }

  .modal-card .make-deal-primary {
    overflow: auto;
    min-width: 400px !important;
    max-width: 400px !important;
    width: 400px !important;
    max-height: 150px !important;
    min-height: 150px !important;
    height: 150px !important;
  }

  .modal-card-body .make-deal-primary {
    min-width: 400px !important;
    max-width: 400px !important;
    width: 400px !important;
    max-height: 150px !important;
    min-height: 150px !important;
    height: 150px !important;
  }
</style>

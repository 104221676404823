import axios from 'axios'
import baseService from './baseService'
import insurerData from './data/insurer'
import departmentData from './data/department'
import dealerData from './data/dealer'
import designationData from './data/designation'

const insurerService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(insurerData.allInsurers)
      } else {
        axios
          .post('/insurer/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allDepartments (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(departmentData.all)
      } else {
        axios
          .get('/department/list?' + params, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allDealers (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealerData.all)
      } else {
        axios
          .get('/dealer/list?' + params, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allDesignations (params) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(designationData.all)
      } else {
        axios
          .get('/designation/list?' + params, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (insurerId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(insurerData.allInsurers.find((x) => x.id === insurerId))
      } else {
        axios
          .post('/insurer/get?id=' + insurerId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (insurerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var insurer = {
          id: 999,
          name: insurerModel.name,
          status: 'Active'
        }
        insurerData.allInsurers.push(insurer)
        resolve(insurer)
      } else {
        axios
          .post('/insurer/save', insurerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (insurerModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var insurer = insurerData.allInsurers.find((x) => x.id === insurerModel.id)
        if (insurer) {
          insurer.name = insurerModel.name
          insurer.status = insurerModel.status
        }
        resolve(insurer)
      } else {
        axios
          .post('/insurer/update', insurerModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (insurerModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        insurerModels.forEach(element => {
          insurerData.allInsurers.remove((x) => x.id === element.id)
        })
        resolve(insurerData.allInsurers)
      } else {
        var ids = []
        insurerModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/insurer/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default insurerService

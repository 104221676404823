import axios from 'axios'
import baseService from './baseService'
import accessoryBinData from './data/accessoryBin'

const accessoryBinService = {
  all (searchCriteria) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(accessoryBinData)
      } else {
        axios
          .post('/accessoryBin/list', searchCriteria, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  allAccessoryBinByDealer (dealer) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(accessoryBinData)
      } else {
        axios
          .get('/accessoryBin/list?dealerId=' + dealer.id, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  single (accessoryBinId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(accessoryBinData.all.find((x) => x.id === accessoryBinId))
      } else {
        axios
          .post('/accessoryBin/get?id=' + accessoryBinId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  save (accessoryBinModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var accessoryBin = {
          id: 999,
          subscriberId: 1,
          dealerId: accessoryBinModel.dealerId,
          name: accessoryBinModel.name,
          location: accessoryBinModel.location,
          description: accessoryBinModel.description,
          status: 'Active'
        }
        accessoryBinData.all.push(accessoryBin)
        resolve(accessoryBin)
      } else {
        axios
          .post('/accessoryBin/save', accessoryBinModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  update (accessoryBinModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        var accessoryBin = accessoryBinData.all.find((x) => x.id === accessoryBinModel.id)
        if (accessoryBin) {
          accessoryBin.dealerId = accessoryBinModel.dealerId
          accessoryBin.subscriberId = accessoryBinModel.subscriberId
          accessoryBin.name = accessoryBinModel.name
          accessoryBin.location = accessoryBinModel.location
          accessoryBin.description = accessoryBinModel.description
          accessoryBin.status = accessoryBinModel.status
        }
        resolve(accessoryBin)
      } else {
        axios
          .post('/accessoryBin/update', accessoryBinModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  delete (accessoryBinModels) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        accessoryBinModels.forEach(element => {
          accessoryBinData.all.remove((x) => x.id === element.id)
        })
        resolve(accessoryBinData.all)
      } else {
        var ids = []
        accessoryBinModels.forEach(element => {
          ids.push(element.id)
        })
        axios
          .post('/accessoryBin/delete', ids, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default accessoryBinService

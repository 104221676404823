<template>
  <div v-if="hasConvertLeaseAccess && isLeaseReturn">
    <div class="quick-action" v-if="!isConvertLeaseConfirmationActive">
        <button class="button" @click="onRequestConvertLease()" title="Click to convert Lease Return to Used">
            <b-icon pack="fal" icon="exchange" custom-size="fa-lg"></b-icon>
        </button>
    </div>
    <portal to="global-modal-portal" v-else>
        <b-modal :active.sync="isConvertLeaseConfirmationActive" :width="640" scroll="keep" :has-modal-card="true" :canCancel="false">
            <div class="modal-card" style="width: auto">
            <header class="modal-card-head">
                <p class="modal-card-title" v-if="convertedInventory === null">Confirm Convert Lease Return</p>
                <p class="modal-card-title" v-else>Lease Return Converted Successfully</p>
            </header>
            <section class="modal-card-body">
              <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
              <div v-if="convertedInventory === null">
                <p>Are you sure you want to convert <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a> from a lease entry to purchased?</p>
              </div>
              <div v-if="convertedInventory !== null">
                <p>Lease Return <a @click.stop.prevent="onStockNoClick(inventoryItem)"><span class="has-text-weight-semibold">{{ inventoryItem.stockNo }}</span></a> has been succesfully converted to a purchased inventory item.</p>
                <br/>
                <p>A new inventory item has been generated with stock number <a @click.stop.prevent="onStockNoClick(convertedInventory)"><span class="has-text-weight-semibold">{{ convertedInventory.stockNo }}</span></a>.</p>
                <br/>
                <p>Additional updates are required. Please click the view button below to complete the conversion process.</p>
              </div>
            </section>
            <footer class="modal-card-foot">
                <button class="button is-danger" :class="{'is-loading': isSaving }" @click="onSave()" v-if="convertedInventory === null">Confirm Conversion</button>
                <button class="button is-success" @click="onShowConvertedInventory()" v-if="convertedInventory !== null">Complete New Stock-In</button>
                <button class="button" @click="onCancel" v-if="convertedInventory === null">Close</button>
            </footer>
            </div>
        </b-modal>
    </portal>
  </div>
</template>

<script>

import eventBus from '@/eventBus'
import inventoryService from '../../services/inventoryService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  name: 'ConvertLeaseReturnAction',
  props: {
    inventoryItem: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isConvertLeaseConfirmationActive: false,
      convertedInventory: null,
      isSaving: false,
      serverErrors: [],
      serverErrorMessage: '',
      instanceId: this.$uuid.v4()
    }
  },
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser']),
    hasConvertLeaseAccess: function () {
      let allowedPermissions = ['inventory.modify.convert-lease-return']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x)
      })
      return userHasAccess
    },
    isLeaseReturn: function () {
      return this.inventoryItem.type === 'LeaseReturn'
    },
    inventoryItemId: function () {
      return this.inventoryItem.id
    }
  },
  methods: {
    onShowConvertedInventory: function () {
      this.isConvertPpaConfirmationActive = false
      this.$router.push({name: 'EditInventory', params: { id: this.convertedInventory.id, defaultSalesPerson: this.currentUser, defaultPreEntry: false }})
    },
    onRequestConvertLease: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isConvertLeaseConfirmationActive = true
    },
    onSave: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true
      let inventoryModel = {
        ...this.inventoryItem,
        type: 'Purchased'
      }
      inventoryService.convertLeaseReturn(inventoryModel).then(response => {
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.convertedInventory = response
        }
        this.isSaving = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onCancel: function () {
      if (this.convertedInventory) {
        eventBus.$emit('inventory-changed', [this.convertedInventory])
      }
      this.isConvertLeaseConfirmationActive = false
    },
    reset: function () {
      this.convertedInventory = null
      this.serverErrors = []
      this.serverErrorMessage = []
    },
    onStockNoClick: function (inventoryItem) {
      let that = this

      this.$copyText(inventoryItem.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventoryItem.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    }
  },
  watch: {
    inventoryItemId: function (newValue, oldValue) {
      if (oldValue && newValue) {
        this.reset()
      }
    }
  }
}

</script>

<style scoped>
  .content ul{
    list-style-type: none;
    margin-top: 0
  }

  .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }
</style>

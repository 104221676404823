<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Add Supplier</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmitAllScoped" @change="onFormChange">
      <b-tabs size="is-small" v-model="tabIndex" :animated="false" type="is-toggle">
        <b-tab-item label="Definition" icon-pack="fas"  icon="fas fa-info-circle fa-sm">
          <div class="columns">
            <div class="column is-2.5">
              <button-list-selector label="Partial Fill" v-model="selectedState" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Active" v-model="selectedActiveStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Blocked" v-model="selectedBlockedStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Allow Payment without Title" v-model="selectedTitleStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Trusted" v-model="selectedTrustStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
          </div>
          <div class="columns">
            <div class="column is-2.5">
              <button-list-selector label="We buyout their leases" v-model="selectedLeaseBuyoutStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
            </div>
            <div class="column is-2.5">
              <button-list-selector label="Type" v-model="selectedSupplierType" :availableValues="definedTypes.supplierTypes.options" :tabindex=0></button-list-selector>
            </div>
          </div>
          <div class="columns is-multiline">
            <div class="column is-6">
              <div class="field">
                <label class="label">Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                <div class="control">
                  <vue-google-autocomplete ref="compName" types="establishment" id="compName" classname="input" type="text" :value="name" placeholder="e.g EAG" v-on:placechanged="getNameAddressData" v-on:change="onChangeAutoComplete" :enable-geolocation="true" autocomplete="disabled" tabindex=1 @blur="onSearch"></vue-google-autocomplete>
                  <input type="hidden" name="name" data-vv-scope="page-1" data-vv-as="name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-1.name') }" v-model="name">
                  <span v-show="errors.has('page-1.name')" class="help is-danger"><i v-show="errors.has('page-1.name')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-1.name') }}</span>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Legal Name</label>
                <div class="control">
                  <input name="legalName" data-vv-scope="page-1" data-vv-as="legal name" :class="{'input': true }" type="text" v-model="legalName" tabindex=2>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Office</label>
                <div class="control">
                  <input name="office" data-vv-scope="page-1" data-vv-as="office" :class="{'input': true, 'is-danger': errors.has('page-1.office') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="office" tabindex=2 @blur="onSearch">
                </div>
              </div>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Fax</label>
                <div class="control">
                  <input name="fax" data-vv-as="fax" data-vv-scope="page-1" :class="{'input': true, 'is-danger': errors.has('page-1.fax') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="fax" tabindex=3>
                </div>
              </div>
            </div>
            <div class="column is-6">
              <user-selector scope="page-1" v-model="selectedManagedBy" validateAs="managed by" label="Managed By" :required="true"></user-selector>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Supplier Number</label>
                <div class="control">
                  <input name="supplierNumber" data-vv-as="supplier number" :class="{'input': true, 'is-danger': errors.has('supplierNumber') }" type="text" v-model="supplierNo">
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <switch-selector type="is-info" label="Terms Conditions Accepted" data-vv-scope="page-1" v-model="isTermsAccepted" :isDisabled="true"></switch-selector>
            </div>
            <div class="column is-6">
              <div class="field">
                <label class="label">Terms Conditions Accepted At</label>
                <div class="control">
                  <el-date-picker
                    data-vv-scope="page-1"
                    @change="onFormChange()"
                    v-model="this.termsAcceptedAt"
                    type="date"
                    format="MM-dd-yyyy"
                    placeholder="Click to select..."
                    :clearable="false"
                    :editable="false"
                    :disabled="true">
                </el-date-picker>
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <switch-selector type="is-info" label="Send Wholesale Payment ready SMS notification" v-model="wholesaleSendPaymentReadySms" tabindex="6"></switch-selector>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Contacts" icon-pack="fas" icon="fas fa-user-friends fa-sm">
          <div id="contact-section" class="box">
            <section-header title="Contacts" parentSelector="#contact-section"></section-header>
            <div v-for="(contact, index) in contacts" :key="contact.id" >
              <contact-capture scope="page-2" :index="index" @on-contact-remove="removeContact(contact)" @contactBuyerChanged="onNewContactBuyer" @contactSellerChanged="onNewContactSeller"
              v-model="contacts[index]" :isRequired="isPartial === 'required'" :isIcon="isIcon" :useValidator="thisValidator"></contact-capture>
              <br/>
            </div>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addContact(false)">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Bank" icon-pack="fas" icon="fas fa-info-circle fa-sm">
          <bank-details-capture scope="page-3" @formchange="onFormChange()" v-model="bankDetails" :getValidationErrors.sync="getValidationErrors" :bankDetailsRequired="isPartial === 'required'"
          :useValidator="thisValidator"></bank-details-capture>
        </b-tab-item>
        <b-tab-item label="Product" icon-pack="fas" icon="fas fa-barcode fa-sm">
          <div class="box configuration-content-block">
            <div class="columns">
              <div class="column is-12">
                <b-table :data="this.products" class="table">
                  <template slot-scope="props">
                      <b-table-column field="name" label="Name" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.name }}
                      </b-table-column>

                      <b-table-column field="description" label="Description" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.description }}
                      </b-table-column>

                      <b-table-column field="type" label="Type" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.type }}
                      </b-table-column>

                      <b-table-column field="cost" label="Cost" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.cost | currency }}
                      </b-table-column>

                      <b-table-column field="sellingPrice" label="Selling Price" sortable :class="{'striked': props.row.delete === true && props.row.id > 0}">
                        {{ props.row.sellingPrice | currency }}
                      </b-table-column>

                      <b-table-column field="" label="Actions">
                        <span class="icon" @click="editProduct(props.row, props.index)" v-if="props.row.delete !== true">
                          <i class="fal fa-pencil"></i>
                        </span>
                        <span class="icon" @click="removeProduct(props.index)" v-if="props.row.delete !== true">
                          <i class="fal fa-minus-circle"></i>
                        </span>
                        <span class="is-size-7 has-text-danger" v-if="props.row.delete">
                          Pending Delete
                        </span>
                      </b-table-column>
                  </template>
                  <template slot="empty">
                    <section class="section">
                      <div class="has-text-centered">
                        <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                        <p>No products have been added.</p>
                      </div>
                    </section>
                  </template>
                  <template slot="footer" v-if="availableProducts.length > 0">
                    <th colspan="4"></th>
                    <th colspan="1"><label class="label">{{productsTotal | currency}}</label></th>
                    <th colspan="1"></th>
                  </template>
                </b-table>
              </div>
            </div>
            <product-cost-capture :value="this.newProduct" :productCosts="this.products"></product-cost-capture>
            <div class="inner-add-remove-button">
              <a class="button is-primary" @click="addProduct()">
                <span class="icon">
                  <i class="fas fa-plus"></i>
                </span>
              </a>
            </div>
          </div>
        </b-tab-item>
        <b-tab-item label="Address" icon-pack="fas" icon="fas fa-address-card fa-sm">
          <address-capture scope="page-5" v-model="address" :required="selectedState && selectedState.id === 1" :useValidator="thisValidator"></address-capture>
        </b-tab-item>
        <b-tab-item label="Licenses" icon-pack="fas" icon="fas fa-camera fa-sm">
          <div class="columns">
            <div class="column is-6">
              <file-selector
              scope="page-6"
              v-model="dealerLicencePhotoFiles"
              label="Dealer License"
              :multiple="false"
              :required="isPartial === 'required'"
              validateAs="dealer license"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete">
              </file-selector>
            </div>
            <div class="column is-6">
              <label class="label">Dealer License Expiration<b-icon pack="fas" :icon="isPartial === 'required' ? 'star' : ''" class="fa-ss"></b-icon></label>
              <el-date-picker
                data-vv-scope="page-6"
                @change="onFormChange()"
                v-model="dealerLicenceExpiration"
                type="date"
                format="MM-dd-yyyy"
                name="dealerLicenceExpiration"
                id="dealerLicenceExpiration"
                data-vv-as="dealer license expiration"
                :class="{'is-danger': errors.has('dealerLicenceExpiration') }"
                v-validate="isPartial"
                popper-class="datepicker-container"
                placeholder="Click to select...">
              </el-date-picker>
              <span v-show="errors.has('page-5.dealerLicenceExpiration')" class="help is-danger">
                <i v-show="errors.has('page-5.dealerLicenceExpiration')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-5.dealerLicenceExpiration') }}
              </span>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <file-selector
              v-model="salesTaxCertificatePhotoFiles"
              label="Sales Tax Certificate"
              :multiple="false"
              :required="false"
              validateAs="sales text certificate"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete">
              </file-selector>
            </div>
            <div class="column is-6">
              <label class="label">Sales Tax Certificate Expiration<b-icon pack="fas" :icon="''" class="fa-ss"></b-icon></label>
              <el-date-picker
                @change="onFormChange()"
                v-model="salesTaxCertificateExpiration"
                type="date"
                format="MM-dd-yyyy"
                name="salesTaxCertificateExpiration"
                id="salesTaxCertificateExpiration"
                data-vv-as="sales tax certificate expiration"
                :class="{'is-danger': errors.has('salesTaxCertificateExpiration') }"
                v-validate=""
                popper-class="datepicker-container"
                placeholder="Click to select...">
              </el-date-picker>
              <span v-show="errors.has('salesTaxCertificateExpiration')" class="help is-danger">
                <i v-show="errors.has('salesTaxCertificateExpiration')" class="fas fa-exclamation-triangle"></i> {{ errors.first('salesTaxCertificateExpiration') }}
              </span>
            </div>
          </div>
          <div class="columns">
            <div class="column is-6">
              <file-selector
              v-model="w9PhotoFiles"
              label="W9"
              :multiple="false"
              :required="false"
              validateAs="w9"
              @uploadStarted="uploadStarted"
              @uploadComplete="uploadComplete">
              </file-selector>
            </div>
            <div class="column is-6">
              <label class="label">W9 Expiration<b-icon pack="fas" :icon="''" class="fa-ss"></b-icon></label>
              <el-date-picker
                @change="onFormChange()"
                v-model="w9Expiration"
                type="date"
                format="MM-dd-yyyy"
                name="w9Expiration"
                id="w9Expiration"
                data-vv-as="w9 expiration"
                :class="{'is-danger': errors.has('w9Expiration') }"
                v-validate=""
                popper-class="datepicker-container"
                placeholder="Click to select...">
              </el-date-picker>
              <span v-show="errors.has('w9Expiration')" class="help is-danger">
                <i v-show="errors.has('w9Expiration')" class="fas fa-exclamation-triangle"></i> {{ errors.first('w9Expiration') }}
              </span>
            </div>
          </div>
        </b-tab-item>
      </b-tabs>
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button :disabled="isUploadingFile" class="button is-primary" :class="{'is-loading': isSaving }"  type="button" @click="validateBeforeSubmitAllScoped">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>
    <b-modal :active.sync="isMatchingSuppliersActive" :has-modal-card="true" :canCancel="['escape', 'x']">
      <div class="modal-card" style="width: auto;">
        <header class="modal-card-head">
          <p class="modal-card-title">Possible Matching Suppliers</p>
        </header>
        <section class="modal-card-body" style="width: auto; max-height: 80vh; overflow-y: auto;">
          <p style="width:600px;">Matching suppliers found with the information entered. To enter a different supplier, navigate to the configuration - suppliers menu item.</p>
          <br />
          <p style="width:600px;" class="has-text-danger" v-if="startWith !== ''">Select the supplier to use below or close this dialog and continue to enter your supplier information. NB: Make sure that are not creating a duplicate supplier.</p>
          <br />
          <div>
            <table class="table is-bordered is-narrow is-hoverable is-fullwidth">
              <thead>
                <tr>
                  <th v-if="startWith !== ''"></th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Office</th>
                  <th>Primary Contact</th>
                </tr>
              </thead>
              <tr v-for="(matchingSupplier, index) in matchingSuppliers" :key="index">
                <td v-if="startWith !== ''"><div class="block"><b-radio v-model="selectedId" :native-value="matchingSupplier.id" /></div></td>
                <td>{{ matchingSupplier.name }}</td>
                <td>{{ matchingSupplier.email }}</td>
                <td>{{ matchingSupplier.office }}</td>
                <td>{{ matchingSupplier.primaryContact }}</td>
              </tr>
            </table>
          </div>

        </section>
        <footer class="modal-card-foot">
          <button class="button is-primary" @click="onSelectMatchingSupplier()" type="button" v-if="selectedId !== null">Select</button>
          <button class="button" @click="isMatchingSuppliersActive = false" type="button">Close</button>
        </footer>
      </div>
    </b-modal>
    <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
  </section>
</template>

<script>
import supplierService from '@/services/supplierService'
import AddressCapture from '@/components/generic/AddressCapture'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import completeCancelInitMixin from '@/mixins/generic/completeCancelInit'
import supplierMixin from '@/mixins/supplier/index'
import BankDetailsCapture from '@/components/generic/BankDetailsCapture'
import UserSelector from '@/components/generic/UserSelector'
import FileSelector from '@/components/generic/FileSelector'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import ContactCapture from '@/components/generic/ContactCapture'
import SectionHeader from '@/components/generic/SectionHeader'
import SwitchSelector from '@/components/generic/SwitchSelector'
import _ from 'lodash'

import { createNamespacedHelpers } from 'vuex'
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'NewSupplier',
  mixins: [supplierMixin, validateBeforeSubmitMixin, completeCancelInitMixin],
  components: {
    'address-capture': AddressCapture,
    'bank-details-capture': BankDetailsCapture,
    'file-selector': FileSelector,
    'button-list-selector': ButtonListSelector,
    'contact-capture': ContactCapture,
    'section-header': SectionHeader,
    'user-selector': UserSelector,
    'switch-selector': SwitchSelector
  },
  props: {
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full-screen'
    },
    startWith: {
      type: String,
      default: ''
    },
    fulfillForDepositCaptureMode: {
      type: Boolean,
      default: false
    },
    rejected: {
      type: Boolean,
      default: false
    },
    requiredParentId: {
      type: Number,
      default: 0
    }
  },
  data () {
    return {
      id: 0,
      parentId: 0,
      name: this.$toTitleCase(this.startWith),
      office: '',
      fax: '',
      serverErrors: [],
      serverErrorMessage: '',
      tabIndex: 0,
      isSaving: false,
      contacts: [],
      address: {
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: ''
      },
      bankDetails: {
        accountHolder: '',
        accountNo: '',
        accountType: null,
        branchNo: '',
        branchName: '',
        swiftNo: '',
        selectedBank: null,
        selectedAccountType: null,
        hasValidationErrors: false,
        canSave: null,
        selectedDefaultMethod: null
      },
      selectedManagedBy: null,
      getValidationErrors: false,
      dealerLicencePhotoFiles: [],
      salesTaxCertificatePhotoFiles: [],
      dealerLicenceExpiration: null,
      salesTaxCertificateExpiration: null,
      isPartial: 'required',
      isPartialEmail: 'required|email',
      selectedState: null,
      selectedActiveStatus: null,
      selectedBlockedStatus: null,
      selectedTitleStatus: null,
      selectedTrustStatus: null,
      selectedSupplierType: null,
      isIcon: 'none',
      matchingSuppliers: null,
      isLoading: false,
      isMatchingSuppliersActive: false,
      selectedId: null,
      addressSelected: false,
      isTermsAccepted: false,
      termsAcceptedAt: new Date(),
      w9PhotoFiles: [],
      w9Expiration: null,
      legalName: '',
      selectedLeaseBuyoutStatus: null,
      products: [],
      newProduct: this.createGenericProduct(),
      dealerId: 0,
      supplierNo: '',
      wholesaleSendPaymentReadySms: false
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    canSave: function () {
      return this.bankDetails.canSave
    },
    humanizedIsTermsAccepted: function () {
      return this.isTermsAccepted ? 'Yes' : 'No'
    }
  },
  methods: {
    onValidationFailure: function () {
      this.serverErrors = [{
        errorMessage: 'One or more required fields requires your attention. Please check all tabs for invalid fields.'
      }]
    },
    onSave: function () {
      this.getValidationErrors = true
    },
    onCancel: function () {
      this.$emit('on-cancel')
      if (this.mode === 'full-screen') {
        this.onComplete()
      }
    },
    getNameAddressData: function (addressData, place, id) {
      this.name = place.name
      this.addressSelected = true

      let addressComponents = place.address_components
      this.address.address1 = ''
      this.address.address2 = ''
      this.address.address3 = ''
      this.address.address4 = ''
      this.address.postalCode = ''
      this.address.state = ''
      this.address.city = ''
      this.address.gpsAddress = ''
      this.address.country = ''

      for (var i = 0; i < addressComponents.length; i++) {
        var addressType = addressComponents[i].types[0]
        if (addressType === 'street_number') {
          var streetNumber = addressComponents[i]['short_name']
          this.address.address1 = streetNumber
        }

        if (addressType === 'route') {
          var streetName = addressComponents[i]['short_name']
          this.address.address1 += ' ' + streetName
        }

        if (place.vicinity) {
          var vicinity = place.vicinity
          this.address.address2 = vicinity
        }

        if (addressType === 'locality') {
          var city = addressComponents[i]['short_name']
          this.address.city = city
        }

        if (addressType === 'administrative_area_level_1') {
          var state = addressComponents[i]['long_name']
          this.address.state = state
        }

        if (addressType === 'country') {
          var country = addressComponents[i]['long_name']
          this.address.country = country
        }

        if (addressType === 'postal_code') {
          var postalCode = addressComponents[i]['short_name']
          this.address.postalCode = postalCode
        }
      }

      if (addressData.latitude && addressData.longitude) {
        this.address.gpsAddress = addressData.latitude + ',' + addressData.longitude
      }
    },
    onChangeAutoComplete: function () {
      if (this.addressSelected) {
        this.$refs.compName.update(this.name)
        this.addressSelected = false
      }
      this.name = this.$refs.compName._data.autocompleteText
    },
    getViewRoute: function () {
      return {name: 'ListSuppliers'}
    },
    onCanSave: function () {
      if (!this.bankDetails.hasValidationErrors) {
        var supplierModel = {
          isCreate: true,
          subscriberId: this.$store.state.user.currentUser.subscriberId,
          parentId: this.parentId,
          name: this.name,
          office: this.office,
          fax: this.fax,
          contacts: [],
          address1: this.address.address1,
          address2: this.address.address2,
          address3: this.address.address3,
          address4: this.address.address4,
          city: this.address.city,
          country: this.address.country,
          postalCode: this.address.postalCode,
          state: this.address.state,
          gpsAddress: this.address.gpsAddress,
          bankId: this.bankDetails.selectedBank ? this.bankDetails.selectedBank.id : 0,
          accountHolder: this.bankDetails.accountHolder || '',
          accountNo: this.bankDetails.accountNo || '',
          accountType: this.bankDetails.selectedAccountType ? this.bankDetails.selectedAccountType.name : 'Check',
          branchNo: this.bankDetails.branchNo || '',
          branchName: this.bankDetails.branchName || '',
          swiftNo: this.bankDetails.swiftNo || '',
          dealerLicenceExpiration: this.dealerLicenceExpiration,
          salesTaxCertificateExpiration: this.salesTaxCertificateExpiration,
          status: this.selectedActiveStatus.id === 0 ? 'Active' : 'InActive',
          isPartial: this.selectedState.id === 0,
          isBlocked: this.selectedBlockedStatus.id === 0,
          allowPaymentWithoutTitle: this.selectedTitleStatus.id === 0,
          trusted: this.selectedTrustStatus.id === 0,
          defaultPaymentMethod: this.bankDetails.selectedDefaultMethod ? this.bankDetails.selectedDefaultMethod.name : 'Check',
          managedBy: (this.selectedManagedBy) ? this.selectedManagedBy.id : null,
          isTermsAccepted: this.isTermsAccepted,
          termsAcceptedAt: this.termsAcceptedAt,
          w9Expiration: this.w9Expiration,
          legalName: this.legalName,
          supplierLeaseBuyout: this.selectedLeaseBuyoutStatus.id === 0,
          supplierType: this.selectedSupplierType ? this.selectedSupplierType.altName : 'VehicleSupplier',
          products: [],
          supplierNo: this.supplierNo,
          wholesaleSendPaymentReadySms: this.wholesaleSendPaymentReadySms
        }

        if (this.rejected) supplierModel.status = 'Rejected'

        this.saveContact(supplierModel)
        this.saveProduct(supplierModel)

        if (this.dealerLicencePhotoFiles.length > 0) {
          var dealerLicencePhotoFile = this.dealerLicencePhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          supplierModel.dealerLicenceUploadName = dealerLicencePhotoFile[0].uploadName
          supplierModel.dealerLicenceUniqueId = dealerLicencePhotoFile[0].uniqueId
        }

        if (this.salesTaxCertificatePhotoFiles.length > 0) {
          var salesTaxCertificatePhotoFile = this.salesTaxCertificatePhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          supplierModel.salesTaxCertificateUploadName = salesTaxCertificatePhotoFile[0].uploadName
          supplierModel.salesTaxCertificateUniqueId = salesTaxCertificatePhotoFile[0].uniqueId
        }

        if (this.w9PhotoFiles.length > 0) {
          let w9PhotoFile = this.w9PhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          supplierModel.w9UploadName = w9PhotoFile[0].uploadName
          supplierModel.w9UniqueId = w9PhotoFile[0].uniqueId
        }

        this.isSaving = true
        this.serverErrorMessage = ''
        this.serverErrors = []

        supplierService.save(supplierModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.onChangesSaved()
            this.$emit('on-save', response.supplier)
            if (this.mode === 'full-screen') {
              this.onComplete()
            }
          }
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isSaving = false
        })
      }
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.dealerId,
        sortBy: 'name.asc',
        supplierType: '-1'
      }
      this.isLoading = true
      this.matchingSuppliers = null
      supplierService.all(searchCommand).then(response => {
        this.matchingSuppliers = response.results
        this.isLoading = false
        if (this.matchingSuppliers && this.matchingSuppliers.length > 0) {
          this.isMatchingSuppliersActive = true
        }
      })
    },
    doSupplierSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onSearch (el) {
      if (el && el.target && el.target.value !== '') {
        this.doSupplierSearch(el.target.value)
      }
    },
    onSelectMatchingSupplier: function () {
      this.isMatchingSuppliersActive = false
      let matchingSupplier = this.matchingSuppliers.find((x) => x.id === this.selectedId)
      this.$emit('on-save', matchingSupplier)
    }
  },
  watch: {
    selectedState: function (newValue, oldValue) {
      if (this.selectedState && this.selectedState.id === 1) {
        if (this.selectedSupplierType && this.selectedSupplierType.id !== 1) {
          this.isPartial = 'required'
          this.isPartialEmail = 'required|email'
          this.isIcon = 'star'
        } else {
          this.isPartial = ''
          this.isPartialEmail = 'email'
          this.isIcon = 'none'
        }
      } else {
        this.isPartial = ''
        this.isPartialEmail = 'email'
        this.isIcon = 'none'
      }
    },
    selectedSupplierType: function (newValue, oldValue) {
      if (this.selectedSupplierType && this.selectedSupplierType.id !== 1) {
        if (this.selectedState && this.selectedState.id === 1) {
          this.isPartial = 'required'
          this.isPartialEmail = 'required|email'
          this.isIcon = 'star'
        } else {
          this.isPartial = ''
          this.isPartialEmail = 'required|email'
          this.isIcon = 'star'
        }
      } else {
        this.isPartial = ''
        this.isPartialEmail = 'required|email'
        this.isIcon = 'star'
      }
    },
    canSave: function (newValue, oldValue) {
      if (newValue && newValue === true) {
        this.onCanSave()
      }
      this.getValidationErrors = false
      this.bankDetails.canSave = null
    }
  },
  mounted: function () {
    this.dealerId = this.$store.state.dealer.currentDealer.id
    this.selectedState = (this.rejected) ? this.definedTypes.fillTypes.options.find((x) => x.id === 0) : this.definedTypes.fillTypes.options.find((x) => x.id === 1)
    this.selectedActiveStatus = this.definedTypes.fillTypes.options.find((x) => x.id === 0)
    this.selectedBlockedStatus = this.definedTypes.fillTypes.options.find((x) => x.id === 1)
    this.selectedTitleStatus = this.definedTypes.fillTypes.options.find((x) => x.id === 1)
    this.selectedTrustStatus = this.definedTypes.fillTypes.options.find((x) => x.id === 1)
    this.selectedSupplierType = this.definedTypes.supplierTypes.options.find((x) => x.id === 0)
    this.bankDetails.selectedDefaultMethod = this.definedTypes.paymentMethods.find((x) => x.id === 0)
    this.selectedManagedBy = this.currentUser
    this.selectedLeaseBuyoutStatus = this.definedTypes.fillTypes.options.find((x) => x.id === 1)
    this.addContact(true)
    this.parentId = this.requiredParentId
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

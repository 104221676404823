export default {
  all: {
    valid: true,
    vin: 'WP0AD2A9XFS166895',
    originalAvailableInMarket: 356,
    availableInMarket: 32,
    originalMsrp: 151100,
    appraisedValue: -271513.06757472,
    baseAppraisedValue: 127422.09375,
    mileageAdjustmentValue: -466813.4282184,
    avgDaysOnMarket: 137.59375,
    avgDaysSupply: null,
    estWholesaleValue: -266980.06757472,
    marketAskingPrice: -339391.3344684
  }
}

<template>
  <section style="margin: 0px; padding: 0px;">
    <div v-if="!isWireRequest && !hideActionIcon">
      <div class="quick-action is-pulled-left" v-if="!isVerified && !isListMode">
        <button type="button" class="button" @click="onOpen()"  title="Click to verify supplier">
          <b-icon pack="fal" icon="fal fa-badge-check has-text-danger" :custom-size="iconCustomSize"></b-icon>
        </button>
      </div>
      <div v-if="!isVerified && isListMode">
        <span @click.stop.prevent="onOpen()" style="cursor: pointer;">
          <b-icon pack="fal" icon="fal fa-badge-check" class="has-text-danger" :custom-size="iconCustomSize" title="Click to verify supplier"></b-icon>
        </span>
      </div>

      <div class="quick-action" v-if="isVerified && !isListMode">
        <button type="button" class="button" title="Supplier verified">
          <span @click.stop.prevent="onOpen()" style="cursor: pointer;">
            <b-icon pack="fal" icon="fal fa-badge-check has-text-success" :custom-size="iconCustomSize"></b-icon>
          </span>
        </button>
      </div>
      <div v-if="isVerified && isListMode">
        <span @click.stop.prevent="onOpen()" style="cursor: pointer;">
          <b-icon pack="fal" icon="fal fa-badge-check" class="has-text-success" :custom-size="iconCustomSize" title="Click to verify supplier"></b-icon>
        </span>
      </div>
    </div>

    <div v-if="isWireRequest && !hideActionIcon">
      <div v-if="!isVerified || !hasCompletedRequestedWireQuestions">
        <span @click.stop.prevent="onOpen()" style="cursor: pointer;"><b-icon pack="fal" icon="fal fa-badge-check" class="has-text-danger" :custom-size="iconCustomSize" title="Click to verify supplier"></b-icon></span>
      </div>
      <div v-if="isVerified && hasCompletedRequestedWireQuestions">
        <span @click.stop.prevent="onOpen()" style="cursor: pointer;"><b-icon pack="fal" icon="fal fa-badge-check" class="has-text-success" :custom-size="iconCustomSize" title="Click to verify supplier"></b-icon></span>
      </div>
    </div>

    <portal to="global-modal-portal" v-if="verifySupplierControlActive" :disabled="!usePortal">
      <b-modal :active="verifySupplierControlActive" scroll="keep" has-modal-card full-screen  :canCancel="['escape', 'x']" id="verify-modal">
        <div class="modal-card" style="width: auto">
          <header class="modal-card-head">
            <p class="modal-card-title">{{title}}</p>
          </header>
          <section class="modal-card-body" style="padding: 10px 10px;">
            <div class="box has-background-grey-lighter">
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q1" v-show="permittedQuestions.includes('q1')">Did you call the business office?</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q2" v-show="permittedQuestions.includes('q2')">Did you verify the company name, phone number and address info?</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q3" v-show="permittedQuestions.includes('q3')">Did you verify the primary contact info?</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q4" v-show="permittedQuestions.includes('q4')">Did you verify that this request was made by this company with the proper payment information?</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q6" v-show="permittedQuestions.includes('q6')">Ask them, "Did you request this wire?"</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q7" v-show="permittedQuestions.includes('q7')">Ask them, "Read me the wire instructions to make sure we are sending to the right place."</b-checkbox>
              </div>
              <div class="field is-size-7">
                <b-checkbox v-model="chkQuestions" native-value="q5" v-show="permittedQuestions.includes('q5')">Have the supplier read you the wire instructions, then verify against our records. <b>DO NOT READ WHAT WE HAVE TO THE SUPPLIER</b></b-checkbox>
              </div>
            </div>
            <div class="tile is-ancestor">
              <div class="tile is-vertical is-parent">
                <form @submit.prevent="validateBeforeSubmitAllScoped">
                <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
                <div class="tile is-child box">
                  <div v-if="isStatusBlocked" class="notification is-danger is-flex">
                    <span>
                      <i class="fas fa-exclamation-triangle is-danger fa-lg"></i>
                    </span>
                    <span class="ml-10">
                      <p>Cannot verify BLOCKED/INACTIVE supplier. Click definition tab below to update.</p>
                    </span>
                  </div>
                  <b-tabs size="is-small" v-model="tabIndex" :animated="false" type="is-toggle">
                    <b-tab-item label="Call" icon-pack="fas" icon="fal fa-phone fa-sm">
                      <div class="box" style="background: #f6f8fa;">
                        <div class="notification is-info">Your number: <strong>{{trackingNumber}}</strong></div>
                        <table class="table is-fullwidth" v-if="callLines.length > 0">
                          <thead>
                            <tr>
                              <th><abbr title="Name">Name</abbr></th>
                              <th><abbr title="Line">Line</abbr></th>
                              <th><abbr title="Action">Action</abbr></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr v-for="(callLine, index) in callLines" :key="callLine.name + index">
                              <td>{{callLine.name}} <i class="fal fa-male has-text-success" title="primary contact" v-if="callLine.isPrimary"></i></td>
                              <td>{{callLine.line}}</td>
                              <td>
                                <span class="quick-action">
                                  <button type="button" class="button" :class="{'is-loading': callLine.callInitiated }" @click="onInitiateCall(callLine)">
                                    <b-icon pack="fal" icon="fal fa-phone" custom-size="fa-lg" :class="{'has-text-danger': callLine.callFailed }"></b-icon>
                                  </button>
                                </span>
                                {{( callLine.callInitiated) ? 'initiating call...' : ''}}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div class="field is-size-7" v-if="callLines.length === 0">No supplier phone numbers found.</div>
                        <div class="columns is-size-7 is-multiline">
                          <div class="column is-12">
                            <b-checkbox v-model="chkUseAlternateLine">Alternate lines to dial?</b-checkbox>
                          </div>
                          <div class="column is-4" v-if="chkUseAlternateLine">
                            <label class="label">Your Number<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                            <input name="alternateLine" v-validate="{'min': 16, 'max': 16}" :class="{'input': true, 'is-danger': errors.has('alternateLine') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="alternateLine">
                            <span v-show="errors.has('alternateLine')" class="help is-danger">
                              <i v-show="errors.has('alternateLine')" class="fas fa-exclamation-triangle"></i> {{ errors.first('alternateLine') }}
                            </span>
                          </div>
                          <div class="column is-4" v-if="chkUseAlternateLine">
                            <label class="label">Supplier Number<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                            <input name="alternateSupplierLine" v-validate="{'min': 16, 'max': 16}" :class="{'input': true, 'is-danger': errors.has('alternateSupplierLine') }" type="text"  placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="alternateSupplierLine">
                            <span v-show="errors.has('alternateSupplierLine')" class="help is-danger">
                              <i v-show="errors.has('alternateSupplierLine')" class="fas fa-exclamation-triangle"></i> {{ errors.first('alternateSupplierLine') }}
                            </span>
                          </div>
                          <div class="column is-1 control" style="margin-top: 5px;" v-if="chkUseAlternateLine">
                            <label class="label">&nbsp;</label>
                            <span class="quick-action">
                              <button type="button" class="button" style="margin-top: -6px;" @click="onInitiateAlternateCall()" :class="{'is-loading': alternateCallInitiated }" :disabled="isAlternateLineBtnDisabled">
                                <b-icon pack="fal" icon="fal fa-phone" custom-size="fa-lg" :class="{'has-text-danger': alternateCallFailed }"></b-icon>
                              </button>
                            </span>
                          </div>
                          <div class="column is-2" v-if="chkUseAlternateLine">
                            {{( alternateCallInitiated) ? 'initiating call...' : ''}}
                          </div>
                        </div>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Definition" icon-pack="fas"  icon="fas fa-info-circle fa-sm">
                      <div class="box" style="background: #f6f8fa;">
                        <div class="columns is-multiline">
                          <div class="column is-6">
                            <button-list-selector label="Active" v-model="selectedActiveStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
                          </div>
                          <div class="column is-6">
                            <button-list-selector label="Blocked" v-model="selectedBlockedStatus" :availableValues="definedTypes.fillTypes.options" :tabindex=0></button-list-selector>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <div class="control">
                                <input data-vv-scope="page-2" name="name" data-vv-as="name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-2.name') }" v-model="supplier.name">
                                <span v-show="errors.has('page-2.name')" class="help is-danger"><i v-show="errors.has('page-2.name')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.name') }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Legal Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <div class="control">
                                <input name="legalName" data-vv-scope="page-2" data-vv-as="legal name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('page-2.legalName') }" type="text" v-model="supplier.legalName" tabindex=2>
                                <span v-show="errors.has('page-2.legalName')" class="help is-danger"><i v-show="errors.has('page-2.legalName')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.legalName') }}</span>
                              </div>
                              <label class="label-frontgp is-pulled-right" :class="{'has-text-danger': supplier.legalName !== supplier.name, 'has-text-success': supplier.legalName === supplier.name}"
                                @click="supplier.legalName = supplier.name" title="click to make this the legal name.">{{supplier.name}}</label>
                            </div>
                          </div>
                        </div>
                        <div class="columns is-multiline">
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Office<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                              <div class="control">
                                <input name="office" data-vv-scope="page-2" data-vv-as="office" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('office') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="supplier.office" tabindex=3>
                                <span v-show="errors.has('page-2.office')" class="help is-danger"><i v-show="errors.has('page-2.office')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-2.office') }}</span>
                              </div>
                            </div>
                          </div>
                          <div class="column is-6">
                            <div class="field">
                              <label class="label">Fax</label>
                              <div class="control">
                                <input name="fax" data-vv-as="fax" :class="{'input': true, 'is-danger': errors.has('fax') }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="supplier.fax" tabindex=4>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Contacts" icon-pack="fas" icon="fas fa-user-friends fa-sm">
                      <div v-for="(contact, index) in contacts" :key="contact.id + index">
                        <contact-capture scope="page-3" :index="index" @on-contact-remove="removeContact" @contactBuyerChanged="onNewContactBuyer"
                            @contactSellerChanged="onNewContactSeller" v-model="contacts[index]" @autoSelectPrimaryContact="onAutoSelectPrimaryContact"
                            :isRequired="true" isIcon="star" :useValidator="thisValidator"></contact-capture>
                        <br/>
                      </div>
                      <div class="inner-add-remove-button">
                        <a class="button is-primary" @click="addContact(false)">
                          <span class="icon">
                            <i class="fas fa-plus"></i>
                          </span>
                        </a>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Bank" icon-pack="fas" icon="fas fa-info-circle fa-sm">
                      <div class="box" style="background: #f6f8fa;">
                        <div v-if="isDefaultPaymentMethodLocked" class="notification is-info is-flex">
                          <span>
                            <i class="fas fa-info-circle is-info fa-lg"></i>
                          </span>
                          <span class="ml-10">
                            <p>Default Payment Method is locked to {{overridePaymentType}}. This is the requested payment method. </p>
                          </span>
                        </div>
                        <bank-details-capture scope="page-4" @formchange="onFormChange()" v-model="bankDetails" :getValidationErrors.sync="getValidationErrors"
                          :bankDetailsRequired="true" :useValidator="thisValidator" :disableDefaultMethod="isDefaultPaymentMethodLocked">
                        </bank-details-capture>
                      </div>
                    </b-tab-item>
                    <b-tab-item label="Address" icon-pack="fas" icon="fas fa-address-card fa-sm">
                      <address-capture scope="page-5" v-model="address" :required="true" :useValidator="thisValidator"></address-capture>
                    </b-tab-item>
                    <b-tab-item label="Licenses" icon-pack="fas" icon="fas fa-camera fa-sm">
                      <div class="columns">
                        <div class="column is-6">
                          <file-selector
                          scope="page-6"
                          :useValidator="thisValidator"
                          v-model="dealerLicencePhotoFiles"
                          label="Dealer License"
                          :multiple="false"
                          :required="true"
                          validateAs="dealer license"
                          :usePortalViewer="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                        </div>
                        <div class="column is-6">
                          <label class="label">Dealer License Expiration<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
                          <el-date-picker
                            data-vv-scope="page-6"
                            @change="onFormChange()"
                            v-model="dealerLicenceExpiration"
                            type="date"
                            format="MM-dd-yyyy"
                            name="dealerLicenceExpiration"
                            id="dealerLicenceExpiration"
                            data-vv-as="dealer license expiration"
                            :class="{'is-danger': errors.has('page-6.dealerLicenceExpiration') }"
                            v-validate="'required'"
                            popper-class="datepicker-container"
                            placeholder="Click to select...">
                          </el-date-picker>
                          <span v-show="errors.has('page-6.dealerLicenceExpiration')" class="help is-danger">
                            <i v-show="errors.has('page-6.dealerLicenceExpiration')" class="fas fa-exclamation-triangle"></i> {{ errors.first('page-6.dealerLicenceExpiration') }}
                          </span>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-6">
                          <file-selector
                          v-model="salesTaxCertificatePhotoFiles"
                          label="Sales Tax Certificate"
                          :multiple="false"
                          validateAs="sales tax certificate"
                          :usePortalViewer="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                        </div>
                        <div class="column is-6">
                          <label class="label">Sales Tax Certificate Expiration</label>
                          <el-date-picker
                            @change="onFormChange()"
                            v-model="salesTaxCertificateExpiration"
                            type="date"
                            format="MM-dd-yyyy"
                            name="salesTaxCertificateExpiration"
                            id="salesTaxCertificateExpiration"
                            data-vv-as="sales tax certificate expiration"
                            :class="{'is-danger': errors.has('salesTaxCertificateExpiration') }"
                            popper-class="datepicker-container"
                            placeholder="Click to select...">
                          </el-date-picker>
                          <span v-show="errors.has('salesTaxCertificateExpiration')" class="help is-danger">
                            <i v-show="errors.has('salesTaxCertificateExpiration')" class="fas fa-exclamation-triangle"></i> {{ errors.first('salesTaxCertificateExpiration') }}
                          </span>
                        </div>
                      </div>
                      <div class="columns">
                        <div class="column is-6">
                          <file-selector
                          v-model="w9PhotoFiles"
                          label="W9"
                          :multiple="false"
                          validateAs="w9"
                          :usePortalViewer="false"
                          @uploadStarted="uploadStarted"
                          @uploadComplete="uploadComplete">
                          </file-selector>
                        </div>
                        <div class="column is-6">
                          <label class="label">W9 Expiration</label>
                          <el-date-picker
                            @change="onFormChange()"
                            v-model="w9Expiration"
                            type="date"
                            format="MM-dd-yyyy"
                            name="w9Expiration"
                            id="w9Expiration"
                            data-vv-as="w9 expiration"
                            :class="{'is-danger': errors.has('w9Expiration') }"
                            popper-class="datepicker-container"
                            placeholder="Click to select...">
                          </el-date-picker>
                          <span v-show="errors.has('w9Expiration')" class="help is-danger">
                            <i v-show="errors.has('w9Expiration')" class="fas fa-exclamation-triangle"></i> {{ errors.first('w9Expiration') }}
                          </span>
                        </div>
                      </div>
                    </b-tab-item>
                  </b-tabs>
                </div>
                </form>
              </div>
              <div class="file-container" style="cursor: pointer;">
                  <i class="fal fa-arrow-alt-from-left is-pulled-right" @click="toggleDocView(false)" title="Click to collapse doc view" v-if="isDocViewActive"></i>
                  <i class="fal fa-arrow-alt-from-right is-pulled-right" title="Click to expand doc view" @click="toggleDocView(true)" v-if="!isDocViewActive"></i>
              </div>
              <div class="tile is-parent is-vertical is-6" style="overflow-y: scroll;height: 68vh;" v-if="isDocViewActive">
                <error-display-component :serverErrors="[]" :serverErrorMessage="''"></error-display-component>
                <div class="tile is-child box">
                  <div class="file-container" v-show="isDocViewActive">
                    <file-selector v-model="dealerLicencePhotoFiles" :label="getDealerLicenceIconTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
                    :showFileOnly="true" :usePortalViewer="false" :inline="true"></file-selector>
                  </div>
                  <div class="file-container" v-show="isDocViewActive">
                    <file-selector v-model="salesTaxCertificatePhotoFiles" :label="getSalesTaxCertificateIconTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
                    :showFileOnly="true" :usePortalViewer="false" :inline="true"></file-selector>
                  </div>
                  <div class="file-container" v-show="isDocViewActive">
                    <file-selector v-model="w9PhotoFiles" :label="getW9IconTitle" :required="false" :multiple="false" :isBoxed="false" :enabled="false" displaySize="50"
                    :showFileOnly="true" :usePortalViewer="false" :inline="true"></file-selector>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <footer class="modal-card-foot">
            <button class="button is-primary" :class="{'is-loading': isSaving }" type="button" :disabled="!canSave || isUploadingFile" @click="validateBeforeSubmitAllScoped()">Save</button>
            <button class="button is-danger" @click.stop.prevent="onClose()" type="button" id="vs-close">Cancel</button>
          </footer>
          <b-loading :is-full-page="true" :active.sync="isLoading" :canCancel="false"></b-loading>
        </div>
      </b-modal>
    </portal>
  </section>
</template>
<script>

import supplierService from '@/services/supplierService'
import notificationService from '@/services/notificationService'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import utilitiesMixin from '@/mixins/generic/utilities'
import ContactCapture from '@/components/generic/ContactCapture'
import BankDetailsCapture from '@/components/generic/BankDetailsCapture'
import AddressCapture from '@/components/generic/AddressCapture'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import FileSelector from '@/components/generic/FileSelector'
import eventBus from '@/eventBus'
import { createNamespacedHelpers } from 'vuex'
import _ from 'lodash'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'VerifySupplierControl',
  mixins: [utilitiesMixin, validateBeforeSubmitMixin],
  components: {
    'error-display-component': ErrorDisplayComponent,
    'contact-capture': ContactCapture,
    'bank-details-capture': BankDetailsCapture,
    'address-capture': AddressCapture,
    'file-selector': FileSelector,
    'button-list-selector': ButtonListSelector
  },
  props: {
    value: {
      type: Object,
      default: () => ({ name: '', office: '', legalName: '', fax: '' })
    },
    supplierId: {
      type: Number,
      default: null
    },
    isVerifiedLocalProp: {
      type: Boolean,
      default: false
    },
    usePortal: {
      type: Boolean,
      default: true
    },
    isWireRequest: {
      type: Boolean,
      default: false
    },
    hasCompletedRequestedWireQuestions: {
      type: Boolean,
      default: false
    },
    overridePaymentType: {
      type: String,
      default: null
    },
    hideActionIcon: {
      type: Boolean,
      default: false
    },
    iconCustomSize: {
      type: String,
      default: 'fa-lg'
    }
  },
  data () {
    return {
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      isLoading: false,
      verifySupplierControlActive: false,
      supplier: this.value,
      chkQuestions: [],
      callLines: [],
      tabIndex: 0,
      contacts: [],
      bankDetails: {
        accountHolder: '',
        accountNo: '',
        accountType: null,
        branchNo: '',
        branchName: '',
        swiftNo: '',
        selectedBank: null,
        selectedAccountType: null,
        hasValidationErrors: false,
        canSave: null,
        selectedDefaultMethod: null
      },
      getValidationErrors: false,
      address: {
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: ''
      },
      addressSelected: false,
      chkUseAlternateLine: false,
      alternateLine: '',
      alternateCallInitiated: false,
      alternateCallFailed: false,
      alternateSupplierLine: '',
      dealerLicencePhotoFiles: [],
      dealerLicenceExpiration: null,
      salesTaxCertificatePhotoFiles: [],
      salesTaxCertificateExpiration: null,
      w9PhotoFiles: [],
      w9Expiration: null,
      isDocViewActive: true,
      selectedActiveStatus: null,
      selectedBlockedStatus: null,
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    canSave: function () {
      let totalQuestionsChecked = this.chkQuestions.length
      let permittedTotal = this.permittedQuestions.length
      return totalQuestionsChecked === permittedTotal && !this.isStatusBlocked
    },
    isVerified: function () {
      return (!this.isListMode || this.verifySupplierControlActive === true) ? this.supplier.verified : this.isVerifiedLocalProp
    },
    trackingNumber: function () {
      // need to set tracking number, first check on user then dealer. We use this with InteractiveTel sms gateway
      return (this.currentUser.activeTrackingNumber && this.currentUser.activeTrackingNumber !== '') ? this.currentUser.activeTrackingNumber : this.currentDealer.defaultCallTrackingNumber
    },
    isListMode: function () {
      return this.supplierId !== null
    },
    title: function () {
      let title = 'Verify Supplier'
      return (this.supplier) ? title + ' - ' + this.supplier.name : title
    },
    hasSupplierVerifyAccess: function () {
      let allowedPermissions = ['supplier.verify']
      var userHasAccess = false
      allowedPermissions.forEach((x) => {
        userHasAccess = userHasAccess || this.hasFeatureAccess(x, false)
      })
      return userHasAccess
    },
    isAlternateLineBtnDisabled: function () {
      return !(this.alternateLine && this.alternateLine.length === 16 && this.alternateSupplierLine && this.alternateSupplierLine.length === 16)
    },
    isPartialSupplier: function () {
      return (this.supplier) ? this.supplier.isPartial : false
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    selectedDefaultMethod: function () {
      return this.bankDetails.selectedDefaultMethod
    },
    defaultPaymentMethod: function () {
      return this.selectedDefaultMethod ? this.selectedDefaultMethod.name : 'Check'
    },
    hasDealerLicence: function () {
      return this.dealerLicencePhotoFiles.length > 0
    },
    hasSalesTaxCertificate: function () {
      return this.salesTaxCertificatePhotoFiles.length > 0
    },
    hasW9: function () {
      return this.w9PhotoFiles.length > 0
    },
    getW9IconTitle: function () {
      return this.hasW9 ? 'W9' : 'W9 - Not Provded'
    },
    getDealerLicenceIconTitle: function () {
      return this.hasDealerLicence ? 'Dealer License' : 'Dealer License - Not Provided'
    },
    getSalesTaxCertificateIconTitle: function () {
      return this.hasSalesTaxCertificate ? 'Sale Tax Certificate' : 'Sale Tax Certificate - Not Provided'
    },
    permittedQuestions: function () {
      if (this.isWireRequest) {
        if (this.isVerified) {
          return ['q4', 'q6', 'q7']
        } else {
          return ['q1', 'q2', 'q3', 'q4', 'q5', 'q6', 'q7']
        }
      }

      if (this.defaultPaymentMethod === 'Wire') {
        return ['q1', 'q2', 'q3', 'q4', 'q5']
      }

      return ['q1', 'q2', 'q3', 'q4']
    },
    isActive: function () {
      return this.selectedActiveStatus && this.selectedActiveStatus.id === 0
    },
    isBlocked: function () {
      return this.selectedBlockedStatus && this.selectedBlockedStatus.id === 0
    },
    isStatusBlocked: function () {
      return !this.isActive || this.isBlocked
    },
    isDefaultPaymentMethodLocked: function () {
      return this.overridePaymentType !== null
    }
  },
  methods: {
    onClose: function () {
      this.$emit('on-open-sendwirecontrol')
      this.callLines = []
      this.contacts = []
      this.dealerLicencePhotoFiles = []
      this.salesTaxCertificatePhotoFiles = []
      this.w9PhotoFiles = []
      this.chkQuestions = []
      this.clearErrors()
      this.verifySupplierControlActive = false
    },
    onSave: function () {
      if (this.canSave) {
        var supplierModel = {
          ...this.supplier,
          name: this.supplier.name,
          office: this.supplier.office,
          fax: this.supplier.fax,
          contacts: [],
          address1: this.address.address1,
          address2: this.address.address2,
          address3: this.address.address3,
          address4: this.address.address4,
          city: this.address.city,
          country: this.address.country,
          postalCode: this.address.postalCode,
          state: this.address.state,
          gpsAddress: this.address.gpsAddress,
          bankId: this.bankDetails.selectedBank ? this.bankDetails.selectedBank.id : 0,
          accountHolder: this.bankDetails.accountHolder || '',
          accountNo: this.bankDetails.accountNo || '',
          accountType: this.bankDetails.selectedAccountType ? this.bankDetails.selectedAccountType.name : 'Check',
          branchNo: this.bankDetails.branchNo || '',
          branchName: this.bankDetails.branchName || '',
          swiftNo: this.bankDetails.swiftNo || '',
          defaultPaymentMethod: this.bankDetails.selectedDefaultMethod ? this.bankDetails.selectedDefaultMethod.name : 'Check',
          legalName: this.supplier.legalName,
          dealerLicenceExpiration: this.dealerLicenceExpiration,
          salesTaxCertificateExpiration: this.salesTaxCertificateExpiration,
          w9Expiration: this.w9Expiration,
          isPartial: false,
          status: this.isActive ? 'Active' : 'InActive',
          isBlocked: this.isBlocked
        }

        /*
        // restore uploads so that its not removed from server save
        supplierModel.dealerLicenceUploadName = supplierModel.dealerLicenceFileName
        supplierModel.dealerLicenceUniqueId = supplierModel.dealerLicenceFileName
        supplierModel.salesTaxCertificateUploadName = supplierModel.salesTaxCertificateFileName
        supplierModel.salesTaxCertificateUniqueId = supplierModel.salesTaxCertificateFileName
        supplierModel.w9UploadName = supplierModel.w9FileName
        supplierModel.w9UniqueId = supplierModel.w9FileName
        */

        if (this.dealerLicencePhotoFiles.length > 0) {
          var dealerLicencePhotoFile = this.dealerLicencePhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          supplierModel.dealerLicenceUploadName = dealerLicencePhotoFile[0].uploadName
          supplierModel.dealerLicenceUniqueId = dealerLicencePhotoFile[0].uniqueId
        }

        if (this.salesTaxCertificatePhotoFiles.length > 0) {
          var salesTaxCertificatePhotoFile = this.salesTaxCertificatePhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          supplierModel.salesTaxCertificateUploadName = salesTaxCertificatePhotoFile[0].uploadName
          supplierModel.salesTaxCertificateUniqueId = salesTaxCertificatePhotoFile[0].uniqueId
        }

        if (this.w9PhotoFiles.length > 0) {
          let w9PhotoFile = this.w9PhotoFiles.map((x) => {
            return {
              uploadName: x.name,
              uniqueId: x.uniqueId
            }
          })

          supplierModel.w9UploadName = w9PhotoFile[0].uploadName
          supplierModel.w9UniqueId = w9PhotoFile[0].uniqueId
        }

        this.saveContact(supplierModel)

        this.isSaving = true
        this.clearErrors()

        supplierService.verifySupplier(supplierModel).then(response => {
          this.isSaving = false
          if (response.errors) {
            this.failedToast('Oops! Something went wrong')
            this.serverErrors = response.errors
          } else {
            this.supplier = response.supplier
            this.supplier.verified = true
            this.$emit('on-sync-supplier-callback', this.supplier)
            eventBus.$emit('quickview-refresh')
            eventBus.$emit('reload-supplier-list')
            this.$validator.pause()
            this.onClose()
            this.successToast('Success! Supplier Verified')
          }
        }).catch((error) => {
          this.failedToast('Oops! Something went wrong')
          this.isSaving = false
          this.serverErrorMessage = error.message
        })
      }
    },
    onInitiateCall: function (callLine) {
      let callModel = { source: this.trackingNumber, destination: callLine.line, apiKey: this.currentDealer.apiKeyInteactiveTel, dealerId: this.currentDealer.id, receiverType: 'Wholesaler', receiverId: this.supplier.id }
      callLine.callInitiated = true
      this.initiateCallService(callModel, callLine)
    },
    onInitiateAlternateCall: function () {
      let callModel = { source: this.alternateLine, destination: this.alternateSupplierLine, apiKey: this.currentDealer.apiKeyInteactiveTel, dealerId: this.currentDealer.id, receiverType: 'Wholesaler', receiverId: this.supplier.id }
      this.alternateCallInitiated = true
      this.initiateCallService(callModel, null)
    },
    initiateCallService: function (callModel, callLine) {
      let isAlternateCall = !callLine
      this.clearErrors()
      notificationService.initiateCall(callModel).then(response => {
        if (isAlternateCall) {
          this.alternateCallInitiated = false
        } else {
          callLine.callInitiated = false
        }
        if (response.errors) {
          if (isAlternateCall) {
            this.alternateCallFailed = true
          } else {
            callLine.callFailed = true
          }
          this.failedToast('Oops! Something went wrong')
          this.serverErrors = response.errors
        } else {
          if (response.isSuccess) {
            if (isAlternateCall) {
              this.alternateCallFailed = false
            } else {
              callLine.callFailed = false
            }
            this.successToast('Success! call initiated')
          } else {
            if (isAlternateCall) {
              this.alternateCallFailed = true
            } else {
              callLine.callFailed = true
            }
            this.failedToast('Oops! Something went wrong')
            this.serverErrorMessage = response.callResult
          }
        }
      }).catch((error) => {
        this.failedToast('Oops! Something went wrong')
        if (isAlternateCall) {
          this.alternateCallInitiated = false
          this.alternateCallFailed = true
        } else {
          callLine.callInitiated = false
          callLine.callFailed = true
        }
        this.serverErrorMessage = error.message
      })
    },
    clearErrors: function () {
      this.serverErrors = []
      this.serverErrorMessage = ''
    },
    onOpen () {
      if (this.hasSupplierVerifyAccess) {
        this.callLines = []
        this.contacts = []
        this.dealerLicencePhotoFiles = []
        this.salesTaxCertificatePhotoFiles = []
        this.w9PhotoFiles = []
        this.chkQuestions = []
        if (this.supplierId > 0) {
          this.loadSupplier(this.supplierId)
        } else {
          this.loadSupplier(this.supplier.id)
        }
        this.verifySupplierControlActive = true
      }
    },
    loadSupplier (id) {
      this.isLoading = true
      supplierService.single(id).then(response => {
        this.supplier = response.supplier
        this.init()
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        this.serverErrorMessage = error.message
      })
    },
    init () {
      this.alternateLine = this.trackingNumber
      this.selectedActiveStatus = this.definedTypes.fillTypes.options.find((x) => x.id === (this.supplier.status === 'Active' ? 0 : 1))
      this.selectedBlockedStatus = this.definedTypes.fillTypes.options.find((x) => x.id === (this.supplier.isBlocked ? 0 : 1))

      if (this.supplier.office && this.supplier.office.trim().length > 0) {
        let callLine = { name: 'Office', line: this.supplier.office, isPrimary: false, callInitiated: false, callFailed: false }
        this.callLines.push(callLine)
        // set the default if user going to use alternate lines
        this.alternateSupplierLine = this.supplier.office
      }

      if (!this.supplier.contacts) {
        this.supplier.contacts = []
      }

      this.supplier.contacts.forEach((x) => {
        if (x.fullName && x.fullName.trim().length > 0 && x.mobile && x.mobile.trim().length > 0) {
          let callLine = { name: x.fullName, line: x.mobile, isPrimary: x.isPrimary, callInitiated: false, callFailed: false }
          this.callLines.push(callLine)
          if (callLine.isPrimary) {
            // primary overrides office for default number
            this.alternateSupplierLine = x.mobile
          }
        }
      })

      this.supplier.contacts.forEach(function (contact) {
        let sortOrder = 3
        if (contact.isPrimary) {
          sortOrder = 0
        } else if (contact.status === 'Active') {
          sortOrder = 1
        }
        var config = {
          sectionId: this.contacts.length + 100
        }
        contact.config = config
        contact.selectedReceivesBuyersOrder = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.receivesBuyersOrder ? 0 : 1))
        contact.selectedReceivesSellersOrder = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.receivesSellersOrder ? 0 : 1))
        contact.selectedReceivesPaymentAndTitle = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.receivesPaymentAndTitle ? 0 : 1))
        contact.selectedIsPrimary = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.isPrimary ? 0 : 1))
        contact.selectedActiveStatus = this.definedTypes.fillTypes.options.find((x) => x.id === (contact.status === 'Active' ? 0 : 1))
        contact.sortOrder = sortOrder
        this.contacts.push(contact)
      }, this)
      this.contacts = _.sortBy(this.contacts, ['sortOrder'])
      if (this.contacts.length === 0) {
        this.addContact(true)
      }

      let selectedBank = this.supplier.bankId !== 0
        ? { id: this.supplier.bankId !== 0 ? this.supplier.bankId : null, name: this.supplier.bankName }
        : null

      let defaultPaymentMethod = this.overridePaymentType === null ? this.supplier.defaultPaymentMethod : this.overridePaymentType
      this.bankDetails = {
        accountHolder: this.supplier.accountHolder,
        accountNo: this.supplier.accountNo,
        accountType: this.supplier.accountType,
        branchNo: this.supplier.branchNo,
        branchName: this.supplier.branchName,
        swiftNo: this.supplier.swiftNo,
        selectedBank: selectedBank,
        selectedAccountType: this.definedTypes.bankAccountTypes.find((x) => x.name === this.supplier.accountType),
        hasValidationErrors: false,
        selectedDefaultMethod: this.definedTypes.paymentMethods.find((x) => x.name === defaultPaymentMethod),
        canSave: null
      }

      this.address = {
        address1: this.supplier.address1,
        address2: this.supplier.address2,
        address3: this.supplier.address3,
        address4: this.supplier.address4,
        city: this.supplier.city,
        country: this.supplier.country,
        postalCode: this.supplier.postalCode,
        state: this.supplier.state,
        gpsAddress: this.supplier.gpsAddress
      }

      if (this.supplier.dealerLicenceFileLocation) {
        var dealerLicencePhotoFile = {
          loaded: true,
          imageData: this.supplier.dealerLicenceFileLocation,
          fileName: this.supplier.dealerLicenceFileName,
          uniqueId: this.supplier.dealerLicenceFileName,
          fileType: this.getFileType(this.supplier.dealerLicenceFileName),
          fileLocation: this.supplier.dealerLicenceFileLocation,
          name: this.supplier.dealerLicenceImageName
        }

        this.dealerLicencePhotoFiles.push(dealerLicencePhotoFile)
      }

      if (this.supplier.salesTaxCertificateFileLocation) {
        var salesTaxCertificatePhotoFile = {
          loaded: true,
          imageData: this.supplier.salesTaxCertificateFileLocation,
          fileName: this.supplier.salesTaxCertificateFileName,
          uniqueId: this.supplier.salesTaxCertificateFileName,
          fileType: this.getFileType(this.supplier.salesTaxCertificateFileName),
          fileLocation: this.supplier.salesTaxCertificateFileLocation,
          name: this.supplier.salesTaxCertificateImageName
        }

        this.salesTaxCertificatePhotoFiles.push(salesTaxCertificatePhotoFile)
      }

      if (this.supplier.w9FileLocation) {
        var w9PhotoFile = {
          loaded: true,
          imageData: this.supplier.w9FileLocation,
          fileName: this.supplier.w9FileName,
          uniqueId: this.supplier.w9FileName,
          fileType: this.getFileType(this.supplier.w9FileName),
          fileLocation: this.supplier.w9FileLocation,
          name: this.supplier.w9ImageName
        }

        this.w9PhotoFiles.push(w9PhotoFile)
      }

      this.dealerLicenceExpiration = this.supplier.dealerLicenceExpiration
      this.salesTaxCertificateExpiration = this.supplier.salesTaxCertificateExpiration
      this.w9Expiration = this.supplier.w9Expiration
    },
    onNewContactBuyer: function (newBuyerContact) {
      var found = false
      for (var i = 0; i < this.contacts.length; i++) {
        var contact = this.contacts[i]
        if (contact.id !== newBuyerContact.id) {
          if (contact.selectedReceivesBuyersOrder !== null && contact.selectedReceivesBuyersOrder.name === 'Yes') {
            found = true
          }
          contact.selectedReceivesBuyersOrder = { id: 1, name: 'No', ico: 'fas fa-times has-text-danger' }
          contact.key = this.$uuid.v4()
        }
      }

      if (found) {
        this.showToast(newBuyerContact.firstName + ' ' + newBuyerContact.lastName + ' has been set as the contact to receive paperwork to buy a car from us')
      }
    },
    onNewContactSeller: function (newSellerContact) {
      var found = false
      for (var i = 0; i < this.contacts.length; i++) {
        var contact = this.contacts[i]
        if (contact.id !== newSellerContact.id) {
          if (contact.selectedReceivesSellersOrder !== null && contact.selectedReceivesSellersOrder.name === 'Yes') {
            found = true
          }
          contact.selectedReceivesSellersOrder = { id: 1, name: 'No', ico: 'fas fa-times has-text-danger' }
          contact.key = this.$uuid.v4()
        }
      }

      if (found) {
        this.showToast(newSellerContact.firstName + ' ' + newSellerContact.lastName + ' has been set as the contact to receive paperwork to sell a car to us')
      }
    },
    showToast (message) {
      this.$buefy.toast.open(
        {
          duration: 2500,
          message: message,
          position: 'is-top',
          type: 'is-info'
        })
    },
    onAutoSelectPrimaryContact (updatedContact) {
      // check if another contact is selected as primary, if true then dont auto select
      let primary = this.contacts.find((x) => x.selectedIsPrimary.name === 'Yes' && x.selectedActiveStatus.name === 'Yes' && x.id !== updatedContact.id)
      if (!primary) {
        let newPrimary = this.contacts.find((x) => x.selectedIsPrimary.name === 'No' && x.selectedActiveStatus.name === 'Yes' && x.id !== updatedContact.id)
        if (newPrimary) {
          newPrimary.selectedIsPrimary = this.definedTypes.fillTypes.options.find(x => x.id === this.definedTypes.fillTypes.Yes)
          newPrimary.key = this.$uuid.v4()
          this.showToast(newPrimary.firstName + ' ' + newPrimary.lastName + ' has been set as the primary contact')
        }
      }
    },
    getNameAddressData: function (addressData, place, id) {
      this.supplier.name = place.name
      this.addressSelected = true

      let addressComponents = place.address_components
      this.address.address1 = ''
      this.address.address2 = ''
      this.address.address3 = ''
      this.address.address4 = ''
      this.address.postalCode = ''
      this.address.state = ''
      this.address.city = ''
      this.address.gpsAddress = ''
      this.address.country = ''

      for (var i = 0; i < addressComponents.length; i++) {
        var addressType = addressComponents[i].types[0]
        if (addressType === 'street_number') {
          var streetNumber = addressComponents[i]['short_name']
          this.address.address1 = streetNumber
        }

        if (addressType === 'route') {
          var streetName = addressComponents[i]['short_name']
          this.address.address1 += ' ' + streetName
        }

        if (place.vicinity) {
          var vicinity = place.vicinity
          this.address.address2 = vicinity
        }

        if (addressType === 'locality') {
          var city = addressComponents[i]['short_name']
          this.address.city = city
        }

        if (addressType === 'administrative_area_level_1') {
          var state = addressComponents[i]['long_name']
          this.address.state = state
        }

        if (addressType === 'country') {
          var country = addressComponents[i]['long_name']
          this.address.country = country
        }

        if (addressType === 'postal_code') {
          var postalCode = addressComponents[i]['short_name']
          this.address.postalCode = postalCode
        }
      }

      if (addressData.latitude && addressData.longitude) {
        this.address.gpsAddress = addressData.latitude + ',' + addressData.longitude
      }
    },
    onChangeAutoComplete: function () {
      if (this.addressSelected) {
        this.$refs.compName.update(this.supplier.name)
        this.addressSelected = false
      }
      this.supplier.name = this.$refs.compName._data.autocompleteText
    },
    saveContact: function (supplierModel) {
      this.contacts.forEach(function (item) {
        var contact = {
          id: item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          email: item.email,
          mobile: item.mobile,
          receivesBuyersOrder: item.selectedReceivesBuyersOrder.id === 0,
          receivesSellersOrder: item.selectedReceivesSellersOrder.id === 0,
          receivesPaymentAndTitle: item.selectedReceivesPaymentAndTitle.id === 0,
          isPrimary: item.selectedIsPrimary.id === 0,
          status: item.selectedActiveStatus.id === 0 ? 'Active' : 'InActive'
        }
        contact = this.ensureIdDataTypeSafety(contact)
        supplierModel.contacts.push(contact)
      }, this)
    },
    addContact: function (isFirstContact) {
      var config = {
        sectionId: this.contacts.length + 100
      }

      var contact = this.createGenericContact(isFirstContact)
      contact.config = config
      this.contacts.push(contact)
    },
    removeContact (contactToRemove) {
      let indexToDelete = this.contacts.findIndex((x) => x.id === contactToRemove.id)
      this.contacts.splice(indexToDelete, 1)
    },
    createGenericContact (isFirstContact) {
      return {
        id: this.$uuid.v4(),
        key: this.$uuid.v4(),
        firstName: '',
        lastName: '',
        email: '',
        mobile: '',
        receivesBuyersOrder: 0,
        receivesSellersOrder: 0,
        receivesPaymentAndTitle: 0,
        isPrimary: 0,
        selectedReceivesBuyersOrder: this.definedTypes.fillTypes.options.find((x) => x.id === (isFirstContact ? 0 : 1)),
        selectedReceivesSellersOrder: this.definedTypes.fillTypes.options.find((x) => x.id === (isFirstContact ? 0 : 1)),
        selectedReceivesPaymentAndTitle: this.definedTypes.fillTypes.options.find((x) => x.id === (isFirstContact ? 0 : 1)),
        selectedIsPrimary: this.definedTypes.fillTypes.options.find((x) => x.id === (isFirstContact ? 0 : 1)),
        selectedActiveStatus: this.definedTypes.fillTypes.options.find((x) => x.id === 0)
      }
    },
    onValidationFailure: function () {
      this.serverErrors = [{
        errorMessage: 'One or more required fields requires your attention. Please check all tabs for invalid fields.'
      }]
    },
    toggleDocView: function (status) {
      this.isDocViewActive = status
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  watch: {
    value: function (newValue, oldValue) {
      this.supplier = newValue
    }
  },
  mounted: function () {
    eventBus.$on('on-open-verify-supplier', this.onOpen)
  },
  beforeDestroy () {
    eventBus.$off('on-open-verify-supplier', this.onOpen)
  }
}

</script>

<style scoped>
 .modal-card-head, .modal-card-foot{
    padding: 20px !important
  }

  .modal-card-body {
    overflow:auto;
    height: calc(50vh - 10px);
  }

  .modal-card {
    max-width: inherit !important;
  }

  .file-container {
    margin-bottom: 50px;
  }

</style>

export default {
  all: [
    {
      firstName: 'Garrett',
      lastName: 'Hayim',
      status: 'Active',
      email: 'gh@ferrarifl.com',
      mobile: null,
      telephone: null,
      subscriberId: 1,
      dealerId: 1,
      dealerName: 'Ferrari of Ft. Lauderdale',
      subscriberName: 'Experience Auto Group',
      roles: ['SuperAdmin'],
      designations: [{
        id: 1,
        name: 'CEO'
      }],
      id: 3003
    },
    {
      firstName: 'Raj',
      lastName: 'Moodaley',
      status: 'Active',
      email: 'raj@maxxor.com',
      mobile: null,
      telephone: null,
      subscriberId: 1,
      dealerId: 1,
      dealerName: 'Ferrari of Ft. Lauderdale',
      subscriberName: 'Experience Auto Group',
      roles: ['SuperAdmin'],
      designations: [{
        id: 2,
        name: 'CTO'
      }],
      id: 3004
    },
    {
      firstName: 'Andre',
      lastName: 'Moses',
      status: 'Active',
      email: 'andre@maxxor.com',
      mobile: null,
      telephone: null,
      subscriberId: 1,
      dealerId: 1,
      dealerName: 'Ferrari of Ft. Lauderdale',
      subscriberName: 'Experience Auto Group',
      roles: ['DealerAdmin'],
      designations: [{
        id: 3,
        name: 'CFO'
      }],
      id: 3005
    }
  ],
  emailDomain: '@dev.mail-systems.com'
}

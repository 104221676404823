<template>
  <div class="columns">
    <div class="column is-6">
      <switch-selector type="is-info" label="Rdr Complete" v-model="selectedInventory.rdrComplete"></switch-selector>
    </div>
    <div class="column is-6" v-if="selectedInventory.rdrComplete">
      <div class="field">
        <b-field label="Rdr Completed Date">
          <el-date-picker
            @change="onFormChange()"
            v-model="selectedInventory.rdrCompletedAt"
            type="date"
            placeholder="Click to select..."
            class="is-width-150"
            :clearable="false"
            popper-class="datepicker-container">
          </el-date-picker>
        </b-field>
      </div>
    </div>
  </div>
</template>

<script>
import humanizedSwitchMixin from '@/mixins/generic/humanizedSwitches'
import SwitchSelector from '@/components/generic/SwitchSelector'
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters

export default {
  inject: {
    $validator: '$validator'
  },
  mixins: [humanizedSwitchMixin],
  name: 'RdrQuickViewCapture',
  components: {
    'switch-selector': SwitchSelector
  },
  props: {
    value: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      instanceName: this.$uuid.v4(),
      selectedInventory: this.value
    }
  },
  computed: {
    humanizedRdrComplete: function () {
      return this.selectedInventory.rdrComplete ? 'Yes' : 'No'
    },
    ...mapUserGetters(['hasFeatureAccess'])
  },
  methods: {
    initValues: function () {
      if (this.value !== null) {
        this.selectedInventory = this.value
      }
    }
  },
  mounted: function () {
    this.initValues()
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

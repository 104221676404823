<template>
  <section>
    <portal to="global-modal-portal" v-if="dealExistingActive" :disabled="!usePortal">
      <b-modal :active.sync="dealExistingActive" scroll="keep" :has-modal-card="true" :canCancel="['escape', 'x']">
        <div class="modal-card customer-modal-card" style="width: auto">
          <header class="modal-card-head customer-modal-card-head">
            <p class="modal-card-title">Existing Deals Found</p>
          </header>
          <section class="modal-card-body view-container">
            <b-table :data="deals" class="table box">
              <template slot-scope="props">
                <b-table-column field="Code" label="Code">
                  <a class="is-link" @click="onSelectedDeal(props.row)">{{ props.row.code }}</a>
                </b-table-column>
                <b-table-column field="status" label="Status">
                  {{ props.row.typeDescription }}
                </b-table-column>
                <b-table-column field="type" label="Type">
                  {{props.row.saleTypeDescription}} - {{props.row.stockTypeDescription}}
                </b-table-column>
                <b-table-column field="vehicle" label="Vehicle">
                  {{ props.row.vehicleDisplayName}}
                </b-table-column>
                <b-table-column field="created" label="Created">
                  {{ props.row.createdAt | formatDateOnly }}
                </b-table-column>
                <b-table-column field="createdBy" label="Created By">
                  {{ props.row.createdBy }}
                </b-table-column>
                <b-table-column field="" label="Action">
                  <button class="button is-success" @click="onSelectedDeal(props.row)">select</button>
                </b-table-column>
              </template>
              <template slot="empty">
                <section class="section">
                  <div class="has-text-centered">
                    <p><b-icon icon="emoticon-sad" size="is-large"></b-icon></p>
                    <p>No deals found</p>
                  </div>
                </section>
              </template>
            </b-table>
          </section>
          <footer class="modal-card-foot customer-modal-card-foot">
            <div if="hasDeals" class="notification is-warning is-flex">
              <span><i class="fas fa-exclamation-triangle is-warning fa-2x"></i></span>
              <span class="ml-10">
                <div class="title is-6">Please select an existing deal above OR create a new {{ forType }} for this customer.</div>
                <div>
                  <button class="button is-success is-capitalized" @click="onCreateNew">new {{ forType }}</button>
                  <button class="button is-danger" @click="onCancelCreate">Cancel</button>
                </div>
              </span>
            </div>
          </footer>
        </div>
      </b-modal>
    </portal>
  </section>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const mapUserGetters = createNamespacedHelpers('user').mapGetters
const mapDealerState = createNamespacedHelpers('dealer').mapState
const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'DealExistingComponent',
  mixins: [],
  components: {
  },
  props: {
    value: {
      type: Array,
      default: () => []
    },
    usePortal: {
      type: Boolean,
      default: false
    },
    forType: {
      type: String,
      default: 'deal'
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      dealExistingActive: false
    }
  },
  computed: {
    ...mapUserGetters(['hasFeatureAccess', 'currentUser', 'lastActiveListPage']),
    ...mapDealerState(['currentDealer']),
    ...mapConfigGetters(['definedTypes']),
    deals: function () {
      return this.value
    },
    hasDeals: function () {
      return this.deals && this.deals.length > 0
    }
  },
  methods: {
    onOpenModal: function () {
      this.dealExistingActive = true
    },
    onCloseModal: function () {
      this.dealExistingActive = false
    },
    onCreateNew: function () {
      this.onCloseModal()
      this.$emit('on-create-new')
    },
    onCancelCreate: function () {
      this.onCloseModal()
      this.$emit('on-cancel')
    },
    onSelectedDeal: function (deal) {
      this.onCloseModal()
      this.$emit('on-selected-deal', deal)
    },
    onViewDeal: function (dealId) {
      if (this.hasFeatureAccess('deal.view')) {
        this.onCloseModal()
        this.$router.push({name: 'ListDeals', params: { id: dealId }})
      }
    }
  },
  watch: {
  },
  mounted: function () {
  }
}

</script>

<style scoped>
  .modal-card-body {
    overflow: auto;
    height: calc(60vh - 45px);
    padding: 10px;
  }
</style>

import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import VeeValidate from 'vee-validate'

var mixin = {
  data () {
    return {
      isPartial: false,
      selectedType: null,
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  components: {
    'error-display-component': ErrorDisplayComponent
  },
  computed: {
    isCompanyNameRequired: function () {
      return this.selectedType && this.selectedType.name === 'Company'
    },
    isMobileRequired: function () {
      if (this.currentCustomer) {
        return this.currentCustomer.email.length === 0
      }
      return this.email.length === 0
    },
    isEmailRequired: function () {
      if (this.currentCustomer) {
        return this.currentCustomer.mobile.length === 0
      }
      return this.mobile.length === 0
    },
    thisValidator: function () {
      return (this && this.$validator) ? this.$validator : null
    },
    isAddressRequired: function () {
      return (this.bankDetails && this.bankDetails.selectedDefaultMethod && this.bankDetails.selectedDefaultMethod.name === 'Check') && !this.isPartial
    },
    isBankRequired: function () {
      return (this.bankDetails && this.bankDetails.selectedDefaultMethod && this.bankDetails.selectedDefaultMethod.name === 'Wire') && !this.isPartial
    }
  },
  methods: {
    saveAddress: function (customerModel) {
      this.addresses.forEach(function (item) {
        if (item.address1 !== '') {
          var address = {
            id: isNaN(item.id) ? 0 : item.id,
            address1: item.address1,
            address2: item.address2,
            address3: item.address3,
            address4: item.address4,
            city: item.city,
            country: item.country,
            postalCode: item.postalCode,
            state: item.state,
            gpsAddress: item.gpsAddress,
            ownershipType: item.ownershipType ? item.ownershipType.name : null,
            years: item.years,
            months: item.months,
            rate: item.rate,
            isPrimary: item.isPrimary
          }
          customerModel.addresses.push(address)
        }
      }, this)
    },
    saveWorkHistory: function (customerModel) {
      this.workHistory.forEach(function (item) {
        var history = {
          id: isNaN(item.id) ? 0 : item.id,
          employer: item.employer,
          jobTitle: item.jobTitle,
          employerOffice: item.employerOffice,
          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
          address4: item.address4,
          city: item.city,
          country: item.country,
          postalCode: item.postalCode,
          state: item.state,
          gpsAddress: item.gpsAddress,
          years: item.years,
          months: item.months,
          grossIncome: item.grossIncome,
          otherIncome: item.otherIncome,
          otherIncomeSource: item.otherIncomeSource
        }

        customerModel.workHistory.push(history)
      }, this)
    },
    savePersonalReference: function (customerModel) {
      this.personalReferences.forEach(function (item) {
        var reference = {
          id: isNaN(item.id) ? 0 : item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          relationship: item.relationship,
          phoneNumber: item.phoneNumber,
          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
          address4: item.address4,
          city: item.city,
          country: item.country,
          postalCode: item.postalCode,
          state: item.state,
          gpsAddress: item.gpsAddress
        }

        customerModel.personalReferences.push(reference)
      }, this)
    },
    saveRelative: function (customerModel) {
      this.relatives.forEach(function (item) {
        var relative = {
          id: isNaN(item.id) ? 0 : item.id,
          firstName: item.firstName,
          lastName: item.lastName,
          relationship: item.relationship,
          phoneNumber: item.phoneNumber,
          address1: item.address1,
          address2: item.address2,
          address3: item.address3,
          address4: item.address4,
          city: item.city,
          country: item.country,
          postalCode: item.postalCode,
          state: item.state,
          gpsAddress: item.gpsAddress
        }

        customerModel.relatives.push(relative)
      }, this)
    },
    onAutoSelectPrimaryAddress (address) {
      const primary = this.addresses.find((x) => x.isPrimary && x.id !== address.id)
      if (!primary) {
        const newPrimary = this.addresses.find((x) => !x.isPrimary && x.id !== address.id)
        if (newPrimary) {
          newPrimary.isPrimary = true
        }
      }
    },
    onUnselectNonPrimary (address) {
      if (address.isPrimary) {
        this.addresses.forEach((x) => {
          if (x.id !== address.id) {
            x.isPrimary = false
          }
        })
      }
    },
    addAddress: function (configuration) {
      var config = {
        sectionId: this.addresses.length + 100,
        loadCustomerOnly: (configuration) ? configuration.loadCustomerOnly : false
      }

      var address = this.createGenericAddress()
      address.config = config
      this.addresses.push(address)
      if (this.addresses && this.addresses.length === 1) {
        this.addresses[0].isPrimary = true
      }
    },
    addWorkHistory: function (configuration) {
      var config = {
        sectionId: this.workHistory.length + 100,
        loadCustomerOnly: (configuration) ? configuration.loadCustomerOnly : false
      }

      var history = this.createGenericWorkHistory()
      history.config = config
      this.workHistory.push(history)
    },
    addPersonalReference: function (configuration) {
      var config = {
        sectionId: this.personalReferences.length + 100,
        loadCustomerOnly: (configuration) ? configuration.loadCustomerOnly : false
      }

      var reference = this.createGenericPersonalReference()
      reference.config = config
      this.personalReferences.push(reference)
    },
    addRelative: function (configuration) {
      var config = {
        sectionId: this.relatives.length + 100,
        loadCustomerOnly: (configuration) ? configuration.loadCustomerOnly : false
      }

      var relative = this.createGenericRelative()
      relative.config = config
      this.relatives.push(relative)
    },
    removeAddress (addressToRemove) {
      const indexToDelete = this.addresses.findIndex((x) => x.id === addressToRemove.id)
      this.addresses.splice(indexToDelete, 1)
      const primary = this.addresses.find((x) => x.isPrimary && x.id !== addressToRemove.id)
      if (!primary) {
        const newPrimary = this.addresses.find((x) => !x.isPrimary && x.id !== addressToRemove.id)
        if (newPrimary) {
          newPrimary.isPrimary = true
        }
      }
    },
    removeWorkHistory () {
      var sectionId
      var offset = 100

      sectionId = this.workHistory.length + offset - 1
      var workHistoryFiltered = this.workHistory.filter(function (el) {
        return el.config.sectionId !== sectionId
      })
      this.workHistory = workHistoryFiltered
    },
    removePersonalReference () {
      var sectionId
      var offset = 100

      sectionId = this.personalReferences.length + offset - 1
      var personalReferenceFiltered = this.personalReferences.filter(function (el) {
        return el.config.sectionId !== sectionId
      })
      this.personalReferences = personalReferenceFiltered
    },
    removeRelative () {
      var sectionId
      var offset = 100

      sectionId = this.relatives.length + offset - 1
      var relativeFiltered = this.relatives.filter(function (el) {
        return el.config.sectionId !== sectionId
      })
      this.relatives = relativeFiltered
    },
    createGenericAddress (configuration) {
      return {
        id: this.$uuid.v4(),
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: '',
        ownershipType: null,
        years: 0,
        months: 0,
        rate: 0,
        isPrimary: false
      }
    },
    createGenericWorkHistory (configuration) {
      return {
        id: this.$uuid.v4(),
        employer: '',
        jobTitle: '',
        employerOffice: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: '',
        years: 0,
        months: 0,
        grossIncome: 0,
        otherIncome: 0,
        otherIncomeSource: ''
      }
    },
    createGenericPersonalReference (configuration) {
      return {
        id: this.$uuid.v4(),
        firstName: '',
        lastName: '',
        relationship: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: ''
      }
    },
    createGenericRelative (configuration) {
      return {
        id: this.$uuid.v4(),
        firstName: '',
        lastName: '',
        relationship: '',
        phoneNumber: '',
        address1: '',
        address2: '',
        address3: '',
        address4: '',
        city: '',
        country: '',
        postalCode: '',
        state: '',
        gpsAddress: ''
      }
    },
    buildInitials: function () {
      let firstName = this.currentCustomer ? this.currentCustomer.firstName : this.firstName
      let middleName = this.currentCustomer ? this.currentCustomer.middleName : this.middleName
      let lastName = this.currentCustomer ? this.currentCustomer.lastName : this.lastName
      var derivedInitials = (firstName ? firstName[0] : '') + (middleName ? middleName[0] : '') + (lastName ? lastName[0] : '')
      if (this.currentCustomer) {
        this.currentCustomer.initials = derivedInitials
      } else {
        this.initials = derivedInitials
      }
    },
    removeEmail (emailToRemove) {
      emailToRemove.status = 'Deleted'
    },
    removeContactNumber (contactNumberToRemove) {
      contactNumberToRemove.status = 'Deleted'
    },
    saveEmailAddresses: function (customerModel) {
      this.emailAddresses.forEach(function (item) {
        var emailAddress = {
          id: isNaN(item.id) ? 0 : item.id,
          email: item.email,
          status: item.status
        }
        customerModel.emailAddresses.push(emailAddress)
      }, this)
    },
    saveContactNumbers: function (customerModel) {
      this.contactNumbers.forEach(function (item) {
        var contactNumber = {
          id: isNaN(item.id) ? 0 : item.id,
          contactNumber: item.contactNumber,
          status: item.status
        }

        customerModel.contactNumbers.push(contactNumber)
      }, this)
    },
    addEmailAddress: function () {
      var config = {
        sectionId: this.emailAddresses.length + 100
      }

      var email = this.createGenericEmail()
      email.config = config
      this.emailAddresses.push(email)
    },
    addContactNumber: function () {
      var config = {
        sectionId: this.contactNumbers.length + 100
      }

      var contactNumber = this.createGenericContactNumber()
      contactNumber.config = config
      this.contactNumbers.push(contactNumber)
    },
    createGenericEmail () {
      return {
        id: this.$uuid.v4(),
        key: this.$uuid.v4(),
        email: '',
        status: 'Active'
      }
    },
    createGenericContactNumber () {
      return {
        id: this.$uuid.v4(),
        key: this.$uuid.v4(),
        contactNumber: '',
        status: 'Active'
      }
    },
    isExtraEmailUnique (value) {
      let checkForEmail = this.emailAddresses.filter((x) => x.email === value.trim())
      if (checkForEmail.length > 1) {
        return false
      }
      return true
    },
    isExtraContactNumberUnique (value) {
      let checkForContactNumber = this.contactNumbers.filter((x) => x.contactNumber === value.trim())
      if (checkForContactNumber.length > 1) {
        return false
      }
      return true
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  watch: {
    firstName: function (newValue, oldValue) {
      let titleCaseVersion = (newValue) ? this.$toTitleCase(newValue) : newValue
      if (titleCaseVersion !== newValue) {
        this.firstName = titleCaseVersion
      } else {
        this.buildInitials()
      }
    },
    middleName: function (newValue, oldValue) {
      let titleCaseVersion = (newValue) ? this.$toTitleCase(newValue) : newValue
      if (titleCaseVersion !== newValue) {
        this.middleName = titleCaseVersion
      } else {
        this.buildInitials()
      }
    },
    lastName: function (newValue, oldValue) {
      let titleCaseVersion = (newValue) ? this.$toTitleCase(newValue) : newValue
      if (titleCaseVersion !== newValue) {
        this.lastName = titleCaseVersion
      } else {
        this.buildInitials()
      }
    },
    companyName: function (newValue, oldValue) {
      let titleCaseVersion = (newValue) ? this.$toTitleCase(newValue) : newValue
      if (titleCaseVersion !== newValue) {
        this.companyName = titleCaseVersion
      }
    },
    'currentCustomer.firstName': function (newValue, oldValue) {
      let titleCaseVersion = (newValue) ? this.$toTitleCase(newValue) : newValue
      if (titleCaseVersion !== newValue) {
        this.currentCustomer.firstName = titleCaseVersion
      } else {
        this.buildInitials()
      }
    },
    'currentCustomer.middleName': function (newValue, oldValue) {
      let titleCaseVersion = (newValue) ? this.$toTitleCase(newValue) : newValue
      if (titleCaseVersion !== newValue) {
        this.currentCustomer.middleName = titleCaseVersion
      } else {
        this.buildInitials()
      }
    },
    'currentCustomer.lastName': function (newValue, oldValue) {
      let titleCaseVersion = (newValue) ? this.$toTitleCase(newValue) : newValue
      if (titleCaseVersion !== newValue) {
        this.currentCustomer.lastName = titleCaseVersion
      } else {
        this.buildInitials()
      }
    },
    'currentCustomer.companyName': function (newValue, oldValue) {
      let titleCaseVersion = (newValue) ? this.$toTitleCase(newValue) : newValue
      if (titleCaseVersion !== newValue) {
        this.currentCustomer.companyName = titleCaseVersion
      }
    }
  },
  created () {
    VeeValidate.Validator.extend('email_unique', {
      // Custom validation message
      getMessage: (field) => `The ${field} is not a unique.`,
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        resolve({
          valid: this.isExtraEmailUnique(value)
        })
      })
    })
    VeeValidate.Validator.extend('contact_number_unique', {
      // Custom validation message
      getMessage: (field) => `The ${field} is not a unique.`,
      // Custom validation rule
      validate: (value) => new Promise(resolve => {
        resolve({
          valid: this.isExtraContactNumberUnique(value)
        })
      })
    })
  }
}

export default mixin

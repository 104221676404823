<template>
<div style="display:inline;">
  <i class="fas fa-external-link-square-alt link" :class="{'active-link': dealerPeakUserId !== null }" :ref="_uid" :title="title" @click.stop="linkAction()"></i>
  <i class="fas fa-sync-alt fa-spin" v-if="isCheckingForLead" :ref="_uid" :title="title" @click.stop="linkAction()"></i>
</div>
</template>

<script>

import moment from 'moment'
import eventBus from '@/eventBus'
import customerService from '@/services/customerService'

export default {
  name: 'DealerPeakControl',
  props: {
    customerId: {
      type: Number,
      default: null
    },
    customer: {
      type: Object,
      default: null
    }
  },
  components: {
  },
  data () {
    return {
      baseDealerPeakUrl: 'https://eagfortlauderdale.dealerpeak.net/',
      dealerPeakUserId: null,
      noCustomerMatch: false,
      isCheckingForLead: false,
      customerMatches: [],
      showCustomerMatches: false,
      selectedCustomerMatch: null,
      customerDealerPeakHomeUrl: ''
    }
  },
  computed: {
    title: function () {
      if (this.dealerPeakUserId) {
        return 'Customer Dealer Peak Profile'
      }
      return 'Customer Dealer Peak Lookup'
    }
  },
  watch: {
  },
  methods: {
    linkAction: function () {
      if (this.dealerPeakUserId) {
        this.openLeadProfile()
      } else {
        this.isCheckingForLead = true
        eventBus.$emit('dealer-peak-lookup', {customerId: this.customerId, componentId: this._uid})
      }
    },
    getLeadUrl: function () {
      let baseUrl = this.baseDealerPeakUrl
      if (this.customerDealerPeakHomeUrl) {
        baseUrl = this.customerDealerPeakHomeUrl
      }
      let date = moment()
      let url = baseUrl +
        '?fuseaction=crm.customer' +
        '&customer_userid=' +
        this.dealerPeakUserId +
        '&leadid=' +
        '&ts=' +
        (date || '').toString()
      return encodeURI(url)
    },
    openLeadProfile: function () {
      let url = this.getLeadUrl()
      window.open(url, '_blank')
    },
    onActivateLink: function (dealerPeakUserId) {
      this.dealerPeakUserId = dealerPeakUserId
    },
    loadCustomer: function () {
      customerService.single(this.customerId).then((response) => {
        this.dealerPeakUserId = response.customer.customerId
        this.customerDealerPeakHomeUrl = response.customer.dealerPeakHomeUrl
      })
    },
    onLeadCheckComplete: function () {
      this.isCheckingForLead = false
    }
  },
  mounted: function () {
    if (this.customerId && !this.customer) {
      this.loadCustomer()
    } else if (this.customer && this.customer.customerId) {
      this.dealerPeakUserId = this.customer.customerId
      this.customerDealerPeakHomeUrl = this.customer.dealerPeakHomeUrl
      if (this.customerDealerPeakHomeUrl && this.customerDealerPeakHomeUrl.endsWith('/')) {
        this.customerDealerPeakHomeUrl = this.customerDealerPeakHomeUrl.slice(0, -1)
      }
    }
    eventBus.$on('activate-dealer-peak-link-' + this._uid, this.onActivateLink)
    eventBus.$on('lead-check-complete-' + this._uid, this.onLeadCheckComplete)
  },
  beforeDestroy () {
    eventBus.$off('activate-dealer-peak-link-' + this._uid, this.onActivateLink)
    eventBus.$off('lead-check-complete-' + this._uid, this.onLeadCheckComplete)
  }
}

</script>

<style scoped>

.link {
  cursor:pointer

}
.active-link {
  color:green
}

</style>

<template>
  <div class="fullpage" v-if="!isAuthenticated && !isLoading">
    <div class="columns is-marginless" v-show="!isAuthenticated && !isLoading" >
      <div class="column is-5" style="margin-top:20px;">
        <form @submit.prevent="validateBeforeSubmit">
          <div style="margin-left:30px;">
            <h1 class="logo">
              <div style="width:200px"><img src="@/assets/logo_standard.png" alt="logo"></div>
            </h1>
            <div class="heading">Live</div>
            <div class="field">
              <p class="sub-heading">Please log in to access your account</p>
            </div>
            <div class="field">
              <label class="label">Pin<b-icon pack="fas" icon="none" class="fa-ss"></b-icon></label>
              <div class="control has-icons-right">
                <div class="control is-width-100">
                  <input name="pin" data-vv-as="pin" v-validate="'required'" maxlength="10" :class="{'input': true, 'is-small': true,  'is-danger': errors.has('pin') }" type="password" placeholder="e.g #####" v-model="pin" tabindex=7 autocomplete="new-password" @keyup.enter="validateBeforeSubmit">
                </div>
                <span v-show="errors.has('pin')" class="help is-danger">
                  <i v-show="errors.has('pin')" class="fas fa-exclamation-triangle"></i> {{
                  errors.first('pin') }}
                </span>
                <span class="icon is-small is-right" v-show="!errors.has('pin')">
                  <i class="fas fa-check"></i>
                </span>
                <span class="icon is-small is-right" v-show="errors.has('pin')">
                  <i class="fas fa-exclamation-triangle"></i>
                </span>
              </div>
            </div>
            <a class="button is-danger" :class="{'is-loading': isLoading }" @click="validateBeforeSubmit">Login</a>
            <a class="is-pulled-right" style="font-size: 0.75rem; cursor: pointer; color: inherit;" @click="disableTabletMode">Click here to
              <strong>disable tablet mode</strong>
            </a>
            <div class="loading-container">
              <div class="title is-size-7 has-text-primary" v-if="isLoading">
                <span>{{loadingMessage}}</span>
              </div>
            </div>
            <div class="loading-container has-text-centered">
              <p v-if="errorMessage.length">
                <ul class="errors">
                  <li class="has-text-danger has-text-centered">{{ errorMessage }}</li>
                </ul>
                <br />
              </p>
            </div>
          </div>
        </form>
      </div>
      <div class="column is-7 is-paddingless is-hidden-mobile is-hidden-touch swiper-container" id="banner-slider" v-if="!isAuthenticated && !isLoading">
        <swiper class="swiper"  :slides-per-view="3"
        :space-between="50" :options="swiperOption">
          <swiper-slide class="feat-login-image"></swiper-slide>
          <swiper-slide class="hero-image-1"></swiper-slide>
          <swiper-slide class="hero-image-2"></swiper-slide>
          <swiper-slide class="hero-image-3"></swiper-slide>
          <swiper-slide class="hero-image-4"></swiper-slide>
          <swiper-slide class="hero-image-5"></swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
  <div class="aligner" v-else>
    <div class="aligner-item aligner-item--top"></div>
    <div class="aligner-item" style="text-align:center">
      <img src="@/assets/ajax-loader.gif">
      <div v-if="isLoading && !isLoadingConfiguration">Verifying your identity</div>
      <div v-if="isLoading && isLoadingConfiguration">Loading configuration data</div>
    </div>
    <div class="aligner-item aligner-item--bottom"></div>
  </div>
</template>

<script>

import { createNamespacedHelpers } from 'vuex'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import logger from '@/services/loggingService'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/css'
const mapAuthGetters = createNamespacedHelpers('auth').mapGetters

export default {
  name: 'Login',
  mixins: [validateBeforeSubmitMixin],
  components: {
    Swiper,
    SwiperSlide
  },
  data () {
    return {
      isLoading: false,
      isLoadingConfiguration: false,
      loadingMessage: '',
      error: false,
      errorMessage: '',
      swiperOption: {
        speed: 1000,
        spaceBetween: 0,
        direction: 'horizontal',
        loop: true,
        slidesPerView: 1,
        preloadImages: true,
        updateOnImagesReady: true,
        grabCursor: false,
        autoplay: {
          delay: 10000
        }
      },
      pin: ''
    }
  },
  computed: {
    ...mapAuthGetters(['isAuthenticated'])
  },
  methods: {
    resetErrors: function () {
      this.error = false
      this.errorMessage = ''
    },
    onSave: async function () {
      this.resetErrors()
      this.isLoading = true
      this.loadingMessage = 'Verifying your credentials'
      let credentialModel = {
        pin: this.pin
      }
      try {
        await this.$store.dispatch('auth/pinAuthRequest', credentialModel)
        await this.loadConfiguration()
      } catch (error) {
        this.isLoading = false
        this.error = true
        this.errorMessage = error.message
        console.log(error.message)
      }
    },
    loadBaseData: async function () {
      try {
        this.isLoadingConfiguration = true
        let checkResult = await this.$store.dispatch('user/setCurrentUser')
        if (checkResult) {
          this.$store.dispatch('user/updateUserSetting', {name: 'TabletMode', value: '1'})
          let allActions = []
          allActions.push(this.$store.dispatch('subscriber/setCurrentSubscriber', { id: this.$store.state.user.currentUser.subscriberId }))
          allActions.push(this.$store.dispatch('dealer/setCurrentDealer', { id: this.$store.state.user.currentUser.dealerId }))
          allActions.push(this.$store.dispatch('role/setRoles'))
          allActions.push(this.$store.dispatch('bank/setBanks'))
          allActions.push(this.$store.dispatch('feature/setFeatures'))
          allActions.push(this.$store.dispatch('dealer/setDealers'))
          allActions.push(this.$store.dispatch('dealer/setDesignations'))
          allActions.push(this.$store.dispatch('dealer/setDepartments'))
          allActions.push(this.$store.dispatch('user/setUsers'))
          allActions.push(this.$store.dispatch('location/setLocations'))
          allActions.push(this.$store.dispatch('vehicleMake/setVehicleMakes'))
          allActions.push(this.$store.dispatch('leadSource/setLeadSources'))
          allActions.push(this.$store.dispatch('roServiceVehicleMake/setDealerRoServiceVehicleMakes', { dealerId: this.$store.state.user.currentUser.dealerId }))
          allActions.push(this.$store.dispatch('scheduledPayment/setScheduledPaymentPayees'))
          await Promise.all(allActions)
          this.isLoadingConfiguration = false
          this.isLoading = false
          await this.$store.dispatch('auth/setSystemReady')
          if (this.$route.query && this.$route.query.redir && this.$route.query.redir.length > 1) {
            this.$router.push(this.$route.query.redir)
          } else {
            this.$router.push('/')
          }
        } else {
          this.isLoading = false
          this.$router.push('/auth/login')
        }
      } catch (error) {
        this.isLoadingConfiguration = false
        this.isLoading = false
        logger.log(error)
      }
    },
    loadConfiguration: async function () {
      // If the user is not authenticated client side first do a check to the API to see if they authenticated server side via secure auth cookies
      this.isLoading = true
      if (!this.isAuthenticated) {
        try {
          await this.$store.dispatch('auth/authVerificationRequest')
          await this.loadBaseData()
          this.isLoading = false
        } catch (error) {
          this.isLoading = false
          logger.log(error)
        }
      } else {
        await this.loadBaseData()
        this.isLoading = false
      }
    },
    removeNavBar: function () {
      document.getElementsByTagName('html')[0].classList.remove('has-navbar-fixed-top')
    },
    addNavBar: function () {
      if (!document.getElementsByTagName('html')[0].classList.contains('has-navbar-fixed-top')) {
        document.getElementsByTagName('html')[0].classList.add('has-navbar-fixed-top')
      }
    },
    disableTabletMode: function () {
      document.cookie = 'tabletmode=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;'
      this.$router.push('/auth/login')
    }
  },
  watch: {
  },
  beforeMount () {
    this.removeNavBar()
  },
  beforeDestroy () {
    this.addNavBar()
  },
  mounted: async function () {
    await this.loadConfiguration()
  }
}
</script>

<style scoped>

  .aligner {
    height:100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .aligner-item {
    max-width: 50%;
  }

  .aligner-item--top {
    align-self: flex-start;
  }

  .aligner-item--bottom {
    align-self: flex-end;
  }

  .fullpage {
    background: #fff;
  }

  .swiper {
    width: 64vw;
    max-height:100%
  }

  .content
  {
    padding: 10px;
    text-align: center;
  }

  .inner
  {
    display:inline-block;
  }

  .heading {
    font-size: 1.55rem;
    font-family: "Roboto Regular",sans-serif;
    padding-bottom:100px;
    text-transform: none;
  }

  .sub-heading {
    font-family: "Roboto Regular",sans-serif;
    color: #3f4353;
    margin: 0 0 1em;
    word-break: normal;
    font-size: 0.8rem;
  }

  .loading-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 1.5em;
  }

  .footer {
    font-family: "Roboto Regular",sans-serif;
    color: #3f4353;
    line-height: 1.6rem;
    font-size: 13px;
    font-weight: bold;
  }

  .input-title {
    color:#d4d5db;
    font-family: "Roboto Regular",sans-serif;
    font-size: .8rem;
  }
</style>

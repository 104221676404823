import { render, staticRenderFns } from "./CustomerSelector.vue?vue&type=template&id=4cef76a6&scoped=true"
import script from "./CustomerSelector.vue?vue&type=script&lang=js"
export * from "./CustomerSelector.vue?vue&type=script&lang=js"
import style0 from "./CustomerSelector.vue?vue&type=style&index=0&id=4cef76a6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cef76a6",
  null
  
)

export default component.exports
import mutationTypes from '../mutation-types.js'
import locationService from '../../services/locationService.js'

const state = {
  currentLocation: {},
  allLocations: []
}

const getters = {
  allLocations: state => {
    return state.allLocations
  }
}

const mutations = {
  [mutationTypes.SET_CURRENT_LOCATION] (state, location) {
    state.currentLocation = location
  },
  [mutationTypes.SET_LOCATIONS] (state, locations) {
    state.allLocations = locations
  },
  [mutationTypes.SYNC_LOCATION] (state, entity) {
    var applicable = state.allLocations.find((x) => x.id === entity.id)
    if (applicable) {
      applicable = entity
    } else {
      state.allLocations.push(entity)
    }
  }
}

const actions = {
  setCurrentLocation ({commit, state}, location) {
    commit(mutationTypes.SET_CURRENT_LOCATION, location)
    locationService.single(location.id)
      .then((data) => {
        commit(mutationTypes.SET_CURRENT_LOCATION, data.location)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  setLocations ({commit, state}) {
    commit(mutationTypes.SET_LOCATIONS, [])
    locationService.allLocations()
      .then((data) => {
        commit(mutationTypes.SET_LOCATIONS, data.results)
      })
      .catch((error) => {
        console.log(error)
      }
      )
  },
  syncLocation ({commit, state}, entity) {
    commit(mutationTypes.SYNC_LOCATION, entity)
  }
}

export default {
  state,
  getters,
  mutations,
  actions
}

export default {
  total: 1,
  results: [
    {
      id: 1,
      subscriberId: 1,
      dealerId: 3,
      destinationAddresses: 'ben@hostedapp.co.za',
      overridingSubject: '',
      sent: true,
      lastFailureReason: '',
      attachDocuments: false,
      watermarkTitle: false,
      inventoryLoanerEventId: null,
      isSignInPerson: false,
      signingStatus: 10,
      type: 'Sale',
      dealAttachmentId: 1,
      dealFilename: 'Billing file.pdf',
      dealFileLocation: ''
    }
  ]
}

<template>
  <span v-if="!isWindowStickerUpdateActive">
    <span :id="instanceId" :name="instanceId" :title="windowStickerStatusDescription" @click.stop.prevent="onUpdateWindowStickerStatus()" v-if="isListMode" class="window-sticker-container">
      <i class="icon fal fa-lg fa-fingerprint" :class="getColorClassWindowStickerUploads(currentInventory)"/>
    </span>
    <div :id="instanceId" :name="instanceId" :title="windowStickerStatusDescription" v-else>
      <span :id="instanceId" :name="instanceId" @click.stop.prevent="onUpdateWindowStickerStatus()" class="window-sticker-container">
        <i class="icon fal fa-lg fa-fingerprint list-icon" :class="getColorClassWindowStickerUploads(currentInventory)"/>
      </span>
      <span v-if="label.length > 0">{{label}}</span>
    </div>
  </span>
  <portal to="global-modal-portal" v-else :disabled="isDoubleStackedModal">
    <b-modal :active.sync="isWindowStickerUpdateActive" :width="640" scroll="keep" :has-modal-card="true" v-if="currentInventory">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head is-block">
          <div class="subtitle is-7 has-text-centered has-text-info is-marginless">Window Sticker Status - Stock# <a @click.stop.prevent="onStockNoClick(currentInventory)"><span class="has-text-weight-semibold">{{ currentInventory.stockNo }}</span></a></div>
          <el-steps :active="active" :space="300" finish-status="wait" simple>
            <el-step class="el-step" title="Overview" icon="fal fa-fingerprint fa-lg" @click.native="active = 0"></el-step>
            <el-step class="el-step" title="Send Attachments" icon="fal fa-paperclip fa-lg" @click.native="active = 1" v-if="hasWindowStickerAttachments"></el-step>
          </el-steps>
        </header>
        <section class="modal-card-body">
          <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
          <article class="message is-primary  is-small" v-if="currentInventory.inventoryStickerRequestStatus === 1">
            <div class="message-body">
              <p><strong>Window sticker reload request in progress</strong></p>
            </div>
          </article>
          <article class="message is-danger  is-small" v-else-if="currentInventory.inventoryStickerRequestStatus === 2">
            <div class="message-body">
              <p><strong>Window sticker reload request failed.</strong></p>
            </div>
          </article>
          <div name="Overview" v-show="active === 0">
            <form class="view-container" v-if="windowStickerDataAvailable">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <button-list-selector validateAs="window sticker status" label="Window Sticker Status" :required="windowStickerStatusRequired" v-model="selectedWindowStickerStatus" :availableValues="definedTypes.windowStickerStatusOptions"></button-list-selector>
                </div>
                <div class="column is-12">
                  <image-selector
                    ref="imageSelector"
                    v-model="windowStickerFiles"
                    label="Window Sticker Image"
                    :disabled="windowStickerDisabled"
                    :required="false"
                    :isBoxed="false"
                    v-if="selectedWindowStickerStatus && selectedWindowStickerStatus.id === 0"
                    @uploadStarted="uploadStarted"
                    @uploadComplete="uploadComplete">
                  </image-selector>
                </div>
                <div class="column is-12">
                  <pdf-selector
                    ref="pdfSelector"
                    v-model="windowStickerPdfFiles"
                    label="Window Sticker Pdf"
                    :enabled="!windowStickerDisabled"
                    :required="false"
                    :isBoxed="false"
                    :usePdfModalPortal="false"
                    v-if="selectedWindowStickerStatus && selectedWindowStickerStatus.id === 0"
                    @uploadStarted="uploadStarted"
                    @uploadComplete="uploadComplete">
                  </pdf-selector>
                </div>
              </div>
            </form>
            <div style="text-align: center;" v-else>
              Loading inventory window sticker information...
              <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
            </div>
          </div>
          <div name="SendAttachments" v-show="active === 1 && hasWindowStickerAttachments">
            <form class="view-container">
              <div class="columns is-multiline">
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Customers</label>
                    <multiselect
                      :multiple="true"
                      @tag="onCustomerAdded"
                      name="customers"
                      label="fullNameEmail"
                      trackBy="id"
                      v-model="selectedCustomers"
                      :options="customers"
                      :allowEmpty="true"
                      @search-change="onCustomerSearch"
                      :searchable="true"
                      placeholder="Type to search for a customer"
                      :loading="isLoading"
                      :internal-search="false"
                      :options-limit="300">
                    </multiselect>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="field">
                    <label class="label">Suppliers</label>
                    <multiselect
                      :multiple="true"
                      @tag="onSupplierAdded"
                      name="suppliers"
                      label="fullNameEmail"
                      trackBy="id"
                      v-model="selectedSuppliers"
                      :options="suppliers"
                      :allowEmpty="true"
                      @search-change="onSupplierSearch"
                      :searchable="true"
                      placeholder="Type to search for a supplier"
                      :loading="isLoading"
                      :internal-search="false"
                      :options-limit="300">
                    </multiselect>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="columns">
                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Mobile</label>
                        <div class="control is-flex">
                          <input name="mobile" data-vv-as="mobile" :class="{'input': true }" type="text" placeholder="+1(999)-999-9999" v-mask="'+1(###)-###-####'" v-model="mobile" data-lpignore="true" autocomplete="off">
                          <a class="button is-primary new-inventory-btn" style="margin-left: 5px;" @click="onMobileNumberAdded()">
                            <span class="icon">
                              <i class="fas fa-plus"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>

                    <div class="column is-6">
                        <b-field label="Added Mobile Numbers" class="mobile-input" v-show="this.mobileNumbers.length > 0">
                          <b-taginput
                              v-model="mobileNumbers" :data="mobileNumbers" field="mobile" :readonly="true" :allow-new="false" :rounded="true" size="is-small">
                          </b-taginput>
                        </b-field>
                    </div>
                  </div>
                </div>
                <div class="column is-12">
                  <div class="columns">
                    <div class="column is-6">
                      <div class="field">
                        <label class="label">Email Address</label>
                          <div class="is-flex">
                            <div class="control has-icons-left has-icons-right" style="width: 90%">
                              <input name="email" data-vv-as="email address" v-validate="{'email': true}" data-vv-delay="1000" :class="{'input': true, 'is-danger': errors.has('email') }" type="text" placeholder="Email" v-model="email" data-lpignore="true" autocomplete="off">
                              <span v-show="errors.has('email')" class="help is-danger">
                                <i v-show="errors.has('email')" class="fas fa-exclamation-triangle"></i> {{ errors.first('email') }}
                              </span>
                              <span class="icon is-small is-left">
                                <i class="fas fa-envelope"></i>
                              </span>
                              <span class="icon is-small is-right" v-show="!errors.has('email')">
                                <i class="fas fa-check"></i>
                              </span>
                              <span class="icon is-small is-right" v-show="errors.has('email')">
                                <i class="fas fa-exclamation-triangle"></i>
                              </span>
                            </div>
                            <a class="button is-primary new-inventory-btn" style="margin-left: 5px;" @click="onEmailAdded()">
                              <span class="icon">
                                <i class="fas fa-plus"></i>
                              </span>
                            </a>
                          </div>
                      </div>
                    </div>

                    <div class="column is-6">
                        <b-field label="Added Email Addresses" class="mobile-input" v-show="this.emails.length > 0">
                          <b-taginput
                              v-model="emails" :data="emails" field="email" :readonly="true" :allow-new="false" :rounded="true" size="is-small">
                          </b-taginput>
                        </b-field>
                    </div>
                  </div>
                </div>
              </div>
            </form>
            <b-loading :is-full-page="false" :active.sync="isLoading" :canCancel="false"></b-loading>
          </div>
        </section>
        <footer class="modal-card-foot">
          <button :disabled="isUploadingFile" class="button is-primary" type="button" :class="{'is-loading': isSaving }"  @click="onSave()" v-if="isOverviewTabOpen && !isPpaClosed">Save</button>
          <button class="button is-primary" type="button" :class="{'is-loading': isSaving }"  @click="onSend('SmsEmail')" v-if="isSendTabOpen && !isPpaClosed" :disabled="!canSend || isUploadingFile">Send</button>
          <button class="button is-primary" type="button" :class="{'is-loading': isSaving }"  @click="onSend('Sms')" v-if="isSendTabOpen && !isPpaClosed" :disabled="!canSendSms || isUploadingFile">Send Sms</button>
          <button class="button is-primary" type="button" :class="{'is-loading': isSaving }"  @click="onSend('Email')" v-if="isSendTabOpen && !isPpaClosed" :disabled="!canSendEmail || isUploadingFile">Send Email</button>
          <button :disabled="isUploadingFile" class="button is-primary" type="button" :class="{'is-loading': isRegenerating }"  @click="onRequestStickerReload()" v-if="!currentInventory.windowStickerReloadRequested && isOverviewTabOpen && !isPpaClosed">Regenerate</button>
          <button class="button is-danger" type="button" @click="onCancel()">Cancel</button>
        </footer>
      </div>
    </b-modal>
  </portal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import formattingMixin from '@/mixins/inventory/formatting'
import utilitiesMixin from '@/mixins/generic/utilities'
import dealInventoryStyleClasses from '@/mixins/generic/dealInventoryStyleClasses'
import inventoryService from '@/services/inventoryService'
import ButtonListSelector from '@/components/generic/ButtonListSelector'
import ImageSelector from '@/components/generic/ImageSelector'
import PdfSelector from '@/components/generic/PdfSelector'
import SectionHeader from '@/components/generic/SectionHeader'
import FileSelector from '@/components/generic/FileSelector'
import ErrorDisplayComponent from '@/components/generic/ErrorDisplayComponent'
import customerService from '@/services/customerService'
import supplierService from '@/services/supplierService'
import notificationService from '@/services/notificationService'
import { Steps, Step } from 'element-ui'
import _ from 'lodash'

const mapConfigGetters = createNamespacedHelpers('config').mapGetters

export default {
  name: 'InventoryWindowStickerCapture',
  mixins: [utilitiesMixin, formattingMixin, dealInventoryStyleClasses],
  components: {
    'file-selector': FileSelector,
    'button-list-selector': ButtonListSelector,
    'image-selector': ImageSelector,
    'pdf-selector': PdfSelector,
    'section-header': SectionHeader,
    'error-display-component': ErrorDisplayComponent,
    'el-steps': Steps,
    'el-step': Step
  },
  props: {
    currentInventory: {
      type: Object,
      default: null
    },
    size: {
      type: String,
      default: 'is-width-600'
    },
    label: {
      type: String,
      default: ''
    },
    isListMode: {
      type: Boolean,
      default: false
    },
    isDoubleStackedModal: {
      type: Boolean,
      default: false
    },
    autoOpenWindowSticker: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      instanceId: this.$uuid.v4(),
      isSaving: false,
      isRegenerating: false,
      isLoading: false,
      isWindowStickerUpdateActive: false,
      windowStickerDisabled: false,
      selectedWindowStickerStatus: null,
      windowStickerFiles: [],
      windowStickerPdfFiles: [],
      active: 0,
      selectedCustomers: [],
      customers: [],
      selectedSuppliers: [],
      suppliers: [],
      mobileNumbers: [],
      mobile: '',
      emails: [],
      email: '',
      serverErrors: [],
      serverErrorMessage: '',
      isUploadingFile: false,
      fileUploadCount: 0
    }
  },
  computed: {
    ...mapConfigGetters(['definedTypes']),
    windowStickerStatusRequired: function () {
      return false
    },
    windowStickerStatusDescription: function () {
      if (this.currentInventory.windowStickerStatus === 'Yes') return 'Window sticker has been received'
      return 'Window sticker has not been received'
    },
    windowStickerDataAvailable: function () {
      if (!this.isLoading || (this.currentInventory !== undefined && this.currentInventory.attachments !== undefined && this.currentInventory.attachments.length > 0)) {
        return true
      }
      return false
    },
    isOverviewTabOpen: function () {
      return this.active === 0
    },
    isSendTabOpen: function () {
      return this.active === 1
    },
    canSend: function () {
      return this.selectedCustomers.length > 0 || this.selectedSuppliers.length > 0 || this.mobileNumbers.length > 0 || this.emails.length > 0
    },
    canSendSms: function () {
      return this.selectedCustomers.length > 0 || this.selectedSuppliers.length > 0 || this.mobileNumbers.length > 0
    },
    canSendEmail: function () {
      return this.selectedCustomers.length > 0 || this.selectedSuppliers.length > 0 || this.emails.length > 0
    },
    hasWindowStickerAttachments: function () {
      if (this.currentInventory !== undefined && this.currentInventory.attachments !== undefined && this.currentInventory.attachments.filter((x) => x.classType === 'WindowSticker').length > 0) {
        return true
      }
      return false
    },
    isPpaClosed: function () {
      return this.currentInventory.type === 'PpaClosed'
    }
  },
  methods: {
    onUpdateWindowStickerStatus: function () {
      this.isWindowStickerUpdateActive = true
      this.loadWindowStickerInfo()
      let that = this
      this.$nextTick(() => {
        if (that.autoOpenWindowSticker) {
          if (that.windowStickerPdfFiles.length > 0) {
            that.$refs.pdfSelector.showPdf(that.windowStickerPdfFiles[0])
          }
        }
      })
    },
    onSave: function () {
      let existingAttachments = [...this.windowStickerFiles.filter((y) => y.id > 0), ...this.windowStickerPdfFiles.filter((y) => y.id > 0)]
      let windowStickUpdateModel = {
        id: this.currentInventory.id,
        windowStickerStatus: this.selectedWindowStickerStatus ? this.selectedWindowStickerStatus.name : null,
        attachments: existingAttachments
      }
      if (this.windowStickerFiles.length > 0) {
        var stickerFiles = this.windowStickerFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'WindowSticker'
          }
        })

        windowStickUpdateModel.attachments.push(...stickerFiles)
      }

      if (this.windowStickerPdfFiles.length > 0) {
        var stickerPdfFiles = this.windowStickerPdfFiles.filter((y) => !y.id || y.id <= 0).map((x) => {
          return {
            uploadName: x.name,
            uploadSize: x.size,
            uniqueId: x.uniqueId,
            type: x.type,
            classType: 'WindowSticker'
          }
        })

        windowStickUpdateModel.attachments.push(...stickerPdfFiles)
      }

      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isSaving = true

      inventoryService.updateWindowStickerStatus(windowStickUpdateModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.currentInventory.windowStickerStatus = response.inventory.windowStickerStatus
          this.currentInventory.attachments = response.inventory.attachments
          this.isWindowStickerUpdateActive = false
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    loadData: function () {
      this.windowStickerStatus = null
      this.windowStickerPdfFiles = []
      this.windowStickerFiles = []
      if (this.currentInventory) {
        this.selectedWindowStickerStatus = this.definedTypes.windowStickerStatusOptions.find((x) => (x.name === this.currentInventory.windowStickerStatus || x.altName === this.currentInventory.windowStickerStatus))
        this.currentInventory.attachments.filter((x) => x.classType === 'WindowSticker' && x.status === 'Active').forEach((x) => {
          x.loaded = true
          x.imageData = x.fileLocation
          x.fileType = this.getFileType(x.fileLocation)
          if (x.type === 'Image') {
            this.windowStickerFiles.push(x)
          } else if (x.type === 'Pdf') {
            this.windowStickerPdfFiles.push(x)
          }
        })
        if (this.currentInventory.lockWindowSticker) {
          this.windowStickerDisabled = true
        }
      }
    },
    loadWindowStickerInfo: function () {
      if (!this.currentInventory.attachments || this.currentInventory.attachments.length === 0) {
        this.isLoading = true
        inventoryService.getWindowStickerInfo(this.currentInventory).then(response => {
          if (response.errors) {
            this.serverErrors = response.errors
          } else {
            this.$set(this.currentInventory, 'attachments', response.inventory.attachments)
          }
          this.isLoading = false
        }).catch((error) => {
          this.serverErrorMessage = error.message
          this.isLoading = false
        })
      }
    },
    onCustomerAdded: function (data) {
      var recipient = {
        fullName: data.fullName,
        mobile: data.mobile,
        id: data.id,
        email: data.email,
        fullNameEmail: data.fullName + ' - ' + data.email + '  ' + data.mobile
      }
      this.selectedCustomers.push(recipient)
    },
    clearAllRecipients: function () {
      this.selectedCustomers = []
      this.selectedSuppliers = []
      this.mobile = ''
      this.email = ''
      this.mobileNumbers = []
      this.emails = []
    },
    loadRetailCustomers: function (dealerId) {
      this.isLoading = true
      customerService.findAllByDealerIdForNotification(dealerId).then(response => {
        response.results.forEach((item) => {
          if (this.customers.indexOf(item.displayName) < 0) {
            var recipient = {
              fullName: item.displayName,
              mobile: item.mobile,
              id: item.id,
              email: item.email,
              fullNameEmail: item.displayName + ' - ' + item.email + '  ' + item.mobile
            }
            this.customers.push(recipient)
          }
        })
        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        throw error
      })
    },
    onSupplierAdded: function (data) {
      var supplier = {
        fullName: data.fullName,
        mobile: data.mobile,
        id: data.id,
        email: data.email,
        fullNameEmail: data.fullName + ' - ' + data.email + '  ' + data.mobile
      }
      this.selectedSuppliers.push(supplier)
    },
    loadSuppliers: function () {
      this.isLoading = true
      supplierService.findAllForNotification().then(response => {
        response.results.forEach((item) => {
          if (this.suppliers.indexOf(item.displayName) < 0) {
            var recipient = {
              fullName: item.primaryContact,
              mobile: item.primaryContactMobile,
              id: item.id,
              email: item.email,
              fullNameEmail: item.primaryContact + ' - ' + item.email + ' ' + item.primaryContactMobile
            }
            this.suppliers.push(recipient)
          }
        })

        this.isLoading = false
      }).catch((error) => {
        this.isLoading = false
        throw error
      })
    },
    onSend: function (notificationType) {
      this.$validator.validateAll('recipient-form').then((result) => {
        if (result) {
          // need to set tracking number, first check on user then dealer. We use this with InteractiveTel sms gateway
          let trackingNumber = (this.currentUser.activeTrackingNumber && this.currentUser.activeTrackingNumber !== '') ? this.currentUser.activeTrackingNumber : this.currentDealer.defaultCallTrackingNumber
          let allRecipients = [...this.selectedCustomers, ...this.selectedSuppliers, ...this.mobileNumbers, ...this.emails]

          var windowStickerSendModel = {
            inventoryId: this.currentInventory.id,
            dealerId: this.$store.state.dealer.currentDealer.id,
            callTrackingNumber: trackingNumber,
            recipients: allRecipients,
            notificationType: notificationType
          }

          this.isSaving = true
          notificationService.windowStickerSend(windowStickerSendModel).then(response => {
            this.isSaving = false
            if (response.errors) {
              this.failedToast('Oops! Something went wrong')
              this.serverErrors = response.errors
            } else {
              this.active = 0
              this.clearAllRecipients()
              this.isWindowStickerUpdateActive = false
              this.successToast('Success! Notification queued')
            }
          }).catch((error) => {
            this.failedToast('Oops! Something went wrong')
            this.isSaving = false
            this.serverErrorMessage = error.message
          })
        }
      })
    },
    onMobileNumberAdded: function () {
      let found = this.mobileNumbers.find((x) => x.mobile === this.mobile)
      if (!found && this.mobile.length === 16) {
        let recipient = {
          fullName: '',
          mobile: this.mobile,
          id: 0,
          email: '',
          fullNameEmail: ''
        }
        this.mobileNumbers.push(recipient)
        this.mobile = ''
      }
    },
    onEmailAdded: function () {
      let found = this.emails.find((x) => x.email === this.email)
      if (!found) {
        this.$validator.validate('email').then((result) => {
          if (result) {
            let recipient = {
              fullName: '',
              mobile: '',
              id: 0,
              email: this.email,
              fullNameEmail: ''
            }
            this.emails.push(recipient)
            this.email = ''
          }
        })
      }
    },
    onCancel: function () {
      this.active = 0
      this.isWindowStickerUpdateActive = false
      this.clearAllRecipients()
    },
    loadSupplierAsyncData (query) {
      var searchCommand = {
        perPage: 32767,
        sortField: 'name',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
        tabIndex: 0,
        showIncomplete: false,
        showInactive: false,
        showBlocked: false,
        query: query,
        page: 1
      }
      this.isLoading = true
      this.suppliers = []

      supplierService.findAllForNotification(searchCommand).then(response => {
        response.results.forEach((item) => {
          if (this.suppliers.indexOf(item.displayName) < 0) {
            var recipient = {
              fullName: item.primaryContact,
              mobile: item.primaryContactMobile,
              id: item.id,
              email: item.email,
              fullNameEmail: item.primaryContact + ' - ' + item.email + ' ' + item.primaryContactMobile
            }
            this.suppliers.push(recipient)
          }
        })
        this.isLoading = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
      })
    },
    doSupplierSearch: _.debounce(function (query) { this.loadSupplierAsyncData(query) }, 500),
    onSupplierSearch (query) {
      if (query !== '') {
        this.doSupplierSearch(query)
      }
    },
    loadCustomerAsyncData (query) {
      var searchCommand = {
        perPage: 32767,
        sortField: 'name',
        sortOrder: 'asc',
        defaultSortOrder: 'asc',
        tabIndex: 0,
        showIncomplete: false,
        showInactive: false,
        showBlocked: false,
        query: query,
        page: 1,
        dealerId: this.$store.state.dealer.currentDealer.id
      }
      this.isLoading = true
      this.customers = []

      customerService.findAllByDealerIdForNotification(searchCommand).then(response => {
        response.results.forEach((item) => {
          if (this.customers.indexOf(item.displayName) < 0) {
            var recipient = {
              fullName: item.displayName,
              mobile: item.mobile,
              id: item.id,
              email: item.email,
              fullNameEmail: item.displayName + ' - ' + item.email + '  ' + item.mobile
            }
            this.customers.push(recipient)
          }
        })
        this.isLoading = false
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isLoading = false
      })
    },
    doCustomerSearch: _.debounce(function (query) { this.loadCustomerAsyncData(query) }, 500),
    onCustomerSearch (query) {
      if (query !== '') {
        this.doCustomerSearch(query)
      }
    },
    onRequestStickerReload: function () {
      this.serverErrorMessage = ''
      this.serverErrors = []
      this.isRegenerating = true

      inventoryService.requestWindowStickerReload(this.currentInventory.id).then(response => {
        this.isRegenerating = false
        if (response.errors) {
          this.failedToast('Oops! Something went wrong')
          this.serverErrors = response.errors
        } else {
          this.active = 0
          this.currentInventory.inventoryStickerRequestStatus = 1
          this.currentInventory.windowStickerReloadRequested = true
          this.isWindowStickerUpdateActive = false
          this.successToast('Success! Request queued')
        }
      }).catch((error) => {
        this.failedToast('Oops! Something went wrong')
        this.serverErrorMessage = error.message
        this.isRegenerating = false
      })
    },
    onStockNoClick: function (inventory) {
      let that = this

      this.$copyText(inventory.stockNo).then(
        function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Stock No. ' + inventory.stockNo + ' copied to clipboard',
            position: 'is-top',
            type: 'is-info'
          })
        }, function (e) {
          that.$buefy.toast.open({
            duration: 2500,
            message: 'Could not copy stock no. to clipboard',
            position: 'is-top',
            type: 'is-warning'
          })
        }
      )
    },
    uploadStarted: function () {
      this.fileUploadCount++
      this.isUploadingFile = true
    },
    uploadComplete: function () {
      this.fileUploadCount--
      if (this.fileUploadCount === 0) {
        this.isUploadingFile = false
      }
    }
  },
  watch: {
    '$route.params.id': function (id) {
    },
    currentInventory: function (newValue, oldValue) {
      if (newValue) {
        this.loadData()
        this.active = 0
        this.clearAllRecipients()
      }
    }
  },
  mounted: function () {
    this.clearAllRecipients()
    this.loadData()
  },
  updated: function () {
  }
}

</script>

<style scoped>
  .columns {
    padding-bottom: 0 !important;
    margin-bottom:0 !important;
  }

  .modal-card-head, .modal-card-foot {
    padding: 20px !important;
  }

  .modal-card {
    overflow: auto;
    min-width: 800px !important;
    max-width: 800px !important;
    width: 800px !important;
  }

  .modal-card-body {
    max-height: 575px !important;
    min-height: 575px !important;
    height: 575px !important;
  }

  .el-steps--simple {
    border-radius: 0 !important;
  }

  .window-sticker-container {
    cursor: pointer;
  }
</style>

<template>
  <section>
    <portal :to="headerPortal" :disabled="headerPortal === ''">
      <h1 class="subtitle is-5" :class="{'is-paddingless': (headerPortal !== '')}">Add Tag</h1>
    </portal>
    <error-display-component :serverErrors="serverErrors" :serverErrorMessage="serverErrorMessage"></error-display-component>
    <form @submit.prevent="validateBeforeSubmit" @change="onFormChange">
      <div class="columns">
        <div class="column is-6">
          <div class="field">
            <label class="label">Name<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="control">
              <input name="name" data-vv-as="name" v-validate="'required'" :class="{'input': true, 'is-danger': errors.has('name') }" type="text" placeholder="e.g Sales" v-model="name">
              <span v-show="errors.has('name')" class="help is-danger"><i v-show="errors.has('name')" class="fas fa-exclamation-triangle"></i> {{ errors.first('name') }}</span>
            </div>
          </div>
        </div>
        <div class="column is-6">
          <div class="field">
            <label class="label">Description<b-icon pack="fas" icon="star" class="fa-ss"></b-icon></label>
            <div class="control">
              <input name="description" data-vv-as="description" :class="{'input': true }" type="text" v-model="description">
            </div>
          </div>
        </div>
      </div>
      <portal :to="actionPortal">
        <div class="field is-grouped">
          <p class="control">
            <button class="button is-primary" :class="{'is-loading': isSaving }"  type="button" @click="validateBeforeSubmit">Save</button>
          </p>
          <p class="control">
            <a class="button is-danger" @click="onCancel()">Cancel</a>
          </p>
        </div>
      </portal>
    </form>
  </section>
</template>

<script>
import tagService from '@/services/tagService'
import tagMixin from '@/mixins/tags/index'
import validateBeforeSubmitMixin from '@/mixins/generic/validateBeforeSubmit'
import completeCancelInitMixin from '@/mixins/generic/completeCancelInit'

export default {
  name: 'NewTag',
  mixins: [tagMixin, validateBeforeSubmitMixin, completeCancelInitMixin],
  props: {
    actionPortal: {
      type: String,
      default: 'footer-toolbar-left'
    },
    headerPortal: {
      type: String,
      default: ''
    },
    mode: {
      type: String,
      default: 'full-screen'
    },
    startWith: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      id: 0,
      name: this.startWith,
      description: '',
      serverErrors: [],
      serverErrorMessage: '',
      isSaving: false,
      getValidationErrors: false
    }
  },
  computed: {
  },
  methods: {
    onSave: function () {
      var tagModel = {
        isCreate: true,
        subscriberId: this.$store.state.user.currentUser.subscriberId,
        name: this.name,
        description: this.description,
        status: 'Active'
      }

      this.isSaving = true
      this.serverErrorMessage = ''
      this.serverErrors = []

      tagService.save(tagModel).then(response => {
        this.isSaving = false
        if (response.errors) {
          this.serverErrors = response.errors
        } else {
          this.$emit('on-save', response.tag)
          this.onChangesSaved()
          if (this.mode === 'full-screen') {
            this.onComplete()
          }
        }
      }).catch((error) => {
        this.serverErrorMessage = error.message
        this.isSaving = false
      })
    },
    onCancel: function () {
      this.$emit('on-cancel')
      if (this.mode === 'full-screen') {
        this.onComplete()
      }
    },
    onComplete: function () {
      this.$router.push({name: 'ListTags'})
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.is-checkradio {
  position: relative !important;
}

</style>

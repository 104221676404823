<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      :key="instanceName"
      :name="instanceName"
      :data-vv-scope="scope"
      :taggable="true"
      @tag="supplierAdded"
      tagPlaceholder="Press enter to create a new entity"
      tagPosition="bottom"
      :searchable="true"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      :disabled="isDisabled"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedSupplier"
      :options="allSuppliers"
      label="name"
      :placeholder="placeholder"
      trackBy="id"
      :loading="isSupplierLoading"
      :internal-search="false"
      :options-limit="300"
      @search-change="onSearch"
      :hideSelected="true"
      ref="selector"></multiselect>
      <a class="button is-primary new-supplier-btn" @click="isNewSupplierActive = true" v-if="allowNew && showNewAction">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <br/>
    <div v-if="rejected" class="field is-size-7 is-pulled-right">
      <b-checkbox
        :native-value="false"
        name="includeActive"
        type="is-info"
        v-model="includeActiveSuppliers">
        Include active suppliers
      </b-checkbox>
    </div>
    <b-modal ref="newSupplierModal" :active.sync="isNewSupplierActive" scroll="keep">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body" style="min-width:400px !important; height:550px;">
          <new-supplier
            ref="newSupplierComponent"
            mode="modal"
            actionPortal="modal-footer"
            headerPortal="modal-header"
            v-on:on-save="onSupplierSaved"
            v-on:on-cancel="onSupplierCancelled"
            :startWith="nameOfSupplierToCreate"
            :rejected="rejected"
            :requiredParentId="parentId">
          </new-supplier>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import _ from 'lodash'
import NewSupplier from '@/components/configuration/suppliers/NewSupplier'
import supplierService from '@/services/supplierService'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  name: 'SupplierSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Supplier'
    },
    placeholder: {
      type: String,
      default: 'Type to search for a loaded supplier'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    validateAs: {
      type: String,
      default: 'Supplier'
    },
    required: {
      type: Boolean,
      default: true
    },
    allowNew: {
      type: Boolean,
      default: true
    },
    scope: {
      type: String,
      default: null
    },
    showNewAction: {
      type: Boolean,
      default: false
    },
    vendorsOnly: {
      type: Boolean,
      default: false
    },
    suppliersOnly: {
      type: Boolean,
      default: false
    },
    useValidator: {
      type: Object,
      default: null
    },
    rejected: {
      type: Boolean,
      default: false
    },
    parentId: {
      type: Number,
      default: 0
    }
  },
  components: {
    'new-supplier': NewSupplier
  },
  data () {
    return {
      isSupplierLoading: false,
      isNewSupplierActive: false,
      selectedSupplier: this.value,
      nameOfSupplierToCreate: '',
      data: [],
      instanceName: 'supplier-selector-' + this.$uuid.v4(),
      includeActiveSuppliers: false
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    allSuppliers () {
      return this.data
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      // Only reset the supplier on dealer change if its not the same dealer as the supplier already belongs to
      if (this.selectedSupplier && this.selectedSupplier.dealerId !== this.currentDealer.id) {
        this.selectedSupplier = null
      }
    },
    selectedSupplier: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentSupplier: function (newValue, oldValue) {
      this.selectedSupplier = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedSupplier = newValue
      if (newValue) {
        this.data.push(newValue)
      }
    }
  },
  methods: {
    supplierAdded: function (data) {
      if (this.allowNew) {
        this.nameOfSupplierToCreate = data
        this.isNewSupplierActive = true
        if (this.$refs.newSupplierModal) {
          this.$nextTick(() => {
            if (this.$refs.newSupplierComponent) {
              this.$refs.newSupplierComponent.loadAsyncData(this.nameOfSupplierToCreate)
            }
          })
        }
      }
    },
    onSupplierSaved: function (newSupplier) {
      this.data.push(newSupplier)
      this.selectedSupplier = newSupplier
      this.isNewSupplierActive = false
    },
    onSupplierCancelled: function () {
      this.isNewSupplierActive = false
    },
    clearAllSuppliers () {
      this.data = []
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.currentDealer.id,
        sortBy: 'name.asc',
        vendorsOnly: this.vendorsOnly,
        suppliersOnly: this.suppliersOnly,
        supplierType: '-1',
        requiresStatistics: false,
        showRejectedSuppliers: this.rejected,
        includeActiveSuppliers: this.includeActiveSuppliers
      }
      this.isSupplierLoading = true
      this.clearAllSuppliers()
      supplierService.all(searchCommand).then(response => {
        this.data = response.results
        this.isSupplierLoading = false
      })
    },
    doSupplierSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onSearch (query) {
      if (!query || query === '') {
        return
      }
      this.doSupplierSearch(query)
    }
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  }
}

</script>

<style scoped>
  .new-supplier-btn {
    margin-left:5px;
    height:40px;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(100vh - 185px);
  }
</style>

<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect
        @select="$markDirty()"
        :showLabels="false"
        :name="validationName"
        :data-vv-scope="scope"
        tagPlaceholder="Press enter to create a new tag number"
        :data-vv-as="validateAs"
        data-vv-validate-on="input|close"
        v-validate="{'required': required}"
        :class="{'is-danger': errors.has(validationName) }"
        tagPosition="bottom"
        ref="tagNumber"
        :searchable="true"
        :taggable="true"
        @tag="tagNumberAdded"
        v-model="selectedTagNumber"
        :options="allTagNumbers"
        :disabled="isDisabled"
        :label="useInternalLabel"
        trackBy="id"
        :hideSelected="true">
      </multiselect>
      <a class="button is-primary new-insurer-btn" @click="isNewTagNumberActive = true" v-if="allowNew && showNewAction">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <b-modal :active.sync="isNewTagNumberActive" scroll="keep" :width="500" :has-modal-card="true">
      <div class="modal-card" style="width: 500px">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body" style="min-width:400px !important; height:250px;">

        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import dealerTagNumberService from '@/services/dealerTagNumberService'

export default {
  name: 'TagNumberSelector',
  props: {
    value: {
      type: Object,
      default: null
    },
    label: {
      type: String,
      default: 'Tag Number'
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    validateAs: {
      type: String,
      default: 'Tag '
    },
    required: {
      type: Boolean,
      default: true
    },
    showReserved: {
      type: Boolean,
      default: true
    },
    selectedDealer: {
      type: Object,
      default: null
    },
    allowNew: {
      type: Boolean,
      default: true
    },
    showNewAction: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    },
    useInternalLabel: {
      type: String,
      default: 'tagDesc'
    },
    useValidator: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isTagNumbersLoading: false,
      isNewTagNumberActive: false,
      selectedTagNumber: this.value,
      nameOfTagNummberToCreate: '',
      data: [],
      instanceName: 'tag-number-selector-' + this.$uuid.v4(),
      storeDealer: this.$store.state.dealer.currentDealer
    }
  },
  computed: {
    allTagNumbers () {
      if (this.showReserved) {
        return this.data
      }
      return this.data.filter((x) => x.reserved === false)
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    selectedTagNumber: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    value: function (newValue, oldValue) {
      this.selectedTagNumber = newValue
    },
    selectedDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
      if (this.selectedTagNumber && this.selectedTagNumber.dealerId !== this.selectedDealer.id) {
        this.selectedTagNumber = null
      }
    },
    storeDealer: function (newValue, oldValue) {
      this.selectedDealer = newValue
    }
  },
  methods: {
    tagNumberAdded: function (data) {
      if (this.allowNew) {
        if (this.data.find((x) => x.tagNumber === data) === 0) {
          let tagNumber = {
            id: 0,
            dealerId: this.selectedDealer.id,
            tagNumber: data,
            status: 'Active',
            reserved: false,
            reservedFor: null,
            tagDesc: data
          }
          this.data.push(tagNumber)
          this.selectedTagNumber = tagNumber
        } else {
          this.selectedTagNumber = this.data.find((x) => x.tagNumber === data)
        }
      }
    },
    onTagNumberSaved: function (newTagNumber) {
      this.data.push(newTagNumber)
      this.selectedTagNumber = newTagNumber
      this.isNewTagNumberActive = false
    },
    onTagNumberCancelled: function () {
      this.isNewTagActive = false
    },
    clearAllTagNumbers () {
      this.data = []
    },
    loadAsyncData: function (query) {
      var dealerId = this.$store.state.dealer.currentDealer.id
      this.isTagNumbersLoading = true
      this.clearAllTagNumbers()
      dealerTagNumberService.findAllByDealerId(dealerId).then(response => {
        this.data = response.results
        this.selectedTagNumber = (this.value && this.value.id === -1) ? this.data.find((x) => x.tagNumber === this.value.tagNumber) : this.value
        this.isTagNumbersLoading = false
      }).catch((error) => {
        this.isTagNumbersLoading = false
        throw error
      })
    }
  },
  created: function () {
    if (this.useValidator) {
      this.$validator = this.useValidator
    } else {
      this.$validator = this.$parent.$validator
    }
  },
  mounted: function () {
    this.loadAsyncData()
  }
}

</script>

<style scoped>
  .new-insurer-btn {
    margin-left:5px;
    height:40px;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(100vh - 185px);
  }
</style>

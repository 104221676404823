export default {
  total: 1,
  results: [
    {
      id: 1,
      status: 'Active',
      createdAt: '01/01/2000',
      modifiedAt: '01/01/2000',
      dealerId: 1,
      cdkDate: '01/01/2000',
      cdkAccountId: 1,
      cdkCompanyId: 1,
      stockNo: 'FP5589',
      inventory: {},
      deal: {},
      payeeType: 'Customer',
      customerNumber: '12947',
      customerName: 'Left Lane Exotics Llc',
      description: 'CHECK CHECK',
      journalId: 1,
      expectedAmount: 5000.00,
      cdkAmount: 5000.00,
      referenceNumber: '365434',
      paymentStatus: 'Paid',
      cdkLastUpdatedAt: '01/01/2000',
      paymentMethod: 'Check',
      customerId: 1,
      createdBy: 1,
      modifiedBy: 1,
      inventoryItemName: '2019 Alfa Romeo Giulia'
    }
  ]
}

<template>
  <div class="field">
    <label class="label">
      {{label}}
      <b-icon pack="fas" icon="star" class="fa-ss" v-show="required"></b-icon>
    </label>
    <div class="control is-flex">
      <multiselect @select="$markDirty()"
      :showLabels="false"
      :name="instanceName"
      :multiple="true"
      :taggable="true"
      @tag="accessoryBinAdded"
      tagPlaceholder="Press enter to create a new accessory bin"
      tagPosition="bottom"
      :searchable="true"
      data-vv-validate-on="input|close"
      :data-vv-as="this.validateAs"
      v-validate="{'required': required}"
      :class="{'is-danger': errors.has(validationName) }"
      v-model="selectedAccessoryBins"
      :options="allAccessoryBins"
      label="code"
      :hideSelected="true"
      :placeholder="placeholder"
      trackBy="id"
      :loading="isLoading"
      :internal-search="false"
      :options-limit="300"
      @search-change="onSearch"
      :data-vv-scope="scope"></multiselect>
      <a class="button is-primary new-accessory-bin-btn" @click="isNewAccessoryBinActive = true" v-if="allowNew && showNewAction">
        <span class="icon">
          <i class="fas fa-plus"></i>
        </span>
      </a>
    </div>
    <span v-show="errors.has(validationName)" class="help is-danger"><i v-show="errors.has(validationName)" class="fas fa-exclamation-triangle"></i> {{ errors.first(validationName) }}</span>
    <b-modal :active.sync="isNewAccessoryBinActive" scroll="keep" id="newBinModal">
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title"><portal-target name="modal-header" slim/></p>
        </header>
        <section class="modal-card-body" style="height:350px;">
          <new-accessory-bin
            mode="modal"
            actionPortal="modal-footer"
            headerPortal="modal-header"
            v-on:on-save="onAccessoryBinSaved"
            v-on:on-cancel="onAccessoryBinCancelled"
            :startWith="nameOfAccessoryBinToCreate">
          </new-accessory-bin>
        </section>
        <footer class="modal-card-foot">
          <portal-target name="modal-footer" class="actions" slim/>
        </footer>
      </div>
    </b-modal>
  </div>
</template>

<script>

import _ from 'lodash'
import NewAccessoryBin from '@/components/configuration/accessoryBin/NewAccessoryBin'
import accessoryBinService from '@/services/accessoryBinService'
import { createNamespacedHelpers } from 'vuex'
const mapDealerState = createNamespacedHelpers('dealer').mapState

export default {
  inject: {
    $validator: '$validator'
  },
  name: 'AccessoryBinSelector',
  props: {
    value: {
      type: Array,
      default: () => []
    },
    label: {
      type: String,
      default: 'Accessory Bins'
    },
    placeholder: {
      type: String,
      default: 'Type to search for a loaded accessory bin'
    },
    validateAs: {
      type: String,
      default: 'Accessory Bin'
    },
    required: {
      type: Boolean,
      default: true
    },
    allowNew: {
      type: Boolean,
      default: true
    },
    showNewAction: {
      type: Boolean,
      default: false
    },
    scope: {
      type: String,
      default: null
    }
  },
  components: {
    'new-accessory-bin': NewAccessoryBin
  },
  data () {
    return {
      isLoading: false,
      isNewAccessoryBinActive: false,
      selectedAccessoryBins: [],
      nameOfAccessoryBinToCreate: '',
      data: [],
      instanceName: 'accessory-bin-selector-' + this.$uuid.v4()
    }
  },
  computed: {
    ...mapDealerState(['currentDealer']),
    allAccessoryBins () {
      return this.data
    },
    validationName: function () {
      return this.scope ? this.scope + '.' + this.instanceName : this.instanceName
    }
  },
  watch: {
    currentDealer: function (newValue, oldValue) {
      // Only reset the AccessoryBin on dealer change if its not the same dealer as the AccessoryBin already belongs to
      if (this.selectedAccessoryBins && this.selectedAccessoryBins.dealerId !== this.currentDealer.id) {
        this.selectedAccessoryBins = []
      }
    },
    selectedAccessoryBins: function (newValue, oldValue) {
      this.$emit('input', newValue)
    },
    currentAccessoryBin: function (newValue, oldValue) {
      this.selectedAccessoryBins = newValue
    },
    value: function (newValue, oldValue) {
      this.selectedAccessoryBins = newValue
    }
  },
  methods: {
    accessoryBinAdded: function (data) {
      if (this.allowNew) {
        this.nameOfAccessoryBinToCreate = data
        this.isNewAccessoryBinActive = true
      }
    },
    onAccessoryBinSaved: function (newAccessoryBin) {
      this.data.push(newAccessoryBin)
      this.selectedAccessoryBins.push(newAccessoryBin)
      this.isNewAccessoryBinActive = false
    },
    onAccessoryBinCancelled: function () {
      this.isNewAccessoryBinActive = false
    },
    clearAllAccessoryBins () {
      this.data = []
    },
    loadAsyncData: function (query) {
      var searchCommand = {
        page: 0,
        pageSize: 100,
        query: query,
        dealerId: this.currentDealer.id,
        sortBy: 'code.asc'
      }
      this.isLoading = true
      this.clearAllAccessoryBins()
      accessoryBinService.all(searchCommand).then(response => {
        this.data = response.results
        this.isLoading = false
      })
    },
    doAccessoryBinSearch: _.debounce(function (query) { this.loadAsyncData(query) }, 500),
    onSearch (query) {
      this.doAccessoryBinSearch(query)
    }
  },
  mounted: function () {
    this.selectedAccessoryBins = this.value
  }
}

</script>

<style scoped>
  .new-accessory-bin-btn {
    margin-left:5px;
    height:40px;
  }

  .modal-card-body {
    overflow:auto;
    height: calc(100vh - 185px);
  }

</style>

<template>
  <section class="view-container">
    <h1 v-if="!modalView">View Transaction</h1>
    <div class="box">
        <div class="columns is-multiline">
            <div class="column is-6">
              <label class="label">Created By</label>
              <div class="field">
                {{ selectedTransaction.userName }}
              </div>
            </div>
            <div class="column is-6">
              <label class="label">Reference ID</label>
              <div class="field">
                {{ selectedTransaction.referenceId }}
              </div>
            </div>
            <div class="column is-6">
                <label class="label">Description</label>
                <div class="field">
                    {{ selectedTransaction.description }}
                </div>
            </div>
            <div class="column is-6">
              <label class="label">Transaction Type</label>
              <div class="field">
                {{ selectedTransaction.transactionTypeDescription }}
              </div>
            </div>
            <div class="column is-6">
                <label class="label">Reference Type</label>
                <div class="field">
                    {{ selectedTransaction.transactionReferenceType }}
                </div>
            </div>
            <div class="column is-6">
                <label class="label">Date Created</label>
                <div class="field">
                    {{selectedTransaction.createdAt | formatDateOnly}}
                </div>
            </div>
            <div class="column is-6">
                <label class="label">Amount</label>
                <div class="field">
                    <span :class="getColorClassByTransactionType(selectedTransaction, selectedTransaction.transactionType)">{{selectedTransaction.amount | currency}}</span>
                </div>
            </div>
            <div class="column is-6">
                <label class="label">Reason</label>
                <div class="field">
                    {{selectedTransaction.reason}}
                </div>
            </div>
        </div>
        <portal to="footer-toolbar-left" v-if="!modalView">
          <div class="field is-grouped">
              <p class="control">
                  <a class="button is-danger" @click="onClose()">Close</a>
              </p>
          </div>
      </portal>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ViewEntityTransaction',
  components: {
  },
  props: {
    value: {
      type: Object,
      default: null
    },
    modalView: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      tabIndex: 0,
      selectedTransaction: this.value
    }
  },
  computed: {
  },
  methods: {
    onClose: function () {
      this.$emit('close-view-transaction', {})
    },
    getColorClassByTransactionType: function (row, status) {
      var result = 'has-text-danger'
      switch (status) {
        case 0:
          result = 'has-text-success'
          break
        case 1:
          result = 'has-text-danger'
          break
        case 2:
          result = 'has-text-info'
          break
      }

      let classToAttach = {}
      classToAttach[result] = true
      return classToAttach
    }
  },
  mounted: function () {
  },
  watch: {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

import axios from 'axios'
import baseService from './baseService'
import dealData from './data/product'

const appService = {
  queueSms (smsNotification) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/notifications/queuesms', smsNotification, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getConversation (customerMobile, agentMobile) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        let mobileNumbers = []
        mobileNumbers.push(customerMobile)
        if (agentMobile !== '') {
          mobileNumbers.push(agentMobile)
        }
        axios
          .post('/notifications/GetSmsConversation', mobileNumbers, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getConversationByDealId (dealId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .get('/notifications/GetSmsConversationByDealId?dealId=' + dealId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getConversationBySupplierId (supplierId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .get('/notifications/GetSmsConversationBySupplierId?supplierId=' + supplierId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  queuePickupLocationEmail (postModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/notifications/queuePickupLocationEmail', postModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  windowStickerSend (windowStickerSendModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/notifications/WindowStickerSend', windowStickerSendModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  initiateCall (callModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/notifications/initiateCall', callModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  vinDecodeWindowStickerSend (decodeVinWindowStickerSendModel) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/notifications/VinDecodeWindowStickerSend', decodeVinWindowStickerSendModel, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  deleteSmsNotification (notificationId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .post('/notifications/deletesms?id=' + notificationId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  },
  getSmsByType (type, typeId) {
    return new Promise((resolve, reject) => {
      if (baseService.isTestMode) {
        resolve(dealData)
      } else {
        axios
          .get('/notifications/GetSmsByType?type=' + type + '&typeId=' + typeId, baseService.defaultConfig)
          .then(response => {
            resolve(response.data)
          })
          .catch(error => {
            reject(error.response.data)
          })
      }
    })
  }
}

export default appService

<template>
  <div v-observe-visibility="visibilityChanged">
    <div id="menu-controls-container" class="is-flex is-hidden-mobile">
      <span id="collapse-action-container">
        <i class="fal fa-arrow-alt-from-right is-pulled-right menu-hider" :class="{'active': isSideBarActive}" @click="toggleSideBar" title="Click to collapse menu"></i>
      </span>
      <span id="pin-action-container">
        <i class="fal fa-thumbtack is-pulled-left menu-pin" :class="{'fa-rotate-90': autoCloseMenu}" @click="onToggleAutoCloseMenu()" :title="getPinTitle"></i>
      </span>
    </div>
    <aside :class="{'menu': true, 'app-sidebar': true, 'is-shadowless': true,}"  style="z-index:1">
      <div class="is-flex">
        <dealer-selector id="global-dealer-selector" v-model="selectedDealer" :isSwitcher="true" :showTitle="false" :updateWorkingDealer="true" v-if="hasFeatureAccess('dealer.switcher', false) && !isInTabletMode"></dealer-selector>
      </div>
      <ul class="menu-list">
        <li v-for="(item, index) in menu" :key="item.name" :class="{'is-parent-active': item.meta.isActive}" @click="addClass(index, item, null)">
          <router-link :to="item.path" :exact="true" :aria-expanded="isExpanded(item) ? 'true' : 'false'" v-if="item.path" @click.native="toggle(index, item, 1)">
            <span class="icon is-small"><i :class="['fas', item.meta.icon]"></i></span>
            {{ item.meta.label || item.name }}
            <span class="icon is-small is-angle" v-if="item.children && item.children.length">
              <i class="fas fa-angle-down"></i>
            </span>
          </router-link>

          <a :aria-expanded="isExpanded(item)" v-else @click="toggle(index, item, 2)">
            <span class="icon is-small"><i :class="['fas', item.meta.icon]"></i></span>
            {{ item.meta.label || item.name }}
            <span class="icon is-small is-angle" v-if="item.children && item.children.length">
              <i class="fas fa-angle-down"></i>
            </span>
          </a>

          <expanding v-if="item.children && item.children.length && item.name === 'Tools'">
            <ul v-show="isExpanded(item)">
              <li v-for="subItem in item.children" :key="subItem.name" v-if="subItem.path" @click.stop="addClass(index, item, subItem)">
                <router-link v-if="!subItem.app.isExternal" :to="generatePath(item, subItem)" :exact="true" @click.native="onMenuClick">
                  {{ subItem.meta && subItem.meta.label || subItem.name }}
                </router-link>
                <a v-else target="new_tab" :href="redirectToExternal(subItem)">{{ subItem.name }}</a>
              </li>
              <li v-show="isAuthenticated && canAccessSecurityToken">
                <router-link to="" @click.native="revealSecurityToken" aria-expanded='true'>
                  <span style="color: #b7b7c9;" v-tooltip="securityTokenTitle">{{ securityTokenDisplay }}</span>
                </router-link>
              </li>
            </ul>
          </expanding>
          <expanding v-else-if="item.children && item.children.length">
            <ul v-show="isExpanded(item)">
              <li v-for="subItem in item.children" :key="subItem.name" v-if="subItem.path" @click.stop="addClass(index, item, subItem)">
                <router-link v-if="!subItem.app.isExternal" :to="generatePath(item, subItem)" :exact="true" @click.native="onMenuClick">
                  {{ subItem.meta && subItem.meta.label || subItem.name }}
                </router-link>
                <a v-else target="new_tab" :href="redirectToExternal(subItem)">{{ subItem.name }}</a>
              </li>
            </ul>
          </expanding>
        </li>
      </ul>
    </aside>
  </div>
</template>

<script>

import Expanding from 'vue-bulma-expanding'
import { createNamespacedHelpers } from 'vuex'
import DealerSelector from '@/components/generic/DealerSelector'
import util from '@/utils'
const mapAuthGetters = createNamespacedHelpers('auth').mapGetters
const { mapState, mapGetters, mapActions } = createNamespacedHelpers('user')

export default {
  name: 'AppMenu',
  components: {
    Expanding,
    'dealer-selector': DealerSelector
  },
  props: {
    show: Boolean
  },
  data () {
    return {
      isReady: false,
      selectedDealer: null,
      timeoutId: null,
      autoCloseTimeout: 3,
      isInTabletMode: false,
      isSecurityTokenRevealed: false,
      defaultSecurityToken: '********'
    }
  },
  mounted () {
    let route = this.$route

    if (route.name) {
      this.isReady = true
      this.shouldExpandMatchItem(route)
    }
    if (this.isMobile()) {
      this.toggleSideBar()
    }
  },
  computed: {
    ...mapGetters({menu: 'menuitems'}),
    ...mapGetters(['hasFeatureAccess']),
    ...mapGetters(['isSideBarActive']),
    ...mapGetters(['autoCloseMenu']),
    ...mapAuthGetters(['securityToken']),
    ...mapState(['isAuthenticated', 'currentUser']),
    ...mapGetters(['allowedRoles', 'hasFeatureAccess']),
    getPinTitle: function () {
      if (this.autoCloseMenu) {
        return 'Click to pin the menu and prevent it from auto-closing.'
      } else {
        return 'Click to un-pin the menu and activate auto-closing after ' + this.autoCloseTimeout + ' seconds.'
      }
    },
    securityTokenTitle () {
      let securityTokenParts = this.securityToken.split(',')
      return this.isSecurityTokenRevealed ? '<b>PREVIOUS:</b> ' + securityTokenParts[1] + '<br/>' + '<b>CURRENT:</b> ' + securityTokenParts[0] : (this.isLoading ? 'Loading...' : this.defaultSecurityToken)
    },
    securityTokenDisplay () {
      let securityTokenParts = this.securityToken.split(',')
      return this.isSecurityTokenRevealed ? securityTokenParts[0] : (this.isLoading ? 'Loading...' : this.defaultSecurityToken)
    },
    canAccessSecurityToken: function () {
      return this.hasFeatureAccess('view.security.token')
    }
  },
  methods: {
    ...mapActions([
      'expandMenu',
      'setMenuActiveState',
      'toggleSideBar',
      'toggleAutoCloseMenu'
    ]),
    isMobile: function () {
      let el = document.getElementById('menu-controls-container')
      let style = window.getComputedStyle(el)
      let isMobile = style.getPropertyValue('display') === 'none'
      return isMobile
    },
    onToggleAutoCloseMenu: function () {
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      this.toggleAutoCloseMenu()
      if (this.autoCloseMenu || this.isMobile()) {
        this.executeTimedHiding()
      }
    },
    onMenuClick: function () {
      if (this.autoCloseMenu || this.isMobile()) {
        this.executeTimedHiding()
      }
    },
    executeTimedHiding: function () {
      let that = this
      if (this.timeoutId) {
        clearTimeout(this.timeoutId)
      }
      let timeLeft = (this.isMobile() ? 0.1 : this.autoCloseTimeout) * 1000
      this.timeoutId = setTimeout(function () {
        that.toggleSideBar()
      }, timeLeft)
    },
    isExpanded (item) {
      return item.meta.expanded
    },
    toggle (index, item, mode) {
      var hasOnlyOneChild = item.children && item.children.length === 1
      var routeTo = null
      if (hasOnlyOneChild) {
        let subItem = item.children[0]
        this.addClass(index, item, subItem)
        routeTo = { path: this.generatePath(item, subItem) }
      }

      if (hasOnlyOneChild) {
        if (item.meta.expanded === false) {
          this.expandMenu({
            index: index,
            expanded: !item.meta.expanded
          })
        }
      } else {
        if (item.meta.expanded === false) {
          let subItem = item.children[0]
          if (item.children.length > 0) {
            this.addClass(index, item, subItem)
            routeTo = { path: this.generatePath(item, subItem) }
          }
        }

        this.expandMenu({
          index: index,
          expanded: !item.meta.expanded
        })
      }
      if (routeTo && !routeTo.path.startsWith('http')) {
        this.$router.push(routeTo)
      }
      this.autoHideSideBar(item)
    },
    autoHideSideBar (item) {
      if (!(item.children && item.children.length > 1) && (this.autoCloseMenu || this.isMobile())) {
        this.executeTimedHiding()
      }
    },
    autoNavigateFirstChild (item) {
      if ((item.children && item.children.length === 1)) {
        let mItem = item.children[0]
        if (item.meta && item.meta.expanded) {
          this.$router.push({ path: mItem.path })
        }
      }
    },
    addClass (index, item, subItem) {
      var that = this
      var isChildMenuItem = subItem !== null
      var isChildLessParentMenuItem = item.children.length === 0
      var isSet = false
      if (isChildMenuItem || isChildLessParentMenuItem) {
        isSet = true
        this.setMenuActiveState({
          index: index,
          isActive: true
        })
      }
      if (isSet) {
        var rows = this.menu
        rows.forEach(function (innerItem, innerItemIndex) {
          if (innerItemIndex !== index) {
            that.setMenuActiveState({
              index: innerItemIndex,
              isActive: false
            })
          }
        })
      }
    },
    shouldExpandMatchItem (route) {
      let matched = route.matched

      if (matched && matched.length > 0) {
        let lastMatched = matched[matched.length - 1]
        let parent = lastMatched.parent || lastMatched
        const isParent = parent === lastMatched

        if (isParent) {
          const p = this.findParentFromMenu(route)
          if (p) {
            parent = p
          }
        }

        if ('expanded' in parent.meta && !isParent) {
          this.expandMenu({
            item: parent,
            expanded: true
          })
        }
      }
    },
    generatePath (item, subItem) {
      return `${item.component ? item.path + '/' : ''}${subItem.path}`
    },
    redirectToExternal (subItem) {
      return subItem.path
    },
    findParentFromMenu (route) {
      if (this.menu) {
        const menu = this.menu
        for (let i = 0, l = menu.length; i < l; i++) {
          const item = menu[i]
          const k = item.children && item.children.length
          if (k) {
            for (let j = 0; j < k; j++) {
              if (item.children[j].name === route.name) {
                return item
              }
            }
          }
        }
      }
    },
    visibilityChanged: function (isVisible, entry) {
      if (isVisible) {
        this.isInTabletMode = util.isInTabletMode()
      }
    },
    revealSecurityToken: function () {
      let that = this
      if (this.isSecurityTokenRevealed) return
      this.$store.dispatch('auth/revealSecurityToken', { subscriberId: this.currentUser.subscriberId })
      that.isSecurityTokenRevealed = true
      setTimeout(function () {
        that.isSecurityTokenRevealed = false
      }, 60000)
    }
  },
  watch: {
    $route (route) {
      if (route.name) {
        this.isReady = true
        this.shouldExpandMatchItem(route)
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../node_modules/bulma/sass/utilities/initial-variables';
@import '../../node_modules/bulma/sass/utilities/mixins';

.app-sidebar {
  position: relative;
  left: 0;
  bottom: 0;
  width: 100%;
  min-width: 45px;
  height: 100%;
  z-index: 1024 - 1;
  box-shadow: 0 2px 3px rgba(17, 17, 17, 0.1), 0 0 0 1px rgba(17, 17, 17, 0.1);
  overflow-y: auto;
  overflow-x: hidden;
  .icon {
    vertical-align: baseline;
    &.is-angle {
      position: absolute;
      right: 10px;
      transition: transform .377s ease;
    }
  }
  .menu-label {
    padding-left: 5px;
  }
  .menu-list {
    li a {
      &[aria-expanded="true"] {
        .is-angle {
          transform: rotate(180deg);
        }
      }
    }
    li a + ul {
      margin: 0 10px 0 15px;
    }
  }
}

.app-sidebar::-webkit-scrollbar {
  display:none;
}

.container.is-fluid {
  margin-left:10px;
  margin-right:10px;
}

#menu-controls-container {
  flex-direction: row-reverse;
  flex-wrap: nowrap;
  margin-right: -15px;
  height: 12px;
  max-height: 12px;
}

.menu-pin {
  transition: all 0.3s;
  margin-top: 5px;
  margin-right: 10px;
  color:#b7b7c9;
}

#collapse-action-container {
  width: 50%;
}

#pin-action-container {
  width:50%;
  margin-left: 1em;
}
</style>

<template>
    <div class="is-inline-block">
      <div v-if="isListMode">
        <i class="grid-icon fa-fw fal fa-truck ml-5 fa-lg clickable" @click.stop.prevent="onDownload()"/>
      </div>
      <div class="is-inline-block" v-else>
        <div class="quick-action is-pulled-left">
          <button type="button" class="button" @click="onDownload()">
            <i class="icon fal fa-truck fa-lg" />
          </button>
        </div>
      </div>
      <pdf-modal
        :pdfFile="selectedPdf"
        :usePortal="true"
        v-on:on-pdf-downloaded="onPreviewed"
        v-on:on-pdf-closed="onPreviewClosed"
        v-if="selectedPdf && selectedPdf.src">
      </pdf-modal>
    </div>
</template>

<script>
import PdfModalDisplayComponent from '@/components/generic/PdfModalDisplayComponent'

export default {
  name: 'DealSalesDeliverySignage',
  mixins: [],
  components: {
    'pdf-modal': PdfModalDisplayComponent
  },
  props: {
    currentDeal: {
      type: Object,
      default: null
    },
    isListMode: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedPdf: null,
      activeDeal: null
    }
  },
  computed: {
  },
  methods: {
    onPreviewClosed: function () {
      this.selectedPdf = null
    },
    onPreviewed: function () {
    },
    onDownload: function () {
      this.selectedPdf = {
        name: 'Sales Delivery Signage',
        src: process.env.VUE_APP_ROOT_DOC_SITE + 'docs/deals/sales-delivery-signage/' + this.activeDeal.id,
        fileName: 'sales_delivery_signage_' + this.activeDeal.id + '.pdf',
        key: this.$uuid.v4()
      }
    }
  },
  mounted: function () {
    this.activeDeal = this.currentDeal
  },
  watch: {
    currentDeal: function (newValue, oldValue) {
      if (newValue) {
        this.activeDeal = newValue
      }
    }
  }
}

</script>

<style scoped>
</style>
